/*
 * Copyright 2017, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */
module.exports = function load(global) {global.Intl && global.Intl._localeData && global.Intl._localeData.load({
    "locales": {
        "af-NA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,9g||",
                "Europe/London": "|1mh,0,cd||"
            },
            "metazone": {
                "Afghanistan": "b6,5,bc|||",
                "Africa_Central": "23u,5,c5,5,bc||vj|",
                "Africa_Eastern": "18m,5,c5,5,bc||vp|",
                "Africa_Southern": "32o,5,c5,5,9g||vu|",
                "Africa_Western": "14k,5,c5,5,9g|14k,5,c5,5,cd|103|vq",
                "Alaska": "3p,5,9g|3p,5,ik||",
                "Amazon": "350,5,9g|350,5,cd||",
                "America_Central": "35d,0,9g|35d,0,ik||",
                "America_Eastern": "242,0,9g|242,0,ik||",
                "America_Mountain": "35l,5,9g|35l,5,ik||",
                "America_Pacific": "24h,0,9g|24h,0,ik||",
                "Anadyr": "3l,5,9g|3l,5,cd||",
                "Apia": "3e,5,9g|3e,5,ik||",
                "Arabian": "35r,0,9g|35r,0,ik||",
                "Argentina": "24k,5,9g|24k,5,cd||",
                "Argentina_Western": "14k,5,24k,5,9g|14k,5,24k,5,cd||",
                "Armenia": "361,5,9g|361,5,cd||",
                "Atlantic": "362,0,9g|362,0,ik||",
                "Australia_Central": "173,0,2lc,5,9g|173,0,2lb,0,ik||",
                "Australia_CentralWestern": "173,0,2lc,5,24m,0,5oa,5,bc|173,0,2lc,5,24m,0,ik||",
                "Australia_Eastern": "173,0,242,0,9g|173,0,242,0,ik||",
                "Australia_Western": "173,0,24m,0,9g|173,0,24m,0,ik||",
                "Azerbaijan": "363,5,9g|363,5,cd||",
                "Azores": "36r,5,9g|36r,5,cd||",
                "Bangladesh": "24n,5,9g|24n,5,cd||",
                "Bhutan": "5md,5,bc|||",
                "Bolivia": "aa,5,bc|||",
                "Brasilia": "372,5,9g|372,0,cd||",
                "Brunei": "50q,0,a4,5,bc|||",
                "Cape_Verde": "37k,0,3j,5,9g|37k,0,3j,5,cd||",
                "Chamorro": "6j,5,9g|||",
                "Chatham": "27,5,9g|27,5,ik||",
                "Chile": "cg,5,9g|cg,5,cd||",
                "China": "4h,5,9g|4h,5,ik||",
                "Choibalsan": "2q,5,9g|2q,5,cd||",
                "Christmas": "5d1,5,bc|||",
                "Cocos": "5km,5,bc|||",
                "Colombia": "380,5,9g|380,5,cd||",
                "Cook": "383,5,9g|383,5,5pe||",
                "Cuba": "tb,5,9g|tb,5,ik||",
                "Davis": "5s,5,bc|||",
                "DumontDUrville": "5b,5,5j,5,bc|||",
                "East_Timor": "18m,5,7n,5,bc|||",
                "Easter": "38b,5,9g|38b,5,cd||",
                "Ecuador": "ai,5,bc|||",
                "Europe_Central": "23u,5,hv,0,9g|23u,5,hv,0,cd||",
                "Europe_Eastern": "18m,5,hv,0,9g|18m,5,hv,0,cd||",
                "Europe_Further_Eastern": "1q5,5,592,0,hv,0,59e|||",
                "Europe_Western": "14k,5,hv,0,9g|14k,5,hv,0,cd||",
                "Falkland": "38d,5,9g|38d,5,cd||",
                "Fiji": "cm,5,9g|cm,5,cd||",
                "French_Guiana": "1pg,5,am,5,bc|||",
                "French_Southern": "1ph,0,648,5,0,ou,0,6bb,0,bc|||",
                "Galapagos": "ak,5,bc|||",
                "Gambier": "6v,5,bc|||",
                "Georgia": "2l4,5,9g|2l4,5,cd||",
                "Gilbert_Islands": "5bs,5,bc|||",
                "GMT": "5u,5,5de|||",
                "Greenland_Eastern": "18m,5,8o,5,9g|18m,5,8o,5,cd||",
                "Greenland_Western": "14k,5,8o,5,9g|14k,5,8o,5,cd||",
                "Gulf": "127,5,9g|||",
                "Guyana": "7a,5,bc|||",
                "Hawaii_Aleutian": "4g,5,38p,5,9g|4g,5,38p,5,ik||",
                "Hong_Kong": "qh,5,9g|qh,5,cd||",
                "Hovd": "2g,5,9g|2g,5,cd||",
                "India": "6lg,5,9g|||",
                "Indian_Ocean": "7fb,0,8hs,5,bc|||",
                "Indochina": "4s4,5,bc|||",
                "Indonesia_Central": "23u,0,2l0,5,bc|||",
                "Indonesia_Eastern": "18m,5,2l0,5,bc|||",
                "Indonesia_Western": "14k,5,2l0,5,bc|||",
                "Iran": "5g,5,9g|5g,5,ik||",
                "Irkutsk": "3h,5,9g|3h,5,cd||",
                "Israel": "4e,5,9g|4e,5,ik||",
                "Japan": "6o,5,9g|6o,5,ik||",
                "Kamchatka": "37,5,3k,5,9g|37,5,3k,5,cd||",
                "Kazakhstan_Eastern": "18m,5,2kv,5,bc|||",
                "Kazakhstan_Western": "14k,5,2kv,5,bc|||",
                "Korea": "15c,0,9g|15c,0,ik||",
                "Kosrae": "6f,5,bc|||",
                "Krasnoyarsk": "l0,5,9g|l0,5,cd||",
                "Kyrgystan": "1qu,5,bc|||",
                "Line_Islands": "ac,5,6h7,5,bc|||",
                "Lord_Howe": "1u,0,24,5,9g|1u,0,24,5,ik||",
                "Macquarie": "6m,5,38v,5,bc|||",
                "Magadan": "2o,5,9g|2o,5,cd||",
                "Malaysia": "54h,5,bc|||",
                "Maldives": "54i,5,bc|||",
                "Marquesas": "9e,5,bc|||",
                "Marshall_Islands": "59b,5,bc|||",
                "Mauritius": "4u,5,9g|4u,5,cd||",
                "Mawson": "63,5,bc|||",
                "Mexico_Northwest": "392,5,17k,5,9g|392,5,17k,5,ik||",
                "Mexico_Pacific": "394,0,24h,0,9g|394,0,24h,0,ik||",
                "Mongolia": "396,5,9g|396,5,cd||",
                "Moscow": "143,5,9g|143,5,cd||",
                "Myanmar": "1eb,5,bc|||",
                "Nauru": "5v,5,bc|||",
                "Nepal": "8u,5,bc|||",
                "New_Caledonia": "1o7,5,397,5,9g|1o7,5,397,5,cd||",
                "New_Zealand": "1o7,5,39f,5,9g|1o7,5,39f,5,ik||",
                "Newfoundland": "53,5,9g|53,5,ik||",
                "Niue": "8g,5,bc|||",
                "Norfolk": "8j8,5,bc|||",
                "Noronha": "1s,0,2,0,23,5,9g|1s,0,2,0,23,5,cd||",
                "Novosibirsk": "3b,5,9g|3b,5,cd||",
                "Omsk": "26,5,9g|26,5,cd||",
                "Pakistan": "3i,5,9g|3i,5,cd||",
                "Palau": "9b,5,bc|||",
                "Papua_New_Guinea": "1lc,5,1o7,5,39j,5,bc|||",
                "Paraguay": "3c,5,9g|3c,5,cd||",
                "Peru": "4n,5,9g|4n,5,cd||",
                "Philippines": "39k,0,9g|39k,0,cd||",
                "Phoenix_Islands": "5no,5,bc|||",
                "Pierre_Miquelon": "39l,5,33,0,ou,0,35,5,9g|39l,5,33,0,ou,0,35,5,ik||",
                "Pitcairn": "7e,5,bc|||",
                "Ponape": "9s,5,bc|||",
                "Pyongyang": "8a,5,bc|||",
                "Reunion": "b4,5,bc|||",
                "Rothera": "5q,5,bc|||",
                "Sakhalin": "55,5,9g|55,5,cd||",
                "Samara": "29,5,9g|29,5,ik||",
                "Samoa": "2c,5,9g|2c,5,ik||",
                "Seychelles": "4q8,5,bc|||",
                "Singapore": "53g,5,9g|||",
                "Solomon": "547,5,bc|||",
                "South_Georgia": "32o,5,2l4,5,bc|||",
                "Suriname": "8q,5,bc|||",
                "Syowa": "65,5,bc|||",
                "Tahiti": "8n,5,bc|||",
                "Taipei": "3d,5,9g|3d,5,ik||",
                "Tajikistan": "lc,5,bc|||",
                "Tokelau": "5d,5,bc|||",
                "Tonga": "2j,5,9g|2j,5,cd||",
                "Truk": "6p,5,bc|||",
                "Turkmenistan": "4t,5,9g|4t,5,cd||",
                "Tuvalu": "6h,5,bc|||",
                "Uruguay": "4k,5,9g|4k,5,cd||",
                "Uzbekistan": "39p,5,9g|39p,5,cd||",
                "Vanuatu": "2f,5,9g|2f,5,cd||",
                "Venezuela": "7s,5,bc|||",
                "Vladivostok": "28,5,9g|28,5,cd||",
                "Volgograd": "3f,5,9g|3f,5,cd||",
                "Vostok": "5t,5,bc|||",
                "Wake": "5r,5,38v,5,bc|||",
                "Wallis": "5f,0,ou,0,5k,5,bc|||",
                "Yakutsk": "13v,5,9g|13v,5,cd||",
                "Yekaterinburg": "q6,5,9g|q6,5,cd||"
            }
        },
        "af": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,9g||",
                "Europe/London": "|1mh,0,cd||"
            },
            "metazone": {
                "Afghanistan": "b6,5,bc|||",
                "Africa_Central": "23u,5,c5,5,bc||vj|",
                "Africa_Eastern": "18m,5,c5,5,bc||vp|",
                "Africa_Southern": "32o,5,c5,5,9g||vu|",
                "Africa_Western": "14k,5,c5,5,9g|14k,5,c5,5,cd|103|vq",
                "Alaska": "3p,5,9g|3p,5,ik||",
                "Amazon": "350,5,9g|350,5,cd||",
                "America_Central": "35d,0,9g|35d,0,ik||",
                "America_Eastern": "242,0,9g|242,0,ik||",
                "America_Mountain": "35l,5,9g|35l,5,ik||",
                "America_Pacific": "24h,0,9g|24h,0,ik||",
                "Anadyr": "3l,5,9g|3l,5,cd||",
                "Apia": "3e,5,9g|3e,5,ik||",
                "Arabian": "35r,0,9g|35r,0,ik||",
                "Argentina": "24k,5,9g|24k,5,cd||",
                "Argentina_Western": "14k,5,24k,5,9g|14k,5,24k,5,cd||",
                "Armenia": "361,5,9g|361,5,cd||",
                "Atlantic": "362,0,9g|362,0,ik||",
                "Australia_Central": "173,0,2lc,5,9g|173,0,2lb,0,ik||",
                "Australia_CentralWestern": "173,0,2lc,5,24m,0,5oa,5,bc|173,0,2lc,5,24m,0,ik||",
                "Australia_Eastern": "173,0,242,0,9g|173,0,242,0,ik||",
                "Australia_Western": "173,0,24m,0,9g|173,0,24m,0,ik||",
                "Azerbaijan": "363,5,9g|363,5,cd||",
                "Azores": "36r,5,9g|36r,5,cd||",
                "Bangladesh": "24n,5,9g|24n,5,cd||",
                "Bhutan": "5md,5,bc|||",
                "Bolivia": "aa,5,bc|||",
                "Brasilia": "372,5,9g|372,0,cd||",
                "Brunei": "50q,0,a4,5,bc|||",
                "Cape_Verde": "37k,0,3j,5,9g|37k,0,3j,5,cd||",
                "Chamorro": "6j,5,9g|||",
                "Chatham": "27,5,9g|27,5,ik||",
                "Chile": "cg,5,9g|cg,5,cd||",
                "China": "4h,5,9g|4h,5,ik||",
                "Choibalsan": "2q,5,9g|2q,5,cd||",
                "Christmas": "5d1,5,bc|||",
                "Cocos": "5km,5,bc|||",
                "Colombia": "380,5,9g|380,5,cd||",
                "Cook": "383,5,9g|383,5,5pe||",
                "Cuba": "tb,5,9g|tb,5,ik||",
                "Davis": "5s,5,bc|||",
                "DumontDUrville": "5b,5,5j,5,bc|||",
                "East_Timor": "18m,5,7n,5,bc|||",
                "Easter": "38b,5,9g|38b,5,cd||",
                "Ecuador": "ai,5,bc|||",
                "Europe_Central": "23u,5,hv,0,9g|23u,5,hv,0,cd||",
                "Europe_Eastern": "18m,5,hv,0,9g|18m,5,hv,0,cd||",
                "Europe_Further_Eastern": "1q5,5,592,0,hv,0,59e|||",
                "Europe_Western": "14k,5,hv,0,9g|14k,5,hv,0,cd||",
                "Falkland": "38d,5,9g|38d,5,cd||",
                "Fiji": "cm,5,9g|cm,5,cd||",
                "French_Guiana": "1pg,5,am,5,bc|||",
                "French_Southern": "1ph,0,648,5,0,ou,0,6bb,0,bc|||",
                "Galapagos": "ak,5,bc|||",
                "Gambier": "6v,5,bc|||",
                "Georgia": "2l4,5,9g|2l4,5,cd||",
                "Gilbert_Islands": "5bs,5,bc|||",
                "GMT": "5u,5,5de|||",
                "Greenland_Eastern": "18m,5,8o,5,9g|18m,5,8o,5,cd||",
                "Greenland_Western": "14k,5,8o,5,9g|14k,5,8o,5,cd||",
                "Gulf": "127,5,9g|||",
                "Guyana": "7a,5,bc|||",
                "Hawaii_Aleutian": "4g,5,38p,5,9g|4g,5,38p,5,ik||",
                "Hong_Kong": "qh,5,9g|qh,5,cd||",
                "Hovd": "2g,5,9g|2g,5,cd||",
                "India": "6lg,5,9g|||",
                "Indian_Ocean": "7fb,0,8hs,5,bc|||",
                "Indochina": "4s4,5,bc|||",
                "Indonesia_Central": "23u,0,2l0,5,bc|||",
                "Indonesia_Eastern": "18m,5,2l0,5,bc|||",
                "Indonesia_Western": "14k,5,2l0,5,bc|||",
                "Iran": "5g,5,9g|5g,5,ik||",
                "Irkutsk": "3h,5,9g|3h,5,cd||",
                "Israel": "4e,5,9g|4e,5,ik||",
                "Japan": "6o,5,9g|6o,5,ik||",
                "Kamchatka": "37,5,3k,5,9g|37,5,3k,5,cd||",
                "Kazakhstan_Eastern": "18m,5,2kv,5,bc|||",
                "Kazakhstan_Western": "14k,5,2kv,5,bc|||",
                "Korea": "15c,0,9g|15c,0,ik||",
                "Kosrae": "6f,5,bc|||",
                "Krasnoyarsk": "l0,5,9g|l0,5,cd||",
                "Kyrgystan": "1qu,5,bc|||",
                "Line_Islands": "ac,5,6h7,5,bc|||",
                "Lord_Howe": "1u,0,24,5,9g|1u,0,24,5,ik||",
                "Macquarie": "6m,5,38v,5,bc|||",
                "Magadan": "2o,5,9g|2o,5,cd||",
                "Malaysia": "54h,5,bc|||",
                "Maldives": "54i,5,bc|||",
                "Marquesas": "9e,5,bc|||",
                "Marshall_Islands": "59b,5,bc|||",
                "Mauritius": "4u,5,9g|4u,5,cd||",
                "Mawson": "63,5,bc|||",
                "Mexico_Northwest": "392,5,17k,5,9g|392,5,17k,5,ik||",
                "Mexico_Pacific": "394,0,24h,0,9g|394,0,24h,0,ik||",
                "Mongolia": "396,5,9g|396,5,cd||",
                "Moscow": "143,5,9g|143,5,cd||",
                "Myanmar": "1eb,5,bc|||",
                "Nauru": "5v,5,bc|||",
                "Nepal": "8u,5,bc|||",
                "New_Caledonia": "1o7,5,397,5,9g|1o7,5,397,5,cd||",
                "New_Zealand": "1o7,5,39f,5,9g|1o7,5,39f,5,ik||",
                "Newfoundland": "53,5,9g|53,5,ik||",
                "Niue": "8g,5,bc|||",
                "Norfolk": "8j8,5,bc|||",
                "Noronha": "1s,0,2,0,23,5,9g|1s,0,2,0,23,5,cd||",
                "Novosibirsk": "3b,5,9g|3b,5,cd||",
                "Omsk": "26,5,9g|26,5,cd||",
                "Pakistan": "3i,5,9g|3i,5,cd||",
                "Palau": "9b,5,bc|||",
                "Papua_New_Guinea": "1lc,5,1o7,5,39j,5,bc|||",
                "Paraguay": "3c,5,9g|3c,5,cd||",
                "Peru": "4n,5,9g|4n,5,cd||",
                "Philippines": "39k,0,9g|39k,0,cd||",
                "Phoenix_Islands": "5no,5,bc|||",
                "Pierre_Miquelon": "39l,5,33,0,ou,0,35,5,9g|39l,5,33,0,ou,0,35,5,ik||",
                "Pitcairn": "7e,5,bc|||",
                "Ponape": "9s,5,bc|||",
                "Pyongyang": "8a,5,bc|||",
                "Reunion": "b4,5,bc|||",
                "Rothera": "5q,5,bc|||",
                "Sakhalin": "55,5,9g|55,5,cd||",
                "Samara": "29,5,9g|29,5,ik||",
                "Samoa": "2c,5,9g|2c,5,ik||",
                "Seychelles": "4q8,5,bc|||",
                "Singapore": "53g,5,9g|||",
                "Solomon": "547,5,bc|||",
                "South_Georgia": "32o,5,2l4,5,bc|||",
                "Suriname": "8q,5,bc|||",
                "Syowa": "65,5,bc|||",
                "Tahiti": "8n,5,bc|||",
                "Taipei": "3d,5,9g|3d,5,ik||",
                "Tajikistan": "lc,5,bc|||",
                "Tokelau": "5d,5,bc|||",
                "Tonga": "2j,5,9g|2j,5,cd||",
                "Truk": "6p,5,bc|||",
                "Turkmenistan": "4t,5,9g|4t,5,cd||",
                "Tuvalu": "6h,5,bc|||",
                "Uruguay": "4k,5,9g|4k,5,cd||",
                "Uzbekistan": "39p,5,9g|39p,5,cd||",
                "Vanuatu": "2f,5,9g|2f,5,cd||",
                "Venezuela": "7s,5,bc|||",
                "Vladivostok": "28,5,9g|28,5,cd||",
                "Volgograd": "3f,5,9g|3f,5,cd||",
                "Vostok": "5t,5,bc|||",
                "Wake": "5r,5,38v,5,bc|||",
                "Wallis": "5f,0,ou,0,5k,5,bc|||",
                "Yakutsk": "13v,5,9g|13v,5,cd||",
                "Yekaterinburg": "q6,5,9g|q6,5,cd||"
            }
        },
        "agq": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ak": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "am": {
            "gmtFormat": "ጂ ኤም ቲ{0}",
            "gmtZeroFormat": "ጂ ኤም ቲ",
            "zone": {
                "Europe/Dublin": "|c8i,0,f1,0,8b,0,gm||",
                "Europe/London": "|c8j,0,13f,0,8b,0,gm||"
            },
            "metazone": {
                "Afghanistan": "cee,0,8b|||",
                "Africa_Central": "39s,0,2uo,0,8b|||",
                "Africa_Eastern": "2v4,0,2uo,0,8b|||",
                "Africa_Southern": "56c,0,2uo,0,f1,0,8b|||",
                "Africa_Western": "31h,0,2uo,0,f1,0,8b|31h,0,2uo,0,rm,0,8b||",
                "Alaska": "59s,0,f1,0,15g,0,gm|59s,0,vn,0,8b,0,gm||",
                "Amazon": "5el,0,f1,0,8b,0,gm|5el,0,vn,0,8b,0,gm||",
                "America_Central": "5je,0,f1,0,8b,0,gm|5je,0,vn,0,8b,0,gm||",
                "America_Eastern": "2kp,0,f1,0,15g,0,gm|2kp,0,vn,0,8b,0,gm||",
                "America_Mountain": "5mc,0,f1,0,15g,0,gm|5mc,0,ced,0,gm||",
                "America_Pacific": "5me,0,f1,0,8b,0,gm|5me,0,vn,0,8b,0,gm||",
                "Anadyr": "cec,0,8b,0,gm|ceb,0,13f,0,15g,0,gm||",
                "Apia": "5vj,0,f1,0,8b|5vj,0,vn,0,ce7,0,8b||",
                "Arabian": "641,0,f1,0,8b|641,0,vn,0,1k6,0,8b||",
                "Argentina": "4pv,0,f1,0,8b,0,gm|4pv,0,13f,0,8b,0,gm||",
                "Argentina_Western": "2kp,0,ce6,0,f1,0,8b,0,gm|4pv,0,3a1,0,6bp,0,8b,0,gm||",
                "Armenia": "6d8,0,f1,0,8b|6d8,0,rm,0,8b||",
                "Atlantic": "6go,0,f1,0,15g,0,gm|6go,0,vn,0,8b,0,gm||",
                "Australia_Central": "1r1,0,4pt,0,f1,0,15g,0,gm|1r1,0,4pt,0,vn,0,8b,0,gm||",
                "Australia_CentralWestern": "1r1,0,4pt,0,3a1,0,f1,0,8b,0,gm|1r1,0,ce5,0,6m6,0,vn,0,8b,0,gm||",
                "Australia_Eastern": "1r1,0,6n5,0,f1,0,15g,0,gm|1r1,0,6n5,0,vn,0,8b,0,gm||",
                "Australia_Western": "1r1,0,3a1,0,f1,0,8b,0,gm|1r1,0,3a1,0,vn,0,8b,0,gm||",
                "Azerbaijan": "6n6,0,f1,0,8b|6n6,0,rm,0,8b||",
                "Azores": "6oo,0,f1,0,8b|6oo,0,rm,0,8b||",
                "Bangladesh": "6op,0,f1,0,8b|6op,0,rm,0,8b||",
                "Bhutan": "cdv,0,8b|||",
                "Bolivia": "cdu,0,8b|||",
                "Brasilia": "cdk,0,f1,0,8b,0,gm|cdj,0,13f,0,8b,0,gm||",
                "Brunei": "cdi,0,cdh,0,8b|||",
                "Cape_Verde": "4tu,0,4ud,0,f1,0,8b|4tu,0,4ud,0,rm,0,8b||",
                "Chamorro": "cdg,0,f1,0,8b|||",
                "Chatham": "515,0,f1,0,8b|515,0,vn,0,1k6,0,8b||",
                "Chile": "517,0,f1,0,8b|517,0,rm,0,8b||",
                "China": "51t,0,f1,0,8b|51t,0,vn,0,1k6,0,8b||",
                "Choibalsan": "531,0,f1,0,15g,0,gm|531,0,13f,0,cdf,0,gm||",
                "Christmas": "cde,0,2uq,0,8b|||",
                "Cocos": "cdd,0,1k7,0,8b|||",
                "Colombia": "57l,0,f1,0,8b|57l,0,rm,0,8b||",
                "Cook": "58h,0,1k7,0,f1,0,8b|58h,0,1k7,0,cdc,0,rm,0,8b||",
                "Cuba": "596,0,f1,0,8b|596,0,vn,0,1k6,0,8b||",
                "Davis": "cdb,0,8b|||",
                "DumontDUrville": "cda,5,cd9,0,8b|||",
                "East_Timor": "2v4,0,cd8,0,8b|||",
                "Easter": "5c6,0,2uq,0,f1,0,8b|5c6,0,2uq,0,rm,0,8b||",
                "Ecuador": "cd7,0,8b|||",
                "Europe_Central": "39s,0,2k1,0,f1,0,8b|39s,0,2k1,0,rm,0,8b||",
                "Europe_Eastern": "4p2,0,2k1,0,f1,0,8b|4p2,0,2k1,0,rm,0,8b||",
                "Europe_Further_Eastern": "cd6,0,6m6,0,cd5,0,8b|||",
                "Europe_Western": "2kp,0,2k1,0,f1,0,8b|2kp,0,2k1,0,rm,0,8b||",
                "Falkland": "5lu,0,1k7,0,f1,0,8b|5lu,0,1k7,0,rm,0,8b||",
                "Fiji": "5m1,0,f1,0,8b|5m1,0,rm,0,8b||",
                "French_Guiana": "5m9,0,cd4,0,8b|||",
                "French_Southern": "5m9,0,cd3,0,3gk,0,ccs,0,8b|||",
                "Galapagos": "ccr,0,8b|||",
                "Gambier": "ccq,0,8b|||",
                "Georgia": "5o0,0,f1,0,8b|5o0,0,rm,0,8b||",
                "Gilbert_Islands": "cco,0,1k7,0,8b|||",
                "GMT": "ccl,0,cck,0,8b|||",
                "Greenland_Eastern": "2v4,0,3gl,0,f1,0,8b|2v4,0,3gl,0,rm,0,8b||",
                "Greenland_Western": "31h,0,3gl,0,f1,0,8b|31h,0,3gl,0,rm,0,8b||",
                "Gulf": "ccj,0,f1,0,8b|||",
                "Guyana": "cci,0,8b|||",
                "Hawaii_Aleutian": "5q8,0,5qj,0,f1,0,8b,0,gm|5q8,0,5qj,0,vn,0,8b,0,gm||",
                "Hong_Kong": "5rb,0,5rl,0,f1,0,8b|5rb,0,5rl,0,rm,0,8b||",
                "Hovd": "5rn,0,f1,0,15g,0,gm|5rn,0,13f,0,8b,0,gm||",
                "India": "5sf,0,f1,0,8b|||",
                "Indian_Ocean": "5sf,0,cch,0,8b|||",
                "Indochina": "cc0,0,8b|||",
                "Indonesia_Central": "39s,0,4om,0,8b|||",
                "Indonesia_Eastern": "4p2,0,4om,0,8b|||",
                "Indonesia_Western": "2kp,0,4om,0,8b|||",
                "Iran": "5vk,0,f1,0,8b|5vk,0,vn,0,1k6,0,8b||",
                "Irkutsk": "cbr,0,f1,0,15g,0,gm|cbq,0,13f,0,15g,0,gm||",
                "Israel": "623,0,f1,0,8b|623,0,vn,0,1k6,0,8b||",
                "Japan": "632,0,f1,0,8b|632,0,vn,0,1k6,0,8b||",
                "Kamchatka": "637,0,5,0,63c,0,8b,0,gm|637,0,5,0,63c,0,13f,0,8b,0,gm||",
                "Kazakhstan_Eastern": "2v4,0,63n,0,8b|||",
                "Kazakhstan_Western": "31h,0,63n,0,8b|||",
                "Korea": "63t,0,f1,0,8b|63t,0,vn,0,1k6,0,8b||",
                "Kosrae": "cbk,0,8b|||",
                "Krasnoyarsk": "cbf,0,f1,0,8b,0,gm|cbe,0,13f,0,8b,0,gm||",
                "Kyrgystan": "cbd,0,8b|||",
                "Line_Islands": "cb9,0,1k7,0,8b|||",
                "Lord_Howe": "67b,0,68a,0,f1,0,15g,0,gm|67b,0,68a,0,vn,0,8b,0,gm||",
                "Macquarie": "cb3,0,2uq,0,8b|||",
                "Magadan": "695,0,f1,0,8b,0,gm|695,0,6bp,0,8b,0,gm||",
                "Malaysia": "cb2,0,8b|||",
                "Maldives": "cb1,0,8b|||",
                "Marquesas": "cb0,0,8b|||",
                "Marshall_Islands": "caf,0,1k7,0,8b|||",
                "Mauritius": "6be,0,f1,0,8b|6be,0,rm,0,8b||",
                "Mawson": "cae,0,8b|||",
                "Mexico_Northwest": "6c9,0,6cb,0,3i1,0,f1,0,8b,0,gm|6c9,0,6cb,0,3i1,0,vn,0,8b,0,gm||",
                "Mexico_Pacific": "3i1,0,6d9,0,f1,0,8b,0,gm|3i1,0,6d9,0,vn,0,8b,0,gm||",
                "Mongolia": "6fk,0,6g1,0,f1,0,8b,0,gm|6fk,0,6g1,0,13f,0,8b,0,gm||",
                "Moscow": "6gf,0,f1,0,8b,0,gm|6gf,0,13f,0,8b,0,gm||",
                "Myanmar": "cad,0,8b|||",
                "Nauru": "cac,0,8b|||",
                "Nepal": "cab,0,8b|||",
                "New_Caledonia": "3i2,0,6gq,0,f1,0,8b|3i2,0,6gq,0,rm,0,8b||",
                "New_Zealand": "3i2,0,6gu,0,f1,0,8b|3i2,0,6gu,0,vn,0,1k6,0,8b||",
                "Newfoundland": "6h0,0,f1,0,15g,0,gm|6h0,0,vn,0,15g,0,gm||",
                "Niue": "ca9,0,8b|||",
                "Norfolk": "ca6,0,1k7,0,8b|||",
                "Noronha": "6hp,0,6ic,0,ca5,0,f1,0,8b,0,gm|6hp,0,6ic,0,ca0,0,13f,0,15g,0,gm||",
                "Novosibirsk": "c9u,0,f1,0,15g,0,gm|c9t,0,13f,0,c9s,0,gm||",
                "Omsk": "6mi,0,f1,0,8b,0,gm|6mi,0,13f,0,8b,0,gm||",
                "Pakistan": "6mm,0,f1,0,8b|6mm,0,rm,0,8b||",
                "Palau": "c9r,0,8b|||",
                "Papua_New_Guinea": "c9o,0,c9m,0,c9l,0,8b|||",
                "Paraguay": "6nf,0,f1,0,8b|6nf,0,rm,0,8b||",
                "Peru": "6nl,0,f1,0,8b|6nl,0,rm,0,8b||",
                "Philippines": "6nm,0,f1,0,8b|6nm,0,rm,0,8b||",
                "Phoenix_Islands": "c9k,0,1k7,0,8b|||",
                "Pierre_Miquelon": "6o3,0,6o8,0,3gk,0,6oa,0,f1,0,8b|6o3,0,6o8,0,3gk,0,6oa,0,vn,0,1k6,0,8b||",
                "Pitcairn": "c9j,0,8b|||",
                "Ponape": "c9a,0,8b|||",
                "Pyongyang": "c99,0,8b|||",
                "Reunion": "c98,0,8b|||",
                "Rothera": "c97,0,8b|||",
                "Sakhalin": "6pe,0,f1,0,8b,0,gm|6pe,0,13f,0,8b,0,gm||",
                "Samara": "6po,0,f1,0,8b,0,gm|6po,0,13f,0,8b,0,gm||",
                "Samoa": "6pu,0,f1,0,8b|6pu,0,13f,0,8b||",
                "Seychelles": "c91,0,8b|||",
                "Singapore": "c90,0,f1,0,8b|||",
                "Solomon": "c8v,0,1k7,0,8b|||",
                "South_Georgia": "56c,0,c8r,0,8b|||",
                "Suriname": "c8q,0,8b|||",
                "Syowa": "c8p,0,8b|||",
                "Tahiti": "c8o,0,8b|||",
                "Taipei": "8ck,0,f1,0,8b|8ck,0,vn,0,1k6,0,8b||",
                "Tajikistan": "c8n,0,8b|||",
                "Tokelau": "c8m,0,8b|||",
                "Tonga": "8iv,0,f1,0,8b|8iv,0,rm,0,8b||",
                "Truk": "c8l,0,8b|||",
                "Turkmenistan": "4s3,0,f1,0,8b|4s3,0,rm,0,8b||",
                "Tuvalu": "c8k,0,8b|||",
                "Uruguay": "4s5,0,f1,0,8b|4s5,0,rm,0,8b||",
                "Uzbekistan": "4sd,0,f1,0,8b|4sd,0,rm,0,8b||",
                "Vanuatu": "4st,0,f1,0,8b|4st,0,rm,0,8b||",
                "Venezuela": "c8h,0,8b|||",
                "Vladivostok": "c8g,0,f1,0,15g,0,gm|c8b,0,13f,0,15g,0,gm||",
                "Volgograd": "c8a,0,f1,0,8b,0,gm|c87,0,13f,0,8b,0,gm||",
                "Vostok": "c86,0,8b|||",
                "Wake": "c85,0,2uq,0,8b|||",
                "Wallis": "c84,0,3gk,0,c83,0,8b|||",
                "Yakutsk": "c82,0,f1,0,8b,0,gm|c81,0,13f,0,8b,0,gm||",
                "Yekaterinburg": "53s,0,f1,0,8b,0,gm|53s,0,13f,0,8b,0,gm||"
            }
        },
        "ar-AE": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-BH": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-DJ": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-DZ": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-EG": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-EH": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-ER": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-IL": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-IQ": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-JO": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-KM": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-KW": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-LB": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-LY": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-MA": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-MR": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-OM": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-PS": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-QA": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-SA": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-SD": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-SO": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-SS": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-SY": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-TD": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-TN": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar-YE": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "ar": {
            "gmtFormat": "جرينتش{0}",
            "gmtZeroFormat": "جرينتش",
            "zone": {
                "Europe/Dublin": "|7,0,uf,0,h||",
                "Europe/London": "|7,0,v3,0,g||"
            },
            "metazone": {
                "Afghanistan": "7,0,tv|||",
                "Africa_Central": "7,0,7k,0,ap|||",
                "Africa_Eastern": "7,0,51,0,ap|||",
                "Africa_Southern": "7,0,ij,0,ap|||",
                "Africa_Western": "7,0,2e,0,ap,0,h|7,0,2e,0,ap,0,g||",
                "Alaska": "1o,0,h,0,v1|7,0,tl,0,g||",
                "Amazon": "7,0,iv,0,h|7,0,iv,0,g||",
                "America_Central": "1o,0,h,0,j2,0,9j,0,8m|1o,0,g,0,j2,0,9j,0,8m||",
                "America_Eastern": "1o,0,h,0,ji,0,9j,0,8m|1o,0,g,0,ji,0,9j,0,8m||",
                "America_Mountain": "1o,0,jl,0,h,0,9j,0,8m|1o,0,jl,0,g,0,9j,0,8m||",
                "America_Pacific": "7,0,ao,0,bt,0,h|7,0,ao,0,bt,0,g||",
                "Anadyr": "7,0,v5,0,h|1o,0,g,0,vc||",
                "Apia": "1o,0,h,0,s8|1o,0,g,0,t4||",
                "Arabian": "1o,0,k5,0,h|1o,0,k5,0,g||",
                "Argentina": "7,0,bq,0,h|7,0,bq,0,g||",
                "Argentina_Western": "7,0,2e,0,bq,0,h|7,0,2e,0,bq,0,g||",
                "Armenia": "7,0,k9,0,h|7,0,k9,0,g||",
                "Atlantic": "1o,0,h,0,kr|1o,0,g,0,kr||",
                "Australia_Central": "7,0,7k,0,7i,0,h|7,0,7k,0,7i,0,g||",
                "Australia_CentralWestern": "7,0,2e,0,7k,0,7i,0,h|7,0,2e,0,7k,0,7i,0,g||",
                "Australia_Eastern": "7,0,51,0,7i,0,h|7,0,51,0,7i,0,g||",
                "Australia_Western": "7,0,2e,0,7i,0,h|7,0,2e,0,7i,0,g||",
                "Azerbaijan": "7,0,l9,0,h|7,0,l9,0,g||",
                "Azores": "7,0,la,0,h|7,0,la,0,g||",
                "Bangladesh": "7,0,li,0,h|7,0,li,0,g||",
                "Bhutan": "7,0,rp|||",
                "Bolivia": "7,0,t5|||",
                "Brasilia": "7,0,j1,0,h|7,0,j1,0,g||",
                "Brunei": "7,0,u4|||",
                "Cape_Verde": "7,0,j3,0,j4,0,h|7,0,j3,0,j4,0,g||",
                "Chamorro": "7,0,v9|||",
                "Chatham": "7,0,j8,0,h|7,0,j8,0,g||",
                "Chile": "7,0,jf,0,h|7,0,jf,0,g||",
                "China": "7,0,jg,0,h|7,0,jg,0,g||",
                "Choibalsan": "7,0,vb,0,h|1o,0,g,0,um||",
                "Christmas": "7,0,42,0,un|||",
                "Cocos": "7,0,42,0,ut|||",
                "Colombia": "7,0,jr,0,h|7,0,jr,0,g||",
                "Cook": "7,0,42,0,js,0,h|7,0,42,0,js,0,g||",
                "Cuba": "7,0,jv,0,h|7,0,jv,0,g||",
                "Davis": "7,0,vh|||",
                "DumontDUrville": "7,0,fb,0,sa,0,sl,0,so|||",
                "East_Timor": "7,0,t1,0,t2|||",
                "Easter": "7,0,bs,0,k4,0,h|7,0,bs,0,k4,0,g||",
                "Ecuador": "7,0,ta|||",
                "Europe_Central": "7,0,7k,0,9h,0,h|7,0,7k,0,9h,0,g||",
                "Europe_Eastern": "7,0,51,0,9h,0,h|7,0,51,0,9h,0,g||",
                "Europe_Further_Eastern": "1o,0,ti,0,tm,0,ue|||",
                "Europe_Western": "7,0,2e,0,9h,0,h|7,0,2e,0,9h,0,g||",
                "Falkland": "7,0,42,0,kd,0,h|7,0,42,0,kd,0,g||",
                "Fiji": "7,0,kg,0,h|7,0,kg,0,g||",
                "French_Guiana": "7,0,v2,0,kk|||",
                "French_Southern": "7,0,st,0,kk,0,t8,0,td|||",
                "Galapagos": "7,0,tg|||",
                "Gambier": "7,0,to|||",
                "Georgia": "7,0,f9,0,h|7,0,f9,0,g||",
                "Gilbert_Islands": "7,0,42,0,u6|||",
                "GMT": "7,0,ua|||",
                "Greenland_Eastern": "7,0,51,0,bo,0,h|7,0,51,0,bo,0,g||",
                "Greenland_Western": "7,0,2e,0,bo,0,h|7,0,2e,0,bo,0,g||",
                "Guam": "7,0,ub|||",
                "Gulf": "7,0,uc|||",
                "Guyana": "7,0,ud|||",
                "Hawaii_Aleutian": "7,0,lg,0,lj,0,h|7,0,lg,0,lj,0,g||",
                "Hong_Kong": "7,0,lk,0,ll,0,h|7,0,lk,0,ll,0,g||",
                "Hovd": "7,0,ld,0,h|7,0,ld,0,g||",
                "India": "7,0,lb|||",
                "Indian_Ocean": "7,0,ao,0,sj|||",
                "Indochina": "7,0,lb,0,sk|||",
                "Indonesia_Central": "7,0,7k,0,f5|||",
                "Indonesia_Eastern": "7,0,51,0,f5|||",
                "Indonesia_Western": "7,0,2e,0,f5|||",
                "Iran": "7,0,l5,0,h|7,0,l5,0,g||",
                "Irkutsk": "7,0,l1,0,h|7,0,l1,0,g||",
                "Israel": "7,0,ku,0,h|7,0,ku,0,g||",
                "Japan": "7,0,ks,0,h|7,0,ks,0,g||",
                "Kamchatka": "7,0,kq,5,kp|7,0,kq,5,kp,0,g||",
                "Kazakhstan_Eastern": "7,0,51,0,km|||",
                "Kazakhstan_Western": "7,0,2e,0,km|||",
                "Korea": "7,0,kl,0,h|7,0,kl,0,g||",
                "Kosrae": "7,0,tt|||",
                "Krasnoyarsk": "7,0,tu,0,h|1o,0,g,0,u3||",
                "Kyrgystan": "7,0,u9|||",
                "Line_Islands": "7,0,42,0,s2|||",
                "Lord_Howe": "7,0,rh,0,k2,0,h|1o,0,g,0,uo,0,k2||",
                "Macquarie": "7,0,sn|||",
                "Magadan": "7,0,ja,0,h|7,0,ja,0,g||",
                "Malaysia": "7,0,tc|||",
                "Maldives": "7,0,te|||",
                "Marquesas": "7,0,th|||",
                "Marshall_Islands": "7,0,42,0,rb|||",
                "Mauritius": "7,0,l8,0,h|7,0,l8,0,g||",
                "Mawson": "7,0,rj|||",
                "Mexico_Northwest": "1o,0,h,0,k3,0,2e,0,jt|1o,0,g,0,k3,0,2e,0,jt||",
                "Mexico_Pacific": "7,0,ao,0,bt,0,h,0,jd|7,0,ao,0,bt,0,g,0,jd||",
                "Mongolia": "7,0,jc,0,jb,0,h|7,0,jc,0,jb,0,g||",
                "Moscow": "7,0,j0,0,h|7,0,j0,0,g||",
                "Myanmar": "7,0,uh|||",
                "Nauru": "7,0,ui|||",
                "Nepal": "7,0,ul|||",
                "New_Caledonia": "7,0,kc,0,f2,0,h|7,0,kc,0,f2,0,g||",
                "New_Zealand": "7,0,lm,0,h|7,0,lm,0,g||",
                "Newfoundland": "7,0,jq,0,h|7,0,jq,0,g||",
                "Niue": "7,0,s7|||",
                "Norfolk": "7,0,bs,0,s6|||",
                "Noronha": "7,0,ig,0,fb,0,l6,0,h|7,0,ig,0,fb,0,l6,0,g||",
                "North_Mariana": "7,0,42,0,v6,0,8m|||",
                "Novosibirsk": "7,0,j5,0,h|7,0,j5,0,g||",
                "Omsk": "7,0,j6,0,h|7,0,j6,0,g||",
                "Pakistan": "7,0,j7,0,h|7,0,j7,0,g||",
                "Palau": "7,0,si|||",
                "Papua_New_Guinea": "7,0,ss,0,su,0,f2|||",
                "Paraguay": "7,0,kb,0,h|7,0,kb,0,g||",
                "Peru": "7,0,kn,0,h|7,0,kn,0,g||",
                "Philippines": "7,0,l2,0,h|7,0,l2,0,g||",
                "Phoenix_Islands": "7,0,42,0,tf|||",
                "Pierre_Miquelon": "7,0,l3,0,l4,0,l7,0,h|7,0,l3,0,l4,0,l7,0,g||",
                "Pitcairn": "7,0,tk|||",
                "Ponape": "7,0,tr|||",
                "Pyongyang": "7,0,ts,0,u1|||",
                "Reunion": "7,0,u5|||",
                "Rothera": "7,0,u7|||",
                "Sakhalin": "7,0,k0,0,h|7,0,k0,0,g||",
                "Samara": "7,0,if|7,0,if,0,g||",
                "Samoa": "7,0,ie,0,h|7,0,ie,0,g||",
                "Seychelles": "7,0,us|||",
                "Singapore": "7,0,uv|||",
                "Solomon": "7,0,42,0,v7|||",
                "South_Georgia": "7,0,ij,0,f9|||",
                "Suriname": "7,0,vd|||",
                "Syowa": "7,0,ve|||",
                "Tahiti": "7,0,vf|||",
                "Taipei": "7,0,jp,0,h|7,0,jp,0,g||",
                "Tajikistan": "7,0,vg|||",
                "Tokelau": "7,0,va|||",
                "Tonga": "7,0,j9,0,h|7,0,j9,0,g||",
                "Truk": "7,0,v8|||",
                "Turkmenistan": "7,0,jj,0,h|7,0,jj,0,g||",
                "Tuvalu": "7,0,v4|||",
                "Uruguay": "7,0,jm,0,h|7,0,jm,0,g||",
                "Uzbekistan": "7,0,ka,0,h|7,0,ka,0,g||",
                "Vanuatu": "7,0,ko,0,h|7,0,ko,0,g||",
                "Venezuela": "7,0,v0|||",
                "Vladivostok": "7,0,it,0,h|7,0,it,0,g||",
                "Volgograd": "7,0,iu,0,h|7,0,iu,0,g||",
                "Vostok": "7,0,uq|||",
                "Wake": "7,0,bs,0,up|||",
                "Wallis": "7,0,uj,0,pu,0,rg|||",
                "Yakutsk": "7,0,k7,0,h|7,0,k7,0,g||",
                "Yekaterinburg": "7,0,le,0,h|7,0,le,0,g||"
            }
        },
        "as": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "India": "c80,0,2n||c7v,0,c7u|"
            }
        },
        "asa": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ast": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,d,0,8iu||",
                "Europe/London": "|9,0,eq,0,sc||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "8,0,d,0,61o|8,0,eq,0,61o||",
                "Afghanistan": "9,0,c7t|||",
                "Africa_Central": "9,0,3im,0,6r|||",
                "Africa_Eastern": "9,0,3im,0,19,0,sr|||",
                "Africa_Southern": "9,0,2,0,sf|||",
                "Africa_Western": "9,0,d,0,3im,0,19,0,2a6|9,0,eq,0,3im,0,19,0,2a6||",
                "Alaska": "9,0,d,0,1ht|9,0,eq,0,1ht|18f|18t",
                "Almaty": "8,0,d,0,670|8,0,eq,0,670||",
                "Amazon": "9,0,d,0,19,0,bg|9,0,eq,0,19,0,bg||",
                "America_Central": "9,0,d,0,6r,0,683|9,0,eq,0,6r,0,683|19q|19p",
                "America_Eastern": "9,0,d,0,19,0,sr,0,3in|9,0,eq,0,19,0,sr,0,3in|19o|19n",
                "America_Mountain": "9,0,d,0,2,0,et,0,6ah,0,6ap|9,0,eq,0,2,0,et,0,6ah,0,6ap|18r|16i",
                "America_Pacific": "9,0,d,0,19,0,3io,0,3in|9,0,eq,0,19,0,3io,0,3in|19l|19k",
                "Anadyr": "8,0,d,0,cb|8,0,eq,0,cb||",
                "Apia": "9,0,d,0,c9|9,0,eq,0,c9||",
                "Aqtau": "9,0,d,0,6e1|9,0,eq,0,6e1||",
                "Aqtobe": "9,0,d,0,6e5|9,0,eq,0,6e5||",
                "Arabian": "9,0,d,0,6ed|9,0,eq,0,6ed||",
                "Argentina": "9,0,d,0,3is|9,0,eq,0,3is||",
                "Argentina_Western": "9,0,d,0,48,0,3is|9,0,eq,0,48,0,3is||",
                "Armenia": "9,0,d,0,6gd|9,0,eq,0,6gd||",
                "Atlantic": "9,0,d,0,19,0,6ge|9,0,eq,0,19,0,6ge|19i|190",
                "Australia_Central": "9,0,d,0,1rq,0,6r|9,0,eq,0,1rq,0,6r||",
                "Australia_CentralWestern": "9,0,d,0,1rq,0,6r,0,19,0,2a6|9,0,eq,0,1rq,0,6r,0,19,0,2a6||",
                "Australia_Eastern": "9,0,d,0,1rq,0,19,0,sr|9,0,eq,0,1rq,0,19,0,sr||",
                "Australia_Western": "9,0,d,0,1rq,0,19,0,2a6|9,0,eq,0,1rq,0,19,0,2a6||",
                "Azerbaijan": "9,0,d,0,6gl|9,0,eq,0,6gl||",
                "Azores": "9,0,d,0,2,0,et,0,4r|9,0,eq,0,2,0,4n7,0,4r||",
                "Bangladesh": "9,0,d,0,2,0,6gp|9,0,eq,0,2,0,6gp||",
                "Bhutan": "9,0,2,0,s5|||",
                "Bolivia": "9,0,2,0,aa|||",
                "Brasilia": "9,0,d,0,2,0,3a|9,0,eq,0,2,0,3a||",
                "Brunei": "9,0,2,0,gs,0,a4|||",
                "Cape_Verde": "9,0,d,0,2,0,6h1,0,3j|9,0,eq,0,2,0,6h1,0,3j||",
                "Casey": "9,0,2,0,b7|||",
                "Chamorro": "9,0,d,0,2,0,6j|||",
                "Chatham": "9,0,d,0,2,0,27|9,0,eq,0,2,0,27||",
                "Chile": "9,0,d,0,2,0,49|9,0,eq,0,2,0,49||",
                "China": "9,0,d,0,2,0,4h|9,0,eq,0,2,0,4h||",
                "Choibalsan": "9,0,d,0,2,0,2q|9,0,eq,0,2,0,2q||",
                "Christmas": "9,0,d,0,2,0,1c,0,2j2,0,99|||",
                "Cocos": "9,0,2,0,et,0,1kb,0,7v|||",
                "Colombia": "9,0,d,0,2,0,4m|9,0,eq,0,2,0,4m||",
                "Cook": "9,0,d,0,2,0,et,0,1kb,0,2v|9,0,r1,0,eq,0,2,0,et,0,1kb,0,2v||",
                "Cuba": "9,0,d,0,2,0,34|9,0,eq,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,d,0,2,0,1c,0,2j2,0,2,0,iq|9,0,eq,0,2,0,1c,0,2j2,0,2,0,iq||",
                "Ecuador": "9,0,c7s|||",
                "Europe_Central": "9,0,d,0,pn,0,n|9,0,eq,0,pn,0,n||",
                "Europe_Eastern": "9,0,d,0,pn,0,19,0,3it|9,0,eq,0,pn,0,19,0,3it||",
                "Europe_Further_Eastern": "9,0,pn,0,19,0,c7q,0,sr|||",
                "Europe_Western": "9,0,d,0,pn,0,of|9,0,eq,0,pn,0,of||",
                "Falkland": "9,0,d,0,2,0,et,0,1kb,0,4v|9,0,eq,0,2,0,et,0,1kb,0,4v||",
                "Fiji": "9,0,d,0,2,0,6o7|9,0,eq,0,2,0,6o7||",
                "French_Guiana": "9,0,2,0,mv,0,7a,0,nf|||",
                "French_Southern": "9,0,19,0,c7o,0,bk,0,c7n,0,c7m|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,d,0,2,0,4n4|9,0,eq,0,2,0,4n4||",
                "Gilbert_Islands": "9,0,2,0,et,0,1kb,0,6q|||",
                "GMT": "9,0,r1,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,d,0,2,0,b2,0,3r|9,0,eq,0,2,0,b2,0,3r||",
                "Greenland_Western": "9,0,d,0,2,0,b2,0,48|9,0,eq,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "9,0,d,0,19,0,c7l|||",
                "Guyana": "9,0,2,0,mv,0,7a|||",
                "Hawaii_Aleutian": "9,0,d,0,2,0,4g,5,1i5|9,0,eq,0,2,0,4g,5,1i5|17j|17l",
                "Hong_Kong": "9,0,d,0,2,0,78a,0,2p|9,0,eq,0,2,0,78a,0,2p||",
                "Hovd": "9,0,d,0,2,0,2g|9,0,eq,0,2,0,2g||",
                "India": "9,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "9,0,19,0,c7k,0,c7j|||",
                "Indochina": "9,0,c7i|||",
                "Indonesia_Central": "9,0,4n3,0,6r|||",
                "Indonesia_Eastern": "9,0,4n3,0,19,0,sr|||",
                "Indonesia_Western": "9,0,4n3,0,19,0,2a6|||",
                "Iran": "9,0,d,0,8ho|9,0,eq,0,8ho||",
                "Irkutsk": "9,0,d,0,1ia|9,0,eq,0,1ia||",
                "Israel": "9,0,d,0,1ic|9,0,eq,0,1ic||",
                "Japan": "9,0,d,0,2,0,3iu|9,0,eq,0,2,0,3iu||",
                "Kamchatka": "8,0,c7h,0,2,0,37,5,3k|8,0,eq,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,19,0,8j6,0,3r|||",
                "Kazakhstan_Western": "9,0,19,0,8j6,0,48|||",
                "Korea": "9,0,d,0,2,0,hf|9,0,eq,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,d,0,2,0,41|9,0,eq,0,2,0,41||",
                "Kyrgystan": "9,0,19,0,sp|||",
                "Lanka": "9,0,2,0,9c|||",
                "Line_Islands": "9,0,2,0,et,0,1kb,0,ac|||",
                "Lord_Howe": "9,0,d,0,2,0,1u,0,24|9,0,eq,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,4s7|9,0,eq,0,2,0,4s7||",
                "Macquarie": "9,0,2,0,1c,0,2j2,0,6m|||",
                "Magadan": "9,0,d,0,2,0,jh|9,0,eq,0,2,0,jh||",
                "Malaysia": "9,0,2,0,sh|||",
                "Maldives": "9,0,2,0,4n7,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,1kb,0,7o|||",
                "Mauritius": "9,0,d,0,2,0,50o|9,0,eq,0,2,0,50o||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,d,0,19,0,jn,0,2,0,3j1|9,0,eq,0,19,0,jn,0,2,0,3j1||",
                "Mexico_Pacific": "9,0,d,0,19,0,3io,0,2,0,3j1|9,0,eq,0,19,0,3io,0,2,0,3j1||",
                "Mongolia": "9,0,d,0,516,0,3j2|9,0,eq,0,516,0,3j2||",
                "Moscow": "9,0,d,0,2,0,k6|9,0,eq,0,2,0,k6||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,d,0,2,0,an,0,4o|9,0,eq,0,2,0,an,0,4o||",
                "New_Zealand": "9,0,d,0,2,0,an,0,go|9,0,eq,0,2,0,an,0,go||",
                "Newfoundland": "9,0,d,0,2,0,53|9,0,eq,0,2,0,53||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,1c,0,2j2,0,6l|||",
                "Noronha": "9,0,d,0,2,0,1s,0,2,0,23|9,0,eq,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,et,0,1kb,0,c7g,0,19,0,p4|||",
                "Novosibirsk": "9,0,d,0,2,0,3b|9,0,eq,0,2,0,3b||",
                "Omsk": "9,0,d,0,1d1|9,0,eq,0,1d1||",
                "Pakistan": "9,0,d,0,19,0,3j4|9,0,eq,0,19,0,3j4||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "9,0,d,0,19,0,58f|9,0,eq,0,19,0,58f||",
                "Peru": "9,0,d,0,19,0,ha|9,0,eq,0,19,0,ha||",
                "Philippines": "9,0,d,0,2,0,1jb|9,0,2,0,c7f,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,1kb,0,90|||",
                "Pierre_Miquelon": "9,0,d,0,2,0,8i,0,33,0,bk,0,35|9,0,eq,0,2,0,8i,0,33,0,bk,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,eq,0,2,0,4d||",
                "Reunion": "9,0,2,0,s4|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,d,0,2,0,5ad|9,0,eq,0,2,0,5ad||",
                "Samara": "9,0,d,0,2,0,29|9,0,eq,0,2,0,29||",
                "Samoa": "9,0,d,0,2,0,2c|9,0,eq,0,2,0,2c||",
                "Seychelles": "9,0,2,0,4n7,0,5an|||",
                "Singapore": "9,0,d,0,2,0,m8|||",
                "Solomon": "9,0,2,0,et,0,1kb,0,s1|||",
                "South_Georgia": "9,0,2,0,4n4,0,19,0,4mu|||",
                "Suriname": "9,0,19,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,8n|||",
                "Taipei": "9,0,d,0,2,0,lf|9,0,eq,0,2,0,lf||",
                "Tajikistan": "9,0,19,0,5et|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,d,0,2,0,2j|9,0,eq,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,d,0,19,0,lh|9,0,eq,0,19,0,lh||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,d,0,19,0,5jo|9,0,eq,0,19,0,5jo||",
                "Uzbekistan": "9,0,d,0,19,0,5ka|9,0,eq,0,19,0,5ka||",
                "Vanuatu": "9,0,d,0,2,0,2f|9,0,eq,0,2,0,2f||",
                "Venezuela": "9,0,2,0,7s|||",
                "Vladivostok": "9,0,d,0,2,0,28|9,0,eq,0,2,0,28||",
                "Volgograd": "9,0,d,0,2,0,5kf|9,0,eq,0,2,0,5kf||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,1c,0,2j2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "9,0,d,0,2,0,3u|9,0,eq,0,2,0,3u||",
                "Yekaterinburg": "9,0,d,0,2,0,5kq|9,0,eq,0,2,0,5kq||"
            }
        },
        "az-Cyrl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "az-Latn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|772,0,a3,0,30||",
                "Europe/London": "|3i5,0,a3,0,30||"
            },
            "metazone": {
                "Afghanistan": "5lp,0,30|||",
                "Africa_Central": "13u,0,c5,0,30|||",
                "Africa_Eastern": "105,0,c5,0,30|||",
                "Africa_Southern": "2ir,0,c5,0,30|||",
                "Africa_Western": "r0,0,c5,0,44,0,30|r0,0,c5,0,a3,0,30||",
                "Alaska": "1rr,0,44,0,30|1rr,0,a3,0,30||",
                "Amazon": "5m,0,44,0,30|5m,0,a3,0,30||",
                "America_Central": "17g,0,13u,0,m2,0,44,0,30|17g,0,13u,0,m2,0,a3,0,30||",
                "America_Eastern": "17g,0,105,0,m2,0,44,0,30|17g,0,105,0,m2,0,a3,0,30||",
                "America_Mountain": "17g,0,3j5,0,m2,0,44,0,30|17g,0,3j5,0,m2,0,a3,0,30||",
                "America_Pacific": "17g,0,m2,0,1ru,0,1rv,0,44,0,30|17g,0,m2,0,1ru,0,1rv,0,a3,0,30||",
                "Apia": "3e,0,44,0,30|3e,0,a3,0,30||",
                "Arabian": "3j6,0,44,0,30|3j6,0,a3,0,30||",
                "Argentina": "1e,0,44,0,30|1e,0,a3,0,30||",
                "Argentina_Western": "r0,0,1e,0,44,0,30|r0,0,1e,0,a3,0,30||",
                "Armenia": "3ja,0,44,0,30|3ja,0,a3,0,30||",
                "Atlantic": "r2,0,44,0,5ra|r2,0,a3,0,30||",
                "Australia_Central": "13u,0,r3,0,44,0,30|13u,0,r3,0,a3,0,30||",
                "Australia_CentralWestern": "13u,0,r0,0,r3,0,44,0,30|13u,0,r0,0,r3,0,a3,0,30||",
                "Australia_Eastern": "105,0,r3,0,44,0,30|105,0,r3,0,a3,0,30||",
                "Australia_Western": "r0,0,r3,0,44,0,30|r0,0,r3,0,a3,0,30||",
                "Azerbaijan": "3je,0,44,0,30|3je,0,a3,0,30||",
                "Azores": "1lg,0,44,0,30|1lg,0,a3,0,30||",
                "Bangladesh": "3jg,0,44,0,30|3jg,0,a3,0,30||",
                "Bhutan": "1k4,0,30|||",
                "Bolivia": "3jj,0,30|||",
                "Brasilia": "1s2,0,44,0,30|1s2,0,a3,0,30||",
                "Brunei": "9o,0,a4,0,1b4|||",
                "Cape_Verde": "3jk,0,3j,0,44,0,30|3jk,0,3j,0,a3,0,30||",
                "Chamorro": "4mm,0,30|||",
                "Chatham": "3jl,0,44,0,30|3jl,0,a3,0,30||",
                "Chile": "2i3,0,44,0,30|2i3,0,a3,0,30||",
                "China": "1s7,0,44,0,30|1s7,0,a3,0,30||",
                "Choibalsan": "1s8,0,44,0,30|1s8,0,a3,0,30||",
                "Christmas": "63j,0,137,0,30|||",
                "Cocos": "1eg,0,nl,0,30|||",
                "Colombia": "1s9,0,44,0,30|1s9,0,a3,0,30||",
                "Cook": "1ec,0,nl,0,44,0,30|1ec,0,nl,0,64h,0,a3,0,30||",
                "Cuba": "tb,0,44,0,30|tb,0,a3,0,30||",
                "Davis": "64p,0,30|||",
                "DumontDUrville": "64u,5,652,0,30|||",
                "East_Timor": "105,0,7n,0,30|||",
                "Easter": "1sa,0,137,0,44,0,30|1sa,0,137,0,a3,0,30||",
                "Ecuador": "1k0,0,30|||",
                "Europe_Central": "13u,0,1dv,0,44,0,30|13u,0,1dv,0,a3,0,30||",
                "Europe_Eastern": "105,0,1dv,0,44,0,30|105,0,1dv,0,a3,0,30||",
                "Europe_Further_Eastern": "677,0,105,0,1dv,0,30|||",
                "Europe_Western": "r0,0,1dv,0,44,0,30|r0,0,1dv,0,a3,0,30||",
                "Falkland": "1ov,0,nl,0,44,0,30|1ov,0,nl,0,a3,0,30||",
                "Fiji": "3jn,0,44,0,30|3jn,0,a3,0,30||",
                "French_Guiana": "1sb,0,68e,0,30|||",
                "French_Southern": "1sb,0,2ir,0,1sc,0,68j,0,30|||",
                "Galapagos": "68k,0,30|||",
                "Gambier": "68u,0,30|||",
                "Georgia": "3jo,0,44,0,30|3jo,0,a3,0,30||",
                "Gilbert_Islands": "6q,0,nl,0,30|||",
                "GMT": "69u,0,1bn,0,30|||",
                "Greenland_Eastern": "105,0,1sf,0,44,0,30|105,0,1sf,0,a3,0,30||",
                "Greenland_Western": "r0,0,1sf,0,44,0,30|r0,0,1sf,0,a3,0,30||",
                "Gulf": "6ab,0,30|||",
                "Guyana": "6ad,0,30|||",
                "Hawaii_Aleutian": "3jp,5,1l3,0,44,0,30|3jp,5,1l3,0,a3,0,30||",
                "Hong_Kong": "3k1,0,3k3,0,44,0,30|3k1,0,3k3,0,a3,0,30||",
                "Hovd": "2g,0,44,0,30|2g,0,a3,0,30||",
                "India": "30i,0,30|||",
                "Indian_Ocean": "3k9,0,6bt,0,30|||",
                "Indochina": "6c8,0,30|||",
                "Indonesia_Central": "13u,0,2hk,0,30|||",
                "Indonesia_Eastern": "105,0,2hk,0,30|||",
                "Indonesia_Western": "r0,0,2hk,0,30|||",
                "Iran": "1sj,0,44,0,30|1sj,0,a3,0,30||",
                "Irkutsk": "1sk,0,44,0,30|1sk,0,a3,0,30||",
                "Israel": "1sl,0,44,0,30|1sl,0,a3,0,30||",
                "Japan": "1sn,0,44,0,30|1sn,0,a3,0,30||",
                "Kazakhstan_Eastern": "105,0,3kc,0,30|||",
                "Kazakhstan_Western": "r0,0,3kc,0,30|||",
                "Korea": "1so,0,44,0,30|1so,0,a3,0,30||",
                "Kosrae": "6ei,0,30|||",
                "Krasnoyarsk": "41,0,44,0,30|41,0,a3,0,30||",
                "Kyrgystan": "6fj,0,30|||",
                "Line_Islands": "3kd,0,nl,0,30|||",
                "Lord_Howe": "1u,0,1i2,0,44,0,30|1u,0,1i2,0,a3,0,1b4||",
                "Macquarie": "6g4,0,137,0,30|||",
                "Magadan": "3ke,0,44,0,30|3ke,0,a3,0,30||",
                "Malaysia": "3kk,0,30|||",
                "Maldives": "3kl,0,30|||",
                "Marquesas": "6gg,0,30|||",
                "Marshall_Islands": "4m6,0,nl,0,30|||",
                "Mauritius": "3kn,0,44,0,30|3kn,0,a3,0,30||",
                "Mawson": "2v0,0,30|||",
                "Mexico_Northwest": "3ko,5,r0,0,10g,0,44,0,30|3ko,5,r0,0,10g,0,a3,0,30||",
                "Mexico_Pacific": "10g,0,1ru,0,1rv,0,44,0,30|10g,0,1ru,0,1rv,0,a3,0,30||",
                "Mongolia": "3kp,0,44,0,30|3kp,0,a3,0,30||",
                "Moscow": "pi,0,44,0,30|pi,0,a3,0,1b4||",
                "Myanmar": "3kq,0,30|||",
                "Nauru": "5v,0,30|||",
                "Nepal": "8u,0,1b4|||",
                "New_Caledonia": "142,0,1ss,0,44,0,30|142,0,1ss,0,a3,0,30||",
                "New_Zealand": "142,0,1st,0,44,0,30|142,0,1st,0,a3,0,30||",
                "Newfoundland": "1ta,0,44,0,30|1ta,0,a3,0,30||",
                "Niue": "8g,0,30|||",
                "Norfolk": "6l,0,137,0,30|||",
                "Noronha": "1s,0,2,0,1tb,0,44,0,30|1s,0,2,0,1tb,0,a3,0,30||",
                "Novosibirsk": "3b,0,44,0,30|3b,0,a3,0,30||",
                "Omsk": "26,0,44,0,30|26,0,a3,0,30||",
                "Pakistan": "3i,0,44,0,1b4|3i,0,a3,0,30||",
                "Palau": "9b,0,30|||",
                "Papua_New_Guinea": "9a,0,142,0,6j2,0,30|||",
                "Paraguay": "3ks,0,44,0,30|3ks,0,a3,0,30||",
                "Peru": "4n,0,44,0,30|4n,0,a3,0,30||",
                "Philippines": "1tc,0,44,0,30|1tc,0,a3,0,30||",
                "Phoenix_Islands": "1a7,0,nl,0,30|||",
                "Pierre_Miquelon": "io,0,1p8,0,1sc,0,u2,0,44,0,30|io,0,1p8,0,1sc,0,u2,0,a3,0,30||",
                "Pitcairn": "1ko,0,30|||",
                "Ponape": "9s,0,30|||",
                "Pyongyang": "3kv,0,30|||",
                "Reunion": "b4,0,30|||",
                "Rothera": "1po,0,30|||",
                "Sakhalin": "1th,0,44,0,30|1th,0,a3,0,30||",
                "Samara": "29,0,6i,0,1b4|29,0,6n7,0,1b4||",
                "Samoa": "2c,0,44,0,30|2c,0,a3,0,30||",
                "Seychelles": "6na,0,nl,0,30|||",
                "Singapore": "6nd,0,30|||",
                "Solomon": "b5,0,nl,0,30|||",
                "South_Georgia": "2ir,0,6nj,0,30|||",
                "Suriname": "mg,0,30|||",
                "Syowa": "3lt,0,30|||",
                "Tahiti": "8n,0,30|||",
                "Taipei": "3m3,0,44,0,30|3m3,0,a3,0,30||",
                "Tajikistan": "3mh,0,30|||",
                "Tokelau": "5d,0,30|||",
                "Tonga": "3mi,0,44,0,30|3mi,0,a3,0,30||",
                "Truk": "4lr,0,30|||",
                "Turkmenistan": "3mn,0,44,0,30|3mn,0,a3,0,30||",
                "Tuvalu": "6h,0,30|||",
                "Uruguay": "3mr,0,44,0,30|3mr,0,a3,0,30||",
                "Uzbekistan": "3po,0,44,0,30|3po,0,a3,0,30||",
                "Vanuatu": "2f,0,44,0,30|6on,0,a3,0,30||",
                "Venezuela": "2g9,0,30|||",
                "Vladivostok": "28,0,44,0,30|28,0,a3,0,30||",
                "Volgograd": "3pp,0,44,0,30|3pp,0,a3,0,30||",
                "Vostok": "5t,0,30|||",
                "Wake": "3pq,0,30|||",
                "Wallis": "3pr,0,1sc,0,5k,0,30|||",
                "Yakutsk": "3u,0,44,0,30|3u,0,a3,0,30||",
                "Yekaterinburg": "3pu,0,44,0,30|3pu,0,a3,0,30||"
            }
        },
        "az": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|772,0,a3,0,30||",
                "Europe/London": "|3i5,0,a3,0,30||"
            },
            "metazone": {
                "Afghanistan": "5lp,0,30|||",
                "Africa_Central": "13u,0,c5,0,30|||",
                "Africa_Eastern": "105,0,c5,0,30|||",
                "Africa_Southern": "2ir,0,c5,0,30|||",
                "Africa_Western": "r0,0,c5,0,44,0,30|r0,0,c5,0,a3,0,30||",
                "Alaska": "1rr,0,44,0,30|1rr,0,a3,0,30||",
                "Amazon": "5m,0,44,0,30|5m,0,a3,0,30||",
                "America_Central": "17g,0,13u,0,m2,0,44,0,30|17g,0,13u,0,m2,0,a3,0,30||",
                "America_Eastern": "17g,0,105,0,m2,0,44,0,30|17g,0,105,0,m2,0,a3,0,30||",
                "America_Mountain": "17g,0,3j5,0,m2,0,44,0,30|17g,0,3j5,0,m2,0,a3,0,30||",
                "America_Pacific": "17g,0,m2,0,1ru,0,1rv,0,44,0,30|17g,0,m2,0,1ru,0,1rv,0,a3,0,30||",
                "Apia": "3e,0,44,0,30|3e,0,a3,0,30||",
                "Arabian": "3j6,0,44,0,30|3j6,0,a3,0,30||",
                "Argentina": "1e,0,44,0,30|1e,0,a3,0,30||",
                "Argentina_Western": "r0,0,1e,0,44,0,30|r0,0,1e,0,a3,0,30||",
                "Armenia": "3ja,0,44,0,30|3ja,0,a3,0,30||",
                "Atlantic": "r2,0,44,0,5ra|r2,0,a3,0,30||",
                "Australia_Central": "13u,0,r3,0,44,0,30|13u,0,r3,0,a3,0,30||",
                "Australia_CentralWestern": "13u,0,r0,0,r3,0,44,0,30|13u,0,r0,0,r3,0,a3,0,30||",
                "Australia_Eastern": "105,0,r3,0,44,0,30|105,0,r3,0,a3,0,30||",
                "Australia_Western": "r0,0,r3,0,44,0,30|r0,0,r3,0,a3,0,30||",
                "Azerbaijan": "3je,0,44,0,30|3je,0,a3,0,30||",
                "Azores": "1lg,0,44,0,30|1lg,0,a3,0,30||",
                "Bangladesh": "3jg,0,44,0,30|3jg,0,a3,0,30||",
                "Bhutan": "1k4,0,30|||",
                "Bolivia": "3jj,0,30|||",
                "Brasilia": "1s2,0,44,0,30|1s2,0,a3,0,30||",
                "Brunei": "9o,0,a4,0,1b4|||",
                "Cape_Verde": "3jk,0,3j,0,44,0,30|3jk,0,3j,0,a3,0,30||",
                "Chamorro": "4mm,0,30|||",
                "Chatham": "3jl,0,44,0,30|3jl,0,a3,0,30||",
                "Chile": "2i3,0,44,0,30|2i3,0,a3,0,30||",
                "China": "1s7,0,44,0,30|1s7,0,a3,0,30||",
                "Choibalsan": "1s8,0,44,0,30|1s8,0,a3,0,30||",
                "Christmas": "63j,0,137,0,30|||",
                "Cocos": "1eg,0,nl,0,30|||",
                "Colombia": "1s9,0,44,0,30|1s9,0,a3,0,30||",
                "Cook": "1ec,0,nl,0,44,0,30|1ec,0,nl,0,64h,0,a3,0,30||",
                "Cuba": "tb,0,44,0,30|tb,0,a3,0,30||",
                "Davis": "64p,0,30|||",
                "DumontDUrville": "64u,5,652,0,30|||",
                "East_Timor": "105,0,7n,0,30|||",
                "Easter": "1sa,0,137,0,44,0,30|1sa,0,137,0,a3,0,30||",
                "Ecuador": "1k0,0,30|||",
                "Europe_Central": "13u,0,1dv,0,44,0,30|13u,0,1dv,0,a3,0,30||",
                "Europe_Eastern": "105,0,1dv,0,44,0,30|105,0,1dv,0,a3,0,30||",
                "Europe_Further_Eastern": "677,0,105,0,1dv,0,30|||",
                "Europe_Western": "r0,0,1dv,0,44,0,30|r0,0,1dv,0,a3,0,30||",
                "Falkland": "1ov,0,nl,0,44,0,30|1ov,0,nl,0,a3,0,30||",
                "Fiji": "3jn,0,44,0,30|3jn,0,a3,0,30||",
                "French_Guiana": "1sb,0,68e,0,30|||",
                "French_Southern": "1sb,0,2ir,0,1sc,0,68j,0,30|||",
                "Galapagos": "68k,0,30|||",
                "Gambier": "68u,0,30|||",
                "Georgia": "3jo,0,44,0,30|3jo,0,a3,0,30||",
                "Gilbert_Islands": "6q,0,nl,0,30|||",
                "GMT": "69u,0,1bn,0,30|||",
                "Greenland_Eastern": "105,0,1sf,0,44,0,30|105,0,1sf,0,a3,0,30||",
                "Greenland_Western": "r0,0,1sf,0,44,0,30|r0,0,1sf,0,a3,0,30||",
                "Gulf": "6ab,0,30|||",
                "Guyana": "6ad,0,30|||",
                "Hawaii_Aleutian": "3jp,5,1l3,0,44,0,30|3jp,5,1l3,0,a3,0,30||",
                "Hong_Kong": "3k1,0,3k3,0,44,0,30|3k1,0,3k3,0,a3,0,30||",
                "Hovd": "2g,0,44,0,30|2g,0,a3,0,30||",
                "India": "30i,0,30|||",
                "Indian_Ocean": "3k9,0,6bt,0,30|||",
                "Indochina": "6c8,0,30|||",
                "Indonesia_Central": "13u,0,2hk,0,30|||",
                "Indonesia_Eastern": "105,0,2hk,0,30|||",
                "Indonesia_Western": "r0,0,2hk,0,30|||",
                "Iran": "1sj,0,44,0,30|1sj,0,a3,0,30||",
                "Irkutsk": "1sk,0,44,0,30|1sk,0,a3,0,30||",
                "Israel": "1sl,0,44,0,30|1sl,0,a3,0,30||",
                "Japan": "1sn,0,44,0,30|1sn,0,a3,0,30||",
                "Kazakhstan_Eastern": "105,0,3kc,0,30|||",
                "Kazakhstan_Western": "r0,0,3kc,0,30|||",
                "Korea": "1so,0,44,0,30|1so,0,a3,0,30||",
                "Kosrae": "6ei,0,30|||",
                "Krasnoyarsk": "41,0,44,0,30|41,0,a3,0,30||",
                "Kyrgystan": "6fj,0,30|||",
                "Line_Islands": "3kd,0,nl,0,30|||",
                "Lord_Howe": "1u,0,1i2,0,44,0,30|1u,0,1i2,0,a3,0,1b4||",
                "Macquarie": "6g4,0,137,0,30|||",
                "Magadan": "3ke,0,44,0,30|3ke,0,a3,0,30||",
                "Malaysia": "3kk,0,30|||",
                "Maldives": "3kl,0,30|||",
                "Marquesas": "6gg,0,30|||",
                "Marshall_Islands": "4m6,0,nl,0,30|||",
                "Mauritius": "3kn,0,44,0,30|3kn,0,a3,0,30||",
                "Mawson": "2v0,0,30|||",
                "Mexico_Northwest": "3ko,5,r0,0,10g,0,44,0,30|3ko,5,r0,0,10g,0,a3,0,30||",
                "Mexico_Pacific": "10g,0,1ru,0,1rv,0,44,0,30|10g,0,1ru,0,1rv,0,a3,0,30||",
                "Mongolia": "3kp,0,44,0,30|3kp,0,a3,0,30||",
                "Moscow": "pi,0,44,0,30|pi,0,a3,0,1b4||",
                "Myanmar": "3kq,0,30|||",
                "Nauru": "5v,0,30|||",
                "Nepal": "8u,0,1b4|||",
                "New_Caledonia": "142,0,1ss,0,44,0,30|142,0,1ss,0,a3,0,30||",
                "New_Zealand": "142,0,1st,0,44,0,30|142,0,1st,0,a3,0,30||",
                "Newfoundland": "1ta,0,44,0,30|1ta,0,a3,0,30||",
                "Niue": "8g,0,30|||",
                "Norfolk": "6l,0,137,0,30|||",
                "Noronha": "1s,0,2,0,1tb,0,44,0,30|1s,0,2,0,1tb,0,a3,0,30||",
                "Novosibirsk": "3b,0,44,0,30|3b,0,a3,0,30||",
                "Omsk": "26,0,44,0,30|26,0,a3,0,30||",
                "Pakistan": "3i,0,44,0,1b4|3i,0,a3,0,30||",
                "Palau": "9b,0,30|||",
                "Papua_New_Guinea": "9a,0,142,0,6j2,0,30|||",
                "Paraguay": "3ks,0,44,0,30|3ks,0,a3,0,30||",
                "Peru": "4n,0,44,0,30|4n,0,a3,0,30||",
                "Philippines": "1tc,0,44,0,30|1tc,0,a3,0,30||",
                "Phoenix_Islands": "1a7,0,nl,0,30|||",
                "Pierre_Miquelon": "io,0,1p8,0,1sc,0,u2,0,44,0,30|io,0,1p8,0,1sc,0,u2,0,a3,0,30||",
                "Pitcairn": "1ko,0,30|||",
                "Ponape": "9s,0,30|||",
                "Pyongyang": "3kv,0,30|||",
                "Reunion": "b4,0,30|||",
                "Rothera": "1po,0,30|||",
                "Sakhalin": "1th,0,44,0,30|1th,0,a3,0,30||",
                "Samara": "29,0,6i,0,1b4|29,0,6n7,0,1b4||",
                "Samoa": "2c,0,44,0,30|2c,0,a3,0,30||",
                "Seychelles": "6na,0,nl,0,30|||",
                "Singapore": "6nd,0,30|||",
                "Solomon": "b5,0,nl,0,30|||",
                "South_Georgia": "2ir,0,6nj,0,30|||",
                "Suriname": "mg,0,30|||",
                "Syowa": "3lt,0,30|||",
                "Tahiti": "8n,0,30|||",
                "Taipei": "3m3,0,44,0,30|3m3,0,a3,0,30||",
                "Tajikistan": "3mh,0,30|||",
                "Tokelau": "5d,0,30|||",
                "Tonga": "3mi,0,44,0,30|3mi,0,a3,0,30||",
                "Truk": "4lr,0,30|||",
                "Turkmenistan": "3mn,0,44,0,30|3mn,0,a3,0,30||",
                "Tuvalu": "6h,0,30|||",
                "Uruguay": "3mr,0,44,0,30|3mr,0,a3,0,30||",
                "Uzbekistan": "3po,0,44,0,30|3po,0,a3,0,30||",
                "Vanuatu": "2f,0,44,0,30|6on,0,a3,0,30||",
                "Venezuela": "2g9,0,30|||",
                "Vladivostok": "28,0,44,0,30|28,0,a3,0,30||",
                "Volgograd": "3pp,0,44,0,30|3pp,0,a3,0,30||",
                "Vostok": "5t,0,30|||",
                "Wake": "3pq,0,30|||",
                "Wallis": "3pr,0,1sc,0,5k,0,30|||",
                "Yakutsk": "3u,0,44,0,30|3u,0,a3,0,30||",
                "Yekaterinburg": "3pu,0,44,0,30|3pu,0,a3,0,30||"
            }
        },
        "bas": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "be": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c8s,0,po,0,97||",
                "Europe/London": "|c8t,0,pe,0,97||"
            },
            "metazone": {
                "Afghanistan": "c7e,0,97|||",
                "Africa_Central": "c7d,0,97|||",
                "Africa_Eastern": "c7c,0,97|||",
                "Africa_Southern": "c7b,0,97|||",
                "Africa_Western": "6ps,0,po,0,97|6ps,0,pe,0,97||",
                "Alaska": "oj,0,97,0,6q4|pg,0,97,0,6q4||",
                "Amazon": "6r0,0,po,0,97|6r0,0,pe,0,97||",
                "America_Central": "2g5,0,6rs,0,po,0,97|2g5,0,6rs,0,pe,0,97||",
                "America_Eastern": "2g5,0,6ru,0,po,0,97|2g5,0,6ru,0,pe,0,97||",
                "America_Mountain": "2g5,0,6sb,0,po,0,97|2g5,0,6sb,0,pe,0,97||",
                "America_Pacific": "6sm,0,po,0,97|6sm,0,pe,0,97||",
                "Apia": "oj,0,97,0,6tk|pg,0,97,0,6tk||",
                "Arabian": "oj,0,97,0,6va,0,71n|pg,0,97,0,6va,0,71n||",
                "Argentina": "71s,0,po,0,97|71s,0,pe,0,97||",
                "Argentina_Western": "oj,0,97,0,3q2,0,72l|pg,0,97,0,3q2,0,72l||",
                "Armenia": "oj,0,97,0,735|pg,0,97,0,735||",
                "Atlantic": "73c,0,po,0,97|73c,0,pe,0,97||",
                "Australia_Central": "oj,0,97,0,76d,0,1tq|pg,0,97,0,76d,0,1tq||",
                "Australia_CentralWestern": "77c,0,po,0,97,0,77t,0,1tq|77c,0,pe,0,97,0,77t,0,1tq||",
                "Australia_Eastern": "oj,0,97,0,783,0,1tq|pg,0,97,0,783,0,1tq||",
                "Australia_Western": "oj,0,97,0,785,0,1tq|pg,0,97,0,785,0,1tq||",
                "Azerbaijan": "oj,0,97,0,786|pg,0,97,0,786||",
                "Azores": "oj,0,97,0,787,0,16a|pg,0,97,0,787,0,16a||",
                "Bangladesh": "oj,0,97,0,78b|pg,0,97,0,78b||",
                "Bhutan": "mf,0,c7a|||",
                "Bolivia": "c79,0,97|||",
                "Brasilia": "79p,0,po,0,97|79p,0,pe,0,97||",
                "Brunei": "mf,0,c78|||",
                "Cape_Verde": "oj,0,97,0,7ab,5,7ag|pg,0,97,0,7ab,5,7ag||",
                "Chamorro": "mf,0,c77|||",
                "Chatham": "oj,0,97,0,7ai|pg,0,97,0,7ai||",
                "Chile": "7am,0,po,0,97|7am,0,pe,0,97||",
                "China": "oj,0,97,0,7an|pg,0,97,0,7an||",
                "Choibalsan": "oj,0,97,0,7ap|pg,0,97,0,7ap||",
                "Christmas": "mf,0,1tr,0,c76|||",
                "Cocos": "mf,0,c75,0,16a|||",
                "Colombia": "7fq,0,po,0,97|7fq,0,pe,0,97||",
                "Cook": "oj,0,97,0,16a,0,17u|c74,0,97,0,16a,0,17u||",
                "Cuba": "oj,0,97,0,7op|pg,0,97,0,7op||",
                "Davis": "mf,0,2g0,0,c73|||",
                "DumontDUrville": "mf,0,2g0,0,c72,5,c71|||",
                "East_Timor": "mf,0,c70,0,c6v|||",
                "Easter": "oj,0,97,0,1tr,0,7rn|pg,0,97,0,1tr,0,7rn||",
                "Ecuador": "c6u,0,97|||",
                "Europe_Central": "7sc,0,po,0,97|7sc,0,pe,0,97||",
                "Europe_Eastern": "7sd,0,po,0,97|7sd,0,pe,0,97||",
                "Europe_Further_Eastern": "c6t,0,97|||",
                "Europe_Western": "7ve,0,po,0,97|7ve,0,pe,0,97||",
                "Falkland": "oj,0,97,0,80l,0,16a|pg,0,97,0,80l,0,16a||",
                "Fiji": "oj,0,97,0,895|pg,0,97,0,895||",
                "French_Guiana": "mf,0,c6s,0,c6r|||",
                "French_Southern": "mf,0,c6q,0,c6p,0,1tt,0,c6o,0,c6n|||",
                "Galapagos": "oj,0,97,0,c6m,0,16a|||",
                "Gambier": "mf,0,c6l,0,c6k|||",
                "Georgia": "8bi,0,po,0,97|8bi,0,pe,0,97||",
                "Gilbert_Islands": "mf,0,16a,0,8bk|||",
                "GMT": "mf,0,c6j,0,c6i|||",
                "Greenland_Eastern": "oj,0,97,0,8cf,0,3q4|pg,0,97,0,8cf,0,3q4||",
                "Greenland_Western": "oj,0,97,0,3q2,0,3q4|pg,0,97,0,3q2,0,3q4||",
                "Gulf": "mf,0,c6h,0,c6g|||",
                "Guyana": "mf,0,c6f|||",
                "Hawaii_Aleutian": "8dh,5,8do,0,po,0,97|8dh,5,8do,0,pe,0,97||",
                "Hong_Kong": "oj,0,97,0,8dp|pg,0,97,0,8dp||",
                "Hovd": "oj,0,97,0,8dq|pg,0,97,0,8dq||",
                "India": "mf,0,c6e|||",
                "Indian_Ocean": "mf,0,c6d,0,c6c|||",
                "Indochina": "c6b,0,97|||",
                "Indonesia_Central": "c6a,0,97|||",
                "Indonesia_Eastern": "c69,0,97|||",
                "Indonesia_Western": "c68,0,97|||",
                "Iran": "8gi,0,po,0,97|8gi,0,pe,0,97||",
                "Irkutsk": "8go,0,po,0,97|8go,0,pe,0,97||",
                "Israel": "8h2,0,po,0,97|8h2,0,pe,0,97||",
                "Japan": "oj,0,97,0,8hk|pg,0,97,0,8hk||",
                "Kazakhstan_Eastern": "c67,0,97|||",
                "Kazakhstan_Western": "c66,0,97|||",
                "Korea": "oj,0,97,0,8hr|pg,0,97,0,8hr||",
                "Kosrae": "mf,0,16a,0,c65|||",
                "Krasnoyarsk": "8ht,0,po,0,97|8ht,0,pe,0,97||",
                "Kyrgystan": "mf,0,c64|||",
                "Line_Islands": "mf,0,16a,0,1eo|||",
                "Lord_Howe": "oj,0,97,0,oi,5,10a|pg,0,97,0,oi,5,10a||",
                "Macquarie": "mf,0,1tr,0,c63|||",
                "Magadan": "8jb,0,po,0,97|8jb,0,pe,0,97||",
                "Malaysia": "mf,0,c62|||",
                "Maldives": "mf,0,c61|||",
                "Marquesas": "mf,0,c60,0,16a|||",
                "Marshall_Islands": "mf,0,c5v,0,16a|||",
                "Mauritius": "oj,0,97,0,4s9|pg,0,97,0,4s9||",
                "Mawson": "mf,0,2g0,0,c5u|||",
                "Mexico_Northwest": "4se,5,4sf,0,4so,0,po,0,97|4se,5,4sf,0,4so,0,pe,0,97||",
                "Mexico_Pacific": "4sp,0,4ss,0,c5t,0,97|4sp,0,4ss,0,pe,0,97||",
                "Mongolia": "oj,0,97,0,or,5,4u1|pg,0,97,0,or,5,4u1||",
                "Moscow": "4u3,0,po,0,97|4u3,0,pe,0,97||",
                "Myanmar": "mf,0,c5s|||",
                "Nauru": "mf,0,14s|||",
                "Nepal": "c5r,0,97|||",
                "New_Caledonia": "oj,0,97,0,31c,0,50s|pg,0,97,0,31c,0,50s||",
                "New_Zealand": "oj,0,97,0,31c,0,50t|pg,0,97,0,31c,0,50t||",
                "Newfoundland": "50u,0,po,0,97|50u,0,pe,0,97||",
                "Niue": "mf,0,c5q|||",
                "Norfolk": "mf,0,1tr,0,c5p|||",
                "Noronha": "oj,0,97,0,141,5,518,5,51b|pg,0,97,0,141,5,518,5,51b||",
                "Novosibirsk": "51c,0,po,0,97|51c,0,pe,0,97||",
                "Omsk": "51i,0,po,0,97|51i,0,pe,0,97||",
                "Pakistan": "51m,0,po,0,97|51m,0,pe,0,97||",
                "Palau": "mf,0,159|||",
                "Papua_New_Guinea": "mf,0,13k,5,31c,0,c5o|||",
                "Paraguay": "oj,0,97,0,52c|pg,0,97,0,52c||",
                "Peru": "52f,0,po,0,97|52f,0,pe,0,97||",
                "Philippines": "52m,0,po,0,97|52m,0,pe,0,97||",
                "Phoenix_Islands": "mf,0,16a,0,530|||",
                "Pierre_Miquelon": "oj,0,97,0,pr,5,532,0,1tt,0,3q6|oj,0,pe,0,97,0,pr,5,532,0,1tt,0,3q6||",
                "Pitcairn": "mf,0,1tr,0,c5n|||",
                "Ponape": "mf,0,1tr,0,c5m|||",
                "Pyongyang": "c5l,0,97|||",
                "Reunion": "mf,0,c5k|||",
                "Rothera": "mf,0,2g0,0,c5j|||",
                "Sakhalin": "552,0,po,0,97|552,0,pe,0,97||",
                "Samoa": "oj,0,97,0,p0|pg,0,97,0,p0||",
                "Seychelles": "mf,0,c5i,0,16a|||",
                "Singapore": "c5h,0,97|||",
                "Solomon": "mf,0,c5g,0,16a|||",
                "South_Georgia": "mf,0,c5f,0,c5e|||",
                "Suriname": "mf,0,c5d|||",
                "Syowa": "mf,0,2g0,0,1pr|||",
                "Tahiti": "mf,0,c5c|||",
                "Taipei": "oj,0,97,0,59i|pg,0,97,0,59i||",
                "Tajikistan": "mf,0,c5b|||",
                "Tokelau": "mf,0,c5a|||",
                "Tonga": "oj,0,97,0,ot|pg,0,97,0,ot||",
                "Truk": "mf,0,1f2|||",
                "Turkmenistan": "oj,0,97,0,5c0|pg,0,97,0,5c0||",
                "Tuvalu": "mf,0,14j|||",
                "Uruguay": "5cq,0,po,0,97|5cq,0,pe,0,97||",
                "Uzbekistan": "oj,0,97,0,5d0|pg,0,97,0,5d0||",
                "Vanuatu": "oj,0,97,0,os|pg,0,97,0,os||",
                "Venezuela": "c59,0,97|||",
                "Vladivostok": "5df,0,po,0,97|5df,0,pe,0,97||",
                "Volgograd": "5e6,0,po,0,97|5e6,0,pe,0,97||",
                "Vostok": "mf,0,2g0,0,c58|||",
                "Wake": "mf,0,1tr,0,1m0|||",
                "Wallis": "mf,0,16a,0,c57,0,1tt,0,14l|||",
                "Yakutsk": "5jr,0,po,0,97|5jr,0,pe,0,97||",
                "Yekaterinburg": "5k8,0,po,0,97|5k8,0,pe,0,97||"
            }
        },
        "bem": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "bez": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "bg": {
            "gmtFormat": "Гринуич{0}",
            "gmtZeroFormat": "Гринуич",
            "zone": {
                "Europe/Dublin": "|c8u,0,fd,0,m||",
                "Europe/London": "|6r5,0,fi,0,g2,0,m||"
            },
            "metazone": {
                "Afghanistan": "c56,0,m|||",
                "Africa_Central": "c55,0,m|||",
                "Africa_Eastern": "c54,0,m|||",
                "Africa_Southern": "c53,0,m|||",
                "Africa_Western": "3q7,0,fd,0,m|3q7,0,fi,0,g2,0,m||",
                "Alaska": "12l,0,kf,0,fd,0,m|12l,0,kf,0,fi,0,g2,0,m||",
                "Amazon": "5nh,0,fd,0,m|5nh,0,fi,0,g2,0,m||",
                "America_Central": "1n5,0,p6,0,fd,0,m|1n5,0,p6,0,fi,0,g2,0,m||",
                "America_Eastern": "1n5,0,3q9,0,fd,0,m|1n5,0,3q9,0,fi,0,g2,0,m||",
                "America_Mountain": "1n5,0,1gj,0,fd,0,m|1n5,0,1gj,0,fi,0,g2,0,m||",
                "America_Pacific": "1n5,0,2fm,0,fd,0,m|1n5,0,2fm,0,fi,0,g2,0,m||",
                "Anadyr": "18u,0,kf,0,fd,0,m|18u,0,kf,0,fi,0,g2,0,m||",
                "Apia": "5oi,0,kf,0,fd,0,m|5oi,0,kf,0,fi,0,g2,0,m||",
                "Arabian": "5ok,0,fd,0,m|5ok,0,fi,0,g2,0,m||",
                "Argentina": "5oq,0,fd,0,m|5oq,0,fi,0,g2,0,m||",
                "Argentina_Western": "5or,0,fd,0,m|5or,0,fi,0,g2,0,m||",
                "Armenia": "5p0,0,fd,0,m|5p0,0,fi,0,g2,0,m||",
                "Atlantic": "1n5,0,5p1,0,fd,0,m|1n5,0,5p1,0,fi,0,g2,0,m||",
                "Australia_Central": "eo,0,kf,0,p6,0,fd,0,m|eo,0,kf,0,p6,0,fi,0,g2,0,m||",
                "Australia_CentralWestern": "eo,0,kf,0,uu,0,p6,0,fd,0,m|eo,0,kf,0,uu,0,p6,0,fi,0,g2,0,m||",
                "Australia_Eastern": "eo,0,kf,0,3q9,0,fd,0,m|eo,0,kf,0,3q9,0,fi,0,g2,0,m||",
                "Australia_Western": "eo,0,kf,0,uu,0,fd,0,m|eo,0,kf,0,uu,0,fi,0,g2,0,m||",
                "Azerbaijan": "5pn,0,fd,0,m|5pn,0,fi,0,g2,0,m||",
                "Azores": "5pp,0,191,0,kf,0,fd,0,m|5pp,0,191,0,kf,0,fi,0,g2,0,m||",
                "Bangladesh": "5q4,0,fd,0,m|5q4,0,fi,0,g2,0,m||",
                "Bhutan": "c52,0,m|||",
                "Bolivia": "c51,0,m|||",
                "Brasilia": "5qb,0,fd,0,m|5qb,0,fi,0,g2,0,m||",
                "Brunei": "1gr,0,1o3|||",
                "Cape_Verde": "12p,0,115,0,kf,0,fd,0,m|12p,0,115,0,kf,0,fi,0,g2,0,m||",
                "Chamorro": "1u7,0,kf,0,fd,0,m|||",
                "Chatham": "5rp,0,kf,0,fd,0,m|5rp,0,kf,0,fi,0,g2,0,m||",
                "Chile": "5rr,0,fd,0,m|5rr,0,fi,0,g2,0,m||",
                "China": "5rt,0,fd,0,m|5rt,0,fi,0,g2,0,m||",
                "Choibalsan": "5s0,0,fd,0,m|5s0,0,fi,0,g2,0,m||",
                "Christmas": "3qa,0,4l3|||",
                "Cocos": "c50,0,191|||",
                "Colombia": "5vi,0,fd,0,m|5vi,0,fi,0,g2,0,m||",
                "Cook": "1uj,0,1p2,0,kf,0,fd,0,m|1uj,0,1p2,0,kf,0,fi,0,g2,0,m||",
                "Cuba": "5vn,0,fd,0,m|5vn,0,fi,0,g2,0,m||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,0,c4v|||",
                "East_Timor": "c4u,0,m|||",
                "Easter": "62r,0,4l2,0,kf,0,fd,0,m|62r,0,4l2,0,kf,0,fi,0,g2,0,m||",
                "Ecuador": "c4t,0,m|||",
                "Europe_Central": "639,0,fd,0,m|639,0,fi,0,g2,0,m||",
                "Europe_Eastern": "63b,0,fd,0,m|63b,0,fi,0,g2,0,m||",
                "Europe_Further_Eastern": "c4s,0,c4r,0,m|||",
                "Europe_Western": "63k,0,fd,0,m|63k,0,fi,0,m||",
                "Falkland": "63l,0,191,0,kf,0,fd,0,m|63l,0,191,0,kf,0,fi,0,g2,0,m||",
                "Fiji": "161,0,kf,0,fd,0,m|161,0,kf,0,fi,0,g2,0,m||",
                "French_Guiana": "c4q,0,1pb|||",
                "French_Southern": "c4p,0,c4o,0,il,0,c4n,0,c4m|||",
                "Galapagos": "c4l,0,m|||",
                "Gambier": "659|||",
                "Georgia": "65u,0,fd,0,m|65u,0,fi,0,g2,0,m||",
                "Gilbert_Islands": "1uj,0,c4k|||",
                "GMT": "66b,0,c4j,0,m|||",
                "Greenland_Eastern": "66p,0,fd,0,m|66p,0,fi,0,g2,0,m||",
                "Greenland_Western": "66u,0,fd,0,m|66u,0,fi,0,g2,0,m||",
                "Gulf": "c4i,0,293|||",
                "Guyana": "c4h|||",
                "Hawaii_Aleutian": "67v,5,1ae,0,fd,0,m|67v,5,1ae,0,fi,0,g2,0,m||",
                "Hong_Kong": "687,0,fd,0,m|687,0,fi,0,g2,0,m||",
                "Hovd": "688,0,fd,0,m|688,0,fi,0,g2,0,m||",
                "India": "c4g,0,fd,0,m|||",
                "Indian_Ocean": "c4f,0,1ao|||",
                "Indochina": "c4e,0,m|||",
                "Indonesia_Central": "c4d,0,m|||",
                "Indonesia_Eastern": "c4c,0,m|||",
                "Indonesia_Western": "c4b,0,m|||",
                "Iran": "69e,0,fd,0,m|69e,0,fi,0,g2,0,m||",
                "Irkutsk": "69m,0,fd,0,m|69m,0,fi,0,g2,0,m||",
                "Israel": "1cq,0,fd,0,m|1cq,0,fi,0,g2,0,m||",
                "Japan": "6a6,0,fd,0,m|6a6,0,fi,0,g2,0,m||",
                "Kamchatka": "1d8,5,6a9,0,fd,0,m|1d8,5,6a9,0,kf,0,fi,0,g2,0,m||",
                "Kazakhstan_Eastern": "c4a,0,m|||",
                "Kazakhstan_Western": "c49,0,m|||",
                "Korea": "6ac,0,fd,0,m|6ac,0,fi,0,g2,0,m||",
                "Kosrae": "c48|||",
                "Krasnoyarsk": "6ae,0,fd,0,m|6ae,0,fi,0,g2,0,m||",
                "Kyrgystan": "c47,0,m|||",
                "Line_Islands": "c46,0,191|||",
                "Lord_Howe": "oi,0,10a,0,kf,0,fd,0,m|oi,0,10a,0,kf,0,fi,0,g2,0,m||",
                "Macquarie": "1l5|||",
                "Magadan": "6b8,0,fd,0,m|6b8,0,fi,0,g2,0,m||",
                "Malaysia": "c45,0,m|||",
                "Maldives": "c44,0,191|||",
                "Marquesas": "c43,0,191|||",
                "Marshall_Islands": "c42,0,191|||",
                "Mauritius": "6c2,0,kf,0,fd,0,m|6c2,0,kf,0,fi,0,g2,0,m||",
                "Mawson": "c41|||",
                "Mexico_Northwest": "187,0,kf,0,3qb,0,fd,0,m|187,0,kf,0,3qb,0,fi,0,g2,0,m||",
                "Mexico_Pacific": "6d0,0,2fm,0,fd,0,m|6d0,0,2fm,0,fi,0,g2,0,m||",
                "Mongolia": "6d1,0,fd,0,m|6d1,0,fi,0,g2,0,m||",
                "Moscow": "6d7,0,fd,0,m|6d7,0,fi,0,g2,0,m||",
                "Myanmar": "c40,0,m|||",
                "Nauru": "14s|||",
                "Nepal": "c3v,0,m|||",
                "New_Caledonia": "6de,0,fd,0,m|6de,0,fi,0,g2,0,m||",
                "New_Zealand": "6do,0,fd,0,m|6do,0,fi,0,g2,0,m||",
                "Newfoundland": "6e0,0,fd,0,m|6e0,0,fi,0,g2,0,m||",
                "Niue": "1q4|||",
                "Norfolk": "146|||",
                "Noronha": "138,0,13a,0,6f2,0,kf,0,fd,0,m|138,0,13a,0,6f2,0,kf,0,fi,0,g2,0,m||",
                "Novosibirsk": "6fc,0,fd,0,m|6fc,0,fi,0,g2,0,m||",
                "Omsk": "6fe,0,fd,0,m|6fe,0,fi,0,g2,0,m||",
                "Pakistan": "6fi,0,fd,0,m|6fi,0,fi,0,g2,0,m||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,125,0,1m5|||",
                "Paraguay": "6fl,0,fd,0,m|6fl,0,fi,0,g2,0,m||",
                "Peru": "6fo,0,fd,0,m|6fo,0,fi,0,g2,0,m||",
                "Philippines": "6g0,0,fd,0,m|6g0,0,fi,0,g2,0,m||",
                "Phoenix_Islands": "1uj,0,158|||",
                "Pierre_Miquelon": "pr,0,6g2,0,il,0,qp,0,kf,0,fd,0,m|pr,0,6g2,0,il,0,qp,0,kf,0,fi,0,g2,0,m||",
                "Pitcairn": "1q6|||",
                "Ponape": "1v2|||",
                "Pyongyang": "c3u,0,qe,0,c3t|||",
                "Reunion": "6gi|||",
                "Rothera": "157|||",
                "Sakhalin": "6gk,0,fd,0,m|6gk,0,fi,0,g2,0,m||",
                "Samara": "17o,0,kf,0,fd,0,m|17o,0,kf,0,fi,0,g2,0,m||",
                "Samoa": "6gm,0,fd,0,m|6gm,0,fi,0,g2,0,m||",
                "Seychelles": "c3s,0,191|||",
                "Singapore": "c3r,0,m|||",
                "Solomon": "c3q,0,191|||",
                "South_Georgia": "c3p,0,6gr|||",
                "Suriname": "c3o,0,m|||",
                "Syowa": "28v|||",
                "Tahiti": "c3n,0,m|||",
                "Taipei": "6h3,0,kf,0,fd,0,m|6h3,0,kf,0,fi,0,g2,0,m||",
                "Tajikistan": "c3m,0,m|||",
                "Tokelau": "156|||",
                "Tonga": "ot,0,kf,0,fd,0,m|ot,0,kf,0,fi,0,g2,0,m||",
                "Truk": "c3l|||",
                "Turkmenistan": "6hq,0,fd,0,m|6hq,0,fi,0,g2,0,m||",
                "Tuvalu": "14j|||",
                "Uruguay": "6i1,0,fd,0,m|6i1,0,fi,0,g2,0,m||",
                "Uzbekistan": "6i3,0,fd,0,m|6i3,0,fi,0,g2,0,m||",
                "Vanuatu": "os,0,kf,0,fd,0,m|os,0,kf,0,fi,0,g2,0,m||",
                "Venezuela": "c3k,0,m|||",
                "Vladivostok": "6ie,0,fd,0,m|6ie,0,fi,0,g2,0,m||",
                "Volgograd": "6ij,0,fd,0,m|6ij,0,fi,0,g2,0,m||",
                "Vostok": "155|||",
                "Wake": "3qa,0,6j8|||",
                "Wallis": "6ja,0,il,0,14l|||",
                "Yakutsk": "6jb,0,fd,0,m|6jb,0,fi,0,g2,0,m||",
                "Yekaterinburg": "6jd,0,fd,0,m|6jd,0,fi,0,g2,0,m||"
            }
        },
        "bm": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "bn-IN": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6q3,0,9m,0,2n||",
                "Europe/London": "|6q2,0,cl,0,2n||"
            },
            "metazone": {
                "Afghanistan": "6jm,0,2n|||",
                "Africa_Central": "2ek,0,1ni,0,2n|||",
                "Africa_Eastern": "12k,0,1ni,0,2n|||",
                "Africa_Southern": "2ej,0,1ni,0,9m,0,2n|||",
                "Africa_Western": "1ai,0,1ni,0,9m,0,2n|1ai,0,1ni,0,cl,0,2n||",
                "Alaska": "3qc,0,9m,0,2n|3qc,0,nm,0,2n||",
                "Amazon": "6mt,0,9m,0,2n|6n1,0,cl,0,2n||",
                "America_Central": "1cr,0,9m,0,2n|1cr,0,nm,0,2n||",
                "America_Eastern": "3qd,0,2ei,0,2n|3qd,0,nm,0,2n||",
                "America_Mountain": "3qe,0,1v4,0,2ei,0,2n|3qe,0,1v4,0,1v5,0,2n||",
                "America_Pacific": "1v6,0,1v9,0,1v4,0,9m,0,2n|1v6,0,1v9,0,1v4,0,1v5,0,2n||",
                "Anadyr": "3qf,0,9m,0,2n|3qf,0,cl,0,2n||",
                "Apia": "3qj,0,9m,0,2n|3qj,0,1v5,0,2n||",
                "Arabian": "3qk,0,9m,0,2n|3qk,0,nm,0,2n||",
                "Argentina": "2ea,0,9m,0,2n|2ea,0,cl,0,2n||",
                "Argentina_Western": "1i0,0,6o9,0,2ei,0,2n|1i0,0,2ea,0,1va,0,2n||",
                "Armenia": "3qm,0,9m,0,2n|3qm,0,cl,0,2n||",
                "Atlantic": "3qn,0,9m,0,2n|3qn,0,nm,0,2n||",
                "Australia_Central": "172,0,1cr,0,9m,0,2n|172,0,1cr,0,nm,0,2n||",
                "Australia_CentralWestern": "172,0,1cr,0,1i0,0,9m,0,2n|172,0,1cr,0,1i0,0,nm,0,2n||",
                "Australia_Eastern": "172,0,12k,0,9m,0,2n|172,0,12k,0,nm,0,2n||",
                "Australia_Western": "172,0,1i0,0,9m,0,2n|172,0,1i0,0,nm,0,2n||",
                "Azerbaijan": "3qo,0,9m,0,2n|3qo,0,cl,0,2n||",
                "Azores": "3qt,0,9m,0,2n|3qt,0,cl,0,2n||",
                "Bangladesh": "3qv,0,9m,0,2n|3qv,0,cl,0,2n||",
                "Bhutan": "6p0,0,2n|||",
                "Bolivia": "6p4,0,2n|||",
                "Brasilia": "3r0,0,9m,0,2n|3r0,0,cl,0,2n||",
                "Brunei": "6pa,0,6pb,0,2n|||",
                "Cape_Verde": "3r4,0,3r5,0,9m,0,2n|3r4,0,3r5,0,cl,0,2n||",
                "Chamorro": "6pj,0,9m,0,2n|||",
                "Chatham": "3r7,0,9m,0,2n|3r7,0,nm,0,2n||",
                "Chile": "3rg,0,9m,0,2n|3rg,0,6pn,0,2n||",
                "China": "3ro,0,9m,0,2n|3ro,0,nm,0,2n||",
                "Choibalsan": "3rp,0,9m,0,2n|3rp,0,cl,0,2n||",
                "Christmas": "6pt,0,1lm,0,2n|||",
                "Cocos": "6q1,0,12i,0,2n|||",
                "Colombia": "3rq,0,9m,0,2n|3rq,0,cl,0,2n||",
                "Cook": "3rr,0,12i,0,9m,0,2n|3rr,0,12i,0,6q6,0,cl,0,2n||",
                "Cuba": "3rt,0,9m,0,2n|3rt,0,nm,0,2n||",
                "Davis": "6qa,0,2n|||",
                "DumontDUrville": "6qb,5,6qd,0,2n|||",
                "East_Timor": "12k,0,6qm,0,2n|||",
                "Easter": "3s0,0,1lm,0,9m,0,2n|3s0,0,1lm,0,cl,0,2n||",
                "Ecuador": "6r3,0,2n|||",
                "Europe_Central": "2ek,0,1io,0,9m,0,2n|2ek,0,1io,0,cl,0,2n||",
                "Europe_Eastern": "12k,0,1io,0,9m,0,2n|12k,0,1io,0,cl,0,2n||",
                "Europe_Further_Eastern": "6rh,5,12k,0,6ro,0,2n|||",
                "Europe_Western": "1ai,0,1io,0,9m,0,2n|1ai,0,1io,0,cl,0,2n||",
                "Falkland": "3s3,0,12i,0,9m,0,2n|3s3,0,12i,0,cl,0,2n||",
                "Fiji": "3s7,0,9m,0,2n|3s7,0,cl,0,2n||",
                "French_Guiana": "3sh,0,6sc,0,2n|||",
                "French_Southern": "3sh,0,2ej,0,3sp,0,6sn,0,2n|||",
                "Galapagos": "6t7,0,2n|||",
                "Gambier": "6te,0,2n|||",
                "Georgia": "2dp,0,9m,0,2n|2dp,0,cl,0,2n||",
                "Gilbert_Islands": "6tp,0,12i,0,2n|||",
                "GMT": "6tq,0,6u0,0,6uk|||",
                "Greenland_Eastern": "12k,0,1vp,0,9m,0,2n|12k,0,1vp,0,cl,0,2n||",
                "Greenland_Western": "1ai,0,1vp,0,9m,0,2n|1ai,0,1vp,0,cl,0,2n||",
                "Gulf": "71e,0,9m,0,2n|||",
                "Guyana": "71l,0,2n|||",
                "Hawaii_Aleutian": "3sq,5,3ss,0,9m,0,2n|3sq,5,3ss,0,nm,0,2n||",
                "Hong_Kong": "3st,0,3su,0,9m,0,2n|3st,0,3su,0,cl,0,2n||",
                "Hovd": "3t0,0,9m,0,2n|3t0,0,cl,0,2n||",
                "India": "73b,0,9m,0,2n||15a|",
                "Indian_Ocean": "73g,0,1v9,0,2n|||",
                "Indochina": "741,0,2n|||",
                "Indonesia_Central": "1cr,0,2df,0,2n|||",
                "Indonesia_Eastern": "12k,0,2df,0,2n|||",
                "Indonesia_Western": "76s,0,2df,0,2n|||",
                "Iran": "3t3,0,9m,0,2n|3t3,0,nm,0,2n||",
                "Irkutsk": "3t5,0,9m,0,2n|3t5,0,cl,0,2n||",
                "Israel": "3t8,0,9m,0,2n|3t8,0,nm,0,2n||",
                "Japan": "3t9,0,9m,0,2n|3t9,0,nm,0,2n||",
                "Kamchatka": "3ta,5,0,3te,0,3tk,0,2n|3ta,5,0,3te,0,1va,0,2n||",
                "Kazakhstan_Eastern": "12k,0,3tl,0,9m,0,2n|||",
                "Kazakhstan_Western": "1ai,0,3tl,0,9m,0,2n|||",
                "Korea": "3to,0,9m,0,2n|3to,0,nm,0,2n||",
                "Kosrae": "78d,0,2n|||",
                "Krasnoyarsk": "3tr,0,9m,0,2n|3tr,0,cl,0,2n||",
                "Kyrgystan": "791,0,2n|||",
                "Line_Islands": "79a,0,12i,0,2n|||",
                "Lord_Howe": "3uh,0,3v5,0,9m,0,3v7|3uh,0,3v5,0,nm,0,3v7||",
                "Macquarie": "79q,0,1lm,0,2n|||",
                "Magadan": "3vf,0,9m,0,2n|3vf,0,cl,0,2n||",
                "Malaysia": "79u,0,2n|||",
                "Maldives": "7a2,0,2n|||",
                "Marquesas": "7a3,0,2n|||",
                "Marshall_Islands": "7a7,0,12i,0,2n|||",
                "Mauritius": "3vv,0,9m,0,2n|3vv,0,cl,0,2n||",
                "Mawson": "7ac,0,2n|||",
                "Mexico_Northwest": "43i,0,446,0,9m,0,2n|43i,0,446,0,1v5,0,2n||",
                "Mexico_Pacific": "44c,0,1v6,0,7ak,0,9m,0,2n|44c,0,1v6,0,1v9,0,nm,0,2n||",
                "Mongolia": "451,0,45e,0,9m,0,2n|451,0,45e,0,cl,0,2n||",
                "Moscow": "4b4,0,9m,0,2n|4b4,0,cl,0,2n||",
                "Myanmar": "7eb,0,2n|||",
                "Nauru": "7ec,0,2n|||",
                "Nepal": "7el,0,2n|||",
                "New_Caledonia": "2d4,0,4df,0,9m,0,2n|2d4,0,4df,0,cl,0,2n||",
                "New_Zealand": "4dj,0,9m,0,2n|4dj,0,nm,0,2n||",
                "Newfoundland": "4do,0,9m,0,2n|4do,0,nm,0,2n||",
                "Niue": "7hg,0,2n|||",
                "Norfolk": "7hk,0,12i,0,2n|||",
                "Noronha": "4dp,0,4du,0,4e8,0,9m,0,2n|4dp,0,4du,0,4e8,0,cl,0,2n||",
                "Novosibirsk": "4ec,0,9m,0,2n|4ec,0,cl,0,2n||",
                "Omsk": "4ee,0,9m,0,2n|4ee,0,cl,0,2n||",
                "Pakistan": "4eh,0,9m,0,2n|4eh,0,cl,0,2n||",
                "Palau": "7qa,0,2n|||",
                "Papua_New_Guinea": "7qd,0,2d4,0,7qj,0,2n|||",
                "Paraguay": "4eq,0,9m,0,2n|4eq,0,cl,0,2n||",
                "Peru": "4et,0,9m,0,2n|4et,0,cl,0,2n||",
                "Philippines": "4f5,0,9m,0,2n|4f5,0,cl,0,2n||",
                "Phoenix_Islands": "7rm,0,12i,0,2n|||",
                "Pierre_Miquelon": "4f7,0,4fb,0,4gl,0,4hd,0,9m,0,2n|4f7,0,4fb,0,4gl,0,4hd,0,nm,0,2n||",
                "Pitcairn": "7sf,0,2n|||",
                "Ponape": "7sk,0,2n|||",
                "Pyongyang": "7sl,0,2n|||",
                "Reunion": "7st,0,2n|||",
                "Rothera": "7t1,0,2n|||",
                "Sakhalin": "4hc,0,9m,0,2n|4hc,0,cl,0,2n||",
                "Samara": "4hb,0,3tk,0,2n|4hb,0,1va,0,2n||",
                "Samoa": "4gf,0,9m,0,2n|4gf,0,1va,0,2n||",
                "Seychelles": "812,0,2n|||",
                "Singapore": "836,0,9m,0,2n|||",
                "Solomon": "83k,0,12i,0,2n|||",
                "South_Georgia": "2ej,0,2dp,0,2n|||",
                "Suriname": "868,0,2n|||",
                "Syowa": "88f,0,2n|||",
                "Tahiti": "88m,0,2n|||",
                "Taipei": "4gb,0,9m,0,2n|4gb,0,nm,0,2n||",
                "Tajikistan": "896,0,2n|||",
                "Tokelau": "898,0,2n|||",
                "Tonga": "4g5,0,9m,0,2n|4g5,0,cl,0,2n||",
                "Truk": "89e,0,2n|||",
                "Turkmenistan": "4g3,0,9m,0,2n|4g3,0,cl,0,2n||",
                "Tuvalu": "89h,0,2n|||",
                "Uruguay": "4fq,0,9m,0,2n|4fq,0,cl,0,2n||",
                "Uzbekistan": "4fp,0,9m,0,2n|4fp,0,cl,0,2n||",
                "Vanuatu": "4fo,0,9m,0,2n|4fo,0,cl,0,2n||",
                "Venezuela": "8ah,0,2n|||",
                "Vladivostok": "4fn,0,9m,0,2n|4fn,0,cl,0,2n||",
                "Volgograd": "4fk,0,9m,0,2n|4fk,0,cl,0,2n||",
                "Vostok": "8ar,0,2n|||",
                "Wake": "8at,0,1lm,0,2n|||",
                "Wallis": "8b4,0,3sp,0,8b5,0,2n|||",
                "Yakutsk": "4fj,0,9m,0,2n|4fj,0,cl,0,2n||",
                "Yekaterinburg": "4fh,0,9m,0,2n|4fh,0,cl,0,2n||"
            }
        },
        "bn": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6q3,0,9m,0,2n||",
                "Europe/London": "|6q2,0,cl,0,2n||"
            },
            "metazone": {
                "Afghanistan": "6jm,0,2n|||",
                "Africa_Central": "2ek,0,1ni,0,2n|||",
                "Africa_Eastern": "12k,0,1ni,0,2n|||",
                "Africa_Southern": "2ej,0,1ni,0,9m,0,2n|||",
                "Africa_Western": "1ai,0,1ni,0,9m,0,2n|1ai,0,1ni,0,cl,0,2n||",
                "Alaska": "3qc,0,9m,0,2n|3qc,0,nm,0,2n||",
                "Amazon": "6mt,0,9m,0,2n|6n1,0,cl,0,2n||",
                "America_Central": "1cr,0,9m,0,2n|1cr,0,nm,0,2n||",
                "America_Eastern": "3qd,0,2ei,0,2n|3qd,0,nm,0,2n||",
                "America_Mountain": "3qe,0,1v4,0,2ei,0,2n|3qe,0,1v4,0,1v5,0,2n||",
                "America_Pacific": "1v6,0,1v9,0,1v4,0,9m,0,2n|1v6,0,1v9,0,1v4,0,1v5,0,2n||",
                "Anadyr": "3qf,0,9m,0,2n|3qf,0,cl,0,2n||",
                "Apia": "3qj,0,9m,0,2n|3qj,0,1v5,0,2n||",
                "Arabian": "3qk,0,9m,0,2n|3qk,0,nm,0,2n||",
                "Argentina": "2ea,0,9m,0,2n|2ea,0,cl,0,2n||",
                "Argentina_Western": "1i0,0,6o9,0,2ei,0,2n|1i0,0,2ea,0,1va,0,2n||",
                "Armenia": "3qm,0,9m,0,2n|3qm,0,cl,0,2n||",
                "Atlantic": "3qn,0,9m,0,2n|3qn,0,nm,0,2n||",
                "Australia_Central": "172,0,1cr,0,9m,0,2n|172,0,1cr,0,nm,0,2n||",
                "Australia_CentralWestern": "172,0,1cr,0,1i0,0,9m,0,2n|172,0,1cr,0,1i0,0,nm,0,2n||",
                "Australia_Eastern": "172,0,12k,0,9m,0,2n|172,0,12k,0,nm,0,2n||",
                "Australia_Western": "172,0,1i0,0,9m,0,2n|172,0,1i0,0,nm,0,2n||",
                "Azerbaijan": "3qo,0,9m,0,2n|3qo,0,cl,0,2n||",
                "Azores": "3qt,0,9m,0,2n|3qt,0,cl,0,2n||",
                "Bangladesh": "3qv,0,9m,0,2n|3qv,0,cl,0,2n||",
                "Bhutan": "6p0,0,2n|||",
                "Bolivia": "6p4,0,2n|||",
                "Brasilia": "3r0,0,9m,0,2n|3r0,0,cl,0,2n||",
                "Brunei": "6pa,0,6pb,0,2n|||",
                "Cape_Verde": "3r4,0,3r5,0,9m,0,2n|3r4,0,3r5,0,cl,0,2n||",
                "Chamorro": "6pj,0,9m,0,2n|||",
                "Chatham": "3r7,0,9m,0,2n|3r7,0,nm,0,2n||",
                "Chile": "3rg,0,9m,0,2n|3rg,0,6pn,0,2n||",
                "China": "3ro,0,9m,0,2n|3ro,0,nm,0,2n||",
                "Choibalsan": "3rp,0,9m,0,2n|3rp,0,cl,0,2n||",
                "Christmas": "6pt,0,1lm,0,2n|||",
                "Cocos": "6q1,0,12i,0,2n|||",
                "Colombia": "3rq,0,9m,0,2n|3rq,0,cl,0,2n||",
                "Cook": "3rr,0,12i,0,9m,0,2n|3rr,0,12i,0,6q6,0,cl,0,2n||",
                "Cuba": "3rt,0,9m,0,2n|3rt,0,nm,0,2n||",
                "Davis": "6qa,0,2n|||",
                "DumontDUrville": "6qb,5,6qd,0,2n|||",
                "East_Timor": "12k,0,6qm,0,2n|||",
                "Easter": "3s0,0,1lm,0,9m,0,2n|3s0,0,1lm,0,cl,0,2n||",
                "Ecuador": "6r3,0,2n|||",
                "Europe_Central": "2ek,0,1io,0,9m,0,2n|2ek,0,1io,0,cl,0,2n||",
                "Europe_Eastern": "12k,0,1io,0,9m,0,2n|12k,0,1io,0,cl,0,2n||",
                "Europe_Further_Eastern": "6rh,5,12k,0,6ro,0,2n|||",
                "Europe_Western": "1ai,0,1io,0,9m,0,2n|1ai,0,1io,0,cl,0,2n||",
                "Falkland": "3s3,0,12i,0,9m,0,2n|3s3,0,12i,0,cl,0,2n||",
                "Fiji": "3s7,0,9m,0,2n|3s7,0,cl,0,2n||",
                "French_Guiana": "3sh,0,6sc,0,2n|||",
                "French_Southern": "3sh,0,2ej,0,3sp,0,6sn,0,2n|||",
                "Galapagos": "6t7,0,2n|||",
                "Gambier": "6te,0,2n|||",
                "Georgia": "2dp,0,9m,0,2n|2dp,0,cl,0,2n||",
                "Gilbert_Islands": "6tp,0,12i,0,2n|||",
                "GMT": "6tq,0,6u0,0,6uk|||",
                "Greenland_Eastern": "12k,0,1vp,0,9m,0,2n|12k,0,1vp,0,cl,0,2n||",
                "Greenland_Western": "1ai,0,1vp,0,9m,0,2n|1ai,0,1vp,0,cl,0,2n||",
                "Gulf": "71e,0,9m,0,2n|||",
                "Guyana": "71l,0,2n|||",
                "Hawaii_Aleutian": "3sq,5,3ss,0,9m,0,2n|3sq,5,3ss,0,nm,0,2n||",
                "Hong_Kong": "3st,0,3su,0,9m,0,2n|3st,0,3su,0,cl,0,2n||",
                "Hovd": "3t0,0,9m,0,2n|3t0,0,cl,0,2n||",
                "India": "73b,0,9m,0,2n||15a|",
                "Indian_Ocean": "73g,0,1v9,0,2n|||",
                "Indochina": "741,0,2n|||",
                "Indonesia_Central": "1cr,0,2df,0,2n|||",
                "Indonesia_Eastern": "12k,0,2df,0,2n|||",
                "Indonesia_Western": "76s,0,2df,0,2n|||",
                "Iran": "3t3,0,9m,0,2n|3t3,0,nm,0,2n||",
                "Irkutsk": "3t5,0,9m,0,2n|3t5,0,cl,0,2n||",
                "Israel": "3t8,0,9m,0,2n|3t8,0,nm,0,2n||",
                "Japan": "3t9,0,9m,0,2n|3t9,0,nm,0,2n||",
                "Kamchatka": "3ta,5,0,3te,0,3tk,0,2n|3ta,5,0,3te,0,1va,0,2n||",
                "Kazakhstan_Eastern": "12k,0,3tl,0,9m,0,2n|||",
                "Kazakhstan_Western": "1ai,0,3tl,0,9m,0,2n|||",
                "Korea": "3to,0,9m,0,2n|3to,0,nm,0,2n||",
                "Kosrae": "78d,0,2n|||",
                "Krasnoyarsk": "3tr,0,9m,0,2n|3tr,0,cl,0,2n||",
                "Kyrgystan": "791,0,2n|||",
                "Line_Islands": "79a,0,12i,0,2n|||",
                "Lord_Howe": "3uh,0,3v5,0,9m,0,3v7|3uh,0,3v5,0,nm,0,3v7||",
                "Macquarie": "79q,0,1lm,0,2n|||",
                "Magadan": "3vf,0,9m,0,2n|3vf,0,cl,0,2n||",
                "Malaysia": "79u,0,2n|||",
                "Maldives": "7a2,0,2n|||",
                "Marquesas": "7a3,0,2n|||",
                "Marshall_Islands": "7a7,0,12i,0,2n|||",
                "Mauritius": "3vv,0,9m,0,2n|3vv,0,cl,0,2n||",
                "Mawson": "7ac,0,2n|||",
                "Mexico_Northwest": "43i,0,446,0,9m,0,2n|43i,0,446,0,1v5,0,2n||",
                "Mexico_Pacific": "44c,0,1v6,0,7ak,0,9m,0,2n|44c,0,1v6,0,1v9,0,nm,0,2n||",
                "Mongolia": "451,0,45e,0,9m,0,2n|451,0,45e,0,cl,0,2n||",
                "Moscow": "4b4,0,9m,0,2n|4b4,0,cl,0,2n||",
                "Myanmar": "7eb,0,2n|||",
                "Nauru": "7ec,0,2n|||",
                "Nepal": "7el,0,2n|||",
                "New_Caledonia": "2d4,0,4df,0,9m,0,2n|2d4,0,4df,0,cl,0,2n||",
                "New_Zealand": "4dj,0,9m,0,2n|4dj,0,nm,0,2n||",
                "Newfoundland": "4do,0,9m,0,2n|4do,0,nm,0,2n||",
                "Niue": "7hg,0,2n|||",
                "Norfolk": "7hk,0,12i,0,2n|||",
                "Noronha": "4dp,0,4du,0,4e8,0,9m,0,2n|4dp,0,4du,0,4e8,0,cl,0,2n||",
                "Novosibirsk": "4ec,0,9m,0,2n|4ec,0,cl,0,2n||",
                "Omsk": "4ee,0,9m,0,2n|4ee,0,cl,0,2n||",
                "Pakistan": "4eh,0,9m,0,2n|4eh,0,cl,0,2n||",
                "Palau": "7qa,0,2n|||",
                "Papua_New_Guinea": "7qd,0,2d4,0,7qj,0,2n|||",
                "Paraguay": "4eq,0,9m,0,2n|4eq,0,cl,0,2n||",
                "Peru": "4et,0,9m,0,2n|4et,0,cl,0,2n||",
                "Philippines": "4f5,0,9m,0,2n|4f5,0,cl,0,2n||",
                "Phoenix_Islands": "7rm,0,12i,0,2n|||",
                "Pierre_Miquelon": "4f7,0,4fb,0,4gl,0,4hd,0,9m,0,2n|4f7,0,4fb,0,4gl,0,4hd,0,nm,0,2n||",
                "Pitcairn": "7sf,0,2n|||",
                "Ponape": "7sk,0,2n|||",
                "Pyongyang": "7sl,0,2n|||",
                "Reunion": "7st,0,2n|||",
                "Rothera": "7t1,0,2n|||",
                "Sakhalin": "4hc,0,9m,0,2n|4hc,0,cl,0,2n||",
                "Samara": "4hb,0,3tk,0,2n|4hb,0,1va,0,2n||",
                "Samoa": "4gf,0,9m,0,2n|4gf,0,1va,0,2n||",
                "Seychelles": "812,0,2n|||",
                "Singapore": "836,0,9m,0,2n|||",
                "Solomon": "83k,0,12i,0,2n|||",
                "South_Georgia": "2ej,0,2dp,0,2n|||",
                "Suriname": "868,0,2n|||",
                "Syowa": "88f,0,2n|||",
                "Tahiti": "88m,0,2n|||",
                "Taipei": "4gb,0,9m,0,2n|4gb,0,nm,0,2n||",
                "Tajikistan": "896,0,2n|||",
                "Tokelau": "898,0,2n|||",
                "Tonga": "4g5,0,9m,0,2n|4g5,0,cl,0,2n||",
                "Truk": "89e,0,2n|||",
                "Turkmenistan": "4g3,0,9m,0,2n|4g3,0,cl,0,2n||",
                "Tuvalu": "89h,0,2n|||",
                "Uruguay": "4fq,0,9m,0,2n|4fq,0,cl,0,2n||",
                "Uzbekistan": "4fp,0,9m,0,2n|4fp,0,cl,0,2n||",
                "Vanuatu": "4fo,0,9m,0,2n|4fo,0,cl,0,2n||",
                "Venezuela": "8ah,0,2n|||",
                "Vladivostok": "4fn,0,9m,0,2n|4fn,0,cl,0,2n||",
                "Volgograd": "4fk,0,9m,0,2n|4fk,0,cl,0,2n||",
                "Vostok": "8ar,0,2n|||",
                "Wake": "8at,0,1lm,0,2n|||",
                "Wallis": "8b4,0,3sp,0,8b5,0,2n|||",
                "Yakutsk": "4fj,0,9m,0,2n|4fj,0,cl,0,2n||",
                "Yekaterinburg": "4fh,0,9m,0,2n|4fh,0,cl,0,2n||"
            }
        },
        "bo-IN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "bo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "br": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8s,0,gr,0,c92||",
                "Europe/London": "|8s,0,hh,0,c93,5,c94||"
            },
            "metazone": {
                "Afghanistan": "8s,0,b6|||",
                "Africa_Central": "8s,0,c3j|||",
                "Africa_Eastern": "8s,0,c5,0,q3,0,1js|||",
                "Africa_Southern": "8s,0,gr,0,c3i|||",
                "Africa_Western": "8s,0,gr,0,c5,0,q3,0,1b5|8s,0,hh,0,c5,0,q3,0,1b5||",
                "Alaska": "8s,0,gr,0,3p|8s,0,hh,0,3p||",
                "Almaty": "8s,0,gr,0,56|8s,0,hh,0,56||",
                "Amazon": "8s,0,gr,0,nk,0,5m|8s,0,hh,0,nk,0,5m||",
                "America_Eastern": "8s,0,gr,0,q3,0,1js|8s,0,hh,0,q3,0,1js||",
                "America_Mountain": "8s,0,gr,0,q3,0,8d6|8s,0,hh,0,q3,0,8d6||",
                "Anadyr": "8s,0,gr,0,8d9|8s,0,hh,0,8d9||",
                "Apia": "8s,0,gr,0,3e|8s,0,hh,0,3e||",
                "Arabian": "8s,0,gr,0,hs|8s,0,hh,0,hs||",
                "Argentina": "8s,0,gr,0,4ff|8s,0,hh,0,4ff||",
                "Argentina_Western": "8s,0,gr,0,4ff,0,q3,0,1b5|8s,0,hh,0,4ff,0,q3,0,1b5||",
                "Armenia": "8s,0,gr,0,4l|8s,0,hh,0,4l||",
                "Australia_Central": "8s,0,gr,0,4fe|8s,0,hh,0,4fe||",
                "Australia_CentralWestern": "8s,0,gr,0,4fe,0,q3,0,1b5|8s,0,hh,0,4fe,0,q3,0,1b5||",
                "Australia_Eastern": "8s,0,gr,0,4fd,0,q3,0,1js|8s,0,hh,0,4fd,0,q3,0,1js||",
                "Australia_Western": "8s,0,gr,0,4fd,0,q3,0,1b5|8s,0,hh,0,4fd,0,q3,0,1b5||",
                "Azerbaijan": "8s,0,gr,0,8eg|8s,0,hh,0,8eg||",
                "Azores": "8s,0,gr,0,nk,0,8f4|8s,0,hh,0,nk,0,8f4||",
                "Bangladesh": "8s,0,gr,0,3n|8s,0,hh,0,3n||",
                "Bhutan": "8s,0,n4|||",
                "Bolivia": "8s,0,aa|||",
                "Brasilia": "8s,0,gr,0,in|8s,0,hh,0,in||",
                "Brunei": "8s,0,9o,0,a4|||",
                "Cape_Verde": "8s,0,gr,0,q3,0,8ff,5,8fh|8s,0,hh,0,q3,0,8ff,5,8fh||",
                "Chatham": "8s,0,gr,0,27|8s,0,hh,0,27||",
                "Chile": "8s,0,gr,0,49|8s,0,hh,0,49||",
                "China": "8s,0,gr,0,4hi|8s,0,hh,0,4hi||",
                "Christmas": "8s,0,2v7,0,99|||",
                "Cocos": "8s,0,1qc,0,c3h|||",
                "Colombia": "8s,0,gr,0,20u|8s,0,hh,0,20u||",
                "Cook": "8s,0,gr,0,1qc,0,2v|8s,0,hh,0,1qc,0,2v||",
                "Cuba": "8s,0,gr,0,tb|8s,0,hh,0,tb||",
                "East_Timor": "8s,0,7n,0,q3,0,1js|||",
                "Easter": "8s,0,gr,0,2v7,0,8h0|8s,0,hh,0,2v7,0,8h0||",
                "Ecuador": "8s,0,ai|||",
                "Europe_Central": "8s,0,gr,0,8h1|8s,0,hh,0,8h1||",
                "Europe_Eastern": "8s,0,gr,0,4p,0,q3,0,1js|8s,0,hh,0,4p,0,q3,0,1js||",
                "Europe_Western": "8s,0,gr,0,4p,0,q3,0,1b5|8s,0,hh,0,4p,0,q3,0,1b5||",
                "Falkland": "8s,0,gr,0,1qc,0,4v|8s,0,hh,0,1qc,0,4v||",
                "Fiji": "8s,0,gr,0,cm|8s,0,hh,0,cm||",
                "French_Guiana": "8s,0,8h5,0,c3g|||",
                "French_Southern": "8s,0,c3f,0,c3e,0,c3d,0,c3c,0,27v|||",
                "Galapagos": "8s,0,1qc,0,cp|||",
                "Gambier": "8s,0,6v|||",
                "Georgia": "8s,0,gr,0,8i0|8s,0,hh,0,8i0||",
                "GMT": "c3b,0,c3a,0,5u,0,c39|||",
                "Greenland_Eastern": "8s,0,gr,0,4f8,0,q3,0,1js|8s,0,hh,0,4f8,0,q3,0,1js||",
                "Greenland_Western": "8s,0,gr,0,4f8,0,q3,0,1b5|8s,0,hh,0,4f8,0,q3,0,1b5||",
                "Guam": "8s,0,gr,0,83|||",
                "Gulf": "8s,0,gr,0,q3,0,c38,5,c37,0,1qe,5,4f4,5,c36|||",
                "Guyana": "8s,0,7a|||",
                "Hong_Kong": "8s,0,gr,0,2l,0,2p|8s,0,hh,0,2l,0,2p||",
                "India": "8s,0,gr,0,af|||",
                "Indochina": "8s,0,c35,5,4hi|||",
                "Indonesia_Eastern": "8s,0,4sb,0,q3,0,1js|||",
                "Indonesia_Western": "8s,0,4sb,0,q3,0,1b5|||",
                "Iran": "8s,0,gr,0,5g|8s,0,hh,0,5g||",
                "Irkutsk": "8s,0,gr,0,3h|8s,0,hh,0,3h||",
                "Israel": "8s,0,gr,0,4e|8s,0,hh,0,4e||",
                "Japan": "8s,0,gr,0,6o|8s,0,hh,0,6o||",
                "Kazakhstan_Eastern": "8s,0,2kv,0,q3,0,1js|||",
                "Kazakhstan_Western": "8s,0,2kv,0,q3,0,1b5|||",
                "Korea": "8s,0,gr,0,qf|8s,0,hh,0,qf||",
                "Kyrgystan": "8s,0,c34|||",
                "Lanka": "8s,0,me,0,9c|||",
                "Macau": "8s,0,gr,0,57|8s,0,hh,0,57||",
                "Macquarie": "8s,0,2v7,0,6m|||",
                "Malaysia": "8s,0,bf|||",
                "Maldives": "8s,0,q3,0,c33|||",
                "Marquesas": "8s,0,1qc,0,1jm|||",
                "Marshall_Islands": "8s,0,1qc,0,7o|||",
                "Mauritius": "8s,0,gr,0,4tn|8s,0,hh,0,4tn||",
                "Mexico_Northwest": "8s,0,gr,0,4to,0,4tr|8s,0,hh,0,4to,0,4tr||",
                "Mongolia": "8s,0,gr,0,p2|8s,0,hh,0,p2||",
                "Moscow": "8s,0,gr,0,4tv|8s,0,hh,0,4tv||",
                "Myanmar": "8s,0,8v|||",
                "Nauru": "8s,0,5v|||",
                "Nepal": "8s,0,8u|||",
                "New_Caledonia": "8s,0,gr,0,215,0,4f1|8s,0,hh,0,215,0,4f1||",
                "New_Zealand": "8s,0,gr,0,1p4,5,4f1|8s,0,hh,0,1p4,5,4f1||",
                "Newfoundland": "8s,0,gr,0,53|8s,0,hh,0,53||",
                "Niue": "8s,0,8g|||",
                "Norfolk": "8s,0,2v7,0,6l|||",
                "Novosibirsk": "8s,0,gr,0,3b|8s,0,hh,0,3b||",
                "Pakistan": "8s,0,gr,0,3i|8s,0,hh,0,3i||",
                "Palau": "8s,0,9b|||",
                "Paraguay": "8s,0,gr,0,3c|8s,0,hh,0,3c||",
                "Peru": "8s,0,gr,0,4uf|8s,0,hh,0,4uf||",
                "Philippines": "8s,0,gr,0,q3,0,4ug|8s,0,hh,0,q3,0,4ug||",
                "Pierre_Miquelon": "8s,0,gr,0,50g,5,50l,5,4f4,5,u2|8s,0,hh,0,50g,5,50l,5,4f4,5,u2||",
                "Pitcairn": "8s,0,7e|||",
                "Reunion": "8s,0,q3,0,c32|||",
                "Sakhalin": "8s,0,gr,0,55|8s,0,hh,0,55||",
                "Samoa": "8s,0,gr,0,2c|8s,0,hh,0,2c||",
                "Seychelles": "8s,0,c31|||",
                "Singapore": "8s,0,gr,0,mu|||",
                "Solomon": "8s,0,1qc,0,mq|||",
                "South_Georgia": "8s,0,2u,0,q3,0,c30|||",
                "Suriname": "8s,0,mg|||",
                "Tahiti": "8s,0,8n|||",
                "Taipei": "8s,0,gr,0,3d|8s,0,hh,0,3d||",
                "Tajikistan": "8s,0,lc|||",
                "Tokelau": "8s,0,5d|||",
                "Tonga": "8s,0,gr,0,2j|8s,0,hh,0,2j||",
                "Turkmenistan": "8s,0,gr,0,4t|8s,0,hh,0,4t||",
                "Tuvalu": "8s,0,6h|||",
                "Uruguay": "8s,0,gr,0,4k|8s,0,hh,0,4k||",
                "Uzbekistan": "8s,0,gr,0,50v|8s,0,hh,0,50v||",
                "Vanuatu": "8s,0,gr,0,2f|8s,0,hh,0,2f||",
                "Venezuela": "8s,0,7s|||",
                "Vladivostok": "8s,0,gr,0,28|8s,0,hh,0,28||",
                "Volgograd": "8s,0,gr,0,3f|8s,0,hh,0,3f||",
                "Wallis": "8s,0,5f,0,4f4,0,5k|||",
                "Yakutsk": "8s,0,gr,0,3u|8s,0,hh,0,3u||",
                "Yekaterinburg": "8s,0,gr,0,511|8s,0,hh,0,511||"
            }
        },
        "brx": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c95,0,ic,0,70||",
                "Europe/London": "|c96,0,ic,0,70||"
            },
            "metazone": {
                "Acre": "514,0,aj,0,70|514,0,ic,0,70||",
                "Afghanistan": "c2v,0,aj,0,70|||",
                "Africa_Central": "1a6,0,31i,0,aj,0,70|||",
                "Africa_Eastern": "oq,0,31i,0,aj,0,70|||",
                "Africa_Southern": "1jl,0,31i,0,aj,0,70|||",
                "Africa_Western": "2bi,0,31i,0,aj,0,70|2bi,0,31i,0,ic,0,70||",
                "Alaska": "4es,0,aj,0,70|4es,0,11u,0,70||",
                "Almaty": "520,0,aj,0,70|520,0,ic,0,70||",
                "Amazon": "523,0,aj,0,70|523,0,ic,0,70||",
                "America_Central": "529,0,2bd,0,aj,0,70|529,0,2bd,0,11u,0,70||",
                "America_Eastern": "27t,0,2bd,0,aj,0,70|27t,0,2bd,0,11u,0,70||",
                "America_Mountain": "2bd,0,52g,0,aj,0,70|2bd,0,52g,0,11u,0,70||",
                "America_Pacific": "52l,0,aj,0,70|52l,0,11u,0,70||",
                "Anadyr": "4er,0,aj,0,70|4er,0,ic,0,70||",
                "Aqtau": "52n,0,aj,0,70|52n,0,ic,0,70||",
                "Aqtobe": "52v,0,aj,0,70|52v,0,ic,0,70||",
                "Arabian": "2bb,0,aj,0,70|2bb,0,11u,0,70||",
                "Argentina": "c2u,0,aj,0,70|4hn,0,ic,0,70||",
                "Argentina_Western": "2bi,0,4hn,0,aj,0,70|2bi,0,4hn,0,ic,0,70||",
                "Armenia": "537,0,aj,0,70|537,0,ic,0,70||",
                "Atlantic": "53b,0,aj,0,70|53b,0,11u,0,70||",
                "Australia_Central": "1a6,0,21b,0,aj,0,70|1a6,0,21b,0,11u,0,70||",
                "Australia_CentralWestern": "1a6,5,2bi,0,21b,0,aj,0,70|1a6,5,2bi,0,21b,0,11u,0,70||",
                "Australia_Eastern": "oq,0,21b,0,aj,0,70|oq,0,21b,0,11u,0,70||",
                "Australia_Western": "1jl,0,21b,0,aj,0,70|1jl,0,21b,0,11u,0,70||",
                "Azerbaijan": "53i,0,aj,0,70|53i,0,ic,0,70||",
                "Azores": "53r,0,aj,0,70|53r,0,ic,0,70||",
                "Bangladesh": "4eo,0,aj,0,70|4eo,0,ic,0,70||",
                "Bhutan": "c2t,0,aj,0,70|||",
                "Bolivia": "c2s,0,aj,0,70|||",
                "Brasilia": "549,0,aj,0,70|549,0,ic,0,70||",
                "Brunei": "54g,0,c2r,0,c2q,0,c2p,0,aj,0,70|||",
                "Cape_Verde": "557,0,55r,0,aj,0,70|557,0,55r,0,ic,0,70||",
                "Chamorro": "c2o,0,aj,0,70|||",
                "Chatham": "4en,0,aj,0,70|4en,0,11u,0,70||",
                "Chile": "58a,0,aj,0,70|58a,0,ic,0,70||",
                "China": "58b,0,aj,0,70|58b,0,11u,0,70||",
                "Choibalsan": "58e,0,aj,0,70|58e,0,ic,0,70||",
                "Christmas": "c2n,0,aj,0,70|||",
                "Cocos": "c2m,0,1ev,0,aj,0,70|||",
                "Colombia": "2b7,0,aj,0,70|2b7,0,ic,0,70||",
                "Cook": "1lj,0,1ev,0,aj,0,70|1lj,0,1ev,0,597,0,ic,0,70||",
                "Cuba": "599,0,aj,0,70|599,0,11u,0,70||",
                "Davis": "c2l,0,aj,0,70|||",
                "DumontDUrville": "c2k,0,c2j,0,aj,0,70|||",
                "East_Timor": "c2i,0,c2h,0,aj,0,70|||",
                "Easter": "4em,0,5bq,0,aj,0,70|4em,0,5bq,0,ic,0,70||",
                "Ecuador": "c2g,0,aj,0,70|||",
                "Europe_Central": "1a6,0,2b4,0,aj,0,70|1a6,0,2b4,0,ic,0,70||",
                "Europe_Eastern": "27t,0,2b4,0,aj,0,70|27t,0,2b4,0,ic,0,70||",
                "Europe_Western": "2au,0,2b4,0,aj,0,70|2au,0,2b4,0,ic,0,70||",
                "Falkland": "5c8,0,aj,0,70|5c8,0,ic,0,70||",
                "Fiji": "5cc,0,aj,0,70|5cc,0,ic,0,70||",
                "French_Guiana": "5cd,0,2as,0,aj,0,70|||",
                "French_Southern": "1jl,0,5cd,0,aj,0,70|||",
                "Galapagos": "c2f,0,aj,0,70|||",
                "Gambier": "c2e,0,aj,0,70|||",
                "Georgia": "5dd,0,aj,0,70|5dd,0,ic,0,70||",
                "Gilbert_Islands": "c2d,0,1ev,0,aj,0,70|||",
                "GMT": "c2c,0,aj,0,70|||",
                "Greenland_Eastern": "4el,0,27t,0,aj,0,70|4el,0,27t,0,ic,0,70||",
                "Greenland_Western": "4el,0,2au,0,aj,0,70|4el,0,2au,0,ic,0,70||",
                "Guam": "5e7,0,aj,0,70|||",
                "Gulf": "c2b,0,aj,0,70|||",
                "Guyana": "2as,0,aj,0,70|||",
                "Hawaii_Aleutian": "21u,0,5g2,0,aj,0,70|21u,0,5g2,0,11u,0,70||",
                "Hong_Kong": "5ia,0,aj,0,70|5ia,0,ic,0,70||",
                "Hovd": "4ei,0,aj,0,70|4ei,0,ic,0,70||",
                "India": "27j,0,aj,0,70||15a|",
                "Indian_Ocean": "27j,0,c2a,0,aj,0,70|||",
                "Indochina": "c29,0,c28,0,aj,0,70|||",
                "Indonesia_Central": "4ho,0,aj,0,70|||",
                "Indonesia_Eastern": "27t,0,4ho,0,aj,0,70|||",
                "Indonesia_Western": "2au,0,4ho,0,aj,0,70|||",
                "Iran": "4eg,0,aj,0,70|4eg,0,11u,0,70||",
                "Irkutsk": "5kn,0,aj,0,70|5kn,0,ic,0,70||",
                "Israel": "5kp,0,aj,0,70|5kp,0,11u,0,70||",
                "Japan": "4ef,0,aj,0,70|4ef,0,11u,0,70||",
                "Kamchatka": "5l4,0,5l7,0,aj,0,70|5l4,0,5l7,0,ic,0,70||",
                "Kazakhstan_Eastern": "5lc,0,70|||",
                "Kazakhstan_Western": "2au,0,5lc,0,70|||",
                "Korea": "5lk,0,aj,0,70|5lk,0,11u,0,70||",
                "Kosrae": "c27,0,aj,0,70|||",
                "Krasnoyarsk": "5lr,0,aj,0,70|5lr,0,ic,0,70||",
                "Kyrgystan": "c26,0,aj,0,70|||",
                "Lanka": "5m0,0,aj,0,70|||",
                "Line_Islands": "c25,0,1ev,0,aj,0,70|||",
                "Lord_Howe": "5m2,0,5m3,0,aj,0,70|5m2,0,5m3,0,11u,0,70||",
                "Macau": "5m6,0,aj,0,70|5m6,0,ic,0,70||",
                "Magadan": "5m7,0,aj,0,70|5m7,0,ic,0,70||",
                "Malaysia": "4hp,0,aj,0,70|||",
                "Maldives": "5ma,0,aj,0,70|||",
                "Marquesas": "c24,0,aj,0,70|||",
                "Marshall_Islands": "2ob,0,c23,0,70|||",
                "Mauritius": "5mf,0,aj,0,70|5mf,0,ic,0,70||",
                "Mawson": "5mg,0,aj,0,70|||",
                "Mongolia": "5na,0,aj,0,70|5na,0,ic,0,70||",
                "Moscow": "2lk,0,aj,0,70|2lk,0,ic,0,70||",
                "Myanmar": "4ed,0,aj,0,70|||",
                "Nauru": "c22,0,aj,0,70|||",
                "Nepal": "5nu,0,aj,0,70|||",
                "New_Caledonia": "4hq,0,4e9,0,aj,0,70|4hq,0,4e9,0,ic,0,70||",
                "New_Zealand": "5o7,0,aj,0,70|5o7,0,11u,0,70||",
                "Newfoundland": "5o9,0,aj,0,70|5o9,0,11u,0,70||",
                "Niue": "c21,0,aj,0,70|||",
                "Norfolk": "5od,0,aj,0,70|||",
                "Noronha": "5of,0,5og,0,1n8,0,aj,0,70|5of,0,5og,0,1n8,0,ic,0,70||",
                "North_Mariana": "c20,0,c1v,0,aj,0,70|||",
                "Novosibirsk": "5om,0,aj,0,70|5om,0,ic,0,70||",
                "Omsk": "1nb,0,aj,0,70|1nb,0,ic,0,70||",
                "Pakistan": "2li,0,aj,0,70|2li,0,ic,0,70||",
                "Palau": "5os,0,aj,0,70|||",
                "Papua_New_Guinea": "4hr,0,4hq,0,c1u,0,aj,0,70|||",
                "Paraguay": "5p8,0,aj,0,70|5p8,0,ic,0,70||",
                "Peru": "2k9,0,aj,0,70|2k9,0,ic,0,70||",
                "Philippines": "5pa,0,aj,0,70|5pa,0,ic,0,70||",
                "Phoenix_Islands": "c1t,0,1ev,0,aj,0,70|||",
                "Pierre_Miquelon": "5pj,0,5pm,0,4hs,0,5po,0,aj,0,70|5pj,0,5pm,0,4hs,0,5po,0,11u,0,70||",
                "Pitcairn": "c1s,0,aj,0,70|||",
                "Ponape": "4e6,0,c1r,0,aj,0,70|||",
                "Qyzylorda": "5q5,0,aj,0,70|5q5,0,ic,0,70||",
                "Reunion": "c1q,0,aj,0,70|||",
                "Rothera": "2qb,0,aj,0,70|||",
                "Sakhalin": "5q9,0,aj,0,70|5q9,0,ic,0,70||",
                "Samara": "4e5,0,aj,0,70|4e5,0,ic,0,70||",
                "Samoa": "4e4,0,aj,0,70|4e4,0,ic,0,70||",
                "Seychelles": "5qu,0,aj,0,70|||",
                "Singapore": "c1p,0,aj,0,70|||",
                "Solomon": "c1o,0,aj,0,70|||",
                "South_Georgia": "c1n,0,27i,0,aj,0,70|||",
                "Suriname": "c1m,0,aj,0,70|||",
                "Syowa": "c1l,0,aj,0,70|||",
                "Tahiti": "c1k,0,aj,0,70|||",
                "Tajikistan": "c1j,0,aj,0,70|||",
                "Tokelau": "c1i,0,aj,0,70|||",
                "Tonga": "5sk,0,aj,0,70|5sk,0,ic,0,70||",
                "Truk": "c1h,0,aj,0,70|||",
                "Turkmenistan": "5so,0,aj,0,70|5so,0,ic,0,70||",
                "Tuvalu": "c1g,0,aj,0,70|||",
                "Uruguay": "5ua,0,aj,0,70|5ua,0,ic,0,70||",
                "Uzbekistan": "5ug,0,aj,0,70|5ug,0,ic,0,70||",
                "Vanuatu": "5ut,0,aj,0,70|5ut,0,ic,0,70||",
                "Venezuela": "5vc,0,aj,0,70|||",
                "Vladivostok": "5vd,0,aj,0,70|5vd,0,11u,0,70||",
                "Volgograd": "5vf,0,aj,0,70|5vf,0,ic,0,70||",
                "Vostok": "c1f,0,aj,0,70|||",
                "Wake": "c1e,0,1ev,0,aj,0,70|||",
                "Wallis": "c1d,0,4hs,0,c1c,0,aj,0,70|||",
                "Yakutsk": "5vp,0,aj,0,70|5vp,0,ic,0,70||",
                "Yekaterinburg": "5vq,0,aj,0,70|5vq,0,ic,0,70||"
            }
        },
        "bs-Cyrl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "63g,0,1q,0,m|63g,0,20,0,m||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "18j,0,1q,0,m|18j,0,20,0,2b,0,2d||",
                "America_Eastern": "12g,0,1q,0,m|12g,0,20,0,2b,0,2d||",
                "America_Mountain": "4e3,0,1q,0,m|4e3,0,20,0,2b,0,2d||",
                "America_Pacific": "4dv,0,1q,0,m|4dv,0,20,0,2b,0,2d||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "64i,0,1q,0,m|64i,0,20,0,2b,0,2d||",
                "Atlantic": "64n,0,1q,0,m|64n,0,c1b,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "c1a,0,1q,0,m|c19,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "c18,0,pm,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d||",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d||",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "c17,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "4ht,0,c16,0,m|||",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "6di,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|c15,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,4dr||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "c14,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "c13,0,ir,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,4dr||",
                "Macquarie": "c12,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,4dr||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,4dr||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "1v2,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "4dn,0,1q,0,c11|4dn,0,20,0,2b,0,2d||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "26d,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "bs-Latn": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6p7,0,1a,0,10||",
                "Europe/London": "|6ou,0,4b,0,10||"
            },
            "metazone": {
                "Acre": "3q,0,1a,0,1d|3q,0,3o,0,40,0,3v||",
                "Afghanistan": "6ov,0,10|||",
                "Africa_Central": "6p1,0,10|||",
                "Africa_Eastern": "6p2,0,10|||",
                "Africa_Southern": "6p3,0,1a,0,10|||",
                "Africa_Western": "4di,0,1a,0,10|4di,0,4b,0,10||",
                "Alaska": "4de,0,1a,0,10|4de,0,4b,0,10||",
                "Almaty": "4dc,0,1a,0,1d|4dc,0,3o,0,40,0,3v||",
                "Amazon": "4db,0,1a,0,10|4db,0,4b,0,10||",
                "America_Central": "13n,0,ug,0,1a,0,10|13n,0,ug,0,4b,0,10||",
                "America_Eastern": "13n,0,111,0,1a,0,10|13n,0,111,0,4b,0,10||",
                "America_Mountain": "13n,0,17r,0,1a,0,10|13n,0,17r,0,4b,0,10||",
                "America_Pacific": "13n,0,110,0,1a,0,10|13n,0,110,0,4b,0,10||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "4d8,0,1a,0,10|4d8,0,4b,0,10||",
                "Aqtau": "4d7,0,1a,0,1d|4d7,0,3o,0,40,0,3v||",
                "Aqtobe": "4d5,0,1a,0,1d|4d5,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,10|1i1,0,4b,0,10||",
                "Argentina": "4d4,0,1a,0,10|4d4,0,4b,0,10||",
                "Argentina_Western": "4d3,0,1a,0,10|4d3,0,4b,0,10||",
                "Armenia": "4d2,0,1a,0,10|4d2,0,4b,0,10||",
                "Atlantic": "13n,0,23j,0,1a,0,10|13n,0,23j,0,4b,0,10||",
                "Australia_Central": "4cv,0,1a,0,10|4cv,0,4b,0,10||",
                "Australia_CentralWestern": "pp,0,4cu,0,1a,0,10|pp,0,4cu,0,4b,0,10||",
                "Australia_Eastern": "4ct,0,1a,0,10|4ct,0,4b,0,10||",
                "Australia_Western": "4cs,0,1a,0,10|4cs,0,4b,0,10||",
                "Azerbaijan": "4cr,0,1a,0,10|4cr,0,4b,0,10||",
                "Azores": "4cq,0,1a,0,10|4cq,0,4b,0,10||",
                "Bangladesh": "4cp,0,1a,0,10|4cp,0,4b,0,10||",
                "Bhutan": "6r8,0,10|||",
                "Bolivia": "6rg,0,10|||",
                "Brasilia": "4co,0,1a,0,10|4co,0,4b,0,10||",
                "Brunei": "6rl,0,10|||",
                "Cape_Verde": "4cn,0,1a,0,10|4cn,0,4b,0,10||",
                "Chamorro": "6rq,0,1a,0,10|||",
                "Chatham": "4cm,0,1a,0,10|4cm,0,4b,0,10||",
                "Chile": "4cl,0,1a,0,10|4cl,0,4b,0,10||",
                "China": "23o,0,1a,0,10|23o,0,4b,0,10||",
                "Choibalsan": "4ck,0,1a,0,10|4ck,0,4b,0,10||",
                "Christmas": "ln,0,52,0,6so,0,10o|||",
                "Cocos": "ln,0,52,0,1b9,0,1eg|||",
                "Colombia": "4cj,0,1a,0,10|4cj,0,4b,0,10||",
                "Cook": "1lq,0,10,0,52,0,4ci,0,1lv|6u2,0,10,0,52,0,4ci,0,1lv||",
                "Cuba": "4ch,0,1a,0,10|4ch,0,4b,0,10||",
                "Davis": "ln,0,10j,0,5s|||",
                "DumontDUrville": "ln,0,10j,0,5b,5,5j|||",
                "East_Timor": "70n,0,10|||",
                "Easter": "4cg,0,1a,0,10|4cg,0,4b,0,10||",
                "Ecuador": "71f,0,10|||",
                "Europe_Central": "4cf,0,1a,0,10|4cf,0,4b,0,10||",
                "Europe_Eastern": "1hr,0,1a,0,10|1hr,0,4b,0,10||",
                "Europe_Further_Eastern": "71p,0,10|||",
                "Europe_Western": "1hq,0,1a,0,10|1hq,0,4b,0,10||",
                "Falkland": "4ce,0,1a,0,10|4ce,0,4b,0,10||",
                "Fiji": "1lq,0,10,0,52,0,725|727,0,4b,0,10||",
                "French_Guiana": "72a,0,10|||",
                "French_Southern": "ln,0,52,0,72c,0,72f,0,72h,0,i3,0,72r|||",
                "Galapagos": "72s,0,10|||",
                "Gambier": "72t,0,10|||",
                "Georgia": "4cd,0,1a,0,10|4cd,0,4b,0,10||",
                "Gilbert_Islands": "ln,0,52,0,736,0,1lv|||",
                "GMT": "73a,0,10|||",
                "Greenland_Eastern": "4cb,0,1a,0,10|4cb,0,4b,0,10||",
                "Greenland_Western": "4ca,0,1a,0,10|4ca,0,4b,0,10||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1a,0,10|||",
                "Guyana": "740,0,10|||",
                "Hawaii_Aleutian": "1c5,5,4c9,0,1a,0,10|1c5,5,4c9,0,4b,0,10||",
                "Hong_Kong": "4c8,0,1a,0,10|4c8,0,4b,0,10||",
                "Hovd": "4c7,0,1a,0,10|4c7,0,4b,0,10||",
                "India": "1n7,0,1a,0,10|||",
                "Indian_Ocean": "ln,0,52,0,77g,0,77h|||",
                "Indochina": "77j,0,10|||",
                "Indonesia_Central": "77l,0,10|||",
                "Indonesia_Eastern": "77o,0,10|||",
                "Indonesia_Western": "77s,0,10|||",
                "Iran": "4c6,0,1a,0,10|4c6,0,4b,0,10||",
                "Irkutsk": "2c5,0,1a,0,10|2c5,0,4b,0,10||",
                "Israel": "1hm,0,1a,0,10|1hm,0,4b,0,10||",
                "Japan": "1hl,0,1a,0,10|1hl,0,4b,0,10||",
                "Kamchatka": "37,5,2c4,0,1a,0,1d|37,5,2c4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "788,0,10|||",
                "Kazakhstan_Western": "789,0,10|||",
                "Korea": "1hk,0,1a,0,10|1hk,0,4b,0,10||",
                "Kosrae": "ln,0,52,0,10o,0,6f|||",
                "Krasnoyarsk": "2br,0,1a,0,10|2br,0,4b,0,10||",
                "Kyrgystan": "78c,0,10|||",
                "Lanka": "9c,0,1d|||",
                "Line_Islands": "ln,0,52,0,1b9,0,2bp|||",
                "Lord_Howe": "1lq,0,10,0,52,0,10o,0,1u,0,1i2|2bk,0,10,0,52,0,10o,0,1u,0,1i2||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "ln,0,52,0,10o,0,794|||",
                "Magadan": "4c5,0,1a,0,10|4c5,0,4b,0,10||",
                "Malaysia": "79b,0,10|||",
                "Maldives": "79e,0,10|||",
                "Marquesas": "ln,0,52,0,1b9,0,1jm|||",
                "Marshall_Islands": "ln,0,52,0,79h,0,1lv|||",
                "Mauritius": "4c4,0,1a,0,10|4c4,0,4b,0,10||",
                "Mawson": "ln,0,10j,0,63|||",
                "Mexico_Northwest": "4c3,0,1hj,0,1a,0,10|4c3,0,1hj,0,4b,0,10||",
                "Mexico_Pacific": "4c2,0,110,0,1a,0,10|4c2,0,110,0,4b,0,10||",
                "Mongolia": "4c0,0,1a,0,10|4c0,0,4b,0,10||",
                "Moscow": "4bv,0,1a,0,10|4bv,0,4b,0,10||",
                "Myanmar": "79v,0,10|||",
                "Nauru": "ln,0,52,0,10o,0,5v|||",
                "Nepal": "7a0,0,10|||",
                "New_Caledonia": "4bu,0,1a,0,10|4bu,0,4b,0,10||",
                "New_Zealand": "4bt,0,1a,0,10|4bt,0,4b,0,10||",
                "Newfoundland": "4bs,0,1a,0,10|4bs,0,4b,0,10||",
                "Niue": "ln,0,52,0,10o,0,8g|||",
                "Norfolk": "7a8,0,10|||",
                "Noronha": "1lq,0,10,0,52,0,4bq,0,1s,0,4i,0,1hg|2bk,0,10,0,52,0,4bq,0,1s,0,4i,0,1hg||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "2b8,0,1a,0,10|2b8,0,4b,0,10||",
                "Omsk": "2b1,0,1a,0,10|2b1,0,4b,0,10||",
                "Pakistan": "4bp,0,1a,0,10|4bp,0,4b,0,10||",
                "Palau": "ln,0,52,0,10o,0,9b|||",
                "Papua_New_Guinea": "ln,0,52,0,7b2,0,7br,0,7cc|||",
                "Paraguay": "4bo,0,1a,0,10|4bo,0,4b,0,10||",
                "Peru": "4bn,0,1a,0,10|4bn,0,4b,0,10||",
                "Philippines": "4bl,0,1a,0,10|4bl,0,4b,0,10||",
                "Phoenix_Islands": "ln,0,52,0,1b9,0,7ep|||",
                "Pierre_Miquelon": "1lq,0,10,0,52,0,1b9,0,14i,0,1he,0,i3,0,u2|2bk,0,10,0,52,0,1b9,0,14i,0,1he,0,i3,0,u2||",
                "Pitcairn": "ln,0,52,0,10o,0,1ko|||",
                "Ponape": "ln,0,52,0,10o,0,9s|||",
                "Pyongyang": "2b0,0,10|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "7fs,0,10|||",
                "Rothera": "ln,0,10j,0,5q|||",
                "Sakhalin": "4bk,0,1a,0,10|4bk,0,4b,0,10||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "4bj,0,1a,0,10|4bj,0,4b,0,10||",
                "Seychelles": "7j1,0,10|||",
                "Singapore": "7j2,0,1a,0,10|||",
                "Solomon": "ln,0,52,0,7l1,0,1lv|||",
                "South_Georgia": "7o5,0,10|||",
                "Suriname": "7oe,0,10|||",
                "Syowa": "ln,0,10j,0,65|||",
                "Tahiti": "7oh,0,10|||",
                "Taipei": "4bi,0,1a,0,10|4bi,0,4b,0,10||",
                "Tajikistan": "7om,0,10|||",
                "Tokelau": "ln,0,52,0,10o,0,5d|||",
                "Tonga": "4bh,0,1a,0,10|4bh,0,4b,0,10||",
                "Truk": "7oo,0,10|||",
                "Turkmenistan": "4bg,0,1a,0,10|4bg,0,4b,0,10||",
                "Tuvalu": "7p1,0,10|||",
                "Uruguay": "4bf,0,1a,0,10|4bf,0,4b,0,10||",
                "Uzbekistan": "4be,0,1a,0,10|4be,0,4b,0,10||",
                "Vanuatu": "4bd,0,1a,0,10|4bd,0,4b,0,10||",
                "Venezuela": "7q9,0,10|||",
                "Vladivostok": "4bc,0,1a,0,10|4bc,0,4b,0,10||",
                "Volgograd": "4bb,0,1a,0,10|4bb,0,4b,0,10||",
                "Vostok": "ln,0,10j,0,5t|||",
                "Wake": "ln,0,52,0,10o,0,2ar|||",
                "Wallis": "ln,0,52,0,1b9,0,2aq,0,i3,0,5k|||",
                "Yakutsk": "2ap,0,1a,0,10|2ap,0,4b,0,10||",
                "Yekaterinburg": "4ba,0,1a,0,10|4ba,0,4b,0,10||"
            }
        },
        "bs": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6p7,0,1a,0,10||",
                "Europe/London": "|6ou,0,4b,0,10||"
            },
            "metazone": {
                "Acre": "3q,0,1a,0,1d|3q,0,3o,0,40,0,3v||",
                "Afghanistan": "6ov,0,10|||",
                "Africa_Central": "6p1,0,10|||",
                "Africa_Eastern": "6p2,0,10|||",
                "Africa_Southern": "6p3,0,1a,0,10|||",
                "Africa_Western": "4di,0,1a,0,10|4di,0,4b,0,10||",
                "Alaska": "4de,0,1a,0,10|4de,0,4b,0,10||",
                "Almaty": "4dc,0,1a,0,1d|4dc,0,3o,0,40,0,3v||",
                "Amazon": "4db,0,1a,0,10|4db,0,4b,0,10||",
                "America_Central": "13n,0,ug,0,1a,0,10|13n,0,ug,0,4b,0,10||",
                "America_Eastern": "13n,0,111,0,1a,0,10|13n,0,111,0,4b,0,10||",
                "America_Mountain": "13n,0,17r,0,1a,0,10|13n,0,17r,0,4b,0,10||",
                "America_Pacific": "13n,0,110,0,1a,0,10|13n,0,110,0,4b,0,10||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "4d8,0,1a,0,10|4d8,0,4b,0,10||",
                "Aqtau": "4d7,0,1a,0,1d|4d7,0,3o,0,40,0,3v||",
                "Aqtobe": "4d5,0,1a,0,1d|4d5,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,10|1i1,0,4b,0,10||",
                "Argentina": "4d4,0,1a,0,10|4d4,0,4b,0,10||",
                "Argentina_Western": "4d3,0,1a,0,10|4d3,0,4b,0,10||",
                "Armenia": "4d2,0,1a,0,10|4d2,0,4b,0,10||",
                "Atlantic": "13n,0,23j,0,1a,0,10|13n,0,23j,0,4b,0,10||",
                "Australia_Central": "4cv,0,1a,0,10|4cv,0,4b,0,10||",
                "Australia_CentralWestern": "pp,0,4cu,0,1a,0,10|pp,0,4cu,0,4b,0,10||",
                "Australia_Eastern": "4ct,0,1a,0,10|4ct,0,4b,0,10||",
                "Australia_Western": "4cs,0,1a,0,10|4cs,0,4b,0,10||",
                "Azerbaijan": "4cr,0,1a,0,10|4cr,0,4b,0,10||",
                "Azores": "4cq,0,1a,0,10|4cq,0,4b,0,10||",
                "Bangladesh": "4cp,0,1a,0,10|4cp,0,4b,0,10||",
                "Bhutan": "6r8,0,10|||",
                "Bolivia": "6rg,0,10|||",
                "Brasilia": "4co,0,1a,0,10|4co,0,4b,0,10||",
                "Brunei": "6rl,0,10|||",
                "Cape_Verde": "4cn,0,1a,0,10|4cn,0,4b,0,10||",
                "Chamorro": "6rq,0,1a,0,10|||",
                "Chatham": "4cm,0,1a,0,10|4cm,0,4b,0,10||",
                "Chile": "4cl,0,1a,0,10|4cl,0,4b,0,10||",
                "China": "23o,0,1a,0,10|23o,0,4b,0,10||",
                "Choibalsan": "4ck,0,1a,0,10|4ck,0,4b,0,10||",
                "Christmas": "ln,0,52,0,6so,0,10o|||",
                "Cocos": "ln,0,52,0,1b9,0,1eg|||",
                "Colombia": "4cj,0,1a,0,10|4cj,0,4b,0,10||",
                "Cook": "1lq,0,10,0,52,0,4ci,0,1lv|6u2,0,10,0,52,0,4ci,0,1lv||",
                "Cuba": "4ch,0,1a,0,10|4ch,0,4b,0,10||",
                "Davis": "ln,0,10j,0,5s|||",
                "DumontDUrville": "ln,0,10j,0,5b,5,5j|||",
                "East_Timor": "70n,0,10|||",
                "Easter": "4cg,0,1a,0,10|4cg,0,4b,0,10||",
                "Ecuador": "71f,0,10|||",
                "Europe_Central": "4cf,0,1a,0,10|4cf,0,4b,0,10||",
                "Europe_Eastern": "1hr,0,1a,0,10|1hr,0,4b,0,10||",
                "Europe_Further_Eastern": "71p,0,10|||",
                "Europe_Western": "1hq,0,1a,0,10|1hq,0,4b,0,10||",
                "Falkland": "4ce,0,1a,0,10|4ce,0,4b,0,10||",
                "Fiji": "1lq,0,10,0,52,0,725|727,0,4b,0,10||",
                "French_Guiana": "72a,0,10|||",
                "French_Southern": "ln,0,52,0,72c,0,72f,0,72h,0,i3,0,72r|||",
                "Galapagos": "72s,0,10|||",
                "Gambier": "72t,0,10|||",
                "Georgia": "4cd,0,1a,0,10|4cd,0,4b,0,10||",
                "Gilbert_Islands": "ln,0,52,0,736,0,1lv|||",
                "GMT": "73a,0,10|||",
                "Greenland_Eastern": "4cb,0,1a,0,10|4cb,0,4b,0,10||",
                "Greenland_Western": "4ca,0,1a,0,10|4ca,0,4b,0,10||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1a,0,10|||",
                "Guyana": "740,0,10|||",
                "Hawaii_Aleutian": "1c5,5,4c9,0,1a,0,10|1c5,5,4c9,0,4b,0,10||",
                "Hong_Kong": "4c8,0,1a,0,10|4c8,0,4b,0,10||",
                "Hovd": "4c7,0,1a,0,10|4c7,0,4b,0,10||",
                "India": "1n7,0,1a,0,10|||",
                "Indian_Ocean": "ln,0,52,0,77g,0,77h|||",
                "Indochina": "77j,0,10|||",
                "Indonesia_Central": "77l,0,10|||",
                "Indonesia_Eastern": "77o,0,10|||",
                "Indonesia_Western": "77s,0,10|||",
                "Iran": "4c6,0,1a,0,10|4c6,0,4b,0,10||",
                "Irkutsk": "2c5,0,1a,0,10|2c5,0,4b,0,10||",
                "Israel": "1hm,0,1a,0,10|1hm,0,4b,0,10||",
                "Japan": "1hl,0,1a,0,10|1hl,0,4b,0,10||",
                "Kamchatka": "37,5,2c4,0,1a,0,1d|37,5,2c4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "788,0,10|||",
                "Kazakhstan_Western": "789,0,10|||",
                "Korea": "1hk,0,1a,0,10|1hk,0,4b,0,10||",
                "Kosrae": "ln,0,52,0,10o,0,6f|||",
                "Krasnoyarsk": "2br,0,1a,0,10|2br,0,4b,0,10||",
                "Kyrgystan": "78c,0,10|||",
                "Lanka": "9c,0,1d|||",
                "Line_Islands": "ln,0,52,0,1b9,0,2bp|||",
                "Lord_Howe": "1lq,0,10,0,52,0,10o,0,1u,0,1i2|2bk,0,10,0,52,0,10o,0,1u,0,1i2||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "ln,0,52,0,10o,0,794|||",
                "Magadan": "4c5,0,1a,0,10|4c5,0,4b,0,10||",
                "Malaysia": "79b,0,10|||",
                "Maldives": "79e,0,10|||",
                "Marquesas": "ln,0,52,0,1b9,0,1jm|||",
                "Marshall_Islands": "ln,0,52,0,79h,0,1lv|||",
                "Mauritius": "4c4,0,1a,0,10|4c4,0,4b,0,10||",
                "Mawson": "ln,0,10j,0,63|||",
                "Mexico_Northwest": "4c3,0,1hj,0,1a,0,10|4c3,0,1hj,0,4b,0,10||",
                "Mexico_Pacific": "4c2,0,110,0,1a,0,10|4c2,0,110,0,4b,0,10||",
                "Mongolia": "4c0,0,1a,0,10|4c0,0,4b,0,10||",
                "Moscow": "4bv,0,1a,0,10|4bv,0,4b,0,10||",
                "Myanmar": "79v,0,10|||",
                "Nauru": "ln,0,52,0,10o,0,5v|||",
                "Nepal": "7a0,0,10|||",
                "New_Caledonia": "4bu,0,1a,0,10|4bu,0,4b,0,10||",
                "New_Zealand": "4bt,0,1a,0,10|4bt,0,4b,0,10||",
                "Newfoundland": "4bs,0,1a,0,10|4bs,0,4b,0,10||",
                "Niue": "ln,0,52,0,10o,0,8g|||",
                "Norfolk": "7a8,0,10|||",
                "Noronha": "1lq,0,10,0,52,0,4bq,0,1s,0,4i,0,1hg|2bk,0,10,0,52,0,4bq,0,1s,0,4i,0,1hg||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "2b8,0,1a,0,10|2b8,0,4b,0,10||",
                "Omsk": "2b1,0,1a,0,10|2b1,0,4b,0,10||",
                "Pakistan": "4bp,0,1a,0,10|4bp,0,4b,0,10||",
                "Palau": "ln,0,52,0,10o,0,9b|||",
                "Papua_New_Guinea": "ln,0,52,0,7b2,0,7br,0,7cc|||",
                "Paraguay": "4bo,0,1a,0,10|4bo,0,4b,0,10||",
                "Peru": "4bn,0,1a,0,10|4bn,0,4b,0,10||",
                "Philippines": "4bl,0,1a,0,10|4bl,0,4b,0,10||",
                "Phoenix_Islands": "ln,0,52,0,1b9,0,7ep|||",
                "Pierre_Miquelon": "1lq,0,10,0,52,0,1b9,0,14i,0,1he,0,i3,0,u2|2bk,0,10,0,52,0,1b9,0,14i,0,1he,0,i3,0,u2||",
                "Pitcairn": "ln,0,52,0,10o,0,1ko|||",
                "Ponape": "ln,0,52,0,10o,0,9s|||",
                "Pyongyang": "2b0,0,10|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "7fs,0,10|||",
                "Rothera": "ln,0,10j,0,5q|||",
                "Sakhalin": "4bk,0,1a,0,10|4bk,0,4b,0,10||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "4bj,0,1a,0,10|4bj,0,4b,0,10||",
                "Seychelles": "7j1,0,10|||",
                "Singapore": "7j2,0,1a,0,10|||",
                "Solomon": "ln,0,52,0,7l1,0,1lv|||",
                "South_Georgia": "7o5,0,10|||",
                "Suriname": "7oe,0,10|||",
                "Syowa": "ln,0,10j,0,65|||",
                "Tahiti": "7oh,0,10|||",
                "Taipei": "4bi,0,1a,0,10|4bi,0,4b,0,10||",
                "Tajikistan": "7om,0,10|||",
                "Tokelau": "ln,0,52,0,10o,0,5d|||",
                "Tonga": "4bh,0,1a,0,10|4bh,0,4b,0,10||",
                "Truk": "7oo,0,10|||",
                "Turkmenistan": "4bg,0,1a,0,10|4bg,0,4b,0,10||",
                "Tuvalu": "7p1,0,10|||",
                "Uruguay": "4bf,0,1a,0,10|4bf,0,4b,0,10||",
                "Uzbekistan": "4be,0,1a,0,10|4be,0,4b,0,10||",
                "Vanuatu": "4bd,0,1a,0,10|4bd,0,4b,0,10||",
                "Venezuela": "7q9,0,10|||",
                "Vladivostok": "4bc,0,1a,0,10|4bc,0,4b,0,10||",
                "Volgograd": "4bb,0,1a,0,10|4bb,0,4b,0,10||",
                "Vostok": "ln,0,10j,0,5t|||",
                "Wake": "ln,0,52,0,10o,0,2ar|||",
                "Wallis": "ln,0,52,0,1b9,0,2aq,0,i3,0,5k|||",
                "Yakutsk": "2ap,0,1a,0,10|2ap,0,4b,0,10||",
                "Yekaterinburg": "4ba,0,1a,0,10|4ba,0,4b,0,10||"
            }
        },
        "ca-AD": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,36,0,2v3||",
                "Europe/London": "|9,0,39,0,2v1||"
            },
            "metazone": {
                "Afghanistan": "9,0,2,0,2vj|||",
                "Africa_Central": "9,0,2,0,10i,0,n|||",
                "Africa_Eastern": "9,0,2,0,10i,0,8h|||",
                "Africa_Southern": "9,0,36,0,19,0,2vm,0,2,0,10i|||",
                "Africa_Western": "9,0,36,0,2,0,10i,0,of|9,0,39,0,2,0,10i,0,of||",
                "Alaska": "9,0,36,0,1ht|9,0,39,0,1ht||",
                "Amazon": "9,0,36,0,2,0,1ll|9,0,39,0,2,0,1ll||",
                "America_Central": "9,0,36,0,6r,0,ri,0,19,0,98|9,0,39,0,6r,0,ri,0,19,0,98||",
                "America_Eastern": "9,0,36,0,3r,0,ri,0,19,0,98|9,0,39,0,3r,0,ri,0,19,0,98||",
                "America_Mountain": "9,0,36,0,2,0,1lp,0,ri,0,19,0,98|9,0,39,0,2,0,1lp,0,ri,0,19,0,98||",
                "America_Pacific": "9,0,36,0,19,0,14b|9,0,39,0,19,0,14b||",
                "Anadyr": "9,0,36,0,cb|19v,0,39,0,cb||",
                "Apia": "9,0,36,0,c9|9,0,39,0,c9||",
                "Arabian": "9,0,36,0,1lu|9,0,39,0,1lu||",
                "Argentina": "9,0,36,0,2,0,13s|9,0,39,0,2,0,13s||",
                "Argentina_Western": "9,0,36,0,2,0,151,0,2,0,13s|9,0,39,0,2,0,151,0,2,0,13s||",
                "Armenia": "9,0,36,0,1m1|9,0,39,0,1m1||",
                "Atlantic": "9,0,36,0,2,0,1m2|9,0,39,0,2,0,1m2||",
                "Australia_Central": "9,0,36,0,p3,0,n|9,0,39,0,p3,0,n||",
                "Australia_CentralWestern": "9,0,36,0,p3,0,6n,5,48|9,0,39,0,p3,0,6n,5,48||",
                "Australia_Eastern": "9,0,36,0,p3,0,8h|9,0,39,0,p3,0,8h||",
                "Australia_Western": "9,0,36,0,p3,0,of|9,0,39,0,p3,0,of||",
                "Azerbaijan": "9,0,36,0,1m7|9,0,39,0,1m7||",
                "Azores": "9,0,36,0,2,0,et,0,b9|9,0,39,0,2,0,et,0,b9||",
                "Bangladesh": "9,0,36,0,2,0,1m9,0,1mc|9,0,39,0,2,0,1m9,0,1mc||",
                "Bhutan": "9,0,2,0,as|||",
                "Bolivia": "9,0,2,0,16g|||",
                "Brasilia": "9,0,36,0,2,0,in|9,0,39,0,2,0,in||",
                "Brunei": "9,0,2,0,9o,0,a4|||",
                "Cape_Verde": "9,0,36,0,2,0,cf,0,1mi|9,0,39,0,2,0,cf,0,1mi||",
                "Chamorro": "9,0,2,0,6j|||",
                "Chatham": "9,0,36,0,2,0,27|9,0,39,0,2,0,27||",
                "Chile": "9,0,36,0,2,0,1mj|9,0,39,0,2,0,1mj||",
                "China": "9,0,36,0,2,0,1c,0,1mk|9,0,39,0,2,0,1c,0,1mk||",
                "Choibalsan": "9,0,36,0,2,0,2q|9,0,39,0,2,0,2q||",
                "Christmas": "9,0,2,0,30k|||",
                "Cocos": "9,0,2,0,et,0,o7,0,7v|||",
                "Colombia": "9,0,36,0,2,0,1mn|9,0,39,0,2,0,1mn||",
                "Cook": "9,0,36,0,2,0,et,0,o7,0,2v|9,0,2,0,30q,0,30s,0,2,0,et,0,o7,0,2v||",
                "Cuba": "9,0,36,0,2,0,34|9,0,39,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,36,0,2,0,1mo,0,2,0,181|9,0,39,0,2,0,1mo,0,2,0,181||",
                "Ecuador": "9,0,2,0,30v|||",
                "Europe_Central": "9,0,36,0,19,0,cc,0,pn|9,0,39,0,19,0,cc,0,pn|h0|h4",
                "Europe_Eastern": "9,0,36,0,2,0,1v,0,pn|9,0,39,0,2,0,1v,0,pn|gu|gv",
                "Europe_Further_Eastern": "9,0,2,0,319,0,31b,0,2ao|||",
                "Europe_Western": "9,0,36,0,2,0,144,0,pn|9,0,39,0,2,0,144,0,pn|h1|h2",
                "Falkland": "9,0,36,0,2,0,et,0,o7,0,1nd|9,0,39,0,2,0,et,0,o7,0,1nd||",
                "Fiji": "9,0,36,0,2,0,4q|9,0,39,0,2,0,4q||",
                "French_Guiana": "9,0,2,0,1c,0,31j,0,nf|||",
                "French_Southern": "9,0,31k,0,i3,0,2ah,0,19,0,i6|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,36,0,2,0,1a0|9,0,39,0,2,0,1a0||",
                "Gilbert_Islands": "9,0,2,0,et,0,o7,0,6q|||",
                "GMT": "9,0,19,0,31o,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,36,0,2,0,1v,0,2,0,152|9,0,39,0,2,0,1v,0,2,0,152||",
                "Greenland_Western": "9,0,36,0,2,0,144,0,2,0,152|9,0,39,0,2,0,144,0,2,0,152||",
                "Gulf": "9,0,19,0,127|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "9,0,36,0,2,0,4g,5,1i5|9,0,39,0,2,0,4g,5,1i5||",
                "Hong_Kong": "9,0,36,0,2,0,2l,0,2p|9,0,39,0,2,0,2l,0,2p||",
                "Hovd": "9,0,36,0,2,0,2g|9,0,39,0,2,0,2g||",
                "India": "9,0,36,0,2,0,31q|||",
                "Indian_Ocean": "9,0,2,0,2lm,0,2ln|||",
                "Indochina": "9,0,2lo|||",
                "Indonesia_Central": "9,0,6r,0,1a1|||",
                "Indonesia_Eastern": "9,0,2,0,1nl,0,1a1|||",
                "Indonesia_Western": "9,0,2,0,151,0,1a1|||",
                "Iran": "9,0,36,0,ce|9,0,39,0,ce||",
                "Irkutsk": "9,0,36,0,1ia|9,0,39,0,1ia||",
                "Israel": "9,0,36,0,1ic|9,0,39,0,1ic||",
                "Japan": "9,0,36,0,19,0,1no|9,0,39,0,19,0,1no||",
                "Kamchatka": "9,0,36,0,2,0,37,0,2,0,1np|19v,0,39,0,2,0,37,0,2,0,1np||",
                "Kazakhstan_Eastern": "9,0,2,0,1nl,0,19,0,45|||",
                "Kazakhstan_Western": "9,0,2,0,151,0,19,0,45|||",
                "Korea": "9,0,36,0,2,0,hf|9,0,39,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,36,0,2,0,1c7|9,0,39,0,2,0,1c7||",
                "Kyrgystan": "9,0,19,0,2m4|||",
                "Line_Islands": "9,0,2,0,ac,0,o|||",
                "Lord_Howe": "9,0,36,0,2,0,1u,0,24|19v,0,39,0,2,0,1u,0,24||",
                "Macau": "9,0,36,0,2,0,57|9,0,39,0,2,0,57||",
                "Macquarie": "9,0,2,0,6m|||",
                "Magadan": "9,0,36,0,2,0,2o|9,0,39,0,2,0,2o||",
                "Malaysia": "9,0,2,0,2m6|||",
                "Maldives": "9,0,2,0,et,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,o7,0,7o|||",
                "Mauritius": "9,0,36,0,2,0,1nu|9,0,39,0,2,0,1nu||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,36,0,19,0,93,5,1o0,0,2,0,149|9,0,39,0,19,0,93,5,1o0,0,2,0,149||",
                "Mexico_Pacific": "9,0,36,0,19,0,14b,0,2,0,149|9,0,39,0,19,0,14b,0,2,0,149||",
                "Mongolia": "9,0,36,0,1o2,0,2m|9,0,39,0,1o2,0,2m||",
                "Moscow": "9,0,36,0,2,0,ca|9,0,39,0,2,0,ca||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,36,0,2,0,ba,0,1oc|9,0,39,0,2,0,ba,0,1oc||",
                "New_Zealand": "9,0,36,0,2,0,ba,0,go|9,0,39,0,2,0,ba,0,go||",
                "Newfoundland": "9,0,36,0,2,0,h5|9,0,39,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,et,0,o7,0,6l|||",
                "Noronha": "9,0,36,0,2,0,1s,0,2,0,23|9,0,39,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "9,0,36,0,2,0,3b|9,0,39,0,2,0,3b||",
                "Omsk": "9,0,36,0,1d1|9,0,39,0,1d1||",
                "Pakistan": "9,0,36,0,19,0,3i|9,0,39,0,19,0,3i||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,a8|||",
                "Paraguay": "9,0,36,0,19,0,p8|9,0,39,0,19,0,p8||",
                "Peru": "9,0,36,0,19,0,ha|9,0,39,0,19,0,ha||",
                "Philippines": "9,0,36,0,2,0,1jb|9,0,39,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,o7,0,90|||",
                "Pierre_Miquelon": "9,0,36,0,2,0,8i,5,33,0,i3,0,35|9,0,39,0,2,0,8i,5,33,0,i3,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Reunion": "9,0,2,0,2mi|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,36,0,2,0,55|9,0,39,0,2,0,55||",
                "Samara": "9,0,36,0,2,0,29|9,0,39,0,2,0,29||",
                "Samoa": "9,0,36,0,2,0,2c|9,0,39,0,2,0,2c||",
                "Seychelles": "9,0,2,0,et,0,8k|||",
                "Singapore": "9,0,2,0,m8|||",
                "Solomon": "9,0,2,0,2mk|||",
                "South_Georgia": "9,0,2,0,1a0,0,19,0,i6|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "9,0,36,0,2,0,3d|9,0,39,0,2,0,3d||",
                "Tajikistan": "9,0,19,0,lc|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,36,0,2,0,2j|9,0,39,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,36,0,19,0,4t|9,0,39,0,19,0,4t||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,36,0,2,0,1om|9,0,39,0,2,0,1om||",
                "Uzbekistan": "9,0,36,0,2,0,1op|9,0,39,0,2,0,1op||",
                "Vanuatu": "9,0,36,0,2,0,1oq|9,0,39,0,2,0,1oq||",
                "Venezuela": "9,0,2,0,2mr|||",
                "Vladivostok": "9,0,36,0,2,0,28|9,0,39,0,2,0,28||",
                "Volgograd": "9,0,36,0,2,0,3f|9,0,39,0,2,0,3f||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,i3,0,5k|||",
                "Yakutsk": "9,0,36,0,2,0,1h6|9,0,39,0,2,0,1h6||",
                "Yekaterinburg": "9,0,36,0,1os|9,0,39,0,1os||"
            }
        },
        "ca-ES-VALENCIA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,36,0,2v3||",
                "Europe/London": "|9,0,39,0,2v1||"
            },
            "metazone": {
                "Afghanistan": "9,0,2,0,2vj|||",
                "Africa_Central": "9,0,2,0,10i,0,n|||",
                "Africa_Eastern": "9,0,2,0,10i,0,8h|||",
                "Africa_Southern": "9,0,36,0,19,0,2vm,0,2,0,10i|||",
                "Africa_Western": "9,0,36,0,2,0,10i,0,of|9,0,39,0,2,0,10i,0,of||",
                "Alaska": "9,0,36,0,1ht|9,0,39,0,1ht||",
                "Amazon": "9,0,36,0,2,0,1ll|9,0,39,0,2,0,1ll||",
                "America_Central": "9,0,36,0,6r,0,ri,0,19,0,98|9,0,39,0,6r,0,ri,0,19,0,98||",
                "America_Eastern": "9,0,36,0,3r,0,ri,0,19,0,98|9,0,39,0,3r,0,ri,0,19,0,98||",
                "America_Mountain": "9,0,36,0,2,0,1lp,0,ri,0,19,0,98|9,0,39,0,2,0,1lp,0,ri,0,19,0,98||",
                "America_Pacific": "9,0,36,0,19,0,14b|9,0,39,0,19,0,14b||",
                "Anadyr": "9,0,36,0,cb|19v,0,39,0,cb||",
                "Apia": "9,0,36,0,c9|9,0,39,0,c9||",
                "Arabian": "9,0,36,0,1lu|9,0,39,0,1lu||",
                "Argentina": "9,0,36,0,2,0,13s|9,0,39,0,2,0,13s||",
                "Argentina_Western": "9,0,36,0,2,0,151,0,2,0,13s|9,0,39,0,2,0,151,0,2,0,13s||",
                "Armenia": "9,0,36,0,1m1|9,0,39,0,1m1||",
                "Atlantic": "9,0,36,0,2,0,1m2|9,0,39,0,2,0,1m2||",
                "Australia_Central": "9,0,36,0,p3,0,n|9,0,39,0,p3,0,n||",
                "Australia_CentralWestern": "9,0,36,0,p3,0,6n,5,48|9,0,39,0,p3,0,6n,5,48||",
                "Australia_Eastern": "9,0,36,0,p3,0,8h|9,0,39,0,p3,0,8h||",
                "Australia_Western": "9,0,36,0,p3,0,of|9,0,39,0,p3,0,of||",
                "Azerbaijan": "9,0,36,0,1m7|9,0,39,0,1m7||",
                "Azores": "9,0,36,0,2,0,et,0,b9|9,0,39,0,2,0,et,0,b9||",
                "Bangladesh": "9,0,36,0,2,0,1m9,0,1mc|9,0,39,0,2,0,1m9,0,1mc||",
                "Bhutan": "9,0,2,0,as|||",
                "Bolivia": "9,0,2,0,16g|||",
                "Brasilia": "9,0,36,0,2,0,in|9,0,39,0,2,0,in||",
                "Brunei": "9,0,2,0,9o,0,a4|||",
                "Cape_Verde": "9,0,36,0,2,0,cf,0,1mi|9,0,39,0,2,0,cf,0,1mi||",
                "Chamorro": "9,0,2,0,6j|||",
                "Chatham": "9,0,36,0,2,0,27|9,0,39,0,2,0,27||",
                "Chile": "9,0,36,0,2,0,1mj|9,0,39,0,2,0,1mj||",
                "China": "9,0,36,0,2,0,1c,0,1mk|9,0,39,0,2,0,1c,0,1mk||",
                "Choibalsan": "9,0,36,0,2,0,2q|9,0,39,0,2,0,2q||",
                "Christmas": "9,0,2,0,30k|||",
                "Cocos": "9,0,2,0,et,0,o7,0,7v|||",
                "Colombia": "9,0,36,0,2,0,1mn|9,0,39,0,2,0,1mn||",
                "Cook": "9,0,36,0,2,0,et,0,o7,0,2v|9,0,2,0,30q,0,30s,0,2,0,et,0,o7,0,2v||",
                "Cuba": "9,0,36,0,2,0,34|9,0,39,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,36,0,2,0,1mo,0,2,0,181|9,0,39,0,2,0,1mo,0,2,0,181||",
                "Ecuador": "9,0,2,0,30v|||",
                "Europe_Central": "9,0,36,0,19,0,cc,0,pn|9,0,39,0,19,0,cc,0,pn|h0|h4",
                "Europe_Eastern": "9,0,36,0,2,0,1v,0,pn|9,0,39,0,2,0,1v,0,pn|gu|gv",
                "Europe_Further_Eastern": "9,0,2,0,319,0,31b,0,2ao|||",
                "Europe_Western": "9,0,36,0,2,0,144,0,pn|9,0,39,0,2,0,144,0,pn|h1|h2",
                "Falkland": "9,0,36,0,2,0,et,0,o7,0,1nd|9,0,39,0,2,0,et,0,o7,0,1nd||",
                "Fiji": "9,0,36,0,2,0,4q|9,0,39,0,2,0,4q||",
                "French_Guiana": "9,0,2,0,1c,0,31j,0,nf|||",
                "French_Southern": "9,0,31k,0,i3,0,2ah,0,19,0,i6|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,36,0,2,0,1a0|9,0,39,0,2,0,1a0||",
                "Gilbert_Islands": "9,0,2,0,et,0,o7,0,6q|||",
                "GMT": "9,0,19,0,31o,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,36,0,2,0,1v,0,2,0,152|9,0,39,0,2,0,1v,0,2,0,152||",
                "Greenland_Western": "9,0,36,0,2,0,144,0,2,0,152|9,0,39,0,2,0,144,0,2,0,152||",
                "Gulf": "9,0,19,0,127|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "9,0,36,0,2,0,4g,5,1i5|9,0,39,0,2,0,4g,5,1i5||",
                "Hong_Kong": "9,0,36,0,2,0,2l,0,2p|9,0,39,0,2,0,2l,0,2p||",
                "Hovd": "9,0,36,0,2,0,2g|9,0,39,0,2,0,2g||",
                "India": "9,0,36,0,2,0,31q|||",
                "Indian_Ocean": "9,0,2,0,2lm,0,2ln|||",
                "Indochina": "9,0,2lo|||",
                "Indonesia_Central": "9,0,6r,0,1a1|||",
                "Indonesia_Eastern": "9,0,2,0,1nl,0,1a1|||",
                "Indonesia_Western": "9,0,2,0,151,0,1a1|||",
                "Iran": "9,0,36,0,ce|9,0,39,0,ce||",
                "Irkutsk": "9,0,36,0,1ia|9,0,39,0,1ia||",
                "Israel": "9,0,36,0,1ic|9,0,39,0,1ic||",
                "Japan": "9,0,36,0,19,0,1no|9,0,39,0,19,0,1no||",
                "Kamchatka": "9,0,36,0,2,0,37,0,2,0,1np|19v,0,39,0,2,0,37,0,2,0,1np||",
                "Kazakhstan_Eastern": "9,0,2,0,1nl,0,19,0,45|||",
                "Kazakhstan_Western": "9,0,2,0,151,0,19,0,45|||",
                "Korea": "9,0,36,0,2,0,hf|9,0,39,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,36,0,2,0,1c7|9,0,39,0,2,0,1c7||",
                "Kyrgystan": "9,0,19,0,2m4|||",
                "Line_Islands": "9,0,2,0,ac,0,o|||",
                "Lord_Howe": "9,0,36,0,2,0,1u,0,24|19v,0,39,0,2,0,1u,0,24||",
                "Macau": "9,0,36,0,2,0,57|9,0,39,0,2,0,57||",
                "Macquarie": "9,0,2,0,6m|||",
                "Magadan": "9,0,36,0,2,0,2o|9,0,39,0,2,0,2o||",
                "Malaysia": "9,0,2,0,2m6|||",
                "Maldives": "9,0,2,0,et,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,o7,0,7o|||",
                "Mauritius": "9,0,36,0,2,0,1nu|9,0,39,0,2,0,1nu||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,36,0,19,0,93,5,1o0,0,2,0,149|9,0,39,0,19,0,93,5,1o0,0,2,0,149||",
                "Mexico_Pacific": "9,0,36,0,19,0,14b,0,2,0,149|9,0,39,0,19,0,14b,0,2,0,149||",
                "Mongolia": "9,0,36,0,1o2,0,2m|9,0,39,0,1o2,0,2m||",
                "Moscow": "9,0,36,0,2,0,ca|9,0,39,0,2,0,ca||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,36,0,2,0,ba,0,1oc|9,0,39,0,2,0,ba,0,1oc||",
                "New_Zealand": "9,0,36,0,2,0,ba,0,go|9,0,39,0,2,0,ba,0,go||",
                "Newfoundland": "9,0,36,0,2,0,h5|9,0,39,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,et,0,o7,0,6l|||",
                "Noronha": "9,0,36,0,2,0,1s,0,2,0,23|9,0,39,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "9,0,36,0,2,0,3b|9,0,39,0,2,0,3b||",
                "Omsk": "9,0,36,0,1d1|9,0,39,0,1d1||",
                "Pakistan": "9,0,36,0,19,0,3i|9,0,39,0,19,0,3i||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,a8|||",
                "Paraguay": "9,0,36,0,19,0,p8|9,0,39,0,19,0,p8||",
                "Peru": "9,0,36,0,19,0,ha|9,0,39,0,19,0,ha||",
                "Philippines": "9,0,36,0,2,0,1jb|9,0,39,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,o7,0,90|||",
                "Pierre_Miquelon": "9,0,36,0,2,0,8i,5,33,0,i3,0,35|9,0,39,0,2,0,8i,5,33,0,i3,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Reunion": "9,0,2,0,2mi|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,36,0,2,0,55|9,0,39,0,2,0,55||",
                "Samara": "9,0,36,0,2,0,29|9,0,39,0,2,0,29||",
                "Samoa": "9,0,36,0,2,0,2c|9,0,39,0,2,0,2c||",
                "Seychelles": "9,0,2,0,et,0,8k|||",
                "Singapore": "9,0,2,0,m8|||",
                "Solomon": "9,0,2,0,2mk|||",
                "South_Georgia": "9,0,2,0,1a0,0,19,0,i6|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "9,0,36,0,2,0,3d|9,0,39,0,2,0,3d||",
                "Tajikistan": "9,0,19,0,lc|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,36,0,2,0,2j|9,0,39,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,36,0,19,0,4t|9,0,39,0,19,0,4t||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,36,0,2,0,1om|9,0,39,0,2,0,1om||",
                "Uzbekistan": "9,0,36,0,2,0,1op|9,0,39,0,2,0,1op||",
                "Vanuatu": "9,0,36,0,2,0,1oq|9,0,39,0,2,0,1oq||",
                "Venezuela": "9,0,2,0,2mr|||",
                "Vladivostok": "9,0,36,0,2,0,28|9,0,39,0,2,0,28||",
                "Volgograd": "9,0,36,0,2,0,3f|9,0,39,0,2,0,3f||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,i3,0,5k|||",
                "Yakutsk": "9,0,36,0,2,0,1h6|9,0,39,0,2,0,1h6||",
                "Yekaterinburg": "9,0,36,0,1os|9,0,39,0,1os||"
            }
        },
        "ca-FR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,36,0,2v3||",
                "Europe/London": "|9,0,39,0,2v1||"
            },
            "metazone": {
                "Afghanistan": "9,0,2,0,2vj|||",
                "Africa_Central": "9,0,2,0,10i,0,n|||",
                "Africa_Eastern": "9,0,2,0,10i,0,8h|||",
                "Africa_Southern": "9,0,36,0,19,0,2vm,0,2,0,10i|||",
                "Africa_Western": "9,0,36,0,2,0,10i,0,of|9,0,39,0,2,0,10i,0,of||",
                "Alaska": "9,0,36,0,1ht|9,0,39,0,1ht||",
                "Amazon": "9,0,36,0,2,0,1ll|9,0,39,0,2,0,1ll||",
                "America_Central": "9,0,36,0,6r,0,ri,0,19,0,98|9,0,39,0,6r,0,ri,0,19,0,98||",
                "America_Eastern": "9,0,36,0,3r,0,ri,0,19,0,98|9,0,39,0,3r,0,ri,0,19,0,98||",
                "America_Mountain": "9,0,36,0,2,0,1lp,0,ri,0,19,0,98|9,0,39,0,2,0,1lp,0,ri,0,19,0,98||",
                "America_Pacific": "9,0,36,0,19,0,14b|9,0,39,0,19,0,14b||",
                "Anadyr": "9,0,36,0,cb|19v,0,39,0,cb||",
                "Apia": "9,0,36,0,c9|9,0,39,0,c9||",
                "Arabian": "9,0,36,0,1lu|9,0,39,0,1lu||",
                "Argentina": "9,0,36,0,2,0,13s|9,0,39,0,2,0,13s||",
                "Argentina_Western": "9,0,36,0,2,0,151,0,2,0,13s|9,0,39,0,2,0,151,0,2,0,13s||",
                "Armenia": "9,0,36,0,1m1|9,0,39,0,1m1||",
                "Atlantic": "9,0,36,0,2,0,1m2|9,0,39,0,2,0,1m2||",
                "Australia_Central": "9,0,36,0,p3,0,n|9,0,39,0,p3,0,n||",
                "Australia_CentralWestern": "9,0,36,0,p3,0,6n,5,48|9,0,39,0,p3,0,6n,5,48||",
                "Australia_Eastern": "9,0,36,0,p3,0,8h|9,0,39,0,p3,0,8h||",
                "Australia_Western": "9,0,36,0,p3,0,of|9,0,39,0,p3,0,of||",
                "Azerbaijan": "9,0,36,0,1m7|9,0,39,0,1m7||",
                "Azores": "9,0,36,0,2,0,et,0,b9|9,0,39,0,2,0,et,0,b9||",
                "Bangladesh": "9,0,36,0,2,0,1m9,0,1mc|9,0,39,0,2,0,1m9,0,1mc||",
                "Bhutan": "9,0,2,0,as|||",
                "Bolivia": "9,0,2,0,16g|||",
                "Brasilia": "9,0,36,0,2,0,in|9,0,39,0,2,0,in||",
                "Brunei": "9,0,2,0,9o,0,a4|||",
                "Cape_Verde": "9,0,36,0,2,0,cf,0,1mi|9,0,39,0,2,0,cf,0,1mi||",
                "Chamorro": "9,0,2,0,6j|||",
                "Chatham": "9,0,36,0,2,0,27|9,0,39,0,2,0,27||",
                "Chile": "9,0,36,0,2,0,1mj|9,0,39,0,2,0,1mj||",
                "China": "9,0,36,0,2,0,1c,0,1mk|9,0,39,0,2,0,1c,0,1mk||",
                "Choibalsan": "9,0,36,0,2,0,2q|9,0,39,0,2,0,2q||",
                "Christmas": "9,0,2,0,30k|||",
                "Cocos": "9,0,2,0,et,0,o7,0,7v|||",
                "Colombia": "9,0,36,0,2,0,1mn|9,0,39,0,2,0,1mn||",
                "Cook": "9,0,36,0,2,0,et,0,o7,0,2v|9,0,2,0,30q,0,30s,0,2,0,et,0,o7,0,2v||",
                "Cuba": "9,0,36,0,2,0,34|9,0,39,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,36,0,2,0,1mo,0,2,0,181|9,0,39,0,2,0,1mo,0,2,0,181||",
                "Ecuador": "9,0,2,0,30v|||",
                "Europe_Central": "9,0,36,0,19,0,cc,0,pn|9,0,39,0,19,0,cc,0,pn|h0|h4",
                "Europe_Eastern": "9,0,36,0,2,0,1v,0,pn|9,0,39,0,2,0,1v,0,pn|gu|gv",
                "Europe_Further_Eastern": "9,0,2,0,319,0,31b,0,2ao|||",
                "Europe_Western": "9,0,36,0,2,0,144,0,pn|9,0,39,0,2,0,144,0,pn|h1|h2",
                "Falkland": "9,0,36,0,2,0,et,0,o7,0,1nd|9,0,39,0,2,0,et,0,o7,0,1nd||",
                "Fiji": "9,0,36,0,2,0,4q|9,0,39,0,2,0,4q||",
                "French_Guiana": "9,0,2,0,1c,0,31j,0,nf|||",
                "French_Southern": "9,0,31k,0,i3,0,2ah,0,19,0,i6|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,36,0,2,0,1a0|9,0,39,0,2,0,1a0||",
                "Gilbert_Islands": "9,0,2,0,et,0,o7,0,6q|||",
                "GMT": "9,0,19,0,31o,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,36,0,2,0,1v,0,2,0,152|9,0,39,0,2,0,1v,0,2,0,152||",
                "Greenland_Western": "9,0,36,0,2,0,144,0,2,0,152|9,0,39,0,2,0,144,0,2,0,152||",
                "Gulf": "9,0,19,0,127|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "9,0,36,0,2,0,4g,5,1i5|9,0,39,0,2,0,4g,5,1i5||",
                "Hong_Kong": "9,0,36,0,2,0,2l,0,2p|9,0,39,0,2,0,2l,0,2p||",
                "Hovd": "9,0,36,0,2,0,2g|9,0,39,0,2,0,2g||",
                "India": "9,0,36,0,2,0,31q|||",
                "Indian_Ocean": "9,0,2,0,2lm,0,2ln|||",
                "Indochina": "9,0,2lo|||",
                "Indonesia_Central": "9,0,6r,0,1a1|||",
                "Indonesia_Eastern": "9,0,2,0,1nl,0,1a1|||",
                "Indonesia_Western": "9,0,2,0,151,0,1a1|||",
                "Iran": "9,0,36,0,ce|9,0,39,0,ce||",
                "Irkutsk": "9,0,36,0,1ia|9,0,39,0,1ia||",
                "Israel": "9,0,36,0,1ic|9,0,39,0,1ic||",
                "Japan": "9,0,36,0,19,0,1no|9,0,39,0,19,0,1no||",
                "Kamchatka": "9,0,36,0,2,0,37,0,2,0,1np|19v,0,39,0,2,0,37,0,2,0,1np||",
                "Kazakhstan_Eastern": "9,0,2,0,1nl,0,19,0,45|||",
                "Kazakhstan_Western": "9,0,2,0,151,0,19,0,45|||",
                "Korea": "9,0,36,0,2,0,hf|9,0,39,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,36,0,2,0,1c7|9,0,39,0,2,0,1c7||",
                "Kyrgystan": "9,0,19,0,2m4|||",
                "Line_Islands": "9,0,2,0,ac,0,o|||",
                "Lord_Howe": "9,0,36,0,2,0,1u,0,24|19v,0,39,0,2,0,1u,0,24||",
                "Macau": "9,0,36,0,2,0,57|9,0,39,0,2,0,57||",
                "Macquarie": "9,0,2,0,6m|||",
                "Magadan": "9,0,36,0,2,0,2o|9,0,39,0,2,0,2o||",
                "Malaysia": "9,0,2,0,2m6|||",
                "Maldives": "9,0,2,0,et,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,o7,0,7o|||",
                "Mauritius": "9,0,36,0,2,0,1nu|9,0,39,0,2,0,1nu||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,36,0,19,0,93,5,1o0,0,2,0,149|9,0,39,0,19,0,93,5,1o0,0,2,0,149||",
                "Mexico_Pacific": "9,0,36,0,19,0,14b,0,2,0,149|9,0,39,0,19,0,14b,0,2,0,149||",
                "Mongolia": "9,0,36,0,1o2,0,2m|9,0,39,0,1o2,0,2m||",
                "Moscow": "9,0,36,0,2,0,ca|9,0,39,0,2,0,ca||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,36,0,2,0,ba,0,1oc|9,0,39,0,2,0,ba,0,1oc||",
                "New_Zealand": "9,0,36,0,2,0,ba,0,go|9,0,39,0,2,0,ba,0,go||",
                "Newfoundland": "9,0,36,0,2,0,h5|9,0,39,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,et,0,o7,0,6l|||",
                "Noronha": "9,0,36,0,2,0,1s,0,2,0,23|9,0,39,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "9,0,36,0,2,0,3b|9,0,39,0,2,0,3b||",
                "Omsk": "9,0,36,0,1d1|9,0,39,0,1d1||",
                "Pakistan": "9,0,36,0,19,0,3i|9,0,39,0,19,0,3i||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,a8|||",
                "Paraguay": "9,0,36,0,19,0,p8|9,0,39,0,19,0,p8||",
                "Peru": "9,0,36,0,19,0,ha|9,0,39,0,19,0,ha||",
                "Philippines": "9,0,36,0,2,0,1jb|9,0,39,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,o7,0,90|||",
                "Pierre_Miquelon": "9,0,36,0,2,0,8i,5,33,0,i3,0,35|9,0,39,0,2,0,8i,5,33,0,i3,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Reunion": "9,0,2,0,2mi|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,36,0,2,0,55|9,0,39,0,2,0,55||",
                "Samara": "9,0,36,0,2,0,29|9,0,39,0,2,0,29||",
                "Samoa": "9,0,36,0,2,0,2c|9,0,39,0,2,0,2c||",
                "Seychelles": "9,0,2,0,et,0,8k|||",
                "Singapore": "9,0,2,0,m8|||",
                "Solomon": "9,0,2,0,2mk|||",
                "South_Georgia": "9,0,2,0,1a0,0,19,0,i6|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "9,0,36,0,2,0,3d|9,0,39,0,2,0,3d||",
                "Tajikistan": "9,0,19,0,lc|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,36,0,2,0,2j|9,0,39,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,36,0,19,0,4t|9,0,39,0,19,0,4t||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,36,0,2,0,1om|9,0,39,0,2,0,1om||",
                "Uzbekistan": "9,0,36,0,2,0,1op|9,0,39,0,2,0,1op||",
                "Vanuatu": "9,0,36,0,2,0,1oq|9,0,39,0,2,0,1oq||",
                "Venezuela": "9,0,2,0,2mr|||",
                "Vladivostok": "9,0,36,0,2,0,28|9,0,39,0,2,0,28||",
                "Volgograd": "9,0,36,0,2,0,3f|9,0,39,0,2,0,3f||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,i3,0,5k|||",
                "Yakutsk": "9,0,36,0,2,0,1h6|9,0,39,0,2,0,1h6||",
                "Yekaterinburg": "9,0,36,0,1os|9,0,39,0,1os||"
            }
        },
        "ca-IT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,36,0,2v3||",
                "Europe/London": "|9,0,39,0,2v1||"
            },
            "metazone": {
                "Afghanistan": "9,0,2,0,2vj|||",
                "Africa_Central": "9,0,2,0,10i,0,n|||",
                "Africa_Eastern": "9,0,2,0,10i,0,8h|||",
                "Africa_Southern": "9,0,36,0,19,0,2vm,0,2,0,10i|||",
                "Africa_Western": "9,0,36,0,2,0,10i,0,of|9,0,39,0,2,0,10i,0,of||",
                "Alaska": "9,0,36,0,1ht|9,0,39,0,1ht||",
                "Amazon": "9,0,36,0,2,0,1ll|9,0,39,0,2,0,1ll||",
                "America_Central": "9,0,36,0,6r,0,ri,0,19,0,98|9,0,39,0,6r,0,ri,0,19,0,98||",
                "America_Eastern": "9,0,36,0,3r,0,ri,0,19,0,98|9,0,39,0,3r,0,ri,0,19,0,98||",
                "America_Mountain": "9,0,36,0,2,0,1lp,0,ri,0,19,0,98|9,0,39,0,2,0,1lp,0,ri,0,19,0,98||",
                "America_Pacific": "9,0,36,0,19,0,14b|9,0,39,0,19,0,14b||",
                "Anadyr": "9,0,36,0,cb|19v,0,39,0,cb||",
                "Apia": "9,0,36,0,c9|9,0,39,0,c9||",
                "Arabian": "9,0,36,0,1lu|9,0,39,0,1lu||",
                "Argentina": "9,0,36,0,2,0,13s|9,0,39,0,2,0,13s||",
                "Argentina_Western": "9,0,36,0,2,0,151,0,2,0,13s|9,0,39,0,2,0,151,0,2,0,13s||",
                "Armenia": "9,0,36,0,1m1|9,0,39,0,1m1||",
                "Atlantic": "9,0,36,0,2,0,1m2|9,0,39,0,2,0,1m2||",
                "Australia_Central": "9,0,36,0,p3,0,n|9,0,39,0,p3,0,n||",
                "Australia_CentralWestern": "9,0,36,0,p3,0,6n,5,48|9,0,39,0,p3,0,6n,5,48||",
                "Australia_Eastern": "9,0,36,0,p3,0,8h|9,0,39,0,p3,0,8h||",
                "Australia_Western": "9,0,36,0,p3,0,of|9,0,39,0,p3,0,of||",
                "Azerbaijan": "9,0,36,0,1m7|9,0,39,0,1m7||",
                "Azores": "9,0,36,0,2,0,et,0,b9|9,0,39,0,2,0,et,0,b9||",
                "Bangladesh": "9,0,36,0,2,0,1m9,0,1mc|9,0,39,0,2,0,1m9,0,1mc||",
                "Bhutan": "9,0,2,0,as|||",
                "Bolivia": "9,0,2,0,16g|||",
                "Brasilia": "9,0,36,0,2,0,in|9,0,39,0,2,0,in||",
                "Brunei": "9,0,2,0,9o,0,a4|||",
                "Cape_Verde": "9,0,36,0,2,0,cf,0,1mi|9,0,39,0,2,0,cf,0,1mi||",
                "Chamorro": "9,0,2,0,6j|||",
                "Chatham": "9,0,36,0,2,0,27|9,0,39,0,2,0,27||",
                "Chile": "9,0,36,0,2,0,1mj|9,0,39,0,2,0,1mj||",
                "China": "9,0,36,0,2,0,1c,0,1mk|9,0,39,0,2,0,1c,0,1mk||",
                "Choibalsan": "9,0,36,0,2,0,2q|9,0,39,0,2,0,2q||",
                "Christmas": "9,0,2,0,30k|||",
                "Cocos": "9,0,2,0,et,0,o7,0,7v|||",
                "Colombia": "9,0,36,0,2,0,1mn|9,0,39,0,2,0,1mn||",
                "Cook": "9,0,36,0,2,0,et,0,o7,0,2v|9,0,2,0,30q,0,30s,0,2,0,et,0,o7,0,2v||",
                "Cuba": "9,0,36,0,2,0,34|9,0,39,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,36,0,2,0,1mo,0,2,0,181|9,0,39,0,2,0,1mo,0,2,0,181||",
                "Ecuador": "9,0,2,0,30v|||",
                "Europe_Central": "9,0,36,0,19,0,cc,0,pn|9,0,39,0,19,0,cc,0,pn|h0|h4",
                "Europe_Eastern": "9,0,36,0,2,0,1v,0,pn|9,0,39,0,2,0,1v,0,pn|gu|gv",
                "Europe_Further_Eastern": "9,0,2,0,319,0,31b,0,2ao|||",
                "Europe_Western": "9,0,36,0,2,0,144,0,pn|9,0,39,0,2,0,144,0,pn|h1|h2",
                "Falkland": "9,0,36,0,2,0,et,0,o7,0,1nd|9,0,39,0,2,0,et,0,o7,0,1nd||",
                "Fiji": "9,0,36,0,2,0,4q|9,0,39,0,2,0,4q||",
                "French_Guiana": "9,0,2,0,1c,0,31j,0,nf|||",
                "French_Southern": "9,0,31k,0,i3,0,2ah,0,19,0,i6|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,36,0,2,0,1a0|9,0,39,0,2,0,1a0||",
                "Gilbert_Islands": "9,0,2,0,et,0,o7,0,6q|||",
                "GMT": "9,0,19,0,31o,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,36,0,2,0,1v,0,2,0,152|9,0,39,0,2,0,1v,0,2,0,152||",
                "Greenland_Western": "9,0,36,0,2,0,144,0,2,0,152|9,0,39,0,2,0,144,0,2,0,152||",
                "Gulf": "9,0,19,0,127|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "9,0,36,0,2,0,4g,5,1i5|9,0,39,0,2,0,4g,5,1i5||",
                "Hong_Kong": "9,0,36,0,2,0,2l,0,2p|9,0,39,0,2,0,2l,0,2p||",
                "Hovd": "9,0,36,0,2,0,2g|9,0,39,0,2,0,2g||",
                "India": "9,0,36,0,2,0,31q|||",
                "Indian_Ocean": "9,0,2,0,2lm,0,2ln|||",
                "Indochina": "9,0,2lo|||",
                "Indonesia_Central": "9,0,6r,0,1a1|||",
                "Indonesia_Eastern": "9,0,2,0,1nl,0,1a1|||",
                "Indonesia_Western": "9,0,2,0,151,0,1a1|||",
                "Iran": "9,0,36,0,ce|9,0,39,0,ce||",
                "Irkutsk": "9,0,36,0,1ia|9,0,39,0,1ia||",
                "Israel": "9,0,36,0,1ic|9,0,39,0,1ic||",
                "Japan": "9,0,36,0,19,0,1no|9,0,39,0,19,0,1no||",
                "Kamchatka": "9,0,36,0,2,0,37,0,2,0,1np|19v,0,39,0,2,0,37,0,2,0,1np||",
                "Kazakhstan_Eastern": "9,0,2,0,1nl,0,19,0,45|||",
                "Kazakhstan_Western": "9,0,2,0,151,0,19,0,45|||",
                "Korea": "9,0,36,0,2,0,hf|9,0,39,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,36,0,2,0,1c7|9,0,39,0,2,0,1c7||",
                "Kyrgystan": "9,0,19,0,2m4|||",
                "Line_Islands": "9,0,2,0,ac,0,o|||",
                "Lord_Howe": "9,0,36,0,2,0,1u,0,24|19v,0,39,0,2,0,1u,0,24||",
                "Macau": "9,0,36,0,2,0,57|9,0,39,0,2,0,57||",
                "Macquarie": "9,0,2,0,6m|||",
                "Magadan": "9,0,36,0,2,0,2o|9,0,39,0,2,0,2o||",
                "Malaysia": "9,0,2,0,2m6|||",
                "Maldives": "9,0,2,0,et,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,o7,0,7o|||",
                "Mauritius": "9,0,36,0,2,0,1nu|9,0,39,0,2,0,1nu||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,36,0,19,0,93,5,1o0,0,2,0,149|9,0,39,0,19,0,93,5,1o0,0,2,0,149||",
                "Mexico_Pacific": "9,0,36,0,19,0,14b,0,2,0,149|9,0,39,0,19,0,14b,0,2,0,149||",
                "Mongolia": "9,0,36,0,1o2,0,2m|9,0,39,0,1o2,0,2m||",
                "Moscow": "9,0,36,0,2,0,ca|9,0,39,0,2,0,ca||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,36,0,2,0,ba,0,1oc|9,0,39,0,2,0,ba,0,1oc||",
                "New_Zealand": "9,0,36,0,2,0,ba,0,go|9,0,39,0,2,0,ba,0,go||",
                "Newfoundland": "9,0,36,0,2,0,h5|9,0,39,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,et,0,o7,0,6l|||",
                "Noronha": "9,0,36,0,2,0,1s,0,2,0,23|9,0,39,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "9,0,36,0,2,0,3b|9,0,39,0,2,0,3b||",
                "Omsk": "9,0,36,0,1d1|9,0,39,0,1d1||",
                "Pakistan": "9,0,36,0,19,0,3i|9,0,39,0,19,0,3i||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,a8|||",
                "Paraguay": "9,0,36,0,19,0,p8|9,0,39,0,19,0,p8||",
                "Peru": "9,0,36,0,19,0,ha|9,0,39,0,19,0,ha||",
                "Philippines": "9,0,36,0,2,0,1jb|9,0,39,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,o7,0,90|||",
                "Pierre_Miquelon": "9,0,36,0,2,0,8i,5,33,0,i3,0,35|9,0,39,0,2,0,8i,5,33,0,i3,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Reunion": "9,0,2,0,2mi|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,36,0,2,0,55|9,0,39,0,2,0,55||",
                "Samara": "9,0,36,0,2,0,29|9,0,39,0,2,0,29||",
                "Samoa": "9,0,36,0,2,0,2c|9,0,39,0,2,0,2c||",
                "Seychelles": "9,0,2,0,et,0,8k|||",
                "Singapore": "9,0,2,0,m8|||",
                "Solomon": "9,0,2,0,2mk|||",
                "South_Georgia": "9,0,2,0,1a0,0,19,0,i6|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "9,0,36,0,2,0,3d|9,0,39,0,2,0,3d||",
                "Tajikistan": "9,0,19,0,lc|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,36,0,2,0,2j|9,0,39,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,36,0,19,0,4t|9,0,39,0,19,0,4t||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,36,0,2,0,1om|9,0,39,0,2,0,1om||",
                "Uzbekistan": "9,0,36,0,2,0,1op|9,0,39,0,2,0,1op||",
                "Vanuatu": "9,0,36,0,2,0,1oq|9,0,39,0,2,0,1oq||",
                "Venezuela": "9,0,2,0,2mr|||",
                "Vladivostok": "9,0,36,0,2,0,28|9,0,39,0,2,0,28||",
                "Volgograd": "9,0,36,0,2,0,3f|9,0,39,0,2,0,3f||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,i3,0,5k|||",
                "Yakutsk": "9,0,36,0,2,0,1h6|9,0,39,0,2,0,1h6||",
                "Yekaterinburg": "9,0,36,0,1os|9,0,39,0,1os||"
            }
        },
        "ca": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,36,0,2v3||",
                "Europe/London": "|9,0,39,0,2v1||"
            },
            "metazone": {
                "Afghanistan": "9,0,2,0,2vj|||",
                "Africa_Central": "9,0,2,0,10i,0,n|||",
                "Africa_Eastern": "9,0,2,0,10i,0,8h|||",
                "Africa_Southern": "9,0,36,0,19,0,2vm,0,2,0,10i|||",
                "Africa_Western": "9,0,36,0,2,0,10i,0,of|9,0,39,0,2,0,10i,0,of||",
                "Alaska": "9,0,36,0,1ht|9,0,39,0,1ht||",
                "Amazon": "9,0,36,0,2,0,1ll|9,0,39,0,2,0,1ll||",
                "America_Central": "9,0,36,0,6r,0,ri,0,19,0,98|9,0,39,0,6r,0,ri,0,19,0,98||",
                "America_Eastern": "9,0,36,0,3r,0,ri,0,19,0,98|9,0,39,0,3r,0,ri,0,19,0,98||",
                "America_Mountain": "9,0,36,0,2,0,1lp,0,ri,0,19,0,98|9,0,39,0,2,0,1lp,0,ri,0,19,0,98||",
                "America_Pacific": "9,0,36,0,19,0,14b|9,0,39,0,19,0,14b||",
                "Anadyr": "9,0,36,0,cb|19v,0,39,0,cb||",
                "Apia": "9,0,36,0,c9|9,0,39,0,c9||",
                "Arabian": "9,0,36,0,1lu|9,0,39,0,1lu||",
                "Argentina": "9,0,36,0,2,0,13s|9,0,39,0,2,0,13s||",
                "Argentina_Western": "9,0,36,0,2,0,151,0,2,0,13s|9,0,39,0,2,0,151,0,2,0,13s||",
                "Armenia": "9,0,36,0,1m1|9,0,39,0,1m1||",
                "Atlantic": "9,0,36,0,2,0,1m2|9,0,39,0,2,0,1m2||",
                "Australia_Central": "9,0,36,0,p3,0,n|9,0,39,0,p3,0,n||",
                "Australia_CentralWestern": "9,0,36,0,p3,0,6n,5,48|9,0,39,0,p3,0,6n,5,48||",
                "Australia_Eastern": "9,0,36,0,p3,0,8h|9,0,39,0,p3,0,8h||",
                "Australia_Western": "9,0,36,0,p3,0,of|9,0,39,0,p3,0,of||",
                "Azerbaijan": "9,0,36,0,1m7|9,0,39,0,1m7||",
                "Azores": "9,0,36,0,2,0,et,0,b9|9,0,39,0,2,0,et,0,b9||",
                "Bangladesh": "9,0,36,0,2,0,1m9,0,1mc|9,0,39,0,2,0,1m9,0,1mc||",
                "Bhutan": "9,0,2,0,as|||",
                "Bolivia": "9,0,2,0,16g|||",
                "Brasilia": "9,0,36,0,2,0,in|9,0,39,0,2,0,in||",
                "Brunei": "9,0,2,0,9o,0,a4|||",
                "Cape_Verde": "9,0,36,0,2,0,cf,0,1mi|9,0,39,0,2,0,cf,0,1mi||",
                "Chamorro": "9,0,2,0,6j|||",
                "Chatham": "9,0,36,0,2,0,27|9,0,39,0,2,0,27||",
                "Chile": "9,0,36,0,2,0,1mj|9,0,39,0,2,0,1mj||",
                "China": "9,0,36,0,2,0,1c,0,1mk|9,0,39,0,2,0,1c,0,1mk||",
                "Choibalsan": "9,0,36,0,2,0,2q|9,0,39,0,2,0,2q||",
                "Christmas": "9,0,2,0,30k|||",
                "Cocos": "9,0,2,0,et,0,o7,0,7v|||",
                "Colombia": "9,0,36,0,2,0,1mn|9,0,39,0,2,0,1mn||",
                "Cook": "9,0,36,0,2,0,et,0,o7,0,2v|9,0,2,0,30q,0,30s,0,2,0,et,0,o7,0,2v||",
                "Cuba": "9,0,36,0,2,0,34|9,0,39,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,8h|||",
                "Easter": "9,0,36,0,2,0,1mo,0,2,0,181|9,0,39,0,2,0,1mo,0,2,0,181||",
                "Ecuador": "9,0,2,0,30v|||",
                "Europe_Central": "9,0,36,0,19,0,cc,0,pn|9,0,39,0,19,0,cc,0,pn|h0|h4",
                "Europe_Eastern": "9,0,36,0,2,0,1v,0,pn|9,0,39,0,2,0,1v,0,pn|gu|gv",
                "Europe_Further_Eastern": "9,0,2,0,319,0,31b,0,2ao|||",
                "Europe_Western": "9,0,36,0,2,0,144,0,pn|9,0,39,0,2,0,144,0,pn|h1|h2",
                "Falkland": "9,0,36,0,2,0,et,0,o7,0,1nd|9,0,39,0,2,0,et,0,o7,0,1nd||",
                "Fiji": "9,0,36,0,2,0,4q|9,0,39,0,2,0,4q||",
                "French_Guiana": "9,0,2,0,1c,0,31j,0,nf|||",
                "French_Southern": "9,0,31k,0,i3,0,2ah,0,19,0,i6|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,36,0,2,0,1a0|9,0,39,0,2,0,1a0||",
                "Gilbert_Islands": "9,0,2,0,et,0,o7,0,6q|||",
                "GMT": "9,0,19,0,31o,0,2,0,5u||ah|",
                "Greenland_Eastern": "9,0,36,0,2,0,1v,0,2,0,152|9,0,39,0,2,0,1v,0,2,0,152||",
                "Greenland_Western": "9,0,36,0,2,0,144,0,2,0,152|9,0,39,0,2,0,144,0,2,0,152||",
                "Gulf": "9,0,19,0,127|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "9,0,36,0,2,0,4g,5,1i5|9,0,39,0,2,0,4g,5,1i5||",
                "Hong_Kong": "9,0,36,0,2,0,2l,0,2p|9,0,39,0,2,0,2l,0,2p||",
                "Hovd": "9,0,36,0,2,0,2g|9,0,39,0,2,0,2g||",
                "India": "9,0,36,0,2,0,31q|||",
                "Indian_Ocean": "9,0,2,0,2lm,0,2ln|||",
                "Indochina": "9,0,2lo|||",
                "Indonesia_Central": "9,0,6r,0,1a1|||",
                "Indonesia_Eastern": "9,0,2,0,1nl,0,1a1|||",
                "Indonesia_Western": "9,0,2,0,151,0,1a1|||",
                "Iran": "9,0,36,0,ce|9,0,39,0,ce||",
                "Irkutsk": "9,0,36,0,1ia|9,0,39,0,1ia||",
                "Israel": "9,0,36,0,1ic|9,0,39,0,1ic||",
                "Japan": "9,0,36,0,19,0,1no|9,0,39,0,19,0,1no||",
                "Kamchatka": "9,0,36,0,2,0,37,0,2,0,1np|19v,0,39,0,2,0,37,0,2,0,1np||",
                "Kazakhstan_Eastern": "9,0,2,0,1nl,0,19,0,45|||",
                "Kazakhstan_Western": "9,0,2,0,151,0,19,0,45|||",
                "Korea": "9,0,36,0,2,0,hf|9,0,39,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,36,0,2,0,1c7|9,0,39,0,2,0,1c7||",
                "Kyrgystan": "9,0,19,0,2m4|||",
                "Line_Islands": "9,0,2,0,ac,0,o|||",
                "Lord_Howe": "9,0,36,0,2,0,1u,0,24|19v,0,39,0,2,0,1u,0,24||",
                "Macau": "9,0,36,0,2,0,57|9,0,39,0,2,0,57||",
                "Macquarie": "9,0,2,0,6m|||",
                "Magadan": "9,0,36,0,2,0,2o|9,0,39,0,2,0,2o||",
                "Malaysia": "9,0,2,0,2m6|||",
                "Maldives": "9,0,2,0,et,0,9k|||",
                "Marquesas": "9,0,2,0,et,0,2it|||",
                "Marshall_Islands": "9,0,2,0,et,0,o7,0,7o|||",
                "Mauritius": "9,0,36,0,2,0,1nu|9,0,39,0,2,0,1nu||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,36,0,19,0,93,5,1o0,0,2,0,149|9,0,39,0,19,0,93,5,1o0,0,2,0,149||",
                "Mexico_Pacific": "9,0,36,0,19,0,14b,0,2,0,149|9,0,39,0,19,0,14b,0,2,0,149||",
                "Mongolia": "9,0,36,0,1o2,0,2m|9,0,39,0,1o2,0,2m||",
                "Moscow": "9,0,36,0,2,0,ca|9,0,39,0,2,0,ca||",
                "Myanmar": "9,0,2,0,8v|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,19,0,8u|||",
                "New_Caledonia": "9,0,36,0,2,0,ba,0,1oc|9,0,39,0,2,0,ba,0,1oc||",
                "New_Zealand": "9,0,36,0,2,0,ba,0,go|9,0,39,0,2,0,ba,0,go||",
                "Newfoundland": "9,0,36,0,2,0,h5|9,0,39,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,et,0,o7,0,6l|||",
                "Noronha": "9,0,36,0,2,0,1s,0,2,0,23|9,0,39,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "9,0,36,0,2,0,3b|9,0,39,0,2,0,3b||",
                "Omsk": "9,0,36,0,1d1|9,0,39,0,1d1||",
                "Pakistan": "9,0,36,0,19,0,3i|9,0,39,0,19,0,3i||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,a8|||",
                "Paraguay": "9,0,36,0,19,0,p8|9,0,39,0,19,0,p8||",
                "Peru": "9,0,36,0,19,0,ha|9,0,39,0,19,0,ha||",
                "Philippines": "9,0,36,0,2,0,1jb|9,0,39,0,2,0,1jb||",
                "Phoenix_Islands": "9,0,2,0,et,0,o7,0,90|||",
                "Pierre_Miquelon": "9,0,36,0,2,0,8i,5,33,0,i3,0,35|9,0,39,0,2,0,8i,5,33,0,i3,0,35||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Reunion": "9,0,2,0,2mi|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,36,0,2,0,55|9,0,39,0,2,0,55||",
                "Samara": "9,0,36,0,2,0,29|9,0,39,0,2,0,29||",
                "Samoa": "9,0,36,0,2,0,2c|9,0,39,0,2,0,2c||",
                "Seychelles": "9,0,2,0,et,0,8k|||",
                "Singapore": "9,0,2,0,m8|||",
                "Solomon": "9,0,2,0,2mk|||",
                "South_Georgia": "9,0,2,0,1a0,0,19,0,i6|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "9,0,36,0,2,0,3d|9,0,39,0,2,0,3d||",
                "Tajikistan": "9,0,19,0,lc|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,36,0,2,0,2j|9,0,39,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,36,0,19,0,4t|9,0,39,0,19,0,4t||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,36,0,2,0,1om|9,0,39,0,2,0,1om||",
                "Uzbekistan": "9,0,36,0,2,0,1op|9,0,39,0,2,0,1op||",
                "Vanuatu": "9,0,36,0,2,0,1oq|9,0,39,0,2,0,1oq||",
                "Venezuela": "9,0,2,0,2mr|||",
                "Vladivostok": "9,0,36,0,2,0,28|9,0,39,0,2,0,28||",
                "Volgograd": "9,0,36,0,2,0,3f|9,0,39,0,2,0,3f||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,i3,0,5k|||",
                "Yakutsk": "9,0,36,0,2,0,1h6|9,0,39,0,2,0,1h6||",
                "Yekaterinburg": "9,0,36,0,1os|9,0,39,0,1os||"
            }
        },
        "ce": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c9b,k,0,g7,0,9v||",
                "Europe/London": "|c9c,k,0,g7,0,9v||"
            },
            "metazone": {
                "Afghanistan": "c10,5,c0v|||",
                "Africa_Central": "1ou,0,mi|||",
                "Africa_Eastern": "1h3,0,mi|||",
                "Africa_Southern": "4tg,0,mi|||",
                "Africa_Western": "1h1,0,mi,k,0,gi,0,9v|1h1,0,mi,k,0,g7,0,9v||",
                "Alaska": "12l,k,0,gi,0,9v|12l,k,0,g7,0,9v||",
                "Amazon": "18e,k,0,gi,0,9v|18e,k,0,g7,0,9v||",
                "America_Central": "1ou,0,m6,k,0,gi,0,9v|1ou,0,m6,k,0,g7,0,9v||",
                "America_Eastern": "1h3,0,m6,k,0,gi,0,9v|1h3,0,m6,k,0,g7,0,9v||",
                "America_Mountain": "4u6,0,gi,0,9v,0,4uc|4u6,0,g7,0,9v,0,4uc||",
                "America_Pacific": "4b9,0,4b6,0,gi,0,9v|4b9,0,4b6,0,g7,0,9v||",
                "Apia": "gi,0,9v,0,15s,k,0,p0|g7,0,9v,0,15s,k,0,p0||",
                "Arabian": "4uh,0,4uq,k,0,gi,0,9v|4uh,0,4uq,k,0,g7,0,9v||",
                "Argentina": "hk,k,0,gi,0,9v|hk,k,0,g7,0,9v||",
                "Argentina_Western": "1h1,0,hk,k,0,gi,0,9v|1h1,0,hk,k,0,g7,0,9v||",
                "Armenia": "4us,k,0,gi,0,9v|4us,k,0,g7,0,9v||",
                "Atlantic": "4v9,0,gi,0,9v|4v9,0,g7,0,9v||",
                "Australia_Central": "1ou,0,1qs,k,0,gi,0,9v|1ou,0,1qs,k,0,g7,0,9v||",
                "Australia_CentralWestern": "1ou,0,1qs,k,0,50i,0,gi,0,9v|1ou,0,1qs,k,0,50i,0,g7,0,9v||",
                "Australia_Eastern": "1h3,0,1qs,k,0,gi,0,9v|1h3,0,1qs,k,0,g7,0,9v||",
                "Australia_Western": "1h1,0,1qs,k,0,gi,0,9v|1h1,0,1qs,k,0,g7,0,9v||",
                "Azerbaijan": "1df,k,0,gi,0,9v|1df,k,0,g7,0,9v||",
                "Azores": "50m,0,2je,k,0,gi,0,9v|50m,0,2je,k,0,g7,0,9v||",
                "Bangladesh": "q8,k,0,gi,0,9v|q8,k,0,g7,0,9v||",
                "Bhutan": "16f|||",
                "Bolivia": "c0u|||",
                "Brasilia": "50p,k,0,gi,0,9v|50p,k,0,g7,0,9v||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,gi,0,9v|12p,5,115,k,0,g7,0,9v||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,gi,0,9v|162,k,0,g7,0,9v||",
                "Chile": "150,k,0,gi,0,9v|150,k,0,g7,0,9v||",
                "China": "1dm,k,0,gi,0,9v|1dm,k,0,g7,0,9v||",
                "Choibalsan": "11e,k,0,gi,0,9v|11e,k,0,g7,0,9v||",
                "Christmas": "c0t,0,c0s,0,c0r,0,c0q,0,4i9|||",
                "Cocos": "c0p,k,0,1h0,5,1k3|||",
                "Colombia": "51d,k,0,gi,0,9v|51d,k,0,g7,0,9v||",
                "Cook": "51g,k,0,1h0,5,1k3,k,0,gi,0,9v|51g,k,0,1h0,5,1k3,k,0,g7,0,9v||",
                "Cuba": "ql,k,0,gi,0,9v|ql,k,0,g7,0,9v||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1h3,0,1ki|||",
                "Easter": "51p,0,4i9,k,0,gi,0,9v|51p,0,4i9,k,0,g7,0,9v||",
                "Ecuador": "1qk|||",
                "Europe_Central": "1ou,0,lo,k,0,gi,0,9v|1ou,0,lo,k,0,g7,0,9v||",
                "Europe_Eastern": "1h3,0,lo,k,0,gi,0,9v|1h3,0,lo,k,0,g7,0,9v||",
                "Europe_Further_Eastern": "c0o,0,1lf,k,0,c0n,0,1lf,0,9v|||",
                "Europe_Western": "1h1,0,lo,k,0,gi,0,9v|1h1,0,lo,k,0,g7,0,9v||",
                "Falkland": "524,0,2je,k,0,gi,0,9v|524,0,2je,k,0,g7,0,9v||",
                "Fiji": "161,k,0,gi,0,9v|161,k,0,g7,0,9v||",
                "French_Guiana": "525,0,1pb|||",
                "French_Southern": "525,0,c0m,0,1lf,k,0,c0l,0,1lf,0,9v|||",
                "Galapagos": "c0k,0,2je|||",
                "Gambier": "244|||",
                "Georgia": "52k,k,0,gi,0,9v|52k,k,0,g7,0,9v||",
                "Gilbert_Islands": "c0j,k,0,1h0,5,1k3|||",
                "GMT": "c0i,0,c0h,0,9v|||",
                "Greenland_Eastern": "1h3,0,4b3,k,0,gi,0,9v|1h3,0,4b3,k,0,g7,0,9v||",
                "Greenland_Western": "1h1,0,4b3,k,0,gi,0,9v|1h1,0,4b3,k,0,g7,0,9v||",
                "Gulf": "c0g,0,c0f|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "533,5,536,0,gi,0,9v|533,5,536,0,g7,0,9v||",
                "Hong_Kong": "13t,k,0,gi,0,9v|13t,k,0,g7,0,9v||",
                "Hovd": "qg,k,0,gi,0,9v|qg,k,0,g7,0,9v||",
                "India": "53a|||",
                "Indian_Ocean": "c0e,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "1ou,0,4ia|||",
                "Indonesia_Eastern": "1h3,0,4ia|||",
                "Indonesia_Western": "1h1,0,4ia|||",
                "Iran": "53l,k,0,gi,0,9v|53l,k,0,g7,0,9v||",
                "Irkutsk": "12u,k,0,gi,0,9v|12u,k,0,g7,0,9v||",
                "Israel": "165,k,0,gi,0,9v|165,k,0,g7,0,9v||",
                "Japan": "540,k,0,gi,0,9v|540,k,0,g7,0,9v||",
                "Kazakhstan_Eastern": "1h3,0,180|||",
                "Kazakhstan_Western": "1h1,0,180|||",
                "Korea": "545,k,0,gi,0,9v|545,k,0,g7,0,9v||",
                "Kosrae": "546|||",
                "Krasnoyarsk": "14h,k,0,gi,0,9v|14h,k,0,g7,0,9v||",
                "Kyrgystan": "c0d|||",
                "Line_Islands": "1eo,k,0,1h0,5,1k3|||",
                "Lord_Howe": "oi,5,10a,k,0,gi,0,9v|oi,5,10a,k,0,g7,0,9v||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,gi,0,9v|qq,k,0,g7,0,9v||",
                "Malaysia": "c0c|||",
                "Maldives": "c0b|||",
                "Marquesas": "c0a,k,0,1h0,5,1k3|||",
                "Marshall_Islands": "c09,0,k,0,1h0,5,1k3|||",
                "Mauritius": "554,k,0,gi,0,9v|554,k,0,g7,0,9v||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "55a,0,55g,0,4b0,0,gi,0,9v|55a,0,55g,0,4b0,0,g7,0,9v||",
                "Mexico_Pacific": "4b9,0,4b6,0,4b0,0,gi,0,9v|4b9,0,4b6,0,4b0,0,g7,0,9v||",
                "Mongolia": "or,5,qo,k,0,gi,0,9v|or,5,qo,k,0,g7,0,9v||",
                "Moscow": "r6,k,0,gi,0,9v|r6,k,0,g7,0,9v||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "2ps,0,57t,k,0,gi,0,9v|2ps,0,57t,k,0,g7,0,9v||",
                "New_Zealand": "2ps,0,589,k,0,gi,0,9v|2ps,0,589,k,0,g7,0,9v||",
                "Newfoundland": "14t,k,0,gi,0,9v|14t,k,0,g7,0,9v||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,gi,0,9v|141,5,17m,5,132,k,0,g7,0,9v||",
                "Novosibirsk": "qt,k,0,gi,0,9v|qt,k,0,g7,0,9v||",
                "Omsk": "r5,k,0,gi,0,9v|r5,k,0,g7,0,9v||",
                "Pakistan": "rq,k,0,gi,0,9v|rq,k,0,g7,0,9v||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,2ps,0,c08|||",
                "Paraguay": "13p,k,0,gi,0,9v|13p,k,0,g7,0,9v||",
                "Peru": "q1,k,0,gi,0,9v|q1,k,0,g7,0,9v||",
                "Philippines": "594,k,0,gi,0,9v|594,k,0,g7,0,9v||",
                "Phoenix_Islands": "158,k,0,1h0,5,1k3|||",
                "Pierre_Miquelon": "pr,5,13m,0,1lf,k,0,qp,0,1lf,k,0,gi,0,9v|pr,5,13m,0,1lf,k,0,qp,0,1lf,k,0,g7,0,9v||",
                "Pitcairn": "24j|||",
                "Ponape": "1v2,k,0,1h0,5,1k3|||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,gi,0,9v|r8,k,0,g7,0,9v||",
                "Samoa": "p0,k,0,gi,0,9v|p0,k,0,g7,0,9v||",
                "Seychelles": "c07,0,2je|||",
                "Singapore": "16b|||",
                "Solomon": "c06,k,0,1h0,5,1k3|||",
                "South_Georgia": "4tg,0,c05|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma,k,0,1h0,5,1k3|||",
                "Taipei": "1a9,k,0,gi,0,9v|1a9,k,0,g7,0,9v||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,gi,0,9v|ot,k,0,g7,0,9v||",
                "Truk": "1f2|||",
                "Turkmenistan": "5am,0,gi,0,9v|5am,0,g7,0,9v||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,gi,0,9v|14a,k,0,g7,0,9v||",
                "Uzbekistan": "5b4,0,gi,0,9v|5b4,0,g7,0,9v||",
                "Vanuatu": "os,k,0,gi,0,9v|os,k,0,g7,0,9v||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,gi,0,9v|qn,k,0,g7,0,9v||",
                "Volgograd": "q9,k,0,gi,0,9v|q9,k,0,g7,0,9v||",
                "Vostok": "155|||",
                "Wake": "1m0,k,0,1h0,5,c04|||",
                "Wallis": "1qq,0,1lf,k,0,14l,0,1lf|||",
                "Yakutsk": "14d,k,0,gi,0,9v|14d,k,0,g7,0,9v||",
                "Yekaterinburg": "136,k,0,gi,0,9v|136,k,0,g7,0,9v||"
            }
        },
        "cgg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "chr": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c9d,0,fg,0,8l||",
                "Europe/London": "|c9e,0,mj,0,8l||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Afghanistan": "c03,0,8l|||",
                "Africa_Central": "1kj,0,2qm,0,8l|||",
                "Africa_Eastern": "19t,0,2qm,0,8l|||",
                "Africa_Southern": "5d9,0,2qm,0,fg,0,8l|||",
                "Africa_Western": "14r,0,2qm,0,fg,0,8l|14r,0,2qm,0,mj,0,8l||",
                "Alaska": "5e5,0,fg,0,8l|5e5,0,sg,0,se,0,1kk|18f|18t",
                "Amazon": "5ep,0,fg,0,8l|5ep,0,mj,0,8l||",
                "America_Central": "1kj,0,fg,0,8l|1kj,0,sg,0,se,0,1kk|19q|19p",
                "America_Eastern": "19t,0,18d,0,fg,0,8l|19t,0,18d,0,sg,0,se,0,1kk|19o|19n",
                "America_Mountain": "5fo,0,fg,0,8l|5fo,0,sg,0,se,0,1kk|18r|16i",
                "America_Pacific": "14r,0,fg,0,8l|14r,0,sg,0,se,0,1kk|19l|19k",
                "Apia": "5fv,0,fg,0,8l|5fv,0,sg,0,se,0,169||",
                "Arabian": "5ht,0,fg,0,8l|5ht,0,sg,0,se,0,169||",
                "Argentina": "4au,0,fg,0,8l|4au,0,mj,0,8l||",
                "Argentina_Western": "14r,0,18d,0,4au,0,fg,0,8l|14r,0,18d,0,4au,0,mj,0,fg,0,8l||",
                "Armenia": "5jb,0,fg,0,8l|5jb,0,mj,0,8l||",
                "Atlantic": "19t,0,fg,0,8l|19t,0,sg,0,se,0,1kk|19i|190",
                "Australia_Central": "1kj,0,24o,0,fg,0,8l|1kj,0,24o,0,sg,0,se,0,169||",
                "Australia_CentralWestern": "1kj,0,24o,0,14r,0,18d,0,fg,0,8l|1kj,0,24o,0,14r,0,18d,0,sg,0,se,0,169||",
                "Australia_Eastern": "24o,0,19t,0,18d,0,fg,0,8l|24o,0,19t,0,18d,0,sg,0,se,0,169||",
                "Australia_Western": "24o,0,14r,0,18d,0,fg,0,8l|24o,0,14r,0,18d,0,sg,0,se,0,169||",
                "Azerbaijan": "5jj,0,fg,0,8l|5jj,0,mj,0,8l||",
                "Azores": "5jm,0,fg,0,8l|5jm,0,mj,0,8l||",
                "Bangladesh": "5jn,0,fg,0,8l|5jn,0,mj,0,8l||",
                "Bhutan": "c02,0,8l|||",
                "Bolivia": "c01,0,8l|||",
                "Brasilia": "5js,0,fg,0,8l|5js,0,mj,0,8l||",
                "Brunei": "c00,0,bvv,0,8l|||",
                "Cape_Verde": "5kb,0,5kc,0,fg,0,8l|5kb,0,5kc,0,mj,0,8l||",
                "Chamorro": "bvu,0,fg,0,8l|||",
                "Chatham": "5kg,0,fg,0,8l|5kg,0,sg,0,se,0,169||",
                "Chile": "5kh,0,fg,0,8l|5kh,0,mj,0,8l||",
                "China": "5kj,0,fg,0,8l|5kj,0,sg,0,se,0,169||",
                "Choibalsan": "5kk,0,fg,0,8l|5kk,0,mj,0,8l||",
                "Christmas": "bvt,0,263,0,8l|||",
                "Cocos": "bvs,0,1n9,0,8l|||",
                "Colombia": "5kt,0,fg,0,8l|5kt,0,mj,0,8l||",
                "Cook": "5ku,0,1n9,0,fg,0,8l|5ku,0,1n9,0,1kj,0,mj,0,8l||",
                "Cuba": "5l1,0,fg,0,8l|5l1,0,sg,0,se,0,1kk||",
                "Davis": "bvr,0,8l|||",
                "DumontDUrville": "bvq,5,bvp,0,8l|||",
                "East_Timor": "19t,0,bvo,0,8l|||",
                "Easter": "5lo,0,263,0,fg,0,8l|5lo,0,263,0,mj,0,8l||",
                "Ecuador": "bvn,0,8l|||",
                "Europe_Central": "1kj,0,262,0,fg,0,8l|1kj,0,262,0,mj,0,8l||",
                "Europe_Eastern": "19t,0,18d,0,262,0,fg,0,8l|19t,0,18d,0,262,0,mj,0,8l||",
                "Europe_Further_Eastern": "19t,0,262,0,8l|||",
                "Europe_Western": "14r,0,18d,0,262,0,fg,0,8l|14r,0,18d,0,262,0,mj,0,8l||",
                "Falkland": "5ls,0,1n9,0,fg,0,8l|5ls,0,1n9,0,mj,0,8l||",
                "Fiji": "5lt,0,fg,0,8l|5lt,0,mj,0,8l||",
                "French_Guiana": "bvm,0,bvl,0,8l|||",
                "French_Southern": "bvk,0,bvj,0,4as,0,bvi,0,8l|||",
                "Galapagos": "bvh,0,bvg,0,263,0,8l|||",
                "Gambier": "bvf,0,8l|||",
                "Georgia": "4ib,0,fg,0,8l|4ib,0,mj,0,8l||",
                "Gilbert_Islands": "bve,0,1n9,0,8l|||",
                "GMT": "2fi,0,bvd,0,8l|||",
                "Greenland_Eastern": "19t,0,4aq,0,fg,0,5n5|19t,0,4aq,0,mj,0,8l||",
                "Greenland_Western": "14r,0,4aq,0,fg,0,5n5|14r,0,4aq,0,mj,0,8l||",
                "Gulf": "bvc,0,fg,0,8l|||",
                "Guyana": "bvb,0,8l|||",
                "Hawaii_Aleutian": "5ni,5,5nk,0,fg,0,8l|5ni,5,5nk,0,sg,0,se,0,1kk|17j|17l",
                "Hong_Kong": "5nl,0,5nm,0,fg,0,8l|5nl,0,5nm,0,mj,0,8l||",
                "Hovd": "5nn,0,fg,0,8l|5nn,0,mj,0,8l||",
                "India": "bva,0,fg,0,8l|||",
                "Indian_Ocean": "bv9,0,bv8,0,8l|||",
                "Indochina": "bv7,0,8l|||",
                "Indonesia_Central": "1kj,0,4ic,0,8l|||",
                "Indonesia_Eastern": "19t,0,18d,0,4ic,0,8l|||",
                "Indonesia_Western": "14r,0,18d,0,4ic,0,8l|||",
                "Iran": "5o1,0,fg,0,8l|5o1,0,sg,0,se,0,169||",
                "Irkutsk": "5o2,0,fg,0,8l|5o2,0,mj,0,8l||",
                "Israel": "5o3,0,fg,0,8l|5o3,0,sg,0,se,0,169||",
                "Japan": "5o4,0,fg,0,8l|5o4,0,sg,0,se,0,169||",
                "Kazakhstan_Eastern": "19t,0,5o5,0,8l|||",
                "Kazakhstan_Western": "14r,0,5o5,0,8l|||",
                "Korea": "5o6,0,fg,0,8l|5o6,0,sg,0,se,0,169||",
                "Kosrae": "bv6,0,8l|||",
                "Krasnoyarsk": "5o8,0,fg,0,8l|5o8,0,mj,0,8l||",
                "Kyrgystan": "bv5,0,8l|||",
                "Line_Islands": "bv4,0,1n9,0,8l|||",
                "Lord_Howe": "5ob,0,5oc,0,fg,0,8l|5ob,0,5oc,0,sg,0,se,0,169||",
                "Macquarie": "bv3,0,263,0,8l|||",
                "Magadan": "5oe,0,8l,0,8l|5oe,0,mj,0,8l||",
                "Malaysia": "bv2,0,8l|||",
                "Maldives": "bv1,0,8l|||",
                "Marquesas": "bv0,0,8l|||",
                "Marshall_Islands": "buv,0,1n9,0,8l|||",
                "Mauritius": "5oj,0,fg,0,8l|5oj,0,mj,0,8l||",
                "Mawson": "buu,0,8l|||",
                "Mexico_Northwest": "5ol,0,14r,0,4ao,0,fg,0,8l|5ol,0,14r,0,4ao,0,sg,0,se,0,1kk||",
                "Mexico_Pacific": "4ao,0,14r,0,fg,0,8l|4ao,0,14r,0,sg,0,se,0,1kk||",
                "Mongolia": "5on,0,5oo,0,fg,0,8l|5on,0,5oo,0,mj,0,8l||",
                "Moscow": "5op,0,fg,0,8l|5op,0,mj,0,8l||",
                "Myanmar": "but,0,8l|||",
                "Nauru": "bus,0,8l|||",
                "Nepal": "bur,0,8l|||",
                "New_Caledonia": "2fi,0,5ot,0,fg,0,8l|2fi,0,5ot,0,mj,0,8l||",
                "New_Zealand": "2fi,0,5ou,0,fg,0,8l|2fi,0,5ou,0,sg,0,se,0,169||",
                "Newfoundland": "5ov,0,fg,0,8l|5ov,0,sg,0,se,0,1kk||",
                "Niue": "buq,0,8l|||",
                "Norfolk": "bup,0,263,0,8l|||",
                "Noronha": "5p2,0,5p3,0,5p4,0,fg,0,8l|5p2,0,5p3,0,5p4,0,mj,0,8l||",
                "Novosibirsk": "5p5,0,fg,0,8l|5p5,0,mj,0,8l||",
                "Omsk": "5p6,0,fg,0,8l|5p6,0,mj,0,8l||",
                "Pakistan": "5p7,0,fg,0,8l|5p7,0,mj,0,8l||",
                "Palau": "buo,0,8l|||",
                "Papua_New_Guinea": "bun,0,2fi,0,bum,0,8l|||",
                "Paraguay": "5pb,0,fg,0,8l|5pb,0,mj,0,8l||",
                "Peru": "5pc,0,fg,0,8l|5pc,0,mj,0,8l||",
                "Philippines": "5pd,0,fg,0,8l|5pd,0,mj,0,8l||",
                "Phoenix_Islands": "bul,0,1n9,0,8l|||",
                "Pierre_Miquelon": "5pf,0,5pg,0,4as,0,5pi,0,fg,0,8l|5pf,0,5pg,0,4as,0,5pi,0,sg,0,se,0,169||",
                "Pitcairn": "buk,0,8l|||",
                "Ponape": "buj,0,8l|||",
                "Pyongyang": "bui,0,8l|||",
                "Reunion": "buh,0,8l|||",
                "Rothera": "bug,0,8l|||",
                "Sakhalin": "5pq,0,fg,0,8l|5pq,0,mj,0,8l||",
                "Samoa": "5pr,0,fg,0,8l|5pr,0,sg,0,se,0,169||",
                "Seychelles": "buf,0,8l|||",
                "Singapore": "bue,0,fg,0,8l|||",
                "Solomon": "bud,0,1n9,0,8l|||",
                "South_Georgia": "5d9,0,4ib,0,8l|||",
                "Suriname": "buc,0,8l|||",
                "Syowa": "bub,0,8l|||",
                "Tahiti": "bua,0,8l|||",
                "Taipei": "5q7,0,fg,0,8l|5q7,0,sg,0,se,0,169||",
                "Tajikistan": "bu9,0,8l|||",
                "Tokelau": "bu8,0,8l|||",
                "Tonga": "5qa,0,fg,0,8l|5qa,0,mj,0,8l||",
                "Truk": "bu7,0,8l|||",
                "Turkmenistan": "5qd,0,fg,0,8l|5qd,0,mj,0,8l||",
                "Tuvalu": "bu6,0,8l|||",
                "Uruguay": "5qk,0,fg,0,8l|5qk,0,mj,0,8l||",
                "Uzbekistan": "5qm,0,fg,0,8l|5qm,0,mj,0,8l||",
                "Vanuatu": "5qq,0,fg,0,8l|5qq,0,mj,0,8l||",
                "Venezuela": "bu5,0,8l|||",
                "Vladivostok": "5qv,0,8l,0,8l|5qv,0,mj,0,8l||",
                "Volgograd": "5r2,0,fg,0,8l|5r2,0,mj,0,8l||",
                "Vostok": "bu4,0,8l|||",
                "Wake": "bu3,0,263,0,8l|||",
                "Wallis": "bu2,0,4as,0,bu1,0,8l|||",
                "Yakutsk": "5re,0,fg,0,8l|5re,0,mj,0,8l||",
                "Yekaterinburg": "5ri,0,fg,0,8l|5ri,0,mj,0,8l||"
            }
        },
        "ckb-IR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ckb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "cs": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c9f,0,fa,0,11||",
                "Europe/London": "|c9g,0,fa,0,11||"
            },
            "metazone": {
                "Acre": "5rk,0,fe,0,11|5rk,0,fa,0,11||",
                "Afghanistan": "bu0,0,11|||",
                "Africa_Central": "btv,0,11|||",
                "Africa_Eastern": "btu,0,11|||",
                "Africa_Southern": "btt,0,11|||",
                "Africa_Western": "5s1,0,fe,0,11|5s1,0,fa,0,11||",
                "Alaska": "5s3,0,fe,0,11|5s3,0,fa,0,11||",
                "Almaty": "5s8,0,fe,0,11|5s8,0,fa,0,11||",
                "Amazon": "5s9,0,fe,0,11|5s9,0,fa,0,11||",
                "America_Central": "256,0,5sg,0,fe,0,11|256,0,5sg,0,fa,0,11||",
                "America_Eastern": "256,0,5si,0,fe,0,11|256,0,5si,0,fa,0,11||",
                "America_Mountain": "256,0,4a1,0,fe,0,11|256,0,4a1,0,fa,0,11||",
                "America_Pacific": "256,0,49s,0,fe,0,11|256,0,49s,0,fa,0,11||",
                "Anadyr": "49r,0,fe,0,11|49r,0,fa,0,11||",
                "Apia": "5sr,0,fe,0,11|5sr,0,fa,0,11||",
                "Aqtau": "5st,0,fe,0,11|5st,0,fa,0,11||",
                "Aqtobe": "5t1,0,fe,0,11|5t1,0,fa,0,11||",
                "Arabian": "5t6,0,fe,0,11|5t6,0,fa,0,11||",
                "Argentina": "5t8,0,fe,0,11|5t8,0,fa,0,11||",
                "Argentina_Western": "5tf,0,fe,0,11|5tf,0,fa,0,11||",
                "Armenia": "5tm,0,fe,0,11|5tm,0,fa,0,11||",
                "Atlantic": "5tn,0,fe,0,11|5tn,0,fa,0,11||",
                "Australia_Central": "5u0,0,fe,0,11|5u0,0,fa,0,11||",
                "Australia_CentralWestern": "5u2,0,5u3,0,fe,0,11|5u2,0,5u3,0,fa,0,11||",
                "Australia_Eastern": "5u4,0,fe,0,11|5u4,0,fa,0,11||",
                "Australia_Western": "5u5,0,fe,0,11|5u5,0,fa,0,11||",
                "Azerbaijan": "5u6,0,fe,0,11|5u6,0,fa,0,11||",
                "Azores": "5u7,0,fe,0,11|5u7,0,fa,0,11||",
                "Bangladesh": "5u8,0,fe,0,11|5u8,0,fa,0,11||",
                "Bhutan": "bts,0,11|||",
                "Bolivia": "btr,0,11|||",
                "Brasilia": "5uc,0,fe,0,11|5uc,0,fa,0,11||",
                "Brunei": "btq,0,11|||",
                "Cape_Verde": "5uf,0,fe,0,11|5uf,0,fa,0,11||",
                "Casey": "135,0,btp,0,10j|||",
                "Chamorro": "bto,0,11|||",
                "Chatham": "5v2,0,fe,0,11|5v2,0,fa,0,11||",
                "Chile": "5v7,0,fe,0,11|5v7,0,fa,0,11||",
                "China": "5v8,0,fe,0,11|5v8,0,fa,0,11||",
                "Choibalsan": "5va,0,fe,0,11|5va,0,fa,0,11||",
                "Christmas": "135,0,btn,0,19g|||",
                "Cocos": "135,0,5ve,0,1pf|||",
                "Colombia": "5vh,0,fe,0,11|5vh,0,fa,0,11||",
                "Cook": "2uh,0,11,0,49j,0,1pf|49i,0,11,0,49j,0,1pf||",
                "Cuba": "5vm,0,fe,0,11|5vm,0,fa,0,11||",
                "Davis": "135,0,btm,0,10j|||",
                "DumontDUrville": "135,0,10j,0,5vo,0,btl|||",
                "East_Timor": "btk,0,11|||",
                "Easter": "2uh,0,11,0,5vr,0,19g|49i,0,11,0,5vr,0,19g||",
                "Ecuador": "btj,0,11|||",
                "Europe_Central": "5vt,0,fe,0,11|5vt,0,fa,0,11|5vu|606",
                "Europe_Eastern": "60o,0,fe,0,11|60o,0,fa,0,11||",
                "Europe_Further_Eastern": "bti,0,11|||",
                "Europe_Western": "60t,0,fe,0,11|60t,0,fa,0,11||",
                "Falkland": "60u,0,fe,0,11|60u,0,fa,0,11||",
                "Fiji": "610,0,fe,0,11|610,0,fa,0,11||",
                "French_Guiana": "bth,0,11|||",
                "French_Southern": "135,0,btg,0,btf,0,a6,0,61p,0,61s|||",
                "Galapagos": "bte,0,11|||",
                "Gambier": "btd,0,11|||",
                "Georgia": "62b,0,fe,0,11|62b,0,fa,0,11||",
                "Gilbert_Islands": "135,0,62i,0,1pf|||",
                "GMT": "btc,0,btb,0,11|||",
                "Greenland_Eastern": "633,0,fe,0,11|633,0,fa,0,11||",
                "Greenland_Western": "634,0,fe,0,11|634,0,fa,0,11||",
                "Guam": "bta,0,11|||",
                "Gulf": "2uh,0,11,0,bt9,0,63a|||",
                "Guyana": "bt8,0,11|||",
                "Hawaii_Aleutian": "1c5,5,48l,0,fe,0,11|1c5,5,48l,0,fa,0,11||",
                "Hong_Kong": "63e,0,fe,0,11|63e,0,fa,0,11||",
                "Hovd": "63f,0,fe,0,11|63f,0,fa,0,11||",
                "India": "bt7,0,11|||",
                "Indian_Ocean": "bt6,0,11|||",
                "Indochina": "bt5,0,11|||",
                "Indonesia_Central": "bt4,0,11|||",
                "Indonesia_Eastern": "bt3,0,11|||",
                "Indonesia_Western": "bt2,0,11|||",
                "Iran": "63o,0,fe,0,11|63o,0,fa,0,11||",
                "Irkutsk": "63p,0,fe,0,11|63p,0,fa,0,11||",
                "Israel": "63r,0,fe,0,11|63r,0,fa,0,11||",
                "Japan": "63s,0,fe,0,11|63s,0,fa,0,11||",
                "Kamchatka": "1gd,5,63v,0,fe,0,11|1gd,5,63v,0,fa,0,11||",
                "Kazakhstan_Eastern": "bt1,0,11|||",
                "Kazakhstan_Western": "bt0,0,11|||",
                "Korea": "647,0,fe,0,11|647,0,fa,0,11||",
                "Kosrae": "bsv,0,11|||",
                "Krasnoyarsk": "64f,0,fe,0,11|64f,0,fa,0,11||",
                "Kyrgystan": "bsu,0,11|||",
                "Lanka": "bst,0,11|||",
                "Line_Islands": "135,0,64l,0,1pf|||",
                "Lord_Howe": "2uh,0,11,0,19g,0,48a,0,489|49i,0,11,0,19g,0,48a,0,489||",
                "Macau": "64q,0,fe,0,11|64q,0,fa,0,11||",
                "Macquarie": "135,0,19g,0,6m|||",
                "Magadan": "64s,0,fe,0,11|64s,0,fa,0,11||",
                "Malaysia": "bss,0,11|||",
                "Maldives": "bsr,0,11|||",
                "Marquesas": "bsq,0,11|||",
                "Marshall_Islands": "135,0,65h,0,1pf|||",
                "Mauritius": "65j,0,fe,0,11|65j,0,fa,0,11||",
                "Mawson": "135,0,bsp,0,10j|||",
                "Mexico_Northwest": "65t,0,2d6,0,fe,0,11|65t,0,2d6,0,fa,0,11||",
                "Mexico_Pacific": "65v,0,49s,0,fe,0,11|65v,0,49s,0,fa,0,11||",
                "Mongolia": "663,0,fe,0,11|663,0,fa,0,11||",
                "Moscow": "665,0,fe,0,11|665,0,fa,0,11||",
                "Myanmar": "bso,0,11|||",
                "Nauru": "bsn,0,11|||",
                "Nepal": "bsm,0,11|||",
                "New_Caledonia": "66m,0,fe,0,11|66m,0,fa,0,11||",
                "New_Zealand": "66n,0,fe,0,11|66n,0,fa,0,11||",
                "Newfoundland": "66o,0,fe,0,11|66o,0,fa,0,11||",
                "Niue": "bsl,0,11|||",
                "Norfolk": "bsk,0,11|||",
                "Noronha": "2uh,0,11,0,66v,0,1s,0,2,0,23|49i,0,11,0,66v,0,1s,0,2,0,23||",
                "North_Mariana": "bsj,0,11|||",
                "Novosibirsk": "671,0,fe,0,11|671,0,fa,0,11||",
                "Omsk": "675,0,fe,0,11|675,0,fa,0,11||",
                "Pakistan": "676,0,fe,0,11|676,0,fa,0,11||",
                "Palau": "bsi,0,11|||",
                "Papua_New_Guinea": "135,0,678,5,bsh,0,67f|||",
                "Paraguay": "67h,0,fe,0,11|67h,0,fa,0,11||",
                "Peru": "67m,0,fe,0,11|67m,0,fa,0,11||",
                "Philippines": "67r,0,fe,0,11|67r,0,fa,0,11||",
                "Phoenix_Islands": "135,0,67s,0,1pf|||",
                "Pierre_Miquelon": "33,5,47t,0,fe,0,11|33,5,47t,0,fa,0,11||",
                "Pitcairn": "135,0,bsg,0,19g|||",
                "Ponape": "bsf,0,11|||",
                "Pyongyang": "bse,0,11|||",
                "Qyzylorda": "686,0,fe,0,11|686,0,fa,0,11||",
                "Reunion": "bsd,0,11|||",
                "Rothera": "135,0,bsc,0,10j|||",
                "Sakhalin": "689,0,fe,0,11|689,0,fa,0,11||",
                "Samara": "47p,0,fe,0,11|47p,0,fa,0,11||",
                "Samoa": "68d,0,fe,0,11|68d,0,fa,0,11||",
                "Seychelles": "bsb,0,11|||",
                "Singapore": "bsa,0,11|||",
                "Solomon": "135,0,bs9,0,1pf|||",
                "South_Georgia": "135,0,bs8,0,68o|||",
                "Suriname": "bs7,0,11|||",
                "Syowa": "135,0,10j,0,68s|||",
                "Tahiti": "bs6,0,11|||",
                "Taipei": "68v,0,fe,0,11|68v,0,fa,0,11||",
                "Tajikistan": "bs5,0,11|||",
                "Tokelau": "bs4,0,11|||",
                "Tonga": "69c,0,fe,0,11|69c,0,fa,0,11||",
                "Truk": "bs3,0,11|||",
                "Turkmenistan": "69j,0,fe,0,11|69j,0,fa,0,11||",
                "Tuvalu": "bs2,0,11|||",
                "Uruguay": "69p,0,fe,0,11|69p,0,fa,0,11||",
                "Uzbekistan": "69r,0,fe,0,11|69r,0,fa,0,11||",
                "Vanuatu": "69t,0,fe,0,11|69t,0,fa,0,11||",
                "Venezuela": "bs1,0,11|||",
                "Vladivostok": "69v,0,fe,0,11|69v,0,fa,0,11||",
                "Volgograd": "6a1,0,fe,0,11|6a1,0,fa,0,11||",
                "Vostok": "135,0,10j,0,5t|||",
                "Wake": "135,0,19g,0,5r|||",
                "Wallis": "135,0,1pf,0,5f,0,a6,0,5k|||",
                "Yakutsk": "6a2,0,fe,0,11|6a2,0,fa,0,11||",
                "Yekaterinburg": "6a4,0,fe,0,11|6a4,0,fa,0,11||"
            }
        },
        "cu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "cy": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|7h,0,g6,0,c9h||",
                "Europe/London": "|7h,0,g6,0,c9i||"
            },
            "metazone": {
                "Afghanistan": "7h,0,b6|||",
                "Africa_Central": "7h,0,1mg,0,30n|||",
                "Africa_Eastern": "7h,0,1ep,0,30n|||",
                "Africa_Southern": "7h,0,g1,0,47f,0,30n|||",
                "Africa_Western": "7h,0,g1,0,1au,0,30n|7h,0,g6,0,1au,0,30n||",
                "Alaska": "7h,0,g1,0,3p|7h,0,g6,0,3p||",
                "Amazon": "7h,0,g1,0,bg|7h,0,g6,0,bg||",
                "America_Central": "7h,0,g1,0,1mg,0,1mm,0,14n|7h,0,g6,0,1mg,0,1mm,0,14n||",
                "America_Eastern": "7h,0,g1,0,1ep,0,1mm,0,14n|7h,0,g6,0,1ep,0,1mm,0,14n||",
                "America_Mountain": "7h,0,g1,0,6al,0,1mm,0,14n|7h,0,g6,0,6al,0,1mm,0,14n||",
                "America_Pacific": "7h,0,g1,0,312,0,6av,0,1mm,0,14n|7h,0,g6,0,312,0,6av,0,1mm,0,14n||",
                "Apia": "7h,0,g1,0,3e|7h,0,g6,0,3e||",
                "Arabian": "7h,0,g1,0,6b7|7h,0,g6,0,6b7||",
                "Argentina": "7h,0,g1,0,47e|7h,0,g6,0,47e||",
                "Argentina_Western": "7h,0,g1,0,1au,0,47e|7h,0,g6,0,1au,0,47e||",
                "Armenia": "7h,0,g1,0,4l|7h,0,g6,0,4l||",
                "Atlantic": "7h,0,g1,0,312,0,1mp,0,6bd|7h,0,g6,0,312,0,1mp,0,6bd||",
                "Australia_Central": "7h,0,g1,0,1mg,0,257|7h,0,g6,0,1mg,0,257||",
                "Australia_CentralWestern": "7h,0,g1,0,1mg,0,1au,0,257|7h,0,g6,0,1mg,0,1au,0,257||",
                "Australia_Eastern": "7h,0,g1,0,1ep,0,257|7h,0,g6,0,1ep,0,257||",
                "Australia_Western": "7h,0,g1,0,1au,0,257|7h,0,g6,0,1au,0,257||",
                "Azerbaijan": "7h,0,g1,0,6bk|7h,0,g6,0,6bk||",
                "Azores": "7h,0,g1,0,1mp,0,4r|7h,0,g6,0,1mp,0,4r||",
                "Bangladesh": "7h,0,g1,0,3n|7h,0,g6,0,3n||",
                "Bhutan": "7h,0,as|||",
                "Bolivia": "7h,0,aa|||",
                "Brasilia": "7h,0,g1,0,in|7h,0,g6,0,in||",
                "Brunei": "7h,0,9o,0,a4|||",
                "Cape_Verde": "7h,0,g1,0,ck,0,3j|7h,0,g6,0,ck,0,3j||",
                "Chamorro": "7h,0,6j|||",
                "Chatham": "7h,0,g1,0,27|7h,0,g6,0,27||",
                "Chile": "7h,0,g1,0,49|7h,0,g6,0,49||",
                "China": "7h,0,g1,0,4ie|7h,0,g6,0,4ie||",
                "Choibalsan": "7h,0,g1,0,2q|7h,0,g6,0,2q||",
                "Christmas": "7h,0,1mv,0,6c7,0,bs0|||",
                "Cocos": "7h,0,1n1,0,7v|||",
                "Colombia": "7h,0,g1,0,4m|7h,0,g6,0,4m||",
                "Cook": "7h,0,g1,0,1n1,0,2v|7h,0,brv,0,g6,0,1n1,0,2v||",
                "Cuba": "7h,0,g1,0,34|7h,0,g6,0,34||",
                "Davis": "7h,0,5s|||",
                "DumontDUrville": "7h,0,5b,5,5j|||",
                "East_Timor": "7h,0,1ep,0,7n|||",
                "Easter": "7h,0,g1,0,1mv,0,bk,0,6cm|7h,0,g6,0,1mv,0,bk,0,6cm||",
                "Ecuador": "7h,0,ai|||",
                "Europe_Central": "7h,0,g1,0,1mg,0,288|7h,0,g6,0,1mg,0,288|h0|h4",
                "Europe_Eastern": "7h,0,g1,0,1ep,0,288|7h,0,g6,0,1ep,0,288|gu|gv",
                "Europe_Further_Eastern": "7h,0,1ep,0,bru,0,288|||",
                "Europe_Western": "7h,0,g1,0,1au,0,288|7h,0,g6,0,1au,0,288|h1|h2",
                "Falkland": "7h,0,g1,0,1n1,0,6d2|7h,0,g6,0,1n1,0,6d2||",
                "Fiji": "7h,0,g1,0,4q|7h,0,g6,0,4q||",
                "French_Guiana": "7h,0,n1,0,brt|||",
                "French_Southern": "7h,0,brs,0,brr,0,brq,0,brp|||",
                "Galapagos": "7h,0,ak|||",
                "Gambier": "7h,0,6v|||",
                "Georgia": "7h,0,g1,0,2u|7h,0,g6,0,2u||",
                "Gilbert_Islands": "7h,0,1n1,0,6q|||",
                "GMT": "7h,0,g1,0,5u||ah|",
                "Greenland_Eastern": "7h,0,g1,0,1ep,0,1mp,0,1mv,0,475|7h,0,g6,0,1ep,0,1mp,0,1mv,0,475||",
                "Greenland_Western": "7h,0,g1,0,1au,0,1mp,0,1mv,0,475|7h,0,g6,0,1au,0,1mp,0,1mv,0,475||",
                "Gulf": "7h,0,g1,0,bk,0,bro|||",
                "Guyana": "7h,0,7a|||",
                "Hawaii_Aleutian": "7h,0,g1,0,4g,5,62|7h,0,g6,0,4g,5,62||",
                "Hong_Kong": "7h,0,g1,0,2l,0,2p|7h,0,g6,0,2l,0,2p||",
                "Hovd": "7h,0,g1,0,2g|7h,0,g6,0,2g||",
                "India": "7h,0,af|||",
                "Indian_Ocean": "7h,0,312,0,af|||",
                "Indochina": "7h,0,brn,5,4ie|||",
                "Indonesia_Central": "7h,0,1mg,0,2t|||",
                "Indonesia_Eastern": "7h,0,1ep,0,2t|||",
                "Indonesia_Western": "7h,0,1au,0,2t|||",
                "Iran": "7h,0,g1,0,5g|7h,0,g6,0,5g||",
                "Irkutsk": "7h,0,g1,0,3h|7h,0,g6,0,3h||",
                "Israel": "7h,0,g1,0,4e|7h,0,g6,0,4e||",
                "Japan": "7h,0,g1,0,6eb|7h,0,g6,0,6eb||",
                "Kazakhstan_Eastern": "7h,0,1ep,0,4if|||",
                "Kazakhstan_Western": "7h,0,1au,0,4if|||",
                "Korea": "7h,0,g1,0,hf|7h,0,g6,0,hf||",
                "Kosrae": "7h,0,6f|||",
                "Krasnoyarsk": "7h,0,g1,0,41|7h,0,g6,0,41||",
                "Kyrgystan": "7h,0,4if|||",
                "Line_Islands": "7h,0,1n1,0,ac|||",
                "Lord_Howe": "7h,0,g1,0,1mp,0,6ee,0,24|7h,0,g6,0,1mp,0,6ee,0,24||",
                "Macquarie": "7h,0,1mv,0,6m|||",
                "Magadan": "7h,0,g1,0,2o|7h,0,g6,0,2o||",
                "Malaysia": "7h,0,bf|||",
                "Maldives": "7h,0,6c7,0,9k|||",
                "Marquesas": "7h,0,n3|||",
                "Marshall_Islands": "7h,0,1n1,0,7o|||",
                "Mauritius": "7h,0,g1,0,4u|7h,0,g6,0,4u||",
                "Mawson": "7h,0,63|||",
                "Mexico_Northwest": "7h,0,g1,0,1mm,0,6ek,0,472|7h,0,g6,0,1mm,0,6ek,0,472||",
                "Mexico_Pacific": "7h,0,g1,0,6f5,0,472|7h,0,g6,0,6f5,0,472||",
                "Mongolia": "7h,0,g1,0,4f,0,2m|7h,0,g6,0,4f,0,2m||",
                "Moscow": "7h,0,g1,0,6fd|7h,0,g6,0,6fd||",
                "Myanmar": "7h,0,8v|||",
                "Nauru": "7h,0,5v|||",
                "Nepal": "7h,0,8u|||",
                "New_Caledonia": "7h,0,g1,0,4o,0,2lp|7h,0,g6,0,4o,0,2lp||",
                "New_Zealand": "7h,0,g1,0,6ff,0,2lp|7h,0,g6,0,6ff,0,2lp||",
                "Newfoundland": "7h,0,g1,0,53|7h,0,g6,0,53||",
                "Niue": "7h,0,8g|||",
                "Norfolk": "7h,0,1mv,0,6l|||",
                "Noronha": "7h,0,g1,0,1s,0,2,0,23|7h,0,g6,0,1s,0,2,0,23||",
                "Novosibirsk": "7h,0,g1,0,3b|7h,0,g6,0,3b||",
                "Omsk": "7h,0,g1,0,26|7h,0,g6,0,26||",
                "Pakistan": "7h,0,g1,0,3i|7h,0,g6,0,3i||",
                "Palau": "7h,0,9b|||",
                "Papua_New_Guinea": "7h,0,9a,0,a8,0,2lp|||",
                "Paraguay": "7h,0,g1,0,3c|7h,0,g6,0,3c||",
                "Peru": "7h,0,g1,0,6fh|7h,0,g6,0,6fh||",
                "Philippines": "7h,0,g1,0,471|7h,0,g6,0,471||",
                "Phoenix_Islands": "7h,0,1n1,0,90|||",
                "Pierre_Miquelon": "7h,0,g1,0,8i,5,33,5,8p,5,35|7h,0,g6,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "7h,0,7e|||",
                "Ponape": "7h,0,e5|||",
                "Pyongyang": "7h,0,8a|||",
                "Reunion": "7h,0,i8|||",
                "Rothera": "7h,0,5q|||",
                "Sakhalin": "7h,0,g1,0,55|7h,0,g6,0,55||",
                "Samoa": "7h,0,g1,0,2c|7h,0,g6,0,2c||",
                "Seychelles": "7h,0,8k|||",
                "Singapore": "7h,0,bi|||",
                "Solomon": "7h,0,1n1,0,b5|||",
                "South_Georgia": "7h,0,47f,0,2u|||",
                "Suriname": "7h,0,8q|||",
                "Syowa": "7h,0,65|||",
                "Tahiti": "7h,0,8n|||",
                "Taipei": "7h,0,g1,0,3d|7h,0,g6,0,3d||",
                "Tajikistan": "7h,0,brm|||",
                "Tokelau": "7h,0,5d|||",
                "Tonga": "7h,0,g1,0,2j|7h,0,g6,0,2j||",
                "Truk": "7h,0,6p|||",
                "Turkmenistan": "7h,0,g1,0,6fu|7h,0,g6,0,6fu||",
                "Tuvalu": "7h,0,6h|||",
                "Uruguay": "7h,0,g1,0,4k|7h,0,g6,0,4k||",
                "Uzbekistan": "7h,0,g1,0,6fv|7h,0,g6,0,6fv||",
                "Vanuatu": "7h,0,g1,0,2f|7h,0,g6,0,2f||",
                "Venezuela": "7h,0,7s|||",
                "Vladivostok": "7h,0,g1,0,28|7h,0,g6,0,28||",
                "Volgograd": "7h,0,g1,0,3f|7h,0,g6,0,3f||",
                "Vostok": "7h,0,5t|||",
                "Wake": "7h,0,1mv,0,5r|||",
                "Wallis": "7h,0,5f,0,a6,0,5k|||",
                "Yakutsk": "7h,0,g1,0,3u|7h,0,g6,0,3u||",
                "Yekaterinburg": "7h,0,g1,0,5l|7h,0,g6,0,5l||"
            }
        },
        "da-GL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6om,0,1l||",
                "Europe/London": "|6np,0,3t||"
            },
            "metazone": {
                "Acre": "3q,5,1l|3q,5,3t||",
                "Afghanistan": "6g3,0,4j|||",
                "Africa_Central": "6g7,0,4j|||",
                "Africa_Eastern": "6g8,0,4j|||",
                "Africa_Southern": "6g9,0,4j|||",
                "Africa_Western": "470,0,1l|470,0,3t||",
                "Alaska": "3p,5,1l|3p,5,3t||",
                "Almaty": "56,5,1l|56,5,3t||",
                "Amazon": "bg,5,1l|bg,5,3t||",
                "America_Central": "n,5,1l|n,5,3t||",
                "America_Eastern": "17,5,1l|17,5,3t||",
                "America_Mountain": "5a,5,1l|5a,5,3t||",
                "America_Pacific": "2h,5,1l|2h,5,3t||",
                "Anadyr": "3l,5,1l|3l,5,3t||",
                "Apia": "3e,5,1l|3e,5,3t||",
                "Aqtau": "50,5,1l|50,5,3t||",
                "Aqtobe": "5o,5,1l|5o,5,3t||",
                "Arabian": "24b,0,1l|24b,0,3t||",
                "Argentina": "46v,0,1l|46v,0,3t||",
                "Argentina_Western": "46u,0,1l|46u,0,3t||",
                "Armenia": "46t,0,1l|46t,0,3t||",
                "Atlantic": "6g,5,1l|6g,5,3t||",
                "Australia_Central": "46s,0,1l|46s,0,3t||",
                "Australia_CentralWestern": "46r,0,46q,0,1l|46r,0,46q,0,3t||",
                "Australia_Eastern": "46p,0,1l|46p,0,3t||",
                "Australia_Western": "46o,0,1l|46o,0,3t||",
                "Azerbaijan": "46l,0,1l|46l,0,3t||",
                "Azores": "46k,5,1l|46k,5,3t||",
                "Bangladesh": "46j,0,1l|46j,0,3t||",
                "Bhutan": "6h5,0,4j|||",
                "Bolivia": "6h6,0,4j|||",
                "Brasilia": "46i,0,1l|46i,0,3t||",
                "Brunei": "9o,0,a4,5,4j|||",
                "Cape_Verde": "46h,0,1l|46h,0,3t||",
                "Chamorro": "6j,5,4j|||",
                "Chatham": "27,5,1l|27,5,3t||",
                "Chile": "46g,0,1l|46g,0,3t||",
                "China": "46d,0,1l|46d,0,3t||",
                "Choibalsan": "2q,5,1l|2q,5,3t||",
                "Christmas": "99,0,1f,5,1l|||",
                "Cocos": "6i2,5,1l|||",
                "Colombia": "46c,0,1l|46c,0,3t||",
                "Cook": "46b,5,1l|46b,5,3t||",
                "Cuba": "46a,0,1l|46a,0,3t||",
                "Davis": "5s,5,4j|||",
                "DumontDUrville": "5b,5,5j,5,4j|||",
                "East_Timor": "6if,5,4j|||",
                "Easter": "469,5,1l|469,5,3t||",
                "Ecuador": "6iv,0,4j|||",
                "Europe_Central": "468,0,1l|468,0,3t|h0|h4",
                "Europe_Eastern": "467,0,1l|467,0,3t|gu|gv",
                "Europe_Further_Eastern": "6j9,0,4j|||",
                "Europe_Western": "466,0,1l|466,0,3t|h1|h2",
                "Falkland": "465,5,1l|465,5,3t||",
                "Fiji": "464,0,1l|464,0,3t||",
                "French_Guiana": "463,0,7a,5,4j|||",
                "French_Southern": "6jo,0,6jp,0,u0,0,6jt,0,6jv,5,4j|||",
                "Galapagos": "ak,5,4j|||",
                "Gambier": "6v,5,4j|||",
                "Georgia": "461,0,1l|461,0,3t||",
                "Gilbert_Islands": "6ln,5,4j|||",
                "GMT": "ah|||",
                "Greenland_Eastern": "460,0,1l|460,0,3t||",
                "Greenland_Western": "45s,0,1l|45s,0,3t||",
                "Guam": "83,5,1l|||",
                "Gulf": "6ma,5,1l|||",
                "Guyana": "7a,5,4j|||",
                "Hawaii_Aleutian": "4g,5,62,5,1l|4g,5,62,5,3t||",
                "Hong_Kong": "qh,5,1l|qh,5,3t||",
                "Hovd": "2g,5,1l|2g,5,3t||",
                "India": "6mb,0,1l|||",
                "Indian_Ocean": "6mc,0,bv,5,1l|||",
                "Indochina": "23t,5,4j|||",
                "Indonesia_Central": "6mj,0,4j|||",
                "Indonesia_Eastern": "6mk,0,4j|||",
                "Indonesia_Western": "6ml,0,4j|||",
                "Iran": "45r,0,1l|45r,0,3t||",
                "Irkutsk": "3h,5,1l|3h,5,3t||",
                "Israel": "45q,0,1l|45q,0,3t||",
                "Japan": "45m,0,1l|45m,0,3t||",
                "Kamchatka": "37,5,3k,0,1l|37,5,3k,0,3t||",
                "Kazakhstan_Eastern": "6mp,0,4j|||",
                "Kazakhstan_Western": "6ms,0,4j|||",
                "Korea": "45k,0,1l|45k,0,3t||",
                "Kosrae": "6f,5,4j|||",
                "Krasnoyarsk": "41,5,1l|41,5,3t||",
                "Kyrgystan": "6mu,0,4j|||",
                "Lanka": "6mv,0,4j|||",
                "Line_Islands": "6n0,5,4j|||",
                "Lord_Howe": "1u,0,24,5,1l|1u,0,24,5,3t||",
                "Macau": "ip,5,1l|ip,5,3t||",
                "Macquarie": "6m,5,4j|||",
                "Magadan": "2o,5,1l|2o,5,3t||",
                "Malaysia": "6n2,0,4j|||",
                "Maldives": "6n3,0,4j|||",
                "Marquesas": "9e,5,4j|||",
                "Marshall_Islands": "6n4,5,4j|||",
                "Mauritius": "4u,5,1l|4u,5,3t||",
                "Mawson": "63,5,4j|||",
                "Mexico_Northwest": "45j,0,1l|45j,0,3t||",
                "Mexico_Pacific": "45i,0,2h,5,1l|45i,0,2h,5,3t||",
                "Mongolia": "4f,0,2m,5,1l|4f,0,2m,5,3t||",
                "Moscow": "45h,0,1l|45h,0,3t||",
                "Myanmar": "6n8,0,4j|||",
                "Nauru": "5v,5,4j|||",
                "Nepal": "6n9,0,4j|||",
                "New_Caledonia": "45d,0,1l|45d,0,3t||",
                "New_Zealand": "45c,0,1l|45c,0,3t||",
                "Newfoundland": "45b,0,1l|45b,0,3t||",
                "Niue": "8g,5,4j|||",
                "Norfolk": "6l,0,1f,5,4j|||",
                "Noronha": "1s,0,2,0,23,5,1l|1s,0,2,0,23,5,3t||",
                "North_Mariana": "6ng,5,4j|||",
                "Novosibirsk": "3b,0,1l|3b,0,3t||",
                "Omsk": "26,5,1l|26,5,3t||",
                "Pakistan": "459,0,1l|459,0,3t||",
                "Palau": "9b,5,1l|||",
                "Papua_New_Guinea": "9a,0,6nk,0,a8,5,4j|||",
                "Paraguay": "457,0,1l|457,0,3t||",
                "Peru": "456,0,1l|456,0,3t||",
                "Philippines": "454,0,1l|454,0,3t||",
                "Phoenix_Islands": "6nq,5,4j|||",
                "Pierre_Miquelon": "8i,0,33,5,0,u0,0,35,5,1l|8i,0,33,5,0,u0,0,35,5,3t||",
                "Pitcairn": "7e,5,4j|||",
                "Ponape": "9s,5,4j|||",
                "Pyongyang": "8a,5,4j|||",
                "Qyzylorda": "4d,5,1l|4d,5,3t||",
                "Reunion": "b4,5,4j|||",
                "Rothera": "5q,5,4j|||",
                "Sakhalin": "55,5,1l|55,5,3t||",
                "Samara": "29,5,1l|29,5,3t||",
                "Samoa": "2c,5,1l|2c,5,3t||",
                "Seychelles": "6nr,0,4j|||",
                "Singapore": "6nt,0,1l|||",
                "Solomon": "6o1,5,4j|||",
                "South_Georgia": "85,0,2u,5,4j|||",
                "Suriname": "mg,5,4j|||",
                "Syowa": "65,5,4j|||",
                "Tahiti": "6o4,0,4j|||",
                "Taipei": "3d,5,1l|3d,5,3t||",
                "Tajikistan": "6o6,0,4j|||",
                "Tokelau": "5d,5,4j|||",
                "Tonga": "44v,0,1l|44v,0,3t||",
                "Truk": "6p,5,4j|||",
                "Turkmenistan": "44u,0,1l|44u,0,3t||",
                "Tuvalu": "6h,5,4j|||",
                "Uruguay": "44t,0,1l|44t,0,3t||",
                "Uzbekistan": "44s,0,1l|44s,0,3t||",
                "Vanuatu": "2f,5,1l|2f,5,3t||",
                "Venezuela": "6oh,0,4j|||",
                "Vladivostok": "28,5,1l|28,5,3t||",
                "Volgograd": "3f,5,1l|3f,5,3t||",
                "Vostok": "5t,5,4j|||",
                "Wake": "6ok,5,4j|||",
                "Wallis": "5f,0,u0,0,5k,5,4j|||",
                "Yakutsk": "3u,5,1l|3u,5,3t||",
                "Yekaterinburg": "44r,0,1l|44r,0,3t||"
            }
        },
        "da": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6om,0,1l||",
                "Europe/London": "|6np,0,3t||"
            },
            "metazone": {
                "Acre": "3q,5,1l|3q,5,3t||",
                "Afghanistan": "6g3,0,4j|||",
                "Africa_Central": "6g7,0,4j|||",
                "Africa_Eastern": "6g8,0,4j|||",
                "Africa_Southern": "6g9,0,4j|||",
                "Africa_Western": "470,0,1l|470,0,3t||",
                "Alaska": "3p,5,1l|3p,5,3t||",
                "Almaty": "56,5,1l|56,5,3t||",
                "Amazon": "bg,5,1l|bg,5,3t||",
                "America_Central": "n,5,1l|n,5,3t||",
                "America_Eastern": "17,5,1l|17,5,3t||",
                "America_Mountain": "5a,5,1l|5a,5,3t||",
                "America_Pacific": "2h,5,1l|2h,5,3t||",
                "Anadyr": "3l,5,1l|3l,5,3t||",
                "Apia": "3e,5,1l|3e,5,3t||",
                "Aqtau": "50,5,1l|50,5,3t||",
                "Aqtobe": "5o,5,1l|5o,5,3t||",
                "Arabian": "24b,0,1l|24b,0,3t||",
                "Argentina": "46v,0,1l|46v,0,3t||",
                "Argentina_Western": "46u,0,1l|46u,0,3t||",
                "Armenia": "46t,0,1l|46t,0,3t||",
                "Atlantic": "6g,5,1l|6g,5,3t||",
                "Australia_Central": "46s,0,1l|46s,0,3t||",
                "Australia_CentralWestern": "46r,0,46q,0,1l|46r,0,46q,0,3t||",
                "Australia_Eastern": "46p,0,1l|46p,0,3t||",
                "Australia_Western": "46o,0,1l|46o,0,3t||",
                "Azerbaijan": "46l,0,1l|46l,0,3t||",
                "Azores": "46k,5,1l|46k,5,3t||",
                "Bangladesh": "46j,0,1l|46j,0,3t||",
                "Bhutan": "6h5,0,4j|||",
                "Bolivia": "6h6,0,4j|||",
                "Brasilia": "46i,0,1l|46i,0,3t||",
                "Brunei": "9o,0,a4,5,4j|||",
                "Cape_Verde": "46h,0,1l|46h,0,3t||",
                "Chamorro": "6j,5,4j|||",
                "Chatham": "27,5,1l|27,5,3t||",
                "Chile": "46g,0,1l|46g,0,3t||",
                "China": "46d,0,1l|46d,0,3t||",
                "Choibalsan": "2q,5,1l|2q,5,3t||",
                "Christmas": "99,0,1f,5,1l|||",
                "Cocos": "6i2,5,1l|||",
                "Colombia": "46c,0,1l|46c,0,3t||",
                "Cook": "46b,5,1l|46b,5,3t||",
                "Cuba": "46a,0,1l|46a,0,3t||",
                "Davis": "5s,5,4j|||",
                "DumontDUrville": "5b,5,5j,5,4j|||",
                "East_Timor": "6if,5,4j|||",
                "Easter": "469,5,1l|469,5,3t||",
                "Ecuador": "6iv,0,4j|||",
                "Europe_Central": "468,0,1l|468,0,3t|h0|h4",
                "Europe_Eastern": "467,0,1l|467,0,3t|gu|gv",
                "Europe_Further_Eastern": "6j9,0,4j|||",
                "Europe_Western": "466,0,1l|466,0,3t|h1|h2",
                "Falkland": "465,5,1l|465,5,3t||",
                "Fiji": "464,0,1l|464,0,3t||",
                "French_Guiana": "463,0,7a,5,4j|||",
                "French_Southern": "6jo,0,6jp,0,u0,0,6jt,0,6jv,5,4j|||",
                "Galapagos": "ak,5,4j|||",
                "Gambier": "6v,5,4j|||",
                "Georgia": "461,0,1l|461,0,3t||",
                "Gilbert_Islands": "6ln,5,4j|||",
                "GMT": "ah|||",
                "Greenland_Eastern": "460,0,1l|460,0,3t||",
                "Greenland_Western": "45s,0,1l|45s,0,3t||",
                "Guam": "83,5,1l|||",
                "Gulf": "6ma,5,1l|||",
                "Guyana": "7a,5,4j|||",
                "Hawaii_Aleutian": "4g,5,62,5,1l|4g,5,62,5,3t||",
                "Hong_Kong": "qh,5,1l|qh,5,3t||",
                "Hovd": "2g,5,1l|2g,5,3t||",
                "India": "6mb,0,1l|||",
                "Indian_Ocean": "6mc,0,bv,5,1l|||",
                "Indochina": "23t,5,4j|||",
                "Indonesia_Central": "6mj,0,4j|||",
                "Indonesia_Eastern": "6mk,0,4j|||",
                "Indonesia_Western": "6ml,0,4j|||",
                "Iran": "45r,0,1l|45r,0,3t||",
                "Irkutsk": "3h,5,1l|3h,5,3t||",
                "Israel": "45q,0,1l|45q,0,3t||",
                "Japan": "45m,0,1l|45m,0,3t||",
                "Kamchatka": "37,5,3k,0,1l|37,5,3k,0,3t||",
                "Kazakhstan_Eastern": "6mp,0,4j|||",
                "Kazakhstan_Western": "6ms,0,4j|||",
                "Korea": "45k,0,1l|45k,0,3t||",
                "Kosrae": "6f,5,4j|||",
                "Krasnoyarsk": "41,5,1l|41,5,3t||",
                "Kyrgystan": "6mu,0,4j|||",
                "Lanka": "6mv,0,4j|||",
                "Line_Islands": "6n0,5,4j|||",
                "Lord_Howe": "1u,0,24,5,1l|1u,0,24,5,3t||",
                "Macau": "ip,5,1l|ip,5,3t||",
                "Macquarie": "6m,5,4j|||",
                "Magadan": "2o,5,1l|2o,5,3t||",
                "Malaysia": "6n2,0,4j|||",
                "Maldives": "6n3,0,4j|||",
                "Marquesas": "9e,5,4j|||",
                "Marshall_Islands": "6n4,5,4j|||",
                "Mauritius": "4u,5,1l|4u,5,3t||",
                "Mawson": "63,5,4j|||",
                "Mexico_Northwest": "45j,0,1l|45j,0,3t||",
                "Mexico_Pacific": "45i,0,2h,5,1l|45i,0,2h,5,3t||",
                "Mongolia": "4f,0,2m,5,1l|4f,0,2m,5,3t||",
                "Moscow": "45h,0,1l|45h,0,3t||",
                "Myanmar": "6n8,0,4j|||",
                "Nauru": "5v,5,4j|||",
                "Nepal": "6n9,0,4j|||",
                "New_Caledonia": "45d,0,1l|45d,0,3t||",
                "New_Zealand": "45c,0,1l|45c,0,3t||",
                "Newfoundland": "45b,0,1l|45b,0,3t||",
                "Niue": "8g,5,4j|||",
                "Norfolk": "6l,0,1f,5,4j|||",
                "Noronha": "1s,0,2,0,23,5,1l|1s,0,2,0,23,5,3t||",
                "North_Mariana": "6ng,5,4j|||",
                "Novosibirsk": "3b,0,1l|3b,0,3t||",
                "Omsk": "26,5,1l|26,5,3t||",
                "Pakistan": "459,0,1l|459,0,3t||",
                "Palau": "9b,5,1l|||",
                "Papua_New_Guinea": "9a,0,6nk,0,a8,5,4j|||",
                "Paraguay": "457,0,1l|457,0,3t||",
                "Peru": "456,0,1l|456,0,3t||",
                "Philippines": "454,0,1l|454,0,3t||",
                "Phoenix_Islands": "6nq,5,4j|||",
                "Pierre_Miquelon": "8i,0,33,5,0,u0,0,35,5,1l|8i,0,33,5,0,u0,0,35,5,3t||",
                "Pitcairn": "7e,5,4j|||",
                "Ponape": "9s,5,4j|||",
                "Pyongyang": "8a,5,4j|||",
                "Qyzylorda": "4d,5,1l|4d,5,3t||",
                "Reunion": "b4,5,4j|||",
                "Rothera": "5q,5,4j|||",
                "Sakhalin": "55,5,1l|55,5,3t||",
                "Samara": "29,5,1l|29,5,3t||",
                "Samoa": "2c,5,1l|2c,5,3t||",
                "Seychelles": "6nr,0,4j|||",
                "Singapore": "6nt,0,1l|||",
                "Solomon": "6o1,5,4j|||",
                "South_Georgia": "85,0,2u,5,4j|||",
                "Suriname": "mg,5,4j|||",
                "Syowa": "65,5,4j|||",
                "Tahiti": "6o4,0,4j|||",
                "Taipei": "3d,5,1l|3d,5,3t||",
                "Tajikistan": "6o6,0,4j|||",
                "Tokelau": "5d,5,4j|||",
                "Tonga": "44v,0,1l|44v,0,3t||",
                "Truk": "6p,5,4j|||",
                "Turkmenistan": "44u,0,1l|44u,0,3t||",
                "Tuvalu": "6h,5,4j|||",
                "Uruguay": "44t,0,1l|44t,0,3t||",
                "Uzbekistan": "44s,0,1l|44s,0,3t||",
                "Vanuatu": "2f,5,1l|2f,5,3t||",
                "Venezuela": "6oh,0,4j|||",
                "Vladivostok": "28,5,1l|28,5,3t||",
                "Volgograd": "3f,5,1l|3f,5,3t||",
                "Vostok": "5t,5,4j|||",
                "Wake": "6ok,5,4j|||",
                "Wallis": "5f,0,u0,0,5k,5,4j|||",
                "Yakutsk": "3u,5,1l|3u,5,3t||",
                "Yekaterinburg": "44r,0,1l|44r,0,3t||"
            }
        },
        "dav": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "de-AT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de-BE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de-CH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de-IT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de-LI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de-LU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "de": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2a8,0,1j||",
                "Europe/London": "|2a9,0,1j||"
            },
            "metazone": {
                "Acre": "3q,5,1k|3q,5,1j||",
                "Afghanistan": "b6,5,25|||",
                "Africa_Central": "26u,0,25|||",
                "Africa_Eastern": "26v,0,25|||",
                "Africa_Southern": "270,0,25|||",
                "Africa_Western": "1ac,0,1k|1ac,0,1j||",
                "Alaska": "3p,5,1k|3p,5,1j||",
                "Almaty": "56,5,1k|56,5,1j||",
                "Amazon": "bg,5,1k|bg,5,1j||",
                "America_Central": "on,0,178,5,1k|on,0,178,5,1j||",
                "America_Eastern": "on,0,179,5,1k|on,0,179,5,1j||",
                "America_Mountain": "145,0,5a,5,1k|145,5,5a,5,1j||",
                "America_Pacific": "on,0,17b,5,1k|on,0,17b,5,1j||",
                "Anadyr": "3l,0,1k|3l,0,1j||",
                "Apia": "3e,5,1k|3e,5,1j||",
                "Aqtau": "50,5,1k|50,5,1j||",
                "Aqtobe": "rs,5,1k|rs,5,1j||",
                "Arabian": "t0,0,1k|t0,0,1j||",
                "Argentina": "1ak,0,1k|1ak,0,1j||",
                "Argentina_Western": "1al,0,1k|1al,0,1j||",
                "Armenia": "1am,0,1k|1am,0,1j||",
                "Atlantic": "r2,5,1k|r2,5,1j||",
                "Australia_Central": "1an,0,1k|1an,0,1j||",
                "Australia_CentralWestern": "17e,5,1ap,0,1k|17e,5,1ap,0,1j||",
                "Australia_Eastern": "1aq,0,1k|1aq,0,1j||",
                "Australia_Western": "1ar,0,1k|1ar,0,1j||",
                "Azerbaijan": "271,0,1k|273,0,1j||",
                "Azores": "qv,5,1k|qv,5,1j||",
                "Bangladesh": "17i,5,1k|17i,5,1j||",
                "Bhutan": "as,5,25|||",
                "Bolivia": "275,0,25|||",
                "Brasilia": "in,5,1k|in,5,1j||",
                "Brunei": "9o,5,25|||",
                "Cape_Verde": "ck,5,3j,5,1k|ck,5,3j,5,1j||",
                "Casey": "b7,5,25|||",
                "Chamorro": "6j,5,25|||",
                "Chatham": "27,5,1k|27,5,1j||",
                "Chile": "1b1,0,1k|1b1,0,1j||",
                "China": "1b2,0,1k|1b2,0,1j||",
                "Choibalsan": "1b3,5,1k|1b3,5,1j||",
                "Christmas": "278,5,25|||",
                "Cocos": "279,5,25|||",
                "Colombia": "1b6,0,1k|1b6,0,1j||",
                "Cook": "1b7,5,1k|1b7,5,1j||",
                "Cuba": "1b8,0,1k|1b8,0,1j||",
                "Davis": "5s,5,25|||",
                "DumontDUrville": "5b,5,5j,5,25|||",
                "East_Timor": "22p,5,25|||",
                "Easter": "1ba,5,1k|1ba,5,1j||",
                "Ecuador": "27b,0,25|||",
                "Europe_Central": "1bc,0,1k|1bc,0,1j|1bd|1be",
                "Europe_Eastern": "1bf,0,1k|1bf,0,1j|1bg|1bh",
                "Europe_Further_Eastern": "27c,0,25|||",
                "Europe_Western": "1bj,0,1k|1bj,0,1j|1bk|1bl",
                "Falkland": "1bm,5,1k|1bm,5,1j||",
                "Fiji": "17p,5,1k|17p,5,1j||",
                "French_Guiana": "27d,5,qk,5,25|||",
                "French_Southern": "27e,0,22k,5,0,sv,0,27f,5,25|||",
                "Galapagos": "ak,5,25|||",
                "Gambier": "6v,5,25|||",
                "Georgia": "qb,0,1k|qb,0,1j||",
                "Gilbert_Islands": "6q,5,27g,5,25|||",
                "GMT": "27h,0,5u,5,25|||",
                "Greenland_Eastern": "183,5,1k|183,5,1j||",
                "Greenland_Western": "184,5,1k|184,5,1j||",
                "Guam": "83,5,25|||",
                "Gulf": "127,5,25|||",
                "Guyana": "7a,5,25|||",
                "Hawaii_Aleutian": "4g,5,185,5,1k|4g,5,185,5,1j||",
                "Hong_Kong": "qh,5,1k|qh,5,1j||",
                "Hovd": "1c4,5,1k|1c4,5,1j||",
                "India": "193,0,25|||",
                "Indian_Ocean": "27k,0,21v,5,25|||",
                "Indochina": "9p,5,25|||",
                "Indonesia_Central": "27l,0,25|||",
                "Indonesia_Eastern": "27m,0,25|||",
                "Indonesia_Western": "27n,0,25|||",
                "Iran": "1cb,0,1k|1cb,0,1j||",
                "Irkutsk": "3h,5,1k|3h,5,1j||",
                "Israel": "1cc,0,1k|1cc,0,1j||",
                "Japan": "1cd,0,1k|1cd,0,1j||",
                "Kamchatka": "18c,5,1k|18c,5,1j||",
                "Kazakhstan_Eastern": "27o,0,25|||",
                "Kazakhstan_Western": "27q,0,25|||",
                "Korea": "1ch,0,1k|1ch,0,1j||",
                "Kosrae": "6f,5,25|||",
                "Krasnoyarsk": "l0,5,1k|l0,5,1j||",
                "Kyrgystan": "21g,5,25|||",
                "Lanka": "me,5,9c,5,25|||",
                "Line_Islands": "27r,5,25|||",
                "Lord_Howe": "1u,5,24,5,1k|1u,5,24,5,1j||",
                "Macau": "57,5,1k|57,5,1j||",
                "Macquarie": "214,5,25|||",
                "Magadan": "2o,5,1k|2o,5,1j||",
                "Malaysia": "27u,0,25|||",
                "Maldives": "281,5,25|||",
                "Marquesas": "9e,5,25|||",
                "Marshall_Islands": "283,5,25|||",
                "Mauritius": "4u,5,1k|4u,5,1j||",
                "Mawson": "63,5,25|||",
                "Mexico_Northwest": "ro,0,1cn,0,1co,5,1k|ro,0,1cn,0,1co,5,1j||",
                "Mexico_Pacific": "ro,0,1cp,5,1k|ro,0,1cp,5,1j||",
                "Mongolia": "p2,5,1k|p2,5,1j||",
                "Moscow": "131,0,1k|131,0,1j||",
                "Myanmar": "8v,5,25|||",
                "Nauru": "5v,5,25|||",
                "Nepal": "284,0,25|||",
                "New_Caledonia": "1cs,0,1k|1cs,0,1j||",
                "New_Zealand": "1ct,5,1k|1ct,5,1j||",
                "Newfoundland": "1cu,5,1k|1cu,5,1j||",
                "Niue": "8g,5,25|||",
                "Norfolk": "285,5,25|||",
                "Noronha": "1s,0,2,0,23,5,1k|1s,0,2,0,23,5,1j||",
                "North_Mariana": "286,5,287,5,25|||",
                "Novosibirsk": "16c,5,1k|16c,5,1j||",
                "Omsk": "26,5,1k|26,5,1j||",
                "Pakistan": "1d3,0,1k|1d3,0,1j||",
                "Palau": "9b,5,25|||",
                "Papua_New_Guinea": "9a,5,289,5,25|||",
                "Paraguay": "1d5,0,1k|1d5,0,1j||",
                "Peru": "1d6,0,1k|1d6,0,1j||",
                "Philippines": "1d7,0,1k|1d7,0,1j||",
                "Phoenix_Islands": "28a,5,25|||",
                "Pierre_Miquelon": "8i,5,33,5,sv,5,35,5,1k|8i,5,33,5,sv,5,35,5,1j||",
                "Pitcairn": "28b,5,25|||",
                "Ponape": "9s,5,25|||",
                "Pyongyang": "28d,5,25|||",
                "Qyzylorda": "28e,5,1k|28g,5,1j||",
                "Reunion": "i8,5,25|||",
                "Rothera": "5q,5,25|||",
                "Sakhalin": "ph,5,1k|ph,5,1j||",
                "Samara": "29,5,1k|29,5,1j||",
                "Samoa": "2c,5,1k|2c,5,1j||",
                "Seychelles": "1vo,5,25|||",
                "Singapore": "m8,5,25|||",
                "Solomon": "28j,5,25|||",
                "South_Georgia": "28k,0,25|||",
                "Suriname": "8q,5,25|||",
                "Syowa": "65,5,25|||",
                "Tahiti": "8n,5,25|||",
                "Taipei": "1dg,5,1k|1dg,5,1j||",
                "Tajikistan": "1vl,5,25|||",
                "Tokelau": "5d,5,25|||",
                "Tonga": "1di,0,1k|1di,0,1j||",
                "Truk": "6p,5,25|||",
                "Turkmenistan": "4t,5,1k|4t,5,1j||",
                "Tuvalu": "6h,5,25|||",
                "Uruguay": "28l,0,1k|28m,0,1j||",
                "Uzbekistan": "14m,5,1k|14m,5,1j||",
                "Vanuatu": "2f,5,1k|2f,5,1j||",
                "Venezuela": "7s,5,25|||",
                "Vladivostok": "15m,5,1k|15m,5,1j||",
                "Volgograd": "qi,5,1k|qi,5,1j||",
                "Vostok": "1kt,5,25|||",
                "Wake": "5r,5,1vg,5,25|||",
                "Wallis": "5f,5,sv,5,5k,5,25|||",
                "Yakutsk": "qj,5,1k|qj,5,1j||",
                "Yekaterinburg": "q6,5,1k|q6,5,1j||"
            }
        },
        "dje": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "dsb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c9n,0,g9,0,7q||",
                "Europe/London": "|6mo,0,g9,0,7q||"
            },
            "metazone": {
                "Afghanistan": "brl,0,7q|||",
                "Africa_Central": "brk,0,7q|||",
                "Africa_Eastern": "brj,0,7q|||",
                "Africa_Southern": "bri,0,7q|||",
                "Africa_Western": "7aa,0,ad,0,7q|7aa,0,g9,0,7q||",
                "Alaska": "7af,0,ad,0,7q|7af,0,g9,0,7q||",
                "Amazon": "44m,0,ad,0,7q|44m,0,g9,0,7q||",
                "America_Central": "1ve,0,44l,0,ad,0,7q|1ve,0,44l,0,g9,0,7q||",
                "America_Eastern": "1ve,0,7aj,0,ad,0,7q|1ve,0,7aj,0,g9,0,7q||",
                "America_Mountain": "1ve,0,44j,0,ad,0,7q|1ve,0,44j,0,g9,0,7q||",
                "America_Pacific": "1ve,0,1vb,0,ad,0,7q|1ve,0,1vb,0,g9,0,7q||",
                "Apia": "44i,0,ad,0,7q|44i,0,g9,0,7q||",
                "Arabian": "7ao,0,ad,0,7q|7ao,0,g9,0,7q||",
                "Argentina": "2ed,0,ad,0,7q|2ed,0,g9,0,7q||",
                "Argentina_Western": "7aq,0,ad,0,7q|7aq,0,g9,0,7q||",
                "Armenia": "7ar,0,ad,0,7q|7ar,0,g9,0,7q||",
                "Atlantic": "7as,0,ad,0,7q|7as,0,g9,0,7q||",
                "Australia_Central": "7at,0,ad,0,7q|7at,0,g9,0,7q||",
                "Australia_CentralWestern": "7b1,0,44h,0,ad,0,7q|7b1,0,44h,0,g9,0,7q||",
                "Australia_Eastern": "7b3,0,ad,0,7q|7b3,0,g9,0,7q||",
                "Australia_Western": "7b4,0,ad,0,7q|7b4,0,g9,0,7q||",
                "Azerbaijan": "7b6,0,ad,0,7q|7b6,0,g9,0,7q||",
                "Azores": "7bb,0,ad,0,7q|7bb,0,g9,0,7q||",
                "Bangladesh": "7bc,0,ad,0,7q|7bc,0,g9,0,7q||",
                "Bhutan": "brh,0,7q|||",
                "Bolivia": "brg,0,7q|||",
                "Brasilia": "in,0,ad,0,7q|in,0,g9,0,7q||",
                "Brunei": "brf,0,7q|||",
                "Cape_Verde": "44b,0,ad,0,7q|44b,0,g9,0,7q||",
                "Chamorro": "bre,0,7q|||",
                "Chatham": "7ee,0,ad,0,7q|7ee,0,g9,0,7q||",
                "Chile": "7ef,0,ad,0,7q|7ef,0,g9,0,7q||",
                "China": "7eg,0,ad,0,7q|7eg,0,g9,0,7q||",
                "Choibalsan": "7ek,0,ad,0,7q|7ek,0,g9,0,7q||",
                "Christmas": "7q,0,brd,0,10v|||",
                "Cocos": "7q,0,7eq,0,10v|||",
                "Colombia": "7ev,0,ad,0,7q|7ev,0,g9,0,7q||",
                "Cook": "7f3,0,7q,0,445,0,10v|g9,0,7q,0,445,0,10v||",
                "Cuba": "7fc,0,ad,0,7q|7fc,0,g9,0,7q||",
                "Davis": "5s,0,7q|||",
                "DumontDUrville": "brc,0,7q|||",
                "East_Timor": "brb,0,7q|||",
                "Easter": "ad,0,7q,0,7fm,0,19r|g9,0,7q,0,7fm,0,19r||",
                "Ecuador": "bra,0,7q|||",
                "Europe_Central": "7g1,0,ad,0,7q|7g1,0,g9,0,7q|1bd|1be",
                "Europe_Eastern": "7g4,0,ad,0,7q|7g4,0,g9,0,7q|1bg|1bh",
                "Europe_Further_Eastern": "7g5,0,7q|||",
                "Europe_Western": "7ga,0,ad,0,7q|7ga,0,g9,0,7q|1bk|1bl",
                "Falkland": "7gr,0,ad,0,7q|7gr,0,g9,0,7q||",
                "Fiji": "7hf,0,ad,0,7q|7hf,0,g9,0,7q||",
                "French_Guiana": "br9,0,7q|||",
                "French_Southern": "7q,0,br8,0,br7,0,a6,0,7i9,0,br6|||",
                "Galapagos": "br5,0,7q|||",
                "Gambier": "7je,0,7q|||",
                "Georgia": "7jp,0,ad,0,7q|7jp,0,g9,0,7q||",
                "Gilbert_Islands": "7q,0,7kc,0,10v|||",
                "GMT": "7kd,0,7q|||",
                "Greenland_Eastern": "7ko,0,ad,0,7q|7ko,0,g9,0,7q||",
                "Greenland_Western": "7kq,0,ad,0,7q|7kq,0,g9,0,7q||",
                "Gulf": "7q,0,br4,0,7m8|||",
                "Guyana": "br3,0,7q|||",
                "Hawaii_Aleutian": "7o7,5,2eq,0,ad,0,7q|7o7,5,2eq,0,g9,0,7q||",
                "Hong_Kong": "43m,0,ad,0,7q|43m,0,g9,0,7q||",
                "Hovd": "43l,0,ad,0,7q|43l,0,g9,0,7q||",
                "India": "br2,0,7q|||",
                "Indian_Ocean": "br1,0,7q|||",
                "Indochina": "br0,0,7q|||",
                "Indonesia_Central": "bqv,0,7q|||",
                "Indonesia_Eastern": "bqu|||",
                "Indonesia_Western": "bqt,0,7q|||",
                "Iran": "7pa,0,ad,0,7q|7pa,0,g9,0,7q||",
                "Irkutsk": "1uo,0,ad,0,7q|1uo,0,g9,0,7q||",
                "Israel": "7pd,0,ad,0,7q|7pd,0,g9,0,7q||",
                "Japan": "7pi,0,ad,0,7q|7pi,0,g9,0,7q||",
                "Kazakhstan_Eastern": "bqs,0,7q|||",
                "Kazakhstan_Western": "bqr,0,7q|||",
                "Korea": "43a,0,ad,0,7q|43a,0,g9,0,7q||",
                "Kosrae": "bqq,0,7q|||",
                "Krasnoyarsk": "1un,0,ad,0,7q|1un,0,g9,0,7q||",
                "Kyrgystan": "bqp,0,7q|||",
                "Line_Islands": "7q,0,7qh,0,10v|||",
                "Lord_Howe": "7f3,0,7q,0,19r,0,1u,5,24|g9,0,7q,0,19r,0,1u,5,24||",
                "Macquarie": "7q,0,19r,0,6m|||",
                "Magadan": "7qi,0,ad,0,7q|7qi,0,g9,0,7q||",
                "Malaysia": "bqo,0,7q|||",
                "Maldives": "bqn,0,7q|||",
                "Marquesas": "bqm,0,7q|||",
                "Marshall_Islands": "7q,0,7r5,0,10v|||",
                "Mauritius": "7ra,0,ad,0,7q|7ra,0,g9,0,7q||",
                "Mawson": "63,0,7q|||",
                "Mexico_Northwest": "424,0,7rd,0,ad,0,7q|424,0,7rd,0,g9,0,7q||",
                "Mexico_Pacific": "424,0,1vb,0,ad,0,7q|424,0,1vb,0,g9,0,7q||",
                "Mongolia": "4f,5,41m,0,ad,0,7q|4f,5,41m,0,g9,0,7q||",
                "Moscow": "41h,0,ad,0,7q|41h,0,g9,0,7q||",
                "Myanmar": "bql,0,7q|||",
                "Nauru": "bqk,0,7q|||",
                "Nepal": "7rs,0,7q|||",
                "New_Caledonia": "7rv,0,ad,0,7q|7rv,0,g9,0,7q||",
                "New_Zealand": "7s0,0,ad,0,7q|7s0,0,g9,0,7q||",
                "Newfoundland": "7s1,0,ad,0,7q|7s1,0,g9,0,7q||",
                "Niue": "bqj,0,7q|||",
                "Norfolk": "7q,0,19r,0,6l|||",
                "Noronha": "ad,0,7q,0,1s,0,2,0,23|g9,0,7q,0,1s,0,2,0,23||",
                "Novosibirsk": "414,0,ad,0,7q|414,0,g9,0,7q||",
                "Omsk": "1ug,0,ad,0,7q|1ug,0,g9,0,7q||",
                "Pakistan": "7se,0,ad,0,7q|7se,0,g9,0,7q||",
                "Palau": "bqi,0,7q|||",
                "Papua_New_Guinea": "9a,5,bqh,0,7q|||",
                "Paraguay": "40k,0,ad,0,7q|40k,0,g9,0,7q||",
                "Peru": "7sm,0,ad,0,7q|7sm,0,g9,0,7q||",
                "Philippines": "40e,0,ad,0,7q|40e,0,g9,0,7q||",
                "Phoenix_Islands": "7q,0,7su,0,10v|||",
                "Pierre_Miquelon": "7u,5,33,5,a6,5,7tl,0,ad,0,7q|7u,5,33,5,a6,5,7tl,0,g9,0,7q||",
                "Pitcairn": "7q,0,7tn,0,10v|||",
                "Ponape": "7u1,0,7q|||",
                "Reunion": "7u7,0,7q|||",
                "Rothera": "7q,0,5q|||",
                "Sakhalin": "7uv,0,ad,0,7q|7uv,0,g9,0,7q||",
                "Samoa": "7v3,0,ad,0,7q|7v3,0,g9,0,7q||",
                "Seychelles": "bqg,0,7q|||",
                "Singapore": "4j2,0,7q|||",
                "Solomon": "bqf,0,7q|||",
                "South_Georgia": "bqe,0,7q|||",
                "Suriname": "81u,0,7q|||",
                "Syowa": "65,0,7q|||",
                "Tahiti": "bqd,0,7q|||",
                "Taipei": "83g,0,ad,0,7q|83g,0,g9,0,7q||",
                "Tajikistan": "bqc,0,7q|||",
                "Tokelau": "bqb,0,7q|||",
                "Tonga": "840,0,ad,0,7q|840,0,g9,0,7q||",
                "Truk": "bqa,0,7q|||",
                "Turkmenistan": "861,0,ad,0,7q|861,0,g9,0,7q||",
                "Tuvalu": "bq9,0,7q|||",
                "Uruguay": "86c,0,ad,0,7q|86c,0,g9,0,7q||",
                "Uzbekistan": "86u,0,ad,0,7q|86u,0,g9,0,7q||",
                "Vanuatu": "872,0,ad,0,7q|872,0,g9,0,7q||",
                "Venezuela": "87d,0,7q|||",
                "Vladivostok": "403,0,ad,0,7q|403,0,g9,0,7q||",
                "Volgograd": "401,0,ad,0,7q|401,0,g9,0,7q||",
                "Vostok": "7q,0,1kt|||",
                "Wake": "7q,0,19r,0,5r|||",
                "Wallis": "7q,0,10v,0,5f,0,a6,0,5k|||",
                "Yakutsk": "1u5,0,ad,0,7q|1u5,0,g9,0,7q||",
                "Yekaterinburg": "3vt,0,ad,0,7q|3vt,0,g9,0,7q||"
            }
        },
        "dua": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "dyo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "dz": {
            "gmtFormat": "ཇི་ཨེམ་ཏི་{0}",
            "gmtZeroFormat": "ཇི་ཨེམ་ཊི་",
            "zone": {
                "Europe/Dublin": "|c9p||",
                "Europe/London": "|c9q||"
            },
            "metazone": {
                "Afghanistan": "bq8|||",
                "Africa_Central": "bq7|||",
                "Africa_Eastern": "bq6|||",
                "Africa_Southern": "bq5|||",
                "Africa_Western": "bq4|bq3||",
                "Alaska": "bq2|bq1||",
                "Amazon": "bq0|bpv||",
                "America_Central": "bpu|bpt||",
                "America_Eastern": "bps|bpr||",
                "America_Mountain": "bpq|bpp||",
                "America_Pacific": "bpo|bpn||",
                "Arabian": "bpm|bpl||",
                "Argentina": "bpk|bpj||",
                "Argentina_Western": "bpi|bph||",
                "Armenia": "bpg|bpf||",
                "Atlantic": "bpe|bpd||",
                "Australia_Central": "bpc|bpb||",
                "Australia_CentralWestern": "bpa|bp9||",
                "Australia_Eastern": "bp8|bp7||",
                "Australia_Western": "bp6|bp5||",
                "Azerbaijan": "bp4|bp3||",
                "Azores": "bp2|bp1||",
                "Bangladesh": "bp0|bov||",
                "Bhutan": "bou|||",
                "Bolivia": "bot|||",
                "Brasilia": "bos|bor||",
                "Cape_Verde": "boq|bop||",
                "Chile": "boo|bon||",
                "China": "bom|bol||",
                "Christmas": "bok|||",
                "Colombia": "boj|boi||",
                "Cuba": "boh|bog||",
                "Easter": "bof|boe||",
                "Ecuador": "bod|||",
                "Europe_Central": "boc|bob||",
                "Europe_Eastern": "boa|bo9||",
                "Europe_Western": "bo8|bo7||",
                "Falkland": "bo6|bo5||",
                "French_Guiana": "bo4|||",
                "Galapagos": "bo3|||",
                "Georgia": "bo2|bo1||",
                "GMT": "bo0|||",
                "Greenland_Eastern": "bnv|bnu||",
                "Greenland_Western": "bnt|bns||",
                "Gulf": "bnr|||",
                "Guyana": "bnq|||",
                "Hawaii_Aleutian": "4sc,5,bnp|4sc,5,bno||",
                "India": "bnn|||",
                "Indian_Ocean": "bnm|||",
                "Indochina": "bnl|||",
                "Indonesia_Central": "bnk|||",
                "Indonesia_Eastern": "bnj|||",
                "Indonesia_Western": "bni|||",
                "Iran": "bnh|bng||",
                "Irkutsk": "bnf|bne||",
                "Israel": "bnd|bnc||",
                "Japan": "bnb|bna||",
                "Korea": "bn9|bn8||",
                "Krasnoyarsk": "bn7|bn6||",
                "Magadan": "bn5|bn4||",
                "Maldives": "bn3|||",
                "Mauritius": "bn2|bn1||",
                "Moscow": "bn0|bmv||",
                "Nepal": "bmu|||",
                "New_Zealand": "bmt|bms||",
                "Newfoundland": "bmr|bmq||",
                "Noronha": "4uo,0,4up,0,bmp|4uo,0,4up,0,bmo||",
                "Novosibirsk": "bmn|bmm||",
                "Omsk": "bmi|bmh||",
                "Pakistan": "bmg|bmf||",
                "Paraguay": "bme|bmd||",
                "Peru": "bmc|bmb||",
                "Pierre_Miquelon": "bma|bm9||",
                "Reunion": "bm8|||",
                "Sakhalin": "bm7|bm6||",
                "Seychelles": "bm2|||",
                "Suriname": "bm1|||",
                "Uruguay": "blv|blr||",
                "Venezuela": "blq|||",
                "Vladivostok": "blp|bln||",
                "Volgograd": "blm|bll||",
                "Yakutsk": "blk|blj||",
                "Yekaterinburg": "bli|blh||"
            }
        },
        "ebu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ee-TG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Africa_Central": "1ah,0,c5,0,mr||vj|",
                "Africa_Eastern": "1ad,0,1t,0,mr||vp|",
                "Africa_Southern": "510,0,1t,0,mr||vu|",
                "Africa_Western": "1ii,0,c5,0,pt|1ii,0,1t,0,qm,0,mr|103|vq",
                "America_Central": "1ah,0,14n,0,pt|1ah,0,14n,0,qm,0,mr||",
                "America_Eastern": "1ad,0,14n,0,pt|1ad,0,14n,0,qm,0,mr||",
                "America_Mountain": "14n,0,3r6,0,3r3,0,pt|14n,0,3r6,0,3r3,0,3r2,0,mr||",
                "America_Pacific": "2h,0,pt|2h,0,3r2,0,mr||",
                "Arabian": "hs,0,pt|hs,0,qm,0,mr||",
                "Atlantic": "6g,0,pt|6g,0,qm,0,mr||",
                "Australia_Central": "1ah,0,4c,0,pt|1ah,0,4c,0,qm,0,mr||",
                "Australia_CentralWestern": "4c,0,3r1,0,pt|4c,0,3r1,0,qm,0,mr||",
                "Australia_Eastern": "1ad,0,4c,0,pt|1ad,0,4c,0,qm,0,mr||",
                "Australia_Western": "1ii,0,4c,0,pt|1ii,0,4c,0,qm,0,mr||",
                "China": "4h,0,pt|4h,0,qm,0,mr||",
                "Europe_Central": "1ah,0,r4,0,pt|1ah,0,r4,0,qm,0,mr||",
                "Europe_Eastern": "1ad,0,r4,0,pt|1ad,0,r4,0,qm,0,mr||",
                "Europe_Western": "1ii,0,r4,0,pt|1ii,0,r4,0,qm,0,mr||",
                "GMT": "5u,0,mr|||",
                "Israel": "4e,0,pt|4e,0,qm,0,mr||",
                "Japan": "6o,0,51e|6o,0,qm,0,mr||",
                "Korea": "qf,0,pt|qf,0,qm,0,mr||",
                "Moscow": "71,0,pt|71,0,qm,0,mr||",
                "Suriname": "8q,0,mr|||"
            }
        },
        "ee": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Africa_Central": "1ah,0,c5,0,mr||vj|",
                "Africa_Eastern": "1ad,0,1t,0,mr||vp|",
                "Africa_Southern": "510,0,1t,0,mr||vu|",
                "Africa_Western": "1ii,0,c5,0,pt|1ii,0,1t,0,qm,0,mr|103|vq",
                "America_Central": "1ah,0,14n,0,pt|1ah,0,14n,0,qm,0,mr||",
                "America_Eastern": "1ad,0,14n,0,pt|1ad,0,14n,0,qm,0,mr||",
                "America_Mountain": "14n,0,3r6,0,3r3,0,pt|14n,0,3r6,0,3r3,0,3r2,0,mr||",
                "America_Pacific": "2h,0,pt|2h,0,3r2,0,mr||",
                "Arabian": "hs,0,pt|hs,0,qm,0,mr||",
                "Atlantic": "6g,0,pt|6g,0,qm,0,mr||",
                "Australia_Central": "1ah,0,4c,0,pt|1ah,0,4c,0,qm,0,mr||",
                "Australia_CentralWestern": "4c,0,3r1,0,pt|4c,0,3r1,0,qm,0,mr||",
                "Australia_Eastern": "1ad,0,4c,0,pt|1ad,0,4c,0,qm,0,mr||",
                "Australia_Western": "1ii,0,4c,0,pt|1ii,0,4c,0,qm,0,mr||",
                "China": "4h,0,pt|4h,0,qm,0,mr||",
                "Europe_Central": "1ah,0,r4,0,pt|1ah,0,r4,0,qm,0,mr||",
                "Europe_Eastern": "1ad,0,r4,0,pt|1ad,0,r4,0,qm,0,mr||",
                "Europe_Western": "1ii,0,r4,0,pt|1ii,0,r4,0,qm,0,mr||",
                "GMT": "5u,0,mr|||",
                "Israel": "4e,0,pt|4e,0,qm,0,mr||",
                "Japan": "6o,0,51e|6o,0,qm,0,mr||",
                "Korea": "qf,0,pt|qf,0,qm,0,mr||",
                "Moscow": "71,0,pt|71,0,qm,0,mr||",
                "Suriname": "8q,0,mr|||"
            }
        },
        "el-CY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|a2,0,46,0,6mn||",
                "Europe/London": "|a2,0,46,0,6me||"
            },
            "metazone": {
                "Afghanistan": "at,0,51j|||",
                "Africa_Central": "at,0,1it,0,1n4|||",
                "Africa_Eastern": "at,0,15v,0,1n4|||",
                "Africa_Southern": "a7,0,46,0,3qu,0,1n4|||",
                "Africa_Western": "a7,0,46,0,128,0,1n4|a2,0,46,0,128,0,1n4||",
                "Alaska": "a7,0,46,0,3qs|a2,0,46,0,3qs||",
                "Amazon": "a7,0,46,0,3qr|a2,0,46,0,3qr||",
                "America_Central": "3qq,0,1ra,0,46,0,182,0,186|3qq,0,1r9,0,46,0,182,0,186||",
                "America_Eastern": "3qp,0,1ra,0,46,0,182,0,186|3qp,0,1r9,0,46,0,182,0,186||",
                "America_Mountain": "3ql,0,1ra,0,46,0,182,0,186|3ql,0,1r9,0,46,0,182,0,186||",
                "America_Pacific": "a7,0,46,0,182,0,186|a2,0,46,0,182,0,186||",
                "Anadyr": "a7,0,46,0,3qi|a2,0,46,0,3qi||",
                "Apia": "a7,0,46,0,3qh|a2,0,46,0,3qh||",
                "Arabian": "3qg,0,1ra,0,46|3qg,0,1r9,0,46||",
                "Argentina": "a7,0,46,0,1r0|a2,0,46,0,1r0||",
                "Argentina_Western": "a7,0,46,0,128,0,1r0|a2,0,46,0,128,0,1r0||",
                "Armenia": "a7,0,46,0,3q8|a2,0,46,0,3q8||",
                "Atlantic": "a7,0,46,0,3q5|a2,0,46,0,3q5||",
                "Australia_Central": "a7,0,46,0,1it,0,17d|a2,0,46,0,1it,0,17d||",
                "Australia_CentralWestern": "a7,0,46,0,3q3,0,17d|a2,0,46,0,3q3,0,17d||",
                "Australia_Eastern": "a7,0,46,0,15v,0,17d|a2,0,46,0,15v,0,17d||",
                "Australia_Western": "a7,0,46,0,128,0,17d|a2,0,46,0,128,0,17d||",
                "Azerbaijan": "a7,0,46,0,3q1|a2,0,46,0,3q1||",
                "Azores": "a7,0,46,0,3q0|a2,0,46,0,3q0||",
                "Bangladesh": "a7,0,46,0,3pv|a2,0,46,0,3pv||",
                "Bhutan": "at,0,53j|||",
                "Bolivia": "at,0,53k|||",
                "Brasilia": "a7,0,46,0,3pt|a2,0,46,0,3pt||",
                "Brunei": "at,0,53p,0,53q|||",
                "Cape_Verde": "a7,0,46,0,3ps,0,3pn|a2,0,46,0,3ps,0,3pn||",
                "Chamorro": "at,0,53v|||",
                "Chatham": "a7,0,46,0,3pm|a2,0,46,0,3pm||",
                "Chile": "a7,0,46,0,3pl|a2,0,46,0,3pl||",
                "China": "a7,0,46,0,3pk|a2,0,46,0,3pk||",
                "Choibalsan": "a7,0,46,0,3pj|a2,0,46,0,3pj||",
                "Christmas": "at,0,1o6,0,548|||",
                "Cocos": "at,0,121,0,54d|||",
                "Colombia": "a7,0,46,0,3pi|a2,0,46,0,3pi||",
                "Cook": "a7,0,46,0,121,0,3ph|a2,0,46,0,121,0,3ph||",
                "Cuba": "a7,0,46,0,3pg|a2,0,46,0,3pg||",
                "Davis": "at,0,54r|||",
                "DumontDUrville": "at,0,54u,0,550|||",
                "East_Timor": "at,0,3pf,0,553|||",
                "Easter": "a7,0,46,0,1o6,0,3pe|a2,0,46,0,1o6,0,3pe||",
                "Ecuador": "at,0,556|||",
                "Europe_Central": "a7,0,46,0,1it,0,1a5|a2,0,46,0,1it,0,1a5|h0|h4",
                "Europe_Eastern": "a7,0,46,0,15v,0,1a5|a2,0,46,0,15v,0,1a5|gu|gv",
                "Europe_Further_Eastern": "at,0,559,0,15v,0,1a5|||",
                "Europe_Western": "a7,0,46,0,128,0,1a5|a2,0,46,0,128,0,1a5|h1|h2",
                "Falkland": "a7,0,46,0,121,0,3pd|a2,0,46,0,121,0,3pd||",
                "Fiji": "a7,0,46,0,3pc|a2,0,46,0,3pc||",
                "French_Guiana": "at,0,55i,0,3pb|||",
                "French_Southern": "at,0,567,0,56b,0,247,0,577|||",
                "Galapagos": "at,0,57a|||",
                "Gambier": "at,0,57k|||",
                "Georgia": "a7,0,46,0,2lg|a2,0,46,0,2lg||",
                "Gilbert_Islands": "at,0,121,0,57m|||",
                "GMT": "57q,0,46,0,57s|||",
                "Greenland_Eastern": "a7,0,46,0,15v,0,23s|a2,0,46,0,15v,0,23s||",
                "Greenland_Western": "a7,0,46,0,128,0,23s|a2,0,46,0,128,0,23s||",
                "Guam": "at,0,585|||",
                "Gulf": "at,0,586|||",
                "Guyana": "at,0,3pb|||",
                "Hawaii_Aleutian": "a7,0,46,0,3pa,5,3p9,0,3p8|a2,0,46,0,3pa,5,3p9,0,3p8||",
                "Hong_Kong": "a7,0,46,0,3p7,0,3p6|a2,0,46,0,3p7,0,3p6||",
                "Hovd": "a7,0,46,0,3p5|a2,0,46,0,3p5||",
                "India": "at,0,58m|||",
                "Indian_Ocean": "at,0,58q,0,590|||",
                "Indochina": "at,0,591|||",
                "Indonesia_Central": "at,0,1it,0,2lh|||",
                "Indonesia_Eastern": "at,0,15v,0,2lh|||",
                "Indonesia_Western": "at,0,128,0,2lh|||",
                "Iran": "a7,0,46,0,3p4|a2,0,46,0,3p4||",
                "Irkutsk": "a7,0,46,0,3p3|a2,0,46,0,3p3||",
                "Israel": "a7,0,46,0,3p2|a2,0,46,0,3p2||",
                "Japan": "a7,0,46,0,3p1|a2,0,46,0,3p1||",
                "Kamchatka": "a7,0,46,0,3ov,5,3ou|a2,0,46,0,3ov,5,3ou||",
                "Kazakhstan_Eastern": "at,0,3pf,0,3ot|||",
                "Kazakhstan_Western": "at,0,59h,0,3ot|||",
                "Korea": "a7,0,46,0,3or|a2,0,46,0,3or||",
                "Kosrae": "at,0,59o|||",
                "Krasnoyarsk": "a7,0,46,0,3oq|a2,0,46,0,3oq||",
                "Kyrgystan": "at,0,5a5|||",
                "Line_Islands": "at,0,121,0,5ac|||",
                "Lord_Howe": "a7,0,46,0,3op,0,3oo|a2,0,46,0,3op,0,3oo||",
                "Macau": "a7,0,46,0,3on|a2,0,46,0,3on||",
                "Macquarie": "at,0,1o6,0,5au|||",
                "Magadan": "a7,0,46,0,3om|a2,0,46,0,3om||",
                "Malaysia": "at,0,5b6|||",
                "Maldives": "at,0,5b7|||",
                "Marquesas": "at,0,5be|||",
                "Marshall_Islands": "at,0,121,0,5bf|||",
                "Mauritius": "a7,0,46,0,3ol|a2,0,46,0,3ol||",
                "Mawson": "at,0,5br|||",
                "Mexico_Northwest": "a7,0,46,0,3ok,0,1vk|a2,0,46,0,3ok,0,1vk||",
                "Mexico_Pacific": "a7,0,46,0,3oj,0,1vk|a2,0,46,0,3oj,0,1vk||",
                "Mongolia": "a7,0,46,0,3oi,0,3oh|a2,0,46,0,3oi,0,3oh||",
                "Moscow": "a7,0,46,0,3og|a2,0,46,0,3og||",
                "Myanmar": "at,0,5ce|||",
                "Nauru": "at,0,5cl|||",
                "Nepal": "at,0,5cp|||",
                "New_Caledonia": "a7,0,46,0,1gs,0,3oe|a2,0,46,0,1gs,0,3oe||",
                "New_Zealand": "a7,0,46,0,1gs,0,3oc|a2,0,46,0,1gs,0,3oc||",
                "Newfoundland": "a7,0,46,0,1gs,0,3ob|a2,0,46,0,1gs,0,3ob||",
                "Niue": "at,0,5db|||",
                "Norfolk": "at,0,121,0,5dc|||",
                "Noronha": "a7,0,46,0,3oa,0,3o9,0,3o7|a2,0,46,0,3oa,0,3o9,0,3o7||",
                "North_Mariana": "at,0,121,0,5dh,0,5e4|||",
                "Novosibirsk": "a7,0,46,0,3o5|a2,0,46,0,3o5||",
                "Omsk": "a7,0,46,0,3o4|a2,0,46,0,3o4||",
                "Pakistan": "a7,0,46,0,3o3|a2,0,46,0,3o3||",
                "Palau": "at,0,5e9|||",
                "Papua_New_Guinea": "at,0,5ea,0,5eb,0,5ee|||",
                "Paraguay": "a7,0,46,0,3nu|a2,0,46,0,3nu||",
                "Peru": "a7,0,46,0,3ns|a2,0,46,0,3ns||",
                "Philippines": "a7,0,46,0,3nr|a2,0,46,0,3nr||",
                "Phoenix_Islands": "at,0,121,0,5f7|||",
                "Pierre_Miquelon": "a7,0,46,0,3nn,0,3nj,0,247,0,3nf|a2,0,46,0,3nn,0,3nj,0,247,0,3nf||",
                "Pitcairn": "at,0,5g6|||",
                "Ponape": "at,0,5gi|||",
                "Pyongyang": "at,0,5h8|||",
                "Reunion": "at,0,5he|||",
                "Rothera": "at,0,5hg|||",
                "Sakhalin": "a7,0,46,0,3ne|a2,0,46,0,3ne||",
                "Samara": "a7,0,46,0,5hu|a2,0,46,0,5i2||",
                "Samoa": "a7,0,46,0,3nc|a2,0,46,0,3nc||",
                "Seychelles": "at,0,5ih|||",
                "Singapore": "at,0,5ii|||",
                "Solomon": "at,0,121,0,5j7|||",
                "South_Georgia": "at,0,3qu,0,2lg|||",
                "Suriname": "at,0,5j8|||",
                "Syowa": "at,0,5j9|||",
                "Tahiti": "at,0,5ja|||",
                "Taipei": "a7,0,46,0,3nb|a2,0,46,0,3nb||",
                "Tajikistan": "at,0,5jc|||",
                "Tokelau": "at,0,5jd|||",
                "Tonga": "a7,0,46,0,3n8|a2,0,46,0,3n8||",
                "Truk": "at,0,5ji|||",
                "Turkmenistan": "a7,0,46,0,3n7|a2,0,46,0,3n7||",
                "Tuvalu": "at,0,5jl|||",
                "Uruguay": "a7,0,46,0,3n2|a2,0,46,0,3n2||",
                "Uzbekistan": "a7,0,46,0,3n0|a2,0,46,0,3n0||",
                "Vanuatu": "a7,0,46,0,3mv|a2,0,46,0,3mv||",
                "Venezuela": "at,0,5jq|||",
                "Vladivostok": "a7,0,46,0,3mt|a2,0,46,0,3mt||",
                "Volgograd": "a7,0,46,0,3ms|a2,0,46,0,3ms||",
                "Vostok": "at,0,5jv|||",
                "Wake": "at,0,1o6,0,5k0|||",
                "Wallis": "at,0,5k2,0,247,0,5k3|||",
                "Yakutsk": "a7,0,46,0,3mp|a2,0,46,0,3mp||",
                "Yekaterinburg": "a7,0,46,0,3mo|a2,0,46,0,3mo||"
            }
        },
        "el": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|a2,0,46,0,6mn||",
                "Europe/London": "|a2,0,46,0,6me||"
            },
            "metazone": {
                "Afghanistan": "at,0,51j|||",
                "Africa_Central": "at,0,1it,0,1n4|||",
                "Africa_Eastern": "at,0,15v,0,1n4|||",
                "Africa_Southern": "a7,0,46,0,3qu,0,1n4|||",
                "Africa_Western": "a7,0,46,0,128,0,1n4|a2,0,46,0,128,0,1n4||",
                "Alaska": "a7,0,46,0,3qs|a2,0,46,0,3qs||",
                "Amazon": "a7,0,46,0,3qr|a2,0,46,0,3qr||",
                "America_Central": "3qq,0,1ra,0,46,0,182,0,186|3qq,0,1r9,0,46,0,182,0,186||",
                "America_Eastern": "3qp,0,1ra,0,46,0,182,0,186|3qp,0,1r9,0,46,0,182,0,186||",
                "America_Mountain": "3ql,0,1ra,0,46,0,182,0,186|3ql,0,1r9,0,46,0,182,0,186||",
                "America_Pacific": "a7,0,46,0,182,0,186|a2,0,46,0,182,0,186||",
                "Anadyr": "a7,0,46,0,3qi|a2,0,46,0,3qi||",
                "Apia": "a7,0,46,0,3qh|a2,0,46,0,3qh||",
                "Arabian": "3qg,0,1ra,0,46|3qg,0,1r9,0,46||",
                "Argentina": "a7,0,46,0,1r0|a2,0,46,0,1r0||",
                "Argentina_Western": "a7,0,46,0,128,0,1r0|a2,0,46,0,128,0,1r0||",
                "Armenia": "a7,0,46,0,3q8|a2,0,46,0,3q8||",
                "Atlantic": "a7,0,46,0,3q5|a2,0,46,0,3q5||",
                "Australia_Central": "a7,0,46,0,1it,0,17d|a2,0,46,0,1it,0,17d||",
                "Australia_CentralWestern": "a7,0,46,0,3q3,0,17d|a2,0,46,0,3q3,0,17d||",
                "Australia_Eastern": "a7,0,46,0,15v,0,17d|a2,0,46,0,15v,0,17d||",
                "Australia_Western": "a7,0,46,0,128,0,17d|a2,0,46,0,128,0,17d||",
                "Azerbaijan": "a7,0,46,0,3q1|a2,0,46,0,3q1||",
                "Azores": "a7,0,46,0,3q0|a2,0,46,0,3q0||",
                "Bangladesh": "a7,0,46,0,3pv|a2,0,46,0,3pv||",
                "Bhutan": "at,0,53j|||",
                "Bolivia": "at,0,53k|||",
                "Brasilia": "a7,0,46,0,3pt|a2,0,46,0,3pt||",
                "Brunei": "at,0,53p,0,53q|||",
                "Cape_Verde": "a7,0,46,0,3ps,0,3pn|a2,0,46,0,3ps,0,3pn||",
                "Chamorro": "at,0,53v|||",
                "Chatham": "a7,0,46,0,3pm|a2,0,46,0,3pm||",
                "Chile": "a7,0,46,0,3pl|a2,0,46,0,3pl||",
                "China": "a7,0,46,0,3pk|a2,0,46,0,3pk||",
                "Choibalsan": "a7,0,46,0,3pj|a2,0,46,0,3pj||",
                "Christmas": "at,0,1o6,0,548|||",
                "Cocos": "at,0,121,0,54d|||",
                "Colombia": "a7,0,46,0,3pi|a2,0,46,0,3pi||",
                "Cook": "a7,0,46,0,121,0,3ph|a2,0,46,0,121,0,3ph||",
                "Cuba": "a7,0,46,0,3pg|a2,0,46,0,3pg||",
                "Davis": "at,0,54r|||",
                "DumontDUrville": "at,0,54u,0,550|||",
                "East_Timor": "at,0,3pf,0,553|||",
                "Easter": "a7,0,46,0,1o6,0,3pe|a2,0,46,0,1o6,0,3pe||",
                "Ecuador": "at,0,556|||",
                "Europe_Central": "a7,0,46,0,1it,0,1a5|a2,0,46,0,1it,0,1a5|h0|h4",
                "Europe_Eastern": "a7,0,46,0,15v,0,1a5|a2,0,46,0,15v,0,1a5|gu|gv",
                "Europe_Further_Eastern": "at,0,559,0,15v,0,1a5|||",
                "Europe_Western": "a7,0,46,0,128,0,1a5|a2,0,46,0,128,0,1a5|h1|h2",
                "Falkland": "a7,0,46,0,121,0,3pd|a2,0,46,0,121,0,3pd||",
                "Fiji": "a7,0,46,0,3pc|a2,0,46,0,3pc||",
                "French_Guiana": "at,0,55i,0,3pb|||",
                "French_Southern": "at,0,567,0,56b,0,247,0,577|||",
                "Galapagos": "at,0,57a|||",
                "Gambier": "at,0,57k|||",
                "Georgia": "a7,0,46,0,2lg|a2,0,46,0,2lg||",
                "Gilbert_Islands": "at,0,121,0,57m|||",
                "GMT": "57q,0,46,0,57s|||",
                "Greenland_Eastern": "a7,0,46,0,15v,0,23s|a2,0,46,0,15v,0,23s||",
                "Greenland_Western": "a7,0,46,0,128,0,23s|a2,0,46,0,128,0,23s||",
                "Guam": "at,0,585|||",
                "Gulf": "at,0,586|||",
                "Guyana": "at,0,3pb|||",
                "Hawaii_Aleutian": "a7,0,46,0,3pa,5,3p9,0,3p8|a2,0,46,0,3pa,5,3p9,0,3p8||",
                "Hong_Kong": "a7,0,46,0,3p7,0,3p6|a2,0,46,0,3p7,0,3p6||",
                "Hovd": "a7,0,46,0,3p5|a2,0,46,0,3p5||",
                "India": "at,0,58m|||",
                "Indian_Ocean": "at,0,58q,0,590|||",
                "Indochina": "at,0,591|||",
                "Indonesia_Central": "at,0,1it,0,2lh|||",
                "Indonesia_Eastern": "at,0,15v,0,2lh|||",
                "Indonesia_Western": "at,0,128,0,2lh|||",
                "Iran": "a7,0,46,0,3p4|a2,0,46,0,3p4||",
                "Irkutsk": "a7,0,46,0,3p3|a2,0,46,0,3p3||",
                "Israel": "a7,0,46,0,3p2|a2,0,46,0,3p2||",
                "Japan": "a7,0,46,0,3p1|a2,0,46,0,3p1||",
                "Kamchatka": "a7,0,46,0,3ov,5,3ou|a2,0,46,0,3ov,5,3ou||",
                "Kazakhstan_Eastern": "at,0,3pf,0,3ot|||",
                "Kazakhstan_Western": "at,0,59h,0,3ot|||",
                "Korea": "a7,0,46,0,3or|a2,0,46,0,3or||",
                "Kosrae": "at,0,59o|||",
                "Krasnoyarsk": "a7,0,46,0,3oq|a2,0,46,0,3oq||",
                "Kyrgystan": "at,0,5a5|||",
                "Line_Islands": "at,0,121,0,5ac|||",
                "Lord_Howe": "a7,0,46,0,3op,0,3oo|a2,0,46,0,3op,0,3oo||",
                "Macau": "a7,0,46,0,3on|a2,0,46,0,3on||",
                "Macquarie": "at,0,1o6,0,5au|||",
                "Magadan": "a7,0,46,0,3om|a2,0,46,0,3om||",
                "Malaysia": "at,0,5b6|||",
                "Maldives": "at,0,5b7|||",
                "Marquesas": "at,0,5be|||",
                "Marshall_Islands": "at,0,121,0,5bf|||",
                "Mauritius": "a7,0,46,0,3ol|a2,0,46,0,3ol||",
                "Mawson": "at,0,5br|||",
                "Mexico_Northwest": "a7,0,46,0,3ok,0,1vk|a2,0,46,0,3ok,0,1vk||",
                "Mexico_Pacific": "a7,0,46,0,3oj,0,1vk|a2,0,46,0,3oj,0,1vk||",
                "Mongolia": "a7,0,46,0,3oi,0,3oh|a2,0,46,0,3oi,0,3oh||",
                "Moscow": "a7,0,46,0,3og|a2,0,46,0,3og||",
                "Myanmar": "at,0,5ce|||",
                "Nauru": "at,0,5cl|||",
                "Nepal": "at,0,5cp|||",
                "New_Caledonia": "a7,0,46,0,1gs,0,3oe|a2,0,46,0,1gs,0,3oe||",
                "New_Zealand": "a7,0,46,0,1gs,0,3oc|a2,0,46,0,1gs,0,3oc||",
                "Newfoundland": "a7,0,46,0,1gs,0,3ob|a2,0,46,0,1gs,0,3ob||",
                "Niue": "at,0,5db|||",
                "Norfolk": "at,0,121,0,5dc|||",
                "Noronha": "a7,0,46,0,3oa,0,3o9,0,3o7|a2,0,46,0,3oa,0,3o9,0,3o7||",
                "North_Mariana": "at,0,121,0,5dh,0,5e4|||",
                "Novosibirsk": "a7,0,46,0,3o5|a2,0,46,0,3o5||",
                "Omsk": "a7,0,46,0,3o4|a2,0,46,0,3o4||",
                "Pakistan": "a7,0,46,0,3o3|a2,0,46,0,3o3||",
                "Palau": "at,0,5e9|||",
                "Papua_New_Guinea": "at,0,5ea,0,5eb,0,5ee|||",
                "Paraguay": "a7,0,46,0,3nu|a2,0,46,0,3nu||",
                "Peru": "a7,0,46,0,3ns|a2,0,46,0,3ns||",
                "Philippines": "a7,0,46,0,3nr|a2,0,46,0,3nr||",
                "Phoenix_Islands": "at,0,121,0,5f7|||",
                "Pierre_Miquelon": "a7,0,46,0,3nn,0,3nj,0,247,0,3nf|a2,0,46,0,3nn,0,3nj,0,247,0,3nf||",
                "Pitcairn": "at,0,5g6|||",
                "Ponape": "at,0,5gi|||",
                "Pyongyang": "at,0,5h8|||",
                "Reunion": "at,0,5he|||",
                "Rothera": "at,0,5hg|||",
                "Sakhalin": "a7,0,46,0,3ne|a2,0,46,0,3ne||",
                "Samara": "a7,0,46,0,5hu|a2,0,46,0,5i2||",
                "Samoa": "a7,0,46,0,3nc|a2,0,46,0,3nc||",
                "Seychelles": "at,0,5ih|||",
                "Singapore": "at,0,5ii|||",
                "Solomon": "at,0,121,0,5j7|||",
                "South_Georgia": "at,0,3qu,0,2lg|||",
                "Suriname": "at,0,5j8|||",
                "Syowa": "at,0,5j9|||",
                "Tahiti": "at,0,5ja|||",
                "Taipei": "a7,0,46,0,3nb|a2,0,46,0,3nb||",
                "Tajikistan": "at,0,5jc|||",
                "Tokelau": "at,0,5jd|||",
                "Tonga": "a7,0,46,0,3n8|a2,0,46,0,3n8||",
                "Truk": "at,0,5ji|||",
                "Turkmenistan": "a7,0,46,0,3n7|a2,0,46,0,3n7||",
                "Tuvalu": "at,0,5jl|||",
                "Uruguay": "a7,0,46,0,3n2|a2,0,46,0,3n2||",
                "Uzbekistan": "a7,0,46,0,3n0|a2,0,46,0,3n0||",
                "Vanuatu": "a7,0,46,0,3mv|a2,0,46,0,3mv||",
                "Venezuela": "at,0,5jq|||",
                "Vladivostok": "a7,0,46,0,3mt|a2,0,46,0,3mt||",
                "Volgograd": "a7,0,46,0,3ms|a2,0,46,0,3ms||",
                "Vostok": "at,0,5jv|||",
                "Wake": "at,0,1o6,0,5k0|||",
                "Wallis": "at,0,5k2,0,247,0,5k3|||",
                "Yakutsk": "a7,0,46,0,3mp|a2,0,46,0,3mp||",
                "Yekaterinburg": "a7,0,46,0,3mo|a2,0,46,0,3mo||"
            }
        },
        "en-001": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-150": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-AG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-AI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-AS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-AT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-AU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "17,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "hs,0,4,0,1|hs,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1|4lq|5mi",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1|5mj|5mk",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1|5ml|5mo",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1|5mp|5mq",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,1f,0,4,0,1|2v,0,1f,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,6,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,6,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1|5mr|5ms",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,c,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1|5mt|5n0",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,6,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,6,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BB": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-BZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1|ble|bld",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-CY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-DE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-DG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-DK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-DM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-ER": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-FI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-FJ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-FK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-FM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GB": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||c9v"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GD": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1||blc|",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-GY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1||blb|",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-HK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1|5nb|5ne",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-IE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||15a",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-IL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-IM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-IN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1||15a|",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-IO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-JE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-JM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-KE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-KI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-KN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-KY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-LC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-LR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-LS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1|5nb|5ne",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1|18r|16i",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MP": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-MY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1||1r5|",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NF": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-NZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1|4lq|5mi",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1|5mj|5mk",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1|5ml|5mo",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1|5mp|5mq",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1|bla|bl9",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1|5mr|5ms",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1|5mt|5n0",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-PW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-RW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SB": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SD": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1||1r5|",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1||2ad|",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1|h0|h4",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1|gu|gv",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1|h1|h2",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-SZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TV": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-TZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-UG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-UM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-US-POSIX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-VC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-VG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-VI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-VU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-WS": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-ZA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-ZM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en-ZW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1||vj|",
                "Africa_Eastern": "1r,0,1t,0,1||vp|",
                "Africa_Southern": "85,0,1t,0,4,0,1||vu|",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1|103|vq",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1||",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1||",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1||",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1||",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1||",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1||",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1||",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "en": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|c6,0,4,0,1||",
                "Europe/London": "|c1,0,6,0,1||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,0,4,0,1|3q,0,6,0,1||",
                "Afghanistan": "b6,0,1|||",
                "Africa_Central": "n,0,1t,0,1|||",
                "Africa_Eastern": "1r,0,1t,0,1|||",
                "Africa_Southern": "85,0,1t,0,4,0,1|||",
                "Africa_Western": "1g,0,1t,0,4,0,1|1g,0,1t,0,6,0,1||",
                "Alaska": "3p,0,4,0,1|3p,0,c,0,1|18f|18t",
                "Almaty": "56,0,4,0,1|56,0,6,0,1||",
                "Amazon": "5m,0,4,0,1|5m,0,6,0,1||",
                "America_Central": "n,0,4,0,1|n,0,c,0,1|19q|19p",
                "America_Eastern": "17,0,4,0,1|17,0,c,0,1|19o|19n",
                "America_Mountain": "5a,0,4,0,1|5a,0,c,0,1|18r|16i",
                "America_Pacific": "2h,0,4,0,1|2h,0,c,0,1|19l|19k",
                "Anadyr": "3l,0,4,0,1|3l,0,6,0,1||",
                "Apia": "3e,0,4,0,1|3e,0,c,0,1||",
                "Aqtau": "50,0,4,0,1|50,0,6,0,1||",
                "Aqtobe": "5o,0,4,0,1|5o,0,6,0,1||",
                "Arabian": "87,0,4,0,1|87,0,c,0,1||",
                "Argentina": "1e,0,4,0,1|1e,0,6,0,1||",
                "Argentina_Western": "t,0,1e,0,4,0,1|t,0,1e,0,6,0,1||",
                "Armenia": "4l,0,4,0,1|4l,0,6,0,1||",
                "Atlantic": "6g,0,4,0,1|6g,0,c,0,1|19i|190",
                "Australia_Central": "13,0,n,0,4,0,1|13,0,n,0,c,0,1||",
                "Australia_CentralWestern": "13,0,n,0,t,0,4,0,1|13,0,n,0,t,0,c,0,1||",
                "Australia_Eastern": "13,0,17,0,4,0,1|13,0,17,0,c,0,1||",
                "Australia_Western": "13,0,t,0,4,0,1|13,0,t,0,c,0,1||",
                "Azerbaijan": "7m,0,4,0,1|7m,0,6,0,1||",
                "Azores": "4r,0,4,0,1|4r,0,6,0,1||",
                "Bangladesh": "3n,0,4,0,1|3n,0,6,0,1||",
                "Bhutan": "as,0,1|||",
                "Bolivia": "aa,0,1|||",
                "Brasilia": "3a,0,4,0,1|3a,0,6,0,1||",
                "Brunei": "9o,0,a4,0,1|||",
                "Cape_Verde": "7c,0,3j,0,4,0,1|7c,0,3j,0,6,0,1||",
                "Casey": "b7,0,1|||",
                "Chamorro": "6j,0,4,0,1|||",
                "Chatham": "27,0,4,0,1|27,0,c,0,1||",
                "Chile": "49,0,4,0,1|49,0,6,0,1||",
                "China": "4h,0,4,0,1|4h,0,c,0,1||",
                "Choibalsan": "2q,0,4,0,1|2q,0,6,0,1||",
                "Christmas": "99,0,1f,0,1|||",
                "Cocos": "7v,0,o,0,1|||",
                "Colombia": "4m,0,4,0,1|4m,0,6,0,1||",
                "Cook": "2v,0,o,0,4,0,1|2v,0,o,0,c8,0,6,0,1||",
                "Cuba": "34,0,4,0,1|34,0,c,0,1||",
                "Davis": "5s,0,1|||",
                "DumontDUrville": "5b,5,5j,0,1|||",
                "East_Timor": "1r,0,7n,0,1|||",
                "Easter": "7t,0,1f,0,4,0,1|7t,0,1f,0,6,0,1||",
                "Ecuador": "ai,0,1|||",
                "Europe_Central": "n,0,18,0,4,0,1|n,0,18,0,6,0,1||",
                "Europe_Eastern": "17,0,18,0,4,0,1|17,0,18,0,6,0,1||",
                "Europe_Further_Eastern": "bu,5,c0,0,18,0,1|||",
                "Europe_Western": "t,0,18,0,4,0,1|t,0,18,0,6,0,1||",
                "Falkland": "4v,0,o,0,4,0,1|4v,0,o,0,6,0,1||",
                "Fiji": "4q,0,4,0,1|4q,0,6,0,1||",
                "French_Guiana": "84,0,am,0,1|||",
                "French_Southern": "84,0,c7,0,31,0,c4,0,1|||",
                "Galapagos": "ak,0,1|||",
                "Gambier": "6v,0,1|||",
                "Georgia": "2u,0,4,0,1|2u,0,6,0,1||",
                "Gilbert_Islands": "6q,0,o,0,1|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "1r,0,2k,0,4,0,1|1r,0,2k,0,6,0,1||",
                "Greenland_Western": "1g,0,2k,0,4,0,1|1g,0,2k,0,6,0,1||",
                "Guam": "83,0,4,0,1|||",
                "Gulf": "bn,0,4,0,1|||",
                "Guyana": "7a,0,1|||",
                "Hawaii_Aleutian": "4g,5,62,0,4,0,1|4g,5,62,0,c,0,1|17j|17l",
                "Hong_Kong": "2l,0,2p,0,4,0,1|2l,0,2p,0,6,0,1||",
                "Hovd": "2g,0,4,0,1|2g,0,6,0,1||",
                "India": "af,0,4,0,1|||",
                "Indian_Ocean": "br,0,bv,0,1|||",
                "Indochina": "9p,0,1|||",
                "Indonesia_Central": "n,0,2t,0,1|||",
                "Indonesia_Eastern": "17,0,2t,0,1|||",
                "Indonesia_Western": "t,0,2t,0,1|||",
                "Iran": "5g,0,4,0,1|5g,0,c,0,1||",
                "Irkutsk": "3h,0,4,0,1|3h,0,6,0,1||",
                "Israel": "4e,0,4,0,1|4e,0,c,0,1||",
                "Japan": "6o,0,4,0,1|6o,0,c,0,1||",
                "Kamchatka": "37,5,3k,0,4,0,1|37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "1r,0,45,0,1|||",
                "Kazakhstan_Western": "1g,0,45,0,1|||",
                "Korea": "88,0,4,0,1|88,0,c,0,1||",
                "Kosrae": "6f,0,1|||",
                "Krasnoyarsk": "41,0,4,0,1|41,0,6,0,1||",
                "Kyrgystan": "bl,0,1|||",
                "Lanka": "9c,0,1|||",
                "Line_Islands": "ac,0,o,0,1|||",
                "Lord_Howe": "1u,0,24,0,4,0,1|1u,0,24,0,c,0,1||",
                "Macau": "57,0,4,0,1|57,0,6,0,1||",
                "Macquarie": "6m,0,1f,0,1|||",
                "Magadan": "2o,0,4,0,1|2o,0,6,0,1||",
                "Malaysia": "bf,0,1|||",
                "Maldives": "9k,0,1|||",
                "Marquesas": "9e,0,1|||",
                "Marshall_Islands": "7o,0,o,0,1|||",
                "Mauritius": "4u,0,4,0,1|4u,0,6,0,1||",
                "Mawson": "63,0,1|||",
                "Mexico_Northwest": "80,0,5e,0,4,0,1|80,0,5e,0,c,0,1||",
                "Mexico_Pacific": "81,0,2h,0,4,0,1|81,0,2h,0,c,0,1||",
                "Mongolia": "4f,0,2m,0,4,0,1|4f,0,2m,0,6,0,1||",
                "Moscow": "71,0,4,0,1|71,0,6,0,1||",
                "Myanmar": "8v,0,1|||",
                "Nauru": "5v,0,1|||",
                "Nepal": "8u,0,1|||",
                "New_Caledonia": "1m,0,4o,0,4,0,1|1m,0,4o,0,6,0,1||",
                "New_Zealand": "1m,0,74,0,4,0,1|1m,0,74,0,c,0,1||",
                "Newfoundland": "53,0,4,0,1|53,0,c,0,1||",
                "Niue": "8g,0,1|||",
                "Norfolk": "6l,0,1f,0,1|||",
                "Noronha": "1s,0,2,0,23,0,4,0,1|1s,0,2,0,23,0,6,0,1||",
                "North_Mariana": "bm,0,bd,0,o,0,1|||",
                "Novosibirsk": "3b,0,4,0,1|3b,0,6,0,1||",
                "Omsk": "26,0,4,0,1|26,0,6,0,1||",
                "Pakistan": "3i,0,4,0,1|3i,0,6,0,1||",
                "Palau": "9b,0,1|||",
                "Papua_New_Guinea": "9a,0,1m,0,a8,0,1|||",
                "Paraguay": "3c,0,4,0,1|3c,0,6,0,1||",
                "Peru": "4n,0,4,0,1|4n,0,6,0,1||",
                "Philippines": "86,0,4,0,1|86,0,6,0,1||",
                "Phoenix_Islands": "90,0,o,0,1|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,4,0,1|7u,0,33,0,31,0,35,0,c,0,1||",
                "Pitcairn": "7e,0,1|||",
                "Ponape": "9s,0,1|||",
                "Pyongyang": "8a,0,1|||",
                "Qyzylorda": "4d,0,4,0,1|4d,0,6,0,1||",
                "Reunion": "b4,0,1|||",
                "Rothera": "5q,0,1|||",
                "Sakhalin": "55,0,4,0,1|55,0,6,0,1||",
                "Samara": "29,0,4,0,1|29,0,6,0,1||",
                "Samoa": "2c,0,4,0,1|2c,0,c,0,1||",
                "Seychelles": "8k,0,1|||",
                "Singapore": "bi,0,4,0,1|||",
                "Solomon": "b5,0,o,0,1|||",
                "South_Georgia": "85,0,2u,0,1|||",
                "Suriname": "8q,0,1|||",
                "Syowa": "65,0,1|||",
                "Tahiti": "8n,0,1|||",
                "Taipei": "3d,0,4,0,1|3d,0,c,0,1||",
                "Tajikistan": "bj,0,1|||",
                "Tokelau": "5d,0,1|||",
                "Tonga": "2j,0,4,0,1|2j,0,6,0,1||",
                "Truk": "6p,0,1|||",
                "Turkmenistan": "4t,0,4,0,1|4t,0,6,0,1||",
                "Tuvalu": "6h,0,1|||",
                "Uruguay": "4k,0,4,0,1|4k,0,6,0,1||",
                "Uzbekistan": "60,0,4,0,1|60,0,6,0,1||",
                "Vanuatu": "2f,0,4,0,1|2f,0,6,0,1||",
                "Venezuela": "7s,0,1|||",
                "Vladivostok": "28,0,4,0,1|28,0,6,0,1||",
                "Volgograd": "3f,0,4,0,1|3f,0,6,0,1||",
                "Vostok": "5t,0,1|||",
                "Wake": "5r,0,1f,0,1|||",
                "Wallis": "5f,0,31,0,5k,0,1|||",
                "Yakutsk": "3u,0,4,0,1|3u,0,6,0,1||",
                "Yekaterinburg": "5l,0,4,0,1|5l,0,6,0,1||"
            }
        },
        "eo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "es-419": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-AR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e|bl8|bl7",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48|bl6|bl5",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-BO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa||5pl|",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-BR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-CL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49|bl4|bl3",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-CO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m|bl2|bl1",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-CR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-CU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-DO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-EA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,al,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,al,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r|h0|h4",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r|gu|gv",
                "Europe_Further_Eastern": "8,0,2,0,4p,0,3r,0,2uu|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48|h1|h2",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,1gc|8,0,2,0,e,0,2,0,lt,5,1gc||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-EC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai||5ps|",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-GQ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,al,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,al,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r|h0|h4",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r|gu|gv",
                "Europe_Further_Eastern": "8,0,2,0,4p,0,3r,0,2uu|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48|h1|h2",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,1gc|8,0,2,0,e,0,2,0,lt,5,1gc||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-GT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-HN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-IC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,al,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,al,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r|h0|h4",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r|gu|gv",
                "Europe_Further_Eastern": "8,0,2,0,4p,0,3r,0,2uu|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48|h1|h2",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,1gc|8,0,2,0,e,0,2,0,lt,5,1gc||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-MX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,e,0,8iu||",
                "Europe/London": "|9,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "9,0,2,0,9d,0,n|||",
                "Africa_Eastern": "9,0,2,0,9d,0,8h|||",
                "Africa_Southern": "9,0,2,0,sf|||",
                "Africa_Western": "9,0,d,0,2,0,9d,0,of|9,0,2,0,e,0,2,0,9d,0,of||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "9,0,d,0,2,0,3e|9,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "9,0,d,0,2,0,hs|9,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "9,0,d,0,2,0,4l|9,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "9,0,d,0,2,0,4c,0,n|9,0,2,0,e,0,2,0,4c,0,n||",
                "Australia_CentralWestern": "9,0,d,0,2,0,4c,0,5pt|9,0,2,0,e,0,2,0,4c,0,5pt||",
                "Australia_Eastern": "9,0,d,0,2,0,4c,0,8h|9,0,2,0,e,0,2,0,4c,0,8h||",
                "Australia_Western": "9,0,d,0,2,0,4c,0,of|9,0,2,0,e,0,2,0,4c,0,of||",
                "Azerbaijan": "9,0,d,0,2,0,lp|9,0,2,0,e,0,2,0,lp||",
                "Azores": "9,0,d,0,2,0,32,0,4r|9,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "9,0,d,0,2,0,ck,0,3j|9,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,d,0,2,0,6j|||",
                "Chatham": "9,0,d,0,2,0,27|9,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "9,0,2,0,1c,0,av,0,2,0,vs|||",
                "Cocos": "9,0,2,0,32,0,b3,0,7v|||",
                "Colombia": "9,0,d,0,2,0,4m|9,0,2,0,e,0,2,0,4m||",
                "Cook": "9,0,d,0,2,0,32,0,b3,0,2v|9,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "9,0,d,0,2,0,1c,0,av,0,2,0,iq|9,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "9,0,2,0,ai|||",
                "Europe_Central": "9,0,d,0,2,0,4p,0,n|9,0,2,0,e,0,2,0,4p,0,n||",
                "Europe_Eastern": "9,0,d,0,2,0,4p,0,19,0,3it|9,0,2,0,e,0,2,0,4p,0,19,0,3it||",
                "Europe_Further_Eastern": "9,0,19,0,1ej,0,8h,0,2,0,4p|||",
                "Europe_Western": "9,0,d,0,2,0,4p,0,of|9,0,2,0,e,0,2,0,4p,0,of||",
                "Falkland": "9,0,d,0,2,0,32,0,b3,0,ju|9,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "9,0,d,0,2,0,m1|9,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "9,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "9,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "9,0,2,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,d,0,2,0,2u|9,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "9,0,2,0,32,0,b3,0,6q|||",
                "GMT": "9,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "9,0,d,0,19,0,oh|||",
                "Guyana": "9,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "9,0,19,0,5pv,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "9,0,d,0,2,0,3h|9,0,2,0,e,0,2,0,3h||",
                "Israel": "9,0,d,0,2,0,4e|9,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "9,0,2,0,m3,0,8h|||",
                "Kazakhstan_Western": "9,0,2,0,m3,0,of|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,d,0,2,0,41|9,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "9,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "9,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "9,0,d,0,2,0,1u,0,24|9,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "9,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "9,0,d,0,2,0,jh|9,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "9,0,2,0,32,0,b3,0,9e|||",
                "Marshall_Islands": "9,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "9,0,d,0,2,0,kt|9,0,2,0,e,0,2,0,kt||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "9,0,d,0,2,0,k6|9,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "9,0,d,0,2,0,an,0,4o|9,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "9,0,d,0,2,0,an,0,go|9,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,2,0,32,0,b3,0,6l|||",
                "Noronha": "9,0,d,0,2,0,1s,0,2,0,23|9,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "9,0,d,0,2,0,3b|9,0,2,0,e,0,2,0,3b||",
                "Omsk": "9,0,d,0,2,0,26|9,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "9,0,2,0,gt|||",
                "Papua_New_Guinea": "9,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "9,0,d,0,2,0,3c|9,0,2,0,e,0,2,0,3c||",
                "Peru": "9,0,d,0,2,0,ha|9,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "9,0,2,0,32,0,b3,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "9,0,2,0,s4|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,d,0,2,0,m4|9,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "9,0,d,0,2,0,2c|9,0,2,0,e,0,2,0,2c||",
                "Seychelles": "9,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "9,0,2,0,32,0,b3,0,s1|||",
                "South_Georgia": "9,0,2,0,2u,0,104|||",
                "Suriname": "9,0,2,0,mg|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "9,0,2,0,100|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,d,0,2,0,2j|9,0,2,0,e,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,d,0,2,0,lh|9,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,d,0,2,0,4k|9,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "9,0,d,0,2,0,lq|9,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "9,0,d,0,2,0,2f|9,0,2,0,e,0,2,0,2f||",
                "Venezuela": "9,0,2,0,7s|||",
                "Vladivostok": "9,0,d,0,2,0,28|9,0,2,0,e,0,2,0,28||",
                "Volgograd": "9,0,d,0,2,0,ff|9,0,2,0,e,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "9,0,d,0,2,0,3u|9,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "9,0,d,0,2,0,kh|9,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-NI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-PA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-PE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha|5q0|5q1",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-PH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,al,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,al,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r|h0|h4",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r|gu|gv",
                "Europe_Further_Eastern": "8,0,2,0,4p,0,3r,0,2uu|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48|h1|h2",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,1gc|8,0,2,0,e,0,2,0,lt,5,1gc||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-PR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-PY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-SV": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-US": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p|18f|18t",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r|19q|19p",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r|19o|19n",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls|18r|16i",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u|19l|19k",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk|19i|190",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa|17j|17l",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-UY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k|bl0|bkv",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es-VE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,b3,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,b3,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r||",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r||",
                "Europe_Further_Eastern": "n0,0,19,0,13i,0,sr,0,2,0,4p|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48||",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u|||",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,oa|8,0,2,0,e,0,2,0,lt,5,oa||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,2,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,12m|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s||bku|",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "es": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|8,0,2,0,e,0,2,0,pk||",
                "Europe/London": "|8,0,2,0,e,0,sc||"
            },
            "metazone": {
                "Acre": "9,0,d,0,2,0,3q|9,0,2,0,e,0,2,0,3q||",
                "Afghanistan": "8,0,2,0,t9|||",
                "Africa_Central": "8,0,2,0,9d,0,6r|||",
                "Africa_Eastern": "8,0,2,0,9d,0,3r|||",
                "Africa_Southern": "8,0,2,0,sf|||",
                "Africa_Western": "8,0,d,0,2,0,9d,0,48|8,0,2,0,e,0,2,0,9d,0,48||",
                "Alaska": "8,0,d,0,2,0,3p|8,0,2,0,e,0,2,0,3p||",
                "Amazon": "8,0,d,0,19,0,bg|8,0,2,0,e,0,19,0,bg||",
                "America_Central": "8,0,d,0,6r|8,0,2,0,e,0,6r||",
                "America_Eastern": "8,0,d,0,3r|8,0,2,0,e,0,3r||",
                "America_Mountain": "8,0,d,0,2,0,32,0,ls|8,0,2,0,e,0,2,0,32,0,ls||",
                "America_Pacific": "8,0,d,0,19,0,9u|8,0,2,0,e,0,19,0,9u||",
                "Anadyr": "9,0,d,0,2,0,3l|9,0,2,0,e,0,2,0,3l||",
                "Apia": "8,0,d,0,2,0,3e|n0,0,2,0,e,0,2,0,3e||",
                "Aqtau": "9,0,d,0,2,0,i4|9,0,2,0,e,0,2,0,i4||",
                "Aqtobe": "9,0,d,0,2,0,im|9,0,2,0,e,0,2,0,im||",
                "Arabian": "8,0,d,0,2,0,hs|8,0,2,0,e,0,2,0,hs||",
                "Argentina": "8,0,d,0,2,0,1e|8,0,2,0,e,0,2,0,1e||",
                "Argentina_Western": "8,0,d,0,2,0,1e,0,48|8,0,2,0,e,0,2,0,1e,0,48||",
                "Armenia": "8,0,d,0,2,0,4l|8,0,2,0,e,0,2,0,4l||",
                "Atlantic": "8,0,d,0,19,0,jk|8,0,2,0,e,0,19,0,jk||",
                "Australia_Central": "8,0,d,0,2,0,4c,0,6r|8,0,2,0,e,0,2,0,4c,0,6r||",
                "Australia_CentralWestern": "8,0,d,0,2,0,4c,0,m9|8,0,2,0,e,0,2,0,4c,0,m9||",
                "Australia_Eastern": "8,0,d,0,2,0,4c,0,3r|8,0,2,0,e,0,2,0,4c,0,3r||",
                "Australia_Western": "8,0,d,0,2,0,4c,0,48|8,0,2,0,e,0,2,0,4c,0,48||",
                "Azerbaijan": "8,0,d,0,2,0,lp|8,0,2,0,e,0,2,0,lp||",
                "Azores": "8,0,d,0,2,0,32,0,4r|8,0,2,0,e,0,2,0,32,0,4r||",
                "Bangladesh": "8,0,d,0,2,0,je|8,0,2,0,e,0,2,0,je||",
                "Bhutan": "8,0,2,0,s5|||",
                "Bolivia": "8,0,2,0,aa|||",
                "Brasilia": "8,0,d,0,2,0,3a|8,0,2,0,e,0,2,0,3a||",
                "Brunei": "8,0,2,0,gs|||",
                "Cape_Verde": "8,0,d,0,2,0,ck,0,3j|8,0,2,0,e,0,2,0,ck,0,3j||",
                "Chamorro": "8,0,d,0,2,0,6j|||",
                "Chatham": "8,0,d,0,2,0,27|8,0,2,0,e,0,2,0,27||",
                "Chile": "8,0,d,0,2,0,49|8,0,2,0,e,0,2,0,49||",
                "China": "8,0,d,0,2,0,4h|8,0,2,0,e,0,2,0,4h||",
                "Choibalsan": "8,0,d,0,2,0,2q|8,0,2,0,e,0,2,0,2q||",
                "Christmas": "8,0,2,0,1c,0,10f,0,2,0,vs|||",
                "Cocos": "8,0,2,0,32,0,al,0,7v|||",
                "Colombia": "8,0,d,0,2,0,4m|8,0,2,0,e,0,2,0,4m||",
                "Cook": "8,0,d,0,2,0,32,0,al,0,2v|8,0,2,0,e,0,r1,0,2,0,32,0,al,0,2v||",
                "Cuba": "8,0,d,0,2,0,34|8,0,2,0,e,0,2,0,34||",
                "Davis": "8,0,2,0,5s|||",
                "DumontDUrville": "8,0,2,0,5b,5,5j|||",
                "East_Timor": "8,0,2,0,7n,0,8h|||",
                "Easter": "8,0,d,0,2,0,1c,0,av,0,2,0,iq|8,0,2,0,e,0,2,0,1c,0,av,0,2,0,iq||",
                "Ecuador": "8,0,2,0,ai|||",
                "Europe_Central": "8,0,d,0,2,0,4p,0,6r|8,0,2,0,e,0,2,0,4p,0,6r|h0|h4",
                "Europe_Eastern": "8,0,d,0,2,0,4p,0,3r|8,0,2,0,e,0,2,0,4p,0,3r|gu|gv",
                "Europe_Further_Eastern": "8,0,2,0,4p,0,3r,0,2uu|||",
                "Europe_Western": "8,0,d,0,2,0,4p,0,48|8,0,2,0,e,0,2,0,4p,0,48|h1|h2",
                "Falkland": "8,0,d,0,2,0,32,0,b3,0,ju|8,0,2,0,e,0,2,0,32,0,b3,0,ju||",
                "Fiji": "8,0,d,0,2,0,m1|8,0,2,0,e,0,2,0,m1||",
                "French_Guiana": "8,0,2,0,1c,0,qk,0,nf|||",
                "French_Southern": "8,0,2,0,32,0,vv,0,101,0,bk,0,sd,0,pf|||",
                "Galapagos": "8,0,2,0,cp|||",
                "Gambier": "8,0,2,0,6v|||",
                "Georgia": "8,0,d,0,2,0,2u|8,0,2,0,e,0,2,0,2u||",
                "Gilbert_Islands": "8,0,2,0,32,0,b3,0,6q|||",
                "GMT": "8,0,19,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "8,0,d,0,2,0,b2,0,3r|8,0,2,0,e,0,2,0,b2,0,3r||",
                "Greenland_Western": "8,0,d,0,2,0,b2,0,48|8,0,2,0,e,0,2,0,b2,0,48||",
                "Guam": "9,0,d,0,2,0,83|||",
                "Gulf": "8,0,d,0,19,0,oh|||",
                "Guyana": "8,0,2,0,7a|||",
                "Hawaii_Aleutian": "8,0,d,0,2,0,lt,5,1gc|8,0,2,0,e,0,2,0,lt,5,1gc||",
                "Hong_Kong": "8,0,d,0,2,0,2l,0,2p|8,0,2,0,e,0,2,0,2l,0,2p||",
                "Hovd": "8,0,d,0,2,0,2g|8,0,2,0,e,0,2,0,2g||",
                "India": "8,0,d,0,2,0,1c,0,af|||",
                "Indian_Ocean": "8,0,19,0,10h,0,pa|||",
                "Indochina": "8,0,2,0,9p|||",
                "Indonesia_Central": "8,0,2,0,2t,0,6r|||",
                "Indonesia_Eastern": "8,0,2,0,2t,0,3r|||",
                "Indonesia_Western": "8,0,2,0,2t,0,48|||",
                "Iran": "8,0,d,0,2,0,k1|8,0,2,0,e,0,2,0,k1||",
                "Irkutsk": "8,0,d,0,2,0,3h|8,0,2,0,e,0,2,0,3h||",
                "Israel": "8,0,d,0,2,0,4e|8,0,2,0,e,0,2,0,4e||",
                "Japan": "8,0,d,0,2,0,lu|8,0,2,0,e,0,2,0,lu||",
                "Kamchatka": "9,0,2,0,d,0,2,0,lr|9,0,2,0,e,0,2,0,lr||",
                "Kazakhstan_Eastern": "8,0,2,0,m3,0,3r|||",
                "Kazakhstan_Western": "8,0,2,0,m3,0,48|||",
                "Korea": "8,0,d,0,2,0,hf|8,0,2,0,e,0,2,0,hf||",
                "Kosrae": "8,0,2,0,6f|||",
                "Krasnoyarsk": "8,0,d,0,2,0,41|8,0,2,0,e,0,2,0,41||",
                "Kyrgystan": "8,0,2,0,sp|||",
                "Lanka": "9,0,2,0,me,0,9c|||",
                "Line_Islands": "8,0,2,0,32,0,102,0,vt|||",
                "Lord_Howe": "8,0,d,0,2,0,1u,0,24|8,0,2,0,e,0,2,0,1u,0,24||",
                "Macau": "9,0,d,0,2,0,ip|9,0,2,0,e,0,2,0,ip||",
                "Macquarie": "8,0,2,0,1c,0,av,0,6m|||",
                "Magadan": "8,0,d,0,2,0,jh|8,0,2,0,e,0,2,0,jh||",
                "Malaysia": "8,0,2,0,sh|||",
                "Maldives": "8,0,2,0,ov|||",
                "Marquesas": "8,0,2,0,9e|||",
                "Marshall_Islands": "8,0,2,0,32,0,al,0,7o|||",
                "Mauritius": "8,0,d,0,2,0,kt|8,0,2,0,e,0,2,0,kt||",
                "Mawson": "8,0,2,0,63|||",
                "Mexico_Northwest": "8,0,d,0,19,0,jn,0,2,0,au|8,0,2,0,e,0,19,0,jn,0,2,0,au||",
                "Mexico_Pacific": "8,0,d,0,19,0,9u,0,2,0,au|8,0,2,0,e,0,19,0,9u,0,2,0,au||",
                "Mongolia": "8,0,d,0,2,0,ke,0,2m|8,0,2,0,e,0,2,0,ke,0,2m||",
                "Moscow": "8,0,d,0,2,0,k6|8,0,2,0,e,0,2,0,k6||",
                "Myanmar": "8,0,2,0,ra|||",
                "Nauru": "8,0,2,0,5v|||",
                "Nepal": "8,0,2,0,8u|||",
                "New_Caledonia": "8,0,d,0,2,0,an,0,4o|8,0,2,0,e,0,2,0,an,0,4o||",
                "New_Zealand": "8,0,d,0,2,0,an,0,go|8,0,2,0,e,0,2,0,an,0,go||",
                "Newfoundland": "8,0,d,0,2,0,h5|8,0,2,0,e,0,2,0,h5||",
                "Niue": "8,0,2,0,8g|||",
                "Norfolk": "8,0,2,0,1c,0,av,0,6l|||",
                "Noronha": "8,0,d,0,2,0,1s,0,2,0,23|8,0,2,0,e,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,2,0,32,0,al,0,vm,0,19,0,p4|||",
                "Novosibirsk": "8,0,d,0,2,0,3b|8,0,2,0,e,0,2,0,3b||",
                "Omsk": "8,0,d,0,2,0,26|8,0,2,0,e,0,2,0,26||",
                "Pakistan": "8,0,d,0,2,0,lv|8,0,2,0,e,0,2,0,lv||",
                "Palau": "8,0,2,0,gt|||",
                "Papua_New_Guinea": "8,0,2,0,re,0,an,0,a8|||",
                "Paraguay": "8,0,d,0,2,0,3c|8,0,2,0,e,0,2,0,3c||",
                "Peru": "8,0,d,0,2,0,ha|8,0,2,0,e,0,2,0,ha||",
                "Philippines": "8,0,d,0,2,0,g0|8,0,2,0,e,0,2,0,g0||",
                "Phoenix_Islands": "8,0,2,0,32,0,al,0,pd|||",
                "Pierre_Miquelon": "8,0,d,0,2,0,io,0,gp,0,bk,0,m0|8,0,2,0,e,0,2,0,io,0,gp,0,bk,0,m0||",
                "Pitcairn": "8,0,2,0,7e|||",
                "Ponape": "8,0,2,0,e5|||",
                "Pyongyang": "8,0,2,0,8a|||",
                "Qyzylorda": "9,0,d,0,2,0,4d|9,0,2,0,e,0,2,0,4d||",
                "Reunion": "8,0,2,0,s4|||",
                "Rothera": "8,0,2,0,5q|||",
                "Sakhalin": "8,0,d,0,2,0,m4|8,0,2,0,e,0,2,0,m4||",
                "Samara": "9,0,d,0,2,0,29|9,0,2,0,e,0,2,0,29||",
                "Samoa": "8,0,d,0,2,0,2c|8,0,2,0,e,0,2,0,2c||",
                "Seychelles": "8,0,2,0,8k|||",
                "Singapore": "8,0,2,0,m8|||",
                "Solomon": "8,0,2,0,32,0,al,0,s1|||",
                "South_Georgia": "8,0,2,0,2u,0,104|||",
                "Suriname": "8,0,2,0,mg|||",
                "Syowa": "8,0,2,0,65|||",
                "Tahiti": "8,0,2,0,qr|||",
                "Taipei": "8,0,d,0,2,0,lf|8,0,2,0,e,0,2,0,lf||",
                "Tajikistan": "8,0,2,0,100|||",
                "Tokelau": "8,0,2,0,5d|||",
                "Tonga": "8,0,d,0,2,0,2j|8,0,2,0,e,0,2,0,2j||",
                "Truk": "8,0,2,0,6p|||",
                "Turkmenistan": "8,0,d,0,2,0,lh|8,0,2,0,e,0,2,0,lh||",
                "Tuvalu": "8,0,2,0,6h|||",
                "Uruguay": "8,0,d,0,2,0,4k|8,0,2,0,e,0,2,0,4k||",
                "Uzbekistan": "8,0,d,0,2,0,lq|8,0,2,0,e,0,2,0,lq||",
                "Vanuatu": "8,0,d,0,2,0,2f|8,0,2,0,e,0,2,0,2f||",
                "Venezuela": "8,0,2,0,7s|||",
                "Vladivostok": "8,0,d,0,2,0,28|8,0,2,0,e,0,2,0,28||",
                "Volgograd": "8,0,d,0,2,0,ff|8,0,2,0,e,0,2,0,ff||",
                "Vostok": "8,0,2,0,5t|||",
                "Wake": "8,0,2,0,1c,0,av,0,5r|||",
                "Wallis": "8,0,2,0,5f,0,bk,0,5k|||",
                "Yakutsk": "8,0,d,0,2,0,3u|8,0,2,0,e,0,2,0,3u||",
                "Yekaterinburg": "8,0,d,0,2,0,kh|8,0,2,0,e,0,2,0,kh||"
            }
        },
        "et": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ca1,0,el||",
                "Europe/London": "|ca2,0,el||"
            },
            "metazone": {
                "Acre": "3q,0,e8|3q,0,el||",
                "Afghanistan": "bkt,0,i2|||",
                "Africa_Central": "1oo,5,2va,0,i2|||",
                "Africa_Eastern": "1on,5,2va,0,i2|||",
                "Africa_Southern": "5qc,5,2va,0,e8|||",
                "Africa_Western": "1e6,5,2va,0,e8|1e6,5,2va,0,el||",
                "Alaska": "3p,0,e8|3p,0,el||",
                "Almaty": "5qe,0,e8|5qe,0,el||",
                "Amazon": "5qf,0,e8|5qf,0,el||",
                "America_Central": "1oo,5,5qg,0,e8|1oo,5,5qg,0,el||",
                "America_Eastern": "5qh,0,e8|5qh,0,el||",
                "America_Mountain": "5qi,0,e8|5qi,0,el||",
                "America_Pacific": "3mk,0,2ve,0,e8|3mk,0,2ve,0,el||",
                "Anadyr": "5ql,0,e8|5ql,0,el||",
                "Apia": "3e,0,e8|3e,0,el||",
                "Aqtau": "i4,0,e8|i4,0,el||",
                "Aqtobe": "2gg,0,e8|2gg,0,el||",
                "Arabian": "5qn,0,e8|5qn,0,el||",
                "Argentina": "1e,0,e8|1e,0,el||",
                "Argentina_Western": "1e6,5,1e,0,e8|1e6,5,1e,0,el||",
                "Armenia": "5qo,0,e8|5qo,0,el||",
                "Atlantic": "5qp,0,e8|5qp,0,el||",
                "Australia_Central": "1oo,5,1ti,0,e8|1oo,5,1ti,0,el||",
                "Australia_CentralWestern": "1oo,5,1e6,0,1ti,0,e8|1oo,5,1e6,0,1ti,0,el||",
                "Australia_Eastern": "1on,5,1ti,0,e8|1on,5,1ti,0,el||",
                "Australia_Western": "1e6,5,1ti,0,e8|1e6,5,1ti,0,el||",
                "Azerbaijan": "5qr,0,e8|5qr,0,el||",
                "Azores": "5qs,0,e8|5qs,0,el||",
                "Bangladesh": "5qt,0,e8|5qt,0,el||",
                "Bhutan": "bks,0,i2|||",
                "Bolivia": "bkr,0,i2|||",
                "Brasilia": "5r0,0,e8|5r0,0,el||",
                "Brunei": "9o,0,i2|||",
                "Cape_Verde": "5r1,0,e8|5r1,0,el||",
                "Chamorro": "bkq,0,e8|||",
                "Chatham": "5r3,0,e8|5r3,0,el||",
                "Chile": "5r4,0,e8|5r4,0,el||",
                "China": "5r5,0,e8|5r5,0,el||",
                "Choibalsan": "5r6,0,e8|5r6,0,el||",
                "Christmas": "bkp,0,i2|||",
                "Cocos": "bko,0,i2|||",
                "Colombia": "4m,0,e8|4m,0,el||",
                "Cook": "5r9,0,29d,0,e8|5r9,0,29d,0,bkn,0,el||",
                "Cuba": "5rc,0,e8|5rc,0,el||",
                "Davis": "bkm,0,i2|||",
                "DumontDUrville": "5b,5,bkl,0,i2|||",
                "East_Timor": "1on,5,bkk,0,i2|||",
                "Easter": "5rg,0,e8|5rg,0,el||",
                "Ecuador": "bkj,0,i2|||",
                "Europe_Central": "1oo,5,2g2,0,e8|1oo,5,2g2,0,el||",
                "Europe_Eastern": "1on,5,2g2,0,e8|1on,5,2g2,0,el||",
                "Europe_Further_Eastern": "bki,0,1pq,0,bkh,0,i2|||",
                "Europe_Western": "1e6,5,2g2,0,e8|1e6,5,2g2,0,el||",
                "Falkland": "5rm,0,29d,0,e8|5rm,0,29d,0,el||",
                "Fiji": "1fh,0,e8|1fh,0,el||",
                "French_Guiana": "5ro,0,bkg,0,i2|||",
                "French_Southern": "5ro,0,bkf,0,1pq,0,bke,0,i2|||",
                "Galapagos": "bkd,0,i2|||",
                "Gambier": "bkc,0,i2|||",
                "Georgia": "5ru,0,e8|5ru,0,el||",
                "Gilbert_Islands": "bkb,0,29d,0,i2|||",
                "GMT": "bka,0,i2|||",
                "Greenland_Eastern": "1on,5,3m5,0,e8|1on,5,3m5,0,el||",
                "Greenland_Western": "1e6,5,3m5,0,e8|1e6,5,3m5,0,el||",
                "Guam": "5s2,0,e8|||",
                "Gulf": "bk9,0,bk8,0,e8|||",
                "Guyana": "7a,0,i2|||",
                "Hawaii_Aleutian": "4g,5,5s5,0,e8|4g,5,5s5,0,el||",
                "Hong_Kong": "5s6,0,e8|5s6,0,el||",
                "Hovd": "5s7,0,e8|5s7,0,el||",
                "India": "af,0,i2|||",
                "Indian_Ocean": "af,0,2ve,0,i2|||",
                "Indochina": "bk7,0,i2|||",
                "Indonesia_Central": "1oo,5,4lv,0,i2|||",
                "Indonesia_Eastern": "1on,5,4lv,0,i2|||",
                "Indonesia_Western": "1e6,5,4lv,0,i2|||",
                "Iran": "5sa,0,e8|5sa,0,el||",
                "Irkutsk": "1uo,0,e8|1uo,0,el||",
                "Israel": "5sb,0,e8|5sb,0,el||",
                "Japan": "5sc,0,e8|5sc,0,el||",
                "Kamchatka": "5sd,0,e8|5sd,0,el||",
                "Kazakhstan_Eastern": "1on,5,5se,0,i2|||",
                "Kazakhstan_Western": "1e6,5,5se,0,i2|||",
                "Korea": "qf,0,e8|qf,0,el||",
                "Kosrae": "6f,0,i2|||",
                "Krasnoyarsk": "1un,0,e8|1un,0,el||",
                "Kyrgystan": "bk6,0,i2|||",
                "Line_Islands": "bk5,0,29d,0,i2|||",
                "Lord_Howe": "1u,0,5sh,0,e8|1u,0,5sh,0,el||",
                "Macquarie": "6m,0,bk4,0,i2|||",
                "Magadan": "5sj,0,e8|5sj,0,el||",
                "Malaysia": "3lm,0,bk3|||",
                "Maldives": "bk2,0,i2|||",
                "Marquesas": "bk1,0,i2|||",
                "Marshall_Islands": "bk0,0,5sp,0,i2|||",
                "Mauritius": "5sq,0,e8|5sq,0,el||",
                "Mawson": "bjv,0,i2|||",
                "Mexico_Northwest": "5ss,5,3li,0,e8|5ss,5,3li,0,el||",
                "Mexico_Pacific": "3li,0,3mk,0,2ve,0,e8|3li,0,3mk,0,2ve,0,el||",
                "Mongolia": "5su,0,e8|5su,0,el||",
                "Moscow": "pi,0,e8|pi,0,el||",
                "Myanmar": "bju,0,i2|||",
                "Nauru": "5v,0,i2|||",
                "Nepal": "bjt,0,i2|||",
                "New_Caledonia": "30g,5,5t2,0,e8|30g,5,5t2,0,el||",
                "New_Zealand": "30g,5,5t3,0,e8|30g,5,5t3,0,el||",
                "Newfoundland": "5t4,0,e8|5t4,0,el||",
                "Niue": "8g,0,i2|||",
                "Norfolk": "bjs,0,29d,0,i2|||",
                "Noronha": "1s,0,2,0,23,0,e8|1s,0,2,0,23,0,el||",
                "Novosibirsk": "3lg,0,e8|3lg,0,el||",
                "Omsk": "1ug,0,e8|1ug,0,el||",
                "Pakistan": "5t7,0,e8|5t7,0,el||",
                "Palau": "bjr,0,i2|||",
                "Papua_New_Guinea": "bjq,0,30g,5,a8,0,i2|||",
                "Paraguay": "3c,0,e8|3c,0,el||",
                "Peru": "5ta,0,e8|5ta,0,el||",
                "Philippines": "5tb,0,e8|5tb,0,el||",
                "Phoenix_Islands": "bjp,0,i2|||",
                "Pierre_Miquelon": "8i,5,5td,0,1pq,0,5te,0,e8|8i,5,5td,0,1pq,0,5te,0,el||",
                "Pitcairn": "bjo,0,i2|||",
                "Ponape": "e5,0,i2|||",
                "Pyongyang": "bjn,0,i2|||",
                "Qyzylorda": "5th,0,e8|5th,0,el||",
                "Reunion": "bjm,0,i2|||",
                "Rothera": "5q,0,i2|||",
                "Sakhalin": "5tj,0,e8|5tj,0,el||",
                "Samara": "29,0,e8|29,0,el||",
                "Samoa": "2c,0,e8|2c,0,el||",
                "Seychelles": "bjl,0,i2|||",
                "Singapore": "bjk,0,e8|||",
                "Solomon": "bjj,0,5sp,0,i2|||",
                "South_Georgia": "5qc,5,2u,0,i2|||",
                "Suriname": "8q,0,i2|||",
                "Syowa": "65,0,i2|||",
                "Tahiti": "8n,0,i2|||",
                "Taipei": "3d,0,e8|3d,0,el||",
                "Tajikistan": "bji,0,i2|||",
                "Tokelau": "5d,0,i2|||",
                "Tonga": "2j,0,e8|2j,0,el||",
                "Truk": "bjh,0,i2|||",
                "Turkmenistan": "5tp,0,e8|5tp,0,el||",
                "Tuvalu": "6h,0,i2|||",
                "Uruguay": "4k,0,e8|4k,0,el||",
                "Uzbekistan": "5tq,0,e8|5tq,0,el||",
                "Vanuatu": "2f,0,e8|2f,0,el||",
                "Venezuela": "7s,0,i2|||",
                "Vladivostok": "5tr,0,e8|5tr,0,el||",
                "Volgograd": "5ts,0,e8|5ts,0,el||",
                "Vostok": "bjg,0,i2|||",
                "Wake": "bjf,0,i2|||",
                "Wallis": "bje,0,1pq,0,5k,0,i2|||",
                "Yakutsk": "1u5,0,e8|1u5,0,el||",
                "Yekaterinburg": "bjd,0,e8|bjc,0,el||"
            }
        },
        "eu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ca3,0,17f,0,f6||",
                "Europe/London": "|ca4,0,fv,0,b8||"
            },
            "metazone": {
                "Afghanistan": "bjb,0,b8|||",
                "Africa_Central": "c5,0,1q2,0,b8|||",
                "Africa_Eastern": "c5,0,1eq,0,b8|||",
                "Africa_Southern": "c5,0,bja,0,b8|||",
                "Africa_Western": "c5,0,1e5,0,hm,0,f6|c5,0,1e5,0,fv,0,b8||",
                "Alaska": "5ub,0,17f,0,f6|5ub,0,fv,0,13l||",
                "Amazon": "5ue,0,17f,0,f6|5ue,0,fv,0,13l||",
                "America_Central": "1uv,0,1v0,0,1q2,0,hm,0,f6|1uv,0,1v0,0,1q2,0,fv,0,13l||",
                "America_Eastern": "1uv,0,1v0,0,1eq,0,17f,0,f6|1uv,0,1v0,0,1eq,0,fv,0,13l||",
                "America_Mountain": "1uv,0,1v0,0,5uh,0,17f,0,f6|1uv,0,1v0,0,5uh,0,fv,0,13l||",
                "America_Pacific": "1uv,0,1v0,0,3la,0,17f,0,f6|1uv,0,1v0,0,3la,0,fv,0,13l||",
                "Anadyr": "5uj,0,hm,0,f6|5uj,0,fv,0,b8||",
                "Apia": "5uk,0,hm,0,f6|5uk,0,fv,0,b8||",
                "Arabian": "5ul,0,hm,0,f6|5ul,0,fv,0,b8||",
                "Argentina": "5um,0,17f,0,f6|5um,0,fv,0,13l||",
                "Argentina_Western": "1e,0,1e5,0,13l,0,f6|1e,0,1e5,0,fv,0,13l||",
                "Armenia": "5un,0,hm,0,f6|5un,0,fv,0,b8||",
                "Atlantic": "5uo,0,17f,0,f6|5uo,0,fv,0,13l||",
                "Australia_Central": "4c,0,1q2,0,hm,0,f6|4c,0,1q2,0,fv,0,b8||",
                "Australia_CentralWestern": "4c,0,5up,5,1e5,0,hm,0,f6|4c,0,5up,5,1e5,0,fv,0,b8||",
                "Australia_Eastern": "4c,0,1eq,0,hm,0,f6|4c,0,1eq,0,fv,0,b8||",
                "Australia_Western": "4c,0,1e5,0,hm,0,f6|4c,0,1e5,0,fv,0,b8||",
                "Azerbaijan": "5uq,0,hm,0,f6|5uq,0,fv,0,b8||",
                "Azores": "5ur,0,hm,0,f6|5ur,0,fv,0,b8||",
                "Bangladesh": "5us,0,hm,0,f6|5us,0,fv,0,b8||",
                "Bhutan": "bj9,0,b8|||",
                "Bolivia": "bj8,0,13l|||",
                "Brasilia": "5uv,0,hm,0,f6|5uv,0,fv,0,b8||",
                "Brunei": "9o,0,bj7,0,b8|||",
                "Cape_Verde": "ck,0,5v1,0,hm,0,f6|ck,0,5v1,0,fv,0,b8||",
                "Chamorro": "bj6,0,hm,0,f6|||",
                "Chatham": "5v3,0,hm,0,f6|5v3,0,fv,0,b8||",
                "Chile": "5v4,0,hm,0,f6|5v4,0,fv,0,b8||",
                "China": "5v5,0,hm,0,f6|5v5,0,fv,0,b8||",
                "Choibalsan": "5v6,0,hm,0,f6|5v6,0,fv,0,b8||",
                "Christmas": "99,0,31a,0,b8|||",
                "Cocos": "7v,0,19m,0,b8|||",
                "Colombia": "5v9,0,hm,0,f6|5v9,0,fv,0,b8||",
                "Cook": "2v,0,19m,0,hm,0,f6|2v,0,19m,0,bj5,0,1q2,0,b8||",
                "Cuba": "5vb,0,17f,0,f6|5vb,0,fv,0,13l||",
                "Davis": "bj4,0,b8|||",
                "DumontDUrville": "5b,5,bj3,0,b8|||",
                "East_Timor": "bj2,0,bj1,0,b8|||",
                "Easter": "5vg,0,31a,0,hm,0,f6|5vg,0,31a,0,fv,0,b8||",
                "Ecuador": "bj0,0,b8|||",
                "Europe_Central": "4p,0,1q2,0,hm,0,f6|4p,0,1q2,0,fv,0,b8|h0|h4",
                "Europe_Eastern": "4p,0,1eq,0,hm,0,f6|4p,0,1eq,0,fv,0,b8|gu|gv",
                "Europe_Further_Eastern": "biv,0,biu,0,bit,0,b8|||",
                "Europe_Western": "4p,0,1e5,0,hm,0,f6|4p,0,1e5,0,fv,0,b8|h1|h2",
                "Falkland": "4v,0,19m,0,hm,0,f6|4v,0,19m,0,fv,0,b8||",
                "Fiji": "5vl,0,hm,0,f6|5vl,0,fv,0,b8||",
                "French_Guiana": "7a,0,bis,0,b8|||",
                "French_Southern": "bir,0,biq,0,bip,0,3l0,0,bio,0,13l|||",
                "Galapagos": "bin,0,b8|||",
                "Gambier": "bim,0,b8|||",
                "Georgia": "4m0,0,hm,0,f6|4m0,0,fv,0,b8||",
                "Gilbert_Islands": "6q,0,19m,0,b8|||",
                "GMT": "5u,0,bil,0,b8||ah|",
                "Greenland_Eastern": "b2,0,1eq,0,17f,0,f6|b2,0,1eq,0,fv,0,13l||",
                "Greenland_Western": "b2,0,1e5,0,17f,0,f6|b2,0,1e5,0,fv,0,13l||",
                "Gulf": "bij,0,hm,0,f6|||",
                "Guyana": "bii,0,b8|||",
                "Hawaii_Aleutian": "4g,5,602,0,19m,0,17f,0,f6|4g,5,602,0,19m,0,fv,0,13l||",
                "Hong_Kong": "2l,0,603,0,hm,0,f6|2l,0,603,0,fv,0,b8||",
                "Hovd": "604,0,hm,0,f6|604,0,fv,0,b8||",
                "India": "bif,0,b8|||",
                "Indian_Ocean": "bie,0,bia,0,b8|||",
                "Indochina": "bi8,0,b8|||",
                "Indonesia_Central": "2t,0,1q2,0,b8|||",
                "Indonesia_Eastern": "2t,0,1eq,0,b8|||",
                "Indonesia_Western": "2t,0,1e5,0,b8|||",
                "Iran": "609,0,hm,0,f6|609,0,fv,0,b8||",
                "Irkutsk": "60a,0,hm,0,f6|60a,0,fv,0,b8||",
                "Israel": "60b,0,hm,0,f6|60b,0,fv,0,b8||",
                "Japan": "60c,0,hm,0,f6|60c,0,fv,0,b8||",
                "Kamchatka": "37,5,60d,0,hm,0,f6|37,5,60d,0,fv,0,b8||",
                "Kazakhstan_Eastern": "45,0,1eq,0,b8|||",
                "Kazakhstan_Western": "45,0,1e5,0,b8|||",
                "Korea": "60e,0,hm,0,f6|60e,0,fv,0,b8||",
                "Kosrae": "bi7,0,b8|||",
                "Krasnoyarsk": "60g,0,hm,0,f6|60g,0,fv,0,b8||",
                "Kyrgystan": "bi6,0,b8|||",
                "Line_Islands": "ac,0,19m,0,b8|||",
                "Lord_Howe": "1u,0,60i,0,hm,0,f6|1u,0,60i,0,fv,0,b8||",
                "Macquarie": "6m,0,31a,0,b8|||",
                "Magadan": "60j,0,hm,0,f6|60j,0,fv,0,b8||",
                "Malaysia": "bi5,0,b8|||",
                "Maldives": "bi3,0,b8|||",
                "Marquesas": "bhu,0,b8|||",
                "Marshall_Islands": "7o,0,19m,0,b8|||",
                "Mauritius": "60n,0,hm,0,f6|60n,0,fv,0,b8||",
                "Mawson": "bhr,0,b8|||",
                "Mexico_Northwest": "3ku,0,60q,5,1eq,0,17f,0,f6|3ku,0,60q,5,1eq,0,fv,0,13l||",
                "Mexico_Pacific": "3ku,0,3la,0,17f,0,f6|3ku,0,3la,0,fv,0,13l||",
                "Mongolia": "4f,0,60r,0,hm,0,f6|4f,0,60r,0,fv,0,b8||",
                "Moscow": "60s,0,hm,0,f6|60s,0,fv,0,b8||",
                "Myanmar": "bhq,0,b8|||",
                "Nauru": "bhp,0,b8|||",
                "Nepal": "bho,0,b8|||",
                "New_Caledonia": "215,0,2lv,0,hm,0,f6|215,0,2lv,0,fv,0,b8||",
                "New_Zealand": "611,0,2lv,0,hm,0,f6|611,0,2lv,0,fv,0,b8||",
                "Newfoundland": "612,0,17f,0,f6|612,0,fv,0,13l||",
                "Niue": "bhl,0,b8|||",
                "Norfolk": "6l,0,19m,0,b8|||",
                "Noronha": "1s,0,2,0,614,0,hm,0,f6|1s,0,2,0,614,0,fv,0,b8||",
                "Novosibirsk": "615,0,hm,0,f6|615,0,fv,0,b8||",
                "Omsk": "616,0,hm,0,f6|616,0,fv,0,b8||",
                "Pakistan": "617,0,hm,0,f6|617,0,fv,0,b8||",
                "Palau": "bhk,0,b8|||",
                "Papua_New_Guinea": "9a,0,bhj,0,2lv,0,b8|||",
                "Paraguay": "61a,0,hm,0,f6|61a,0,fv,0,b8||",
                "Peru": "61b,0,hm,0,f6|61b,0,fv,0,b8||",
                "Philippines": "61c,0,hm,0,f6|61c,0,fv,0,b8||",
                "Phoenix_Islands": "90,0,19m,0,b8|||",
                "Pierre_Miquelon": "8i,5,33,0,3l0,0,61d,0,17f,0,f6|8i,5,33,0,3l0,0,61d,0,fv,0,13l||",
                "Pitcairn": "bhi,0,b8|||",
                "Ponape": "bhh,0,b8|||",
                "Pyongyang": "bhg,0,b8|||",
                "Reunion": "b4,0,19m,0,b8|||",
                "Rothera": "bhf,0,b8|||",
                "Sakhalin": "61i,0,hm,0,f6|61i,0,fv,0,b8||",
                "Samara": "61j,0,hm,0,f6|61j,0,fv,0,b8||",
                "Samoa": "61k,0,hm,0,f6|61k,0,fv,0,b8||",
                "Seychelles": "4q8,0,19m,0,b8|||",
                "Singapore": "bhe,0,hm,0,f6|||",
                "Solomon": "mq,0,19m,0,b8|||",
                "South_Georgia": "bhd,0,4m0,0,b8|||",
                "Suriname": "bhc,0,b8|||",
                "Syowa": "bhb,0,b8|||",
                "Tahiti": "bha,0,b8|||",
                "Taipei": "61q,0,hm,0,f6|61q,0,fv,0,b8||",
                "Tajikistan": "bh9,0,b8|||",
                "Tokelau": "bh8,0,b8|||",
                "Tonga": "61u,0,hm,0,f6|61u,0,fv,0,b8||",
                "Truk": "bh7,0,b8|||",
                "Turkmenistan": "620,0,hm,0,f6|620,0,fv,0,b8||",
                "Tuvalu": "bh6,0,b8|||",
                "Uruguay": "624,0,hm,0,f6|624,0,fv,0,b8||",
                "Uzbekistan": "627,0,hm,0,f6|627,0,fv,0,b8||",
                "Vanuatu": "628,0,hm,0,f6|628,0,fv,0,b8||",
                "Venezuela": "bh1,0,b8|||",
                "Vladivostok": "62e,0,hm,0,f6|62e,0,fv,0,b8||",
                "Volgograd": "62f,0,hm,0,f6|62f,0,fv,0,b8||",
                "Vostok": "bh0,0,b8|||",
                "Wake": "5r,0,31a,0,b8|||",
                "Wallis": "5f,0,3l0,0,bgv,0,13l|||",
                "Yakutsk": "62l,0,hm,0,f6|62l,0,fv,0,b8||",
                "Yekaterinburg": "bgu,0,hm,0,f6|bgt,0,fv,0,b8||"
            }
        },
        "ewo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "fa-AF": {
            "gmtFormat": "{0} گرینویچ",
            "gmtZeroFormat": "گرینویچ",
            "zone": {
                "Europe/Dublin": "|1n,0,92,0,6js||",
                "Europe/London": "|1n,0,9f,0,6ha||"
            },
            "metazone": {
                "Afghanistan": "1n,0,3ki|||",
                "Africa_Central": "1n,0,14g,0,1kr|||",
                "Africa_Eastern": "1n,0,51,0,1kr|||",
                "Africa_Southern": "1n,0,92,0,ij,0,1kr|||",
                "Africa_Western": "1n,0,92,0,2e,0,1kr|1n,0,9f,0,2e,0,1kr||",
                "Alaska": "1n,0,92,0,3kh|1n,0,9f,0,3kh||",
                "Almaty": "1n,0,92,0,3kg|1n,0,9f,0,3kg||",
                "Amazon": "1n,0,92,0,3kf|1n,0,9f,0,3kf||",
                "America_Central": "1n,0,92,0,14g,0,18i|1n,0,9f,0,14g,0,18i||",
                "America_Eastern": "1n,0,92,0,51,0,18i|1n,0,9f,0,51,0,18i||",
                "America_Mountain": "1n,0,92,0,3k8,0,18i|1n,0,9f,0,3k8,0,18i||",
                "America_Pacific": "1n,0,92,0,2e,0,18i|1n,0,9f,0,2e,0,18i||",
                "Anadyr": "1n,0,92,0,3k7|1n,0,9f,0,3k7||",
                "Apia": "1n,0,92,0,3k6|1n,0,9f,0,3k6||",
                "Arabian": "1n,0,92,0,3k5|1n,0,9f,0,3k5||",
                "Argentina": "1n,0,92,0,20j|1n,0,9f,0,20j||",
                "Argentina_Western": "1n,0,92,0,2e,0,20j|1n,0,9f,0,2e,0,20j||",
                "Armenia": "1n,0,92,0,3k4|1n,0,9f,0,3k4||",
                "Atlantic": "1n,0,92,0,3k2|1n,0,9f,0,3k2||",
                "Australia_Central": "1n,0,92,0,14g,0,18h|1n,0,9f,0,14g,0,18h||",
                "Australia_CentralWestern": "1n,0,92,0,14g,5,2e,0,18h|1n,0,9f,0,14g,5,2e,0,18h||",
                "Australia_Eastern": "1n,0,92,0,51,0,18h|1n,0,9f,0,51,0,18h||",
                "Australia_Western": "1n,0,92,0,2e,0,18h|1n,0,9f,0,2e,0,18h||",
                "Azerbaijan": "1n,0,92,0,3k0,0,3jv|1n,0,9f,0,3k0,0,3jv||",
                "Azores": "1n,0,92,0,3jt|1n,0,9f,0,3jt||",
                "Bangladesh": "1n,0,92,0,3js|1n,0,9f,0,3js||",
                "Bhutan": "1n,0,rp|||",
                "Bolivia": "1n,0,63u|||",
                "Brasilia": "1n,0,92,0,20q|1n,0,9f,0,20q||",
                "Brunei": "1n,0,3jr,0,3jq|||",
                "Cape_Verde": "1n,0,92,0,3jm|1n,0,9f,0,3jm||",
                "Chamorro": "1n,0,92,0,3ji|||",
                "Chatham": "1n,0,92,0,3jh|1n,0,9f,0,3jh||",
                "Chile": "1n,0,92,0,3jd|1n,0,9f,0,3jd||",
                "China": "1n,0,92,0,211|1n,0,9f,0,211||",
                "Choibalsan": "1n,0,92,0,3jc|1n,0,9f,0,3jc||",
                "Christmas": "1n,0,1p0,0,64o|||",
                "Cocos": "1n,0,113,0,3jb|||",
                "Colombia": "1n,0,92,0,3j9|1n,0,9f,0,3j9||",
                "Cook": "1n,0,92,0,113,0,3j8|1n,0,9f,0,113,0,3j8||",
                "Cuba": "1n,0,92,0,3j7|1n,0,9f,0,3j7||",
                "Davis": "1n,0,653|||",
                "DumontDUrville": "1n,0,654,0,655|||",
                "East_Timor": "1n,0,3j3,0,65a|||",
                "Easter": "1n,0,92,0,1p0,0,3j0|1n,0,9f,0,1p0,0,3j0||",
                "Ecuador": "1n,0,65i|||",
                "Europe_Central": "1n,0,92,0,14g,0,19h|1n,0,9f,0,14g,0,19h||",
                "Europe_Eastern": "1n,0,92,0,51,0,19h|1n,0,9f,0,51,0,19h||",
                "Europe_Further_Eastern": "1n,0,9f,0,65n,0,65r,0,51,0,19h|||",
                "Europe_Western": "1n,0,92,0,2e,0,19h|1n,0,9f,0,2e,0,19h||",
                "Falkland": "1n,0,92,0,113,0,3iv|1n,0,9f,0,113,0,3iv||",
                "Fiji": "1n,0,92,0,3ir|1n,0,9f,0,3ir||",
                "French_Guiana": "1n,0,3iq,0,3ip|||",
                "French_Southern": "1n,0,662,0,1ot,0,pu,0,664,0,3ip|||",
                "Galapagos": "1n,0,4nd|||",
                "Gambier": "1n,0,666|||",
                "Georgia": "1n,0,92,0,3ij|1n,0,9f,0,3ij||",
                "Gilbert_Islands": "1n,0,113,0,668|||",
                "GMT": "1n,0,66a|||",
                "Greenland_Eastern": "1n,0,92,0,51,0,21j|1n,0,9f,0,51,0,21j||",
                "Greenland_Western": "1n,0,92,0,2e,0,21j|1n,0,9f,0,2e,0,21j||",
                "Guam": "1n,0,92,0,66d|||",
                "Gulf": "1n,0,92,0,3ii,0,66j|||",
                "Guyana": "1n,0,3iq|||",
                "Hawaii_Aleutian": "1n,0,92,0,3ig|1n,0,9f,0,3ig||",
                "Hong_Kong": "1n,0,92,0,3ie|1n,0,9f,0,3ie||",
                "Hovd": "1n,0,92,0,3id|1n,0,9f,0,3id||",
                "India": "1n,0,3ic|||",
                "Indian_Ocean": "1n,0,66s,0,3ic|||",
                "Indochina": "1n,0,66t|||",
                "Indonesia_Central": "1n,0,14g,0,2at|||",
                "Indonesia_Eastern": "1n,0,51,0,2at|||",
                "Indonesia_Western": "1n,0,2e,0,2at|||",
                "Iran": "1n,0,92,0,21q|1n,0,9f,0,21q||",
                "Irkutsk": "1n,0,92,0,3ib|1n,0,9f,0,3ib||",
                "Israel": "1n,0,92,0,21s|1n,0,9f,0,21s||",
                "Japan": "1n,0,92,0,3ia|1n,0,9f,0,3ia||",
                "Kamchatka": "1n,0,92,0,3i9|1n,0,9f,0,3i9||",
                "Kazakhstan_Eastern": "1n,0,51,0,3i8|||",
                "Kazakhstan_Western": "1n,0,2e,0,3i8|||",
                "Korea": "1n,0,92,0,3i7|1n,0,9f,0,3i7||",
                "Kosrae": "1n,0,67a|||",
                "Krasnoyarsk": "1n,0,92,0,3i6|1n,0,9f,0,3i6||",
                "Kyrgystan": "1n,0,67c|||",
                "Lanka": "1n,0,67d|||",
                "Line_Islands": "1n,0,113,0,67e|||",
                "Lord_Howe": "1n,0,92,0,3i3|1n,0,9f,0,3i3||",
                "Macau": "1n,0,92,0,3hj|1n,0,9f,0,3hj||",
                "Macquarie": "1n,0,1p0,0,67l|||",
                "Magadan": "1n,0,92,0,2al|1n,0,9f,0,2al||",
                "Malaysia": "1n,0,67n|||",
                "Maldives": "1n,0,67p|||",
                "Marquesas": "1n,0,67q|||",
                "Marshall_Islands": "1n,0,113,0,rb|||",
                "Mauritius": "1n,0,92,0,3hi|1n,0,9f,0,3hi||",
                "Mawson": "1n,0,rj|||",
                "Mexico_Northwest": "1n,0,92,0,225,0,2e,0,226|1n,0,9f,0,225,0,2e,0,226||",
                "Mexico_Pacific": "1n,0,92,0,51,0,226|1n,0,9f,0,51,0,226||",
                "Mongolia": "1n,0,92,0,3hh|1n,0,9f,0,3hh||",
                "Moscow": "1n,0,92,0,3hd|1n,0,9f,0,3hd||",
                "Myanmar": "1n,0,3hc|||",
                "Nauru": "1n,0,684|||",
                "Nepal": "1n,0,685|||",
                "New_Caledonia": "1n,0,92,0,3hb,0,3h7|1n,0,9f,0,3hb,0,3h7||",
                "New_Zealand": "1n,0,92,0,3h3,0,2jl|1n,0,9f,0,3h3,0,2jl||",
                "Newfoundland": "1n,0,92,0,3h2|1n,0,9f,0,3h2||",
                "Niue": "1n,0,68b|||",
                "Norfolk": "1n,0,113,0,68c|||",
                "Noronha": "1n,0,92,0,ig,0,3gd,0,3g9|1n,0,9f,0,ig,0,3gd,0,3g9||",
                "North_Mariana": "1n,0,113,0,68f,0,68g|||",
                "Novosibirsk": "1n,0,92,0,3g8|1n,0,9f,0,3g8||",
                "Omsk": "1n,0,92,0,22h|1n,0,9f,0,22h||",
                "Pakistan": "1n,0,92,0,22i|1n,0,9f,0,22i||",
                "Palau": "1n,0,68l|||",
                "Papua_New_Guinea": "1n,0,68m,0,68n,0,2jl|||",
                "Paraguay": "1n,0,92,0,3g7|1n,0,9f,0,3g7||",
                "Peru": "1n,0,92,0,3g6|1n,0,9f,0,3g6||",
                "Philippines": "1n,0,92,0,3g5|1n,0,9f,0,3g5||",
                "Phoenix_Islands": "1n,0,113,0,68t|||",
                "Pierre_Miquelon": "1n,0,92,0,3g4,0,pu,0,3g3|1n,0,9f,0,3g4,0,pu,0,3g3||",
                "Pitcairn": "1n,0,690|||",
                "Ponape": "1n,0,691|||",
                "Pyongyang": "1n,0,694|||",
                "Qyzylorda": "1n,0,92,0,3g2|1n,0,9f,0,3g2||",
                "Reunion": "1n,0,696|||",
                "Rothera": "1n,0,69a|||",
                "Sakhalin": "1n,0,92,0,3g1|1n,0,9f,0,3g1||",
                "Samara": "1n,0,92,0,2ij|1n,0,9f,0,2ij||",
                "Samoa": "1n,0,92,0,ie|1n,0,9f,0,ie||",
                "Seychelles": "1n,0,69d|||",
                "Singapore": "1n,0,3g0|||",
                "Solomon": "1n,0,113,0,69g|||",
                "South_Georgia": "1n,0,69i,0,1ot|||",
                "Suriname": "1n,0,3fv|||",
                "Syowa": "1n,0,69k|||",
                "Tahiti": "1n,0,69l|||",
                "Taipei": "1n,0,92,0,3fu|1n,0,9f,0,3fu||",
                "Tajikistan": "1n,0,69n|||",
                "Tokelau": "1n,0,69o|||",
                "Tonga": "1n,0,92,0,2ho|1n,0,9f,0,2ho||",
                "Truk": "1n,0,69q|||",
                "Turkmenistan": "1n,0,92,0,3fr|1n,0,9f,0,3fr||",
                "Tuvalu": "1n,0,69s|||",
                "Uruguay": "1n,0,92,0,3fq|1n,0,9f,0,3fq||",
                "Uzbekistan": "1n,0,92,0,231|1n,0,9f,0,231||",
                "Vanuatu": "1n,0,92,0,3fp|1n,0,9f,0,3fp||",
                "Venezuela": "1n,0,6a0|||",
                "Vladivostok": "1n,0,92,0,3fo|1n,0,9f,0,3fo||",
                "Volgograd": "1n,0,92,0,3fn|1n,0,9f,0,3fn||",
                "Vostok": "1n,0,6a3|||",
                "Wake": "1n,0,1p0,0,2pn|||",
                "Wallis": "1n,0,6a5,0,pu,0,rg|||",
                "Yakutsk": "1n,0,92,0,3fm|1n,0,9f,0,3fm||",
                "Yekaterinburg": "1n,0,92,0,3fl|1n,0,9f,0,3fl||"
            }
        },
        "fa": {
            "gmtFormat": "{0} گرینویچ",
            "gmtZeroFormat": "گرینویچ",
            "zone": {
                "Europe/Dublin": "|1n,0,92,0,6js||",
                "Europe/London": "|1n,0,9f,0,6ha||"
            },
            "metazone": {
                "Afghanistan": "1n,0,3ki|||",
                "Africa_Central": "1n,0,14g,0,1kr|||",
                "Africa_Eastern": "1n,0,51,0,1kr|||",
                "Africa_Southern": "1n,0,92,0,ij,0,1kr|||",
                "Africa_Western": "1n,0,92,0,2e,0,1kr|1n,0,9f,0,2e,0,1kr||",
                "Alaska": "1n,0,92,0,3kh|1n,0,9f,0,3kh||",
                "Almaty": "1n,0,92,0,3kg|1n,0,9f,0,3kg||",
                "Amazon": "1n,0,92,0,3kf|1n,0,9f,0,3kf||",
                "America_Central": "1n,0,92,0,14g,0,18i|1n,0,9f,0,14g,0,18i||",
                "America_Eastern": "1n,0,92,0,51,0,18i|1n,0,9f,0,51,0,18i||",
                "America_Mountain": "1n,0,92,0,3k8,0,18i|1n,0,9f,0,3k8,0,18i||",
                "America_Pacific": "1n,0,92,0,2e,0,18i|1n,0,9f,0,2e,0,18i||",
                "Anadyr": "1n,0,92,0,3k7|1n,0,9f,0,3k7||",
                "Apia": "1n,0,92,0,3k6|1n,0,9f,0,3k6||",
                "Arabian": "1n,0,92,0,3k5|1n,0,9f,0,3k5||",
                "Argentina": "1n,0,92,0,20j|1n,0,9f,0,20j||",
                "Argentina_Western": "1n,0,92,0,2e,0,20j|1n,0,9f,0,2e,0,20j||",
                "Armenia": "1n,0,92,0,3k4|1n,0,9f,0,3k4||",
                "Atlantic": "1n,0,92,0,3k2|1n,0,9f,0,3k2||",
                "Australia_Central": "1n,0,92,0,14g,0,18h|1n,0,9f,0,14g,0,18h||",
                "Australia_CentralWestern": "1n,0,92,0,14g,5,2e,0,18h|1n,0,9f,0,14g,5,2e,0,18h||",
                "Australia_Eastern": "1n,0,92,0,51,0,18h|1n,0,9f,0,51,0,18h||",
                "Australia_Western": "1n,0,92,0,2e,0,18h|1n,0,9f,0,2e,0,18h||",
                "Azerbaijan": "1n,0,92,0,3k0,0,3jv|1n,0,9f,0,3k0,0,3jv||",
                "Azores": "1n,0,92,0,3jt|1n,0,9f,0,3jt||",
                "Bangladesh": "1n,0,92,0,3js|1n,0,9f,0,3js||",
                "Bhutan": "1n,0,rp|||",
                "Bolivia": "1n,0,63u|||",
                "Brasilia": "1n,0,92,0,20q|1n,0,9f,0,20q||",
                "Brunei": "1n,0,3jr,0,3jq|||",
                "Cape_Verde": "1n,0,92,0,3jm|1n,0,9f,0,3jm||",
                "Chamorro": "1n,0,92,0,3ji|||",
                "Chatham": "1n,0,92,0,3jh|1n,0,9f,0,3jh||",
                "Chile": "1n,0,92,0,3jd|1n,0,9f,0,3jd||",
                "China": "1n,0,92,0,211|1n,0,9f,0,211||",
                "Choibalsan": "1n,0,92,0,3jc|1n,0,9f,0,3jc||",
                "Christmas": "1n,0,1p0,0,64o|||",
                "Cocos": "1n,0,113,0,3jb|||",
                "Colombia": "1n,0,92,0,3j9|1n,0,9f,0,3j9||",
                "Cook": "1n,0,92,0,113,0,3j8|1n,0,9f,0,113,0,3j8||",
                "Cuba": "1n,0,92,0,3j7|1n,0,9f,0,3j7||",
                "Davis": "1n,0,653|||",
                "DumontDUrville": "1n,0,654,0,655|||",
                "East_Timor": "1n,0,3j3,0,65a|||",
                "Easter": "1n,0,92,0,1p0,0,3j0|1n,0,9f,0,1p0,0,3j0||",
                "Ecuador": "1n,0,65i|||",
                "Europe_Central": "1n,0,92,0,14g,0,19h|1n,0,9f,0,14g,0,19h||",
                "Europe_Eastern": "1n,0,92,0,51,0,19h|1n,0,9f,0,51,0,19h||",
                "Europe_Further_Eastern": "1n,0,9f,0,65n,0,65r,0,51,0,19h|||",
                "Europe_Western": "1n,0,92,0,2e,0,19h|1n,0,9f,0,2e,0,19h||",
                "Falkland": "1n,0,92,0,113,0,3iv|1n,0,9f,0,113,0,3iv||",
                "Fiji": "1n,0,92,0,3ir|1n,0,9f,0,3ir||",
                "French_Guiana": "1n,0,3iq,0,3ip|||",
                "French_Southern": "1n,0,662,0,1ot,0,pu,0,664,0,3ip|||",
                "Galapagos": "1n,0,4nd|||",
                "Gambier": "1n,0,666|||",
                "Georgia": "1n,0,92,0,3ij|1n,0,9f,0,3ij||",
                "Gilbert_Islands": "1n,0,113,0,668|||",
                "GMT": "1n,0,66a|||",
                "Greenland_Eastern": "1n,0,92,0,51,0,21j|1n,0,9f,0,51,0,21j||",
                "Greenland_Western": "1n,0,92,0,2e,0,21j|1n,0,9f,0,2e,0,21j||",
                "Guam": "1n,0,92,0,66d|||",
                "Gulf": "1n,0,92,0,3ii,0,66j|||",
                "Guyana": "1n,0,3iq|||",
                "Hawaii_Aleutian": "1n,0,92,0,3ig|1n,0,9f,0,3ig||",
                "Hong_Kong": "1n,0,92,0,3ie|1n,0,9f,0,3ie||",
                "Hovd": "1n,0,92,0,3id|1n,0,9f,0,3id||",
                "India": "1n,0,3ic|||",
                "Indian_Ocean": "1n,0,66s,0,3ic|||",
                "Indochina": "1n,0,66t|||",
                "Indonesia_Central": "1n,0,14g,0,2at|||",
                "Indonesia_Eastern": "1n,0,51,0,2at|||",
                "Indonesia_Western": "1n,0,2e,0,2at|||",
                "Iran": "1n,0,92,0,21q|1n,0,9f,0,21q||",
                "Irkutsk": "1n,0,92,0,3ib|1n,0,9f,0,3ib||",
                "Israel": "1n,0,92,0,21s|1n,0,9f,0,21s||",
                "Japan": "1n,0,92,0,3ia|1n,0,9f,0,3ia||",
                "Kamchatka": "1n,0,92,0,3i9|1n,0,9f,0,3i9||",
                "Kazakhstan_Eastern": "1n,0,51,0,3i8|||",
                "Kazakhstan_Western": "1n,0,2e,0,3i8|||",
                "Korea": "1n,0,92,0,3i7|1n,0,9f,0,3i7||",
                "Kosrae": "1n,0,67a|||",
                "Krasnoyarsk": "1n,0,92,0,3i6|1n,0,9f,0,3i6||",
                "Kyrgystan": "1n,0,67c|||",
                "Lanka": "1n,0,67d|||",
                "Line_Islands": "1n,0,113,0,67e|||",
                "Lord_Howe": "1n,0,92,0,3i3|1n,0,9f,0,3i3||",
                "Macau": "1n,0,92,0,3hj|1n,0,9f,0,3hj||",
                "Macquarie": "1n,0,1p0,0,67l|||",
                "Magadan": "1n,0,92,0,2al|1n,0,9f,0,2al||",
                "Malaysia": "1n,0,67n|||",
                "Maldives": "1n,0,67p|||",
                "Marquesas": "1n,0,67q|||",
                "Marshall_Islands": "1n,0,113,0,rb|||",
                "Mauritius": "1n,0,92,0,3hi|1n,0,9f,0,3hi||",
                "Mawson": "1n,0,rj|||",
                "Mexico_Northwest": "1n,0,92,0,225,0,2e,0,226|1n,0,9f,0,225,0,2e,0,226||",
                "Mexico_Pacific": "1n,0,92,0,51,0,226|1n,0,9f,0,51,0,226||",
                "Mongolia": "1n,0,92,0,3hh|1n,0,9f,0,3hh||",
                "Moscow": "1n,0,92,0,3hd|1n,0,9f,0,3hd||",
                "Myanmar": "1n,0,3hc|||",
                "Nauru": "1n,0,684|||",
                "Nepal": "1n,0,685|||",
                "New_Caledonia": "1n,0,92,0,3hb,0,3h7|1n,0,9f,0,3hb,0,3h7||",
                "New_Zealand": "1n,0,92,0,3h3,0,2jl|1n,0,9f,0,3h3,0,2jl||",
                "Newfoundland": "1n,0,92,0,3h2|1n,0,9f,0,3h2||",
                "Niue": "1n,0,68b|||",
                "Norfolk": "1n,0,113,0,68c|||",
                "Noronha": "1n,0,92,0,ig,0,3gd,0,3g9|1n,0,9f,0,ig,0,3gd,0,3g9||",
                "North_Mariana": "1n,0,113,0,68f,0,68g|||",
                "Novosibirsk": "1n,0,92,0,3g8|1n,0,9f,0,3g8||",
                "Omsk": "1n,0,92,0,22h|1n,0,9f,0,22h||",
                "Pakistan": "1n,0,92,0,22i|1n,0,9f,0,22i||",
                "Palau": "1n,0,68l|||",
                "Papua_New_Guinea": "1n,0,68m,0,68n,0,2jl|||",
                "Paraguay": "1n,0,92,0,3g7|1n,0,9f,0,3g7||",
                "Peru": "1n,0,92,0,3g6|1n,0,9f,0,3g6||",
                "Philippines": "1n,0,92,0,3g5|1n,0,9f,0,3g5||",
                "Phoenix_Islands": "1n,0,113,0,68t|||",
                "Pierre_Miquelon": "1n,0,92,0,3g4,0,pu,0,3g3|1n,0,9f,0,3g4,0,pu,0,3g3||",
                "Pitcairn": "1n,0,690|||",
                "Ponape": "1n,0,691|||",
                "Pyongyang": "1n,0,694|||",
                "Qyzylorda": "1n,0,92,0,3g2|1n,0,9f,0,3g2||",
                "Reunion": "1n,0,696|||",
                "Rothera": "1n,0,69a|||",
                "Sakhalin": "1n,0,92,0,3g1|1n,0,9f,0,3g1||",
                "Samara": "1n,0,92,0,2ij|1n,0,9f,0,2ij||",
                "Samoa": "1n,0,92,0,ie|1n,0,9f,0,ie||",
                "Seychelles": "1n,0,69d|||",
                "Singapore": "1n,0,3g0|||",
                "Solomon": "1n,0,113,0,69g|||",
                "South_Georgia": "1n,0,69i,0,1ot|||",
                "Suriname": "1n,0,3fv|||",
                "Syowa": "1n,0,69k|||",
                "Tahiti": "1n,0,69l|||",
                "Taipei": "1n,0,92,0,3fu|1n,0,9f,0,3fu||",
                "Tajikistan": "1n,0,69n|||",
                "Tokelau": "1n,0,69o|||",
                "Tonga": "1n,0,92,0,2ho|1n,0,9f,0,2ho||",
                "Truk": "1n,0,69q|||",
                "Turkmenistan": "1n,0,92,0,3fr|1n,0,9f,0,3fr||",
                "Tuvalu": "1n,0,69s|||",
                "Uruguay": "1n,0,92,0,3fq|1n,0,9f,0,3fq||",
                "Uzbekistan": "1n,0,92,0,231|1n,0,9f,0,231||",
                "Vanuatu": "1n,0,92,0,3fp|1n,0,9f,0,3fp||",
                "Venezuela": "1n,0,6a0|||",
                "Vladivostok": "1n,0,92,0,3fo|1n,0,9f,0,3fo||",
                "Volgograd": "1n,0,92,0,3fn|1n,0,9f,0,3fn||",
                "Vostok": "1n,0,6a3|||",
                "Wake": "1n,0,1p0,0,2pn|||",
                "Wallis": "1n,0,6a5,0,pu,0,rg|||",
                "Yakutsk": "1n,0,92,0,3fm|1n,0,9f,0,3fm||",
                "Yekaterinburg": "1n,0,92,0,3fl|1n,0,9f,0,3fl||"
            }
        },
        "ff-CM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ff-GN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ff-MR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ff": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "fi": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|ca7,0,ef||",
                "Europe/London": "|ca8,0,ef||"
            },
            "metazone": {
                "Acre": "6a8,0,ee|6a8,0,ef||",
                "Afghanistan": "bgs,0,hd|||",
                "Africa_Central": "23b,5,2q0,0,hd|||",
                "Africa_Eastern": "1li,5,2q0,0,hd|||",
                "Africa_Southern": "6ag,5,2q0,0,hd|||",
                "Africa_Western": "1f6,5,2q0,0,ee|1f6,5,2q0,0,ef||",
                "Alaska": "6ai,0,ee|6ai,0,ef||",
                "Almaty": "6aj,0,ee|6aj,0,ef||",
                "Amazon": "6ak,0,ee|6ak,0,ef||",
                "America_Central": "2ck,0,6am,0,ee|2ck,0,6am,0,ef||",
                "America_Eastern": "2ck,0,6an,0,ee|2ck,0,6an,0,ef||",
                "America_Mountain": "6ao,0,ee|6ao,0,ef||",
                "America_Pacific": "2ck,0,3fi,0,ee|2ck,0,3fi,0,ef||",
                "Anadyr": "6aq,0,ee|6aq,0,ef||",
                "Apia": "6ar,0,ee|6ar,0,ef||",
                "Aqtau": "6as,0,ee|6as,0,ef||",
                "Aqtobe": "6at,0,ee|6at,0,ef||",
                "Arabian": "6au,5,87,0,ee|6au,5,87,0,ef||",
                "Argentina": "3fh,0,ee|3fh,0,ef||",
                "Argentina_Western": "1f6,5,3fh,0,ee|1f6,5,3fh,0,ef||",
                "Armenia": "6b0,0,ee|6b0,0,ef||",
                "Atlantic": "6b1,0,6b2,0,ee|6b1,0,6b2,0,ef||",
                "Australia_Central": "23b,5,13,0,ee|23b,5,13,0,ef||",
                "Australia_CentralWestern": "6b3,0,23b,5,13,0,ee|6b3,0,23b,5,13,0,ef||",
                "Australia_Eastern": "1li,5,13,0,ee|1li,5,13,0,ef||",
                "Australia_Western": "1f6,5,13,0,ee|1f6,5,13,0,ef||",
                "Azerbaijan": "6b4,0,ee|6b4,0,ef||",
                "Azores": "6b5,0,ee|6b5,0,ef||",
                "Bangladesh": "6b6,0,ee|6b6,0,ef||",
                "Bhutan": "bgr,0,hd|||",
                "Bolivia": "bgq,0,hd|||",
                "Brasilia": "6b9,0,ee|6b9,0,ef||",
                "Brunei": "bgp,0,hd|||",
                "Cape_Verde": "1l8,0,6bc,0,ee|1l8,0,6bc,0,ef||",
                "Casey": "bgo,0,hd|||",
                "Chamorro": "bgn,0,hd|||",
                "Chatham": "6bf,0,ee|6bf,0,ef||",
                "Chile": "6bg,0,ee|6bg,0,ef||",
                "China": "6bh,0,ee|6bh,0,ef||",
                "Choibalsan": "6bi,0,ee|6bi,0,ef||",
                "Christmas": "bgm,0,hd|||",
                "Cocos": "bgl,0,hd|||",
                "Colombia": "6bl,0,ee|6bl,0,ef||",
                "Cook": "6bm,0,ee|6bm,0,ef||",
                "Cuba": "6bn,0,ee|6bn,0,ef||",
                "Davis": "bgk,0,hd|||",
                "DumontDUrville": "5b,5,bgj,0,hd|||",
                "East_Timor": "1li,5,bgi,0,hd|||",
                "Easter": "6br,0,ee|6br,0,ef||",
                "Ecuador": "bgh,0,hd|||",
                "Europe_Central": "23b,5,268,0,ee|23b,5,268,0,ef||",
                "Europe_Eastern": "1li,5,268,0,ee|1li,5,268,0,ef||",
                "Europe_Further_Eastern": "bgg,0,268,0,hd|||",
                "Europe_Western": "1f6,5,268,0,ee|1f6,5,268,0,ef||",
                "Falkland": "6bv,0,ee|6bv,0,ef||",
                "Fiji": "6c0,0,ee|6c0,0,ef||",
                "French_Guiana": "6c1,0,bgf,0,hd|||",
                "French_Southern": "6c1,0,bge,0,1pq,0,bgd,0,bgc,0,hd|||",
                "Galapagos": "bgb,0,hd|||",
                "Gambier": "bga,0,hd|||",
                "Georgia": "4pe,0,ee|4pe,0,ef||",
                "Gilbert_Islands": "bg9,0,hd|||",
                "GMT": "bg8,0,ee|||",
                "Greenland_Eastern": "1li,5,3ev,0,ee|1li,5,3ev,0,ef||",
                "Greenland_Western": "1f6,5,3ev,0,ee|1f6,5,3ev,0,ef||",
                "Guam": "bg7,0,hd|||",
                "Gulf": "bg6,0,ee|||",
                "Guyana": "bg5,0,hd|||",
                "Hawaii_Aleutian": "6cf,5,6cg,0,ee|6cf,5,6cg,0,ef||",
                "Hong_Kong": "6ch,0,ee|6ch,0,ef||",
                "Hovd": "6ci,0,ee|6ci,0,ef||",
                "India": "6cj,0,hd|||",
                "Indian_Ocean": "6cj,0,bg4,0,hd|||",
                "Indochina": "bg3,0,hd|||",
                "Indonesia_Central": "23b,5,4pg,0,hd|||",
                "Indonesia_Eastern": "1li,5,4pg,0,hd|||",
                "Indonesia_Western": "1f6,5,4pg,0,hd|||",
                "Iran": "6cn,0,ee|6cn,0,ef||",
                "Irkutsk": "6cp,0,ee|6cp,0,ef||",
                "Israel": "6cq,0,ee|6cq,0,ef||",
                "Japan": "6cr,0,ee|6cr,0,ef||",
                "Kamchatka": "6cs,0,ee|6cs,0,ef||",
                "Kazakhstan_Eastern": "1li,5,6ct,0,hd|||",
                "Kazakhstan_Western": "1f6,5,6ct,0,hd|||",
                "Korea": "88,0,ee|88,0,ef||",
                "Kosrae": "bg2,0,hd|||",
                "Krasnoyarsk": "6cv,0,ee|6cv,0,ef||",
                "Kyrgystan": "bg1,0,hd|||",
                "Lanka": "me,0,bg0,0,hd|||",
                "Line_Islands": "bfv,0,hd|||",
                "Lord_Howe": "1u,0,6d3,0,ee|1u,0,6d3,0,ef||",
                "Macau": "6d4,0,ee|6d4,0,ef||",
                "Macquarie": "bfu,0,hd|||",
                "Magadan": "6d6,0,ee|6d6,0,ef||",
                "Malaysia": "bft,0,hd|||",
                "Maldives": "bfs,0,hd|||",
                "Marquesas": "bfr,0,hd|||",
                "Marshall_Islands": "bfq,0,hd|||",
                "Mauritius": "6db,0,ee|6db,0,ef||",
                "Mawson": "bfp,0,hd|||",
                "Mexico_Northwest": "6dd,5,3el,0,ee|6dd,5,3el,0,ef||",
                "Mexico_Pacific": "3el,0,3fi,0,ee|3el,0,3fi,0,ef||",
                "Mongolia": "4f,0,6df,0,ee|4f,0,6df,0,ef||",
                "Moscow": "6dg,0,ee|6dg,0,ef||",
                "Myanmar": "bfo,0,hd|||",
                "Nauru": "bfn,0,hd|||",
                "Nepal": "bfm,0,hd|||",
                "New_Caledonia": "2qv,5,6dl,0,ee|2qv,5,6dl,0,ef||",
                "New_Zealand": "2qv,5,6dm,0,ee|2qv,5,6dm,0,ef||",
                "Newfoundland": "6dn,0,ee|6dn,0,ef||",
                "Niue": "bfl,0,hd|||",
                "Norfolk": "bfk,0,hd|||",
                "Noronha": "1s,0,2,0,6dq,0,ee|1s,0,2,0,6dq,0,ef||",
                "North_Mariana": "bfj,5,bfi,0,hd|||",
                "Novosibirsk": "6dt,0,ee|6dt,0,ef||",
                "Omsk": "6du,0,ee|6du,0,ef||",
                "Pakistan": "6dv,0,ee|6dv,0,ef||",
                "Palau": "bfh,0,hd|||",
                "Papua_New_Guinea": "9a,5,2qv,5,bfg,0,hd|||",
                "Paraguay": "6e2,0,ee|6e2,0,ef||",
                "Peru": "6e3,0,ee|6e3,0,ef||",
                "Philippines": "6e4,0,ee|6e4,0,ef||",
                "Phoenix_Islands": "bff,0,hd|||",
                "Pierre_Miquelon": "8i,5,6e6,0,1pq,0,6e7,0,ee|8i,5,6e6,0,1pq,0,6e7,0,ef||",
                "Pitcairn": "bfe,0,hd|||",
                "Ponape": "bfd,0,hd|||",
                "Pyongyang": "bfc,0,hd|||",
                "Qyzylorda": "6ec,0,ee|6ec,0,ef||",
                "Reunion": "bfb,0,hd|||",
                "Rothera": "bfa,0,hd|||",
                "Sakhalin": "6ef,0,ee|6ef,0,ef||",
                "Samara": "6eg,0,ee|6eg,0,ef||",
                "Samoa": "6eh,0,ee|6eh,0,ef||",
                "Seychelles": "bf9,0,hd|||",
                "Singapore": "bf8,0,hd|||",
                "Solomon": "bf7,0,hd|||",
                "South_Georgia": "6ag,5,4pe,0,hd|||",
                "Suriname": "bf6,0,hd|||",
                "Syowa": "bf5,0,hd|||",
                "Tahiti": "bf4,0,hd|||",
                "Taipei": "6eo,0,ee|6eo,0,ef||",
                "Tajikistan": "bf3,0,hd|||",
                "Tokelau": "bf2,0,hd|||",
                "Tonga": "6er,0,ee|6er,0,ef||",
                "Truk": "bf1,0,hd|||",
                "Turkmenistan": "6et,0,ee|6et,0,ef||",
                "Tuvalu": "bf0,0,hd|||",
                "Uruguay": "6ev,0,ee|6ev,0,ef||",
                "Uzbekistan": "6f0,0,ee|6f0,0,ef||",
                "Vanuatu": "6f1,0,ee|6f1,0,ef||",
                "Venezuela": "bev,0,hd|||",
                "Vladivostok": "6f3,0,ee|6f3,0,ef||",
                "Volgograd": "6f4,0,ee|6f4,0,ef||",
                "Vostok": "beu,0,hd|||",
                "Wake": "bet,0,hd|||",
                "Wallis": "bes,0,1pq,0,ber,0,hd|||",
                "Yakutsk": "6fa,0,ee|6fa,0,ef||",
                "Yekaterinburg": "6fb,0,ee|6fb,0,ef||"
            }
        },
        "fil": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4,0,52,0,8r,0,8c,0,3i4||",
                "Europe/London": "|8r,0,8c,0,mc,5,mb,0,h8,0,caa||"
            },
            "metazone": {
                "Afghanistan": "8r,0,8c,0,b6|||",
                "Africa_Central": "8r,0,8c,0,24l,0,1t|||",
                "Africa_Eastern": "8r,0,8c,0,1qp,0,1t|||",
                "Africa_Southern": "8r,0,8c,0,6fg,0,1t|||",
                "Africa_Western": "4,0,52,0,8r,0,8c,0,1fi,0,1t|8r,0,8c,0,mc,5,mb,0,h8,0,1fi,0,1t||",
                "Alaska": "4,0,52,0,8r,0,8c,0,3p|c,0,1,0,8c,0,3p||",
                "Amazon": "4,0,52,0,8r,0,8c,0,5m|8r,0,8c,0,mc,5,mb,0,h8,0,5m||",
                "America_Central": "1qr,0,52,0,6fm,0,8r|1qr,0,52,0,c,0,1||",
                "America_Eastern": "17,0,52,0,4,0,52,0,8r|17,0,c,0,1||",
                "America_Mountain": "6fm,0,8r,0,8c,0,6fn|c,0,1,0,8c,0,6fn||",
                "America_Pacific": "4,0,52,0,8r,0,8c,0,3e8|c,0,1,0,8c,0,3e8||",
                "Anadyr": "4,0,1,0,8c,0,3l|6,0,1,0,8c,0,3l||",
                "Apia": "4,0,52,0,8r,0,8c,0,3e|3e,0,c,0,1||",
                "Arabian": "4,0,52,0,8r,0,8c,0,hs|c,0,1,0,8c,0,hs||",
                "Argentina": "4,0,52,0,8r,0,8c,0,1e|8r,0,8c,0,mc,5,mb,0,h8,0,1e||",
                "Argentina_Western": "4,0,52,0,8r,0,8c,0,1fi,0,1e|8r,0,8c,0,mc,5,mb,0,h8,0,1fi,0,1e||",
                "Armenia": "4,0,52,0,8r,0,8c,0,4l|8r,0,8c,0,mc,5,mb,0,h8,0,4l||",
                "Atlantic": "4,0,52,0,8r,0,8c,0,6fp|c,0,1,0,8c,0,6fp||",
                "Australia_Central": "4,0,1,0,h8,0,24l,0,24t|c,0,1,0,h8,0,24l,0,24t||",
                "Australia_CentralWestern": "4,0,1,0,h8,0,24l,0,6fr,0,h8,0,24t|c,0,1,0,h8,0,24l,0,6fr,0,h8,0,24t||",
                "Australia_Eastern": "4,0,1,0,h8,0,1qp,0,24t|c,0,1,0,h8,0,1qp,0,24t||",
                "Australia_Western": "4,0,1,0,h8,0,1fi,0,24t|c,0,1,0,8c,0,1fi,0,24t||",
                "Azerbaijan": "4,0,52,0,8r,0,8c,0,7m|8r,0,8c,0,mc,5,mb,0,h8,0,7m||",
                "Azores": "4,0,52,0,8r,0,8c,0,4r|8r,0,8c,0,mc,5,mb,0,h8,0,4r||",
                "Bangladesh": "4,0,52,0,8r,0,8c,0,3n|8r,0,8c,0,mc,5,mb,0,h8,0,3n||",
                "Bhutan": "8r,0,8c,0,as|||",
                "Bolivia": "8r,0,8c,0,aa|||",
                "Brasilia": "4,0,52,0,8r,0,8c,0,3a|8r,0,8c,0,mc,5,mb,0,h8,0,3a||",
                "Brunei": "8r,0,h8,0,9o,0,a4|||",
                "Cape_Verde": "4,0,52,0,8r,0,8c,0,7c,0,3j|8r,0,8c,0,mc,5,mb,0,h8,0,7c,0,3j||",
                "Chamorro": "4,0,52,0,8r,0,8c,0,6j|||",
                "Chatham": "4,0,52,0,8r,0,8c,0,27|c,0,1,0,8c,0,27||",
                "Chile": "4,0,52,0,8r,0,8c,0,49|8r,0,8c,0,mc,5,mb,0,h8,0,49||",
                "China": "4,0,52,0,8r,0,8c,0,4h|c,0,1,0,8c,0,4h||",
                "Choibalsan": "4,0,52,0,8r,0,8c,0,2q|8r,0,8c,0,mc,5,mb,0,h8,0,2q||",
                "Christmas": "8r,0,8c,0,99,0,1f|||",
                "Cocos": "8r,0,8c,0,7v,0,o|||",
                "Colombia": "4,0,52,0,8r,0,8c,0,4m|8r,0,8c,0,mc,5,mb,0,h8,0,4m||",
                "Cook": "4,0,52,0,8r,0,8c,0,2v,0,o|8r,0,8c,0,beq,0,h8,0,mc,5,mb,0,h8,0,2v,0,o||",
                "Cuba": "4,0,52,0,8r,0,8c,0,34|c,0,1,0,8c,0,34||",
                "Davis": "8r,0,8c,0,5s|||",
                "DumontDUrville": "8r,0,8c,0,5b,5,5j|||",
                "East_Timor": "8r,0,h8,0,1r,0,7n|||",
                "Easter": "4,0,52,0,8r,0,8c,0,7t,0,1f|8r,0,8c,0,mc,5,mb,0,h8,0,7t,0,1f||",
                "Ecuador": "8r,0,8c,0,ai|||",
                "Europe_Central": "4,0,52,0,8r,0,8c,0,24l,0,r4|8r,0,8c,0,mc,5,mb,0,h8,0,24l,0,r4||",
                "Europe_Eastern": "4,0,52,0,8r,0,8c,0,1qp,0,r4|8r,0,8c,0,mc,5,mb,0,h8,0,1qp,0,r4||",
                "Europe_Further_Eastern": "8r,0,8c,0,bep,5,beo,0,r4|||",
                "Europe_Western": "4,0,52,0,8r,0,8c,0,1fi,0,r4|8r,0,8c,0,mc,5,mb,0,h8,0,1fi,0,r4||",
                "Falkland": "4,0,52,0,8r,0,8c,0,4v,0,o|8r,0,8c,0,mc,5,mb,0,h8,0,4v,0,o||",
                "Fiji": "4,0,52,0,8r,0,8c,0,4q|8r,0,8c,0,mc,5,mb,0,h8,0,4q||",
                "French_Guiana": "8r,0,8c,0,84,0,am|||",
                "French_Southern": "8r,0,8c,0,ben,0,bem,0,bel,0,bek|||",
                "Galapagos": "8r,0,8c,0,ak|||",
                "Gambier": "8r,0,8c,0,6v|||",
                "Georgia": "4,0,52,0,8r,0,8c,0,2u|8r,0,8c,0,mc,5,mb,0,h8,0,2u||",
                "Gilbert_Islands": "8r,0,8c,0,6q,0,o|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "4,0,52,0,8r,0,8c,0,1qp,0,2k|8r,0,8c,0,mc,5,mb,0,h8,0,1qp,0,2k||",
                "Greenland_Western": "4,0,52,0,8r,0,8c,0,1fi,0,2k|8r,0,8c,0,mc,5,mb,0,h8,0,1fi,0,2k||",
                "Gulf": "8r,0,8c,0,bn|||",
                "Guyana": "8r,0,8c,0,7a|||",
                "Hawaii_Aleutian": "4,0,52,0,8r,0,8c,0,4g,5,62|8r,0,8c,0,mc,5,mb,0,h8,0,4g,5,62||",
                "Hong_Kong": "4,0,52,0,8r,0,8c,0,2l,0,2p|8r,0,8c,0,mc,5,mb,0,h8,0,2l,0,2p||",
                "Hovd": "4,0,52,0,8r,0,8c,0,2g|8r,0,8c,0,mc,5,mb,0,h8,0,2g||",
                "India": "4,0,52,0,8r,0,8c,0,as|||",
                "Indian_Ocean": "8r,0,8c,0,br,0,bv|||",
                "Indochina": "8r,0,h8,0,9p|||",
                "Indonesia_Central": "8r,0,h8,0,24l,0,4ph|||",
                "Indonesia_Eastern": "8r,0,h8,0,1qp,0,4ph|||",
                "Indonesia_Western": "8r,0,h8,0,1fi,0,4ph|||",
                "Iran": "4,0,52,0,8r,0,8c,0,5g|c,0,1,0,8c,0,5g||",
                "Irkutsk": "4,0,52,0,8r,0,8c,0,3h|8r,0,8c,0,mc,5,mb,0,h8,0,3h||",
                "Israel": "4,0,52,0,8r,0,8c,0,4e|c,0,1,0,8c,0,4e||",
                "Japan": "4,0,52,0,8r,0,8c,0,6o|c,0,1,0,8c,0,6o||",
                "Kamchatka": "4,0,1,0,8c,0,37,5,3k|6,0,1,0,8c,0,37,5,3k||",
                "Kazakhstan_Eastern": "8r,0,8c,0,1qp,0,45|||",
                "Kazakhstan_Western": "8r,0,8c,0,1fi,0,45|||",
                "Korea": "4,0,52,0,8r,0,8c,0,qf|c,0,1,0,8c,0,qf||",
                "Kosrae": "8r,0,8c,0,6f|||",
                "Krasnoyarsk": "4,0,52,0,8r,0,8c,0,41|8r,0,8c,0,mc,5,mb,0,h8,0,41||",
                "Kyrgystan": "8r,0,8c,0,bl|||",
                "Line_Islands": "8r,0,8c,0,ac,0,o|||",
                "Lord_Howe": "4,0,52,0,8r,0,8c,0,1u,0,24|c,0,1,0,8c,0,bej,0,24||",
                "Macquarie": "8r,0,8c,0,6m,0,1f|||",
                "Magadan": "4,0,52,0,8r,0,8c,0,2o|8r,0,8c,0,mc,5,mb,0,h8,0,2o||",
                "Malaysia": "8r,0,h8,0,bf|||",
                "Maldives": "8r,0,8c,0,9k|||",
                "Marquesas": "8r,0,8c,0,9e|||",
                "Marshall_Islands": "8r,0,8c,0,7o,0,o|||",
                "Mauritius": "4,0,52,0,8r,0,8c,0,4u|8r,0,8c,0,mc,5,mb,0,h8,0,4u||",
                "Mawson": "8r,0,8c,0,63|||",
                "Mexico_Northwest": "4,0,52,0,8r,0,8c,0,6g5,5,6g6,0,5e|c,0,1,0,8c,0,6g5,5,6g6,0,5e||",
                "Mexico_Pacific": "4,0,52,0,8r,0,8c,0,3e8,0,h8,0,5e|c,0,1,0,8c,0,3e8,0,h8,0,5e||",
                "Mongolia": "4,0,52,0,8r,0,8c,0,4f,0,2m|8r,0,8c,0,mc,5,mb,0,h8,0,4f,0,2m||",
                "Moscow": "4,0,52,0,8r,0,8c,0,71|8r,0,8c,0,mc,5,mb,0,h8,0,71||",
                "Myanmar": "8r,0,h8,0,8v|||",
                "Nauru": "8r,0,8c,0,5v|||",
                "Nepal": "8r,0,8c,0,8u|||",
                "New_Caledonia": "4,0,52,0,8r,0,8c,0,1m,0,4o|8r,0,8c,0,mc,5,mb,0,h8,0,1m,0,4o||",
                "New_Zealand": "4,0,52,0,8r,0,8c,0,1m,0,74|c,0,1,0,8c,0,1m,0,74||",
                "Newfoundland": "4,0,52,0,8r,0,8c,0,53|c,0,1,0,8c,0,53||",
                "Niue": "8r,0,8c,0,8g|||",
                "Norfolk": "8r,0,8c,0,6l,0,1f|||",
                "Noronha": "4,0,52,0,8r,0,8c,0,1s,0,2,0,23|8r,0,8c,0,mc,5,mb,0,h8,0,1s,0,2,0,23||",
                "Novosibirsk": "4,0,52,0,8r,0,8c,0,3b|8r,0,8c,0,mc,5,mb,0,h8,0,3b||",
                "Omsk": "4,0,52,0,8r,0,8c,0,26|8r,0,8c,0,mc,5,mb,0,h8,0,26||",
                "Pakistan": "4,0,52,0,8r,0,8c,0,3i|8r,0,8c,0,mc,5,mb,0,h8,0,3i||",
                "Palau": "8r,0,8c,0,9b|||",
                "Papua_New_Guinea": "8r,0,8c,0,9a,0,1m,0,a8|||",
                "Paraguay": "4,0,52,0,8r,0,8c,0,3c|8r,0,8c,0,mc,5,mb,0,h8,0,3c||",
                "Peru": "4,0,52,0,8r,0,8c,0,4n|8r,0,8c,0,mc,5,mb,0,h8,0,4n||",
                "Philippines": "4,0,52,0,8r,0,8c,0,471|8r,0,8c,0,mc,5,mb,0,h8,0,471||",
                "Phoenix_Islands": "8r,0,8c,0,90,0,o|||",
                "Pierre_Miquelon": "4,0,52,0,8r,0,8c,0,8i,0,33,0,4pi,0,35|c,0,1,0,8c,0,8i,0,33,0,4pi,0,35||",
                "Pitcairn": "8r,0,8c,0,7e|||",
                "Ponape": "8r,0,8c,0,9s|||",
                "Pyongyang": "8r,0,8c,0,8a|||",
                "Reunion": "8r,0,8c,0,b4|||",
                "Rothera": "8r,0,8c,0,5q|||",
                "Sakhalin": "4,0,52,0,8r,0,8c,0,55|8r,0,8c,0,mc,5,mb,0,h8,0,55||",
                "Samara": "4,0,1,0,8c,0,29|29,0,c||",
                "Samoa": "4,0,52,0,8r,0,8c,0,2c|c,0,1,0,8c,0,2c||",
                "Seychelles": "8r,0,8c,0,8k|||",
                "Singapore": "4,0,52,0,8r,0,8c,0,bi|||",
                "Solomon": "8r,0,8c,0,b5,0,o|||",
                "South_Georgia": "8r,0,8c,0,6fg,0,2u|||",
                "Suriname": "8r,0,8c,0,8q|||",
                "Syowa": "8r,0,8c,0,65|||",
                "Tahiti": "8r,0,8c,0,8n|||",
                "Taipei": "4,0,52,0,8r,0,8c,0,3d|c,0,1,0,8c,0,3d||",
                "Tajikistan": "8r,0,8c,0,bj|||",
                "Tokelau": "8r,0,8c,0,5d|||",
                "Tonga": "4,0,52,0,8r,0,8c,0,2j|8r,0,8c,0,mc,5,mb,0,h8,0,2j||",
                "Truk": "8r,0,8c,0,6p|||",
                "Turkmenistan": "4,0,52,0,8r,0,8c,0,4t|8r,0,8c,0,mc,5,mb,0,h8,0,4t||",
                "Tuvalu": "8r,0,8c,0,6h|||",
                "Uruguay": "4,0,52,0,8r,0,8c,0,4k|8r,0,8c,0,mc,5,mb,0,h8,0,4k||",
                "Uzbekistan": "4,0,52,0,8r,0,8c,0,60|8r,0,8c,0,mc,5,mb,0,h8,0,60||",
                "Vanuatu": "4,0,52,0,8r,0,8c,0,2f|8r,0,8c,0,mc,5,mb,0,h8,0,2f||",
                "Venezuela": "8r,0,8c,0,7s|||",
                "Vladivostok": "4,0,52,0,8r,0,8c,0,28|8r,0,8c,0,mc,5,mb,0,h8,0,28||",
                "Volgograd": "4,0,52,0,8r,0,8c,0,3f|8r,0,8c,0,mc,5,mb,0,h8,0,3f||",
                "Vostok": "8r,0,8c,0,5t|||",
                "Wake": "8r,0,8c,0,5r,0,1f|||",
                "Wallis": "8r,0,8c,0,5f,0,4pi,0,5k|||",
                "Yakutsk": "4,0,52,0,8r,0,8c,0,3u|8r,0,8c,0,mc,5,mb,0,h8,0,3u||",
                "Yekaterinburg": "4,0,52,0,8r,0,8c,0,5l|8r,0,8c,0,mc,5,mb,0,h8,0,5l||"
            }
        },
        "fo-DK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6gn,0,9q,0,4s||",
                "Europe/London": "|6gj,0,6gh,0,a9||"
            },
            "metazone": {
                "Afghanistan": "2f0,0,4s|||",
                "Africa_Central": "6ga,0,4s|||",
                "Africa_Eastern": "6gb,0,4s|||",
                "Africa_Southern": "6gc,0,9q,0,4s|||",
                "Africa_Western": "3do,0,9q,0,4s|3do,0,a9||",
                "Alaska": "3p,0,9q,0,4s|3p,0,a9||",
                "Amazon": "3dn,0,9q,0,4s|3dn,0,a9||",
                "America_Central": "n,0,9q,0,4s|n,0,a9||",
                "America_Eastern": "17,0,9q,0,4s|17,0,a9||",
                "America_Mountain": "5a,0,9q,0,4s|5a,0,a9||",
                "America_Pacific": "2h,0,9q,0,4s|2h,0,a9||",
                "Apia": "3e,0,9q,0,4s|3e,0,a9||",
                "Arabian": "24b,0,9q,0,4s|24b,0,a9||",
                "Argentina": "1e,0,9q,0,4s|1e,0,a9||",
                "Argentina_Western": "11j,0,1e,0,9q,0,4s|11j,0,1e,0,a9||",
                "Armenia": "4l,0,9q,0,4s|4l,0,a9||",
                "Atlantic": "6g,0,9q,0,4s|6g,0,a9||",
                "Australia_Central": "3dl,0,17s,0,9q,0,4s|3dl,0,17s,0,a9||",
                "Australia_CentralWestern": "3dj,0,17s,0,9q,0,4s|3dj,0,17s,0,a9||",
                "Australia_Eastern": "3di,0,17s,0,9q,0,4s|3di,0,17s,0,a9||",
                "Australia_Western": "3dh,0,17s,0,9q,0,4s|3dh,0,17s,0,a9||",
                "Azerbaijan": "3df,0,9q,0,4s|3df,0,a9||",
                "Azores": "3dc,0,9q,0,4s|3dc,0,a9||",
                "Bangladesh": "24n,0,9q,0,4s|24n,0,a9||",
                "Bhutan": "1k4,0,4s|||",
                "Bolivia": "aa,0,4s|||",
                "Brasilia": "3a,0,9q,0,4s|3a,0,a9||",
                "Brunei": "9o,0,a4,0,4s|||",
                "Cape_Verde": "3d9,0,9q,0,4s|3d9,0,a9||",
                "Chamorro": "6j,0,9q,0,4s|||",
                "Chatham": "27,0,9q,0,4s|27,0,a9||",
                "Chile": "3d7,0,9q,0,4s|3d7,0,a9||",
                "China": "25m,0,9q,0,4s|25m,0,a9||",
                "Choibalsan": "2q,0,9q,0,4s|2q,0,a9||",
                "Christmas": "6gs,0,4s|||",
                "Cocos": "6gt,0,4s|||",
                "Colombia": "20u,0,9q,0,4s|20u,0,a9||",
                "Cook": "3d5,0,9q,0,4s|3d5,0,a9||",
                "Cuba": "34,0,9q,0,4s|34,0,a9||",
                "Davis": "5s,0,4s|||",
                "DumontDUrville": "5b,5,5j,0,4s|||",
                "East_Timor": "6gv,0,4s|||",
                "Easter": "3d4,0,9q,0,4s|3d4,0,a9||",
                "Ecuador": "1k0,0,4s|||",
                "Europe_Central": "3d2,0,9q,0,4s|3d2,0,a9||",
                "Europe_Eastern": "2in,0,9q,0,4s|2in,0,a9||",
                "Europe_Further_Eastern": "6h4,0,2in,0,4s|||",
                "Europe_Western": "3cs,0,9q,0,4s|3cs,0,a9||",
                "Falkland": "3co,0,9q,0,4s|3co,0,a9||",
                "Fiji": "4q,0,9q,0,4s|4q,0,a9||",
                "French_Guiana": "25t,0,2fd,0,4s|||",
                "French_Southern": "6he,0,6hf,0,6hi,0,u0,0,6hm,0,4s|||",
                "Galapagos": "ak,0,4s|||",
                "Gambier": "6v,0,4s|||",
                "Georgia": "2u,0,9q,0,4s|2u,0,a9||",
                "Gilbert_Islands": "6hn,0,4s|||",
                "GMT": "5u,0,bh,0,4s|||",
                "Greenland_Eastern": "25v,0,25u,0,9q,0,4s|25v,0,25u,0,a9||",
                "Greenland_Western": "11j,0,25u,0,9q,0,4s|11j,0,25u,0,a9||",
                "Gulf": "bn,0,9q,0,4s|||",
                "Guyana": "2fd,0,4s|||",
                "Hawaii_Aleutian": "4g,5,62,0,9q,0,4s|4g,5,62,0,a9||",
                "Hong_Kong": "2l,0,2p,0,9q,0,4s|2l,0,2p,0,a9||",
                "Hovd": "2g,0,9q,0,4s|2g,0,a9||",
                "India": "af,0,4s|||",
                "Indian_Ocean": "6ht,0,4s|||",
                "Indochina": "23t,0,4s|||",
                "Indonesia_Central": "25s,0,2t,0,4s|||",
                "Indonesia_Eastern": "25v,0,2t,0,4s|||",
                "Indonesia_Western": "11j,0,2t,0,4s|||",
                "Iran": "5g,0,9q,0,4s|5g,0,a9||",
                "Irkutsk": "3h,0,9q,0,4s|3h,0,a9||",
                "Israel": "2fv,0,9q,0,4s|2fv,0,a9||",
                "Japan": "6o,0,9q,0,4s|6o,0,a9||",
                "Kazakhstan_Eastern": "25v,0,2g1,0,4s|||",
                "Kazakhstan_Western": "11j,0,2g1,0,4s|||",
                "Korea": "qf,0,9q,0,4s|qf,0,a9||",
                "Kosrae": "6f,0,4s|||",
                "Krasnoyarsk": "41,0,9q,0,4s|41,0,a9||",
                "Kyrgystan": "6i4,0,4s|||",
                "Line_Islands": "6i5,0,4s|||",
                "Lord_Howe": "1u,0,24,0,9q,0,4s|1u,0,24,0,a9||",
                "Macquarie": "6i6,0,4s|||",
                "Magadan": "2o,0,9q,0,4s|2o,0,a9||",
                "Malaysia": "3lm,0,4s|||",
                "Maldives": "6i9,0,4s|||",
                "Marquesas": "9e,0,4s|||",
                "Marshall_Islands": "6ib,0,4s|||",
                "Mauritius": "3cl,0,9q,0,4s|3cl,0,a9||",
                "Mawson": "63,0,4s|||",
                "Mexico_Northwest": "80,0,5e,0,9q,0,4s|80,0,5e,0,a9||",
                "Mexico_Pacific": "81,0,2h,0,9q,0,4s|81,0,2h,0,a9||",
                "Mongolia": "4f,0,2m,0,9q,0,4s|4f,0,2m,0,a9||",
                "Moscow": "pi,0,9q,0,4s|pi,0,a9||",
                "Myanmar": "8v,0,6id,0,4s|||",
                "Nauru": "5v,0,4s|||",
                "Nepal": "8u,0,4s|||",
                "New_Caledonia": "3ck,0,9q,0,4s|3ck,0,a9||",
                "New_Zealand": "3cj,0,9q,0,4s|3cj,0,a9||",
                "Newfoundland": "53,0,9q,0,4s|53,0,a9||",
                "Niue": "8g,0,4s|||",
                "Norfolk": "6ih,0,4s|||",
                "Noronha": "1s,0,2,0,23,0,9q,0,4s|1s,0,2,0,23,0,a9||",
                "Novosibirsk": "3b,0,9q,0,4s|3b,0,a9||",
                "Omsk": "26,0,9q,0,4s|26,0,a9||",
                "Pakistan": "3i,0,9q,0,4s|3i,0,a9||",
                "Palau": "9b,0,4s|||",
                "Papua_New_Guinea": "9a,0,6ii,0,4s|||",
                "Paraguay": "p8,0,9q,0,4s|p8,0,a9||",
                "Peru": "4n,0,9q,0,4s|4n,0,a9||",
                "Philippines": "3ci,0,9q,0,4s|3ci,0,a9||",
                "Phoenix_Islands": "6io,0,4s|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,9q,0,4s|7u,0,33,0,31,0,35,0,a9||",
                "Pitcairn": "6ip,0,4s|||",
                "Ponape": "9s,0,4s|||",
                "Pyongyang": "8a,0,4s|||",
                "Reunion": "i8,0,4s|||",
                "Rothera": "5q,0,4s|||",
                "Sakhalin": "55,0,9q,0,4s|55,0,a9||",
                "Samoa": "2c,0,9q,0,4s|2c,0,a9||",
                "Seychelles": "6iq,0,4s|||",
                "Singapore": "6ir,0,4s|||",
                "Solomon": "6is,0,4s|||",
                "South_Georgia": "6it,0,4s|||",
                "Suriname": "mg,0,4s|||",
                "Syowa": "65,0,4s|||",
                "Tahiti": "8n,0,4s|||",
                "Taipei": "3d,0,9q,0,4s|3d,0,a9||",
                "Tajikistan": "6iu,0,4s|||",
                "Tokelau": "5d,0,4s|||",
                "Tonga": "2j,0,9q,0,4s|2j,0,a9||",
                "Truk": "6p,0,4s|||",
                "Turkmenistan": "4t,0,9q,0,4s|4t,0,a9||",
                "Tuvalu": "6h,0,4s|||",
                "Uruguay": "rn,0,9q,0,4s|rn,0,a9||",
                "Uzbekistan": "14m,0,9q,0,4s|14m,0,a9||",
                "Vanuatu": "2f,0,9q,0,4s|2f,0,a9||",
                "Venezuela": "2g9,0,4s|||",
                "Vladivostok": "28,0,9q,0,4s|28,0,a9||",
                "Volgograd": "3f,0,9q,0,4s|3f,0,a9||",
                "Vostok": "5t,0,4s|||",
                "Wake": "6j0,0,4s|||",
                "Wallis": "5f,5,0,u0,0,6j1,0,4s|||",
                "Yakutsk": "3u,0,9q,0,4s|3u,0,a9||",
                "Yekaterinburg": "5l,0,9q,0,4s|5l,0,a9||"
            }
        },
        "fo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6gn,0,9q,0,4s||",
                "Europe/London": "|6gj,0,6gh,0,a9||"
            },
            "metazone": {
                "Afghanistan": "2f0,0,4s|||",
                "Africa_Central": "6ga,0,4s|||",
                "Africa_Eastern": "6gb,0,4s|||",
                "Africa_Southern": "6gc,0,9q,0,4s|||",
                "Africa_Western": "3do,0,9q,0,4s|3do,0,a9||",
                "Alaska": "3p,0,9q,0,4s|3p,0,a9||",
                "Amazon": "3dn,0,9q,0,4s|3dn,0,a9||",
                "America_Central": "n,0,9q,0,4s|n,0,a9||",
                "America_Eastern": "17,0,9q,0,4s|17,0,a9||",
                "America_Mountain": "5a,0,9q,0,4s|5a,0,a9||",
                "America_Pacific": "2h,0,9q,0,4s|2h,0,a9||",
                "Apia": "3e,0,9q,0,4s|3e,0,a9||",
                "Arabian": "24b,0,9q,0,4s|24b,0,a9||",
                "Argentina": "1e,0,9q,0,4s|1e,0,a9||",
                "Argentina_Western": "11j,0,1e,0,9q,0,4s|11j,0,1e,0,a9||",
                "Armenia": "4l,0,9q,0,4s|4l,0,a9||",
                "Atlantic": "6g,0,9q,0,4s|6g,0,a9||",
                "Australia_Central": "3dl,0,17s,0,9q,0,4s|3dl,0,17s,0,a9||",
                "Australia_CentralWestern": "3dj,0,17s,0,9q,0,4s|3dj,0,17s,0,a9||",
                "Australia_Eastern": "3di,0,17s,0,9q,0,4s|3di,0,17s,0,a9||",
                "Australia_Western": "3dh,0,17s,0,9q,0,4s|3dh,0,17s,0,a9||",
                "Azerbaijan": "3df,0,9q,0,4s|3df,0,a9||",
                "Azores": "3dc,0,9q,0,4s|3dc,0,a9||",
                "Bangladesh": "24n,0,9q,0,4s|24n,0,a9||",
                "Bhutan": "1k4,0,4s|||",
                "Bolivia": "aa,0,4s|||",
                "Brasilia": "3a,0,9q,0,4s|3a,0,a9||",
                "Brunei": "9o,0,a4,0,4s|||",
                "Cape_Verde": "3d9,0,9q,0,4s|3d9,0,a9||",
                "Chamorro": "6j,0,9q,0,4s|||",
                "Chatham": "27,0,9q,0,4s|27,0,a9||",
                "Chile": "3d7,0,9q,0,4s|3d7,0,a9||",
                "China": "25m,0,9q,0,4s|25m,0,a9||",
                "Choibalsan": "2q,0,9q,0,4s|2q,0,a9||",
                "Christmas": "6gs,0,4s|||",
                "Cocos": "6gt,0,4s|||",
                "Colombia": "20u,0,9q,0,4s|20u,0,a9||",
                "Cook": "3d5,0,9q,0,4s|3d5,0,a9||",
                "Cuba": "34,0,9q,0,4s|34,0,a9||",
                "Davis": "5s,0,4s|||",
                "DumontDUrville": "5b,5,5j,0,4s|||",
                "East_Timor": "6gv,0,4s|||",
                "Easter": "3d4,0,9q,0,4s|3d4,0,a9||",
                "Ecuador": "1k0,0,4s|||",
                "Europe_Central": "3d2,0,9q,0,4s|3d2,0,a9||",
                "Europe_Eastern": "2in,0,9q,0,4s|2in,0,a9||",
                "Europe_Further_Eastern": "6h4,0,2in,0,4s|||",
                "Europe_Western": "3cs,0,9q,0,4s|3cs,0,a9||",
                "Falkland": "3co,0,9q,0,4s|3co,0,a9||",
                "Fiji": "4q,0,9q,0,4s|4q,0,a9||",
                "French_Guiana": "25t,0,2fd,0,4s|||",
                "French_Southern": "6he,0,6hf,0,6hi,0,u0,0,6hm,0,4s|||",
                "Galapagos": "ak,0,4s|||",
                "Gambier": "6v,0,4s|||",
                "Georgia": "2u,0,9q,0,4s|2u,0,a9||",
                "Gilbert_Islands": "6hn,0,4s|||",
                "GMT": "5u,0,bh,0,4s|||",
                "Greenland_Eastern": "25v,0,25u,0,9q,0,4s|25v,0,25u,0,a9||",
                "Greenland_Western": "11j,0,25u,0,9q,0,4s|11j,0,25u,0,a9||",
                "Gulf": "bn,0,9q,0,4s|||",
                "Guyana": "2fd,0,4s|||",
                "Hawaii_Aleutian": "4g,5,62,0,9q,0,4s|4g,5,62,0,a9||",
                "Hong_Kong": "2l,0,2p,0,9q,0,4s|2l,0,2p,0,a9||",
                "Hovd": "2g,0,9q,0,4s|2g,0,a9||",
                "India": "af,0,4s|||",
                "Indian_Ocean": "6ht,0,4s|||",
                "Indochina": "23t,0,4s|||",
                "Indonesia_Central": "25s,0,2t,0,4s|||",
                "Indonesia_Eastern": "25v,0,2t,0,4s|||",
                "Indonesia_Western": "11j,0,2t,0,4s|||",
                "Iran": "5g,0,9q,0,4s|5g,0,a9||",
                "Irkutsk": "3h,0,9q,0,4s|3h,0,a9||",
                "Israel": "2fv,0,9q,0,4s|2fv,0,a9||",
                "Japan": "6o,0,9q,0,4s|6o,0,a9||",
                "Kazakhstan_Eastern": "25v,0,2g1,0,4s|||",
                "Kazakhstan_Western": "11j,0,2g1,0,4s|||",
                "Korea": "qf,0,9q,0,4s|qf,0,a9||",
                "Kosrae": "6f,0,4s|||",
                "Krasnoyarsk": "41,0,9q,0,4s|41,0,a9||",
                "Kyrgystan": "6i4,0,4s|||",
                "Line_Islands": "6i5,0,4s|||",
                "Lord_Howe": "1u,0,24,0,9q,0,4s|1u,0,24,0,a9||",
                "Macquarie": "6i6,0,4s|||",
                "Magadan": "2o,0,9q,0,4s|2o,0,a9||",
                "Malaysia": "3lm,0,4s|||",
                "Maldives": "6i9,0,4s|||",
                "Marquesas": "9e,0,4s|||",
                "Marshall_Islands": "6ib,0,4s|||",
                "Mauritius": "3cl,0,9q,0,4s|3cl,0,a9||",
                "Mawson": "63,0,4s|||",
                "Mexico_Northwest": "80,0,5e,0,9q,0,4s|80,0,5e,0,a9||",
                "Mexico_Pacific": "81,0,2h,0,9q,0,4s|81,0,2h,0,a9||",
                "Mongolia": "4f,0,2m,0,9q,0,4s|4f,0,2m,0,a9||",
                "Moscow": "pi,0,9q,0,4s|pi,0,a9||",
                "Myanmar": "8v,0,6id,0,4s|||",
                "Nauru": "5v,0,4s|||",
                "Nepal": "8u,0,4s|||",
                "New_Caledonia": "3ck,0,9q,0,4s|3ck,0,a9||",
                "New_Zealand": "3cj,0,9q,0,4s|3cj,0,a9||",
                "Newfoundland": "53,0,9q,0,4s|53,0,a9||",
                "Niue": "8g,0,4s|||",
                "Norfolk": "6ih,0,4s|||",
                "Noronha": "1s,0,2,0,23,0,9q,0,4s|1s,0,2,0,23,0,a9||",
                "Novosibirsk": "3b,0,9q,0,4s|3b,0,a9||",
                "Omsk": "26,0,9q,0,4s|26,0,a9||",
                "Pakistan": "3i,0,9q,0,4s|3i,0,a9||",
                "Palau": "9b,0,4s|||",
                "Papua_New_Guinea": "9a,0,6ii,0,4s|||",
                "Paraguay": "p8,0,9q,0,4s|p8,0,a9||",
                "Peru": "4n,0,9q,0,4s|4n,0,a9||",
                "Philippines": "3ci,0,9q,0,4s|3ci,0,a9||",
                "Phoenix_Islands": "6io,0,4s|||",
                "Pierre_Miquelon": "7u,0,33,0,31,0,35,0,9q,0,4s|7u,0,33,0,31,0,35,0,a9||",
                "Pitcairn": "6ip,0,4s|||",
                "Ponape": "9s,0,4s|||",
                "Pyongyang": "8a,0,4s|||",
                "Reunion": "i8,0,4s|||",
                "Rothera": "5q,0,4s|||",
                "Sakhalin": "55,0,9q,0,4s|55,0,a9||",
                "Samoa": "2c,0,9q,0,4s|2c,0,a9||",
                "Seychelles": "6iq,0,4s|||",
                "Singapore": "6ir,0,4s|||",
                "Solomon": "6is,0,4s|||",
                "South_Georgia": "6it,0,4s|||",
                "Suriname": "mg,0,4s|||",
                "Syowa": "65,0,4s|||",
                "Tahiti": "8n,0,4s|||",
                "Taipei": "3d,0,9q,0,4s|3d,0,a9||",
                "Tajikistan": "6iu,0,4s|||",
                "Tokelau": "5d,0,4s|||",
                "Tonga": "2j,0,9q,0,4s|2j,0,a9||",
                "Truk": "6p,0,4s|||",
                "Turkmenistan": "4t,0,9q,0,4s|4t,0,a9||",
                "Tuvalu": "6h,0,4s|||",
                "Uruguay": "rn,0,9q,0,4s|rn,0,a9||",
                "Uzbekistan": "14m,0,9q,0,4s|14m,0,a9||",
                "Vanuatu": "2f,0,9q,0,4s|2f,0,a9||",
                "Venezuela": "2g9,0,4s|||",
                "Vladivostok": "28,0,9q,0,4s|28,0,a9||",
                "Volgograd": "3f,0,9q,0,4s|3f,0,a9||",
                "Vostok": "5t,0,4s|||",
                "Wake": "6j0,0,4s|||",
                "Wallis": "5f,5,0,u0,0,6j1,0,4s|||",
                "Yakutsk": "3u,0,9q,0,4s|3u,0,a9||",
                "Yekaterinburg": "5l,0,9q,0,4s|5l,0,a9||"
            }
        },
        "fr-BE": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-BF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-BI": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-BJ": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-BL": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CA": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,f0,0,nq||",
                "Europe/London": "|3,0,f0,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,f0,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,77,0,9r|||",
                "Africa_Eastern": "bei,0,77,0,op|||",
                "Africa_Southern": "3,0,a,0,77,0,f,0,i6|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,f0,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,f0,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,f0,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,f0,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,cc|3,0,f0,0,f,0,cc|beh|beg",
                "America_Eastern": "3,0,a,0,2,0,1v|3,0,f0,0,2,0,1v|bee|bed",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,f0,0,j,0,e3|bec|beb",
                "America_Pacific": "3,0,a,0,f,0,8t|3,0,f0,0,f,0,8t|bea|be8",
                "Anadyr": "3,0,a,0,cb|3,0,f0,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,f0,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,f0,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,f0,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,f0,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,f0,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,f0,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,f0,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,f0,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,f0,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,f0,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,f0,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,f0,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,f0,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,f0,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,f0,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,f0,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,f0,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,6qi,0,27|3,0,f0,0,j,0,6qi,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,f0,0,f,0,cg||",
                "China": "3,0,a,0,2,0,d5|3,0,f0,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,f0,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,f0,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,f0,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,f0,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,f0,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,f0,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,f0,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,f0,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,f0,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,f0,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,f0,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,f0,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,f0,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,6ql,5,ds|3,0,f0,0,6ql,5,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,f0,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,f0,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,f0,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,f0,0,da||",
                "Israel": "3,0,a,0,db|3,0,f0,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,f0,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,f0,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,f0,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,f0,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,f0,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,f0,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,f0,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,f0,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,f0,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,f0,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,f0,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,f0,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,f0,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,f0,0,2,0,co,5,de|be7|be6",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,f0,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,f0,0,2,0,di||",
                "Omsk": "3,0,a,0,1d1|3,0,f0,0,1d1||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,f0,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,f0,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,f0,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,f0,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,f0,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,1c,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,f0,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,f0,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,f0,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,f0,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,f0,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,f0,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,f0,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,f0,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,f0,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,f0,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,f0,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,f0,0,dv||"
            }
        },
        "fr-CD": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CG": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CH": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CI": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-CM": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-DJ": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-DZ": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-GA": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-GF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne||be5|",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-GN": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-GP": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-GQ": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-HT": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-KM": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-LU": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MA": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MC": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MG": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-ML": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MQ": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MR": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-MU": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-NC": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-NE": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-PF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-PM": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-RE": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-RW": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-SC": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-SN": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-SY": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-TD": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-TG": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-TN": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-VU": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-WF": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr-YT": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fr": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {
                "Europe/Dublin": "|3,0,b,0,nq||",
                "Europe/London": "|3,0,b,0,o4||"
            },
            "metazone": {
                "Acre": "3,0,a,0,2,0,d8|3,0,b,0,2,0,d8||",
                "Afghanistan": "3,0,2,0,o3|||",
                "Africa_Central": "3,0,a,0,77,0,9r|||",
                "Africa_Eastern": "3,0,a,0,77,0,2,0,1v|||",
                "Africa_Southern": "3,0,a,0,77,0,o8|||",
                "Africa_Western": "3,0,a,0,77,0,2,0,1p|3,0,b,0,77,0,2,0,1p||",
                "Alaska": "3,0,a,0,2,0,d4|3,0,b,0,2,0,d4||",
                "Almaty": "3,0,a,0,d2,0,c2|3,0,b,0,d2,0,c2||",
                "Amazon": "3,0,a,0,2,0,cv|3,0,b,0,2,0,cv||",
                "America_Central": "3,0,a,0,f,0,6n,0,93,5,b0|3,0,b,0,f,0,cc||",
                "America_Eastern": "3,0,a,0,2,0,1v,0,93,5,b0|3,0,b,0,2,0,1v||",
                "America_Mountain": "3,0,a,0,j,0,e3|3,0,b,0,j,0,e3||",
                "America_Pacific": "3,0,a,0,f,0,8t,0,93,5,b0|3,0,b,0,f,0,8t||",
                "Anadyr": "3,0,a,0,cb|3,0,b,0,cb||",
                "Apia": "3,0,a,0,c9|3,0,b,0,c9||",
                "Aqtau": "3,0,a,0,e0|3,0,b,0,e0||",
                "Aqtobe": "3,0,a,0,dm|3,0,b,0,dm||",
                "Arabian": "3,0,a,0,2,0,dl|3,0,b,0,2,0,dl||",
                "Argentina": "3,0,a,0,na|3,0,b,0,2,0,o6||",
                "Argentina_Western": "3,0,a,0,2,0,1p,0,df|3,0,b,0,2,0,1p,0,df||",
                "Armenia": "3,0,a,0,2,0,nh|3,0,b,0,ni||",
                "Atlantic": "3,0,a,0,2,0,dd|3,0,b,0,2,0,dd||",
                "Australia_Central": "3,0,a,0,f,0,6n,0,2,0,3m|3,0,b,0,f,0,6n,0,2,0,3m||",
                "Australia_CentralWestern": "3,0,a,0,f,0,6n,5,d7,0,2,0,3m|3,0,b,0,f,0,6n,5,d7,0,2,0,3m||",
                "Australia_Eastern": "3,0,a,0,2,0,1v,0,2,0,3m|3,0,b,0,2,0,1v,0,2,0,3m||",
                "Australia_Western": "3,0,a,0,2,0,1p,0,2,0,3m|3,0,b,0,2,0,1p,0,2,0,3m||",
                "Azerbaijan": "3,0,a,0,2,0,ns|3,0,b,0,nn||",
                "Azores": "3,0,a,0,j,0,b9|3,0,b,0,j,0,b9||",
                "Bangladesh": "3,0,a,0,f,0,3n|3,0,b,0,f,0,3n||",
                "Bhutan": "3,0,f,0,n4|||",
                "Bolivia": "3,0,2,0,nj|||",
                "Brasilia": "3,0,a,0,2,0,3a|3,0,b,0,2,0,3a||",
                "Brunei": "3,0,f,0,gs|||",
                "Cape_Verde": "3,0,a,0,f,0,cf,5,d6|3,0,b,0,f,0,cf,5,d6||",
                "Chamorro": "3,0,j,0,6j|||",
                "Chatham": "3,0,a,0,j,0,12,0,27|3,0,b,0,j,0,12,0,27||",
                "Chile": "3,0,a,0,f,0,cg|3,0,b,0,f,0,cg||",
                "China": "3,0,a,0,2,0,1c,0,d5|3,0,b,0,2,0,d5||",
                "Choibalsan": "3,0,a,0,2,0,2q|3,0,b,0,2,0,2q||",
                "Christmas": "3,0,2,0,4a,0,99|||",
                "Cocos": "3,0,j,0,12,0,7v|||",
                "Colombia": "3,0,a,0,2,0,e1|3,0,b,0,2,0,e1||",
                "Cook": "3,0,a,0,j,0,12,0,2v|3,0,b,0,j,0,12,0,2v||",
                "Cuba": "3,0,a,0,2,0,34|3,0,b,0,2,0,34||",
                "Davis": "3,0,2,0,5s|||",
                "DumontDUrville": "3,0,2,0,5b,5,5j|||",
                "East_Timor": "3,0,f,0,7n,0,3r|||",
                "Easter": "3,0,a,0,2,0,4a,0,2,0,dt|3,0,b,0,2,0,4a,0,2,0,dt||",
                "Ecuador": "3,0,2,0,ng|||",
                "Europe_Central": "3,0,a,0,54,0,9r|3,0,b,0,54,0,9r||",
                "Europe_Eastern": "3,0,a,0,54,0,2,0,1v|3,0,b,0,54,0,2,0,1v||",
                "Europe_Further_Eastern": "3,0,2,0,mo|||",
                "Europe_Western": "3,0,a,0,54,0,2,0,1p|3,0,b,0,54,0,2,0,1p||",
                "Falkland": "3,0,a,0,j,0,12,0,dr|3,0,b,0,j,0,12,0,dr||",
                "Fiji": "3,0,a,0,j,0,12,0,cm|3,0,b,0,j,0,12,0,cm||",
                "French_Guiana": "3,0,2,0,1c,0,n1,0,ne|||",
                "French_Southern": "3,0,j,0,nc,0,o5,0,8p,0,o1,0,no|||",
                "Galapagos": "3,0,j,0,12,0,cp|||",
                "Gambier": "3,0,j,0,12,0,6v|||",
                "Georgia": "3,0,a,0,2,0,1c,0,aq|3,0,b,0,2,0,aq||",
                "Gilbert_Islands": "3,0,j,0,12,0,6q|||",
                "GMT": "3,0,np,0,2,0,5u|||",
                "Greenland_Eastern": "3,0,a,0,2,0,1v,0,f,0,8o|3,0,b,0,2,0,1v,0,f,0,8o||",
                "Greenland_Western": "3,0,a,0,2,0,1p,0,f,0,8o|3,0,b,0,2,0,1p,0,f,0,8o||",
                "Guam": "3,0,2,0,83|||",
                "Gulf": "3,0,f,0,n7|||",
                "Guyana": "3,0,f,0,7a|||",
                "Hawaii_Aleutian": "3,0,a,0,ea,0,5,0,ds|3,0,b,0,ea,0,5,0,ds||",
                "Hong_Kong": "3,0,a,0,2,0,2l,0,2p|3,0,b,0,2,0,2l,0,2p||",
                "Hovd": "3,0,a,0,2,0,2g|3,0,b,0,2,0,2g||",
                "India": "3,0,2,0,nv|||",
                "Indian_Ocean": "3,0,2,0,n5,0,n8|||",
                "Indochina": "3,0,nb|||",
                "Indonesia_Central": "3,0,f,0,cc,0,ar|||",
                "Indonesia_Eastern": "3,0,2,0,1v,0,ar|||",
                "Indonesia_Western": "3,0,2,0,1p,0,ar|||",
                "Iran": "3,0,a,0,ce|3,0,b,0,ce||",
                "Irkutsk": "3,0,a,0,da|3,0,b,0,da||",
                "Israel": "3,0,a,0,db|3,0,b,0,db||",
                "Japan": "3,0,a,0,f,0,dg|3,0,b,0,f,0,dg||",
                "Kamchatka": "3,0,a,0,2,0,37,5,3k|3,0,b,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "3,0,2,0,1v,0,f,0,45|||",
                "Kazakhstan_Western": "3,0,2,0,1p,0,f,0,45|||",
                "Korea": "3,0,a,0,2,0,1c,0,d3|3,0,b,0,2,0,d3||",
                "Kosrae": "3,0,2,0,6f|||",
                "Krasnoyarsk": "3,0,a,0,2,0,d9|3,0,b,0,2,0,d9||",
                "Kyrgystan": "3,0,f,0,mm|||",
                "Line_Islands": "3,0,j,0,12,0,2,0,1c,0,n9|||",
                "Lord_Howe": "3,0,a,0,2,0,1u,0,24|3,0,b,0,2,0,1u,0,24||",
                "Macquarie": "3,0,2,0,4a,0,6m|||",
                "Magadan": "3,0,a,0,2,0,2o|3,0,b,0,2,0,2o||",
                "Malaysia": "3,0,2,0,1c,0,nt|||",
                "Maldives": "3,0,j,0,9k|||",
                "Marquesas": "3,0,j,0,12,0,n3|||",
                "Marshall_Islands": "3,0,j,0,12,0,7o|||",
                "Mauritius": "3,0,a,0,2,0,cs|3,0,b,0,2,0,cs||",
                "Mawson": "3,0,2,0,63|||",
                "Mexico_Northwest": "3,0,a,0,f,0,98,5,e2,0,f,0,e4|3,0,b,0,f,0,98,5,e2,0,f,0,e4||",
                "Mexico_Pacific": "3,0,a,0,f,0,8t,0,ct|3,0,b,0,f,0,8t,0,ct||",
                "Mongolia": "3,0,a,0,cu,5,2m|3,0,b,0,cu,5,2m||",
                "Moscow": "3,0,a,0,2,0,ca|3,0,b,0,2,0,ca||",
                "Myanmar": "3,0,f,0,8v|||",
                "Nauru": "3,0,2,0,5v|||",
                "Nepal": "3,0,f,0,n6|||",
                "New_Caledonia": "3,0,a,0,2,0,1c,0,76,5,d1|3,0,b,0,2,0,76,5,d1||",
                "New_Zealand": "3,0,a,0,2,0,1c,0,76,5,dc|3,0,b,0,2,0,1c,0,76,5,dc||",
                "Newfoundland": "3,0,a,0,2,0,co,5,de|3,0,b,0,2,0,co,5,de||",
                "Niue": "3,0,2,0,nr|||",
                "Norfolk": "3,0,2,0,4a,0,6l|||",
                "Noronha": "3,0,a,0,2,0,1s,0,2,0,23|3,0,b,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "3,0,j,0,12,0,nu,0,f,0,98|||",
                "Novosibirsk": "3,0,a,0,2,0,di|3,0,b,0,2,0,di||",
                "Omsk": "3,0,a,0,2,0,26|3,0,b,0,2,0,26||",
                "Pakistan": "3,0,a,0,f,0,3i|3,0,b,0,f,0,3i||",
                "Palau": "3,0,j,0,gt|||",
                "Papua_New_Guinea": "3,0,2,0,1c,0,o2,5,76,5,o0|||",
                "Paraguay": "3,0,a,0,f,0,3c|3,0,b,0,f,0,3c||",
                "Peru": "3,0,a,0,f,0,dj|3,0,b,0,f,0,dj||",
                "Philippines": "3,0,a,0,j,0,dk|3,0,b,0,j,0,dk||",
                "Phoenix_Islands": "3,0,j,0,12,0,90|||",
                "Pierre_Miquelon": "3,0,a,0,2,0,8i,5,33,5,8p,5,35|3,0,b,0,2,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "3,0,j,0,12,0,7e|||",
                "Ponape": "3,0,2,0,4a,0,2,0,e5|||",
                "Pyongyang": "3,0,2,0,8a|||",
                "Reunion": "3,0,2,0,mv,0,i8|||",
                "Rothera": "3,0,2,0,5q|||",
                "Sakhalin": "3,0,a,0,2,0,dn|3,0,b,0,2,0,dn||",
                "Samara": "3,0,a,0,2,0,29|3,0,b,0,2,0,29||",
                "Samoa": "3,0,a,0,j,0,2c|3,0,b,0,j,0,2c||",
                "Seychelles": "3,0,j,0,8k|||",
                "Singapore": "3,0,2,0,mu|||",
                "Solomon": "3,0,j,0,12,0,mq|||",
                "South_Georgia": "3,0,2,0,aq,0,f,0,i6|||",
                "Suriname": "3,0,f,0,8q|||",
                "Syowa": "3,0,2,0,65|||",
                "Tahiti": "3,0,2,0,8n|||",
                "Taipei": "3,0,a,0,2,0,3d|3,0,b,0,2,0,3d||",
                "Tajikistan": "3,0,f,0,lc|||",
                "Tokelau": "3,0,2,0,5d|||",
                "Tonga": "3,0,a,0,j,0,2j|3,0,b,0,2,0,2j||",
                "Truk": "3,0,2,0,6p|||",
                "Turkmenistan": "3,0,a,0,f,0,dp|3,0,b,0,f,0,dp||",
                "Tuvalu": "3,0,j,0,6h|||",
                "Uruguay": "3,0,a,0,2,0,dq|3,0,b,0,2,0,dq||",
                "Uzbekistan": "3,0,a,0,2,0,d0|3,0,b,0,2,0,d0||",
                "Vanuatu": "3,0,a,0,f,0,2f|3,0,b,0,2,0,2f||",
                "Venezuela": "3,0,f,0,7s|||",
                "Vladivostok": "3,0,a,0,2,0,28|3,0,b,0,2,0,28||",
                "Volgograd": "3,0,a,0,2,0,3f|3,0,b,0,2,0,3f||",
                "Vostok": "3,0,2,0,5t|||",
                "Wake": "3,0,2,0,4a,0,5r|||",
                "Wallis": "3,0,2,0,5f,5,8p,5,5k|||",
                "Yakutsk": "3,0,a,0,2,0,du|3,0,b,0,2,0,du||",
                "Yekaterinburg": "3,0,a,0,dv|3,0,b,0,dv||"
            }
        },
        "fur": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Europe_Central": "234,0,38,0,2,0,r4,0,6r7|234,0,3c7,0,2,0,r4,0,6r7|h0|h4",
                "Europe_Eastern": "234,0,38,0,2,0,r4,0,6r9|234,0,3c7,0,2,0,r4,0,6r9|gu|gv",
                "Europe_Western": "234,0,38,0,2,0,r4,0,6rb|234,0,3c7,0,2,0,r4,0,6rb|h1|h2",
                "GMT": "||ah|",
                "Moscow": "234,0,38,0,4i,0,6rc|234,0,3c7,0,4i,0,6rc||"
            }
        },
        "fy": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,ev||",
                "Europe/London": "|1mh,0,ev||"
            },
            "metazone": {
                "Acre": "3q,5,er|3q,5,ev||",
                "Afghanistan": "be4,0,hq|||",
                "Africa_Central": "6rm,5,30r,0,hq|||",
                "Africa_Eastern": "1r,5,30r,0,hq|||",
                "Africa_Southern": "6rp,5,30r,0,hq|||",
                "Africa_Western": "1g,5,30r,0,er|1g,5,30r,0,ev||",
                "Alaska": "3p,5,er|3p,5,ev||",
                "Almaty": "16j,5,c2,5,er|16j,5,c2,5,ev||",
                "Amazon": "16q,5,er|16q,5,ev||",
                "America_Central": "n,5,er|n,5,ev||",
                "America_Eastern": "17,5,er|17,5,ev||",
                "America_Mountain": "5a,5,er|5a,5,ev||",
                "America_Pacific": "10c,5,er|10c,5,ev||",
                "Anadyr": "3l,5,er|3l,5,ev||",
                "Aqtau": "50,5,er|50,5,ev||",
                "Aqtobe": "rs,5,er|rs,5,ev||",
                "Arabian": "6s8,0,er|6s8,0,ev||",
                "Argentina": "3c6,0,er|3c6,0,ev||",
                "Argentina_Western": "1g,5,3c6,0,er|1g,5,3c6,0,ev||",
                "Armenia": "16v,0,er|16v,0,ev||",
                "Atlantic": "6g,5,er|6g,5,ev||",
                "Australia_Central": "ms,5,22r,0,er|ms,5,22r,0,ev||",
                "Australia_CentralWestern": "ms,5,22r,0,174,0,er|ms,5,22r,0,174,0,ev||",
                "Australia_Eastern": "1r,5,22r,0,er|1r,5,22r,0,ev||",
                "Australia_Western": "1g,5,22r,0,er|1g,5,22r,0,ev||",
                "Azerbaijan": "6t2,0,er|6t2,0,ev||",
                "Azores": "qv,5,er|qv,5,ev||",
                "Bangladesh": "17c,0,er|17c,0,ev||",
                "Bhutan": "be3,0,hq|||",
                "Bolivia": "be2,0,hq|||",
                "Brasilia": "6ta,0,er|6ta,0,ev||",
                "Brunei": "22q,0,hq|||",
                "Cape_Verde": "6tf,0,er|6tf,0,ev||",
                "Chamorro": "6j,5,hq|||",
                "Chatham": "27,0,er|27,0,ev||",
                "Chile": "6tg,0,er|6tg,0,ev||",
                "China": "6tj,0,er|6tj,0,ev||",
                "Choibalsan": "148,0,er|148,0,ev||",
                "Christmas": "be1,0,hq|||",
                "Cocos": "be0,0,hq|||",
                "Colombia": "6tu,0,er|6tu,0,ev||",
                "Cook": "6tv,0,er|6tv,0,22m,0,ev||",
                "Cuba": "6u1,0,er|6u1,0,ev||",
                "Davis": "5s,0,hq|||",
                "DumontDUrville": "5b,5,5j,0,hq|||",
                "East_Timor": "1r,5,22l,0,hq|||",
                "Easter": "6uf,0,er|6uf,0,ev||",
                "Ecuador": "bdv,0,hq|||",
                "Europe_Central": "ms,5,2j6,0,er|ms,5,2j6,0,ev|h0|h4",
                "Europe_Eastern": "1r,5,2j6,0,er|1r,5,2j6,0,ev|gu|gv",
                "Europe_Western": "1g,5,2j6,0,er|1g,5,2j6,0,ev|h1|h2",
                "Falkland": "6vi,0,er|6vi,0,ev||",
                "Fiji": "6vo,0,er|6vo,0,ev||",
                "French_Guiana": "bdu,5,70q,0,hq|||",
                "French_Southern": "bdt,0,bds,0,ou,0,bdr,0,hq|||",
                "Galapagos": "bdq,0,hq|||",
                "Gambier": "bdp,0,hq|||",
                "Georgia": "4pj,0,er|4pj,0,ev||",
                "Gilbert_Islands": "bdo,0,hq|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "1r,5,3br,0,er|1r,5,3br,0,ev||",
                "Greenland_Western": "1g,5,3br,0,er|1g,5,3br,0,ev||",
                "Guam": "22b,0,er|||",
                "Gulf": "127,0,er|||",
                "Guyana": "70q,0,hq|||",
                "Hawaii_Aleutian": "4g,5,71t,0,er|4g,5,71t,0,ev||",
                "Hong_Kong": "188,0,er|188,0,ev||",
                "Hovd": "2g,0,er|2g,0,ev||",
                "India": "bdn,0,hq|||",
                "Indian_Ocean": "bdm,0,229,5,hq|||",
                "Indochina": "bdl,0,hq|||",
                "Indonesia_Central": "6rm,5,4pk,0,hq|||",
                "Indonesia_Eastern": "1r,5,4pk,0,hq|||",
                "Indonesia_Western": "1g,5,4pk,0,hq|||",
                "Iran": "72b,0,er|72b,0,ev||",
                "Irkutsk": "16k,5,er|16k,5,ev||",
                "Israel": "72d,0,er|72d,0,ev||",
                "Japan": "72e,0,er|72e,0,ev||",
                "Kamchatka": "37,5,16l,5,er|37,5,16l,5,ev||",
                "Kazakhstan_Eastern": "1r,5,16n,0,hq|||",
                "Kazakhstan_Western": "1g,5,16n,0,hq|||",
                "Korea": "72j,0,er|72j,0,ev||",
                "Kosrae": "224,0,hq|||",
                "Krasnoyarsk": "l0,5,er|l0,5,ev||",
                "Kyrgystan": "bdk,0,hq|||",
                "Lanka": "9c,5,hq|||",
                "Line_Islands": "ac,5,2aj,0,hq|||",
                "Lord_Howe": "1u,0,24,5,2aj,0,er|1u,0,24,5,2aj,0,ev||",
                "Macau": "16o,0,er|16o,0,ev||",
                "Macquarie": "6m,5,2aj,0,hq|||",
                "Magadan": "2o,5,er|2o,5,ev||",
                "Malaysia": "bdj,0,hq|||",
                "Maldives": "bdi,0,hq|||",
                "Marquesas": "bdh,0,hq|||",
                "Marshall_Islands": "bdg,0,hq|||",
                "Mauritius": "739,0,er|739,0,ev||",
                "Mawson": "63,0,hq|||",
                "Mongolia": "p2,0,er|p2,0,ev||",
                "Moscow": "143,5,er|143,5,ev||",
                "Myanmar": "220,0,hq|||",
                "Nauru": "bdf,0,hq|||",
                "Nepal": "21t,0,hq|||",
                "New_Caledonia": "2m1,5,73l,0,er|2m1,5,73l,0,ev||",
                "New_Zealand": "2m1,5,73r,0,er|2m1,5,73r,0,ev||",
                "Newfoundland": "73t,5,er|73t,5,ev||",
                "Niue": "21r,0,hq|||",
                "Norfolk": "bde,0,hq|||",
                "Noronha": "1s,0,2,0,23,5,er|1s,0,2,0,23,5,ev||",
                "North_Mariana": "bdd,0,bdc,0,hq|||",
                "Novosibirsk": "3b,5,er|3b,5,ev||",
                "Omsk": "26,5,er|26,5,ev||",
                "Pakistan": "75s,0,er|75s,0,ev||",
                "Palau": "21p,0,hq|||",
                "Papua_New_Guinea": "1lc,5,2m1,5,bdb,0,hq|||",
                "Paraguay": "76l,0,er|76l,0,ev||",
                "Peru": "76n,0,er|76n,0,ev||",
                "Philippines": "16t,0,er|16t,0,ev||",
                "Phoenix_Islands": "bda,0,hq|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,er|8i,0,33,0,ou,0,35,5,ev||",
                "Pitcairn": "bd9,0,hq|||",
                "Ponape": "e5,0,hq|||",
                "Qyzylorda": "4d,5,er|4d,5,ev||",
                "Reunion": "21l,0,hq|||",
                "Rothera": "5q,0,hq|||",
                "Sakhalin": "ph,5,er|ph,5,ev||",
                "Samara": "29,5,er|29,5,ev||",
                "Samoa": "77d,0,er|77d,0,ev||",
                "Seychelles": "21h,0,hq|||",
                "Singapore": "21f,0,er|||",
                "Solomon": "bd8,0,hq|||",
                "South_Georgia": "6rp,5,4pj,0,hq|||",
                "Suriname": "bd7,0,hq|||",
                "Syowa": "65,0,hq|||",
                "Tahiti": "bd6,0,hq|||",
                "Taipei": "3d,0,er|3d,0,ev||",
                "Tajikistan": "21d,0,hq|||",
                "Tokelau": "5d,5,2aj,0,hq|||",
                "Tonga": "77q,0,er|77q,0,ev||",
                "Truk": "21c,0,hq|||",
                "Turkmenistan": "171,0,er|171,0,ev||",
                "Tuvalu": "bd5,0,hq|||",
                "Uruguay": "781,0,er|781,0,ev||",
                "Uzbekistan": "175,0,er|175,0,ev||",
                "Vanuatu": "784,0,er|784,0,ev||",
                "Venezuela": "bd4,0,hq|||",
                "Vladivostok": "28,5,er|28,5,ev||",
                "Volgograd": "qi,5,er|qi,5,ev||",
                "Vostok": "5t,0,hq|||",
                "Wake": "5r,5,2aj,0,hq|||",
                "Wallis": "5f,0,ou,0,219,0,hq|||",
                "Yakutsk": "13v,5,er|13v,5,ev||",
                "Yekaterinburg": "17a,5,er|17a,5,ev||"
            }
        },
        "ga": {
            "gmtFormat": "MAG{0}",
            "gmtZeroFormat": "MAG",
            "zone": {
                "Europe/Dublin": "|66,0,cag,0,52,0,cah||cai",
                "Europe/London": "|66,0,cr,0,52,0,caj||cak"
            },
            "metazone": {
                "Acre": "66,0,dh,0,3q|66,0,cr,0,3q||",
                "Afghanistan": "66,0,52,0,bd3|||",
                "Africa_Central": "66,0,2bg,0,52,0,2mo|||",
                "Africa_Eastern": "66,0,1qf,0,52,0,2mo|||",
                "Africa_Southern": "66,0,dh,0,52,0,2mo,0,790|||",
                "Africa_Western": "66,0,dh,0,1jk,0,52,0,2mo|66,0,cr,0,1jk,0,52,0,2mo||",
                "Alaska": "66,0,dh,0,tj|66,0,cr,0,tj||",
                "Almaty": "66,0,dh,0,56|66,0,cr,0,56||",
                "Amazon": "66,0,dh,0,52,0,795|66,0,cr,0,52,0,795||",
                "America_Central": "66,0,dh,0,796|66,0,cr,0,796||",
                "America_Eastern": "66,0,dh,0,nk,0,798|66,0,cr,0,nk,0,798||",
                "America_Mountain": "66,0,dh,0,52,0,799|66,0,cr,0,52,0,799||",
                "America_Pacific": "66,0,dh,0,nk,0,2ms,0,3b8|66,0,cr,0,nk,0,2ms,0,3b8||",
                "Anadyr": "66,0,dh,0,3l|66,0,cr,0,3l||",
                "Apia": "66,0,dh,0,3e|66,0,cr,0,3e||",
                "Aqtau": "66,0,dh,0,50|66,0,cr,0,50||",
                "Aqtobe": "66,0,dh,0,5o|66,0,cr,0,5o||",
                "Arabian": "66,0,dh,0,52,0,79c|66,0,cr,0,52,0,79c||",
                "Argentina": "66,0,dh,0,52,0,3b5|66,0,cr,0,52,0,3b5||",
                "Argentina_Western": "66,0,dh,0,1jk,0,52,0,3b5|66,0,cr,0,1jk,0,52,0,3b5||",
                "Armenia": "66,0,dh,0,52,0,79f|66,0,cr,0,52,0,79f||",
                "Atlantic": "66,0,dh,0,nk,0,79g|66,0,cr,0,nk,0,79g||",
                "Australia_Central": "66,0,dh,0,2bg,0,52,0,210|66,0,cr,0,2bg,0,52,0,210||",
                "Australia_CentralWestern": "66,0,dh,0,79i,0,52,0,210|66,0,cr,0,79i,0,52,0,210||",
                "Australia_Eastern": "66,0,dh,0,1qf,0,52,0,210|66,0,cr,0,1qf,0,52,0,210||",
                "Australia_Western": "66,0,dh,0,1jk,0,52,0,210|66,0,cr,0,1jk,0,52,0,210||",
                "Azerbaijan": "66,0,dh,0,52,0,79j|66,0,cr,0,52,0,79j||",
                "Azores": "66,0,dh,0,52,0,79k|66,0,cr,0,52,0,79k||",
                "Bangladesh": "66,0,dh,0,52,0,79l|66,0,cr,0,52,0,79l||",
                "Bhutan": "66,0,52,0,bd2|||",
                "Bolivia": "66,0,52,0,bd1|||",
                "Brasilia": "66,0,dh,0,79o|66,0,cr,0,79o||",
                "Brunei": "66,0,bd0,0,bcv|||",
                "Cape_Verde": "66,0,dh,0,79r,0,3j|66,0,cr,0,79r,0,3j||",
                "Casey": "66,0,280,0,b7|||",
                "Chamorro": "66,0,dh,0,bcu|||",
                "Chatham": "66,0,dh,0,27|66,0,cr,0,27||",
                "Chile": "66,0,dh,0,52,0,3b1|66,0,cr,0,52,0,3b1||",
                "China": "66,0,dh,0,52,0,4pl|66,0,cr,0,52,0,4pl||",
                "Choibalsan": "66,0,dh,0,2q|66,0,cr,0,2q||",
                "Christmas": "66,0,1qd,0,52,0,bct|||",
                "Cocos": "66,0,1f0,0,7v|||",
                "Colombia": "66,0,dh,0,52,0,7a4|66,0,cr,0,52,0,7a4||",
                "Cook": "66,0,dh,0,1f0,0,2v|66,0,bcs,0,1f0,0,2v||",
                "Cuba": "66,0,dh,0,7a6|66,0,cr,0,7a6||",
                "Davis": "66,0,280,0,5s|||",
                "DumontDUrville": "66,0,280,0,5b,5,5j|||",
                "East_Timor": "66,0,bcr,0,bcq|||",
                "Easter": "66,0,dh,0,1qd,0,52,0,7a9|66,0,cr,0,1qd,0,52,0,7a9||",
                "Ecuador": "66,0,bcp|||",
                "Europe_Central": "66,0,dh,0,2bg,0,52,0,282|66,0,cr,0,2bg,0,52,0,282|h0|h4",
                "Europe_Eastern": "66,0,dh,0,1qf,0,52,0,282|66,0,cr,0,1qf,0,52,0,282|gu|gv",
                "Europe_Further_Eastern": "66,0,bco,0,52,0,282|||",
                "Europe_Western": "66,0,dh,0,1jk,0,52,0,282|66,0,cr,0,1jk,0,52,0,282|h1|h2",
                "Falkland": "66,0,dh,0,1f0,0,7ad|66,0,cr,0,1f0,0,7ad||",
                "Fiji": "66,0,dh,0,7ae|66,0,cr,0,7ae||",
                "French_Guiana": "66,0,bcn,0,52,0,bcm|||",
                "French_Southern": "66,0,bcl,0,bck,0,bcj,0,nk,0,bci|||",
                "Galapagos": "66,0,1f0,0,cp|||",
                "Gambier": "66,0,7al|||",
                "Georgia": "66,0,dh,0,52,0,4pm|66,0,cr,0,52,0,4pm||",
                "Gilbert_Islands": "66,0,bch|||",
                "GMT": "bcg,5,66,0,5u||bcf|",
                "Greenland_Eastern": "66,0,dh,0,1qf,0,52,0,3ad|66,0,cr,0,1qf,0,52,0,3ad||",
                "Greenland_Western": "66,0,dh,0,1jk,0,52,0,3ad|66,0,cr,0,1jk,0,52,0,3ad||",
                "Guam": "66,0,dh,0,bce|||",
                "Gulf": "66,0,dh,0,52,0,bcd|||",
                "Guyana": "66,0,52,0,bcc|||",
                "Hawaii_Aleutian": "66,0,dh,0,7au,5,7av|66,0,cr,0,7au,5,7av||",
                "Hong_Kong": "66,0,dh,0,2l,0,7b0|66,0,cr,0,2l,0,7b0||",
                "Hovd": "66,0,dh,0,2g|66,0,cr,0,2g||",
                "India": "66,0,dh,0,52,0,bcb|||",
                "Indian_Ocean": "66,0,nk,0,2ms,0,bca|||",
                "Indochina": "66,0,52,0,bc9,5,4pl|||",
                "Indonesia_Central": "66,0,2bg,0,52,0,4ps|||",
                "Indonesia_Eastern": "66,0,1qf,0,52,0,4ps|||",
                "Indonesia_Western": "66,0,1jk,0,52,0,4ps|||",
                "Iran": "66,0,dh,0,52,0,7b5|66,0,cr,0,52,0,7b5||",
                "Irkutsk": "66,0,dh,0,3h|66,0,cr,0,3h||",
                "Israel": "66,0,dh,0,3a6|66,0,cr,0,3a6||",
                "Japan": "66,0,dh,0,52,0,7b7|66,0,cr,0,52,0,7b7||",
                "Kamchatka": "66,0,dh,0,7b8,5,3k|66,0,cr,0,7b8,5,3k||",
                "Kazakhstan_Eastern": "66,0,1qf,0,52,0,7b9|||",
                "Kazakhstan_Western": "66,0,1jk,0,52,0,7b9|||",
                "Korea": "66,0,dh,0,52,0,7ba|66,0,cr,0,52,0,7ba||",
                "Kosrae": "66,0,6f|||",
                "Krasnoyarsk": "66,0,dh,0,41|66,0,cr,0,41||",
                "Kyrgystan": "66,0,52,0,bc8|||",
                "Lanka": "66,0,bc7,0,7bf|||",
                "Line_Islands": "66,0,1f0,0,52,0,bc6|||",
                "Lord_Howe": "66,0,dh,0,1u,0,24|66,0,cr,0,1u,0,24||",
                "Macau": "66,0,dh,0,7bi|66,0,cr,0,7bi||",
                "Macquarie": "66,0,1qd,0,bc5,0,bc4|||",
                "Magadan": "66,0,dh,0,7bm|66,0,cr,0,7bm||",
                "Malaysia": "66,0,52,0,bc2|||",
                "Maldives": "66,0,1f0,0,bc1|||",
                "Marquesas": "66,0,52,0,3a5,0,bbv|||",
                "Marshall_Islands": "66,0,1f0,0,7o|||",
                "Mauritius": "66,0,dh,0,1qd,0,7bt|66,0,cr,0,1qd,0,7bt||",
                "Mawson": "66,0,280,0,63|||",
                "Mexico_Northwest": "66,0,dh,0,7c0,0,7c1|66,0,cr,0,7c0,0,7c1||",
                "Mexico_Pacific": "66,0,dh,0,7c2,0,nk,0,2ms,0,3b8|66,0,cr,0,7c2,0,nk,0,2ms,0,3b8||",
                "Mongolia": "66,0,dh,0,7c3|66,0,cr,0,7c3||",
                "Moscow": "66,0,dh,0,7c4|66,0,cr,0,7c4||",
                "Myanmar": "66,0,bbu|||",
                "Nauru": "66,0,7c6|||",
                "Nepal": "66,0,bbt|||",
                "New_Caledonia": "66,0,dh,0,52,0,2o7,5,7ce|66,0,cr,0,52,0,2o7,5,7ce||",
                "New_Zealand": "66,0,dh,0,52,0,2o7,5,7cg|66,0,cr,0,52,0,2o7,5,7cg||",
                "Newfoundland": "66,0,dh,0,7ci,0,nk,0,7cm|66,0,cr,0,7ci,0,nk,0,7cm||",
                "Niue": "66,0,8g|||",
                "Norfolk": "66,0,1qd,0,6l|||",
                "Noronha": "66,0,dh,0,7cn,0,2,0,23|66,0,cr,0,7cn,0,2,0,23||",
                "North_Mariana": "66,0,52,0,3a5,0,bbr,0,bbi|||",
                "Novosibirsk": "66,0,dh,0,3b|66,0,cr,0,3b||",
                "Omsk": "66,0,dh,0,26|66,0,cr,0,26||",
                "Pakistan": "66,0,dh,0,52,0,7ct|66,0,cr,0,52,0,7ct||",
                "Palau": "66,0,1f0,0,9b|||",
                "Papua_New_Guinea": "66,0,2o7,5,bbh,0,bbd|||",
                "Paraguay": "66,0,dh,0,7d0|66,0,cr,0,7d0||",
                "Peru": "66,0,dh,0,7d1|66,0,cr,0,7d1||",
                "Philippines": "66,0,dh,0,52,0,3a5,0,7d2|66,0,cr,0,52,0,3a5,0,7d2||",
                "Phoenix_Islands": "66,0,1f0,0,nk,0,bbc|||",
                "Pierre_Miquelon": "66,0,dh,0,8i,5,33,5,8p,5,35|66,0,cr,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "66,0,1qd,0,7e|||",
                "Ponape": "66,0,bbb|||",
                "Pyongyang": "66,0,8a|||",
                "Qyzylorda": "66,0,dh,0,4d|66,0,cr,0,4d||",
                "Reunion": "66,0,i8|||",
                "Rothera": "66,0,280,0,5q|||",
                "Sakhalin": "66,0,dh,0,7d7|66,0,cr,0,7d7||",
                "Samara": "66,0,dh,0,7d8|66,0,cr,0,7d8||",
                "Samoa": "66,0,dh,0,7d9|66,0,cr,0,7d9||",
                "Seychelles": "66,0,52,0,bb9|||",
                "Singapore": "66,0,dh,0,bb8|||",
                "Solomon": "66,0,1f0,0,bb7|||",
                "South_Georgia": "66,0,52,0,4pm,0,790|||",
                "Suriname": "66,0,bb6|||",
                "Syowa": "66,0,280,0,65|||",
                "Tahiti": "66,0,bb4|||",
                "Taipei": "66,0,dh,0,7df|66,0,cr,0,7df||",
                "Tajikistan": "66,0,52,0,bb3|||",
                "Tokelau": "66,0,1f0,0,bb2|||",
                "Tonga": "66,0,dh,0,7di|66,0,cr,0,7di||",
                "Truk": "66,0,6p|||",
                "Turkmenistan": "66,0,dh,0,52,0,7dj|66,0,cr,0,52,0,7dj||",
                "Tuvalu": "66,0,bb1|||",
                "Uruguay": "66,0,dh,0,7dm|66,0,cr,0,7dm||",
                "Uzbekistan": "66,0,dh,0,52,0,7dn|66,0,cr,0,52,0,7dn||",
                "Vanuatu": "66,0,dh,0,7dp|66,0,cr,0,7dp||",
                "Venezuela": "66,0,bau|||",
                "Vladivostok": "66,0,dh,0,28|66,0,cr,0,28||",
                "Volgograd": "66,0,dh,0,3f|66,0,cr,0,3f||",
                "Vostok": "66,0,280,0,5t|||",
                "Wake": "66,0,1qd,0,5r|||",
                "Wallis": "66,0,bat,0,2o8,0,bas|||",
                "Yakutsk": "66,0,dh,0,7ed|66,0,cr,0,7ed||",
                "Yekaterinburg": "66,0,dh,0,5l|66,0,cr,0,5l||"
            }
        },
        "gd": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ei,5,eh,0,52,0,2oo,5,cal||cam,0,can",
                "Europe/London": "|ep,0,g4,0,cao||cap",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "ei,5,eh,0,3q|ep,0,g4,0,3q||",
                "Afghanistan": "he,0,bar|||",
                "Africa_Central": "he,0,1p7,0,2og|||",
                "Africa_Eastern": "he,0,2og,0,nk,0,1jq|||",
                "Africa_Southern": "he,0,2og,0,a6,0,7f2|||",
                "Africa_Western": "ei,5,eh,0,2og,0,nk,0,1c8|ep,0,cr,0,2og,0,nk,0,1c8||",
                "Alaska": "ei,5,eh,0,3p|ep,0,g4,0,3p|18f|18t",
                "Almaty": "ei,5,eh,0,56|ep,0,g4,0,56||",
                "Amazon": "ei,5,eh,0,7f6|ep,0,g4,0,7f6||",
                "America_Central": "ei,5,eh,0,1p7,0,2d7,0,a6,0,28f|ep,0,g4,0,1p7,0,2d7,0,a6,0,28f|19q|19p",
                "America_Eastern": "ei,5,eh,0,2d7,0,a6,0,28f,0,nk,0,1jq|ep,0,g4,0,2d7,0,a6,0,28f,0,nk,0,1jq|19o|19n",
                "America_Mountain": "ei,5,eh,0,7ff,0,2d7,0,a6,0,28f|ep,0,g4,0,7ff,0,2d7,0,a6,0,28f|18r|16i",
                "America_Pacific": "ei,5,eh,0,1kh,0,2da,0,3a3|ep,0,g4,0,1kh,0,2da,0,3a3|19l|19k",
                "Anadyr": "ei,5,eh,0,3l|ep,0,g4,0,3l||",
                "Apia": "ei,5,eh,0,3e|ep,0,g4,0,3e||",
                "Aqtau": "ei,5,eh,0,50|ep,0,g4,0,50||",
                "Aqtobe": "ei,5,eh,0,5o|ep,0,g4,0,5o||",
                "Arabian": "ei,5,eh,0,7fn|ep,0,g4,0,7fn||",
                "Argentina": "ei,5,eh,0,52,0,2oo,5,3a2|ep,0,g4,0,52,0,2oo,5,3a2||",
                "Argentina_Western": "ei,5,eh,0,52,0,2oo,5,3a2,0,7ft|ep,0,g4,0,52,0,2oo,5,3a2,0,7ft||",
                "Armenia": "ei,5,eh,0,7fu|ep,0,g4,0,7fu||",
                "Atlantic": "ei,5,eh,0,1kh,0,2da,0,7fv|ep,0,g4,0,1kh,0,2da,0,7fv|19i|190",
                "Australia_Central": "ei,5,eh,0,1p7,0,1vt|ep,0,g4,0,1p7,0,1vt||",
                "Australia_CentralWestern": "ei,5,eh,0,1p7,0,1vt,0,nk,0,1c8|ep,0,g4,0,1p7,0,1vt,0,nk,0,1c8||",
                "Australia_Eastern": "ei,5,eh,0,1vt,0,nk,0,1jq|ep,0,g4,0,1vt,0,nk,0,1jq||",
                "Australia_Western": "ei,5,eh,0,1vt,0,nk,0,1c8|ep,0,g4,0,1vt,0,nk,0,1c8||",
                "Azerbaijan": "ei,5,eh,0,7g2|ep,0,g4,0,7g2||",
                "Azores": "ei,5,eh,0,15r,0,14u,0,7g6|ep,0,g4,0,15r,0,14u,0,7g6||",
                "Bangladesh": "ei,5,eh,0,7g7|ep,0,g4,0,7g7||",
                "Bhutan": "he,0,baq|||",
                "Bolivia": "he,0,bap|||",
                "Brasilia": "ei,5,eh,0,7gc|ep,0,g4,0,7gc||",
                "Brunei": "he,0,bak,0,bah,0,bag,5,baf|||",
                "Cape_Verde": "ei,5,eh,0,1kh,0,7gj,0,7gk|ep,0,g4,0,1kh,0,7gj,0,7gk||",
                "Casey": "he,0,bae|||",
                "Chamorro": "he,0,6j|||",
                "Chatham": "ei,5,eh,0,27|ep,0,g4,0,27||",
                "Chile": "ei,5,eh,0,52,0,3b1|ep,0,g4,0,52,0,3b1||",
                "China": "ei,5,eh,0,52,0,7gm|ep,0,g4,0,52,0,7gm||",
                "Choibalsan": "ei,5,eh,0,2q|ep,0,g4,0,2q||",
                "Christmas": "he,0,2dg,0,52,0,bad|||",
                "Cocos": "he,0,14u,0,bac|||",
                "Colombia": "ei,5,eh,0,7h4|ep,0,g4,0,7h4||",
                "Cook": "ei,5,eh,0,14u,0,2v|bab,5,baa,0,g4,0,14u,0,2v||",
                "Cuba": "ei,5,eh,0,7h8|ep,0,g4,0,7h8||",
                "Davis": "he,0,ba2|||",
                "DumontDUrville": "he,0,5b,5,5j|||",
                "East_Timor": "he,0,ba1,0,nk,0,1jq|||",
                "Easter": "ei,5,eh,0,2dg,0,52,0,7hc|ep,0,g4,0,2dg,0,52,0,7hc||",
                "Ecuador": "he,0,ba0|||",
                "Europe_Central": "ei,5,eh,0,1p7,0,52,0,28h,5,28i|ep,0,g4,0,1p7,0,52,0,28h,5,28i|h0|h4",
                "Europe_Eastern": "ei,5,eh,0,52,0,28h,5,28i,0,nk,0,1jq|ep,0,g4,0,52,0,28h,5,28i,0,nk,0,1jq|gu|gv",
                "Europe_Further_Eastern": "he,0,52,0,28h,5,28i,0,b9v,0,b9r,0,b9q|||",
                "Europe_Western": "ei,5,eh,0,52,0,28h,5,28i,0,nk,0,1c8|ep,0,g4,0,52,0,28h,5,28i,0,nk,0,1c8|h1|h2",
                "Falkland": "ei,5,eh,0,15r,0,14u,0,7hp|ep,0,g4,0,15r,0,14u,0,7hp||",
                "Fiji": "ei,5,eh,0,7hq|ep,0,g4,0,7hq||",
                "French_Guiana": "he,0,7hs,0,52,0,7hu|||",
                "French_Southern": "he,0,b9o,0,2o8,0,b9f,0,52,0,7hu|||",
                "Galapagos": "he,0,b9e|||",
                "Gambier": "he,0,7al|||",
                "Georgia": "ei,5,eh,0,52,0,7i6|ep,0,g4,0,52,0,7i6||",
                "Gilbert_Islands": "he,0,14u,0,b9d|||",
                "GMT": "5u,0,bh,0,1||ah|",
                "Greenland_Eastern": "ei,5,eh,0,52,0,39q,0,nk,0,1jq|ep,0,g4,0,52,0,39q,0,nk,0,1jq||",
                "Greenland_Western": "ei,5,eh,0,52,0,39q,0,nk,0,1c8|ep,0,g4,0,52,0,39q,0,nk,0,1c8||",
                "Guam": "he,0,83|||",
                "Gulf": "he,0,1kh,0,b9a|||",
                "Guyana": "he,0,7hs|||",
                "Hawaii_Aleutian": "ei,5,eh,0,15r,0,14u,0,7id,0,7ie,0,7if|ep,0,cr,0,15r,0,14u,0,7id,0,7ie,0,7if|17j|17l",
                "Hong_Kong": "ei,5,eh,0,2l,0,2p|ep,0,g4,0,2l,0,2p||",
                "Hovd": "ei,5,eh,0,2g|ep,0,g4,0,2g||",
                "India": "he,0,15r,0,7ig|||",
                "Indian_Ocean": "he,0,b99,0,15r,0,7ig|||",
                "Indochina": "he,0,b93,5,b92|||",
                "Indonesia_Central": "he,0,1p7,0,15r,0,4q4,5,4q5|||",
                "Indonesia_Eastern": "he,0,15r,0,4q4,5,4q5,0,nk,0,1jq|||",
                "Indonesia_Western": "he,0,15r,0,4q4,5,4q5,0,nk,0,1c8|||",
                "Iran": "ei,5,eh,0,7j6|ep,0,g4,0,7j6||",
                "Irkutsk": "ei,5,eh,0,3h|ep,0,cr,0,3h||",
                "Israel": "ei,5,eh,0,3a6|ep,0,g4,0,3a6||",
                "Japan": "ei,5,eh,0,52,0,7j8|ep,0,g4,0,52,0,7j8||",
                "Kamchatka": "ei,5,eh,0,37,5,4q6|ep,0,g4,0,37,5,4q6||",
                "Kazakhstan_Eastern": "he,0,7jf,0,nk,0,1jq|||",
                "Kazakhstan_Western": "he,0,7jf,0,nk,0,1c8|||",
                "Korea": "ei,5,eh,0,7jo|ep,0,g4,0,7jo||",
                "Kosrae": "he,0,6f|||",
                "Krasnoyarsk": "ei,5,eh,0,41|ep,0,g4,0,41||",
                "Kyrgystan": "he,0,b8v|||",
                "Lanka": "he,0,7bf|||",
                "Line_Islands": "he,0,14u,0,b8u|||",
                "Lord_Howe": "ei,5,eh,0,1u,0,24|ep,0,g4,0,1u,0,24||",
                "Macau": "ei,5,eh,0,7ju|ep,0,g4,0,7ju||",
                "Macquarie": "he,0,7k1,0,b8t|||",
                "Magadan": "ei,5,eh,0,2o|ep,0,cr,0,2o||",
                "Malaysia": "he,0,b8s|||",
                "Maldives": "he,0,15r,0,14u,0,b8p|||",
                "Marquesas": "he,0,14u,0,1kh,0,b8n|||",
                "Marshall_Islands": "he,0,14u,0,b8k|||",
                "Mauritius": "ei,5,eh,0,15r,0,14u,0,7kp|ep,0,g4,0,15r,0,14u,0,7kp||",
                "Mawson": "he,0,b8j|||",
                "Mexico_Northwest": "ei,5,eh,0,39d,0,nk,0,1c8,5,7l2|ep,0,g4,0,39d,0,nk,0,1c8,5,7l2||",
                "Mexico_Pacific": "ei,5,eh,0,1kh,0,2da,0,3a3,0,39d|ep,0,g4,0,1kh,0,2da,0,3a3,0,39d||",
                "Mongolia": "ei,5,eh,0,4f,0,2m|ep,0,g4,0,4f,0,2m||",
                "Moscow": "ei,5,eh,0,7l3|ep,0,g4,0,7l3||",
                "Myanmar": "he,0,b8i|||",
                "Nauru": "he,0,b8h|||",
                "Nepal": "he,0,b8g|||",
                "New_Caledonia": "ei,5,eh,0,7lu,0,2pd|ep,0,g4,0,7lu,0,2pd||",
                "New_Zealand": "ei,5,eh,0,7mf,0,2pd|ep,0,g4,0,7mf,0,2pd||",
                "Newfoundland": "ei,5,eh,0,7mj,0,nk,0,7mp|ep,0,g4,0,7mj,0,nk,0,7mp||",
                "Niue": "he,0,8g|||",
                "Norfolk": "he,0,7k1,0,6l|||",
                "Noronha": "ei,5,eh,0,1s,0,2,0,23|ep,0,cr,0,1s,0,2,0,23||",
                "North_Mariana": "he,0,15r,0,14u,0,b8f,0,a6,0,28f|||",
                "Novosibirsk": "ei,5,eh,0,3b|ep,0,g4,0,3b||",
                "Omsk": "ei,5,eh,0,26|ep,0,g4,0,26||",
                "Pakistan": "ei,5,eh,0,7n4|ep,0,g4,0,7n4||",
                "Palau": "he,0,b8e|||",
                "Papua_New_Guinea": "he,0,b8d,0,2pd,0,b8c|||",
                "Paraguay": "ei,5,eh,0,7ne|ep,0,g4,0,7ne||",
                "Peru": "ei,5,eh,0,7nf|ep,0,g4,0,7nf||",
                "Philippines": "ei,5,eh,0,15r,0,2dg,0,7ng|ep,0,g4,0,15r,0,2dg,0,7ng||",
                "Phoenix_Islands": "he,0,14u,0,90|||",
                "Pierre_Miquelon": "ei,5,eh,0,8i,0,33,0,2o8,0,35|ep,0,cr,0,8i,0,33,0,2o8,0,35||",
                "Pitcairn": "he,0,b8b,0,1kh,0,b8a|||",
                "Ponape": "he,0,e5|||",
                "Qyzylorda": "ei,5,eh,0,7np|ep,0,g4,0,7np||",
                "Reunion": "he,0,b4|||",
                "Rothera": "he,0,5q|||",
                "Sakhalin": "ei,5,eh,0,55|ep,0,g4,0,55||",
                "Samara": "ei,5,eh,0,29|ep,0,g4,0,29||",
                "Samoa": "ei,5,eh,0,7ns|ep,0,g4,0,7ns||",
                "Seychelles": "he,0,15r,0,14u,0,b89|||",
                "Singapore": "he,0,b88|||",
                "Solomon": "he,0,14u,0,b87|||",
                "South_Georgia": "he,0,b86,0,a6,0,7f2|||",
                "Suriname": "he,0,b85|||",
                "Syowa": "he,0,b84|||",
                "Tahiti": "he,0,8n|||",
                "Taipei": "ei,5,eh,0,3d|ep,0,g4,0,3d||",
                "Tajikistan": "he,0,b83|||",
                "Tokelau": "he,0,5d|||",
                "Tonga": "ei,5,eh,0,2j|ep,0,g4,0,2j||",
                "Truk": "he,0,6p|||",
                "Turkmenistan": "ei,5,eh,0,7o8|ep,0,g4,0,7o8||",
                "Tuvalu": "he,0,b82|||",
                "Uruguay": "ei,5,eh,0,7oc|ep,0,g4,0,7oc||",
                "Uzbekistan": "ei,5,eh,0,7od|ep,0,g4,0,7od||",
                "Vanuatu": "ei,5,eh,0,2f|ep,0,g4,0,2f||",
                "Venezuela": "he,0,52,0,b81|||",
                "Vladivostok": "ei,5,eh,0,28|ep,0,cr,0,28||",
                "Volgograd": "ei,5,eh,0,3f|ep,0,g4,0,3f||",
                "Vostok": "he,0,5t|||",
                "Wake": "he,0,2dg,0,5r|||",
                "Wallis": "he,0,b80,0,2o8,0,5k|||",
                "Yakutsk": "ei,5,eh,0,3u|ep,0,g4,0,3u||",
                "Yekaterinburg": "ei,5,eh,0,5l|ep,0,g4,0,5l||"
            }
        },
        "gl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|75,0,d,0,caq||",
                "Europe/London": "|75,0,2,0,fl,0,car||"
            },
            "metazone": {
                "Afghanistan": "75,0,2,0,t9|||",
                "Africa_Central": "75,0,2,0,9d,0,n|||",
                "Africa_Eastern": "75,0,2,0,9d,0,8h|||",
                "Africa_Southern": "75,0,d,0,2,0,9d,0,16,0,4mu|||",
                "Africa_Western": "75,0,d,0,2,0,9d,0,of|75,0,2,0,fl,0,2,0,9d,0,of||",
                "Alaska": "75,0,d,0,2,0,tj|75,0,2,0,fl,0,2,0,tj||",
                "Amazon": "75,0,d,0,16,0,bg|75,0,2,0,fl,0,16,0,bg||",
                "America_Central": "75,0,d,0,6r,k,0,1vh|75,0,2,0,fl,0,6r,k,0,1vh||",
                "America_Eastern": "75,0,d,0,16,0,4qj,k,0,1vh|75,0,2,0,fl,0,16,0,4qj,k,0,1vh||",
                "America_Mountain": "75,0,d,0,14,0,7or,k,0,1vh|75,0,2,0,fl,0,14,0,7or,k,0,1vh||",
                "America_Pacific": "75,0,d,0,16,0,9u,k,0,1vh|75,0,2,0,fl,0,16,0,9u,k,0,1vh||",
                "Anadyr": "75,0,d,0,2,0,1eh|75,0,2,0,fl,0,2,0,1eh||",
                "Apia": "75,0,d,0,2,0,3e|75,0,2,0,fl,0,2,0,3e||",
                "Arabian": "75,0,d,0,7os|75,0,2,0,fl,0,7os||",
                "Argentina": "75,0,d,0,2,0,398|75,0,2,0,fl,0,2,0,398||",
                "Argentina_Western": "75,0,d,0,2,0,398,0,of|75,0,2,0,fl,0,2,0,398,0,of||",
                "Armenia": "75,0,d,0,2,0,4l|75,0,2,0,fl,0,2,0,4l||",
                "Atlantic": "75,0,d,0,16,0,jk|75,0,2,0,fl,0,16,0,jk||",
                "Australia_Central": "75,0,d,0,2,0,4c,0,n|75,0,2,0,fl,0,2,0,4c,0,n||",
                "Australia_CentralWestern": "75,0,d,0,2,0,4c,0,of,0,n|75,0,2,0,fl,0,2,0,4c,0,of,0,n||",
                "Australia_Eastern": "75,0,d,0,2,0,4c,0,8h|75,0,2,0,fl,0,2,0,4c,0,8h||",
                "Australia_Western": "75,0,d,0,2,0,4c,0,of|75,0,2,0,fl,0,2,0,4c,0,of||",
                "Azerbaijan": "75,0,d,0,2,0,7p2|75,0,2,0,fl,0,2,0,7p2||",
                "Azores": "75,0,d,0,6t,0,4r|75,0,2,0,fl,0,6t,0,4r||",
                "Bangladesh": "75,0,d,0,2,0,je|75,0,2,0,fl,0,2,0,je||",
                "Bhutan": "75,0,2,0,s5|||",
                "Bolivia": "75,0,2,0,aa|||",
                "Brasilia": "75,0,d,0,2,0,3a|75,0,2,0,fl,0,2,0,3a||",
                "Brunei": "75,0,2,0,9o,0,a4|||",
                "Cape_Verde": "75,0,d,0,2,0,ck,0,3j|75,0,2,0,fl,0,2,0,ck,0,3j||",
                "Chamorro": "75,0,d,0,b7v|||",
                "Chatham": "75,0,d,0,2,0,27|75,0,2,0,fl,0,2,0,27||",
                "Chile": "75,0,d,0,2,0,49|75,0,2,0,fl,0,2,0,49||",
                "China": "75,0,d,0,2,0,4h|75,0,2,0,fl,0,2,0,4h||",
                "Choibalsan": "75,0,d,0,2,0,2q|75,0,2,0,fl,0,2,0,2q||",
                "Christmas": "75,0,14,0,2pp,0,2,0,b7u|||",
                "Cocos": "75,0,6t,0,1kd,0,7v|||",
                "Colombia": "75,0,d,0,2,0,4m|75,0,2,0,fl,0,2,0,4m||",
                "Cook": "75,0,d,0,6t,0,1kd,0,2v|75,0,2,0,fl,0,b7t,0,6t,0,1kd,0,2v||",
                "Cuba": "75,0,d,0,2,0,34|75,0,2,0,fl,0,2,0,34||",
                "Davis": "75,0,2,0,5s|||",
                "DumontDUrville": "75,0,2,0,5b,5,5j|||",
                "East_Timor": "75,0,2,0,7n,0,vk|||",
                "Easter": "75,0,d,0,14,0,2pp,0,2,0,iq|75,0,2,0,fl,0,14,0,2pp,0,2,0,iq||",
                "Ecuador": "75,0,2,0,ai|||",
                "Europe_Central": "75,0,d,0,2,0,4p,0,n|75,0,2,0,fl,0,2,0,4p,0,n|h0|h4",
                "Europe_Eastern": "75,0,d,0,2,0,4p,0,8h|75,0,2,0,fl,0,2,0,4p,0,8h|gu|gv",
                "Europe_Further_Eastern": "75,0,16,0,b7s,0,4qj,0,b7n|||",
                "Europe_Western": "75,0,d,0,2,0,4p,0,of|75,0,2,0,fl,0,2,0,4p,0,of|h1|h2",
                "Falkland": "75,0,d,0,6t,0,1kd,0,ju|75,0,2,0,fl,0,6t,0,1kd,0,ju||",
                "Fiji": "75,0,d,0,2,0,7pv|75,0,2,0,fl,0,2,0,7pv||",
                "French_Guiana": "75,0,14,0,7q4,0,nf|||",
                "French_Southern": "75,0,6t,0,1h4,0,1h2,0,15,0,sd,0,pf|||",
                "Galapagos": "75,0,6t,0,cp|||",
                "Gambier": "75,0,2,0,6v|||",
                "Georgia": "75,0,d,0,2,0,4qk|75,0,2,0,fl,0,2,0,4qk||",
                "Gilbert_Islands": "75,0,6t,0,1kd,0,6q|||",
                "GMT": "75,0,16,0,rc,0,2,0,5u||ah|",
                "Greenland_Eastern": "75,0,d,0,2,0,b2,0,8h|75,0,2,0,fl,0,2,0,b2,0,8h||",
                "Greenland_Western": "75,0,d,0,2,0,b2,0,of|75,0,2,0,fl,0,2,0,b2,0,of||",
                "Gulf": "75,0,d,0,16,0,oh|||",
                "Guyana": "75,0,14,0,7q4|||",
                "Hawaii_Aleutian": "75,0,d,0,2,0,7qe,5,1gc|75,0,2,0,fl,0,2,0,7qe,5,1gc||",
                "Hong_Kong": "75,0,d,0,2,0,2l,0,2p|75,0,2,0,fl,0,2,0,2l,0,2p||",
                "Hovd": "75,0,d,0,2,0,2g|75,0,2,0,fl,0,2,0,2g||",
                "India": "75,0,d,0,14,0,af|||",
                "Indian_Ocean": "75,0,16,0,5pv,0,pa|||",
                "Indochina": "75,0,2,0,9p|||",
                "Indonesia_Central": "75,0,2,0,2t,0,n|||",
                "Indonesia_Eastern": "75,0,2,0,2t,0,8h|||",
                "Indonesia_Western": "75,0,2,0,2t,0,of|||",
                "Iran": "75,0,d,0,2,0,k1|75,0,2,0,fl,0,2,0,k1||",
                "Irkutsk": "75,0,d,0,2,0,3h|75,0,2,0,fl,0,2,0,3h||",
                "Israel": "75,0,d,0,2,0,4e|75,0,2,0,fl,0,2,0,4e||",
                "Japan": "75,0,d,0,16,0,3iu|75,0,2,0,fl,0,16,0,3iu||",
                "Kamchatka": "75,0,d,0,2,0,7qf,5,3k|75,0,2,0,fl,0,2,0,7qf,5,3k||",
                "Kazakhstan_Eastern": "75,0,2,0,7qg,0,8h|||",
                "Kazakhstan_Western": "75,0,2,0,7qg,0,of|||",
                "Korea": "75,0,d,0,2,0,hf|75,0,2,0,fl,0,2,0,hf||",
                "Kosrae": "75,0,2,0,6f|||",
                "Krasnoyarsk": "75,0,d,0,2,0,41|75,0,2,0,fl,0,2,0,41||",
                "Kyrgystan": "75,0,2,0,b7m|||",
                "Line_Islands": "75,0,6t,0,1kd,0,14,0,b7l|||",
                "Lord_Howe": "75,0,d,0,2,0,1u,0,24|75,0,2,0,fl,0,2,0,1u,0,24||",
                "Macquarie": "75,0,14,0,2pp,0,6m|||",
                "Magadan": "75,0,d,0,2,0,2o|75,0,2,0,fl,0,2,0,2o||",
                "Malaysia": "75,0,2,0,3lm|||",
                "Maldives": "75,0,6t,0,ov|||",
                "Marquesas": "75,0,6t,0,9e|||",
                "Marshall_Islands": "75,0,6t,0,1kd,0,7o|||",
                "Mauritius": "75,0,d,0,2,0,kt|75,0,2,0,fl,0,2,0,kt||",
                "Mawson": "75,0,2,0,63|||",
                "Mexico_Northwest": "75,0,d,0,2,0,au,0,107|75,0,2,0,fl,0,2,0,au,0,107||",
                "Mexico_Pacific": "75,0,d,0,16,0,9u,0,7qk|75,0,2,0,fl,0,16,0,9u,0,7qk||",
                "Mongolia": "75,0,d,0,2,0,ke,0,3j2|75,0,2,0,fl,0,2,0,ke,0,3j2||",
                "Moscow": "75,0,d,0,2,0,7qn|75,0,2,0,fl,0,2,0,7qn||",
                "Myanmar": "75,0,2,0,ra|||",
                "Nauru": "75,0,2,0,5v|||",
                "Nepal": "75,0,2,0,8u|||",
                "New_Caledonia": "75,0,d,0,2,0,ba,0,4o|75,0,2,0,fl,0,2,0,ba,0,4o||",
                "New_Zealand": "75,0,d,0,2,0,ba,0,395|75,0,2,0,fl,0,2,0,ba,0,395||",
                "Newfoundland": "75,0,d,0,2,0,h5|75,0,2,0,fl,0,2,0,h5||",
                "Niue": "75,0,2,0,8g|||",
                "Norfolk": "75,0,6t,0,1kd,0,6l|||",
                "Noronha": "75,0,d,0,2,0,1s,0,2,0,23|75,0,2,0,fl,0,2,0,1s,0,2,0,23||",
                "Novosibirsk": "75,0,d,0,2,0,3b|75,0,2,0,fl,0,2,0,3b||",
                "Omsk": "75,0,d,0,2,0,26|75,0,2,0,fl,0,2,0,26||",
                "Pakistan": "75,0,d,0,2,0,3j4|75,0,2,0,fl,0,2,0,3j4||",
                "Palau": "75,0,2,0,9b|||",
                "Papua_New_Guinea": "75,0,2,0,re,5,ba,0,a8|||",
                "Paraguay": "75,0,d,0,2,0,p8|75,0,2,0,fl,0,2,0,p8||",
                "Peru": "75,0,d,0,2,0,ha|75,0,2,0,fl,0,2,0,ha||",
                "Philippines": "75,0,d,0,2,0,g0|75,0,2,0,fl,0,2,0,g0||",
                "Phoenix_Islands": "75,0,6t,0,1kd,0,pd|||",
                "Pierre_Miquelon": "75,0,d,0,2,0,8i,0,33,0,15,0,35|75,0,2,0,fl,0,2,0,8i,0,33,0,15,0,35||",
                "Pitcairn": "75,0,2,0,7e|||",
                "Ponape": "75,0,2,0,e5|||",
                "Pyongyang": "75,0,2,0,8a|||",
                "Reunion": "75,0,2,0,s4|||",
                "Rothera": "75,0,2,0,5q|||",
                "Sakhalin": "75,0,d,0,2,0,b7k|75,0,2,0,fl,0,2,0,55||",
                "Samara": "75,0,d,0,2,0,29|75,0,2,0,fl,0,2,0,29||",
                "Samoa": "75,0,d,0,2,0,2c|75,0,2,0,fl,0,2,0,2c||",
                "Seychelles": "75,0,6t,0,5an|||",
                "Singapore": "75,0,d,0,2,0,m8|||",
                "Solomon": "75,0,6t,0,1kd,0,s1|||",
                "South_Georgia": "75,0,2,0,4qk,0,16,0,4mu|||",
                "Suriname": "75,0,2,0,8q|||",
                "Syowa": "75,0,2,0,65|||",
                "Tahiti": "75,0,2,0,qr|||",
                "Taipei": "75,0,d,0,2,0,3d|75,0,2,0,fl,0,2,0,3d||",
                "Tajikistan": "75,0,2,0,5et|||",
                "Tokelau": "75,0,2,0,b7j|||",
                "Tonga": "75,0,d,0,2,0,2j|75,0,2,0,fl,0,2,0,2j||",
                "Truk": "75,0,2,0,6p|||",
                "Turkmenistan": "75,0,d,0,2,0,7r1|75,0,2,0,fl,0,2,0,7r1||",
                "Tuvalu": "75,0,2,0,6h|||",
                "Uruguay": "75,0,d,0,2,0,rn|75,0,2,0,fl,0,2,0,rn||",
                "Uzbekistan": "75,0,d,0,2,0,7r4|75,0,2,0,fl,0,2,0,7r4||",
                "Vanuatu": "75,0,d,0,2,0,2f|75,0,2,0,fl,0,2,0,2f||",
                "Venezuela": "75,0,2,0,7s|||",
                "Vladivostok": "75,0,d,0,2,0,28|75,0,2,0,fl,0,2,0,28||",
                "Volgograd": "75,0,d,0,2,0,ff|75,0,2,0,fl,0,2,0,ff||",
                "Vostok": "75,0,2,0,5t|||",
                "Wake": "75,0,14,0,2pp,0,5r|||",
                "Wallis": "75,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "75,0,d,0,2,0,1h6|75,0,2,0,fl,0,2,0,1h6||",
                "Yekaterinburg": "75,0,d,0,2,0,kh|75,0,2,0,fl,0,2,0,kh||"
            }
        },
        "gsw-FR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Acre": "3q,5,s0|3q,5,rf||",
                "Afghanistan": "4qn,5,1pe|||",
                "Africa_Central": "4qo,0,1pe|||",
                "Africa_Eastern": "4qp,0,1pe|||",
                "Africa_Southern": "4qq,0,4qr|||",
                "Africa_Western": "2ev,0,s0|2ev,0,rf||",
                "Alaska": "3p,5,s0|3p,5,rf||",
                "Almaty": "56,5,s0|56,5,rf||",
                "Amazon": "bg,5,s0|bg,5,rf||",
                "America_Central": "m2,5,291,0,s0|m2,5,291,0,rf||",
                "Europe_Central": "2f1,0,s0|2f1,0,rf|1bd|1be",
                "Europe_Eastern": "2f2,0,s0|2f2,0,rf|1bg|1bh",
                "Europe_Western": "2f3,0,s0|2f3,0,rf|1bk|1bl",
                "Moscow": "131,0,s0|131,0,rf||"
            }
        },
        "gsw-LI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Acre": "3q,5,s0|3q,5,rf||",
                "Afghanistan": "4qn,5,1pe|||",
                "Africa_Central": "4qo,0,1pe|||",
                "Africa_Eastern": "4qp,0,1pe|||",
                "Africa_Southern": "4qq,0,4qr|||",
                "Africa_Western": "2ev,0,s0|2ev,0,rf||",
                "Alaska": "3p,5,s0|3p,5,rf||",
                "Almaty": "56,5,s0|56,5,rf||",
                "Amazon": "bg,5,s0|bg,5,rf||",
                "America_Central": "m2,5,291,0,s0|m2,5,291,0,rf||",
                "Europe_Central": "2f1,0,s0|2f1,0,rf|1bd|1be",
                "Europe_Eastern": "2f2,0,s0|2f2,0,rf|1bg|1bh",
                "Europe_Western": "2f3,0,s0|2f3,0,rf|1bk|1bl",
                "Moscow": "131,0,s0|131,0,rf||"
            }
        },
        "gsw": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Acre": "3q,5,s0|3q,5,rf||",
                "Afghanistan": "4qn,5,1pe|||",
                "Africa_Central": "4qo,0,1pe|||",
                "Africa_Eastern": "4qp,0,1pe|||",
                "Africa_Southern": "4qq,0,4qr|||",
                "Africa_Western": "2ev,0,s0|2ev,0,rf||",
                "Alaska": "3p,5,s0|3p,5,rf||",
                "Almaty": "56,5,s0|56,5,rf||",
                "Amazon": "bg,5,s0|bg,5,rf||",
                "America_Central": "m2,5,291,0,s0|m2,5,291,0,rf||",
                "Europe_Central": "2f1,0,s0|2f1,0,rf|1bd|1be",
                "Europe_Eastern": "2f2,0,s0|2f2,0,rf|1bg|1bh",
                "Europe_Western": "2f3,0,s0|2f3,0,rf|1bk|1bl",
                "Moscow": "131,0,s0|131,0,rf||"
            }
        },
        "gu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cas,0,hg,0,6e||",
                "Europe/London": "|cat,0,ih,0,6e||"
            },
            "metazone": {
                "Acre": "7s5,0,11f,0,6e|7s5,0,ih,0,6e||",
                "Afghanistan": "b7i,0,6e|||",
                "Africa_Central": "1q1,0,2qq,0,6e|||",
                "Africa_Eastern": "2qr,0,2qq,0,6e|||",
                "Africa_Southern": "7ss,0,2qq,0,hg,0,6e|||",
                "Africa_Western": "2qt,0,2qq,0,hg,0,6e|2qt,0,2qq,0,ih,0,6e||",
                "Alaska": "7t0,0,11f,0,6e|7t0,0,vi,0,6e||",
                "Almaty": "7ta,0,11f,0,6e|7ta,0,ih,0,6e||",
                "Amazon": "7tj,0,hg,0,6e|7tj,0,ih,0,6e||",
                "America_Central": "1q0,0,1ue,0,b7h,0,11f,0,6e|1q0,0,1ue,0,1q1,0,vi,0,6e||",
                "America_Eastern": "1q0,0,1ue,0,38u,0,11f,0,6e|1q0,0,1ue,0,38u,0,vi,0,6e||",
                "America_Mountain": "1q0,0,1ue,0,7u2,0,11f,0,6e|1q0,0,1ue,0,7u2,0,vi,0,6e||",
                "America_Pacific": "1q0,0,1ue,0,38t,0,11f,0,6e|1q0,0,1ue,0,38t,0,vi,0,6e||",
                "Anadyr": "7ua,0,11f,0,6e|7ua,0,ih,0,6e||",
                "Apia": "b7g,0,11f,0,6e|b7b,0,vi,0,6e||",
                "Aqtau": "7ud,0,11f,0,6e|7ud,0,ih,0,6e||",
                "Aqtobe": "7ug,0,11f,0,6e|7ug,0,ih,0,6e||",
                "Arabian": "7uh,0,hg,0,6e|7uh,0,vi,0,6e||",
                "Argentina": "4qs,0,hg,0,6e|b7a,0,ih,0,6e||",
                "Argentina_Western": "1pv,0,4qs,0,hg,0,6e|1pv,0,4qs,0,ih,0,6e||",
                "Armenia": "7v4,0,hg,0,6e|7v4,0,ih,0,6e||",
                "Atlantic": "7v5,0,11f,0,6e|7v5,0,vi,0,6e||",
                "Australia_Central": "1u9,0,1q1,0,11f,0,6e|1u9,0,1q1,0,vi,0,6e||",
                "Australia_CentralWestern": "1u9,0,1q1,0,1pv,0,11f,0,6e|1u9,0,1q1,0,1pv,0,vi,0,6e||",
                "Australia_Eastern": "1u9,0,38r,0,11f,0,6e|1u9,0,38r,0,vi,0,6e||",
                "Australia_Western": "1u9,0,1pv,0,11f,0,6e|1u9,0,1pv,0,vi,0,6e||",
                "Azerbaijan": "7vn,0,hg,0,6e|7vn,0,ih,0,6e||",
                "Azores": "7vr,0,hg,0,6e|7vr,0,ih,0,6e||",
                "Bangladesh": "7vs,0,hg,0,6e|7vs,0,ih,0,6e||",
                "Bhutan": "b78,0,6e|||",
                "Bolivia": "b77,0,6e|||",
                "Brasilia": "802,0,hg,0,6e|802,0,ih,0,6e||",
                "Brunei": "b76,0,b74,0,6e|||",
                "Cape_Verde": "805,0,806,0,hg,0,6e|805,0,806,0,ih,0,6e||",
                "Chamorro": "b70,0,hg,0,6e|||",
                "Chatham": "808,0,hg,0,6e|808,0,vi,0,6e||",
                "Chile": "809,0,hg,0,6e|809,0,ih,0,6e||",
                "China": "80c,0,hg,0,6e|80c,0,vi,0,6e||",
                "Choibalsan": "80f,0,hg,0,6e|80f,0,ih,0,6e||",
                "Christmas": "b6m,0,1u6,0,6e|||",
                "Cocos": "b6j,0,1u6,0,6e|||",
                "Colombia": "80s,0,hg,0,6e|80s,0,ih,0,6e||",
                "Cook": "80t,0,1ps,0,hg,0,6e|80t,0,1ps,0,b6g,0,ih,0,6e||",
                "Cuba": "817,0,hg,0,6e|817,0,vi,0,6e||",
                "Davis": "b6f,0,6e|||",
                "DumontDUrville": "b6e,5,b6c,0,6e|||",
                "East_Timor": "2qr,0,b6b,0,6e|||",
                "Easter": "81p,0,1u6,0,hg,0,6e|81p,0,1u6,0,ih,0,6e||",
                "Ecuador": "b6a,0,6e|||",
                "Europe_Central": "1q1,0,2r9,0,hg,0,6e|1q1,0,2r9,0,ih,0,6e||",
                "Europe_Eastern": "38u,0,2r9,0,hg,0,6e|38u,0,81v,0,ih,0,6e||",
                "Europe_Further_Eastern": "b66,5,38r,0,81v,0,6e|||",
                "Europe_Western": "1pv,0,2r9,0,hg,0,6e|1pv,0,2r9,0,ih,0,6e||",
                "Falkland": "b65,0,1ps,0,hg,0,6e|b64,0,1ps,0,ih,0,6e||",
                "Fiji": "82a,0,hg,0,6e|82a,0,ih,0,6e||",
                "French_Guiana": "82b,0,82i,0,6e|||",
                "French_Southern": "82b,0,b62,0,82r,0,b61,0,6e|||",
                "Galapagos": "b5v,0,6e|||",
                "Gambier": "b5u,0,6e|||",
                "Georgia": "834,0,hg,0,6e|834,0,ih,0,6e||",
                "Gilbert_Islands": "b5t,0,1u6,0,6e|||",
                "GMT": "b5s,0,b5o,0,6e|||",
                "Greenland_Eastern": "2qr,0,38q,0,hg,0,6e|2qr,0,38q,0,ih,0,6e||",
                "Greenland_Western": "2qt,0,38q,0,hg,0,6e|2qt,0,38q,0,ih,0,6e||",
                "Guam": "b5n,0,11f,0,6e|||",
                "Gulf": "b5m,0,hg,0,6e|||",
                "Guyana": "82i,0,6e|||",
                "Hawaii_Aleutian": "83l,5,83n,0,hg,0,6e|83l,5,83n,0,vi,0,6e||",
                "Hong_Kong": "83o,0,83p,0,hg,0,6e|83o,0,83p,0,ih,0,6e||",
                "Hovd": "83q,0,hg,0,6e|83q,0,ih,0,6e||",
                "India": "83r,0,hg,0,6e||15a|",
                "Indian_Ocean": "83r,0,b5l,0,6e|||",
                "Indochina": "b5j,0,6e|||",
                "Indonesia_Central": "1q1,0,4qu,0,6e|||",
                "Indonesia_Eastern": "38r,0,4qu,0,6e|||",
                "Indonesia_Western": "1pv,0,4qu,0,6e|||",
                "Iran": "841,0,hg,0,6e|841,0,vi,0,6e||",
                "Irkutsk": "842,0,hg,0,6e|842,0,ih,0,6e||",
                "Israel": "843,0,hg,0,6e|843,0,vi,0,6e||",
                "Japan": "844,0,hg,0,6e|844,0,vi,0,6e||",
                "Kamchatka": "845,5,847,0,11f,0,6e|845,5,847,0,ih,0,6e||",
                "Kazakhstan_Eastern": "2qr,0,848,0,6e|||",
                "Kazakhstan_Western": "2qt,0,848,0,6e|||",
                "Korea": "84a,0,hg,0,6e|84a,0,vi,0,6e||",
                "Kosrae": "b5e,0,6e|||",
                "Krasnoyarsk": "b5d,0,hg,0,6e|b59,0,ih,0,6e||",
                "Kyrgystan": "b57,0,6e|||",
                "Lanka": "b56,0,6e|||",
                "Line_Islands": "b55,0,1ps,0,6e|||",
                "Lord_Howe": "84r,0,84s,0,11f,0,6e|84r,0,84s,0,vi,0,6e||",
                "Macau": "84t,0,11f,0,6e|84t,0,ih,0,6e||",
                "Macquarie": "b54,0,1u6,0,6e|||",
                "Magadan": "84v,0,hg,0,6e|84v,0,ih,0,6e||",
                "Malaysia": "b53,0,6e|||",
                "Maldives": "b52,0,6e|||",
                "Marquesas": "b51,0,6e|||",
                "Marshall_Islands": "b50,0,1ps,0,6e|||",
                "Mauritius": "85b,0,hg,0,6e|85b,0,ih,0,6e||",
                "Mawson": "b4v,0,6e|||",
                "Mexico_Northwest": "85g,0,85o,0,hg,0,6e|85g,0,85o,0,vi,0,6e||",
                "Mexico_Pacific": "85p,0,38t,0,hg,0,6e|85p,0,38t,0,vi,0,6e||",
                "Mongolia": "85q,0,b4t,0,hg,0,6e|85q,0,b4r,0,ih,0,6e||",
                "Moscow": "85t,0,11f,0,6e|85t,0,ih,0,6e||",
                "Myanmar": "b4q,0,6e|||",
                "Nauru": "b4p,0,6e|||",
                "Nepal": "b4o,0,6e|||",
                "New_Caledonia": "4r0,0,863,0,hg,0,6e|4r0,0,863,0,ih,0,6e||",
                "New_Zealand": "866,0,hg,0,6e|866,0,vi,0,6e||",
                "Newfoundland": "867,0,11f,0,6e|867,0,vi,0,6e||",
                "Niue": "b4l,0,6e|||",
                "Norfolk": "b4i,0,1u6,0,6e|||",
                "Noronha": "b4f,0,b4e,0,b4d,0,hg,0,6e|b4c,0,b4b,0,b4a,0,ih,0,6e||",
                "North_Mariana": "1q0,0,b49,0,1ps|||",
                "Novosibirsk": "86v,0,hg,0,6e|86v,0,ih,0,6e||",
                "Omsk": "b41,0,hg,0,6e|b3v,0,ih,0,6e||",
                "Pakistan": "873,0,hg,0,6e|873,0,ih,0,6e||",
                "Palau": "b3u,0,6e|||",
                "Papua_New_Guinea": "b3t,0,4r0,0,b3r,0,6e|||",
                "Paraguay": "87g,0,hg,0,6e|87g,0,ih,0,6e||",
                "Peru": "87h,0,hg,0,6e|87h,0,ih,0,6e||",
                "Philippines": "87j,0,hg,0,6e|87j,0,ih,0,6e||",
                "Phoenix_Islands": "b3p,0,1ps,0,6e|||",
                "Pierre_Miquelon": "87o,0,87p,0,87t,0,87v,0,hg,0,6e|87o,0,87p,0,87t,0,87v,0,vi,0,6e||",
                "Pitcairn": "b3o,0,6e|||",
                "Ponape": "b3n,0,6e|||",
                "Pyongyang": "b3m,0,6e|||",
                "Qyzylorda": "885,0,11f,0,6e|885,0,ih,0,6e||",
                "Reunion": "b3j,0,6e|||",
                "Rothera": "b3i,0,6e|||",
                "Sakhalin": "888,0,hg,0,6e|888,0,ih,0,6e||",
                "Samara": "889,0,11f,0,6e|889,0,ih,0,6e||",
                "Samoa": "88a,0,hg,0,6e|88a,0,ih,0,6e||",
                "Seychelles": "b3f,0,6e|||",
                "Singapore": "b3c,0,hg,0,6e|||",
                "Solomon": "b3b,0,1ps,0,6e|||",
                "South_Georgia": "7ss,0,b3a,0,6e|||",
                "Suriname": "b38,0,6e|||",
                "Syowa": "b37,0,6e|||",
                "Tahiti": "b36,0,6e|||",
                "Taipei": "88i,0,hg,0,6e|88i,0,vi,0,6e||",
                "Tajikistan": "b35,0,6e|||",
                "Tokelau": "b34,0,6e|||",
                "Tonga": "88o,0,hg,0,6e|88o,0,ih,0,6e||",
                "Truk": "b33,0,6e|||",
                "Turkmenistan": "88q,0,hg,0,6e|88q,0,ih,0,6e||",
                "Tuvalu": "b32,0,6e|||",
                "Uruguay": "88s,0,hg,0,6e|88s,0,ih,0,6e||",
                "Uzbekistan": "88u,0,hg,0,6e|88u,0,ih,0,6e||",
                "Vanuatu": "88v,0,hg,0,6e|88v,0,ih,0,6e||",
                "Venezuela": "b31,0,6e|||",
                "Vladivostok": "891,0,hg,0,6e|891,0,ih,0,6e||",
                "Volgograd": "892,0,hg,0,6e|892,0,ih,0,6e||",
                "Vostok": "b30,0,6e|||",
                "Wake": "b2v,0,1u6,0,6e|||",
                "Wallis": "b2u,0,82r,0,b2t,0,6e|||",
                "Yakutsk": "89a,0,hg,0,6e|89a,0,ih,0,6e||",
                "Yekaterinburg": "89b,0,hg,0,6e|89b,0,ih,0,6e||"
            }
        },
        "guz": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "gv": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ha-GH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ha-NE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ha": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "haw": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Alaska": "||18f|18t",
                "Hawaii_Aleutian": "||17j|17l"
            }
        },
        "he": {
            "gmtFormat": "GMT{0}‎",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|72,0,2gc,0,cau||",
                "Europe/London": "|72,0,2gc,0,cav||"
            },
            "metazone": {
                "Afghanistan": "72,0,b2s|||",
                "Africa_Central": "72,0,1ns,0,2rt|||",
                "Africa_Eastern": "72,0,1n2,0,2rt|||",
                "Africa_Southern": "72,0,89i,0,2rt|||",
                "Africa_Western": "72,0,14e,0,2rt,0,gh|72,0,14e,0,2rt,0,gl||",
                "Alaska": "72,0,89t,0,gh|72,0,89t,0,gl||",
                "Amazon": "72,0,89v,0,gh|72,0,89v,0,gl||",
                "America_Central": "72,0,1ns,0,38e,0,gh|72,0,1ns,0,38e,0,gl||",
                "America_Eastern": "72,0,8a3,0,8a4,0,gh|72,0,8a3,0,8a4,0,gl||",
                "America_Mountain": "72,0,8a7,0,8a8,0,8a9,0,gh|72,0,8a7,0,8a8,0,8a9,0,gl||",
                "America_Pacific": "72,0,14e,0,38e,0,gh|72,0,14e,0,38e,0,gl||",
                "Anadyr": "72,0,4r3,0,8ac|72,0,2gc,0,8ac||",
                "Apia": "72,0,8af,0,gh|72,0,8af,0,gl||",
                "Arabian": "72,0,8ag,0,2s5,0,8ai,0,gh|72,0,8ag,0,2s5,0,8ai,0,gl||",
                "Argentina": "72,0,38c,0,gh|72,0,38c,0,gl||",
                "Argentina_Western": "72,0,14e,0,38c,0,gh|72,0,14e,0,38c,0,gl||",
                "Armenia": "72,0,8ak,0,gh|72,0,8ak,0,gl||",
                "Atlantic": "72,0,4r4,0,8ao,0,gh|72,0,4r4,0,8ao,0,gl||",
                "Australia_Central": "72,0,1ns,0,1tj,0,gh|72,0,1ns,0,1tj,0,gl||",
                "Australia_CentralWestern": "72,0,1ns,5,14e,0,1tj,0,gh|72,0,1ns,5,14e,0,1tj,0,gl||",
                "Australia_Eastern": "72,0,1n2,0,1tj,0,gh|72,0,1n2,0,1tj,0,gl||",
                "Australia_Western": "72,0,14e,0,1tj,0,gh|72,0,14e,0,1tj,0,gl||",
                "Azerbaijan": "72,0,8as,0,gh|72,0,8as,0,gl||",
                "Azores": "72,0,2s9,0,8au,0,gh|72,0,2s9,0,8au,0,gl||",
                "Bangladesh": "72,0,8av,0,gh|72,0,8av,0,gl||",
                "Bhutan": "72,0,b2r|||",
                "Bolivia": "72,0,b2q|||",
                "Brasilia": "72,0,8b2,0,gh|72,0,8b2,0,gl||",
                "Brunei": "72,0,b2p,0,b2o|||",
                "Cape_Verde": "72,0,8b6,0,8b7,0,gh|72,0,8b6,0,8b7,0,gl||",
                "Chamorro": "72,0,b2n|||",
                "Chatham": "72,0,8b9,0,gh|72,0,8b9,0,gl||",
                "Chile": "72,0,8ba,0,gh|72,0,8ba,0,gl||",
                "China": "72,0,4r6,0,gh|72,0,4r6,0,gl||",
                "Choibalsan": "72,0,8bf,0,gh|72,0,8bf,0,gl||",
                "Christmas": "72,0,2s5,0,b2m|||",
                "Cocos": "72,0,1e3,0,b2l|||",
                "Colombia": "72,0,8bn,0,gh|72,0,8bn,0,gl||",
                "Cook": "72,0,1e3,0,8bo,0,gh|72,0,1e3,0,8bo,0,b2j,0,b2h||",
                "Cuba": "72,0,8c4,0,gh|72,0,8c4,0,gl||",
                "Davis": "72,0,b2g|||",
                "DumontDUrville": "72,0,b2e,0,b2d|||",
                "East_Timor": "72,0,1n2,0,b2c|||",
                "Easter": "72,0,37q,0,8cj,0,gh|72,0,37q,0,8cj,0,gl||",
                "Ecuador": "72,0,b2b|||",
                "Europe_Central": "72,0,1ns,0,2gs,0,gh|72,0,1ns,0,2gs,0,gl||",
                "Europe_Eastern": "72,0,1n2,0,2gs,0,gh|72,0,1n2,0,2gs,0,gl||",
                "Europe_Further_Eastern": "72,0,b2a|||",
                "Europe_Western": "72,0,14e,0,2gs,0,gh|72,0,14e,0,2gs,0,gl||",
                "Falkland": "72,0,1e3,0,8cp,0,gh|72,0,1e3,0,8cp,0,gl||",
                "Fiji": "72,0,8d0,0,gh|72,0,8d0,0,gl||",
                "French_Guiana": "72,0,8d1,0,b29|||",
                "French_Southern": "72,0,b28,0,b27,0,b26,0,b25,0,b24|||",
                "Galapagos": "72,0,1e3,0,b23|||",
                "Gambier": "72,0,1e3,0,b22|||",
                "Georgia": "72,0,8dm,0,gh|72,0,8dm,0,gl||",
                "Gilbert_Islands": "72,0,1e3,0,b21|||",
                "GMT": "72,0,b20|||",
                "Greenland_Eastern": "72,0,1n2,0,377,0,gh|72,0,1n2,0,377,0,gl||",
                "Greenland_Western": "72,0,14e,0,377,0,gh|72,0,14e,0,377,0,gl||",
                "Gulf": "72,0,b1v,0,b1u|||",
                "Guyana": "72,0,8d1|||",
                "Hawaii_Aleutian": "72,0,2s9,0,8du,0,8dv,0,gh|72,0,2s9,0,8du,0,8dv,0,gl||",
                "Hong_Kong": "72,0,8e0,0,8e3,0,gh|72,0,8e0,0,8e3,0,gl||",
                "Hovd": "72,0,8e7,0,gh|72,0,8e7,0,gl||",
                "India": "72,0,8ec|||",
                "Indian_Ocean": "72,0,4r4,0,b1t|||",
                "Indochina": "72,0,8ec,5,4r6|||",
                "Indonesia_Central": "72,0,1ns,0,4r9|||",
                "Indonesia_Eastern": "72,0,1n2,0,4r9|||",
                "Indonesia_Western": "72,0,14e,0,4r9|||",
                "Iran": "72,0,8ei,0,gh|72,0,8ei,0,gl||",
                "Irkutsk": "72,0,b1s,0,gh|72,0,b1r,0,gl||",
                "Israel": "72,0,8er,0,gh|72,0,8er,0,gl||",
                "Japan": "72,0,8et,0,gh|72,0,8et,0,gl||",
                "Kamchatka": "72,0,4r3,0,8eu,5,8ev|72,0,2gc,0,8eu,5,8ev||",
                "Kazakhstan_Eastern": "72,0,1n2,0,8f0|||",
                "Kazakhstan_Western": "72,0,14e,0,8f0|||",
                "Korea": "72,0,8f1,0,gh|72,0,8f1,0,gl||",
                "Kosrae": "72,0,b1q|||",
                "Krasnoyarsk": "72,0,8f3,0,gh|72,0,8f3,0,gl||",
                "Kyrgystan": "72,0,b1p|||",
                "Line_Islands": "72,0,1e3,0,b1o|||",
                "Lord_Howe": "72,0,37q,0,8f6,0,8f7,0,gh|72,0,37q,0,8f6,0,8f7,0,gl||",
                "Macau": "72,0,b1n,0,8f9|72,0,2gc,0,8f9||",
                "Macquarie": "72,0,b1m|||",
                "Magadan": "72,0,8fb,0,gh|72,0,8fb,0,gl||",
                "Malaysia": "72,0,b1l|||",
                "Maldives": "72,0,2s9,0,b1k|||",
                "Marquesas": "72,0,1e3,0,b1j|||",
                "Marshall_Islands": "72,0,1e3,0,b1i|||",
                "Mauritius": "72,0,8fg,0,gh|72,0,8fg,0,gl||",
                "Mawson": "72,0,b1h|||",
                "Mexico_Northwest": "72,0,8fi,5,14e,0,36v,0,gh|72,0,8fi,5,14e,0,36v,0,gl||",
                "Mexico_Pacific": "72,0,14e,0,36v,0,gh|72,0,14e,0,36v,0,gl||",
                "Mongolia": "72,0,8fk,0,8fl,0,gh|72,0,8fk,0,8fl,0,gl||",
                "Moscow": "72,0,8fm,0,gh|72,0,8fm,0,gl||",
                "Myanmar": "72,0,b1g|||",
                "Nauru": "72,0,b1f|||",
                "Nepal": "72,0,b1e|||",
                "New_Caledonia": "72,0,8fq,0,4rb,0,gh|72,0,8fq,0,4rb,0,gl||",
                "New_Zealand": "72,0,8fs,0,8fv,0,gh|72,0,8fs,0,8fv,0,gl||",
                "Newfoundland": "72,0,8g0,0,gh|72,0,8g0,0,gl||",
                "Niue": "72,0,b1d|||",
                "Norfolk": "72,0,2s5,0,b1c|||",
                "Noronha": "72,0,8g5,0,8g6,0,8g7,0,gh|72,0,8g5,0,8g6,0,8g7,0,gl||",
                "Novosibirsk": "72,0,8g9,0,gh|72,0,8g9,0,gl||",
                "Omsk": "72,0,8ga,0,gh|72,0,8ga,0,gl||",
                "Pakistan": "72,0,8gb,0,gh|72,0,8gb,0,gl||",
                "Palau": "72,0,b1b|||",
                "Papua_New_Guinea": "72,0,b1a,0,b19,0,4rb|||",
                "Paraguay": "72,0,8gj,0,gh|72,0,8gj,0,gl||",
                "Peru": "72,0,8gm,0,gh|72,0,8gm,0,gl||",
                "Philippines": "72,0,8gn,0,gh|72,0,8gn,0,gl||",
                "Phoenix_Islands": "72,0,1e3,0,b18|||",
                "Pierre_Miquelon": "72,0,8gq,0,8gu,0,8gv,0,gh|72,0,8gq,0,8gu,0,8gv,0,gl||",
                "Pitcairn": "72,0,b17|||",
                "Ponape": "72,0,b16|||",
                "Pyongyang": "72,0,b15|||",
                "Reunion": "72,0,b14|||",
                "Rothera": "72,0,b13|||",
                "Sakhalin": "72,0,8ha,0,gh|72,0,8ha,0,gl||",
                "Samara": "72,0,4r3,0,8he|72,0,2gc,0,8he||",
                "Samoa": "72,0,8hf,0,gh|72,0,8hf,0,gl||",
                "Seychelles": "72,0,1e3,0,b12|||",
                "Singapore": "72,0,b11|||",
                "Solomon": "72,0,1e3,0,b10|||",
                "South_Georgia": "72,0,89i,0,b0v|||",
                "Suriname": "72,0,b0u|||",
                "Syowa": "72,0,b0t|||",
                "Tahiti": "72,0,b0s|||",
                "Taipei": "72,0,8hv,0,gh|72,0,8hv,0,gl||",
                "Tajikistan": "72,0,b0r|||",
                "Tokelau": "72,0,b0q|||",
                "Tonga": "72,0,8ia,0,gh|72,0,8ia,0,gl||",
                "Truk": "72,0,b0p|||",
                "Turkmenistan": "72,0,8id,0,gh|72,0,8id,0,gl||",
                "Tuvalu": "72,0,b0k|||",
                "Uruguay": "72,0,8if,0,gh|72,0,8if,0,gl||",
                "Uzbekistan": "72,0,8ii,0,gh|72,0,8ii,0,gl||",
                "Vanuatu": "72,0,8ij,0,gh|72,0,8ij,0,gl||",
                "Venezuela": "72,0,b0j|||",
                "Vladivostok": "72,0,8il,0,gh|72,0,8il,0,gl||",
                "Volgograd": "72,0,8im,0,gh|72,0,8im,0,gl||",
                "Vostok": "72,0,b0h|||",
                "Wake": "72,0,2s5,0,b0g|||",
                "Wallis": "72,0,b0f,0,b0d|||",
                "Yakutsk": "72,0,8ir,0,gh|72,0,8ir,0,gl||",
                "Yekaterinburg": "72,0,8is,0,gh|72,0,8is,0,gl||"
            }
        },
        "hi": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4ni,0,5n,0,1b||",
                "Europe/London": "|6aa,0,a5,0,1b||"
            },
            "metazone": {
                "Afghanistan": "b0b,0,1b|||",
                "Africa_Central": "1a6,0,2tu,0,1b|||",
                "Africa_Eastern": "oq,0,2tu,0,1b|||",
                "Africa_Southern": "1jl,0,2tu,0,1b|||",
                "Africa_Western": "124,0,2tu,0,5n,0,1b|124,0,2tu,0,a5,0,1b||",
                "Alaska": "8j9,0,5n,0,1b|8j9,0,s9,0,1b||",
                "Amazon": "8jc,0,5n,0,1b|8jc,0,a5,0,1b||",
                "America_Central": "1s6,0,1s5,0,1s4,0,5n,0,1b|1s6,0,1s5,0,1s4,0,s9,0,1b||",
                "America_Eastern": "1s6,0,1s5,0,oq,0,5n,0,1b|1s6,0,1s5,0,oq,0,s9,0,1b||",
                "America_Mountain": "1s6,0,1s5,0,4s6,0,5n,0,1b|1s6,0,1s5,0,4s6,0,s9,0,1b||",
                "America_Pacific": "1s6,0,1s5,0,369,0,5n,0,1b|1s6,0,1s5,0,369,0,s9,0,1b||",
                "Anadyr": "4s8,0,5n,0,1b|4s8,0,a5,0,1b||",
                "Apia": "8jd,0,5n,0,1b|8jd,0,s9,0,1b||",
                "Arabian": "4sa,0,5n,0,1b|4sa,0,s9,0,1b||",
                "Argentina": "366,0,5n,0,1b|366,0,a5,0,1b||",
                "Argentina_Western": "q4,0,366,0,5n,0,1b|q4,0,366,0,a5,0,1b||",
                "Armenia": "365,0,5n,0,1b|365,0,a5,0,1b||",
                "Atlantic": "364,0,5n,0,1b|364,0,s9,0,1b||",
                "Australia_Central": "2ie,0,1s4,0,5n,0,1b|2ie,0,1s4,0,s9,0,1b||",
                "Australia_CentralWestern": "2ie,0,1s4,0,q4,0,5n,0,1b|2ie,0,1s4,0,q4,0,s9,0,1b||",
                "Australia_Eastern": "2ie,0,oq,0,5n,0,1b|2ie,0,oq,0,s9,0,1b||",
                "Australia_Western": "4sg,0,q4,0,5n,0,1b|4sg,0,q4,0,s9,0,1b||",
                "Azerbaijan": "4sh,0,5n,0,1b|4sh,0,a5,0,1b||",
                "Azores": "4si,0,5n,0,1b|4si,0,a5,0,1b||",
                "Bangladesh": "4sj,0,5n,0,1b|4sj,0,a5,0,1b||",
                "Bhutan": "b08,0,1b|||",
                "Bolivia": "b00,0,1b|||",
                "Brasilia": "4sm,0,5n,0,1b|4sm,0,a5,0,1b||",
                "Brunei": "avv,0,4rf,0,1b|||",
                "Cape_Verde": "1s1,0,4sq,0,5n,0,1b|1s1,0,4sq,0,a5,0,1b||",
                "Chamorro": "4sr,0,5n,0,1b|||",
                "Chatham": "4en,0,5n,0,1b|4en,0,s9,0,1b||",
                "Chile": "1s0,0,5n,0,1b|1s0,0,a5,0,1b||",
                "China": "2ii,0,5n,0,1b|2ii,0,s9,0,1b||",
                "Choibalsan": "4su,0,5n,0,1b|4su,0,a5,0,1b||",
                "Christmas": "avu,0,1ev,0,1b|||",
                "Cocos": "4rg,0,1l0,0,1b|||",
                "Colombia": "2b7,0,5n,0,1b|2b7,0,a5,0,1b||",
                "Cook": "1lj,0,1l0,0,5n,0,1b|1lj,0,1l0,0,avr,0,a5,0,1b||",
                "Cuba": "360,0,5n,0,1b|360,0,s9,0,1b||",
                "Davis": "avo,0,1b|||",
                "DumontDUrville": "avn,0,avm,0,1b|||",
                "East_Timor": "oq,0,4t7,0,1b|||",
                "Easter": "4em,0,1ev,0,5n,0,1b|4em,0,1ev,0,a5,0,1b||",
                "Ecuador": "avl,0,1b|||",
                "Europe_Central": "1a6,0,29u,0,5n,0,1b|2um,0,29u,0,4tb,0,1b||",
                "Europe_Eastern": "oq,0,29u,0,5n,0,1b|oq,0,29u,0,a5,0,1b||",
                "Europe_Further_Eastern": "4tc,0,oq,0,29u,0,1b|||",
                "Europe_Western": "q4,0,29u,0,5n,0,1b|q4,0,29u,0,4tb,0,1b||",
                "Falkland": "4td,0,1l0,0,5n,0,1b|4td,0,1l0,0,a5,0,1b||",
                "Fiji": "4te,0,5n,0,1b|4te,0,a5,0,1b||",
                "French_Guiana": "avj,0,2as,0,1b|||",
                "French_Southern": "35u,0,avi,0,35t,0,avh,0,1b|||",
                "Galapagos": "ave,0,avd,0,1b|||",
                "Gambier": "avc,0,1b|||",
                "Georgia": "27i,0,5n,0,1b|27i,0,a5,0,1b||",
                "Gilbert_Islands": "35s,0,1l0,0,1b|||",
                "GMT": "4ri,0,ava,0,av9|||",
                "Greenland_Eastern": "oq,0,35q,0,5n,0,1b|oq,0,35q,0,a5,0,1b||",
                "Greenland_Western": "q4,0,35q,0,5n,0,1b|q4,0,35q,0,a5,0,1b||",
                "Gulf": "av8,0,5n,0,1b|||",
                "Guyana": "2as,0,1b|||",
                "Hawaii_Aleutian": "4tt,0,5n,0,1b|4tt,0,s9,0,1b||",
                "Hong_Kong": "35p,0,35o,0,5n,0,1b|35p,0,35o,0,a5,0,1b||",
                "Hovd": "4ei,0,5n,0,1b|4ei,0,a5,0,1b||",
                "India": "27j,0,5n,0,1b||15a|",
                "Indian_Ocean": "av5,0,4rj,0,1b|||",
                "Indochina": "av4,0,1b|||",
                "Indonesia_Central": "1a6,0,2j0,0,1b|||",
                "Indonesia_Eastern": "oq,0,2j0,0,1b|||",
                "Indonesia_Western": "q4,0,2j0,0,1b|||",
                "Iran": "4eg,0,5n,0,1b|4eg,0,s9,0,1b||",
                "Irkutsk": "4u4,0,5n,0,1b|4u4,0,a5,0,1b||",
                "Israel": "4u5,0,5n,0,1b|4u5,0,s9,0,1b||",
                "Japan": "2j1,0,5n,0,1b|2j1,0,s9,0,1b||",
                "Kamchatka": "4u7,5,4u8,0,5n,0,1b|4u7,5,4u8,0,a5,0,1b||",
                "Kazakhstan_Eastern": "1lo,0,4ua,0,1b|||",
                "Kazakhstan_Western": "124,0,4ua,0,1b|||",
                "Korea": "4ub,0,5n,0,1b|4ub,0,s9,0,1b||",
                "Kosrae": "4rk,0,1b|||",
                "Krasnoyarsk": "4rl,0,5n,0,1b|4rl,0,a5,0,1b||",
                "Kyrgystan": "av3,0,1b|||",
                "Line_Islands": "35k,0,1l0,0,1b|||",
                "Lord_Howe": "35j,0,35i,0,5n,0,1b|35j,0,35i,0,s9,0,1b||",
                "Macquarie": "auv,0,1ev,0,1b|||",
                "Magadan": "2j8,0,5n,0,1b|2j8,0,a5,0,1b||",
                "Malaysia": "4hp,0,1b|||",
                "Maldives": "5ma,0,1b|||",
                "Marquesas": "auu,0,1b|||",
                "Marshall_Islands": "2ob,0,1l0,0,1b|||",
                "Mauritius": "35e,0,5n,0,1b|35e,0,a5,0,1b||",
                "Mawson": "aut,0,1b|||",
                "Mexico_Northwest": "2a5,0,q4,0,2ja,0,5n,0,1b|2a5,0,q4,0,2ja,0,s9,0,1b||",
                "Mexico_Pacific": "2jb,0,369,0,5n,0,1b|2jb,0,369,0,s9,0,1b||",
                "Mongolia": "1rp,0,4ur,0,5n,0,1b|1rp,0,4ur,0,a5,0,1b||",
                "Moscow": "2lk,0,5n,0,1b|2lk,0,a5,0,1b||",
                "Myanmar": "aus,0,1b|||",
                "Nauru": "auq,0,1b|||",
                "Nepal": "5nu,0,1b|||",
                "New_Caledonia": "1ld,0,4e9,0,5n,0,1b|1ld,0,4e9,0,a5,0,1b||",
                "New_Zealand": "4uv,0,5n,0,1b|4uv,0,s9,0,1b||",
                "Newfoundland": "4v0,0,5n,0,1b|4v0,0,s9,0,1b||",
                "Niue": "aul,0,1b|||",
                "Norfolk": "auj,0,1ev,0,1b|||",
                "Noronha": "4v3,0,2jf,0,1n8,0,5n,0,1b|4v3,0,2jf,0,1n8,0,a5,0,1b||",
                "Novosibirsk": "356,0,5n,0,1b|356,0,a5,0,1b||",
                "Omsk": "1nb,0,5n,0,1b|1nb,0,a5,0,1b||",
                "Pakistan": "2li,0,5n,0,1b|2li,0,a5,0,1b||",
                "Palau": "aui,0,1b|||",
                "Papua_New_Guinea": "4hr,0,1ld,0,355,0,1b|||",
                "Paraguay": "4v8,0,5n,0,1b|4v8,0,a5,0,1b||",
                "Peru": "354,0,5n,0,1b|354,0,a5,0,1b||",
                "Philippines": "4va,0,5n,0,1b|4va,0,a5,0,1b||",
                "Phoenix_Islands": "aue,0,1l0,0,1b|||",
                "Pierre_Miquelon": "351,0,4vd,0,35t,0,4ve,0,5n,0,1b|351,0,4vd,0,35t,0,4ve,0,s9,0,1b||",
                "Pitcairn": "4vf,0,1b|||",
                "Ponape": "4e6,0,1b|||",
                "Pyongyang": "auc,0,1b|||",
                "Reunion": "aub,0,1b|||",
                "Rothera": "2qb,0,1b|||",
                "Sakhalin": "4vi,0,5n,0,1b|4vi,0,a5,0,1b||",
                "Samara": "4e5,0,5n,0,1b|4e5,0,a5,0,1b||",
                "Samoa": "4e4,0,5n,0,1b|4e4,0,s9,0,1b||",
                "Seychelles": "aua,0,1b|||",
                "Singapore": "4rq,0,1b|||",
                "Solomon": "au9,0,1l0,0,1b|||",
                "South_Georgia": "35u,0,27i,0,1b|||",
                "Suriname": "au8,0,1b|||",
                "Syowa": "34v,0,1b|||",
                "Tahiti": "34u,0,1b|||",
                "Taipei": "4vp,0,5n,0,1b|4vp,0,s9,0,1b||",
                "Tajikistan": "4vq,0,1b|||",
                "Tokelau": "4vr,0,1b|||",
                "Tonga": "1rl,0,5n,0,1b|1rl,0,a5,0,1b||",
                "Truk": "4vt,0,1b|||",
                "Turkmenistan": "1ri,0,5n,0,1b|1ri,0,a5,0,1b||",
                "Tuvalu": "4vv,0,1b|||",
                "Uruguay": "2jp,0,5n,0,1b|2jp,0,a5,0,1b||",
                "Uzbekistan": "501,0,5n,0,1b|501,0,a5,0,1b||",
                "Vanuatu": "502,0,5n,0,1b|502,0,a5,0,1b||",
                "Venezuela": "5vc,0,1b|||",
                "Vladivostok": "34t,0,5n,0,1b|34t,0,a5,0,1b||",
                "Volgograd": "504,0,5n,0,1b|504,0,a5,0,1b||",
                "Vostok": "au7,0,1b|||",
                "Wake": "34s,0,1ev,0,1b|||",
                "Wallis": "au6,0,35t,0,au5,0,1b|||",
                "Yakutsk": "509,0,5n,0,1b|509,0,a5,0,1b||",
                "Yekaterinburg": "50a,0,5n,0,1b|50a,0,a5,0,1b||"
            }
        },
        "hr-BA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6a7,0,1a,0,10||",
                "Europe/London": "|68h,0,4b,0,10||"
            },
            "metazone": {
                "Acre": "3q,0,1a,0,10|3q,0,4b,0,10||",
                "Afghanistan": "50b,0,10|||",
                "Africa_Central": "50c,0,10|||",
                "Africa_Eastern": "50d,0,10|||",
                "Africa_Southern": "50e,0,10|||",
                "Africa_Western": "34q,0,1a,0,10|34q,0,4b,0,10||",
                "Alaska": "34p,0,1a,0,10|34p,0,4b,0,10||",
                "Almaty": "34o,0,1a,0,10|34o,0,4b,0,10||",
                "Amazon": "34n,0,1a,0,10|34n,0,4b,0,10||",
                "America_Central": "1rf,0,1a,0,10|1rf,0,4b,0,10||",
                "America_Eastern": "111,0,1a,0,10|111,0,4b,0,10||",
                "America_Mountain": "17r,0,1a,0,10|17r,0,4b,0,10||",
                "America_Pacific": "110,0,1a,0,10|110,0,4b,0,10||",
                "Anadyr": "34m,0,1a,0,10|34m,0,4b,0,10||",
                "Apia": "1a,0,10,0,34l|4b,0,10,0,34l||",
                "Aqtau": "1a,0,10,0,18s,0,i4|4b,0,10,0,18s,0,i4||",
                "Aqtobe": "1a,0,10,0,18s,0,im|4b,0,10,0,18s,0,im||",
                "Arabian": "34k,0,1a,0,10|34k,0,4b,0,10||",
                "Argentina": "1rd,0,1a,0,10|1rd,0,4b,0,10||",
                "Argentina_Western": "11c,5,1rd,0,1a,0,10|11c,5,1rd,0,4b,0,10||",
                "Armenia": "34h,0,1a,0,10|34h,0,4b,0,10||",
                "Atlantic": "23j,0,1a,0,10|23j,0,4b,0,10||",
                "Australia_Central": "34g,0,1a,0,10|34g,0,4b,0,10||",
                "Australia_CentralWestern": "34f,0,1rf,0,11c,0,1a,0,10|34f,0,1rf,0,11c,0,4b,0,10||",
                "Australia_Eastern": "34e,0,1a,0,10|34e,0,4b,0,10||",
                "Australia_Western": "34d,0,1a,0,10|34d,0,4b,0,10||",
                "Azerbaijan": "34c,0,1a,0,10|34c,0,4b,0,10||",
                "Azores": "34a,0,1a,0,10|34a,0,4b,0,10||",
                "Bangladesh": "348,0,1a,0,10|348,0,4b,0,10||",
                "Bhutan": "512,0,10|||",
                "Bolivia": "513,0,10|||",
                "Brasilia": "347,0,1a,0,10|347,0,4b,0,10||",
                "Brunei": "10,0,l,0,2kg,0,a4|||",
                "Cape_Verde": "1a,0,10,0,343,0,33v|4b,0,10,0,343,0,33v||",
                "Casey": "10,0,519|||",
                "Chamorro": "1a,0,10,0,51a|||",
                "Chatham": "1a,0,10,0,33t|4b,0,10,0,33t||",
                "Chile": "33s,0,1a,0,10|33s,0,4b,0,10||",
                "China": "33r,0,1a,0,10|33r,0,4b,0,10||",
                "Choibalsan": "33q,0,1a,0,10|33q,0,4b,0,10||",
                "Christmas": "10,0,51f,0,1cl|||",
                "Cocos": "10,0,51h,0,12b|||",
                "Colombia": "33o,0,1a,0,10|33o,0,4b,0,10||",
                "Cook": "1a,0,10,0,51k,0,12b|51l,0,33l,k,0,51n,0,51o,k,0,4b,0,10||",
                "Cuba": "33k,0,1a,0,10|33k,0,4b,0,10||",
                "Davis": "10,0,51q|||",
                "DumontDUrville": "10,0,5b,5,4s0|||",
                "East_Timor": "51s,0,10|||",
                "Easter": "1a,0,10,0,33i,0,1cl|4b,0,10,0,33i,0,1cl||",
                "Ecuador": "51u,0,10|||",
                "Europe_Central": "33h,0,1a,0,10|33h,0,4b,0,10|h0|h4",
                "Europe_Eastern": "33g,0,1a,0,10|33g,0,4b,0,10|gu|gv",
                "Europe_Further_Eastern": "521,0,522,0,10|||",
                "Europe_Western": "33f,0,1a,0,10|33f,0,4b,0,10|h1|h2",
                "Falkland": "33e,0,1a,0,10|33e,0,4b,0,10||",
                "Fiji": "1a,0,10,0,33d|4b,0,10,0,33d||",
                "French_Guiana": "10,0,526,0,527|||",
                "French_Southern": "528,0,i3,0,2l1,0,10|||",
                "Galapagos": "10,0,52a|||",
                "Gambier": "10,0,52b|||",
                "Georgia": "33c,0,1a,0,10|33c,0,4b,0,10||",
                "Gilbert_Islands": "10,0,52d,0,12b|||",
                "GMT": "52e,0,10||ah|",
                "Greenland_Eastern": "33b,0,1a,0,10|33b,0,4b,0,10||",
                "Greenland_Western": "33a,0,1a,0,10|33a,0,4b,0,10||",
                "Guam": "52h,0,1a,0,10|||",
                "Gulf": "52i,0,1a,0,10|||",
                "Guyana": "52j,0,10|||",
                "Hawaii_Aleutian": "2l5,5,1k2,0,1a,0,10|2l5,5,1k2,0,4b,0,10||",
                "Hong_Kong": "339,0,1a,0,10|339,0,4b,0,10||",
                "Hovd": "338,0,1a,0,10|338,0,4b,0,10||",
                "India": "52o,0,10|||",
                "Indian_Ocean": "10,0,52p,0,52q|||",
                "Indochina": "52r,0,10|||",
                "Indonesia_Central": "52s,0,10|||",
                "Indonesia_Eastern": "52t,0,10|||",
                "Indonesia_Western": "52u,0,10|||",
                "Iran": "337,0,1a,0,10|337,0,4b,0,10||",
                "Irkutsk": "336,0,1a,0,10|336,0,4b,0,10||",
                "Israel": "335,0,1a,0,10|335,0,4b,0,10||",
                "Japan": "334,0,1a,0,10|334,0,4b,0,10||",
                "Kamchatka": "37,5,1i4,0,1a,0,10|37,5,1i4,0,4b,0,10||",
                "Kazakhstan_Eastern": "534,0,10|||",
                "Kazakhstan_Western": "535,0,10|||",
                "Korea": "333,0,1a,0,10|333,0,4b,0,10||",
                "Kosrae": "10,0,6f|||",
                "Krasnoyarsk": "332,0,1a,0,10|332,0,4b,0,10||",
                "Kyrgystan": "538,0,10|||",
                "Lanka": "539,0,10|||",
                "Line_Islands": "10,0,12b,0,ac|||",
                "Lord_Howe": "1a,0,10,0,1cl,0,1u,0,24|4b,0,10,0,1cl,0,1u,0,24||",
                "Macau": "1a,0,331,0,10|4b,0,331,0,10||",
                "Macquarie": "10,0,1cl,0,6m|||",
                "Magadan": "32u,0,1a,0,10|32u,0,4b,0,10||",
                "Malaysia": "53c,0,10|||",
                "Maldives": "53d,0,10|||",
                "Marquesas": "53e,0,10|||",
                "Marshall_Islands": "10,0,53f,0,12b|||",
                "Mauritius": "1a,0,10,0,32r|4b,0,10,0,32r||",
                "Mawson": "53h,0,10|||",
                "Mexico_Northwest": "32q,0,1hj,0,1a,0,10|32q,0,1hj,0,4b,0,10||",
                "Mexico_Pacific": "1hj,0,110,0,1a,0,10|1hj,0,110,0,4b,0,10||",
                "Mongolia": "32m,5,32l,0,1a,0,10|32m,5,32l,0,4b,0,10||",
                "Moscow": "31s,0,1a,0,10|31s,0,4b,0,10||",
                "Myanmar": "53m,0,10|||",
                "Nauru": "10,0,53n|||",
                "Nepal": "53o,0,10|||",
                "New_Caledonia": "1a,0,10,0,2ll,0,3rs|4b,0,10,0,2ll,0,3rs||",
                "New_Zealand": "31t,0,1a,0,10|31t,0,4b,0,10||",
                "Newfoundland": "31u,0,1a,0,10|31u,0,4b,0,10||",
                "Niue": "10,0,53t|||",
                "Norfolk": "10,0,12b,0,6l|||",
                "Noronha": "1a,0,10,0,18s,0,1s,0,2,0,23|4b,0,10,0,18s,0,1s,0,2,0,23||",
                "North_Mariana": "10,0,53u,0,12b|||",
                "Novosibirsk": "31v,0,1a,0,10|31v,0,4b,0,10||",
                "Omsk": "320,0,1a,0,10|320,0,4b,0,10||",
                "Pakistan": "321,0,1a,0,10|321,0,4b,0,10||",
                "Palau": "10,0,542|||",
                "Papua_New_Guinea": "10,0,543,0,2ll,0,544|||",
                "Paraguay": "322,0,1a,0,10|322,0,4b,0,10||",
                "Peru": "323,0,1a,0,10|323,0,4b,0,10||",
                "Philippines": "324,0,1a,0,10|324,0,4b,0,10||",
                "Phoenix_Islands": "10,0,12b,0,90|||",
                "Pierre_Miquelon": "1a,0,10,0,l,0,325,0,326,0,i3,0,u2|4b,0,10,0,l,0,325,0,326,0,i3,0,u2||",
                "Pitcairn": "10,0,54a|||",
                "Ponape": "10,0,54b|||",
                "Pyongyang": "54c,0,10|||",
                "Qyzylorda": "1a,0,10,0,18s,0,327|4b,0,10,0,18s,0,327||",
                "Reunion": "10,0,54e|||",
                "Rothera": "10,0,54f|||",
                "Sakhalin": "328,0,1a,0,10|328,0,4b,0,10||",
                "Samara": "329,0,1a,0,10|329,0,4b,0,10||",
                "Samoa": "32a,0,1a,0,10|32a,0,4b,0,10||",
                "Seychelles": "54j,0,10|||",
                "Singapore": "54k,0,10|||",
                "Solomon": "10,0,54l,0,12b|||",
                "South_Georgia": "10,0,54m,0,54n|||",
                "Suriname": "54o,0,10|||",
                "Syowa": "10,0,54p|||",
                "Tahiti": "10,0,54q|||",
                "Taipei": "32b,0,1a,0,10|32b,0,4b,0,10||",
                "Tajikistan": "54s,0,10|||",
                "Tokelau": "10,0,54t|||",
                "Tonga": "1a,0,10,0,32c|4b,0,10,0,32c||",
                "Truk": "10,0,54v|||",
                "Turkmenistan": "32d,0,1a,0,10|32d,0,4b,0,10||",
                "Tuvalu": "10,0,551|||",
                "Uruguay": "32e,0,1a,0,10|32e,0,4b,0,10||",
                "Uzbekistan": "32f,0,1a,0,10|32f,0,4b,0,10||",
                "Vanuatu": "1a,0,10,0,32g|4b,0,10,0,32g||",
                "Venezuela": "555,0,10|||",
                "Vladivostok": "32h,0,1a,0,10|32h,0,4b,0,10||",
                "Volgograd": "32i,0,1a,0,10|32i,0,4b,0,10||",
                "Vostok": "558,0,10|||",
                "Wake": "10,0,12b,0,5r|||",
                "Wallis": "10,0,12b,0,5f,0,i3,0,5k|||",
                "Yakutsk": "32j,0,1a,0,10|32j,0,4b,0,10||",
                "Yekaterinburg": "32k,0,1a,0,10|32k,0,4b,0,10||"
            }
        },
        "hr": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6a7,0,1a,0,10||",
                "Europe/London": "|68h,0,4b,0,10||"
            },
            "metazone": {
                "Acre": "3q,0,1a,0,10|3q,0,4b,0,10||",
                "Afghanistan": "50b,0,10|||",
                "Africa_Central": "50c,0,10|||",
                "Africa_Eastern": "50d,0,10|||",
                "Africa_Southern": "50e,0,10|||",
                "Africa_Western": "34q,0,1a,0,10|34q,0,4b,0,10||",
                "Alaska": "34p,0,1a,0,10|34p,0,4b,0,10||",
                "Almaty": "34o,0,1a,0,10|34o,0,4b,0,10||",
                "Amazon": "34n,0,1a,0,10|34n,0,4b,0,10||",
                "America_Central": "1rf,0,1a,0,10|1rf,0,4b,0,10||",
                "America_Eastern": "111,0,1a,0,10|111,0,4b,0,10||",
                "America_Mountain": "17r,0,1a,0,10|17r,0,4b,0,10||",
                "America_Pacific": "110,0,1a,0,10|110,0,4b,0,10||",
                "Anadyr": "34m,0,1a,0,10|34m,0,4b,0,10||",
                "Apia": "1a,0,10,0,34l|4b,0,10,0,34l||",
                "Aqtau": "1a,0,10,0,18s,0,i4|4b,0,10,0,18s,0,i4||",
                "Aqtobe": "1a,0,10,0,18s,0,im|4b,0,10,0,18s,0,im||",
                "Arabian": "34k,0,1a,0,10|34k,0,4b,0,10||",
                "Argentina": "1rd,0,1a,0,10|1rd,0,4b,0,10||",
                "Argentina_Western": "11c,5,1rd,0,1a,0,10|11c,5,1rd,0,4b,0,10||",
                "Armenia": "34h,0,1a,0,10|34h,0,4b,0,10||",
                "Atlantic": "23j,0,1a,0,10|23j,0,4b,0,10||",
                "Australia_Central": "34g,0,1a,0,10|34g,0,4b,0,10||",
                "Australia_CentralWestern": "34f,0,1rf,0,11c,0,1a,0,10|34f,0,1rf,0,11c,0,4b,0,10||",
                "Australia_Eastern": "34e,0,1a,0,10|34e,0,4b,0,10||",
                "Australia_Western": "34d,0,1a,0,10|34d,0,4b,0,10||",
                "Azerbaijan": "34c,0,1a,0,10|34c,0,4b,0,10||",
                "Azores": "34a,0,1a,0,10|34a,0,4b,0,10||",
                "Bangladesh": "348,0,1a,0,10|348,0,4b,0,10||",
                "Bhutan": "512,0,10|||",
                "Bolivia": "513,0,10|||",
                "Brasilia": "347,0,1a,0,10|347,0,4b,0,10||",
                "Brunei": "10,0,l,0,2kg,0,a4|||",
                "Cape_Verde": "1a,0,10,0,343,0,33v|4b,0,10,0,343,0,33v||",
                "Casey": "10,0,519|||",
                "Chamorro": "1a,0,10,0,51a|||",
                "Chatham": "1a,0,10,0,33t|4b,0,10,0,33t||",
                "Chile": "33s,0,1a,0,10|33s,0,4b,0,10||",
                "China": "33r,0,1a,0,10|33r,0,4b,0,10||",
                "Choibalsan": "33q,0,1a,0,10|33q,0,4b,0,10||",
                "Christmas": "10,0,51f,0,1cl|||",
                "Cocos": "10,0,51h,0,12b|||",
                "Colombia": "33o,0,1a,0,10|33o,0,4b,0,10||",
                "Cook": "1a,0,10,0,51k,0,12b|51l,0,33l,k,0,51n,0,51o,k,0,4b,0,10||",
                "Cuba": "33k,0,1a,0,10|33k,0,4b,0,10||",
                "Davis": "10,0,51q|||",
                "DumontDUrville": "10,0,5b,5,4s0|||",
                "East_Timor": "51s,0,10|||",
                "Easter": "1a,0,10,0,33i,0,1cl|4b,0,10,0,33i,0,1cl||",
                "Ecuador": "51u,0,10|||",
                "Europe_Central": "33h,0,1a,0,10|33h,0,4b,0,10|h0|h4",
                "Europe_Eastern": "33g,0,1a,0,10|33g,0,4b,0,10|gu|gv",
                "Europe_Further_Eastern": "521,0,522,0,10|||",
                "Europe_Western": "33f,0,1a,0,10|33f,0,4b,0,10|h1|h2",
                "Falkland": "33e,0,1a,0,10|33e,0,4b,0,10||",
                "Fiji": "1a,0,10,0,33d|4b,0,10,0,33d||",
                "French_Guiana": "10,0,526,0,527|||",
                "French_Southern": "528,0,i3,0,2l1,0,10|||",
                "Galapagos": "10,0,52a|||",
                "Gambier": "10,0,52b|||",
                "Georgia": "33c,0,1a,0,10|33c,0,4b,0,10||",
                "Gilbert_Islands": "10,0,52d,0,12b|||",
                "GMT": "52e,0,10||ah|",
                "Greenland_Eastern": "33b,0,1a,0,10|33b,0,4b,0,10||",
                "Greenland_Western": "33a,0,1a,0,10|33a,0,4b,0,10||",
                "Guam": "52h,0,1a,0,10|||",
                "Gulf": "52i,0,1a,0,10|||",
                "Guyana": "52j,0,10|||",
                "Hawaii_Aleutian": "2l5,5,1k2,0,1a,0,10|2l5,5,1k2,0,4b,0,10||",
                "Hong_Kong": "339,0,1a,0,10|339,0,4b,0,10||",
                "Hovd": "338,0,1a,0,10|338,0,4b,0,10||",
                "India": "52o,0,10|||",
                "Indian_Ocean": "10,0,52p,0,52q|||",
                "Indochina": "52r,0,10|||",
                "Indonesia_Central": "52s,0,10|||",
                "Indonesia_Eastern": "52t,0,10|||",
                "Indonesia_Western": "52u,0,10|||",
                "Iran": "337,0,1a,0,10|337,0,4b,0,10||",
                "Irkutsk": "336,0,1a,0,10|336,0,4b,0,10||",
                "Israel": "335,0,1a,0,10|335,0,4b,0,10||",
                "Japan": "334,0,1a,0,10|334,0,4b,0,10||",
                "Kamchatka": "37,5,1i4,0,1a,0,10|37,5,1i4,0,4b,0,10||",
                "Kazakhstan_Eastern": "534,0,10|||",
                "Kazakhstan_Western": "535,0,10|||",
                "Korea": "333,0,1a,0,10|333,0,4b,0,10||",
                "Kosrae": "10,0,6f|||",
                "Krasnoyarsk": "332,0,1a,0,10|332,0,4b,0,10||",
                "Kyrgystan": "538,0,10|||",
                "Lanka": "539,0,10|||",
                "Line_Islands": "10,0,12b,0,ac|||",
                "Lord_Howe": "1a,0,10,0,1cl,0,1u,0,24|4b,0,10,0,1cl,0,1u,0,24||",
                "Macau": "1a,0,331,0,10|4b,0,331,0,10||",
                "Macquarie": "10,0,1cl,0,6m|||",
                "Magadan": "32u,0,1a,0,10|32u,0,4b,0,10||",
                "Malaysia": "53c,0,10|||",
                "Maldives": "53d,0,10|||",
                "Marquesas": "53e,0,10|||",
                "Marshall_Islands": "10,0,53f,0,12b|||",
                "Mauritius": "1a,0,10,0,32r|4b,0,10,0,32r||",
                "Mawson": "53h,0,10|||",
                "Mexico_Northwest": "32q,0,1hj,0,1a,0,10|32q,0,1hj,0,4b,0,10||",
                "Mexico_Pacific": "1hj,0,110,0,1a,0,10|1hj,0,110,0,4b,0,10||",
                "Mongolia": "32m,5,32l,0,1a,0,10|32m,5,32l,0,4b,0,10||",
                "Moscow": "31s,0,1a,0,10|31s,0,4b,0,10||",
                "Myanmar": "53m,0,10|||",
                "Nauru": "10,0,53n|||",
                "Nepal": "53o,0,10|||",
                "New_Caledonia": "1a,0,10,0,2ll,0,3rs|4b,0,10,0,2ll,0,3rs||",
                "New_Zealand": "31t,0,1a,0,10|31t,0,4b,0,10||",
                "Newfoundland": "31u,0,1a,0,10|31u,0,4b,0,10||",
                "Niue": "10,0,53t|||",
                "Norfolk": "10,0,12b,0,6l|||",
                "Noronha": "1a,0,10,0,18s,0,1s,0,2,0,23|4b,0,10,0,18s,0,1s,0,2,0,23||",
                "North_Mariana": "10,0,53u,0,12b|||",
                "Novosibirsk": "31v,0,1a,0,10|31v,0,4b,0,10||",
                "Omsk": "320,0,1a,0,10|320,0,4b,0,10||",
                "Pakistan": "321,0,1a,0,10|321,0,4b,0,10||",
                "Palau": "10,0,542|||",
                "Papua_New_Guinea": "10,0,543,0,2ll,0,544|||",
                "Paraguay": "322,0,1a,0,10|322,0,4b,0,10||",
                "Peru": "323,0,1a,0,10|323,0,4b,0,10||",
                "Philippines": "324,0,1a,0,10|324,0,4b,0,10||",
                "Phoenix_Islands": "10,0,12b,0,90|||",
                "Pierre_Miquelon": "1a,0,10,0,l,0,325,0,326,0,i3,0,u2|4b,0,10,0,l,0,325,0,326,0,i3,0,u2||",
                "Pitcairn": "10,0,54a|||",
                "Ponape": "10,0,54b|||",
                "Pyongyang": "54c,0,10|||",
                "Qyzylorda": "1a,0,10,0,18s,0,327|4b,0,10,0,18s,0,327||",
                "Reunion": "10,0,54e|||",
                "Rothera": "10,0,54f|||",
                "Sakhalin": "328,0,1a,0,10|328,0,4b,0,10||",
                "Samara": "329,0,1a,0,10|329,0,4b,0,10||",
                "Samoa": "32a,0,1a,0,10|32a,0,4b,0,10||",
                "Seychelles": "54j,0,10|||",
                "Singapore": "54k,0,10|||",
                "Solomon": "10,0,54l,0,12b|||",
                "South_Georgia": "10,0,54m,0,54n|||",
                "Suriname": "54o,0,10|||",
                "Syowa": "10,0,54p|||",
                "Tahiti": "10,0,54q|||",
                "Taipei": "32b,0,1a,0,10|32b,0,4b,0,10||",
                "Tajikistan": "54s,0,10|||",
                "Tokelau": "10,0,54t|||",
                "Tonga": "1a,0,10,0,32c|4b,0,10,0,32c||",
                "Truk": "10,0,54v|||",
                "Turkmenistan": "32d,0,1a,0,10|32d,0,4b,0,10||",
                "Tuvalu": "10,0,551|||",
                "Uruguay": "32e,0,1a,0,10|32e,0,4b,0,10||",
                "Uzbekistan": "32f,0,1a,0,10|32f,0,4b,0,10||",
                "Vanuatu": "1a,0,10,0,32g|4b,0,10,0,32g||",
                "Venezuela": "555,0,10|||",
                "Vladivostok": "32h,0,1a,0,10|32h,0,4b,0,10||",
                "Volgograd": "32i,0,1a,0,10|32i,0,4b,0,10||",
                "Vostok": "558,0,10|||",
                "Wake": "10,0,12b,0,5r|||",
                "Wallis": "10,0,12b,0,5f,0,i3,0,5k|||",
                "Yakutsk": "32j,0,1a,0,10|32j,0,4b,0,10||",
                "Yekaterinburg": "32k,0,1a,0,10|32k,0,4b,0,10||"
            }
        },
        "hsb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cb4,0,g5,0,11||",
                "Europe/London": "|6mo,0,g5,0,11||"
            },
            "metazone": {
                "Afghanistan": "au1,0,11|||",
                "Africa_Central": "au0,0,11|||",
                "Africa_Eastern": "atv,0,11|||",
                "Africa_Southern": "atu,0,11|||",
                "Africa_Western": "55f,0,ad,0,11|55f,0,g5,0,11||",
                "Alaska": "55h,0,ad,0,11|55h,0,g5,0,11||",
                "Amazon": "44m,0,ad,0,11|44m,0,g5,0,11||",
                "America_Central": "1uc,0,44l,0,ad,0,11|1uc,0,44l,0,g5,0,11||",
                "America_Eastern": "1uc,0,55j,0,ad,0,11|1uc,0,55j,0,g5,0,11||",
                "America_Mountain": "1uc,0,55k,0,ad,0,11|1uc,0,55k,0,g5,0,11||",
                "America_Pacific": "1uc,0,1vb,0,ad,0,11|1uc,0,1vb,0,g5,0,11||",
                "Apia": "44i,0,ad,0,11|44i,0,g5,0,11||",
                "Arabian": "55l,0,ad,0,11|55l,0,g5,0,11||",
                "Argentina": "55m,0,ad,0,11|55m,0,g5,0,11||",
                "Argentina_Western": "55n,0,ad,0,11|55n,0,g5,0,11||",
                "Armenia": "55o,0,ad,0,11|55o,0,g5,0,11||",
                "Atlantic": "55p,0,ad,0,11|55p,0,g5,0,11||",
                "Australia_Central": "55q,0,ad,0,11|55q,0,g5,0,11||",
                "Australia_CentralWestern": "atr,0,44h,0,ad,0,11|atn,0,44h,0,g5,0,11||",
                "Australia_Eastern": "55t,0,ad,0,11|55t,0,g5,0,11||",
                "Australia_Western": "55u,0,ad,0,11|55u,0,g5,0,11||",
                "Azerbaijan": "55v,0,ad,0,11|55v,0,g5,0,11||",
                "Azores": "560,0,ad,0,11|560,0,g5,0,11||",
                "Bangladesh": "561,0,ad,0,11|561,0,g5,0,11||",
                "Bhutan": "ati,0,11|||",
                "Bolivia": "ath,0,11|||",
                "Brasilia": "564,0,ad,0,11|564,0,g5,0,11||",
                "Brunei": "atg,0,11|||",
                "Cape_Verde": "566,0,ad,0,11|566,0,g5,0,11||",
                "Chamorro": "atf,0,11|||",
                "Chatham": "568,0,ad,0,11|568,0,g5,0,11||",
                "Chile": "569,0,ad,0,11|569,0,g5,0,11||",
                "China": "56a,0,ad,0,11|56a,0,g5,0,11||",
                "Choibalsan": "32p,0,ad,0,11|32p,0,g5,0,11||",
                "Christmas": "11,0,atd,0,19r|||",
                "Cocos": "11,0,7eq,0,10v|||",
                "Colombia": "56d,0,ad,0,11|56d,0,g5,0,11||",
                "Cook": "ad,0,11,0,445,0,10v|g5,0,11,0,445,0,10v||",
                "Cuba": "56e,0,ad,0,11|56e,0,g5,0,11||",
                "Davis": "atb,0,11|||",
                "DumontDUrville": "5b,0,at6,0,at5,0,11|||",
                "East_Timor": "at4,0,11|||",
                "Easter": "ad,0,11,0,56j,0,19r|g5,0,11,0,56j,0,19r||",
                "Ecuador": "at1,0,11|||",
                "Europe_Central": "56l,0,ad,0,11|56l,0,g5,0,11|1bd|1be",
                "Europe_Eastern": "56m,0,ad,0,11|56m,0,g5,0,11|1bg|1bh",
                "Europe_Further_Eastern": "7g5,0,11|||",
                "Europe_Western": "56n,0,ad,0,11|56n,0,g5,0,11|1bk|1bl",
                "Falkland": "56o,0,ad,0,11|56o,0,g5,0,11||",
                "Fiji": "56p,0,ad,0,11|56p,0,g5,0,11||",
                "French_Guiana": "at0,0,11|||",
                "French_Southern": "11,0,asv,0,asu,0,a6,0,7i9,0,asr|||",
                "Galapagos": "asq,0,11|||",
                "Gambier": "aso,0,11|||",
                "Georgia": "570,0,ad,0,11|570,0,g5,0,11||",
                "Gilbert_Islands": "11,0,7kc,0,10v|||",
                "GMT": "7kd,0,11|||",
                "Greenland_Eastern": "571,0,ad,0,11|571,0,g5,0,11||",
                "Greenland_Western": "572,0,ad,0,11|572,0,g5,0,11||",
                "Gulf": "11,0,asl,0,7m8|||",
                "Guyana": "ask,0,11|||",
                "Hawaii_Aleutian": "575,5,2eq,0,ad,0,11|575,5,2eq,0,g5,0,11||",
                "Hong_Kong": "43m,0,ad,0,11|43m,0,g5,0,11||",
                "Hovd": "43l,0,ad,0,11|43l,0,g5,0,11||",
                "India": "asj,0,11|||",
                "Indian_Ocean": "asi,0,11|||",
                "Indochina": "ash,0,11|||",
                "Indonesia_Central": "asg,0,11|||",
                "Indonesia_Eastern": "asf|||",
                "Indonesia_Western": "ase,0,11|||",
                "Iran": "57c,0,ad,0,11|57c,0,g5,0,11||",
                "Irkutsk": "1uo,0,ad,0,11|1uo,0,g5,0,11||",
                "Israel": "57d,0,ad,0,11|57d,0,g5,0,11||",
                "Japan": "57e,0,ad,0,11|57e,0,g5,0,11||",
                "Kazakhstan_Eastern": "asd,0,11|||",
                "Kazakhstan_Western": "asc,0,11|||",
                "Korea": "57h,0,ad,0,11|57h,0,g5,0,11||",
                "Kosrae": "asb,0,11|||",
                "Krasnoyarsk": "1un,0,ad,0,11|1un,0,g5,0,11||",
                "Kyrgystan": "asa,0,11|||",
                "Line_Islands": "11,0,7qh,0,10v|||",
                "Lord_Howe": "ad,0,11,0,19r,0,1u,5,24|g5,0,11,0,19r,0,1u,5,24||",
                "Macquarie": "11,0,19r,0,6m|||",
                "Magadan": "32t,0,ad,0,11|32t,0,g5,0,11||",
                "Malaysia": "as9,0,11|||",
                "Maldives": "as8,0,11|||",
                "Marquesas": "as7,0,11|||",
                "Marshall_Islands": "11,0,7r5,0,10v|||",
                "Mauritius": "57o,0,ad,0,11|57o,0,g5,0,11||",
                "Mawson": "57p,0,11|||",
                "Mexico_Northwest": "330,0,57r,0,ad,0,11|330,0,57r,0,g5,0,11||",
                "Mexico_Pacific": "330,0,1vb,0,ad,0,11|330,0,1vb,0,g5,0,11||",
                "Mongolia": "4f,5,41m,0,ad,0,11|4f,5,41m,0,g5,0,11||",
                "Moscow": "41h,0,ad,0,11|41h,0,g5,0,11||",
                "Myanmar": "as6,0,11|||",
                "Nauru": "as5,0,11|||",
                "Nepal": "as4,0,11|||",
                "New_Caledonia": "57v,0,ad,0,11|57v,0,g5,0,11||",
                "New_Zealand": "580,0,ad,0,11|580,0,g5,0,11||",
                "Newfoundland": "581,0,ad,0,11|581,0,g5,0,11||",
                "Niue": "as3,0,11|||",
                "Norfolk": "11,0,19r,0,6l|||",
                "Noronha": "ad,0,11,0,10v,0,1s,0,2,0,23|g5,0,11,0,10v,0,1s,0,2,0,23||",
                "Novosibirsk": "414,0,ad,0,11|414,0,g5,0,11||",
                "Omsk": "1ug,0,ad,0,11|1ug,0,g5,0,11||",
                "Pakistan": "583,0,ad,0,11|583,0,g5,0,11||",
                "Palau": "as2,0,11|||",
                "Papua_New_Guinea": "as1,5,arv,0,11|||",
                "Paraguay": "40k,0,ad,0,11|40k,0,g5,0,11||",
                "Peru": "587,0,ad,0,11|587,0,g5,0,11||",
                "Philippines": "588,0,ad,0,11|588,0,g5,0,11||",
                "Phoenix_Islands": "11,0,7su,0,10v|||",
                "Pierre_Miquelon": "ad,0,11,0,10v,0,7u,0,33,0,a6,0,35|g5,0,11,0,10v,0,7u,0,33,0,a6,0,35||",
                "Pitcairn": "11,0,7tn,0,10v|||",
                "Ponape": "aru,0,11|||",
                "Reunion": "art,0,11|||",
                "Rothera": "arr,0,11|||",
                "Sakhalin": "58c,0,ad,0,11|58c,0,g5,0,11||",
                "Samoa": "58d,0,ad,0,11|58d,0,g5,0,11||",
                "Seychelles": "arq,0,11|||",
                "Singapore": "4j2,0,11|||",
                "Solomon": "11,0,arp,0,10v|||",
                "South_Georgia": "aro,0,11|||",
                "Suriname": "arn,0,11|||",
                "Syowa": "arm,0,11|||",
                "Tahiti": "arl,0,11|||",
                "Taipei": "58k,0,ad,0,11|58k,0,g5,0,11||",
                "Tajikistan": "arh,0,11|||",
                "Tokelau": "arg,0,11|||",
                "Tonga": "58n,0,ad,0,11|58n,0,g5,0,11||",
                "Truk": "arf,0,11|||",
                "Turkmenistan": "58p,0,ad,0,11|58p,0,g5,0,11||",
                "Tuvalu": "ard,0,11|||",
                "Uruguay": "58r,0,ad,0,11|58r,0,g5,0,11||",
                "Uzbekistan": "58s,0,ad,0,11|58s,0,g5,0,11||",
                "Vanuatu": "58t,0,ad,0,11|58t,0,g5,0,11||",
                "Venezuela": "arb,0,11|||",
                "Vladivostok": "403,0,ad,0,11|403,0,g5,0,11||",
                "Volgograd": "401,0,ad,0,11|401,0,g5,0,11||",
                "Vostok": "ar5,0,11|||",
                "Wake": "11,0,19r,0,5r|||",
                "Wallis": "11,0,10v,0,5f,0,a6,0,5k|||",
                "Yakutsk": "1u5,0,ad,0,11|1u5,0,g5,0,11||",
                "Yekaterinburg": "3vt,0,ad,0,11|3vt,0,g5,0,11||"
            }
        },
        "hu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cb5,0,eb,0,7f||",
                "Europe/London": "|cb6,0,eb,0,7f||"
            },
            "metazone": {
                "Acre": "3q,0,147|3q,0,eb,0,7f||",
                "Afghanistan": "ar4,0,7f|||",
                "Africa_Central": "1qv,5,310,0,eu,0,7f|||",
                "Africa_Eastern": "1n3,5,310,0,eu,0,7f|||",
                "Africa_Southern": "ar3,5,310,0,eu,0,7f|||",
                "Africa_Western": "1a8,5,310,0,eu,0,7f|1a8,5,310,0,eb,0,7f||",
                "Alaska": "59a,0,147|59a,0,eb,0,7f||",
                "Almaty": "1nf,0,147|1nf,0,eb,0,7f||",
                "Amazon": "59c,0,eu,0,7f|59c,0,eb,0,7f||",
                "America_Central": "59d,0,33m,0,147|59d,0,33m,0,eb,0,7f||",
                "America_Eastern": "59f,0,33m,0,147|59f,0,33m,0,eb,0,7f||",
                "America_Mountain": "59g,0,147|59g,0,eb,0,7f||",
                "America_Pacific": "33n,5,2ae,0,147|33n,5,2ae,0,eb,0,7f||",
                "Anadyr": "59j,0,147|59j,0,eb,0,7f||",
                "Apia": "59k,0,eu,0,7f|59k,0,eb,0,7f||",
                "Aqtau": "59l,0,147|59l,0,eb,0,7f||",
                "Aqtobe": "59m,0,147|59m,0,eb,0,7f||",
                "Arabian": "59n,0,eu,0,7f|59n,0,eb,0,7f||",
                "Argentina": "4rv,0,eu,0,7f|ar1,0,eb,0,7f||",
                "Argentina_Western": "1a8,5,4rv,0,eu,0,7f|1a8,5,4rv,0,eb,0,7f||",
                "Armenia": "59q,0,eu,0,7f|59q,0,eb,0,7f||",
                "Atlantic": "59r,5,2ae,0,147|59r,5,2ae,0,eb,0,7f||",
                "Australia_Central": "1qv,5,1r2,0,eu,0,7f|1qv,5,1r2,0,eb,0,7f||",
                "Australia_CentralWestern": "1qv,5,1a8,5,1r2,0,eu,0,7f|1qv,5,1a8,5,1r2,0,eb,0,7f||",
                "Australia_Eastern": "1n3,5,1r2,0,eu,0,7f|1n3,5,1r2,0,eb,0,7f||",
                "Australia_Western": "1a8,5,1r2,0,eu,0,7f|1a8,5,1r2,0,eb,0,7f||",
                "Azerbaijan": "59t,0,eu,0,7f|59t,0,eb,0,7f||",
                "Azores": "59u,0,eu,0,7f|59u,0,eb,0,7f||",
                "Bangladesh": "59v,0,eu,0,7f|59v,0,eb,0,7f||",
                "Bhutan": "aqv,0,7f|||",
                "Bolivia": "aqu,0,eu,0,7f|||",
                "Brasilia": "5a2,0,eu,0,7f|5a2,0,eb,0,7f||",
                "Brunei": "9o,0,a4,5,i3,0,7f|||",
                "Cape_Verde": "5a3,5,5a4,5,140,0,eu,0,7f|5a3,5,5a4,5,140,0,eb,0,7f||",
                "Chamorro": "aqt,0,eu,0,7f|||",
                "Chatham": "5a7,0,eu,0,7f|5a7,0,eb,0,7f||",
                "Chile": "5a8,0,eu,0,7f|5a8,0,eb,0,7f||",
                "China": "5a9,0,eu,0,7f|5a9,0,eb,0,7f||",
                "Choibalsan": "5aa,0,eu,0,7f|5aa,0,eb,0,7f||",
                "Christmas": "aqs,5,1r6,0,eu,0,7f|||",
                "Cocos": "aqr,5,140,0,eu,0,7f|||",
                "Colombia": "5ae,0,eu,0,7f|5ae,0,eb,0,7f||",
                "Cook": "5af,5,140,0,eu,0,7f|5af,5,140,0,aqp,0,eb,0,7f||",
                "Cuba": "5ah,0,eu,0,7f|5ah,0,eb,0,7f||",
                "Davis": "aqo,0,7f|||",
                "DumontDUrville": "aqn,5,5j,5,i3,0,7f|||",
                "East_Timor": "1n3,5,aqm,0,eu,0,7f|||",
                "Easter": "5al,5,1r6,0,eu,0,7f|5al,5,1r6,0,eb,0,7f||",
                "Ecuador": "aqj,0,eu,0,7f|||",
                "Europe_Central": "1qv,5,2ki,0,eu,0,7f|1qv,5,2ki,0,eb,0,7f|h0|h4",
                "Europe_Eastern": "1n3,5,2ki,0,eu,0,7f|1n3,5,2ki,0,eb,0,7f|gu|gv",
                "Europe_Further_Eastern": "aqg,0,7f|||",
                "Europe_Western": "1a8,5,2ki,0,eu,0,7f|1a8,5,2ki,0,eb,0,7f|h1|h2",
                "Falkland": "5ap,5,140,0,eu,0,7f|5ap,5,140,0,eb,0,7f||",
                "Fiji": "5aq,0,eu,0,7f|5aq,0,eb,0,7f||",
                "French_Guiana": "5ar,5,aqe,0,7f|||",
                "French_Southern": "5ar,0,5at,0,340,0,aqd,0,7f|||",
                "Galapagos": "aqc,0,eu,0,7f|||",
                "Gambier": "aqb,0,7f|||",
                "Georgia": "5b2,0,eu,0,7f|5b2,0,eb,0,7f||",
                "Gilbert_Islands": "aqa,5,140,0,7f|||",
                "GMT": "aq9,0,aq6,k,0,eu,0,7f||ah|",
                "Greenland_Eastern": "1n3,5,342,0,eu,0,7f|1n3,5,342,0,eb,0,7f||",
                "Greenland_Western": "1a8,5,342,0,eu,0,7f|1a8,5,342,0,eb,0,7f||",
                "Guam": "5s2,0,147|||",
                "Gulf": "aq5,0,eu,0,7f|||",
                "Guyana": "aq4,0,eu,0,7f|||",
                "Hawaii_Aleutian": "5b9,5,5ba,0,eu,0,7f|5b9,5,5ba,0,eb,0,7f||",
                "Hong_Kong": "5bb,0,eu,0,7f|5bb,0,eb,0,7f||",
                "Hovd": "5bc,0,eu,0,7f|5bc,0,eb,0,7f||",
                "India": "5bd,0,eu,0,7f|||",
                "Indian_Ocean": "5bd,5,2ae,0,7f|||",
                "Indochina": "aq0,0,7f|||",
                "Indonesia_Central": "1qv,5,4rs,0,7f|||",
                "Indonesia_Eastern": "1n3,5,4rs,0,7f|||",
                "Indonesia_Western": "1a8,5,4rs,0,eu,0,7f|||",
                "Iran": "5bg,0,eu,0,7f|5bg,0,eb,0,7f||",
                "Irkutsk": "5bh,0,eu,0,7f|5bh,0,eb,0,7f||",
                "Israel": "5bi,0,eu,0,7f|5bi,0,eb,0,7f||",
                "Japan": "5bj,0,eu,0,7f|5bj,0,eb,0,7f||",
                "Kamchatka": "5bk,5,5bl,0,147|5bk,5,5bl,0,eb,0,7f||",
                "Kazakhstan_Eastern": "1n3,5,5bm,0,7f|||",
                "Kazakhstan_Western": "1a8,5,5bm,0,7f|||",
                "Korea": "5bn,0,eu,0,7f|5bn,0,eb,0,7f||",
                "Kosrae": "aps,0,7f|||",
                "Krasnoyarsk": "5bp,0,eu,0,7f|5bp,0,eb,0,7f||",
                "Kyrgystan": "apq,0,7f|||",
                "Lanka": "app,0,7f|||",
                "Line_Islands": "apn,5,140,0,7f|||",
                "Lord_Howe": "1u,0,24,5,1r6,0,eu,0,7f|1u,0,24,5,1r6,0,eb,0,7f||",
                "Macau": "5bt,0,147|5bt,0,eb,0,7f||",
                "Macquarie": "apm,5,1r6,0,eu,0,7f|||",
                "Magadan": "aph,0,eu,0,7f|apg,0,eb,0,7f||",
                "Malaysia": "ape,0,7f|||",
                "Maldives": "apd,5,140,0,7f|||",
                "Marquesas": "apc,5,140,0,7f|||",
                "Marshall_Islands": "apb,5,140,0,7f|||",
                "Mauritius": "5c5,0,eu,0,7f|5c5,0,eb,0,7f||",
                "Mawson": "apa,0,7f|||",
                "Mexico_Northwest": "5c7,5,34b,0,147|5c7,5,34b,0,eb,0,7f||",
                "Mexico_Pacific": "34b,0,33n,5,2ae,0,147|34b,0,33n,5,2ae,0,eb,0,7f||",
                "Mongolia": "5c9,0,eu,0,7f|5c9,0,eb,0,7f||",
                "Moscow": "5ca,0,eu,0,7f|5ca,0,eb,0,7f||",
                "Myanmar": "ap8,0,7f|||",
                "Nauru": "ap6,0,7f|||",
                "Nepal": "ap5,0,7f|||",
                "New_Caledonia": "2ab,5,5cf,0,eu,0,7f|2ab,5,5cf,0,eb,0,7f||",
                "New_Zealand": "2ab,5,5cg,0,eu,0,7f|2ab,5,5cg,0,eb,0,7f||",
                "Newfoundland": "2ab,5,5ch,0,147|2ab,5,5ch,0,eb,0,7f||",
                "Niue": "ap4,0,7f|||",
                "Norfolk": "ap3,5,140,0,7f|||",
                "Noronha": "1s,0,2,0,23,5,i3,0,eu,0,7f|1s,0,2,0,23,5,i3,0,eb,0,7f||",
                "North_Mariana": "ap2,5,ap1,5,140,0,7f|||",
                "Novosibirsk": "5cm,0,eu,0,7f|5cm,0,eb,0,7f||",
                "Omsk": "5cn,0,eu,0,7f|5cn,0,eb,0,7f||",
                "Pakistan": "5co,0,eu,0,7f|5co,0,eb,0,7f||",
                "Palau": "ap0,0,7f|||",
                "Papua_New_Guinea": "aov,0,2ab,5,aou,0,7f|||",
                "Paraguay": "5cs,0,eu,0,7f|5cs,0,eb,0,7f||",
                "Peru": "5ct,0,eu,0,7f|5ct,0,eb,0,7f||",
                "Philippines": "5cu,5,140,0,eu,0,7f|5cu,5,140,0,eb,0,7f||",
                "Phoenix_Islands": "aot,5,140,0,eu,0,7f|||",
                "Pierre_Miquelon": "8i,0,33,0,340,0,35,5,i3,0,147|8i,0,33,0,340,0,35,5,i3,0,eb,0,7f||",
                "Pitcairn": "aos,5,140,0,7f|||",
                "Ponape": "aor,5,1r6,0,7f|||",
                "Pyongyang": "aoq,0,7f|||",
                "Qyzylorda": "5d3,0,147|5d3,0,eb,0,7f||",
                "Reunion": "aop,0,7f|||",
                "Rothera": "aoo,0,7f|||",
                "Sakhalin": "5d6,0,eu,0,7f|5d6,0,eb,0,7f||",
                "Samara": "5d7,0,147|5d7,0,eb,0,7f||",
                "Samoa": "5d8,0,eu,0,7f|5d8,0,eb,0,7f||",
                "Seychelles": "aon,5,140,0,7f|||",
                "Singapore": "aom,0,eu,0,7f|||",
                "Solomon": "aol,5,140,0,7f|||",
                "South_Georgia": "5at,5,aok,0,7f|||",
                "Suriname": "aoj,0,7f|||",
                "Syowa": "aoi,0,7f|||",
                "Tahiti": "aoh,0,7f|||",
                "Taipei": "5dg,0,eu,0,7f|5dg,0,eb,0,7f||",
                "Tajikistan": "aog,0,7f|||",
                "Tokelau": "aku,0,7f|||",
                "Tonga": "5dj,0,eu,0,7f|5dj,0,eb,0,7f||",
                "Truk": "ak9,0,7f|||",
                "Turkmenistan": "5dl,0,eu,0,7f|5dl,0,eb,0,7f||",
                "Tuvalu": "ago,0,7f|||",
                "Uruguay": "5dn,0,eu,0,7f|5dn,0,eb,0,7f||",
                "Uzbekistan": "5do,0,eu,0,7f|5do,0,eb,0,7f||",
                "Vanuatu": "5dp,0,eu,0,7f|5dp,0,eb,0,7f||",
                "Venezuela": "agn,0,7f|||",
                "Vladivostok": "5dr,0,eu,0,7f|5dr,0,eb,0,7f||",
                "Volgograd": "5ds,0,eu,0,7f|5ds,0,eb,0,7f||",
                "Vostok": "agi,0,7f|||",
                "Wake": "agh,5,1r6,0,7f|||",
                "Wallis": "5f,0,340,0,5k,5,i3,0,7f|||",
                "Yakutsk": "agf,0,eu,0,7f|age,0,eb,0,7f||",
                "Yekaterinburg": "agd,0,eu,0,7f|agb,0,eb,0,7f||"
            }
        },
        "hy": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cb7,0,ge,0,7j||",
                "Europe/London": "|cb8,0,gk,0,7j||"
            },
            "metazone": {
                "Afghanistan": "ag9,0,7j|||",
                "Africa_Central": "1nr,0,2vq,0,7j|||",
                "Africa_Eastern": "1gk,0,2vq,0,7j|||",
                "Africa_Southern": "5e8,0,2vq,0,7j|||",
                "Africa_Western": "1fu,0,2vq,0,ge,0,7j|1fu,0,2vq,0,gk,0,7j||",
                "Alaska": "5ec,0,ge,0,7j|5ec,0,gk,0,7j||",
                "Amazon": "5ed,0,ge,0,7j|5ed,0,gk,0,7j||",
                "America_Central": "1nr,0,352,0,ge,0,7j|1nr,0,352,0,gk,0,7j||",
                "America_Eastern": "1gk,0,352,0,ge,0,7j|1gk,0,352,0,gk,0,7j||",
                "America_Mountain": "5ef,0,ge,0,7j,0,5eg|5ef,0,gk,0,7j,0,5eg||",
                "America_Pacific": "5eh,0,ge,0,7j|5eh,0,gk,0,7j||",
                "Apia": "5ei,0,ge,0,7j|5ei,0,gk,0,7j||",
                "Arabian": "5ej,0,5ek,0,ge,0,7j|5ej,0,5ek,0,gk,0,7j||",
                "Argentina": "353,0,5em,0,7j|353,0,gk,0,7j||",
                "Argentina_Western": "1fu,0,353,0,5em,0,7j|1fu,0,353,0,gk,0,7j||",
                "Armenia": "5en,0,ge,0,7j|5en,0,gk,0,7j||",
                "Atlantic": "5eo,0,ge,0,7j|5eo,0,gk,0,7j||",
                "Australia_Central": "1nr,0,1rm,0,ge,0,7j|1nr,0,1rm,0,gk,0,7j||",
                "Australia_CentralWestern": "1nr,0,1rm,0,5eq,0,ge,0,7j|1nr,0,1rm,0,5eq,0,gk,0,7j||",
                "Australia_Eastern": "1gk,0,1rm,0,ge,0,7j|1gk,0,1rm,0,gk,0,7j||",
                "Australia_Western": "1fu,0,1rm,0,ge,0,7j|1fu,0,1rm,0,gk,0,7j||",
                "Azerbaijan": "5er,0,ge,0,7j|5er,0,gk,0,7j||",
                "Azores": "5es,0,16r,0,ge,0,7j|5es,0,16r,0,gk,0,7j||",
                "Bangladesh": "5eu,0,ge,0,7j|5eu,0,gk,0,7j||",
                "Bhutan": "ag5,0,7j|||",
                "Bolivia": "afu,0,7j|||",
                "Brasilia": "5f1,0,ge,0,7j|5f1,0,gk,0,7j||",
                "Brunei": "aft,0,7j|||",
                "Cape_Verde": "5f3,0,5f4,0,ge,0,7j|5f3,0,5f4,0,gk,0,7j||",
                "Chamorro": "afs,0,7j|||",
                "Chatham": "5f6,0,1pk,0,ge,0,7j|5f6,0,1pk,0,gk,0,7j||",
                "Chile": "5f8,0,ge,0,7j|5f8,0,gk,0,7j||",
                "China": "5f9,0,ge,0,7j|5f9,0,gk,0,7j||",
                "Choibalsan": "5fa,0,ge,0,7j|5fa,0,gk,0,7j||",
                "Christmas": "afp,0,afk,0,1pk,0,7j|||",
                "Cocos": "afi,0,16r,0,7j|||",
                "Colombia": "5fe,0,ge,0,7j|5fe,0,gk,0,7j||",
                "Cook": "5ff,0,16r,0,ge,0,7j|5ff,0,16r,0,afg,0,7j||",
                "Cuba": "5fh,0,ge,0,7j|5fh,0,gk,0,7j||",
                "Davis": "aff,0,7j|||",
                "DumontDUrville": "afe,0,af8,0,7j|||",
                "East_Timor": "1gk,0,af3,0,7j|||",
                "Easter": "5fm,0,1pk,0,ge,0,7j|5fm,0,1pk,0,gk,0,7j||",
                "Ecuador": "af2,0,7j|||",
                "Europe_Central": "1nr,0,2jd,0,ge,0,7j|1nr,0,2jd,0,gk,0,7j||",
                "Europe_Eastern": "1gk,0,2jd,0,ge,0,7j|1gk,0,2jd,0,gk,0,7j||",
                "Europe_Further_Eastern": "af1,0,7j|||",
                "Europe_Western": "1fu,0,2jd,0,ge,0,7j|1fu,0,2jd,0,gk,0,7j||",
                "Falkland": "5fq,0,16r,0,ge,0,7j|5fq,0,16r,0,gk,0,7j||",
                "Fiji": "5fr,0,ge,0,7j|5fr,0,gk,0,7j||",
                "French_Guiana": "5fs,0,aeu,0,7j|||",
                "French_Southern": "5fs,0,aet,0,358,0,aes,0,7j|||",
                "Galapagos": "aer,0,16r,0,7j|||",
                "Gambier": "aeq,0,16r,0,7j|||",
                "Georgia": "5g3,0,ge,0,7j|5g3,0,gk,0,7j||",
                "Gilbert_Islands": "aem,0,16r,0,7j|||",
                "GMT": "ael,0,7j|||",
                "Greenland_Eastern": "1gk,0,35a,0,ge,0,7j|1gk,0,35a,0,gk,0,7j||",
                "Greenland_Western": "1fu,0,35a,0,ge,0,7j|1fu,0,35a,0,gk,0,7j||",
                "Gulf": "aek,0,aej,0,ge,0,7j|||",
                "Guyana": "aei,0,7j|||",
                "Hawaii_Aleutian": "5ga,5,5gb,0,ge,0,7j|5ga,5,5gb,0,gk,0,7j||",
                "Hong_Kong": "5gc,0,ge,0,7j|5gc,0,gk,0,7j||",
                "Hovd": "5gd,0,ge,0,7j|5gd,0,gk,0,7j||",
                "India": "aeh,0,ge,0,7j|||",
                "Indian_Ocean": "aef,0,aea,0,7j|||",
                "Indochina": "ae8,0,7j|||",
                "Indonesia_Central": "1nr,0,4ro,0,7j|||",
                "Indonesia_Eastern": "1gk,0,4ro,0,7j|||",
                "Indonesia_Western": "1fu,0,4ro,0,7j|||",
                "Iran": "5gj,0,ge,0,7j|5gj,0,gk,0,7j||",
                "Irkutsk": "5gk,0,ge,0,7j|5gk,0,gk,0,7j||",
                "Israel": "5gl,0,ge,0,7j|5gl,0,gk,0,7j||",
                "Japan": "5gm,0,ge,0,7j|5gm,0,gk,0,7j||",
                "Kazakhstan_Eastern": "1gk,0,5gn,0,7j|||",
                "Kazakhstan_Western": "1fu,0,5gn,0,7j|||",
                "Korea": "5go,0,ge,0,7j|5go,0,gk,0,7j||",
                "Kosrae": "ae7,0,7j|||",
                "Krasnoyarsk": "5gq,0,ge,0,7j|5gq,0,gk,0,7j||",
                "Kyrgystan": "ae6,0,7j|||",
                "Line_Islands": "ae2,0,16r,0,7j|||",
                "Lord_Howe": "5gt,0,5gu,0,ge,0,7j|5gt,0,5gu,0,gk,0,7j||",
                "Macquarie": "ae0,0,1pk,0,7j|||",
                "Magadan": "5h0,0,ge,0,7j|5h0,0,gk,0,7j||",
                "Malaysia": "adt,0,7j|||",
                "Maldives": "ads,0,7j|||",
                "Marquesas": "adr,0,16r,0,7j|||",
                "Marshall_Islands": "adq,0,16r,0,7j|||",
                "Mauritius": "5h5,0,ge,0,7j|5h5,0,gk,0,7j||",
                "Mawson": "adp,0,7j|||",
                "Mexico_Northwest": "5h7,0,35c,0,ge,0,7j|5h7,0,35c,0,gk,0,7j||",
                "Mexico_Pacific": "35c,0,5h9,0,ge,0,7j|35c,0,5h9,0,gk,0,7j||",
                "Mongolia": "5ha,0,5hb,0,ge,0,7j|5ha,0,5hb,0,gk,0,7j||",
                "Moscow": "5hc,0,ge,0,7j|5hc,0,gk,0,7j||",
                "Myanmar": "ado,0,7j|||",
                "Nauru": "adn,0,7j|||",
                "Nepal": "adh,0,7j|||",
                "New_Caledonia": "2v8,0,5hh,0,ge,0,7j|2v8,0,5hh,0,gk,0,7j||",
                "New_Zealand": "2v8,0,5hi,0,ge,0,7j|2v8,0,5hi,0,gk,0,7j||",
                "Newfoundland": "5hj,0,ge,0,7j|5hj,0,gk,0,7j||",
                "Niue": "adg,0,7j|||",
                "Norfolk": "adf,0,1pk,0,7j|||",
                "Noronha": "5hm,0,5hn,0,5ho,0,ge,0,7j|5hm,0,5hn,0,5ho,0,gk,0,7j||",
                "Novosibirsk": "5hp,0,ge,0,7j|5hp,0,gk,0,7j||",
                "Omsk": "5hq,0,ge,0,7j|5hq,0,gk,0,7j||",
                "Pakistan": "5hr,0,ge,0,7j|5hr,0,gk,0,7j||",
                "Palau": "ade,0,7j|||",
                "Papua_New_Guinea": "adb,0,2v8,0,ad7,0,7j|||",
                "Paraguay": "5hv,0,ge,0,7j|5hv,0,gk,0,7j||",
                "Peru": "5i0,0,ge,0,7j|5i0,0,gk,0,7j||",
                "Philippines": "5i1,0,ge,0,7j|5i1,0,gk,0,7j||",
                "Phoenix_Islands": "ad3,0,16r,0,7j|||",
                "Pierre_Miquelon": "5i3,0,5i4,0,358,0,5i5,0,ge,0,7j|5i3,0,5i4,0,358,0,5i5,0,gk,0,7j||",
                "Pitcairn": "ad2,0,7j|||",
                "Ponape": "ad1,0,1pk,0,7j|||",
                "Pyongyang": "ad0,0,7j|||",
                "Reunion": "acv,0,7j|||",
                "Rothera": "act,0,7j|||",
                "Sakhalin": "5ib,0,ge,0,7j|5ib,0,gk,0,7j||",
                "Samoa": "5ic,0,ge,0,7j|5ic,0,gk,0,7j||",
                "Seychelles": "acr,0,16r,0,7j|||",
                "Singapore": "acm,0,7j|||",
                "Solomon": "ack,0,16r,0,7j|||",
                "South_Georgia": "5e8,0,acj,0,7j|||",
                "Suriname": "aci,0,7j|||",
                "Syowa": "acg,0,7j|||",
                "Tahiti": "ace,0,7j|||",
                "Taipei": "5ik,0,ge,0,7j|5ik,0,gk,0,7j||",
                "Tajikistan": "acd,0,7j|||",
                "Tokelau": "acc,0,7j|||",
                "Tonga": "5in,0,ge,0,7j|5in,0,gk,0,7j||",
                "Truk": "acb,0,7j|||",
                "Turkmenistan": "5ip,0,ge,0,7j|5ip,0,gk,0,7j||",
                "Tuvalu": "ac9,0,7j|||",
                "Uruguay": "5ir,0,ge,0,7j|5ir,0,gk,0,7j||",
                "Uzbekistan": "5is,0,ge,0,7j|5is,0,gk,0,7j||",
                "Vanuatu": "5it,0,ge,0,7j|5it,0,gk,0,7j||",
                "Venezuela": "ac8,0,7j|||",
                "Vladivostok": "5iv,0,ge,0,7j|5iv,0,gk,0,7j||",
                "Volgograd": "5j0,0,ge,0,7j|5j0,0,gk,0,7j||",
                "Vostok": "ac5,0,7j|||",
                "Wake": "ac2,0,1pk,0,7j|||",
                "Wallis": "ac1,0,358,0,ac0,0,7j|||",
                "Yakutsk": "5j5,0,ge,0,7j|5j5,0,gk,0,7j||",
                "Yekaterinburg": "5j6,0,ge,0,7j|5j6,0,gk,0,7j||"
            }
        },
        "id": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|u,0,e6,0,66f||",
                "Europe/London": "|u,0,5i,0,5h,0,cba||"
            },
            "metazone": {
                "Acre": "u,0,e6,0,3q|u,0,5i,0,5h,0,3q||",
                "Afghanistan": "u,0,2f0|||",
                "Africa_Central": "u,0,c5,0,pl|||",
                "Africa_Eastern": "u,0,c5,0,mt|||",
                "Africa_Southern": "u,0,e6,0,c5,0,1jj|||",
                "Africa_Western": "u,0,e6,0,c5,0,ht|u,0,5i,0,5h,0,c5,0,ht||",
                "Alaska": "u,0,e6,0,3p|u,0,5i,0,5h,0,3p||",
                "Almaty": "u,0,e6,0,56|u,0,5i,0,5h,0,56||",
                "Amazon": "u,0,e6,0,5m|u,0,5i,0,5h,0,5m||",
                "America_Central": "u,0,e6,0,pl|u,0,5i,0,5h,0,pl||",
                "America_Eastern": "u,0,e6,0,mt|u,0,5i,0,5h,0,mt||",
                "America_Mountain": "u,0,e6,0,5jf|u,0,5i,0,5h,0,5jf||",
                "America_Pacific": "u,0,e6,0,10c|u,0,5i,0,5h,0,10c||",
                "Anadyr": "u,0,e6,0,3l|u,0,5i,0,5h,0,3l||",
                "Apia": "u,0,e6,0,3e|u,0,5i,0,5h,0,3e||",
                "Aqtau": "u,0,e6,0,50|u,0,5i,0,5h,0,50||",
                "Aqtobe": "u,0,e6,0,5o|u,0,5i,0,5h,0,5o||",
                "Arabian": "u,0,e6,0,1qe|u,0,5i,0,5h,0,1qe||",
                "Argentina": "u,0,e6,0,1e|u,0,5i,0,5h,0,1e||",
                "Argentina_Western": "u,0,e6,0,1e,0,5jg,0,ht|u,0,5i,0,5h,0,1e,0,5jg,0,ht||",
                "Armenia": "u,0,e6,0,4l|u,0,5i,0,5h,0,4l||",
                "Atlantic": "u,0,e6,0,r2|u,0,5i,0,5h,0,r2||",
                "Australia_Central": "u,0,e6,0,pl,0,4c|u,0,5i,0,5h,0,pl,0,4c||",
                "Australia_CentralWestern": "u,0,e6,0,ht,0,pl,0,4c|u,0,5i,0,5h,0,ht,0,pl,0,4c||",
                "Australia_Eastern": "u,0,e6,0,mt,0,4c|u,0,5i,0,5h,0,mt,0,4c||",
                "Australia_Western": "u,0,e6,0,ht,0,4c|u,0,5i,0,5h,0,ht,0,4c||",
                "Azerbaijan": "u,0,e6,0,7m|u,0,5i,0,5h,0,7m||",
                "Azores": "u,0,e6,0,4r|u,0,5i,0,5h,0,4r||",
                "Bangladesh": "u,0,e6,0,3n|u,0,5i,0,5h,0,3n||",
                "Bhutan": "u,0,as|||",
                "Bolivia": "u,0,aa|||",
                "Brasilia": "u,0,e6,0,5jh|u,0,5i,0,5h,0,5jh||",
                "Brunei": "u,0,9o,0,a4|||",
                "Cape_Verde": "u,0,e6,0,1rs,0,3j|u,0,5i,0,5h,0,1rs,0,3j||",
                "Casey": "u,0,b7|||",
                "Chamorro": "u,0,e6,0,6j|||",
                "Chatham": "u,0,e6,0,27|u,0,5i,0,5h,0,27||",
                "Chile": "u,0,e6,0,1rt|u,0,5i,0,5h,0,1rt||",
                "China": "u,0,e6,0,5jk|u,0,5i,0,5h,0,5jk||",
                "Choibalsan": "u,0,e6,0,2q|u,0,5i,0,5h,0,2q||",
                "Christmas": "u,0,15f,0,1jn|||",
                "Cocos": "u,0,oo,0,7v|||",
                "Colombia": "u,0,e6,0,20u|u,0,5i,0,5h,0,20u||",
                "Cook": "u,0,e6,0,2if,0,2v|u,0,pl,0,5i,0,5h,0,2if,0,2v||",
                "Cuba": "u,0,e6,0,tb|u,0,5i,0,5h,0,tb||",
                "Davis": "u,0,5s|||",
                "DumontDUrville": "u,0,5b,5,5j|||",
                "East_Timor": "u,0,7n,0,vk|||",
                "Easter": "u,0,e6,0,15f,0,5jp|u,0,5i,0,5h,0,15f,0,5jp||",
                "Ecuador": "u,0,abv|||",
                "Europe_Central": "u,0,e6,0,29r,0,pl|u,0,5i,0,5h,0,29r,0,pl||",
                "Europe_Eastern": "u,0,e6,0,29r,0,mt|u,0,5i,0,5h,0,29r,0,mt||",
                "Europe_Further_Eastern": "u,0,29r,0,mt,0,abu|||",
                "Europe_Western": "u,0,e6,0,29r,0,ht|u,0,5i,0,5h,0,29r,0,ht||",
                "Falkland": "u,0,e6,0,oo,0,4v|u,0,5i,0,5h,0,oo,0,4v||",
                "Fiji": "u,0,e6,0,4q|u,0,5i,0,5h,0,4q||",
                "French_Guiana": "u,0,7a,0,5jt|||",
                "French_Southern": "u,0,abt,0,1jj,0,17t,0,27v,0,5jt|||",
                "Galapagos": "u,0,ak|||",
                "Gambier": "u,0,6v|||",
                "Georgia": "u,0,e6,0,2u|u,0,5i,0,5h,0,2u||",
                "Gilbert_Islands": "u,0,2if,0,6q|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "u,0,e6,0,2k,0,mt|u,0,5i,0,5h,0,2k,0,mt||",
                "Greenland_Western": "u,0,e6,0,2k,0,ht|u,0,5i,0,5h,0,2k,0,ht||",
                "Guam": "u,0,83|||",
                "Gulf": "u,0,e6,0,367|||",
                "Guyana": "u,0,7a|||",
                "Hawaii_Aleutian": "u,0,e6,0,4g,5,62|u,0,5i,0,5h,0,4g,5,62||",
                "Hong_Kong": "u,0,e6,0,2l,0,2p|u,0,5i,0,5h,0,2l,0,2p||",
                "Hovd": "u,0,e6,0,2g|u,0,5i,0,5h,0,2g||",
                "India": "u,0,af|||",
                "Indian_Ocean": "u,0,abs,0,abq|||",
                "Indochina": "u,0,9p|||",
                "Indonesia_Central": "u,0,2t,0,pl||abp|",
                "Indonesia_Eastern": "u,0,2t,0,mt||abo|",
                "Indonesia_Western": "u,0,2t,0,ht||abn|",
                "Iran": "u,0,e6,0,5g|u,0,5i,0,5h,0,5g||",
                "Irkutsk": "u,0,e6,0,3h|u,0,5i,0,5h,0,3h||",
                "Israel": "u,0,e6,0,4e|u,0,5i,0,5h,0,4e||",
                "Japan": "u,0,e6,0,5k6|u,0,5i,0,5h,0,5k6||",
                "Kamchatka": "u,0,e6,0,37,5,4q6|u,0,5i,0,5h,0,37,5,3k||",
                "Kazakhstan_Eastern": "u,0,45,0,mt|||",
                "Kazakhstan_Western": "u,0,45,0,ht|||",
                "Korea": "u,0,e6,0,qf|u,0,5i,0,5h,0,qf||",
                "Kosrae": "u,0,6f|||",
                "Krasnoyarsk": "u,0,e6,0,41|u,0,5i,0,5h,0,41||",
                "Kyrgystan": "u,0,abm|||",
                "Lanka": "u,0,9c|||",
                "Line_Islands": "u,0,2if,0,ac|||",
                "Lord_Howe": "u,0,e6,0,1u,0,24|u,0,5i,0,5h,0,1u,0,24||",
                "Macau": "u,0,e6,0,36a|u,0,5i,0,5h,0,36a||",
                "Macquarie": "u,0,oo,0,6m|||",
                "Magadan": "u,0,e6,0,2o|u,0,5i,0,5h,0,2o||",
                "Malaysia": "u,0,bf|||",
                "Maldives": "u,0,abl|||",
                "Marquesas": "u,0,9e|||",
                "Marshall_Islands": "u,0,2if,0,7o|||",
                "Mauritius": "u,0,e6,0,4u|u,0,5i,0,5h,0,4u||",
                "Mawson": "u,0,63|||",
                "Mexico_Northwest": "u,0,e6,0,17k,0,ht,0,1se|u,0,5i,0,5h,0,17k,0,ht,0,1se||",
                "Mexico_Pacific": "u,0,e6,0,10c,0,17k|u,0,5i,0,5h,0,10c,0,17k||",
                "Mongolia": "u,0,e6,0,4f,0,2m|u,0,5i,0,5h,0,4f,0,2m||",
                "Moscow": "u,0,e6,0,36c|u,0,5i,0,5h,0,36c||",
                "Myanmar": "u,0,8v|||",
                "Nauru": "u,0,5v|||",
                "Nepal": "u,0,8u|||",
                "New_Caledonia": "u,0,e6,0,215,0,36d|u,0,5i,0,5h,0,215,0,36d||",
                "New_Zealand": "u,0,e6,0,5kd,0,36d|u,0,5i,0,5h,0,5kd,0,36d||",
                "Newfoundland": "u,0,e6,0,53|u,0,5i,0,5h,0,53||",
                "Niue": "u,0,8g|||",
                "Norfolk": "u,0,oo,0,6l|||",
                "Noronha": "u,0,e6,0,1s,0,2,0,23|u,0,5i,0,5h,0,1s,0,2,0,23||",
                "North_Mariana": "u,0,2if,0,bd,0,abk|||",
                "Novosibirsk": "u,0,e6,0,3b|u,0,5i,0,5h,0,3b||",
                "Omsk": "u,0,e6,0,26|u,0,5i,0,5h,0,26||",
                "Pakistan": "u,0,e6,0,3i|u,0,5i,0,5h,0,3i||",
                "Palau": "u,0,9b|||",
                "Papua_New_Guinea": "u,0,9a,0,abj|||",
                "Paraguay": "u,0,e6,0,3c|u,0,5i,0,5h,0,3c||",
                "Peru": "u,0,e6,0,4n|u,0,5i,0,5h,0,4n||",
                "Philippines": "u,0,e6,0,1sg|u,0,5i,0,5h,0,1sg||",
                "Phoenix_Islands": "u,0,oo,0,90|||",
                "Pierre_Miquelon": "u,0,e6,0,8i,0,33,0,17t,0,35|u,0,5i,0,5h,0,8i,0,33,0,17t,0,35||",
                "Pitcairn": "u,0,7e|||",
                "Ponape": "u,0,9s|||",
                "Pyongyang": "u,0,8a|||",
                "Qyzylorda": "u,0,e6,0,4d|u,0,5i,0,5h,0,4d||",
                "Reunion": "u,0,b4|||",
                "Rothera": "u,0,5q|||",
                "Sakhalin": "u,0,e6,0,55|u,0,5i,0,5h,0,55||",
                "Samara": "u,0,e6,0,29|u,0,5i,0,5h,0,29||",
                "Samoa": "u,0,e6,0,2c|u,0,5i,0,5h,0,2c||",
                "Seychelles": "u,0,8k|||",
                "Singapore": "u,0,e6,0,196|||",
                "Solomon": "u,0,oo,0,b5|||",
                "South_Georgia": "u,0,2u,0,1jj|||",
                "Suriname": "u,0,8q|||",
                "Syowa": "u,0,65|||",
                "Tahiti": "u,0,8n|||",
                "Taipei": "u,0,e6,0,3d|u,0,5i,0,5h,0,3d||",
                "Tajikistan": "u,0,bj|||",
                "Tokelau": "u,0,5d|||",
                "Tonga": "u,0,e6,0,2j|u,0,5i,0,5h,0,2j||",
                "Truk": "u,0,6p|||",
                "Turkmenistan": "u,0,e6,0,4t|u,0,5i,0,5h,0,4t||",
                "Tuvalu": "u,0,6h|||",
                "Uruguay": "u,0,e6,0,4k|u,0,5i,0,5h,0,4k||",
                "Uzbekistan": "u,0,e6,0,60|u,0,5i,0,5h,0,60||",
                "Vanuatu": "u,0,e6,0,2f|u,0,5i,0,5h,0,2f||",
                "Venezuela": "u,0,7s|||",
                "Vladivostok": "u,0,e6,0,28|u,0,5i,0,5h,0,28||",
                "Volgograd": "u,0,e6,0,3f|u,0,5i,0,5h,0,3f||",
                "Vostok": "u,0,5t|||",
                "Wake": "u,0,oo,0,5r|||",
                "Wallis": "u,0,5f,0,17t,0,5k|||",
                "Yakutsk": "u,0,e6,0,3u|u,0,5i,0,5h,0,3u||",
                "Yekaterinburg": "u,0,e6,0,5l|u,0,5i,0,5h,0,5l||"
            }
        },
        "ig": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ii": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "is": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|fr,0,is,0,cbb||",
                "Europe/London": "|fr,0,be,0,cbc||"
            },
            "metazone": {
                "Afghanistan": "abi|||",
                "Africa_Central": "25s,5,4rc|||",
                "Africa_Eastern": "1pn,5,4rc|||",
                "Africa_Southern": "5kl,5,4rc|||",
                "Africa_Western": "fo,0,be,0,11j,5,5ko|fr,0,be,0,11j,5,5ko||",
                "Alaska": "fo,0,be,0,3p|fr,0,be,0,3p||",
                "Amazon": "fo,0,is,0,5kr|fr,0,is,0,5kr||",
                "America_Central": "fo,0,be,0,5ks,0,36n,0,u0,0,36o|fr,0,be,0,5ks,0,36n,0,u0,0,36o||",
                "America_Eastern": "fo,0,be,0,5kv,0,36n,0,u0,0,36o|fr,0,be,0,5kv,0,36n,0,u0,0,36o||",
                "America_Mountain": "fo,0,be,0,5l0|fr,0,be,0,5l0||",
                "America_Pacific": "fo,0,is,0,36p|fr,0,is,0,36p||",
                "Anadyr": "fo,0,be,0,3l|fr,0,be,0,3l||",
                "Apia": "fo,0,be,0,5l2|fr,0,be,0,5l2||",
                "Arabian": "fo,0,be,0,5l3|fr,0,be,0,5l3||",
                "Argentina": "fo,0,be,0,36q|fr,0,be,0,36q||",
                "Argentina_Western": "fo,0,be,0,11j,5,36q|fr,0,be,0,11j,5,36q||",
                "Armenia": "fo,0,be,0,5l5|fr,0,be,0,5l5||",
                "Atlantic": "fo,0,is,0,5l6|fr,0,is,0,5l6||",
                "Australia_Central": "fo,0,be,0,25s,5,1sm|fr,0,be,0,25s,5,1sm||",
                "Australia_CentralWestern": "fo,0,be,0,5l8,0,1sm|fr,0,be,0,5l8,0,1sm||",
                "Australia_Eastern": "fo,0,be,0,1pn,5,1sm|fr,0,be,0,1pn,5,1sm||",
                "Australia_Western": "fo,0,be,0,11j,5,1sm|fr,0,be,0,11j,5,1sm||",
                "Azerbaijan": "fo,0,be,0,5l9|fr,0,be,0,5l9||",
                "Azores": "fo,0,is,0,5la|fr,0,is,0,5la||",
                "Bangladesh": "fo,0,be,0,5lb|fr,0,be,0,5lb||",
                "Bhutan": "abh|||",
                "Bolivia": "abg|||",
                "Brasilia": "fo,0,be,0,5le|fr,0,be,0,5le||",
                "Brunei": "abf|||",
                "Cape_Verde": "fo,0,is,0,5lg|fr,0,is,0,5lg||",
                "Chamorro": "6j,5,5lh|||",
                "Chatham": "fo,0,be,0,27|fr,0,be,0,27||",
                "Chile": "fo,0,be,0,5li|fr,0,be,0,5li||",
                "China": "fo,0,be,0,5lj|fr,0,be,0,5lj||",
                "Choibalsan": "fo,0,be,0,2q|fr,0,be,0,2q||",
                "Christmas": "abe|||",
                "Cocos": "abd|||",
                "Colombia": "fo,0,be,0,5lm|fr,0,be,0,5lm||",
                "Cook": "fo,0,is,0,5ln,5,36u|abc,0,is,0,5ln,5,36u||",
                "Cuba": "fo,0,is,0,5lq|fr,0,is,0,5lq||",
                "Davis": "5s,5,13c|||",
                "DumontDUrville": "1ha,0,is,0,5b,5,5j|||",
                "East_Timor": "abb,0,is,0,aba,5,vk|||",
                "Easter": "fo,0,is,0,5lv|fr,0,is,0,5lv||",
                "Ecuador": "ab9|||",
                "Europe_Central": "fo,0,be,0,25s,5,2h5|fr,0,be,0,25s,5,2h5||",
                "Europe_Eastern": "fo,0,be,0,1pn,5,2h5|fr,0,be,0,1pn,5,2h5||",
                "Europe_Further_Eastern": "ab8,0,ab6|||",
                "Europe_Western": "fo,0,be,0,11j,5,2h5|fr,0,be,0,11j,5,2h5||",
                "Falkland": "fo,0,is,0,5m4|fr,0,is,0,5m4||",
                "Fiji": "fo,0,is,0,5m5|fr,0,is,0,5m5||",
                "French_Guiana": "1ha,0,be,0,ab5,0,ab4|||",
                "French_Southern": "1ha,0,is,0,ab3,0,ab2,0,u0,0,ab1|||",
                "Galapagos": "ak,5,13c|||",
                "Gambier": "6v,5,13c|||",
                "Georgia": "fo,0,be,0,5mb|fr,0,be,0,5mb||",
                "Gilbert_Islands": "1ha,0,is,0,6q,5,36u|||",
                "GMT": "5u,5,5lh|||",
                "Greenland_Eastern": "fo,0,is,0,1pn,5,37c|fr,0,is,0,1pn,5,37c||",
                "Greenland_Western": "fo,0,is,0,11j,5,37c|fr,0,is,0,11j,5,37c||",
                "Gulf": "fo,0,ab0,0,aav|||",
                "Guyana": "aau|||",
                "Hawaii_Aleutian": "fo,0,is,0,37g,0,u0,0,5mh|fr,0,is,0,37g,0,u0,0,5mh||",
                "Hong_Kong": "fo,0,be,0,2l,0,2p|fr,0,be,0,2l,0,2p||",
                "Hovd": "fo,0,be,0,2g|fr,0,be,0,2g||",
                "India": "aat|||",
                "Indian_Ocean": "aas|||",
                "Indochina": "aar|||",
                "Indonesia_Central": "25s,5,4r8|||",
                "Indonesia_Eastern": "1pn,5,4r8|||",
                "Indonesia_Western": "11j,5,4r8|||",
                "Iran": "fo,0,be,0,5mm|fr,0,be,0,5mm||",
                "Irkutsk": "fo,0,be,0,3h|fr,0,be,0,3h||",
                "Israel": "fo,0,be,0,2fv|fr,0,be,0,2fv||",
                "Japan": "fo,0,be,0,6o|fr,0,be,0,6o||",
                "Kamchatka": "fo,0,be,0,37,5,3k|fr,0,be,0,37,5,3k||",
                "Kazakhstan_Eastern": "1ha,0,be,0,1pn,5,2g1|||",
                "Kazakhstan_Western": "1ha,0,be,0,11j,5,2g1|||",
                "Korea": "fo,0,be,0,5mn|fr,0,be,0,5mn||",
                "Kosrae": "6f,5,13c|||",
                "Krasnoyarsk": "fo,0,be,0,41|fr,0,be,0,41||",
                "Kyrgystan": "1qu,5,13c|||",
                "Line_Islands": "aaq,5,13c|||",
                "Lord_Howe": "fo,0,is,0,1u,0,24,5,37m|fr,0,is,0,1u,0,24,5,37m||",
                "Macquarie": "6m,5,aap|||",
                "Magadan": "fo,0,be,0,2o|fr,0,be,0,2o||",
                "Malaysia": "aao|||",
                "Maldives": "aan,5,13c|||",
                "Marquesas": "1ha,0,is,0,aam|||",
                "Marshall_Islands": "1ha,0,is,0,7o,5,36u|||",
                "Mauritius": "fo,0,is,0,5mu|fr,0,is,0,5mu||",
                "Mawson": "63,5,13c|||",
                "Mexico_Northwest": "fo,0,be,0,5mv,5,37r|fr,0,be,0,5mv,5,37r||",
                "Mexico_Pacific": "fo,0,be,0,37r,0,is,0,36p|fr,0,be,0,37r,0,is,0,36p||",
                "Mongolia": "fo,0,be,0,5n1,0,2m|fr,0,be,0,5n1,0,2m||",
                "Moscow": "fo,0,be,0,5n2|fr,0,be,0,5n2||",
                "Myanmar": "aal,5,13c|||",
                "Nauru": "7c6,5,13c|||",
                "Nepal": "aak|||",
                "New_Caledonia": "fo,0,be,0,4r7,5,5n6|fr,0,be,0,4r7,5,5n6||",
                "New_Zealand": "fo,0,is,0,5n7,5,5n8|fr,0,is,0,5n7,5,5n8||",
                "Newfoundland": "fo,0,is,0,5n9|fr,0,is,0,5n9||",
                "Niue": "8g,5,13c|||",
                "Norfolk": "1ha,0,is,0,6l,5,37m|||",
                "Noronha": "fo,0,be,0,1s,0,2,0,23|fr,0,be,0,1s,0,2,0,23||",
                "Novosibirsk": "fo,0,be,0,3b|fr,0,be,0,3b||",
                "Omsk": "fo,0,be,0,26|fr,0,be,0,26||",
                "Pakistan": "fo,0,be,0,3i|fr,0,be,0,3i||",
                "Palau": "aaj|||",
                "Papua_New_Guinea": "1ha,0,is,0,re,0,4r7,5,aai|||",
                "Paraguay": "fo,0,be,0,5nc|fr,0,be,0,5nc||",
                "Peru": "fo,0,be,0,ha|fr,0,be,0,ha||",
                "Philippines": "fo,0,is,0,5nd|fr,0,is,0,5nd||",
                "Phoenix_Islands": "aah|||",
                "Pierre_Miquelon": "fo,0,is,0,5nf,0,33,0,u0,0,35|fr,0,is,0,5nf,0,33,0,u0,0,35||",
                "Pitcairn": "7e,5,13c|||",
                "Ponape": "9s,5,13c|||",
                "Reunion": "i8,5,13c|||",
                "Rothera": "5q,5,13c|||",
                "Sakhalin": "fo,0,be,0,55|fr,0,be,0,55||",
                "Samara": "fo,0,be,0,29|fr,0,be,0,29||",
                "Samoa": "fo,0,is,0,5ng|fr,0,is,0,5ng||",
                "Seychelles": "8k,5,aag|||",
                "Singapore": "aaf|||",
                "Solomon": "aae|||",
                "South_Georgia": "5kl,5,aad|||",
                "Suriname": "aac|||",
                "Syowa": "65,5,13c|||",
                "Tahiti": "aab,5,13c|||",
                "Taipei": "fo,0,be,0,3d|fr,0,be,0,3d||",
                "Tajikistan": "aaa,5,13c|||",
                "Tokelau": "aa9,5,13c|||",
                "Tonga": "fo,0,is,0,2j|fr,0,is,0,2j||",
                "Truk": "6p,5,13c|||",
                "Turkmenistan": "fo,0,be,0,5np|fr,0,be,0,5np||",
                "Tuvalu": "aa8|||",
                "Uruguay": "fo,0,be,0,5nr|fr,0,be,0,5nr||",
                "Uzbekistan": "fo,0,be,0,5ns|fr,0,be,0,5ns||",
                "Vanuatu": "fo,0,is,0,5nt|fr,0,is,0,5nt||",
                "Venezuela": "aa7|||",
                "Vladivostok": "fo,0,be,0,28|fr,0,be,0,28||",
                "Volgograd": "fo,0,be,0,3f|fr,0,be,0,3f||",
                "Vostok": "5t,5,13c|||",
                "Wake": "1ha,0,is,0,5r,5,37m|||",
                "Wallis": "1ha,0,is,0,5f,5,0,u0,0,aa6|||",
                "Yakutsk": "fo,0,be,0,3u|fr,0,be,0,3u||",
                "Yekaterinburg": "fo,0,be,0,aa5|fr,0,be,0,5l||"
            }
        },
        "it-CH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|i,0,61,0,4nj||",
                "Europe/London": "|i,0,61,0,19,0,4nk,0,4nl||"
            },
            "metazone": {
                "Afghanistan": "i,0,4r2|||",
                "Africa_Central": "i,0,19s,0,9r|||",
                "Africa_Eastern": "i,0,19s,0,op|||",
                "Africa_Southern": "i,0,19s,0,4r1|||",
                "Africa_Western": "i,0,38,0,19s,0,nd|i,0,61,0,19s,0,nd||",
                "Alaska": "i,0,38,0,2fr|i,0,61,0,2fr||",
                "Amazon": "i,0,38,0,2fq|i,0,61,0,2fq||",
                "America_Central": "i,0,38,0,9r,0,10r|i,0,61,0,9r,0,10r||",
                "America_Eastern": "i,0,38,0,op,0,10r|i,0,61,0,op,0,10r||",
                "America_Mountain": "i,0,38,0,2fo,0,2fn,0,10r|i,0,61,0,2fo,0,2fn,0,10r||",
                "America_Pacific": "i,0,38,0,19,0,1gl,0,10r|i,0,61,0,19,0,1gl,0,10r||",
                "Anadyr": "i,0,38,0,4i,0,3l|i,0,61,0,4i,0,3l||",
                "Apia": "i,0,38,0,4i,0,3e|i,0,61,0,4i,0,3e||",
                "Arabian": "i,0,38,0,2fl|i,0,61,0,2fl||",
                "Argentina": "i,0,38,0,1gt|i,0,61,0,1gt||",
                "Argentina_Western": "i,0,38,0,1gt,0,nd|i,0,61,0,1gt,0,nd||",
                "Armenia": "i,0,38,0,2ff|i,0,61,0,2ff||",
                "Atlantic": "i,0,38,0,2fe|i,0,61,0,2fe||",
                "Australia_Central": "i,0,38,0,112,0,9r|i,0,61,0,112,0,9r||",
                "Australia_CentralWestern": "i,0,38,0,112,0,2eu|i,0,61,0,112,0,2eu||",
                "Australia_Eastern": "i,0,38,0,112,0,op|i,0,61,0,112,0,op||",
                "Australia_Western": "i,0,38,0,112,0,nd|i,0,61,0,112,0,nd||",
                "Azerbaijan": "i,0,38,0,2et|i,0,61,0,2et||",
                "Azores": "i,0,38,0,em,0,2er|i,0,61,0,em,0,2er||",
                "Bangladesh": "i,0,38,0,19,0,3n|i,0,61,0,19,0,3n||",
                "Bhutan": "i,0,19,0,as|||",
                "Bolivia": "i,0,ho,0,aa|||",
                "Brasilia": "i,0,38,0,4i,0,3a|i,0,61,0,4i,0,3a||",
                "Brunei": "i,0,19,0,9o,0,a4|||",
                "Cape_Verde": "i,0,38,0,4i,0,2em,0,3j|i,0,61,0,4i,0,2em,0,3j||",
                "Chamorro": "i,0,4i,0,6j|||",
                "Chatham": "i,0,38,0,em,0,27|i,0,61,0,em,0,27||",
                "Chile": "i,0,38,0,19,0,1rt|i,0,61,0,19,0,1rt||",
                "China": "i,0,38,0,ho,0,2el|i,0,61,0,ho,0,2el||",
                "Choibalsan": "i,0,38,0,4i,0,2q|i,0,61,0,4i,0,2q||",
                "Christmas": "i,0,194,0,99|||",
                "Cocos": "i,0,em,0,vl,0,7v|||",
                "Colombia": "i,0,38,0,ho,0,4m|i,0,61,0,ho,0,4m||",
                "Cook": "i,0,38,0,em,0,1q8,0,2v|i,0,61,0,r1,0,em,0,1q8,0,2v||",
                "Cuba": "i,0,38,0,4i,0,34|i,0,61,0,4i,0,34||",
                "Davis": "i,0,4i,0,5s|||",
                "DumontDUrville": "i,0,4i,0,5b,5,5j|||",
                "East_Timor": "i,0,4i,0,7n,0,195|||",
                "Easter": "i,0,38,0,194,0,4i,0,181|i,0,61,0,194,0,4i,0,181||",
                "Ecuador": "i,0,4qi|||",
                "Europe_Central": "i,0,38,0,13g,0,9r|i,0,61,0,13g,0,9r|h0|h4",
                "Europe_Eastern": "i,0,38,0,13g,0,op|i,0,61,0,13g,0,op|gu|gv",
                "Europe_Further_Eastern": "i,0,13g,0,op,0,4qh|||",
                "Europe_Western": "i,0,38,0,13g,0,nd|i,0,61,0,13g,0,nd|h1|h2",
                "Falkland": "i,0,38,0,em,0,vl,0,4v|i,0,61,0,em,0,vl,0,4v||",
                "Fiji": "i,0,38,0,em,0,2du|i,0,61,0,em,0,2du||",
                "French_Guiana": "i,0,ho,0,am,0,4qg|||",
                "French_Southern": "i,0,em,0,co,0,4qe,0,15,0,4qd,0,4qc|||",
                "Galapagos": "i,0,em,0,ak|||",
                "Gambier": "i,0,4i,0,6v|||",
                "Georgia": "i,0,38,0,ho,0,2u|i,0,61,0,ho,0,2u||",
                "Gilbert_Islands": "i,0,em,0,1q8,0,6q|||",
                "GMT": "i,0,19,0,rc,0,4i,0,5u|||",
                "Greenland_Eastern": "i,0,38,0,ho,0,b2,0,op|i,0,61,0,ho,0,b2,0,op||",
                "Greenland_Western": "i,0,38,0,ho,0,b2,0,nd|i,0,61,0,ho,0,b2,0,nd||",
                "Gulf": "i,0,19,0,oh|||",
                "Guyana": "i,0,ho,0,7a|||",
                "Hawaii_Aleutian": "i,0,38,0,em,0,vl,0,4g,5,1ml|i,0,61,0,em,0,vl,0,4g,5,1ml||",
                "Hong_Kong": "i,0,38,0,4i,0,2l,0,2p|i,0,61,0,4i,0,2l,0,2p||",
                "Hovd": "i,0,38,0,4i,0,2g|i,0,61,0,4i,0,2g||",
                "India": "i,0,38,0,4qb|||",
                "Indian_Ocean": "i,0,4qa,0,4q9|||",
                "Indochina": "i,0,4q7|||",
                "Indonesia_Central": "i,0,1qa,0,9r|||",
                "Indonesia_Eastern": "i,0,1qa,0,op|||",
                "Indonesia_Western": "i,0,1qa,0,nd|||",
                "Iran": "i,0,38,0,2dn|i,0,61,0,2dn||",
                "Irkutsk": "i,0,38,0,4i,0,3h|i,0,61,0,4i,0,3h||",
                "Israel": "i,0,38,0,4i,0,2dm|i,0,61,0,4i,0,2dm||",
                "Japan": "i,0,38,0,19,0,2dl|i,0,61,0,19,0,2dl||",
                "Kamchatka": "i,0,38,0,4i,0,37,5,3k|i,0,61,0,4i,0,37,5,3k||",
                "Kazakhstan_Eastern": "i,0,19,0,1mt,0,op|||",
                "Kazakhstan_Western": "i,0,19,0,1mt,0,nd|||",
                "Korea": "i,0,38,0,2dj|i,0,61,0,2dj||",
                "Kosrae": "i,0,19,0,6f|||",
                "Krasnoyarsk": "i,0,38,0,4i,0,41|i,0,61,0,4i,0,41||",
                "Kyrgystan": "i,0,19,0,mm|||",
                "Line_Islands": "i,0,em,0,4q2,0,4q1|||",
                "Lord_Howe": "i,0,38,0,4i,0,1u,0,24|i,0,61,0,4i,0,1u,0,24||",
                "Macquarie": "i,0,194,0,6m|||",
                "Magadan": "i,0,38,0,4i,0,2o|i,0,61,0,4i,0,2o||",
                "Malaysia": "i,0,ho,0,4q0|||",
                "Maldives": "i,0,em,0,2ot|||",
                "Marquesas": "i,0,em,0,4pu|||",
                "Marshall_Islands": "i,0,em,0,vl,0,7o|||",
                "Mauritius": "i,0,38,0,em,0,4u|i,0,61,0,em,0,4u||",
                "Mawson": "i,0,4i,0,63|||",
                "Mexico_Northwest": "i,0,38,0,19,0,2dc,0,93,5,nd|i,0,61,0,19,0,2dc,0,93,5,nd||",
                "Mexico_Pacific": "i,0,38,0,19,0,1gl,0,2db|i,0,61,0,19,0,1gl,0,2db||",
                "Mongolia": "i,0,38,0,4i,0,4f,0,2m|i,0,61,0,4i,0,4f,0,2m||",
                "Moscow": "i,0,38,0,4i,0,2cu|i,0,61,0,4i,0,2cu||",
                "Myanmar": "i,0,ho,0,ra|||",
                "Nauru": "i,0,4i,0,5v|||",
                "Nepal": "i,0,19,0,8u|||",
                "New_Caledonia": "i,0,38,0,ho,0,197,0,4o|i,0,61,0,ho,0,197,0,4o||",
                "New_Zealand": "i,0,38,0,ho,0,197,0,go|i,0,61,0,ho,0,197,0,go||",
                "Newfoundland": "i,0,38,0,4i,0,h5|i,0,61,0,4i,0,h5||",
                "Niue": "i,0,4i,0,8g|||",
                "Norfolk": "i,0,em,0,vl,0,6l|||",
                "Noronha": "i,0,38,0,4i,0,1s,0,2,0,23|i,0,61,0,4i,0,1s,0,2,0,23||",
                "Novosibirsk": "i,0,38,0,4i,0,3b|i,0,61,0,4i,0,3b||",
                "Omsk": "i,0,38,0,4i,0,26|i,0,61,0,4i,0,26||",
                "Pakistan": "i,0,38,0,19,0,3i|i,0,61,0,19,0,3i||",
                "Palau": "i,0,4i,0,9b|||",
                "Papua_New_Guinea": "i,0,ho,0,9a,0,197,0,a8|||",
                "Paraguay": "i,0,38,0,19,0,3c|i,0,61,0,19,0,3c||",
                "Peru": "i,0,38,0,19,0,2cp|i,0,61,0,19,0,2cp||",
                "Philippines": "i,0,38,0,em,0,2co|i,0,61,0,em,0,2co||",
                "Phoenix_Islands": "i,0,em,0,vl,0,ho,0,4pr|||",
                "Pierre_Miquelon": "i,0,38,0,4i,0,8i,5,33,0,15,0,35|i,0,61,0,4i,0,8i,5,33,0,15,0,35||",
                "Pitcairn": "i,0,em,0,7e|||",
                "Ponape": "i,0,4i,0,e5|||",
                "Pyongyang": "i,0,4i,0,8a|||",
                "Reunion": "i,0,4i,0,4pq|||",
                "Rothera": "i,0,4i,0,5q|||",
                "Sakhalin": "i,0,38,0,4i,0,55|i,0,61,0,4i,0,55||",
                "Samara": "i,0,38,0,4i,0,29|i,0,61,0,4i,0,29||",
                "Samoa": "i,0,38,0,4i,0,2c|i,0,61,0,4i,0,2c||",
                "Seychelles": "i,0,em,0,8k|||",
                "Singapore": "i,0,4i,0,bi|||",
                "Solomon": "i,0,em,0,vl,0,4pp|||",
                "South_Georgia": "i,0,ho,0,2u,0,19,0,i6|||",
                "Suriname": "i,0,19,0,8q|||",
                "Syowa": "i,0,4i,0,65|||",
                "Tahiti": "i,0,4i,0,8n|||",
                "Taipei": "i,0,38,0,4i,0,3d|i,0,61,0,4i,0,3d||",
                "Tajikistan": "i,0,19,0,4po|||",
                "Tokelau": "i,0,4i,0,5d|||",
                "Tonga": "i,0,38,0,4i,0,2j|i,0,61,0,4i,0,2j||",
                "Truk": "i,0,19,0,6p|||",
                "Turkmenistan": "i,0,38,0,19,0,4t|i,0,61,0,19,0,4t||",
                "Tuvalu": "i,0,4i,0,6h|||",
                "Uruguay": "i,0,38,0,2cl|i,0,61,0,2cl||",
                "Uzbekistan": "i,0,38,0,2ch|i,0,61,0,2ch||",
                "Vanuatu": "i,0,38,0,19,0,2f|i,0,61,0,19,0,2f||",
                "Venezuela": "i,0,19,0,7s|||",
                "Vladivostok": "i,0,38,0,4i,0,28|i,0,61,0,4i,0,28||",
                "Volgograd": "i,0,38,0,4i,0,3f|i,0,61,0,4i,0,3f||",
                "Vostok": "i,0,4i,0,5t|||",
                "Wake": "i,0,194,0,4i,0,5r|||",
                "Wallis": "i,0,4i,0,5f,0,15,0,5k|||",
                "Yakutsk": "i,0,38,0,4i,0,3u|i,0,61,0,4i,0,3u||",
                "Yekaterinburg": "i,0,38,0,4i,0,1lr|i,0,61,0,4i,0,1lr||"
            }
        },
        "it-SM": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|i,0,61,0,4nj||",
                "Europe/London": "|i,0,61,0,19,0,4nk,0,4nl||"
            },
            "metazone": {
                "Afghanistan": "i,0,4r2|||",
                "Africa_Central": "i,0,19s,0,9r|||",
                "Africa_Eastern": "i,0,19s,0,op|||",
                "Africa_Southern": "i,0,19s,0,4r1|||",
                "Africa_Western": "i,0,38,0,19s,0,nd|i,0,61,0,19s,0,nd||",
                "Alaska": "i,0,38,0,2fr|i,0,61,0,2fr||",
                "Amazon": "i,0,38,0,2fq|i,0,61,0,2fq||",
                "America_Central": "i,0,38,0,9r,0,10r|i,0,61,0,9r,0,10r||",
                "America_Eastern": "i,0,38,0,op,0,10r|i,0,61,0,op,0,10r||",
                "America_Mountain": "i,0,38,0,2fo,0,2fn,0,10r|i,0,61,0,2fo,0,2fn,0,10r||",
                "America_Pacific": "i,0,38,0,19,0,1gl,0,10r|i,0,61,0,19,0,1gl,0,10r||",
                "Anadyr": "i,0,38,0,4i,0,3l|i,0,61,0,4i,0,3l||",
                "Apia": "i,0,38,0,4i,0,3e|i,0,61,0,4i,0,3e||",
                "Arabian": "i,0,38,0,2fl|i,0,61,0,2fl||",
                "Argentina": "i,0,38,0,1gt|i,0,61,0,1gt||",
                "Argentina_Western": "i,0,38,0,1gt,0,nd|i,0,61,0,1gt,0,nd||",
                "Armenia": "i,0,38,0,2ff|i,0,61,0,2ff||",
                "Atlantic": "i,0,38,0,2fe|i,0,61,0,2fe||",
                "Australia_Central": "i,0,38,0,112,0,9r|i,0,61,0,112,0,9r||",
                "Australia_CentralWestern": "i,0,38,0,112,0,2eu|i,0,61,0,112,0,2eu||",
                "Australia_Eastern": "i,0,38,0,112,0,op|i,0,61,0,112,0,op||",
                "Australia_Western": "i,0,38,0,112,0,nd|i,0,61,0,112,0,nd||",
                "Azerbaijan": "i,0,38,0,2et|i,0,61,0,2et||",
                "Azores": "i,0,38,0,em,0,2er|i,0,61,0,em,0,2er||",
                "Bangladesh": "i,0,38,0,19,0,3n|i,0,61,0,19,0,3n||",
                "Bhutan": "i,0,19,0,as|||",
                "Bolivia": "i,0,ho,0,aa|||",
                "Brasilia": "i,0,38,0,4i,0,3a|i,0,61,0,4i,0,3a||",
                "Brunei": "i,0,19,0,9o,0,a4|||",
                "Cape_Verde": "i,0,38,0,4i,0,2em,0,3j|i,0,61,0,4i,0,2em,0,3j||",
                "Chamorro": "i,0,4i,0,6j|||",
                "Chatham": "i,0,38,0,em,0,27|i,0,61,0,em,0,27||",
                "Chile": "i,0,38,0,19,0,1rt|i,0,61,0,19,0,1rt||",
                "China": "i,0,38,0,ho,0,2el|i,0,61,0,ho,0,2el||",
                "Choibalsan": "i,0,38,0,4i,0,2q|i,0,61,0,4i,0,2q||",
                "Christmas": "i,0,194,0,99|||",
                "Cocos": "i,0,em,0,vl,0,7v|||",
                "Colombia": "i,0,38,0,ho,0,4m|i,0,61,0,ho,0,4m||",
                "Cook": "i,0,38,0,em,0,1q8,0,2v|i,0,61,0,r1,0,em,0,1q8,0,2v||",
                "Cuba": "i,0,38,0,4i,0,34|i,0,61,0,4i,0,34||",
                "Davis": "i,0,4i,0,5s|||",
                "DumontDUrville": "i,0,4i,0,5b,5,5j|||",
                "East_Timor": "i,0,4i,0,7n,0,195|||",
                "Easter": "i,0,38,0,194,0,4i,0,181|i,0,61,0,194,0,4i,0,181||",
                "Ecuador": "i,0,4qi|||",
                "Europe_Central": "i,0,38,0,13g,0,9r|i,0,61,0,13g,0,9r|h0|h4",
                "Europe_Eastern": "i,0,38,0,13g,0,op|i,0,61,0,13g,0,op|gu|gv",
                "Europe_Further_Eastern": "i,0,13g,0,op,0,4qh|||",
                "Europe_Western": "i,0,38,0,13g,0,nd|i,0,61,0,13g,0,nd|h1|h2",
                "Falkland": "i,0,38,0,em,0,vl,0,4v|i,0,61,0,em,0,vl,0,4v||",
                "Fiji": "i,0,38,0,em,0,2du|i,0,61,0,em,0,2du||",
                "French_Guiana": "i,0,ho,0,am,0,4qg|||",
                "French_Southern": "i,0,em,0,co,0,4qe,0,15,0,4qd,0,4qc|||",
                "Galapagos": "i,0,em,0,ak|||",
                "Gambier": "i,0,4i,0,6v|||",
                "Georgia": "i,0,38,0,ho,0,2u|i,0,61,0,ho,0,2u||",
                "Gilbert_Islands": "i,0,em,0,1q8,0,6q|||",
                "GMT": "i,0,19,0,rc,0,4i,0,5u|||",
                "Greenland_Eastern": "i,0,38,0,ho,0,b2,0,op|i,0,61,0,ho,0,b2,0,op||",
                "Greenland_Western": "i,0,38,0,ho,0,b2,0,nd|i,0,61,0,ho,0,b2,0,nd||",
                "Gulf": "i,0,19,0,oh|||",
                "Guyana": "i,0,ho,0,7a|||",
                "Hawaii_Aleutian": "i,0,38,0,em,0,vl,0,4g,5,1ml|i,0,61,0,em,0,vl,0,4g,5,1ml||",
                "Hong_Kong": "i,0,38,0,4i,0,2l,0,2p|i,0,61,0,4i,0,2l,0,2p||",
                "Hovd": "i,0,38,0,4i,0,2g|i,0,61,0,4i,0,2g||",
                "India": "i,0,38,0,4qb|||",
                "Indian_Ocean": "i,0,4qa,0,4q9|||",
                "Indochina": "i,0,4q7|||",
                "Indonesia_Central": "i,0,1qa,0,9r|||",
                "Indonesia_Eastern": "i,0,1qa,0,op|||",
                "Indonesia_Western": "i,0,1qa,0,nd|||",
                "Iran": "i,0,38,0,2dn|i,0,61,0,2dn||",
                "Irkutsk": "i,0,38,0,4i,0,3h|i,0,61,0,4i,0,3h||",
                "Israel": "i,0,38,0,4i,0,2dm|i,0,61,0,4i,0,2dm||",
                "Japan": "i,0,38,0,19,0,2dl|i,0,61,0,19,0,2dl||",
                "Kamchatka": "i,0,38,0,4i,0,37,5,3k|i,0,61,0,4i,0,37,5,3k||",
                "Kazakhstan_Eastern": "i,0,19,0,1mt,0,op|||",
                "Kazakhstan_Western": "i,0,19,0,1mt,0,nd|||",
                "Korea": "i,0,38,0,2dj|i,0,61,0,2dj||",
                "Kosrae": "i,0,19,0,6f|||",
                "Krasnoyarsk": "i,0,38,0,4i,0,41|i,0,61,0,4i,0,41||",
                "Kyrgystan": "i,0,19,0,mm|||",
                "Line_Islands": "i,0,em,0,4q2,0,4q1|||",
                "Lord_Howe": "i,0,38,0,4i,0,1u,0,24|i,0,61,0,4i,0,1u,0,24||",
                "Macquarie": "i,0,194,0,6m|||",
                "Magadan": "i,0,38,0,4i,0,2o|i,0,61,0,4i,0,2o||",
                "Malaysia": "i,0,ho,0,4q0|||",
                "Maldives": "i,0,em,0,2ot|||",
                "Marquesas": "i,0,em,0,4pu|||",
                "Marshall_Islands": "i,0,em,0,vl,0,7o|||",
                "Mauritius": "i,0,38,0,em,0,4u|i,0,61,0,em,0,4u||",
                "Mawson": "i,0,4i,0,63|||",
                "Mexico_Northwest": "i,0,38,0,19,0,2dc,0,93,5,nd|i,0,61,0,19,0,2dc,0,93,5,nd||",
                "Mexico_Pacific": "i,0,38,0,19,0,1gl,0,2db|i,0,61,0,19,0,1gl,0,2db||",
                "Mongolia": "i,0,38,0,4i,0,4f,0,2m|i,0,61,0,4i,0,4f,0,2m||",
                "Moscow": "i,0,38,0,4i,0,2cu|i,0,61,0,4i,0,2cu||",
                "Myanmar": "i,0,ho,0,ra|||",
                "Nauru": "i,0,4i,0,5v|||",
                "Nepal": "i,0,19,0,8u|||",
                "New_Caledonia": "i,0,38,0,ho,0,197,0,4o|i,0,61,0,ho,0,197,0,4o||",
                "New_Zealand": "i,0,38,0,ho,0,197,0,go|i,0,61,0,ho,0,197,0,go||",
                "Newfoundland": "i,0,38,0,4i,0,h5|i,0,61,0,4i,0,h5||",
                "Niue": "i,0,4i,0,8g|||",
                "Norfolk": "i,0,em,0,vl,0,6l|||",
                "Noronha": "i,0,38,0,4i,0,1s,0,2,0,23|i,0,61,0,4i,0,1s,0,2,0,23||",
                "Novosibirsk": "i,0,38,0,4i,0,3b|i,0,61,0,4i,0,3b||",
                "Omsk": "i,0,38,0,4i,0,26|i,0,61,0,4i,0,26||",
                "Pakistan": "i,0,38,0,19,0,3i|i,0,61,0,19,0,3i||",
                "Palau": "i,0,4i,0,9b|||",
                "Papua_New_Guinea": "i,0,ho,0,9a,0,197,0,a8|||",
                "Paraguay": "i,0,38,0,19,0,3c|i,0,61,0,19,0,3c||",
                "Peru": "i,0,38,0,19,0,2cp|i,0,61,0,19,0,2cp||",
                "Philippines": "i,0,38,0,em,0,2co|i,0,61,0,em,0,2co||",
                "Phoenix_Islands": "i,0,em,0,vl,0,ho,0,4pr|||",
                "Pierre_Miquelon": "i,0,38,0,4i,0,8i,5,33,0,15,0,35|i,0,61,0,4i,0,8i,5,33,0,15,0,35||",
                "Pitcairn": "i,0,em,0,7e|||",
                "Ponape": "i,0,4i,0,e5|||",
                "Pyongyang": "i,0,4i,0,8a|||",
                "Reunion": "i,0,4i,0,4pq|||",
                "Rothera": "i,0,4i,0,5q|||",
                "Sakhalin": "i,0,38,0,4i,0,55|i,0,61,0,4i,0,55||",
                "Samara": "i,0,38,0,4i,0,29|i,0,61,0,4i,0,29||",
                "Samoa": "i,0,38,0,4i,0,2c|i,0,61,0,4i,0,2c||",
                "Seychelles": "i,0,em,0,8k|||",
                "Singapore": "i,0,4i,0,bi|||",
                "Solomon": "i,0,em,0,vl,0,4pp|||",
                "South_Georgia": "i,0,ho,0,2u,0,19,0,i6|||",
                "Suriname": "i,0,19,0,8q|||",
                "Syowa": "i,0,4i,0,65|||",
                "Tahiti": "i,0,4i,0,8n|||",
                "Taipei": "i,0,38,0,4i,0,3d|i,0,61,0,4i,0,3d||",
                "Tajikistan": "i,0,19,0,4po|||",
                "Tokelau": "i,0,4i,0,5d|||",
                "Tonga": "i,0,38,0,4i,0,2j|i,0,61,0,4i,0,2j||",
                "Truk": "i,0,19,0,6p|||",
                "Turkmenistan": "i,0,38,0,19,0,4t|i,0,61,0,19,0,4t||",
                "Tuvalu": "i,0,4i,0,6h|||",
                "Uruguay": "i,0,38,0,2cl|i,0,61,0,2cl||",
                "Uzbekistan": "i,0,38,0,2ch|i,0,61,0,2ch||",
                "Vanuatu": "i,0,38,0,19,0,2f|i,0,61,0,19,0,2f||",
                "Venezuela": "i,0,19,0,7s|||",
                "Vladivostok": "i,0,38,0,4i,0,28|i,0,61,0,4i,0,28||",
                "Volgograd": "i,0,38,0,4i,0,3f|i,0,61,0,4i,0,3f||",
                "Vostok": "i,0,4i,0,5t|||",
                "Wake": "i,0,194,0,4i,0,5r|||",
                "Wallis": "i,0,4i,0,5f,0,15,0,5k|||",
                "Yakutsk": "i,0,38,0,4i,0,3u|i,0,61,0,4i,0,3u||",
                "Yekaterinburg": "i,0,38,0,4i,0,1lr|i,0,61,0,4i,0,1lr||"
            }
        },
        "it": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|i,0,61,0,4nj||",
                "Europe/London": "|i,0,61,0,19,0,4nk,0,4nl||"
            },
            "metazone": {
                "Afghanistan": "i,0,4r2|||",
                "Africa_Central": "i,0,19s,0,9r|||",
                "Africa_Eastern": "i,0,19s,0,op|||",
                "Africa_Southern": "i,0,19s,0,4r1|||",
                "Africa_Western": "i,0,38,0,19s,0,nd|i,0,61,0,19s,0,nd||",
                "Alaska": "i,0,38,0,2fr|i,0,61,0,2fr||",
                "Amazon": "i,0,38,0,2fq|i,0,61,0,2fq||",
                "America_Central": "i,0,38,0,9r,0,10r|i,0,61,0,9r,0,10r||",
                "America_Eastern": "i,0,38,0,op,0,10r|i,0,61,0,op,0,10r||",
                "America_Mountain": "i,0,38,0,2fo,0,2fn,0,10r|i,0,61,0,2fo,0,2fn,0,10r||",
                "America_Pacific": "i,0,38,0,19,0,1gl,0,10r|i,0,61,0,19,0,1gl,0,10r||",
                "Anadyr": "i,0,38,0,4i,0,3l|i,0,61,0,4i,0,3l||",
                "Apia": "i,0,38,0,4i,0,3e|i,0,61,0,4i,0,3e||",
                "Arabian": "i,0,38,0,2fl|i,0,61,0,2fl||",
                "Argentina": "i,0,38,0,1gt|i,0,61,0,1gt||",
                "Argentina_Western": "i,0,38,0,1gt,0,nd|i,0,61,0,1gt,0,nd||",
                "Armenia": "i,0,38,0,2ff|i,0,61,0,2ff||",
                "Atlantic": "i,0,38,0,2fe|i,0,61,0,2fe||",
                "Australia_Central": "i,0,38,0,112,0,9r|i,0,61,0,112,0,9r||",
                "Australia_CentralWestern": "i,0,38,0,112,0,2eu|i,0,61,0,112,0,2eu||",
                "Australia_Eastern": "i,0,38,0,112,0,op|i,0,61,0,112,0,op||",
                "Australia_Western": "i,0,38,0,112,0,nd|i,0,61,0,112,0,nd||",
                "Azerbaijan": "i,0,38,0,2et|i,0,61,0,2et||",
                "Azores": "i,0,38,0,em,0,2er|i,0,61,0,em,0,2er||",
                "Bangladesh": "i,0,38,0,19,0,3n|i,0,61,0,19,0,3n||",
                "Bhutan": "i,0,19,0,as|||",
                "Bolivia": "i,0,ho,0,aa|||",
                "Brasilia": "i,0,38,0,4i,0,3a|i,0,61,0,4i,0,3a||",
                "Brunei": "i,0,19,0,9o,0,a4|||",
                "Cape_Verde": "i,0,38,0,4i,0,2em,0,3j|i,0,61,0,4i,0,2em,0,3j||",
                "Chamorro": "i,0,4i,0,6j|||",
                "Chatham": "i,0,38,0,em,0,27|i,0,61,0,em,0,27||",
                "Chile": "i,0,38,0,19,0,1rt|i,0,61,0,19,0,1rt||",
                "China": "i,0,38,0,ho,0,2el|i,0,61,0,ho,0,2el||",
                "Choibalsan": "i,0,38,0,4i,0,2q|i,0,61,0,4i,0,2q||",
                "Christmas": "i,0,194,0,99|||",
                "Cocos": "i,0,em,0,vl,0,7v|||",
                "Colombia": "i,0,38,0,ho,0,4m|i,0,61,0,ho,0,4m||",
                "Cook": "i,0,38,0,em,0,1q8,0,2v|i,0,61,0,r1,0,em,0,1q8,0,2v||",
                "Cuba": "i,0,38,0,4i,0,34|i,0,61,0,4i,0,34||",
                "Davis": "i,0,4i,0,5s|||",
                "DumontDUrville": "i,0,4i,0,5b,5,5j|||",
                "East_Timor": "i,0,4i,0,7n,0,195|||",
                "Easter": "i,0,38,0,194,0,4i,0,181|i,0,61,0,194,0,4i,0,181||",
                "Ecuador": "i,0,4qi|||",
                "Europe_Central": "i,0,38,0,13g,0,9r|i,0,61,0,13g,0,9r|h0|h4",
                "Europe_Eastern": "i,0,38,0,13g,0,op|i,0,61,0,13g,0,op|gu|gv",
                "Europe_Further_Eastern": "i,0,13g,0,op,0,4qh|||",
                "Europe_Western": "i,0,38,0,13g,0,nd|i,0,61,0,13g,0,nd|h1|h2",
                "Falkland": "i,0,38,0,em,0,vl,0,4v|i,0,61,0,em,0,vl,0,4v||",
                "Fiji": "i,0,38,0,em,0,2du|i,0,61,0,em,0,2du||",
                "French_Guiana": "i,0,ho,0,am,0,4qg|||",
                "French_Southern": "i,0,em,0,co,0,4qe,0,15,0,4qd,0,4qc|||",
                "Galapagos": "i,0,em,0,ak|||",
                "Gambier": "i,0,4i,0,6v|||",
                "Georgia": "i,0,38,0,ho,0,2u|i,0,61,0,ho,0,2u||",
                "Gilbert_Islands": "i,0,em,0,1q8,0,6q|||",
                "GMT": "i,0,19,0,rc,0,4i,0,5u|||",
                "Greenland_Eastern": "i,0,38,0,ho,0,b2,0,op|i,0,61,0,ho,0,b2,0,op||",
                "Greenland_Western": "i,0,38,0,ho,0,b2,0,nd|i,0,61,0,ho,0,b2,0,nd||",
                "Gulf": "i,0,19,0,oh|||",
                "Guyana": "i,0,ho,0,7a|||",
                "Hawaii_Aleutian": "i,0,38,0,em,0,vl,0,4g,5,1ml|i,0,61,0,em,0,vl,0,4g,5,1ml||",
                "Hong_Kong": "i,0,38,0,4i,0,2l,0,2p|i,0,61,0,4i,0,2l,0,2p||",
                "Hovd": "i,0,38,0,4i,0,2g|i,0,61,0,4i,0,2g||",
                "India": "i,0,38,0,4qb|||",
                "Indian_Ocean": "i,0,4qa,0,4q9|||",
                "Indochina": "i,0,4q7|||",
                "Indonesia_Central": "i,0,1qa,0,9r|||",
                "Indonesia_Eastern": "i,0,1qa,0,op|||",
                "Indonesia_Western": "i,0,1qa,0,nd|||",
                "Iran": "i,0,38,0,2dn|i,0,61,0,2dn||",
                "Irkutsk": "i,0,38,0,4i,0,3h|i,0,61,0,4i,0,3h||",
                "Israel": "i,0,38,0,4i,0,2dm|i,0,61,0,4i,0,2dm||",
                "Japan": "i,0,38,0,19,0,2dl|i,0,61,0,19,0,2dl||",
                "Kamchatka": "i,0,38,0,4i,0,37,5,3k|i,0,61,0,4i,0,37,5,3k||",
                "Kazakhstan_Eastern": "i,0,19,0,1mt,0,op|||",
                "Kazakhstan_Western": "i,0,19,0,1mt,0,nd|||",
                "Korea": "i,0,38,0,2dj|i,0,61,0,2dj||",
                "Kosrae": "i,0,19,0,6f|||",
                "Krasnoyarsk": "i,0,38,0,4i,0,41|i,0,61,0,4i,0,41||",
                "Kyrgystan": "i,0,19,0,mm|||",
                "Line_Islands": "i,0,em,0,4q2,0,4q1|||",
                "Lord_Howe": "i,0,38,0,4i,0,1u,0,24|i,0,61,0,4i,0,1u,0,24||",
                "Macquarie": "i,0,194,0,6m|||",
                "Magadan": "i,0,38,0,4i,0,2o|i,0,61,0,4i,0,2o||",
                "Malaysia": "i,0,ho,0,4q0|||",
                "Maldives": "i,0,em,0,2ot|||",
                "Marquesas": "i,0,em,0,4pu|||",
                "Marshall_Islands": "i,0,em,0,vl,0,7o|||",
                "Mauritius": "i,0,38,0,em,0,4u|i,0,61,0,em,0,4u||",
                "Mawson": "i,0,4i,0,63|||",
                "Mexico_Northwest": "i,0,38,0,19,0,2dc,0,93,5,nd|i,0,61,0,19,0,2dc,0,93,5,nd||",
                "Mexico_Pacific": "i,0,38,0,19,0,1gl,0,2db|i,0,61,0,19,0,1gl,0,2db||",
                "Mongolia": "i,0,38,0,4i,0,4f,0,2m|i,0,61,0,4i,0,4f,0,2m||",
                "Moscow": "i,0,38,0,4i,0,2cu|i,0,61,0,4i,0,2cu||",
                "Myanmar": "i,0,ho,0,ra|||",
                "Nauru": "i,0,4i,0,5v|||",
                "Nepal": "i,0,19,0,8u|||",
                "New_Caledonia": "i,0,38,0,ho,0,197,0,4o|i,0,61,0,ho,0,197,0,4o||",
                "New_Zealand": "i,0,38,0,ho,0,197,0,go|i,0,61,0,ho,0,197,0,go||",
                "Newfoundland": "i,0,38,0,4i,0,h5|i,0,61,0,4i,0,h5||",
                "Niue": "i,0,4i,0,8g|||",
                "Norfolk": "i,0,em,0,vl,0,6l|||",
                "Noronha": "i,0,38,0,4i,0,1s,0,2,0,23|i,0,61,0,4i,0,1s,0,2,0,23||",
                "Novosibirsk": "i,0,38,0,4i,0,3b|i,0,61,0,4i,0,3b||",
                "Omsk": "i,0,38,0,4i,0,26|i,0,61,0,4i,0,26||",
                "Pakistan": "i,0,38,0,19,0,3i|i,0,61,0,19,0,3i||",
                "Palau": "i,0,4i,0,9b|||",
                "Papua_New_Guinea": "i,0,ho,0,9a,0,197,0,a8|||",
                "Paraguay": "i,0,38,0,19,0,3c|i,0,61,0,19,0,3c||",
                "Peru": "i,0,38,0,19,0,2cp|i,0,61,0,19,0,2cp||",
                "Philippines": "i,0,38,0,em,0,2co|i,0,61,0,em,0,2co||",
                "Phoenix_Islands": "i,0,em,0,vl,0,ho,0,4pr|||",
                "Pierre_Miquelon": "i,0,38,0,4i,0,8i,5,33,0,15,0,35|i,0,61,0,4i,0,8i,5,33,0,15,0,35||",
                "Pitcairn": "i,0,em,0,7e|||",
                "Ponape": "i,0,4i,0,e5|||",
                "Pyongyang": "i,0,4i,0,8a|||",
                "Reunion": "i,0,4i,0,4pq|||",
                "Rothera": "i,0,4i,0,5q|||",
                "Sakhalin": "i,0,38,0,4i,0,55|i,0,61,0,4i,0,55||",
                "Samara": "i,0,38,0,4i,0,29|i,0,61,0,4i,0,29||",
                "Samoa": "i,0,38,0,4i,0,2c|i,0,61,0,4i,0,2c||",
                "Seychelles": "i,0,em,0,8k|||",
                "Singapore": "i,0,4i,0,bi|||",
                "Solomon": "i,0,em,0,vl,0,4pp|||",
                "South_Georgia": "i,0,ho,0,2u,0,19,0,i6|||",
                "Suriname": "i,0,19,0,8q|||",
                "Syowa": "i,0,4i,0,65|||",
                "Tahiti": "i,0,4i,0,8n|||",
                "Taipei": "i,0,38,0,4i,0,3d|i,0,61,0,4i,0,3d||",
                "Tajikistan": "i,0,19,0,4po|||",
                "Tokelau": "i,0,4i,0,5d|||",
                "Tonga": "i,0,38,0,4i,0,2j|i,0,61,0,4i,0,2j||",
                "Truk": "i,0,19,0,6p|||",
                "Turkmenistan": "i,0,38,0,19,0,4t|i,0,61,0,19,0,4t||",
                "Tuvalu": "i,0,4i,0,6h|||",
                "Uruguay": "i,0,38,0,2cl|i,0,61,0,2cl||",
                "Uzbekistan": "i,0,38,0,2ch|i,0,61,0,2ch||",
                "Vanuatu": "i,0,38,0,19,0,2f|i,0,61,0,19,0,2f||",
                "Venezuela": "i,0,19,0,7s|||",
                "Vladivostok": "i,0,38,0,4i,0,28|i,0,61,0,4i,0,28||",
                "Volgograd": "i,0,38,0,4i,0,3f|i,0,61,0,4i,0,3f||",
                "Vostok": "i,0,4i,0,5t|||",
                "Wake": "i,0,194,0,4i,0,5r|||",
                "Wallis": "i,0,4i,0,5f,0,15,0,5k|||",
                "Yakutsk": "i,0,38,0,4i,0,3u|i,0,61,0,4i,0,3u||",
                "Yekaterinburg": "i,0,38,0,4i,0,1lr|i,0,61,0,4i,0,1lr||"
            }
        },
        "ja": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cbg||",
                "Europe/London": "|cbh||"
            },
            "metazone": {
                "Acre": "aa4|aa3||",
                "Afghanistan": "aa2|||",
                "Africa_Central": "aa1|||",
                "Africa_Eastern": "aa0|||",
                "Africa_Southern": "a9v|||",
                "Africa_Western": "a9u|a9t||",
                "Alaska": "a9s|a9r||",
                "Almaty": "a9q|a9p||",
                "Amazon": "a9o|a9n||",
                "America_Central": "a9m|a9l||",
                "America_Eastern": "a9k|a9j||",
                "America_Mountain": "a9i|a9h||",
                "America_Pacific": "a9g|a9f||",
                "Anadyr": "a9e|a9d||",
                "Apia": "a9c|a9b||",
                "Aqtau": "a9a|a99||",
                "Aqtobe": "a98|a97||",
                "Arabian": "a96|a95||",
                "Argentina": "a94|a93||",
                "Argentina_Western": "a92|a91||",
                "Armenia": "a90|a8v||",
                "Atlantic": "a8u|a8t||",
                "Australia_Central": "a8s|a8r||",
                "Australia_CentralWestern": "a8q|a8p||",
                "Australia_Eastern": "a8o|a8n||",
                "Australia_Western": "a8m|a8l||",
                "Azerbaijan": "a8k|a8j||",
                "Azores": "a8i|a8h||",
                "Bangladesh": "a8g|a8f||",
                "Bhutan": "a8e|||",
                "Bolivia": "a8d|||",
                "Brasilia": "a8c|a8b||",
                "Brunei": "a8a|||",
                "Cape_Verde": "a89|a88||",
                "Casey": "a87|||",
                "Chamorro": "a86|||",
                "Chatham": "a85|a84||",
                "Chile": "a83|a82||",
                "China": "a81|a80||",
                "Choibalsan": "a7v|a7u||",
                "Christmas": "a7t|||",
                "Cocos": "a7s|||",
                "Colombia": "a7r|a7q||",
                "Cook": "a7p|a7o||",
                "Cuba": "a7n|a7m||",
                "Davis": "a7l|||",
                "DumontDUrville": "a7k|||",
                "East_Timor": "a7j|||",
                "Easter": "a7i|a7h||",
                "Ecuador": "a7g|||",
                "Europe_Central": "a7f|a7e||",
                "Europe_Eastern": "a7d|a7c||",
                "Europe_Further_Eastern": "a7b|||",
                "Europe_Western": "a7a|a79||",
                "Falkland": "a78|a77||",
                "Fiji": "a76|a75||",
                "French_Guiana": "a74|||",
                "French_Southern": "a73|||",
                "Galapagos": "a72|||",
                "Gambier": "a71|||",
                "Georgia": "a70|a6v||",
                "Gilbert_Islands": "a6u|||",
                "GMT": "a6t|||",
                "Greenland_Eastern": "a6s|a6r||",
                "Greenland_Western": "a6q|a6p||",
                "Guam": "a6o|||",
                "Gulf": "a6n|||",
                "Guyana": "a6m|||",
                "Hawaii_Aleutian": "a6l|a6k||",
                "Hong_Kong": "a6j|a6i||",
                "Hovd": "a6h|a6g||",
                "India": "a6f|||",
                "Indian_Ocean": "a6e|||",
                "Indochina": "a6d|||",
                "Indonesia_Central": "a6c|||",
                "Indonesia_Eastern": "a6b|||",
                "Indonesia_Western": "a6a|||",
                "Iran": "a69|a68||",
                "Irkutsk": "a67|a66||",
                "Israel": "a65|a64||",
                "Japan": "a63|a62|a61|a60",
                "Kamchatka": "a5v|a5u||",
                "Kazakhstan_Eastern": "a5t|||",
                "Kazakhstan_Western": "a5s|||",
                "Korea": "a5r|a5q||",
                "Kosrae": "a5p|||",
                "Krasnoyarsk": "a5o|a5n||",
                "Kyrgystan": "a5m|||",
                "Lanka": "a5l|||",
                "Line_Islands": "a5k|||",
                "Lord_Howe": "a5j|a5i||",
                "Macau": "a5h|a5g||",
                "Macquarie": "a5f|||",
                "Magadan": "a5e|a5d||",
                "Malaysia": "a5c|||",
                "Maldives": "a5b|||",
                "Marquesas": "a5a|||",
                "Marshall_Islands": "a59|||",
                "Mauritius": "a58|a57||",
                "Mawson": "a56|||",
                "Mexico_Northwest": "a55|a54||",
                "Mexico_Pacific": "a53|a52||",
                "Mongolia": "a51|a50||",
                "Moscow": "a4v|a4u||",
                "Myanmar": "a4t|||",
                "Nauru": "a4s|||",
                "Nepal": "a4r|||",
                "New_Caledonia": "a4q|a4p||",
                "New_Zealand": "a4o|a4n||",
                "Newfoundland": "a4m|a4l||",
                "Niue": "a4k|||",
                "Norfolk": "a4j|||",
                "Noronha": "a4i|a4h||",
                "North_Mariana": "a4g|||",
                "Novosibirsk": "a4f|a4e||",
                "Omsk": "a4d|a4c||",
                "Pakistan": "a4b|a4a||",
                "Palau": "a49|||",
                "Papua_New_Guinea": "a48|||",
                "Paraguay": "a47|a46||",
                "Peru": "a45|a44||",
                "Philippines": "a43|a42||",
                "Phoenix_Islands": "a41|||",
                "Pierre_Miquelon": "a40|a3v||",
                "Pitcairn": "a3u|||",
                "Ponape": "a3t|||",
                "Pyongyang": "a3s|||",
                "Qyzylorda": "a3r|a3q||",
                "Reunion": "a3p|||",
                "Rothera": "a3o|||",
                "Sakhalin": "a3n|a3m||",
                "Samara": "a3l|a3k||",
                "Samoa": "a3j|a3i||",
                "Seychelles": "a3h|||",
                "Singapore": "a3g|||",
                "Solomon": "a3f|||",
                "South_Georgia": "a3e|||",
                "Suriname": "a3d|||",
                "Syowa": "2op|||",
                "Tahiti": "a3c|||",
                "Taipei": "a3b|a3a||",
                "Tajikistan": "a39|||",
                "Tokelau": "a38|||",
                "Tonga": "a37|a36||",
                "Truk": "a35|||",
                "Turkmenistan": "a34|a33||",
                "Tuvalu": "a32|||",
                "Uruguay": "a31|a30||",
                "Uzbekistan": "a2v|a2u||",
                "Vanuatu": "a2t|a2s||",
                "Venezuela": "a2r|||",
                "Vladivostok": "a2q|a2p||",
                "Volgograd": "a2o|a2n||",
                "Vostok": "a2m|||",
                "Wake": "a2l|||",
                "Wallis": "a2k|||",
                "Yakutsk": "a2j|a2i||",
                "Yekaterinburg": "a2h|a2g||"
            }
        },
        "jgo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "jmc": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ka": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cbi,0,gd,0,7p||",
                "Europe/London": "|cbj,0,gn,0,7p||"
            },
            "metazone": {
                "Afghanistan": "a2f,0,7p|||",
                "Africa_Central": "1oa,0,2oa,0,7p|||",
                "Africa_Eastern": "1kq,0,2oa,0,7p|||",
                "Africa_Southern": "61t,0,2oa,0,7p|||",
                "Africa_Western": "18a,0,2oa,0,gd,0,7p|18a,0,2oa,0,gn,0,7p||",
                "Alaska": "621,0,gd,0,7p|621,0,gn,0,7p||",
                "Amazon": "622,0,gd,0,7p|622,0,gn,0,7p||",
                "America_Central": "21n,0,21m,0,1oa,0,gd,0,7p|21n,0,21m,0,1oa,0,gn,0,7p||",
                "America_Eastern": "21n,0,21m,0,625,0,gd,0,7p|21n,0,21m,0,625,0,gn,0,7p||",
                "America_Mountain": "21n,0,21m,0,626,0,gd,0,7p|21n,0,21m,0,626,0,gn,0,7p||",
                "America_Pacific": "21n,0,21m,0,3if,0,27p,0,gd,0,7p|21n,0,21m,0,3if,0,27p,0,gn,0,7p||",
                "Apia": "629,0,gd,0,7p|629,0,gn,0,7p||",
                "Arabian": "62a,0,gd,0,7p|62a,0,gn,0,7p||",
                "Argentina": "3ih,0,gd,0,7p|3ih,0,gn,0,7p||",
                "Argentina_Western": "18a,0,3ih,0,gd,0,7p|18a,0,3ih,0,gn,0,7p||",
                "Armenia": "62c,0,gd,0,7p|62c,0,gn,0,7p||",
                "Atlantic": "62d,0,27p,0,gd,0,7p|62d,0,27p,0,gn,0,7p||",
                "Australia_Central": "21k,0,1oa,0,gd,0,7p|21k,0,1oa,0,gn,0,7p||",
                "Australia_CentralWestern": "1oa,0,21i,0,18a,0,21k,0,gd,0,7p|1oa,0,21i,0,18a,0,21k,0,gn,0,7p||",
                "Australia_Eastern": "1kq,0,21k,0,gd,0,7p|1kq,0,21k,0,gn,0,7p||",
                "Australia_Western": "18a,0,21k,0,gd,0,7p|18a,0,21k,0,gn,0,7p||",
                "Azerbaijan": "62g,0,gd,0,7p|62g,0,gn,0,7p||",
                "Azores": "62h,0,192,0,gd,0,7p|62h,0,192,0,gn,0,7p||",
                "Bangladesh": "62j,0,gd,0,7p|62j,0,gn,0,7p||",
                "Bhutan": "a2e,0,7p|||",
                "Bolivia": "a2d,0,7p|||",
                "Brasilia": "62m,0,gd,0,7p|62m,0,gn,0,7p||",
                "Brunei": "a2c,5,a2b,0,7p|||",
                "Cape_Verde": "62p,5,62q,0,gd,0,7p|62p,5,62q,0,gn,0,7p||",
                "Chamorro": "a2a,0,7p|||",
                "Chatham": "62s,0,gd,0,7p|62s,0,gn,0,7p||",
                "Chile": "62t,0,gd,0,7p|62t,0,gn,0,7p||",
                "China": "62u,0,gd,0,7p|62u,0,62v,0,630||",
                "Choibalsan": "631,0,gd,0,7p|631,0,gn,0,7p||",
                "Christmas": "a29,0,2b5,0,7p|||",
                "Cocos": "a28,0,192,0,7p|||",
                "Colombia": "635,0,gd,0,7p|635,0,gn,0,7p||",
                "Cook": "636,0,192,0,gd,0,7p|636,0,192,0,a27,0,gn,0,7p||",
                "Cuba": "638,0,gd,0,7p|638,0,gn,0,7p||",
                "Davis": "a26,0,7p|||",
                "DumontDUrville": "a25,5,a24,0,7p|||",
                "East_Timor": "1kq,0,a23,0,7p|||",
                "Easter": "63d,0,2b5,0,gd,0,7p|63d,0,2b5,0,gn,0,7p||",
                "Ecuador": "a22,0,7p|||",
                "Europe_Central": "1oa,0,27s,0,gd,0,7p|1oa,0,27s,0,gn,0,7p||",
                "Europe_Eastern": "1kq,0,27s,0,gd,0,7p|1kq,0,27s,0,gn,0,7p||",
                "Europe_Further_Eastern": "a21,0,1kq,0,27s,0,7p|||",
                "Europe_Western": "18a,0,27s,0,gd,0,7p|18a,0,27s,0,gn,0,7p||",
                "Falkland": "63h,0,192,0,gd,0,7p|63h,0,192,0,gn,0,7p||",
                "Fiji": "63i,0,gd,0,7p|63i,0,gn,0,7p||",
                "French_Guiana": "a20,0,a1v,0,7p|||",
                "French_Southern": "a1u,0,a1t,0,21i,0,a1s,0,7p|||",
                "Galapagos": "a1r,0,7p|||",
                "Gambier": "a1q,0,7p|||",
                "Georgia": "63q,0,gd,0,7p|63q,0,gn,0,7p||",
                "Gilbert_Islands": "a1p,0,192,0,7p|||",
                "GMT": "a1o,0,a1n,0,7p|||",
                "Greenland_Eastern": "1kq,0,3jf,0,gd,0,7p|1kq,0,3jf,0,gn,0,7p||",
                "Greenland_Western": "18a,0,3jf,0,gd,0,7p|18a,0,3jf,0,gn,0,7p||",
                "Gulf": "a1m,0,a1l,0,gd,0,7p|||",
                "Guyana": "a1k,0,7p|||",
                "Hawaii_Aleutian": "642,0,21i,0,643,0,gd,0,7p|642,0,21i,0,643,0,gn,0,7p||",
                "Hong_Kong": "644,0,gd,0,7p|644,0,gn,0,7p||",
                "Hovd": "645,0,gd,0,7p|645,0,gn,0,7p||",
                "India": "646,0,7p|||",
                "Indian_Ocean": "646,0,27p,0,192,0,7p|||",
                "Indochina": "a1j,0,7p|||",
                "Indonesia_Central": "1oa,0,4mn,0,7p|||",
                "Indonesia_Eastern": "1kq,0,4mn,0,7p|||",
                "Indonesia_Western": "18a,0,4mn,0,7p|||",
                "Iran": "649,0,gd,0,7p|649,0,62v,0,630||",
                "Irkutsk": "64a,0,gd,0,7p|64a,0,gn,0,7p||",
                "Israel": "64b,0,gd,0,7p|64b,0,gn,0,7p||",
                "Japan": "64c,0,gd,0,7p|64c,0,gn,0,7p||",
                "Kazakhstan_Eastern": "1kq,0,64d,0,7p|||",
                "Kazakhstan_Western": "18a,0,64d,0,7p|||",
                "Korea": "64e,0,gd,0,7p|64e,0,gn,0,7p||",
                "Kosrae": "a1i,0,7p|||",
                "Krasnoyarsk": "64g,0,gd,0,7p|64g,0,gn,0,7p||",
                "Kyrgystan": "a1h,0,7p|||",
                "Line_Islands": "a1g,0,192,0,7p|||",
                "Lord_Howe": "64j,5,64k,0,gd,0,7p|64j,5,64k,0,gn,0,7p||",
                "Macquarie": "a1f,0,2b5,0,7p|||",
                "Magadan": "64m,0,gd,0,7p|64m,0,gn,0,7p||",
                "Malaysia": "a1e,0,7p|||",
                "Maldives": "a1d,0,7p|||",
                "Marquesas": "a1c,0,192,0,7p|||",
                "Marshall_Islands": "a1b,0,192,0,7p|||",
                "Mauritius": "64r,0,gd,0,7p|64r,0,gn,0,7p||",
                "Mawson": "a1a,0,7p|||",
                "Mexico_Northwest": "64t,5,18a,0,3ju,0,7p|64t,5,18a,0,3ju,0,gn,0,7p||",
                "Mexico_Pacific": "3ju,0,3if,0,27p,0,gd,0,7p|3ju,0,3if,0,27p,0,gn,0,7p||",
                "Mongolia": "64v,5,650,0,gd,0,7p|64v,5,650,0,gn,0,7p||",
                "Moscow": "651,0,gd,0,7p|651,0,gn,0,7p||",
                "Myanmar": "a19,0,7p|||",
                "Nauru": "a18,0,7p|||",
                "Nepal": "a17,0,7p|||",
                "New_Caledonia": "2mt,0,656,0,gd,0,7p|2mt,0,656,0,gn,0,7p||",
                "New_Zealand": "2mt,0,657,0,gd,0,7p|2mt,0,657,0,gn,0,7p||",
                "Newfoundland": "658,0,gd,0,7p|658,0,gn,0,7p||",
                "Niue": "a16,0,7p|||",
                "Norfolk": "a15,0,2b5,0,7p|||",
                "Noronha": "65b,5,65c,5,65d,0,gd,0,7p|65b,5,65c,5,65d,0,gn,0,7p||",
                "Novosibirsk": "65e,0,gd,0,7p|65e,0,gn,0,7p||",
                "Omsk": "65f,0,gd,0,7p|65f,0,gn,0,7p||",
                "Pakistan": "65g,0,gd,0,7p|65g,0,gn,0,7p||",
                "Palau": "a14,0,7p|||",
                "Papua_New_Guinea": "a13,5,2mt,0,a12,0,7p|||",
                "Paraguay": "65k,0,gd,0,7p|65k,0,gn,0,7p||",
                "Peru": "65l,0,gd,0,7p|65l,0,gn,0,7p||",
                "Philippines": "65m,0,gd,0,7p|65m,0,gn,0,7p||",
                "Phoenix_Islands": "a11,0,192,0,7p|||",
                "Pierre_Miquelon": "65o,5,65p,0,21i,0,65q,0,gd,0,7p|65o,5,65p,0,21i,0,65q,0,gn,0,7p||",
                "Pitcairn": "a10,0,7p|||",
                "Ponape": "a0v,0,7p|||",
                "Pyongyang": "a0u,0,7p|||",
                "Reunion": "a0t,0,7p|||",
                "Rothera": "a0s,0,7p|||",
                "Sakhalin": "660,0,gd,0,7p|660,0,gn,0,7p||",
                "Samoa": "661,0,gd,0,7p|661,0,gn,0,7p||",
                "Seychelles": "a0r,0,192,0,7p|||",
                "Singapore": "a0q,0,7p|||",
                "Solomon": "a0p,0,192,0,7p|||",
                "South_Georgia": "61t,0,a0o,0,7p|||",
                "Suriname": "a0n,0,7p|||",
                "Syowa": "a0m,0,7p|||",
                "Tahiti": "a0l,0,7p|||",
                "Taipei": "669,0,gd,0,7p|669,0,gn,0,7p||",
                "Tajikistan": "a0k,0,7p|||",
                "Tokelau": "a0j,0,7p|||",
                "Tonga": "66c,0,gd,0,7p|66c,0,gn,0,7p||",
                "Truk": "a0i,0,7p|||",
                "Turkmenistan": "66e,0,gd,0,7p|66e,0,gn,0,7p||",
                "Tuvalu": "a0h,0,7p|||",
                "Uruguay": "66g,0,gd,0,7p|66g,0,gn,0,7p||",
                "Uzbekistan": "66h,0,gd,0,7p|66h,0,gn,0,7p||",
                "Vanuatu": "66i,0,gd,0,7p|66i,0,gn,0,7p||",
                "Venezuela": "a0g,0,7p|||",
                "Vladivostok": "66k,0,gd,0,7p|66k,0,gn,0,7p||",
                "Volgograd": "66l,0,gd,0,7p|66l,0,gn,0,7p||",
                "Vostok": "a0f,0,7p|||",
                "Wake": "a0e,0,2b5,0,7p|||",
                "Wallis": "a0d,0,21i,0,a0c,0,7p|||",
                "Yakutsk": "66q,0,gd,0,7p|66q,0,gn,0,7p||",
                "Yekaterinburg": "66r,0,gd,0,7p|66r,0,gn,0,7p||"
            }
        },
        "kab": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kam": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kde": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kea": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Africa_Central": "i,0,4i,0,c5,0,1qr|||",
                "Africa_Eastern": "i,0,4i,0,c5,0,8h|||",
                "Africa_Southern": "i,0,4i,0,10d,0,4i,0,c5|||",
                "Africa_Western": "i,0,1el,0,4i,0,c5,0,1vi|i,0,4i,0,ec,0,4i,0,c5,0,1vi||",
                "America_Central": "i,0,1qr,0,1el|i,0,1qr,0,4i,0,1ie||",
                "America_Eastern": "i,0,8h,0,1el|i,0,8h,0,4i,0,1ie||",
                "America_Mountain": "i,0,4i,0,109,0,1el|i,0,4i,0,1ie,0,4i,0,109||",
                "America_Pacific": "i,0,4i,0,672,0,1el|i,0,4i,0,672,0,4i,0,1ie||",
                "Atlantic": "i,0,1el,0,4i,0,673|i,0,4i,0,1ie,0,4i,0,673||",
                "Australia_Central": "i,0,1el,0,4i,0,4c,0,1qr|i,0,4i,0,1ie,0,4i,0,4c,0,1qr||",
                "Australia_CentralWestern": "i,0,1el,0,4i,0,4c,0,674,5,1vi|i,0,4i,0,1ie,0,4i,0,4c,0,674,5,1vi||",
                "Australia_Eastern": "i,0,1el,0,4i,0,4c,0,8h|i,0,4i,0,1ie,0,4i,0,4c,0,8h||",
                "Australia_Western": "i,0,1el,0,4i,0,4c,0,1vi|i,0,4i,0,1ie,0,4i,0,4c,0,1vi||",
                "Europe_Central": "i,0,1el,0,4i,0,4p,0,1qr|i,0,4i,0,1ie,0,4i,0,4p,0,1qr||",
                "Europe_Eastern": "i,0,1el,0,4i,0,4p,0,8h|i,0,4i,0,1ie,0,4i,0,4p,0,8h||",
                "Europe_Western": "i,0,1el,0,4i,0,4p,0,1vi|i,0,4i,0,1ie,0,4i,0,4p,0,1vi||"
            }
        },
        "khq": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ki": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,0,ga,0,7l||",
                "Europe/London": "|cbl,0,ga,0,7l||"
            },
            "metazone": {
                "Afghanistan": "a0b,0,7l|||",
                "Africa_Central": "3l6,0,mi,0,7l|||",
                "Africa_Eastern": "1q7,0,mi,0,7l|||",
                "Africa_Southern": "679,0,mi,0,7l|||",
                "Africa_Western": "1hp,0,mi,0,ft,0,7l|1hp,0,mi,0,ga,0,7l||",
                "Alaska": "12l,0,ft,0,7l|12l,0,ga,0,7l||",
                "Amazon": "15q,0,ft,0,7l|15q,0,ga,0,7l||",
                "America_Central": "1mq,0,m6,0,ft,0,2f8,0,7l|1mq,0,m6,0,ga,0,2f8,0,7l||",
                "America_Eastern": "1mq,0,m6,0,ft,0,3ld,0,7l|1mq,0,m6,0,ga,0,3ld,0,7l||",
                "America_Mountain": "1mq,0,m6,0,ft,0,67g,0,7l|1mq,0,m6,0,ga,0,67g,0,7l||",
                "America_Pacific": "1mq,0,m6,0,ft,0,3le,0,67i,0,7l|1mq,0,m6,0,ga,0,3le,0,67i,0,7l||",
                "Apia": "15s,0,ft,0,7l|15s,0,ga,0,7l||",
                "Arabian": "67j,0,67k,0,ft,0,7l|67j,0,67k,0,ga,0,7l||",
                "Argentina": "hk,0,ft,0,7l|hk,0,ga,0,7l||",
                "Argentina_Western": "1hp,0,hk,0,ft,0,7l|1hp,0,hk,0,ga,0,7l||",
                "Armenia": "18n,0,ft,0,7l|18n,0,ga,0,7l||",
                "Atlantic": "2fc,0,ft,0,7l|2fc,0,ga,0,7l||",
                "Australia_Central": "eo,0,ft,0,2f8,0,7l|eo,0,ga,0,2f8,0,7l||",
                "Australia_CentralWestern": "eo,0,ft,0,2f8,5,3lh,0,7l|eo,0,ga,0,2f8,5,3lh,0,7l||",
                "Australia_Eastern": "eo,0,ft,0,3ld,0,7l|eo,0,ga,0,3ld,0,7l||",
                "Australia_Western": "eo,0,ft,0,3lh,0,7l|eo,0,ga,0,3lh,0,7l||",
                "Azerbaijan": "67o,0,ft,0,7l|67o,0,ga,0,7l||",
                "Azores": "30f,0,2fg,0,ft,0,7l|30f,0,2fg,0,ga,0,7l||",
                "Bangladesh": "q8,0,ft,0,7l|q8,0,ga,0,7l||",
                "Bhutan": "16f,0,7l|||",
                "Bolivia": "1pu,0,7l|||",
                "Brasilia": "15k,0,ft,0,7l|15k,0,ga,0,7l||",
                "Brunei": "1gr,5,1o3,0,7l|||",
                "Cape_Verde": "12p,5,115,0,ft,0,7l|12p,5,115,0,ga,0,7l||",
                "Chamorro": "1qj,0,ft,0,7l|||",
                "Chatham": "162,0,ft,0,7l|162,0,ga,0,7l||",
                "Chile": "150,0,ft,0,7l|150,0,ga,0,7l||",
                "China": "67t,0,ft,0,7l|67t,0,ga,0,7l||",
                "Choibalsan": "11e,0,ft,0,7l|11e,0,ga,0,7l||",
                "Christmas": "4l3,0,67u,0,7l|||",
                "Cocos": "1le,0,1nc,0,7l|||",
                "Colombia": "15d,0,ft,0,7l|15d,0,ga,0,7l||",
                "Cook": "1p2,0,1nc,0,ft,0,7l|1p2,0,1nc,0,a0a,0,ga,0,7l||",
                "Cuba": "ql,0,ft,0,7l|ql,0,ga,0,7l||",
                "Davis": "1q3,0,7l|||",
                "DumontDUrville": "1gu,5,241,0,7l|||",
                "East_Timor": "1q7,0,1ki,0,7l|||",
                "Easter": "682,0,3lp,0,ft,0,7l|682,0,3lp,0,ga,0,7l||",
                "Ecuador": "1qk,0,7l|||",
                "Europe_Central": "3l6,0,298,0,ft,0,7l|3l6,0,298,0,ga,0,7l||",
                "Europe_Eastern": "1q7,0,298,0,ft,0,7l|1q7,0,298,0,ga,0,7l|gu|gv",
                "Europe_Further_Eastern": "a09,0,1q7,0,298,0,7l|||",
                "Europe_Western": "1hp,0,298,0,ft,0,7l|1hp,0,298,0,ga,0,7l||",
                "Falkland": "3ls,0,2fg,0,ft,0,7l|3ls,0,2fg,0,ga,0,7l||",
                "Fiji": "161,0,ft,0,7l|161,0,ga,0,7l||",
                "French_Guiana": "307,0,a08,0,7l|||",
                "French_Southern": "a07,0,a06,0,a05,0,2fj,0,4lu,0,7l|||",
                "Galapagos": "1na,0,7l|||",
                "Gambier": "244,0,7l|||",
                "Georgia": "15e,0,ft,0,7l|15e,0,ga,0,7l||",
                "Gilbert_Islands": "1ne,0,1nc,0,7l|||",
                "GMT": "4ht,0,7l|||",
                "Greenland_Eastern": "1q7,0,pq,0,ft,0,7l|1q7,0,pq,0,ga,0,7l||",
                "Greenland_Western": "1hp,0,pq,0,ft,0,7l|1hp,0,pq,0,ga,0,7l||",
                "Gulf": "a04,0,a03,0,7l|||",
                "Guyana": "1ql,0,7l|||",
                "Hawaii_Aleutian": "68i,0,2fj,0,3m8,0,2fg,0,ft,0,7l|68i,0,2fj,0,3m8,0,2fg,0,ga,0,7l||",
                "Hong_Kong": "13t,0,ft,0,7l|13t,0,ga,0,7l||",
                "Hovd": "qg,0,ft,0,7l|qg,0,ga,0,7l||",
                "India": "a02,0,ft,0,7l|||",
                "Indian_Ocean": "a01,0,a00,0,7l|||",
                "Indochina": "9vv,0,7l|||",
                "Indonesia_Central": "3l6,0,vo,0,7l|||",
                "Indonesia_Eastern": "1q7,0,vo,0,7l|||",
                "Indonesia_Western": "1hp,0,vo,0,7l|||",
                "Iran": "rr,0,ft,0,7l|rr,0,ga,0,7l||",
                "Irkutsk": "12u,0,ft,0,7l|12u,0,ga,0,7l||",
                "Israel": "165,0,ft,0,7l|165,0,ga,0,7l||",
                "Japan": "68p,0,ft,0,7l|68p,0,ga,0,7l||",
                "Kazakhstan_Eastern": "1q7,0,68q,0,7l|||",
                "Kazakhstan_Western": "1hp,0,68q,0,7l|||",
                "Korea": "15j,0,ft,0,7l|15j,0,ga,0,7l||",
                "Kosrae": "546,0,7l|||",
                "Krasnoyarsk": "14h,0,ft,0,7l|14h,0,ga,0,7l||",
                "Kyrgystan": "9vu,0,7l|||",
                "Line_Islands": "1eo,0,1nc,0,7l|||",
                "Lord_Howe": "oi,5,10a,0,ft,0,7l|oi,5,10a,0,ga,0,7l||",
                "Macquarie": "1l5,0,3lp,0,7l|||",
                "Magadan": "qq,0,ft,0,7l|qq,0,ga,0,7l||",
                "Malaysia": "1pp,0,7l|||",
                "Maldives": "4lt,0,1nc,0,7l|||",
                "Marquesas": "26l,0,1nc,0,7l|||",
                "Marshall_Islands": "4ls,0,1nc,0,7l|||",
                "Mauritius": "15t,0,ft,0,7l|15t,0,ga,0,7l||",
                "Mawson": "1lt,0,7l|||",
                "Mexico_Northwest": "1mq,5,1hp,0,1tg,0,ft,0,7l|1mq,5,1hp,0,1tg,0,ga,0,7l||",
                "Mexico_Pacific": "1tg,0,ft,0,3le,0,692,0,7l|1tg,0,ga,0,3le,0,692,0,7l||",
                "Mongolia": "or,5,qo,0,ft,0,7l|or,5,qo,0,ga,0,7l||",
                "Moscow": "693,0,ft,0,7l|693,0,ga,0,7l||",
                "Myanmar": "1qm,0,7l|||",
                "Nauru": "14s,0,7l|||",
                "Nepal": "16d,0,7l|||",
                "New_Caledonia": "2ut,0,15l,0,ft,0,7l|2ut,0,15l,0,ga,0,7l||",
                "New_Zealand": "2ut,0,15h,0,ft,0,7l|2ut,0,15h,0,ga,0,7l||",
                "Newfoundland": "14t,0,ft,0,7l|14t,0,ga,0,7l||",
                "Niue": "1qn,0,7l|||",
                "Norfolk": "146,0,3lp,0,7l|||",
                "Noronha": "141,5,17m,5,132,0,ft,0,7l|141,5,17m,5,132,0,ga,0,7l||",
                "Novosibirsk": "697,0,ft,0,7l|697,0,ga,0,7l||",
                "Omsk": "698,0,ft,0,7l|698,0,ga,0,7l||",
                "Pakistan": "699,0,ft,0,7l|699,0,ga,0,7l||",
                "Palau": "159,0,7l|||",
                "Papua_New_Guinea": "13k,0,kf,0,2ut,0,1m5,0,7l|||",
                "Paraguay": "13p,0,ft,0,7l|13p,0,ga,0,7l||",
                "Peru": "q1,0,ft,0,7l|q1,0,ga,0,7l||",
                "Philippines": "2lj,0,ft,0,7l|2lj,0,ga,0,7l||",
                "Phoenix_Islands": "158,0,1nc,0,7l|||",
                "Pierre_Miquelon": "pr,5,13m,0,2fj,0,qp,0,ft,0,7l|pr,5,13m,0,2fj,0,qp,0,ga,0,7l||",
                "Pitcairn": "24j,0,7l|||",
                "Ponape": "1v2,0,7l|||",
                "Pyongyang": "1u8,0,7l|||",
                "Reunion": "1qo,0,7l|||",
                "Rothera": "157,0,7l|||",
                "Sakhalin": "r8,0,ft,0,7l|r8,0,ga,0,7l||",
                "Samoa": "p0,0,ft,0,7l|p0,0,ga,0,7l||",
                "Seychelles": "9vt,0,7l|||",
                "Singapore": "16b,0,ft,0,7l|||",
                "Solomon": "4lp,0,1nc,0,7l|||",
                "South_Georgia": "679,0,261,0,7l|||",
                "Suriname": "18v,0,7l|||",
                "Syowa": "1pr,0,7l|||",
                "Tahiti": "1ma,0,7l|||",
                "Taipei": "69f,0,ft,0,7l|69f,0,ga,0,7l||",
                "Tajikistan": "9vs,0,7l|||",
                "Tokelau": "156,0,7l|||",
                "Tonga": "ot,0,ft,0,7l|ot,0,ga,0,7l||",
                "Truk": "1f2,0,7l|||",
                "Turkmenistan": "69h,0,ft,0,7l|69h,0,ga,0,7l||",
                "Tuvalu": "14j,0,7l|||",
                "Uruguay": "14a,0,ft,0,7l|14a,0,ga,0,7l||",
                "Uzbekistan": "3mu,0,ft,0,7l|3mu,0,ga,0,7l||",
                "Vanuatu": "os,0,ft,0,7l|os,0,ga,0,7l||",
                "Venezuela": "1mf,0,7l|||",
                "Vladivostok": "qn,0,ft,0,7l|qn,0,ga,0,7l||",
                "Volgograd": "q9,0,ft,0,7l|q9,0,ga,0,7l||",
                "Vostok": "155,0,7l|||",
                "Wake": "1m0,0,67u,0,7l|||",
                "Wallis": "1qq,0,2fj,0,14l,0,7l|||",
                "Yakutsk": "14d,0,ft,0,7l|14d,0,ga,0,7l||",
                "Yekaterinburg": "136,0,ft,0,7l|136,0,ga,0,7l||"
            }
        },
        "kkj": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "kln": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "km": {
            "gmtFormat": "ម៉ោង​សកល {0}",
            "gmtZeroFormat": "ម៉ោង​សកល",
            "zone": {
                "Europe/Dublin": "|cbm||",
                "Europe/London": "|cbn||"
            },
            "metazone": {
                "Afghanistan": "9vr|||",
                "Africa_Central": "9vq|||",
                "Africa_Eastern": "9vp|||",
                "Africa_Southern": "9vo|||",
                "Africa_Western": "9vn|9vm||",
                "Alaska": "9vl|9vk||",
                "Amazon": "9vj|9vi||",
                "America_Central": "9vh|9vg||",
                "America_Eastern": "9vf|9ve||",
                "America_Mountain": "9vd|9vc||",
                "America_Pacific": "9vb|9va||",
                "Apia": "9v9|9v8||",
                "Arabian": "9v7|9v6||",
                "Argentina": "9v5|9v4||",
                "Argentina_Western": "9v3|9v2||",
                "Armenia": "9v1|9v0||",
                "Atlantic": "9uv|9uu||",
                "Australia_Central": "9ut|9us||",
                "Australia_CentralWestern": "9ur|9uq||",
                "Australia_Eastern": "9up|9uo||",
                "Australia_Western": "9un|9um||",
                "Azerbaijan": "9ul|9uk||",
                "Azores": "9uj|9ui||",
                "Bangladesh": "9uh|9ug||",
                "Bhutan": "9uf|||",
                "Bolivia": "9ue|||",
                "Brasilia": "9ud|9uc||",
                "Brunei": "9ub|||",
                "Cape_Verde": "9ua|9u9||",
                "Chamorro": "9u8|||",
                "Chatham": "9u7|9u6||",
                "Chile": "9u5|9u4||",
                "China": "9u3|9u2||",
                "Choibalsan": "9u1|9u0||",
                "Christmas": "9tv|||",
                "Cocos": "9tu|||",
                "Colombia": "9tt|9ts||",
                "Cook": "9tr|9tq||",
                "Cuba": "9tp|9to||",
                "Davis": "9tn|||",
                "DumontDUrville": "9tm|||",
                "East_Timor": "9tl|||",
                "Easter": "9tk|9tj||",
                "Ecuador": "9ti|||",
                "Europe_Central": "9th|9tg||",
                "Europe_Eastern": "9tf|9te||",
                "Europe_Further_Eastern": "9td|||",
                "Europe_Western": "9tc|9tb||",
                "Falkland": "9ta|9t9||",
                "Fiji": "9t8|9t7||",
                "French_Guiana": "9t6|||",
                "French_Southern": "9t5,0,9t4|||",
                "Galapagos": "9t3|||",
                "Gambier": "9t2|||",
                "Georgia": "9t1|9t0||",
                "Gilbert_Islands": "9sv|||",
                "GMT": "9su|||",
                "Greenland_Eastern": "9st|9ss||",
                "Greenland_Western": "9sr|9sq||",
                "Gulf": "9sp|||",
                "Guyana": "9so|||",
                "Hawaii_Aleutian": "9sn,5,6co|9sm,5,6co||",
                "Hong_Kong": "9sl|9sk||",
                "Hovd": "9sj|9si||",
                "India": "9sh|||",
                "Indian_Ocean": "9sg|||",
                "Indochina": "9sf|||",
                "Indonesia_Central": "9se|||",
                "Indonesia_Eastern": "9sd|||",
                "Indonesia_Western": "9sc|||",
                "Iran": "9sb|9sa||",
                "Irkutsk": "9s9|9s8||",
                "Israel": "9s7|9s6||",
                "Japan": "9s5|9s4||",
                "Kazakhstan_Eastern": "9s3|||",
                "Kazakhstan_Western": "9s2|||",
                "Korea": "9s1|9s0||",
                "Kosrae": "9rv|||",
                "Krasnoyarsk": "9ru|9rt||",
                "Kyrgystan": "9rs|||",
                "Line_Islands": "9rr|||",
                "Lord_Howe": "9rq|9rp||",
                "Macquarie": "9ro|||",
                "Magadan": "9rn|9rm||",
                "Malaysia": "9rl|||",
                "Maldives": "9rk|||",
                "Marquesas": "9rj|||",
                "Marshall_Islands": "9ri|||",
                "Mauritius": "9rh|9rg||",
                "Mawson": "9rf|||",
                "Mexico_Northwest": "9re|9rd||",
                "Mexico_Pacific": "9rc|9rb||",
                "Mongolia": "9ra|9r9||",
                "Moscow": "9r8|9r7,0,6e9||",
                "Myanmar": "9r6|||",
                "Nauru": "9r5|||",
                "Nepal": "9r4|||",
                "New_Caledonia": "9r3|9r2,0,6e9||",
                "New_Zealand": "9r1|9r0||",
                "Newfoundland": "9qv|9qu||",
                "Niue": "9qt|||",
                "Norfolk": "9qs|||",
                "Noronha": "9qr|9qq||",
                "Novosibirsk": "9qp|9qo||",
                "Omsk": "9qn|9qm||",
                "Pakistan": "9ql|9qk||",
                "Palau": "9qj|||",
                "Papua_New_Guinea": "9qi|||",
                "Paraguay": "9qh|9qg||",
                "Peru": "9qf|9qe||",
                "Philippines": "9qd|9qc||",
                "Phoenix_Islands": "9qb|||",
                "Pierre_Miquelon": "9qa,0,6f7|9q9,0,6f7||",
                "Pitcairn": "9q8|||",
                "Ponape": "9q7|||",
                "Pyongyang": "9q6|||",
                "Reunion": "9q5|||",
                "Rothera": "9q4|||",
                "Sakhalin": "9q3|9q2||",
                "Samoa": "9q1|9q0||",
                "Seychelles": "9pv|||",
                "Singapore": "9pu|||",
                "Solomon": "9pt|||",
                "South_Georgia": "9ps|||",
                "Suriname": "9pr|||",
                "Syowa": "9pq|||",
                "Tahiti": "9pp|||",
                "Taipei": "9po|9pn||",
                "Tajikistan": "9pm|||",
                "Tokelau": "9pl|||",
                "Tonga": "9pk|9pj||",
                "Truk": "9pi|||",
                "Turkmenistan": "9ph|9pg||",
                "Tuvalu": "9pf|||",
                "Uruguay": "9pe|9pd||",
                "Uzbekistan": "9pc|9pb||",
                "Vanuatu": "9pa|9p9||",
                "Venezuela": "9p8|||",
                "Vladivostok": "9p7|9p6||",
                "Volgograd": "9p5|9p4||",
                "Vostok": "9p3|||",
                "Wake": "9p2|||",
                "Wallis": "9p1,0,9p0|||",
                "Yakutsk": "9ov|9ou||",
                "Yekaterinburg": "9ot|9os||"
            }
        },
        "kn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cbo,0,en,0,78||",
                "Europe/London": "|cbp,0,ma,0,78||"
            },
            "metazone": {
                "Afghanistan": "9or,0,78|||",
                "Africa_Central": "3u1,0,315,0,78|||",
                "Africa_Eastern": "1kn,0,315,0,78|||",
                "Africa_Southern": "4j9,0,315,0,en,0,78|||",
                "Africa_Western": "1dp,0,315,0,en,0,78|1dp,0,315,0,ma,0,78||",
                "Alaska": "9oq,0,en,0,78|9op,0,153,0,78||",
                "Amazon": "6h2,0,en,0,78|6h2,0,ma,0,78||",
                "America_Central": "1t5,0,1t6,0,30p,0,en,0,78|1t5,0,1t6,0,9oo,0,29h,0,78||",
                "America_Eastern": "1t5,0,1t6,0,6h8,0,en,0,78|1t5,0,1t6,0,6h8,0,29h,0,78||",
                "America_Mountain": "1t5,0,1t6,0,6h9,0,en,0,78|1t5,0,1t6,0,6h9,0,29h,0,78||",
                "America_Pacific": "1t5,0,1t6,0,3ug,0,en,0,78|1t5,0,1t6,0,3ug,0,29h,0,78||",
                "Anadyr": "6hb,0,en,0,78|6hb,0,153,0,78||",
                "Apia": "6hc,0,en,0,78|6hc,0,153,0,78||",
                "Arabian": "6hd,0,en,0,78|6hd,0,153,0,78||",
                "Argentina": "4j8,0,en,0,78|9on,0,ma,0,78||",
                "Argentina_Western": "1dp,0,4j8,0,en,0,78|1dp,0,4j8,0,ma,0,78||",
                "Armenia": "6hg,0,en,0,78|6hg,0,ma,0,78||",
                "Atlantic": "6hh,0,en,0,78|6hh,0,29h,0,78||",
                "Australia_Central": "1t9,0,30p,0,en,0,78|1t9,0,30p,0,153,0,78||",
                "Australia_CentralWestern": "1t9,0,30p,0,1dp,0,en,0,78|1t9,0,30p,0,1dp,0,153,0,78||",
                "Australia_Eastern": "1t9,0,1kn,0,en,0,78|1kn,0,1t9,0,153,0,78||",
                "Australia_Western": "1t9,0,1dp,0,en,0,78|1t9,0,1dp,0,153,0,78||",
                "Azerbaijan": "6hj,0,en,0,78|6hj,0,ma,0,78||",
                "Azores": "6hk,0,en,0,78|6hk,0,ma,0,78||",
                "Bangladesh": "6hl,0,en,0,78|6hl,0,ma,0,78||",
                "Bhutan": "9om,0,78|||",
                "Bolivia": "9ol,0,78|||",
                "Brasilia": "6ho,0,en,0,78|6ho,0,ma,0,78||",
                "Brunei": "9ok,0,9oj,0,78|||",
                "Cape_Verde": "6hr,0,6hs,0,en,0,78|6hr,0,6hs,0,ma,0,78||",
                "Chamorro": "9oi,0,en,0,78|||",
                "Chatham": "6hu,0,en,0,78|6hu,0,153,0,78||",
                "Chile": "6hv,0,en,0,78|6hv,0,ma,0,78||",
                "China": "6i0,0,en,0,78|6i0,0,153,0,78||",
                "Choibalsan": "9oh,0,en,0,78|9og,0,ma,0,78||",
                "Christmas": "9of,0,2gh,0,78|||",
                "Cocos": "9oe,0,1og,0,78|||",
                "Colombia": "6i7,0,en,0,78|6i7,0,ma,0,78||",
                "Cook": "6i8,0,1og,0,en,0,78|6i8,0,1og,0,9od,0,ma,0,78||",
                "Cuba": "6ia,0,en,0,78|6ia,0,29h,0,78||",
                "Davis": "9oc,0,78|||",
                "DumontDUrville": "9ob,5,9oa,0,9o9,0,78|||",
                "East_Timor": "1kn,0,9o8,0,78|||",
                "Easter": "6ig,0,2gh,0,en,0,78|6ig,0,2gh,0,ma,0,78||",
                "Ecuador": "9o7,0,78|||",
                "Europe_Central": "3u1,0,29c,0,en,0,78|3u1,0,29c,0,ma,0,78||",
                "Europe_Eastern": "1kn,0,29c,0,en,0,78|1kn,0,29c,0,ma,0,78||",
                "Europe_Further_Eastern": "9o6,5,1kn,0,29c,0,78|||",
                "Europe_Western": "1dp,0,29c,0,en,0,78|1dp,0,29c,0,ma,0,78||",
                "Falkland": "6ik,0,1og,0,en,0,78|6ik,0,1og,0,ma,0,78||",
                "Fiji": "6il,0,en,0,78|6il,0,ma,0,78||",
                "French_Guiana": "6im,0,6in,0,78|||",
                "French_Southern": "4j9,0,6im,0,3v8,0,9o5,0,78|||",
                "Galapagos": "9o4,0,78|||",
                "Gambier": "9o3,0,78|||",
                "Georgia": "4j5,0,en,0,78|4j5,0,ma,0,78||",
                "Gilbert_Islands": "9o2,0,1og,0,78|||",
                "GMT": "9o1,0,9o0,0,9nv|||",
                "Greenland_Eastern": "1kn,0,3vh,0,en,0,78|1kn,0,3vh,0,ma,0,78||",
                "Greenland_Western": "1dp,0,3vh,0,en,0,78|1dp,0,3vh,0,ma,0,78||",
                "Gulf": "9nu,0,en,0,78|||",
                "Guyana": "6in,0,78|||",
                "Hawaii_Aleutian": "6j3,5,6j4,0,en,0,78|6j3,5,6j4,0,153,0,78||",
                "Hong_Kong": "6j5,0,6j6,0,en,0,78|6j5,0,6j6,0,ma,0,78||",
                "Hovd": "6j7,0,en,0,78|6j7,0,ma,0,78||",
                "India": "9nt,0,en,0,78||15a|",
                "Indian_Ocean": "9ns,0,9nr,0,78|||",
                "Indochina": "9nq,0,78|||",
                "Indonesia_Central": "3u1,0,6jc,0,78|||",
                "Indonesia_Eastern": "1kn,0,6jc,0,78|||",
                "Indonesia_Western": "1dp,0,9np,0,78|||",
                "Iran": "6je,0,en,0,78|6je,0,153,0,78||",
                "Irkutsk": "6jf,0,en,0,78|6jf,0,ma,0,78||",
                "Israel": "6jg,0,en,0,78|6jg,0,153,0,78||",
                "Japan": "6jh,0,en,0,78|6jh,0,153,0,78||",
                "Kamchatka": "6ji,5,6jj,0,en,0,78|6ji,5,6jj,0,ma,0,78||",
                "Kazakhstan_Eastern": "1kn,0,6jk,0,78|||",
                "Kazakhstan_Western": "1dp,0,6jk,0,78|||",
                "Korea": "6jl,0,en,0,78|6jl,0,153,0,78||",
                "Kosrae": "9no,0,78|||",
                "Krasnoyarsk": "6jn,0,en,0,78|6jn,0,ma,0,78||",
                "Kyrgystan": "9nn,0,78|||",
                "Line_Islands": "9nm,0,1og,0,78|||",
                "Lord_Howe": "6jq,0,6jr,0,en,0,78|6jq,0,6jr,0,9nl,0,78||",
                "Macquarie": "9nk,0,2gh,0,78|||",
                "Magadan": "6ju,0,en,0,78|6ju,0,ma,0,78||",
                "Malaysia": "9nj,0,78|||",
                "Maldives": "9ni,0,78|||",
                "Marquesas": "9ng,0,78|||",
                "Marshall_Islands": "9nf,0,1og,0,78|||",
                "Mauritius": "6k3,0,en,0,78|6k3,0,ma,0,78||",
                "Mawson": "9ne,0,78|||",
                "Mexico_Northwest": "6k5,0,6k6,0,en,0,78|6k5,0,6k6,0,153,0,78||",
                "Mexico_Pacific": "6k7,0,3ug,0,en,0,78|6k7,0,3ug,0,153,0,78||",
                "Mongolia": "6k8,0,6k9,0,en,0,78|6k8,0,6k9,0,ma,0,78||",
                "Moscow": "6ka,0,en,0,78|6ka,0,ma,0,78||",
                "Myanmar": "9nd,0,78|||",
                "Nauru": "9nc,0,78|||",
                "Nepal": "9na,0,78|||",
                "New_Caledonia": "6ke,0,6kf,0,en,0,78|6ke,0,6kf,0,9n9,0,78||",
                "New_Zealand": "6kh,0,en,0,78|6kh,0,153,0,78||",
                "Newfoundland": "6ki,0,en,0,78|6ki,0,29h,0,78||",
                "Niue": "9n8,0,78|||",
                "Norfolk": "9n5,0,2gh,0,78|||",
                "Noronha": "9n0,0,9mv,0,6kn,0,en,0,78|9mu,0,9mp,0,6kn,0,ma,0,78||",
                "Novosibirsk": "6kq,0,en,0,78|6kq,0,ma,0,78||",
                "Omsk": "6kr,0,en,0,78|6kr,0,ma,0,78||",
                "Pakistan": "6ks,0,en,0,78|6ks,0,ma,0,78||",
                "Palau": "9mo,0,78|||",
                "Papua_New_Guinea": "9ml,0,9mk,0,9mh,0,78|||",
                "Paraguay": "6l1,0,en,0,78|6l1,0,ma,0,78||",
                "Peru": "6l2,0,en,0,78|6l2,0,ma,0,78||",
                "Philippines": "6l3,0,en,0,78|6l3,0,ma,0,78||",
                "Phoenix_Islands": "9mg,0,1og,0,78|||",
                "Pierre_Miquelon": "6l5,0,6l6,0,3v8,0,6l7,0,en,0,78|6l5,0,6l6,0,3v8,0,6l7,0,153,0,78||",
                "Pitcairn": "9mf,0,78|||",
                "Ponape": "9mc,0,78|||",
                "Pyongyang": "9mb,0,78|||",
                "Reunion": "9ma,0,78|||",
                "Rothera": "9m9,0,78|||",
                "Sakhalin": "6ld,0,en,0,78|6ld,0,ma,0,78||",
                "Samara": "6le,0,en,0,78|6le,0,ma,0,78||",
                "Samoa": "6lf,0,en,0,78|6lf,0,ma,0,78||",
                "Seychelles": "9m8,0,78|||",
                "Singapore": "9m7,0,en,0,78|||",
                "Solomon": "9ls,0,1og,0,78|||",
                "South_Georgia": "4j9,0,4j5,0,78|||",
                "Suriname": "9lr,0,78|||",
                "Syowa": "9lq,0,78|||",
                "Tahiti": "9lp,0,78|||",
                "Taipei": "6lm,0,en,0,78|6lm,0,153,0,78||",
                "Tajikistan": "9ln,0,78|||",
                "Tokelau": "9lj,0,78|||",
                "Tonga": "6lp,0,en,0,78|6lp,0,ma,0,78||",
                "Truk": "9li,0,78|||",
                "Turkmenistan": "6lr,0,en,0,78|6lr,0,ma,0,78||",
                "Tuvalu": "9lh,0,78|||",
                "Uruguay": "6lt,0,en,0,78|6lt,0,ma,0,78||",
                "Uzbekistan": "6lu,0,en,0,78|6lu,0,ma,0,78||",
                "Vanuatu": "6lv,0,en,0,78|6lv,0,ma,0,78||",
                "Venezuela": "9le,0,78|||",
                "Vladivostok": "6m1,0,en,0,78|6m1,0,ma,0,78||",
                "Volgograd": "6m2,0,en,0,78|6m2,0,ma,0,78||",
                "Vostok": "9ld,0,78|||",
                "Wake": "9l9,0,2gh,0,78|||",
                "Wallis": "9l6,0,3v8,0,9l3,0,78|||",
                "Yakutsk": "6m7,0,en,0,78|6m7,0,ma,0,78||",
                "Yekaterinburg": "9l1,0,en,0,78|9l0,0,ma,0,78||"
            }
        },
        "ko-KP": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|61v,0,3s||",
                "Europe/London": "|60p,0,94,0,3s||"
            },
            "metazone": {
                "Acre": "402,0,3s|402,0,94,0,3s||",
                "Afghanistan": "6md,0,b1|||",
                "Africa_Central": "6mf,0,b1|||",
                "Africa_Eastern": "6mg,0,b1|||",
                "Africa_Southern": "6mh,0,b1|||",
                "Africa_Western": "406,0,3s|406,0,94,0,3s||",
                "Alaska": "407,0,3s|407,0,94,0,3s||",
                "Almaty": "408,0,1nh,0,3s|408,0,94,0,3s||",
                "Amazon": "40a,0,3s|40a,0,94,0,3s||",
                "America_Central": "15p,0,1e8,0,3s|15p,0,1e8,0,94,0,3s||",
                "America_Eastern": "15p,0,16p,0,3s|15p,0,16p,0,94,0,3s||",
                "America_Mountain": "15p,0,6mq,0,3s|15p,0,6mr,0,94,0,3s||",
                "America_Pacific": "15p,0,1ua,0,3s|15p,0,1ua,0,94,0,3s||",
                "Anadyr": "40f,0,3s|40f,0,94,0,3s||",
                "Apia": "40g,0,3s|40g,0,94,0,3s||",
                "Aqtau": "40h,0,1nh,0,3s|40h,0,94,0,3s||",
                "Aqtobe": "40i,0,1nh,0,3s|40i,0,94,0,3s||",
                "Arabian": "40j,0,3s|40j,0,94,0,3s||",
                "Argentina": "1ub,0,3s|1ub,0,94,0,3s||",
                "Argentina_Western": "1ub,0,17q,0,3s|1ub,0,17q,0,94,0,3s||",
                "Armenia": "40m,0,3s|40m,0,94,0,3s||",
                "Atlantic": "40n,0,3s|15p,0,40n,0,94,0,3s||",
                "Australia_Central": "189,0,1e8,0,3s|189,0,1e8,0,94,0,3s||",
                "Australia_CentralWestern": "189,0,40p,0,3s|189,0,40p,0,94,0,3s||",
                "Australia_Eastern": "189,0,16p,0,3s|189,0,16p,0,94,0,3s||",
                "Australia_Western": "189,0,17q,0,3s|189,0,17q,0,94,0,3s||",
                "Azerbaijan": "40q,0,3s|40q,0,94,0,3s||",
                "Azores": "40r,0,3s|40r,0,94,0,3s||",
                "Bangladesh": "40s,0,3s|40s,0,94,0,3s||",
                "Bhutan": "6nb,0,b1|||",
                "Bolivia": "6nc,0,b1|||",
                "Brasilia": "40t,0,3s|40t,0,94,0,3s||",
                "Brunei": "6ne,0,b1|||",
                "Cape_Verde": "40u,0,40v,0,3s|40u,0,40v,0,94,0,3s||",
                "Casey": "6nh,0,b1|||",
                "Chamorro": "6ni,0,b1|||",
                "Chatham": "410,0,3s|410,0,94,0,3s||",
                "Chile": "411,0,3s|411,0,94,0,3s||",
                "China": "412,0,3s|412,0,94,0,3s||",
                "Choibalsan": "413,0,3s|413,0,94,0,3s||",
                "Christmas": "6nn,0,b1|||",
                "Cocos": "6no,0,11o,0,b1|||",
                "Colombia": "415,0,3s|415,0,94,0,3s||",
                "Cook": "416,0,11o,0,3s|416,0,11o,0,6ns,0,94,0,3s||",
                "Cuba": "417,0,3s|417,0,94,0,3s||",
                "Davis": "6nu,0,b1|||",
                "DumontDUrville": "6nv,0,b1|||",
                "East_Timor": "6o0,0,b1|||",
                "Easter": "418,0,3s|418,0,94,0,3s||",
                "Ecuador": "6o2,0,b1|||",
                "Europe_Central": "1e8,0,2fb,0,3s|1e8,0,2fb,0,94,0,3s||",
                "Europe_Eastern": "41a,0,3s|41a,0,94,0,3s||",
                "Europe_Further_Eastern": "6o5,0,2fb,0,3s|||",
                "Europe_Western": "41b,0,3s|41b,0,94,0,3s||",
                "Falkland": "41c,0,11o,0,3s|41c,0,11o,0,94,0,3s||",
                "Fiji": "41d,0,3s|41d,0,94,0,3s||",
                "French_Guiana": "41e,0,41f,0,b1|||",
                "French_Southern": "41e,0,6ob,0,6oc,0,6od,0,6oe,0,b1|||",
                "Galapagos": "6of,0,b1|||",
                "Gambier": "6og,0,b1|||",
                "Georgia": "41g,0,3s|41g,0,94,0,3s||",
                "Gilbert_Islands": "6oi,0,11o,0,b1|||",
                "GMT": "6oj,0,3s|||",
                "Greenland_Eastern": "1ui,0,16p,0,3s|1ui,0,16p,0,94,0,3s||",
                "Greenland_Western": "1ui,0,17q,0,3s|1ui,0,17q,0,94,0,3s||",
                "Gulf": "6ol,0,3s|||",
                "Guyana": "41f,0,b1|||",
                "Hawaii_Aleutian": "41i,0,41j,0,3s|41i,0,41j,0,94,0,3s||",
                "Hong_Kong": "41k,0,3s|41k,0,94,0,3s||",
                "Hovd": "41l,0,3s|41l,0,94,0,3s||",
                "India": "6oq,0,3s|||",
                "Indian_Ocean": "6or,0,b1|||",
                "Indochina": "6os,0,b1|||",
                "Indonesia_Central": "1e8,0,2f9,0,b1|||",
                "Indonesia_Eastern": "16p,0,2f9,0,b1|||",
                "Indonesia_Western": "17q,0,2f9,0,b1|||",
                "Iran": "41n,0,3s|41n,0,94,0,3s||",
                "Irkutsk": "41o,0,3s|41o,0,94,0,3s||",
                "Israel": "41p,0,3s|41p,0,94,0,3s||",
                "Japan": "41q,0,3s|41q,0,94,0,3s||",
                "Kamchatka": "41r,5,41s,0,3s|41r,5,41s,0,94,0,3s||",
                "Kazakhstan_Eastern": "16p,0,41t,0,b1|||",
                "Kazakhstan_Western": "17q,0,41t,0,b1|||",
                "Korea": "6p5,0,3s|6p5,0,94,0,3s||",
                "Kosrae": "6p6,0,b1|||",
                "Krasnoyarsk": "41u,0,3s|41u,0,94,0,3s||",
                "Kyrgystan": "6p8,0,b1|||",
                "Line_Islands": "6p9,0,11o,0,b1|||",
                "Lord_Howe": "41v,0,420,0,3s|41v,0,420,0,94,0,3s||",
                "Macau": "421,0,1nh,0,b1|421,0,94,0,3s||",
                "Macquarie": "6pd,0,b1|||",
                "Magadan": "422,0,3s|422,0,94,0,3s||",
                "Malaysia": "6pf,0,b1|||",
                "Maldives": "6pg,0,b1|||",
                "Marquesas": "6ph,0,11o,0,b1|||",
                "Marshall_Islands": "6pi,0,11o,0,b1|||",
                "Mauritius": "423,0,3s|423,0,94,0,3s||",
                "Mawson": "6pk,0,b1|||",
                "Mexico_Northwest": "1ul,0,425,0,3s|1ul,0,425,0,94,0,3s||",
                "Mexico_Pacific": "1ul,0,1ua,0,3s|1ul,0,1ua,0,94,0,3s||",
                "Mongolia": "426,0,3s|426,0,94,0,3s||",
                "Moscow": "427,0,3s|427,0,94,0,3s||",
                "Myanmar": "6pp,0,b1|||",
                "Nauru": "6pq,0,b1|||",
                "Nepal": "6pr,0,b1|||",
                "New_Caledonia": "428,0,3s|428,0,94,0,3s||",
                "New_Zealand": "429,0,3s|429,0,94,0,3s||",
                "Newfoundland": "42a,0,3s|42a,0,94,0,3s||",
                "Niue": "6pv,0,b1|||",
                "Norfolk": "6q0,0,b1|||",
                "Noronha": "42b,0,42c,0,42d,0,3s|42b,0,42c,0,42d,0,94,0,3s||",
                "Novosibirsk": "42e,0,3s|42e,0,94,0,3s||",
                "Omsk": "42f,0,3s|42f,0,94,0,3s||",
                "Pakistan": "42g,0,3s|42g,0,94,0,3s||",
                "Palau": "6q7,0,b1|||",
                "Papua_New_Guinea": "6q8,0,b1|||",
                "Paraguay": "42h,0,3s|42h,0,94,0,3s||",
                "Peru": "42i,0,3s|42i,0,94,0,3s||",
                "Philippines": "42j,0,3s|42j,0,94,0,3s||",
                "Phoenix_Islands": "6qc,0,11o,0,b1|||",
                "Pierre_Miquelon": "42k,0,42l,0,3s|42k,0,42l,0,94,0,3s||",
                "Pitcairn": "6qf,0,b1|||",
                "Ponape": "6qg,0,b1|||",
                "Pyongyang": "6qh,0,b1|||",
                "Qyzylorda": "42m,0,1nh,0,b1|42m,0,94,0,3s||",
                "Reunion": "6qj,0,b1|||",
                "Rothera": "6qk,0,b1|||",
                "Sakhalin": "42n,0,3s|42n,0,94,0,3s||",
                "Samara": "42o,0,3s|42o,0,94,0,3s||",
                "Samoa": "42p,0,3s|42p,0,94,0,3s||",
                "Seychelles": "6qo,0,b1|||",
                "Singapore": "6qp,0,3s|||",
                "Solomon": "6qq,0,11o,0,b1|||",
                "South_Georgia": "6qr,0,6qs,0,b1|||",
                "Suriname": "6qt,0,b1|||",
                "Syowa": "6qu,0,b1|||",
                "Tahiti": "6qv,0,b1|||",
                "Taipei": "42q,0,3s|42q,0,94,0,3s||",
                "Tajikistan": "6r1,0,b1|||",
                "Tokelau": "6r2,0,b1|||",
                "Tonga": "42r,0,3s|42r,0,94,0,3s||",
                "Truk": "6r4,0,b1|||",
                "Turkmenistan": "42s,0,3s|42s,0,94,0,3s||",
                "Tuvalu": "6r6,0,b1|||",
                "Uruguay": "42t,0,3s|42t,0,94,0,3s||",
                "Uzbekistan": "42u,0,3s|42u,0,94,0,3s||",
                "Vanuatu": "42v,0,3s|42v,0,94,0,3s||",
                "Venezuela": "6ra,0,b1|||",
                "Vladivostok": "430,0,3s|430,0,94,0,3s||",
                "Volgograd": "431,0,3s|431,0,94,0,3s||",
                "Vostok": "6rd,0,b1|||",
                "Wake": "6re,0,b1|||",
                "Wallis": "6rf,0,11o,0,b1|||",
                "Yakutsk": "432,0,3s|432,0,94,0,3s||",
                "Yekaterinburg": "433,0,3s|433,0,94,0,3s||"
            }
        },
        "ko": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|61v,0,3s||",
                "Europe/London": "|60p,0,94,0,3s||"
            },
            "metazone": {
                "Acre": "402,0,3s|402,0,94,0,3s||",
                "Afghanistan": "6md,0,b1|||",
                "Africa_Central": "6mf,0,b1|||",
                "Africa_Eastern": "6mg,0,b1|||",
                "Africa_Southern": "6mh,0,b1|||",
                "Africa_Western": "406,0,3s|406,0,94,0,3s||",
                "Alaska": "407,0,3s|407,0,94,0,3s||",
                "Almaty": "408,0,1nh,0,3s|408,0,94,0,3s||",
                "Amazon": "40a,0,3s|40a,0,94,0,3s||",
                "America_Central": "15p,0,1e8,0,3s|15p,0,1e8,0,94,0,3s||",
                "America_Eastern": "15p,0,16p,0,3s|15p,0,16p,0,94,0,3s||",
                "America_Mountain": "15p,0,6mq,0,3s|15p,0,6mr,0,94,0,3s||",
                "America_Pacific": "15p,0,1ua,0,3s|15p,0,1ua,0,94,0,3s||",
                "Anadyr": "40f,0,3s|40f,0,94,0,3s||",
                "Apia": "40g,0,3s|40g,0,94,0,3s||",
                "Aqtau": "40h,0,1nh,0,3s|40h,0,94,0,3s||",
                "Aqtobe": "40i,0,1nh,0,3s|40i,0,94,0,3s||",
                "Arabian": "40j,0,3s|40j,0,94,0,3s||",
                "Argentina": "1ub,0,3s|1ub,0,94,0,3s||",
                "Argentina_Western": "1ub,0,17q,0,3s|1ub,0,17q,0,94,0,3s||",
                "Armenia": "40m,0,3s|40m,0,94,0,3s||",
                "Atlantic": "40n,0,3s|15p,0,40n,0,94,0,3s||",
                "Australia_Central": "189,0,1e8,0,3s|189,0,1e8,0,94,0,3s||",
                "Australia_CentralWestern": "189,0,40p,0,3s|189,0,40p,0,94,0,3s||",
                "Australia_Eastern": "189,0,16p,0,3s|189,0,16p,0,94,0,3s||",
                "Australia_Western": "189,0,17q,0,3s|189,0,17q,0,94,0,3s||",
                "Azerbaijan": "40q,0,3s|40q,0,94,0,3s||",
                "Azores": "40r,0,3s|40r,0,94,0,3s||",
                "Bangladesh": "40s,0,3s|40s,0,94,0,3s||",
                "Bhutan": "6nb,0,b1|||",
                "Bolivia": "6nc,0,b1|||",
                "Brasilia": "40t,0,3s|40t,0,94,0,3s||",
                "Brunei": "6ne,0,b1|||",
                "Cape_Verde": "40u,0,40v,0,3s|40u,0,40v,0,94,0,3s||",
                "Casey": "6nh,0,b1|||",
                "Chamorro": "6ni,0,b1|||",
                "Chatham": "410,0,3s|410,0,94,0,3s||",
                "Chile": "411,0,3s|411,0,94,0,3s||",
                "China": "412,0,3s|412,0,94,0,3s||",
                "Choibalsan": "413,0,3s|413,0,94,0,3s||",
                "Christmas": "6nn,0,b1|||",
                "Cocos": "6no,0,11o,0,b1|||",
                "Colombia": "415,0,3s|415,0,94,0,3s||",
                "Cook": "416,0,11o,0,3s|416,0,11o,0,6ns,0,94,0,3s||",
                "Cuba": "417,0,3s|417,0,94,0,3s||",
                "Davis": "6nu,0,b1|||",
                "DumontDUrville": "6nv,0,b1|||",
                "East_Timor": "6o0,0,b1|||",
                "Easter": "418,0,3s|418,0,94,0,3s||",
                "Ecuador": "6o2,0,b1|||",
                "Europe_Central": "1e8,0,2fb,0,3s|1e8,0,2fb,0,94,0,3s||",
                "Europe_Eastern": "41a,0,3s|41a,0,94,0,3s||",
                "Europe_Further_Eastern": "6o5,0,2fb,0,3s|||",
                "Europe_Western": "41b,0,3s|41b,0,94,0,3s||",
                "Falkland": "41c,0,11o,0,3s|41c,0,11o,0,94,0,3s||",
                "Fiji": "41d,0,3s|41d,0,94,0,3s||",
                "French_Guiana": "41e,0,41f,0,b1|||",
                "French_Southern": "41e,0,6ob,0,6oc,0,6od,0,6oe,0,b1|||",
                "Galapagos": "6of,0,b1|||",
                "Gambier": "6og,0,b1|||",
                "Georgia": "41g,0,3s|41g,0,94,0,3s||",
                "Gilbert_Islands": "6oi,0,11o,0,b1|||",
                "GMT": "6oj,0,3s|||",
                "Greenland_Eastern": "1ui,0,16p,0,3s|1ui,0,16p,0,94,0,3s||",
                "Greenland_Western": "1ui,0,17q,0,3s|1ui,0,17q,0,94,0,3s||",
                "Gulf": "6ol,0,3s|||",
                "Guyana": "41f,0,b1|||",
                "Hawaii_Aleutian": "41i,0,41j,0,3s|41i,0,41j,0,94,0,3s||",
                "Hong_Kong": "41k,0,3s|41k,0,94,0,3s||",
                "Hovd": "41l,0,3s|41l,0,94,0,3s||",
                "India": "6oq,0,3s|||",
                "Indian_Ocean": "6or,0,b1|||",
                "Indochina": "6os,0,b1|||",
                "Indonesia_Central": "1e8,0,2f9,0,b1|||",
                "Indonesia_Eastern": "16p,0,2f9,0,b1|||",
                "Indonesia_Western": "17q,0,2f9,0,b1|||",
                "Iran": "41n,0,3s|41n,0,94,0,3s||",
                "Irkutsk": "41o,0,3s|41o,0,94,0,3s||",
                "Israel": "41p,0,3s|41p,0,94,0,3s||",
                "Japan": "41q,0,3s|41q,0,94,0,3s||",
                "Kamchatka": "41r,5,41s,0,3s|41r,5,41s,0,94,0,3s||",
                "Kazakhstan_Eastern": "16p,0,41t,0,b1|||",
                "Kazakhstan_Western": "17q,0,41t,0,b1|||",
                "Korea": "6ri,0,3s|6ri,0,94,0,3s||",
                "Kosrae": "6p6,0,b1|||",
                "Krasnoyarsk": "41u,0,3s|41u,0,94,0,3s||",
                "Kyrgystan": "6p8,0,b1|||",
                "Line_Islands": "6p9,0,11o,0,b1|||",
                "Lord_Howe": "41v,0,420,0,3s|41v,0,420,0,94,0,3s||",
                "Macau": "421,0,1nh,0,b1|421,0,94,0,3s||",
                "Macquarie": "6pd,0,b1|||",
                "Magadan": "422,0,3s|422,0,94,0,3s||",
                "Malaysia": "6pf,0,b1|||",
                "Maldives": "6pg,0,b1|||",
                "Marquesas": "6ph,0,11o,0,b1|||",
                "Marshall_Islands": "6pi,0,11o,0,b1|||",
                "Mauritius": "423,0,3s|423,0,94,0,3s||",
                "Mawson": "6pk,0,b1|||",
                "Mexico_Northwest": "1ul,0,425,0,3s|1ul,0,425,0,94,0,3s||",
                "Mexico_Pacific": "1ul,0,1ua,0,3s|1ul,0,1ua,0,94,0,3s||",
                "Mongolia": "426,0,3s|426,0,94,0,3s||",
                "Moscow": "427,0,3s|427,0,94,0,3s||",
                "Myanmar": "6pp,0,b1|||",
                "Nauru": "6pq,0,b1|||",
                "Nepal": "6pr,0,b1|||",
                "New_Caledonia": "428,0,3s|428,0,94,0,3s||",
                "New_Zealand": "429,0,3s|429,0,94,0,3s||",
                "Newfoundland": "42a,0,3s|42a,0,94,0,3s||",
                "Niue": "6pv,0,b1|||",
                "Norfolk": "6q0,0,b1|||",
                "Noronha": "42b,0,42c,0,42d,0,3s|42b,0,42c,0,42d,0,94,0,3s||",
                "Novosibirsk": "42e,0,3s|42e,0,94,0,3s||",
                "Omsk": "42f,0,3s|42f,0,94,0,3s||",
                "Pakistan": "42g,0,3s|42g,0,94,0,3s||",
                "Palau": "6q7,0,b1|||",
                "Papua_New_Guinea": "6q8,0,b1|||",
                "Paraguay": "42h,0,3s|42h,0,94,0,3s||",
                "Peru": "42i,0,3s|42i,0,94,0,3s||",
                "Philippines": "42j,0,3s|42j,0,94,0,3s||",
                "Phoenix_Islands": "6qc,0,11o,0,b1|||",
                "Pierre_Miquelon": "42k,0,42l,0,3s|42k,0,42l,0,94,0,3s||",
                "Pitcairn": "6qf,0,b1|||",
                "Ponape": "6qg,0,b1|||",
                "Pyongyang": "6qh,0,b1|||",
                "Qyzylorda": "42m,0,1nh,0,b1|42m,0,94,0,3s||",
                "Reunion": "6qj,0,b1|||",
                "Rothera": "6qk,0,b1|||",
                "Sakhalin": "42n,0,3s|42n,0,94,0,3s||",
                "Samara": "42o,0,3s|42o,0,94,0,3s||",
                "Samoa": "42p,0,3s|42p,0,94,0,3s||",
                "Seychelles": "6qo,0,b1|||",
                "Singapore": "6qp,0,3s|||",
                "Solomon": "6qq,0,11o,0,b1|||",
                "South_Georgia": "6qr,0,6qs,0,b1|||",
                "Suriname": "6qt,0,b1|||",
                "Syowa": "6qu,0,b1|||",
                "Tahiti": "6qv,0,b1|||",
                "Taipei": "42q,0,3s|42q,0,94,0,3s||",
                "Tajikistan": "6r1,0,b1|||",
                "Tokelau": "6r2,0,b1|||",
                "Tonga": "42r,0,3s|42r,0,94,0,3s||",
                "Truk": "6r4,0,b1|||",
                "Turkmenistan": "42s,0,3s|42s,0,94,0,3s||",
                "Tuvalu": "6r6,0,b1|||",
                "Uruguay": "42t,0,3s|42t,0,94,0,3s||",
                "Uzbekistan": "42u,0,3s|42u,0,94,0,3s||",
                "Vanuatu": "42v,0,3s|42v,0,94,0,3s||",
                "Venezuela": "6ra,0,b1|||",
                "Vladivostok": "430,0,3s|430,0,94,0,3s||",
                "Volgograd": "431,0,3s|431,0,94,0,3s||",
                "Vostok": "6rd,0,b1|||",
                "Wake": "6re,0,b1|||",
                "Wallis": "6rf,0,11o,0,b1|||",
                "Yakutsk": "432,0,3s|432,0,94,0,3s||",
                "Yekaterinburg": "433,0,3s|433,0,94,0,3s||"
            }
        },
        "kok": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "India": "27j,0,1b||15a|"
            }
        },
        "ks": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cbs,0,hr,0,73||",
                "Europe/London": "|cbt,0,hr,0,73||"
            },
            "metazone": {
                "Acre": "6rj,0,f4,0,73|6rj,0,hr,0,73||",
                "Afghanistan": "9kv,0,73|||",
                "Africa_Central": "1mu,0,438,0,73|||",
                "Africa_Eastern": "10l,0,438,0,73|||",
                "Africa_Southern": "6rr,0,1kr,0,73|||",
                "Africa_Western": "ru,0,438,0,f4,0,73|ru,0,438,0,hr,0,73||",
                "Alaska": "6rt,0,f4,0,73|6rt,0,1i8,0,73||",
                "Almaty": "6rv,0,f4,0,73|6rv,0,hr,0,73||",
                "Amazon": "6s0,0,f4,0,73|6s0,0,hr,0,73||",
                "America_Central": "1mu,0,f4,0,73|1mu,0,1i8,0,73||",
                "America_Eastern": "10l,0,f4,0,73|10l,0,1i8,0,73||",
                "America_Mountain": "6s1,0,f4,0,73|6s1,0,1i8,0,73||",
                "America_Pacific": "6s2,0,f4,0,73|6s2,0,1i8,0,73||",
                "Anadyr": "9ks,0,f4,0,73|9kr,0,hr,0,73||",
                "Aqtau": "6s5,0,f4,0,73|6s5,0,hr,0,73||",
                "Aqtobe": "6s6,0,f4,0,73|6s6,0,hr,0,73||",
                "Arabian": "6s7,0,f4,0,73|6s7,0,1i8,0,73||",
                "Argentina": "43c,0,f4,0,73|43c,0,hr,0,73||",
                "Argentina_Western": "ru,0,43c,0,f4,0,73|ru,0,43c,0,hr,0,73||",
                "Armenia": "6s9,0,f4,0,73|6s9,0,hr,0,73||",
                "Atlantic": "6sa,0,f4,0,73|6sa,0,1i8,0,73||",
                "Australia_Central": "1up,0,1mu,0,f4,0,73|1up,0,1mu,0,1i8,0,73||",
                "Australia_CentralWestern": "1up,0,1mu,0,ru,0,f4,0,73|1up,0,1mu,0,ru,0,1i8,0,73||",
                "Australia_Eastern": "1up,0,10l,0,f4,0,73|1up,0,10l,0,1i8,0,73||",
                "Australia_Western": "1up,0,294,0,f4,0,73|1up,0,9kp,0,9ko,0,73||",
                "Azerbaijan": "6sf,0,f4,0,73|6sf,0,hr,0,73||",
                "Azores": "6sg,0,f4,0,73|6sg,0,hr,0,9km||",
                "Bangladesh": "6si,0,f4,0,73|6si,0,hr,0,73||",
                "Bhutan": "9kk,0,73|||",
                "Bolivia": "9kj,0,73|||",
                "Brasilia": "6sl,0,f4,0,73|6sl,0,hr,0,73||",
                "Brunei": "9ki,0,9kg,0,73|||",
                "Cape_Verde": "2es,0,9ke,0,f4,0,73|2es,0,hr,0,73||",
                "Chamorro": "9kd,0,f4,0,73|||",
                "Chatham": "9kc,0,f4,0,73|9k9,0,hr,0,73||",
                "Chile": "6st,0,f4,0,73|6st,0,hr,0,73||",
                "China": "6su,0,f4,0,73|6su,0,1i8,0,73||",
                "Choibalsan": "6sv,0,f4,0,73|6sv,0,hr,0,73||",
                "Christmas": "9k6,0,73|||",
                "Cocos": "9k5,0,4j1,0,73|||",
                "Colombia": "6t3,0,f4,0,73|6t3,0,hr,0,73||",
                "Cook": "6t4,0,4j1,0,f4,0,73|6t4,0,4j1,0,9k4,0,hr,0,73||",
                "Cuba": "6t6,0,f4,0,73|6t6,0,1i8,0,73||",
                "Davis": "9k3,0,73|||",
                "DumontDUrville": "9k2,0,2u7,0,9k1,0,73|||",
                "East_Timor": "9k0,0,9jv,0,73|||",
                "Easter": "6td,0,f4,0,73|6td,0,hr,0,73||",
                "Ecuador": "9ju,0,73|||",
                "Europe_Central": "1mu,0,2u3,0,f4,0,73|1mu,0,2u3,0,hr,0,73||",
                "Europe_Eastern": "10l,0,2u3,0,f4,0,73|10l,0,2u3,0,hr,0,73||",
                "Europe_Western": "294,0,2u3,0,f4,0,73|294,0,9jt,0,hr,0,73||",
                "Falkland": "6th,0,f4,0,73|6th,0,hr,0,73||",
                "Fiji": "6ti,0,f4,0,73|6ti,0,hr,0,73||",
                "French_Guiana": "9js,0,9jr,0,73|||",
                "French_Southern": "6rr,0,9jq,0,73|||",
                "Galapagos": "9jp,0,73|||",
                "Gambier": "9jo,0,73|||",
                "Georgia": "6to,0,f4,0,73|6to,0,hr,0,73||",
                "Gilbert_Islands": "9jn,0,2tv,0,73|||",
                "GMT": "9jm,0,9jl,0,9jk,0,73|||",
                "Greenland_Eastern": "43u,0,43v,0,440,0,f4,0,73|43u,0,43v,0,440,0,hr,0,73||",
                "Greenland_Western": "294,0,43v,0,440,0,f4,0,73|294,0,43v,0,440,0,hr,0,73||",
                "Guam": "9jj,0,73|||",
                "Gulf": "9ji,0,f4,0,73|||",
                "Guyana": "9jh,0,73|||",
                "Hawaii_Aleutian": "6u4,0,6u5,0,f4,0,73|6u4,0,6u5,0,hr,0,73||",
                "Hong_Kong": "9jc,0,6u7,0,f4,0,73|9jb,0,6u7,0,hr,0,73||",
                "Hovd": "6u9,0,f4,0,73|6u9,0,hr,0,73||",
                "India": "9ja|||",
                "Indian_Ocean": "9j9,0,9j7,0,9j6|||",
                "Indochina": "9j5,0,73|||",
                "Indonesia_Central": "1mu,0,4iu,0,73|||",
                "Indonesia_Eastern": "43u,0,4iu,0,73|||",
                "Indonesia_Western": "294,0,4iu,0,73|||",
                "Iran": "9j3,0,f4,0,73|9j1,0,hr,0,73||",
                "Irkutsk": "6ui,0,f4,0,73|6ui,0,hr,0,73||",
                "Israel": "6uj,0,f4,0,73|6uj,0,2tq,0,73||",
                "Japan": "6ul,0,f4,0,73|6ul,0,2tq,0,73||",
                "Kamchatka": "6um,0,f4,0,73|6um,0,hr,0,73||",
                "Kazakhstan_Eastern": "43u,0,6un,0,73|||",
                "Kazakhstan_Western": "294,0,6un,0,73|||",
                "Korea": "6uo,0,f4,0,73|6uo,0,2tq,0,73||",
                "Kosrae": "9iu,0,73|||",
                "Krasnoyarsk": "6uq,0,f4,0,73|6uq,0,hr,0,73||",
                "Kyrgystan": "9in,0,73|||",
                "Lanka": "9im,0,73|||",
                "Line_Islands": "9ig,0,2tv,0,73|||",
                "Lord_Howe": "6uu,0,9if,0,f4,0,73|6uu,0,6v0,0,73||",
                "Macau": "6v1,0,f4,0,73|6v1,0,hr,0,73||",
                "Magadan": "6v2,0,f4,0,73|6v2,0,hr,0,73||",
                "Malaysia": "9ie,0,73|||",
                "Maldives": "9id,0,73|||",
                "Marquesas": "9ia,0,73|||",
                "Marshall_Islands": "9i9,0,2tv,0,73|||",
                "Mauritius": "6v7,0,f4,0,73|6v7,0,hr,0,73||",
                "Mawson": "9i8,0,73|||",
                "Mongolia": "6v9,0,f4,0,73|6v9,0,hr,0,73||",
                "Moscow": "2eo,0,f4,0,73|2eo,0,hr,0,73||",
                "Myanmar": "9i7,0,73|||",
                "Nauru": "9i4,0,73|||",
                "Nepal": "9i3,0,73|||",
                "New_Caledonia": "6ve,0,9i2,0,f4,0,73|6ve,0,9hu,0,hr,0,73||",
                "New_Zealand": "6vh,0,f4,0,73|6vh,0,6v0,0,73||",
                "Newfoundland": "4it,0,9ht,0,f4,0,73|4it,0,9hs,0,9hq,0,2tq,0,73||",
                "Niue": "9hm,0,73|||",
                "Norfolk": "9hk,0,73|||",
                "Noronha": "447,0,f4,0,73|447,0,hr,0,73||",
                "North_Mariana": "6vp,0,9hi,0,73|||",
                "Novosibirsk": "6vr,0,f4,0,73|6vr,0,hr,0,73||",
                "Omsk": "6vs,0,f4,0,73|6vs,0,hr,0,73||",
                "Pakistan": "6vt,0,f4,0,73|6vt,0,hr,0,73||",
                "Palau": "9hd,0,73|||",
                "Papua_New_Guinea": "9hc,0,4it,0,9hb,0,73|||",
                "Paraguay": "701,0,f4,0,73|701,0,hr,0,73||",
                "Peru": "702,0,f4,0,73|702,0,hr,0,73||",
                "Philippines": "703,0,f4,0,73|703,0,hr,0,73||",
                "Phoenix_Islands": "9ha,0,2tv,0,73|||",
                "Pierre_Miquelon": "705,0,706,0,707,0,f4,0,73|705,0,706,0,707,0,2tq,0,73||",
                "Pitcairn": "9h3,0,73|||",
                "Ponape": "9h2,0,73|||",
                "Qyzylorda": "70a,0,f4,0,73|70a,0,hr,0,73||",
                "Reunion": "9gv,0,73|||",
                "Rothera": "9gu,0,73|||",
                "Sakhalin": "70d,0,f4,0,73|70d,0,hr,0,73||",
                "Samara": "70e,0,f4,0,73|70e,0,hr,0,73||",
                "Samoa": "70f,0,f4,0,73|70f,0,hr,0,73||",
                "Seychelles": "9gs,0,73|||",
                "Singapore": "9gm,0,73|||",
                "Solomon": "9gl,0,9gk,0,9gi,0,73|||",
                "South_Georgia": "6vp,0,9gh,0,73|||",
                "Suriname": "9gf,0,73|||",
                "Syowa": "4is,0,73|||",
                "Tahiti": "9gc,0,73|||",
                "Tajikistan": "9gb,0,73|||",
                "Tokelau": "9ga,0,73|||",
                "Tonga": "70r,0,f4,0,73|70r,0,hr,0,73||",
                "Truk": "9g8,0,73|||",
                "Turkmenistan": "70t,0,f4,0,73|70t,0,hr,0,73||",
                "Tuvalu": "9g7,0,73|||",
                "Uruguay": "70v,0,f4,0,73|70v,0,hr,0,73||",
                "Uzbekistan": "9g6,0,f4,0,73|9g5,0,hr,0,73||",
                "Vanuatu": "712,0,f4,0,73|712,0,hr,0,73||",
                "Venezuela": "9g3,0,73|||",
                "Vladivostok": "714,0,f4,0,73|714,0,hr,0,73||",
                "Volgograd": "715,0,f4,0,73|715,0,hr,0,73||",
                "Vostok": "9g1,0,73|||",
                "Wake": "2pn,0,2tv,0,73|||",
                "Wallis": "9fu,0,9ft,0,9fs,0,73|||",
                "Yakutsk": "9fr,0,f4,0,73|9fq,0,hr,0,73||",
                "Yekaterinburg": "9fp,0,f4,0,73|9fo,0,hr,0,73||"
            }
        },
        "ksb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ksf": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ksh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cbu,0,1m3,0,2ee||",
                "Europe/London": "|cbv,0,1m3,0,2ee||"
            },
            "metazone": {
                "Africa_Central": "291,5,2tm,0,1v3|||",
                "Africa_Eastern": "4ir,5,2tm,0,1v3|||",
                "Africa_Southern": "9fn,5,2tm,0,1v3|||",
                "Africa_Western": "4iq,0,71j,5,2tm,0,1v3|71j,5,2tm,0,71k||",
                "Azores": "2,0,2eg,0,71m,0,44f,0,2ef|2,0,2eg,0,71m,0,2ee||",
                "Cape_Verde": "4iq,0,71q,0,1v3|71q,0,71k||",
                "Europe_Central": "71r,5,2e9,0,1m3,0,44f,0,2ef|71r,5,2e9,0,1m3,0,2ee|1bd|1be",
                "Europe_Eastern": "4ir,5,2e9,0,1m3,0,44f,0,2ef|4ir,5,2e9,0,1m3,0,2ee|1bg|1bh",
                "Europe_Western": "71u,5,2e9,0,1m3,0,44f,0,2ef|71u,5,2e9,0,1m3,0,2ee|1bk|1bl",
                "GMT": "5u,0,1m3,0,9fm,5,2ef|||",
                "Indian_Ocean": "9fl,0,193,0,9fk,0,1m3,0,2ef|||",
                "Mauritius": "4iq,0,1v3,0,44k,0,4u|9fj,0,44k,0,4u||",
                "Reunion": "1v3,0,44k,0,b4|||",
                "Seychelles": "1v3,0,44k,0,2,0,9fi|||"
            }
        },
        "kw": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ky": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,0,g8,0,i1||",
                "Europe/London": "|5u9,0,gc,0,a0||"
            },
            "metazone": {
                "Afghanistan": "28q,0,a0|||",
                "Africa_Central": "44n,0,mi,0,a0|||",
                "Africa_Eastern": "1vf,0,mi,0,a0|||",
                "Africa_Southern": "729,0,mi,0,a0|||",
                "Africa_Western": "1ls,0,mi,0,g8,0,i1|1ls,0,mi,0,gc,0,a0||",
                "Alaska": "12l,0,g8,0,a0|12l,0,gc,0,a0||",
                "Amazon": "15q,0,g8,0,a0|15q,0,gc,0,a0||",
                "America_Central": "1ln,0,m6,k,0,2fa,0,g8,0,a0|1ln,0,m6,k,0,2fa,0,gc,0,72g||",
                "America_Eastern": "1ln,0,m6,k,0,26q,0,g8,0,a0|1ln,0,m6,k,0,26q,0,gc,0,a0||",
                "America_Mountain": "1ln,0,m6,k,0,72i,0,g8,0,a0|1ln,0,m6,k,0,72i,0,gc,0,a0||",
                "America_Pacific": "1ln,0,m6,k,0,450,0,1ao,0,g8,0,a0|1ln,0,m6,k,0,450,0,1ao,0,gc,0,a0||",
                "Apia": "15s,0,g8,0,i1|15s,0,gc,0,a0||",
                "Arabian": "72k,0,g8,0,i1|72k,0,gc,0,i1||",
                "Argentina": "hk,0,g8,0,a0|hk,0,gc,0,a0||",
                "Argentina_Western": "1ls,0,hk,0,g8,0,a0|1ls,0,hk,0,gc,0,a0||",
                "Armenia": "18n,0,g8,0,i1|18n,0,gc,0,a0||",
                "Atlantic": "2fc,0,g8,0,a0|2fc,0,gc,0,a0||",
                "Australia_Central": "eo,0,2fa,0,g8,0,i1|eo,0,2fa,0,gc,0,a0||",
                "Australia_CentralWestern": "eo,0,2fa,0,4ip,0,g8,0,i1|eo,0,2fa,0,26q,0,gc,0,a0||",
                "Australia_Eastern": "eo,0,26q,0,g8,0,i1|eo,0,26q,0,gc,0,a0||",
                "Australia_Western": "eo,0,4ip,0,g8,0,i1|eo,0,4ip,0,gc,0,a0||",
                "Azerbaijan": "72m,0,g8,0,i1|72m,0,gc,0,a0||",
                "Azores": "30f,0,g8,0,i1|9fh,0,gc,0,a0||",
                "Bangladesh": "q8,0,g8,0,i1|q8,0,gc,0,a0||",
                "Bhutan": "16f,0,a0|||",
                "Bolivia": "1pu,0,a0|||",
                "Brasilia": "15k,0,g8,0,a0|15k,0,gc,0,a0||",
                "Brunei": "1gr,0,1o3,0,a0|||",
                "Cape_Verde": "72o,0,115,0,g8,0,i1|72o,0,115,0,gc,0,a0||",
                "Chamorro": "1qj,0,a0|||",
                "Chatham": "1bu,0,g8,0,72g|1bu,0,gc,0,a0||",
                "Chile": "150,0,g8,0,a0|150,0,gc,0,a0||",
                "China": "452,0,g8,0,i1|452,0,gc,0,i1||",
                "Choibalsan": "11e,0,g8,0,i1|11e,0,gc,0,a0||",
                "Christmas": "72q,0,4io,0,a0|||",
                "Cocos": "1le,0,1f5,0,a0|||",
                "Colombia": "15d,0,g8,0,a0|15d,0,gc,0,a0||",
                "Cook": "1p2,0,1f5,0,g8,0,i1|1p2,0,1f5,0,9fg,0,gc,0,a0||",
                "Cuba": "ql,0,g8,0,a0|ql,0,gc,0,a0||",
                "Davis": "72u,0,a0|||",
                "DumontDUrville": "1gu,5,72v,5,730,0,a0|||",
                "East_Timor": "1vf,0,1ki,0,a0|||",
                "Easter": "731,0,4io,0,g8,0,i1|731,0,4io,0,gc,0,i1||",
                "Ecuador": "9ff,0,a0|||",
                "Europe_Central": "44n,0,lo,0,g8,0,i1|44n,0,lo,0,gc,0,a0||",
                "Europe_Eastern": "1vf,0,lo,0,g8,0,i1|1vf,0,lo,0,gc,0,a0||",
                "Europe_Further_Eastern": "9fe,0,2nv,0,9fd,0,a0|||",
                "Europe_Western": "1ls,0,lo,0,g8,0,i1|1ls,0,lo,0,gc,0,a0||",
                "Falkland": "3ls,0,1f5,0,g8,0,i1|3ls,0,1f5,0,gc,0,a0||",
                "Fiji": "458,0,g8,0,i1|458,0,gc,0,a0||",
                "French_Guiana": "307,0,1pb,0,a0|||",
                "French_Southern": "307,0,9fc,0,2nv,0,4lu,0,a0|||",
                "Galapagos": "1na,0,a0|||",
                "Gambier": "659,0,a0|||",
                "Georgia": "15e,0,g8,0,i1|15e,0,gc,0,a0||",
                "Gilbert_Islands": "1ne,0,a0|||",
                "GMT": "ah,k,0,g8,0,i1|||",
                "Greenland_Eastern": "1vf,0,pq,0,g8,0,a0|1vf,0,pq,0,gc,0,a0||",
                "Greenland_Western": "1ls,0,pq,0,g8,0,a0|1ls,0,pq,0,gc,0,a0||",
                "Gulf": "9fb,0,9fa,0,gc,0,i1|||",
                "Guyana": "1pb,0,a0|||",
                "Hawaii_Aleutian": "45a,5,3m8,0,g8,0,a0|45a,5,3m8,0,gc,0,a0||",
                "Hong_Kong": "13t,0,g8,0,i1|13t,0,gc,0,a0||",
                "Hovd": "qg,0,g8,0,i1|qg,0,gc,0,a0||",
                "India": "26o,0,a0|||",
                "Indian_Ocean": "53a,0,1ao,0,a0|||",
                "Indochina": "9f9,0,a0|||",
                "Indonesia_Central": "44n,0,vo,0,a0|||",
                "Indonesia_Eastern": "1vf,0,vo,0,a0|||",
                "Indonesia_Western": "1ls,0,vo,0,a0|||",
                "Iran": "rr,0,g8,0,i1|rr,0,9f8,0,a0||",
                "Irkutsk": "12u,0,g8,0,i1|12u,0,gc,0,i1||",
                "Israel": "165,0,g8,0,i1|165,0,gc,0,i1||",
                "Japan": "73e,0,g8,0,i1|73e,0,gc,0,a0||",
                "Kazakhstan_Eastern": "1vf,0,73f,0,a0|||",
                "Kazakhstan_Western": "1ls,0,73f,0,a0|||",
                "Korea": "15j,0,g8,0,i1|15j,0,gc,0,i1||",
                "Kosrae": "1qi,0,a0|||",
                "Krasnoyarsk": "14h,0,g8,0,i1|14h,0,gc,0,a0||",
                "Kyrgystan": "9f3,0,a0|||",
                "Line_Islands": "1eo,0,1f5,0,a0|||",
                "Lord_Howe": "oi,0,10a,0,g8,0,i1|oi,0,10a,0,gc,0,a0||",
                "Macquarie": "73i,0,a0|||",
                "Magadan": "qq,0,g8,0,i1|qq,0,gc,0,a0||",
                "Malaysia": "1pp,0,a0|||",
                "Maldives": "4lt,0,a0|||",
                "Marquesas": "73j,0,a0|||",
                "Marshall_Islands": "4ls,0,1f5,0,a0|||",
                "Mauritius": "15t,0,g8,0,i1|15t,0,gc,0,a0||",
                "Mawson": "1lt,0,a0|||",
                "Mexico_Northwest": "1ln,5,26q,0,1tg,0,g8,0,a0|1ln,5,26q,0,1tg,0,gc,0,a0||",
                "Mexico_Pacific": "1tg,k,0,450,0,1ao,0,g8,0,a0|1tg,k,0,450,0,1ao,0,gc,0,a0||",
                "Mongolia": "or,0,qo,0,g8,0,i1|or,0,qo,0,gc,0,a0||",
                "Moscow": "r6,0,g8,0,i1|r6,0,gc,0,a0||",
                "Myanmar": "9f2,0,a0|||",
                "Nauru": "14s,0,a0|||",
                "Nepal": "16d,0,a0|||",
                "New_Caledonia": "4in,0,15l,0,g8,0,i1|4in,0,15l,0,gc,0,a0||",
                "New_Zealand": "4in,0,15h,0,g8,0,i1|73m,0,15h,0,gc,0,i1||",
                "Newfoundland": "73n,0,g8,0,a0|73n,0,gc,0,a0||",
                "Niue": "1qn,0,a0|||",
                "Norfolk": "146,0,a0|||",
                "Noronha": "138,0,13a,0,132,0,g8,0,a0|138,0,13a,0,132,0,gc,0,a0||",
                "Novosibirsk": "qt,0,g8,0,i1|qt,0,gc,0,a0||",
                "Omsk": "r5,0,g8,0,i1|r5,0,gc,0,a0||",
                "Pakistan": "rq,0,g8,0,i1|rq,0,gc,0,a0||",
                "Palau": "159,0,a0|||",
                "Papua_New_Guinea": "13k,5,73m,0,1m5,0,a0|||",
                "Paraguay": "13p,0,g8,0,a0|13p,0,gc,0,a0||",
                "Peru": "q1,0,g8,0,a0|q1,0,gc,0,a0||",
                "Philippines": "2lj,0,1f5,0,g8,0,a0|2lj,0,1f5,0,gc,0,a0||",
                "Phoenix_Islands": "158,0,1f5,0,a0|||",
                "Pierre_Miquelon": "pr,0,13m,0,2nv,0,qp,0,g8,0,a0|pr,0,13m,0,2nv,0,qp,0,gc,0,a0||",
                "Pitcairn": "9et,0,a0|||",
                "Ponape": "1v2,0,a0|||",
                "Pyongyang": "1u8,0,i1|||",
                "Reunion": "6gi,0,a0|||",
                "Rothera": "157,0,a0|||",
                "Sakhalin": "r8,0,g8,0,i1|r8,0,gc,0,a0||",
                "Samoa": "p0,0,g8,0,i1|p0,0,gc,0,a0||",
                "Seychelles": "73p,0,a0|||",
                "Singapore": "16b,0,a0|||",
                "Solomon": "4lp,0,1f5,0,a0|||",
                "South_Georgia": "729,0,9es,0,a0|||",
                "Suriname": "9eq,0,a0|||",
                "Syowa": "9ep,0,a0|||",
                "Tahiti": "1ma,0,a0|||",
                "Taipei": "45g,0,g8,0,i1|45g,0,gc,0,i1||",
                "Tajikistan": "9eo,0,a0|||",
                "Tokelau": "156,0,a0|||",
                "Tonga": "ot,0,g8,0,i1|ot,0,gc,0,a0||",
                "Truk": "1f2,0,a0|||",
                "Turkmenistan": "73v,0,g8,0,i1|73v,0,gc,0,a0||",
                "Tuvalu": "14j,0,a0|||",
                "Uruguay": "14a,0,g8,0,a0|14a,0,gc,0,a0||",
                "Uzbekistan": "3mu,0,g8,0,i1|3mu,0,gc,0,a0||",
                "Vanuatu": "os,0,g8,0,i1|os,0,gc,0,a0||",
                "Venezuela": "1mf,0,a0|||",
                "Vladivostok": "qn,0,g8,0,i1|qn,0,gc,0,a0||",
                "Volgograd": "q9,0,g8,0,i1|q9,0,gc,0,a0||",
                "Vostok": "155,0,a0|||",
                "Wake": "6j8,0,1f5,0,a0|||",
                "Wallis": "6ja,0,2nv,0,14l,0,a0|||",
                "Yakutsk": "14d,0,g8,0,i1|14d,0,gc,0,a0||",
                "Yekaterinburg": "136,0,g8,0,i1|136,0,gc,0,a0||"
            }
        },
        "lag": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "lb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cc1,0,fc||",
                "Europe/London": "|cc2,0,fc||"
            },
            "metazone": {
                "Acre": "3q,5,fn|3q,5,fc||",
                "Afghanistan": "b6,5,hp|||",
                "Africa_Central": "9en,0,hp|||",
                "Africa_Eastern": "9el,0,hp|||",
                "Africa_Southern": "9ej,0,hp|||",
                "Africa_Western": "746,0,fn|746,0,fc||",
                "Alaska": "3p,5,fn|3p,5,fc||",
                "Almaty": "56,5,fn|56,5,fc||",
                "Amazon": "bg,5,fn|bg,5,fc||",
                "America_Central": "2ci,0,178,5,fn|2ci,0,178,5,fc||",
                "America_Eastern": "2ci,0,179,5,fn|2ci,0,179,5,fc||",
                "America_Mountain": "145,5,5a,5,fn|145,5,5a,5,fc||",
                "America_Pacific": "2ci,0,17b,5,fn|2ci,0,17b,5,fc||",
                "Anadyr": "3l,5,fn|3l,5,fc||",
                "Arabian": "748,0,fn|748,0,fc||",
                "Argentina": "749,0,fn|749,0,fc||",
                "Argentina_Western": "74a,0,fn|74a,0,fc||",
                "Armenia": "74b,0,fn|74b,0,fc||",
                "Atlantic": "r2,5,fn|r2,5,fc||",
                "Australia_Central": "74c,0,fn|74c,0,fc||",
                "Australia_CentralWestern": "17e,5,74d,0,fn|17e,5,74d,0,fc||",
                "Australia_Eastern": "74e,0,fn|74e,0,fc||",
                "Australia_Western": "74f,0,fn|74f,0,fc||",
                "Azerbaijan": "9eg,0,fn|9e8,0,fc||",
                "Azores": "qv,5,fn|qv,5,fc||",
                "Bangladesh": "17i,5,fn|17i,5,fc||",
                "Bhutan": "as,5,hp|||",
                "Bolivia": "9e7,0,hp|||",
                "Brasilia": "in,5,fn|in,5,fc||",
                "Brunei": "9o,5,hp|||",
                "Cape_Verde": "1l8,5,3j,5,fn|1l8,5,3j,5,fc||",
                "Chamorro": "6j,5,hp|||",
                "Chatham": "27,5,fn|27,5,fc||",
                "Chile": "74j,0,fn|74j,0,fc||",
                "China": "74k,0,fn|74k,0,fc||",
                "Choibalsan": "2q,5,fn|2q,5,fc||",
                "Christmas": "9e1,5,hp|||",
                "Cocos": "9e0,5,hp|||",
                "Colombia": "74n,0,fn|74n,0,fc||",
                "Cook": "74o,5,fn|74o,5,fc||",
                "Cuba": "74p,0,fn|74p,0,fc||",
                "Davis": "5s,5,hp|||",
                "DumontDUrville": "5b,5,5j,5,hp|||",
                "East_Timor": "22p,5,hp|||",
                "Easter": "74q,5,fn|74q,5,fc||",
                "Ecuador": "9dv,0,hp|||",
                "Europe_Central": "74s,0,fn|74s,0,fc||",
                "Europe_Eastern": "74t,0,fn|74t,0,fc||",
                "Europe_Western": "74u,0,fn|74u,0,fc||",
                "Falkland": "74v,5,fn|74v,5,fc||",
                "Fiji": "17p,5,fn|17p,5,fc||",
                "French_Guiana": "750,5,9dt,5,hp|||",
                "French_Southern": "750,0,22k,5,0,nk,0,9ds,5,hp|||",
                "Galapagos": "ak,5,hp|||",
                "Gambier": "6v,5,hp|||",
                "Georgia": "753,0,fn|753,0,fc||",
                "Gilbert_Islands": "6q,5,9dr,5,hp|||",
                "GMT": "9dm,0,5u,5,hp|||",
                "Greenland_Eastern": "183,5,fn|183,5,fc||",
                "Greenland_Western": "184,5,fn|184,5,fc||",
                "Guam": "83,5,hp|||",
                "Gulf": "127,5,hp|||",
                "Guyana": "7a,5,hp|||",
                "Hawaii_Aleutian": "4g,5,185,5,fn|4g,5,185,5,fc||",
                "Hong_Kong": "2l,5,2p,5,fn|2l,5,2p,5,fc||",
                "Hovd": "2g,5,fn|2g,5,fc||",
                "India": "9dl,0,hp|||",
                "Indian_Ocean": "9dk,0,21v,5,hp|||",
                "Indochina": "9p,5,hp|||",
                "Indonesia_Central": "9dj,0,hp|||",
                "Indonesia_Eastern": "9di,0,hp|||",
                "Indonesia_Western": "9dh,0,hp|||",
                "Iran": "75b,0,fn|75b,0,fc||",
                "Irkutsk": "3h,5,fn|3h,5,fc||",
                "Israel": "75c,0,fn|75c,0,fc||",
                "Japan": "75d,0,fn|75d,0,fc||",
                "Kamchatka": "18c,5,fn|18c,5,fc||",
                "Kazakhstan_Eastern": "9de,0,hp|||",
                "Kazakhstan_Western": "9dd,0,hp|||",
                "Korea": "75g,0,fn|75g,0,fc||",
                "Kosrae": "6f,5,hp|||",
                "Krasnoyarsk": "l0,5,fn|l0,5,fc||",
                "Kyrgystan": "21g,5,hp|||",
                "Line_Islands": "9dc,5,hp|||",
                "Lord_Howe": "1u,5,24,5,fn|1u,5,24,5,fc||",
                "Macquarie": "214,5,hp|||",
                "Magadan": "2o,5,fn|2o,5,fc||",
                "Malaysia": "9db,0,hp|||",
                "Maldives": "9d7,5,hp|||",
                "Marquesas": "9e,5,hp|||",
                "Marshall_Islands": "9d6,5,hp|||",
                "Mauritius": "4u,5,fn|4u,5,fc||",
                "Mawson": "63,5,hp|||",
                "Mexico_Northwest": "75l,5,ro,5,fn|75l,5,ro,5,fc||",
                "Mexico_Pacific": "75m,0,75n,5,fn|75m,0,75n,5,fc||",
                "Mongolia": "p2,5,fn|p2,5,fc||",
                "Moscow": "131,0,fn|131,0,fc||",
                "Myanmar": "8v,5,hp|||",
                "Nauru": "5v,5,hp|||",
                "Nepal": "9d5,0,hp|||",
                "New_Caledonia": "75p,0,fn|75p,0,fc||",
                "New_Zealand": "75q,5,fn|75q,5,fc||",
                "Newfoundland": "75r,5,fn|75r,5,fc||",
                "Niue": "8g,5,hp|||",
                "Norfolk": "9d4,5,hp|||",
                "Noronha": "1s,5,2,5,23,5,fn|1s,5,2,5,23,5,fc||",
                "Novosibirsk": "16c,5,fn|16c,5,fc||",
                "Omsk": "26,5,fn|26,5,fc||",
                "Pakistan": "75t,0,fn|75t,0,fc||",
                "Palau": "9b,5,hp|||",
                "Papua_New_Guinea": "9a,5,9d3,5,hp|||",
                "Paraguay": "75v,0,fn|75v,0,fc||",
                "Peru": "760,0,fn|760,0,fc||",
                "Philippines": "761,0,fn|761,0,fc||",
                "Phoenix_Islands": "9d1,5,hp|||",
                "Pierre_Miquelon": "8i,5,33,5,a6,5,35,5,fn|8i,5,33,5,a6,5,35,5,fc||",
                "Pitcairn": "9cs,5,hp|||",
                "Ponape": "9s,5,hp|||",
                "Reunion": "i8,5,hp|||",
                "Rothera": "5q,5,hp|||",
                "Sakhalin": "55,5,fn|55,5,fc||",
                "Samara": "29,5,fn|29,5,fc||",
                "Samoa": "2c,5,fn|2c,5,fc||",
                "Seychelles": "1vo,5,hp|||",
                "Singapore": "m8,5,9cq|||",
                "Solomon": "9cp,5,hp|||",
                "South_Georgia": "9ck,0,hp|||",
                "Suriname": "8q,5,hp|||",
                "Syowa": "65,5,hp|||",
                "Tahiti": "8n,5,hp|||",
                "Taipei": "3d,5,fn|3d,5,fc||",
                "Tajikistan": "1vl,5,hp|||",
                "Tokelau": "5d,5,hp|||",
                "Tonga": "767,0,fn|767,0,fc||",
                "Truk": "6p,5,hp|||",
                "Turkmenistan": "4t,5,fn|4t,5,fc||",
                "Tuvalu": "6h,5,hp|||",
                "Uruguay": "9ci,0,fn|9ch,0,fc||",
                "Uzbekistan": "14m,5,fn|14m,5,fc||",
                "Vanuatu": "2f,5,fn|2f,5,fc||",
                "Venezuela": "7s,5,hp|||",
                "Vladivostok": "15m,5,fn|15m,5,fc||",
                "Volgograd": "qi,5,fn|qi,5,fc||",
                "Vostok": "1kt,5,hp|||",
                "Wake": "5r,5,1vg,5,hp|||",
                "Wallis": "5f,5,a6,5,5k,5,hp|||",
                "Yakutsk": "qj,5,fn|qj,5,fc||",
                "Yekaterinburg": "76a,5,fn|76a,5,fc||"
            }
        },
        "lg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "lkt": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ln-AO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ln-CF": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ln-CG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ln": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "lo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cc3||",
                "Europe/London": "|cc4||"
            },
            "metazone": {
                "Acre": "9cg|9cf||",
                "Afghanistan": "2cg,0,9ce|||",
                "Africa_Central": "9cd|||",
                "Africa_Eastern": "9cc|||",
                "Africa_Southern": "9c7|||",
                "Africa_Western": "9c6|9c5||",
                "Alaska": "9c4|9c2||",
                "Almaty": "9bv|9br||",
                "Amazon": "9bp|9bo||",
                "America_Central": "9bn|9bm||",
                "America_Eastern": "9bk|9bi||",
                "America_Mountain": "9bh|9bc||",
                "America_Pacific": "9ba|9b9||",
                "Apia": "9b7|9b5||",
                "Aqtau": "9b4|9b3||",
                "Aqtobe": "9b2|9b0||",
                "Arabian": "9at|9aq||",
                "Argentina": "9ap|9ao||",
                "Argentina_Western": "9am|9al||",
                "Armenia": "9ak|9aj||",
                "Atlantic": "9ai|9ah||",
                "Australia_Central": "9ag|9af||",
                "Australia_CentralWestern": "9ae|9ad||",
                "Australia_Eastern": "9ac|9ab||",
                "Australia_Western": "9aa|9a9||",
                "Azerbaijan": "9a8|9a7||",
                "Azores": "9a6|9a5||",
                "Bangladesh": "77u,0,77v|2cg,0,9a4,0,77v||",
                "Bhutan": "9a3|||",
                "Bolivia": "9a2|||",
                "Brasilia": "9a1|9a0||",
                "Brunei": "99v|||",
                "Cape_Verde": "99u|99s||",
                "Casey": "99r|||",
                "Chamorro": "99q|||",
                "Chatham": "99p|99o||",
                "Chile": "99n|99m||",
                "China": "99l|99k||",
                "Choibalsan": "99j|99i||",
                "Christmas": "99h|||",
                "Cocos": "99g|||",
                "Colombia": "99f|99e||",
                "Cook": "99d|99c||",
                "Cuba": "99b|99a||",
                "Davis": "999|||",
                "DumontDUrville": "997|||",
                "East_Timor": "996|||",
                "Easter": "995|994||",
                "Ecuador": "990|||",
                "Europe_Central": "98v|98u||",
                "Europe_Eastern": "98t|98s||",
                "Europe_Further_Eastern": "98r|||",
                "Europe_Western": "98q|98p||",
                "Falkland": "98o|98n||",
                "Fiji": "98m|98l||",
                "French_Guiana": "98k|||",
                "French_Southern": "98j,0,79d,0,98i|||",
                "Galapagos": "98h|||",
                "Gambier": "98g|||",
                "Georgia": "98f|98e||",
                "Gilbert_Islands": "98d|||",
                "GMT": "98c|||",
                "Greenland_Eastern": "98b|98a||",
                "Greenland_Western": "989|988||",
                "Guam": "987|||",
                "Gulf": "986|||",
                "Guyana": "985|||",
                "Hawaii_Aleutian": "984,5,79t|983,5,79t||",
                "Hong_Kong": "982|981||",
                "Hovd": "980|97v||",
                "India": "2cg,0,97u|||",
                "Indian_Ocean": "97t|||",
                "Indochina": "97s|||",
                "Indonesia_Central": "97r|||",
                "Indonesia_Eastern": "97q|||",
                "Indonesia_Western": "97p|||",
                "Iran": "97o|97n||",
                "Irkutsk": "97m|97l||",
                "Israel": "97k|97j||",
                "Japan": "97i|97h||",
                "Kazakhstan_Eastern": "97g|||",
                "Kazakhstan_Western": "97f|||",
                "Korea": "97e|97d||",
                "Kosrae": "97c|||",
                "Krasnoyarsk": "97b|97a||",
                "Kyrgystan": "979|||",
                "Lanka": "978|||",
                "Line_Islands": "977|||",
                "Lord_Howe": "976|975||",
                "Macau": "974|973||",
                "Macquarie": "972|||",
                "Magadan": "971|970||",
                "Malaysia": "96v|||",
                "Maldives": "96u|||",
                "Marquesas": "96t|||",
                "Marshall_Islands": "96s|||",
                "Mauritius": "96r|96q||",
                "Mawson": "96p|||",
                "Mexico_Northwest": "96o|96n||",
                "Mexico_Pacific": "96m|96l||",
                "Mongolia": "77u,0,96k|96j||",
                "Moscow": "96i|96h||",
                "Myanmar": "96g|||",
                "Nauru": "96f|||",
                "Nepal": "96e|||",
                "New_Caledonia": "96d|96c||",
                "New_Zealand": "96b|96a||",
                "Newfoundland": "969|968||",
                "Niue": "967|||",
                "Norfolk": "966|||",
                "Noronha": "965|964||",
                "North_Mariana": "963|||",
                "Novosibirsk": "962|961||",
                "Omsk": "960|95v||",
                "Pakistan": "95u|95t||",
                "Palau": "95s|||",
                "Papua_New_Guinea": "95r|||",
                "Paraguay": "95q|95p||",
                "Peru": "95o|95n||",
                "Philippines": "95m|95l||",
                "Phoenix_Islands": "95k|||",
                "Pierre_Miquelon": "95j,0,7cf|95i,0,7cf||",
                "Pitcairn": "95h|||",
                "Ponape": "95g|||",
                "Pyongyang": "95f|||",
                "Qyzylorda": "95e|95d||",
                "Reunion": "95c|||",
                "Rothera": "2cg,0,95b|||",
                "Sakhalin": "95a|959||",
                "Samoa": "958|957||",
                "Seychelles": "956|||",
                "Singapore": "955|||",
                "Solomon": "954|||",
                "South_Georgia": "953|||",
                "Suriname": "952|||",
                "Syowa": "2cg,0,951|||",
                "Tahiti": "950|||",
                "Taipei": "94v|94u||",
                "Tajikistan": "94t|||",
                "Tokelau": "94s|||",
                "Tonga": "94r|94q||",
                "Truk": "94p|||",
                "Turkmenistan": "94o|94n||",
                "Tuvalu": "94m|||",
                "Uruguay": "94l|94k||",
                "Uzbekistan": "94j|94i||",
                "Vanuatu": "94h|94g||",
                "Venezuela": "94f|||",
                "Vladivostok": "94e|94d||",
                "Volgograd": "94c|94b||",
                "Vostok": "2cg,0,94a|||",
                "Wake": "949|||",
                "Wallis": "948,0,79d,0,945|||",
                "Yakutsk": "944|943||",
                "Yekaterinburg": "942|941||"
            }
        },
        "lrc-IQ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "America_Central": "7e0,0,7e1,0,7e2|7e3,0,7e4,0,7e5||"
            }
        },
        "lrc": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "America_Central": "7e0,0,7e1,0,7e2|7e3,0,7e4,0,7e5||"
            }
        },
        "lt": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cc5,0,ed,0,64||",
                "Europe/London": "|cc6,0,ed,0,64||"
            },
            "metazone": {
                "Acre": "7e6,0,940,0,64|7e6,0,ed,0,64||",
                "Afghanistan": "93p,0,64|||",
                "Africa_Central": "2f7,0,2tb,0,64|||",
                "Africa_Eastern": "24q,0,2tb,0,64|||",
                "Africa_Southern": "4id,0,2tb,0,64|||",
                "Africa_Western": "1gq,0,2tb,0,es,0,64|1gq,0,2tb,0,ed,0,64||",
                "Alaska": "7eh,0,es,0,64|7eh,0,ed,0,64||",
                "Almaty": "7ei,0,es,0,64|7ei,0,ed,0,64||",
                "Amazon": "7ej,0,es,0,64|7ej,0,ed,0,64||",
                "America_Central": "1kl,0,24p,0,7em,0,es,0,64|1kl,0,24p,0,7em,0,ed,0,64||",
                "America_Eastern": "1kl,0,24p,0,2rs,0,es,0,64|1kl,0,24p,0,2rs,0,ed,0,64||",
                "America_Mountain": "1kl,0,24p,0,7eo,0,es,0,64|1kl,0,24p,0,7eo,0,ed,0,64||",
                "America_Pacific": "1kl,0,24p,0,4at,0,2r4,0,es,0,64|1kl,0,24p,0,4at,0,2r4,0,ed,0,64||",
                "Anadyr": "7er,0,es,0,64|7er,0,ed,0,64||",
                "Apia": "7es,0,es,0,64|7es,0,ed,0,64||",
                "Aqtau": "i4,0,es,0,64|i4,0,ed,0,64||",
                "Aqtobe": "7et,0,es,0,64|7et,0,ed,0,64||",
                "Arabian": "7eu,0,es,0,64|7eu,0,ed,0,64||",
                "Argentina": "4av,0,es,0,64|4av,0,ed,0,64||",
                "Argentina_Western": "1gq,0,4av,0,es,0,64|1gq,0,4av,0,ed,0,64||",
                "Armenia": "7f0,0,es,0,64|7f0,0,ed,0,64||",
                "Atlantic": "7f1,0,es,0,64|7f1,0,ed,0,64||",
                "Australia_Central": "2f7,0,249,0,es,0,64|2f7,0,249,0,ed,0,64||",
                "Australia_CentralWestern": "2f7,0,4b1,0,249,0,es,0,64|2f7,0,4b1,0,249,0,ed,0,64||",
                "Australia_Eastern": "24q,0,249,0,es,0,64|24q,0,249,0,ed,0,64||",
                "Australia_Western": "1gq,0,249,0,es,0,64|1gq,0,249,0,ed,0,64||",
                "Azerbaijan": "7f4,0,es,0,64|7f4,0,ed,0,64||",
                "Azores": "7f5,0,19u,0,es,0,64|7f5,0,19u,0,ed,0,64||",
                "Bangladesh": "7f7,0,es,0,64|7f7,0,ed,0,64||",
                "Bhutan": "93o,0,64|||",
                "Bolivia": "93n,0,64|||",
                "Brasilia": "7fa,0,es,0,64|7fa,0,ed,0,64||",
                "Brunei": "93j,0,93i,0,64|||",
                "Cape_Verde": "7fd,0,7fe,0,es,0,64|7fd,0,7fe,0,ed,0,64||",
                "Casey": "93h,0,64|||",
                "Chamorro": "2nf,0,64|||",
                "Chatham": "7fh,0,es,0,64|7fh,0,ed,0,64||",
                "Chile": "7fi,0,es,0,64|7fi,0,ed,0,64||",
                "China": "7fj,0,es,0,64|7fj,0,ed,0,64||",
                "Choibalsan": "7fk,0,es,0,64|7fk,0,ed,0,64||",
                "Christmas": "93g,0,4b8,0,64|||",
                "Cocos": "93f,0,19u,0,64|||",
                "Colombia": "7fo,0,es,0,64|7fo,0,ed,0,64||",
                "Cook": "7fp,0,19u,0,es,0,64|7fp,0,19u,0,93e,0,ed,0,64||",
                "Cuba": "7fr,0,es,0,64|7fr,0,ed,0,64||",
                "Davis": "93d,0,64|||",
                "DumontDUrville": "93c,0,93b,0,64|||",
                "East_Timor": "24q,0,93a,0,64|||",
                "Easter": "7g0,0,939,0,es,0,64|7g0,0,4b8,0,ed,0,64||",
                "Ecuador": "938,0,64|||",
                "Europe_Central": "7g3,0,267,0,es,0,64|7g3,0,267,0,ed,0,64||",
                "Europe_Eastern": "24q,0,267,0,es,0,64|24q,0,267,0,ed,0,64||",
                "Europe_Further_Eastern": "937,0,2rs,0,267,0,64|||",
                "Europe_Western": "1gq,0,267,0,es,0,64|1gq,0,267,0,ed,0,64||",
                "Falkland": "936,0,19u,0,es,0,64|935,0,19u,0,ed,0,64||",
                "Fiji": "7g8,0,es,0,64|7g8,0,ed,0,64||",
                "French_Guiana": "7g9,0,934,0,64|||",
                "French_Southern": "4id,0,7g9,0,4bm,0,933,0,64|||",
                "Galapagos": "932,0,64|||",
                "Gambier": "931,0,64|||",
                "Georgia": "7gf,0,es,0,64|7gf,0,ed,0,64||",
                "Gilbert_Islands": "7gg,0,19u,0,64|||",
                "GMT": "930,0,64|||",
                "Greenland_Eastern": "4br,0,2rs,0,es,0,64|4br,0,2rs,0,ed,0,64||",
                "Greenland_Western": "4br,0,4b1,0,es,0,64|4br,0,4b1,0,ed,0,64||",
                "Guam": "92v,0,64|||",
                "Gulf": "92u,0,92t,0,64|||",
                "Guyana": "92s,0,64|||",
                "Hawaii_Aleutian": "7gn,0,es,0,64|7gn,0,ed,0,64||",
                "Hong_Kong": "7go,0,es,0,64|7go,0,ed,0,64||",
                "Hovd": "7gp,0,es,0,64|7gp,0,ed,0,64||",
                "India": "7gq,0,64|||",
                "Indian_Ocean": "7gq,0,2r4,0,64|||",
                "Indochina": "92r,0,64|||",
                "Indonesia_Central": "2f7,0,4i8,0,64|||",
                "Indonesia_Eastern": "24q,0,4i8,0,64|||",
                "Indonesia_Western": "1gq,0,4i8,0,64|||",
                "Iran": "7gt,0,es,0,64|7gt,0,ed,0,64||",
                "Irkutsk": "2c5,0,es,0,64|2c5,0,ed,0,64||",
                "Israel": "7gu,0,es,0,64|7gu,0,ed,0,64||",
                "Japan": "7gv,0,es,0,64|7gv,0,ed,0,64||",
                "Kamchatka": "7h0,0,1gd,0,es,0,64|7h0,0,1gd,0,ed,0,64||",
                "Kazakhstan_Eastern": "24q,0,7h1,0,64|||",
                "Kazakhstan_Western": "1gq,0,7h1,0,64|||",
                "Korea": "7h2,0,es,0,64|7h2,0,ed,0,64||",
                "Kosrae": "92q,0,64|||",
                "Krasnoyarsk": "2br,0,es,0,64|2br,0,ed,0,64||",
                "Kyrgystan": "92p,0,64|||",
                "Lanka": "92o,0,64|||",
                "Line_Islands": "92n,0,19u,0,64|||",
                "Lord_Howe": "7h7,0,1i2,0,es,0,64|7h7,0,1i2,0,ed,0,64||",
                "Macau": "36a,0,es,0,64|36a,0,ed,0,64||",
                "Macquarie": "92m,0,4b8,0,64|||",
                "Magadan": "7h9,0,es,0,64|7h9,0,ed,0,64||",
                "Malaysia": "92l,0,64|||",
                "Maldives": "92k,0,64|||",
                "Marquesas": "92j,0,19u,0,64|||",
                "Marshall_Islands": "92i,0,19u,0,64|||",
                "Mauritius": "7he,0,es,0,64|7he,0,ed,0,64||",
                "Mawson": "92h,0,64|||",
                "Mexico_Northwest": "1kl,0,1gq,0,4cc,0,es,0,64|1kl,0,1gq,0,4cc,0,ed,0,64||",
                "Mexico_Pacific": "4cc,0,4at,0,2r4,0,es,0,64|4cc,0,4at,0,2r4,0,ed,0,64||",
                "Mongolia": "4f,0,7hh,0,es,0,64|4f,0,7hh,0,ed,0,64||",
                "Moscow": "7hi,0,es,0,64|7hi,0,ed,0,64||",
                "Myanmar": "92g,0,64|||",
                "Nauru": "5v,0,64|||",
                "Nepal": "92f,0,64|||",
                "New_Caledonia": "2r3,0,7hm,0,es,0,64|2r3,0,7hm,0,ed,0,64||",
                "New_Zealand": "2r3,0,7hn,0,es,0,64|2r3,0,7hn,0,ed,0,64||",
                "Newfoundland": "7ho,0,es,0,64|7ho,0,ed,0,64||",
                "Niue": "92e,0,64|||",
                "Norfolk": "92d,0,19u,0,64|||",
                "Noronha": "1s,0,2,0,7hr,0,es,0,64|1s,0,2,0,7hr,0,ed,0,64||",
                "North_Mariana": "1kl,0,92c,0,19u,0,64|||",
                "Novosibirsk": "2b8,0,es,0,64|2b8,0,ed,0,64||",
                "Omsk": "2b1,0,es,0,64|2b1,0,ed,0,64||",
                "Pakistan": "7ht,0,es,0,64|7ht,0,ed,0,64||",
                "Palau": "9b,0,64|||",
                "Papua_New_Guinea": "9a,0,2r3,0,92b,0,64|||",
                "Paraguay": "7hv,0,es,0,64|7hv,0,ed,0,64||",
                "Peru": "4n,0,es,0,64|4n,0,ed,0,64||",
                "Philippines": "7i0,0,es,0,64|7i0,0,ed,0,64||",
                "Phoenix_Islands": "92a,0,19u,0,64|||",
                "Pierre_Miquelon": "14i,0,7i2,0,4bm,0,7i3,0,es,0,64|14i,0,7i2,0,4bm,0,7i3,0,ed,0,64||",
                "Pitcairn": "929,0,64|||",
                "Ponape": "928,0,64|||",
                "Pyongyang": "927,0,64|||",
                "Qyzylorda": "7i7,0,es,0,64|7i7,0,ed,0,64||",
                "Reunion": "926,0,64|||",
                "Rothera": "925,0,64|||",
                "Sakhalin": "7ia,0,es,0,64|7ia,0,ed,0,64||",
                "Samara": "7ib,0,es,0,64|7ib,0,ed,0,64||",
                "Samoa": "2c,0,es,0,64|2c,0,ed,0,64||",
                "Seychelles": "924,0,64|||",
                "Singapore": "923,0,64|||",
                "Solomon": "922,0,19u,0,64|||",
                "South_Georgia": "4id,0,921,0,64|||",
                "Suriname": "920,0,64|||",
                "Syowa": "91v,0,64|||",
                "Tahiti": "91r,0,64|||",
                "Taipei": "7ij,0,es,0,64|7ij,0,ed,0,64||",
                "Tajikistan": "91p,0,64|||",
                "Tokelau": "5d,0,64|||",
                "Tonga": "7il,0,es,0,64|7il,0,ed,0,64||",
                "Truk": "91o,0,64|||",
                "Turkmenistan": "7in,0,es,0,64|7in,0,ed,0,64||",
                "Tuvalu": "6h,0,64|||",
                "Uruguay": "7io,0,es,0,64|7io,0,ed,0,64||",
                "Uzbekistan": "7ip,0,es,0,64|7ip,0,ed,0,64||",
                "Vanuatu": "2f,0,es,0,64|2f,0,ed,0,64||",
                "Venezuela": "91n,0,64|||",
                "Vladivostok": "7ir,0,es,0,64|7ir,0,ed,0,64||",
                "Volgograd": "ff,0,es,0,64|ff,0,ed,0,64||",
                "Vostok": "91m,0,64|||",
                "Wake": "91l,0,4b8,0,64|||",
                "Wallis": "91k,0,4bm,0,91i,0,64|||",
                "Yakutsk": "2ap,0,es,0,64|2ap,0,ed,0,64||",
                "Yekaterinburg": "7j0,0,es,0,64|7j0,0,ed,0,64||"
            }
        },
        "lu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "luo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "luy": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "lv": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cc7,0,f7,0,7b||",
                "Europe/London": "|cc8,0,fh,0,7b||"
            },
            "metazone": {
                "Afghanistan": "91g,0,7b|||",
                "Africa_Central": "91b,0,7b|||",
                "Africa_Eastern": "91a,0,7b|||",
                "Africa_Southern": "918,0,f7,0,7b|||",
                "Africa_Western": "7j7,0,f7,0,7b|7j7,0,fh,0,7b||",
                "Alaska": "7j9,0,f7,0,7b|7j9,0,fh,0,7b||",
                "Amazon": "7ja,0,f7,0,7b|7ja,0,fh,0,7b||",
                "America_Central": "7jb,0,f7,0,7b|7jb,0,fh,0,7b||",
                "America_Eastern": "7jc,0,f7,0,7b|7jc,0,fh,0,7b||",
                "America_Mountain": "7jd,0,f7,0,7b|7jd,0,fh,0,7b||",
                "America_Pacific": "4d6,0,2p8,0,f7,0,7b|4d6,0,2p8,0,fh,0,7b||",
                "Anadyr": "7jg,0,f7,0,7b|7jg,0,fh,0,7b||",
                "Apia": "7jh,0,f7,0,7b|7jh,0,fh,0,7b||",
                "Arabian": "7ji,0,7jj,0,f7,0,7b|7ji,0,7jj,0,fh,0,7b||",
                "Argentina": "7jk,0,f7,0,7b|7jk,0,fh,0,7b||",
                "Argentina_Western": "7jl,0,f7,0,7b|7jl,0,fh,0,7b||",
                "Armenia": "7jm,0,f7,0,7b|7jm,0,fh,0,7b||",
                "Atlantic": "7jn,0,f7,0,7b|7jn,0,fh,0,7b||",
                "Australia_Central": "23i,0,4d9,0,f7,0,7b|23i,0,4d9,0,fh,0,7b||",
                "Australia_CentralWestern": "23i,0,4d9,0,4da,0,f7,0,7b|23i,0,4d9,0,4da,0,fh,0,7b||",
                "Australia_Eastern": "23i,0,7jr,0,f7,0,7b|23i,0,7jr,0,fh,0,7b||",
                "Australia_Western": "23i,0,4da,0,f7,0,7b|23i,0,4da,0,fh,0,7b||",
                "Azerbaijan": "7js,0,f7,0,7b|7js,0,fh,0,7b||",
                "Azores": "7jt,0,19j,0,f7,0,7b|7jt,0,19j,0,fh,0,7b||",
                "Bangladesh": "7jv,0,f7,0,7b|7jv,0,fh,0,7b||",
                "Bhutan": "917,0,7b|||",
                "Bolivia": "916,0,7b|||",
                "Brasilia": "7k2,0,f7,0,7b|7k2,0,fh,0,7b||",
                "Brunei": "914,0,913,0,7b|||",
                "Cape_Verde": "7k5,0,f7,0,7b|7k5,0,fh,0,7b||",
                "Chamorro": "912,0,f7,0,7b|||",
                "Chatham": "7k7,0,f7,0,7b|7k7,0,fh,0,7b||",
                "Chile": "7k8,0,f7,0,7b|7k8,0,fh,0,7b||",
                "China": "7k9,0,f7,0,7b|7k9,0,fh,0,7b||",
                "Choibalsan": "7ka,0,f7,0,7b|7ka,0,fh,0,7b||",
                "Christmas": "911,0,23e,0,7b|||",
                "Cocos": "90q,0,90p,0,19j,0,7b|||",
                "Colombia": "7kf,0,f7,0,7b|7kf,0,fh,0,7b||",
                "Cook": "4dg,0,19j,0,f7,0,7b|4dg,0,19j,0,fh,0,7b||",
                "Cuba": "7kh,0,f7,0,7b|7kh,0,fh,0,7b||",
                "Davis": "90o,0,7b|||",
                "DumontDUrville": "90n,0,7b|||",
                "East_Timor": "90m,0,7b|||",
                "Easter": "7kl,0,23e,0,f7,0,7b|7kl,0,23e,0,fh,0,7b||",
                "Ecuador": "90l,0,7b|||",
                "Europe_Central": "7kn,0,f7,0,7b|7kn,0,fh,0,7b|h0|h4",
                "Europe_Eastern": "4i4,0,f7,0,7b|4i4,0,fh,0,7b|gu|gv",
                "Europe_Further_Eastern": "4i4,0,90k,0,90i,0,90e|||",
                "Europe_Western": "7ks,0,f7,0,7b|7ks,0,fh,0,7b|h1|h2",
                "Falkland": "7kt,0,7ku,0,19j,0,f7,0,7b|7kt,0,7ku,0,19j,0,fh,0,7b||",
                "Fiji": "1fh,0,f7,0,7b|1fh,0,fh,0,7b||",
                "French_Guiana": "90d,0,90c,0,7b|||",
                "French_Southern": "909,0,908,0,4dm,0,906,0,901,0,7b|||",
                "Galapagos": "900,0,7b|||",
                "Gambier": "8vv,0,19j,0,7b|||",
                "Georgia": "7l8,0,f7,0,7b|7l8,0,fh,0,7b||",
                "Gilbert_Islands": "4i0,0,19j,0,7b|||",
                "GMT": "8vu,0,7b|||",
                "Greenland_Eastern": "7lb,0,f7,0,7b|7lb,0,fh,0,7b||",
                "Greenland_Western": "7lc,0,f7,0,7b|7lc,0,fh,0,7b||",
                "Gulf": "8vs,0,8vr,0,7b|||",
                "Guyana": "8vq,0,7b|||",
                "Hawaii_Aleutian": "7lg,0,f7,0,7b|7lg,0,fh,0,7b||",
                "Hong_Kong": "7lh,0,f7,0,7b|7lh,0,fh,0,7b||",
                "Hovd": "7li,0,f7,0,7b|7li,0,fh,0,7b||",
                "India": "7lj,0,f7,0,7b|||",
                "Indian_Ocean": "7lj,0,2p8,0,7b|||",
                "Indochina": "8vp,0,7b|||",
                "Indonesia_Central": "8vo,0,7b|||",
                "Indonesia_Eastern": "8vn,0,7b|||",
                "Indonesia_Western": "8vm,0,7b|||",
                "Iran": "7lo,0,f7,0,7b|7lo,0,fh,0,7b||",
                "Irkutsk": "7lp,0,f7,0,7b|7lp,0,fh,0,7b||",
                "Israel": "7lq,0,f7,0,7b|7lq,0,fh,0,7b||",
                "Japan": "7lr,0,f7,0,7b|7lr,0,fh,0,7b||",
                "Kamchatka": "7ls,5,7lt,0,f7,0,7b|7ls,5,7lt,0,fh,0,7b||",
                "Kazakhstan_Eastern": "8vi,0,7b|||",
                "Kazakhstan_Western": "8vh,0,7b|||",
                "Korea": "7m0,0,f7,0,7b|7m0,0,fh,0,7b||",
                "Kosrae": "6f,0,7b|||",
                "Krasnoyarsk": "7m1,0,f7,0,7b|7m1,0,fh,0,7b||",
                "Kyrgystan": "8vg,0,7b|||",
                "Line_Islands": "8ve,0,19j,0,7b|||",
                "Lord_Howe": "7m4,0,7m5,0,23e,0,f7,0,7b|7m4,0,7m5,0,23e,0,fh,0,7b||",
                "Macquarie": "8vd,0,23e,0,7b|||",
                "Magadan": "7m7,0,f7,0,7b|7m7,0,fh,0,7b||",
                "Malaysia": "8vc,0,7b|||",
                "Maldives": "8va,0,7b|||",
                "Marquesas": "8v9,0,19j,0,7b|||",
                "Marshall_Islands": "8v8,0,19j,0,7b|||",
                "Mauritius": "7mc,0,f7,0,7b|7mc,0,fh,0,7b||",
                "Mawson": "8v7,0,7b|||",
                "Mexico_Northwest": "7me,0,4ds,0,f7,0,7b|7me,0,4ds,0,fh,0,7b||",
                "Mexico_Pacific": "4ds,0,4d6,0,2p8,0,7mg,0,f7,0,7b|4ds,0,4d6,0,2p8,0,7mg,0,fh,0,7b||",
                "Mongolia": "7mh,0,f7,0,7b|7mh,0,fh,0,7b||",
                "Moscow": "7mi,0,f7,0,7b|7mi,0,fh,0,7b||",
                "Myanmar": "8v6,0,7b|||",
                "Nauru": "5v,0,7b|||",
                "Nepal": "8v5,0,7b|||",
                "New_Caledonia": "7ml,0,f7,0,7b|7ml,0,fh,0,7b||",
                "New_Zealand": "7mm,0,f7,0,7b|7mm,0,fh,0,7b||",
                "Newfoundland": "7mn,0,f7,0,7b|7mn,0,fh,0,7b||",
                "Niue": "8g,0,7b|||",
                "Norfolk": "8v4,0,23e,0,7b|||",
                "Noronha": "23a,0,4i,0,7mq,0,f7,0,7b|23a,0,4i,0,7mq,0,fh,0,7b||",
                "Novosibirsk": "7mr,0,f7,0,7b|7mr,0,fh,0,7b||",
                "Omsk": "7ms,0,f7,0,7b|7ms,0,fh,0,7b||",
                "Pakistan": "7mt,0,f7,0,7b|7mt,0,fh,0,7b||",
                "Palau": "9b,0,7b|||",
                "Papua_New_Guinea": "9a,5,8v3,0,7b|||",
                "Paraguay": "7mv,0,f7,0,7b|7mv,0,fh,0,7b||",
                "Peru": "4n,0,f7,0,7b|4n,0,fh,0,7b||",
                "Philippines": "7n0,0,f7,0,7b|7n0,0,fh,0,7b||",
                "Phoenix_Islands": "8v2,0,19j,0,7b|||",
                "Pierre_Miquelon": "7n2,0,4dm,0,7n3,0,f7,0,7b|7n2,0,4dm,0,7n3,0,fh,0,7b||",
                "Pitcairn": "8v1,0,7b|||",
                "Ponape": "8v0,0,7b|||",
                "Pyongyang": "8uv,0,7b|||",
                "Reunion": "8uu,0,7b|||",
                "Rothera": "8ut,0,7b|||",
                "Sakhalin": "7n9,0,f7,0,7b|7n9,0,fh,0,7b||",
                "Samara": "7na,0,f7,0,7b|7na,0,fh,0,7b||",
                "Samoa": "2c,0,f7,0,7b|2c,0,fh,0,7b||",
                "Seychelles": "8us,0,19j,0,7b|||",
                "Singapore": "8ur,0,7b|||",
                "Solomon": "8uq,0,19j,0,7b|||",
                "South_Georgia": "8up,0,7b|||",
                "Suriname": "8uo,0,7b|||",
                "Syowa": "8un,0,7b|||",
                "Tahiti": "198,0,7b|||",
                "Taipei": "7ni,0,f7,0,7b|7ni,0,fh,0,7b||",
                "Tajikistan": "8um,0,7b|||",
                "Tokelau": "5d,0,7b|||",
                "Tonga": "7nk,0,f7,0,7b|7nk,0,fh,0,7b||",
                "Truk": "8ul,0,7b|||",
                "Turkmenistan": "7nm,0,f7,0,7b|7nm,0,fh,0,7b||",
                "Tuvalu": "6h,0,7b|||",
                "Uruguay": "7nn,0,f7,0,7b|7nn,0,fh,0,7b||",
                "Uzbekistan": "7no,0,f7,0,7b|7no,0,fh,0,7b||",
                "Vanuatu": "2f,0,f7,0,7b|2f,0,fh,0,7b||",
                "Venezuela": "8uh,0,7b|||",
                "Vladivostok": "7nq,0,f7,0,7b|7nq,0,fh,0,7b||",
                "Volgograd": "7nr,0,f7,0,7b|7nr,0,fh,0,7b||",
                "Vostok": "8ug,0,7b|||",
                "Wake": "8uf,0,23e,0,7b|||",
                "Wallis": "8ue,0,4dm,0,8ud,0,7b|||",
                "Yakutsk": "7o0,0,f7,0,7b|7o0,0,fh,0,7b||",
                "Yekaterinburg": "7o1,0,f7,0,7b|7o1,0,fh,0,7b||"
            }
        },
        "mas-TZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mas": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mer": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mfe": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mgh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mgo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "mk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cc9,0,1q,0,m||",
                "Europe/London": "|6r5,0,1de,0,m||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,1de,0,od,0,ag,0,oe||",
                "Afghanistan": "i7,0,9l,0,2ak|||",
                "Africa_Central": "8uc,0,m|||",
                "Africa_Eastern": "8ub,0,m|||",
                "Africa_Southern": "i7,0,9l,0,8ua,0,8u9|||",
                "Africa_Western": "3q7,0,1q,0,m|3q7,0,1de,0,od,0,ag,0,oe||",
                "Alaska": "hi,0,m,0,9l,0,1jf|hl,0,od,0,ag,0,oe,0,9l,0,1jf||",
                "Amazon": "hi,0,m,0,9l,0,15q|hl,0,od,0,ag,0,oe,0,9l,0,15q||",
                "America_Central": "18j,0,1q,0,m,0,9l,0,26j,0,m6|18j,0,1de,0,od,0,ag,0,oe||",
                "America_Eastern": "12g,0,1q,0,m|12g,0,1de,0,od,0,ag,0,oe||",
                "America_Mountain": "4e3,0,1q,0,m|4e3,0,1de,0,od,0,ag,0,oe||",
                "America_Pacific": "4dv,0,1q,0,m|4dv,0,1de,0,od,0,ag,0,oe||",
                "Anadyr": "7og,0,1q,0,m|7og,0,1de,0,m||",
                "Apia": "hi,0,m,0,9l,0,1jh|hl,0,m,0,9l,0,1jh||",
                "Arabian": "hi,0,8u8,0,m|8u7,0,1de,0,od,0,ag,0,oe||",
                "Argentina": "hi,0,m,0,9l,0,hk|hl,0,od,0,ag,0,oe,0,9l,0,hk||",
                "Argentina_Western": "hi,0,m,0,9l,0,7ok,0,hk|hl,0,od,0,ag,0,oe,0,9l,0,7ok,0,hk||",
                "Armenia": "hi,0,m,0,9l,0,7ol|hl,0,m,0,9l,0,7ol||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,1de,0,od,0,ag,0,oe||",
                "Australia_Central": "hi,0,m,0,9l,0,2lu,0,218|hl,0,od,0,ag,0,oe,0,9l,0,2lu,0,218||",
                "Australia_CentralWestern": "hi,0,m,0,9l,0,2lu,0,il,0,16h,0,218|hl,0,od,0,ag,0,oe,0,9l,0,2lu,0,il,0,16h,0,218||",
                "Australia_Eastern": "hi,0,m,0,9l,0,4hl,0,218|hl,0,od,0,ag,0,oe,0,9l,0,4hl,0,218||",
                "Australia_Western": "hi,0,m,0,9l,0,16h,0,218|hl,0,od,0,ag,0,oe,0,9l,0,16h,0,218||",
                "Azerbaijan": "hi,0,m,0,9l,0,1b0|hl,0,m,0,9l,0,1b0||",
                "Azores": "hi,0,m,0,9l,0,7oq|hl,0,m,0,9l,0,7oq||",
                "Bangladesh": "hi,0,m,0,9l,0,q8|hl,0,m,0,9l,0,q8||",
                "Bhutan": "i7,0,9l,0,16f|||",
                "Bolivia": "i7,0,9l,0,27a|||",
                "Brasilia": "hi,0,m,0,9l,0,1bo|hl,0,od,0,ag,0,oe,0,9l,0,1bo||",
                "Brunei": "i7,0,9l,0,8u6,0,8u5|||",
                "Cape_Verde": "hi,0,m,0,ag,0,7ot,0,7ou|hl,0,m,0,ag,0,7ot,0,7ou||",
                "Chamorro": "i7,0,9l,0,1u7|||",
                "Chatham": "hi,0,m,0,9l,0,7ov|hl,0,od,0,ag,0,oe,0,9l,0,7ov||",
                "Chile": "hi,0,m,0,9l,0,1c0|hl,0,od,0,ag,0,oe,0,9l,0,1c0||",
                "China": "hi,0,m,0,9l,0,22t|hl,0,od,0,ag,0,oe,0,9l,0,22t||",
                "Choibalsan": "hi,0,m,0,9l,0,1c6|hl,0,m,0,9l,0,1c6||",
                "Christmas": "i7,0,ag,0,8u4,0,4l2|||",
                "Cocos": "i7,0,ag,0,8u3,0,1uj|||",
                "Colombia": "hi,0,m,0,9l,0,1ck|hl,0,od,0,ag,0,oe,0,9l,0,1ck||",
                "Cook": "hi,0,m,0,ag,0,30j,0,1p2|hl,0,m,0,ag,0,30j,0,1p2||",
                "Cuba": "hi,0,m,0,9l,0,ql|hl,0,od,0,ag,0,oe,0,9l,0,ql||",
                "Davis": "i7,0,9l,0,277|||",
                "DumontDUrville": "i7,0,9l,0,276,5,8u2|||",
                "East_Timor": "i7,0,9l,0,4f2,0,1ki|||",
                "Easter": "hi,0,m,0,ag,0,7p5,0,3qa|hl,0,m,0,ag,0,7p5,0,3qa||",
                "Ecuador": "i7,0,9l,0,274|||",
                "Europe_Central": "7p6,0,1q,0,m|7p6,0,1de,0,m|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,1de,0,m|gu|gv",
                "Europe_Further_Eastern": "8u1,0,m|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,1de,0,m|h1|h2",
                "Falkland": "hi,0,m,0,ag,0,7p8,0,1uj|hl,0,od,0,ag,0,oe,0,ag,0,7p8,0,1uj||",
                "Fiji": "hi,0,m,0,9l,0,1dn|hl,0,m,0,9l,0,1dn||",
                "French_Guiana": "i7,0,9l,0,272,0,1dr|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "i7,0,9l,0,1na|||",
                "Gambier": "i7,0,9l,0,8u0|||",
                "Georgia": "hi,0,m,0,9l,0,19a|hl,0,m,0,9l,0,19a||",
                "Gilbert_Islands": "i7,0,ag,0,30j,0,1ne|||",
                "GMT": "66b,0,m,0,1jo,0,8tv|||",
                "Greenland_Eastern": "hi,0,m,0,9l,0,4f2,0,ur|hl,0,od,0,ag,0,oe,0,9l,0,4f2,0,ur||",
                "Greenland_Western": "hi,0,m,0,9l,0,4hk,0,ur|hl,0,od,0,ag,0,oe,0,9l,0,4hk,0,ur||",
                "Gulf": "i7,0,ag,0,8tu,0,6di|||",
                "Guyana": "i7,0,9l,0,1dr|||",
                "Hawaii_Aleutian": "hi,0,m,0,9l,0,7pe,0,5,0,7pf,0,191|hl,0,od,0,ag,0,oe,0,9l,0,7pe,0,5,0,7pf,0,191||",
                "Hong_Kong": "hi,0,m,0,9l,0,19c,0,1eu|hl,0,m,0,9l,0,19c,0,1eu||",
                "Hovd": "hi,0,m,0,9l,0,qg|hl,0,m,0,9l,0,qg||",
                "India": "i7,0,9l,0,8tt|||",
                "Indian_Ocean": "i7,0,ag,0,8ts,0,1ao|||",
                "Indochina": "i7,0,9l,0,26p|||",
                "Indonesia_Central": "i7,0,9l,0,2lu,0,4hj|||",
                "Indonesia_Eastern": "i7,0,9l,0,4hl,0,4hj|||",
                "Indonesia_Western": "i7,0,9l,0,16h,0,4hj|||",
                "Iran": "hi,0,m,0,9l,0,rr|hl,0,od,0,ag,0,oe,0,9l,0,rr||",
                "Irkutsk": "hi,0,m,0,9l,0,12u|hl,0,m,0,9l,0,12u||",
                "Israel": "hi,0,m,0,9l,0,7pj|hl,0,od,0,ag,0,oe,0,9l,0,7pj||",
                "Japan": "hi,0,m,0,9l,0,7pk|hl,0,od,0,ag,0,oe,0,9l,0,7pk||",
                "Kazakhstan_Eastern": "i7,0,9l,0,4f2,0,180|||",
                "Kazakhstan_Western": "i7,0,9l,0,4hk,0,180|||",
                "Korea": "hi,0,m,0,9l,0,7pl|hl,0,od,0,ag,0,oe,0,9l,0,7pl||",
                "Kosrae": "i7,0,9l,0,8tr|||",
                "Krasnoyarsk": "hi,0,m,0,9l,0,1af|hl,0,m,0,9l,0,1af||",
                "Kyrgystan": "i7,0,9l,0,2bo|||",
                "Line_Islands": "i7,0,9l,0,8tq,0,1uj|||",
                "Lord_Howe": "hi,0,m,0,9l,0,oi,0,10a|hl,0,od,0,ag,0,oe,0,9l,0,oi,0,10a||",
                "Macquarie": "i7,0,ag,0,8tp,0,73i|||",
                "Magadan": "hi,0,m,0,9l,0,qq|hl,0,m,0,9l,0,qq||",
                "Malaysia": "i7,0,9l,0,26n|||",
                "Maldives": "i7,0,ag,0,26m|||",
                "Marquesas": "i7,0,9l,0,8to|||",
                "Marshall_Islands": "i7,0,ag,0,8tn,0,191|||",
                "Mauritius": "hi,0,m,0,ag,0,7ps|hl,0,od,0,ag,0,oe,0,ag,0,7ps||",
                "Mawson": "i7,0,9l,0,26k|||",
                "Mexico_Northwest": "hi,0,m,0,9l,0,3qb,0,187|hl,0,od,0,ag,0,oe,0,9l,0,3qb,0,187||",
                "Mexico_Pacific": "hi,0,2fm,0,m,0,9l,0,187|hl,0,2fm,0,m,0,9l,0,187||",
                "Mongolia": "hi,0,m,0,9l,0,or,0,qo|hl,0,m,0,9l,0,or,0,qo||",
                "Moscow": "hi,0,m,0,9l,0,r6|hl,0,od,0,ag,0,oe,0,9l,0,r6||",
                "Myanmar": "i7,0,9l,0,8tm|||",
                "Nauru": "i7,0,9l,0,14s|||",
                "Nepal": "i7,0,9l,0,16d|||",
                "New_Caledonia": "hi,0,m,0,9l,0,125,0,1bq|hl,0,m,0,9l,0,125,0,1bq||",
                "New_Zealand": "hi,0,m,0,9l,0,7pu,0,1bs|hl,0,od,0,ag,0,oe,0,9l,0,7pu,0,1bs||",
                "Newfoundland": "hi,0,m,0,ag,0,1bt|hl,0,od,0,ag,0,oe,0,ag,0,1bt||",
                "Niue": "i7,0,9l,0,1q4|||",
                "Norfolk": "i7,0,ag,0,30j,0,146|||",
                "Noronha": "hi,0,m,0,ag,0,138,0,13a,0,1bv|hl,0,od,0,ag,0,oe,0,ag,0,138,0,13a,0,1bv||",
                "Novosibirsk": "hi,0,m,0,9l,0,qt|hl,0,m,0,9l,0,qt||",
                "Omsk": "hi,0,m,0,9l,0,r5|hl,0,m,0,9l,0,r5||",
                "Pakistan": "hi,0,m,0,9l,0,rq|hl,0,m,0,9l,0,rq||",
                "Palau": "i7,0,9l,0,159|||",
                "Papua_New_Guinea": "i7,0,9l,0,13k,0,125,0,26i|||",
                "Paraguay": "hi,0,m,0,9l,0,1ca|hl,0,od,0,ag,0,oe,0,9l,0,1ca||",
                "Peru": "hi,0,m,0,9l,0,q1|hl,0,od,0,ag,0,oe,0,9l,0,q1||",
                "Philippines": "hi,0,m,0,9l,0,1cg|hl,0,m,0,9l,0,1cg||",
                "Phoenix_Islands": "i7,0,ag,0,30j,0,158|||",
                "Pierre_Miquelon": "hi,0,m,0,ag,0,2c3,0,1ci,0,il,0,7q0|hl,0,od,0,ag,0,oe,0,ag,0,2c3,0,1ci,0,il,0,7q0||",
                "Pitcairn": "i7,0,9l,0,1q6|||",
                "Ponape": "i7,0,9l,0,1v2|||",
                "Pyongyang": "8tl|||",
                "Reunion": "i7,0,ag,0,8tk|||",
                "Rothera": "i7,0,9l,0,157|||",
                "Sakhalin": "hi,0,m,0,9l,0,7q3|hl,0,m,0,9l,0,7q3||",
                "Samara": "17o,0,1q,0,m|17o,0,1de,0,od,0,ag,0,oe||",
                "Samoa": "hi,0,m,0,9l,0,p0|hl,0,m,0,9l,0,p0||",
                "Seychelles": "i7,0,ag,0,26h|||",
                "Singapore": "i7,0,9l,0,16b|||",
                "Solomon": "i7,0,ag,0,8tj,0,191|||",
                "South_Georgia": "i7,0,9l,0,26g,0,19a|||",
                "Suriname": "i7,0,9l,0,18v|||",
                "Syowa": "i7,0,9l,0,8ti|||",
                "Tahiti": "i7,0,9l,0,26f|||",
                "Taipei": "hi,0,m,0,9l,0,4dn|hl,0,od,0,ag,0,oe,0,9l,0,4dn||",
                "Tajikistan": "i7,0,9l,0,26e|||",
                "Tokelau": "i7,0,9l,0,156|||",
                "Tonga": "hi,0,m,0,9l,0,ot|hl,0,m,0,9l,0,ot||",
                "Truk": "i7,0,9l,0,1f2|||",
                "Turkmenistan": "hi,0,m,0,9l,0,1db|hl,0,m,0,9l,0,1db||",
                "Tuvalu": "i7,0,9l,0,14j|||",
                "Uruguay": "hi,0,m,0,9l,0,1dc|hl,0,od,0,ag,0,oe,0,9l,0,1dc||",
                "Uzbekistan": "hi,0,m,0,9l,0,108|hl,0,m,0,9l,0,108||",
                "Vanuatu": "hi,0,m,0,9l,0,os|hl,0,m,0,9l,0,os||",
                "Venezuela": "i7,0,9l,0,26c|||",
                "Vladivostok": "hi,0,m,0,9l,0,qn|hl,0,m,0,9l,0,qn||",
                "Volgograd": "hi,0,m,0,9l,0,q9|hl,0,od,0,ag,0,oe,0,9l,0,q9||",
                "Vostok": "i7,0,9l,0,155|||",
                "Wake": "i7,0,ag,0,8th,0,26b|||",
                "Wallis": "i7,0,9l,0,26a,0,il,0,14l|||",
                "Yakutsk": "hi,0,m,0,9l,0,1do|hl,0,m,0,9l,0,1do||",
                "Yekaterinburg": "hi,0,m,0,9l,0,136|hl,0,m,0,9l,0,136||"
            }
        },
        "ml": {
            "gmtFormat": "ജിഎംടി {0}",
            "gmtZeroFormat": "ജിഎംടി",
            "zone": {
                "Europe/Dublin": "|cca,0,cq,0,6a||",
                "Europe/London": "|ccb,0,mk,0,6a||"
            },
            "metazone": {
                "Acre": "7q7,0,cq,0,6a|7q7,0,1qb,0,6a||",
                "Afghanistan": "8tg,0,6a|||",
                "Africa_Central": "7qc,0,4fg,0,6a|||",
                "Africa_Eastern": "1jt,0,4fg,0,6a|||",
                "Africa_Southern": "8tf,0,cq,0,6a|||",
                "Africa_Western": "1ag,0,4fg,0,cq,0,6a|1ag,0,4fg,0,mk,0,6a||",
                "Alaska": "8te,0,cq,0,6a|8td,0,106,0,6a||",
                "Almaty": "7ql,0,cq,0,6a|7ql,0,1qb,0,6a||",
                "Amazon": "7qm,0,cq,0,6a|7qm,0,mk,0,6a||",
                "America_Central": "209,0,208,0,207,0,cq,0,6a|209,0,208,0,207,0,106,0,6a||",
                "America_Eastern": "209,0,208,0,1jt,0,cq,0,6a|209,0,208,0,1jt,0,106,0,6a||",
                "America_Mountain": "209,0,208,0,7qq,0,cq,0,6a|209,0,208,0,7qq,0,106,0,6a||",
                "America_Pacific": "209,0,208,0,4fr,0,cq,0,6a|209,0,208,0,4fr,0,106,0,6a||",
                "Anadyr": "7qs,0,cq,0,6a|7qs,0,1qb,0,6a||",
                "Apia": "7qt,0,cq,0,6a|7qt,0,106,0,6a||",
                "Aqtau": "7qu,0,cq,0,6a|7qu,0,1qb,0,6a||",
                "Aqtobe": "7qv,0,cq,0,6a|7qv,0,1qb,0,6a||",
                "Arabian": "7r0,0,cq,0,6a|7r0,0,106,0,6a||",
                "Argentina": "4fs,0,cq,0,6a|4fs,0,mk,0,6a||",
                "Argentina_Western": "1ag,0,4fs,0,cq,0,6a|1ag,0,4fs,0,mk,0,6a||",
                "Armenia": "7r2,0,cq,0,6a|7r2,0,mk,0,6a||",
                "Atlantic": "7r3,0,cq,0,6a|7r3,0,106,0,6a||",
                "Australia_Central": "4ft,0,207,0,cq,0,6a|4ft,0,207,0,106,0,6a||",
                "Australia_CentralWestern": "4ft,0,207,0,1ag,0,cq,0,6a|4ft,0,207,0,1ag,0,106,0,6a||",
                "Australia_Eastern": "4fu,0,1jt,0,cq,0,6a|4fu,0,1jt,0,106,0,6a||",
                "Australia_Western": "4fu,0,1ag,0,cq,0,6a|4fu,0,1ag,0,106,0,6a||",
                "Azerbaijan": "7r6,0,cq,0,6a|7r6,0,mk,0,6a||",
                "Azores": "7r7,0,cq,0,6a|7r7,0,mk,0,6a||",
                "Bangladesh": "7r8,0,cq,0,6a|7r8,0,mk,0,6a||",
                "Bhutan": "8tc,0,6a|||",
                "Bolivia": "8tb,0,6a|||",
                "Brasilia": "7rb,0,cq,0,6a|7rb,0,mk,0,6a||",
                "Brunei": "8ta,0,8t9,0,6a|||",
                "Cape_Verde": "7re,0,7rf,0,cq,0,6a|7re,0,7rf,0,mk,0,6a||",
                "Chamorro": "8t8,0,cq,0,6a|||",
                "Chatham": "7rh,0,cq,0,6a|7rh,0,mk,0,6a||",
                "Chile": "7ri,0,cq,0,6a|7ri,0,mk,0,6a||",
                "China": "7rj,0,cq,0,6a|7rj,0,106,0,6a||",
                "Choibalsan": "8t7,0,cq,0,6a|8t6,0,mk,0,6a||",
                "Christmas": "8t5,0,2ts,0,6a|||",
                "Cocos": "8t4,0,1ju,0,6a|||",
                "Colombia": "7rq,0,cq,0,6a|7rq,0,mk,0,6a||",
                "Cook": "7rr,0,1ju,0,cq,0,6a|7rr,0,1ju,0,8t3,0,mk,0,6a||",
                "Cuba": "7rt,0,cq,0,6a|7rt,0,106,0,6a||",
                "Davis": "8t2,0,6a|||",
                "DumontDUrville": "8t1,0,4hh,0,8t0,0,6a|||",
                "East_Timor": "1jt,0,8sv,0,6a|||",
                "Easter": "7s3,0,2ts,0,cq,0,6a|7s3,0,2ts,0,mk,0,6a||",
                "Ecuador": "8su,0,6a|||",
                "Europe_Central": "207,0,260,0,cq,0,6a|207,0,260,0,7s6,0,6a||",
                "Europe_Eastern": "1jt,0,260,0,cq,0,6a|1jt,0,260,0,7s6,0,6a||",
                "Europe_Further_Eastern": "8st,0,260,0,6a|||",
                "Europe_Western": "1ag,0,260,0,cq,0,6a|1ag,0,260,0,mk,0,6a||",
                "Falkland": "7s8,0,1ju,0,cq,0,6a|7s8,0,1ju,0,mk,0,6a||",
                "Fiji": "7s9,0,cq,0,6a|7s9,0,mk,0,6a||",
                "French_Guiana": "7sa,0,7sb,0,6a|||",
                "French_Southern": "7sa,0,8ss,k,0,8sr,0,6a|||",
                "Galapagos": "8sq,0,6a|||",
                "Gambier": "8sp,0,6a|||",
                "Georgia": "7sg,0,cq,0,6a|7sg,0,mk,0,6a||",
                "Gilbert_Islands": "8so,0,1ju,0,6a|||",
                "GMT": "8sn,0,8sm,0,6a|||",
                "Greenland_Eastern": "1jt,0,4gg,0,cq,0,6a|1jt,0,4gg,0,mk,0,6a||",
                "Greenland_Western": "1ag,0,4gg,0,cq,0,6a|1ag,0,4gg,0,mk,0,6a||",
                "Guam": "8sl,0,cq,0,6a|||",
                "Gulf": "8sk,0,cq,0,6a|||",
                "Guyana": "7sb,0,6a|||",
                "Hawaii_Aleutian": "7sn,5,7so,0,cq,0,6a|7sn,5,7so,0,106,0,6a||",
                "Hong_Kong": "7sp,0,cq,0,6a|7sp,0,mk,0,6a||",
                "Hovd": "7sq,0,cq,0,6a|7sq,0,mk,0,6a||",
                "India": "7sr,0,cq,0,6a||15a|",
                "Indian_Ocean": "7sr,0,8sj,0,6a|||",
                "Indochina": "8si,0,6a|||",
                "Indonesia_Central": "7qc,0,4mp,0,6a|||",
                "Indonesia_Eastern": "1jt,0,4mp,0,6a|||",
                "Indonesia_Western": "1ag,0,4mp,0,6a|||",
                "Iran": "7sv,0,cq,0,6a|7sv,0,106,0,6a||",
                "Irkutsk": "8sh,0,cq,0,6a|8sg,0,mk,0,6a||",
                "Israel": "7t2,0,cq,0,6a|7t2,0,106,0,6a||",
                "Japan": "7t3,0,cq,0,6a|7t3,0,106,0,6a||",
                "Kamchatka": "7t4,0,7t5,0,cq,0,6a|7t4,0,7t5,0,1qb,0,6a||",
                "Kazakhstan_Eastern": "1jt,0,7t6,0,6a|||",
                "Kazakhstan_Western": "1ag,0,7t6,0,6a|||",
                "Korea": "7t7,0,cq,0,6a|7t7,0,106,0,6a||",
                "Kosrae": "8sf,0,6a|||",
                "Krasnoyarsk": "7t9,0,cq,0,6a|7t9,0,mk,0,6a||",
                "Kyrgystan": "8se,0,6a|||",
                "Lanka": "8sd,0,6a|||",
                "Line_Islands": "8sc,0,1ju,0,6a|||",
                "Lord_Howe": "7td,0,7te,0,cq,0,6a|7td,0,7te,0,106,0,6a||",
                "Macau": "7tf,0,cq,0,6a|7tf,0,1qb,0,6a||",
                "Macquarie": "8sb,0,2ts,0,6a|||",
                "Magadan": "7th,0,cq,0,6a|7th,0,mk,0,6a||",
                "Malaysia": "8sa,0,6a|||",
                "Maldives": "8s9,0,6a|||",
                "Marquesas": "8s8,0,6a|||",
                "Marshall_Islands": "8s7,0,1ju,0,6a|||",
                "Mauritius": "7tm,0,cq,0,6a|7tm,0,mk,0,6a||",
                "Mawson": "8s6,0,6a|||",
                "Mexico_Northwest": "7to,0,7tp,0,cq,0,6a|7to,0,7tq,0,106,0,6a||",
                "Mexico_Pacific": "7tp,0,4fr,0,cq,0,6a|7tq,0,4fr,0,106,0,6a||",
                "Mongolia": "7tr,0,7ts,0,cq,0,6a|7tr,0,7ts,0,mk,0,6a||",
                "Moscow": "8s5,0,cq,0,6a|8s4,0,mk,0,6a||",
                "Myanmar": "8s3,0,6a|||",
                "Nauru": "8s2,0,6a|||",
                "Nepal": "8s1,0,6a|||",
                "New_Caledonia": "4hg,0,7u3,0,cq,0,6a|4hg,0,7u3,0,mk,0,6a||",
                "New_Zealand": "7u4,0,cq,0,6a|7u4,0,106,0,6a||",
                "Newfoundland": "7u5,0,cq,0,6a|7u5,0,106,0,6a||",
                "Niue": "8s0,0,6a|||",
                "Norfolk": "8rv,0,1ju,0,6a|||",
                "Noronha": "7u8,0,4hh,0,7u9,0,cq,0,6a|7u8,0,4hh,0,7u9,0,mk,0,6a||",
                "North_Mariana": "8ru,0,8rt,0,1ju,0,6a|||",
                "Novosibirsk": "8rs,0,cq,0,6a|8rr,0,mk,0,6a||",
                "Omsk": "7ue,0,cq,0,6a|7ue,0,mk,0,6a||",
                "Pakistan": "7uf,0,cq,0,6a|7uf,0,mk,0,6a||",
                "Palau": "8rq,0,6a|||",
                "Papua_New_Guinea": "8rp,0,4hg,0,8ro,0,6a|||",
                "Paraguay": "7uj,0,cq,0,6a|7uj,0,mk,0,6a||",
                "Peru": "7uk,0,cq,0,6a|7uk,0,mk,0,6a||",
                "Philippines": "7ul,0,cq,0,6a|7ul,0,mk,0,6a||",
                "Phoenix_Islands": "8rn,0,1ju,0,6a|||",
                "Pierre_Miquelon": "7un,0,7uo,0,7up,0,7uq,0,cq,0,6a|7un,0,7uo,0,7up,0,7uq,0,106,0,6a||",
                "Pitcairn": "8rm,0,6a|||",
                "Ponape": "8rl,0,6a|||",
                "Pyongyang": "8rk,0,6a|||",
                "Qyzylorda": "7uu,0,cq,0,6a|7uu,0,1qb,0,6a||",
                "Reunion": "8rj,0,6a|||",
                "Rothera": "8ri,0,6a|||",
                "Sakhalin": "7v1,0,cq,0,6a|7v1,0,mk,0,6a||",
                "Samara": "7v2,0,cq,0,6a|7v2,0,1qb,0,6a||",
                "Samoa": "8rh,0,cq,0,6a|8rg,0,mk,0,6a||",
                "Seychelles": "8rf,0,6a|||",
                "Singapore": "8re,0,cq,0,6a|||",
                "Solomon": "8rd,0,1ju,0,6a|||",
                "South_Georgia": "8rc,0,8rb,0,6a|||",
                "Suriname": "8ra,0,6a|||",
                "Syowa": "8r9,0,6a|||",
                "Tahiti": "8r8,0,6a|||",
                "Taipei": "7vd,0,cq,0,6a|7vd,0,106,0,6a||",
                "Tajikistan": "8r7,0,6a|||",
                "Tokelau": "8r6,0,6a|||",
                "Tonga": "7vg,0,cq,0,6a|7vg,0,mk,0,6a||",
                "Truk": "8r5,0,6a|||",
                "Turkmenistan": "7vi,0,cq,0,6a|7vi,0,mk,0,6a||",
                "Tuvalu": "8r4,0,6a|||",
                "Uruguay": "7vk,0,cq,0,6a|7vk,0,mk,0,6a||",
                "Uzbekistan": "7vl,0,cq,0,6a|7vl,0,mk,0,6a||",
                "Vanuatu": "7vm,0,cq,0,6a|7vm,0,mk,0,6a||",
                "Venezuela": "8r3,0,6a|||",
                "Vladivostok": "7vo,0,cq,0,6a|7vo,0,mk,0,6a||",
                "Volgograd": "7vp,0,cq,0,6a|7vp,0,mk,0,6a||",
                "Vostok": "8r2,0,6a|||",
                "Wake": "8r1,0,2ts,0,6a|||",
                "Wallis": "8r0,0,8qv,0,8qu,0,6a|||",
                "Yakutsk": "7vv,0,cq,0,6a|7vv,0,mk,0,6a||",
                "Yekaterinburg": "800,0,cq,0,6a|800,0,mk,0,6a||"
            }
        },
        "mn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ccc,0,ab,0,7g||",
                "Europe/London": "|ccd,0,gf,0,7g||"
            },
            "metazone": {
                "Afghanistan": "8qt,0,7g|||",
                "Africa_Central": "203,0,2te,0,7g|||",
                "Africa_Eastern": "1jv,0,2te,0,7g|||",
                "Africa_Southern": "4hm,0,2te,0,ab,0,7g|||",
                "Africa_Western": "1k1,0,2te,0,ab,0,7g|1k1,0,2te,0,gf,0,7g||",
                "Alaska": "80a,0,ab,0,7g|80a,0,gf,0,7g||",
                "Amazon": "80b,0,ab,0,7g|80b,0,gf,0,7g||",
                "America_Central": "203,0,ab,0,7g|203,0,gf,0,7g||",
                "America_Eastern": "1jv,0,202,0,ab,0,7g|1jv,0,202,0,gf,0,7g||",
                "America_Mountain": "80d,0,ab,0,7g|80d,0,gf,0,7g||",
                "America_Pacific": "80e,0,2s7,0,ab,0,7g|80e,0,2s7,0,gf,0,7g||",
                "Apia": "80g,0,ab,0,7g|80g,0,gf,0,7g||",
                "Arabian": "80h,0,ab,0,7g|80h,0,gf,0,7g||",
                "Argentina": "4e7,0,ab,0,7g|4e7,0,gf,0,7g||",
                "Argentina_Western": "1k1,0,4e7,0,ab,0,7g|1k1,0,4e7,0,gf,0,7g||",
                "Armenia": "80j,0,ab,0,7g|80j,0,gf,0,7g||",
                "Atlantic": "80k,0,ab,0,7g|80k,0,gf,0,7g||",
                "Australia_Central": "203,0,200,0,ab,0,7g|203,0,200,0,gf,0,7g||",
                "Australia_CentralWestern": "200,0,80m,0,4dq,0,202,0,ab,0,7g|200,0,80m,0,4dq,0,202,0,gf,0,7g||",
                "Australia_Eastern": "200,0,80o,0,202,0,ab,0,7g|200,0,80o,0,202,0,gf,0,7g||",
                "Australia_Western": "200,0,4dq,0,202,0,ab,0,7g|200,0,4dq,0,202,0,gf,0,7g||",
                "Azerbaijan": "80p,0,ab,0,7g|80p,0,gf,0,7g||",
                "Azores": "80q,0,ab,0,7g|80q,0,gf,0,7g||",
                "Bangladesh": "80r,0,ab,0,7g|80r,0,gf,0,7g||",
                "Bhutan": "8qs,0,7g|||",
                "Bolivia": "8qr,0,7g|||",
                "Brasilia": "80u,0,ab,0,7g|80u,0,gf,0,7g||",
                "Brunei": "1gr,0,8qq,0,7g|||",
                "Cape_Verde": "810,0,811,0,ab,0,7g|810,0,811,0,gf,0,7g||",
                "Chamorro": "8qp,0,7g|||",
                "Chatham": "813,0,ab,0,7g|813,0,gf,0,7g||",
                "Chile": "814,0,ab,0,7g|814,0,gf,0,7g||",
                "China": "4i2,0,ab,0,7g|4i2,0,gf,0,7g||",
                "Choibalsan": "816,0,ab,0,7g|816,0,gf,0,7g||",
                "Christmas": "72q,0,1p9,0,7g|||",
                "Cocos": "1le,0,1p9,0,7g|||",
                "Colombia": "818,0,ab,0,7g|818,0,gf,0,7g||",
                "Cook": "819,0,1p9,0,ab,0,7g|819,0,1p9,0,8qo,0,gf,0,7g||",
                "Cuba": "81b,0,ab,0,7g|81b,0,gf,0,7g||",
                "Davis": "8qn,0,7g|||",
                "DumontDUrville": "1gu,0,8qm,0,7g|||",
                "East_Timor": "1jv,0,8ql,0,7g|||",
                "Easter": "1jv,0,81f,0,ab,0,7g|1jv,0,81f,0,gf,0,7g||",
                "Ecuador": "8qk,0,7g|||",
                "Europe_Central": "203,0,28c,0,ab,0,7g|203,0,28c,0,gf,0,7g||",
                "Europe_Eastern": "1jv,0,28c,0,ab,0,7g|1jv,0,28c,0,gf,0,7g||",
                "Europe_Further_Eastern": "8qj,0,8qi,0,28c,0,7g|||",
                "Europe_Western": "1k1,0,28c,0,ab,0,7g|1k1,0,28c,0,gf,0,7g||",
                "Falkland": "81k,0,81l,0,ab,0,7g|81k,0,81l,0,gf,0,7g||",
                "Fiji": "81m,0,ab,0,7g|81m,0,gf,0,7g||",
                "French_Guiana": "81n,0,8qh,0,7g|||",
                "French_Southern": "81n,0,4hm,0,4c1,0,8qg,0,7g|||",
                "Galapagos": "8qf,0,7g|||",
                "Gambier": "8qe,0,7g|||",
                "Georgia": "81t,0,ab,0,7g|81t,0,gf,0,7g||",
                "Gilbert_Islands": "8qd,0,1p9,0,7g|||",
                "GMT": "8qc,0,8qb,0,7g|||",
                "Greenland_Eastern": "1jv,0,4an,0,ab,0,7g|1jv,0,4an,0,gf,0,7g||",
                "Greenland_Western": "1k1,0,4an,0,ab,0,7g|1k1,0,4an,0,gf,0,7g||",
                "Gulf": "8qa,0,ab,0,7g|||",
                "Guyana": "8q9,0,7g|||",
                "Hawaii_Aleutian": "824,5,825,0,ab,0,7g|824,5,825,0,gf,0,7g||",
                "Hong_Kong": "19c,0,826,0,ab,0,7g|19c,0,826,0,gf,0,7g||",
                "Hovd": "827,0,ab,0,7g|827,0,gf,0,7g||",
                "India": "828,0,7g|||",
                "Indian_Ocean": "828,0,2s7,0,7g|||",
                "Indochina": "8q8,5,4i2,0,8q7,0,7g|||",
                "Indonesia_Central": "203,0,4ig,0,7g|||",
                "Indonesia_Eastern": "1jv,0,4ig,0,7g|||",
                "Indonesia_Western": "1k1,0,4ig,0,7g|||",
                "Iran": "82c,0,ab,0,7g|82c,0,gf,0,7g||",
                "Irkutsk": "82d,0,ab,0,7g|82d,0,gf,0,7g||",
                "Israel": "82e,0,ab,0,7g|82e,0,gf,0,7g||",
                "Japan": "82f,0,ab,0,7g|82f,0,gf,0,7g||",
                "Kazakhstan_Eastern": "1jv,0,82g,0,7g|||",
                "Kazakhstan_Western": "1k1,0,82g,0,7g|||",
                "Korea": "82h,0,ab,0,7g|82h,0,gf,0,7g||",
                "Kosrae": "8q6,0,7g|||",
                "Krasnoyarsk": "82j,0,ab,0,7g|82j,0,gf,0,7g||",
                "Kyrgystan": "8q5,0,7g|||",
                "Line_Islands": "1eo,0,1p9,0,7g|||",
                "Lord_Howe": "oi,0,82l,0,ab,0,7g|oi,0,82l,0,gf,0,7g||",
                "Macquarie": "1l5,0,8q4,0,7g|||",
                "Magadan": "82n,0,ab,0,7g|82n,0,gf,0,7g||",
                "Malaysia": "8q3,0,7g|||",
                "Maldives": "8q2,0,7g|||",
                "Marquesas": "8q1,0,7g|||",
                "Marshall_Islands": "8q0,0,1p9,0,7g|||",
                "Mauritius": "82s,0,ab,0,7g|82s,0,gf,0,7g||",
                "Mawson": "8pv,0,7g|||",
                "Mexico_Northwest": "1k1,0,82u,0,45n,0,ab,0,7g|1k1,0,82u,0,45n,0,gf,0,7g||",
                "Mexico_Pacific": "45n,0,830,0,2s7,0,ab,0,7g|45n,0,830,0,2s7,0,gf,0,7g||",
                "Mongolia": "831,0,ab,0,7g|831,0,gf,0,7g||",
                "Moscow": "832,0,ab,0,7g|832,0,gf,0,7g||",
                "Myanmar": "8pu,0,7g|||",
                "Nauru": "8pt,0,7g|||",
                "Nepal": "8ps,0,7g|||",
                "New_Caledonia": "2q5,0,837,0,ab,0,7g|2q5,0,837,0,gf,0,7g||",
                "New_Zealand": "2q5,0,838,0,ab,0,7g|2q5,0,838,0,gf,0,7g||",
                "Newfoundland": "839,5,83a,0,ab,0,7g|839,5,83a,0,gf,0,7g||",
                "Niue": "8pr,0,7g|||",
                "Norfolk": "146,0,1p9,0,7g|||",
                "Noronha": "138,0,13a,0,83c,0,ab,0,7g|138,0,13a,0,83c,0,gf,0,7g||",
                "Novosibirsk": "83d,0,ab,0,7g|83d,0,gf,0,7g||",
                "Omsk": "83e,0,ab,0,7g|83e,0,gf,0,7g||",
                "Pakistan": "83f,0,ab,0,7g|83f,0,gf,0,7g||",
                "Palau": "8pq,0,7g|||",
                "Papua_New_Guinea": "13k,0,2q5,0,cef,0,7g|||",
                "Paraguay": "83i,0,ab,0,7g|83i,0,gf,0,7g||",
                "Peru": "83j,0,ab,0,7g|83j,0,gf,0,7g||",
                "Philippines": "1cf,0,ab,0,7g|1cf,0,gf,0,7g||",
                "Phoenix_Islands": "158,0,1p9,0,7g|||",
                "Pierre_Miquelon": "2c3,5,8po,0,4c1,0,83m,0,ab,0,7g|2c3,5,13m,0,4c1,0,83m,0,gf,0,7g||",
                "Pitcairn": "8pn,0,7g|||",
                "Ponape": "8pm,0,7g|||",
                "Pyongyang": "8pl,0,7g|||",
                "Reunion": "8pk,0,7g|||",
                "Rothera": "8pj,0,7g|||",
                "Sakhalin": "83s,0,ab,0,7g|83s,0,gf,0,7g||",
                "Samoa": "83t,0,ab,0,7g|83t,0,gf,0,7g||",
                "Seychelles": "8pi,0,7g|||",
                "Singapore": "8ph,0,7g|||",
                "Solomon": "8pg,0,8pf,0,7g|||",
                "South_Georgia": "4hm,0,8pe,0,7g|||",
                "Suriname": "8pd,0,7g|||",
                "Syowa": "8pc,0,7g|||",
                "Tahiti": "8pb,0,7g|||",
                "Taipei": "846,0,ab,0,7g|846,0,gf,0,7g||",
                "Tajikistan": "8pa,0,7g|||",
                "Tokelau": "8p9,0,7g|||",
                "Tonga": "849,0,ab,0,7g|849,0,gf,0,7g||",
                "Truk": "8p8,0,7g|||",
                "Turkmenistan": "84b,0,ab,0,7g|84b,0,gf,0,7g||",
                "Tuvalu": "8p7,0,7g|||",
                "Uruguay": "84d,0,ab,0,7g|84d,0,gf,0,7g||",
                "Uzbekistan": "84e,0,ab,0,7g|84e,0,gf,0,7g||",
                "Vanuatu": "84f,0,ab,0,7g|84f,0,gf,0,7g||",
                "Venezuela": "8p6,0,7g|||",
                "Vladivostok": "84h,0,ab,0,7g|84h,0,gf,0,7g||",
                "Volgograd": "84i,0,ab,0,7g|84i,0,gf,0,7g||",
                "Vostok": "8p5,0,7g|||",
                "Wake": "84k,0,1p9,0,7g|||",
                "Wallis": "1qq,0,4c1,0,8p4,0,7g|||",
                "Yakutsk": "84m,0,ab,0,7g|84m,0,gf,0,7g||",
                "Yekaterinburg": "84n,0,ab,0,7g|84n,0,gf,0,7g||"
            }
        },
        "mr": {
            "gmtFormat": "[GMT]{0}",
            "gmtZeroFormat": "[GMT]",
            "zone": {
                "Europe/Dublin": "|cce,0,f3,0,6u||",
                "Europe/London": "|6aa,0,mh,0,6u||"
            },
            "metazone": {
                "Acre": "8p3,0,1nk|8p2,0,4jl,0,6u||",
                "Afghanistan": "8p1,0,6u|||",
                "Africa_Central": "2um,0,2n2,0,6u|||",
                "Africa_Eastern": "1lo,0,2n2,0,6u|||",
                "Africa_Southern": "1jl,0,2n2,0,f3,0,6u|||",
                "Africa_Western": "124,0,2n2,0,f3,0,6u|124,0,2n2,0,mh,0,6u||",
                "Alaska": "4es,0,f3,0,6u|4es,0,sm,0,6u||",
                "Almaty": "852,0,1nk|852,0,a5,0,6u||",
                "Amazon": "853,0,f3,0,6u|853,0,mh,0,6u||",
                "America_Central": "1s4,0,f3,0,6u|1s4,0,sm,0,6u||",
                "America_Eastern": "3sn,0,f3,0,6u|3sn,0,sm,0,6u||",
                "America_Mountain": "855,0,f3,0,6u|855,0,sm,0,6u||",
                "America_Pacific": "3sm,0,f3,0,6u|3sm,0,sm,0,6u||",
                "Anadyr": "4er,0,1nk|4er,0,a5,0,6u||",
                "Apia": "857,0,f3,0,6u|857,0,sm,0,6u||",
                "Aqtau": "858,0,1nk|858,0,a5,0,6u||",
                "Aqtobe": "859,0,1nk|859,0,a5,0,6u||",
                "Arabian": "85a,0,f3,0,6u|85a,0,sm,0,6u||",
                "Argentina": "3si,0,f3,0,6u|3si,0,mh,0,6u||",
                "Argentina_Western": "q4,0,3si,0,f3,0,6u|q4,0,3si,0,mh,0,6u||",
                "Armenia": "365,0,f3,0,6u|365,0,mh,0,6u||",
                "Atlantic": "364,0,f3,0,6u|364,0,sm,0,6u||",
                "Australia_Central": "1vm,0,1a6,0,f3,0,6u|1vm,0,1a6,0,sm,0,6u||",
                "Australia_CentralWestern": "1vm,0,1a6,5,124,0,f3,0,6u|1vm,0,1a6,5,124,0,sm,0,6u||",
                "Australia_Eastern": "1vm,0,1lo,0,f3,0,6u|1vm,0,1lo,0,sm,0,6u||",
                "Australia_Western": "1vm,0,124,0,f3,0,6u|1vm,0,124,0,sm,0,6u||",
                "Azerbaijan": "85d,0,f3,0,6u|85d,0,mh,0,6u||",
                "Azores": "85e,0,f3,0,6u|85e,0,mh,0,6u||",
                "Bangladesh": "4eo,0,f3,0,6u|4eo,0,mh,0,6u||",
                "Bhutan": "8p0,0,6u|||",
                "Bolivia": "8ov,0,6u|||",
                "Brasilia": "85h,0,f3,0,6u|85h,0,mh,0,6u||",
                "Brunei": "54g,0,8ou,0,6u|||",
                "Cape_Verde": "1s1,0,85j,0,f3,0,6u|1s1,0,85j,0,mh,0,6u||",
                "Chamorro": "4sr,0,f3,0,6u|||",
                "Chatham": "85k,0,f3,0,6u|85k,0,sm,0,6u||",
                "Chile": "1s0,0,f3,0,6u|1s0,0,mh,0,6u||",
                "China": "85l,0,f3,0,6u|85l,0,sm,0,6u||",
                "Choibalsan": "85m,0,f3,0,6u|85m,0,mh,0,6u||",
                "Christmas": "8ot,0,2ag,0,6u|||",
                "Cocos": "8os,0,1k5,0,6u|||",
                "Colombia": "2b7,0,f3,0,6u|2b7,0,mh,0,6u||",
                "Cook": "1lj,0,1k5,0,f3,0,6u|1lj,0,1k5,0,597,0,mh,0,6u||",
                "Cuba": "360,0,f3,0,6u|360,0,sm,0,6u||",
                "Davis": "8or,0,6u|||",
                "DumontDUrville": "8oq,5,8op,0,6u|||",
                "East_Timor": "1lo,0,4t7,0,6u|||",
                "Easter": "2ds,0,2ag,0,f3,0,6u|2ds,0,2ag,0,mh,0,6u||",
                "Ecuador": "8oo,0,6u|||",
                "Europe_Central": "2um,0,2dv,0,f3,0,6u|2um,0,2dv,0,mh,0,6u||",
                "Europe_Eastern": "1lo,0,2dv,0,f3,0,6u|1lo,0,2dv,0,mh,0,6u||",
                "Europe_Further_Eastern": "4tc,5,3sn,0,8on,0,6u|||",
                "Europe_Western": "124,0,2dv,0,f3,0,6u|124,0,2dv,0,mh,0,6u||",
                "Falkland": "862,0,1k5,0,f3,0,6u|862,0,1k5,0,mh,0,6u||",
                "Fiji": "2e1,0,f3,0,6u|2e1,0,mh,0,6u||",
                "French_Guiana": "864,0,865,0,6u|||",
                "French_Southern": "864,0,1jl,0,3rm,0,8om,0,6u|||",
                "Galapagos": "8ol,0,6u|||",
                "Gambier": "8oj,0,6u|||",
                "Georgia": "27i,0,f3,0,6u|27i,0,mh,0,6u||",
                "Gilbert_Islands": "35s,0,1k5,0,6u|||",
                "GMT": "8of,0,f3,0,6u|||",
                "Greenland_Eastern": "1lo,0,3re,0,f3,0,6u|1lo,0,3re,0,mh,0,6u||",
                "Greenland_Western": "124,0,3re,0,f3,0,6u|124,0,3re,0,mh,0,6u||",
                "Guam": "5e7,0,1nk|||",
                "Gulf": "4kg,0,f3,0,6u|||",
                "Guyana": "865,0,6u|||",
                "Hawaii_Aleutian": "21u,5,86d,0,f3,0,6u|21u,5,86d,0,sm,0,6u||",
                "Hong_Kong": "35p,0,35o,0,f3,0,6u|35p,0,35o,0,mh,0,6u||",
                "Hovd": "86e,0,f3,0,6u|86e,0,mh,0,6u||",
                "India": "27j,0,f3,0,6u||15a|",
                "Indian_Ocean": "8oe,0,6u|||",
                "Indochina": "8od,0,6u|||",
                "Indonesia_Central": "2um,0,2j0,0,6u|||",
                "Indonesia_Eastern": "3sn,0,2j0,0,6u|||",
                "Indonesia_Western": "q4,0,2j0,0,6u|||",
                "Iran": "86h,0,f3,0,6u|86h,0,sm,0,6u||",
                "Irkutsk": "86i,0,f3,0,6u|86i,0,mh,0,6u||",
                "Israel": "86j,0,f3,0,6u|86j,0,sm,0,6u||",
                "Japan": "4ef,0,f3,0,6u|4ef,0,sm,0,6u||",
                "Kamchatka": "86k,5,0,86l,0,1nk|86k,5,0,86l,0,a5,0,6u||",
                "Kazakhstan_Eastern": "1lo,0,86m,0,6u|||",
                "Kazakhstan_Western": "124,0,86m,0,6u|||",
                "Korea": "86n,0,f3,0,6u|86n,0,sm,0,6u||",
                "Kosrae": "8oa,0,6u|||",
                "Krasnoyarsk": "4rl,0,f3,0,6u|8o9,0,mh,0,6u||",
                "Kyrgystan": "8o8,0,6u|||",
                "Lanka": "5m0,0,6u|||",
                "Line_Islands": "35k,0,1k5,0,6u|||",
                "Lord_Howe": "35j,0,35i,0,f3,0,6u|35j,0,35i,0,sm,0,6u||",
                "Macau": "86r,0,1nk|86r,0,a5,0,6u||",
                "Macquarie": "8o7,0,2ag,0,6u|||",
                "Magadan": "86t,0,f3,0,6u|86t,0,mh,0,6u||",
                "Malaysia": "4hp,0,6u|||",
                "Maldives": "8o6,0,6u|||",
                "Marquesas": "8o5,0,6u|||",
                "Marshall_Islands": "2ob,0,1k5,0,6u|||",
                "Mauritius": "35e,0,f3,0,6u|35e,0,mh,0,6u||",
                "Mawson": "5mg,0,6u|||",
                "Mexico_Northwest": "870,0,2ja,0,f3,0,6u|870,0,2ja,0,sm,0,6u||",
                "Mexico_Pacific": "2ja,0,3sm,0,f3,0,6u|2ja,0,3sm,0,sm,0,6u||",
                "Mongolia": "1rp,0,2e6,0,f3,0,6u|1rp,0,2e6,0,mh,0,6u||",
                "Moscow": "2lk,0,f3,0,6u|2lk,0,mh,0,6u||",
                "Myanmar": "4ed,0,6u|||",
                "Nauru": "8o4,0,6u|||",
                "Nepal": "8o3,0,6u|||",
                "New_Caledonia": "1ld,0,874,0,f3,0,6u|1ld,0,874,0,mh,0,6u||",
                "New_Zealand": "875,0,f3,0,6u|875,0,sm,0,6u||",
                "Newfoundland": "1ld,0,876,0,f3,0,6u|1ld,0,876,0,sm,0,6u||",
                "Niue": "8o2,0,6u|||",
                "Norfolk": "5od,0,2ag,0,6u|||",
                "Noronha": "878,0,879,0,1n8,0,f3,0,6u|878,0,879,0,1n8,0,mh,0,6u||",
                "North_Mariana": "2a5,0,8o1,0,1k5,0,6u|||",
                "Novosibirsk": "356,0,f3,0,6u|356,0,mh,0,6u||",
                "Omsk": "1nb,0,f3,0,6u|1nb,0,mh,0,6u||",
                "Pakistan": "2li,0,f3,0,6u|2li,0,mh,0,6u||",
                "Palau": "5os,0,6u|||",
                "Papua_New_Guinea": "4hr,0,1ld,0,355,0,6u|||",
                "Paraguay": "87b,0,f3,0,6u|87b,0,mh,0,6u||",
                "Peru": "2k9,0,f3,0,6u|2k9,0,mh,0,6u||",
                "Philippines": "87c,0,f3,0,6u|87c,0,mh,0,6u||",
                "Phoenix_Islands": "8o0,0,1k5,0,6u|||",
                "Pierre_Miquelon": "351,0,87e,0,3rm,0,87f,0,f3,0,6u|351,0,87e,0,3rm,0,87f,0,sm,0,6u||",
                "Pitcairn": "4vf,0,6u|||",
                "Ponape": "8nv,0,6u|||",
                "Pyongyang": "8nu,0,6u|||",
                "Qyzylorda": "87i,0,1nk|87i,0,a5,0,6u||",
                "Reunion": "4l0,0,6u|||",
                "Rothera": "2qb,0,6u|||",
                "Sakhalin": "87k,0,f3,0,6u|87k,0,mh,0,6u||",
                "Samara": "87l,0,1nk|87l,0,a5,0,6u||",
                "Samoa": "87m,0,f3,0,6u|87m,0,sm,0,6u||",
                "Seychelles": "5qu,0,6u|||",
                "Singapore": "8nt,0,f3,0,6u|||",
                "Solomon": "8ns,0,1k5,0,6u|||",
                "South_Georgia": "1jl,0,27i,0,6u|||",
                "Suriname": "8nr,0,6u|||",
                "Syowa": "34v,0,6u|||",
                "Tahiti": "34u,0,6u|||",
                "Taipei": "87q,0,f3,0,6u|87q,0,sm,0,6u||",
                "Tajikistan": "4vq,0,6u|||",
                "Tokelau": "4vr,0,6u|||",
                "Tonga": "1rl,0,f3,0,6u|1rl,0,mh,0,6u||",
                "Truk": "4vt,0,6u|||",
                "Turkmenistan": "1ri,0,f3,0,6u|1ri,0,mh,0,6u||",
                "Tuvalu": "4vv,0,6u|||",
                "Uruguay": "2jp,0,f3,0,6u|2jp,0,mh,0,6u||",
                "Uzbekistan": "87r,0,f3,0,6u|87r,0,mh,0,6u||",
                "Vanuatu": "87s,0,f3,0,6u|87s,0,mh,0,6u||",
                "Venezuela": "8nq,0,6u|||",
                "Vladivostok": "34t,0,f3,0,6u|34t,0,mh,0,6u||",
                "Volgograd": "87u,0,f3,0,6u|87u,0,mh,0,6u||",
                "Vostok": "8np,0,6u|||",
                "Wake": "34s,0,2ag,0,6u|||",
                "Wallis": "8no,0,3rm,0,4l7,0,6u|||",
                "Yakutsk": "882,0,f3,0,6u|882,0,mh,0,6u||",
                "Yekaterinburg": "883,0,f3,0,6u|883,0,mh,0,6u||"
            }
        },
        "ms-BN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|u,0,5i,0,5h,0,3i4||",
                "Europe/London": "|u,0,5i,0,5h,0,c1||"
            },
            "metazone": {
                "Afghanistan": "u,0,b6|||",
                "Africa_Central": "u,0,c5,0,pl|||",
                "Africa_Eastern": "u,0,c5,0,mt|||",
                "Africa_Southern": "u,0,5p,0,c5,0,1jj|||",
                "Africa_Western": "u,0,5p,0,c5,0,ht|u,0,5i,0,5h,0,c5,0,ht||",
                "Alaska": "u,0,5p,0,3p|u,0,fq,0,3p||",
                "Amazon": "u,0,5p,0,5m|u,0,5i,0,5h,0,5m||",
                "America_Central": "u,0,5p,0,4la|u,0,fq,0,pl||",
                "America_Eastern": "u,0,5p,0,mt|u,0,fq,0,mt||",
                "America_Mountain": "u,0,5p,0,2g4|u,0,4lc,0,fq,0,2g4||",
                "America_Pacific": "u,0,5p,0,10c|u,0,fq,0,10c||",
                "Anadyr": "u,0,5p,0,3l|u,0,5i,0,5h,0,3l||",
                "Apia": "u,0,4,0,3e|u,0,fq,0,3e||",
                "Arabian": "u,0,5p,0,1qe|u,0,fq,0,1qe||",
                "Argentina": "u,0,5p,0,1e|u,0,5i,0,5h,0,1e||",
                "Argentina_Western": "u,0,5p,0,1e,0,ht|u,0,5i,0,5h,0,1e,0,ht||",
                "Armenia": "u,0,5p,0,4l|u,0,5i,0,5h,0,4l||",
                "Atlantic": "u,0,5p,0,r2|u,0,fq,0,r2||",
                "Australia_Central": "u,0,5p,0,4c,0,pl|u,0,fq,0,4c,0,pl||",
                "Australia_CentralWestern": "u,0,5p,0,ht,0,pl,0,4c|u,0,fq,0,ht,0,pl,0,4c||",
                "Australia_Eastern": "u,0,5p,0,mt,0,4c|u,0,fq,0,4c,0,mt||",
                "Australia_Western": "u,0,5p,0,4c,0,ht|u,0,fq,0,4c,0,ht||",
                "Azerbaijan": "u,0,5p,0,7m|u,0,5i,0,5h,0,7m||",
                "Azores": "u,0,5p,0,4r|u,0,5i,0,5h,0,4r||",
                "Bangladesh": "u,0,5p,0,3n|u,0,5i,0,5h,0,3n||",
                "Bhutan": "u,0,as|||",
                "Bolivia": "u,0,aa|||",
                "Brasilia": "u,0,5p,0,3a|u,0,5i,0,5h,0,3a||",
                "Brunei": "u,0,9o,0,a4|||",
                "Cape_Verde": "u,0,5p,0,1rs,0,3j|u,0,5i,0,5h,0,1rs,0,3j||",
                "Chamorro": "u,0,5p,0,6j|||",
                "Chatham": "u,0,5p,0,27|u,0,fq,0,27||",
                "Chile": "u,0,5p,0,49|u,0,5i,0,5h,0,49||",
                "China": "u,0,5p,0,4h|u,0,fq,0,4h||",
                "Choibalsan": "u,0,5p,0,2q|u,0,5i,0,5h,0,2q||",
                "Christmas": "u,0,15f,0,99|||",
                "Cocos": "u,0,oo,0,7v|||",
                "Colombia": "u,0,5p,0,4m|u,0,5i,0,5h,0,4m||",
                "Cook": "u,0,5p,0,oo,0,2v|u,0,5i,0,5h,0,4ld,0,oo,0,2v||",
                "Cuba": "u,0,5p,0,34|u,0,fq,0,34||",
                "Davis": "u,0,5s|||",
                "DumontDUrville": "u,0,5b,5,5j|||",
                "East_Timor": "u,0,7n,0,mt|||",
                "Easter": "u,0,5p,0,15f,0,7t|u,0,5i,0,5h,0,15f,0,7t||",
                "Ecuador": "u,0,ai|||",
                "Europe_Central": "u,0,5p,0,13b,0,pl|u,0,5i,0,5h,0,13b,0,pl||",
                "Europe_Eastern": "u,0,5p,0,13b,0,mt|u,0,5i,0,5h,0,13b,0,mt||",
                "Europe_Further_Eastern": "u,0,13b,0,4lf,0,4lg|||",
                "Europe_Western": "u,0,5p,0,13b,0,ht|u,0,5i,0,5h,0,13b,0,ht||",
                "Falkland": "u,0,5p,0,oo,0,4v|u,0,5i,0,5h,0,oo,0,4v||",
                "Fiji": "u,0,5p,0,4q|u,0,5i,0,5h,0,4q||",
                "French_Guiana": "u,0,7a,0,2g6|||",
                "French_Southern": "u,0,2g6,0,1jj,0,17t,0,4li|||",
                "Galapagos": "u,0,ak|||",
                "Gambier": "u,0,6v|||",
                "Georgia": "u,0,5p,0,2u|u,0,5i,0,5h,0,2u||",
                "Gilbert_Islands": "u,0,oo,0,6q|||",
                "GMT": "u,0,4lj,0,5u|||",
                "Greenland_Eastern": "u,0,5p,0,2k,0,mt|u,0,5i,0,5h,0,2k,0,mt||",
                "Greenland_Western": "u,0,5p,0,2k,0,ht|u,0,5i,0,5h,0,2k,0,ht||",
                "Gulf": "u,0,367|||",
                "Guyana": "u,0,7a|||",
                "Hawaii_Aleutian": "u,0,5p,0,4g,5,62|u,0,fq,0,4g,5,62||",
                "Hong_Kong": "u,0,5p,0,2l,0,2p|u,0,5i,0,5h,0,2l,0,2p||",
                "Hovd": "u,0,5p,0,2g|u,0,5i,0,5h,0,2g||",
                "India": "u,0,5p,0,af|||",
                "Indian_Ocean": "u,0,4lk,0,1to|||",
                "Indochina": "u,0,9p|||",
                "Indonesia_Central": "u,0,2t,0,pl|||",
                "Indonesia_Eastern": "u,0,2t,0,mt|||",
                "Indonesia_Western": "u,0,2t,0,ht|||",
                "Iran": "u,0,5p,0,5g|u,0,fq,0,5g||",
                "Irkutsk": "u,0,5p,0,3h|u,0,5i,0,5h,0,3h||",
                "Israel": "u,0,5p,0,4e|u,0,fq,0,4e||",
                "Japan": "u,0,5p,0,2g8|u,0,fq,0,2g8||",
                "Kamchatka": "u,0,5p,0,37,5,3k|u,0,5i,0,5h,0,37,5,3k||",
                "Kazakhstan_Eastern": "u,0,45,0,mt|||",
                "Kazakhstan_Western": "u,0,45,0,ht|||",
                "Korea": "u,0,5p,0,qf|u,0,fq,0,qf||",
                "Kosrae": "u,0,6f|||",
                "Krasnoyarsk": "u,0,5p,0,41|u,0,5i,0,5h,0,41||",
                "Kyrgystan": "u,0,bl|||",
                "Line_Islands": "u,0,oo,0,ac|||",
                "Lord_Howe": "u,0,5p,0,1u,0,24|u,0,fq,0,1u,0,24||",
                "Macquarie": "u,0,15f,0,6m|||",
                "Magadan": "u,0,5p,0,2o|u,0,5i,0,5h,0,2o||",
                "Malaysia": "u,0,bf||1r5|",
                "Maldives": "u,0,9k|||",
                "Marquesas": "u,0,9e|||",
                "Marshall_Islands": "u,0,oo,0,7o|||",
                "Mauritius": "u,0,5p,0,4u|u,0,5i,0,5h,0,4u||",
                "Mawson": "u,0,63|||",
                "Mexico_Northwest": "u,0,5p,0,ht,0,1se,0,5e|u,0,fq,0,ht,0,1se,0,5e||",
                "Mexico_Pacific": "u,0,5p,0,10c,0,5e|u,0,fq,0,10c,0,5e||",
                "Mongolia": "u,0,5p,0,4f,0,2m|u,0,5i,0,5h,0,4f,0,2m||",
                "Moscow": "u,0,5p,0,71|u,0,5i,0,5h,0,71||",
                "Myanmar": "u,0,8v|||",
                "Nauru": "u,0,5v|||",
                "Nepal": "u,0,8u|||",
                "New_Caledonia": "u,0,5p,0,1m,0,4o|u,0,5i,0,5h,0,1m,0,4o||",
                "New_Zealand": "u,0,5p,0,1m,0,74|u,0,fq,0,1m,0,74||",
                "Newfoundland": "u,0,5p,0,53|u,0,fq,0,53||",
                "Niue": "u,0,8g|||",
                "Norfolk": "u,0,oo,0,6l|||",
                "Noronha": "u,0,5p,0,1s,0,2,0,23|u,0,5i,0,5h,0,1s,0,2,0,23||",
                "Novosibirsk": "u,0,5p,0,3b|u,0,5i,0,5h,0,3b||",
                "Omsk": "u,0,5p,0,26|u,0,5i,0,5h,0,26||",
                "Pakistan": "u,0,5p,0,3i|u,0,5i,0,5h,0,3i||",
                "Palau": "u,0,9b|||",
                "Papua_New_Guinea": "u,0,9a,0,1m,0,a8|||",
                "Paraguay": "u,0,5p,0,3c|u,0,5i,0,5h,0,3c||",
                "Peru": "u,0,5p,0,4n|u,0,5i,0,5h,0,4n||",
                "Philippines": "u,0,5p,0,1sg|u,0,5i,0,5h,0,1sg||",
                "Phoenix_Islands": "u,0,oo,0,90|||",
                "Pierre_Miquelon": "u,0,5p,0,8i,0,33,0,17t,0,35|u,0,fq,0,8i,0,33,0,17t,0,35||",
                "Pitcairn": "u,0,7e|||",
                "Ponape": "u,0,9s|||",
                "Pyongyang": "u,0,8a|||",
                "Reunion": "u,0,b4|||",
                "Rothera": "u,0,5q|||",
                "Sakhalin": "u,0,5p,0,55|u,0,5i,0,5h,0,55||",
                "Samara": "u,0,5p,0,29|u,0,5i,0,5h,0,29||",
                "Samoa": "u,0,5p,0,2c|u,0,5i,0,5h,0,2c||",
                "Seychelles": "u,0,8k|||",
                "Singapore": "u,0,5p,0,196||2ad|",
                "Solomon": "u,0,oo,0,b5|||",
                "South_Georgia": "u,0,2u,0,1jj|||",
                "Suriname": "u,0,8q|||",
                "Syowa": "u,0,65|||",
                "Tahiti": "u,0,8n|||",
                "Taipei": "u,0,5p,0,3d|u,0,fq,0,3d||",
                "Tajikistan": "u,0,bj|||",
                "Tokelau": "u,0,5d|||",
                "Tonga": "u,0,5p,0,2j|u,0,5i,0,5h,0,2j||",
                "Truk": "u,0,6p|||",
                "Turkmenistan": "u,0,5p,0,4t|u,0,5i,0,5h,0,4t||",
                "Tuvalu": "u,0,6h|||",
                "Uruguay": "u,0,5p,0,4k|u,0,5i,0,5h,0,4k||",
                "Uzbekistan": "u,0,5p,0,60|u,0,5i,0,5h,0,60||",
                "Vanuatu": "u,0,5p,0,2f|u,0,5i,0,5h,0,2f||",
                "Venezuela": "u,0,7s|||",
                "Vladivostok": "u,0,5p,0,28|u,0,5i,0,5h,0,28||",
                "Volgograd": "u,0,5p,0,3f|u,0,5i,0,5h,0,3f||",
                "Vostok": "u,0,5t|||",
                "Wake": "u,0,15f,0,5r|||",
                "Wallis": "u,0,5f,0,17t,0,5k|||",
                "Yakutsk": "u,0,5p,0,3u|u,0,5i,0,5h,0,3u||",
                "Yekaterinburg": "u,0,5p,0,5l|u,0,5i,0,5h,0,5l||"
            }
        },
        "ms-SG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|u,0,5i,0,5h,0,3i4||",
                "Europe/London": "|u,0,5i,0,5h,0,c1||"
            },
            "metazone": {
                "Afghanistan": "u,0,b6|||",
                "Africa_Central": "u,0,c5,0,pl|||",
                "Africa_Eastern": "u,0,c5,0,mt|||",
                "Africa_Southern": "u,0,5p,0,c5,0,1jj|||",
                "Africa_Western": "u,0,5p,0,c5,0,ht|u,0,5i,0,5h,0,c5,0,ht||",
                "Alaska": "u,0,5p,0,3p|u,0,fq,0,3p||",
                "Amazon": "u,0,5p,0,5m|u,0,5i,0,5h,0,5m||",
                "America_Central": "u,0,5p,0,4la|u,0,fq,0,pl||",
                "America_Eastern": "u,0,5p,0,mt|u,0,fq,0,mt||",
                "America_Mountain": "u,0,5p,0,2g4|u,0,4lc,0,fq,0,2g4||",
                "America_Pacific": "u,0,5p,0,10c|u,0,fq,0,10c||",
                "Anadyr": "u,0,5p,0,3l|u,0,5i,0,5h,0,3l||",
                "Apia": "u,0,4,0,3e|u,0,fq,0,3e||",
                "Arabian": "u,0,5p,0,1qe|u,0,fq,0,1qe||",
                "Argentina": "u,0,5p,0,1e|u,0,5i,0,5h,0,1e||",
                "Argentina_Western": "u,0,5p,0,1e,0,ht|u,0,5i,0,5h,0,1e,0,ht||",
                "Armenia": "u,0,5p,0,4l|u,0,5i,0,5h,0,4l||",
                "Atlantic": "u,0,5p,0,r2|u,0,fq,0,r2||",
                "Australia_Central": "u,0,5p,0,4c,0,pl|u,0,fq,0,4c,0,pl||",
                "Australia_CentralWestern": "u,0,5p,0,ht,0,pl,0,4c|u,0,fq,0,ht,0,pl,0,4c||",
                "Australia_Eastern": "u,0,5p,0,mt,0,4c|u,0,fq,0,4c,0,mt||",
                "Australia_Western": "u,0,5p,0,4c,0,ht|u,0,fq,0,4c,0,ht||",
                "Azerbaijan": "u,0,5p,0,7m|u,0,5i,0,5h,0,7m||",
                "Azores": "u,0,5p,0,4r|u,0,5i,0,5h,0,4r||",
                "Bangladesh": "u,0,5p,0,3n|u,0,5i,0,5h,0,3n||",
                "Bhutan": "u,0,as|||",
                "Bolivia": "u,0,aa|||",
                "Brasilia": "u,0,5p,0,3a|u,0,5i,0,5h,0,3a||",
                "Brunei": "u,0,9o,0,a4|||",
                "Cape_Verde": "u,0,5p,0,1rs,0,3j|u,0,5i,0,5h,0,1rs,0,3j||",
                "Chamorro": "u,0,5p,0,6j|||",
                "Chatham": "u,0,5p,0,27|u,0,fq,0,27||",
                "Chile": "u,0,5p,0,49|u,0,5i,0,5h,0,49||",
                "China": "u,0,5p,0,4h|u,0,fq,0,4h||",
                "Choibalsan": "u,0,5p,0,2q|u,0,5i,0,5h,0,2q||",
                "Christmas": "u,0,15f,0,99|||",
                "Cocos": "u,0,oo,0,7v|||",
                "Colombia": "u,0,5p,0,4m|u,0,5i,0,5h,0,4m||",
                "Cook": "u,0,5p,0,oo,0,2v|u,0,5i,0,5h,0,4ld,0,oo,0,2v||",
                "Cuba": "u,0,5p,0,34|u,0,fq,0,34||",
                "Davis": "u,0,5s|||",
                "DumontDUrville": "u,0,5b,5,5j|||",
                "East_Timor": "u,0,7n,0,mt|||",
                "Easter": "u,0,5p,0,15f,0,7t|u,0,5i,0,5h,0,15f,0,7t||",
                "Ecuador": "u,0,ai|||",
                "Europe_Central": "u,0,5p,0,13b,0,pl|u,0,5i,0,5h,0,13b,0,pl||",
                "Europe_Eastern": "u,0,5p,0,13b,0,mt|u,0,5i,0,5h,0,13b,0,mt||",
                "Europe_Further_Eastern": "u,0,13b,0,4lf,0,4lg|||",
                "Europe_Western": "u,0,5p,0,13b,0,ht|u,0,5i,0,5h,0,13b,0,ht||",
                "Falkland": "u,0,5p,0,oo,0,4v|u,0,5i,0,5h,0,oo,0,4v||",
                "Fiji": "u,0,5p,0,4q|u,0,5i,0,5h,0,4q||",
                "French_Guiana": "u,0,7a,0,2g6|||",
                "French_Southern": "u,0,2g6,0,1jj,0,17t,0,4li|||",
                "Galapagos": "u,0,ak|||",
                "Gambier": "u,0,6v|||",
                "Georgia": "u,0,5p,0,2u|u,0,5i,0,5h,0,2u||",
                "Gilbert_Islands": "u,0,oo,0,6q|||",
                "GMT": "u,0,4lj,0,5u|||",
                "Greenland_Eastern": "u,0,5p,0,2k,0,mt|u,0,5i,0,5h,0,2k,0,mt||",
                "Greenland_Western": "u,0,5p,0,2k,0,ht|u,0,5i,0,5h,0,2k,0,ht||",
                "Gulf": "u,0,367|||",
                "Guyana": "u,0,7a|||",
                "Hawaii_Aleutian": "u,0,5p,0,4g,5,62|u,0,fq,0,4g,5,62||",
                "Hong_Kong": "u,0,5p,0,2l,0,2p|u,0,5i,0,5h,0,2l,0,2p||",
                "Hovd": "u,0,5p,0,2g|u,0,5i,0,5h,0,2g||",
                "India": "u,0,5p,0,af|||",
                "Indian_Ocean": "u,0,4lk,0,1to|||",
                "Indochina": "u,0,9p|||",
                "Indonesia_Central": "u,0,2t,0,pl|||",
                "Indonesia_Eastern": "u,0,2t,0,mt|||",
                "Indonesia_Western": "u,0,2t,0,ht|||",
                "Iran": "u,0,5p,0,5g|u,0,fq,0,5g||",
                "Irkutsk": "u,0,5p,0,3h|u,0,5i,0,5h,0,3h||",
                "Israel": "u,0,5p,0,4e|u,0,fq,0,4e||",
                "Japan": "u,0,5p,0,2g8|u,0,fq,0,2g8||",
                "Kamchatka": "u,0,5p,0,37,5,3k|u,0,5i,0,5h,0,37,5,3k||",
                "Kazakhstan_Eastern": "u,0,45,0,mt|||",
                "Kazakhstan_Western": "u,0,45,0,ht|||",
                "Korea": "u,0,5p,0,qf|u,0,fq,0,qf||",
                "Kosrae": "u,0,6f|||",
                "Krasnoyarsk": "u,0,5p,0,41|u,0,5i,0,5h,0,41||",
                "Kyrgystan": "u,0,bl|||",
                "Line_Islands": "u,0,oo,0,ac|||",
                "Lord_Howe": "u,0,5p,0,1u,0,24|u,0,fq,0,1u,0,24||",
                "Macquarie": "u,0,15f,0,6m|||",
                "Magadan": "u,0,5p,0,2o|u,0,5i,0,5h,0,2o||",
                "Malaysia": "u,0,bf||1r5|",
                "Maldives": "u,0,9k|||",
                "Marquesas": "u,0,9e|||",
                "Marshall_Islands": "u,0,oo,0,7o|||",
                "Mauritius": "u,0,5p,0,4u|u,0,5i,0,5h,0,4u||",
                "Mawson": "u,0,63|||",
                "Mexico_Northwest": "u,0,5p,0,ht,0,1se,0,5e|u,0,fq,0,ht,0,1se,0,5e||",
                "Mexico_Pacific": "u,0,5p,0,10c,0,5e|u,0,fq,0,10c,0,5e||",
                "Mongolia": "u,0,5p,0,4f,0,2m|u,0,5i,0,5h,0,4f,0,2m||",
                "Moscow": "u,0,5p,0,71|u,0,5i,0,5h,0,71||",
                "Myanmar": "u,0,8v|||",
                "Nauru": "u,0,5v|||",
                "Nepal": "u,0,8u|||",
                "New_Caledonia": "u,0,5p,0,1m,0,4o|u,0,5i,0,5h,0,1m,0,4o||",
                "New_Zealand": "u,0,5p,0,1m,0,74|u,0,fq,0,1m,0,74||",
                "Newfoundland": "u,0,5p,0,53|u,0,fq,0,53||",
                "Niue": "u,0,8g|||",
                "Norfolk": "u,0,oo,0,6l|||",
                "Noronha": "u,0,5p,0,1s,0,2,0,23|u,0,5i,0,5h,0,1s,0,2,0,23||",
                "Novosibirsk": "u,0,5p,0,3b|u,0,5i,0,5h,0,3b||",
                "Omsk": "u,0,5p,0,26|u,0,5i,0,5h,0,26||",
                "Pakistan": "u,0,5p,0,3i|u,0,5i,0,5h,0,3i||",
                "Palau": "u,0,9b|||",
                "Papua_New_Guinea": "u,0,9a,0,1m,0,a8|||",
                "Paraguay": "u,0,5p,0,3c|u,0,5i,0,5h,0,3c||",
                "Peru": "u,0,5p,0,4n|u,0,5i,0,5h,0,4n||",
                "Philippines": "u,0,5p,0,1sg|u,0,5i,0,5h,0,1sg||",
                "Phoenix_Islands": "u,0,oo,0,90|||",
                "Pierre_Miquelon": "u,0,5p,0,8i,0,33,0,17t,0,35|u,0,fq,0,8i,0,33,0,17t,0,35||",
                "Pitcairn": "u,0,7e|||",
                "Ponape": "u,0,9s|||",
                "Pyongyang": "u,0,8a|||",
                "Reunion": "u,0,b4|||",
                "Rothera": "u,0,5q|||",
                "Sakhalin": "u,0,5p,0,55|u,0,5i,0,5h,0,55||",
                "Samara": "u,0,5p,0,29|u,0,5i,0,5h,0,29||",
                "Samoa": "u,0,5p,0,2c|u,0,5i,0,5h,0,2c||",
                "Seychelles": "u,0,8k|||",
                "Singapore": "u,0,5p,0,196||2ad|",
                "Solomon": "u,0,oo,0,b5|||",
                "South_Georgia": "u,0,2u,0,1jj|||",
                "Suriname": "u,0,8q|||",
                "Syowa": "u,0,65|||",
                "Tahiti": "u,0,8n|||",
                "Taipei": "u,0,5p,0,3d|u,0,fq,0,3d||",
                "Tajikistan": "u,0,bj|||",
                "Tokelau": "u,0,5d|||",
                "Tonga": "u,0,5p,0,2j|u,0,5i,0,5h,0,2j||",
                "Truk": "u,0,6p|||",
                "Turkmenistan": "u,0,5p,0,4t|u,0,5i,0,5h,0,4t||",
                "Tuvalu": "u,0,6h|||",
                "Uruguay": "u,0,5p,0,4k|u,0,5i,0,5h,0,4k||",
                "Uzbekistan": "u,0,5p,0,60|u,0,5i,0,5h,0,60||",
                "Vanuatu": "u,0,5p,0,2f|u,0,5i,0,5h,0,2f||",
                "Venezuela": "u,0,7s|||",
                "Vladivostok": "u,0,5p,0,28|u,0,5i,0,5h,0,28||",
                "Volgograd": "u,0,5p,0,3f|u,0,5i,0,5h,0,3f||",
                "Vostok": "u,0,5t|||",
                "Wake": "u,0,15f,0,5r|||",
                "Wallis": "u,0,5f,0,17t,0,5k|||",
                "Yakutsk": "u,0,5p,0,3u|u,0,5i,0,5h,0,3u||",
                "Yekaterinburg": "u,0,5p,0,5l|u,0,5i,0,5h,0,5l||"
            }
        },
        "ms": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|u,0,5i,0,5h,0,3i4||",
                "Europe/London": "|u,0,5i,0,5h,0,c1||"
            },
            "metazone": {
                "Afghanistan": "u,0,b6|||",
                "Africa_Central": "u,0,c5,0,pl|||",
                "Africa_Eastern": "u,0,c5,0,mt|||",
                "Africa_Southern": "u,0,5p,0,c5,0,1jj|||",
                "Africa_Western": "u,0,5p,0,c5,0,ht|u,0,5i,0,5h,0,c5,0,ht||",
                "Alaska": "u,0,5p,0,3p|u,0,fq,0,3p||",
                "Amazon": "u,0,5p,0,5m|u,0,5i,0,5h,0,5m||",
                "America_Central": "u,0,5p,0,4la|u,0,fq,0,pl||",
                "America_Eastern": "u,0,5p,0,mt|u,0,fq,0,mt||",
                "America_Mountain": "u,0,5p,0,2g4|u,0,4lc,0,fq,0,2g4||",
                "America_Pacific": "u,0,5p,0,10c|u,0,fq,0,10c||",
                "Anadyr": "u,0,5p,0,3l|u,0,5i,0,5h,0,3l||",
                "Apia": "u,0,4,0,3e|u,0,fq,0,3e||",
                "Arabian": "u,0,5p,0,1qe|u,0,fq,0,1qe||",
                "Argentina": "u,0,5p,0,1e|u,0,5i,0,5h,0,1e||",
                "Argentina_Western": "u,0,5p,0,1e,0,ht|u,0,5i,0,5h,0,1e,0,ht||",
                "Armenia": "u,0,5p,0,4l|u,0,5i,0,5h,0,4l||",
                "Atlantic": "u,0,5p,0,r2|u,0,fq,0,r2||",
                "Australia_Central": "u,0,5p,0,4c,0,pl|u,0,fq,0,4c,0,pl||",
                "Australia_CentralWestern": "u,0,5p,0,ht,0,pl,0,4c|u,0,fq,0,ht,0,pl,0,4c||",
                "Australia_Eastern": "u,0,5p,0,mt,0,4c|u,0,fq,0,4c,0,mt||",
                "Australia_Western": "u,0,5p,0,4c,0,ht|u,0,fq,0,4c,0,ht||",
                "Azerbaijan": "u,0,5p,0,7m|u,0,5i,0,5h,0,7m||",
                "Azores": "u,0,5p,0,4r|u,0,5i,0,5h,0,4r||",
                "Bangladesh": "u,0,5p,0,3n|u,0,5i,0,5h,0,3n||",
                "Bhutan": "u,0,as|||",
                "Bolivia": "u,0,aa|||",
                "Brasilia": "u,0,5p,0,3a|u,0,5i,0,5h,0,3a||",
                "Brunei": "u,0,9o,0,a4|||",
                "Cape_Verde": "u,0,5p,0,1rs,0,3j|u,0,5i,0,5h,0,1rs,0,3j||",
                "Chamorro": "u,0,5p,0,6j|||",
                "Chatham": "u,0,5p,0,27|u,0,fq,0,27||",
                "Chile": "u,0,5p,0,49|u,0,5i,0,5h,0,49||",
                "China": "u,0,5p,0,4h|u,0,fq,0,4h||",
                "Choibalsan": "u,0,5p,0,2q|u,0,5i,0,5h,0,2q||",
                "Christmas": "u,0,15f,0,99|||",
                "Cocos": "u,0,oo,0,7v|||",
                "Colombia": "u,0,5p,0,4m|u,0,5i,0,5h,0,4m||",
                "Cook": "u,0,5p,0,oo,0,2v|u,0,5i,0,5h,0,4ld,0,oo,0,2v||",
                "Cuba": "u,0,5p,0,34|u,0,fq,0,34||",
                "Davis": "u,0,5s|||",
                "DumontDUrville": "u,0,5b,5,5j|||",
                "East_Timor": "u,0,7n,0,mt|||",
                "Easter": "u,0,5p,0,15f,0,7t|u,0,5i,0,5h,0,15f,0,7t||",
                "Ecuador": "u,0,ai|||",
                "Europe_Central": "u,0,5p,0,13b,0,pl|u,0,5i,0,5h,0,13b,0,pl||",
                "Europe_Eastern": "u,0,5p,0,13b,0,mt|u,0,5i,0,5h,0,13b,0,mt||",
                "Europe_Further_Eastern": "u,0,13b,0,4lf,0,4lg|||",
                "Europe_Western": "u,0,5p,0,13b,0,ht|u,0,5i,0,5h,0,13b,0,ht||",
                "Falkland": "u,0,5p,0,oo,0,4v|u,0,5i,0,5h,0,oo,0,4v||",
                "Fiji": "u,0,5p,0,4q|u,0,5i,0,5h,0,4q||",
                "French_Guiana": "u,0,7a,0,2g6|||",
                "French_Southern": "u,0,2g6,0,1jj,0,17t,0,4li|||",
                "Galapagos": "u,0,ak|||",
                "Gambier": "u,0,6v|||",
                "Georgia": "u,0,5p,0,2u|u,0,5i,0,5h,0,2u||",
                "Gilbert_Islands": "u,0,oo,0,6q|||",
                "GMT": "u,0,4lj,0,5u|||",
                "Greenland_Eastern": "u,0,5p,0,2k,0,mt|u,0,5i,0,5h,0,2k,0,mt||",
                "Greenland_Western": "u,0,5p,0,2k,0,ht|u,0,5i,0,5h,0,2k,0,ht||",
                "Gulf": "u,0,367|||",
                "Guyana": "u,0,7a|||",
                "Hawaii_Aleutian": "u,0,5p,0,4g,5,62|u,0,fq,0,4g,5,62||",
                "Hong_Kong": "u,0,5p,0,2l,0,2p|u,0,5i,0,5h,0,2l,0,2p||",
                "Hovd": "u,0,5p,0,2g|u,0,5i,0,5h,0,2g||",
                "India": "u,0,5p,0,af|||",
                "Indian_Ocean": "u,0,4lk,0,1to|||",
                "Indochina": "u,0,9p|||",
                "Indonesia_Central": "u,0,2t,0,pl|||",
                "Indonesia_Eastern": "u,0,2t,0,mt|||",
                "Indonesia_Western": "u,0,2t,0,ht|||",
                "Iran": "u,0,5p,0,5g|u,0,fq,0,5g||",
                "Irkutsk": "u,0,5p,0,3h|u,0,5i,0,5h,0,3h||",
                "Israel": "u,0,5p,0,4e|u,0,fq,0,4e||",
                "Japan": "u,0,5p,0,2g8|u,0,fq,0,2g8||",
                "Kamchatka": "u,0,5p,0,37,5,3k|u,0,5i,0,5h,0,37,5,3k||",
                "Kazakhstan_Eastern": "u,0,45,0,mt|||",
                "Kazakhstan_Western": "u,0,45,0,ht|||",
                "Korea": "u,0,5p,0,qf|u,0,fq,0,qf||",
                "Kosrae": "u,0,6f|||",
                "Krasnoyarsk": "u,0,5p,0,41|u,0,5i,0,5h,0,41||",
                "Kyrgystan": "u,0,bl|||",
                "Line_Islands": "u,0,oo,0,ac|||",
                "Lord_Howe": "u,0,5p,0,1u,0,24|u,0,fq,0,1u,0,24||",
                "Macquarie": "u,0,15f,0,6m|||",
                "Magadan": "u,0,5p,0,2o|u,0,5i,0,5h,0,2o||",
                "Malaysia": "u,0,bf||1r5|",
                "Maldives": "u,0,9k|||",
                "Marquesas": "u,0,9e|||",
                "Marshall_Islands": "u,0,oo,0,7o|||",
                "Mauritius": "u,0,5p,0,4u|u,0,5i,0,5h,0,4u||",
                "Mawson": "u,0,63|||",
                "Mexico_Northwest": "u,0,5p,0,ht,0,1se,0,5e|u,0,fq,0,ht,0,1se,0,5e||",
                "Mexico_Pacific": "u,0,5p,0,10c,0,5e|u,0,fq,0,10c,0,5e||",
                "Mongolia": "u,0,5p,0,4f,0,2m|u,0,5i,0,5h,0,4f,0,2m||",
                "Moscow": "u,0,5p,0,71|u,0,5i,0,5h,0,71||",
                "Myanmar": "u,0,8v|||",
                "Nauru": "u,0,5v|||",
                "Nepal": "u,0,8u|||",
                "New_Caledonia": "u,0,5p,0,1m,0,4o|u,0,5i,0,5h,0,1m,0,4o||",
                "New_Zealand": "u,0,5p,0,1m,0,74|u,0,fq,0,1m,0,74||",
                "Newfoundland": "u,0,5p,0,53|u,0,fq,0,53||",
                "Niue": "u,0,8g|||",
                "Norfolk": "u,0,oo,0,6l|||",
                "Noronha": "u,0,5p,0,1s,0,2,0,23|u,0,5i,0,5h,0,1s,0,2,0,23||",
                "Novosibirsk": "u,0,5p,0,3b|u,0,5i,0,5h,0,3b||",
                "Omsk": "u,0,5p,0,26|u,0,5i,0,5h,0,26||",
                "Pakistan": "u,0,5p,0,3i|u,0,5i,0,5h,0,3i||",
                "Palau": "u,0,9b|||",
                "Papua_New_Guinea": "u,0,9a,0,1m,0,a8|||",
                "Paraguay": "u,0,5p,0,3c|u,0,5i,0,5h,0,3c||",
                "Peru": "u,0,5p,0,4n|u,0,5i,0,5h,0,4n||",
                "Philippines": "u,0,5p,0,1sg|u,0,5i,0,5h,0,1sg||",
                "Phoenix_Islands": "u,0,oo,0,90|||",
                "Pierre_Miquelon": "u,0,5p,0,8i,0,33,0,17t,0,35|u,0,fq,0,8i,0,33,0,17t,0,35||",
                "Pitcairn": "u,0,7e|||",
                "Ponape": "u,0,9s|||",
                "Pyongyang": "u,0,8a|||",
                "Reunion": "u,0,b4|||",
                "Rothera": "u,0,5q|||",
                "Sakhalin": "u,0,5p,0,55|u,0,5i,0,5h,0,55||",
                "Samara": "u,0,5p,0,29|u,0,5i,0,5h,0,29||",
                "Samoa": "u,0,5p,0,2c|u,0,5i,0,5h,0,2c||",
                "Seychelles": "u,0,8k|||",
                "Singapore": "u,0,5p,0,196||2ad|",
                "Solomon": "u,0,oo,0,b5|||",
                "South_Georgia": "u,0,2u,0,1jj|||",
                "Suriname": "u,0,8q|||",
                "Syowa": "u,0,65|||",
                "Tahiti": "u,0,8n|||",
                "Taipei": "u,0,5p,0,3d|u,0,fq,0,3d||",
                "Tajikistan": "u,0,bj|||",
                "Tokelau": "u,0,5d|||",
                "Tonga": "u,0,5p,0,2j|u,0,5i,0,5h,0,2j||",
                "Truk": "u,0,6p|||",
                "Turkmenistan": "u,0,5p,0,4t|u,0,5i,0,5h,0,4t||",
                "Tuvalu": "u,0,6h|||",
                "Uruguay": "u,0,5p,0,4k|u,0,5i,0,5h,0,4k||",
                "Uzbekistan": "u,0,5p,0,60|u,0,5i,0,5h,0,60||",
                "Vanuatu": "u,0,5p,0,2f|u,0,5i,0,5h,0,2f||",
                "Venezuela": "u,0,7s|||",
                "Vladivostok": "u,0,5p,0,28|u,0,5i,0,5h,0,28||",
                "Volgograd": "u,0,5p,0,3f|u,0,5i,0,5h,0,3f||",
                "Vostok": "u,0,5t|||",
                "Wake": "u,0,15f,0,5r|||",
                "Wallis": "u,0,5f,0,17t,0,5k|||",
                "Yakutsk": "u,0,5p,0,3u|u,0,5i,0,5h,0,3u||",
                "Yekaterinburg": "u,0,5p,0,5l|u,0,5i,0,5h,0,5l||"
            }
        },
        "mt": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Europe_Central": "88j,0,88k,0,88l,0,4|88j,0,88k,0,88l,0,8nn,5,8nm|h0|h4",
                "Europe_Eastern": "||gu|gv",
                "Europe_Western": "||h1|h2",
                "GMT": "||ah|"
            }
        },
        "mua": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "my": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ccf||",
                "Europe/London": "|ccg||"
            },
            "metazone": {
                "Afghanistan": "8nl,0,fm|||",
                "Africa_Central": "8nk,0,fm|||",
                "Africa_Eastern": "8nj,0,fm|||",
                "Africa_Southern": "8ni,0,fm|||",
                "Africa_Western": "88t,0,id|88t,0,m5,0,fm||",
                "Alaska": "8nh|8ng,0,2vd||",
                "Amazon": "893,0,id|893,0,894||",
                "America_Central": "1sr,0,8nf|1sr,0,2ha,0,2vd||",
                "America_Eastern": "1sr,0,8ne|1sr,0,899,0,2vd||",
                "America_Mountain": "1sr,0,8nd|1sr,0,8nc,0,2vd||",
                "America_Pacific": "1sr,0,8nb|1sr,0,4ma,0,2vd||",
                "Apia": "8na|8n9,0,m5,0,fm||",
                "Arabian": "89g,0,id|89g,0,m5,0,fm||",
                "Argentina": "3kb,0,id|3kb,0,894||",
                "Argentina_Western": "3ka,0,3kb,0,id|3ka,0,3kb,0,m5,0,fm||",
                "Armenia": "89j,0,id|89j,0,m5,0,fm||",
                "Atlantic": "89k,0,id|89k,0,m5,0,id||",
                "Australia_Central": "311,0,2ha,0,id|311,0,2ha,0,m5,0,fm||",
                "Australia_CentralWestern": "311,0,89m,0,id|311,0,89m,0,m5,0,fm||",
                "Australia_Eastern": "89n,0,id|89n,0,m5,0,fm||",
                "Australia_Western": "8n8,0,id|311,0,m5,0,fm||",
                "Azerbaijan": "89p,0,id|89p,0,m5,0,fm||",
                "Azores": "89q,0,id|89q,0,m5,0,fm||",
                "Bangladesh": "89r,0,id|89r,0,m5,0,fm||",
                "Bhutan": "8n7,0,fm|||",
                "Bolivia": "8n6,0,fm|||",
                "Brasilia": "89u,0,id|89u,0,m5,0,fm||",
                "Brunei": "8n5,0,id|||",
                "Cape_Verde": "8a0,0,8a1,0,id|8a0,0,8a1,0,m5,0,fm||",
                "Chamorro": "8n4|||",
                "Chatham": "8n3|8n2,0,m5,0,fm||",
                "Chile": "8a5,0,id|8a5,0,m5,0,fm||",
                "China": "8a6,0,id|8a6,0,m5,0,fm||",
                "Choibalsan": "8n1|8n0||",
                "Christmas": "8mv|||",
                "Cocos": "8mu|||",
                "Colombia": "8ab,0,id|8ab,0,m5,0,fm||",
                "Cook": "8mt|8ms||",
                "Cuba": "8ae,0,id|8ae,0,m5,0,id||",
                "Davis": "8mr|||",
                "DumontDUrville": "8mq,0,8mp|||",
                "East_Timor": "8mo,0,fm|||",
                "Easter": "8mn|8mm||",
                "Ecuador": "8ml,0,fm|||",
                "Europe_Central": "8am,0,2ha,0,id|8am,0,2ha,0,m5,0,fm||",
                "Europe_Eastern": "8an,0,id|8an,0,m5,0,fm||",
                "Europe_Further_Eastern": "8mk|||",
                "Europe_Western": "8ap,0,id|8ap,0,m5,0,fm||",
                "Falkland": "8aq,0,id|8aq,0,m5,0,fm||",
                "Fiji": "8mj|8mi||",
                "French_Guiana": "8mh,0,8mg,0,fm|||",
                "French_Southern": "8mf,0,8me|||",
                "Galapagos": "8md|||",
                "Gambier": "8mc,0,fm|||",
                "Georgia": "8b3,0,id|8b3,0,m5,0,fm||",
                "Gilbert_Islands": "8mb|||",
                "GMT": "8ma,0,id|||",
                "Greenland_Eastern": "8m9,0,id|8m8,0,m5,0,id||",
                "Greenland_Western": "3ka,0,8m7,0,id|3ka,0,8m6,0,m5,0,id||",
                "Gulf": "8m5,0,fm|||",
                "Guyana": "8m4,0,fm|||",
                "Hawaii_Aleutian": "8bc,0,8bd,0,id|8bc,0,8bd,0,m5,0,id||",
                "Hong_Kong": "8be,0,id|8be,0,m5,0,fm||",
                "Hovd": "8m3|8m2||",
                "India": "8bh,0,id|||",
                "Indian_Ocean": "8bh,0,8m1,0,fm|||",
                "Indochina": "8m0,0,fm|||",
                "Indonesia_Central": "2ha,0,4no,0,fm|||",
                "Indonesia_Eastern": "899,0,4no,0,fm|||",
                "Indonesia_Western": "8lv,0,4no,0,fm|||",
                "Iran": "8bm,0,id|8bm,0,m5,0,fm||",
                "Irkutsk": "8lu|8lt||",
                "Israel": "8bp,0,id|8bp,0,m5,0,fm||",
                "Japan": "8bq,0,id|8bq,0,m5,0,fm||",
                "Kazakhstan_Eastern": "8ls,0,fm|||",
                "Kazakhstan_Western": "8lr|||",
                "Korea": "8bt,0,id|8bt,0,m5,0,fm||",
                "Kosrae": "8lq|||",
                "Krasnoyarsk": "8lp|8lo||",
                "Kyrgystan": "8ln,0,fm|||",
                "Lanka": "8lm,0,fm|||",
                "Line_Islands": "8ll|||",
                "Lord_Howe": "8lk|8lj,0,m5,0,fm||",
                "Macau": "8c6,0,id|8c6,0,m5,0,fm||",
                "Macquarie": "8li,0,fm|||",
                "Magadan": "8lh|8lg||",
                "Malaysia": "8lf,0,fm|||",
                "Maldives": "8le,0,fm|||",
                "Marquesas": "8ld|||",
                "Marshall_Islands": "8lc|||",
                "Mauritius": "8ce,0,id|8ce,0,m5,0,fm||",
                "Mawson": "8lb|||",
                "Mexico_Northwest": "8cg,0,8la,0,id|8cg,0,8l9,0,m5,0,id||",
                "Mexico_Pacific": "8l8,0,4ma,0,id|8l7,0,4ma,0,m5,0,id||",
                "Mongolia": "8l6|8l5||",
                "Moscow": "8l4|8l3,0,m5,0,fm||",
                "Myanmar": "8l2,0,fm|||",
                "Nauru": "8l1|||",
                "Nepal": "8l0,0,fm|||",
                "New_Caledonia": "8kv|8ku||",
                "New_Zealand": "8cu,0,id|8cu,0,m5,0,fm||",
                "Newfoundland": "8cv,0,id|8cv,0,m5,0,id||",
                "Niue": "8kt|||",
                "Norfolk": "8ks|||",
                "Noronha": "8kr|8kq||",
                "Novosibirsk": "8kp|8ko||",
                "Omsk": "8kn|8km||",
                "Pakistan": "8d8,0,id|8d8,0,m5,0,fm||",
                "Palau": "8kl,0,fm|||",
                "Papua_New_Guinea": "8kk,0,fm|||",
                "Paraguay": "8db,0,id|8db,0,m5,0,fm||",
                "Peru": "8dc,0,id|8dc,0,m5,0,fm||",
                "Philippines": "8dd,0,id|8dd,0,m5,0,fm||",
                "Phoenix_Islands": "8kj|||",
                "Pierre_Miquelon": "8ki|8kh,0,8kg,0,m5,0,id||",
                "Pitcairn": "8kf,0,fm|||",
                "Ponape": "8ke|||",
                "Pyongyang": "8kd,0,fm|||",
                "Reunion": "8kc,0,fm|||",
                "Rothera": "8kb|||",
                "Sakhalin": "8ka|8k9,0,m5,0,fm||",
                "Samoa": "8k8|8k7,0,m5,0,fm||",
                "Seychelles": "8k6,0,fm|||",
                "Singapore": "8k5,0,fm|||",
                "Solomon": "8k4|||",
                "South_Georgia": "8k3,0,fm|||",
                "Suriname": "8k2|||",
                "Syowa": "8k1|||",
                "Tahiti": "8k0|||",
                "Taipei": "8e2,0,id|8e2,0,m5,0,fm||",
                "Tajikistan": "8jv,0,fm|||",
                "Tokelau": "8ju,0,fm|||",
                "Tonga": "8jt|8js||",
                "Truk": "8jr|||",
                "Turkmenistan": "8e8,0,id|8e8,0,m5,0,fm||",
                "Tuvalu": "8jq|||",
                "Uruguay": "8ea,0,id|8ea,0,m5,0,fm||",
                "Uzbekistan": "8eb,0,id|8eb,0,m5,0,fm||",
                "Vanuatu": "8jp|8jo||",
                "Venezuela": "8jn,0,fm|||",
                "Vladivostok": "8ef,0,id|8ef,0,m5,0,fm||",
                "Volgograd": "8jm|8jl||",
                "Vostok": "8jk|||",
                "Wake": "8jj|||",
                "Wallis": "8ji,0,8jh,0,fm|||",
                "Yakutsk": "8jg|8jf||",
                "Yekaterinburg": "8pp|8je||"
            }
        },
        "mzn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "naq": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nb-SJ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|5sn,0,3t||",
                "Europe/London": "|5q6,0,3t||"
            },
            "metazone": {
                "Acre": "3q,0,1l|3q,0,3t||",
                "Afghanistan": "2qd,0,4j|||",
                "Africa_Central": "4p3,0,4j|||",
                "Africa_Eastern": "8es,0,4j|||",
                "Africa_Southern": "4p4,0,4j|||",
                "Africa_Western": "2k2,0,1l|2k2,0,3t||",
                "Alaska": "3gf,0,1l|3gf,0,3t|18f|18t",
                "Almaty": "56,k,0,12j|56,k,0,3t||",
                "Amazon": "1l,0,89,0,bg|3t,0,89,0,bg||",
                "America_Central": "1l,0,89,0,3gc,0,2lb,0,98,5,m2|3t,0,89,0,3gc,0,2lb,0,98,5,m2|19q|19p",
                "America_Eastern": "1l,0,89,0,1i6,0,1rc,0,3gb|3t,0,89,0,1i6,0,1rc,0,3gb|19o|19n",
                "America_Mountain": "1l,0,89,0,145,0,3ga,0,3f1|3t,0,89,0,145,0,3ga,0,3f1|18r|16i",
                "America_Pacific": "1l,0,89,0,1i6,0,1rc,0,1r8|3t,0,89,0,1i6,0,1rc,0,1r8|19l|19k",
                "Anadyr": "1j0,0,3ds,0,1l|1j0,0,3ds,0,3t||",
                "Apia": "1l,0,89,0,3e|3t,0,89,0,3e||",
                "Aqtau": "50,k,0,12j|50,k,0,3t||",
                "Aqtobe": "5o,k,0,12j|5o,k,0,3t||",
                "Arabian": "3dp,0,12j|3dp,0,3t||",
                "Argentina": "3bt,0,1l|3bt,0,3t||",
                "Argentina_Western": "3bk,0,1l|3bk,0,3t||",
                "Armenia": "3b0,0,1l|3b0,0,3t||",
                "Atlantic": "3ah,0,12j|3ah,0,3t|19i|190",
                "Australia_Central": "1j3,0,1l|1j3,0,3t||",
                "Australia_CentralWestern": "1o9,5,1j3,0,1l|1o9,5,1j3,0,3t||",
                "Australia_Eastern": "3aa,0,1l|3aa,0,3t||",
                "Australia_Western": "2km,0,1l|2km,0,3t||",
                "Azerbaijan": "3a8,0,1l|3a8,0,3t||",
                "Azores": "3a7,0,1l|3a7,0,3t||",
                "Bangladesh": "1o1,0,1l|1o1,0,3t||",
                "Bhutan": "2vt,0,4j|||",
                "Bolivia": "2vl,0,4j|||",
                "Brasilia": "1l,0,89,0,3a|3t,0,89,0,3a||",
                "Brunei": "h9,0,89,0,9o,0,a4|||",
                "Cape_Verde": "1l,0,89,0,39u,0,3j|3t,0,89,0,39u,0,3j||",
                "Casey": "b7,5,4j|||",
                "Chamorro": "h9,0,89,0,6j|||",
                "Chatham": "1l,0,89,0,27|3t,0,89,0,27||",
                "Chile": "1ng,0,1l|1ng,0,3t||",
                "China": "1la,0,1l|1la,0,3t||",
                "Choibalsan": "1l,0,89,0,148|3t,0,89,0,148||",
                "Christmas": "h9,0,89,0,8ft|||",
                "Cocos": "h9,0,89,0,8fu|||",
                "Colombia": "1nv,0,1l|1nv,0,3t||",
                "Cook": "1l,0,89,0,39o|8g1,0,3t,0,89,0,39o||",
                "Cuba": "39n,0,1l|39n,0,3t||",
                "Davis": "h9,0,89,0,5s|||",
                "DumontDUrville": "h9,0,89,0,5b,0,5j|||",
                "East_Timor": "8g3,0,4j|||",
                "Easter": "1l,0,89,0,39m|3t,0,89,0,39m||",
                "Ecuador": "2ug,0,4j|||",
                "Europe_Central": "2kq,0,1l|2kq,0,3t|h0|h4",
                "Europe_Eastern": "2kr,0,1l|2kr,0,3t|gu|gv",
                "Europe_Further_Eastern": "8g8,5,2kr,0,4j|||",
                "Europe_Western": "2ks,0,1l|2ks,0,3t|h1|h2",
                "Falkland": "1l,0,89,0,39h|3t,0,89,0,39h||",
                "Fiji": "39g,0,1l|39g,0,3t||",
                "French_Guiana": "h9,0,89,0,463,0,7a|||",
                "French_Southern": "h9,0,89,0,47f,0,8gc,0,8gd|||",
                "Galapagos": "h9,0,89,0,8ge|||",
                "Gambier": "h9,0,89,0,6v|||",
                "Georgia": "1ol,0,1l|1ol,0,3t||",
                "Gilbert_Islands": "h9,0,89,0,8gg|||",
                "GMT": "5u,0,4qf||ah|",
                "Greenland_Eastern": "39e,0,1l|39e,0,3t||",
                "Greenland_Western": "39c,0,1l|39c,0,3t||",
                "Guam": "83,5,4j|||",
                "Gulf": "h9,0,89,0,8gk|||",
                "Guyana": "8gl,0,4j|||",
                "Hawaii_Aleutian": "1l,0,89,0,4g,0,u0,0,39a|3t,0,89,0,4g,0,u0,0,39a|17j|17l",
                "Hong_Kong": "1l,0,89,0,qh|3t,0,89,0,qh||",
                "Hovd": "1l,0,89,0,399|3t,0,89,0,399||",
                "India": "2n9,0,4j|||",
                "Indian_Ocean": "h9,0,89,0,8gp|||",
                "Indochina": "31n,0,4j|||",
                "Indonesia_Central": "8gr,0,4j|||",
                "Indonesia_Eastern": "8gs,0,4j|||",
                "Indonesia_Western": "8gt,0,4j|||",
                "Iran": "1me,0,1l|1me,0,3t||",
                "Irkutsk": "1l,0,89,0,3h|3t,0,89,0,3h||",
                "Israel": "393,0,1l|393,0,3t||",
                "Japan": "1l9,0,1l|1l9,0,3t||",
                "Kamchatka": "1j0,0,391,5,390,0,1l|1j0,0,391,5,390,0,3t||",
                "Kazakhstan_Eastern": "8h3,0,4j|||",
                "Kazakhstan_Western": "8h4,0,4j|||",
                "Korea": "1pa,0,1l|1pa,0,3t||",
                "Kosrae": "h9,0,89,0,6f|||",
                "Krasnoyarsk": "1l,0,89,0,l0|3t,0,89,0,l0||",
                "Kyrgystan": "8h6,0,4j|||",
                "Lanka": "9c,5,4j|||",
                "Line_Islands": "h9,0,89,0,8h7|||",
                "Lord_Howe": "1l,0,89,0,1u,0,24,5,38s|3t,0,89,0,1u,0,24,5,38s||",
                "Macau": "57,k,0,12j|57,k,0,3t||",
                "Macquarie": "h9,0,89,0,8h9|||",
                "Magadan": "1l,0,89,0,2o|3t,0,89,0,2o||",
                "Malaysia": "2lt,0,4j|||",
                "Maldives": "8hb,0,4j|||",
                "Marquesas": "h9,0,89,0,8hc|||",
                "Marshall_Islands": "h9,0,89,0,8hd|||",
                "Mauritius": "38n,0,1l|38n,0,3t||",
                "Mawson": "h9,0,89,0,63|||",
                "Mexico_Northwest": "1l,0,89,0,38m,0,5e|3t,0,89,0,38m,0,5e||",
                "Mexico_Pacific": "1l,0,89,0,1i6,0,38l,0,1r8|3t,0,89,0,1i6,0,38l,0,1r8||",
                "Mongolia": "1l,0,89,0,4f,0,2m|3t,0,89,0,4f,0,2m||",
                "Moscow": "1l,0,89,0,pi|3t,0,89,0,pi||",
                "Myanmar": "8hh,0,4j|||",
                "Nauru": "8hi,0,4j|||",
                "Nepal": "8hj,0,4j|||",
                "New_Caledonia": "38h,0,1l|38h,0,3t||",
                "New_Zealand": "38f,0,1l|38f,0,3t||",
                "Newfoundland": "1l,0,89,0,53|3t,0,89,0,53||",
                "Niue": "h9,0,89,0,8g|||",
                "Norfolk": "h9,0,89,0,8hm|||",
                "Noronha": "1l,0,89,0,1s,0,2,0,23|3t,0,89,0,1s,0,2,0,23||",
                "North_Mariana": "98,5,8hn,5,4j|||",
                "Novosibirsk": "1l,0,89,0,3b|3t,0,89,0,3b||",
                "Omsk": "1l,0,89,0,26|3t,0,89,0,26||",
                "Pakistan": "1m6,0,1l|1m6,0,3t||",
                "Palau": "8hp,0,4j|||",
                "Papua_New_Guinea": "8hq,0,4j|||",
                "Paraguay": "1pd,0,1l|1pd,0,3t||",
                "Peru": "1nj,0,1l|1nj,0,3t||",
                "Philippines": "1ms,0,1l|1ms,0,3t||",
                "Phoenix_Islands": "h9,0,89,0,8hu|||",
                "Pierre_Miquelon": "1l,0,89,0,8i,5,33,5,8p,5,35|3t,0,89,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "h9,0,89,0,7e|||",
                "Ponape": "h9,0,89,0,e5|||",
                "Pyongyang": "h9,0,89,0,8a|||",
                "Qyzylorda": "4d,k,0,12j|4d,k,0,3t||",
                "Reunion": "h9,0,89,0,i8|||",
                "Rothera": "h9,0,89,0,5q|||",
                "Sakhalin": "1l,0,89,0,55|3t,0,89,0,55||",
                "Samara": "1j0,0,37u,0,1l|1j0,0,37u,0,3t||",
                "Samoa": "1nt,0,1l|1nt,0,3t||",
                "Seychelles": "8i1,0,4j|||",
                "Singapore": "8i2,0,4j|||",
                "Solomon": "h9,0,89,0,8i3|||",
                "South_Georgia": "h9,0,89,0,8i4,5,2u|||",
                "Suriname": "8i5,0,4j|||",
                "Syowa": "h9,0,89,0,65|||",
                "Tahiti": "8i6,0,4j|||",
                "Taipei": "1l,0,89,0,3d|3t,0,89,0,3d||",
                "Tajikistan": "8i7,0,4j|||",
                "Tokelau": "h9,0,89,0,5d|||",
                "Tonga": "374,0,1l|374,0,3t||",
                "Truk": "h9,0,89,0,8i9|||",
                "Turkmenistan": "1l4,0,1l|1l4,0,3t||",
                "Tuvalu": "8ib,0,4j|||",
                "Uruguay": "1p5,0,1l|1p5,0,3t||",
                "Uzbekistan": "36h,0,1l|36h,0,3t||",
                "Vanuatu": "368,0,1l|368,0,3t||",
                "Venezuela": "2tl,0,4j|||",
                "Vladivostok": "1l,0,89,0,28|3t,0,89,0,28||",
                "Volgograd": "1l,0,89,0,3f|3t,0,89,0,3f||",
                "Vostok": "h9,0,89,0,5t|||",
                "Wake": "h9,0,89,0,5r,0,1f|||",
                "Wallis": "h9,0,89,0,5f,5,0,u0,0,8ig|||",
                "Yakutsk": "1l,0,89,0,qj|3t,0,89,0,qj||",
                "Yekaterinburg": "1l,0,89,0,q6|3t,0,89,0,q6||"
            }
        },
        "nb": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|5sn,0,3t||",
                "Europe/London": "|5q6,0,3t||"
            },
            "metazone": {
                "Acre": "3q,0,1l|3q,0,3t||",
                "Afghanistan": "2qd,0,4j|||",
                "Africa_Central": "4p3,0,4j|||",
                "Africa_Eastern": "8es,0,4j|||",
                "Africa_Southern": "4p4,0,4j|||",
                "Africa_Western": "2k2,0,1l|2k2,0,3t||",
                "Alaska": "3gf,0,1l|3gf,0,3t|18f|18t",
                "Almaty": "56,k,0,12j|56,k,0,3t||",
                "Amazon": "1l,0,89,0,bg|3t,0,89,0,bg||",
                "America_Central": "1l,0,89,0,3gc,0,2lb,0,98,5,m2|3t,0,89,0,3gc,0,2lb,0,98,5,m2|19q|19p",
                "America_Eastern": "1l,0,89,0,1i6,0,1rc,0,3gb|3t,0,89,0,1i6,0,1rc,0,3gb|19o|19n",
                "America_Mountain": "1l,0,89,0,145,0,3ga,0,3f1|3t,0,89,0,145,0,3ga,0,3f1|18r|16i",
                "America_Pacific": "1l,0,89,0,1i6,0,1rc,0,1r8|3t,0,89,0,1i6,0,1rc,0,1r8|19l|19k",
                "Anadyr": "1j0,0,3ds,0,1l|1j0,0,3ds,0,3t||",
                "Apia": "1l,0,89,0,3e|3t,0,89,0,3e||",
                "Aqtau": "50,k,0,12j|50,k,0,3t||",
                "Aqtobe": "5o,k,0,12j|5o,k,0,3t||",
                "Arabian": "3dp,0,12j|3dp,0,3t||",
                "Argentina": "3bt,0,1l|3bt,0,3t||",
                "Argentina_Western": "3bk,0,1l|3bk,0,3t||",
                "Armenia": "3b0,0,1l|3b0,0,3t||",
                "Atlantic": "3ah,0,12j|3ah,0,3t|19i|190",
                "Australia_Central": "1j3,0,1l|1j3,0,3t||",
                "Australia_CentralWestern": "1o9,5,1j3,0,1l|1o9,5,1j3,0,3t||",
                "Australia_Eastern": "3aa,0,1l|3aa,0,3t||",
                "Australia_Western": "2km,0,1l|2km,0,3t||",
                "Azerbaijan": "3a8,0,1l|3a8,0,3t||",
                "Azores": "3a7,0,1l|3a7,0,3t||",
                "Bangladesh": "1o1,0,1l|1o1,0,3t||",
                "Bhutan": "2vt,0,4j|||",
                "Bolivia": "2vl,0,4j|||",
                "Brasilia": "1l,0,89,0,3a|3t,0,89,0,3a||",
                "Brunei": "h9,0,89,0,9o,0,a4|||",
                "Cape_Verde": "1l,0,89,0,39u,0,3j|3t,0,89,0,39u,0,3j||",
                "Casey": "b7,5,4j|||",
                "Chamorro": "h9,0,89,0,6j|||",
                "Chatham": "1l,0,89,0,27|3t,0,89,0,27||",
                "Chile": "1ng,0,1l|1ng,0,3t||",
                "China": "1la,0,1l|1la,0,3t||",
                "Choibalsan": "1l,0,89,0,148|3t,0,89,0,148||",
                "Christmas": "h9,0,89,0,8ft|||",
                "Cocos": "h9,0,89,0,8fu|||",
                "Colombia": "1nv,0,1l|1nv,0,3t||",
                "Cook": "1l,0,89,0,39o|8g1,0,3t,0,89,0,39o||",
                "Cuba": "39n,0,1l|39n,0,3t||",
                "Davis": "h9,0,89,0,5s|||",
                "DumontDUrville": "h9,0,89,0,5b,0,5j|||",
                "East_Timor": "8g3,0,4j|||",
                "Easter": "1l,0,89,0,39m|3t,0,89,0,39m||",
                "Ecuador": "2ug,0,4j|||",
                "Europe_Central": "2kq,0,1l|2kq,0,3t|h0|h4",
                "Europe_Eastern": "2kr,0,1l|2kr,0,3t|gu|gv",
                "Europe_Further_Eastern": "8g8,5,2kr,0,4j|||",
                "Europe_Western": "2ks,0,1l|2ks,0,3t|h1|h2",
                "Falkland": "1l,0,89,0,39h|3t,0,89,0,39h||",
                "Fiji": "39g,0,1l|39g,0,3t||",
                "French_Guiana": "h9,0,89,0,463,0,7a|||",
                "French_Southern": "h9,0,89,0,47f,0,8gc,0,8gd|||",
                "Galapagos": "h9,0,89,0,8ge|||",
                "Gambier": "h9,0,89,0,6v|||",
                "Georgia": "1ol,0,1l|1ol,0,3t||",
                "Gilbert_Islands": "h9,0,89,0,8gg|||",
                "GMT": "5u,0,4qf||ah|",
                "Greenland_Eastern": "39e,0,1l|39e,0,3t||",
                "Greenland_Western": "39c,0,1l|39c,0,3t||",
                "Guam": "83,5,4j|||",
                "Gulf": "h9,0,89,0,8gk|||",
                "Guyana": "8gl,0,4j|||",
                "Hawaii_Aleutian": "1l,0,89,0,4g,0,u0,0,39a|3t,0,89,0,4g,0,u0,0,39a|17j|17l",
                "Hong_Kong": "1l,0,89,0,qh|3t,0,89,0,qh||",
                "Hovd": "1l,0,89,0,399|3t,0,89,0,399||",
                "India": "2n9,0,4j|||",
                "Indian_Ocean": "h9,0,89,0,8gp|||",
                "Indochina": "31n,0,4j|||",
                "Indonesia_Central": "8gr,0,4j|||",
                "Indonesia_Eastern": "8gs,0,4j|||",
                "Indonesia_Western": "8gt,0,4j|||",
                "Iran": "1me,0,1l|1me,0,3t||",
                "Irkutsk": "1l,0,89,0,3h|3t,0,89,0,3h||",
                "Israel": "393,0,1l|393,0,3t||",
                "Japan": "1l9,0,1l|1l9,0,3t||",
                "Kamchatka": "1j0,0,391,5,390,0,1l|1j0,0,391,5,390,0,3t||",
                "Kazakhstan_Eastern": "8h3,0,4j|||",
                "Kazakhstan_Western": "8h4,0,4j|||",
                "Korea": "1pa,0,1l|1pa,0,3t||",
                "Kosrae": "h9,0,89,0,6f|||",
                "Krasnoyarsk": "1l,0,89,0,l0|3t,0,89,0,l0||",
                "Kyrgystan": "8h6,0,4j|||",
                "Lanka": "9c,5,4j|||",
                "Line_Islands": "h9,0,89,0,8h7|||",
                "Lord_Howe": "1l,0,89,0,1u,0,24,5,38s|3t,0,89,0,1u,0,24,5,38s||",
                "Macau": "57,k,0,12j|57,k,0,3t||",
                "Macquarie": "h9,0,89,0,8h9|||",
                "Magadan": "1l,0,89,0,2o|3t,0,89,0,2o||",
                "Malaysia": "2lt,0,4j|||",
                "Maldives": "8hb,0,4j|||",
                "Marquesas": "h9,0,89,0,8hc|||",
                "Marshall_Islands": "h9,0,89,0,8hd|||",
                "Mauritius": "38n,0,1l|38n,0,3t||",
                "Mawson": "h9,0,89,0,63|||",
                "Mexico_Northwest": "1l,0,89,0,38m,0,5e|3t,0,89,0,38m,0,5e||",
                "Mexico_Pacific": "1l,0,89,0,1i6,0,38l,0,1r8|3t,0,89,0,1i6,0,38l,0,1r8||",
                "Mongolia": "1l,0,89,0,4f,0,2m|3t,0,89,0,4f,0,2m||",
                "Moscow": "1l,0,89,0,pi|3t,0,89,0,pi||",
                "Myanmar": "8hh,0,4j|||",
                "Nauru": "8hi,0,4j|||",
                "Nepal": "8hj,0,4j|||",
                "New_Caledonia": "38h,0,1l|38h,0,3t||",
                "New_Zealand": "38f,0,1l|38f,0,3t||",
                "Newfoundland": "1l,0,89,0,53|3t,0,89,0,53||",
                "Niue": "h9,0,89,0,8g|||",
                "Norfolk": "h9,0,89,0,8hm|||",
                "Noronha": "1l,0,89,0,1s,0,2,0,23|3t,0,89,0,1s,0,2,0,23||",
                "North_Mariana": "98,5,8hn,5,4j|||",
                "Novosibirsk": "1l,0,89,0,3b|3t,0,89,0,3b||",
                "Omsk": "1l,0,89,0,26|3t,0,89,0,26||",
                "Pakistan": "1m6,0,1l|1m6,0,3t||",
                "Palau": "8hp,0,4j|||",
                "Papua_New_Guinea": "8hq,0,4j|||",
                "Paraguay": "1pd,0,1l|1pd,0,3t||",
                "Peru": "1nj,0,1l|1nj,0,3t||",
                "Philippines": "1ms,0,1l|1ms,0,3t||",
                "Phoenix_Islands": "h9,0,89,0,8hu|||",
                "Pierre_Miquelon": "1l,0,89,0,8i,5,33,5,8p,5,35|3t,0,89,0,8i,5,33,5,8p,5,35||",
                "Pitcairn": "h9,0,89,0,7e|||",
                "Ponape": "h9,0,89,0,e5|||",
                "Pyongyang": "h9,0,89,0,8a|||",
                "Qyzylorda": "4d,k,0,12j|4d,k,0,3t||",
                "Reunion": "h9,0,89,0,i8|||",
                "Rothera": "h9,0,89,0,5q|||",
                "Sakhalin": "1l,0,89,0,55|3t,0,89,0,55||",
                "Samara": "1j0,0,37u,0,1l|1j0,0,37u,0,3t||",
                "Samoa": "1nt,0,1l|1nt,0,3t||",
                "Seychelles": "8i1,0,4j|||",
                "Singapore": "8i2,0,4j|||",
                "Solomon": "h9,0,89,0,8i3|||",
                "South_Georgia": "h9,0,89,0,8i4,5,2u|||",
                "Suriname": "8i5,0,4j|||",
                "Syowa": "h9,0,89,0,65|||",
                "Tahiti": "8i6,0,4j|||",
                "Taipei": "1l,0,89,0,3d|3t,0,89,0,3d||",
                "Tajikistan": "8i7,0,4j|||",
                "Tokelau": "h9,0,89,0,5d|||",
                "Tonga": "374,0,1l|374,0,3t||",
                "Truk": "h9,0,89,0,8i9|||",
                "Turkmenistan": "1l4,0,1l|1l4,0,3t||",
                "Tuvalu": "8ib,0,4j|||",
                "Uruguay": "1p5,0,1l|1p5,0,3t||",
                "Uzbekistan": "36h,0,1l|36h,0,3t||",
                "Vanuatu": "368,0,1l|368,0,3t||",
                "Venezuela": "2tl,0,4j|||",
                "Vladivostok": "1l,0,89,0,28|3t,0,89,0,28||",
                "Volgograd": "1l,0,89,0,3f|3t,0,89,0,3f||",
                "Vostok": "h9,0,89,0,5t|||",
                "Wake": "h9,0,89,0,5r,0,1f|||",
                "Wallis": "h9,0,89,0,5f,5,0,u0,0,8ig|||",
                "Yakutsk": "1l,0,89,0,qj|3t,0,89,0,qj||",
                "Yekaterinburg": "1l,0,89,0,q6|3t,0,89,0,q6||"
            }
        },
        "nd": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nds-NL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nds": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ne-IN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4ni,0,5n,0,1b||",
                "Europe/London": "|5pu,0,a5,0,1b||"
            },
            "metazone": {
                "Afghanistan": "8ih,0,1b|||",
                "Africa_Central": "14c,0,1or,0,1b|||",
                "Africa_Eastern": "oq,0,1or,0,1b|||",
                "Africa_Southern": "1jl,0,1or,0,1b|||",
                "Africa_Western": "124,0,1or,0,5n,0,1b|124,0,1or,0,a5,0,1b||",
                "Alaska": "35v,0,5n,0,1b|35v,0,jo,0,1b||",
                "Amazon": "35n,0,5n,0,1b|35n,0,a5,0,1b||",
                "America_Central": "14c,0,5n,0,1b|14c,0,jo,0,1b||",
                "America_Eastern": "oq,0,5n,0,1b|oq,0,jo,0,1b||",
                "America_Mountain": "35m,0,5n,0,1b|35m,0,jo,0,1b||",
                "America_Pacific": "24g,0,5n,0,1b|24g,0,jo,0,1b||",
                "Apia": "35g,0,5n,0,1b|35g,0,jo,0,1b||",
                "Arabian": "2bb,0,5n,0,1b|2bb,0,jo,0,1b||",
                "Argentina": "1u1,0,5n,0,1b|1u1,0,a5,0,1b||",
                "Argentina_Western": "q4,0,1u1,0,5n,0,1b|q4,0,1u1,0,a5,0,1b||",
                "Armenia": "35b,0,5n,0,1b|35b,0,a5,0,1b||",
                "Atlantic": "359,0,5n,0,1b|359,0,jo,0,1b||",
                "Australia_Central": "14c,0,177,0,5n,0,1b|14c,0,177,0,jo,0,1b||",
                "Australia_CentralWestern": "14c,0,q4,0,177,0,5n,0,1b|14c,0,q4,0,177,0,jo,0,1b||",
                "Australia_Eastern": "oq,0,177,0,5n,0,1b|oq,0,177,0,jo,0,1b||",
                "Australia_Western": "q4,0,177,0,5n,0,1b|q4,0,177,0,jo,0,1b||",
                "Azerbaijan": "34r,0,5n,0,1b|34r,0,a5,0,1b||",
                "Azores": "345,0,5n,0,1b|345,0,a5,0,1b||",
                "Bangladesh": "341,0,5n,0,1b|341,0,a5,0,1b||",
                "Bhutan": "8j1,0,1b|||",
                "Bolivia": "8j2,0,1b|||",
                "Brasilia": "8j3,0,5n,0,1b|8j4,0,a5,0,1b||",
                "Brunei": "8j5,0,4rf,0,1b|||",
                "Cape_Verde": "1s1,0,33u,0,5n,0,1b|1s1,0,33u,0,a5,0,1b||",
                "Chamorro": "8j7,0,5n,0,1b|||",
                "Chatham": "33p,0,5n,0,1b|33p,0,jo,0,1b||",
                "Chile": "1s0,0,5n,0,1b|1s0,0,a5,0,1b||",
                "China": "2ii,0,5n,0,1b|2ii,0,jo,0,1b||",
                "Choibalsan": "33j,0,5n,0,1b|33j,0,a5,0,1b||",
                "Christmas": "8ja,0,12h,0,1b|||",
                "Cocos": "4rg,0,12h,0,1b|||",
                "Colombia": "4hf,0,5n,0,1b|4hf,0,a5,0,1b||",
                "Cook": "1lj,0,12h,0,5n,0,1b|1lj,0,12h,0,8ep,0,a5,0,1b||",
                "Cuba": "3gh,0,5n,0,1b|3gh,0,jo,0,1b||",
                "Davis": "8en,0,1b|||",
                "DumontDUrville": "8em,5,8el,0,8ek,0,1b|||",
                "East_Timor": "oq,0,8ej,0,1b|||",
                "Easter": "2ds,0,1md,0,5n,0,1b|2ds,0,1md,0,4jl,0,1b||",
                "Ecuador": "8eh,0,1b|||",
                "Europe_Central": "14c,0,1e4,0,5n,0,1b|14c,0,1e4,0,a5,0,1b||",
                "Europe_Eastern": "oq,0,1e4,0,5n,0,1b|oq,0,1e4,0,a5,0,1b||",
                "Europe_Further_Eastern": "8ee,5,oq,0,1e4,0,1b|||",
                "Europe_Western": "q4,0,1e4,0,5n,0,1b|1e4,0,a5,0,1b||",
                "Falkland": "3gi,0,1md,0,5n,0,1b|3gi,0,1md,0,a5,0,1b||",
                "Fiji": "2e1,0,5n,0,1b|2e1,0,a5,0,1b||",
                "French_Guiana": "3gj,0,8e9,0,1b|||",
                "French_Southern": "3gj,0,35u,0,1re,0,8e6,0,1b|||",
                "Galapagos": "8e5,0,1b|||",
                "Gambier": "8e4,0,1b|||",
                "Georgia": "2ju,0,5n,0,1b|2ju,0,a5,0,1b||",
                "Gilbert_Islands": "35s,0,12h,0,1b|||",
                "GMT": "4ri,0,8e1,0,1b|||",
                "Greenland_Eastern": "oq,0,1rg,0,5n,0,1b|oq,0,1rg,0,a5,0,1b||",
                "Greenland_Western": "q4,0,1rg,0,5n,0,1b|q4,0,1rg,0,a5,0,1b||",
                "Gulf": "4kg,0,5n,0,1b|||",
                "Guyana": "2as,0,1b|||",
                "Hawaii_Aleutian": "21u,5,3gm,0,5n,0,1b|21u,5,3gm,0,jo,0,1b||",
                "Hong_Kong": "3gn,0,5n,0,1b|3gn,0,a5,0,1b||",
                "Hovd": "3go,0,5n,0,1b|3go,0,a5,0,1b||",
                "India": "27j,0,5n,0,1b||15a|",
                "Indian_Ocean": "8ds,0,4rj,0,1b|||",
                "Indochina": "8dr,0,1b|||",
                "Indonesia_Central": "14c,0,2jr,0,1b|||",
                "Indonesia_Eastern": "oq,0,2jr,0,1b|||",
                "Indonesia_Western": "q4,0,2jr,0,1b|||",
                "Iran": "3gp,0,5n,0,1b|3gp,0,jo,0,1b||",
                "Irkutsk": "3gq,0,5n,0,1b|3gq,0,a5,0,1b||",
                "Israel": "3gr,0,5n,0,1b|3gr,0,jo,0,1b||",
                "Japan": "2j1,0,5n,0,1b|2j1,0,jo,0,1b||",
                "Kazakhstan_Eastern": "oq,0,3gs,0,1b|||",
                "Kazakhstan_Western": "124,0,3gs,0,1b|||",
                "Korea": "3gt,0,5n,0,1b|3gt,0,jo,0,1b||",
                "Kosrae": "4rk,0,1b|||",
                "Krasnoyarsk": "3gu,0,5n,0,1b|3gu,0,a5,0,1b||",
                "Kyrgystan": "8dj,0,1b|||",
                "Line_Islands": "35k,0,12h,0,1b|||",
                "Lord_Howe": "3gv,0,3h0,0,5n,0,1b|3gv,0,3h0,0,jo,0,1b||",
                "Macquarie": "8dg,0,12h,0,1b|||",
                "Magadan": "2j8,0,5n,0,1b|2j8,0,a5,0,1b||",
                "Malaysia": "8df,0,1b|||",
                "Maldives": "8de,0,1b|||",
                "Marquesas": "8da,0,1b|||",
                "Marshall_Islands": "2ob,0,12h,0,1b|||",
                "Mauritius": "3h1,0,5n,0,1b|3h1,0,a5,0,1b||",
                "Mawson": "8d7,0,1b|||",
                "Mexico_Northwest": "2a5,0,124,0,3h4,0,5n,0,1b|2a5,0,124,0,3h4,0,jo,0,1b||",
                "Mexico_Pacific": "2jb,0,24g,0,5n,0,1b|2jb,0,24g,0,jo,0,1b||",
                "Mongolia": "1rp,0,2e6,0,5n,0,1b|1rp,0,2e6,0,a5,0,1b||",
                "Moscow": "3h5,0,5n,0,1b|3h5,0,a5,0,1b||",
                "Myanmar": "4ed,0,1b|||",
                "Nauru": "8d4,0,1b|||",
                "Nepal": "8d3,0,1b|||",
                "New_Caledonia": "3h6,0,3h8,0,5n,0,1b|3h6,0,3h8,0,a5,0,1b||",
                "New_Zealand": "3h9,0,5n,0,1b|3h9,0,jo,0,1b||",
                "Newfoundland": "8ct,0,5n,0,1b|8cs,0,jo,0,1b||",
                "Niue": "8cr,0,1b|||",
                "Norfolk": "8cq,0,1md,0,1b|||",
                "Noronha": "3ha,0,2jf,0,1n8,0,5n,0,1b|3ha,0,2jf,0,1n8,0,a5,0,1b||",
                "Novosibirsk": "3he,0,5n,0,1b|3he,0,a5,0,1b||",
                "Omsk": "1nb,0,5n,0,1b|1nb,0,a5,0,1b||",
                "Pakistan": "3hf,0,5n,0,1b|3hf,0,a5,0,1b||",
                "Palau": "8cm,0,1b|||",
                "Papua_New_Guinea": "8cl,0,1ld,0,355,0,1b|||",
                "Paraguay": "3hg,0,5n,0,1b|3hg,0,a5,0,1b||",
                "Peru": "354,0,5n,0,1b|2k9,0,a5,0,1b||",
                "Philippines": "3hk,0,5n,0,1b|3hk,0,a5,0,1b||",
                "Phoenix_Islands": "8ci,0,12h,0,1b|||",
                "Pierre_Miquelon": "3hl,0,3hm,0,1re,0,3hn,0,5n,0,1b|3hl,0,3hm,0,1re,0,3hn,0,jo,0,1b||",
                "Pitcairn": "8cc,0,1b|||",
                "Ponape": "4e6,0,1b|||",
                "Pyongyang": "8cb,0,1b|||",
                "Reunion": "4l0,0,1b|||",
                "Rothera": "2qb,0,1b|||",
                "Sakhalin": "3ho,0,5n,0,1b|3ho,0,a5,0,1b||",
                "Samoa": "3hp,0,5n,0,1b|3hp,0,jo,0,1b||",
                "Seychelles": "8c8,0,1b|||",
                "Singapore": "4rq,0,5n,0,1b|||",
                "Solomon": "8c7,0,12h,0,1b|||",
                "South_Georgia": "1jl,0,2ju,0,1b|||",
                "Suriname": "8c5,0,1b|||",
                "Syowa": "34v,0,1b|||",
                "Tahiti": "34u,0,1b|||",
                "Taipei": "3hq,0,5n,0,1b|3hq,0,jo,0,1b||",
                "Tajikistan": "8c3,0,1b|||",
                "Tokelau": "8c2,0,1b|||",
                "Tonga": "1rl,0,5n,0,1b|1rl,0,a5,0,1b||",
                "Truk": "8c1,0,1b|||",
                "Turkmenistan": "1ri,0,5n,0,1b|1ri,0,a5,0,5n,0,1b||",
                "Tuvalu": "8c0,0,1b|||",
                "Uruguay": "8bv,0,5n,0,1b|2jp,0,a5,0,1b||",
                "Uzbekistan": "3hr,0,5n,0,1b|3hr,0,a5,0,1b||",
                "Vanuatu": "3hs,0,5n,0,1b|3hs,0,a5,0,1b||",
                "Venezuela": "8br,0,1b|||",
                "Vladivostok": "3ht,0,5n,0,1b|3ht,0,a5,0,1b||",
                "Volgograd": "3hu,0,5n,0,1b|3hu,0,a5,0,1b||",
                "Vostok": "8bl,0,1b|||",
                "Wake": "34s,0,12h,0,1b|||",
                "Wallis": "8bj,0,1re,0,4l7,0,1b|||",
                "Yakutsk": "3hv,0,5n,0,1b|3hv,0,a5,0,1b||",
                "Yekaterinburg": "3i0,0,5n,0,1b|3i0,0,a5,0,1b||"
            }
        },
        "ne": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4ni,0,5n,0,1b||",
                "Europe/London": "|5pu,0,a5,0,1b||"
            },
            "metazone": {
                "Afghanistan": "8ih,0,1b|||",
                "Africa_Central": "14c,0,1or,0,1b|||",
                "Africa_Eastern": "oq,0,1or,0,1b|||",
                "Africa_Southern": "1jl,0,1or,0,1b|||",
                "Africa_Western": "124,0,1or,0,5n,0,1b|124,0,1or,0,a5,0,1b||",
                "Alaska": "35v,0,5n,0,1b|35v,0,jo,0,1b||",
                "Amazon": "35n,0,5n,0,1b|35n,0,a5,0,1b||",
                "America_Central": "14c,0,5n,0,1b|14c,0,jo,0,1b||",
                "America_Eastern": "oq,0,5n,0,1b|oq,0,jo,0,1b||",
                "America_Mountain": "35m,0,5n,0,1b|35m,0,jo,0,1b||",
                "America_Pacific": "24g,0,5n,0,1b|24g,0,jo,0,1b||",
                "Apia": "35g,0,5n,0,1b|35g,0,jo,0,1b||",
                "Arabian": "2bb,0,5n,0,1b|2bb,0,jo,0,1b||",
                "Argentina": "1u1,0,5n,0,1b|1u1,0,a5,0,1b||",
                "Argentina_Western": "q4,0,1u1,0,5n,0,1b|q4,0,1u1,0,a5,0,1b||",
                "Armenia": "35b,0,5n,0,1b|35b,0,a5,0,1b||",
                "Atlantic": "359,0,5n,0,1b|359,0,jo,0,1b||",
                "Australia_Central": "14c,0,177,0,5n,0,1b|14c,0,177,0,jo,0,1b||",
                "Australia_CentralWestern": "14c,0,q4,0,177,0,5n,0,1b|14c,0,q4,0,177,0,jo,0,1b||",
                "Australia_Eastern": "oq,0,177,0,5n,0,1b|oq,0,177,0,jo,0,1b||",
                "Australia_Western": "q4,0,177,0,5n,0,1b|q4,0,177,0,jo,0,1b||",
                "Azerbaijan": "34r,0,5n,0,1b|34r,0,a5,0,1b||",
                "Azores": "345,0,5n,0,1b|345,0,a5,0,1b||",
                "Bangladesh": "341,0,5n,0,1b|341,0,a5,0,1b||",
                "Bhutan": "8j1,0,1b|||",
                "Bolivia": "8j2,0,1b|||",
                "Brasilia": "8j3,0,5n,0,1b|8j4,0,a5,0,1b||",
                "Brunei": "8j5,0,4rf,0,1b|||",
                "Cape_Verde": "1s1,0,33u,0,5n,0,1b|1s1,0,33u,0,a5,0,1b||",
                "Chamorro": "8j7,0,5n,0,1b|||",
                "Chatham": "33p,0,5n,0,1b|33p,0,jo,0,1b||",
                "Chile": "1s0,0,5n,0,1b|1s0,0,a5,0,1b||",
                "China": "2ii,0,5n,0,1b|2ii,0,jo,0,1b||",
                "Choibalsan": "33j,0,5n,0,1b|33j,0,a5,0,1b||",
                "Christmas": "8ja,0,12h,0,1b|||",
                "Cocos": "4rg,0,12h,0,1b|||",
                "Colombia": "4hf,0,5n,0,1b|4hf,0,a5,0,1b||",
                "Cook": "1lj,0,12h,0,5n,0,1b|1lj,0,12h,0,8ep,0,a5,0,1b||",
                "Cuba": "3gh,0,5n,0,1b|3gh,0,jo,0,1b||",
                "Davis": "8en,0,1b|||",
                "DumontDUrville": "8em,5,8el,0,8ek,0,1b|||",
                "East_Timor": "oq,0,8ej,0,1b|||",
                "Easter": "2ds,0,1md,0,5n,0,1b|2ds,0,1md,0,4jl,0,1b||",
                "Ecuador": "8eh,0,1b|||",
                "Europe_Central": "14c,0,1e4,0,5n,0,1b|14c,0,1e4,0,a5,0,1b||",
                "Europe_Eastern": "oq,0,1e4,0,5n,0,1b|oq,0,1e4,0,a5,0,1b||",
                "Europe_Further_Eastern": "8ee,5,oq,0,1e4,0,1b|||",
                "Europe_Western": "q4,0,1e4,0,5n,0,1b|1e4,0,a5,0,1b||",
                "Falkland": "3gi,0,1md,0,5n,0,1b|3gi,0,1md,0,a5,0,1b||",
                "Fiji": "2e1,0,5n,0,1b|2e1,0,a5,0,1b||",
                "French_Guiana": "3gj,0,8e9,0,1b|||",
                "French_Southern": "3gj,0,35u,0,1re,0,8e6,0,1b|||",
                "Galapagos": "8e5,0,1b|||",
                "Gambier": "8e4,0,1b|||",
                "Georgia": "2ju,0,5n,0,1b|2ju,0,a5,0,1b||",
                "Gilbert_Islands": "35s,0,12h,0,1b|||",
                "GMT": "4ri,0,8e1,0,1b|||",
                "Greenland_Eastern": "oq,0,1rg,0,5n,0,1b|oq,0,1rg,0,a5,0,1b||",
                "Greenland_Western": "q4,0,1rg,0,5n,0,1b|q4,0,1rg,0,a5,0,1b||",
                "Gulf": "4kg,0,5n,0,1b|||",
                "Guyana": "2as,0,1b|||",
                "Hawaii_Aleutian": "21u,5,3gm,0,5n,0,1b|21u,5,3gm,0,jo,0,1b||",
                "Hong_Kong": "3gn,0,5n,0,1b|3gn,0,a5,0,1b||",
                "Hovd": "3go,0,5n,0,1b|3go,0,a5,0,1b||",
                "India": "27j,0,5n,0,1b|||",
                "Indian_Ocean": "8ds,0,4rj,0,1b|||",
                "Indochina": "8dr,0,1b|||",
                "Indonesia_Central": "14c,0,2jr,0,1b|||",
                "Indonesia_Eastern": "oq,0,2jr,0,1b|||",
                "Indonesia_Western": "q4,0,2jr,0,1b|||",
                "Iran": "3gp,0,5n,0,1b|3gp,0,jo,0,1b||",
                "Irkutsk": "3gq,0,5n,0,1b|3gq,0,a5,0,1b||",
                "Israel": "3gr,0,5n,0,1b|3gr,0,jo,0,1b||",
                "Japan": "2j1,0,5n,0,1b|2j1,0,jo,0,1b||",
                "Kazakhstan_Eastern": "oq,0,3gs,0,1b|||",
                "Kazakhstan_Western": "124,0,3gs,0,1b|||",
                "Korea": "3gt,0,5n,0,1b|3gt,0,jo,0,1b||",
                "Kosrae": "4rk,0,1b|||",
                "Krasnoyarsk": "3gu,0,5n,0,1b|3gu,0,a5,0,1b||",
                "Kyrgystan": "8dj,0,1b|||",
                "Line_Islands": "35k,0,12h,0,1b|||",
                "Lord_Howe": "3gv,0,3h0,0,5n,0,1b|3gv,0,3h0,0,jo,0,1b||",
                "Macquarie": "8dg,0,12h,0,1b|||",
                "Magadan": "2j8,0,5n,0,1b|2j8,0,a5,0,1b||",
                "Malaysia": "8df,0,1b|||",
                "Maldives": "8de,0,1b|||",
                "Marquesas": "8da,0,1b|||",
                "Marshall_Islands": "2ob,0,12h,0,1b|||",
                "Mauritius": "3h1,0,5n,0,1b|3h1,0,a5,0,1b||",
                "Mawson": "8d7,0,1b|||",
                "Mexico_Northwest": "2a5,0,124,0,3h4,0,5n,0,1b|2a5,0,124,0,3h4,0,jo,0,1b||",
                "Mexico_Pacific": "2jb,0,24g,0,5n,0,1b|2jb,0,24g,0,jo,0,1b||",
                "Mongolia": "1rp,0,2e6,0,5n,0,1b|1rp,0,2e6,0,a5,0,1b||",
                "Moscow": "3h5,0,5n,0,1b|3h5,0,a5,0,1b||",
                "Myanmar": "4ed,0,1b|||",
                "Nauru": "8d4,0,1b|||",
                "Nepal": "8d3,0,1b|||",
                "New_Caledonia": "3h6,0,3h8,0,5n,0,1b|3h6,0,3h8,0,a5,0,1b||",
                "New_Zealand": "3h9,0,5n,0,1b|3h9,0,jo,0,1b||",
                "Newfoundland": "8ct,0,5n,0,1b|8cs,0,jo,0,1b||",
                "Niue": "8cr,0,1b|||",
                "Norfolk": "8cq,0,1md,0,1b|||",
                "Noronha": "3ha,0,2jf,0,1n8,0,5n,0,1b|3ha,0,2jf,0,1n8,0,a5,0,1b||",
                "Novosibirsk": "3he,0,5n,0,1b|3he,0,a5,0,1b||",
                "Omsk": "1nb,0,5n,0,1b|1nb,0,a5,0,1b||",
                "Pakistan": "3hf,0,5n,0,1b|3hf,0,a5,0,1b||",
                "Palau": "8cm,0,1b|||",
                "Papua_New_Guinea": "8cl,0,1ld,0,355,0,1b|||",
                "Paraguay": "3hg,0,5n,0,1b|3hg,0,a5,0,1b||",
                "Peru": "354,0,5n,0,1b|2k9,0,a5,0,1b||",
                "Philippines": "3hk,0,5n,0,1b|3hk,0,a5,0,1b||",
                "Phoenix_Islands": "8ci,0,12h,0,1b|||",
                "Pierre_Miquelon": "3hl,0,3hm,0,1re,0,3hn,0,5n,0,1b|3hl,0,3hm,0,1re,0,3hn,0,jo,0,1b||",
                "Pitcairn": "8cc,0,1b|||",
                "Ponape": "4e6,0,1b|||",
                "Pyongyang": "8cb,0,1b|||",
                "Reunion": "4l0,0,1b|||",
                "Rothera": "2qb,0,1b|||",
                "Sakhalin": "3ho,0,5n,0,1b|3ho,0,a5,0,1b||",
                "Samoa": "3hp,0,5n,0,1b|3hp,0,jo,0,1b||",
                "Seychelles": "8c8,0,1b|||",
                "Singapore": "4rq,0,5n,0,1b|||",
                "Solomon": "8c7,0,12h,0,1b|||",
                "South_Georgia": "1jl,0,2ju,0,1b|||",
                "Suriname": "8c5,0,1b|||",
                "Syowa": "34v,0,1b|||",
                "Tahiti": "34u,0,1b|||",
                "Taipei": "3hq,0,5n,0,1b|3hq,0,jo,0,1b||",
                "Tajikistan": "8c3,0,1b|||",
                "Tokelau": "8c2,0,1b|||",
                "Tonga": "1rl,0,5n,0,1b|1rl,0,a5,0,1b||",
                "Truk": "8c1,0,1b|||",
                "Turkmenistan": "1ri,0,5n,0,1b|1ri,0,a5,0,5n,0,1b||",
                "Tuvalu": "8c0,0,1b|||",
                "Uruguay": "8bv,0,5n,0,1b|2jp,0,a5,0,1b||",
                "Uzbekistan": "3hr,0,5n,0,1b|3hr,0,a5,0,1b||",
                "Vanuatu": "3hs,0,5n,0,1b|3hs,0,a5,0,1b||",
                "Venezuela": "8br,0,1b|||",
                "Vladivostok": "3ht,0,5n,0,1b|3ht,0,a5,0,1b||",
                "Volgograd": "3hu,0,5n,0,1b|3hu,0,a5,0,1b||",
                "Vostok": "8bl,0,1b|||",
                "Wake": "34s,0,12h,0,1b|||",
                "Wallis": "8bj,0,1re,0,4l7,0,1b|||",
                "Yakutsk": "3hv,0,5n,0,1b|3hv,0,a5,0,1b||",
                "Yekaterinburg": "3i0,0,5n,0,1b|3i0,0,a5,0,1b||"
            }
        },
        "nl-AW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl-BE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl-BQ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl-CW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl-SR": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i||8ob|",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl-SX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1mr,0,1i||",
                "Europe/London": "|1mh,0,1i||",
                "Pacific/Honolulu": "||13h|13d"
            },
            "metazone": {
                "Acre": "3q,5,1h|3q,5,1i||",
                "Afghanistan": "2aa,0,2i|||",
                "Africa_Central": "1e1,5,qa,0,2i|||",
                "Africa_Eastern": "hc,5,qa,0,2i|||",
                "Africa_Southern": "1a2,5,qa,0,2i|||",
                "Africa_Western": "1g,5,qa,0,1h|1g,5,qa,0,1i||",
                "Alaska": "3p,5,1h|3p,5,1i||",
                "Almaty": "16j,5,c2,5,1h|16j,5,c2,5,1i||",
                "Amazon": "16q,5,1h|16q,5,1i||",
                "America_Central": "n,5,1h|n,5,1i||",
                "America_Eastern": "17,5,1h|17,5,1i||",
                "America_Mountain": "5a,5,1h|5a,5,1i||",
                "America_Pacific": "2h,5,1h|2h,5,1i||",
                "Anadyr": "3l,5,1h|3l,5,1i||",
                "Apia": "3e,5,1h|3e,5,1i||",
                "Aqtau": "50,5,1h|50,5,1i||",
                "Aqtobe": "rs,5,1h|rs,5,1i||",
                "Arabian": "t0,0,1h|t0,0,1i||",
                "Argentina": "sb,0,1h|sb,0,1i||",
                "Argentina_Western": "1g,5,sb,0,1h|1g,5,sb,0,1i||",
                "Armenia": "16v,0,1h|16v,0,1i||",
                "Atlantic": "6g,5,1h|6g,5,1i||",
                "Australia_Central": "ms,5,kv,0,1h|ms,5,kv,0,1i||",
                "Australia_CentralWestern": "ms,5,kv,0,174,0,1h|ms,5,kv,0,174,0,1i||",
                "Australia_Eastern": "hc,5,kv,0,1h|hc,5,kv,0,1i||",
                "Australia_Western": "1g,5,kv,0,1h|1g,5,kv,0,1i||",
                "Azerbaijan": "1ce,0,1h|1ce,0,1i||",
                "Azores": "qv,5,1h|qv,5,1i||",
                "Bangladesh": "17c,0,1h|17c,0,1i||",
                "Bhutan": "2a3,0,2i|||",
                "Bolivia": "2a2,0,2i|||",
                "Brasilia": "1e7,0,1h|1e7,0,1i||",
                "Brunei": "22q,0,2i|||",
                "Cape_Verde": "1e0,0,1h|1e0,0,1i||",
                "Casey": "b7,0,2i|||",
                "Chamorro": "6j,5,2i|||",
                "Chatham": "27,5,1h|27,5,1i||",
                "Chile": "1dk,0,1h|1dk,0,1i||",
                "China": "1da,0,1h|1da,0,1i||",
                "Choibalsan": "148,5,1h|148,5,1i||",
                "Christmas": "2a1,0,2i|||",
                "Cocos": "2a0,0,2i|||",
                "Colombia": "1at,0,1h|1at,0,1i||",
                "Cook": "1e2,0,1h|1e2,0,22m,0,1i||",
                "Cuba": "1du,0,1h|1du,0,1i||",
                "Davis": "5s,5,2i|||",
                "DumontDUrville": "5b,5,5j,5,2i|||",
                "East_Timor": "hc,5,22l,0,2i|||",
                "Easter": "1aj,0,1h|1aj,0,1i||",
                "Ecuador": "29v,0,2i|||",
                "Europe_Central": "ms,5,hv,0,1h|ms,5,hv,0,1i|h0|h4",
                "Europe_Eastern": "hc,5,hv,0,1h|hc,5,hv,0,1i|gu|gv",
                "Europe_Further_Eastern": "1q5,5,29t,5,hv,0,2i|||",
                "Europe_Western": "1g,5,hv,0,1h|1g,5,hv,0,1i|h1|h2",
                "Falkland": "1dt,0,1h|1dt,0,1i||",
                "Fiji": "1d4,0,1h|1d4,0,1i||",
                "French_Guiana": "1pg,5,1c9,0,2i|||",
                "French_Southern": "1ph,0,29s,0,ou,0,29q,0,2i|||",
                "Galapagos": "29p,0,2i|||",
                "Gambier": "29o,0,2i|||",
                "Georgia": "qb,0,1h|qb,0,1i||",
                "Gilbert_Islands": "29n,0,2i|||",
                "GMT": "5u,0,bh,0,1|||",
                "Greenland_Eastern": "hc,5,uk,0,1h|hc,5,uk,0,1i||",
                "Greenland_Western": "1g,5,uk,0,1h|1g,5,uk,0,1i||",
                "Guam": "22b,0,1h|||",
                "Gulf": "127,5,1h|||",
                "Guyana": "1c9,0,2i|||",
                "Hawaii_Aleutian": "4g,5,1d2,0,1h|4g,5,1d2,0,1i||",
                "Hong_Kong": "188,0,1h|188,0,1i||",
                "Hovd": "2g,5,1h|2g,5,1i||",
                "India": "29m,0,2i|||",
                "Indian_Ocean": "193,0,229,5,2i|||",
                "Indochina": "29l,0,2i|||",
                "Indonesia_Central": "1e1,5,13j,0,2i|||",
                "Indonesia_Eastern": "hc,5,13j,0,2i|||",
                "Indonesia_Western": "1g,5,13j,0,2i|||",
                "Iran": "1c1,0,1h|1c1,0,1i||",
                "Irkutsk": "16k,5,1h|16k,5,1i||",
                "Israel": "1av,0,1h|1av,0,1i||",
                "Japan": "1aa,0,1h|1aa,0,1i||",
                "Kamchatka": "37,5,16l,5,1h|37,5,16l,5,1i||",
                "Kazakhstan_Eastern": "hc,5,16n,0,2i|||",
                "Kazakhstan_Western": "1g,5,16n,0,2i|||",
                "Korea": "15c,0,1h|15c,0,1i||",
                "Kosrae": "224,0,2i|||",
                "Krasnoyarsk": "l0,5,1h|l0,5,1i||",
                "Kyrgystan": "29k,0,2i|||",
                "Lanka": "9c,5,2i|||",
                "Line_Islands": "ac,5,om,0,2i|||",
                "Lord_Howe": "1u,0,24,5,om,0,1h|1u,0,24,5,om,0,1i||",
                "Macau": "16o,0,1h|16o,0,1i||",
                "Macquarie": "6m,5,om,0,2i|||",
                "Magadan": "2o,5,1h|2o,5,1i||",
                "Malaysia": "29j,0,2i|||",
                "Maldives": "29i,0,2i|||",
                "Marquesas": "29g,0,2i|||",
                "Marshall_Islands": "29f,0,2i|||",
                "Mauritius": "1e9,0,1h|1e9,0,1i||",
                "Mawson": "63,5,2i|||",
                "Mexico_Northwest": "1ei,5,t3,0,1h|1ei,5,t3,0,1i||",
                "Mexico_Pacific": "t3,0,2h,5,1h|t3,0,2h,5,1i||",
                "Mongolia": "p2,5,1h|p2,5,1i||",
                "Moscow": "143,5,1h|143,5,1i||",
                "Myanmar": "220,0,2i|||",
                "Nauru": "29e,0,2i|||",
                "Nepal": "21t,0,2i|||",
                "New_Caledonia": "qc,5,1ea,0,1h|qc,5,1ea,0,1i||",
                "New_Zealand": "qc,5,1a4,0,1h|qc,5,1a4,0,1i||",
                "Newfoundland": "53,5,1h|53,5,1i||",
                "Niue": "21r,0,2i|||",
                "Norfolk": "299,0,2i|||",
                "Noronha": "1s,0,2,0,23,5,1h|1s,0,2,0,23,5,1i||",
                "North_Mariana": "297,0,296,0,2i|||",
                "Novosibirsk": "3b,5,1h|3b,5,1i||",
                "Omsk": "26,5,1h|26,5,1i||",
                "Pakistan": "1ee,0,1h|1ee,0,1i||",
                "Palau": "21p,0,2i|||",
                "Papua_New_Guinea": "1lc,5,qc,5,292,0,2i|||",
                "Paraguay": "1c3,0,1h|1c3,0,1i||",
                "Peru": "1d0,0,1h|1d0,0,1i||",
                "Philippines": "16t,0,1h|16t,0,1i||",
                "Phoenix_Islands": "28u,0,2i|||",
                "Pierre_Miquelon": "8i,0,33,0,ou,0,35,5,1h|8i,0,33,0,ou,0,35,5,1i||",
                "Pitcairn": "28t,0,2i|||",
                "Ponape": "e5,5,2i|||",
                "Pyongyang": "8a,5,2i|||",
                "Qyzylorda": "4d,5,1h|4d,5,1i||",
                "Reunion": "21l,0,2i|||",
                "Rothera": "5q,5,2i|||",
                "Sakhalin": "ph,5,1h|ph,5,1i||",
                "Samara": "29,5,1h|29,5,1i||",
                "Samoa": "1ef,0,1h|1ef,0,1i||",
                "Seychelles": "21h,0,2i|||",
                "Singapore": "21f,0,1h|||",
                "Solomon": "28s,0,2i|||",
                "South_Georgia": "1a2,5,qb,0,2i|||",
                "Suriname": "28r,0,2i|||",
                "Syowa": "65,5,2i|||",
                "Tahiti": "28p,0,2i|||",
                "Taipei": "3d,5,1h|3d,5,1i||",
                "Tajikistan": "21d,0,2i|||",
                "Tokelau": "5d,5,om,0,2i|||",
                "Tonga": "1as,0,1h|1as,0,1i||",
                "Truk": "21c,0,2i|||",
                "Turkmenistan": "171,0,1h|171,0,1i||",
                "Tuvalu": "28o,0,2i|||",
                "Uruguay": "1bi,0,1h|1bi,0,1i||",
                "Uzbekistan": "175,0,1h|175,0,1i||",
                "Vanuatu": "1bp,0,1h|1bp,0,1i||",
                "Venezuela": "28n,0,2i|||",
                "Vladivostok": "28,5,1h|28,5,1i||",
                "Volgograd": "qi,5,1h|qi,5,1i||",
                "Vostok": "5t,5,2i|||",
                "Wake": "5r,5,om,0,2i|||",
                "Wallis": "5f,0,ou,0,219,0,2i|||",
                "Yakutsk": "13v,5,1h|13v,5,1i||",
                "Yekaterinburg": "17a,5,1h|17a,5,1i||"
            }
        },
        "nmg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Africa_Central": "4p3,0,4j|||",
                "Africa_Eastern": "8oc,0,4j|||",
                "Africa_Southern": "4p4,0,4j|||",
                "Africa_Western": "2k2,0,12j|2k2,0,6c||",
                "Australia_Central": "1j3,0,12j|1j3,0,6c||",
                "Australia_CentralWestern": "1o9,5,1j3,0,12j|1o9,5,1j3,0,6c||",
                "Australia_Eastern": "86f,0,12j|86f,0,6c||",
                "Australia_Western": "2km,0,12j|2km,0,6c||",
                "Europe_Central": "2kq,0,12j|2kq,0,6c|h0|h4",
                "Europe_Eastern": "86a,0,12j|86a,0,6c|gu|gv",
                "Europe_Western": "2ks,0,12j|2ks,0,6c|h1|h2",
                "GMT": "8og,0,4qf||ah|"
            }
        },
        "nnh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nus": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "nyn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "om-KE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "om": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "or": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Christmas": "8oh,0,8oi,0,869|||",
                "Galapagos": "8ok,0,869|||",
                "India": "||15a|"
            }
        },
        "os-RU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Europe_Central": "3rf,0,1i7,0,1lh,0,12a|3rf,0,1i7,0,1m8,0,12a||",
                "Europe_Eastern": "3s1,0,1i7,0,1lh,0,12a|3s1,0,1i7,0,1m8,0,12a||",
                "Europe_Western": "3s2,0,1i7,0,1lh,0,12a|3s2,0,1i7,0,1m8,0,12a||",
                "Georgia": "3s5,0,1lh,0,12a|3s5,0,1m8,0,12a||",
                "GMT": "85n,0,85i,0,12a|||",
                "Moscow": "3sg,0,1lh,0,12a|3sg,0,1m8,0,12a||"
            }
        },
        "os": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Europe_Central": "3rf,0,1i7,0,1lh,0,12a|3rf,0,1i7,0,1m8,0,12a||",
                "Europe_Eastern": "3s1,0,1i7,0,1lh,0,12a|3s1,0,1i7,0,1m8,0,12a||",
                "Europe_Western": "3s2,0,1i7,0,1lh,0,12a|3s2,0,1i7,0,1m8,0,12a||",
                "Georgia": "3s5,0,1lh,0,12a|3s5,0,1m8,0,12a||",
                "GMT": "85n,0,85i,0,12a|||",
                "Moscow": "3sg,0,1lh,0,12a|3sg,0,1m8,0,12a||"
            }
        },
        "pa-Arab": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "pa-Guru": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|5p9,0,91,0,2r||",
                "Europe/London": "|5oh,0,ch,0,ci,0,2r||"
            },
            "metazone": {
                "Afghanistan": "85f,0,2r|||",
                "Africa_Central": "1ab,0,1vr,0,2r|||",
                "Africa_Eastern": "10b,0,1vr,0,2r|||",
                "Africa_Southern": "2d9,0,84g,0,91,0,2r|||",
                "Africa_Western": "r7,0,1vr,0,91,0,2r|r7,0,1vr,0,ch,0,ci,0,2r||",
                "Alaska": "3tq,0,91,0,2r|3tq,0,k8,0,2r||",
                "Almaty": "3u4,0,91,0,tq|3u4,0,1oj,5,1ok,0,tq||",
                "Amazon": "3vc,0,91,0,2r|3vc,0,ch,0,ci,0,2r||",
                "America_Central": "15o,0,18q,0,1ab,0,91,0,2r|15o,0,18q,0,1ab,0,k8,0,2r||",
                "America_Eastern": "15o,0,18q,0,10b,0,91,0,2r|15o,0,18q,0,10b,0,k8,0,2r||",
                "America_Mountain": "15o,0,18q,0,40c,0,91,0,2r|15o,0,18q,0,40c,0,k8,0,2r||",
                "America_Pacific": "15o,0,18q,0,1vu,0,91,0,2r|15o,0,18q,0,1vu,0,k8,0,2r||",
                "Apia": "43j,0,91,0,2r|43j,0,k8,0,2r||",
                "Aqtau": "43s,0,91,0,tq|43s,0,1oj,5,1ok,0,tq||",
                "Aqtobe": "443,0,91,0,tq|443,0,1oj,5,1ok,0,tq||",
                "Arabian": "448,0,91,0,2r|448,0,k8,0,2r||",
                "Argentina": "1vv,0,91,0,2r|1vv,0,ch,0,ci,0,2r||",
                "Argentina_Western": "r7,0,1vv,0,91,0,2r|r7,0,1vv,0,ch,0,ci,0,2r||",
                "Armenia": "44d,0,91,0,2r|44d,0,ch,0,ci,0,2r||",
                "Atlantic": "453,0,91,0,2r|453,0,k8,0,2r||",
                "Australia_Central": "18k,0,1ab,0,91,0,2r|18k,0,1ab,0,k8,0,2r||",
                "Australia_CentralWestern": "18k,0,1ab,0,r7,0,91,0,2r|18k,0,1ab,0,r7,0,k8,0,2r||",
                "Australia_Eastern": "18k,0,10b,0,91,0,2r|18k,0,10b,0,k8,0,2r||",
                "Australia_Western": "18k,0,r7,0,91,0,2r|18k,0,r7,0,k8,0,2r||",
                "Azerbaijan": "45f,0,91,0,2r|45f,0,ch,0,ci,0,2r||",
                "Azores": "46e,0,91,0,2r|46e,0,ch,0,ci,0,2r||",
                "Bangladesh": "46f,0,91,0,2r|46f,0,ch,0,ci,0,2r||",
                "Bhutan": "82q,0,2r|||",
                "Bolivia": "82p,0,2r|||",
                "Brasilia": "46m,0,91,0,2r|46m,0,ch,0,ci,0,2r||",
                "Brunei": "82m,0,82k,0,2r|||",
                "Cape_Verde": "46n,0,473,0,91,0,2r|46n,0,473,0,ch,0,ci,0,2r||",
                "Casey": "829,0,tq|||",
                "Chamorro": "823,0,91,0,2r|||",
                "Chatham": "47d,0,91,0,2r|47d,0,k8,0,2r||",
                "Chile": "4ap,0,91,0,2r|4ap,0,ch,0,ci,0,2r||",
                "China": "4ar,0,91,0,2r|4ar,0,k8,0,2r||",
                "Choibalsan": "4b2,0,91,0,2r|4b2,0,ch,0,ci,0,2r||",
                "Christmas": "81s,0,u8,0,2r|||",
                "Cocos": "81q,0,u8,0,2r|||",
                "Colombia": "4d0,0,91,0,2r|4d0,0,ch,0,ci,0,2r||",
                "Cook": "4d1,0,u8,0,91,0,2r|4d1,0,u8,0,81i,0,ch,0,ci,0,2r||",
                "Cuba": "4dh,0,91,0,2r|4dh,0,k8,0,2r||",
                "Davis": "81e,0,2r|||",
                "DumontDUrville": "81d,0,81c,0,2r|||",
                "East_Timor": "10b,0,81a,0,2r|||",
                "Easter": "4dk,0,u8,0,91,0,2r|4dk,0,u8,0,ch,0,ci,0,2r||",
                "Ecuador": "80v,0,2r|||",
                "Europe_Central": "2d2,0,1dd,0,91,0,2r|2d2,0,1dd,0,ch,0,ci,0,2r||",
                "Europe_Eastern": "10b,0,1dd,0,91,0,2r|10b,0,1dd,0,ch,0,ci,0,2r||",
                "Europe_Further_Eastern": "801,5,10b,0,1dd,0,2r|||",
                "Europe_Western": "r7,0,1dd,0,91,0,2r|r7,0,1dd,0,ch,0,ci,0,2r||",
                "Falkland": "4g8,0,2ct,0,91,0,2r|4g8,0,2ct,0,ch,0,ci,0,2r||",
                "Fiji": "4gu,0,91,0,2r|4gu,0,ch,0,ci,0,2r||",
                "French_Guiana": "4he,0,7vq,0,2r|||",
                "French_Southern": "4he,0,2d9,0,204,0,7vj,0,2r|||",
                "Galapagos": "7vh,0,2r|||",
                "Gambier": "7vf,0,2r|||",
                "Georgia": "2cq,0,91,0,2r|2cq,0,ch,0,ci,0,2r||",
                "Gilbert_Islands": "7vc,0,u8,0,2r|||",
                "GMT": "7vb,0,7va,0,2r|||",
                "Greenland_Eastern": "10b,0,206,0,91,0,2r|10b,0,206,0,ch,0,ci,0,2r||",
                "Greenland_Western": "r7,0,206,0,91,0,2r|r7,0,206,0,ch,0,ci,0,2r||",
                "Guam": "7v8,0,tq|||",
                "Gulf": "7v7,0,91,0,2r|||",
                "Guyana": "7v6,0,2r|||",
                "Hawaii_Aleutian": "4ha,5,4h9,0,91,0,2r|4ha,5,4h9,0,k8,0,2r||",
                "Hong_Kong": "4h8,0,4h7,0,91,0,2r|4h8,0,4h7,0,ch,0,ci,0,2r||",
                "Hovd": "4h6,0,91,0,2r|4h6,0,ch,0,ci,0,2r||",
                "India": "7ut,0,91,0,2r||15a|",
                "Indian_Ocean": "7us,0,7ur,0,2r|||",
                "Indochina": "7um,0,2r|||",
                "Indonesia_Central": "2d2,0,7ui,0,2r|||",
                "Indonesia_Eastern": "10b,0,4h5,0,2r|||",
                "Indonesia_Western": "r7,0,4h5,0,2r|||",
                "Iran": "4h4,0,91,0,2r|4h4,0,k8,0,2r||",
                "Irkutsk": "4h3,0,91,0,2r|4h3,0,ch,0,ci,0,2r||",
                "Israel": "4h2,0,91,0,2r|4h2,0,k8,0,2r||",
                "Japan": "4h1,0,91,0,2r|4h1,0,k8,0,2r||",
                "Kazakhstan_Eastern": "10b,0,4h0,0,2r|||",
                "Kazakhstan_Western": "r7,0,4h0,0,2r|||",
                "Korea": "4gv,0,91,0,2r|4gv,0,k8,0,2r||",
                "Kosrae": "7u6,0,2r|||",
                "Krasnoyarsk": "4gt,0,91,0,2r|4gt,0,ch,0,ci,0,2r||",
                "Kyrgystan": "7u0,0,2r|||",
                "Lanka": "7tv,0,tq|||",
                "Line_Islands": "7tu,0,u8,0,2r|||",
                "Lord_Howe": "4gs,0,4gr,0,91,0,2r|4gs,0,4gr,0,k8,0,2r||",
                "Macau": "4gq,0,91,0,tq|4gq,0,1oj,5,1ok,0,tq||",
                "Macquarie": "7tk,0,u8,0,2r|||",
                "Magadan": "4gp,0,91,0,2r|4gp,0,ch,0,ci,0,2r||",
                "Malaysia": "7ti,0,2r|||",
                "Maldives": "7tg,0,2r|||",
                "Marquesas": "7tc,0,2r|||",
                "Marshall_Islands": "7tb,0,u8,0,2r|||",
                "Mauritius": "4go,0,91,0,2r|4go,0,ch,0,ci,0,2r||",
                "Mawson": "7t8,0,2r|||",
                "Mexico_Northwest": "4gn,0,r7,0,4gm,0,91,0,2r|4gn,0,r7,0,4gm,0,k8,0,2r||",
                "Mexico_Pacific": "4gk,0,1vu,0,91,0,2r|4gk,0,1vu,0,k8,0,2r||",
                "Mongolia": "4gj,0,4gi,0,91,0,2r|4gj,0,4gi,0,ch,0,ci,0,2r||",
                "Moscow": "4gh,0,91,0,2r|4gh,0,ch,0,ci,0,2r||",
                "Myanmar": "7sj,0,2r|||",
                "Nauru": "7si,0,2r|||",
                "Nepal": "7sh,0,2r|||",
                "New_Caledonia": "2cm,0,4ge,0,91,0,2r|2cm,0,4ge,0,ch,0,ci,0,2r||",
                "New_Zealand": "4gd,0,91,0,2r|4gd,0,k8,0,2r||",
                "Newfoundland": "4gc,0,91,0,2r|4gc,0,k8,0,2r||",
                "Niue": "7s7,0,2r|||",
                "Norfolk": "7s4,0,u8,0,2r|||",
                "Noronha": "4ga,0,4g9,0,4g7,0,91,0,2r|4ga,0,4g9,0,4g7,0,ch,0,ci,0,2r||",
                "North_Mariana": "15o,0,7ru,0,u8,0,tq|||",
                "Novosibirsk": "4g6,0,91,0,2r|4g6,0,ch,0,ci,0,2r||",
                "Omsk": "4g4,0,91,0,2r|4g4,0,ch,0,ci,0,2r||",
                "Pakistan": "4g2,0,91,0,2r|4g2,0,ch,0,ci,0,2r||",
                "Palau": "7rl,0,2r|||",
                "Papua_New_Guinea": "7rk,0,2cm,0,7rg,0,2r|||",
                "Paraguay": "4g1,0,91,0,2r|4g1,0,ch,0,ci,0,2r||",
                "Peru": "4g0,0,91,0,2r|4g0,0,ch,0,ci,0,2r||",
                "Philippines": "4fv,0,91,0,2r|4fv,0,ch,0,ci,0,2r||",
                "Phoenix_Islands": "7r9,0,u8,0,2r|||",
                "Pierre_Miquelon": "4fm,0,4fl,0,204,0,4fi,0,91,0,2r|4fm,0,4fl,0,204,0,4fi,0,k8,0,2r||",
                "Pitcairn": "7qb,0,2r|||",
                "Ponape": "7q6,0,2r|||",
                "Pyongyang": "7q5,0,2r|||",
                "Qyzylorda": "4fc,0,91,0,tq|4fc,0,1oj,5,1ok,0,tq||",
                "Reunion": "7q2,0,2r|||",
                "Rothera": "7q1,0,2r|||",
                "Sakhalin": "4fa,0,91,0,2r|4fa,0,ch,0,ci,0,2r||",
                "Samoa": "4f9,0,91,0,2r|4f9,0,k8,0,2r||",
                "Seychelles": "7pq,0,2r|||",
                "Singapore": "7pp,0,91,0,2r|||",
                "Solomon": "7po,0,2ct,0,2r|||",
                "South_Georgia": "2d9,0,2cq,0,2r|||",
                "Suriname": "7pm,0,2r|||",
                "Syowa": "7ph,0,2r|||",
                "Tahiti": "7pg,0,2r|||",
                "Taipei": "4f6,0,91,0,2r|4f6,0,k8,0,2r||",
                "Tajikistan": "7pb,0,2r|||",
                "Tokelau": "7p9,0,2r|||",
                "Tonga": "4f3,0,91,0,2r|4f3,0,ch,0,ci,0,2r||",
                "Truk": "7p3,0,2r|||",
                "Turkmenistan": "4f0,0,91,0,2r|4f0,0,ch,0,ci,0,2r||",
                "Tuvalu": "7p0,0,2r|||",
                "Uruguay": "4ev,0,91,0,2r|4ev,0,ch,0,ci,0,2r||",
                "Uzbekistan": "4eu,0,91,0,2r|4eu,0,ch,0,ci,0,2r||",
                "Vanuatu": "4ep,0,91,0,2r|4ep,0,ch,0,ci,0,2r||",
                "Venezuela": "7oi,0,2r|||",
                "Vladivostok": "4ek,0,91,0,2r|4ek,0,ch,0,ci,0,2r||",
                "Volgograd": "4ej,0,91,0,2r|4ej,0,ch,0,ci,0,2r||",
                "Vostok": "7oa,0,2r|||",
                "Wake": "7o9,0,u8,0,2r|||",
                "Wallis": "7nv,0,204,0,7nu,0,2r|||",
                "Yakutsk": "4eb,0,91,0,2r|4eb,0,ch,0,ci,0,2r||",
                "Yekaterinburg": "4ea,0,91,0,2r|4ea,0,ch,0,ci,0,2r||"
            }
        },
        "pa": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|5p9,0,91,0,2r||",
                "Europe/London": "|5oh,0,ch,0,ci,0,2r||"
            },
            "metazone": {
                "Afghanistan": "85f,0,2r|||",
                "Africa_Central": "1ab,0,1vr,0,2r|||",
                "Africa_Eastern": "10b,0,1vr,0,2r|||",
                "Africa_Southern": "2d9,0,84g,0,91,0,2r|||",
                "Africa_Western": "r7,0,1vr,0,91,0,2r|r7,0,1vr,0,ch,0,ci,0,2r||",
                "Alaska": "3tq,0,91,0,2r|3tq,0,k8,0,2r||",
                "Almaty": "3u4,0,91,0,tq|3u4,0,1oj,5,1ok,0,tq||",
                "Amazon": "3vc,0,91,0,2r|3vc,0,ch,0,ci,0,2r||",
                "America_Central": "15o,0,18q,0,1ab,0,91,0,2r|15o,0,18q,0,1ab,0,k8,0,2r||",
                "America_Eastern": "15o,0,18q,0,10b,0,91,0,2r|15o,0,18q,0,10b,0,k8,0,2r||",
                "America_Mountain": "15o,0,18q,0,40c,0,91,0,2r|15o,0,18q,0,40c,0,k8,0,2r||",
                "America_Pacific": "15o,0,18q,0,1vu,0,91,0,2r|15o,0,18q,0,1vu,0,k8,0,2r||",
                "Apia": "43j,0,91,0,2r|43j,0,k8,0,2r||",
                "Aqtau": "43s,0,91,0,tq|43s,0,1oj,5,1ok,0,tq||",
                "Aqtobe": "443,0,91,0,tq|443,0,1oj,5,1ok,0,tq||",
                "Arabian": "448,0,91,0,2r|448,0,k8,0,2r||",
                "Argentina": "1vv,0,91,0,2r|1vv,0,ch,0,ci,0,2r||",
                "Argentina_Western": "r7,0,1vv,0,91,0,2r|r7,0,1vv,0,ch,0,ci,0,2r||",
                "Armenia": "44d,0,91,0,2r|44d,0,ch,0,ci,0,2r||",
                "Atlantic": "453,0,91,0,2r|453,0,k8,0,2r||",
                "Australia_Central": "18k,0,1ab,0,91,0,2r|18k,0,1ab,0,k8,0,2r||",
                "Australia_CentralWestern": "18k,0,1ab,0,r7,0,91,0,2r|18k,0,1ab,0,r7,0,k8,0,2r||",
                "Australia_Eastern": "18k,0,10b,0,91,0,2r|18k,0,10b,0,k8,0,2r||",
                "Australia_Western": "18k,0,r7,0,91,0,2r|18k,0,r7,0,k8,0,2r||",
                "Azerbaijan": "45f,0,91,0,2r|45f,0,ch,0,ci,0,2r||",
                "Azores": "46e,0,91,0,2r|46e,0,ch,0,ci,0,2r||",
                "Bangladesh": "46f,0,91,0,2r|46f,0,ch,0,ci,0,2r||",
                "Bhutan": "82q,0,2r|||",
                "Bolivia": "82p,0,2r|||",
                "Brasilia": "46m,0,91,0,2r|46m,0,ch,0,ci,0,2r||",
                "Brunei": "82m,0,82k,0,2r|||",
                "Cape_Verde": "46n,0,473,0,91,0,2r|46n,0,473,0,ch,0,ci,0,2r||",
                "Casey": "829,0,tq|||",
                "Chamorro": "823,0,91,0,2r|||",
                "Chatham": "47d,0,91,0,2r|47d,0,k8,0,2r||",
                "Chile": "4ap,0,91,0,2r|4ap,0,ch,0,ci,0,2r||",
                "China": "4ar,0,91,0,2r|4ar,0,k8,0,2r||",
                "Choibalsan": "4b2,0,91,0,2r|4b2,0,ch,0,ci,0,2r||",
                "Christmas": "81s,0,u8,0,2r|||",
                "Cocos": "81q,0,u8,0,2r|||",
                "Colombia": "4d0,0,91,0,2r|4d0,0,ch,0,ci,0,2r||",
                "Cook": "4d1,0,u8,0,91,0,2r|4d1,0,u8,0,81i,0,ch,0,ci,0,2r||",
                "Cuba": "4dh,0,91,0,2r|4dh,0,k8,0,2r||",
                "Davis": "81e,0,2r|||",
                "DumontDUrville": "81d,0,81c,0,2r|||",
                "East_Timor": "10b,0,81a,0,2r|||",
                "Easter": "4dk,0,u8,0,91,0,2r|4dk,0,u8,0,ch,0,ci,0,2r||",
                "Ecuador": "80v,0,2r|||",
                "Europe_Central": "2d2,0,1dd,0,91,0,2r|2d2,0,1dd,0,ch,0,ci,0,2r||",
                "Europe_Eastern": "10b,0,1dd,0,91,0,2r|10b,0,1dd,0,ch,0,ci,0,2r||",
                "Europe_Further_Eastern": "801,5,10b,0,1dd,0,2r|||",
                "Europe_Western": "r7,0,1dd,0,91,0,2r|r7,0,1dd,0,ch,0,ci,0,2r||",
                "Falkland": "4g8,0,2ct,0,91,0,2r|4g8,0,2ct,0,ch,0,ci,0,2r||",
                "Fiji": "4gu,0,91,0,2r|4gu,0,ch,0,ci,0,2r||",
                "French_Guiana": "4he,0,7vq,0,2r|||",
                "French_Southern": "4he,0,2d9,0,204,0,7vj,0,2r|||",
                "Galapagos": "7vh,0,2r|||",
                "Gambier": "7vf,0,2r|||",
                "Georgia": "2cq,0,91,0,2r|2cq,0,ch,0,ci,0,2r||",
                "Gilbert_Islands": "7vc,0,u8,0,2r|||",
                "GMT": "7vb,0,7va,0,2r|||",
                "Greenland_Eastern": "10b,0,206,0,91,0,2r|10b,0,206,0,ch,0,ci,0,2r||",
                "Greenland_Western": "r7,0,206,0,91,0,2r|r7,0,206,0,ch,0,ci,0,2r||",
                "Guam": "7v8,0,tq|||",
                "Gulf": "7v7,0,91,0,2r|||",
                "Guyana": "7v6,0,2r|||",
                "Hawaii_Aleutian": "4ha,5,4h9,0,91,0,2r|4ha,5,4h9,0,k8,0,2r||",
                "Hong_Kong": "4h8,0,4h7,0,91,0,2r|4h8,0,4h7,0,ch,0,ci,0,2r||",
                "Hovd": "4h6,0,91,0,2r|4h6,0,ch,0,ci,0,2r||",
                "India": "7ut,0,91,0,2r||15a|",
                "Indian_Ocean": "7us,0,7ur,0,2r|||",
                "Indochina": "7um,0,2r|||",
                "Indonesia_Central": "2d2,0,7ui,0,2r|||",
                "Indonesia_Eastern": "10b,0,4h5,0,2r|||",
                "Indonesia_Western": "r7,0,4h5,0,2r|||",
                "Iran": "4h4,0,91,0,2r|4h4,0,k8,0,2r||",
                "Irkutsk": "4h3,0,91,0,2r|4h3,0,ch,0,ci,0,2r||",
                "Israel": "4h2,0,91,0,2r|4h2,0,k8,0,2r||",
                "Japan": "4h1,0,91,0,2r|4h1,0,k8,0,2r||",
                "Kazakhstan_Eastern": "10b,0,4h0,0,2r|||",
                "Kazakhstan_Western": "r7,0,4h0,0,2r|||",
                "Korea": "4gv,0,91,0,2r|4gv,0,k8,0,2r||",
                "Kosrae": "7u6,0,2r|||",
                "Krasnoyarsk": "4gt,0,91,0,2r|4gt,0,ch,0,ci,0,2r||",
                "Kyrgystan": "7u0,0,2r|||",
                "Lanka": "7tv,0,tq|||",
                "Line_Islands": "7tu,0,u8,0,2r|||",
                "Lord_Howe": "4gs,0,4gr,0,91,0,2r|4gs,0,4gr,0,k8,0,2r||",
                "Macau": "4gq,0,91,0,tq|4gq,0,1oj,5,1ok,0,tq||",
                "Macquarie": "7tk,0,u8,0,2r|||",
                "Magadan": "4gp,0,91,0,2r|4gp,0,ch,0,ci,0,2r||",
                "Malaysia": "7ti,0,2r|||",
                "Maldives": "7tg,0,2r|||",
                "Marquesas": "7tc,0,2r|||",
                "Marshall_Islands": "7tb,0,u8,0,2r|||",
                "Mauritius": "4go,0,91,0,2r|4go,0,ch,0,ci,0,2r||",
                "Mawson": "7t8,0,2r|||",
                "Mexico_Northwest": "4gn,0,r7,0,4gm,0,91,0,2r|4gn,0,r7,0,4gm,0,k8,0,2r||",
                "Mexico_Pacific": "4gk,0,1vu,0,91,0,2r|4gk,0,1vu,0,k8,0,2r||",
                "Mongolia": "4gj,0,4gi,0,91,0,2r|4gj,0,4gi,0,ch,0,ci,0,2r||",
                "Moscow": "4gh,0,91,0,2r|4gh,0,ch,0,ci,0,2r||",
                "Myanmar": "7sj,0,2r|||",
                "Nauru": "7si,0,2r|||",
                "Nepal": "7sh,0,2r|||",
                "New_Caledonia": "2cm,0,4ge,0,91,0,2r|2cm,0,4ge,0,ch,0,ci,0,2r||",
                "New_Zealand": "4gd,0,91,0,2r|4gd,0,k8,0,2r||",
                "Newfoundland": "4gc,0,91,0,2r|4gc,0,k8,0,2r||",
                "Niue": "7s7,0,2r|||",
                "Norfolk": "7s4,0,u8,0,2r|||",
                "Noronha": "4ga,0,4g9,0,4g7,0,91,0,2r|4ga,0,4g9,0,4g7,0,ch,0,ci,0,2r||",
                "North_Mariana": "15o,0,7ru,0,u8,0,tq|||",
                "Novosibirsk": "4g6,0,91,0,2r|4g6,0,ch,0,ci,0,2r||",
                "Omsk": "4g4,0,91,0,2r|4g4,0,ch,0,ci,0,2r||",
                "Pakistan": "4g2,0,91,0,2r|4g2,0,ch,0,ci,0,2r||",
                "Palau": "7rl,0,2r|||",
                "Papua_New_Guinea": "7rk,0,2cm,0,7rg,0,2r|||",
                "Paraguay": "4g1,0,91,0,2r|4g1,0,ch,0,ci,0,2r||",
                "Peru": "4g0,0,91,0,2r|4g0,0,ch,0,ci,0,2r||",
                "Philippines": "4fv,0,91,0,2r|4fv,0,ch,0,ci,0,2r||",
                "Phoenix_Islands": "7r9,0,u8,0,2r|||",
                "Pierre_Miquelon": "4fm,0,4fl,0,204,0,4fi,0,91,0,2r|4fm,0,4fl,0,204,0,4fi,0,k8,0,2r||",
                "Pitcairn": "7qb,0,2r|||",
                "Ponape": "7q6,0,2r|||",
                "Pyongyang": "7q5,0,2r|||",
                "Qyzylorda": "4fc,0,91,0,tq|4fc,0,1oj,5,1ok,0,tq||",
                "Reunion": "7q2,0,2r|||",
                "Rothera": "7q1,0,2r|||",
                "Sakhalin": "4fa,0,91,0,2r|4fa,0,ch,0,ci,0,2r||",
                "Samoa": "4f9,0,91,0,2r|4f9,0,k8,0,2r||",
                "Seychelles": "7pq,0,2r|||",
                "Singapore": "7pp,0,91,0,2r|||",
                "Solomon": "7po,0,2ct,0,2r|||",
                "South_Georgia": "2d9,0,2cq,0,2r|||",
                "Suriname": "7pm,0,2r|||",
                "Syowa": "7ph,0,2r|||",
                "Tahiti": "7pg,0,2r|||",
                "Taipei": "4f6,0,91,0,2r|4f6,0,k8,0,2r||",
                "Tajikistan": "7pb,0,2r|||",
                "Tokelau": "7p9,0,2r|||",
                "Tonga": "4f3,0,91,0,2r|4f3,0,ch,0,ci,0,2r||",
                "Truk": "7p3,0,2r|||",
                "Turkmenistan": "4f0,0,91,0,2r|4f0,0,ch,0,ci,0,2r||",
                "Tuvalu": "7p0,0,2r|||",
                "Uruguay": "4ev,0,91,0,2r|4ev,0,ch,0,ci,0,2r||",
                "Uzbekistan": "4eu,0,91,0,2r|4eu,0,ch,0,ci,0,2r||",
                "Vanuatu": "4ep,0,91,0,2r|4ep,0,ch,0,ci,0,2r||",
                "Venezuela": "7oi,0,2r|||",
                "Vladivostok": "4ek,0,91,0,2r|4ek,0,ch,0,ci,0,2r||",
                "Volgograd": "4ej,0,91,0,2r|4ej,0,ch,0,ci,0,2r||",
                "Vostok": "7oa,0,2r|||",
                "Wake": "7o9,0,u8,0,2r|||",
                "Wallis": "7nv,0,204,0,7nu,0,2r|||",
                "Yakutsk": "4eb,0,91,0,2r|4eb,0,ch,0,ci,0,2r||",
                "Yekaterinburg": "4ea,0,91,0,2r|4ea,0,ch,0,ci,0,2r||"
            }
        },
        "pl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|66f,0,bb,0,i0||",
                "Europe/London": "|ccm,0,ccn,0,154||"
            },
            "metazone": {
                "Afghanistan": "2f0|||",
                "Africa_Central": "og,0,8ui|||",
                "Africa_Eastern": "og,0,8uj|||",
                "Africa_Southern": "og,0,8uk|||",
                "Africa_Western": "og,0,7nl,0,15i|og,0,7nl,0,154||",
                "Alaska": "3p,0,bb,0,i5|3p,0,bb,0,i0||",
                "Amazon": "og,0,7nc,0,15i|og,0,7nc,0,154||",
                "America_Central": "og,0,7nb,0,15i|og,0,7nb,0,154||",
                "America_Eastern": "og,0,7n8,0,15i|og,0,7n8,0,154||",
                "America_Mountain": "og,0,44j,0,15i|og,0,44j,0,154||",
                "America_Pacific": "og,0,4e2,0,15i|og,0,4e2,0,154||",
                "Anadyr": "og,0,15i,0,3l|og,0,3l,0,154||",
                "Apia": "3e,0,bb,0,i5|3e,0,bb,0,i0||",
                "Arabian": "7n6,0,4e1,0,bb,0,i5|7n6,0,4e1,0,bb,0,i0||",
                "Argentina": "4e0,0,bb,0,i5|4e0,0,bb,0,i0||",
                "Argentina_Western": "4e0,0,2tg,0,bb,0,i5|4e0,0,2tg,0,bb,0,i0||",
                "Armenia": "4l,0,bb,0,i5|4l,0,bb,0,i0||",
                "Atlantic": "og,0,7mu,0,15i|og,0,7mu,0,154||",
                "Australia_Central": "og,0,7mo,0,15i|og,0,7mo,0,154||",
                "Australia_CentralWestern": "og,0,7mk,5,4dt,0,15i|og,0,7mk,5,4dt,0,154||",
                "Australia_Eastern": "og,0,7md,0,15i|og,0,7md,0,154||",
                "Australia_Western": "og,0,4dt,0,15i|og,0,4dt,0,154||",
                "Azerbaijan": "7mb,0,bb,0,i5|7mb,0,bb,0,i0||",
                "Azores": "7ma,0,bb,0,i5|7ma,0,bb,0,i0||",
                "Bangladesh": "7m9,0,bb,0,i5|7m9,0,bb,0,i0||",
                "Bhutan": "as|||",
                "Bolivia": "8vb|||",
                "Brasilia": "in,0,bb,0,i5|in,0,bb,0,i0||",
                "Brunei": "9o,0,a4|||",
                "Cape_Verde": "23d,0,7m6,0,7m3,0,bb,0,i5|23d,0,7m6,0,7m3,0,bb,0,i0||",
                "Chamorro": "8vf|||",
                "Chatham": "27,0,bb,0,i5|27,0,bb,0,i0||",
                "Chile": "49,0,bb,0,i5|49,0,bb,0,i0||",
                "China": "7m2,0,bb,0,i5|7m2,0,bb,0,i0||",
                "Choibalsan": "7lv,0,bb,0,i5|7lv,0,bb,0,i0||",
                "Christmas": "4hu,0,8vj,0,8vk|||",
                "Cocos": "23d,0,8vl|||",
                "Colombia": "7ln,0,bb,0,i5|7ln,0,bb,0,i0||",
                "Cook": "23d,0,7lm,0,bb,0,i5|23d,0,7lm,0,bb,0,i0||",
                "Cuba": "tb,0,bb,0,i5|tb,0,bb,0,i0||",
                "Davis": "5s|||",
                "DumontDUrville": "5b,5,5j|||",
                "East_Timor": "7n,0,7ll|||",
                "Easter": "4hu,0,7lk,0,bb,0,i5|4hu,0,7lk,0,bb,0,i0||",
                "Ecuador": "7lf|||",
                "Europe_Central": "og,0,7le,0,15i|og,0,7le,0,154|h0|h4",
                "Europe_Eastern": "og,0,4hv,0,15i|og,0,4hv,0,154|gu|gv",
                "Europe_Further_Eastern": "og,0,4hv,0,8vt|||",
                "Europe_Western": "og,0,7la,0,15i|og,0,7la,0,154|h1|h2",
                "Falkland": "7l7,0,bb,0,i5|7l7,0,bb,0,i0||",
                "Fiji": "7l6,0,bb,0,i5|7l6,0,bb,0,i0||",
                "French_Guiana": "2fd,0,2n4|||",
                "French_Southern": "902,0,903,0,904,0,i3,0,905|||",
                "Galapagos": "ak|||",
                "Gambier": "6v|||",
                "Georgia": "7l4,0,bb,0,i5|7l4,0,bb,0,i0||",
                "Gilbert_Islands": "23d,0,4i0|||",
                "GMT": "og,0,907|||",
                "Greenland_Eastern": "4dl,0,4i1,0,bb,0,i5|4dl,0,4i1,0,bb,0,i0||",
                "Greenland_Western": "4dl,0,2tg,0,bb,0,i5|4dl,0,2tg,0,bb,0,i0||",
                "Gulf": "90a,0,90b|||",
                "Guyana": "2fd|||",
                "Hawaii_Aleutian": "7l0,5,7kv,0,bb,0,i5|7l0,5,7kv,0,bb,0,i0||",
                "Hong_Kong": "qh,0,bb,0,i5|qh,0,bb,0,i0||",
                "Hovd": "7kr,0,bb,0,i5|7kr,0,bb,0,i0||",
                "India": "og,0,90f|||",
                "Indian_Ocean": "bv,0,90g|||",
                "Indochina": "og,0,90h|||",
                "Indonesia_Central": "4i3,0,90j|||",
                "Indonesia_Eastern": "4i3,0,4i1|||",
                "Indonesia_Western": "4i3,0,2tg|||",
                "Iran": "5g,0,bb,0,i5|5g,0,bb,0,i0||",
                "Irkutsk": "1oi,0,bb,0,i5|1oi,0,bb,0,i0||",
                "Israel": "7km,0,bb,0,i5|7km,0,bb,0,i0||",
                "Japan": "7kk,0,bb,0,i5|7kk,0,bb,0,i0||",
                "Kamchatka": "og,0,15i,0,7kj,0,7ki|og,0,7kj,0,7ki,0,154||",
                "Kazakhstan_Eastern": "7ke,0,7ll|||",
                "Kazakhstan_Western": "7ke,0,4i5|||",
                "Korea": "qf,0,bb,0,i5|qf,0,bb,0,i0||",
                "Kosrae": "6f|||",
                "Krasnoyarsk": "l0,0,bb,0,i5|l0,0,bb,0,i0||",
                "Kyrgystan": "1qu|||",
                "Line_Islands": "90r,0,90s|||",
                "Lord_Howe": "1u,0,24,0,bb,0,i5|1u,0,24,0,bb,0,i0||",
                "Macquarie": "6m|||",
                "Magadan": "2o,0,bb,0,i5|2o,0,bb,0,i0||",
                "Malaysia": "90t|||",
                "Maldives": "90u|||",
                "Marquesas": "90v|||",
                "Marshall_Islands": "23d,0,910|||",
                "Mauritius": "4u,0,bb,0,i5|4u,0,bb,0,i0||",
                "Mawson": "63|||",
                "Mexico_Northwest": "4dd,0,7k6,5,4i5,0,bb,0,i5|4dd,0,7k6,5,4i5,0,bb,0,i0||",
                "Mexico_Pacific": "4dd,0,bb,0,4e2,0,i5|4dd,0,bb,0,4e2,0,i0||",
                "Mongolia": "7k4,0,2m,0,bb,0,i5|7k4,0,2m,0,bb,0,i0||",
                "Moscow": "og,0,7k3,0,15i|og,0,7k3,0,154||",
                "Myanmar": "915|||",
                "Nauru": "5v|||",
                "Nepal": "8u|||",
                "New_Caledonia": "269,0,215,0,bb,0,i5|269,0,215,0,bb,0,i0||",
                "New_Zealand": "269,0,395,0,bb,0,i5|269,0,395,0,bb,0,i0||",
                "Newfoundland": "269,0,7k0,0,bb,0,i5|269,0,7k0,0,bb,0,i0||",
                "Niue": "8g|||",
                "Norfolk": "6l|||",
                "Noronha": "1s,0,2,0,23,0,bb,0,i5|1s,0,2,0,23,0,bb,0,i0||",
                "Novosibirsk": "7j5,0,bb,0,i5|7j5,0,bb,0,i0||",
                "Omsk": "26,0,bb,0,i5|26,0,bb,0,i0||",
                "Pakistan": "3i,0,bb,0,i5|3i,0,bb,0,i0||",
                "Palau": "9b|||",
                "Papua_New_Guinea": "9a,5,269,0,919|||",
                "Paraguay": "7j4,0,bb,0,i5|7j4,0,bb,0,i0||",
                "Peru": "4n,0,bb,0,i5|4n,0,bb,0,i0||",
                "Philippines": "7j3,0,bb,0,i5|7j3,0,bb,0,i0||",
                "Phoenix_Islands": "1a7|||",
                "Pierre_Miquelon": "8i,5,33,0,i3,0,35,0,bb,0,i5|8i,5,33,0,i3,0,35,0,bb,0,i0||",
                "Pitcairn": "7e|||",
                "Ponape": "e5|||",
                "Pyongyang": "91c|||",
                "Reunion": "b4|||",
                "Rothera": "5q|||",
                "Sakhalin": "ph,0,bb,0,i5|ph,0,bb,0,i0||",
                "Samara": "og,0,15i,0,29|og,0,29,0,154||",
                "Samoa": "2c,0,bb,0,i5|2c,0,bb,0,i0||",
                "Seychelles": "91d|||",
                "Singapore": "m8|||",
                "Solomon": "23d,0,91e|||",
                "South_Georgia": "2u,0,91f|||",
                "Suriname": "mg|||",
                "Syowa": "65|||",
                "Tahiti": "8n|||",
                "Taipei": "1pc,0,bb,0,i5|1pc,0,bb,0,i0||",
                "Tajikistan": "91h|||",
                "Tokelau": "5d|||",
                "Tonga": "2j,0,bb,0,i5|2j,0,bb,0,i0||",
                "Truk": "6p|||",
                "Turkmenistan": "4t,0,bb,0,i5|4t,0,bb,0,i0||",
                "Tuvalu": "6h|||",
                "Uruguay": "7iv,0,bb,0,i5|7iv,0,bb,0,i0||",
                "Uzbekistan": "60,0,bb,0,i5|60,0,bb,0,i0||",
                "Vanuatu": "2f,0,bb,0,i5|2f,0,bb,0,i0||",
                "Venezuela": "91j|||",
                "Vladivostok": "7iu,0,bb,0,i5|7iu,0,bb,0,i0||",
                "Volgograd": "7it,0,bb,0,i5|7it,0,bb,0,i0||",
                "Vostok": "1kt|||",
                "Wake": "5r|||",
                "Wallis": "5f,0,i3,0,5k|||",
                "Yakutsk": "7is,0,bb,0,i5|7is,0,bb,0,i0||",
                "Yekaterinburg": "7iq,0,bb,0,i5|7iq,0,bb,0,i0||"
            }
        },
        "prg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ps": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Almaty": "|||",
                "Europe_Western": "4i6,0,7ik,0,19h,0,91q,0,4i7|4i6,0,7ik,0,91s,0,4i6,0,91t,0,4i7||",
                "GMT": "91u,0,4i7|||"
            }
        },
        "pt-AO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-CH": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9|4b7|4b5",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n|h0|h4",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h|gu|gv",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i|h1|h2",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-CV": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-GQ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9|4b7|4b5",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n|h0|h4",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h|gu|gv",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i|h1|h2",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-GW": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-LU": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9|4b7|4b5",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n|h0|h4",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h|gu|gv",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i|h1|h2",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-MO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-MZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-PT": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9|4b7|4b5",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n|h0|h4",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h|gu|gv",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i|h1|h2",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-ST": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt-TL": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|9,0,2,0,s,0,14,0,pk||",
                "Europe/London": "|9,0,2,0,s,0,1kg||"
            },
            "metazone": {
                "Acre": "9,0,q,0,16,0,3q|9,0,2,0,s,0,16,0,3q||",
                "Afghanistan": "9,0,16,0,1hv|||",
                "Africa_Central": "9,0,14,0,9d,0,n|||",
                "Africa_Eastern": "9,0,14,0,9d,0,8h|||",
                "Africa_Southern": "9,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "9,0,q,0,14,0,9d,0,9i|9,0,2,0,s,0,14,0,9d,0,9i||",
                "Alaska": "9,0,q,0,16,0,tj|9,0,2,0,s,0,16,0,tj||",
                "Almaty": "9,0,q,0,2,0,56|9,0,2,0,s,0,2,0,56||",
                "Amazon": "9,0,q,0,16,0,bg|9,0,2,0,s,0,16,0,bg||",
                "America_Central": "9,0,q,0,n|9,0,2,0,s,0,n||",
                "America_Eastern": "9,0,q,0,8h|9,0,2,0,s,0,8h||",
                "America_Mountain": "9,0,q,0,14,0,109|9,0,2,0,s,0,14,0,109||",
                "America_Pacific": "9,0,q,0,16,0,9u|9,0,2,0,s,0,16,0,9u||",
                "Anadyr": "9,0,q,0,2,0,3l|9,0,2,0,s,0,2,0,3l||",
                "Apia": "9,0,q,0,2,0,3e|9,0,2,0,s,0,2,0,3e||",
                "Aqtau": "9,0,q,0,2,0,50|9,0,2,0,s,0,2,0,50||",
                "Aqtobe": "9,0,q,0,2,0,5o|9,0,2,0,s,0,2,0,5o||",
                "Arabian": "9,0,q,0,14,0,10t|9,0,2,0,s,0,14,0,10t||",
                "Argentina": "9,0,q,0,14,0,1e|9,0,2,0,s,0,14,0,1e||",
                "Argentina_Western": "9,0,q,0,14,0,1e,0,9i|9,0,2,0,s,0,14,0,1e,0,9i||",
                "Armenia": "9,0,q,0,14,0,12d|9,0,2,0,s,0,14,0,12d||",
                "Atlantic": "9,0,q,0,16,0,10s|9,0,2,0,s,0,16,0,10s||",
                "Australia_Central": "9,0,q,0,14,0,cn,0,n|9,0,2,0,s,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "9,0,q,0,14,0,cn,0,n,0,9i|9,0,2,0,s,0,14,0,cn,0,n,0,9i||",
                "Australia_Eastern": "9,0,q,0,14,0,cn,0,8h|9,0,2,0,s,0,14,0,cn,0,8h||",
                "Australia_Western": "9,0,q,0,14,0,cn,0,9i|9,0,2,0,s,0,14,0,cn,0,9i||",
                "Azerbaijan": "9,0,q,0,16,0,133|9,0,2,0,s,0,16,0,133||",
                "Azores": "9,0,q,0,10q,0,b9|9,0,2,0,s,0,10q,0,b9||",
                "Bangladesh": "9,0,q,0,16,0,3n|9,0,2,0,s,0,16,0,3n||",
                "Bhutan": "9,0,16,0,1hu|||",
                "Bolivia": "9,0,14,0,16g|||",
                "Brasilia": "9,0,q,0,2,0,in|9,0,2,0,s,0,2,0,in||",
                "Brunei": "9,0,16,0,9o,0,a4|||",
                "Cape_Verde": "9,0,q,0,2,0,ck,0,3j|9,0,2,0,s,0,2,0,ck,0,3j||",
                "Chamorro": "9,0,q,0,16,0,6j|||",
                "Chatham": "9,0,q,0,16,0,27|9,0,2,0,s,0,16,0,27||",
                "Chile": "9,0,q,0,16,0,49|9,0,2,0,s,0,16,0,49||",
                "China": "9,0,q,0,14,0,4h|9,0,2,0,s,0,14,0,4h||",
                "Choibalsan": "9,0,q,0,2,0,2q|9,0,2,0,s,0,2,0,2q||",
                "Christmas": "9,0,14,0,h7,0,16,0,1jn|||",
                "Cocos": "9,0,6t,0,ae,0,7v|||",
                "Colombia": "9,0,q,0,14,0,10n|9,0,2,0,s,0,14,0,10n||",
                "Cook": "9,0,q,0,6t,0,ae,0,2v|9,0,2,0,s,0,6t,0,ae,0,2v||",
                "Cuba": "9,0,q,0,2,0,34|9,0,2,0,s,0,2,0,34||",
                "Davis": "9,0,2,0,5s|||",
                "DumontDUrville": "9,0,2,0,5b,5,5j|||",
                "East_Timor": "9,0,2,0,7n,0,vk|||",
                "Easter": "9,0,q,0,14,0,h7,0,14,0,120|9,0,2,0,s,0,14,0,h7,0,14,0,120||",
                "Ecuador": "9,0,16,0,1hs|||",
                "Europe_Central": "9,0,q,0,14,0,4p,0,n|9,0,2,0,s,0,14,0,4p,0,n||",
                "Europe_Eastern": "9,0,q,0,14,0,4p,0,8h|9,0,2,0,s,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "9,0,16,0,1ej,0,vk,0,14,0,4p|||",
                "Europe_Western": "9,0,q,0,14,0,4p,0,9i|9,0,2,0,s,0,14,0,4p,0,9i||",
                "Falkland": "9,0,q,0,6t,0,ae,0,4v|9,0,2,0,s,0,6t,0,ae,0,4v||",
                "Fiji": "9,0,q,0,2,0,4q|9,0,2,0,s,0,2,0,4q||",
                "French_Guiana": "9,0,14,0,am,0,nf|||",
                "French_Southern": "9,0,6t,0,1h4,0,1h2,0,15,0,1ku,0,pf|||",
                "Galapagos": "9,0,6t,0,cp|||",
                "Gambier": "9,0,2,0,6v|||",
                "Georgia": "9,0,q,0,14,0,pv|9,0,2,0,s,0,14,0,pv||",
                "Gilbert_Islands": "9,0,6t,0,ae,0,6q|||",
                "GMT": "9,0,2,0,5u|||",
                "Greenland_Eastern": "9,0,q,0,14,0,o9,0,8h|9,0,2,0,s,0,14,0,o9,0,8h||",
                "Greenland_Western": "9,0,q,0,14,0,o9,0,9i|9,0,2,0,s,0,14,0,o9,0,9i||",
                "Guam": "9,0,q,0,2,0,83|||",
                "Gulf": "9,0,q,0,16,0,oh|||",
                "Guyana": "9,0,14,0,am|||",
                "Hawaii_Aleutian": "9,0,q,0,16,0,12f,0,15,0,11d|9,0,2,0,s,0,16,0,12f,0,15,0,11d||",
                "Hong_Kong": "9,0,q,0,2,0,2l,0,2p|9,0,2,0,s,0,2,0,2l,0,2p||",
                "Hovd": "9,0,q,0,2,0,2g|9,0,2,0,s,0,2,0,2g||",
                "India": "9,0,q,0,14,0,1ho|||",
                "Indian_Ocean": "9,0,16,0,1hn,0,pa|||",
                "Indochina": "9,0,14,0,9p|||",
                "Indonesia_Central": "9,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "9,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "9,0,14,0,q2,0,9i|||",
                "Iran": "9,0,q,0,16,0,12r|9,0,2,0,s,0,16,0,12r||",
                "Irkutsk": "9,0,q,0,2,0,3h|9,0,2,0,s,0,2,0,3h||",
                "Israel": "9,0,q,0,2,0,4e|9,0,2,0,s,0,2,0,4e||",
                "Japan": "9,0,q,0,16,0,11g|9,0,2,0,s,0,16,0,11g||",
                "Kamchatka": "9,0,q,0,2,0,37,5,3k|9,0,2,0,s,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "9,0,16,0,12n,0,8h|||",
                "Kazakhstan_Western": "9,0,16,0,12n,0,9i|||",
                "Korea": "9,0,q,0,14,0,10p|9,0,2,0,s,0,14,0,10p||",
                "Kosrae": "9,0,2,0,6f|||",
                "Krasnoyarsk": "9,0,q,0,2,0,41|9,0,2,0,s,0,2,0,41||",
                "Kyrgystan": "9,0,16,0,1hi|||",
                "Lanka": "9,0,16,0,me,0,9c|||",
                "Line_Islands": "9,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "9,0,q,0,2,0,1u,0,24|9,0,2,0,s,0,2,0,1u,0,24||",
                "Macau": "9,0,q,0,2,0,57|9,0,2,0,s,0,2,0,57||",
                "Macquarie": "9,0,14,0,h7,0,6m|||",
                "Magadan": "9,0,q,0,2,0,2o|9,0,2,0,s,0,2,0,2o||",
                "Malaysia": "9,0,14,0,1hh|||",
                "Maldives": "9,0,6t,0,ov|||",
                "Marquesas": "9,0,6t,0,ae,0,9e|||",
                "Marshall_Islands": "9,0,6t,0,ae,0,7o|||",
                "Mauritius": "9,0,q,0,14,0,12q|9,0,2,0,s,0,14,0,12q||",
                "Mawson": "9,0,2,0,63|||",
                "Mexico_Northwest": "9,0,q,0,16,0,107,0,16,0,au|9,0,2,0,s,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "9,0,q,0,16,0,9u,0,12t|9,0,2,0,s,0,16,0,9u,0,12t||",
                "Mongolia": "9,0,q,0,2,0,4f,0,2m|9,0,2,0,s,0,2,0,4f,0,2m||",
                "Moscow": "9,0,q,0,2,0,134|9,0,2,0,s,0,2,0,134||",
                "Myanmar": "9,0,2,0,1eb|||",
                "Nauru": "9,0,2,0,5v|||",
                "Nepal": "9,0,16,0,8u|||",
                "New_Caledonia": "9,0,q,0,14,0,ba,0,12e|9,0,2,0,s,0,14,0,ba,0,12e||",
                "New_Zealand": "9,0,q,0,14,0,ba,0,117|9,0,2,0,s,0,14,0,ba,0,117||",
                "Newfoundland": "9,0,q,0,14,0,119,0,ba|9,0,2,0,s,0,14,0,119,0,ba||",
                "Niue": "9,0,2,0,8g|||",
                "Norfolk": "9,0,14,0,h7,0,6l|||",
                "Noronha": "9,0,q,0,2,0,1s,0,2,0,23|9,0,2,0,s,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "9,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "9,0,q,0,2,0,3b|9,0,2,0,s,0,2,0,3b||",
                "Omsk": "9,0,q,0,2,0,26|9,0,2,0,s,0,2,0,26||",
                "Pakistan": "9,0,q,0,16,0,11h|9,0,2,0,s,0,16,0,11h||",
                "Palau": "9,0,2,0,9b|||",
                "Papua_New_Guinea": "9,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "9,0,q,0,16,0,p8|9,0,2,0,s,0,16,0,p8||",
                "Peru": "9,0,q,0,16,0,4n|9,0,2,0,s,0,16,0,4n||",
                "Philippines": "9,0,q,0,6t,0,g0|9,0,2,0,s,0,6t,0,g0||",
                "Phoenix_Islands": "9,0,6t,0,ae,0,pd|||",
                "Pierre_Miquelon": "9,0,q,0,2,0,12c,0,gp,0,15,0,12s|9,0,2,0,s,0,2,0,12c,0,gp,0,15,0,12s||",
                "Pitcairn": "9,0,2,0,7e|||",
                "Ponape": "9,0,2,0,9s|||",
                "Pyongyang": "9,0,2,0,8a|||",
                "Qyzylorda": "9,0,q,0,2,0,4d|9,0,2,0,s,0,2,0,4d||",
                "Reunion": "9,0,2,0,1hc|||",
                "Rothera": "9,0,2,0,5q|||",
                "Sakhalin": "9,0,q,0,2,0,11v|9,0,2,0,s,0,2,0,11v||",
                "Samara": "9,0,q,0,2,0,29|9,0,2,0,s,0,2,0,29||",
                "Samoa": "9,0,q,0,2,0,2c|9,0,2,0,s,0,2,0,2c||",
                "Seychelles": "9,0,6t,0,1ke|||",
                "Singapore": "9,0,q,0,2,0,196|||",
                "Solomon": "9,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "9,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "9,0,16,0,8q|||",
                "Syowa": "9,0,2,0,65|||",
                "Tahiti": "9,0,16,0,198|||",
                "Taipei": "9,0,q,0,2,0,12v|9,0,2,0,s,0,2,0,12v||",
                "Tajikistan": "9,0,16,0,1h7|||",
                "Tokelau": "9,0,2,0,5d|||",
                "Tonga": "9,0,q,0,2,0,2j|9,0,2,0,s,0,2,0,2j||",
                "Truk": "9,0,2,0,6p|||",
                "Turkmenistan": "9,0,q,0,16,0,130|9,0,2,0,s,0,16,0,130||",
                "Tuvalu": "9,0,2,0,6h|||",
                "Uruguay": "9,0,q,0,16,0,rn|9,0,2,0,s,0,16,0,rn||",
                "Uzbekistan": "9,0,q,0,16,0,123|9,0,2,0,s,0,16,0,123||",
                "Vanuatu": "9,0,q,0,16,0,2f|9,0,2,0,s,0,16,0,2f||",
                "Venezuela": "9,0,14,0,7s|||",
                "Vladivostok": "9,0,q,0,2,0,28|9,0,2,0,s,0,2,0,28||",
                "Volgograd": "9,0,q,0,2,0,ff|9,0,2,0,s,0,2,0,ff||",
                "Vostok": "9,0,2,0,5t|||",
                "Wake": "9,0,14,0,h7,0,5r|||",
                "Wallis": "9,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "9,0,q,0,2,0,3u|9,0,2,0,s,0,2,0,3u||",
                "Yekaterinburg": "9,0,q,0,2,0,11s|9,0,2,0,s,0,2,0,11s||"
            }
        },
        "pt": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|69,0,e9,0,14,0,pk||",
                "Europe/London": "|69,0,2,0,ec,0,ccp||"
            },
            "metazone": {
                "Acre": "69,0,e9,0,16,0,3q|69,0,2,0,ec,0,16,0,3q|93k|4lq",
                "Afghanistan": "69,0,16,0,1hv|||",
                "Africa_Central": "69,0,14,0,9d,0,n|||",
                "Africa_Eastern": "69,0,14,0,9d,0,8h|||",
                "Africa_Southern": "69,0,14,0,9d,0,16,0,10d|||",
                "Africa_Western": "69,0,e9,0,14,0,9d,0,9i|69,0,2,0,ec,0,14,0,9d,0,9i||",
                "Alaska": "69,0,e9,0,16,0,tj|69,0,2,0,ec,0,16,0,tj||",
                "Almaty": "69,0,e9,0,16,0,56|69,0,2,0,ec,0,16,0,56||",
                "Amazon": "69,0,e9,0,16,0,bg|69,0,2,0,ec,0,16,0,bg|93l|93m",
                "America_Central": "69,0,e9,0,n|69,0,2,0,ec,0,n||",
                "America_Eastern": "69,0,e9,0,8h|69,0,2,0,ec,0,8h||",
                "America_Mountain": "69,0,e9,0,14,0,109|69,0,2,0,ec,0,14,0,109||",
                "America_Pacific": "69,0,e9,0,16,0,9u|69,0,2,0,ec,0,16,0,9u||",
                "Anadyr": "69,0,e9,0,16,0,3l|69,0,2,0,ec,0,16,0,3l||",
                "Apia": "69,0,e9,0,2,0,3e|69,0,2,0,ec,0,2,0,3e||",
                "Aqtau": "69,0,e9,0,16,0,50|69,0,2,0,ec,0,16,0,50||",
                "Aqtobe": "69,0,e9,0,16,0,5o|69,0,2,0,ec,0,16,0,5o||",
                "Arabian": "69,0,e9,0,14,0,10t|69,0,2,0,ec,0,14,0,10t||",
                "Argentina": "69,0,e9,0,14,0,1e|69,0,2,0,ec,0,14,0,1e||",
                "Argentina_Western": "69,0,e9,0,14,0,1e,0,9i|69,0,2,0,ec,0,14,0,1e,0,9i||",
                "Armenia": "69,0,e9,0,14,0,7f9|69,0,2,0,ec,0,14,0,7f9||",
                "Atlantic": "69,0,e9,0,16,0,10s|69,0,2,0,ec,0,16,0,10s||",
                "Australia_Central": "69,0,e9,0,14,0,cn,0,n|69,0,2,0,ec,0,14,0,cn,0,n||",
                "Australia_CentralWestern": "69,0,e9,0,14,0,cn,0,7f8,5,9i|69,0,2,0,ec,0,14,0,cn,0,7f8,5,9i||",
                "Australia_Eastern": "69,0,e9,0,14,0,cn,0,8h|69,0,2,0,ec,0,14,0,cn,0,8h||",
                "Australia_Western": "69,0,e9,0,14,0,cn,0,9i|69,0,2,0,ec,0,14,0,cn,0,9i||",
                "Azerbaijan": "69,0,e9,0,16,0,7ea|69,0,2,0,ec,0,16,0,7ea||",
                "Azores": "69,0,e9,0,10q,0,b9|69,0,2,0,ec,0,10q,0,b9||",
                "Bangladesh": "69,0,e9,0,2,0,3n|69,0,2,0,ec,0,2,0,3n||",
                "Bhutan": "69,0,16,0,1hu|||",
                "Bolivia": "69,0,14,0,16g|||",
                "Brasilia": "69,0,e9,0,2,0,in|69,0,2,0,ec,0,2,0,in|93q|93r",
                "Brunei": "69,0,2,0,9o,0,a4|||",
                "Cape_Verde": "69,0,e9,0,16,0,ck,0,3j|69,0,2,0,ec,0,16,0,ck,0,3j||",
                "Chamorro": "69,0,2,0,6j|||",
                "Chatham": "69,0,e9,0,2,0,27|69,0,2,0,ec,0,2,0,27||",
                "Chile": "69,0,e9,0,16,0,49|69,0,2,0,ec,0,16,0,49||",
                "China": "69,0,e9,0,14,0,4h|69,0,2,0,ec,0,14,0,4h||",
                "Choibalsan": "69,0,e9,0,2,0,2q|69,0,2,0,ec,0,2,0,2q||",
                "Christmas": "69,0,14,0,h7,0,99|||",
                "Cocos": "69,0,6t,0,ae,0,93s|||",
                "Colombia": "69,0,e9,0,14,0,10n|69,0,2,0,ec,0,14,0,10n||",
                "Cook": "69,0,e9,0,6t,0,ae,0,2v|93t,0,69,0,2,0,ec,0,6t,0,ae,0,2v||",
                "Cuba": "69,0,e9,0,2,0,34|69,0,2,0,ec,0,2,0,34||",
                "Davis": "69,0,2,0,5s|||",
                "DumontDUrville": "69,0,2,0,5b,5,5j|||",
                "East_Timor": "69,0,16,0,7n,5,vk|||",
                "Easter": "69,0,e9,0,14,0,h7,0,2,0,120|69,0,2,0,ec,0,14,0,h7,0,2,0,120||",
                "Ecuador": "69,0,16,0,1hs|||",
                "Europe_Central": "69,0,e9,0,14,0,4p,0,n|69,0,2,0,ec,0,14,0,4p,0,n||",
                "Europe_Eastern": "69,0,e9,0,14,0,4p,0,8h|69,0,2,0,ec,0,14,0,4p,0,8h||",
                "Europe_Further_Eastern": "69,0,16,0,1ej,0,vk,0,2ao|||",
                "Europe_Western": "69,0,e9,0,14,0,4p,0,9i|69,0,2,0,ec,0,14,0,4p,0,9i||",
                "Falkland": "69,0,e9,0,6t,0,ae,0,4v|69,0,2,0,ec,0,6t,0,ae,0,4v||",
                "Fiji": "69,0,e9,0,2,0,4q|69,0,2,0,ec,0,2,0,4q||",
                "French_Guiana": "69,0,14,0,am,0,nf|||",
                "French_Southern": "69,0,14,0,93u,0,15,0,16,0,10d,0,14,0,2ah|||",
                "Galapagos": "69,0,2,0,cp|||",
                "Gambier": "69,0,2,0,6v|||",
                "Georgia": "69,0,e9,0,14,0,pv|69,0,2,0,ec,0,14,0,pv||",
                "Gilbert_Islands": "69,0,6t,0,ae,0,7gg|||",
                "GMT": "69,0,16,0,93v,0,2,0,5u|||",
                "Greenland_Eastern": "69,0,e9,0,14,0,7e7,0,8h|69,0,2,0,ec,0,14,0,7e7,0,8h||",
                "Greenland_Western": "69,0,e9,0,14,0,7dv,0,9i|69,0,2,0,ec,0,14,0,7dv,0,9i||",
                "Guam": "69,0,e9,0,2,0,83|||",
                "Gulf": "69,0,16,0,oh|||",
                "Guyana": "69,0,14,0,am|||",
                "Hawaii_Aleutian": "69,0,e9,0,16,0,37g,0,15,0,ae,0,11d|69,0,2,0,ec,0,16,0,37g,0,15,0,ae,0,11d||",
                "Hong_Kong": "69,0,e9,0,2,0,2l,0,2p|69,0,2,0,ec,0,2,0,2l,0,2p||",
                "Hovd": "69,0,e9,0,2,0,2g|69,0,2,0,ec,0,2,0,2g||",
                "India": "69,0,e9,0,14,0,1ho|||",
                "Indian_Ocean": "69,0,16,0,1hn,0,pa|||",
                "Indochina": "69,0,14,0,9p|||",
                "Indonesia_Central": "69,0,14,0,q2,0,n|||",
                "Indonesia_Eastern": "69,0,14,0,q2,0,8h|||",
                "Indonesia_Western": "69,0,14,0,q2,0,9i|||",
                "Iran": "69,0,e9,0,16,0,7du|69,0,2,0,ec,0,16,0,7du||",
                "Irkutsk": "69,0,e9,0,2,0,3h|69,0,2,0,ec,0,2,0,3h||",
                "Israel": "69,0,e9,0,2,0,4e|69,0,2,0,ec,0,2,0,4e||",
                "Japan": "69,0,e9,0,16,0,11g|69,0,2,0,ec,0,16,0,11g||",
                "Kamchatka": "69,0,e9,0,2,0,37,5,3k|69,0,2,0,ec,0,2,0,37,5,3k||",
                "Kazakhstan_Eastern": "69,0,16,0,7dt,0,8h|||",
                "Kazakhstan_Western": "69,0,16,0,7dt,0,9i|||",
                "Korea": "69,0,e9,0,14,0,10p|69,0,2,0,ec,0,14,0,10p||",
                "Kosrae": "69,0,2,0,6f|||",
                "Krasnoyarsk": "69,0,e9,0,2,0,41|69,0,2,0,ec,0,2,0,41||",
                "Kyrgystan": "69,0,16,0,1hi|||",
                "Lanka": "69,0,2,0,9c|||",
                "Line_Islands": "69,0,6t,0,ae,0,ac|||",
                "Lord_Howe": "69,0,e9,0,2,0,1u,0,24|69,0,2,0,ec,0,2,0,1u,0,24||",
                "Macau": "69,0,e9,0,2,0,57|69,0,2,0,ec,0,2,0,57||",
                "Macquarie": "69,0,14,0,h7,0,6m|||",
                "Magadan": "69,0,e9,0,2,0,2o|69,0,2,0,ec,0,2,0,2o||",
                "Malaysia": "69,0,14,0,1hh|||",
                "Maldives": "69,0,6t,0,ae,0,ov|||",
                "Marquesas": "69,0,6t,0,9e|||",
                "Marshall_Islands": "69,0,6t,0,ae,0,7o|||",
                "Mauritius": "69,0,e9,0,6t,0,ae,0,7ds|69,0,2,0,ec,0,6t,0,ae,0,7ds||",
                "Mawson": "69,0,2,0,63|||",
                "Mexico_Northwest": "69,0,e9,0,16,0,107,0,16,0,au|69,0,2,0,ec,0,16,0,107,0,16,0,au||",
                "Mexico_Pacific": "69,0,e9,0,16,0,9u,0,16,0,au|69,0,2,0,ec,0,16,0,9u,0,16,0,au||",
                "Mongolia": "69,0,e9,0,2,0,4f,0,2m|69,0,2,0,ec,0,2,0,4f,0,2m||",
                "Moscow": "69,0,e9,0,2,0,ca|69,0,2,0,ec,0,2,0,ca||",
                "Myanmar": "69,0,2,0,1eb|||",
                "Nauru": "69,0,2,0,5v|||",
                "Nepal": "69,0,16,0,8u|||",
                "New_Caledonia": "69,0,e9,0,14,0,ba,0,7dr|69,0,2,0,ec,0,14,0,ba,0,7dr||",
                "New_Zealand": "69,0,e9,0,14,0,ba,0,117|69,0,2,0,ec,0,14,0,ba,0,117||",
                "Newfoundland": "69,0,e9,0,2,0,119,0,ba|69,0,2,0,ec,0,2,0,119,0,ba||",
                "Niue": "69,0,2,0,8g|||",
                "Norfolk": "69,0,14,0,h7,0,6l|||",
                "Noronha": "69,0,e9,0,2,0,1s,0,2,0,23|69,0,2,0,ec,0,2,0,1s,0,2,0,23||",
                "North_Mariana": "69,0,6t,0,ae,0,bd,0,16,0,p4|||",
                "Novosibirsk": "69,0,e9,0,2,0,3b|69,0,2,0,ec,0,2,0,3b||",
                "Omsk": "69,0,e9,0,2,0,26|69,0,2,0,ec,0,2,0,26||",
                "Pakistan": "69,0,e9,0,16,0,11h|69,0,2,0,ec,0,16,0,11h||",
                "Palau": "69,0,2,0,9b|||",
                "Papua_New_Guinea": "69,0,2,0,9a,0,ba,0,1hf|||",
                "Paraguay": "69,0,e9,0,16,0,p8|69,0,2,0,ec,0,16,0,p8||",
                "Peru": "69,0,e9,0,16,0,4n|69,0,2,0,ec,0,16,0,4n||",
                "Philippines": "69,0,e9,0,6t,0,g0|69,0,2,0,ec,0,6t,0,g0||",
                "Phoenix_Islands": "69,0,6t,0,ae,0,946|||",
                "Pierre_Miquelon": "69,0,e9,0,2,0,8i,0,33,0,15,0,35|69,0,2,0,ec,0,2,0,8i,0,33,0,15,0,35||",
                "Pitcairn": "69,0,2,0,7e|||",
                "Ponape": "69,0,2,0,9s|||",
                "Pyongyang": "69,0,2,0,8a|||",
                "Qyzylorda": "69,0,e9,0,2,0,4d|69,0,2,0,ec,0,2,0,4d||",
                "Reunion": "69,0,6t,0,ae,0,1hc|||",
                "Rothera": "69,0,2,0,5q|||",
                "Sakhalin": "69,0,e9,0,2,0,11v|69,0,2,0,ec,0,2,0,11v||",
                "Samara": "69,0,e9,0,2,0,29|69,0,2,0,ec,0,2,0,29||",
                "Samoa": "69,0,e9,0,2,0,2c|69,0,2,0,ec,0,2,0,2c||",
                "Seychelles": "69,0,6t,0,ae,0,8k|||",
                "Singapore": "69,0,e9,0,2,0,947|||",
                "Solomon": "69,0,6t,0,ae,0,1h8|||",
                "South_Georgia": "69,0,14,0,pv,0,16,0,10d|||",
                "Suriname": "69,0,16,0,8q|||",
                "Syowa": "69,0,2,0,65|||",
                "Tahiti": "69,0,16,0,198|||",
                "Taipei": "69,0,e9,0,2,0,3d|69,0,2,0,ec,0,2,0,3d||",
                "Tajikistan": "69,0,16,0,1h7|||",
                "Tokelau": "69,0,2,0,5d|||",
                "Tonga": "69,0,e9,0,2,0,2j|69,0,2,0,ec,0,2,0,2j||",
                "Truk": "69,0,2,0,6p|||",
                "Turkmenistan": "69,0,e9,0,16,0,7dq|69,0,2,0,ec,0,16,0,7dq||",
                "Tuvalu": "69,0,2,0,6h|||",
                "Uruguay": "69,0,e9,0,16,0,rn|69,0,2,0,ec,0,16,0,rn||",
                "Uzbekistan": "69,0,e9,0,16,0,123|69,0,2,0,ec,0,16,0,123||",
                "Vanuatu": "69,0,e9,0,2,0,2f|69,0,2,0,ec,0,2,0,2f||",
                "Venezuela": "69,0,14,0,7s|||",
                "Vladivostok": "69,0,e9,0,2,0,28|69,0,2,0,ec,0,2,0,28||",
                "Volgograd": "69,0,e9,0,2,0,ff|69,0,2,0,ec,0,2,0,ff||",
                "Vostok": "69,0,2,0,5t|||",
                "Wake": "69,0,6t,0,ae,0,5r|||",
                "Wallis": "69,0,2,0,5f,0,15,0,5k|||",
                "Yakutsk": "69,0,e9,0,2,0,3u|69,0,2,0,ec,0,2,0,3u||",
                "Yekaterinburg": "69,0,e9,0,2,0,11s|69,0,2,0,ec,0,2,0,11s||"
            }
        },
        "qu-BO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Bolivia": "||5pl|"
            }
        },
        "qu-EC": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Ecuador": "||5ps|"
            }
        },
        "qu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Peru": "||5q0|5q1"
            }
        },
        "rm": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "rn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ro-MD": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|i,0,2,0,9n,0,a6,0,5nv||",
                "Europe/London": "|i,0,2,0,9n,0,5nq||"
            },
            "metazone": {
                "Acre": "i,0,38,0,3q|i,0,2,0,9n,0,3q||",
                "Afghanistan": "i,0,7do|||",
                "Africa_Central": "i,0,1nn,0,1gp|||",
                "Africa_Eastern": "i,0,1nn,0,2f6|||",
                "Africa_Southern": "i,0,1nn,0,7dk|||",
                "Africa_Western": "i,0,38,0,a6,0,1nn,0,18o|i,0,2,0,9n,0,a6,0,1nn,0,18o||",
                "Alaska": "i,0,38,0,6d,0,3p|i,0,2,0,9n,0,6d,0,3p||",
                "Amazon": "i,0,38,0,a6,0,4ab|i,0,2,0,9n,0,a6,0,4ab||",
                "America_Central": "i,0,38,0,4aa,0,93,5,14q|i,0,2,0,9n,0,4aa,0,93,5,14q||",
                "America_Eastern": "i,0,38,0,4a8,0,93,5,14q|i,0,2,0,9n,0,4a8,0,93,5,14q||",
                "America_Mountain": "i,0,38,0,1go,0,1gn,0,4a5,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,4a5,0,93,5,14q||",
                "America_Pacific": "i,0,38,0,1go,0,1gn,0,2h,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,2h,0,93,5,14q||",
                "Anadyr": "i,0,38,0,6d,0,3l|i,0,2,0,9n,0,6d,0,3l||",
                "Apia": "i,0,38,0,6d,0,3e|i,0,2,0,9n,0,6d,0,3e||",
                "Arabian": "i,0,38,0,4a4|i,0,2,0,9n,0,4a4||",
                "Argentina": "i,0,38,0,a6,0,24u|i,0,2,0,9n,0,a6,0,24u||",
                "Argentina_Western": "i,0,38,0,a6,0,24u,0,18o|i,0,2,0,9n,0,a6,0,24u,0,18o||",
                "Armenia": "i,0,38,0,a6,0,4a2|i,0,2,0,9n,0,a6,0,4a2||",
                "Atlantic": "i,0,38,0,1go,0,1gn,0,6g,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,6g,0,93,5,14q||",
                "Australia_Central": "i,0,38,0,a6,0,17v,0,1gp|i,0,2,0,9n,0,a6,0,17v,0,1gp||",
                "Australia_CentralWestern": "i,0,38,0,a6,0,17v,0,n,0,18o|i,0,2,0,9n,0,a6,0,17v,0,n,0,18o||",
                "Australia_Eastern": "i,0,38,0,a6,0,17v,0,2f6|i,0,2,0,9n,0,a6,0,17v,0,2f6||",
                "Australia_Western": "i,0,38,0,a6,0,17v,0,18o|i,0,2,0,9n,0,a6,0,17v,0,18o||",
                "Azerbaijan": "i,0,38,0,a6,0,4a0|i,0,2,0,9n,0,a6,0,4a0||",
                "Azores": "i,0,38,0,6d,0,2eg|i,0,2,0,9n,0,6d,0,2eg||",
                "Bangladesh": "i,0,38,0,6d,0,3n|i,0,2,0,9n,0,6d,0,3n||",
                "Bhutan": "i,0,7d5|||",
                "Bolivia": "i,0,7d4|||",
                "Brasilia": "i,0,38,0,a6,0,49v|i,0,2,0,9n,0,a6,0,49v||",
                "Brunei": "i,0,6d,0,9o,0,a4|||",
                "Cape_Verde": "i,0,38,0,6d,0,49u,0,3j|i,0,2,0,9n,0,6d,0,49u,0,3j||",
                "Chamorro": "i,0,6d,0,6j|||",
                "Chatham": "i,0,38,0,6d,0,27|i,0,2,0,9n,0,6d,0,27||",
                "Chile": "i,0,38,0,6d,0,49|i,0,2,0,9n,0,6d,0,49||",
                "China": "i,0,38,0,a6,0,49t|i,0,2,0,9n,0,a6,0,49t||",
                "Choibalsan": "i,0,38,0,6d,0,2q|i,0,2,0,9n,0,6d,0,2q||",
                "Christmas": "i,0,6d,0,2en,0,99|||",
                "Cocos": "i,0,167,0,7v|||",
                "Colombia": "i,0,38,0,a6,0,49q|i,0,2,0,9n,0,a6,0,49q||",
                "Cook": "i,0,38,0,a6,0,167,0,2v|i,0,2,0,9n,0,a6,0,167,0,2v||",
                "Cuba": "i,0,38,0,a6,0,49p|i,0,2,0,9n,0,a6,0,49p||",
                "Davis": "i,0,6d,0,5s|||",
                "DumontDUrville": "i,0,6d,0,5b,5,5j|||",
                "East_Timor": "i,0,7cs,0,2,0,195|||",
                "Easter": "i,0,38,0,6d,0,2en,0,49o|i,0,2,0,9n,0,6d,0,2en,0,49o||",
                "Ecuador": "i,0,7cq|||",
                "Europe_Central": "i,0,38,0,a6,0,1cm,0,1gp|i,0,2,0,9n,0,a6,0,1cm,0,1gp|h0|h4",
                "Europe_Eastern": "i,0,38,0,a6,0,1cm,0,2,0,195|i,0,2,0,9n,0,a6,0,1cm,0,2,0,195|gu|gv",
                "Europe_Further_Eastern": "i,0,1cm,0,2,0,195,0,7co|||",
                "Europe_Western": "i,0,38,0,a6,0,1cm,0,2,0,258|i,0,2,0,9n,0,a6,0,1cm,0,2,0,258|h1|h2",
                "Falkland": "i,0,38,0,6d,0,2eb,0,4v|i,0,2,0,9n,0,6d,0,2eb,0,4v||",
                "Fiji": "i,0,38,0,6d,0,4q|i,0,2,0,9n,0,6d,0,4q||",
                "French_Guiana": "i,0,6d,0,7a,0,7cl|||",
                "French_Southern": "i,0,6d,0,7ck,0,7cj,0,25a,0,7ch,0,25d|||",
                "Galapagos": "i,0,6d,0,ak|||",
                "Gambier": "i,0,6d,0,6v|||",
                "Georgia": "i,0,38,0,a6,0,2e0|i,0,2,0,9n,0,a6,0,2e0||",
                "Gilbert_Islands": "i,0,167,0,6q|||",
                "GMT": "i,0,2,0,7cd||ah|",
                "Greenland_Eastern": "i,0,38,0,a6,0,25e,0,op|i,0,2,0,9n,0,a6,0,25e,0,op||",
                "Greenland_Western": "i,0,38,0,a6,0,25e,0,nd|i,0,2,0,9n,0,a6,0,25e,0,nd||",
                "Gulf": "i,0,38,0,a6,0,7cb|||",
                "Guyana": "i,0,6d,0,7a|||",
                "Hawaii_Aleutian": "i,0,38,0,6d,0,4g,5,1ml|i,0,2,0,9n,0,6d,0,4g,5,1ml||",
                "Hong_Kong": "i,0,38,0,6d,0,2l,0,2p|i,0,2,0,9n,0,6d,0,2l,0,2p||",
                "Hovd": "i,0,38,0,6d,0,2g|i,0,2,0,9n,0,6d,0,2g||",
                "India": "i,0,7ca|||",
                "Indian_Ocean": "i,0,7c9,0,br|||",
                "Indochina": "i,0,7c8|||",
                "Indonesia_Central": "i,0,2di,0,1gp|||",
                "Indonesia_Eastern": "i,0,2di,0,2,0,195|||",
                "Indonesia_Western": "i,0,2di,0,2,0,258|||",
                "Iran": "i,0,38,0,a6,0,49f|i,0,2,0,9n,0,a6,0,49f||",
                "Irkutsk": "i,0,38,0,6d,0,49e|i,0,2,0,9n,0,6d,0,49e||",
                "Israel": "i,0,38,0,a6,0,49d|i,0,2,0,9n,0,a6,0,49d||",
                "Japan": "i,0,38,0,a6,0,49c|i,0,2,0,9n,0,a6,0,49c||",
                "Kamchatka": "i,0,38,0,6d,0,37,5,49b|i,0,2,0,9n,0,6d,0,37,5,49b||",
                "Kazakhstan_Eastern": "i,0,6d,0,49a,0,2,0,195|||",
                "Kazakhstan_Western": "i,0,6d,0,49a,0,2,0,258|||",
                "Korea": "i,0,38,0,a6,0,499|i,0,2,0,9n,0,a6,0,499||",
                "Kosrae": "i,0,6d,0,6f|||",
                "Krasnoyarsk": "i,0,38,0,6d,0,1c7|i,0,2,0,9n,0,6d,0,1c7||",
                "Kyrgystan": "i,0,6d,0,7bv|||",
                "Line_Islands": "i,0,6d,0,2eb,0,ac|||",
                "Lord_Howe": "i,0,38,0,6d,0,1u,0,24|i,0,2,0,9n,0,6d,0,1u,0,24||",
                "Macquarie": "i,0,6d,0,6m|||",
                "Magadan": "i,0,38,0,6d,0,2o|i,0,2,0,9n,0,6d,0,2o||",
                "Malaysia": "i,0,6d,0,bf|||",
                "Maldives": "i,0,6d,0,2ot|||",
                "Marquesas": "i,0,167,0,7bu|||",
                "Marshall_Islands": "i,0,167,0,7o|||",
                "Mauritius": "i,0,38,0,6d,0,4u|i,0,2,0,9n,0,6d,0,4u||",
                "Mawson": "i,0,6d,0,63|||",
                "Mexico_Northwest": "i,0,38,0,a6,0,498,0,2,0,93,5,1o9|i,0,2,0,9n,0,a6,0,498,0,2,0,93,5,1o9||",
                "Mexico_Pacific": "i,0,38,0,a6,0,497,0,2h,0,496|i,0,2,0,9n,0,a6,0,497,0,2h,0,496||",
                "Mongolia": "i,0,38,0,6d,0,4f,0,2m|i,0,2,0,9n,0,6d,0,4f,0,2m||",
                "Moscow": "i,0,38,0,a6,0,495|i,0,2,0,9n,0,a6,0,495||",
                "Myanmar": "i,0,7bp|||",
                "Nauru": "i,0,6d,0,5v|||",
                "Nepal": "i,0,7bo|||",
                "New_Caledonia": "i,0,38,0,a6,0,25f,0,493|i,0,2,0,9n,0,a6,0,25f,0,493||",
                "New_Zealand": "i,0,38,0,a6,0,25f,0,492|i,0,2,0,9n,0,a6,0,25f,0,492||",
                "Newfoundland": "i,0,38,0,6d,0,53|i,0,2,0,9n,0,6d,0,53||",
                "Niue": "i,0,6d,0,8g|||",
                "Norfolk": "i,0,167,0,6l|||",
                "Noronha": "i,0,38,0,6d,0,1s,0,2,0,23|i,0,2,0,9n,0,6d,0,1s,0,2,0,23||",
                "Novosibirsk": "i,0,38,0,6d,0,3b|i,0,2,0,9n,0,6d,0,3b||",
                "Omsk": "i,0,38,0,6d,0,26|i,0,2,0,9n,0,6d,0,26||",
                "Pakistan": "i,0,38,0,a6,0,491|i,0,2,0,9n,0,a6,0,491||",
                "Palau": "i,0,6d,0,9b|||",
                "Papua_New_Guinea": "i,0,6d,0,9a,0,7bj,0,39j|||",
                "Paraguay": "i,0,38,0,6d,0,3c|i,0,2,0,9n,0,6d,0,3c||",
                "Peru": "i,0,38,0,6d,0,4n|i,0,2,0,9n,0,6d,0,4n||",
                "Philippines": "i,0,38,0,6d,0,490|i,0,2,0,9n,0,6d,0,490||",
                "Phoenix_Islands": "i,0,167,0,90|||",
                "Pierre_Miquelon": "i,0,38,0,6d,0,8i,5,33,0,25a,0,35|i,0,2,0,9n,0,6d,0,8i,5,33,0,25a,0,35||",
                "Pitcairn": "i,0,6d,0,7e|||",
                "Ponape": "i,0,6d,0,9s|||",
                "Pyongyang": "i,0,6d,0,8a|||",
                "Reunion": "i,0,6d,0,b4|||",
                "Rothera": "i,0,6d,0,5q|||",
                "Sakhalin": "i,0,38,0,6d,0,164|i,0,2,0,9n,0,6d,0,164||",
                "Samara": "i,0,38,0,6d,0,29|i,0,2,0,9n,0,6d,0,29||",
                "Samoa": "i,0,38,0,6d,0,2c|i,0,2,0,9n,0,6d,0,2c||",
                "Seychelles": "i,0,6d,0,8k|||",
                "Singapore": "i,0,6d,0,bi|||",
                "Solomon": "i,0,167,0,b5|||",
                "South_Georgia": "i,0,2e0,0,2,0,i6|||",
                "Suriname": "i,0,7bg|||",
                "Syowa": "i,0,6d,0,65|||",
                "Tahiti": "i,0,6d,0,8n|||",
                "Taipei": "i,0,38,0,6d,0,3d|i,0,2,0,9n,0,6d,0,3d||",
                "Tajikistan": "i,0,6d,0,lc|||",
                "Tokelau": "i,0,6d,0,5d|||",
                "Tonga": "i,0,38,0,6d,0,2j|i,0,2,0,9n,0,6d,0,2j||",
                "Truk": "i,0,6d,0,6p|||",
                "Turkmenistan": "i,0,38,0,6d,0,4t|i,0,2,0,9n,0,6d,0,4t||",
                "Tuvalu": "i,0,6d,0,6h|||",
                "Uruguay": "i,0,38,0,a6,0,48u|i,0,2,0,9n,0,a6,0,48u||",
                "Uzbekistan": "i,0,38,0,6d,0,60|i,0,2,0,9n,0,6d,0,60||",
                "Vanuatu": "i,0,38,0,6d,0,2f|i,0,2,0,9n,0,6d,0,2f||",
                "Venezuela": "i,0,7be|||",
                "Vladivostok": "i,0,38,0,6d,0,28|i,0,2,0,9n,0,6d,0,28||",
                "Volgograd": "i,0,38,0,6d,0,3f|i,0,2,0,9n,0,6d,0,3f||",
                "Vostok": "i,0,6d,0,5t|||",
                "Wake": "i,0,7bd,0,5r|||",
                "Wallis": "i,0,6d,0,5f,0,25a,0,5k|||",
                "Yakutsk": "i,0,38,0,6d,0,48t|i,0,2,0,9n,0,6d,0,48t||",
                "Yekaterinburg": "i,0,38,0,6d,0,1lr|i,0,2,0,9n,0,6d,0,1lr||"
            }
        },
        "ro": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|i,0,2,0,9n,0,a6,0,5nv||",
                "Europe/London": "|i,0,2,0,9n,0,5nq||"
            },
            "metazone": {
                "Acre": "i,0,38,0,3q|i,0,2,0,9n,0,3q||",
                "Afghanistan": "i,0,7do|||",
                "Africa_Central": "i,0,1nn,0,1gp|||",
                "Africa_Eastern": "i,0,1nn,0,2f6|||",
                "Africa_Southern": "i,0,1nn,0,7dk|||",
                "Africa_Western": "i,0,38,0,a6,0,1nn,0,18o|i,0,2,0,9n,0,a6,0,1nn,0,18o||",
                "Alaska": "i,0,38,0,6d,0,3p|i,0,2,0,9n,0,6d,0,3p||",
                "Amazon": "i,0,38,0,a6,0,4ab|i,0,2,0,9n,0,a6,0,4ab||",
                "America_Central": "i,0,38,0,4aa,0,93,5,14q|i,0,2,0,9n,0,4aa,0,93,5,14q||",
                "America_Eastern": "i,0,38,0,4a8,0,93,5,14q|i,0,2,0,9n,0,4a8,0,93,5,14q||",
                "America_Mountain": "i,0,38,0,1go,0,1gn,0,4a5,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,4a5,0,93,5,14q||",
                "America_Pacific": "i,0,38,0,1go,0,1gn,0,2h,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,2h,0,93,5,14q||",
                "Anadyr": "i,0,38,0,6d,0,3l|i,0,2,0,9n,0,6d,0,3l||",
                "Apia": "i,0,38,0,6d,0,3e|i,0,2,0,9n,0,6d,0,3e||",
                "Arabian": "i,0,38,0,4a4|i,0,2,0,9n,0,4a4||",
                "Argentina": "i,0,38,0,a6,0,24u|i,0,2,0,9n,0,a6,0,24u||",
                "Argentina_Western": "i,0,38,0,a6,0,24u,0,18o|i,0,2,0,9n,0,a6,0,24u,0,18o||",
                "Armenia": "i,0,38,0,a6,0,4a2|i,0,2,0,9n,0,a6,0,4a2||",
                "Atlantic": "i,0,38,0,1go,0,1gn,0,6g,0,93,5,14q|i,0,2,0,9n,0,1go,0,1gn,0,6g,0,93,5,14q||",
                "Australia_Central": "i,0,38,0,a6,0,17v,0,1gp|i,0,2,0,9n,0,a6,0,17v,0,1gp||",
                "Australia_CentralWestern": "i,0,38,0,a6,0,17v,0,n,0,18o|i,0,2,0,9n,0,a6,0,17v,0,n,0,18o||",
                "Australia_Eastern": "i,0,38,0,a6,0,17v,0,2f6|i,0,2,0,9n,0,a6,0,17v,0,2f6||",
                "Australia_Western": "i,0,38,0,a6,0,17v,0,18o|i,0,2,0,9n,0,a6,0,17v,0,18o||",
                "Azerbaijan": "i,0,38,0,a6,0,4a0|i,0,2,0,9n,0,a6,0,4a0||",
                "Azores": "i,0,38,0,6d,0,2eg|i,0,2,0,9n,0,6d,0,2eg||",
                "Bangladesh": "i,0,38,0,6d,0,3n|i,0,2,0,9n,0,6d,0,3n||",
                "Bhutan": "i,0,7d5|||",
                "Bolivia": "i,0,7d4|||",
                "Brasilia": "i,0,38,0,a6,0,49v|i,0,2,0,9n,0,a6,0,49v||",
                "Brunei": "i,0,6d,0,9o,0,a4|||",
                "Cape_Verde": "i,0,38,0,6d,0,49u,0,3j|i,0,2,0,9n,0,6d,0,49u,0,3j||",
                "Chamorro": "i,0,6d,0,6j|||",
                "Chatham": "i,0,38,0,6d,0,27|i,0,2,0,9n,0,6d,0,27||",
                "Chile": "i,0,38,0,6d,0,49|i,0,2,0,9n,0,6d,0,49||",
                "China": "i,0,38,0,a6,0,49t|i,0,2,0,9n,0,a6,0,49t||",
                "Choibalsan": "i,0,38,0,6d,0,2q|i,0,2,0,9n,0,6d,0,2q||",
                "Christmas": "i,0,6d,0,2en,0,99|||",
                "Cocos": "i,0,167,0,7v|||",
                "Colombia": "i,0,38,0,a6,0,49q|i,0,2,0,9n,0,a6,0,49q||",
                "Cook": "i,0,38,0,a6,0,167,0,2v|i,0,2,0,9n,0,a6,0,167,0,2v||",
                "Cuba": "i,0,38,0,a6,0,49p|i,0,2,0,9n,0,a6,0,49p||",
                "Davis": "i,0,6d,0,5s|||",
                "DumontDUrville": "i,0,6d,0,5b,5,5j|||",
                "East_Timor": "i,0,7cs,0,2,0,195|||",
                "Easter": "i,0,38,0,6d,0,2en,0,49o|i,0,2,0,9n,0,6d,0,2en,0,49o||",
                "Ecuador": "i,0,7cq|||",
                "Europe_Central": "i,0,38,0,a6,0,1cm,0,1gp|i,0,2,0,9n,0,a6,0,1cm,0,1gp|h0|h4",
                "Europe_Eastern": "i,0,38,0,a6,0,1cm,0,2,0,195|i,0,2,0,9n,0,a6,0,1cm,0,2,0,195|gu|gv",
                "Europe_Further_Eastern": "i,0,1cm,0,2,0,195,0,7co|||",
                "Europe_Western": "i,0,38,0,a6,0,1cm,0,2,0,258|i,0,2,0,9n,0,a6,0,1cm,0,2,0,258|h1|h2",
                "Falkland": "i,0,38,0,6d,0,2eb,0,4v|i,0,2,0,9n,0,6d,0,2eb,0,4v||",
                "Fiji": "i,0,38,0,6d,0,4q|i,0,2,0,9n,0,6d,0,4q||",
                "French_Guiana": "i,0,6d,0,7a,0,7cl|||",
                "French_Southern": "i,0,6d,0,7ck,0,7cj,0,25a,0,7ch,0,25d|||",
                "Galapagos": "i,0,6d,0,ak|||",
                "Gambier": "i,0,6d,0,6v|||",
                "Georgia": "i,0,38,0,a6,0,2e0|i,0,2,0,9n,0,a6,0,2e0||",
                "Gilbert_Islands": "i,0,167,0,6q|||",
                "GMT": "i,0,2,0,7cd||ah|",
                "Greenland_Eastern": "i,0,38,0,a6,0,25e,0,op|i,0,2,0,9n,0,a6,0,25e,0,op||",
                "Greenland_Western": "i,0,38,0,a6,0,25e,0,nd|i,0,2,0,9n,0,a6,0,25e,0,nd||",
                "Gulf": "i,0,38,0,a6,0,7cb|||",
                "Guyana": "i,0,6d,0,7a|||",
                "Hawaii_Aleutian": "i,0,38,0,6d,0,4g,5,1ml|i,0,2,0,9n,0,6d,0,4g,5,1ml||",
                "Hong_Kong": "i,0,38,0,6d,0,2l,0,2p|i,0,2,0,9n,0,6d,0,2l,0,2p||",
                "Hovd": "i,0,38,0,6d,0,2g|i,0,2,0,9n,0,6d,0,2g||",
                "India": "i,0,7ca|||",
                "Indian_Ocean": "i,0,7c9,0,br|||",
                "Indochina": "i,0,7c8|||",
                "Indonesia_Central": "i,0,2di,0,1gp|||",
                "Indonesia_Eastern": "i,0,2di,0,2,0,195|||",
                "Indonesia_Western": "i,0,2di,0,2,0,258|||",
                "Iran": "i,0,38,0,a6,0,49f|i,0,2,0,9n,0,a6,0,49f||",
                "Irkutsk": "i,0,38,0,6d,0,49e|i,0,2,0,9n,0,6d,0,49e||",
                "Israel": "i,0,38,0,a6,0,49d|i,0,2,0,9n,0,a6,0,49d||",
                "Japan": "i,0,38,0,a6,0,49c|i,0,2,0,9n,0,a6,0,49c||",
                "Kamchatka": "i,0,38,0,6d,0,37,5,49b|i,0,2,0,9n,0,6d,0,37,5,49b||",
                "Kazakhstan_Eastern": "i,0,6d,0,49a,0,2,0,195|||",
                "Kazakhstan_Western": "i,0,6d,0,49a,0,2,0,258|||",
                "Korea": "i,0,38,0,a6,0,499|i,0,2,0,9n,0,a6,0,499||",
                "Kosrae": "i,0,6d,0,6f|||",
                "Krasnoyarsk": "i,0,38,0,6d,0,1c7|i,0,2,0,9n,0,6d,0,1c7||",
                "Kyrgystan": "i,0,6d,0,7bv|||",
                "Line_Islands": "i,0,6d,0,2eb,0,ac|||",
                "Lord_Howe": "i,0,38,0,6d,0,1u,0,24|i,0,2,0,9n,0,6d,0,1u,0,24||",
                "Macquarie": "i,0,6d,0,6m|||",
                "Magadan": "i,0,38,0,6d,0,2o|i,0,2,0,9n,0,6d,0,2o||",
                "Malaysia": "i,0,6d,0,bf|||",
                "Maldives": "i,0,6d,0,2ot|||",
                "Marquesas": "i,0,167,0,7bu|||",
                "Marshall_Islands": "i,0,167,0,7o|||",
                "Mauritius": "i,0,38,0,6d,0,4u|i,0,2,0,9n,0,6d,0,4u||",
                "Mawson": "i,0,6d,0,63|||",
                "Mexico_Northwest": "i,0,38,0,a6,0,498,0,2,0,93,5,1o9|i,0,2,0,9n,0,a6,0,498,0,2,0,93,5,1o9||",
                "Mexico_Pacific": "i,0,38,0,a6,0,497,0,2h,0,496|i,0,2,0,9n,0,a6,0,497,0,2h,0,496||",
                "Mongolia": "i,0,38,0,6d,0,4f,0,2m|i,0,2,0,9n,0,6d,0,4f,0,2m||",
                "Moscow": "i,0,38,0,a6,0,495|i,0,2,0,9n,0,a6,0,495||",
                "Myanmar": "i,0,7bp|||",
                "Nauru": "i,0,6d,0,5v|||",
                "Nepal": "i,0,7bo|||",
                "New_Caledonia": "i,0,38,0,a6,0,25f,0,493|i,0,2,0,9n,0,a6,0,25f,0,493||",
                "New_Zealand": "i,0,38,0,a6,0,25f,0,492|i,0,2,0,9n,0,a6,0,25f,0,492||",
                "Newfoundland": "i,0,38,0,6d,0,53|i,0,2,0,9n,0,6d,0,53||",
                "Niue": "i,0,6d,0,8g|||",
                "Norfolk": "i,0,167,0,6l|||",
                "Noronha": "i,0,38,0,6d,0,1s,0,2,0,23|i,0,2,0,9n,0,6d,0,1s,0,2,0,23||",
                "Novosibirsk": "i,0,38,0,6d,0,3b|i,0,2,0,9n,0,6d,0,3b||",
                "Omsk": "i,0,38,0,6d,0,26|i,0,2,0,9n,0,6d,0,26||",
                "Pakistan": "i,0,38,0,a6,0,491|i,0,2,0,9n,0,a6,0,491||",
                "Palau": "i,0,6d,0,9b|||",
                "Papua_New_Guinea": "i,0,6d,0,9a,0,7bj,0,39j|||",
                "Paraguay": "i,0,38,0,6d,0,3c|i,0,2,0,9n,0,6d,0,3c||",
                "Peru": "i,0,38,0,6d,0,4n|i,0,2,0,9n,0,6d,0,4n||",
                "Philippines": "i,0,38,0,6d,0,490|i,0,2,0,9n,0,6d,0,490||",
                "Phoenix_Islands": "i,0,167,0,90|||",
                "Pierre_Miquelon": "i,0,38,0,6d,0,8i,5,33,0,25a,0,35|i,0,2,0,9n,0,6d,0,8i,5,33,0,25a,0,35||",
                "Pitcairn": "i,0,6d,0,7e|||",
                "Ponape": "i,0,6d,0,9s|||",
                "Pyongyang": "i,0,6d,0,8a|||",
                "Reunion": "i,0,6d,0,b4|||",
                "Rothera": "i,0,6d,0,5q|||",
                "Sakhalin": "i,0,38,0,6d,0,164|i,0,2,0,9n,0,6d,0,164||",
                "Samara": "i,0,38,0,6d,0,29|i,0,2,0,9n,0,6d,0,29||",
                "Samoa": "i,0,38,0,6d,0,2c|i,0,2,0,9n,0,6d,0,2c||",
                "Seychelles": "i,0,6d,0,8k|||",
                "Singapore": "i,0,6d,0,bi|||",
                "Solomon": "i,0,167,0,b5|||",
                "South_Georgia": "i,0,2e0,0,2,0,i6|||",
                "Suriname": "i,0,7bg|||",
                "Syowa": "i,0,6d,0,65|||",
                "Tahiti": "i,0,6d,0,8n|||",
                "Taipei": "i,0,38,0,6d,0,3d|i,0,2,0,9n,0,6d,0,3d||",
                "Tajikistan": "i,0,6d,0,lc|||",
                "Tokelau": "i,0,6d,0,5d|||",
                "Tonga": "i,0,38,0,6d,0,2j|i,0,2,0,9n,0,6d,0,2j||",
                "Truk": "i,0,6d,0,6p|||",
                "Turkmenistan": "i,0,38,0,6d,0,4t|i,0,2,0,9n,0,6d,0,4t||",
                "Tuvalu": "i,0,6d,0,6h|||",
                "Uruguay": "i,0,38,0,a6,0,48u|i,0,2,0,9n,0,a6,0,48u||",
                "Uzbekistan": "i,0,38,0,6d,0,60|i,0,2,0,9n,0,6d,0,60||",
                "Vanuatu": "i,0,38,0,6d,0,2f|i,0,2,0,9n,0,6d,0,2f||",
                "Venezuela": "i,0,7be|||",
                "Vladivostok": "i,0,38,0,6d,0,28|i,0,2,0,9n,0,6d,0,28||",
                "Volgograd": "i,0,38,0,6d,0,3f|i,0,2,0,9n,0,6d,0,3f||",
                "Vostok": "i,0,6d,0,5t|||",
                "Wake": "i,0,7bd,0,5r|||",
                "Wallis": "i,0,6d,0,5f,0,25a,0,5k|||",
                "Yakutsk": "i,0,38,0,6d,0,48t|i,0,2,0,9n,0,6d,0,48t||",
                "Yekaterinburg": "i,0,38,0,6d,0,1lr|i,0,2,0,9n,0,6d,0,1lr||"
            }
        },
        "rof": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "root": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ru-BY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "ru-KG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "ru-KZ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "ru-MD": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "ru-UA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "ru": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,k,0,21,0,p||",
                "Europe/London": "|2jt,k,0,22,0,p||"
            },
            "metazone": {
                "Acre": "1gm,0,21,0,p|1gm,0,22,0,p||",
                "Afghanistan": "28q|||",
                "Africa_Central": "ii,0,mi|||",
                "Africa_Eastern": "ib,0,mi|||",
                "Africa_Southern": "1gi,0,mi|||",
                "Africa_Western": "hn,0,mi,k,0,21,0,p|hn,0,mi,k,0,22,0,p||",
                "Alaska": "12l,k,0,21,0,p|12l,k,0,22,0,p||",
                "Almaty": "1gh,5,1gg,0,21,0,p|1gh,5,1gg,0,22,0,p||",
                "Amazon": "18e,k,0,21,0,p|18e,k,0,22,0,p||",
                "America_Central": "ii,0,m6,k,0,21,0,p|ii,0,m6,k,0,22,0,p||",
                "America_Eastern": "ib,0,m6,k,0,21,0,p|ib,0,m6,k,0,22,0,p||",
                "America_Mountain": "1gf,0,21,0,p,0,1ge|1gf,0,22,0,p,0,1ge||",
                "America_Pacific": "11b,0,21,0,p|11b,0,22,0,p||",
                "Anadyr": "1g7,0,21,0,p|1g7,0,22,0,p||",
                "Apia": "15s,k,0,21,0,p|15s,k,0,22,0,p||",
                "Aqtau": "1g6,k,0,21,0,p|1g6,0,22,0,p||",
                "Aqtobe": "1g5,0,21,0,p|1g5,0,22,0,p||",
                "Arabian": "1g4,0,1g2,k,0,21,0,p|1g4,0,1g2,k,0,22,0,p||",
                "Argentina": "hk,k,0,21,0,p|hk,k,0,22,0,p||",
                "Argentina_Western": "hn,0,hk,k,0,21,0,p|hn,0,hk,k,0,22,0,p||",
                "Armenia": "18n,k,0,21,0,p|18n,k,0,22,0,p||",
                "Atlantic": "1g0,0,21,0,p|1g0,0,22,0,p||",
                "Australia_Central": "ii,0,eo,k,0,21,0,p|ii,0,eo,k,0,22,0,p||",
                "Australia_CentralWestern": "ii,0,eo,k,0,11n,0,21,0,p|ii,0,eo,k,0,11n,0,22,0,p||",
                "Australia_Eastern": "ib,0,eo,k,0,21,0,p|ib,0,eo,k,0,22,0,p||",
                "Australia_Western": "hn,0,eo,k,0,21,0,p|hn,0,eo,k,0,22,0,p||",
                "Azerbaijan": "1df,k,0,21,0,p|1df,k,0,22,0,p||",
                "Azores": "1fv,0,h3,5,hb,k,0,21,0,p|1fv,0,h3,5,hb,k,0,22,0,p||",
                "Bangladesh": "q8,k,0,21,0,p|q8,k,0,22,0,p||",
                "Bhutan": "16f|||",
                "Bolivia": "1pu|||",
                "Brasilia": "15k,k,0,21,0,p|15k,k,0,22,0,p||",
                "Brunei": "1gr,5,1o3|||",
                "Cape_Verde": "12p,5,115,k,0,21,0,p|12p,5,115,k,0,22,0,p||",
                "Casey": "2d5|||",
                "Chamorro": "1qj|||",
                "Chatham": "162,k,0,21,0,p|162,k,0,22,0,p||",
                "Chile": "150,k,0,21,0,p|150,k,0,22,0,p||",
                "China": "1dm,k,0,21,0,p|1dm,k,0,22,0,p||",
                "Choibalsan": "11e,k,0,21,0,p|11e,k,0,22,0,p||",
                "Christmas": "h3,5,qe,0,2d3|||",
                "Cocos": "2d1,0,h3,5,hb|||",
                "Colombia": "15d,k,0,21,0,p|15d,k,0,22,0,p||",
                "Cook": "rk,0,17u,k,0,21,0,p|rk,0,17u,k,0,2d0,0,p||",
                "Cuba": "ql,k,0,21,0,p|ql,k,0,22,0,p||",
                "Davis": "1q3|||",
                "DumontDUrville": "1gu,5,241|||",
                "East_Timor": "1fs,0,1ki|||",
                "Easter": "1fq,5,qe,0,170,k,0,21,0,p|1fq,5,qe,0,170,k,0,22,0,p||",
                "Ecuador": "1qk|||",
                "Europe_Central": "ii,0,lo,k,0,21,0,p|ii,0,lo,k,0,22,0,p||",
                "Europe_Eastern": "ib,0,lo,k,0,21,0,p|ib,0,lo,k,0,22,0,p||",
                "Europe_Further_Eastern": "2cr,0,p|||",
                "Europe_Western": "hn,0,lo,k,0,21,0,p|hn,0,lo,k,0,22,0,p||",
                "Falkland": "1fp,0,h3,5,hb,k,0,21,0,p|1fp,0,h3,5,hb,k,0,22,0,p||",
                "Fiji": "161,k,0,21,0,p|161,k,0,22,0,p||",
                "French_Guiana": "2cj,0,1pb|||",
                "French_Southern": "2cf,0,2dr,0,il,0,2gk,0,2ji|||",
                "Galapagos": "2av,0,h3,5,hb|||",
                "Gambier": "244|||",
                "Georgia": "15e,k,0,21,0,p|15e,k,0,22,0,p||",
                "Gilbert_Islands": "h3,5,hb,0,2dd|||",
                "GMT": "2dq,0,p,0,1jo,0,2e3|||",
                "Greenland_Eastern": "ib,0,pq,k,0,2e7,0,p|ib,0,pq,k,0,22,0,p||",
                "Greenland_Western": "hn,0,pq,k,0,21,0,p|hn,0,pq,k,0,22,0,p||",
                "Guam": "1ir|||",
                "Gulf": "2e8,0,293|||",
                "Guyana": "1ql|||",
                "Hawaii_Aleutian": "1fm,5,1fk,0,21,0,p|1fm,5,1fk,0,22,0,p||",
                "Hong_Kong": "13t,k,0,21,0,p|13t,k,0,22,0,p||",
                "Hovd": "qg,k,0,21,0,p|qg,k,0,22,0,p||",
                "India": "26o|||",
                "Indian_Ocean": "2eh,0,1ao|||",
                "Indochina": "265|||",
                "Indonesia_Central": "ii,0,vo|||",
                "Indonesia_Eastern": "ib,0,vo|||",
                "Indonesia_Western": "hn,0,vo|||",
                "Iran": "rr,k,0,21,0,p|rr,k,0,22,0,p||",
                "Irkutsk": "12u,k,0,21,0,p|12u,k,0,22,0,p||",
                "Israel": "165,k,0,21,0,p|165,k,0,22,0,p||",
                "Japan": "1bb,k,0,21,0,p|1bb,k,0,22,0,p||",
                "Kamchatka": "1d8,5,1fj,k,0,21,0,p|1d8,5,1fj,k,0,22,0,p||",
                "Kazakhstan_Eastern": "1fs,0,180|||",
                "Kazakhstan_Western": "2f4,0,180|||",
                "Korea": "15j,k,0,21,0,p|15j,k,0,22,0,p||",
                "Kosrae": "1qi|||",
                "Krasnoyarsk": "14h,k,0,21,0,p|14h,k,0,22,0,p||",
                "Kyrgystan": "2fp|||",
                "Lanka": "1if,5,1et|||",
                "Line_Islands": "h3,5,hb,0,1eo|||",
                "Lord_Howe": "oi,5,10a,k,0,21,0,p|oi,5,10a,k,0,22,0,p||",
                "Macau": "118,k,0,21,0,p|118,k,0,22,0,p||",
                "Macquarie": "1l5|||",
                "Magadan": "qq,k,0,21,0,p|qq,k,0,22,0,p||",
                "Malaysia": "1pp|||",
                "Maldives": "2g7|||",
                "Marquesas": "2ga,0,h3,5,hb|||",
                "Marshall_Islands": "2h8,0,rk|||",
                "Mauritius": "15t,k,0,21,0,p|15t,k,0,22,0,p||",
                "Mawson": "1lt|||",
                "Mexico_Northwest": "1fg,5,11n,0,11r,0,21,0,p|1fg,5,11n,0,11r,0,22,0,p||",
                "Mexico_Pacific": "11b,0,11r,0,21,0,p|11b,0,11r,0,22,0,p||",
                "Mongolia": "or,5,qo,k,0,21,0,p|or,5,qo,k,0,22,0,p||",
                "Moscow": "r6,k,0,21,0,p|r6,k,0,22,0,p||",
                "Myanmar": "1qm|||",
                "Nauru": "14s|||",
                "Nepal": "16d|||",
                "New_Caledonia": "rl,0,15l,k,0,21,0,p|rl,0,15l,k,0,22,0,p||",
                "New_Zealand": "rl,0,15h,k,0,21,0,p|rl,0,15h,k,0,22,0,p||",
                "Newfoundland": "14t,k,0,21,0,p|14t,k,0,22,0,p||",
                "Niue": "1qn|||",
                "Norfolk": "146|||",
                "Noronha": "141,5,17m,5,132,k,0,21,0,p|141,5,17m,5,132,k,0,22,0,p||",
                "North_Mariana": "2hb,0,2i4,0,h3,5,hb|||",
                "Novosibirsk": "qt,k,0,21,0,p|qt,k,0,22,0,p||",
                "Omsk": "r5,k,0,21,0,p|r5,k,0,22,0,p||",
                "Pakistan": "rq,k,0,21,0,p|rq,k,0,22,0,p||",
                "Palau": "159|||",
                "Papua_New_Guinea": "13k,0,kf,0,rl,0,1m5|||",
                "Paraguay": "13p,k,0,21,0,p|13p,k,0,22,0,p||",
                "Peru": "q1,k,0,21,0,p|q1,k,0,22,0,p||",
                "Philippines": "1cf,k,0,21,0,p|1cf,k,0,22,0,p||",
                "Phoenix_Islands": "h3,5,hb,0,158|||",
                "Pierre_Miquelon": "pr,5,13m,0,il,0,qp,k,0,21,0,p|pr,5,13m,0,il,0,qp,k,0,22,0,p||",
                "Pitcairn": "24j|||",
                "Ponape": "2ia|||",
                "Pyongyang": "1u8|||",
                "Qyzylorda": "1ff,k,0,21,0,1fe|1ff,k,0,22,0,1fe||",
                "Reunion": "1qo|||",
                "Rothera": "157|||",
                "Sakhalin": "r8,k,0,21,0,p|r8,k,0,22,0,p||",
                "Samara": "1fd,0,21,0,p|1fd,0,22,0,p||",
                "Samoa": "p0,k,0,21,0,p|p0,k,0,22,0,p||",
                "Seychelles": "2im,0,rk|||",
                "Singapore": "16b|||",
                "Solomon": "2is,0,rk|||",
                "South_Georgia": "1gi,0,261|||",
                "Suriname": "18v|||",
                "Syowa": "1pr|||",
                "Tahiti": "1ma|||",
                "Taipei": "1a9,k,0,21,0,p|1a9,k,0,22,0,p||",
                "Tajikistan": "264|||",
                "Tokelau": "156|||",
                "Tonga": "ot,k,0,21,0,p|ot,k,0,22,0,p||",
                "Truk": "26d|||",
                "Turkmenistan": "1fc,k,0,21,0,p|1fc,k,0,22,0,p||",
                "Tuvalu": "14j|||",
                "Uruguay": "14a,k,0,21,0,p|14a,k,0,22,0,p||",
                "Uzbekistan": "108,k,0,21,0,p|108,k,0,22,0,p||",
                "Vanuatu": "os,k,0,21,0,p|os,k,0,22,0,p||",
                "Venezuela": "1mf|||",
                "Vladivostok": "qn,k,0,21,0,p|qn,k,0,22,0,p||",
                "Volgograd": "q9,k,0,21,0,p|q9,k,0,22,0,p||",
                "Vostok": "155|||",
                "Wake": "1m0|||",
                "Wallis": "1qq,0,il,0,14l|||",
                "Yakutsk": "14d,k,0,21,0,p|14d,k,0,22,0,p||",
                "Yekaterinburg": "136,k,0,21,0,p|136,k,0,22,0,p||"
            }
        },
        "rw": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "rwk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "sah": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Arabian": "797,0,10m,0,m7|797,0,122,0,m7||",
                "Armenia": "793,0,10m,0,m7|793,0,122,0,m7||",
                "Australia_Central": "792,0,eo,0,10m,0,m7|792,0,eo,0,122,0,m7||",
                "Australia_Eastern": "4ih,0,eo,0,10m,0,m7|4ih,0,eo,0,122,0,m7||",
                "Australia_Western": "4ii,0,eo,0,10m,0,m7|4ii,0,eo,0,122,0,m7||",
                "China": "452,0,10m,0,m7|452,0,122,0,m7||",
                "Choibalsan": "11e,0,10m,0,m7|11e,0,122,0,m7||",
                "Georgia": "78v,0,10m,0,m7|78v,0,122,0,m7||",
                "India": "991,0,10m,0,m7|||",
                "Iran": "992,0,10m,0,m7|993,0,122,0,m7||",
                "Japan": "78u,0,10m,0,m7|78u,0,122,0,m7||",
                "Kazakhstan_Eastern": "4ih,0,78t,0,m7|||",
                "Kazakhstan_Western": "4ii,0,78t,0,m7|||",
                "Korea": "78s,0,10m,0,m7|78s,0,122,0,m7||",
                "Krasnoyarsk": "78r,0,10m,0,m7|78r,0,122,0,m7||",
                "Kyrgystan": "998,0,m7|||",
                "Magadan": "78q,0,10m,0,m7|78q,0,122,0,m7||",
                "Mongolia": "or,0,78p,0,10m,0,m7|or,0,78p,0,122,0,m7||",
                "Moscow": "78o,0,10m,0,m7|78o,0,122,0,m7||",
                "New_Zealand": "78n,0,78m,0,10m,0,m7|78n,0,78m,0,122,0,m7||",
                "Novosibirsk": "78l,0,10m,0,m7|78l,0,122,0,m7||",
                "Omsk": "78k,0,10m,0,m7|78k,0,122,0,m7||",
                "Pakistan": "78j,0,10m,0,m7|78j,0,122,0,m7||",
                "Sakhalin": "78i,0,10m,0,m7|78i,0,122,0,m7||",
                "Vladivostok": "78h,0,10m,0,m7|78h,0,122,0,m7||",
                "Volgograd": "q9,0,10m,0,m7|q9,0,122,0,m7||",
                "Yakutsk": "78g,0,10m,0,m7|78g,0,122,0,m7||",
                "Yekaterinburg": "78f,0,10m,0,m7|78f,0,122,0,m7||"
            }
        },
        "saq": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "sbp": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "se-FI": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {},
            "metazone": {
                "Europe_Central": "2iu,5,15b,0,1fb|2iu,5,15b,0,1fa|h0|h4",
                "Europe_Eastern": "2iv,5,15b,0,1fb|2iv,5,15b,0,1fa|gu|gv",
                "Europe_Western": "2j4,5,15b,0,1fb|2j4,5,15b,0,1fa|h1|h2",
                "GMT": "5u,0,4ij,0,4ik||ah|",
                "Moscow": "pi,5,1fb|pi,5,1fa||"
            }
        },
        "se-SE": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {},
            "metazone": {
                "Europe_Central": "2iu,5,15b,0,1fb|2iu,5,15b,0,1fa|h0|h4",
                "Europe_Eastern": "2iv,5,15b,0,1fb|2iv,5,15b,0,1fa|gu|gv",
                "Europe_Western": "2j4,5,15b,0,1fb|2j4,5,15b,0,1fa|h1|h2",
                "GMT": "5u,0,4ij,0,4ik||ah|",
                "Moscow": "pi,5,1fb|pi,5,1fa||"
            }
        },
        "se": {
            "gmtFormat": "UTC{0}",
            "gmtZeroFormat": "UTC",
            "zone": {},
            "metazone": {
                "Europe_Central": "2iu,5,15b,0,1fb|2iu,5,15b,0,1fa|h0|h4",
                "Europe_Eastern": "2iv,5,15b,0,1fb|2iv,5,15b,0,1fa|gu|gv",
                "Europe_Western": "2j4,5,15b,0,1fb|2j4,5,15b,0,1fa|h1|h2",
                "GMT": "5u,0,4ij,0,4ik||ah|",
                "Moscow": "pi,5,1fb|pi,5,1fa||"
            }
        },
        "seh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ses": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "sg": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "shi-Latn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "shi-Tfng": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "shi": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "si": {
            "gmtFormat": "ග්‍රිමවේ{0}",
            "gmtZeroFormat": "ග්‍රිමවේ",
            "zone": {
                "Europe/Dublin": "|cct,0,4im,0,12o||",
                "Europe/London": "|ccu,0,4im,0,12o||"
            },
            "metazone": {
                "Afghanistan": "99t,0,8f|||",
                "Africa_Central": "1p6,0,2n3,0,8f|||",
                "Africa_Eastern": "1f9,0,2n3,0,8f|||",
                "Africa_Southern": "782,0,2n3,0,8f|||",
                "Africa_Western": "1dq,0,2n3,0,gj,0,8f|1dq,0,2n3,0,ml,0,12o||",
                "Alaska": "77r,0,gj,0,8f|77r,0,1f8,0,8f||",
                "Amazon": "77p,0,gj,0,8f|77p,0,ml,0,12o||",
                "America_Central": "240,0,23v,0,1p6,0,gj,0,8f|240,0,23v,0,1p6,0,1f8,0,8f||",
                "America_Eastern": "240,0,23v,0,1f9,0,gj,0,8f|240,0,23v,0,1f9,0,1f8,0,8f||",
                "America_Mountain": "240,0,23v,0,77m,0,gj,0,8f|240,0,23v,0,77m,0,1f8,0,8f||",
                "America_Pacific": "240,0,23v,0,462,0,gj,0,8f|240,0,23v,0,462,0,1f8,0,8f||",
                "Apia": "77k,0,gj,0,8f|77k,0,2nd,0,8f||",
                "Arabian": "77i,0,gj,0,8f|77i,0,1nm,0,8f||",
                "Argentina": "45v,0,gj,0,8f|45v,0,ml,0,12o||",
                "Argentina_Western": "1dq,0,45v,0,gj,0,8f|1dq,0,45v,0,ml,0,12o||",
                "Armenia": "77f,0,gj,0,8f|77f,0,ml,0,8f||",
                "Atlantic": "77e,0,gj,0,8f|77e,0,1f8,0,8f||",
                "Australia_Central": "45u,0,gj,0,8f|1p6,0,45t,0,1nm,0,8f||",
                "Australia_CentralWestern": "1p6,0,1dq,0,45t,0,gj,0,8f|1p6,0,1dq,0,45t,0,1nm,0,8f||",
                "Australia_Eastern": "1f9,0,45u,0,gj,0,8f|9an,0,45u,0,1nm,0,8f||",
                "Australia_Western": "1dq,0,45u,0,gj,0,8f|1dq,0,45t,0,1nm,0,8f||",
                "Azerbaijan": "77b,0,gj,0,8f|77b,0,ml,0,8f||",
                "Azores": "77a,0,gj,0,8f|77a,0,ml,0,8f||",
                "Bangladesh": "779,0,gj,0,8f|779,0,ml,0,12o||",
                "Bhutan": "9ar,0,8f|||",
                "Bolivia": "9as,0,8f|||",
                "Brasilia": "778,0,gj,0,8f|778,0,ml,0,12o||",
                "Brunei": "9au,0,9av,0,8f|||",
                "Cape_Verde": "777,0,gj,0,8f|777,0,ml,0,12o||",
                "Chamorro": "9b1,0,8f|||",
                "Chatham": "776,0,gj,0,8f|776,0,2nd,0,8f||",
                "Chile": "775,0,gj,0,8f|775,0,ml,0,12o||",
                "China": "774,0,gj,0,8f|774,0,1nm,0,8f||",
                "Choibalsan": "773,0,gj,0,8f|773,0,ml,0,8f||",
                "Christmas": "9b6,0,1l2,0,8f|||",
                "Cocos": "9b8,0,1l2,0,8f|||",
                "Colombia": "771,0,gj,0,8f|771,0,ml,0,12o||",
                "Cook": "770,0,1l2,0,gj,0,8f|770,0,1l2,0,9bb,0,ml,0,8f||",
                "Cuba": "76v,0,gj,0,8f|76v,0,1f8,0,8f||",
                "Davis": "9bd,0,8f|||",
                "DumontDUrville": "9be,5,9bf,0,8f|||",
                "East_Timor": "1f9,0,9bg,0,8f|||",
                "Easter": "76u,0,2j7,0,gj,0,8f|76u,0,2j7,0,ml,0,12o||",
                "Ecuador": "9bj,0,8f|||",
                "Europe_Central": "1p6,0,266,0,gj,0,8f|1p6,0,266,0,ml,0,8f||",
                "Europe_Eastern": "1f9,0,266,0,gj,0,8f|1f9,0,266,0,ml,0,8f||",
                "Europe_Further_Eastern": "9bl,5,1f9,0,266,0,8f|||",
                "Europe_Western": "1dq,0,266,0,gj,0,8f|1dq,0,266,0,ml,0,8f||",
                "Falkland": "76r,0,2j7,0,gj,0,8f|76r,0,2j7,0,ml,0,12o||",
                "Fiji": "76q,0,gj,0,8f|76q,0,ml,0,8f||",
                "French_Guiana": "76p,0,76o,0,8f|||",
                "French_Southern": "76p,0,9bq,0,45p,0,9bs,0,8f|||",
                "Galapagos": "9bt,0,8f|||",
                "Gambier": "9bu,0,8f|||",
                "Georgia": "76m,0,gj,0,8f|76m,0,ml,0,8f||",
                "Gilbert_Islands": "9c0,0,1l2,0,8f|||",
                "GMT": "9c1,0,1p6,0,8f|||",
                "Greenland_Eastern": "1f9,0,45o,0,gj,0,8f|1f9,0,45o,0,ml,0,12o||",
                "Greenland_Western": "1dq,0,45o,0,gj,0,8f|1dq,0,45o,0,ml,0,12o||",
                "Gulf": "9c3,0,8f|||",
                "Guyana": "76o,0,8f|||",
                "Hawaii_Aleutian": "76k,5,76j,0,gj,0,8f|76k,5,76j,0,1f8,0,8f||",
                "Hong_Kong": "76i,0,gj,0,8f|76i,0,ml,0,8f||",
                "Hovd": "76h,0,gj,0,8f|76h,0,ml,0,8f||",
                "India": "9c8,0,8f|||",
                "Indian_Ocean": "9c9,0,9ca,0,8f|||",
                "Indochina": "9cb,0,8f|||",
                "Indonesia_Central": "1p6,0,4il,0,8f|||",
                "Indonesia_Eastern": "1f9,0,4il,0,8f|||",
                "Indonesia_Western": "1dq,0,4il,0,8f|||",
                "Iran": "76f,0,gj,0,8f|76f,0,2nd,0,12o||",
                "Irkutsk": "76e,0,gj,0,8f|76e,0,ml,0,8f||",
                "Israel": "76c,0,gj,0,8f|76c,0,1nm,0,8f||",
                "Japan": "76b,0,gj,0,8f|76b,0,1nm,0,8f||",
                "Kazakhstan_Eastern": "1f9,0,769,0,8f|||",
                "Kazakhstan_Western": "1dq,0,769,0,8f|||",
                "Korea": "768,0,gj,0,8f|768,0,1nm,0,8f||",
                "Kosrae": "9cj,0,8f|||",
                "Krasnoyarsk": "766,0,gj,0,8f|766,0,ml,0,8f||",
                "Kyrgystan": "9cl,0,8f|||",
                "Lanka": "9cm,0,9cn,0,8f|||",
                "Line_Islands": "9co,0,1l2,0,8f|||",
                "Lord_Howe": "765,0,764,0,gj,0,8f|765,0,764,0,2nd,0,8f||",
                "Macquarie": "9cr,0,1l2,0,8f|||",
                "Magadan": "763,0,gj,0,8f|763,0,ml,0,8f||",
                "Malaysia": "9ct,0,8f|||",
                "Maldives": "9cu,0,8f|||",
                "Marquesas": "9cv,0,8f|||",
                "Marshall_Islands": "9d0,0,1l2,0,8f|||",
                "Mauritius": "762,0,gj,0,8f|762,0,ml,0,12o||",
                "Mawson": "9d2,0,8f|||",
                "Mexico_Northwest": "75u,0,45l,0,gj,0,8f|75u,0,45l,0,1f8,0,8f||",
                "Mexico_Pacific": "45l,0,462,0,gj,0,8f|45l,0,462,0,1f8,0,8f||",
                "Mongolia": "75o,0,75k,0,gj,0,8f|75o,0,75k,0,ml,0,8f||",
                "Moscow": "75j,0,gj,0,8f|75j,0,ml,0,8f||",
                "Myanmar": "9d8,0,8f|||",
                "Nauru": "9d9,0,8f|||",
                "Nepal": "9da,0,8f|||",
                "New_Caledonia": "75i,0,75h,0,gj,0,8f|75i,0,75h,0,ml,0,8f||",
                "New_Zealand": "75f,0,gj,0,8f|75f,0,2nd,0,8f||",
                "Newfoundland": "75e,0,gj,0,8f|75e,0,1f8,0,8f||",
                "Niue": "9df,0,8f|||",
                "Norfolk": "9dg,0,1l2,0,8f|||",
                "Noronha": "75a,0,759,0,758,0,gj,0,8f|75a,0,759,0,758,0,ml,0,12o||",
                "Novosibirsk": "757,0,gj,0,8f|757,0,ml,0,8f||",
                "Omsk": "756,0,gj,0,8f|756,0,ml,0,8f||",
                "Pakistan": "755,0,gj,0,8f|755,0,ml,0,12o||",
                "Palau": "9dn,0,8f|||",
                "Papua_New_Guinea": "9do,0,9dp,0,9dq,0,8f|||",
                "Paraguay": "754,0,gj,0,8f|754,0,ml,0,12o||",
                "Peru": "752,0,gj,0,8f|752,0,ml,0,12o||",
                "Philippines": "751,0,gj,0,8f|751,0,ml,0,8f||",
                "Phoenix_Islands": "9du,0,1l2,0,8f|||",
                "Pierre_Miquelon": "74r,0,74m,0,45p,0,74l,0,gj,0,8f|74r,0,74m,0,45p,0,74l,0,1f8,0,8f||",
                "Pitcairn": "9e2,0,8f|||",
                "Ponape": "9e3,0,8f|||",
                "Pyongyang": "9e4,0,8f|||",
                "Reunion": "9e5,0,8f|||",
                "Rothera": "9e6,0,8f|||",
                "Sakhalin": "74i,0,gj,0,8f|74i,0,ml,0,8f||",
                "Samoa": "74h,0,gj,0,8f|74h,0,ml,0,8f||",
                "Seychelles": "9e9,0,8f|||",
                "Singapore": "9ea,0,8f|||",
                "Solomon": "9eb,0,2j7,0,8f|||",
                "South_Georgia": "782,0,9ec,0,8f|||",
                "Suriname": "9ed,0,8f|||",
                "Syowa": "9ee,0,8f|||",
                "Tahiti": "9ef,0,8f|||",
                "Taipei": "74g,0,gj,0,8f|74g,0,1nm,0,8f||",
                "Tajikistan": "9eh,0,8f|||",
                "Tokelau": "9ei,0,8f|||",
                "Tonga": "745,0,gj,0,8f|745,0,ml,0,8f||",
                "Truk": "9ek,0,8f|||",
                "Turkmenistan": "744,0,gj,0,8f|744,0,ml,0,8f||",
                "Tuvalu": "9em,0,8f|||",
                "Uruguay": "743,0,gj,0,8f|743,0,ml,0,12o||",
                "Uzbekistan": "73u,0,gj,0,8f|73u,0,ml,0,8f||",
                "Vanuatu": "73s,0,gj,0,8f|73s,0,4im,0,8f||",
                "Venezuela": "9er,0,8f|||",
                "Vladivostok": "73q,0,gj,0,8f|73q,0,ml,0,8f||",
                "Volgograd": "73o,0,gj,0,8f|73o,0,ml,0,8f||",
                "Vostok": "9eu,0,8f|||",
                "Wake": "9ev,0,2j7,0,8f|||",
                "Wallis": "9f0,0,45p,0,9f1,0,8f|||",
                "Yakutsk": "73k,0,gj,0,8f|73k,0,ml,0,8f||",
                "Yekaterinburg": "73h,0,gj,0,8f|73h,0,ml,0,8f||"
            }
        },
        "sk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ccv,0,f8,0,11||",
                "Europe/London": "|cd0,0,fp,0,11||"
            },
            "metazone": {
                "Afghanistan": "9f4,0,11|||",
                "Africa_Central": "9f5,0,11|||",
                "Africa_Eastern": "9f6,0,11|||",
                "Africa_Southern": "9f7,0,11|||",
                "Africa_Western": "73d,0,f8,0,11|73d,0,fp,0,11||",
                "Alaska": "738,0,f8,0,11|738,0,fp,0,11||",
                "Amazon": "737,0,f8,0,11|737,0,fp,0,11||",
                "America_Central": "23c,0,733,0,f8,0,11|23c,0,733,0,fp,0,11||",
                "America_Eastern": "23c,0,732,0,f8,0,11|23c,0,732,0,fp,0,11||",
                "America_Mountain": "23c,0,4a1,0,f8,0,11|23c,0,4a1,0,fp,0,11||",
                "America_Pacific": "23c,0,455,0,f8,0,11|23c,0,455,0,fp,0,11||",
                "Anadyr": "49r,0,f8,0,11|49r,0,fp,0,11||",
                "Apia": "72n,0,f8,0,11|72n,0,fp,0,11||",
                "Arabian": "724,0,f8,0,11|724,0,fp,0,11||",
                "Argentina": "723,0,f8,0,11|723,0,fp,0,11||",
                "Argentina_Western": "721,0,f8,0,11|721,0,fp,0,11||",
                "Armenia": "720,0,f8,0,11|720,0,fp,0,11||",
                "Atlantic": "71v,0,f8,0,11|71v,0,fp,0,11||",
                "Australia_Central": "71h,0,f8,0,11|71h,0,fp,0,11||",
                "Australia_CentralWestern": "71d,0,71c,0,f8,0,11|71d,0,71c,0,fp,0,11||",
                "Australia_Eastern": "71b,0,f8,0,11|71b,0,fp,0,11||",
                "Australia_Western": "71a,0,f8,0,11|71a,0,fp,0,11||",
                "Azerbaijan": "719,0,f8,0,11|719,0,fp,0,11||",
                "Azores": "718,0,f8,0,11|718,0,fp,0,11||",
                "Bangladesh": "717,0,f8,0,11|717,0,fp,0,11||",
                "Bhutan": "9fv,0,11|||",
                "Bolivia": "9g0,0,11|||",
                "Brasilia": "716,0,f8,0,11|716,0,fp,0,11||",
                "Brunei": "9g2,0,11|||",
                "Cape_Verde": "713,0,f8,0,11|713,0,fp,0,11||",
                "Chamorro": "9g4,0,f8,0,11|||",
                "Chatham": "711,0,f8,0,11|711,0,fp,0,11||",
                "Chile": "710,0,f8,0,11|710,0,fp,0,11||",
                "China": "70u,0,f8,0,11|70u,0,fp,0,11||",
                "Choibalsan": "70s,0,f8,0,11|70s,0,fp,0,11||",
                "Christmas": "11,0,9g9,0,19g|||",
                "Cocos": "11,0,5ve,0,1mb|||",
                "Colombia": "70p,0,f8,0,11|70p,0,fp,0,11||",
                "Cook": "f8,0,11,0,49j,0,1mb|fp,0,11,0,49j,0,1mb||",
                "Cuba": "70o,0,f8,0,11|70o,0,fp,0,11||",
                "Davis": "11,0,9gd,0,10j|||",
                "DumontDUrville": "11,0,10j,0,5vo,0,4s0|||",
                "East_Timor": "9ge,0,11|||",
                "Easter": "f8,0,11,0,70m,0,19g|fp,0,11,0,70m,0,19g||",
                "Ecuador": "9gg,0,11|||",
                "Europe_Central": "70l,0,f8,0,11|70l,0,fp,0,11|5vu|606",
                "Europe_Eastern": "70k,0,f8,0,11|70k,0,fp,0,11||",
                "Europe_Further_Eastern": "9gj,0,11|||",
                "Europe_Western": "70j,0,f8,0,11|70j,0,fp,0,11||",
                "Falkland": "70i,0,f8,0,11|70i,0,fp,0,11||",
                "Fiji": "70h,0,f8,0,11|70h,0,fp,0,11||",
                "French_Guiana": "9gn,0,11|||",
                "French_Southern": "11,0,9go,0,9gp,0,a6,0,61p,0,61s|||",
                "Galapagos": "9gq,0,11|||",
                "Gambier": "9gr,0,11|||",
                "Georgia": "70g,0,f8,0,11|70g,0,fp,0,11||",
                "Gilbert_Islands": "11,0,62i,0,1mb|||",
                "GMT": "9gt,0,11|||",
                "Greenland_Eastern": "70c,0,f8,0,11|70c,0,fp,0,11||",
                "Greenland_Western": "70b,0,f8,0,11|70b,0,fp,0,11||",
                "Gulf": "f8,0,11,0,9h0,0,63a|||",
                "Guyana": "9h1,0,11|||",
                "Hawaii_Aleutian": "2l5,5,48l,0,f8,0,11|2l5,5,48l,0,fp,0,11||",
                "Hong_Kong": "709,0,f8,0,11|709,0,fp,0,11||",
                "Hovd": "708,0,f8,0,11|708,0,fp,0,11||",
                "India": "9h4,0,11|||",
                "Indian_Ocean": "9h5,0,11|||",
                "Indochina": "9h6,0,11|||",
                "Indonesia_Central": "9h7,0,11|||",
                "Indonesia_Eastern": "9h8,0,11|||",
                "Indonesia_Western": "9h9,0,11|||",
                "Iran": "704,0,f8,0,11|704,0,fp,0,11||",
                "Irkutsk": "700,0,f8,0,11|700,0,fp,0,11||",
                "Israel": "6vv,0,f8,0,11|6vv,0,fp,0,11||",
                "Japan": "6vu,0,f8,0,11|6vu,0,fp,0,11||",
                "Kamchatka": "37,5,9he,0,f8,0,11|37,5,9hf,0,fp,0,11||",
                "Kazakhstan_Eastern": "9hg,0,11|||",
                "Kazakhstan_Western": "9hh,0,11|||",
                "Korea": "6vq,0,f8,0,11|6vq,0,fp,0,11||",
                "Kosrae": "9hj,0,11|||",
                "Krasnoyarsk": "6vn,0,f8,0,11|6vn,0,fp,0,11||",
                "Kyrgystan": "9hl,0,11|||",
                "Line_Islands": "11,0,64l,0,1mb|||",
                "Lord_Howe": "f8,0,11,0,19g,0,48a,0,489|fp,0,11,0,19g,0,48a,0,489||",
                "Macquarie": "11,0,19g,0,6m|||",
                "Magadan": "6vm,0,f8,0,11|6vm,0,fp,0,11||",
                "Malaysia": "9hn,0,11|||",
                "Maldives": "9ho,0,11|||",
                "Marquesas": "9hp,0,11|||",
                "Marshall_Islands": "11,0,65h,0,1mb|||",
                "Mauritius": "6vl,0,f8,0,11|6vl,0,fp,0,11||",
                "Mawson": "11,0,9hr,0,10j|||",
                "Mexico_Northwest": "6vk,0,2d6,0,f8,0,11|6vk,0,2d6,0,fp,0,11||",
                "Mexico_Pacific": "2d6,0,455,0,f8,0,11|2d6,0,455,0,fp,0,11||",
                "Mongolia": "6vj,0,f8,0,11|6vj,0,fp,0,11||",
                "Moscow": "6vg,0,f8,0,11|6vg,0,fp,0,11||",
                "Myanmar": "9hv,0,11|||",
                "Nauru": "9i0,0,11|||",
                "Nepal": "9i1,0,11|||",
                "New_Caledonia": "6vf,0,f8,0,11|6vf,0,fp,0,11||",
                "New_Zealand": "6vd,0,f8,0,11|6vd,0,fp,0,11||",
                "Newfoundland": "6vc,0,f8,0,11|6vc,0,fp,0,11||",
                "Niue": "9i5,0,11|||",
                "Norfolk": "9i6,0,11|||",
                "Noronha": "f8,0,11,0,6vb,0,1s,0,2,0,23|fp,0,11,0,6vb,0,1s,0,2,0,23||",
                "Novosibirsk": "6v8,0,f8,0,11|6v8,0,fp,0,11||",
                "Omsk": "6v6,0,f8,0,11|6v6,0,fp,0,11||",
                "Pakistan": "6v5,0,f8,0,11|6v5,0,fp,0,11||",
                "Palau": "9ib,0,11|||",
                "Papua_New_Guinea": "11,0,678,5,9ic,0,67f|||",
                "Paraguay": "6v4,0,f8,0,11|6v4,0,fp,0,11||",
                "Peru": "6v3,0,f8,0,11|6v3,0,fp,0,11||",
                "Philippines": "6uv,0,f8,0,11|6uv,0,fp,0,11||",
                "Phoenix_Islands": "11,0,67s,0,1mb|||",
                "Pierre_Miquelon": "6ut,5,47t,0,f8,0,11|6ut,5,47t,0,fp,0,11||",
                "Pitcairn": "11,0,9ih,0,1mb|||",
                "Ponape": "9ii,0,11|||",
                "Pyongyang": "9ij,0,11|||",
                "Reunion": "9ik,0,11|||",
                "Rothera": "11,0,9il,0,10j|||",
                "Sakhalin": "6us,0,f8,0,11|6us,0,fp,0,11||",
                "Samara": "47p,0,f8,0,11|47p,0,fp,0,11||",
                "Samoa": "6ur,0,f8,0,11|6ur,0,fp,0,11||",
                "Seychelles": "9io,0,11|||",
                "Singapore": "9ip,0,f8,0,11|||",
                "Solomon": "11,0,9iq,0,1mb|||",
                "South_Georgia": "11,0,9ir,0,68o|||",
                "Suriname": "9is,0,11|||",
                "Syowa": "11,0,10j,0,68s|||",
                "Tahiti": "9it,0,11|||",
                "Taipei": "6up,0,f8,0,11|6up,0,fp,0,11||",
                "Tajikistan": "9iv,0,11|||",
                "Tokelau": "9j0,0,11|||",
                "Tonga": "6uh,0,f8,0,11|6uh,0,fp,0,11||",
                "Truk": "9j2,0,11|||",
                "Turkmenistan": "6ug,0,f8,0,11|6ug,0,fp,0,11||",
                "Tuvalu": "9j4,0,11|||",
                "Uruguay": "6ue,0,f8,0,11|6ue,0,fp,0,11||",
                "Uzbekistan": "6ud,0,f8,0,11|6ud,0,fp,0,11||",
                "Vanuatu": "6uc,0,f8,0,11|6uc,0,fp,0,11||",
                "Venezuela": "9j8,0,11|||",
                "Vladivostok": "6ub,0,f8,0,11|6ub,0,fp,0,11||",
                "Volgograd": "6ua,0,f8,0,11|6ua,0,fp,0,11||",
                "Vostok": "11,0,10j,0,5t|||",
                "Wake": "11,0,19g,0,5r|||",
                "Wallis": "11,0,1mb,0,5f,0,a6,0,5k|||",
                "Yakutsk": "6u8,0,f8,0,11|6u8,0,fp,0,11||",
                "Yekaterinburg": "6u6,0,f8,0,11|6u6,0,fp,0,11||"
            }
        },
        "sl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cd1,0,fu,0,11||",
                "Europe/London": "|cd2,0,gq,0,11||"
            },
            "metazone": {
                "Afghanistan": "9jd,0,11|||",
                "Africa_Central": "9je,0,11|||",
                "Africa_Eastern": "9jf,0,11|||",
                "Africa_Southern": "9jg,0,11|||",
                "Africa_Western": "6u3,0,fu,0,11|6u3,0,gq,0,11||",
                "Alaska": "6tt,0,fu,0,11|6tt,0,gq,0,11||",
                "Amazon": "6ts,0,fu,0,11|6ts,0,gq,0,11||",
                "America_Central": "6tr,0,fu,0,11|6tr,0,gq,0,11||",
                "America_Eastern": "4iv,0,fu,0,11|4iv,0,gq,0,11||",
                "America_Mountain": "6tn,0,fu,0,11|6tn,0,gq,0,11||",
                "America_Pacific": "6tm,0,fu,0,11|6tm,0,gq,0,11||",
                "Anadyr": "6tl,0,fu,0,11|6tl,0,gq,0,11||",
                "Apia": "2u0,0,1v1,0,3e|2u2,0,1v1,0,3e||",
                "Arabian": "4e1,0,fu,0,11|4e1,0,gq,0,11||",
                "Argentina": "2ed,0,fu,0,11|2ed,0,gq,0,11||",
                "Argentina_Western": "2ed,0,290,0,fu,0,11|2ed,0,290,0,gq,0,11||",
                "Armenia": "6tc,0,fu,0,11|6tc,0,gq,0,11||",
                "Atlantic": "6tb,0,fu,0,11|6tb,0,gq,0,11||",
                "Australia_Central": "1us,0,43k,0,fu,0,11|1us,0,43k,0,gq,0,11||",
                "Australia_CentralWestern": "1us,0,43k,0,290,0,fu,0,11|1us,0,43k,0,290,0,gq,0,11||",
                "Australia_Eastern": "1us,0,4j0,0,fu,0,11|1us,0,4j0,0,gq,0,11||",
                "Australia_Western": "1us,0,290,0,fu,0,11|1us,0,290,0,gq,0,11||",
                "Azerbaijan": "6t5,0,fu,0,11|6t5,0,gq,0,11||",
                "Azores": "6t1,0,fu,0,11|6t1,0,gq,0,11||",
                "Bangladesh": "6t0,0,fu,0,11|6t0,0,gq,0,11||",
                "Bhutan": "9k7,0,11|||",
                "Bolivia": "9k8,0,11|||",
                "Brasilia": "6ss,0,fu,0,11|6ss,0,gq,0,11||",
                "Brunei": "9ka,0,11|||",
                "Cape_Verde": "44b,0,fu,0,11|44b,0,gq,0,11||",
                "Chamorro": "9kb,0,fu,0,11|||",
                "Chatham": "6sr,0,fu,0,11|6sr,0,gq,0,11||",
                "Chile": "6sq,0,fu,0,11|6sq,0,gq,0,11||",
                "China": "6sp,0,fu,0,11|6sp,0,gq,0,11||",
                "Choibalsan": "32p,0,fu,0,11|32p,0,gq,0,11||",
                "Christmas": "9kf,0,11|||",
                "Cocos": "1ek,0,9kh,0,2ub|||",
                "Colombia": "6sk,0,fu,0,11|6sk,0,gq,0,11||",
                "Cook": "6sj,0,fu,0,11|6sj,0,9kl,0,11||",
                "Cuba": "6sh,0,fu,0,11|6sh,0,gq,0,11||",
                "Davis": "1ek,0,5s|||",
                "DumontDUrville": "1ek,0,5b,5,5j|||",
                "East_Timor": "9kn,0,11|||",
                "Easter": "2u0,0,1v1,0,6se,0,6sd|2u2,0,1v1,0,6se,0,6sd||",
                "Ecuador": "9kq,0,11|||",
                "Europe_Central": "6s4,0,fu,0,11|6s4,0,gq,0,11||",
                "Europe_Eastern": "6s3,0,fu,0,11|6s3,0,gq,0,11||",
                "Europe_Further_Eastern": "9kt,0,9ku,0,11|||",
                "Europe_Western": "6rn,0,fu,0,11|6rn,0,gq,0,11||",
                "Falkland": "2u0,0,1v1,0,6m9,0,33l|2u2,0,1v1,0,6m9,0,33l||",
                "Fiji": "6m8,0,fu,0,11|6m8,0,gq,0,11||",
                "French_Guiana": "1ek,0,9l2,0,1pt|||",
                "French_Southern": "9l4,0,9l5,0,400,0,9l7,0,11|||",
                "Galapagos": "9l8,0,11|||",
                "Gambier": "7je,0,11|||",
                "Georgia": "6m4,0,fu,0,11|6m4,0,gq,0,11||",
                "Gilbert_Islands": "1ek,0,9la,0,2ub|||",
                "GMT": "9lb,0,9lc,0,11|||",
                "Greenland_Eastern": "6m3,0,fu,0,11|6m3,0,gq,0,11||",
                "Greenland_Western": "6m0,0,fu,0,11|6m0,0,gq,0,11||",
                "Gulf": "9lf,0,fu,0,11|||",
                "Guyana": "9lg,0,11|||",
                "Hawaii_Aleutian": "6ls,0,2eq,0,fu,0,11|6ls,0,2eq,0,gq,0,11||",
                "Hong_Kong": "6lq,0,fu,0,11|6lq,0,gq,0,11||",
                "Hovd": "6lo,0,fu,0,11|6lo,0,gq,0,11||",
                "India": "9lk,0,fu,0,11|||",
                "Indian_Ocean": "9ll,0,11|||",
                "Indochina": "9lm,0,11|||",
                "Indonesia_Central": "4j3,0,9lo,0,11|||",
                "Indonesia_Eastern": "4j3,0,4j0,0,11|||",
                "Indonesia_Western": "4j3,0,290,0,11|||",
                "Iran": "6ll,0,fu,0,11|6ll,0,gq,0,11||",
                "Irkutsk": "1uo,0,fu,0,11|1uo,0,gq,0,11||",
                "Israel": "6lk,0,fu,0,11|6lk,0,gq,0,11||",
                "Japan": "6lj,0,fu,0,11|6lj,0,gq,0,11||",
                "Kamchatka": "37,5,2c4,0,fu,0,11|37,5,2c4,0,gq,0,11||",
                "Kazakhstan_Eastern": "4iv,0,6li,0,11|||",
                "Kazakhstan_Western": "9lt,0,6li,0,11|||",
                "Korea": "43a,0,fu,0,11|43a,0,gq,0,11||",
                "Kosrae": "9lu,0,11|||",
                "Krasnoyarsk": "1un,0,fu,0,11|1un,0,gq,0,11||",
                "Kyrgystan": "9lv,0,11|||",
                "Line_Islands": "9m0,0,9m1,0,135|||",
                "Lord_Howe": "2u0,0,11,0,1cl,0,1u,0,24|2u2,0,11,0,1cl,0,1u,0,24||",
                "Macquarie": "9m2,0,11|||",
                "Magadan": "32t,0,fu,0,11|32t,0,gq,0,11||",
                "Malaysia": "9m3,0,11|||",
                "Maldives": "9m4,0,11|||",
                "Marquesas": "1ek,0,9m5,0,2ub|||",
                "Marshall_Islands": "1ek,0,9m6,0,2ub|||",
                "Mauritius": "6lh,0,fu,0,11|6lh,0,gq,0,11||",
                "Mawson": "57p,0,11|||",
                "Mexico_Northwest": "3vu,0,6lc,0,fu,0,11|3vu,0,6lc,0,gq,0,11||",
                "Mexico_Pacific": "3vu,0,6lb,0,fu,0,11|3vu,0,6lb,0,gq,0,11||",
                "Mongolia": "6la,0,fu,0,11|6la,0,gq,0,11||",
                "Moscow": "6l9,0,fu,0,11|6l9,0,gq,0,11||",
                "Myanmar": "9md,0,11|||",
                "Nauru": "9me,0,11|||",
                "Nepal": "7rs,0,11|||",
                "New_Caledonia": "6l8,0,fu,0,11|6l8,0,gq,0,11||",
                "New_Zealand": "6l4,0,fu,0,11|6l4,0,gq,0,11||",
                "Newfoundland": "6l0,0,fu,0,11|6l0,0,gq,0,11||",
                "Niue": "9mi,0,11|||",
                "Norfolk": "1ek,0,9mj,0,2ub|||",
                "Noronha": "1s,0,2,0,6kv,0,fu,0,11|1s,0,2,0,6kv,0,gq,0,11||",
                "Novosibirsk": "3lg,0,fu,0,11|3lg,0,gq,0,11||",
                "Omsk": "1ug,0,fu,0,11|1ug,0,gq,0,11||",
                "Pakistan": "6ku,0,fu,0,11|6ku,0,gq,0,11||",
                "Palau": "9mm,0,11|||",
                "Papua_New_Guinea": "9mn,0,11|||",
                "Paraguay": "6kt,0,fu,0,11|6kt,0,gq,0,11||",
                "Peru": "6kp,0,fu,0,11|6kp,0,gq,0,11||",
                "Philippines": "40e,0,fu,0,11|40e,0,gq,0,11||",
                "Phoenix_Islands": "1ek,0,9mq,0,1a7|||",
                "Pierre_Miquelon": "2u0,0,1v1,0,8i,0,33,0,400,0,35|2u2,0,1v1,0,8i,0,33,0,400,0,35||",
                "Pitcairn": "9mr,0,11|||",
                "Ponape": "7u1,0,11|||",
                "Pyongyang": "9ms,0,11|||",
                "Reunion": "7u7,0,11|||",
                "Rothera": "9mt,0,11|||",
                "Sakhalin": "6ko,0,fu,0,11|6ko,0,gq,0,11||",
                "Samara": "6km,0,fu,0,11|6km,0,gq,0,11||",
                "Samoa": "6kl,0,fu,0,11|6kl,0,gq,0,11||",
                "Seychelles": "9n1,0,11|||",
                "Singapore": "4j2,0,fu,0,11|||",
                "Solomon": "9n2,0,11|||",
                "South_Georgia": "9n3,0,11|||",
                "Suriname": "81u,0,11|||",
                "Syowa": "1ek,0,65|||",
                "Tahiti": "9n4,0,11|||",
                "Taipei": "6kk,0,fu,0,11|6kk,0,gq,0,11||",
                "Tajikistan": "9n6,0,11|||",
                "Tokelau": "9n7,0,11|||",
                "Tonga": "6kj,0,fu,0,11|6kj,0,gq,0,11||",
                "Truk": "1ek,0,6kg,0,6p|||",
                "Turkmenistan": "6kd,0,fu,0,11|6kd,0,gq,0,11||",
                "Tuvalu": "9nb,0,11|||",
                "Uruguay": "6kc,0,fu,0,11|6kc,0,gq,0,11||",
                "Uzbekistan": "6kb,0,fu,0,11|6kb,0,gq,0,11||",
                "Vanuatu": "6k4,0,fu,0,11|6k4,0,gq,0,11||",
                "Venezuela": "87d,0,11|||",
                "Vladivostok": "6k2,0,fu,0,11|6k2,0,gq,0,11||",
                "Volgograd": "6k1,0,fu,0,11|6k1,0,gq,0,11||",
                "Vostok": "9nh,0,11|||",
                "Wake": "1ek,0,6kg,0,5r|||",
                "Wallis": "1ek,0,5f,0,400,0,5k|||",
                "Yakutsk": "1u5,0,fu,0,11|1u5,0,gq,0,11||",
                "Yekaterinburg": "6k0,0,fu,0,11|6k0,0,gq,0,11||"
            }
        },
        "smn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "sn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "so-DJ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Colombia": "1u4,0,3vs,0,3vr,0,3vq,0,3vp|1u4,0,3vo,0,3vn||",
                "Galapagos": "3vm,0,3vl|||"
            }
        },
        "so-ET": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Colombia": "1u4,0,3vs,0,3vr,0,3vq,0,3vp|1u4,0,3vo,0,3vn||",
                "Galapagos": "3vm,0,3vl|||"
            }
        },
        "so-KE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Colombia": "1u4,0,3vs,0,3vr,0,3vq,0,3vp|1u4,0,3vo,0,3vn||",
                "Galapagos": "3vm,0,3vl|||"
            }
        },
        "so": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Colombia": "1u4,0,3vs,0,3vr,0,3vq,0,3vp|1u4,0,3vo,0,3vn||",
                "Galapagos": "3vm,0,3vl|||"
            }
        },
        "sq-MK": {
            "gmtFormat": "Ora e Grinuiçit: {0}",
            "gmtZeroFormat": "Ora e Grinuiçit",
            "zone": {
                "Europe/Dublin": "|i,0,4os,0,15,0,4ov||",
                "Europe/London": "|i,0,5c,0,4p0||"
            },
            "metazone": {
                "Acre": "i,0,58,0,15,0,2fk,0,2fs|i,0,5c,0,15,0,2fk,0,2fs||",
                "Afghanistan": "i,0,15,0,4j4|||",
                "Africa_Central": "i,0,15,0,199,0,11l|||",
                "Africa_Eastern": "i,0,15,0,199,0,qs|||",
                "Africa_Southern": "i,0,58,0,15,0,199,0,2fu|||",
                "Africa_Western": "i,0,58,0,15,0,199,0,pc|i,0,5c,0,15,0,199,0,pc||",
                "Alaska": "i,0,58,0,15,0,4j6|i,0,5c,0,15,0,4j7||",
                "Almaty": "i,0,58,0,15,0,2gb|i,0,5c,0,15,0,2gb||",
                "Amazon": "i,0,58,0,15,0,2gd|i,0,5c,0,15,0,2gd||",
                "America_Central": "i,0,58,0,15,0,1fr,5,pb,0,11l|i,0,5c,0,15,0,1fr,5,pb,0,11l||",
                "America_Eastern": "i,0,58,0,15,0,1fr,5,pb,0,qs|i,0,5c,0,15,0,1fr,5,pb,0,qs||",
                "America_Mountain": "i,0,58,0,1ft,0,15,0,2ge,0,2gf|i,0,5c,0,1ft,0,15,0,2ge,0,2gf||",
                "America_Pacific": "i,0,58,0,1ft,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,1ft,0,15,0,1g1,0,q5,0,1g3||",
                "Anadyr": "i,0,58,0,15,0,2gi|i,0,5c,0,15,0,2gi||",
                "Apia": "i,0,58,0,15,0,2gj|i,0,5c,0,15,0,2gj||",
                "Aqtau": "i,0,58,0,15,0,2gl|i,0,5c,0,15,0,2gl||",
                "Aqtobe": "i,0,58,0,15,0,2gn|i,0,5c,0,15,0,2gn||",
                "Arabian": "i,0,58,0,2go|i,0,5c,0,2go||",
                "Argentina": "i,0,58,0,15,0,1g8|i,0,5c,0,15,0,1g8||",
                "Argentina_Western": "i,0,58,0,15,0,1g8,0,pc|i,0,5c,0,15,0,1g8,0,pc||",
                "Armenia": "i,0,58,0,15,0,2gq|i,0,5c,0,15,0,2gq||",
                "Atlantic": "i,0,58,0,15,0,2gr|i,0,5c,0,15,0,2gr||",
                "Australia_Central": "i,0,58,0,15,0,11a,0,11l|i,0,5c,0,15,0,11a,0,11l||",
                "Australia_CentralWestern": "i,0,58,0,15,0,11a,0,2gt,5,pc|i,0,5c,0,15,0,11a,0,2gt,5,pc||",
                "Australia_Eastern": "i,0,58,0,15,0,11a,0,qs|i,0,5c,0,15,0,11a,0,qs||",
                "Australia_Western": "i,0,58,0,15,0,11a,0,pc|i,0,5c,0,15,0,11a,0,pc||",
                "Azerbaijan": "i,0,58,0,15,0,2gu|i,0,5c,0,15,0,2gu||",
                "Azores": "i,0,58,0,15,0,2gv|i,0,5c,0,15,0,2gv||",
                "Bangladesh": "i,0,58,0,15,0,2h0|i,0,5c,0,15,0,2h0||",
                "Bhutan": "i,0,15,0,4ja|||",
                "Bolivia": "i,0,15,0,4jb|||",
                "Brasilia": "i,0,58,0,15,0,2h1|i,0,5c,0,15,0,2h1||",
                "Brunei": "i,0,15,0,9o,5,4jc|||",
                "Cape_Verde": "i,0,58,0,15,0,2h2,0,q5,0,2h3|i,0,5c,0,15,0,2h2,0,q5,0,2h3||",
                "Casey": "i,0,15,0,4jd|||",
                "Chamorro": "i,0,15,0,4je|||",
                "Chatham": "i,0,58,0,15,0,2h4|i,0,5c,0,15,0,2h4||",
                "Chile": "i,0,58,0,15,0,2h7|i,0,5c,0,15,0,2h7||",
                "China": "i,0,58,0,15,0,2h9|i,0,5c,0,15,0,2h9||",
                "Choibalsan": "i,0,58,0,15,0,2hd|i,0,5c,0,15,0,2hd||",
                "Christmas": "i,0,15,0,163,0,q5,0,4jf|||",
                "Cocos": "i,0,15,0,ol,0,1eg|||",
                "Colombia": "i,0,58,0,15,0,2he|i,0,5c,0,15,0,2he||",
                "Cook": "i,0,58,0,15,0,ol,0,1ec|i,0,5c,0,15,0,ol,0,1ec||",
                "Cuba": "i,0,58,0,15,0,2hg|i,0,5c,0,15,0,2hg||",
                "Davis": "i,0,15,0,4jg|||",
                "DumontDUrville": "i,0,15,0,5b,5,4jh|||",
                "East_Timor": "i,0,15,0,4ji,0,2hh|||",
                "Easter": "i,0,58,0,15,0,163,0,q5,0,2hi|i,0,5c,0,15,0,163,0,q5,0,2hi||",
                "Ecuador": "i,0,15,0,4jj|||",
                "Europe_Central": "i,0,58,0,15,0,13e,0,11l|i,0,5c,0,15,0,13e,0,11l||",
                "Europe_Eastern": "i,0,58,0,15,0,13e,0,qs|i,0,5c,0,15,0,13e,0,qs||",
                "Europe_Further_Eastern": "i,0,15,0,13e,0,pb,0,4jk,0,qs|||",
                "Europe_Western": "i,0,58,0,15,0,13e,0,pc|i,0,5c,0,15,0,13e,0,pc||",
                "Falkland": "i,0,58,0,15,0,ol,0,4v|i,0,5c,0,15,0,ol,0,4v||",
                "Fiji": "i,0,58,0,15,0,2hj|i,0,5c,0,15,0,2hj||",
                "French_Guiana": "i,0,15,0,2hl,0,25d|||",
                "French_Southern": "i,0,15,0,4jm,0,2fu,0,1h9,0,4jn,0,25d|||",
                "Galapagos": "i,0,15,0,4jo|||",
                "Gambier": "i,0,15,0,4jp|||",
                "Georgia": "i,0,58,0,15,0,2hn|i,0,5c,0,15,0,2hn||",
                "Gilbert_Islands": "i,0,15,0,ol,0,6q|||",
                "GMT": "i,0,15,0,4jq,0,q5,0,4jr|||",
                "Greenland_Eastern": "i,0,58,0,15,0,1hb,0,qs|i,0,5c,0,15,0,1hb,0,qs||",
                "Greenland_Western": "i,0,58,0,15,0,1hb,0,pc|i,0,5c,0,15,0,1hb,0,pc||",
                "Guam": "i,0,15,0,4js|||",
                "Gulf": "i,0,15,0,4jt|||",
                "Guyana": "i,0,15,0,2hl|||",
                "Hawaii_Aleutian": "i,0,58,0,15,0,ol,0,2hp,5,62|i,0,5c,0,15,0,ol,0,2hp,5,62||",
                "Hong_Kong": "i,0,58,0,15,0,2l,5,2hq|i,0,5c,0,15,0,2l,5,2hq||",
                "Hovd": "i,0,58,0,15,0,2hr|i,0,5c,0,15,0,2hr||",
                "India": "i,0,58,0,15,0,4ju|||",
                "Indian_Ocean": "i,0,15,0,4jv,0,br|||",
                "Indochina": "i,0,15,0,4k0|||",
                "Indonesia_Central": "i,0,15,0,1pm,0,11l|||",
                "Indonesia_Eastern": "i,0,15,0,1pm,0,qs|||",
                "Indonesia_Western": "i,0,15,0,1pm,0,pc|||",
                "Iran": "i,0,58,0,15,0,2hs|i,0,5c,0,15,0,2hs||",
                "Irkutsk": "i,0,58,0,15,0,2hu|i,0,5c,0,15,0,2hu||",
                "Israel": "i,0,58,0,15,0,2hv|i,0,5c,0,15,0,2hv||",
                "Japan": "i,0,58,0,15,0,2i0|i,0,5c,0,15,0,2i0||",
                "Kamchatka": "i,0,58,0,15,0,2i1,5,2i2|i,0,5c,0,15,0,2i1,5,2i2||",
                "Kazakhstan_Eastern": "i,0,15,0,2i6,0,2hh|||",
                "Kazakhstan_Western": "i,0,15,0,2i6,0,4k1|||",
                "Korea": "i,0,58,0,2i7|i,0,5c,0,2i7||",
                "Kosrae": "i,0,15,0,4k2|||",
                "Krasnoyarsk": "i,0,58,0,15,0,2i8|i,0,5c,0,15,0,2i8||",
                "Kyrgystan": "i,0,15,0,4k3|||",
                "Lanka": "i,0,15,0,4k4|||",
                "Line_Islands": "i,0,15,0,ol,0,4k5,0,4k6|||",
                "Lord_Howe": "i,0,58,0,15,0,1u,5,2i9|i,0,5c,0,15,0,1u,5,2i9||",
                "Macau": "i,0,58,0,15,0,2ib|i,0,5c,0,15,0,2ib||",
                "Macquarie": "i,0,15,0,163,0,4k7|||",
                "Magadan": "i,0,58,0,15,0,2ig|i,0,5c,0,15,0,2ig||",
                "Malaysia": "i,0,15,0,4k8|||",
                "Maldives": "i,0,15,0,4k9|||",
                "Marquesas": "i,0,15,0,ol,0,4ka|||",
                "Marshall_Islands": "i,0,15,0,ol,0,4kb|||",
                "Mauritius": "i,0,58,0,15,0,2ih|i,0,5c,0,15,0,2ih||",
                "Mawson": "i,0,15,0,4kc|||",
                "Mexico_Northwest": "i,0,58,0,15,0,2ik,0,2il|i,0,5c,0,15,0,2ik,0,2il||",
                "Mexico_Pacific": "i,0,58,0,2io,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,2io,0,15,0,1g1,0,q5,0,1g3||",
                "Mongolia": "i,0,58,0,15,0,4f,5,2ip|i,0,5c,0,15,0,4f,5,2ip||",
                "Moscow": "i,0,58,0,15,0,2iq|i,0,5c,0,15,0,2iq||",
                "Myanmar": "i,0,15,0,4kd|||",
                "Nauru": "i,0,15,0,4ke|||",
                "Nepal": "i,0,15,0,4kf|||",
                "New_Caledonia": "i,0,58,0,15,0,2j3,0,pb,0,19f|i,0,5c,0,15,0,2j3,0,pb,0,19f||",
                "New_Zealand": "i,0,58,0,15,0,2jc,0,pb,0,19f|i,0,5c,0,15,0,2jc,0,pb,0,19f||",
                "Newfoundland": "i,0,58,0,15,0,2jg,0,2jh,0,pb,0,2jn|i,0,5c,0,15,0,2jg,0,2jh,0,pb,0,2jn||",
                "Niue": "i,0,15,0,4kh|||",
                "Norfolk": "i,0,15,0,163,0,6l|||",
                "Noronha": "i,0,58,0,15,0,1s,5,2,5,2jo|i,0,5c,0,15,0,1s,5,2,5,2jo||",
                "North_Mariana": "i,0,15,0,ol,0,q5,0,4ki,0,pb,0,4kj|||",
                "Novosibirsk": "i,0,58,0,15,0,2jq|i,0,5c,0,15,0,2jq||",
                "Omsk": "i,0,58,0,15,0,2js|i,0,5c,0,15,0,2js||",
                "Pakistan": "i,0,58,0,15,0,2jv|i,0,5c,0,15,0,2jv||",
                "Palau": "i,0,15,0,4kk|||",
                "Papua_New_Guinea": "i,0,15,0,9a,5,4kl,0,pb,0,19f|||",
                "Paraguay": "i,0,58,0,15,0,2k0|i,0,4km,0,15,0,2k0||",
                "Peru": "i,0,58,0,15,0,2k4|i,0,5c,0,15,0,2k4||",
                "Philippines": "i,0,58,0,15,0,2k5|i,0,5c,0,15,0,2k5||",
                "Phoenix_Islands": "i,0,15,0,ol,0,1a7|||",
                "Pierre_Miquelon": "i,0,58,0,15,0,2k8,5,1p8,0,1h9,0,2ka|i,0,5c,0,15,0,2k8,5,1p8,0,1h9,0,2ka||",
                "Pitcairn": "i,0,15,0,4kn|||",
                "Ponape": "i,0,15,0,4ko|||",
                "Pyongyang": "i,0,15,0,4kp|||",
                "Qyzylorda": "i,0,58,0,15,0,2kj|i,0,5c,0,15,0,2kj||",
                "Reunion": "i,0,15,0,4kq|||",
                "Rothera": "i,0,15,0,4kr|||",
                "Sakhalin": "i,0,58,0,15,0,2kk|i,0,5c,0,15,0,2kk||",
                "Samara": "i,0,58,0,15,0,2kl|i,0,5c,0,15,0,2kl||",
                "Samoa": "i,0,58,0,15,0,2kn|i,0,5c,0,15,0,2kn||",
                "Seychelles": "i,0,15,0,4ks|||",
                "Singapore": "i,0,15,0,4kt|||",
                "Solomon": "i,0,15,0,ol,0,b5|||",
                "South_Georgia": "i,0,15,0,4ku,0,q5,0,4kv|||",
                "Suriname": "i,0,15,0,4l1|||",
                "Syowa": "i,0,15,0,4l4|||",
                "Tahiti": "i,0,15,0,4l5|||",
                "Taipei": "i,0,58,0,15,0,2kt|i,0,5c,0,15,0,2kt||",
                "Tajikistan": "i,0,15,0,4l6|||",
                "Tokelau": "i,0,15,0,4l8|||",
                "Tonga": "i,0,58,0,15,0,2ku|i,0,5c,0,15,0,2ku||",
                "Truk": "i,0,15,0,4l9|||",
                "Turkmenistan": "i,0,58,0,15,0,2l6|i,0,5c,0,15,0,2l6||",
                "Tuvalu": "i,0,15,0,4lb|||",
                "Uruguay": "i,0,58,0,15,0,2l7|i,0,5c,0,15,0,2l7||",
                "Uzbekistan": "i,0,58,0,15,0,2l8|i,0,5c,0,15,0,2l8||",
                "Vanuatu": "i,0,58,0,15,0,2l9|i,0,5c,0,15,0,2l9||",
                "Venezuela": "i,0,15,0,4lh|||",
                "Vladivostok": "i,0,58,0,15,0,2la|i,0,5c,0,15,0,2la||",
                "Volgograd": "i,0,58,0,15,0,2ld|i,0,5c,0,15,0,2ld||",
                "Vostok": "i,0,15,0,4ll|||",
                "Wake": "i,0,15,0,163,0,4lm|||",
                "Wallis": "i,0,15,0,4ln,0,1h9,0,4lo|||",
                "Yakutsk": "i,0,58,0,15,0,2le|i,0,5c,0,15,0,2le||",
                "Yekaterinburg": "i,0,58,0,15,0,2lf|i,0,5c,0,15,0,2lf||"
            }
        },
        "sq-XK": {
            "gmtFormat": "Ora e Grinuiçit: {0}",
            "gmtZeroFormat": "Ora e Grinuiçit",
            "zone": {
                "Europe/Dublin": "|i,0,4os,0,15,0,4ov||",
                "Europe/London": "|i,0,5c,0,4p0||"
            },
            "metazone": {
                "Acre": "i,0,58,0,15,0,2fk,0,2fs|i,0,5c,0,15,0,2fk,0,2fs||",
                "Afghanistan": "i,0,15,0,4j4|||",
                "Africa_Central": "i,0,15,0,199,0,11l|||",
                "Africa_Eastern": "i,0,15,0,199,0,qs|||",
                "Africa_Southern": "i,0,58,0,15,0,199,0,2fu|||",
                "Africa_Western": "i,0,58,0,15,0,199,0,pc|i,0,5c,0,15,0,199,0,pc||",
                "Alaska": "i,0,58,0,15,0,4j6|i,0,5c,0,15,0,4j7||",
                "Almaty": "i,0,58,0,15,0,2gb|i,0,5c,0,15,0,2gb||",
                "Amazon": "i,0,58,0,15,0,2gd|i,0,5c,0,15,0,2gd||",
                "America_Central": "i,0,58,0,15,0,1fr,5,pb,0,11l|i,0,5c,0,15,0,1fr,5,pb,0,11l||",
                "America_Eastern": "i,0,58,0,15,0,1fr,5,pb,0,qs|i,0,5c,0,15,0,1fr,5,pb,0,qs||",
                "America_Mountain": "i,0,58,0,1ft,0,15,0,2ge,0,2gf|i,0,5c,0,1ft,0,15,0,2ge,0,2gf||",
                "America_Pacific": "i,0,58,0,1ft,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,1ft,0,15,0,1g1,0,q5,0,1g3||",
                "Anadyr": "i,0,58,0,15,0,2gi|i,0,5c,0,15,0,2gi||",
                "Apia": "i,0,58,0,15,0,2gj|i,0,5c,0,15,0,2gj||",
                "Aqtau": "i,0,58,0,15,0,2gl|i,0,5c,0,15,0,2gl||",
                "Aqtobe": "i,0,58,0,15,0,2gn|i,0,5c,0,15,0,2gn||",
                "Arabian": "i,0,58,0,2go|i,0,5c,0,2go||",
                "Argentina": "i,0,58,0,15,0,1g8|i,0,5c,0,15,0,1g8||",
                "Argentina_Western": "i,0,58,0,15,0,1g8,0,pc|i,0,5c,0,15,0,1g8,0,pc||",
                "Armenia": "i,0,58,0,15,0,2gq|i,0,5c,0,15,0,2gq||",
                "Atlantic": "i,0,58,0,15,0,2gr|i,0,5c,0,15,0,2gr||",
                "Australia_Central": "i,0,58,0,15,0,11a,0,11l|i,0,5c,0,15,0,11a,0,11l||",
                "Australia_CentralWestern": "i,0,58,0,15,0,11a,0,2gt,5,pc|i,0,5c,0,15,0,11a,0,2gt,5,pc||",
                "Australia_Eastern": "i,0,58,0,15,0,11a,0,qs|i,0,5c,0,15,0,11a,0,qs||",
                "Australia_Western": "i,0,58,0,15,0,11a,0,pc|i,0,5c,0,15,0,11a,0,pc||",
                "Azerbaijan": "i,0,58,0,15,0,2gu|i,0,5c,0,15,0,2gu||",
                "Azores": "i,0,58,0,15,0,2gv|i,0,5c,0,15,0,2gv||",
                "Bangladesh": "i,0,58,0,15,0,2h0|i,0,5c,0,15,0,2h0||",
                "Bhutan": "i,0,15,0,4ja|||",
                "Bolivia": "i,0,15,0,4jb|||",
                "Brasilia": "i,0,58,0,15,0,2h1|i,0,5c,0,15,0,2h1||",
                "Brunei": "i,0,15,0,9o,5,4jc|||",
                "Cape_Verde": "i,0,58,0,15,0,2h2,0,q5,0,2h3|i,0,5c,0,15,0,2h2,0,q5,0,2h3||",
                "Casey": "i,0,15,0,4jd|||",
                "Chamorro": "i,0,15,0,4je|||",
                "Chatham": "i,0,58,0,15,0,2h4|i,0,5c,0,15,0,2h4||",
                "Chile": "i,0,58,0,15,0,2h7|i,0,5c,0,15,0,2h7||",
                "China": "i,0,58,0,15,0,2h9|i,0,5c,0,15,0,2h9||",
                "Choibalsan": "i,0,58,0,15,0,2hd|i,0,5c,0,15,0,2hd||",
                "Christmas": "i,0,15,0,163,0,q5,0,4jf|||",
                "Cocos": "i,0,15,0,ol,0,1eg|||",
                "Colombia": "i,0,58,0,15,0,2he|i,0,5c,0,15,0,2he||",
                "Cook": "i,0,58,0,15,0,ol,0,1ec|i,0,5c,0,15,0,ol,0,1ec||",
                "Cuba": "i,0,58,0,15,0,2hg|i,0,5c,0,15,0,2hg||",
                "Davis": "i,0,15,0,4jg|||",
                "DumontDUrville": "i,0,15,0,5b,5,4jh|||",
                "East_Timor": "i,0,15,0,4ji,0,2hh|||",
                "Easter": "i,0,58,0,15,0,163,0,q5,0,2hi|i,0,5c,0,15,0,163,0,q5,0,2hi||",
                "Ecuador": "i,0,15,0,4jj|||",
                "Europe_Central": "i,0,58,0,15,0,13e,0,11l|i,0,5c,0,15,0,13e,0,11l||",
                "Europe_Eastern": "i,0,58,0,15,0,13e,0,qs|i,0,5c,0,15,0,13e,0,qs||",
                "Europe_Further_Eastern": "i,0,15,0,13e,0,pb,0,4jk,0,qs|||",
                "Europe_Western": "i,0,58,0,15,0,13e,0,pc|i,0,5c,0,15,0,13e,0,pc||",
                "Falkland": "i,0,58,0,15,0,ol,0,4v|i,0,5c,0,15,0,ol,0,4v||",
                "Fiji": "i,0,58,0,15,0,2hj|i,0,5c,0,15,0,2hj||",
                "French_Guiana": "i,0,15,0,2hl,0,25d|||",
                "French_Southern": "i,0,15,0,4jm,0,2fu,0,1h9,0,4jn,0,25d|||",
                "Galapagos": "i,0,15,0,4jo|||",
                "Gambier": "i,0,15,0,4jp|||",
                "Georgia": "i,0,58,0,15,0,2hn|i,0,5c,0,15,0,2hn||",
                "Gilbert_Islands": "i,0,15,0,ol,0,6q|||",
                "GMT": "i,0,15,0,4jq,0,q5,0,4jr|||",
                "Greenland_Eastern": "i,0,58,0,15,0,1hb,0,qs|i,0,5c,0,15,0,1hb,0,qs||",
                "Greenland_Western": "i,0,58,0,15,0,1hb,0,pc|i,0,5c,0,15,0,1hb,0,pc||",
                "Guam": "i,0,15,0,4js|||",
                "Gulf": "i,0,15,0,4jt|||",
                "Guyana": "i,0,15,0,2hl|||",
                "Hawaii_Aleutian": "i,0,58,0,15,0,ol,0,2hp,5,62|i,0,5c,0,15,0,ol,0,2hp,5,62||",
                "Hong_Kong": "i,0,58,0,15,0,2l,5,2hq|i,0,5c,0,15,0,2l,5,2hq||",
                "Hovd": "i,0,58,0,15,0,2hr|i,0,5c,0,15,0,2hr||",
                "India": "i,0,58,0,15,0,4ju|||",
                "Indian_Ocean": "i,0,15,0,4jv,0,br|||",
                "Indochina": "i,0,15,0,4k0|||",
                "Indonesia_Central": "i,0,15,0,1pm,0,11l|||",
                "Indonesia_Eastern": "i,0,15,0,1pm,0,qs|||",
                "Indonesia_Western": "i,0,15,0,1pm,0,pc|||",
                "Iran": "i,0,58,0,15,0,2hs|i,0,5c,0,15,0,2hs||",
                "Irkutsk": "i,0,58,0,15,0,2hu|i,0,5c,0,15,0,2hu||",
                "Israel": "i,0,58,0,15,0,2hv|i,0,5c,0,15,0,2hv||",
                "Japan": "i,0,58,0,15,0,2i0|i,0,5c,0,15,0,2i0||",
                "Kamchatka": "i,0,58,0,15,0,2i1,5,2i2|i,0,5c,0,15,0,2i1,5,2i2||",
                "Kazakhstan_Eastern": "i,0,15,0,2i6,0,2hh|||",
                "Kazakhstan_Western": "i,0,15,0,2i6,0,4k1|||",
                "Korea": "i,0,58,0,2i7|i,0,5c,0,2i7||",
                "Kosrae": "i,0,15,0,4k2|||",
                "Krasnoyarsk": "i,0,58,0,15,0,2i8|i,0,5c,0,15,0,2i8||",
                "Kyrgystan": "i,0,15,0,4k3|||",
                "Lanka": "i,0,15,0,4k4|||",
                "Line_Islands": "i,0,15,0,ol,0,4k5,0,4k6|||",
                "Lord_Howe": "i,0,58,0,15,0,1u,5,2i9|i,0,5c,0,15,0,1u,5,2i9||",
                "Macau": "i,0,58,0,15,0,2ib|i,0,5c,0,15,0,2ib||",
                "Macquarie": "i,0,15,0,163,0,4k7|||",
                "Magadan": "i,0,58,0,15,0,2ig|i,0,5c,0,15,0,2ig||",
                "Malaysia": "i,0,15,0,4k8|||",
                "Maldives": "i,0,15,0,4k9|||",
                "Marquesas": "i,0,15,0,ol,0,4ka|||",
                "Marshall_Islands": "i,0,15,0,ol,0,4kb|||",
                "Mauritius": "i,0,58,0,15,0,2ih|i,0,5c,0,15,0,2ih||",
                "Mawson": "i,0,15,0,4kc|||",
                "Mexico_Northwest": "i,0,58,0,15,0,2ik,0,2il|i,0,5c,0,15,0,2ik,0,2il||",
                "Mexico_Pacific": "i,0,58,0,2io,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,2io,0,15,0,1g1,0,q5,0,1g3||",
                "Mongolia": "i,0,58,0,15,0,4f,5,2ip|i,0,5c,0,15,0,4f,5,2ip||",
                "Moscow": "i,0,58,0,15,0,2iq|i,0,5c,0,15,0,2iq||",
                "Myanmar": "i,0,15,0,4kd|||",
                "Nauru": "i,0,15,0,4ke|||",
                "Nepal": "i,0,15,0,4kf|||",
                "New_Caledonia": "i,0,58,0,15,0,2j3,0,pb,0,19f|i,0,5c,0,15,0,2j3,0,pb,0,19f||",
                "New_Zealand": "i,0,58,0,15,0,2jc,0,pb,0,19f|i,0,5c,0,15,0,2jc,0,pb,0,19f||",
                "Newfoundland": "i,0,58,0,15,0,2jg,0,2jh,0,pb,0,2jn|i,0,5c,0,15,0,2jg,0,2jh,0,pb,0,2jn||",
                "Niue": "i,0,15,0,4kh|||",
                "Norfolk": "i,0,15,0,163,0,6l|||",
                "Noronha": "i,0,58,0,15,0,1s,5,2,5,2jo|i,0,5c,0,15,0,1s,5,2,5,2jo||",
                "North_Mariana": "i,0,15,0,ol,0,q5,0,4ki,0,pb,0,4kj|||",
                "Novosibirsk": "i,0,58,0,15,0,2jq|i,0,5c,0,15,0,2jq||",
                "Omsk": "i,0,58,0,15,0,2js|i,0,5c,0,15,0,2js||",
                "Pakistan": "i,0,58,0,15,0,2jv|i,0,5c,0,15,0,2jv||",
                "Palau": "i,0,15,0,4kk|||",
                "Papua_New_Guinea": "i,0,15,0,9a,5,4kl,0,pb,0,19f|||",
                "Paraguay": "i,0,58,0,15,0,2k0|i,0,4km,0,15,0,2k0||",
                "Peru": "i,0,58,0,15,0,2k4|i,0,5c,0,15,0,2k4||",
                "Philippines": "i,0,58,0,15,0,2k5|i,0,5c,0,15,0,2k5||",
                "Phoenix_Islands": "i,0,15,0,ol,0,1a7|||",
                "Pierre_Miquelon": "i,0,58,0,15,0,2k8,5,1p8,0,1h9,0,2ka|i,0,5c,0,15,0,2k8,5,1p8,0,1h9,0,2ka||",
                "Pitcairn": "i,0,15,0,4kn|||",
                "Ponape": "i,0,15,0,4ko|||",
                "Pyongyang": "i,0,15,0,4kp|||",
                "Qyzylorda": "i,0,58,0,15,0,2kj|i,0,5c,0,15,0,2kj||",
                "Reunion": "i,0,15,0,4kq|||",
                "Rothera": "i,0,15,0,4kr|||",
                "Sakhalin": "i,0,58,0,15,0,2kk|i,0,5c,0,15,0,2kk||",
                "Samara": "i,0,58,0,15,0,2kl|i,0,5c,0,15,0,2kl||",
                "Samoa": "i,0,58,0,15,0,2kn|i,0,5c,0,15,0,2kn||",
                "Seychelles": "i,0,15,0,4ks|||",
                "Singapore": "i,0,15,0,4kt|||",
                "Solomon": "i,0,15,0,ol,0,b5|||",
                "South_Georgia": "i,0,15,0,4ku,0,q5,0,4kv|||",
                "Suriname": "i,0,15,0,4l1|||",
                "Syowa": "i,0,15,0,4l4|||",
                "Tahiti": "i,0,15,0,4l5|||",
                "Taipei": "i,0,58,0,15,0,2kt|i,0,5c,0,15,0,2kt||",
                "Tajikistan": "i,0,15,0,4l6|||",
                "Tokelau": "i,0,15,0,4l8|||",
                "Tonga": "i,0,58,0,15,0,2ku|i,0,5c,0,15,0,2ku||",
                "Truk": "i,0,15,0,4l9|||",
                "Turkmenistan": "i,0,58,0,15,0,2l6|i,0,5c,0,15,0,2l6||",
                "Tuvalu": "i,0,15,0,4lb|||",
                "Uruguay": "i,0,58,0,15,0,2l7|i,0,5c,0,15,0,2l7||",
                "Uzbekistan": "i,0,58,0,15,0,2l8|i,0,5c,0,15,0,2l8||",
                "Vanuatu": "i,0,58,0,15,0,2l9|i,0,5c,0,15,0,2l9||",
                "Venezuela": "i,0,15,0,4lh|||",
                "Vladivostok": "i,0,58,0,15,0,2la|i,0,5c,0,15,0,2la||",
                "Volgograd": "i,0,58,0,15,0,2ld|i,0,5c,0,15,0,2ld||",
                "Vostok": "i,0,15,0,4ll|||",
                "Wake": "i,0,15,0,163,0,4lm|||",
                "Wallis": "i,0,15,0,4ln,0,1h9,0,4lo|||",
                "Yakutsk": "i,0,58,0,15,0,2le|i,0,5c,0,15,0,2le||",
                "Yekaterinburg": "i,0,58,0,15,0,2lf|i,0,5c,0,15,0,2lf||"
            }
        },
        "sq": {
            "gmtFormat": "Ora e Grinuiçit: {0}",
            "gmtZeroFormat": "Ora e Grinuiçit",
            "zone": {
                "Europe/Dublin": "|i,0,4os,0,15,0,4ov||",
                "Europe/London": "|i,0,5c,0,4p0||"
            },
            "metazone": {
                "Acre": "i,0,58,0,15,0,2fk,0,2fs|i,0,5c,0,15,0,2fk,0,2fs||",
                "Afghanistan": "i,0,15,0,4j4|||",
                "Africa_Central": "i,0,15,0,199,0,11l|||",
                "Africa_Eastern": "i,0,15,0,199,0,qs|||",
                "Africa_Southern": "i,0,58,0,15,0,199,0,2fu|||",
                "Africa_Western": "i,0,58,0,15,0,199,0,pc|i,0,5c,0,15,0,199,0,pc||",
                "Alaska": "i,0,58,0,15,0,4j6|i,0,5c,0,15,0,4j7||",
                "Almaty": "i,0,58,0,15,0,2gb|i,0,5c,0,15,0,2gb||",
                "Amazon": "i,0,58,0,15,0,2gd|i,0,5c,0,15,0,2gd||",
                "America_Central": "i,0,58,0,15,0,1fr,5,pb,0,11l|i,0,5c,0,15,0,1fr,5,pb,0,11l||",
                "America_Eastern": "i,0,58,0,15,0,1fr,5,pb,0,qs|i,0,5c,0,15,0,1fr,5,pb,0,qs||",
                "America_Mountain": "i,0,58,0,1ft,0,15,0,2ge,0,2gf|i,0,5c,0,1ft,0,15,0,2ge,0,2gf||",
                "America_Pacific": "i,0,58,0,1ft,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,1ft,0,15,0,1g1,0,q5,0,1g3||",
                "Anadyr": "i,0,58,0,15,0,2gi|i,0,5c,0,15,0,2gi||",
                "Apia": "i,0,58,0,15,0,2gj|i,0,5c,0,15,0,2gj||",
                "Aqtau": "i,0,58,0,15,0,2gl|i,0,5c,0,15,0,2gl||",
                "Aqtobe": "i,0,58,0,15,0,2gn|i,0,5c,0,15,0,2gn||",
                "Arabian": "i,0,58,0,2go|i,0,5c,0,2go||",
                "Argentina": "i,0,58,0,15,0,1g8|i,0,5c,0,15,0,1g8||",
                "Argentina_Western": "i,0,58,0,15,0,1g8,0,pc|i,0,5c,0,15,0,1g8,0,pc||",
                "Armenia": "i,0,58,0,15,0,2gq|i,0,5c,0,15,0,2gq||",
                "Atlantic": "i,0,58,0,15,0,2gr|i,0,5c,0,15,0,2gr||",
                "Australia_Central": "i,0,58,0,15,0,11a,0,11l|i,0,5c,0,15,0,11a,0,11l||",
                "Australia_CentralWestern": "i,0,58,0,15,0,11a,0,2gt,5,pc|i,0,5c,0,15,0,11a,0,2gt,5,pc||",
                "Australia_Eastern": "i,0,58,0,15,0,11a,0,qs|i,0,5c,0,15,0,11a,0,qs||",
                "Australia_Western": "i,0,58,0,15,0,11a,0,pc|i,0,5c,0,15,0,11a,0,pc||",
                "Azerbaijan": "i,0,58,0,15,0,2gu|i,0,5c,0,15,0,2gu||",
                "Azores": "i,0,58,0,15,0,2gv|i,0,5c,0,15,0,2gv||",
                "Bangladesh": "i,0,58,0,15,0,2h0|i,0,5c,0,15,0,2h0||",
                "Bhutan": "i,0,15,0,4ja|||",
                "Bolivia": "i,0,15,0,4jb|||",
                "Brasilia": "i,0,58,0,15,0,2h1|i,0,5c,0,15,0,2h1||",
                "Brunei": "i,0,15,0,9o,5,4jc|||",
                "Cape_Verde": "i,0,58,0,15,0,2h2,0,q5,0,2h3|i,0,5c,0,15,0,2h2,0,q5,0,2h3||",
                "Casey": "i,0,15,0,4jd|||",
                "Chamorro": "i,0,15,0,4je|||",
                "Chatham": "i,0,58,0,15,0,2h4|i,0,5c,0,15,0,2h4||",
                "Chile": "i,0,58,0,15,0,2h7|i,0,5c,0,15,0,2h7||",
                "China": "i,0,58,0,15,0,2h9|i,0,5c,0,15,0,2h9||",
                "Choibalsan": "i,0,58,0,15,0,2hd|i,0,5c,0,15,0,2hd||",
                "Christmas": "i,0,15,0,163,0,q5,0,4jf|||",
                "Cocos": "i,0,15,0,ol,0,1eg|||",
                "Colombia": "i,0,58,0,15,0,2he|i,0,5c,0,15,0,2he||",
                "Cook": "i,0,58,0,15,0,ol,0,1ec|i,0,5c,0,15,0,ol,0,1ec||",
                "Cuba": "i,0,58,0,15,0,2hg|i,0,5c,0,15,0,2hg||",
                "Davis": "i,0,15,0,4jg|||",
                "DumontDUrville": "i,0,15,0,5b,5,4jh|||",
                "East_Timor": "i,0,15,0,4ji,0,2hh|||",
                "Easter": "i,0,58,0,15,0,163,0,q5,0,2hi|i,0,5c,0,15,0,163,0,q5,0,2hi||",
                "Ecuador": "i,0,15,0,4jj|||",
                "Europe_Central": "i,0,58,0,15,0,13e,0,11l|i,0,5c,0,15,0,13e,0,11l||",
                "Europe_Eastern": "i,0,58,0,15,0,13e,0,qs|i,0,5c,0,15,0,13e,0,qs||",
                "Europe_Further_Eastern": "i,0,15,0,13e,0,pb,0,4jk,0,qs|||",
                "Europe_Western": "i,0,58,0,15,0,13e,0,pc|i,0,5c,0,15,0,13e,0,pc||",
                "Falkland": "i,0,58,0,15,0,ol,0,4v|i,0,5c,0,15,0,ol,0,4v||",
                "Fiji": "i,0,58,0,15,0,2hj|i,0,5c,0,15,0,2hj||",
                "French_Guiana": "i,0,15,0,2hl,0,25d|||",
                "French_Southern": "i,0,15,0,4jm,0,2fu,0,1h9,0,4jn,0,25d|||",
                "Galapagos": "i,0,15,0,4jo|||",
                "Gambier": "i,0,15,0,4jp|||",
                "Georgia": "i,0,58,0,15,0,2hn|i,0,5c,0,15,0,2hn||",
                "Gilbert_Islands": "i,0,15,0,ol,0,6q|||",
                "GMT": "i,0,15,0,4jq,0,q5,0,4jr|||",
                "Greenland_Eastern": "i,0,58,0,15,0,1hb,0,qs|i,0,5c,0,15,0,1hb,0,qs||",
                "Greenland_Western": "i,0,58,0,15,0,1hb,0,pc|i,0,5c,0,15,0,1hb,0,pc||",
                "Guam": "i,0,15,0,4js|||",
                "Gulf": "i,0,15,0,4jt|||",
                "Guyana": "i,0,15,0,2hl|||",
                "Hawaii_Aleutian": "i,0,58,0,15,0,ol,0,2hp,5,62|i,0,5c,0,15,0,ol,0,2hp,5,62||",
                "Hong_Kong": "i,0,58,0,15,0,2l,5,2hq|i,0,5c,0,15,0,2l,5,2hq||",
                "Hovd": "i,0,58,0,15,0,2hr|i,0,5c,0,15,0,2hr||",
                "India": "i,0,58,0,15,0,4ju|||",
                "Indian_Ocean": "i,0,15,0,4jv,0,br|||",
                "Indochina": "i,0,15,0,4k0|||",
                "Indonesia_Central": "i,0,15,0,1pm,0,11l|||",
                "Indonesia_Eastern": "i,0,15,0,1pm,0,qs|||",
                "Indonesia_Western": "i,0,15,0,1pm,0,pc|||",
                "Iran": "i,0,58,0,15,0,2hs|i,0,5c,0,15,0,2hs||",
                "Irkutsk": "i,0,58,0,15,0,2hu|i,0,5c,0,15,0,2hu||",
                "Israel": "i,0,58,0,15,0,2hv|i,0,5c,0,15,0,2hv||",
                "Japan": "i,0,58,0,15,0,2i0|i,0,5c,0,15,0,2i0||",
                "Kamchatka": "i,0,58,0,15,0,2i1,5,2i2|i,0,5c,0,15,0,2i1,5,2i2||",
                "Kazakhstan_Eastern": "i,0,15,0,2i6,0,2hh|||",
                "Kazakhstan_Western": "i,0,15,0,2i6,0,4k1|||",
                "Korea": "i,0,58,0,2i7|i,0,5c,0,2i7||",
                "Kosrae": "i,0,15,0,4k2|||",
                "Krasnoyarsk": "i,0,58,0,15,0,2i8|i,0,5c,0,15,0,2i8||",
                "Kyrgystan": "i,0,15,0,4k3|||",
                "Lanka": "i,0,15,0,4k4|||",
                "Line_Islands": "i,0,15,0,ol,0,4k5,0,4k6|||",
                "Lord_Howe": "i,0,58,0,15,0,1u,5,2i9|i,0,5c,0,15,0,1u,5,2i9||",
                "Macau": "i,0,58,0,15,0,2ib|i,0,5c,0,15,0,2ib||",
                "Macquarie": "i,0,15,0,163,0,4k7|||",
                "Magadan": "i,0,58,0,15,0,2ig|i,0,5c,0,15,0,2ig||",
                "Malaysia": "i,0,15,0,4k8|||",
                "Maldives": "i,0,15,0,4k9|||",
                "Marquesas": "i,0,15,0,ol,0,4ka|||",
                "Marshall_Islands": "i,0,15,0,ol,0,4kb|||",
                "Mauritius": "i,0,58,0,15,0,2ih|i,0,5c,0,15,0,2ih||",
                "Mawson": "i,0,15,0,4kc|||",
                "Mexico_Northwest": "i,0,58,0,15,0,2ik,0,2il|i,0,5c,0,15,0,2ik,0,2il||",
                "Mexico_Pacific": "i,0,58,0,2io,0,15,0,1g1,0,q5,0,1g3|i,0,5c,0,2io,0,15,0,1g1,0,q5,0,1g3||",
                "Mongolia": "i,0,58,0,15,0,4f,5,2ip|i,0,5c,0,15,0,4f,5,2ip||",
                "Moscow": "i,0,58,0,15,0,2iq|i,0,5c,0,15,0,2iq||",
                "Myanmar": "i,0,15,0,4kd|||",
                "Nauru": "i,0,15,0,4ke|||",
                "Nepal": "i,0,15,0,4kf|||",
                "New_Caledonia": "i,0,58,0,15,0,2j3,0,pb,0,19f|i,0,5c,0,15,0,2j3,0,pb,0,19f||",
                "New_Zealand": "i,0,58,0,15,0,2jc,0,pb,0,19f|i,0,5c,0,15,0,2jc,0,pb,0,19f||",
                "Newfoundland": "i,0,58,0,15,0,2jg,0,2jh,0,pb,0,2jn|i,0,5c,0,15,0,2jg,0,2jh,0,pb,0,2jn||",
                "Niue": "i,0,15,0,4kh|||",
                "Norfolk": "i,0,15,0,163,0,6l|||",
                "Noronha": "i,0,58,0,15,0,1s,5,2,5,2jo|i,0,5c,0,15,0,1s,5,2,5,2jo||",
                "North_Mariana": "i,0,15,0,ol,0,q5,0,4ki,0,pb,0,4kj|||",
                "Novosibirsk": "i,0,58,0,15,0,2jq|i,0,5c,0,15,0,2jq||",
                "Omsk": "i,0,58,0,15,0,2js|i,0,5c,0,15,0,2js||",
                "Pakistan": "i,0,58,0,15,0,2jv|i,0,5c,0,15,0,2jv||",
                "Palau": "i,0,15,0,4kk|||",
                "Papua_New_Guinea": "i,0,15,0,9a,5,4kl,0,pb,0,19f|||",
                "Paraguay": "i,0,58,0,15,0,2k0|i,0,4km,0,15,0,2k0||",
                "Peru": "i,0,58,0,15,0,2k4|i,0,5c,0,15,0,2k4||",
                "Philippines": "i,0,58,0,15,0,2k5|i,0,5c,0,15,0,2k5||",
                "Phoenix_Islands": "i,0,15,0,ol,0,1a7|||",
                "Pierre_Miquelon": "i,0,58,0,15,0,2k8,5,1p8,0,1h9,0,2ka|i,0,5c,0,15,0,2k8,5,1p8,0,1h9,0,2ka||",
                "Pitcairn": "i,0,15,0,4kn|||",
                "Ponape": "i,0,15,0,4ko|||",
                "Pyongyang": "i,0,15,0,4kp|||",
                "Qyzylorda": "i,0,58,0,15,0,2kj|i,0,5c,0,15,0,2kj||",
                "Reunion": "i,0,15,0,4kq|||",
                "Rothera": "i,0,15,0,4kr|||",
                "Sakhalin": "i,0,58,0,15,0,2kk|i,0,5c,0,15,0,2kk||",
                "Samara": "i,0,58,0,15,0,2kl|i,0,5c,0,15,0,2kl||",
                "Samoa": "i,0,58,0,15,0,2kn|i,0,5c,0,15,0,2kn||",
                "Seychelles": "i,0,15,0,4ks|||",
                "Singapore": "i,0,15,0,4kt|||",
                "Solomon": "i,0,15,0,ol,0,b5|||",
                "South_Georgia": "i,0,15,0,4ku,0,q5,0,4kv|||",
                "Suriname": "i,0,15,0,4l1|||",
                "Syowa": "i,0,15,0,4l4|||",
                "Tahiti": "i,0,15,0,4l5|||",
                "Taipei": "i,0,58,0,15,0,2kt|i,0,5c,0,15,0,2kt||",
                "Tajikistan": "i,0,15,0,4l6|||",
                "Tokelau": "i,0,15,0,4l8|||",
                "Tonga": "i,0,58,0,15,0,2ku|i,0,5c,0,15,0,2ku||",
                "Truk": "i,0,15,0,4l9|||",
                "Turkmenistan": "i,0,58,0,15,0,2l6|i,0,5c,0,15,0,2l6||",
                "Tuvalu": "i,0,15,0,4lb|||",
                "Uruguay": "i,0,58,0,15,0,2l7|i,0,5c,0,15,0,2l7||",
                "Uzbekistan": "i,0,58,0,15,0,2l8|i,0,5c,0,15,0,2l8||",
                "Vanuatu": "i,0,58,0,15,0,2l9|i,0,5c,0,15,0,2l9||",
                "Venezuela": "i,0,15,0,4lh|||",
                "Vladivostok": "i,0,58,0,15,0,2la|i,0,5c,0,15,0,2la||",
                "Volgograd": "i,0,58,0,15,0,2ld|i,0,5c,0,15,0,2ld||",
                "Vostok": "i,0,15,0,4ll|||",
                "Wake": "i,0,15,0,163,0,4lm|||",
                "Wallis": "i,0,15,0,4ln,0,1h9,0,4lo|||",
                "Yakutsk": "i,0,58,0,15,0,2le|i,0,5c,0,15,0,2le||",
                "Yekaterinburg": "i,0,58,0,15,0,2lf|i,0,5c,0,15,0,2lf||"
            }
        },
        "sr-Cyrl-BA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tc,0,1q,0,m||",
                "Europe/London": "|2me,0,20,0,2b,0,2d||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Afghanistan": "2ak,0,m|||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "1jf,k,0,1q,0,m|1jf,k,0,20,0,2b,0,2d||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "p7,0,p6,0,1q,0,m|p7,0,p6,0,20,0,m||",
                "America_Eastern": "p7,0,139,0,1q,0,m|p7,0,139,0,20,0,m||",
                "America_Mountain": "p7,0,1gj,0,1q,0,m|p7,0,1gj,0,20,0,m||",
                "America_Pacific": "p7,0,1o4,0,1q,0,m|p7,0,1o4,0,20,0,m||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Apia": "1jh,0,1q,0,m|1jh,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "1o5,0,1q,0,m|1o5,0,20,0,2b,0,2d||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,20,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "1o8,0,ir,0,1q,0,m|1o8,0,ir,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "2p0,0,1ka,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d|gu|gv",
                "Europe_Further_Eastern": "2p1,0,2p2,0,2p3|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d|h1|h2",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "2p5,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "2p6,0,m,0,1jo,0,2p7||ah|",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "2pb,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|19c,0,1eu,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,2d||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "2bo,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "ir,0,2pc,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,2d||",
                "Macquarie": "1k9,0,2pe,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,2d||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mexico_Northwest": "1od,0,187,0,1q,0,m|1od,0,187,0,20,0,2b,0,2d||",
                "Mexico_Pacific": "1oe,0,1of,0,1q,0,m|1oe,0,1of,0,20,0,2b,0,2d||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Palau": "159,0,m|||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,2d||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "2pk,0,m|||",
                "Pyongyang": "2pl,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "1oh,0,1q,0,m|1oh,0,20,0,m||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "1f2,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "sr-Cyrl-ME": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tc,0,1q,0,m||",
                "Europe/London": "|2me,0,20,0,2b,0,2d||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Afghanistan": "2ak,0,m|||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "1jf,k,0,1q,0,m|1jf,k,0,20,0,2b,0,2d||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "p7,0,p6,0,1q,0,m|p7,0,p6,0,20,0,m||",
                "America_Eastern": "p7,0,139,0,1q,0,m|p7,0,139,0,20,0,m||",
                "America_Mountain": "p7,0,1gj,0,1q,0,m|p7,0,1gj,0,20,0,m||",
                "America_Pacific": "p7,0,1o4,0,1q,0,m|p7,0,1o4,0,20,0,m||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Apia": "1jh,0,1q,0,m|1jh,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "1o5,0,1q,0,m|1o5,0,20,0,2b,0,2d||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,20,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "1o8,0,ir,0,1q,0,m|1o8,0,ir,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "2p0,0,1ka,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d|gu|gv",
                "Europe_Further_Eastern": "2p1,0,2p2,0,2p3|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d|h1|h2",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "2p5,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "2p6,0,m,0,1jo,0,2p7||ah|",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "2pb,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|19c,0,1eu,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,2d||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "2bo,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "ir,0,2pc,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,2d||",
                "Macquarie": "1k9,0,2pe,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,2d||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mexico_Northwest": "1od,0,187,0,1q,0,m|1od,0,187,0,20,0,2b,0,2d||",
                "Mexico_Pacific": "1oe,0,1of,0,1q,0,m|1oe,0,1of,0,20,0,2b,0,2d||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Palau": "159,0,m|||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,2d||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "2pk,0,m|||",
                "Pyongyang": "2pl,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "1oh,0,1q,0,m|1oh,0,20,0,m||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "1f2,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "sr-Cyrl-XK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tc,0,1q,0,m||",
                "Europe/London": "|2me,0,20,0,2b,0,2d||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Afghanistan": "2ak,0,m|||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "1jf,k,0,1q,0,m|1jf,k,0,20,0,2b,0,2d||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "p7,0,p6,0,1q,0,m|p7,0,p6,0,20,0,m||",
                "America_Eastern": "p7,0,139,0,1q,0,m|p7,0,139,0,20,0,m||",
                "America_Mountain": "p7,0,1gj,0,1q,0,m|p7,0,1gj,0,20,0,m||",
                "America_Pacific": "p7,0,1o4,0,1q,0,m|p7,0,1o4,0,20,0,m||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Apia": "1jh,0,1q,0,m|1jh,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "1o5,0,1q,0,m|1o5,0,20,0,2b,0,2d||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,20,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "1o8,0,ir,0,1q,0,m|1o8,0,ir,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "2p0,0,1ka,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d|gu|gv",
                "Europe_Further_Eastern": "2p1,0,2p2,0,2p3|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d|h1|h2",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "2p5,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "2p6,0,m,0,1jo,0,2p7||ah|",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "2pb,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|19c,0,1eu,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,2d||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "2bo,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "ir,0,2pc,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,2d||",
                "Macquarie": "1k9,0,2pe,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,2d||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mexico_Northwest": "1od,0,187,0,1q,0,m|1od,0,187,0,20,0,2b,0,2d||",
                "Mexico_Pacific": "1oe,0,1of,0,1q,0,m|1oe,0,1of,0,20,0,2b,0,2d||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Palau": "159,0,m|||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,2d||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "2pk,0,m|||",
                "Pyongyang": "2pl,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "1oh,0,1q,0,m|1oh,0,20,0,m||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "1f2,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "sr-Cyrl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tc,0,1q,0,m||",
                "Europe/London": "|2me,0,20,0,2b,0,2d||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Afghanistan": "2ak,0,m|||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "1jf,k,0,1q,0,m|1jf,k,0,20,0,2b,0,2d||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "p7,0,p6,0,1q,0,m|p7,0,p6,0,20,0,m||",
                "America_Eastern": "p7,0,139,0,1q,0,m|p7,0,139,0,20,0,m||",
                "America_Mountain": "p7,0,1gj,0,1q,0,m|p7,0,1gj,0,20,0,m||",
                "America_Pacific": "p7,0,1o4,0,1q,0,m|p7,0,1o4,0,20,0,m||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Apia": "1jh,0,1q,0,m|1jh,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "1o5,0,1q,0,m|1o5,0,20,0,2b,0,2d||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,20,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "1o8,0,ir,0,1q,0,m|1o8,0,ir,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "2p0,0,1ka,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d|gu|gv",
                "Europe_Further_Eastern": "2p1,0,2p2,0,2p3|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d|h1|h2",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "2p5,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "2p6,0,m,0,1jo,0,2p7||ah|",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "2pb,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|19c,0,1eu,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,2d||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "2bo,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "ir,0,2pc,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,2d||",
                "Macquarie": "1k9,0,2pe,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,2d||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mexico_Northwest": "1od,0,187,0,1q,0,m|1od,0,187,0,20,0,2b,0,2d||",
                "Mexico_Pacific": "1oe,0,1of,0,1q,0,m|1oe,0,1of,0,20,0,2b,0,2d||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Palau": "159,0,m|||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,2d||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "2pk,0,m|||",
                "Pyongyang": "2pl,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "1oh,0,1q,0,m|1oh,0,20,0,m||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "1f2,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "sr-Latn-BA": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3gg,0,1a,0,1d||",
                "Europe/London": "|3ge,0,3o,0,40,0,3v||"
            },
            "metazone": {
                "Acre": "22d,0,1a,0,1d|22d,0,3o,0,40,0,3v||",
                "Afghanistan": "3p0,0,1d|||",
                "Africa_Central": "228,5,14v,0,1d|||",
                "Africa_Eastern": "1j7,5,14v,0,1d|||",
                "Africa_Southern": "3os,5,14v,0,1d|||",
                "Africa_Western": "16s,5,14v,0,1a,0,1d|16s,5,14v,0,3o,0,40,0,3v||",
                "Alaska": "216,k,0,1a,0,1d|216,k,0,3o,0,40,0,3v||",
                "Almaty": "1nf,0,1a,0,1d|1nf,0,3o,0,40,0,3v||",
                "Amazon": "5m,0,1a,0,1d|5m,0,3o,0,40,0,3v||",
                "America_Central": "qu,0,ug,0,1a,0,1d|qu,0,ug,0,3o,0,1d||",
                "America_Eastern": "qu,0,111,0,1a,0,1d|qu,0,111,0,3o,0,1d||",
                "America_Mountain": "qu,0,17r,0,1a,0,1d|qu,0,17r,0,3o,0,1d||",
                "America_Pacific": "qu,0,110,0,1a,0,1d|qu,0,110,0,3o,0,1d||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "20v,0,1a,0,1d|20v,0,3o,0,40,0,3v||",
                "Aqtau": "20p,0,1a,0,1d|20p,0,3o,0,40,0,3v||",
                "Aqtobe": "20n,0,1a,0,1d|20n,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,1d|1i1,0,3o,0,40,0,3v||",
                "Argentina": "1e,0,1a,0,1d|1e,0,3o,0,40,0,3v||",
                "Argentina_Western": "20a,0,1e,0,1a,0,1d|20a,0,1e,0,3o,0,40,0,3v||",
                "Armenia": "1vn,0,1a,0,1d|1vn,0,3o,0,40,0,3v||",
                "Atlantic": "1vd,0,1a,0,1d|1vd,0,3o,0,40,0,3v||",
                "Australia_Central": "pp,0,ug,0,1a,0,1d|pp,0,ug,0,3o,0,40,0,3v||",
                "Australia_CentralWestern": "pp,0,ug,0,11c,0,1a,0,1d|pp,0,ug,0,11c,0,3o,0,40,0,3v||",
                "Australia_Eastern": "pp,0,111,0,1a,0,1d|pp,0,111,0,3o,0,40,0,3v||",
                "Australia_Western": "pp,0,11c,0,1a,0,1d|pp,0,11c,0,3o,0,40,0,3v||",
                "Azerbaijan": "1v8,0,1a,0,1d|1v8,0,3o,0,40,0,3v||",
                "Azores": "1uu,0,1a,0,1d|1uu,0,3o,0,40,0,3v||",
                "Bangladesh": "1ut,0,1a,0,1d|1ut,0,3o,0,40,0,3v||",
                "Bhutan": "1k4,0,1d|||",
                "Bolivia": "3of,0,1d|||",
                "Brasilia": "1ur,0,1a,0,1d|1ur,0,3o,0,40,0,3v||",
                "Brunei": "2kg,0,3od,0,1d|||",
                "Cape_Verde": "1uq,0,ok,0,1a,0,1d|1uq,0,ok,0,3o,0,40,0,3v||",
                "Chamorro": "2nf,0,1d|||",
                "Chatham": "1um,0,1a,0,1d|1um,0,3o,0,40,0,3v||",
                "Chile": "1uk,0,1a,0,1d|1uk,0,3o,0,40,0,3v||",
                "China": "23o,0,1a,0,1d|25m,0,3o,0,40,0,3v||",
                "Choibalsan": "1uh,0,1a,0,1d|1uh,0,3o,0,40,0,3v||",
                "Christmas": "3o8,0,1ud,0,1d|||",
                "Cocos": "1eg,0,3o6,0,ok,0,1d|||",
                "Colombia": "1ro,0,1a,0,1d|1ro,0,3o,0,40,0,3v||",
                "Cook": "1rh,0,10u,0,1a,0,1d|1rh,0,10u,0,3o2,5,3o,0,40,0,3v||",
                "Cuba": "tb,0,1a,0,1d|tb,0,3o,0,40,0,3v||",
                "Davis": "3o1,0,1d|||",
                "DumontDUrville": "3o0,0,3nv,0,1d|||",
                "East_Timor": "1fl,0,3nt,0,1d|||",
                "Easter": "24r,0,10u,0,1a,0,1d|24r,0,10u,0,3o,0,40,0,3v||",
                "Ecuador": "1k0,0,1d|||",
                "Europe_Central": "23r,0,1a,0,1d|23r,0,3o,0,40,0,3v|h0|h4",
                "Europe_Eastern": "1hr,0,1a,0,1d|1hr,0,3o,0,40,0,3v|gu|gv",
                "Europe_Further_Eastern": "3nq,0,3np,0,3no|||",
                "Europe_Western": "1hq,0,1a,0,1d|1hq,0,3o,0,40,0,3v|h1|h2",
                "Falkland": "25c,0,ok,0,1a,0,1d|25c,0,ok,0,3o,0,40,0,3v||",
                "Fiji": "1fh,0,1a,0,1d|1fh,0,3o,0,40,0,3v||",
                "French_Guiana": "2n4,0,1pt,0,1d|||",
                "French_Southern": "3nm,0,3nl,0,i3,0,2l1,0,1d|||",
                "Galapagos": "ak,0,1d|||",
                "Gambier": "3nk,0,1d|||",
                "Georgia": "1qt,0,1a,0,1d|1qt,0,3o,0,40,0,3v||",
                "Gilbert_Islands": "6q,0,10u,0,1d|||",
                "GMT": "3ni,0,1d,0,3nh,0,3ng||ah|",
                "Greenland_Eastern": "1fl,0,18g,0,1a,0,1d|1fl,0,18g,0,3o,0,40,0,3v||",
                "Greenland_Western": "1rj,0,18g,0,1a,0,1d|1rj,0,18g,0,3o,0,40,0,3v||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1d|||",
                "Guyana": "1pt,0,1d|||",
                "Hawaii_Aleutian": "1c5,5,1k2,0,1a,0,1d|1c5,5,1k2,0,3o,0,40,0,3v||",
                "Hong_Kong": "2l,0,2p,0,1a,0,1d|2l,0,2p,0,3o,0,40,0,3v||",
                "Hovd": "2g,0,1a,0,1d|2g,0,3o,0,40,0,3v||",
                "India": "1n7,0,1a,0,1d|||",
                "Indian_Ocean": "1n7,0,3nd,0,1d|||",
                "Indochina": "23t,0,1d|||",
                "Indonesia_Central": "228,5,1f1,0,1d|||",
                "Indonesia_Eastern": "1j7,5,1f1,0,1d|||",
                "Indonesia_Western": "16s,5,1f1,0,1d|||",
                "Iran": "5g,0,1a,0,1d|5g,0,3o,0,40,0,3v||",
                "Irkutsk": "1oi,0,1a,0,1d|1oi,0,3o,0,40,0,3v||",
                "Israel": "1hm,0,1a,0,1d|1hm,0,3o,0,40,0,3v||",
                "Japan": "1hl,0,1a,0,1d|1hl,0,3o,0,40,0,3v||",
                "Kamchatka": "1gd,5,1i4,0,1a,0,1d|1gd,5,1i4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "1j7,5,1s3,0,1d|||",
                "Kazakhstan_Western": "16s,5,1s3,0,1d|||",
                "Korea": "1hk,0,1a,0,1d|1hk,0,3o,0,40,0,3v||",
                "Kosrae": "3na,0,1d|||",
                "Krasnoyarsk": "l0,0,1a,0,1d|l0,0,3o,0,40,0,3v||",
                "Kyrgystan": "1qu,0,1d|||",
                "Lanka": "3n9,0,9c,0,1d|||",
                "Line_Islands": "ok,0,2bp,0,1d|||",
                "Lord_Howe": "1u,0,1sd,0,1a,0,1d|1u,0,1sd,0,3o,0,40,0,3v||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "1sh,0,3n6,0,1d|||",
                "Magadan": "2o,0,1a,0,1d|2o,0,3o,0,40,0,3v||",
                "Malaysia": "3n5,0,1d|||",
                "Maldives": "3n4,0,1d|||",
                "Marquesas": "1jm,0,1d|||",
                "Marshall_Islands": "3n3,0,ok,0,1d|||",
                "Mauritius": "1te,0,1a,0,1d|1te,0,3o,0,40,0,3v||",
                "Mawson": "3n1,0,1d|||",
                "Mexico_Northwest": "1tn,0,17k,0,1a,0,1d|1tn,0,17k,0,3o,0,40,0,3v||",
                "Mexico_Pacific": "1ts,0,1tu,0,1a,0,1d|1ts,0,1tu,0,3o,0,40,0,3v||",
                "Mongolia": "4f,0,2m,0,1a,0,1d|4f,0,2m,0,3o,0,40,0,3v||",
                "Moscow": "pi,0,1a,0,1d|pi,0,3o,0,40,0,3v||",
                "Myanmar": "3mq,0,1d|||",
                "Nauru": "5v,0,1d|||",
                "Nepal": "8u,0,1d|||",
                "New_Caledonia": "ba,0,1tp,0,1a,0,1d|ba,0,1tp,0,3o,0,40,0,3v||",
                "New_Zealand": "1tv,0,1p4,0,1a,0,1d|1tv,0,1p4,0,3o,0,40,0,3v||",
                "Newfoundland": "1u0,0,1a,0,1d|1u0,0,3o,0,40,0,3v||",
                "Niue": "8g,0,1d|||",
                "Norfolk": "6l,0,1sh,0,1d|||",
                "Noronha": "1s,0,2,0,1hg,0,1a,0,1d|1s,0,2,0,1hg,0,3o,0,40,0,3v||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "3b,0,1a,0,1d|3b,0,3o,0,40,0,3v||",
                "Omsk": "26,0,1a,0,1d|26,0,3o,0,40,0,3v||",
                "Pakistan": "3i,0,1a,0,1d|3i,0,3o,0,40,0,3v||",
                "Palau": "9b,0,1d|||",
                "Papua_New_Guinea": "9a,0,ba,0,3ma,0,1d|||",
                "Paraguay": "1u2,0,1a,0,1d|1u2,0,3o,0,40,0,3v||",
                "Peru": "4n,0,1a,0,1d|4n,0,3o,0,40,0,3v||",
                "Philippines": "1u3,0,1a,0,1d|1u3,0,3o,0,40,0,3v||",
                "Phoenix_Islands": "1a7,0,10u,0,1d|||",
                "Pierre_Miquelon": "14i,0,1he,0,i3,0,u2,0,1a,0,1d|14i,0,1he,0,i3,0,u2,0,3o,0,40,0,3v||",
                "Pitcairn": "1ko,0,1d|||",
                "Ponape": "3m6,0,1d|||",
                "Pyongyang": "2b0,0,1d|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "3m1,0,1d|||",
                "Rothera": "1po,0,1d|||",
                "Sakhalin": "164,0,1a,0,1d|164,0,3o,0,40,0,3v||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "2c,0,1a,0,1d|2c,0,3o,0,40,0,3v||",
                "Seychelles": "3m0,0,1d|||",
                "Singapore": "m8,0,1a,0,1d|||",
                "Solomon": "3lv,0,ok,0,1d|||",
                "South_Georgia": "3lu,0,3lr,0,1d|||",
                "Suriname": "mg,0,1d|||",
                "Syowa": "3lo,0,1d|||",
                "Tahiti": "8n,0,1d|||",
                "Taipei": "1pc,0,1a,0,1d|1pc,0,3o,0,1d||",
                "Tajikistan": "3l4,0,1d|||",
                "Tokelau": "5d,0,1d|||",
                "Tonga": "2j,0,1a,0,1d|2j,0,3o,0,40,0,3v||",
                "Truk": "3kt,0,1d|||",
                "Turkmenistan": "4t,0,1a,0,1d|4t,0,3o,0,40,0,3v||",
                "Tuvalu": "6h,0,1d|||",
                "Uruguay": "1vq,0,1a,0,1d|1vq,0,3o,0,40,0,3v||",
                "Uzbekistan": "60,0,1a,0,1d|60,0,3o,0,40,0,3v||",
                "Vanuatu": "2f,0,1a,0,1d|2f,0,3o,0,40,0,3v||",
                "Venezuela": "3kr,0,1d|||",
                "Vladivostok": "28,0,1a,0,1d|28,0,3o,0,40,0,3v||",
                "Volgograd": "3f,0,1a,0,1d|3f,0,3o,0,40,0,3v||",
                "Vostok": "5t,0,1d|||",
                "Wake": "2ar,0,1ud,0,1d|||",
                "Wallis": "2aq,0,i3,0,5k,0,ok,0,1d|||",
                "Yakutsk": "qj,0,1a,0,1d|qj,0,3o,0,40,0,3v||",
                "Yekaterinburg": "q6,0,1a,0,1d|q6,0,3o,0,40,0,3v||"
            }
        },
        "sr-Latn-ME": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3gg,0,1a,0,1d||",
                "Europe/London": "|3ge,0,3o,0,40,0,3v||"
            },
            "metazone": {
                "Acre": "22d,0,1a,0,1d|22d,0,3o,0,40,0,3v||",
                "Afghanistan": "3p0,0,1d|||",
                "Africa_Central": "228,5,14v,0,1d|||",
                "Africa_Eastern": "1j7,5,14v,0,1d|||",
                "Africa_Southern": "3os,5,14v,0,1d|||",
                "Africa_Western": "16s,5,14v,0,1a,0,1d|16s,5,14v,0,3o,0,40,0,3v||",
                "Alaska": "216,k,0,1a,0,1d|216,k,0,3o,0,40,0,3v||",
                "Almaty": "1nf,0,1a,0,1d|1nf,0,3o,0,40,0,3v||",
                "Amazon": "5m,0,1a,0,1d|5m,0,3o,0,40,0,3v||",
                "America_Central": "qu,0,ug,0,1a,0,1d|qu,0,ug,0,3o,0,1d||",
                "America_Eastern": "qu,0,111,0,1a,0,1d|qu,0,111,0,3o,0,1d||",
                "America_Mountain": "qu,0,17r,0,1a,0,1d|qu,0,17r,0,3o,0,1d||",
                "America_Pacific": "qu,0,110,0,1a,0,1d|qu,0,110,0,3o,0,1d||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "20v,0,1a,0,1d|20v,0,3o,0,40,0,3v||",
                "Aqtau": "20p,0,1a,0,1d|20p,0,3o,0,40,0,3v||",
                "Aqtobe": "20n,0,1a,0,1d|20n,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,1d|1i1,0,3o,0,40,0,3v||",
                "Argentina": "1e,0,1a,0,1d|1e,0,3o,0,40,0,3v||",
                "Argentina_Western": "20a,0,1e,0,1a,0,1d|20a,0,1e,0,3o,0,40,0,3v||",
                "Armenia": "1vn,0,1a,0,1d|1vn,0,3o,0,40,0,3v||",
                "Atlantic": "1vd,0,1a,0,1d|1vd,0,3o,0,40,0,3v||",
                "Australia_Central": "pp,0,ug,0,1a,0,1d|pp,0,ug,0,3o,0,40,0,3v||",
                "Australia_CentralWestern": "pp,0,ug,0,11c,0,1a,0,1d|pp,0,ug,0,11c,0,3o,0,40,0,3v||",
                "Australia_Eastern": "pp,0,111,0,1a,0,1d|pp,0,111,0,3o,0,40,0,3v||",
                "Australia_Western": "pp,0,11c,0,1a,0,1d|pp,0,11c,0,3o,0,40,0,3v||",
                "Azerbaijan": "1v8,0,1a,0,1d|1v8,0,3o,0,40,0,3v||",
                "Azores": "1uu,0,1a,0,1d|1uu,0,3o,0,40,0,3v||",
                "Bangladesh": "1ut,0,1a,0,1d|1ut,0,3o,0,40,0,3v||",
                "Bhutan": "1k4,0,1d|||",
                "Bolivia": "3of,0,1d|||",
                "Brasilia": "1ur,0,1a,0,1d|1ur,0,3o,0,40,0,3v||",
                "Brunei": "2kg,0,3od,0,1d|||",
                "Cape_Verde": "1uq,0,ok,0,1a,0,1d|1uq,0,ok,0,3o,0,40,0,3v||",
                "Chamorro": "2nf,0,1d|||",
                "Chatham": "1um,0,1a,0,1d|1um,0,3o,0,40,0,3v||",
                "Chile": "1uk,0,1a,0,1d|1uk,0,3o,0,40,0,3v||",
                "China": "23o,0,1a,0,1d|25m,0,3o,0,40,0,3v||",
                "Choibalsan": "1uh,0,1a,0,1d|1uh,0,3o,0,40,0,3v||",
                "Christmas": "3o8,0,1ud,0,1d|||",
                "Cocos": "1eg,0,3o6,0,ok,0,1d|||",
                "Colombia": "1ro,0,1a,0,1d|1ro,0,3o,0,40,0,3v||",
                "Cook": "1rh,0,10u,0,1a,0,1d|1rh,0,10u,0,3o2,5,3o,0,40,0,3v||",
                "Cuba": "tb,0,1a,0,1d|tb,0,3o,0,40,0,3v||",
                "Davis": "3o1,0,1d|||",
                "DumontDUrville": "3o0,0,3nv,0,1d|||",
                "East_Timor": "1fl,0,3nt,0,1d|||",
                "Easter": "24r,0,10u,0,1a,0,1d|24r,0,10u,0,3o,0,40,0,3v||",
                "Ecuador": "1k0,0,1d|||",
                "Europe_Central": "23r,0,1a,0,1d|23r,0,3o,0,40,0,3v|h0|h4",
                "Europe_Eastern": "1hr,0,1a,0,1d|1hr,0,3o,0,40,0,3v|gu|gv",
                "Europe_Further_Eastern": "3nq,0,3np,0,3no|||",
                "Europe_Western": "1hq,0,1a,0,1d|1hq,0,3o,0,40,0,3v|h1|h2",
                "Falkland": "25c,0,ok,0,1a,0,1d|25c,0,ok,0,3o,0,40,0,3v||",
                "Fiji": "1fh,0,1a,0,1d|1fh,0,3o,0,40,0,3v||",
                "French_Guiana": "2n4,0,1pt,0,1d|||",
                "French_Southern": "3nm,0,3nl,0,i3,0,2l1,0,1d|||",
                "Galapagos": "ak,0,1d|||",
                "Gambier": "3nk,0,1d|||",
                "Georgia": "1qt,0,1a,0,1d|1qt,0,3o,0,40,0,3v||",
                "Gilbert_Islands": "6q,0,10u,0,1d|||",
                "GMT": "3ni,0,1d,0,3nh,0,3ng||ah|",
                "Greenland_Eastern": "1fl,0,18g,0,1a,0,1d|1fl,0,18g,0,3o,0,40,0,3v||",
                "Greenland_Western": "1rj,0,18g,0,1a,0,1d|1rj,0,18g,0,3o,0,40,0,3v||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1d|||",
                "Guyana": "1pt,0,1d|||",
                "Hawaii_Aleutian": "1c5,5,1k2,0,1a,0,1d|1c5,5,1k2,0,3o,0,40,0,3v||",
                "Hong_Kong": "2l,0,2p,0,1a,0,1d|2l,0,2p,0,3o,0,40,0,3v||",
                "Hovd": "2g,0,1a,0,1d|2g,0,3o,0,40,0,3v||",
                "India": "1n7,0,1a,0,1d|||",
                "Indian_Ocean": "1n7,0,3nd,0,1d|||",
                "Indochina": "23t,0,1d|||",
                "Indonesia_Central": "228,5,1f1,0,1d|||",
                "Indonesia_Eastern": "1j7,5,1f1,0,1d|||",
                "Indonesia_Western": "16s,5,1f1,0,1d|||",
                "Iran": "5g,0,1a,0,1d|5g,0,3o,0,40,0,3v||",
                "Irkutsk": "1oi,0,1a,0,1d|1oi,0,3o,0,40,0,3v||",
                "Israel": "1hm,0,1a,0,1d|1hm,0,3o,0,40,0,3v||",
                "Japan": "1hl,0,1a,0,1d|1hl,0,3o,0,40,0,3v||",
                "Kamchatka": "1gd,5,1i4,0,1a,0,1d|1gd,5,1i4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "1j7,5,1s3,0,1d|||",
                "Kazakhstan_Western": "16s,5,1s3,0,1d|||",
                "Korea": "1hk,0,1a,0,1d|1hk,0,3o,0,40,0,3v||",
                "Kosrae": "3na,0,1d|||",
                "Krasnoyarsk": "l0,0,1a,0,1d|l0,0,3o,0,40,0,3v||",
                "Kyrgystan": "1qu,0,1d|||",
                "Lanka": "3n9,0,9c,0,1d|||",
                "Line_Islands": "ok,0,2bp,0,1d|||",
                "Lord_Howe": "1u,0,1sd,0,1a,0,1d|1u,0,1sd,0,3o,0,40,0,3v||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "1sh,0,3n6,0,1d|||",
                "Magadan": "2o,0,1a,0,1d|2o,0,3o,0,40,0,3v||",
                "Malaysia": "3n5,0,1d|||",
                "Maldives": "3n4,0,1d|||",
                "Marquesas": "1jm,0,1d|||",
                "Marshall_Islands": "3n3,0,ok,0,1d|||",
                "Mauritius": "1te,0,1a,0,1d|1te,0,3o,0,40,0,3v||",
                "Mawson": "3n1,0,1d|||",
                "Mexico_Northwest": "1tn,0,17k,0,1a,0,1d|1tn,0,17k,0,3o,0,40,0,3v||",
                "Mexico_Pacific": "1ts,0,1tu,0,1a,0,1d|1ts,0,1tu,0,3o,0,40,0,3v||",
                "Mongolia": "4f,0,2m,0,1a,0,1d|4f,0,2m,0,3o,0,40,0,3v||",
                "Moscow": "pi,0,1a,0,1d|pi,0,3o,0,40,0,3v||",
                "Myanmar": "3mq,0,1d|||",
                "Nauru": "5v,0,1d|||",
                "Nepal": "8u,0,1d|||",
                "New_Caledonia": "ba,0,1tp,0,1a,0,1d|ba,0,1tp,0,3o,0,40,0,3v||",
                "New_Zealand": "1tv,0,1p4,0,1a,0,1d|1tv,0,1p4,0,3o,0,40,0,3v||",
                "Newfoundland": "1u0,0,1a,0,1d|1u0,0,3o,0,40,0,3v||",
                "Niue": "8g,0,1d|||",
                "Norfolk": "6l,0,1sh,0,1d|||",
                "Noronha": "1s,0,2,0,1hg,0,1a,0,1d|1s,0,2,0,1hg,0,3o,0,40,0,3v||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "3b,0,1a,0,1d|3b,0,3o,0,40,0,3v||",
                "Omsk": "26,0,1a,0,1d|26,0,3o,0,40,0,3v||",
                "Pakistan": "3i,0,1a,0,1d|3i,0,3o,0,40,0,3v||",
                "Palau": "9b,0,1d|||",
                "Papua_New_Guinea": "9a,0,ba,0,3ma,0,1d|||",
                "Paraguay": "1u2,0,1a,0,1d|1u2,0,3o,0,40,0,3v||",
                "Peru": "4n,0,1a,0,1d|4n,0,3o,0,40,0,3v||",
                "Philippines": "1u3,0,1a,0,1d|1u3,0,3o,0,40,0,3v||",
                "Phoenix_Islands": "1a7,0,10u,0,1d|||",
                "Pierre_Miquelon": "14i,0,1he,0,i3,0,u2,0,1a,0,1d|14i,0,1he,0,i3,0,u2,0,3o,0,40,0,3v||",
                "Pitcairn": "1ko,0,1d|||",
                "Ponape": "3m6,0,1d|||",
                "Pyongyang": "2b0,0,1d|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "3m1,0,1d|||",
                "Rothera": "1po,0,1d|||",
                "Sakhalin": "164,0,1a,0,1d|164,0,3o,0,40,0,3v||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "2c,0,1a,0,1d|2c,0,3o,0,40,0,3v||",
                "Seychelles": "3m0,0,1d|||",
                "Singapore": "m8,0,1a,0,1d|||",
                "Solomon": "3lv,0,ok,0,1d|||",
                "South_Georgia": "3lu,0,3lr,0,1d|||",
                "Suriname": "mg,0,1d|||",
                "Syowa": "3lo,0,1d|||",
                "Tahiti": "8n,0,1d|||",
                "Taipei": "1pc,0,1a,0,1d|1pc,0,3o,0,1d||",
                "Tajikistan": "3l4,0,1d|||",
                "Tokelau": "5d,0,1d|||",
                "Tonga": "2j,0,1a,0,1d|2j,0,3o,0,40,0,3v||",
                "Truk": "3kt,0,1d|||",
                "Turkmenistan": "4t,0,1a,0,1d|4t,0,3o,0,40,0,3v||",
                "Tuvalu": "6h,0,1d|||",
                "Uruguay": "1vq,0,1a,0,1d|1vq,0,3o,0,40,0,3v||",
                "Uzbekistan": "60,0,1a,0,1d|60,0,3o,0,40,0,3v||",
                "Vanuatu": "2f,0,1a,0,1d|2f,0,3o,0,40,0,3v||",
                "Venezuela": "3kr,0,1d|||",
                "Vladivostok": "28,0,1a,0,1d|28,0,3o,0,40,0,3v||",
                "Volgograd": "3f,0,1a,0,1d|3f,0,3o,0,40,0,3v||",
                "Vostok": "5t,0,1d|||",
                "Wake": "2ar,0,1ud,0,1d|||",
                "Wallis": "2aq,0,i3,0,5k,0,ok,0,1d|||",
                "Yakutsk": "qj,0,1a,0,1d|qj,0,3o,0,40,0,3v||",
                "Yekaterinburg": "q6,0,1a,0,1d|q6,0,3o,0,40,0,3v||"
            }
        },
        "sr-Latn-XK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3gg,0,1a,0,1d||",
                "Europe/London": "|3ge,0,3o,0,40,0,3v||"
            },
            "metazone": {
                "Acre": "22d,0,1a,0,1d|22d,0,3o,0,40,0,3v||",
                "Afghanistan": "3p0,0,1d|||",
                "Africa_Central": "228,5,14v,0,1d|||",
                "Africa_Eastern": "1j7,5,14v,0,1d|||",
                "Africa_Southern": "3os,5,14v,0,1d|||",
                "Africa_Western": "16s,5,14v,0,1a,0,1d|16s,5,14v,0,3o,0,40,0,3v||",
                "Alaska": "216,k,0,1a,0,1d|216,k,0,3o,0,40,0,3v||",
                "Almaty": "1nf,0,1a,0,1d|1nf,0,3o,0,40,0,3v||",
                "Amazon": "5m,0,1a,0,1d|5m,0,3o,0,40,0,3v||",
                "America_Central": "qu,0,ug,0,1a,0,1d|qu,0,ug,0,3o,0,1d||",
                "America_Eastern": "qu,0,111,0,1a,0,1d|qu,0,111,0,3o,0,1d||",
                "America_Mountain": "qu,0,17r,0,1a,0,1d|qu,0,17r,0,3o,0,1d||",
                "America_Pacific": "qu,0,110,0,1a,0,1d|qu,0,110,0,3o,0,1d||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "20v,0,1a,0,1d|20v,0,3o,0,40,0,3v||",
                "Aqtau": "20p,0,1a,0,1d|20p,0,3o,0,40,0,3v||",
                "Aqtobe": "20n,0,1a,0,1d|20n,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,1d|1i1,0,3o,0,40,0,3v||",
                "Argentina": "1e,0,1a,0,1d|1e,0,3o,0,40,0,3v||",
                "Argentina_Western": "20a,0,1e,0,1a,0,1d|20a,0,1e,0,3o,0,40,0,3v||",
                "Armenia": "1vn,0,1a,0,1d|1vn,0,3o,0,40,0,3v||",
                "Atlantic": "1vd,0,1a,0,1d|1vd,0,3o,0,40,0,3v||",
                "Australia_Central": "pp,0,ug,0,1a,0,1d|pp,0,ug,0,3o,0,40,0,3v||",
                "Australia_CentralWestern": "pp,0,ug,0,11c,0,1a,0,1d|pp,0,ug,0,11c,0,3o,0,40,0,3v||",
                "Australia_Eastern": "pp,0,111,0,1a,0,1d|pp,0,111,0,3o,0,40,0,3v||",
                "Australia_Western": "pp,0,11c,0,1a,0,1d|pp,0,11c,0,3o,0,40,0,3v||",
                "Azerbaijan": "1v8,0,1a,0,1d|1v8,0,3o,0,40,0,3v||",
                "Azores": "1uu,0,1a,0,1d|1uu,0,3o,0,40,0,3v||",
                "Bangladesh": "1ut,0,1a,0,1d|1ut,0,3o,0,40,0,3v||",
                "Bhutan": "1k4,0,1d|||",
                "Bolivia": "3of,0,1d|||",
                "Brasilia": "1ur,0,1a,0,1d|1ur,0,3o,0,40,0,3v||",
                "Brunei": "2kg,0,3od,0,1d|||",
                "Cape_Verde": "1uq,0,ok,0,1a,0,1d|1uq,0,ok,0,3o,0,40,0,3v||",
                "Chamorro": "2nf,0,1d|||",
                "Chatham": "1um,0,1a,0,1d|1um,0,3o,0,40,0,3v||",
                "Chile": "1uk,0,1a,0,1d|1uk,0,3o,0,40,0,3v||",
                "China": "23o,0,1a,0,1d|25m,0,3o,0,40,0,3v||",
                "Choibalsan": "1uh,0,1a,0,1d|1uh,0,3o,0,40,0,3v||",
                "Christmas": "3o8,0,1ud,0,1d|||",
                "Cocos": "1eg,0,3o6,0,ok,0,1d|||",
                "Colombia": "1ro,0,1a,0,1d|1ro,0,3o,0,40,0,3v||",
                "Cook": "1rh,0,10u,0,1a,0,1d|1rh,0,10u,0,3o2,5,3o,0,40,0,3v||",
                "Cuba": "tb,0,1a,0,1d|tb,0,3o,0,40,0,3v||",
                "Davis": "3o1,0,1d|||",
                "DumontDUrville": "3o0,0,3nv,0,1d|||",
                "East_Timor": "1fl,0,3nt,0,1d|||",
                "Easter": "24r,0,10u,0,1a,0,1d|24r,0,10u,0,3o,0,40,0,3v||",
                "Ecuador": "1k0,0,1d|||",
                "Europe_Central": "23r,0,1a,0,1d|23r,0,3o,0,40,0,3v|h0|h4",
                "Europe_Eastern": "1hr,0,1a,0,1d|1hr,0,3o,0,40,0,3v|gu|gv",
                "Europe_Further_Eastern": "3nq,0,3np,0,3no|||",
                "Europe_Western": "1hq,0,1a,0,1d|1hq,0,3o,0,40,0,3v|h1|h2",
                "Falkland": "25c,0,ok,0,1a,0,1d|25c,0,ok,0,3o,0,40,0,3v||",
                "Fiji": "1fh,0,1a,0,1d|1fh,0,3o,0,40,0,3v||",
                "French_Guiana": "2n4,0,1pt,0,1d|||",
                "French_Southern": "3nm,0,3nl,0,i3,0,2l1,0,1d|||",
                "Galapagos": "ak,0,1d|||",
                "Gambier": "3nk,0,1d|||",
                "Georgia": "1qt,0,1a,0,1d|1qt,0,3o,0,40,0,3v||",
                "Gilbert_Islands": "6q,0,10u,0,1d|||",
                "GMT": "3ni,0,1d,0,3nh,0,3ng||ah|",
                "Greenland_Eastern": "1fl,0,18g,0,1a,0,1d|1fl,0,18g,0,3o,0,40,0,3v||",
                "Greenland_Western": "1rj,0,18g,0,1a,0,1d|1rj,0,18g,0,3o,0,40,0,3v||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1d|||",
                "Guyana": "1pt,0,1d|||",
                "Hawaii_Aleutian": "1c5,5,1k2,0,1a,0,1d|1c5,5,1k2,0,3o,0,40,0,3v||",
                "Hong_Kong": "2l,0,2p,0,1a,0,1d|2l,0,2p,0,3o,0,40,0,3v||",
                "Hovd": "2g,0,1a,0,1d|2g,0,3o,0,40,0,3v||",
                "India": "1n7,0,1a,0,1d|||",
                "Indian_Ocean": "1n7,0,3nd,0,1d|||",
                "Indochina": "23t,0,1d|||",
                "Indonesia_Central": "228,5,1f1,0,1d|||",
                "Indonesia_Eastern": "1j7,5,1f1,0,1d|||",
                "Indonesia_Western": "16s,5,1f1,0,1d|||",
                "Iran": "5g,0,1a,0,1d|5g,0,3o,0,40,0,3v||",
                "Irkutsk": "1oi,0,1a,0,1d|1oi,0,3o,0,40,0,3v||",
                "Israel": "1hm,0,1a,0,1d|1hm,0,3o,0,40,0,3v||",
                "Japan": "1hl,0,1a,0,1d|1hl,0,3o,0,40,0,3v||",
                "Kamchatka": "1gd,5,1i4,0,1a,0,1d|1gd,5,1i4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "1j7,5,1s3,0,1d|||",
                "Kazakhstan_Western": "16s,5,1s3,0,1d|||",
                "Korea": "1hk,0,1a,0,1d|1hk,0,3o,0,40,0,3v||",
                "Kosrae": "3na,0,1d|||",
                "Krasnoyarsk": "l0,0,1a,0,1d|l0,0,3o,0,40,0,3v||",
                "Kyrgystan": "1qu,0,1d|||",
                "Lanka": "3n9,0,9c,0,1d|||",
                "Line_Islands": "ok,0,2bp,0,1d|||",
                "Lord_Howe": "1u,0,1sd,0,1a,0,1d|1u,0,1sd,0,3o,0,40,0,3v||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "1sh,0,3n6,0,1d|||",
                "Magadan": "2o,0,1a,0,1d|2o,0,3o,0,40,0,3v||",
                "Malaysia": "3n5,0,1d|||",
                "Maldives": "3n4,0,1d|||",
                "Marquesas": "1jm,0,1d|||",
                "Marshall_Islands": "3n3,0,ok,0,1d|||",
                "Mauritius": "1te,0,1a,0,1d|1te,0,3o,0,40,0,3v||",
                "Mawson": "3n1,0,1d|||",
                "Mexico_Northwest": "1tn,0,17k,0,1a,0,1d|1tn,0,17k,0,3o,0,40,0,3v||",
                "Mexico_Pacific": "1ts,0,1tu,0,1a,0,1d|1ts,0,1tu,0,3o,0,40,0,3v||",
                "Mongolia": "4f,0,2m,0,1a,0,1d|4f,0,2m,0,3o,0,40,0,3v||",
                "Moscow": "pi,0,1a,0,1d|pi,0,3o,0,40,0,3v||",
                "Myanmar": "3mq,0,1d|||",
                "Nauru": "5v,0,1d|||",
                "Nepal": "8u,0,1d|||",
                "New_Caledonia": "ba,0,1tp,0,1a,0,1d|ba,0,1tp,0,3o,0,40,0,3v||",
                "New_Zealand": "1tv,0,1p4,0,1a,0,1d|1tv,0,1p4,0,3o,0,40,0,3v||",
                "Newfoundland": "1u0,0,1a,0,1d|1u0,0,3o,0,40,0,3v||",
                "Niue": "8g,0,1d|||",
                "Norfolk": "6l,0,1sh,0,1d|||",
                "Noronha": "1s,0,2,0,1hg,0,1a,0,1d|1s,0,2,0,1hg,0,3o,0,40,0,3v||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "3b,0,1a,0,1d|3b,0,3o,0,40,0,3v||",
                "Omsk": "26,0,1a,0,1d|26,0,3o,0,40,0,3v||",
                "Pakistan": "3i,0,1a,0,1d|3i,0,3o,0,40,0,3v||",
                "Palau": "9b,0,1d|||",
                "Papua_New_Guinea": "9a,0,ba,0,3ma,0,1d|||",
                "Paraguay": "1u2,0,1a,0,1d|1u2,0,3o,0,40,0,3v||",
                "Peru": "4n,0,1a,0,1d|4n,0,3o,0,40,0,3v||",
                "Philippines": "1u3,0,1a,0,1d|1u3,0,3o,0,40,0,3v||",
                "Phoenix_Islands": "1a7,0,10u,0,1d|||",
                "Pierre_Miquelon": "14i,0,1he,0,i3,0,u2,0,1a,0,1d|14i,0,1he,0,i3,0,u2,0,3o,0,40,0,3v||",
                "Pitcairn": "1ko,0,1d|||",
                "Ponape": "3m6,0,1d|||",
                "Pyongyang": "2b0,0,1d|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "3m1,0,1d|||",
                "Rothera": "1po,0,1d|||",
                "Sakhalin": "164,0,1a,0,1d|164,0,3o,0,40,0,3v||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "2c,0,1a,0,1d|2c,0,3o,0,40,0,3v||",
                "Seychelles": "3m0,0,1d|||",
                "Singapore": "m8,0,1a,0,1d|||",
                "Solomon": "3lv,0,ok,0,1d|||",
                "South_Georgia": "3lu,0,3lr,0,1d|||",
                "Suriname": "mg,0,1d|||",
                "Syowa": "3lo,0,1d|||",
                "Tahiti": "8n,0,1d|||",
                "Taipei": "1pc,0,1a,0,1d|1pc,0,3o,0,1d||",
                "Tajikistan": "3l4,0,1d|||",
                "Tokelau": "5d,0,1d|||",
                "Tonga": "2j,0,1a,0,1d|2j,0,3o,0,40,0,3v||",
                "Truk": "3kt,0,1d|||",
                "Turkmenistan": "4t,0,1a,0,1d|4t,0,3o,0,40,0,3v||",
                "Tuvalu": "6h,0,1d|||",
                "Uruguay": "1vq,0,1a,0,1d|1vq,0,3o,0,40,0,3v||",
                "Uzbekistan": "60,0,1a,0,1d|60,0,3o,0,40,0,3v||",
                "Vanuatu": "2f,0,1a,0,1d|2f,0,3o,0,40,0,3v||",
                "Venezuela": "3kr,0,1d|||",
                "Vladivostok": "28,0,1a,0,1d|28,0,3o,0,40,0,3v||",
                "Volgograd": "3f,0,1a,0,1d|3f,0,3o,0,40,0,3v||",
                "Vostok": "5t,0,1d|||",
                "Wake": "2ar,0,1ud,0,1d|||",
                "Wallis": "2aq,0,i3,0,5k,0,ok,0,1d|||",
                "Yakutsk": "qj,0,1a,0,1d|qj,0,3o,0,40,0,3v||",
                "Yekaterinburg": "q6,0,1a,0,1d|q6,0,3o,0,40,0,3v||"
            }
        },
        "sr-Latn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3gg,0,1a,0,1d||",
                "Europe/London": "|3ge,0,3o,0,40,0,3v||"
            },
            "metazone": {
                "Acre": "22d,0,1a,0,1d|22d,0,3o,0,40,0,3v||",
                "Afghanistan": "3p0,0,1d|||",
                "Africa_Central": "228,5,14v,0,1d|||",
                "Africa_Eastern": "1j7,5,14v,0,1d|||",
                "Africa_Southern": "3os,5,14v,0,1d|||",
                "Africa_Western": "16s,5,14v,0,1a,0,1d|16s,5,14v,0,3o,0,40,0,3v||",
                "Alaska": "216,k,0,1a,0,1d|216,k,0,3o,0,40,0,3v||",
                "Almaty": "1nf,0,1a,0,1d|1nf,0,3o,0,40,0,3v||",
                "Amazon": "5m,0,1a,0,1d|5m,0,3o,0,40,0,3v||",
                "America_Central": "qu,0,ug,0,1a,0,1d|qu,0,ug,0,3o,0,1d||",
                "America_Eastern": "qu,0,111,0,1a,0,1d|qu,0,111,0,3o,0,1d||",
                "America_Mountain": "qu,0,17r,0,1a,0,1d|qu,0,17r,0,3o,0,1d||",
                "America_Pacific": "qu,0,110,0,1a,0,1d|qu,0,110,0,3o,0,1d||",
                "Anadyr": "1eh,0,1a,0,1d|1eh,0,3o,0,40,0,3v||",
                "Apia": "20v,0,1a,0,1d|20v,0,3o,0,40,0,3v||",
                "Aqtau": "20p,0,1a,0,1d|20p,0,3o,0,40,0,3v||",
                "Aqtobe": "20n,0,1a,0,1d|20n,0,3o,0,40,0,3v||",
                "Arabian": "1i1,0,1a,0,1d|1i1,0,3o,0,40,0,3v||",
                "Argentina": "1e,0,1a,0,1d|1e,0,3o,0,40,0,3v||",
                "Argentina_Western": "20a,0,1e,0,1a,0,1d|20a,0,1e,0,3o,0,40,0,3v||",
                "Armenia": "1vn,0,1a,0,1d|1vn,0,3o,0,40,0,3v||",
                "Atlantic": "1vd,0,1a,0,1d|1vd,0,3o,0,40,0,3v||",
                "Australia_Central": "pp,0,ug,0,1a,0,1d|pp,0,ug,0,3o,0,40,0,3v||",
                "Australia_CentralWestern": "pp,0,ug,0,11c,0,1a,0,1d|pp,0,ug,0,11c,0,3o,0,40,0,3v||",
                "Australia_Eastern": "pp,0,111,0,1a,0,1d|pp,0,111,0,3o,0,40,0,3v||",
                "Australia_Western": "pp,0,11c,0,1a,0,1d|pp,0,11c,0,3o,0,40,0,3v||",
                "Azerbaijan": "1v8,0,1a,0,1d|1v8,0,3o,0,40,0,3v||",
                "Azores": "1uu,0,1a,0,1d|1uu,0,3o,0,40,0,3v||",
                "Bangladesh": "1ut,0,1a,0,1d|1ut,0,3o,0,40,0,3v||",
                "Bhutan": "1k4,0,1d|||",
                "Bolivia": "3of,0,1d|||",
                "Brasilia": "1ur,0,1a,0,1d|1ur,0,3o,0,40,0,3v||",
                "Brunei": "2kg,0,3od,0,1d|||",
                "Cape_Verde": "1uq,0,ok,0,1a,0,1d|1uq,0,ok,0,3o,0,40,0,3v||",
                "Chamorro": "2nf,0,1d|||",
                "Chatham": "1um,0,1a,0,1d|1um,0,3o,0,40,0,3v||",
                "Chile": "1uk,0,1a,0,1d|1uk,0,3o,0,40,0,3v||",
                "China": "23o,0,1a,0,1d|25m,0,3o,0,40,0,3v||",
                "Choibalsan": "1uh,0,1a,0,1d|1uh,0,3o,0,40,0,3v||",
                "Christmas": "3o8,0,1ud,0,1d|||",
                "Cocos": "1eg,0,3o6,0,ok,0,1d|||",
                "Colombia": "1ro,0,1a,0,1d|1ro,0,3o,0,40,0,3v||",
                "Cook": "1rh,0,10u,0,1a,0,1d|1rh,0,10u,0,3o2,5,3o,0,40,0,3v||",
                "Cuba": "tb,0,1a,0,1d|tb,0,3o,0,40,0,3v||",
                "Davis": "3o1,0,1d|||",
                "DumontDUrville": "3o0,0,3nv,0,1d|||",
                "East_Timor": "1fl,0,3nt,0,1d|||",
                "Easter": "24r,0,10u,0,1a,0,1d|24r,0,10u,0,3o,0,40,0,3v||",
                "Ecuador": "1k0,0,1d|||",
                "Europe_Central": "23r,0,1a,0,1d|23r,0,3o,0,40,0,3v|h0|h4",
                "Europe_Eastern": "1hr,0,1a,0,1d|1hr,0,3o,0,40,0,3v|gu|gv",
                "Europe_Further_Eastern": "3nq,0,3np,0,3no|||",
                "Europe_Western": "1hq,0,1a,0,1d|1hq,0,3o,0,40,0,3v|h1|h2",
                "Falkland": "25c,0,ok,0,1a,0,1d|25c,0,ok,0,3o,0,40,0,3v||",
                "Fiji": "1fh,0,1a,0,1d|1fh,0,3o,0,40,0,3v||",
                "French_Guiana": "2n4,0,1pt,0,1d|||",
                "French_Southern": "3nm,0,3nl,0,i3,0,2l1,0,1d|||",
                "Galapagos": "ak,0,1d|||",
                "Gambier": "3nk,0,1d|||",
                "Georgia": "1qt,0,1a,0,1d|1qt,0,3o,0,40,0,3v||",
                "Gilbert_Islands": "6q,0,10u,0,1d|||",
                "GMT": "3ni,0,1d,0,3nh,0,3ng||ah|",
                "Greenland_Eastern": "1fl,0,18g,0,1a,0,1d|1fl,0,18g,0,3o,0,40,0,3v||",
                "Greenland_Western": "1rj,0,18g,0,1a,0,1d|1rj,0,18g,0,3o,0,40,0,3v||",
                "Guam": "83,0,1a,0,1d|||",
                "Gulf": "2cb,0,1d|||",
                "Guyana": "1pt,0,1d|||",
                "Hawaii_Aleutian": "1c5,5,1k2,0,1a,0,1d|1c5,5,1k2,0,3o,0,40,0,3v||",
                "Hong_Kong": "2l,0,2p,0,1a,0,1d|2l,0,2p,0,3o,0,40,0,3v||",
                "Hovd": "2g,0,1a,0,1d|2g,0,3o,0,40,0,3v||",
                "India": "1n7,0,1a,0,1d|||",
                "Indian_Ocean": "1n7,0,3nd,0,1d|||",
                "Indochina": "23t,0,1d|||",
                "Indonesia_Central": "228,5,1f1,0,1d|||",
                "Indonesia_Eastern": "1j7,5,1f1,0,1d|||",
                "Indonesia_Western": "16s,5,1f1,0,1d|||",
                "Iran": "5g,0,1a,0,1d|5g,0,3o,0,40,0,3v||",
                "Irkutsk": "1oi,0,1a,0,1d|1oi,0,3o,0,40,0,3v||",
                "Israel": "1hm,0,1a,0,1d|1hm,0,3o,0,40,0,3v||",
                "Japan": "1hl,0,1a,0,1d|1hl,0,3o,0,40,0,3v||",
                "Kamchatka": "1gd,5,1i4,0,1a,0,1d|1gd,5,1i4,0,3o,0,40,0,3v||",
                "Kazakhstan_Eastern": "1j7,5,1s3,0,1d|||",
                "Kazakhstan_Western": "16s,5,1s3,0,1d|||",
                "Korea": "1hk,0,1a,0,1d|1hk,0,3o,0,40,0,3v||",
                "Kosrae": "3na,0,1d|||",
                "Krasnoyarsk": "l0,0,1a,0,1d|l0,0,3o,0,40,0,3v||",
                "Kyrgystan": "1qu,0,1d|||",
                "Lanka": "3n9,0,9c,0,1d|||",
                "Line_Islands": "ok,0,2bp,0,1d|||",
                "Lord_Howe": "1u,0,1sd,0,1a,0,1d|1u,0,1sd,0,3o,0,40,0,3v||",
                "Macau": "176,0,1a,0,1d|176,0,3o,0,40,0,3v||",
                "Macquarie": "1sh,0,3n6,0,1d|||",
                "Magadan": "2o,0,1a,0,1d|2o,0,3o,0,40,0,3v||",
                "Malaysia": "3n5,0,1d|||",
                "Maldives": "3n4,0,1d|||",
                "Marquesas": "1jm,0,1d|||",
                "Marshall_Islands": "3n3,0,ok,0,1d|||",
                "Mauritius": "1te,0,1a,0,1d|1te,0,3o,0,40,0,3v||",
                "Mawson": "3n1,0,1d|||",
                "Mexico_Northwest": "1tn,0,17k,0,1a,0,1d|1tn,0,17k,0,3o,0,40,0,3v||",
                "Mexico_Pacific": "1ts,0,1tu,0,1a,0,1d|1ts,0,1tu,0,3o,0,40,0,3v||",
                "Mongolia": "4f,0,2m,0,1a,0,1d|4f,0,2m,0,3o,0,40,0,3v||",
                "Moscow": "pi,0,1a,0,1d|pi,0,3o,0,40,0,3v||",
                "Myanmar": "3mq,0,1d|||",
                "Nauru": "5v,0,1d|||",
                "Nepal": "8u,0,1d|||",
                "New_Caledonia": "ba,0,1tp,0,1a,0,1d|ba,0,1tp,0,3o,0,40,0,3v||",
                "New_Zealand": "1tv,0,1p4,0,1a,0,1d|1tv,0,1p4,0,3o,0,40,0,3v||",
                "Newfoundland": "1u0,0,1a,0,1d|1u0,0,3o,0,40,0,3v||",
                "Niue": "8g,0,1d|||",
                "Norfolk": "6l,0,1sh,0,1d|||",
                "Noronha": "1s,0,2,0,1hg,0,1a,0,1d|1s,0,2,0,1hg,0,3o,0,40,0,3v||",
                "North_Mariana": "2bc,0,2ba,0,ok,0,1d|||",
                "Novosibirsk": "3b,0,1a,0,1d|3b,0,3o,0,40,0,3v||",
                "Omsk": "26,0,1a,0,1d|26,0,3o,0,40,0,3v||",
                "Pakistan": "3i,0,1a,0,1d|3i,0,3o,0,40,0,3v||",
                "Palau": "9b,0,1d|||",
                "Papua_New_Guinea": "9a,0,ba,0,3ma,0,1d|||",
                "Paraguay": "1u2,0,1a,0,1d|1u2,0,3o,0,40,0,3v||",
                "Peru": "4n,0,1a,0,1d|4n,0,3o,0,40,0,3v||",
                "Philippines": "1u3,0,1a,0,1d|1u3,0,3o,0,40,0,3v||",
                "Phoenix_Islands": "1a7,0,10u,0,1d|||",
                "Pierre_Miquelon": "14i,0,1he,0,i3,0,u2,0,1a,0,1d|14i,0,1he,0,i3,0,u2,0,3o,0,40,0,3v||",
                "Pitcairn": "1ko,0,1d|||",
                "Ponape": "3m6,0,1d|||",
                "Pyongyang": "2b0,0,1d|||",
                "Qyzylorda": "1hd,0,1a,0,1d|1hd,0,3o,0,40,0,3v||",
                "Reunion": "3m1,0,1d|||",
                "Rothera": "1po,0,1d|||",
                "Sakhalin": "164,0,1a,0,1d|164,0,3o,0,40,0,3v||",
                "Samara": "29,0,1a,0,1d|29,0,3o,0,40,0,3v||",
                "Samoa": "2c,0,1a,0,1d|2c,0,3o,0,40,0,3v||",
                "Seychelles": "3m0,0,1d|||",
                "Singapore": "m8,0,1a,0,1d|||",
                "Solomon": "3lv,0,ok,0,1d|||",
                "South_Georgia": "3lu,0,3lr,0,1d|||",
                "Suriname": "mg,0,1d|||",
                "Syowa": "3lo,0,1d|||",
                "Tahiti": "8n,0,1d|||",
                "Taipei": "1pc,0,1a,0,1d|1pc,0,3o,0,1d||",
                "Tajikistan": "3l4,0,1d|||",
                "Tokelau": "5d,0,1d|||",
                "Tonga": "2j,0,1a,0,1d|2j,0,3o,0,40,0,3v||",
                "Truk": "3kt,0,1d|||",
                "Turkmenistan": "4t,0,1a,0,1d|4t,0,3o,0,40,0,3v||",
                "Tuvalu": "6h,0,1d|||",
                "Uruguay": "1vq,0,1a,0,1d|1vq,0,3o,0,40,0,3v||",
                "Uzbekistan": "60,0,1a,0,1d|60,0,3o,0,40,0,3v||",
                "Vanuatu": "2f,0,1a,0,1d|2f,0,3o,0,40,0,3v||",
                "Venezuela": "3kr,0,1d|||",
                "Vladivostok": "28,0,1a,0,1d|28,0,3o,0,40,0,3v||",
                "Volgograd": "3f,0,1a,0,1d|3f,0,3o,0,40,0,3v||",
                "Vostok": "5t,0,1d|||",
                "Wake": "2ar,0,1ud,0,1d|||",
                "Wallis": "2aq,0,i3,0,5k,0,ok,0,1d|||",
                "Yakutsk": "qj,0,1a,0,1d|qj,0,3o,0,40,0,3v||",
                "Yekaterinburg": "q6,0,1a,0,1d|q6,0,3o,0,40,0,3v||"
            }
        },
        "sr": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tc,0,1q,0,m||",
                "Europe/London": "|2me,0,20,0,2b,0,2d||"
            },
            "metazone": {
                "Acre": "1ed,0,1q,0,m|1ed,0,20,0,2b,0,2d||",
                "Afghanistan": "2ak,0,m|||",
                "Africa_Central": "18j,5,rt,0,m|||",
                "Africa_Eastern": "12g,5,rt,0,m|||",
                "Africa_Southern": "2j9,5,rt,0,m|||",
                "Africa_Western": "11m,5,rt,0,1q,0,m|11m,5,rt,0,20,0,2b,0,2d||",
                "Alaska": "1jf,k,0,1q,0,m|1jf,k,0,20,0,2b,0,2d||",
                "Almaty": "1ja,0,1q,0,m|1ja,0,20,0,2b,0,2d||",
                "Amazon": "15q,0,1q,0,m|15q,0,20,0,2b,0,2d||",
                "America_Central": "p7,0,p6,0,1q,0,m|p7,0,p6,0,20,0,m||",
                "America_Eastern": "p7,0,139,0,1q,0,m|p7,0,139,0,20,0,m||",
                "America_Mountain": "p7,0,1gj,0,1q,0,m|p7,0,1gj,0,20,0,m||",
                "America_Pacific": "p7,0,1o4,0,1q,0,m|p7,0,1o4,0,20,0,m||",
                "Anadyr": "18u,0,1q,0,m|18u,0,20,0,2b,0,2d||",
                "Apia": "1jh,0,1q,0,m|1jh,0,20,0,2b,0,2d||",
                "Aqtau": "1j9,0,1q,0,m|1j9,0,20,0,2b,0,2d||",
                "Aqtobe": "1j8,0,1q,0,m|1j8,0,20,0,2b,0,2d||",
                "Arabian": "1j6,0,1q,0,m|1j6,0,20,0,2b,0,2d||",
                "Argentina": "hk,0,1q,0,m|hk,0,20,0,2b,0,2d||",
                "Argentina_Western": "16h,0,hk,0,1q,0,m|16h,0,hk,0,20,0,2b,0,2d||",
                "Armenia": "1o5,0,1q,0,m|1o5,0,20,0,2b,0,2d||",
                "Atlantic": "1ji,0,1q,0,m|1ji,0,20,0,2b,0,2d||",
                "Australia_Central": "mp,0,p6,0,1q,0,m|mp,0,p6,0,20,0,2b,0,2d||",
                "Australia_CentralWestern": "mp,0,p6,0,uu,0,1q,0,m|mp,0,p6,0,uu,0,20,0,2b,0,2d||",
                "Australia_Eastern": "mp,0,139,0,1q,0,m|mp,0,139,0,20,0,2b,0,2d||",
                "Australia_Western": "mp,0,uu,0,1q,0,m|mp,0,uu,0,20,0,2b,0,2d||",
                "Azerbaijan": "1b0,0,1q,0,m|1b0,0,20,0,2b,0,2d||",
                "Azores": "1j5,0,1q,0,m|1j5,0,20,0,2b,0,2d||",
                "Bangladesh": "q8,0,1q,0,m|q8,0,20,0,2b,0,2d||",
                "Bhutan": "16f,0,m|||",
                "Bolivia": "27a,0,m|||",
                "Brasilia": "1bo,0,1q,0,m|1bo,0,20,0,2b,0,2d||",
                "Brunei": "2id,0,2ic,0,m|||",
                "Cape_Verde": "1o8,0,ir,0,1q,0,m|1o8,0,ir,0,20,0,2b,0,2d||",
                "Chamorro": "1u7,0,m|||",
                "Chatham": "1bu,0,1q,0,m|1bu,0,20,0,2b,0,2d||",
                "Chile": "1c0,0,1q,0,m|1c0,0,20,0,2b,0,2d||",
                "China": "2i5,0,1q,0,m|22t,0,20,0,2b,0,2d||",
                "Choibalsan": "1c6,0,1q,0,m|1c6,0,20,0,2b,0,2d||",
                "Christmas": "2p0,0,1ka,0,m|||",
                "Cocos": "1le,0,2hm,0,ir,0,m|||",
                "Colombia": "1ck,0,1q,0,m|1ck,0,20,0,2b,0,2d||",
                "Cook": "1j4,0,pm,0,1q,0,m|1j4,0,pm,0,2hc,5,20,0,2b,0,2d||",
                "Cuba": "ql,0,1q,0,m|ql,0,20,0,2b,0,2d||",
                "Davis": "277,0,m|||",
                "DumontDUrville": "276,0,2gp,0,m|||",
                "East_Timor": "15u,0,2gm,0,m|||",
                "Easter": "1j2,0,pm,0,1q,0,m|1j2,0,pm,0,20,0,2b,0,2d||",
                "Ecuador": "274,0,m|||",
                "Europe_Central": "1j1,0,1q,0,m|1j1,0,20,0,2b,0,2d|h0|h4",
                "Europe_Eastern": "1dh,0,1q,0,m|1dh,0,20,0,2b,0,2d|gu|gv",
                "Europe_Further_Eastern": "2p1,0,2p2,0,2p3|||",
                "Europe_Western": "1dj,0,1q,0,m|1dj,0,20,0,2b,0,2d|h1|h2",
                "Falkland": "1iv,0,ir,0,1q,0,m|1iv,0,ir,0,20,0,2b,0,2d||",
                "Fiji": "1dn,0,1q,0,m|1dn,0,20,0,2b,0,2d||",
                "French_Guiana": "272,0,1dr,0,m|||",
                "French_Southern": "26t,0,26s,0,il,0,26r,0,m|||",
                "Galapagos": "1na,0,m|||",
                "Gambier": "2p5,0,m|||",
                "Georgia": "19a,0,1q,0,m|19a,0,20,0,2b,0,2d||",
                "Gilbert_Islands": "1ne,0,pm,0,m|||",
                "GMT": "2p6,0,m,0,1jo,0,2p7||ah|",
                "Greenland_Eastern": "15u,0,ur,0,1q,0,m|15u,0,ur,0,20,0,2b,0,2d||",
                "Greenland_Western": "1is,0,ur,0,1q,0,m|1is,0,ur,0,20,0,2b,0,2d||",
                "Guam": "1ir,0,1q,0,m|||",
                "Gulf": "2pb,0,m|||",
                "Guyana": "1dr,0,m|||",
                "Hawaii_Aleutian": "1iq,5,1ae,0,1q,0,m|1iq,5,1ae,0,20,0,2b,0,2d||",
                "Hong_Kong": "19c,0,1eu,0,1q,0,m|19c,0,1eu,0,20,0,2b,0,2d||",
                "Hovd": "qg,0,1q,0,m|qg,0,20,0,2b,0,2d||",
                "India": "1in,0,1q,0,m|||",
                "Indian_Ocean": "1in,0,2ep,0,m|||",
                "Indochina": "26p,0,m|||",
                "Indonesia_Central": "18j,5,160,0,m|||",
                "Indonesia_Eastern": "12g,5,160,0,m|||",
                "Indonesia_Western": "11m,5,160,0,m|||",
                "Iran": "rr,0,1q,0,m|rr,0,20,0,2b,0,2d||",
                "Irkutsk": "1im,0,1q,0,m|1im,0,20,0,2b,0,2d||",
                "Israel": "1cq,0,1q,0,m|1cq,0,20,0,2b,0,2d||",
                "Japan": "1il,0,1q,0,m|1il,0,20,0,2b,0,2d||",
                "Kamchatka": "1ik,5,1ij,0,1q,0,m|1ik,5,1ij,0,20,0,2b,0,2d||",
                "Kazakhstan_Eastern": "12g,5,1ih,0,m|||",
                "Kazakhstan_Western": "11m,5,1ih,0,m|||",
                "Korea": "1ig,0,1q,0,m|1ig,0,20,0,2b,0,2d||",
                "Kosrae": "2ec,0,m|||",
                "Krasnoyarsk": "1af,0,1q,0,m|1af,0,20,0,2b,0,2d||",
                "Kyrgystan": "2bo,0,m|||",
                "Lanka": "1if,0,1et,0,m|||",
                "Line_Islands": "ir,0,2pc,0,m|||",
                "Lord_Howe": "oi,0,1id,0,1q,0,m|oi,0,1id,0,20,0,2b,0,2d||",
                "Macau": "118,0,1q,0,m|118,0,20,0,2b,0,2d||",
                "Macquarie": "1k9,0,2pe,0,m|||",
                "Magadan": "qq,0,1q,0,m|qq,0,20,0,2b,0,2d||",
                "Malaysia": "26n,0,m|||",
                "Maldives": "26m,0,m|||",
                "Marquesas": "26l,0,m|||",
                "Marshall_Islands": "2e5,0,ir,0,m|||",
                "Mauritius": "1ib,0,1q,0,m|1ib,0,20,0,2b,0,2d||",
                "Mawson": "26k,0,m|||",
                "Mexico_Northwest": "1od,0,187,0,1q,0,m|1od,0,187,0,20,0,2b,0,2d||",
                "Mexico_Pacific": "1oe,0,1of,0,1q,0,m|1oe,0,1of,0,20,0,2b,0,2d||",
                "Mongolia": "or,0,qo,0,1q,0,m|or,0,qo,0,20,0,2b,0,2d||",
                "Moscow": "r6,0,1q,0,m|r6,0,20,0,2b,0,2d||",
                "Myanmar": "2e2,0,m|||",
                "Nauru": "14s,0,m|||",
                "Nepal": "16d,0,m|||",
                "New_Caledonia": "125,0,1bq,0,1q,0,m|125,0,1bq,0,20,0,2b,0,2d||",
                "New_Zealand": "1i9,0,1bs,0,1q,0,m|1i9,0,1bs,0,20,0,2b,0,2d||",
                "Newfoundland": "1bt,0,1q,0,m|1bt,0,20,0,2b,0,2d||",
                "Niue": "1q4,0,m|||",
                "Norfolk": "146,0,1k9,0,m|||",
                "Noronha": "138,0,13a,0,1bv,0,1q,0,m|138,0,13a,0,1bv,0,20,0,2b,0,2d||",
                "North_Mariana": "26j,0,2dt,0,ir,0,m|||",
                "Novosibirsk": "qt,0,1q,0,m|qt,0,20,0,2b,0,2d||",
                "Omsk": "r5,0,1q,0,m|r5,0,20,0,2b,0,2d||",
                "Pakistan": "rq,0,1q,0,m|rq,0,20,0,2b,0,2d||",
                "Palau": "159,0,m|||",
                "Papua_New_Guinea": "13k,0,125,0,26i,0,m|||",
                "Paraguay": "1ca,0,1q,0,m|1ca,0,20,0,2b,0,2d||",
                "Peru": "q1,0,1q,0,m|q1,0,20,0,2b,0,2d||",
                "Philippines": "1cg,0,1q,0,m|1cg,0,20,0,2b,0,2d||",
                "Phoenix_Islands": "158,0,pm,0,m|||",
                "Pierre_Miquelon": "pr,0,1ci,0,il,0,qp,0,1q,0,m|pr,0,1ci,0,il,0,qp,0,20,0,2b,0,2d||",
                "Pitcairn": "1q6,0,m|||",
                "Ponape": "2pk,0,m|||",
                "Pyongyang": "2pl,0,m|||",
                "Qyzylorda": "1cj,0,1q,0,m|1cj,0,20,0,2b,0,2d||",
                "Reunion": "2do,0,m|||",
                "Rothera": "157,0,m|||",
                "Sakhalin": "r8,0,1q,0,m|r8,0,20,0,2b,0,2d||",
                "Samara": "17o,0,1q,0,m|17o,0,20,0,2b,0,2d||",
                "Samoa": "p0,0,1q,0,m|p0,0,20,0,2b,0,2d||",
                "Seychelles": "26h,0,m|||",
                "Singapore": "16b,0,1q,0,m|||",
                "Solomon": "2dh,0,ir,0,m|||",
                "South_Georgia": "26g,0,2d8,0,m|||",
                "Suriname": "18v,0,m|||",
                "Syowa": "28v,0,m|||",
                "Tahiti": "26f,0,m|||",
                "Taipei": "1oh,0,1q,0,m|1oh,0,20,0,m||",
                "Tajikistan": "26e,0,m|||",
                "Tokelau": "156,0,m|||",
                "Tonga": "ot,0,1q,0,m|ot,0,20,0,2b,0,2d||",
                "Truk": "1f2,0,m|||",
                "Turkmenistan": "1db,0,1q,0,m|1db,0,20,0,2b,0,2d||",
                "Tuvalu": "14j,0,m|||",
                "Uruguay": "1dc,0,1q,0,m|1dc,0,20,0,2b,0,2d||",
                "Uzbekistan": "108,0,1q,0,m|108,0,20,0,2b,0,2d||",
                "Vanuatu": "os,0,1q,0,m|os,0,20,0,2b,0,2d||",
                "Venezuela": "26c,0,m|||",
                "Vladivostok": "qn,0,1q,0,m|qn,0,20,0,2b,0,2d||",
                "Volgograd": "q9,0,1q,0,m|q9,0,20,0,2b,0,2d||",
                "Vostok": "155,0,m|||",
                "Wake": "26b,0,1ka,0,m|||",
                "Wallis": "26a,0,il,0,14l,0,ir,0,m|||",
                "Yakutsk": "1do,0,1q,0,m|1do,0,20,0,2b,0,2d||",
                "Yekaterinburg": "1i3,0,1q,0,m|1i3,0,20,0,2b,0,2d||"
            }
        },
        "sv-AX": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4p8,0,6c||",
                "Europe/London": "|4pa,0,6c||",
                "Pacific/Honolulu": "||4pb|4pf"
            },
            "metazone": {
                "Acre": "2de,0,1l|2de,0,6c||",
                "Afghanistan": "2qd,0,4j|||",
                "Africa_Central": "4m3,0,4j|||",
                "Africa_Eastern": "4m4,0,4j|||",
                "Africa_Southern": "4m5,0,4j|||",
                "Africa_Western": "2cv,0,1l|2cv,0,6c||",
                "Alaska": "3p,k,0,1l|3p,k,0,6c||",
                "Almaty": "4m7|4m8||",
                "Amazon": "bg,k,0,1l|bg,k,0,6c||",
                "America_Central": "2cs,0,1l|2cs,0,6c||",
                "America_Eastern": "2cn,0,1l|2cn,0,6c||",
                "America_Mountain": "2ce,0,2cd,k,0,1l|2ce,0,2cd,k,0,6c||",
                "America_Pacific": "2cc,0,1l|2cc,0,6c||",
                "Anadyr": "4m9|4mb||",
                "Apia": "3e,k,0,1l|3e,k,0,6c||",
                "Aqtau": "4mc|4md||",
                "Aqtobe": "4me|4mf||",
                "Arabian": "2ca,0,1l|2ca,0,6c||",
                "Argentina": "2c9,0,1l|2c9,0,6c||",
                "Argentina_Western": "2c8,0,1l|2c8,0,6c||",
                "Armenia": "2c7,0,1l|2c7,0,6c||",
                "Atlantic": "2c6,0,4mg|2c6,0,4mh||",
                "Australia_Central": "2c2,0,1l|2c2,0,6c||",
                "Australia_CentralWestern": "2c1,0,1l|2c1,0,6c||",
                "Australia_Eastern": "2c0,0,1l|2c0,0,6c||",
                "Australia_Western": "2bv,0,1l|2bv,0,6c||",
                "Azerbaijan": "2bu,0,1l|2bu,0,6c||",
                "Azores": "2bt,0,1l|2bt,0,6c||",
                "Bangladesh": "1o1,0,1l|1o1,0,6c||",
                "Bhutan": "2vt,0,4j|||",
                "Bolivia": "2vl,0,4j|||",
                "Brasilia": "3a,k,0,1l|3a,k,0,6c||",
                "Brunei": "4mi|||",
                "Cape_Verde": "1l8,0,3j,k,0,1l|1l8,0,3j,k,0,6c||",
                "Casey": "4mj|||",
                "Chamorro": "4mk|||",
                "Chatham": "27,k,0,1l|27,k,0,6c||",
                "Chile": "1ng,0,1l|1ng,0,6c||",
                "China": "1la,0,1l|1la,0,6c||",
                "Choibalsan": "2bs,k,0,1l|2bs,k,0,6c||",
                "Christmas": "4ml,0,4j|||",
                "Cocos": "4mo,0,4j|||",
                "Colombia": "1nv,0,1l|1nv,0,6c||",
                "Cook": "2bq,0,1l|2bq,0,6c||",
                "Cuba": "2bn,0,1l|2bn,0,6c||",
                "Davis": "4s2|||",
                "DumontDUrville": "5b,0,5j,5,4j|||",
                "East_Timor": "4mq,0,4j|||",
                "Easter": "2bm,k,0,1l|2bm,k,0,6c||",
                "Ecuador": "2ug,0,4j|||",
                "Europe_Central": "2bl,0,1l|2bl,0,6c|h0|h4",
                "Europe_Eastern": "2bj,0,1l|2bj,0,6c|gu|gv",
                "Europe_Further_Eastern": "4mr|||",
                "Europe_Western": "2bh,0,1l|2bh,0,6c|h1|h2",
                "Falkland": "2bf,k,0,1l|2bf,k,0,6c||",
                "Fiji": "4q,k,0,1l|4q,k,0,6c||",
                "French_Guiana": "25t,0,2be|||",
                "French_Southern": "25t,0,4ms,0,4j|||",
                "Galapagos": "4mt|||",
                "Gambier": "4mv|||",
                "Georgia": "1ol,0,1l|1ol,0,6c||",
                "Gilbert_Islands": "4n0|||",
                "GMT": "4n1||ah|",
                "Greenland_Eastern": "2b9,0,1l|2b9,0,6c||",
                "Greenland_Western": "2b6,0,1l|2b6,0,6c||",
                "Guam": "4n2|||",
                "Gulf": "4n5,0,4n8|||",
                "Guyana": "2be|||",
                "Hawaii_Aleutian": "2b3,k,0,1l|2b3,k,0,6c||",
                "Hong_Kong": "qh,k,0,1l|qh,k,0,6c||",
                "Hovd": "2b2,k,0,1l|2b2,k,0,6c||",
                "India": "2n9,0,4j|||",
                "Indian_Ocean": "4n9,0,4na,0,4nb,0,4j|||",
                "Indochina": "31n,0,4j|||",
                "Indonesia_Central": "4nc,0,4j|||",
                "Indonesia_Eastern": "4ne,0,4j|||",
                "Indonesia_Western": "4nf,0,4j|||",
                "Iran": "1me,0,1l|1me,0,6c||",
                "Irkutsk": "3h,k,0,1l|3h,k,0,6c||",
                "Israel": "2an,0,1l|2an,0,6c||",
                "Japan": "1l9,0,1l|1l9,0,6c||",
                "Kamchatka": "4ng|4nh||",
                "Kazakhstan_Eastern": "4nm,0,4j|||",
                "Kazakhstan_Western": "4nn,0,4j|||",
                "Korea": "1pa,0,1l|1pa,0,6c||",
                "Kosrae": "4np|||",
                "Krasnoyarsk": "l0,k,0,1l|l0,k,0,6c||",
                "Kyrgystan": "4nq,0,4j|||",
                "Lanka": "me,0,4nr|||",
                "Line_Islands": "4ns,0,4j|||",
                "Lord_Howe": "1u,0,24,k,0,1l|1u,0,24,k,0,6c||",
                "Macau": "4nt|4nu||",
                "Macquarie": "4nv|||",
                "Magadan": "2o,k,0,1l|2o,k,0,6c||",
                "Malaysia": "2lt,0,4j|||",
                "Maldives": "4o0|||",
                "Marquesas": "4o1|||",
                "Marshall_Islands": "4o2,0,4j|||",
                "Mauritius": "4u,k,0,1l|4u,k,0,6c||",
                "Mawson": "4o3|||",
                "Mexico_Northwest": "2am,0,1l|2am,0,6c||",
                "Mexico_Pacific": "2ai,0,2ko,k,0,1l|2ai,0,2ko,k,0,6c||",
                "Mongolia": "p2,k,0,1l|p2,k,0,6c||",
                "Moscow": "pi,k,0,1l|pi,k,0,6c||",
                "Myanmar": "4o4,0,4j|||",
                "Nauru": "4o5|||",
                "Nepal": "4o6,0,4j|||",
                "New_Caledonia": "1qg,0,2kf,k,0,1l|1qg,0,2kf,k,0,6c||",
                "New_Zealand": "2kd,0,1l|2kd,0,6c||",
                "Newfoundland": "53,k,0,1l|53,k,0,6c||",
                "Niue": "4o7|||",
                "Norfolk": "4o8,0,4j|||",
                "Noronha": "1s,0,2,0,23,k,0,1l|1s,0,2,0,23,k,0,6c||",
                "North_Mariana": "4o9,0,4j|||",
                "Novosibirsk": "3b,k,0,1l|3b,k,0,6c||",
                "Omsk": "26,k,0,1l|26,k,0,6c||",
                "Pakistan": "1m6,0,1l|1m6,0,6c||",
                "Palau": "4oa|||",
                "Papua_New_Guinea": "9a,0,1qg,0,4ob,0,4j|||",
                "Paraguay": "1pd,0,1l|1pd,0,6c||",
                "Peru": "1nj,0,1l|1nj,0,6c||",
                "Philippines": "1ms,0,1l|1ms,0,6c||",
                "Phoenix_Islands": "4oc|||",
                "Pierre_Miquelon": "2jm,0,33,0,1qh,0,35,k,0,1l|2jm,0,33,0,1qh,0,35,k,0,6c||",
                "Pitcairn": "4od|||",
                "Ponape": "4oe|||",
                "Pyongyang": "4of|||",
                "Qyzylorda": "4og|4oh||",
                "Reunion": "4oi|||",
                "Rothera": "4oj|||",
                "Sakhalin": "ph,k,0,1l|ph,k,0,6c||",
                "Samara": "4ok|4ol||",
                "Samoa": "1nt,0,1l|1nt,0,6c||",
                "Seychelles": "4on|||",
                "Singapore": "4oo|||",
                "Solomon": "4op,0,4j|||",
                "South_Georgia": "4oq,0,4j|||",
                "Suriname": "4or|||",
                "Syowa": "4ot|||",
                "Tahiti": "4ou|||",
                "Taipei": "3d,k,0,1l|3d,k,0,6c||",
                "Tajikistan": "4p1|||",
                "Tokelau": "4p5|||",
                "Tonga": "2j,k,0,1l|2j,k,0,6c||",
                "Truk": "4p6|||",
                "Turkmenistan": "1l4,0,1l|1l4,0,6c||",
                "Tuvalu": "4p7|||",
                "Uruguay": "1p5,0,1l|1p5,0,6c||",
                "Uzbekistan": "2jk,0,1l|2jk,0,6c||",
                "Vanuatu": "2f,k,0,1l|2f,k,0,6c||",
                "Venezuela": "2tl,0,4j|||",
                "Vladivostok": "28,k,0,1l|28,k,0,6c||",
                "Volgograd": "3f,k,0,1l|3f,k,0,6c||",
                "Vostok": "4p9|||",
                "Wake": "4pc,0,4j|||",
                "Wallis": "5f,5,0,1qh,0,4pd,0,4j|||",
                "Yakutsk": "qj,k,0,1l|qj,k,0,6c||",
                "Yekaterinburg": "q6,k,0,1l|q6,k,0,6c||"
            }
        },
        "sv-FI": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4p8,0,6c||",
                "Europe/London": "|4pa,0,6c||",
                "Pacific/Honolulu": "||4pb|4pf"
            },
            "metazone": {
                "Acre": "2de,0,1l|2de,0,6c||",
                "Afghanistan": "2qd,0,4j|||",
                "Africa_Central": "4m3,0,4j|||",
                "Africa_Eastern": "4m4,0,4j|||",
                "Africa_Southern": "4m5,0,4j|||",
                "Africa_Western": "2cv,0,1l|2cv,0,6c||",
                "Alaska": "3p,k,0,1l|3p,k,0,6c||",
                "Almaty": "4m7|4m8||",
                "Amazon": "bg,k,0,1l|bg,k,0,6c||",
                "America_Central": "2cs,0,1l|2cs,0,6c||",
                "America_Eastern": "2cn,0,1l|2cn,0,6c||",
                "America_Mountain": "2ce,0,2cd,k,0,1l|2ce,0,2cd,k,0,6c||",
                "America_Pacific": "2cc,0,1l|2cc,0,6c||",
                "Anadyr": "4m9|4mb||",
                "Apia": "3e,k,0,1l|3e,k,0,6c||",
                "Aqtau": "4mc|4md||",
                "Aqtobe": "4me|4mf||",
                "Arabian": "2ca,0,1l|2ca,0,6c||",
                "Argentina": "2c9,0,1l|2c9,0,6c||",
                "Argentina_Western": "2c8,0,1l|2c8,0,6c||",
                "Armenia": "2c7,0,1l|2c7,0,6c||",
                "Atlantic": "2c6,0,4mg|2c6,0,4mh||",
                "Australia_Central": "2c2,0,1l|2c2,0,6c||",
                "Australia_CentralWestern": "2c1,0,1l|2c1,0,6c||",
                "Australia_Eastern": "2c0,0,1l|2c0,0,6c||",
                "Australia_Western": "2bv,0,1l|2bv,0,6c||",
                "Azerbaijan": "2bu,0,1l|2bu,0,6c||",
                "Azores": "2bt,0,1l|2bt,0,6c||",
                "Bangladesh": "1o1,0,1l|1o1,0,6c||",
                "Bhutan": "2vt,0,4j|||",
                "Bolivia": "2vl,0,4j|||",
                "Brasilia": "3a,k,0,1l|3a,k,0,6c||",
                "Brunei": "4mi|||",
                "Cape_Verde": "1l8,0,3j,k,0,1l|1l8,0,3j,k,0,6c||",
                "Casey": "4mj|||",
                "Chamorro": "4mk|||",
                "Chatham": "27,k,0,1l|27,k,0,6c||",
                "Chile": "1ng,0,1l|1ng,0,6c||",
                "China": "1la,0,1l|1la,0,6c||",
                "Choibalsan": "2bs,k,0,1l|2bs,k,0,6c||",
                "Christmas": "4ml,0,4j|||",
                "Cocos": "4mo,0,4j|||",
                "Colombia": "1nv,0,1l|1nv,0,6c||",
                "Cook": "2bq,0,1l|2bq,0,6c||",
                "Cuba": "2bn,0,1l|2bn,0,6c||",
                "Davis": "4s2|||",
                "DumontDUrville": "5b,0,5j,5,4j|||",
                "East_Timor": "4mq,0,4j|||",
                "Easter": "2bm,k,0,1l|2bm,k,0,6c||",
                "Ecuador": "2ug,0,4j|||",
                "Europe_Central": "2bl,0,1l|2bl,0,6c|h0|h4",
                "Europe_Eastern": "2bj,0,1l|2bj,0,6c|gu|gv",
                "Europe_Further_Eastern": "4mr|||",
                "Europe_Western": "2bh,0,1l|2bh,0,6c|h1|h2",
                "Falkland": "2bf,k,0,1l|2bf,k,0,6c||",
                "Fiji": "4q,k,0,1l|4q,k,0,6c||",
                "French_Guiana": "25t,0,2be|||",
                "French_Southern": "25t,0,4ms,0,4j|||",
                "Galapagos": "4mt|||",
                "Gambier": "4mv|||",
                "Georgia": "1ol,0,1l|1ol,0,6c||",
                "Gilbert_Islands": "4n0|||",
                "GMT": "4n1||ah|",
                "Greenland_Eastern": "2b9,0,1l|2b9,0,6c||",
                "Greenland_Western": "2b6,0,1l|2b6,0,6c||",
                "Guam": "4n2|||",
                "Gulf": "4n5,0,4n8|||",
                "Guyana": "2be|||",
                "Hawaii_Aleutian": "2b3,k,0,1l|2b3,k,0,6c||",
                "Hong_Kong": "qh,k,0,1l|qh,k,0,6c||",
                "Hovd": "2b2,k,0,1l|2b2,k,0,6c||",
                "India": "2n9,0,4j|||",
                "Indian_Ocean": "4n9,0,4na,0,4nb,0,4j|||",
                "Indochina": "31n,0,4j|||",
                "Indonesia_Central": "4nc,0,4j|||",
                "Indonesia_Eastern": "4ne,0,4j|||",
                "Indonesia_Western": "4nf,0,4j|||",
                "Iran": "1me,0,1l|1me,0,6c||",
                "Irkutsk": "3h,k,0,1l|3h,k,0,6c||",
                "Israel": "2an,0,1l|2an,0,6c||",
                "Japan": "1l9,0,1l|1l9,0,6c||",
                "Kamchatka": "4ng|4nh||",
                "Kazakhstan_Eastern": "4nm,0,4j|||",
                "Kazakhstan_Western": "4nn,0,4j|||",
                "Korea": "1pa,0,1l|1pa,0,6c||",
                "Kosrae": "4np|||",
                "Krasnoyarsk": "l0,k,0,1l|l0,k,0,6c||",
                "Kyrgystan": "4nq,0,4j|||",
                "Lanka": "me,0,4nr|||",
                "Line_Islands": "4ns,0,4j|||",
                "Lord_Howe": "1u,0,24,k,0,1l|1u,0,24,k,0,6c||",
                "Macau": "4nt|4nu||",
                "Macquarie": "4nv|||",
                "Magadan": "2o,k,0,1l|2o,k,0,6c||",
                "Malaysia": "2lt,0,4j|||",
                "Maldives": "4o0|||",
                "Marquesas": "4o1|||",
                "Marshall_Islands": "4o2,0,4j|||",
                "Mauritius": "4u,k,0,1l|4u,k,0,6c||",
                "Mawson": "4o3|||",
                "Mexico_Northwest": "2am,0,1l|2am,0,6c||",
                "Mexico_Pacific": "2ai,0,2ko,k,0,1l|2ai,0,2ko,k,0,6c||",
                "Mongolia": "p2,k,0,1l|p2,k,0,6c||",
                "Moscow": "pi,k,0,1l|pi,k,0,6c||",
                "Myanmar": "4o4,0,4j|||",
                "Nauru": "4o5|||",
                "Nepal": "4o6,0,4j|||",
                "New_Caledonia": "1qg,0,2kf,k,0,1l|1qg,0,2kf,k,0,6c||",
                "New_Zealand": "2kd,0,1l|2kd,0,6c||",
                "Newfoundland": "53,k,0,1l|53,k,0,6c||",
                "Niue": "4o7|||",
                "Norfolk": "4o8,0,4j|||",
                "Noronha": "1s,0,2,0,23,k,0,1l|1s,0,2,0,23,k,0,6c||",
                "North_Mariana": "4o9,0,4j|||",
                "Novosibirsk": "3b,k,0,1l|3b,k,0,6c||",
                "Omsk": "26,k,0,1l|26,k,0,6c||",
                "Pakistan": "1m6,0,1l|1m6,0,6c||",
                "Palau": "4oa|||",
                "Papua_New_Guinea": "9a,0,1qg,0,4ob,0,4j|||",
                "Paraguay": "1pd,0,1l|1pd,0,6c||",
                "Peru": "1nj,0,1l|1nj,0,6c||",
                "Philippines": "1ms,0,1l|1ms,0,6c||",
                "Phoenix_Islands": "4oc|||",
                "Pierre_Miquelon": "2jm,0,33,0,1qh,0,35,k,0,1l|2jm,0,33,0,1qh,0,35,k,0,6c||",
                "Pitcairn": "4od|||",
                "Ponape": "4oe|||",
                "Pyongyang": "4of|||",
                "Qyzylorda": "4og|4oh||",
                "Reunion": "4oi|||",
                "Rothera": "4oj|||",
                "Sakhalin": "ph,k,0,1l|ph,k,0,6c||",
                "Samara": "4ok|4ol||",
                "Samoa": "1nt,0,1l|1nt,0,6c||",
                "Seychelles": "4on|||",
                "Singapore": "4oo|||",
                "Solomon": "4op,0,4j|||",
                "South_Georgia": "4oq,0,4j|||",
                "Suriname": "4or|||",
                "Syowa": "4ot|||",
                "Tahiti": "4ou|||",
                "Taipei": "3d,k,0,1l|3d,k,0,6c||",
                "Tajikistan": "4p1|||",
                "Tokelau": "4p5|||",
                "Tonga": "2j,k,0,1l|2j,k,0,6c||",
                "Truk": "4p6|||",
                "Turkmenistan": "1l4,0,1l|1l4,0,6c||",
                "Tuvalu": "4p7|||",
                "Uruguay": "1p5,0,1l|1p5,0,6c||",
                "Uzbekistan": "2jk,0,1l|2jk,0,6c||",
                "Vanuatu": "2f,k,0,1l|2f,k,0,6c||",
                "Venezuela": "2tl,0,4j|||",
                "Vladivostok": "28,k,0,1l|28,k,0,6c||",
                "Volgograd": "3f,k,0,1l|3f,k,0,6c||",
                "Vostok": "4p9|||",
                "Wake": "4pc,0,4j|||",
                "Wallis": "5f,5,0,1qh,0,4pd,0,4j|||",
                "Yakutsk": "qj,k,0,1l|qj,k,0,6c||",
                "Yekaterinburg": "q6,k,0,1l|q6,k,0,6c||"
            }
        },
        "sv": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|4p8,0,6c||",
                "Europe/London": "|4pa,0,6c||",
                "Pacific/Honolulu": "||4pb|4pf"
            },
            "metazone": {
                "Acre": "2de,0,1l|2de,0,6c||",
                "Afghanistan": "2qd,0,4j|||",
                "Africa_Central": "4m3,0,4j|||",
                "Africa_Eastern": "4m4,0,4j|||",
                "Africa_Southern": "4m5,0,4j|||",
                "Africa_Western": "2cv,0,1l|2cv,0,6c||",
                "Alaska": "3p,k,0,1l|3p,k,0,6c||",
                "Almaty": "4m7|4m8||",
                "Amazon": "bg,k,0,1l|bg,k,0,6c||",
                "America_Central": "2cs,0,1l|2cs,0,6c||",
                "America_Eastern": "2cn,0,1l|2cn,0,6c||",
                "America_Mountain": "2ce,0,2cd,k,0,1l|2ce,0,2cd,k,0,6c||",
                "America_Pacific": "2cc,0,1l|2cc,0,6c||",
                "Anadyr": "4m9|4mb||",
                "Apia": "3e,k,0,1l|3e,k,0,6c||",
                "Aqtau": "4mc|4md||",
                "Aqtobe": "4me|4mf||",
                "Arabian": "2ca,0,1l|2ca,0,6c||",
                "Argentina": "2c9,0,1l|2c9,0,6c||",
                "Argentina_Western": "2c8,0,1l|2c8,0,6c||",
                "Armenia": "2c7,0,1l|2c7,0,6c||",
                "Atlantic": "2c6,0,4mg|2c6,0,4mh||",
                "Australia_Central": "2c2,0,1l|2c2,0,6c||",
                "Australia_CentralWestern": "2c1,0,1l|2c1,0,6c||",
                "Australia_Eastern": "2c0,0,1l|2c0,0,6c||",
                "Australia_Western": "2bv,0,1l|2bv,0,6c||",
                "Azerbaijan": "2bu,0,1l|2bu,0,6c||",
                "Azores": "2bt,0,1l|2bt,0,6c||",
                "Bangladesh": "1o1,0,1l|1o1,0,6c||",
                "Bhutan": "2vt,0,4j|||",
                "Bolivia": "2vl,0,4j|||",
                "Brasilia": "3a,k,0,1l|3a,k,0,6c||",
                "Brunei": "4mi|||",
                "Cape_Verde": "1l8,0,3j,k,0,1l|1l8,0,3j,k,0,6c||",
                "Casey": "4mj|||",
                "Chamorro": "4mk|||",
                "Chatham": "27,k,0,1l|27,k,0,6c||",
                "Chile": "1ng,0,1l|1ng,0,6c||",
                "China": "1la,0,1l|1la,0,6c||",
                "Choibalsan": "2bs,k,0,1l|2bs,k,0,6c||",
                "Christmas": "4ml,0,4j|||",
                "Cocos": "4mo,0,4j|||",
                "Colombia": "1nv,0,1l|1nv,0,6c||",
                "Cook": "2bq,0,1l|2bq,0,6c||",
                "Cuba": "2bn,0,1l|2bn,0,6c||",
                "Davis": "4s2|||",
                "DumontDUrville": "5b,0,5j,5,4j|||",
                "East_Timor": "4mq,0,4j|||",
                "Easter": "2bm,k,0,1l|2bm,k,0,6c||",
                "Ecuador": "2ug,0,4j|||",
                "Europe_Central": "2bl,0,1l|2bl,0,6c|h0|h4",
                "Europe_Eastern": "2bj,0,1l|2bj,0,6c|gu|gv",
                "Europe_Further_Eastern": "4mr|||",
                "Europe_Western": "2bh,0,1l|2bh,0,6c|h1|h2",
                "Falkland": "2bf,k,0,1l|2bf,k,0,6c||",
                "Fiji": "4q,k,0,1l|4q,k,0,6c||",
                "French_Guiana": "25t,0,2be|||",
                "French_Southern": "25t,0,4ms,0,4j|||",
                "Galapagos": "4mt|||",
                "Gambier": "4mv|||",
                "Georgia": "1ol,0,1l|1ol,0,6c||",
                "Gilbert_Islands": "4n0|||",
                "GMT": "4n1||ah|",
                "Greenland_Eastern": "2b9,0,1l|2b9,0,6c||",
                "Greenland_Western": "2b6,0,1l|2b6,0,6c||",
                "Guam": "4n2|||",
                "Gulf": "4n5,0,4n8|||",
                "Guyana": "2be|||",
                "Hawaii_Aleutian": "2b3,k,0,1l|2b3,k,0,6c||",
                "Hong_Kong": "qh,k,0,1l|qh,k,0,6c||",
                "Hovd": "2b2,k,0,1l|2b2,k,0,6c||",
                "India": "2n9,0,4j|||",
                "Indian_Ocean": "4n9,0,4na,0,4nb,0,4j|||",
                "Indochina": "31n,0,4j|||",
                "Indonesia_Central": "4nc,0,4j|||",
                "Indonesia_Eastern": "4ne,0,4j|||",
                "Indonesia_Western": "4nf,0,4j|||",
                "Iran": "1me,0,1l|1me,0,6c||",
                "Irkutsk": "3h,k,0,1l|3h,k,0,6c||",
                "Israel": "2an,0,1l|2an,0,6c||",
                "Japan": "1l9,0,1l|1l9,0,6c||",
                "Kamchatka": "4ng|4nh||",
                "Kazakhstan_Eastern": "4nm,0,4j|||",
                "Kazakhstan_Western": "4nn,0,4j|||",
                "Korea": "1pa,0,1l|1pa,0,6c||",
                "Kosrae": "4np|||",
                "Krasnoyarsk": "l0,k,0,1l|l0,k,0,6c||",
                "Kyrgystan": "4nq,0,4j|||",
                "Lanka": "me,0,4nr|||",
                "Line_Islands": "4ns,0,4j|||",
                "Lord_Howe": "1u,0,24,k,0,1l|1u,0,24,k,0,6c||",
                "Macau": "4nt|4nu||",
                "Macquarie": "4nv|||",
                "Magadan": "2o,k,0,1l|2o,k,0,6c||",
                "Malaysia": "2lt,0,4j|||",
                "Maldives": "4o0|||",
                "Marquesas": "4o1|||",
                "Marshall_Islands": "4o2,0,4j|||",
                "Mauritius": "4u,k,0,1l|4u,k,0,6c||",
                "Mawson": "4o3|||",
                "Mexico_Northwest": "2am,0,1l|2am,0,6c||",
                "Mexico_Pacific": "2ai,0,2ko,k,0,1l|2ai,0,2ko,k,0,6c||",
                "Mongolia": "p2,k,0,1l|p2,k,0,6c||",
                "Moscow": "pi,k,0,1l|pi,k,0,6c||",
                "Myanmar": "4o4,0,4j|||",
                "Nauru": "4o5|||",
                "Nepal": "4o6,0,4j|||",
                "New_Caledonia": "1qg,0,2kf,k,0,1l|1qg,0,2kf,k,0,6c||",
                "New_Zealand": "2kd,0,1l|2kd,0,6c||",
                "Newfoundland": "53,k,0,1l|53,k,0,6c||",
                "Niue": "4o7|||",
                "Norfolk": "4o8,0,4j|||",
                "Noronha": "1s,0,2,0,23,k,0,1l|1s,0,2,0,23,k,0,6c||",
                "North_Mariana": "4o9,0,4j|||",
                "Novosibirsk": "3b,k,0,1l|3b,k,0,6c||",
                "Omsk": "26,k,0,1l|26,k,0,6c||",
                "Pakistan": "1m6,0,1l|1m6,0,6c||",
                "Palau": "4oa|||",
                "Papua_New_Guinea": "9a,0,1qg,0,4ob,0,4j|||",
                "Paraguay": "1pd,0,1l|1pd,0,6c||",
                "Peru": "1nj,0,1l|1nj,0,6c||",
                "Philippines": "1ms,0,1l|1ms,0,6c||",
                "Phoenix_Islands": "4oc|||",
                "Pierre_Miquelon": "2jm,0,33,0,1qh,0,35,k,0,1l|2jm,0,33,0,1qh,0,35,k,0,6c||",
                "Pitcairn": "4od|||",
                "Ponape": "4oe|||",
                "Pyongyang": "4of|||",
                "Qyzylorda": "4og|4oh||",
                "Reunion": "4oi|||",
                "Rothera": "4oj|||",
                "Sakhalin": "ph,k,0,1l|ph,k,0,6c||",
                "Samara": "4ok|4ol||",
                "Samoa": "1nt,0,1l|1nt,0,6c||",
                "Seychelles": "4on|||",
                "Singapore": "4oo|||",
                "Solomon": "4op,0,4j|||",
                "South_Georgia": "4oq,0,4j|||",
                "Suriname": "4or|||",
                "Syowa": "4ot|||",
                "Tahiti": "4ou|||",
                "Taipei": "3d,k,0,1l|3d,k,0,6c||",
                "Tajikistan": "4p1|||",
                "Tokelau": "4p5|||",
                "Tonga": "2j,k,0,1l|2j,k,0,6c||",
                "Truk": "4p6|||",
                "Turkmenistan": "1l4,0,1l|1l4,0,6c||",
                "Tuvalu": "4p7|||",
                "Uruguay": "1p5,0,1l|1p5,0,6c||",
                "Uzbekistan": "2jk,0,1l|2jk,0,6c||",
                "Vanuatu": "2f,k,0,1l|2f,k,0,6c||",
                "Venezuela": "2tl,0,4j|||",
                "Vladivostok": "28,k,0,1l|28,k,0,6c||",
                "Volgograd": "3f,k,0,1l|3f,k,0,6c||",
                "Vostok": "4p9|||",
                "Wake": "4pc,0,4j|||",
                "Wallis": "5f,5,0,1qh,0,4pd,0,4j|||",
                "Yakutsk": "qj,k,0,1l|qj,k,0,6c||",
                "Yekaterinburg": "q6,k,0,1l|q6,k,0,6c||"
            }
        },
        "sw-CD": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3et||",
                "Europe/London": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3ae||"
            },
            "metazone": {
                "Afghanistan": "v,0,l,0,b6|||",
                "Africa_Central": "v,0,l,0,c5,0,59,0,ps|||",
                "Africa_Eastern": "v,0,l,0,c5,0,mn|||",
                "Africa_Southern": "v,0,43,0,l,0,c5,0,1f3|||",
                "Africa_Western": "v,0,l,0,43,0,l,0,c5,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,c5,0,hu||",
                "Alaska": "v,0,l,0,43,0,l,0,3p|v,0,l,0,cj,0,l,0,3p||",
                "Amazon": "v,0,l,0,43,0,l,0,5m|v,0,l,0,8d,0,59,0,8j,0,l,0,5m||",
                "America_Central": "v,0,l,0,43,0,l,0,ps|v,0,l,0,cj,0,l,0,ps||",
                "America_Eastern": "v,0,l,0,43,0,l,0,mn|v,0,l,0,cj,0,l,0,mn||",
                "America_Mountain": "v,0,l,0,43,0,l,0,5a|v,0,l,0,cj,0,l,0,5a||",
                "America_Pacific": "v,0,l,0,43,0,l,0,22s|v,0,l,0,cj,0,l,0,22s||",
                "Anadyr": "v,0,l,0,43,0,l,0,3l|v,0,l,0,1f4,0,l,0,3l||",
                "Apia": "v,0,43,0,l,0,3e|v,0,l,0,cj,0,l,0,3e||",
                "Arabian": "v,0,43,0,l,0,3ft|v,0,l,0,cj,0,l,0,3fs||",
                "Argentina": "v,0,l,0,43,0,l,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,1e||",
                "Argentina_Western": "v,0,43,0,l,0,hu,0,oc,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,1e||",
                "Armenia": "v,0,43,0,l,0,4l|v,0,l,0,8d,0,59,0,8j,0,l,0,4l||",
                "Atlantic": "v,0,l,0,43,0,l,0,230|v,0,l,0,cj,0,l,0,230||",
                "Australia_Central": "v,0,43,0,l,0,4c,0,59,0,ps|v,0,l,0,cj,0,l,0,4c,0,59,0,ps||",
                "Australia_CentralWestern": "v,0,43,0,l,0,hu,0,59,0,ps,0,59,0,4c|v,0,l,0,cj,0,l,0,hu,0,59,0,ps,0,59,0,4c||",
                "Australia_Eastern": "v,0,43,0,l,0,mn,0,oc,0,4c|v,0,l,0,cj,0,l,0,mn,0,oc,0,4c||",
                "Australia_Western": "v,0,43,0,l,0,4c,0,hu|v,0,l,0,cj,0,l,0,4c,0,hu||",
                "Azerbaijan": "v,0,43,0,l,0,232|v,0,l,0,8d,0,59,0,8j,0,l,0,232||",
                "Azores": "v,0,43,0,l,0,4r|v,0,l,0,8d,0,59,0,8j,0,l,0,4r||",
                "Bangladesh": "v,0,43,0,l,0,3n|v,0,l,0,8d,0,59,0,8j,0,l,0,3n||",
                "Bhutan": "v,0,l,0,as|||",
                "Bolivia": "v,0,l,0,aa|||",
                "Brasilia": "v,0,l,0,43,0,l,0,3a|v,0,l,0,8d,0,59,0,8j,0,l,0,3a||",
                "Brunei": "v,0,l,0,9o,0,a4|||",
                "Cape_Verde": "v,0,l,0,43,0,l,0,7c,0,3j|v,0,l,0,8d,0,59,0,8j,0,l,0,7c,0,3j||",
                "Chamorro": "v,0,43,0,l,0,6j|||",
                "Chatham": "v,0,43,0,l,0,27|v,0,l,0,cj,0,l,0,27||",
                "Chile": "v,0,l,0,43,0,l,0,49|v,0,l,0,8d,0,59,0,8j,0,l,0,49||",
                "China": "v,0,43,0,l,0,233|v,0,l,0,cj,0,l,0,233||",
                "Choibalsan": "v,0,43,0,l,0,2q|v,0,l,0,8d,0,59,0,8j,0,l,0,2q||",
                "Christmas": "v,0,l,0,13r,0,11t,0,99|||",
                "Cocos": "v,0,l,0,p9,0,q0,0,7v|||",
                "Colombia": "v,0,l,0,43,0,l,0,4m|v,0,l,0,8d,0,59,0,8j,0,l,0,4m||",
                "Cook": "v,0,43,0,l,0,p9,0,q0,0,2v|v,0,l,0,8d,0,3fj,0,59,0,8j,0,l,0,p9,0,2v||",
                "Cuba": "v,0,l,0,43,0,59,0,34|v,0,l,0,cj,0,l,0,34||",
                "Davis": "v,0,l,0,5s|||",
                "DumontDUrville": "v,0,l,0,5b,5,5j|||",
                "East_Timor": "v,0,l,0,7n,0,mn|||",
                "Easter": "v,0,43,0,l,0,13r,0,11t,0,23f|v,0,l,0,8d,0,59,0,8j,0,l,0,13r,0,11t,0,23f||",
                "Ecuador": "v,0,l,0,ai|||",
                "Europe_Central": "v,0,43,0,l,0,114,0,59,0,3fg|v,0,l,0,8d,0,59,0,8j,0,l,0,114,0,59,0,ps||",
                "Europe_Eastern": "v,0,43,0,l,0,mn,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,mn,0,oc,0,114||",
                "Europe_Further_Eastern": "v,0,l,0,bu,5,c0,0,18,0,1|||",
                "Europe_Western": "v,0,43,0,l,0,hu,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,114||",
                "Falkland": "v,0,43,0,l,0,p9,0,q0,0,4v|v,0,l,0,8d,0,59,0,8j,0,l,0,p9,0,q0,0,4v||",
                "Fiji": "v,0,43,0,l,0,4q|v,0,l,0,8d,0,59,0,8j,0,l,0,4q||",
                "French_Guiana": "v,0,l,0,am,0,59,0,23h|||",
                "French_Southern": "v,0,l,0,1f3,0,oc,0,23h,0,52,0,3fe|||",
                "Galapagos": "v,0,l,0,ak|||",
                "Gambier": "v,0,l,0,6v|||",
                "Georgia": "v,0,43,0,l,0,1f7|v,0,l,0,8d,0,59,0,8j,0,l,0,1f7||",
                "Gilbert_Islands": "v,0,l,0,p9,0,q0,0,6q|||",
                "GMT": "v,0,l,0,5u|||",
                "Greenland_Eastern": "v,0,l,0,43,0,l,0,2k,0,mn|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,mn||",
                "Greenland_Western": "v,0,l,0,43,0,l,0,2k,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,hu||",
                "Gulf": "v,0,43,0,l,0,bn|||",
                "Guyana": "v,0,l,0,7a|||",
                "Hawaii_Aleutian": "v,0,l,0,43,0,l,0,4g,5,62|v,0,l,0,cj,0,l,0,4g,5,62||",
                "Hong_Kong": "v,0,43,0,l,0,2l,0,2p|v,0,l,0,8d,0,59,0,8j,0,l,0,2l,0,2p||",
                "Hovd": "v,0,43,0,l,0,2g|v,0,l,0,8d,0,59,0,8j,0,l,0,2g||",
                "India": "v,0,43,0,l,0,af|||",
                "Indian_Ocean": "v,0,l,0,3fc,0,1to|||",
                "Indochina": "v,0,l,0,9p|||",
                "Indonesia_Central": "v,0,l,0,2t,0,59,0,ps|||",
                "Indonesia_Eastern": "v,0,l,0,mn,0,oc,0,2t|||",
                "Indonesia_Western": "v,0,l,0,hu,0,oc,0,2t|||",
                "Iran": "v,0,43,0,l,0,5g|v,0,l,0,cj,0,l,0,5g||",
                "Irkutsk": "v,0,l,0,43,0,l,0,3h|v,0,l,0,8d,0,59,0,8j,0,l,0,3h||",
                "Israel": "v,0,43,0,l,0,23k|v,0,l,0,cj,0,l,0,23k||",
                "Japan": "v,0,43,0,l,0,6o|v,0,l,0,cj,0,l,0,6o||",
                "Kamchatka": "v,0,l,0,43,0,l,0,37,5,3k|v,0,l,0,1f4,0,l,0,37,5,3k||",
                "Kazakhstan_Eastern": "v,0,l,0,45,0,mn|||",
                "Kazakhstan_Western": "v,0,l,0,45,0,hu|||",
                "Korea": "v,0,43,0,l,0,qf|v,0,l,0,cj,0,l,0,qf||",
                "Kosrae": "v,0,l,0,6f|||",
                "Krasnoyarsk": "v,0,l,0,43,0,l,0,3fa|v,0,l,0,8d,0,59,0,8j,0,l,0,41||",
                "Kyrgystan": "v,0,l,0,bl|||",
                "Line_Islands": "v,0,l,0,p9,0,q0,0,ac|||",
                "Lord_Howe": "v,0,43,0,l,0,1u,0,24|v,0,l,0,cj,0,l,0,1u,0,24||",
                "Macquarie": "v,0,l,0,3f9,0,11t,0,6m|||",
                "Magadan": "v,0,l,0,43,0,l,0,2o|v,0,l,0,8d,0,59,0,8j,0,l,0,2o||",
                "Malaysia": "v,0,l,0,bf|||",
                "Maldives": "v,0,l,0,9k|||",
                "Marquesas": "v,0,l,0,9e|||",
                "Marshall_Islands": "v,0,l,0,p9,0,q0,0,7o|||",
                "Mauritius": "v,0,l,0,43,0,l,0,4u|v,0,l,0,8d,0,59,0,8j,0,l,0,4u||",
                "Mawson": "v,0,l,0,63|||",
                "Mexico_Northwest": "v,0,43,0,l,0,5e,0,23l,0,hu|v,0,l,0,23m,0,l,0,5e,0,23l,0,hu||",
                "Mexico_Pacific": "v,0,l,0,3f6,0,l,0,23n,0,l,0,5e|v,0,l,0,23m,0,l,0,23n,0,l,0,5e||",
                "Mongolia": "v,0,43,0,l,0,4f,0,2m|v,0,l,0,8d,0,59,0,8j,0,l,0,4f,0,2m||",
                "Moscow": "v,0,l,0,43,0,l,0,71|v,0,l,0,8d,0,59,0,8j,0,l,0,71||",
                "Myanmar": "v,0,l,0,8v|||",
                "Nauru": "v,0,l,0,5v|||",
                "Nepal": "v,0,l,0,8u|||",
                "New_Caledonia": "v,0,43,0,l,0,1m,0,4o|v,0,l,0,8d,0,59,0,8j,0,l,0,1m,0,4o||",
                "New_Zealand": "v,0,43,0,l,0,1m,0,74|v,0,l,0,cj,0,l,0,1m,0,74||",
                "Newfoundland": "v,0,l,0,43,0,l,0,53|v,0,l,0,cj,0,l,0,53||",
                "Niue": "v,0,l,0,8g|||",
                "Norfolk": "v,0,l,0,13r,0,11t,0,6l|||",
                "Noronha": "v,0,43,0,l,0,1s,0,2,0,23|v,0,l,0,8d,0,59,0,8j,0,l,0,1s,0,2,0,23||",
                "Novosibirsk": "v,0,l,0,43,0,l,0,3b|v,0,l,0,8d,0,59,0,8j,0,l,0,3b||",
                "Omsk": "v,0,l,0,43,0,l,0,26|v,0,l,0,8d,0,59,0,8j,0,l,0,26||",
                "Pakistan": "v,0,43,0,l,0,3i|v,0,l,0,8d,0,59,0,8j,0,l,0,3i||",
                "Palau": "v,0,l,0,9b|||",
                "Papua_New_Guinea": "v,0,l,0,9a,0,1m,0,a8|||",
                "Paraguay": "v,0,l,0,43,0,l,0,3c|v,0,l,0,8d,0,59,0,8j,0,l,0,3c||",
                "Peru": "v,0,l,0,43,0,l,0,4n|v,0,l,0,8d,0,59,0,8j,0,l,0,4n||",
                "Philippines": "v,0,43,0,l,0,23p|v,0,l,0,8d,0,59,0,8j,0,l,0,23p||",
                "Phoenix_Islands": "v,0,l,0,p9,0,q0,0,90|||",
                "Pierre_Miquelon": "v,0,l,0,43,0,59,0,8i,5,33,0,52,0,35|v,0,l,0,cj,0,l,0,8i,5,33,0,52,0,35||",
                "Pitcairn": "v,0,l,0,7e|||",
                "Ponape": "v,0,l,0,9s|||",
                "Pyongyang": "v,0,l,0,8a|||",
                "Reunion": "v,0,l,0,b4|||",
                "Rothera": "v,0,l,0,5q|||",
                "Sakhalin": "v,0,l,0,43,0,l,0,55|v,0,l,0,8d,0,59,0,8j,0,l,0,55||",
                "Samara": "v,0,l,0,43,0,l,0,29|v,0,l,0,1f4,0,l,0,29||",
                "Samoa": "v,0,43,0,l,0,2c|v,0,l,0,8d,0,59,0,8j,0,l,0,2c||",
                "Seychelles": "v,0,l,0,3f3|||",
                "Singapore": "v,0,43,0,l,0,bi|||",
                "Solomon": "v,0,l,0,p9,0,q0,0,b5|||",
                "South_Georgia": "v,0,l,0,1f7,0,1f3|||",
                "Suriname": "v,0,l,0,8q|||",
                "Syowa": "v,0,l,0,65|||",
                "Tahiti": "v,0,l,0,8n|||",
                "Taipei": "v,0,43,0,l,0,3d|v,0,l,0,cj,0,l,0,3d||",
                "Tajikistan": "v,0,l,0,bj|||",
                "Tokelau": "v,0,l,0,5d|||",
                "Tonga": "v,0,43,0,l,0,2j|v,0,l,0,8d,0,59,0,8j,0,l,0,2j||",
                "Truk": "v,0,l,0,6p|||",
                "Turkmenistan": "v,0,l,0,43,0,l,0,4t|v,0,l,0,8d,0,59,0,8j,0,l,0,4t||",
                "Tuvalu": "v,0,l,0,6h|||",
                "Uruguay": "v,0,l,0,43,0,l,0,4k|v,0,l,0,8d,0,59,0,8j,0,l,0,4k||",
                "Uzbekistan": "v,0,l,0,43,0,l,0,60|v,0,l,0,8d,0,59,0,8j,0,l,0,60||",
                "Vanuatu": "v,0,43,0,l,0,2f|v,0,l,0,8d,0,59,0,8j,0,l,0,2f||",
                "Venezuela": "v,0,l,0,7s|||",
                "Vladivostok": "v,0,l,0,43,0,l,0,28|v,0,l,0,8d,0,59,0,8j,0,l,0,28||",
                "Volgograd": "v,0,l,0,43,0,l,0,3f|v,0,l,0,8d,0,59,0,8j,0,l,0,3f||",
                "Vostok": "v,0,l,0,5t|||",
                "Wake": "v,0,l,0,13r,0,11t,0,5r|||",
                "Wallis": "v,0,l,0,5f,0,52,0,5k|||",
                "Yakutsk": "v,0,l,0,43,0,l,0,3u|v,0,l,0,8d,0,59,0,8j,0,l,0,3u||",
                "Yekaterinburg": "v,0,l,0,43,0,l,0,5l|v,0,l,0,8d,0,59,0,8j,0,l,0,5l||"
            }
        },
        "sw-KE": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3et||",
                "Europe/London": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3ae||"
            },
            "metazone": {
                "Afghanistan": "v,0,l,0,b6|||",
                "Africa_Central": "v,0,l,0,c5,0,59,0,ps|||",
                "Africa_Eastern": "v,0,l,0,c5,0,mn|||",
                "Africa_Southern": "v,0,43,0,l,0,c5,0,1f3|||",
                "Africa_Western": "v,0,l,0,43,0,l,0,c5,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,c5,0,hu||",
                "Alaska": "v,0,l,0,43,0,l,0,3p|v,0,l,0,cj,0,l,0,3p||",
                "Amazon": "v,0,l,0,43,0,l,0,5m|v,0,l,0,8d,0,59,0,8j,0,l,0,5m||",
                "America_Central": "v,0,l,0,43,0,l,0,ps|v,0,l,0,cj,0,l,0,ps||",
                "America_Eastern": "v,0,l,0,43,0,l,0,mn|v,0,l,0,cj,0,l,0,mn||",
                "America_Mountain": "v,0,l,0,43,0,l,0,5a|v,0,l,0,cj,0,l,0,5a||",
                "America_Pacific": "v,0,l,0,43,0,l,0,22s|v,0,l,0,cj,0,l,0,22s||",
                "Anadyr": "v,0,l,0,43,0,l,0,3l|v,0,l,0,1f4,0,l,0,3l||",
                "Apia": "v,0,43,0,l,0,3e|v,0,l,0,cj,0,l,0,3e||",
                "Arabian": "v,0,43,0,l,0,3ft|v,0,l,0,cj,0,l,0,3fs||",
                "Argentina": "v,0,l,0,43,0,l,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,1e||",
                "Argentina_Western": "v,0,43,0,l,0,hu,0,oc,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,1e||",
                "Armenia": "v,0,43,0,l,0,4l|v,0,l,0,8d,0,59,0,8j,0,l,0,4l||",
                "Atlantic": "v,0,l,0,43,0,l,0,230|v,0,l,0,cj,0,l,0,230||",
                "Australia_Central": "v,0,43,0,l,0,4c,0,59,0,ps|v,0,l,0,cj,0,l,0,4c,0,59,0,ps||",
                "Australia_CentralWestern": "v,0,43,0,l,0,hu,0,59,0,ps,0,59,0,4c|v,0,l,0,cj,0,l,0,hu,0,59,0,ps,0,59,0,4c||",
                "Australia_Eastern": "v,0,43,0,l,0,mn,0,oc,0,4c|v,0,l,0,cj,0,l,0,mn,0,oc,0,4c||",
                "Australia_Western": "v,0,43,0,l,0,4c,0,hu|v,0,l,0,cj,0,l,0,4c,0,hu||",
                "Azerbaijan": "v,0,43,0,l,0,232|v,0,l,0,8d,0,59,0,8j,0,l,0,232||",
                "Azores": "v,0,43,0,l,0,4r|v,0,l,0,8d,0,59,0,8j,0,l,0,4r||",
                "Bangladesh": "v,0,43,0,l,0,3n|v,0,l,0,8d,0,59,0,8j,0,l,0,3n||",
                "Bhutan": "v,0,l,0,as|||",
                "Bolivia": "v,0,l,0,aa|||",
                "Brasilia": "v,0,l,0,43,0,l,0,3a|v,0,l,0,8d,0,59,0,8j,0,l,0,3a||",
                "Brunei": "v,0,l,0,9o,0,a4|||",
                "Cape_Verde": "v,0,l,0,43,0,l,0,7c,0,3j|v,0,l,0,8d,0,59,0,8j,0,l,0,7c,0,3j||",
                "Chamorro": "v,0,43,0,l,0,6j|||",
                "Chatham": "v,0,43,0,l,0,27|v,0,l,0,cj,0,l,0,27||",
                "Chile": "v,0,l,0,43,0,l,0,49|v,0,l,0,8d,0,59,0,8j,0,l,0,49||",
                "China": "v,0,43,0,l,0,233|v,0,l,0,cj,0,l,0,233||",
                "Choibalsan": "v,0,43,0,l,0,2q|v,0,l,0,8d,0,59,0,8j,0,l,0,2q||",
                "Christmas": "v,0,l,0,13r,0,11t,0,99|||",
                "Cocos": "v,0,l,0,p9,0,q0,0,7v|||",
                "Colombia": "v,0,l,0,43,0,l,0,4m|v,0,l,0,8d,0,59,0,8j,0,l,0,4m||",
                "Cook": "v,0,43,0,l,0,p9,0,q0,0,2v|v,0,l,0,8d,0,3fj,0,59,0,8j,0,l,0,p9,0,2v||",
                "Cuba": "v,0,l,0,43,0,59,0,34|v,0,l,0,cj,0,l,0,34||",
                "Davis": "v,0,l,0,5s|||",
                "DumontDUrville": "v,0,l,0,5b,5,5j|||",
                "East_Timor": "v,0,l,0,7n,0,mn|||",
                "Easter": "v,0,43,0,l,0,13r,0,11t,0,23f|v,0,l,0,8d,0,59,0,8j,0,l,0,13r,0,11t,0,23f||",
                "Ecuador": "v,0,l,0,ai|||",
                "Europe_Central": "v,0,43,0,l,0,114,0,59,0,3fg|v,0,l,0,8d,0,59,0,8j,0,l,0,114,0,59,0,ps||",
                "Europe_Eastern": "v,0,43,0,l,0,mn,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,mn,0,oc,0,114||",
                "Europe_Further_Eastern": "v,0,l,0,bu,5,c0,0,18,0,1|||",
                "Europe_Western": "v,0,43,0,l,0,hu,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,114||",
                "Falkland": "v,0,43,0,l,0,p9,0,q0,0,4v|v,0,l,0,8d,0,59,0,8j,0,l,0,p9,0,q0,0,4v||",
                "Fiji": "v,0,43,0,l,0,4q|v,0,l,0,8d,0,59,0,8j,0,l,0,4q||",
                "French_Guiana": "v,0,l,0,am,0,59,0,23h|||",
                "French_Southern": "v,0,l,0,1f3,0,oc,0,23h,0,52,0,3fe|||",
                "Galapagos": "v,0,l,0,ak|||",
                "Gambier": "v,0,l,0,6v|||",
                "Georgia": "v,0,43,0,l,0,1f7|v,0,l,0,8d,0,59,0,8j,0,l,0,1f7||",
                "Gilbert_Islands": "v,0,l,0,p9,0,q0,0,6q|||",
                "GMT": "v,0,l,0,5u|||",
                "Greenland_Eastern": "v,0,l,0,43,0,l,0,2k,0,mn|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,mn||",
                "Greenland_Western": "v,0,l,0,43,0,l,0,2k,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,hu||",
                "Gulf": "v,0,43,0,l,0,bn|||",
                "Guyana": "v,0,l,0,7a|||",
                "Hawaii_Aleutian": "v,0,l,0,43,0,l,0,4g,5,62|v,0,l,0,cj,0,l,0,4g,5,62||",
                "Hong_Kong": "v,0,43,0,l,0,2l,0,2p|v,0,l,0,8d,0,59,0,8j,0,l,0,2l,0,2p||",
                "Hovd": "v,0,43,0,l,0,2g|v,0,l,0,8d,0,59,0,8j,0,l,0,2g||",
                "India": "v,0,43,0,l,0,af|||",
                "Indian_Ocean": "v,0,l,0,3fc,0,1to|||",
                "Indochina": "v,0,l,0,9p|||",
                "Indonesia_Central": "v,0,l,0,2t,0,59,0,ps|||",
                "Indonesia_Eastern": "v,0,l,0,mn,0,oc,0,2t|||",
                "Indonesia_Western": "v,0,l,0,hu,0,oc,0,2t|||",
                "Iran": "v,0,43,0,l,0,5g|v,0,l,0,cj,0,l,0,5g||",
                "Irkutsk": "v,0,l,0,43,0,l,0,3h|v,0,l,0,8d,0,59,0,8j,0,l,0,3h||",
                "Israel": "v,0,43,0,l,0,23k|v,0,l,0,cj,0,l,0,23k||",
                "Japan": "v,0,43,0,l,0,6o|v,0,l,0,cj,0,l,0,6o||",
                "Kamchatka": "v,0,l,0,43,0,l,0,37,5,3k|v,0,l,0,1f4,0,l,0,37,5,3k||",
                "Kazakhstan_Eastern": "v,0,l,0,45,0,mn|||",
                "Kazakhstan_Western": "v,0,l,0,45,0,hu|||",
                "Korea": "v,0,43,0,l,0,qf|v,0,l,0,cj,0,l,0,qf||",
                "Kosrae": "v,0,l,0,6f|||",
                "Krasnoyarsk": "v,0,l,0,43,0,l,0,3fa|v,0,l,0,8d,0,59,0,8j,0,l,0,41||",
                "Kyrgystan": "v,0,l,0,bl|||",
                "Line_Islands": "v,0,l,0,p9,0,q0,0,ac|||",
                "Lord_Howe": "v,0,43,0,l,0,1u,0,24|v,0,l,0,cj,0,l,0,1u,0,24||",
                "Macquarie": "v,0,l,0,3f9,0,11t,0,6m|||",
                "Magadan": "v,0,l,0,43,0,l,0,2o|v,0,l,0,8d,0,59,0,8j,0,l,0,2o||",
                "Malaysia": "v,0,l,0,bf|||",
                "Maldives": "v,0,l,0,9k|||",
                "Marquesas": "v,0,l,0,9e|||",
                "Marshall_Islands": "v,0,l,0,p9,0,q0,0,7o|||",
                "Mauritius": "v,0,l,0,43,0,l,0,4u|v,0,l,0,8d,0,59,0,8j,0,l,0,4u||",
                "Mawson": "v,0,l,0,63|||",
                "Mexico_Northwest": "v,0,43,0,l,0,5e,0,23l,0,hu|v,0,l,0,23m,0,l,0,5e,0,23l,0,hu||",
                "Mexico_Pacific": "v,0,l,0,3f6,0,l,0,23n,0,l,0,5e|v,0,l,0,23m,0,l,0,23n,0,l,0,5e||",
                "Mongolia": "v,0,43,0,l,0,4f,0,2m|v,0,l,0,8d,0,59,0,8j,0,l,0,4f,0,2m||",
                "Moscow": "v,0,l,0,43,0,l,0,71|v,0,l,0,8d,0,59,0,8j,0,l,0,71||",
                "Myanmar": "v,0,l,0,8v|||",
                "Nauru": "v,0,l,0,5v|||",
                "Nepal": "v,0,l,0,8u|||",
                "New_Caledonia": "v,0,43,0,l,0,1m,0,4o|v,0,l,0,8d,0,59,0,8j,0,l,0,1m,0,4o||",
                "New_Zealand": "v,0,43,0,l,0,1m,0,74|v,0,l,0,cj,0,l,0,1m,0,74||",
                "Newfoundland": "v,0,l,0,43,0,l,0,53|v,0,l,0,cj,0,l,0,53||",
                "Niue": "v,0,l,0,8g|||",
                "Norfolk": "v,0,l,0,13r,0,11t,0,6l|||",
                "Noronha": "v,0,43,0,l,0,1s,0,2,0,23|v,0,l,0,8d,0,59,0,8j,0,l,0,1s,0,2,0,23||",
                "Novosibirsk": "v,0,l,0,43,0,l,0,3b|v,0,l,0,8d,0,59,0,8j,0,l,0,3b||",
                "Omsk": "v,0,l,0,43,0,l,0,26|v,0,l,0,8d,0,59,0,8j,0,l,0,26||",
                "Pakistan": "v,0,43,0,l,0,3i|v,0,l,0,8d,0,59,0,8j,0,l,0,3i||",
                "Palau": "v,0,l,0,9b|||",
                "Papua_New_Guinea": "v,0,l,0,9a,0,1m,0,a8|||",
                "Paraguay": "v,0,l,0,43,0,l,0,3c|v,0,l,0,8d,0,59,0,8j,0,l,0,3c||",
                "Peru": "v,0,l,0,43,0,l,0,4n|v,0,l,0,8d,0,59,0,8j,0,l,0,4n||",
                "Philippines": "v,0,43,0,l,0,23p|v,0,l,0,8d,0,59,0,8j,0,l,0,23p||",
                "Phoenix_Islands": "v,0,l,0,p9,0,q0,0,90|||",
                "Pierre_Miquelon": "v,0,l,0,43,0,59,0,8i,5,33,0,52,0,35|v,0,l,0,cj,0,l,0,8i,5,33,0,52,0,35||",
                "Pitcairn": "v,0,l,0,7e|||",
                "Ponape": "v,0,l,0,9s|||",
                "Pyongyang": "v,0,l,0,8a|||",
                "Reunion": "v,0,l,0,b4|||",
                "Rothera": "v,0,l,0,5q|||",
                "Sakhalin": "v,0,l,0,43,0,l,0,55|v,0,l,0,8d,0,59,0,8j,0,l,0,55||",
                "Samara": "v,0,l,0,43,0,l,0,29|v,0,l,0,1f4,0,l,0,29||",
                "Samoa": "v,0,43,0,l,0,2c|v,0,l,0,8d,0,59,0,8j,0,l,0,2c||",
                "Seychelles": "v,0,l,0,3f3|||",
                "Singapore": "v,0,43,0,l,0,bi|||",
                "Solomon": "v,0,l,0,p9,0,q0,0,b5|||",
                "South_Georgia": "v,0,l,0,1f7,0,1f3|||",
                "Suriname": "v,0,l,0,8q|||",
                "Syowa": "v,0,l,0,65|||",
                "Tahiti": "v,0,l,0,8n|||",
                "Taipei": "v,0,43,0,l,0,3d|v,0,l,0,cj,0,l,0,3d||",
                "Tajikistan": "v,0,l,0,bj|||",
                "Tokelau": "v,0,l,0,5d|||",
                "Tonga": "v,0,43,0,l,0,2j|v,0,l,0,8d,0,59,0,8j,0,l,0,2j||",
                "Truk": "v,0,l,0,6p|||",
                "Turkmenistan": "v,0,l,0,43,0,l,0,4t|v,0,l,0,8d,0,59,0,8j,0,l,0,4t||",
                "Tuvalu": "v,0,l,0,6h|||",
                "Uruguay": "v,0,l,0,43,0,l,0,4k|v,0,l,0,8d,0,59,0,8j,0,l,0,4k||",
                "Uzbekistan": "v,0,l,0,43,0,l,0,60|v,0,l,0,8d,0,59,0,8j,0,l,0,60||",
                "Vanuatu": "v,0,43,0,l,0,2f|v,0,l,0,8d,0,59,0,8j,0,l,0,2f||",
                "Venezuela": "v,0,l,0,7s|||",
                "Vladivostok": "v,0,l,0,43,0,l,0,28|v,0,l,0,8d,0,59,0,8j,0,l,0,28||",
                "Volgograd": "v,0,l,0,43,0,l,0,3f|v,0,l,0,8d,0,59,0,8j,0,l,0,3f||",
                "Vostok": "v,0,l,0,5t|||",
                "Wake": "v,0,l,0,13r,0,11t,0,5r|||",
                "Wallis": "v,0,l,0,5f,0,52,0,5k|||",
                "Yakutsk": "v,0,l,0,43,0,l,0,3u|v,0,l,0,8d,0,59,0,8j,0,l,0,3u||",
                "Yekaterinburg": "v,0,l,0,43,0,l,0,5l|v,0,l,0,8d,0,59,0,8j,0,l,0,5l||"
            }
        },
        "sw-UG": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3et||",
                "Europe/London": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3ae||"
            },
            "metazone": {
                "Afghanistan": "v,0,l,0,b6|||",
                "Africa_Central": "v,0,l,0,c5,0,59,0,ps|||",
                "Africa_Eastern": "v,0,l,0,c5,0,mn|||",
                "Africa_Southern": "v,0,43,0,l,0,c5,0,1f3|||",
                "Africa_Western": "v,0,l,0,43,0,l,0,c5,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,c5,0,hu||",
                "Alaska": "v,0,l,0,43,0,l,0,3p|v,0,l,0,cj,0,l,0,3p||",
                "Amazon": "v,0,l,0,43,0,l,0,5m|v,0,l,0,8d,0,59,0,8j,0,l,0,5m||",
                "America_Central": "v,0,l,0,43,0,l,0,ps|v,0,l,0,cj,0,l,0,ps||",
                "America_Eastern": "v,0,l,0,43,0,l,0,mn|v,0,l,0,cj,0,l,0,mn||",
                "America_Mountain": "v,0,l,0,43,0,l,0,5a|v,0,l,0,cj,0,l,0,5a||",
                "America_Pacific": "v,0,l,0,43,0,l,0,22s|v,0,l,0,cj,0,l,0,22s||",
                "Anadyr": "v,0,l,0,43,0,l,0,3l|v,0,l,0,1f4,0,l,0,3l||",
                "Apia": "v,0,43,0,l,0,3e|v,0,l,0,cj,0,l,0,3e||",
                "Arabian": "v,0,43,0,l,0,3ft|v,0,l,0,cj,0,l,0,3fs||",
                "Argentina": "v,0,l,0,43,0,l,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,1e||",
                "Argentina_Western": "v,0,43,0,l,0,hu,0,oc,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,1e||",
                "Armenia": "v,0,43,0,l,0,4l|v,0,l,0,8d,0,59,0,8j,0,l,0,4l||",
                "Atlantic": "v,0,l,0,43,0,l,0,230|v,0,l,0,cj,0,l,0,230||",
                "Australia_Central": "v,0,43,0,l,0,4c,0,59,0,ps|v,0,l,0,cj,0,l,0,4c,0,59,0,ps||",
                "Australia_CentralWestern": "v,0,43,0,l,0,hu,0,59,0,ps,0,59,0,4c|v,0,l,0,cj,0,l,0,hu,0,59,0,ps,0,59,0,4c||",
                "Australia_Eastern": "v,0,43,0,l,0,mn,0,oc,0,4c|v,0,l,0,cj,0,l,0,mn,0,oc,0,4c||",
                "Australia_Western": "v,0,43,0,l,0,4c,0,hu|v,0,l,0,cj,0,l,0,4c,0,hu||",
                "Azerbaijan": "v,0,43,0,l,0,232|v,0,l,0,8d,0,59,0,8j,0,l,0,232||",
                "Azores": "v,0,43,0,l,0,4r|v,0,l,0,8d,0,59,0,8j,0,l,0,4r||",
                "Bangladesh": "v,0,43,0,l,0,3n|v,0,l,0,8d,0,59,0,8j,0,l,0,3n||",
                "Bhutan": "v,0,l,0,as|||",
                "Bolivia": "v,0,l,0,aa|||",
                "Brasilia": "v,0,l,0,43,0,l,0,3a|v,0,l,0,8d,0,59,0,8j,0,l,0,3a||",
                "Brunei": "v,0,l,0,9o,0,a4|||",
                "Cape_Verde": "v,0,l,0,43,0,l,0,7c,0,3j|v,0,l,0,8d,0,59,0,8j,0,l,0,7c,0,3j||",
                "Chamorro": "v,0,43,0,l,0,6j|||",
                "Chatham": "v,0,43,0,l,0,27|v,0,l,0,cj,0,l,0,27||",
                "Chile": "v,0,l,0,43,0,l,0,49|v,0,l,0,8d,0,59,0,8j,0,l,0,49||",
                "China": "v,0,43,0,l,0,233|v,0,l,0,cj,0,l,0,233||",
                "Choibalsan": "v,0,43,0,l,0,2q|v,0,l,0,8d,0,59,0,8j,0,l,0,2q||",
                "Christmas": "v,0,l,0,13r,0,11t,0,99|||",
                "Cocos": "v,0,l,0,p9,0,q0,0,7v|||",
                "Colombia": "v,0,l,0,43,0,l,0,4m|v,0,l,0,8d,0,59,0,8j,0,l,0,4m||",
                "Cook": "v,0,43,0,l,0,p9,0,q0,0,2v|v,0,l,0,8d,0,3fj,0,59,0,8j,0,l,0,p9,0,2v||",
                "Cuba": "v,0,l,0,43,0,59,0,34|v,0,l,0,cj,0,l,0,34||",
                "Davis": "v,0,l,0,5s|||",
                "DumontDUrville": "v,0,l,0,5b,5,5j|||",
                "East_Timor": "v,0,l,0,7n,0,mn|||",
                "Easter": "v,0,43,0,l,0,13r,0,11t,0,23f|v,0,l,0,8d,0,59,0,8j,0,l,0,13r,0,11t,0,23f||",
                "Ecuador": "v,0,l,0,ai|||",
                "Europe_Central": "v,0,43,0,l,0,114,0,59,0,3fg|v,0,l,0,8d,0,59,0,8j,0,l,0,114,0,59,0,ps||",
                "Europe_Eastern": "v,0,43,0,l,0,mn,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,mn,0,oc,0,114||",
                "Europe_Further_Eastern": "v,0,l,0,bu,5,c0,0,18,0,1|||",
                "Europe_Western": "v,0,43,0,l,0,hu,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,114||",
                "Falkland": "v,0,43,0,l,0,p9,0,q0,0,4v|v,0,l,0,8d,0,59,0,8j,0,l,0,p9,0,q0,0,4v||",
                "Fiji": "v,0,43,0,l,0,4q|v,0,l,0,8d,0,59,0,8j,0,l,0,4q||",
                "French_Guiana": "v,0,l,0,am,0,59,0,23h|||",
                "French_Southern": "v,0,l,0,1f3,0,oc,0,23h,0,52,0,3fe|||",
                "Galapagos": "v,0,l,0,ak|||",
                "Gambier": "v,0,l,0,6v|||",
                "Georgia": "v,0,43,0,l,0,1f7|v,0,l,0,8d,0,59,0,8j,0,l,0,1f7||",
                "Gilbert_Islands": "v,0,l,0,p9,0,q0,0,6q|||",
                "GMT": "v,0,l,0,5u|||",
                "Greenland_Eastern": "v,0,l,0,43,0,l,0,2k,0,mn|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,mn||",
                "Greenland_Western": "v,0,l,0,43,0,l,0,2k,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,hu||",
                "Gulf": "v,0,43,0,l,0,bn|||",
                "Guyana": "v,0,l,0,7a|||",
                "Hawaii_Aleutian": "v,0,l,0,43,0,l,0,4g,5,62|v,0,l,0,cj,0,l,0,4g,5,62||",
                "Hong_Kong": "v,0,43,0,l,0,2l,0,2p|v,0,l,0,8d,0,59,0,8j,0,l,0,2l,0,2p||",
                "Hovd": "v,0,43,0,l,0,2g|v,0,l,0,8d,0,59,0,8j,0,l,0,2g||",
                "India": "v,0,43,0,l,0,af|||",
                "Indian_Ocean": "v,0,l,0,3fc,0,1to|||",
                "Indochina": "v,0,l,0,9p|||",
                "Indonesia_Central": "v,0,l,0,2t,0,59,0,ps|||",
                "Indonesia_Eastern": "v,0,l,0,mn,0,oc,0,2t|||",
                "Indonesia_Western": "v,0,l,0,hu,0,oc,0,2t|||",
                "Iran": "v,0,43,0,l,0,5g|v,0,l,0,cj,0,l,0,5g||",
                "Irkutsk": "v,0,l,0,43,0,l,0,3h|v,0,l,0,8d,0,59,0,8j,0,l,0,3h||",
                "Israel": "v,0,43,0,l,0,23k|v,0,l,0,cj,0,l,0,23k||",
                "Japan": "v,0,43,0,l,0,6o|v,0,l,0,cj,0,l,0,6o||",
                "Kamchatka": "v,0,l,0,43,0,l,0,37,5,3k|v,0,l,0,1f4,0,l,0,37,5,3k||",
                "Kazakhstan_Eastern": "v,0,l,0,45,0,mn|||",
                "Kazakhstan_Western": "v,0,l,0,45,0,hu|||",
                "Korea": "v,0,43,0,l,0,qf|v,0,l,0,cj,0,l,0,qf||",
                "Kosrae": "v,0,l,0,6f|||",
                "Krasnoyarsk": "v,0,l,0,43,0,l,0,3fa|v,0,l,0,8d,0,59,0,8j,0,l,0,41||",
                "Kyrgystan": "v,0,l,0,bl|||",
                "Line_Islands": "v,0,l,0,p9,0,q0,0,ac|||",
                "Lord_Howe": "v,0,43,0,l,0,1u,0,24|v,0,l,0,cj,0,l,0,1u,0,24||",
                "Macquarie": "v,0,l,0,3f9,0,11t,0,6m|||",
                "Magadan": "v,0,l,0,43,0,l,0,2o|v,0,l,0,8d,0,59,0,8j,0,l,0,2o||",
                "Malaysia": "v,0,l,0,bf|||",
                "Maldives": "v,0,l,0,9k|||",
                "Marquesas": "v,0,l,0,9e|||",
                "Marshall_Islands": "v,0,l,0,p9,0,q0,0,7o|||",
                "Mauritius": "v,0,l,0,43,0,l,0,4u|v,0,l,0,8d,0,59,0,8j,0,l,0,4u||",
                "Mawson": "v,0,l,0,63|||",
                "Mexico_Northwest": "v,0,43,0,l,0,5e,0,23l,0,hu|v,0,l,0,23m,0,l,0,5e,0,23l,0,hu||",
                "Mexico_Pacific": "v,0,l,0,3f6,0,l,0,23n,0,l,0,5e|v,0,l,0,23m,0,l,0,23n,0,l,0,5e||",
                "Mongolia": "v,0,43,0,l,0,4f,0,2m|v,0,l,0,8d,0,59,0,8j,0,l,0,4f,0,2m||",
                "Moscow": "v,0,l,0,43,0,l,0,71|v,0,l,0,8d,0,59,0,8j,0,l,0,71||",
                "Myanmar": "v,0,l,0,8v|||",
                "Nauru": "v,0,l,0,5v|||",
                "Nepal": "v,0,l,0,8u|||",
                "New_Caledonia": "v,0,43,0,l,0,1m,0,4o|v,0,l,0,8d,0,59,0,8j,0,l,0,1m,0,4o||",
                "New_Zealand": "v,0,43,0,l,0,1m,0,74|v,0,l,0,cj,0,l,0,1m,0,74||",
                "Newfoundland": "v,0,l,0,43,0,l,0,53|v,0,l,0,cj,0,l,0,53||",
                "Niue": "v,0,l,0,8g|||",
                "Norfolk": "v,0,l,0,13r,0,11t,0,6l|||",
                "Noronha": "v,0,43,0,l,0,1s,0,2,0,23|v,0,l,0,8d,0,59,0,8j,0,l,0,1s,0,2,0,23||",
                "Novosibirsk": "v,0,l,0,43,0,l,0,3b|v,0,l,0,8d,0,59,0,8j,0,l,0,3b||",
                "Omsk": "v,0,l,0,43,0,l,0,26|v,0,l,0,8d,0,59,0,8j,0,l,0,26||",
                "Pakistan": "v,0,43,0,l,0,3i|v,0,l,0,8d,0,59,0,8j,0,l,0,3i||",
                "Palau": "v,0,l,0,9b|||",
                "Papua_New_Guinea": "v,0,l,0,9a,0,1m,0,a8|||",
                "Paraguay": "v,0,l,0,43,0,l,0,3c|v,0,l,0,8d,0,59,0,8j,0,l,0,3c||",
                "Peru": "v,0,l,0,43,0,l,0,4n|v,0,l,0,8d,0,59,0,8j,0,l,0,4n||",
                "Philippines": "v,0,43,0,l,0,23p|v,0,l,0,8d,0,59,0,8j,0,l,0,23p||",
                "Phoenix_Islands": "v,0,l,0,p9,0,q0,0,90|||",
                "Pierre_Miquelon": "v,0,l,0,43,0,59,0,8i,5,33,0,52,0,35|v,0,l,0,cj,0,l,0,8i,5,33,0,52,0,35||",
                "Pitcairn": "v,0,l,0,7e|||",
                "Ponape": "v,0,l,0,9s|||",
                "Pyongyang": "v,0,l,0,8a|||",
                "Reunion": "v,0,l,0,b4|||",
                "Rothera": "v,0,l,0,5q|||",
                "Sakhalin": "v,0,l,0,43,0,l,0,55|v,0,l,0,8d,0,59,0,8j,0,l,0,55||",
                "Samara": "v,0,l,0,43,0,l,0,29|v,0,l,0,1f4,0,l,0,29||",
                "Samoa": "v,0,43,0,l,0,2c|v,0,l,0,8d,0,59,0,8j,0,l,0,2c||",
                "Seychelles": "v,0,l,0,3f3|||",
                "Singapore": "v,0,43,0,l,0,bi|||",
                "Solomon": "v,0,l,0,p9,0,q0,0,b5|||",
                "South_Georgia": "v,0,l,0,1f7,0,1f3|||",
                "Suriname": "v,0,l,0,8q|||",
                "Syowa": "v,0,l,0,65|||",
                "Tahiti": "v,0,l,0,8n|||",
                "Taipei": "v,0,43,0,l,0,3d|v,0,l,0,cj,0,l,0,3d||",
                "Tajikistan": "v,0,l,0,bj|||",
                "Tokelau": "v,0,l,0,5d|||",
                "Tonga": "v,0,43,0,l,0,2j|v,0,l,0,8d,0,59,0,8j,0,l,0,2j||",
                "Truk": "v,0,l,0,6p|||",
                "Turkmenistan": "v,0,l,0,43,0,l,0,4t|v,0,l,0,8d,0,59,0,8j,0,l,0,4t||",
                "Tuvalu": "v,0,l,0,6h|||",
                "Uruguay": "v,0,l,0,43,0,l,0,4k|v,0,l,0,8d,0,59,0,8j,0,l,0,4k||",
                "Uzbekistan": "v,0,l,0,43,0,l,0,60|v,0,l,0,8d,0,59,0,8j,0,l,0,60||",
                "Vanuatu": "v,0,43,0,l,0,2f|v,0,l,0,8d,0,59,0,8j,0,l,0,2f||",
                "Venezuela": "v,0,l,0,7s|||",
                "Vladivostok": "v,0,l,0,43,0,l,0,28|v,0,l,0,8d,0,59,0,8j,0,l,0,28||",
                "Volgograd": "v,0,l,0,43,0,l,0,3f|v,0,l,0,8d,0,59,0,8j,0,l,0,3f||",
                "Vostok": "v,0,l,0,5t|||",
                "Wake": "v,0,l,0,13r,0,11t,0,5r|||",
                "Wallis": "v,0,l,0,5f,0,52,0,5k|||",
                "Yakutsk": "v,0,l,0,43,0,l,0,3u|v,0,l,0,8d,0,59,0,8j,0,l,0,3u||",
                "Yekaterinburg": "v,0,l,0,43,0,l,0,5l|v,0,l,0,8d,0,59,0,8j,0,l,0,5l||"
            }
        },
        "sw": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3et||",
                "Europe/London": "|1r7,0,18p,0,8d,0,59,0,1r3,0,18p,0,3ae||"
            },
            "metazone": {
                "Afghanistan": "v,0,l,0,b6|||",
                "Africa_Central": "v,0,l,0,c5,0,59,0,ps|||",
                "Africa_Eastern": "v,0,l,0,c5,0,mn|||",
                "Africa_Southern": "v,0,43,0,l,0,c5,0,1f3|||",
                "Africa_Western": "v,0,l,0,43,0,l,0,c5,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,c5,0,hu||",
                "Alaska": "v,0,l,0,43,0,l,0,3p|v,0,l,0,cj,0,l,0,3p||",
                "Amazon": "v,0,l,0,43,0,l,0,5m|v,0,l,0,8d,0,59,0,8j,0,l,0,5m||",
                "America_Central": "v,0,l,0,43,0,l,0,ps|v,0,l,0,cj,0,l,0,ps||",
                "America_Eastern": "v,0,l,0,43,0,l,0,mn|v,0,l,0,cj,0,l,0,mn||",
                "America_Mountain": "v,0,l,0,43,0,l,0,5a|v,0,l,0,cj,0,l,0,5a||",
                "America_Pacific": "v,0,l,0,43,0,l,0,22s|v,0,l,0,cj,0,l,0,22s||",
                "Anadyr": "v,0,l,0,43,0,l,0,3l|v,0,l,0,1f4,0,l,0,3l||",
                "Apia": "v,0,43,0,l,0,3e|v,0,l,0,cj,0,l,0,3e||",
                "Arabian": "v,0,43,0,l,0,3ft|v,0,l,0,cj,0,l,0,3fs||",
                "Argentina": "v,0,l,0,43,0,l,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,1e||",
                "Argentina_Western": "v,0,43,0,l,0,hu,0,oc,0,1e|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,1e||",
                "Armenia": "v,0,43,0,l,0,4l|v,0,l,0,8d,0,59,0,8j,0,l,0,4l||",
                "Atlantic": "v,0,l,0,43,0,l,0,230|v,0,l,0,cj,0,l,0,230||",
                "Australia_Central": "v,0,43,0,l,0,4c,0,59,0,ps|v,0,l,0,cj,0,l,0,4c,0,59,0,ps||",
                "Australia_CentralWestern": "v,0,43,0,l,0,hu,0,59,0,ps,0,59,0,4c|v,0,l,0,cj,0,l,0,hu,0,59,0,ps,0,59,0,4c||",
                "Australia_Eastern": "v,0,43,0,l,0,mn,0,oc,0,4c|v,0,l,0,cj,0,l,0,mn,0,oc,0,4c||",
                "Australia_Western": "v,0,43,0,l,0,4c,0,hu|v,0,l,0,cj,0,l,0,4c,0,hu||",
                "Azerbaijan": "v,0,43,0,l,0,232|v,0,l,0,8d,0,59,0,8j,0,l,0,232||",
                "Azores": "v,0,43,0,l,0,4r|v,0,l,0,8d,0,59,0,8j,0,l,0,4r||",
                "Bangladesh": "v,0,43,0,l,0,3n|v,0,l,0,8d,0,59,0,8j,0,l,0,3n||",
                "Bhutan": "v,0,l,0,as|||",
                "Bolivia": "v,0,l,0,aa|||",
                "Brasilia": "v,0,l,0,43,0,l,0,3a|v,0,l,0,8d,0,59,0,8j,0,l,0,3a||",
                "Brunei": "v,0,l,0,9o,0,a4|||",
                "Cape_Verde": "v,0,l,0,43,0,l,0,7c,0,3j|v,0,l,0,8d,0,59,0,8j,0,l,0,7c,0,3j||",
                "Chamorro": "v,0,43,0,l,0,6j|||",
                "Chatham": "v,0,43,0,l,0,27|v,0,l,0,cj,0,l,0,27||",
                "Chile": "v,0,l,0,43,0,l,0,49|v,0,l,0,8d,0,59,0,8j,0,l,0,49||",
                "China": "v,0,43,0,l,0,233|v,0,l,0,cj,0,l,0,233||",
                "Choibalsan": "v,0,43,0,l,0,2q|v,0,l,0,8d,0,59,0,8j,0,l,0,2q||",
                "Christmas": "v,0,l,0,13r,0,11t,0,99|||",
                "Cocos": "v,0,l,0,p9,0,q0,0,7v|||",
                "Colombia": "v,0,l,0,43,0,l,0,4m|v,0,l,0,8d,0,59,0,8j,0,l,0,4m||",
                "Cook": "v,0,43,0,l,0,p9,0,q0,0,2v|v,0,l,0,8d,0,3fj,0,59,0,8j,0,l,0,p9,0,2v||",
                "Cuba": "v,0,l,0,43,0,59,0,34|v,0,l,0,cj,0,l,0,34||",
                "Davis": "v,0,l,0,5s|||",
                "DumontDUrville": "v,0,l,0,5b,5,5j|||",
                "East_Timor": "v,0,l,0,7n,0,mn|||",
                "Easter": "v,0,43,0,l,0,13r,0,11t,0,23f|v,0,l,0,8d,0,59,0,8j,0,l,0,13r,0,11t,0,23f||",
                "Ecuador": "v,0,l,0,ai|||",
                "Europe_Central": "v,0,43,0,l,0,114,0,59,0,3fg|v,0,l,0,8d,0,59,0,8j,0,l,0,114,0,59,0,ps||",
                "Europe_Eastern": "v,0,43,0,l,0,mn,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,mn,0,oc,0,114||",
                "Europe_Further_Eastern": "v,0,l,0,bu,5,c0,0,18,0,1|||",
                "Europe_Western": "v,0,43,0,l,0,hu,0,oc,0,114|v,0,l,0,8d,0,59,0,8j,0,l,0,hu,0,oc,0,114||",
                "Falkland": "v,0,43,0,l,0,p9,0,q0,0,4v|v,0,l,0,8d,0,59,0,8j,0,l,0,p9,0,q0,0,4v||",
                "Fiji": "v,0,43,0,l,0,4q|v,0,l,0,8d,0,59,0,8j,0,l,0,4q||",
                "French_Guiana": "v,0,l,0,am,0,59,0,23h|||",
                "French_Southern": "v,0,l,0,1f3,0,oc,0,23h,0,52,0,3fe|||",
                "Galapagos": "v,0,l,0,ak|||",
                "Gambier": "v,0,l,0,6v|||",
                "Georgia": "v,0,43,0,l,0,1f7|v,0,l,0,8d,0,59,0,8j,0,l,0,1f7||",
                "Gilbert_Islands": "v,0,l,0,p9,0,q0,0,6q|||",
                "GMT": "v,0,l,0,5u|||",
                "Greenland_Eastern": "v,0,l,0,43,0,l,0,2k,0,mn|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,mn||",
                "Greenland_Western": "v,0,l,0,43,0,l,0,2k,0,hu|v,0,l,0,8d,0,59,0,8j,0,l,0,2k,0,hu||",
                "Gulf": "v,0,43,0,l,0,bn|||",
                "Guyana": "v,0,l,0,7a|||",
                "Hawaii_Aleutian": "v,0,l,0,43,0,l,0,4g,5,62|v,0,l,0,cj,0,l,0,4g,5,62||",
                "Hong_Kong": "v,0,43,0,l,0,2l,0,2p|v,0,l,0,8d,0,59,0,8j,0,l,0,2l,0,2p||",
                "Hovd": "v,0,43,0,l,0,2g|v,0,l,0,8d,0,59,0,8j,0,l,0,2g||",
                "India": "v,0,43,0,l,0,af|||",
                "Indian_Ocean": "v,0,l,0,3fc,0,1to|||",
                "Indochina": "v,0,l,0,9p|||",
                "Indonesia_Central": "v,0,l,0,2t,0,59,0,ps|||",
                "Indonesia_Eastern": "v,0,l,0,mn,0,oc,0,2t|||",
                "Indonesia_Western": "v,0,l,0,hu,0,oc,0,2t|||",
                "Iran": "v,0,43,0,l,0,5g|v,0,l,0,cj,0,l,0,5g||",
                "Irkutsk": "v,0,l,0,43,0,l,0,3h|v,0,l,0,8d,0,59,0,8j,0,l,0,3h||",
                "Israel": "v,0,43,0,l,0,23k|v,0,l,0,cj,0,l,0,23k||",
                "Japan": "v,0,43,0,l,0,6o|v,0,l,0,cj,0,l,0,6o||",
                "Kamchatka": "v,0,l,0,43,0,l,0,37,5,3k|v,0,l,0,1f4,0,l,0,37,5,3k||",
                "Kazakhstan_Eastern": "v,0,l,0,45,0,mn|||",
                "Kazakhstan_Western": "v,0,l,0,45,0,hu|||",
                "Korea": "v,0,43,0,l,0,qf|v,0,l,0,cj,0,l,0,qf||",
                "Kosrae": "v,0,l,0,6f|||",
                "Krasnoyarsk": "v,0,l,0,43,0,l,0,3fa|v,0,l,0,8d,0,59,0,8j,0,l,0,41||",
                "Kyrgystan": "v,0,l,0,bl|||",
                "Line_Islands": "v,0,l,0,p9,0,q0,0,ac|||",
                "Lord_Howe": "v,0,43,0,l,0,1u,0,24|v,0,l,0,cj,0,l,0,1u,0,24||",
                "Macquarie": "v,0,l,0,3f9,0,11t,0,6m|||",
                "Magadan": "v,0,l,0,43,0,l,0,2o|v,0,l,0,8d,0,59,0,8j,0,l,0,2o||",
                "Malaysia": "v,0,l,0,bf|||",
                "Maldives": "v,0,l,0,9k|||",
                "Marquesas": "v,0,l,0,9e|||",
                "Marshall_Islands": "v,0,l,0,p9,0,q0,0,7o|||",
                "Mauritius": "v,0,l,0,43,0,l,0,4u|v,0,l,0,8d,0,59,0,8j,0,l,0,4u||",
                "Mawson": "v,0,l,0,63|||",
                "Mexico_Northwest": "v,0,43,0,l,0,5e,0,23l,0,hu|v,0,l,0,23m,0,l,0,5e,0,23l,0,hu||",
                "Mexico_Pacific": "v,0,l,0,3f6,0,l,0,23n,0,l,0,5e|v,0,l,0,23m,0,l,0,23n,0,l,0,5e||",
                "Mongolia": "v,0,43,0,l,0,4f,0,2m|v,0,l,0,8d,0,59,0,8j,0,l,0,4f,0,2m||",
                "Moscow": "v,0,l,0,43,0,l,0,71|v,0,l,0,8d,0,59,0,8j,0,l,0,71||",
                "Myanmar": "v,0,l,0,8v|||",
                "Nauru": "v,0,l,0,5v|||",
                "Nepal": "v,0,l,0,8u|||",
                "New_Caledonia": "v,0,43,0,l,0,1m,0,4o|v,0,l,0,8d,0,59,0,8j,0,l,0,1m,0,4o||",
                "New_Zealand": "v,0,43,0,l,0,1m,0,74|v,0,l,0,cj,0,l,0,1m,0,74||",
                "Newfoundland": "v,0,l,0,43,0,l,0,53|v,0,l,0,cj,0,l,0,53||",
                "Niue": "v,0,l,0,8g|||",
                "Norfolk": "v,0,l,0,13r,0,11t,0,6l|||",
                "Noronha": "v,0,43,0,l,0,1s,0,2,0,23|v,0,l,0,8d,0,59,0,8j,0,l,0,1s,0,2,0,23||",
                "Novosibirsk": "v,0,l,0,43,0,l,0,3b|v,0,l,0,8d,0,59,0,8j,0,l,0,3b||",
                "Omsk": "v,0,l,0,43,0,l,0,26|v,0,l,0,8d,0,59,0,8j,0,l,0,26||",
                "Pakistan": "v,0,43,0,l,0,3i|v,0,l,0,8d,0,59,0,8j,0,l,0,3i||",
                "Palau": "v,0,l,0,9b|||",
                "Papua_New_Guinea": "v,0,l,0,9a,0,1m,0,a8|||",
                "Paraguay": "v,0,l,0,43,0,l,0,3c|v,0,l,0,8d,0,59,0,8j,0,l,0,3c||",
                "Peru": "v,0,l,0,43,0,l,0,4n|v,0,l,0,8d,0,59,0,8j,0,l,0,4n||",
                "Philippines": "v,0,43,0,l,0,23p|v,0,l,0,8d,0,59,0,8j,0,l,0,23p||",
                "Phoenix_Islands": "v,0,l,0,p9,0,q0,0,90|||",
                "Pierre_Miquelon": "v,0,l,0,43,0,59,0,8i,5,33,0,52,0,35|v,0,l,0,cj,0,l,0,8i,5,33,0,52,0,35||",
                "Pitcairn": "v,0,l,0,7e|||",
                "Ponape": "v,0,l,0,9s|||",
                "Pyongyang": "v,0,l,0,8a|||",
                "Reunion": "v,0,l,0,b4|||",
                "Rothera": "v,0,l,0,5q|||",
                "Sakhalin": "v,0,l,0,43,0,l,0,55|v,0,l,0,8d,0,59,0,8j,0,l,0,55||",
                "Samara": "v,0,l,0,43,0,l,0,29|v,0,l,0,1f4,0,l,0,29||",
                "Samoa": "v,0,43,0,l,0,2c|v,0,l,0,8d,0,59,0,8j,0,l,0,2c||",
                "Seychelles": "v,0,l,0,3f3|||",
                "Singapore": "v,0,43,0,l,0,bi|||",
                "Solomon": "v,0,l,0,p9,0,q0,0,b5|||",
                "South_Georgia": "v,0,l,0,1f7,0,1f3|||",
                "Suriname": "v,0,l,0,8q|||",
                "Syowa": "v,0,l,0,65|||",
                "Tahiti": "v,0,l,0,8n|||",
                "Taipei": "v,0,43,0,l,0,3d|v,0,l,0,cj,0,l,0,3d||",
                "Tajikistan": "v,0,l,0,bj|||",
                "Tokelau": "v,0,l,0,5d|||",
                "Tonga": "v,0,43,0,l,0,2j|v,0,l,0,8d,0,59,0,8j,0,l,0,2j||",
                "Truk": "v,0,l,0,6p|||",
                "Turkmenistan": "v,0,l,0,43,0,l,0,4t|v,0,l,0,8d,0,59,0,8j,0,l,0,4t||",
                "Tuvalu": "v,0,l,0,6h|||",
                "Uruguay": "v,0,l,0,43,0,l,0,4k|v,0,l,0,8d,0,59,0,8j,0,l,0,4k||",
                "Uzbekistan": "v,0,l,0,43,0,l,0,60|v,0,l,0,8d,0,59,0,8j,0,l,0,60||",
                "Vanuatu": "v,0,43,0,l,0,2f|v,0,l,0,8d,0,59,0,8j,0,l,0,2f||",
                "Venezuela": "v,0,l,0,7s|||",
                "Vladivostok": "v,0,l,0,43,0,l,0,28|v,0,l,0,8d,0,59,0,8j,0,l,0,28||",
                "Volgograd": "v,0,l,0,43,0,l,0,3f|v,0,l,0,8d,0,59,0,8j,0,l,0,3f||",
                "Vostok": "v,0,l,0,5t|||",
                "Wake": "v,0,l,0,13r,0,11t,0,5r|||",
                "Wallis": "v,0,l,0,5f,0,52,0,5k|||",
                "Yakutsk": "v,0,l,0,43,0,l,0,3u|v,0,l,0,8d,0,59,0,8j,0,l,0,3u||",
                "Yekaterinburg": "v,0,l,0,43,0,l,0,5l|v,0,l,0,8d,0,59,0,8j,0,l,0,5l||"
            }
        },
        "ta-LK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3ab,0,47,0,r||",
                "Europe/London": "|3a9,0,6s,0,r||"
            },
            "metazone": {
                "Acre": "23q,0,p5,0,r|23q,0,6s,0,r||",
                "Afghanistan": "3eu,0,r|||",
                "Africa_Central": "p1,0,14o,0,r|||",
                "Africa_Eastern": "11p,0,14o,0,r|||",
                "Africa_Southern": "3eq,0,14o,0,47,0,r|||",
                "Africa_Western": "14f,0,14o,0,47,0,r|14f,0,14o,0,6s,0,r||",
                "Alaska": "243,0,47,0,r|243,0,bp,0,r||",
                "Almaty": "245,0,p5,0,r|245,0,6s,0,r||",
                "Amazon": "246,0,47,0,r|246,0,6s,0,r||",
                "America_Central": "p1,0,47,0,r|p1,0,bp,0,r||",
                "America_Eastern": "sq,0,47,0,r|sq,0,bp,0,r||",
                "America_Mountain": "248,0,47,0,r|248,0,bp,0,r||",
                "America_Pacific": "17n,0,47,0,r|17n,0,bp,0,r||",
                "Anadyr": "24a,0,p5,0,r|24a,0,6s,0,r||",
                "Apia": "24c,0,47,0,r|24c,0,bp,0,r||",
                "Aqtau": "24d,0,p5,0,r|24d,0,6s,0,r||",
                "Aqtobe": "24e,0,p5,0,r|24e,0,6s,0,r||",
                "Arabian": "24f,0,47,0,r|24f,0,bp,0,r||",
                "Argentina": "16u,0,47,0,r|16u,0,6s,0,r||",
                "Argentina_Western": "q7,0,16u,0,47,0,r|q7,0,16u,0,6s,0,r||",
                "Armenia": "24i,0,47,0,r|24i,0,6s,0,r||",
                "Atlantic": "24s,0,47,0,r|24s,0,bp,0,r||",
                "Australia_Central": "r9,0,p1,0,47,0,r|r9,0,p1,0,bp,0,r||",
                "Australia_CentralWestern": "r9,0,p1,0,q7,0,47,0,r|r9,0,p1,0,q7,0,bp,0,r||",
                "Australia_Eastern": "r9,0,sq,0,47,0,r|r9,0,sq,0,bp,0,r||",
                "Australia_Western": "r9,0,q7,0,47,0,r|r9,0,q7,0,bp,0,r||",
                "Azerbaijan": "24v,0,47,0,r|24v,0,6s,0,r||",
                "Azores": "3e5,0,47,0,r|3e4,0,6s,0,r||",
                "Bangladesh": "250,0,47,0,r|250,0,6s,0,r||",
                "Bhutan": "3e2,0,r|||",
                "Bolivia": "3e1,0,r|||",
                "Brasilia": "251,0,47,0,r|251,0,6s,0,r||",
                "Brunei": "3dv,0,3du,0,r|||",
                "Cape_Verde": "252,0,253,0,47,0,r|252,0,253,0,6s,0,r||",
                "Chamorro": "3dr,0,47,0,r|||",
                "Chatham": "254,0,47,0,r|254,0,bp,0,r||",
                "Chile": "255,0,47,0,r|255,0,6s,0,r||",
                "China": "259,0,47,0,r|259,0,bp,0,r||",
                "Choibalsan": "25b,0,47,0,r|25b,0,6s,0,r||",
                "Christmas": "3dm,0,11i,0,r|||",
                "Cocos": "3dk,0,ob,0,r|||",
                "Colombia": "25g,0,47,0,r|25g,0,6s,0,r||",
                "Cook": "25h,0,ob,0,47,0,r|25h,0,ob,0,3dg,0,6s,0,r||",
                "Cuba": "25i,0,47,0,r|25i,0,bp,0,r||",
                "Davis": "3de,0,r|||",
                "DumontDUrville": "3dd,5,1fn,0,3db,0,r|||",
                "East_Timor": "11p,0,3da,0,r|||",
                "Easter": "25k,0,11i,0,47,0,r|25k,0,11i,0,6s,0,r||",
                "Ecuador": "3d8,0,r|||",
                "Europe_Central": "p1,0,tp,0,47,0,r|p1,0,tp,0,6s,0,r||",
                "Europe_Eastern": "sq,0,tp,0,47,0,r|sq,0,tp,0,6s,0,r||",
                "Europe_Further_Eastern": "3d6,5,11p,0,tp,0,r|||",
                "Europe_Western": "q7,0,tp,0,47,0,r|q7,0,tp,0,6s,0,r||",
                "Falkland": "25n,0,ob,0,47,0,r|25n,0,ob,0,6s,0,r||",
                "Fiji": "25o,0,47,0,r|25o,0,6s,0,r||",
                "French_Guiana": "3d3,0,25p,0,r|||",
                "French_Southern": "3d1,0,3d0,0,31,0,3cv,0,r|||",
                "Galapagos": "3cu,0,r|||",
                "Gambier": "3ct,0,r|||",
                "Georgia": "1fo,0,47,0,r|1fo,0,6s,0,r||",
                "Gilbert_Islands": "3cr,0,ob,0,r|||",
                "GMT": "3cq,0,3cp,0,r|||",
                "Greenland_Eastern": "11p,0,17h,0,47,0,r|11p,0,17h,0,6s,0,r||",
                "Greenland_Western": "14f,0,17h,0,47,0,r|14f,0,17h,0,6s,0,r||",
                "Guam": "3cn,0,p5,0,r|||",
                "Gulf": "3cm,0,47,0,r|||",
                "Guyana": "25p,0,r|||",
                "Hawaii_Aleutian": "25r,5,25q,0,47,0,r|25r,5,25q,0,bp,0,r||",
                "Hong_Kong": "25l,0,47,0,r|25l,0,6s,0,r||",
                "Hovd": "25j,0,47,0,r|25j,0,6s,0,r||",
                "India": "3ch,0,47,0,r||15a|",
                "Indian_Ocean": "3cg,0,3cf,0,r|||",
                "Indochina": "3ce,0,r|||",
                "Indonesia_Central": "p1,0,1iu,0,r|||",
                "Indonesia_Eastern": "sq,0,1iu,0,r|||",
                "Indonesia_Western": "q7,0,1iu,0,r|||",
                "Iran": "239,0,47,0,r|239,0,bp,0,r||",
                "Irkutsk": "238,0,47,0,r|238,0,6s,0,r||",
                "Israel": "237,0,47,0,r|237,0,bp,0,r||",
                "Japan": "236,0,47,0,r|236,0,bp,0,r||",
                "Kamchatka": "235,0,22v,0,p5,0,r|235,0,22v,0,6s,0,r||",
                "Kazakhstan_Eastern": "11p,0,22u,0,r|||",
                "Kazakhstan_Western": "14f,0,22u,0,r|||",
                "Korea": "22n,0,47,0,r|22n,0,bp,0,r||",
                "Kosrae": "3c4,0,r|||",
                "Krasnoyarsk": "22j,0,47,0,r|22j,0,6s,0,r||",
                "Kyrgystan": "3c2,0,r|||",
                "Lanka": "3c1,0,r|||",
                "Line_Islands": "3c0,0,ob,0,r|||",
                "Lord_Howe": "22g,0,22f,0,47,0,r|22g,0,22f,0,bp,0,r||",
                "Macau": "22e,0,p5,0,r|22e,0,6s,0,r||",
                "Macquarie": "3bs,0,11i,0,r|||",
                "Magadan": "22c,0,47,0,r|22c,0,6s,0,r||",
                "Malaysia": "3bq,0,r|||",
                "Maldives": "3bp,0,r|||",
                "Marquesas": "3bo,0,r|||",
                "Marshall_Islands": "3bn,0,ob,0,r|||",
                "Mauritius": "22a,0,47,0,r|22a,0,6s,0,r||",
                "Mawson": "3bl,0,r|||",
                "Mexico_Northwest": "227,0,223,0,47,0,r|227,0,223,0,bp,0,r||",
                "Mexico_Pacific": "222,0,17n,0,47,0,r|222,0,17n,0,bp,0,r||",
                "Mongolia": "221,0,21o,0,47,0,r|221,0,21o,0,6s,0,r||",
                "Moscow": "21e,0,47,0,r|21e,0,6s,0,r||",
                "Myanmar": "3be,0,r|||",
                "Nauru": "3bd,0,r|||",
                "Nepal": "3bc,0,r|||",
                "New_Caledonia": "1je,0,21a,0,47,0,r|1je,0,21a,0,6s,0,r||",
                "New_Zealand": "217,0,47,0,r|217,0,bp,0,r||",
                "Newfoundland": "213,0,47,0,r|213,0,bp,0,r||",
                "Niue": "3b7,0,r|||",
                "Norfolk": "3b6,0,11i,0,r|||",
                "Noronha": "212,0,1fn,0,20t,0,47,0,r|212,0,1fn,0,20t,0,6s,0,r||",
                "North_Mariana": "3b3,0,3b2,0,ob,0,r|||",
                "Novosibirsk": "20s,0,47,0,r|20s,0,6s,0,r||",
                "Omsk": "20r,0,47,0,r|20r,0,6s,0,r||",
                "Pakistan": "20o,0,47,0,r|20o,0,6s,0,r||",
                "Palau": "3au,0,r|||",
                "Papua_New_Guinea": "3at,0,1je,0,3as,0,r|||",
                "Paraguay": "20l,0,47,0,r|20l,0,6s,0,r||",
                "Peru": "20k,0,47,0,r|20k,0,6s,0,r||",
                "Philippines": "20h,0,47,0,r|20h,0,6s,0,r||",
                "Phoenix_Islands": "3ao,0,ob,0,r|||",
                "Pierre_Miquelon": "20g,0,20f,0,31,0,20d,0,47,0,r|20g,0,20f,0,31,0,20d,0,bp,0,r||",
                "Pitcairn": "3ak,0,r|||",
                "Ponape": "3aj,0,r|||",
                "Pyongyang": "3ai,0,r|||",
                "Qyzylorda": "20c,0,p5,0,r|20c,0,6s,0,r||",
                "Reunion": "3ag,0,r|||",
                "Rothera": "3af,0,r|||",
                "Sakhalin": "1tm,0,47,0,r|1tm,0,6s,0,r||",
                "Samara": "1tl,0,p5,0,r|1tl,0,6s,0,r||",
                "Samoa": "1tk,0,47,0,r|1tk,0,bp,0,r||",
                "Seychelles": "38a,0,r|||",
                "Singapore": "389,0,47,0,r|||",
                "Solomon": "388,0,ob,0,r|||",
                "South_Georgia": "387,0,1fo,0,r|||",
                "Suriname": "386,0,r|||",
                "Syowa": "385,0,r|||",
                "Tahiti": "384,0,r|||",
                "Taipei": "1tf,0,47,0,r|1tf,0,bp,0,r||",
                "Tajikistan": "382,0,r|||",
                "Tokelau": "381,0,r|||",
                "Tonga": "1td,0,47,0,r|1td,0,6s,0,r||",
                "Truk": "37v,0,r|||",
                "Turkmenistan": "1t8,0,47,0,r|1t8,0,6s,0,r||",
                "Tuvalu": "37s,0,r|||",
                "Uruguay": "1t7,0,47,0,r|1t7,0,6s,0,r||",
                "Uzbekistan": "1t4,0,47,0,r|1t4,0,6s,0,r||",
                "Vanuatu": "1t3,0,47,0,r|1t3,0,6s,0,r||",
                "Venezuela": "37n,0,r|||",
                "Vladivostok": "1t1,0,47,0,r|1t1,0,6s,0,r||",
                "Volgograd": "1t0,0,47,0,r|1t0,0,6s,0,r||",
                "Vostok": "37i,0,r|||",
                "Wake": "37h,0,11i,0,r|||",
                "Wallis": "37f,0,37e,0,37d,0,r|||",
                "Yakutsk": "1sv,0,47,0,r|1sv,0,6s,0,r||",
                "Yekaterinburg": "1su,0,47,0,r|1su,0,6s,0,r||"
            }
        },
        "ta-MY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3ab,0,47,0,r||",
                "Europe/London": "|3a9,0,6s,0,r||"
            },
            "metazone": {
                "Acre": "23q,0,p5,0,r|23q,0,6s,0,r||",
                "Afghanistan": "3eu,0,r|||",
                "Africa_Central": "p1,0,14o,0,r|||",
                "Africa_Eastern": "11p,0,14o,0,r|||",
                "Africa_Southern": "3eq,0,14o,0,47,0,r|||",
                "Africa_Western": "14f,0,14o,0,47,0,r|14f,0,14o,0,6s,0,r||",
                "Alaska": "243,0,47,0,r|243,0,bp,0,r||",
                "Almaty": "245,0,p5,0,r|245,0,6s,0,r||",
                "Amazon": "246,0,47,0,r|246,0,6s,0,r||",
                "America_Central": "p1,0,47,0,r|p1,0,bp,0,r||",
                "America_Eastern": "sq,0,47,0,r|sq,0,bp,0,r||",
                "America_Mountain": "248,0,47,0,r|248,0,bp,0,r||",
                "America_Pacific": "17n,0,47,0,r|17n,0,bp,0,r||",
                "Anadyr": "24a,0,p5,0,r|24a,0,6s,0,r||",
                "Apia": "24c,0,47,0,r|24c,0,bp,0,r||",
                "Aqtau": "24d,0,p5,0,r|24d,0,6s,0,r||",
                "Aqtobe": "24e,0,p5,0,r|24e,0,6s,0,r||",
                "Arabian": "24f,0,47,0,r|24f,0,bp,0,r||",
                "Argentina": "16u,0,47,0,r|16u,0,6s,0,r||",
                "Argentina_Western": "q7,0,16u,0,47,0,r|q7,0,16u,0,6s,0,r||",
                "Armenia": "24i,0,47,0,r|24i,0,6s,0,r||",
                "Atlantic": "24s,0,47,0,r|24s,0,bp,0,r||",
                "Australia_Central": "r9,0,p1,0,47,0,r|r9,0,p1,0,bp,0,r||",
                "Australia_CentralWestern": "r9,0,p1,0,q7,0,47,0,r|r9,0,p1,0,q7,0,bp,0,r||",
                "Australia_Eastern": "r9,0,sq,0,47,0,r|r9,0,sq,0,bp,0,r||",
                "Australia_Western": "r9,0,q7,0,47,0,r|r9,0,q7,0,bp,0,r||",
                "Azerbaijan": "24v,0,47,0,r|24v,0,6s,0,r||",
                "Azores": "3e5,0,47,0,r|3e4,0,6s,0,r||",
                "Bangladesh": "250,0,47,0,r|250,0,6s,0,r||",
                "Bhutan": "3e2,0,r|||",
                "Bolivia": "3e1,0,r|||",
                "Brasilia": "251,0,47,0,r|251,0,6s,0,r||",
                "Brunei": "3dv,0,3du,0,r|||",
                "Cape_Verde": "252,0,253,0,47,0,r|252,0,253,0,6s,0,r||",
                "Chamorro": "3dr,0,47,0,r|||",
                "Chatham": "254,0,47,0,r|254,0,bp,0,r||",
                "Chile": "255,0,47,0,r|255,0,6s,0,r||",
                "China": "259,0,47,0,r|259,0,bp,0,r||",
                "Choibalsan": "25b,0,47,0,r|25b,0,6s,0,r||",
                "Christmas": "3dm,0,11i,0,r|||",
                "Cocos": "3dk,0,ob,0,r|||",
                "Colombia": "25g,0,47,0,r|25g,0,6s,0,r||",
                "Cook": "25h,0,ob,0,47,0,r|25h,0,ob,0,3dg,0,6s,0,r||",
                "Cuba": "25i,0,47,0,r|25i,0,bp,0,r||",
                "Davis": "3de,0,r|||",
                "DumontDUrville": "3dd,5,1fn,0,3db,0,r|||",
                "East_Timor": "11p,0,3da,0,r|||",
                "Easter": "25k,0,11i,0,47,0,r|25k,0,11i,0,6s,0,r||",
                "Ecuador": "3d8,0,r|||",
                "Europe_Central": "p1,0,tp,0,47,0,r|p1,0,tp,0,6s,0,r||",
                "Europe_Eastern": "sq,0,tp,0,47,0,r|sq,0,tp,0,6s,0,r||",
                "Europe_Further_Eastern": "3d6,5,11p,0,tp,0,r|||",
                "Europe_Western": "q7,0,tp,0,47,0,r|q7,0,tp,0,6s,0,r||",
                "Falkland": "25n,0,ob,0,47,0,r|25n,0,ob,0,6s,0,r||",
                "Fiji": "25o,0,47,0,r|25o,0,6s,0,r||",
                "French_Guiana": "3d3,0,25p,0,r|||",
                "French_Southern": "3d1,0,3d0,0,31,0,3cv,0,r|||",
                "Galapagos": "3cu,0,r|||",
                "Gambier": "3ct,0,r|||",
                "Georgia": "1fo,0,47,0,r|1fo,0,6s,0,r||",
                "Gilbert_Islands": "3cr,0,ob,0,r|||",
                "GMT": "3cq,0,3cp,0,r|||",
                "Greenland_Eastern": "11p,0,17h,0,47,0,r|11p,0,17h,0,6s,0,r||",
                "Greenland_Western": "14f,0,17h,0,47,0,r|14f,0,17h,0,6s,0,r||",
                "Guam": "3cn,0,p5,0,r|||",
                "Gulf": "3cm,0,47,0,r|||",
                "Guyana": "25p,0,r|||",
                "Hawaii_Aleutian": "25r,5,25q,0,47,0,r|25r,5,25q,0,bp,0,r||",
                "Hong_Kong": "25l,0,47,0,r|25l,0,6s,0,r||",
                "Hovd": "25j,0,47,0,r|25j,0,6s,0,r||",
                "India": "3ch,0,47,0,r|||",
                "Indian_Ocean": "3cg,0,3cf,0,r|||",
                "Indochina": "3ce,0,r|||",
                "Indonesia_Central": "p1,0,1iu,0,r|||",
                "Indonesia_Eastern": "sq,0,1iu,0,r|||",
                "Indonesia_Western": "q7,0,1iu,0,r|||",
                "Iran": "239,0,47,0,r|239,0,bp,0,r||",
                "Irkutsk": "238,0,47,0,r|238,0,6s,0,r||",
                "Israel": "237,0,47,0,r|237,0,bp,0,r||",
                "Japan": "236,0,47,0,r|236,0,bp,0,r||",
                "Kamchatka": "235,0,22v,0,p5,0,r|235,0,22v,0,6s,0,r||",
                "Kazakhstan_Eastern": "11p,0,22u,0,r|||",
                "Kazakhstan_Western": "14f,0,22u,0,r|||",
                "Korea": "22n,0,47,0,r|22n,0,bp,0,r||",
                "Kosrae": "3c4,0,r|||",
                "Krasnoyarsk": "22j,0,47,0,r|22j,0,6s,0,r||",
                "Kyrgystan": "3c2,0,r|||",
                "Lanka": "3c1,0,r|||",
                "Line_Islands": "3c0,0,ob,0,r|||",
                "Lord_Howe": "22g,0,22f,0,47,0,r|22g,0,22f,0,bp,0,r||",
                "Macau": "22e,0,p5,0,r|22e,0,6s,0,r||",
                "Macquarie": "3bs,0,11i,0,r|||",
                "Magadan": "22c,0,47,0,r|22c,0,6s,0,r||",
                "Malaysia": "3bq,0,r||1r5|",
                "Maldives": "3bp,0,r|||",
                "Marquesas": "3bo,0,r|||",
                "Marshall_Islands": "3bn,0,ob,0,r|||",
                "Mauritius": "22a,0,47,0,r|22a,0,6s,0,r||",
                "Mawson": "3bl,0,r|||",
                "Mexico_Northwest": "227,0,223,0,47,0,r|227,0,223,0,bp,0,r||",
                "Mexico_Pacific": "222,0,17n,0,47,0,r|222,0,17n,0,bp,0,r||",
                "Mongolia": "221,0,21o,0,47,0,r|221,0,21o,0,6s,0,r||",
                "Moscow": "21e,0,47,0,r|21e,0,6s,0,r||",
                "Myanmar": "3be,0,r|||",
                "Nauru": "3bd,0,r|||",
                "Nepal": "3bc,0,r|||",
                "New_Caledonia": "1je,0,21a,0,47,0,r|1je,0,21a,0,6s,0,r||",
                "New_Zealand": "217,0,47,0,r|217,0,bp,0,r||",
                "Newfoundland": "213,0,47,0,r|213,0,bp,0,r||",
                "Niue": "3b7,0,r|||",
                "Norfolk": "3b6,0,11i,0,r|||",
                "Noronha": "212,0,1fn,0,20t,0,47,0,r|212,0,1fn,0,20t,0,6s,0,r||",
                "North_Mariana": "3b3,0,3b2,0,ob,0,r|||",
                "Novosibirsk": "20s,0,47,0,r|20s,0,6s,0,r||",
                "Omsk": "20r,0,47,0,r|20r,0,6s,0,r||",
                "Pakistan": "20o,0,47,0,r|20o,0,6s,0,r||",
                "Palau": "3au,0,r|||",
                "Papua_New_Guinea": "3at,0,1je,0,3as,0,r|||",
                "Paraguay": "20l,0,47,0,r|20l,0,6s,0,r||",
                "Peru": "20k,0,47,0,r|20k,0,6s,0,r||",
                "Philippines": "20h,0,47,0,r|20h,0,6s,0,r||",
                "Phoenix_Islands": "3ao,0,ob,0,r|||",
                "Pierre_Miquelon": "20g,0,20f,0,31,0,20d,0,47,0,r|20g,0,20f,0,31,0,20d,0,bp,0,r||",
                "Pitcairn": "3ak,0,r|||",
                "Ponape": "3aj,0,r|||",
                "Pyongyang": "3ai,0,r|||",
                "Qyzylorda": "20c,0,p5,0,r|20c,0,6s,0,r||",
                "Reunion": "3ag,0,r|||",
                "Rothera": "3af,0,r|||",
                "Sakhalin": "1tm,0,47,0,r|1tm,0,6s,0,r||",
                "Samara": "1tl,0,p5,0,r|1tl,0,6s,0,r||",
                "Samoa": "1tk,0,47,0,r|1tk,0,bp,0,r||",
                "Seychelles": "38a,0,r|||",
                "Singapore": "389,0,47,0,r||2ad|",
                "Solomon": "388,0,ob,0,r|||",
                "South_Georgia": "387,0,1fo,0,r|||",
                "Suriname": "386,0,r|||",
                "Syowa": "385,0,r|||",
                "Tahiti": "384,0,r|||",
                "Taipei": "1tf,0,47,0,r|1tf,0,bp,0,r||",
                "Tajikistan": "382,0,r|||",
                "Tokelau": "381,0,r|||",
                "Tonga": "1td,0,47,0,r|1td,0,6s,0,r||",
                "Truk": "37v,0,r|||",
                "Turkmenistan": "1t8,0,47,0,r|1t8,0,6s,0,r||",
                "Tuvalu": "37s,0,r|||",
                "Uruguay": "1t7,0,47,0,r|1t7,0,6s,0,r||",
                "Uzbekistan": "1t4,0,47,0,r|1t4,0,6s,0,r||",
                "Vanuatu": "1t3,0,47,0,r|1t3,0,6s,0,r||",
                "Venezuela": "37n,0,r|||",
                "Vladivostok": "1t1,0,47,0,r|1t1,0,6s,0,r||",
                "Volgograd": "1t0,0,47,0,r|1t0,0,6s,0,r||",
                "Vostok": "37i,0,r|||",
                "Wake": "37h,0,11i,0,r|||",
                "Wallis": "37f,0,37e,0,37d,0,r|||",
                "Yakutsk": "1sv,0,47,0,r|1sv,0,6s,0,r||",
                "Yekaterinburg": "1su,0,47,0,r|1su,0,6s,0,r||"
            }
        },
        "ta-SG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3ab,0,47,0,r||",
                "Europe/London": "|3a9,0,6s,0,r||"
            },
            "metazone": {
                "Acre": "23q,0,p5,0,r|23q,0,6s,0,r||",
                "Afghanistan": "3eu,0,r|||",
                "Africa_Central": "p1,0,14o,0,r|||",
                "Africa_Eastern": "11p,0,14o,0,r|||",
                "Africa_Southern": "3eq,0,14o,0,47,0,r|||",
                "Africa_Western": "14f,0,14o,0,47,0,r|14f,0,14o,0,6s,0,r||",
                "Alaska": "243,0,47,0,r|243,0,bp,0,r||",
                "Almaty": "245,0,p5,0,r|245,0,6s,0,r||",
                "Amazon": "246,0,47,0,r|246,0,6s,0,r||",
                "America_Central": "p1,0,47,0,r|p1,0,bp,0,r||",
                "America_Eastern": "sq,0,47,0,r|sq,0,bp,0,r||",
                "America_Mountain": "248,0,47,0,r|248,0,bp,0,r||",
                "America_Pacific": "17n,0,47,0,r|17n,0,bp,0,r||",
                "Anadyr": "24a,0,p5,0,r|24a,0,6s,0,r||",
                "Apia": "24c,0,47,0,r|24c,0,bp,0,r||",
                "Aqtau": "24d,0,p5,0,r|24d,0,6s,0,r||",
                "Aqtobe": "24e,0,p5,0,r|24e,0,6s,0,r||",
                "Arabian": "24f,0,47,0,r|24f,0,bp,0,r||",
                "Argentina": "16u,0,47,0,r|16u,0,6s,0,r||",
                "Argentina_Western": "q7,0,16u,0,47,0,r|q7,0,16u,0,6s,0,r||",
                "Armenia": "24i,0,47,0,r|24i,0,6s,0,r||",
                "Atlantic": "24s,0,47,0,r|24s,0,bp,0,r||",
                "Australia_Central": "r9,0,p1,0,47,0,r|r9,0,p1,0,bp,0,r||",
                "Australia_CentralWestern": "r9,0,p1,0,q7,0,47,0,r|r9,0,p1,0,q7,0,bp,0,r||",
                "Australia_Eastern": "r9,0,sq,0,47,0,r|r9,0,sq,0,bp,0,r||",
                "Australia_Western": "r9,0,q7,0,47,0,r|r9,0,q7,0,bp,0,r||",
                "Azerbaijan": "24v,0,47,0,r|24v,0,6s,0,r||",
                "Azores": "3e5,0,47,0,r|3e4,0,6s,0,r||",
                "Bangladesh": "250,0,47,0,r|250,0,6s,0,r||",
                "Bhutan": "3e2,0,r|||",
                "Bolivia": "3e1,0,r|||",
                "Brasilia": "251,0,47,0,r|251,0,6s,0,r||",
                "Brunei": "3dv,0,3du,0,r|||",
                "Cape_Verde": "252,0,253,0,47,0,r|252,0,253,0,6s,0,r||",
                "Chamorro": "3dr,0,47,0,r|||",
                "Chatham": "254,0,47,0,r|254,0,bp,0,r||",
                "Chile": "255,0,47,0,r|255,0,6s,0,r||",
                "China": "259,0,47,0,r|259,0,bp,0,r||",
                "Choibalsan": "25b,0,47,0,r|25b,0,6s,0,r||",
                "Christmas": "3dm,0,11i,0,r|||",
                "Cocos": "3dk,0,ob,0,r|||",
                "Colombia": "25g,0,47,0,r|25g,0,6s,0,r||",
                "Cook": "25h,0,ob,0,47,0,r|25h,0,ob,0,3dg,0,6s,0,r||",
                "Cuba": "25i,0,47,0,r|25i,0,bp,0,r||",
                "Davis": "3de,0,r|||",
                "DumontDUrville": "3dd,5,1fn,0,3db,0,r|||",
                "East_Timor": "11p,0,3da,0,r|||",
                "Easter": "25k,0,11i,0,47,0,r|25k,0,11i,0,6s,0,r||",
                "Ecuador": "3d8,0,r|||",
                "Europe_Central": "p1,0,tp,0,47,0,r|p1,0,tp,0,6s,0,r||",
                "Europe_Eastern": "sq,0,tp,0,47,0,r|sq,0,tp,0,6s,0,r||",
                "Europe_Further_Eastern": "3d6,5,11p,0,tp,0,r|||",
                "Europe_Western": "q7,0,tp,0,47,0,r|q7,0,tp,0,6s,0,r||",
                "Falkland": "25n,0,ob,0,47,0,r|25n,0,ob,0,6s,0,r||",
                "Fiji": "25o,0,47,0,r|25o,0,6s,0,r||",
                "French_Guiana": "3d3,0,25p,0,r|||",
                "French_Southern": "3d1,0,3d0,0,31,0,3cv,0,r|||",
                "Galapagos": "3cu,0,r|||",
                "Gambier": "3ct,0,r|||",
                "Georgia": "1fo,0,47,0,r|1fo,0,6s,0,r||",
                "Gilbert_Islands": "3cr,0,ob,0,r|||",
                "GMT": "3cq,0,3cp,0,r|||",
                "Greenland_Eastern": "11p,0,17h,0,47,0,r|11p,0,17h,0,6s,0,r||",
                "Greenland_Western": "14f,0,17h,0,47,0,r|14f,0,17h,0,6s,0,r||",
                "Guam": "3cn,0,p5,0,r|||",
                "Gulf": "3cm,0,47,0,r|||",
                "Guyana": "25p,0,r|||",
                "Hawaii_Aleutian": "25r,5,25q,0,47,0,r|25r,5,25q,0,bp,0,r||",
                "Hong_Kong": "25l,0,47,0,r|25l,0,6s,0,r||",
                "Hovd": "25j,0,47,0,r|25j,0,6s,0,r||",
                "India": "3ch,0,47,0,r|||",
                "Indian_Ocean": "3cg,0,3cf,0,r|||",
                "Indochina": "3ce,0,r|||",
                "Indonesia_Central": "p1,0,1iu,0,r|||",
                "Indonesia_Eastern": "sq,0,1iu,0,r|||",
                "Indonesia_Western": "q7,0,1iu,0,r|||",
                "Iran": "239,0,47,0,r|239,0,bp,0,r||",
                "Irkutsk": "238,0,47,0,r|238,0,6s,0,r||",
                "Israel": "237,0,47,0,r|237,0,bp,0,r||",
                "Japan": "236,0,47,0,r|236,0,bp,0,r||",
                "Kamchatka": "235,0,22v,0,p5,0,r|235,0,22v,0,6s,0,r||",
                "Kazakhstan_Eastern": "11p,0,22u,0,r|||",
                "Kazakhstan_Western": "14f,0,22u,0,r|||",
                "Korea": "22n,0,47,0,r|22n,0,bp,0,r||",
                "Kosrae": "3c4,0,r|||",
                "Krasnoyarsk": "22j,0,47,0,r|22j,0,6s,0,r||",
                "Kyrgystan": "3c2,0,r|||",
                "Lanka": "3c1,0,r|||",
                "Line_Islands": "3c0,0,ob,0,r|||",
                "Lord_Howe": "22g,0,22f,0,47,0,r|22g,0,22f,0,bp,0,r||",
                "Macau": "22e,0,p5,0,r|22e,0,6s,0,r||",
                "Macquarie": "3bs,0,11i,0,r|||",
                "Magadan": "22c,0,47,0,r|22c,0,6s,0,r||",
                "Malaysia": "3bq,0,r||1r5|",
                "Maldives": "3bp,0,r|||",
                "Marquesas": "3bo,0,r|||",
                "Marshall_Islands": "3bn,0,ob,0,r|||",
                "Mauritius": "22a,0,47,0,r|22a,0,6s,0,r||",
                "Mawson": "3bl,0,r|||",
                "Mexico_Northwest": "227,0,223,0,47,0,r|227,0,223,0,bp,0,r||",
                "Mexico_Pacific": "222,0,17n,0,47,0,r|222,0,17n,0,bp,0,r||",
                "Mongolia": "221,0,21o,0,47,0,r|221,0,21o,0,6s,0,r||",
                "Moscow": "21e,0,47,0,r|21e,0,6s,0,r||",
                "Myanmar": "3be,0,r|||",
                "Nauru": "3bd,0,r|||",
                "Nepal": "3bc,0,r|||",
                "New_Caledonia": "1je,0,21a,0,47,0,r|1je,0,21a,0,6s,0,r||",
                "New_Zealand": "217,0,47,0,r|217,0,bp,0,r||",
                "Newfoundland": "213,0,47,0,r|213,0,bp,0,r||",
                "Niue": "3b7,0,r|||",
                "Norfolk": "3b6,0,11i,0,r|||",
                "Noronha": "212,0,1fn,0,20t,0,47,0,r|212,0,1fn,0,20t,0,6s,0,r||",
                "North_Mariana": "3b3,0,3b2,0,ob,0,r|||",
                "Novosibirsk": "20s,0,47,0,r|20s,0,6s,0,r||",
                "Omsk": "20r,0,47,0,r|20r,0,6s,0,r||",
                "Pakistan": "20o,0,47,0,r|20o,0,6s,0,r||",
                "Palau": "3au,0,r|||",
                "Papua_New_Guinea": "3at,0,1je,0,3as,0,r|||",
                "Paraguay": "20l,0,47,0,r|20l,0,6s,0,r||",
                "Peru": "20k,0,47,0,r|20k,0,6s,0,r||",
                "Philippines": "20h,0,47,0,r|20h,0,6s,0,r||",
                "Phoenix_Islands": "3ao,0,ob,0,r|||",
                "Pierre_Miquelon": "20g,0,20f,0,31,0,20d,0,47,0,r|20g,0,20f,0,31,0,20d,0,bp,0,r||",
                "Pitcairn": "3ak,0,r|||",
                "Ponape": "3aj,0,r|||",
                "Pyongyang": "3ai,0,r|||",
                "Qyzylorda": "20c,0,p5,0,r|20c,0,6s,0,r||",
                "Reunion": "3ag,0,r|||",
                "Rothera": "3af,0,r|||",
                "Sakhalin": "1tm,0,47,0,r|1tm,0,6s,0,r||",
                "Samara": "1tl,0,p5,0,r|1tl,0,6s,0,r||",
                "Samoa": "1tk,0,47,0,r|1tk,0,bp,0,r||",
                "Seychelles": "38a,0,r|||",
                "Singapore": "389,0,47,0,r||2ad|",
                "Solomon": "388,0,ob,0,r|||",
                "South_Georgia": "387,0,1fo,0,r|||",
                "Suriname": "386,0,r|||",
                "Syowa": "385,0,r|||",
                "Tahiti": "384,0,r|||",
                "Taipei": "1tf,0,47,0,r|1tf,0,bp,0,r||",
                "Tajikistan": "382,0,r|||",
                "Tokelau": "381,0,r|||",
                "Tonga": "1td,0,47,0,r|1td,0,6s,0,r||",
                "Truk": "37v,0,r|||",
                "Turkmenistan": "1t8,0,47,0,r|1t8,0,6s,0,r||",
                "Tuvalu": "37s,0,r|||",
                "Uruguay": "1t7,0,47,0,r|1t7,0,6s,0,r||",
                "Uzbekistan": "1t4,0,47,0,r|1t4,0,6s,0,r||",
                "Vanuatu": "1t3,0,47,0,r|1t3,0,6s,0,r||",
                "Venezuela": "37n,0,r|||",
                "Vladivostok": "1t1,0,47,0,r|1t1,0,6s,0,r||",
                "Volgograd": "1t0,0,47,0,r|1t0,0,6s,0,r||",
                "Vostok": "37i,0,r|||",
                "Wake": "37h,0,11i,0,r|||",
                "Wallis": "37f,0,37e,0,37d,0,r|||",
                "Yakutsk": "1sv,0,47,0,r|1sv,0,6s,0,r||",
                "Yekaterinburg": "1su,0,47,0,r|1su,0,6s,0,r||"
            }
        },
        "ta": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|3ab,0,47,0,r||",
                "Europe/London": "|3a9,0,6s,0,r||"
            },
            "metazone": {
                "Acre": "23q,0,p5,0,r|23q,0,6s,0,r||",
                "Afghanistan": "3eu,0,r|||",
                "Africa_Central": "p1,0,14o,0,r|||",
                "Africa_Eastern": "11p,0,14o,0,r|||",
                "Africa_Southern": "3eq,0,14o,0,47,0,r|||",
                "Africa_Western": "14f,0,14o,0,47,0,r|14f,0,14o,0,6s,0,r||",
                "Alaska": "243,0,47,0,r|243,0,bp,0,r||",
                "Almaty": "245,0,p5,0,r|245,0,6s,0,r||",
                "Amazon": "246,0,47,0,r|246,0,6s,0,r||",
                "America_Central": "p1,0,47,0,r|p1,0,bp,0,r||",
                "America_Eastern": "sq,0,47,0,r|sq,0,bp,0,r||",
                "America_Mountain": "248,0,47,0,r|248,0,bp,0,r||",
                "America_Pacific": "17n,0,47,0,r|17n,0,bp,0,r||",
                "Anadyr": "24a,0,p5,0,r|24a,0,6s,0,r||",
                "Apia": "24c,0,47,0,r|24c,0,bp,0,r||",
                "Aqtau": "24d,0,p5,0,r|24d,0,6s,0,r||",
                "Aqtobe": "24e,0,p5,0,r|24e,0,6s,0,r||",
                "Arabian": "24f,0,47,0,r|24f,0,bp,0,r||",
                "Argentina": "16u,0,47,0,r|16u,0,6s,0,r||",
                "Argentina_Western": "q7,0,16u,0,47,0,r|q7,0,16u,0,6s,0,r||",
                "Armenia": "24i,0,47,0,r|24i,0,6s,0,r||",
                "Atlantic": "24s,0,47,0,r|24s,0,bp,0,r||",
                "Australia_Central": "r9,0,p1,0,47,0,r|r9,0,p1,0,bp,0,r||",
                "Australia_CentralWestern": "r9,0,p1,0,q7,0,47,0,r|r9,0,p1,0,q7,0,bp,0,r||",
                "Australia_Eastern": "r9,0,sq,0,47,0,r|r9,0,sq,0,bp,0,r||",
                "Australia_Western": "r9,0,q7,0,47,0,r|r9,0,q7,0,bp,0,r||",
                "Azerbaijan": "24v,0,47,0,r|24v,0,6s,0,r||",
                "Azores": "3e5,0,47,0,r|3e4,0,6s,0,r||",
                "Bangladesh": "250,0,47,0,r|250,0,6s,0,r||",
                "Bhutan": "3e2,0,r|||",
                "Bolivia": "3e1,0,r|||",
                "Brasilia": "251,0,47,0,r|251,0,6s,0,r||",
                "Brunei": "3dv,0,3du,0,r|||",
                "Cape_Verde": "252,0,253,0,47,0,r|252,0,253,0,6s,0,r||",
                "Chamorro": "3dr,0,47,0,r|||",
                "Chatham": "254,0,47,0,r|254,0,bp,0,r||",
                "Chile": "255,0,47,0,r|255,0,6s,0,r||",
                "China": "259,0,47,0,r|259,0,bp,0,r||",
                "Choibalsan": "25b,0,47,0,r|25b,0,6s,0,r||",
                "Christmas": "3dm,0,11i,0,r|||",
                "Cocos": "3dk,0,ob,0,r|||",
                "Colombia": "25g,0,47,0,r|25g,0,6s,0,r||",
                "Cook": "25h,0,ob,0,47,0,r|25h,0,ob,0,3dg,0,6s,0,r||",
                "Cuba": "25i,0,47,0,r|25i,0,bp,0,r||",
                "Davis": "3de,0,r|||",
                "DumontDUrville": "3dd,5,1fn,0,3db,0,r|||",
                "East_Timor": "11p,0,3da,0,r|||",
                "Easter": "25k,0,11i,0,47,0,r|25k,0,11i,0,6s,0,r||",
                "Ecuador": "3d8,0,r|||",
                "Europe_Central": "p1,0,tp,0,47,0,r|p1,0,tp,0,6s,0,r||",
                "Europe_Eastern": "sq,0,tp,0,47,0,r|sq,0,tp,0,6s,0,r||",
                "Europe_Further_Eastern": "3d6,5,11p,0,tp,0,r|||",
                "Europe_Western": "q7,0,tp,0,47,0,r|q7,0,tp,0,6s,0,r||",
                "Falkland": "25n,0,ob,0,47,0,r|25n,0,ob,0,6s,0,r||",
                "Fiji": "25o,0,47,0,r|25o,0,6s,0,r||",
                "French_Guiana": "3d3,0,25p,0,r|||",
                "French_Southern": "3d1,0,3d0,0,31,0,3cv,0,r|||",
                "Galapagos": "3cu,0,r|||",
                "Gambier": "3ct,0,r|||",
                "Georgia": "1fo,0,47,0,r|1fo,0,6s,0,r||",
                "Gilbert_Islands": "3cr,0,ob,0,r|||",
                "GMT": "3cq,0,3cp,0,r|||",
                "Greenland_Eastern": "11p,0,17h,0,47,0,r|11p,0,17h,0,6s,0,r||",
                "Greenland_Western": "14f,0,17h,0,47,0,r|14f,0,17h,0,6s,0,r||",
                "Guam": "3cn,0,p5,0,r|||",
                "Gulf": "3cm,0,47,0,r|||",
                "Guyana": "25p,0,r|||",
                "Hawaii_Aleutian": "25r,5,25q,0,47,0,r|25r,5,25q,0,bp,0,r||",
                "Hong_Kong": "25l,0,47,0,r|25l,0,6s,0,r||",
                "Hovd": "25j,0,47,0,r|25j,0,6s,0,r||",
                "India": "3ch,0,47,0,r||15a|",
                "Indian_Ocean": "3cg,0,3cf,0,r|||",
                "Indochina": "3ce,0,r|||",
                "Indonesia_Central": "p1,0,1iu,0,r|||",
                "Indonesia_Eastern": "sq,0,1iu,0,r|||",
                "Indonesia_Western": "q7,0,1iu,0,r|||",
                "Iran": "239,0,47,0,r|239,0,bp,0,r||",
                "Irkutsk": "238,0,47,0,r|238,0,6s,0,r||",
                "Israel": "237,0,47,0,r|237,0,bp,0,r||",
                "Japan": "236,0,47,0,r|236,0,bp,0,r||",
                "Kamchatka": "235,0,22v,0,p5,0,r|235,0,22v,0,6s,0,r||",
                "Kazakhstan_Eastern": "11p,0,22u,0,r|||",
                "Kazakhstan_Western": "14f,0,22u,0,r|||",
                "Korea": "22n,0,47,0,r|22n,0,bp,0,r||",
                "Kosrae": "3c4,0,r|||",
                "Krasnoyarsk": "22j,0,47,0,r|22j,0,6s,0,r||",
                "Kyrgystan": "3c2,0,r|||",
                "Lanka": "3c1,0,r|||",
                "Line_Islands": "3c0,0,ob,0,r|||",
                "Lord_Howe": "22g,0,22f,0,47,0,r|22g,0,22f,0,bp,0,r||",
                "Macau": "22e,0,p5,0,r|22e,0,6s,0,r||",
                "Macquarie": "3bs,0,11i,0,r|||",
                "Magadan": "22c,0,47,0,r|22c,0,6s,0,r||",
                "Malaysia": "3bq,0,r|||",
                "Maldives": "3bp,0,r|||",
                "Marquesas": "3bo,0,r|||",
                "Marshall_Islands": "3bn,0,ob,0,r|||",
                "Mauritius": "22a,0,47,0,r|22a,0,6s,0,r||",
                "Mawson": "3bl,0,r|||",
                "Mexico_Northwest": "227,0,223,0,47,0,r|227,0,223,0,bp,0,r||",
                "Mexico_Pacific": "222,0,17n,0,47,0,r|222,0,17n,0,bp,0,r||",
                "Mongolia": "221,0,21o,0,47,0,r|221,0,21o,0,6s,0,r||",
                "Moscow": "21e,0,47,0,r|21e,0,6s,0,r||",
                "Myanmar": "3be,0,r|||",
                "Nauru": "3bd,0,r|||",
                "Nepal": "3bc,0,r|||",
                "New_Caledonia": "1je,0,21a,0,47,0,r|1je,0,21a,0,6s,0,r||",
                "New_Zealand": "217,0,47,0,r|217,0,bp,0,r||",
                "Newfoundland": "213,0,47,0,r|213,0,bp,0,r||",
                "Niue": "3b7,0,r|||",
                "Norfolk": "3b6,0,11i,0,r|||",
                "Noronha": "212,0,1fn,0,20t,0,47,0,r|212,0,1fn,0,20t,0,6s,0,r||",
                "North_Mariana": "3b3,0,3b2,0,ob,0,r|||",
                "Novosibirsk": "20s,0,47,0,r|20s,0,6s,0,r||",
                "Omsk": "20r,0,47,0,r|20r,0,6s,0,r||",
                "Pakistan": "20o,0,47,0,r|20o,0,6s,0,r||",
                "Palau": "3au,0,r|||",
                "Papua_New_Guinea": "3at,0,1je,0,3as,0,r|||",
                "Paraguay": "20l,0,47,0,r|20l,0,6s,0,r||",
                "Peru": "20k,0,47,0,r|20k,0,6s,0,r||",
                "Philippines": "20h,0,47,0,r|20h,0,6s,0,r||",
                "Phoenix_Islands": "3ao,0,ob,0,r|||",
                "Pierre_Miquelon": "20g,0,20f,0,31,0,20d,0,47,0,r|20g,0,20f,0,31,0,20d,0,bp,0,r||",
                "Pitcairn": "3ak,0,r|||",
                "Ponape": "3aj,0,r|||",
                "Pyongyang": "3ai,0,r|||",
                "Qyzylorda": "20c,0,p5,0,r|20c,0,6s,0,r||",
                "Reunion": "3ag,0,r|||",
                "Rothera": "3af,0,r|||",
                "Sakhalin": "1tm,0,47,0,r|1tm,0,6s,0,r||",
                "Samara": "1tl,0,p5,0,r|1tl,0,6s,0,r||",
                "Samoa": "1tk,0,47,0,r|1tk,0,bp,0,r||",
                "Seychelles": "38a,0,r|||",
                "Singapore": "389,0,47,0,r|||",
                "Solomon": "388,0,ob,0,r|||",
                "South_Georgia": "387,0,1fo,0,r|||",
                "Suriname": "386,0,r|||",
                "Syowa": "385,0,r|||",
                "Tahiti": "384,0,r|||",
                "Taipei": "1tf,0,47,0,r|1tf,0,bp,0,r||",
                "Tajikistan": "382,0,r|||",
                "Tokelau": "381,0,r|||",
                "Tonga": "1td,0,47,0,r|1td,0,6s,0,r||",
                "Truk": "37v,0,r|||",
                "Turkmenistan": "1t8,0,47,0,r|1t8,0,6s,0,r||",
                "Tuvalu": "37s,0,r|||",
                "Uruguay": "1t7,0,47,0,r|1t7,0,6s,0,r||",
                "Uzbekistan": "1t4,0,47,0,r|1t4,0,6s,0,r||",
                "Vanuatu": "1t3,0,47,0,r|1t3,0,6s,0,r||",
                "Venezuela": "37n,0,r|||",
                "Vladivostok": "1t1,0,47,0,r|1t1,0,6s,0,r||",
                "Volgograd": "1t0,0,47,0,r|1t0,0,6s,0,r||",
                "Vostok": "37i,0,r|||",
                "Wake": "37h,0,11i,0,r|||",
                "Wallis": "37f,0,37e,0,37d,0,r|||",
                "Yakutsk": "1sv,0,47,0,r|1sv,0,6s,0,r||",
                "Yekaterinburg": "1su,0,47,0,r|1su,0,6s,0,r||"
            }
        },
        "te": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cdl,0,do,0,68||",
                "Europe/London": "|cdm,0,ia,0,68||"
            },
            "metazone": {
                "Acre": "5m8,0,do,0,68|5m8,0,ia,0,68||",
                "Afghanistan": "ab7,0,68|||",
                "Africa_Central": "376,0,2t2,0,68|||",
                "Africa_Eastern": "1er,0,2t2,0,68|||",
                "Africa_Southern": "4ra,0,2t2,0,do,0,68|||",
                "Africa_Western": "1br,0,2t2,0,do,0,68|1br,0,2t2,0,ia,0,68||",
                "Alaska": "5ll,0,do,0,68|5ll,0,vr,0,68||",
                "Almaty": "5lf,0,do,0,68|5lf,0,ia,0,68||",
                "Amazon": "5ld,0,do,0,68|5ld,0,ia,0,68||",
                "America_Central": "2hf,0,do,0,68|2hf,0,vr,0,68||",
                "America_Eastern": "1er,0,do,0,68|1er,0,vr,0,68||",
                "America_Mountain": "5ki,0,do,0,68|5ki,0,vr,0,68||",
                "America_Pacific": "36e,0,do,0,68|36e,0,vr,0,68||",
                "Anadyr": "5ke,0,do,0,68|5ke,0,ia,0,68||",
                "Apia": "5k9,0,do,0,68|5k9,0,vr,0,68||",
                "Aqtau": "5k7,0,do,0,68|5k7,0,ia,0,68||",
                "Aqtobe": "5k5,0,do,0,68|5k5,0,ia,0,68||",
                "Arabian": "5k4,0,do,0,68|5k4,0,vr,0,1pl,0,68||",
                "Argentina": "4rd,0,do,0,68|abr,0,ia,0,68||",
                "Argentina_Western": "1br,0,4rd,0,do,0,68|1br,0,4rd,0,ia,0,68||",
                "Armenia": "5k1,0,do,0,68|5k1,0,ia,0,68||",
                "Atlantic": "5ju,0,do,0,68|5ju,0,vr,0,68||",
                "Australia_Central": "2ua,0,2hf,0,do,0,68|2ua,0,2hf,0,vr,0,68||",
                "Australia_CentralWestern": "2hf,0,2ua,0,1br,0,do,0,68|2hf,0,4re,0,1br,0,vr,0,68||",
                "Australia_Eastern": "1er,0,2ua,0,do,0,68|1er,0,2ua,0,vr,0,68||",
                "Australia_Western": "1br,0,4re,0,do,0,68|1br,0,4re,0,vr,0,68||",
                "Azerbaijan": "5j4,0,do,0,68|5j4,0,ia,0,68||",
                "Azores": "5j3,0,do,0,68|5j3,0,ia,0,68||",
                "Bangladesh": "5j2,0,do,0,68|5j2,0,ia,0,68||",
                "Bhutan": "ac3,0,68|||",
                "Bolivia": "ac4,0,68|||",
                "Brasilia": "5j1,0,do,0,68|5j1,0,ia,0,68||",
                "Brunei": "ac6,0,ac7,0,68|||",
                "Cape_Verde": "5iu,0,5iq,0,do,0,68|5iu,0,5iq,0,ia,0,68||",
                "Chamorro": "aca,0,do,0,68|||",
                "Chatham": "5io,0,do,0,68|5io,0,vr,0,1pl,0,68||",
                "Chile": "5im,0,do,0,68|5im,0,ia,0,68||",
                "China": "5il,0,do,0,68|5il,0,vr,0,1pl,0,68||",
                "Choibalsan": "5ij,0,do,0,68|5ij,0,ia,0,68||",
                "Christmas": "acf,0,2j5,0,68|||",
                "Cocos": "ach,0,1kc,0,68|||",
                "Colombia": "5ig,0,do,0,68|5ig,0,ia,0,68||",
                "Cook": "5if,0,1kc,0,do,0,68|5if,0,1kc,0,acl,0,ia,0,68||",
                "Cuba": "5ie,0,do,0,68|5ie,0,vr,0,68||",
                "Davis": "acn,0,68|||",
                "DumontDUrville": "aco,5,acp,0,68|||",
                "East_Timor": "1er,0,acq,0,68|||",
                "Easter": "5id,0,2j5,0,do,0,68|5id,0,2j5,0,ia,0,68||",
                "Ecuador": "acs,0,68|||",
                "Europe_Central": "376,0,2a4,0,do,0,68|376,0,2a4,0,ia,0,68||",
                "Europe_Eastern": "1er,0,2a4,0,do,0,68|1er,0,2a4,0,ia,0,68||",
                "Europe_Further_Eastern": "acu,5,1er,0,2a4,0,68|||",
                "Europe_Western": "1br,0,2a4,0,do,0,68|1br,0,2a4,0,ia,0,68||",
                "Falkland": "5i9,0,1kc,0,do,0,68|5i9,0,1kc,0,ia,0,68||",
                "Fiji": "5i8,0,do,0,68|5i8,0,ia,0,68||",
                "French_Guiana": "5i7,0,5i6,0,68|||",
                "French_Southern": "5i7,0,4ra,0,35h,0,ad4,0,68|||",
                "Galapagos": "ad5,0,68|||",
                "Gambier": "ad6,0,68|||",
                "Georgia": "4rm,0,do,0,68|4rm,0,ia,0,68||",
                "Gilbert_Islands": "ad8,0,1kc,0,68|||",
                "GMT": "ad9,0,ada,0,68|||",
                "Greenland_Eastern": "1er,0,35f,0,do,0,68|1er,0,35f,0,ia,0,68||",
                "Greenland_Western": "1br,0,35f,0,do,0,68|1br,0,35f,0,ia,0,68||",
                "Guam": "adc,0,do,0,68|||",
                "Gulf": "add,0,do,0,68|||",
                "Guyana": "5i6,0,68|||",
                "Hawaii_Aleutian": "5hs,5,5hl,0,do,0,68|5hs,5,5hl,0,vr,0,1pl,0,68||",
                "Hong_Kong": "5hk,0,do,0,68|5hk,0,ia,0,68||",
                "Hovd": "5hf,0,do,0,68|5hf,0,ia,0,68||",
                "India": "adi,0,68||15a|",
                "Indian_Ocean": "adj,0,adk,0,adl,0,68|||",
                "Indochina": "adm,0,68|||",
                "Indonesia_Central": "376,0,4rn,0,68|||",
                "Indonesia_Eastern": "1er,0,4rn,0,68|||",
                "Indonesia_Western": "1br,0,4rn,0,68|||",
                "Iran": "5hd,0,do,0,68|5hd,0,vr,0,1pl,0,68||",
                "Irkutsk": "5h6,0,do,0,68|5h6,0,ia,0,68||",
                "Israel": "5h4,0,do,0,68|5h4,0,vr,0,1pl,0,68||",
                "Japan": "5h3,0,do,0,68|5h3,0,vr,0,1pl,0,68||",
                "Kamchatka": "5h2,5,5h1,0,do,0,68|5h2,5,5h1,0,ia,0,68||",
                "Kazakhstan_Eastern": "1er,0,adu,0,68|||",
                "Kazakhstan_Western": "1br,0,adv,0,68|||",
                "Korea": "5gv,0,do,0,68|5gv,0,vr,0,1pl,0,68||",
                "Kosrae": "ae1,0,68|||",
                "Krasnoyarsk": "5gs,0,do,0,68|5gs,0,ia,0,68||",
                "Kyrgystan": "ae3,0,68|||",
                "Lanka": "ae4,0,68|||",
                "Line_Islands": "ae5,0,1kc,0,68|||",
                "Lord_Howe": "5gr,0,5gp,0,do,0,68|5gr,0,5gp,0,vr,0,68||",
                "Macau": "5gh,0,do,0,68|5gh,0,ia,0,68||",
                "Macquarie": "ae9,0,2j5,0,68|||",
                "Magadan": "5gg,0,do,0,68|5gg,0,ia,0,68||",
                "Malaysia": "aeb,0,68|||",
                "Maldives": "aec,0,68|||",
                "Marquesas": "aed,0,68|||",
                "Marshall_Islands": "aee,0,1kc,0,68|||",
                "Mauritius": "5gf,0,do,0,68|5gf,0,ia,0,68||",
                "Mawson": "aeg,0,68|||",
                "Mexico_Northwest": "5ge,0,5g9,0,do,0,68|5ge,0,5g9,0,vr,0,68||",
                "Mexico_Pacific": "5g8,0,36e,0,do,0,68|5g8,0,36e,0,vr,0,68||",
                "Mongolia": "5g7,0,5g5,0,do,0,68|5g7,0,5g5,0,ia,0,68||",
                "Moscow": "5g4,0,do,0,68|5g4,0,ia,0,68||",
                "Myanmar": "aen,0,68|||",
                "Nauru": "aeo,0,68|||",
                "Nepal": "aep,0,68|||",
                "New_Caledonia": "4rp,0,5g1,0,do,0,68|4rp,0,5g1,0,ia,0,68||",
                "New_Zealand": "5g0,0,do,0,68|5g0,0,vr,0,1pl,0,68||",
                "Newfoundland": "5fu,0,5ft,0,do,0,68|5fu,0,5ft,0,vr,0,68||",
                "Niue": "aev,0,68|||",
                "Norfolk": "af0,0,2j5,0,68|||",
                "Noronha": "5fp,0,5fn,0,5fl,0,do,0,68|5fp,0,5fn,0,5fl,0,ia,0,68||",
                "North_Mariana": "af4,0,af5,0,1kc,0,68|||",
                "Novosibirsk": "af6,0,do,0,68|af7,0,ia,0,68||",
                "Omsk": "5fk,0,do,0,68|5fk,0,ia,0,68||",
                "Pakistan": "af9,0,do,0,68|afa,0,ia,0,68||",
                "Palau": "afb,0,68|||",
                "Papua_New_Guinea": "afc,0,4rp,0,afd,0,68|||",
                "Paraguay": "5fj,0,do,0,68|5fj,0,ia,0,68||",
                "Peru": "5fi,0,do,0,68|5fi,0,ia,0,68||",
                "Philippines": "5fg,0,do,0,68|5fg,0,ia,0,68||",
                "Phoenix_Islands": "afh,0,1kc,0,68|||",
                "Pierre_Miquelon": "5fd,0,afj,0,35h,0,5fc,0,do,0,68|5fd,0,afl,0,35h,0,5fc,0,vr,0,68||",
                "Pitcairn": "afm,0,68|||",
                "Ponape": "afn,0,68|||",
                "Pyongyang": "afo,0,68|||",
                "Qyzylorda": "5fb,0,do,0,68|5fb,0,ia,0,68||",
                "Reunion": "afq,0,68|||",
                "Rothera": "afr,0,68|||",
                "Sakhalin": "5f5,0,do,0,68|5f5,0,ia,0,68||",
                "Samara": "5f2,0,do,0,68|5f2,0,ia,0,68||",
                "Samoa": "5f0,0,do,0,68|5f0,0,ia,0,68||",
                "Seychelles": "afv,0,68|||",
                "Singapore": "ag0,0,do,0,68|||",
                "Solomon": "ag1,0,1kc,0,68|||",
                "South_Georgia": "4ra,0,4rm,0,68|||",
                "Suriname": "ag2,0,68|||",
                "Syowa": "ag3,0,68|||",
                "Tahiti": "ag4,0,68|||",
                "Taipei": "5ev,0,do,0,68|5ev,0,vr,0,ag6,0,68||",
                "Tajikistan": "ag7,0,68|||",
                "Tokelau": "ag8,0,68|||",
                "Tonga": "5e3,0,do,0,68|5e3,0,ia,0,68||",
                "Truk": "aga,0,68|||",
                "Turkmenistan": "5e2,0,do,0,68|5e2,0,ia,0,68||",
                "Tuvalu": "agc,0,68|||",
                "Uruguay": "5e1,0,do,0,68|5e1,0,ia,0,68||",
                "Uzbekistan": "5e0,0,do,0,68|5e0,0,ia,0,68||",
                "Vanuatu": "5dv,0,do,0,68|5dv,0,ia,0,68||",
                "Venezuela": "agg,0,68|||",
                "Vladivostok": "5du,0,do,0,68|5du,0,ia,0,68||",
                "Volgograd": "5dt,0,do,0,68|5dt,0,ia,0,68||",
                "Vostok": "agj,0,68|||",
                "Wake": "agk,0,2j5,0,68|||",
                "Wallis": "agl,0,35h,0,agm,0,68|||",
                "Yakutsk": "5dq,0,do,0,68|5dq,0,ia,0,68||",
                "Yekaterinburg": "5dm,0,do,0,68|5dm,0,ia,0,68||"
            }
        },
        "teo-KE": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "teo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "th": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cdn||",
                "Europe/London": "|cdo||"
            },
            "metazone": {
                "Acre": "agp|agq||",
                "Afghanistan": "agr|||",
                "Africa_Central": "ags|||",
                "Africa_Eastern": "agt|||",
                "Africa_Southern": "agu|||",
                "Africa_Western": "agv|ah0||",
                "Alaska": "ah1|ah2||",
                "Almaty": "ah3|ah4||",
                "Amazon": "ah5|ah6||",
                "America_Central": "ah7|ah8||",
                "America_Eastern": "ah9|aha||",
                "America_Mountain": "ahb|ahc||",
                "America_Pacific": "ahd|ahe||",
                "Anadyr": "ahf|ahg||",
                "Apia": "ahh|ahi||",
                "Aqtau": "ahj|ahk||",
                "Aqtobe": "ahl|ahm||",
                "Arabian": "ahn|aho||",
                "Argentina": "ahp|ahq||",
                "Argentina_Western": "ahr|ahs||",
                "Armenia": "aht|ahu||",
                "Atlantic": "ahv|ai0||",
                "Australia_Central": "ai1|ai2||",
                "Australia_CentralWestern": "ai3|ai4||",
                "Australia_Eastern": "ai5|ai6||",
                "Australia_Western": "ai7|ai8||",
                "Azerbaijan": "ai9|aia||",
                "Azores": "aib|aic||",
                "Bangladesh": "aid|aie||",
                "Bhutan": "aif|||",
                "Bolivia": "aig|||",
                "Brasilia": "aih|aii||",
                "Brunei": "aij|||",
                "Cape_Verde": "aik|ail||",
                "Casey": "aim|||",
                "Chamorro": "ain|||",
                "Chatham": "aio|aip||",
                "Chile": "aiq|air||",
                "China": "ais|ait||",
                "Choibalsan": "aiu|aiv||",
                "Christmas": "aj0|||",
                "Cocos": "aj1|||",
                "Colombia": "aj2|aj3||",
                "Cook": "aj4|aj5||",
                "Cuba": "aj6|aj7||",
                "Davis": "aj8|||",
                "DumontDUrville": "aj9|||",
                "East_Timor": "aja|||",
                "Easter": "ajb|ajc||",
                "Ecuador": "ajd|||",
                "Europe_Central": "aje|ajf||",
                "Europe_Eastern": "ajg|ajh||",
                "Europe_Further_Eastern": "aji|||",
                "Europe_Western": "ajj|ajk||",
                "Falkland": "ajl|ajm||",
                "Fiji": "ajn|ajo||",
                "French_Guiana": "ajp|||",
                "French_Southern": "ajq|||",
                "Galapagos": "ajr|||",
                "Gambier": "ajs|||",
                "Georgia": "ajt|aju||",
                "Gilbert_Islands": "ajv|||",
                "GMT": "ak0|||",
                "Greenland_Eastern": "ak1|ak2||",
                "Greenland_Western": "ak3|ak4||",
                "Guam": "ak5|||",
                "Gulf": "ak6|||",
                "Guyana": "ak7|||",
                "Hawaii_Aleutian": "ak8,5,5dk|aka,5,5dk||",
                "Hong_Kong": "akb|akc||",
                "Hovd": "akd|ake||",
                "India": "akf|||",
                "Indian_Ocean": "akg|||",
                "Indochina": "akh|||",
                "Indonesia_Central": "aki|||",
                "Indonesia_Eastern": "akj|||",
                "Indonesia_Western": "akk|||",
                "Iran": "akl|akm||",
                "Irkutsk": "akn|ako||",
                "Israel": "akp|akq||",
                "Japan": "akr|aks||",
                "Kamchatka": "akt,5,5di|akv,5,5di||",
                "Kazakhstan_Eastern": "al0|||",
                "Kazakhstan_Western": "al1|||",
                "Korea": "al2|al3||",
                "Kosrae": "al4|||",
                "Krasnoyarsk": "al5|al6||",
                "Kyrgystan": "al7|||",
                "Lanka": "al8|||",
                "Line_Islands": "al9|||",
                "Lord_Howe": "ala|alb||",
                "Macau": "alc|ald||",
                "Macquarie": "ale|||",
                "Magadan": "alf|alg||",
                "Malaysia": "alh|||",
                "Maldives": "ali|||",
                "Marquesas": "alj|||",
                "Marshall_Islands": "alk|||",
                "Mauritius": "all|alm||",
                "Mawson": "aln|||",
                "Mexico_Northwest": "alo|alp||",
                "Mexico_Pacific": "alq|alr||",
                "Mongolia": "als|alt||",
                "Moscow": "alu|alv||",
                "Myanmar": "am0|||",
                "Nauru": "am1|||",
                "Nepal": "am2|||",
                "New_Caledonia": "am3|am4||",
                "New_Zealand": "am5|am6||",
                "Newfoundland": "am7|am8||",
                "Niue": "am9|||",
                "Norfolk": "ama|||",
                "Noronha": "amb|amc||",
                "North_Mariana": "amd|||",
                "Novosibirsk": "ame|amf||",
                "Omsk": "amg|amh||",
                "Pakistan": "ami|amj||",
                "Palau": "amk|||",
                "Papua_New_Guinea": "aml|||",
                "Paraguay": "amm|amn||",
                "Peru": "amo|amp||",
                "Philippines": "amq|amr||",
                "Phoenix_Islands": "ams|||",
                "Pierre_Miquelon": "amt|amu||",
                "Pitcairn": "amv|||",
                "Ponape": "an0|||",
                "Pyongyang": "an1|||",
                "Qyzylorda": "an2|an3||",
                "Reunion": "an4|||",
                "Rothera": "an5|||",
                "Sakhalin": "an6|an7||",
                "Samara": "an8|an9||",
                "Samoa": "ana|anb||",
                "Seychelles": "anc|||",
                "Singapore": "and|||",
                "Solomon": "ane|||",
                "South_Georgia": "anf|||",
                "Suriname": "ang|||",
                "Syowa": "anh|||",
                "Tahiti": "ani|||",
                "Taipei": "anj|ank||",
                "Tajikistan": "anl|||",
                "Tokelau": "anm|||",
                "Tonga": "ann|ano||",
                "Truk": "anp|||",
                "Turkmenistan": "anq|anr||",
                "Tuvalu": "ans|||",
                "Uruguay": "ant|anu||",
                "Uzbekistan": "anv|ao0||",
                "Vanuatu": "ao1|ao2||",
                "Venezuela": "ao3|||",
                "Vladivostok": "ao4|ao5||",
                "Volgograd": "ao6|ao7||",
                "Vostok": "ao8|||",
                "Wake": "ao9|||",
                "Wallis": "aoa|||",
                "Yakutsk": "aob|aoc||",
                "Yekaterinburg": "aod|aoe||"
            }
        },
        "ti-ER": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ti": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "tk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|cdp,k,0,6i,0,9t||",
                "Europe/London": "|cdq,0,cdr,k,0,gb,0,9t||"
            },
            "metazone": {
                "Afghanistan": "aof|||",
                "Africa_Central": "1c2,0,c5|||",
                "Africa_Eastern": "1kf,0,c5|||",
                "Africa_Southern": "2vv,0,c5,k,0,6i,0,9t|||",
                "Africa_Western": "1h5,0,c5,k,0,6i,0,9t|1h5,0,c5,k,0,gb,0,9t||",
                "Alaska": "5da,k,0,6i,0,9t|5da,k,0,gb,0,9t||",
                "Amazon": "2k3,k,0,6i,0,9t|2k3,k,0,gb,0,9t||",
                "America_Central": "1c2,0,m2,k,0,6i,0,9t|1c2,0,m2,k,0,gb,0,9t||",
                "America_Eastern": "2vv,0,m2,k,0,6i,0,9t|2vv,0,m2,k,0,gb,0,9t||",
                "America_Mountain": "5d5,0,5d4,k,0,6i,0,9t,0,5d2|5d5,0,5d4,k,0,gb,0,9t,0,5d2||",
                "America_Pacific": "34j,0,34i,k,0,6i,0,9t|34j,0,34i,k,0,gb,0,9t||",
                "Apia": "3e,k,0,6i,0,9t|3e,k,0,gb,0,9t||",
                "Arabian": "5cv,0,5cr,k,0,6i,0,9t|5cv,0,5cr,k,0,gb,0,9t||",
                "Argentina": "1e,k,0,6i,0,9t|1e,k,0,gb,0,9t||",
                "Argentina_Western": "1h5,0,1e,k,0,6i,0,9t|1h5,0,1e,k,0,gb,0,9t||",
                "Armenia": "2k6,k,0,6i,0,9t|2k6,k,0,gb,0,9t||",
                "Atlantic": "2k7,k,0,6i,0,9t|2k7,k,0,gb,0,9t||",
                "Australia_Central": "1c2,0,1rb,k,0,6i,0,9t|1c2,0,1rb,k,0,gb,0,9t||",
                "Australia_CentralWestern": "1c2,0,1rb,k,0,5ck,0,5cj,k,0,6i,0,9t|1c2,0,1rb,k,0,5ck,0,5cj,k,0,gb,0,9t||",
                "Australia_Eastern": "1kf,0,1rb,k,0,6i,0,9t|1kf,0,1rb,k,0,gb,0,9t||",
                "Australia_Western": "1h5,0,1rb,k,0,6i,0,9t|1h5,0,1rb,k,0,gb,0,9t||",
                "Azerbaijan": "5ci,k,0,6i,0,9t|5ci,k,0,gb,0,9t||",
                "Azores": "1lg,0,19b,k,0,6i,0,9t|1lg,0,19b,k,0,gb,0,9t||",
                "Bangladesh": "2kb,k,0,6i,0,9t|2kb,k,0,gb,0,9t||",
                "Bhutan": "1k4|||",
                "Bolivia": "ap7|||",
                "Brasilia": "5cb,k,0,6i,0,9t|5cb,k,0,gb,0,9t||",
                "Brunei": "ap9,5,a4|||",
                "Cape_Verde": "2kc,5,5c4,k,0,6i,0,9t|2kc,5,5c4,k,0,gb,0,9t||",
                "Chamorro": "4mm|||",
                "Chatham": "5c3,k,0,6i,0,9t|5c3,k,0,gb,0,9t||",
                "Chile": "2i3,k,0,6i,0,9t|2i3,k,0,gb,0,9t||",
                "China": "5c2,k,0,6i,0,9t|5c2,k,0,gb,0,9t||",
                "Choibalsan": "5c1,k,0,6i,0,9t|5c1,k,0,gb,0,9t||",
                "Christmas": "apf,0,349|||",
                "Cocos": "1eg,0,19b|||",
                "Colombia": "5bv,k,0,6i,0,9t|5bv,k,0,gb,0,9t||",
                "Cook": "4dg,0,19b,k,0,6i,0,9t|4dg,0,19b,k,0,gb,0,9t||",
                "Cuba": "tb,k,0,6i,0,9t|tb,k,0,gb,0,9t||",
                "Davis": "api|||",
                "DumontDUrville": "apj,5,apk,5,apl|||",
                "East_Timor": "1kf,0,7n|||",
                "Easter": "5bu,0,349,k,0,6i,0,9t|5bu,0,349,k,0,gb,0,9t||",
                "Ecuador": "7lf|||",
                "Europe_Central": "1c2,0,2ac,k,0,6i,0,9t|1c2,0,2ac,k,0,gb,0,9t||",
                "Europe_Eastern": "1kf,0,2ac,k,0,6i,0,9t|1kf,0,2ac,k,0,gb,0,9t||",
                "Europe_Further_Eastern": "apo,0,1kf,0,2ac|||",
                "Europe_Western": "1h5,0,2ac,k,0,6i,0,9t|1h5,0,2ac,k,0,gb,0,9t||",
                "Falkland": "1ov,0,19b,k,0,6i,0,9t|1ov,0,19b,k,0,gb,0,9t||",
                "Fiji": "4q,k,0,6i,0,9t|4q,k,0,gb,0,9t||",
                "French_Guiana": "2ke,0,8h5|||",
                "French_Southern": "2vv,0,2ke,0,346,0,27v|||",
                "Galapagos": "ak,0,19b|||",
                "Gambier": "apr|||",
                "Georgia": "5bo,k,0,6i,0,9t|5bo,k,0,gb,0,9t||",
                "Gilbert_Islands": "4i0,0,19b|||",
                "GMT": "apt,0,apu,0,apv,0,9t|||",
                "Greenland_Eastern": "1kf,0,344,k,0,6i,0,9t|1kf,0,344,k,0,gb,0,9t||",
                "Greenland_Western": "1h5,0,344,k,0,6i,0,9t|1h5,0,344,k,0,gb,0,9t||",
                "Gulf": "aq1,0,aq2,k,0,6i,0,9t|||",
                "Guyana": "aq3|||",
                "Hawaii_Aleutian": "5b8,5,1l3,k,0,6i,0,9t|5b8,5,1l3,k,0,gb,0,9t||",
                "Hong_Kong": "2kh,k,0,6i,0,9t|2kh,k,0,gb,0,9t||",
                "Hovd": "5b5,k,0,6i,0,9t|5b5,k,0,gb,0,9t||",
                "India": "30i|||",
                "Indian_Ocean": "1to,0,aq7|||",
                "Indochina": "aq8|||",
                "Indonesia_Central": "1c2,0,4rt|||",
                "Indonesia_Eastern": "1kf,0,4rt|||",
                "Indonesia_Western": "1h5,0,4rt|||",
                "Iran": "5b3,k,0,6i,0,9t|5b3,k,0,gb,0,9t||",
                "Irkutsk": "3h,k,0,6i,0,9t|3h,k,0,gb,0,9t||",
                "Israel": "5b1,k,0,6i,0,9t|5b1,k,0,gb,0,9t||",
                "Japan": "5b0,k,0,6i,0,9t|5b0,k,0,gb,0,9t||",
                "Kazakhstan_Eastern": "1kf,0,5av|||",
                "Kazakhstan_Western": "1h5,0,5av|||",
                "Korea": "5as,k,0,6i,0,9t|5as,k,0,gb,0,9t||",
                "Kosrae": "aqf|||",
                "Krasnoyarsk": "5ao,k,0,6i,0,9t|5ao,k,0,gb,0,9t||",
                "Kyrgystan": "aqh|||",
                "Line_Islands": "aqi,0,19b|||",
                "Lord_Howe": "1u,5,1i2,k,0,6i,0,9t|1u,5,1i2,k,0,gb,0,9t||",
                "Macquarie": "4ru|||",
                "Magadan": "2o,k,0,6i,0,9t|2o,k,0,gb,0,9t||",
                "Malaysia": "aqk|||",
                "Maldives": "aql|||",
                "Marquesas": "1jm,0,19b|||",
                "Marshall_Islands": "4m6,0,19b|||",
                "Mauritius": "5ak,k,0,6i,0,9t|5ak,k,0,gb,0,9t||",
                "Mawson": "2v0|||",
                "Mexico_Northwest": "5aj,5,5ai,0,10g,k,0,6i,0,9t|5aj,5,5ai,0,10g,k,0,gb,0,9t||",
                "Mexico_Pacific": "5ag,0,34j,0,34i,k,0,6i,0,9t|5ag,0,34j,0,34i,k,0,gb,0,9t||",
                "Mongolia": "4f,5,2m,k,0,6i,0,9t|4f,5,2m,k,0,gb,0,9t||",
                "Moscow": "36c,k,0,6i,0,9t|36c,k,0,gb,0,9t||",
                "Myanmar": "aqq|||",
                "Nauru": "5v|||",
                "Nepal": "8u|||",
                "New_Caledonia": "30l,0,5ab,k,0,6i,0,9t|30l,0,5ab,k,0,gb,0,9t||",
                "New_Zealand": "30l,0,5a6,k,0,6i,0,9t|30l,0,5a6,k,0,gb,0,9t||",
                "Newfoundland": "5a1,k,0,6i,0,9t|5a1,k,0,gb,0,9t||",
                "Niue": "8g|||",
                "Norfolk": "6l|||",
                "Noronha": "23a,5,4i,5,5a0,k,0,6i,0,9t|23a,5,4i,5,5a0,k,0,gb,0,9t||",
                "Novosibirsk": "16c,k,0,6i,0,9t|ar0,k,0,gb,0,9t||",
                "Omsk": "26,k,0,6i,0,9t|26,k,0,gb,0,9t||",
                "Pakistan": "59p,k,0,6i,0,9t|59p,k,0,gb,0,9t||",
                "Palau": "9b|||",
                "Papua_New_Guinea": "9a,0,5,0,30l,0,ar2|||",
                "Paraguay": "598,k,0,6i,0,9t|598,k,0,gb,0,9t||",
                "Peru": "4n,k,0,6i,0,9t|4n,k,0,gb,0,9t||",
                "Philippines": "593,k,0,6i,0,9t|593,k,0,gb,0,9t||",
                "Phoenix_Islands": "1a7,0,19b|||",
                "Pierre_Miquelon": "14i,0,58v,0,346,0,u2,k,0,6i,0,9t|14i,0,58v,0,346,0,u2,k,0,gb,0,9t||",
                "Pitcairn": "1ko|||",
                "Ponape": "9s|||",
                "Reunion": "ar6|||",
                "Rothera": "1po|||",
                "Sakhalin": "164,k,0,6i,0,9t|164,k,0,gb,0,9t||",
                "Samoa": "2c,k,0,6i,0,9t|2c,k,0,gb,0,9t||",
                "Seychelles": "ar7,0,19b|||",
                "Singapore": "m8,k,0,6i,0,9t|||",
                "Solomon": "b5,0,19b|||",
                "South_Georgia": "2vv,0,ar8|||",
                "Suriname": "mg|||",
                "Syowa": "ar9|||",
                "Tahiti": "ara|||",
                "Taipei": "58u,k,0,6i,0,9t|58u,k,0,gb,0,9t||",
                "Tajikistan": "arc|||",
                "Tokelau": "5d|||",
                "Tonga": "2j,k,0,6i,0,9t|2j,k,0,gb,0,9t||",
                "Truk": "4lr|||",
                "Turkmenistan": "2l2,k,0,6i,0,9t|2l2,k,0,gb,0,9t||",
                "Tuvalu": "are|||",
                "Uruguay": "58o,k,0,6i,0,9t|58o,k,0,gb,0,9t||",
                "Uzbekistan": "2l3,k,0,6i,0,9t|2l3,k,0,gb,0,9t||",
                "Vanuatu": "58l,k,0,6i,0,9t|58l,k,0,gb,0,9t||",
                "Venezuela": "ari|||",
                "Vladivostok": "15m,k,0,6i,0,9t|15m,k,0,gb,0,9t||",
                "Volgograd": "qi,k,0,6i,0,9t|qi,k,0,gb,0,9t||",
                "Vostok": "1kt|||",
                "Wake": "arj,0,349|||",
                "Wallis": "ark,0,346,0,5k|||",
                "Yakutsk": "58j,k,0,6i,0,9t|58j,k,0,gb,0,9t||",
                "Yekaterinburg": "58i,k,0,6i,0,9t|58i,k,0,gb,0,9t||"
            }
        },
        "to": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|67,0,cds,0,96,0,eg||",
                "Europe/London": "|67,0,cdt,0,96,0,ek||"
            },
            "metazone": {
                "Acre": "67,0,58g,0,96,0,eg|67,0,58g,0,96,0,ek||",
                "Afghanistan": "67,0,ars|||",
                "Africa_Central": "67,0,31e,5,1p3|||",
                "Africa_Eastern": "67,0,31e,5,1es|||",
                "Africa_Southern": "67,0,31e,5,as0|||",
                "Africa_Western": "67,0,31e,5,1a3,0,96,0,eg|67,0,31e,5,1a3,0,96,0,ek||",
                "Alaska": "67,0,584,0,96,0,eg|67,0,584,0,96,0,ek||",
                "Almaty": "67,0,582,0,96,0,eg|67,0,582,0,96,0,ek||",
                "Amazon": "67,0,57u,0,96,0,eg|67,0,57u,0,96,0,ek||",
                "America_Central": "67,0,1l1,5,1kv,0,1p3,0,96,0,eg|67,0,1l1,5,1kv,0,1p3,0,96,0,ek||",
                "America_Eastern": "67,0,1l1,5,1kv,0,1es,0,96,0,eg|67,0,1l1,5,1kv,0,1es,0,96,0,ek||",
                "America_Mountain": "67,0,1l1,5,1kv,0,57n,0,96,0,eg|67,0,1l1,5,1kv,0,57n,0,96,0,ek||",
                "America_Pacific": "67,0,1l1,5,1kv,0,32v,0,96,0,eg|67,0,1l1,5,1kv,0,32v,0,96,0,ek||",
                "Anadyr": "67,0,tn,5,57j,0,96,0,eg|67,0,tn,5,57j,0,96,0,ek||",
                "Apia": "67,0,57i,0,96,0,eg|67,0,57i,0,96,0,ek||",
                "Aqtau": "67,0,57g,0,96,0,eg|67,0,57g,0,96,0,ek||",
                "Aqtobe": "67,0,57f,0,96,0,eg|67,0,57f,0,96,0,ek||",
                "Arabian": "67,0,57b,0,96,0,eg|67,0,57b,0,96,0,ek||",
                "Argentina": "67,0,32s,0,96,0,eg|67,0,32s,0,96,0,ek||",
                "Argentina_Western": "67,0,32s,5,1a3,0,96,0,eg|67,0,32s,5,1a3,0,96,0,ek||",
                "Armenia": "67,0,579,0,96,0,eg|67,0,579,0,96,0,ek||",
                "Atlantic": "67,0,1l1,5,1kv,0,578,0,96,0,eg|67,0,1l1,5,1kv,0,578,0,96,0,ek||",
                "Australia_Central": "67,0,23g,5,1p3,0,96,0,eg|67,0,23g,5,1p3,0,96,0,ek||",
                "Australia_CentralWestern": "67,0,23g,5,1p3,5,1a3,0,96,0,eg|67,0,23g,5,1p3,5,1a3,0,96,0,ek||",
                "Australia_Eastern": "67,0,23g,5,1es,0,96,0,eg|67,0,23g,5,1es,0,96,0,ek||",
                "Australia_Western": "67,0,23g,5,1a3,0,96,0,eg|67,0,23g,5,1a3,0,96,0,ek||",
                "Azerbaijan": "67,0,576,0,96,0,eg|67,0,576,0,96,0,ek||",
                "Azores": "67,0,574,0,96,0,eg|67,0,574,0,96,0,ek||",
                "Bangladesh": "67,0,573,0,96,0,eg|67,0,573,0,96,0,ek||",
                "Bhutan": "67,0,asm|||",
                "Bolivia": "67,0,asn|||",
                "Brasilia": "67,0,56v,0,96,0,eg|67,0,56v,0,96,0,ek||",
                "Brunei": "67,0,asp|||",
                "Cape_Verde": "67,0,56u,5,56t,0,96,0,eg|67,0,56u,5,56t,0,96,0,ek||",
                "Casey": "67,0,ass|||",
                "Chamorro": "67,0,ast|||",
                "Chatham": "67,0,56s,0,96,0,eg|67,0,56s,0,96,0,ek||",
                "Chile": "67,0,56r,0,96,0,eg|67,0,56r,0,96,0,ek||",
                "China": "67,0,56q,0,96,0,eg|67,0,56q,0,96,0,ek||",
                "Choibalsan": "67,0,56k,0,96,0,eg|67,0,56k,0,96,0,ek||",
                "Christmas": "67,0,at2|||",
                "Cocos": "67,0,at3|||",
                "Colombia": "67,0,56i,0,96,0,eg|67,0,56i,0,96,0,ek||",
                "Cook": "67,0,56h,0,96,0,eg|67,0,56h,0,96,0,ek||",
                "Cuba": "67,0,56g,0,96,0,eg|67,0,56g,0,96,0,ek||",
                "Davis": "67,0,at7|||",
                "DumontDUrville": "67,0,at8,5,at9|||",
                "East_Timor": "67,0,ata,5,1es|||",
                "Easter": "67,0,56f,0,96,0,eg|67,0,56f,0,96,0,ek||",
                "Ecuador": "67,0,atc|||",
                "Europe_Central": "67,0,2af,5,1p3,0,96,0,eg|67,0,2af,5,1p3,0,96,0,ek||",
                "Europe_Eastern": "67,0,2af,5,1es,0,96,0,eg|67,0,2af,5,1es,0,96,0,ek||",
                "Europe_Further_Eastern": "67,0,2af,5,1es,5,ate|||",
                "Europe_Western": "67,0,2af,5,1a3,0,96,0,eg|67,0,2af,5,1a3,0,96,0,ek||",
                "Falkland": "67,0,4s1,5,565,0,96,0,eg|67,0,4s1,5,565,0,96,0,ek||",
                "Fiji": "67,0,563,0,96,0,eg|67,0,563,0,96,0,ek||",
                "French_Guiana": "67,0,562,5,atj|||",
                "French_Southern": "67,0,atk|||",
                "Galapagos": "67,0,atl|||",
                "Gambier": "67,0,atm|||",
                "Georgia": "67,0,55s,0,96,0,eg|67,0,55s,0,96,0,ek||",
                "Gilbert_Islands": "67,0,ato|||",
                "GMT": "67,0,atp,0,atq|||",
                "Greenland_Eastern": "67,0,32n,5,1es,0,96,0,eg|67,0,32n,5,1es,0,96,0,ek||",
                "Greenland_Western": "67,0,32n,5,1a3,0,96,0,eg|67,0,32n,5,1a3,0,96,0,ek||",
                "Guam": "67,0,ats|||",
                "Gulf": "67,0,att|||",
                "Guyana": "67,0,562|||",
                "Hawaii_Aleutian": "67,0,55e,0,96,0,eg|67,0,55e,0,96,0,ek||",
                "Hong_Kong": "67,0,55d,5,55c,0,96,0,eg|67,0,55d,5,55c,0,96,0,ek||",
                "Hovd": "67,0,55b,0,96,0,eg|67,0,55b,0,96,0,ek||",
                "India": "67,0,au2|||",
                "Indian_Ocean": "67,0,au3|||",
                "Indochina": "67,0,au4|||",
                "Indonesia_Central": "67,0,4rr,5,1p3|||",
                "Indonesia_Eastern": "67,0,4rr,5,1es|||",
                "Indonesia_Western": "67,0,4rr,5,1a3|||",
                "Iran": "67,0,507,0,96,0,eg|67,0,507,0,96,0,ek||",
                "Irkutsk": "67,0,tn,5,505,0,96,0,eg|67,0,tn,5,505,0,96,0,ek||",
                "Israel": "67,0,4vm,0,96,0,eg|67,0,4vm,0,96,0,ek||",
                "Japan": "67,0,4vl,0,96,0,eg|67,0,4vl,0,96,0,ek||",
                "Kamchatka": "67,0,tn,5,4vj,0,96,0,eg|67,0,tn,5,4vj,0,96,0,ek||",
                "Kazakhstan_Eastern": "67,0,4vh,5,1es|||",
                "Kazakhstan_Western": "67,0,4vh,5,1a3|||",
                "Korea": "67,0,4vg,0,96,0,eg|67,0,4vg,0,96,0,ek||",
                "Kosrae": "67,0,aud|||",
                "Krasnoyarsk": "67,0,tn,5,4vb,0,96,0,eg|67,0,tn,5,4vb,0,96,0,ek||",
                "Kyrgystan": "67,0,auf|||",
                "Lanka": "67,0,aug|||",
                "Line_Islands": "67,0,auh|||",
                "Lord_Howe": "67,0,4v6,0,96,0,eg|67,0,4v6,0,96,0,ek||",
                "Macau": "67,0,4v2,0,96,0,eg|67,0,4v2,0,96,0,ek||",
                "Macquarie": "67,0,auk|||",
                "Magadan": "67,0,tn,5,4v1,0,96,0,eg|67,0,tn,5,4v1,0,96,0,ek||",
                "Malaysia": "67,0,aum|||",
                "Maldives": "67,0,aun|||",
                "Marquesas": "67,0,auo|||",
                "Marshall_Islands": "67,0,aup|||",
                "Mauritius": "67,0,4ut,0,96,0,eg|67,0,4ut,0,96,0,ek||",
                "Mawson": "67,0,aur|||",
                "Mexico_Northwest": "67,0,357,5,4um,0,96,0,eg|67,0,357,5,4um,0,96,0,ek||",
                "Mexico_Pacific": "67,0,357,5,32v,0,96,0,eg|67,0,357,5,32v,0,96,0,ek||",
                "Mongolia": "67,0,4uk,0,96,0,eg|67,0,4uk,0,96,0,ek||",
                "Moscow": "67,0,tn,5,4ui,0,96,0,eg|67,0,tn,5,4ui,0,96,0,ek||",
                "Myanmar": "67,0,av0|||",
                "Nauru": "67,0,av1|||",
                "Nepal": "67,0,av2|||",
                "New_Caledonia": "67,0,4ue,0,96,0,eg|67,0,4ue,0,96,0,ek||",
                "New_Zealand": "67,0,4u2,0,96,0,eg|67,0,4u2,0,96,0,ek||",
                "Newfoundland": "67,0,4u0,0,96,0,eg|67,0,4u0,0,96,0,ek||",
                "Niue": "67,0,av6|||",
                "Norfolk": "67,0,av7|||",
                "Noronha": "67,0,4ts,5,4tq,5,4tp,0,96,0,eg|67,0,4ts,5,4tq,5,4tp,0,96,0,ek||",
                "North_Mariana": "67,0,avb|||",
                "Novosibirsk": "67,0,tn,5,4tm,0,96,0,eg|67,0,tn,5,4tm,0,96,0,ek||",
                "Omsk": "67,0,tn,5,4tl,0,96,0,eg|67,0,tn,5,4tl,0,96,0,ek||",
                "Pakistan": "67,0,4tk,0,96,0,eg|67,0,4tk,0,96,0,ek||",
                "Palau": "67,0,avf|||",
                "Papua_New_Guinea": "67,0,avg|||",
                "Paraguay": "67,0,4tj,0,96,0,eg|67,0,4tj,0,96,0,ek||",
                "Peru": "67,0,4th,0,96,0,eg|67,0,4th,0,96,0,ek||",
                "Philippines": "67,0,4tf,0,96,0,eg|67,0,4tf,0,96,0,ek||",
                "Phoenix_Islands": "67,0,avk|||",
                "Pierre_Miquelon": "67,0,4t8,5,4t6,5,4rh,5,4t4,0,96,0,eg|67,0,4t8,5,4t6,5,4rh,5,4t4,0,96,0,ek||",
                "Pitcairn": "67,0,avp|||",
                "Ponape": "67,0,avq|||",
                "Qyzylorda": "67,0,4t2,0,96,0,eg|67,0,4t2,0,96,0,ek||",
                "Reunion": "67,0,avs|||",
                "Rothera": "67,0,avt|||",
                "Sakhalin": "67,0,tn,5,4sv,0,96,0,eg|67,0,tn,5,4sv,0,96,0,ek||",
                "Samara": "67,0,tn,5,4sn,0,96,0,eg|67,0,tn,5,4sn,0,96,0,ek||",
                "Samoa": "67,0,4sl,0,96,0,eg|67,0,4sl,0,96,0,ek||",
                "Seychelles": "67,0,4s1,5,b01|||",
                "Singapore": "67,0,b02|||",
                "Solomon": "67,0,b03|||",
                "South_Georgia": "67,0,b04|||",
                "Suriname": "67,0,b05|||",
                "Syowa": "67,0,b06|||",
                "Tahiti": "67,0,b07|||",
                "Taipei": "67,0,4sk,0,96,0,eg|67,0,4sk,0,96,0,ek||",
                "Tajikistan": "67,0,b09|||",
                "Tokelau": "67,0,b0a|||",
                "Tonga": "67,0,8it,0,96,0,eg|67,0,8it,0,96,0,ek||",
                "Truk": "67,0,b0c|||",
                "Turkmenistan": "67,0,8iq,0,96,0,eg|67,0,8iq,0,96,0,ek||",
                "Tuvalu": "67,0,b0e|||",
                "Uruguay": "67,0,8ip,0,96,0,eg|67,0,8ip,0,96,0,ek||",
                "Uzbekistan": "67,0,8io,0,96,0,eg|67,0,8io,0,96,0,ek||",
                "Vanuatu": "67,0,8in,0,96,0,eg|67,0,8in,0,96,0,ek||",
                "Venezuela": "67,0,b0i|||",
                "Vladivostok": "67,0,tn,5,8ik,0,96,0,eg|67,0,tn,5,8ik,0,96,0,ek||",
                "Volgograd": "67,0,tn,5,8ie,0,96,0,eg|67,0,tn,5,8ie,0,96,0,ek||",
                "Vostok": "67,0,b0l|||",
                "Wake": "67,0,b0m|||",
                "Wallis": "67,0,b0n,0,4rh,0,b0o|||",
                "Yakutsk": "67,0,tn,5,8ic,0,96,0,eg|67,0,tn,5,8ic,0,96,0,ek||",
                "Yekaterinburg": "67,0,tn,5,8i8,0,96,0,eg|67,0,tn,5,8i8,0,96,0,ek||"
            }
        },
        "tr-CY": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|78e,0,44,0,2a||",
                "Europe/London": "|722,0,95,0,2a||"
            },
            "metazone": {
                "Acre": "3q,0,44,0,2a|3q,0,95,0,2a||",
                "Afghanistan": "2f0,0,2a|||",
                "Africa_Central": "1bn,0,c5,0,2a|||",
                "Africa_Eastern": "10e,0,c5,0,2a|||",
                "Africa_Southern": "2ht,0,c5,0,44,0,2a|||",
                "Africa_Western": "11k,0,c5,0,44,0,2a|11k,0,c5,0,95,0,2a||",
                "Alaska": "3p,0,44,0,2a|3p,0,95,0,2a||",
                "Almaty": "36b,0,44,0,2a|36b,0,95,0,2a||",
                "Amazon": "5m,0,44,0,2a|5m,0,95,0,2a||",
                "America_Central": "15n,0,m2,0,1c2,0,44,0,2a|15n,0,m2,0,1c2,0,95,0,2a||",
                "America_Eastern": "15n,0,m2,0,10e,0,44,0,2a|15n,0,m2,0,10e,0,95,0,2a||",
                "America_Mountain": "15n,0,m2,0,36f,0,44,0,2a|15n,0,m2,0,36f,0,95,0,2a||",
                "America_Pacific": "15n,0,m2,0,10c,0,44,0,2a|15n,0,m2,0,10c,0,95,0,2a||",
                "Anadyr": "36g,0,44,0,2a|36g,0,95,0,2a||",
                "Apia": "3e,0,44,0,2a|3e,0,95,0,2a||",
                "Aqtau": "36i,0,44,0,2a|36i,0,95,0,2a||",
                "Aqtobe": "2gg,0,44,0,2a|2gg,0,95,0,2a||",
                "Arabian": "36j,0,44,0,2a|36j,0,95,0,2a||",
                "Argentina": "1si,0,44,0,2a|1si,0,95,0,2a||",
                "Argentina_Western": "11k,0,1si,0,44,0,2a|11k,0,1si,0,95,0,2a||",
                "Armenia": "2k6,0,44,0,2a|2k6,0,95,0,2a||",
                "Atlantic": "r2,0,44,0,2a|r2,0,95,0,2a||",
                "Australia_Central": "1bn,0,16m,0,44,0,2a|1bn,0,16m,0,95,0,2a||",
                "Australia_CentralWestern": "36k,0,16m,0,44,0,2a|36k,0,16m,0,95,0,2a||",
                "Australia_Eastern": "10e,0,16m,0,44,0,2a|10e,0,16m,0,95,0,2a||",
                "Australia_Western": "11k,0,16m,0,44,0,2a|11k,0,16m,0,95,0,2a||",
                "Azerbaijan": "36l,0,44,0,2a|36l,0,95,0,2a||",
                "Azores": "36m,0,44,0,2a|36m,0,95,0,2a||",
                "Bangladesh": "2kb,0,44,0,2a|2kb,0,95,0,2a||",
                "Bhutan": "as,0,2a|||",
                "Bolivia": "8gf,0,2a|||",
                "Brasilia": "3a,0,44,0,2a|3a,0,95,0,2a||",
                "Brunei": "9o,0,8g4,5,8g2,0,2a|||",
                "Cape_Verde": "7c,0,3j,0,44,0,2a|7c,0,3j,0,95,0,2a||",
                "Casey": "b7,0,2a|||",
                "Chamorro": "6j,0,2a|||",
                "Chatham": "27,0,44,0,2a|27,0,95,0,2a||",
                "Chile": "36s,0,44,0,2a|36s,0,95,0,2a||",
                "China": "1s7,0,44,0,2a|1s7,0,95,0,2a||",
                "Choibalsan": "1s8,0,44,0,2a|1s8,0,95,0,2a||",
                "Christmas": "99,0,137,0,2a|||",
                "Cocos": "7v,0,nl,0,2a|||",
                "Colombia": "36t,0,44,0,2a|36t,0,95,0,2a||",
                "Cook": "2v,0,nl,0,44,0,2a|2v,0,nl,0,8fn,0,95,0,2a||",
                "Cuba": "370,0,44,0,2a|370,0,95,0,2a||",
                "Davis": "5s,0,2a|||",
                "DumontDUrville": "5b,5,5j,0,2a|||",
                "East_Timor": "10e,0,7n,0,2a|||",
                "Easter": "371,0,137,0,44,0,2a|371,0,137,0,95,0,2a||",
                "Ecuador": "1k0,0,2a|||",
                "Europe_Central": "1bn,0,1ds,0,44,0,2a|1bn,0,1ds,0,95,0,2a||",
                "Europe_Eastern": "10e,0,1ds,0,44,0,2a|10e,0,1ds,0,95,0,2a||",
                "Europe_Further_Eastern": "8fd,0,10e,0,1ds,0,2a|||",
                "Europe_Western": "11k,0,1ds,0,44,0,2a|11k,0,1ds,0,95,0,2a||",
                "Falkland": "4v,0,nl,0,44,0,2a|4v,0,nl,0,95,0,2a||",
                "Fiji": "4q,0,44,0,2a|4q,0,95,0,2a||",
                "French_Guiana": "1sb,0,8fc,0,2a|||",
                "French_Southern": "1sb,0,2ht,0,1sp,0,27v,0,2a|||",
                "Galapagos": "ak,0,2a|||",
                "Gambier": "6v,0,2a|||",
                "Georgia": "373,0,44,0,2a|373,0,95,0,2a||",
                "Gilbert_Islands": "6q,0,nl,0,2a|||",
                "GMT": "5u,0,8f5,0,2a|||",
                "Greenland_Eastern": "10e,0,1sq,0,44,0,2a|10e,0,1sq,0,95,0,2a||",
                "Greenland_Western": "11k,0,1sq,0,44,0,2a|11k,0,1sq,0,95,0,2a||",
                "Guam": "83,0,44,0,2a|||",
                "Gulf": "8f2,0,2a|||",
                "Guyana": "7a,0,2a|||",
                "Hawaii_Aleutian": "4g,5,1l3,0,44,0,2a|4g,5,1l3,0,95,0,2a||",
                "Hong_Kong": "2l,0,2p,0,44,0,2a|2l,0,2p,0,95,0,2a||",
                "Hovd": "2g,0,44,0,2a|2g,0,95,0,2a||",
                "India": "30i,0,44,0,2a|||",
                "Indian_Ocean": "8eq,0,8eo,0,2a|||",
                "Indochina": "8ed,0,2a|||",
                "Indonesia_Central": "1bn,0,2h6,0,2a|||",
                "Indonesia_Eastern": "10e,0,2h6,0,2a|||",
                "Indonesia_Western": "11k,0,2h6,0,2a|||",
                "Iran": "1sj,0,44,0,2a|1sj,0,95,0,2a||",
                "Irkutsk": "1sk,0,44,0,2a|1sk,0,95,0,2a||",
                "Israel": "1sl,0,44,0,2a|1sl,0,95,0,2a||",
                "Japan": "375,0,44,0,2a|375,0,95,0,2a||",
                "Kamchatka": "37,5,378,0,44,0,2a|37,5,378,0,95,0,2a||",
                "Kazakhstan_Eastern": "10e,0,1mt,0,2a|||",
                "Kazakhstan_Western": "11k,0,1mt,0,2a|||",
                "Korea": "379,0,44,0,2a|379,0,95,0,2a||",
                "Kosrae": "6f,0,2a|||",
                "Krasnoyarsk": "41,0,44,0,2a|41,0,95,0,2a||",
                "Kyrgystan": "8dl,0,2a|||",
                "Lanka": "9c,0,2a|||",
                "Line_Islands": "ac,0,nl,0,2a|||",
                "Lord_Howe": "1u,0,24,0,44,0,2a|1u,0,24,0,95,0,2a||",
                "Macau": "176,0,44,0,2a|176,0,95,0,2a||",
                "Macquarie": "6m,0,137,0,2a|||",
                "Magadan": "2o,0,44,0,2a|2o,0,95,0,2a||",
                "Malaysia": "8dk,0,2a|||",
                "Maldives": "8di,0,2a|||",
                "Marquesas": "1jm,0,nl,0,2a|||",
                "Marshall_Islands": "7o,0,nl,0,2a|||",
                "Mauritius": "4u,0,44,0,2a|4u,0,95,0,2a||",
                "Mawson": "63,0,2a|||",
                "Mexico_Northwest": "37a,0,10g,0,44,0,2a|37a,0,10g,0,95,0,2a||",
                "Mexico_Pacific": "10g,0,10c,0,37b,0,44,0,2a|10g,0,10c,0,37b,0,95,0,2a||",
                "Mongolia": "4f,0,37j,0,44,0,2a|4f,0,37j,0,95,0,2a||",
                "Moscow": "37l,0,44,0,2a|37l,0,95,0,2a||",
                "Myanmar": "8v,0,2a|||",
                "Nauru": "5v,0,2a|||",
                "Nepal": "8u,0,2a|||",
                "New_Caledonia": "142,0,37o,0,44,0,2a|142,0,37o,0,95,0,2a||",
                "New_Zealand": "142,0,go,0,44,0,2a|142,0,go,0,95,0,2a||",
                "Newfoundland": "53,0,44,0,2a|53,0,95,0,2a||",
                "Niue": "8g,0,2a|||",
                "Norfolk": "6l,0,137,0,2a|||",
                "Noronha": "1s,0,2,0,23,0,44,0,2a|1s,0,2,0,23,0,95,0,2a||",
                "North_Mariana": "15n,0,bd,0,nl,0,2a|||",
                "Novosibirsk": "3b,0,44,0,2a|3b,0,95,0,2a||",
                "Omsk": "26,0,44,0,2a|26,0,95,0,2a||",
                "Pakistan": "3i,0,44,0,2a|3i,0,95,0,2a||",
                "Palau": "9b,0,2a|||",
                "Papua_New_Guinea": "9a,0,142,0,8co,0,2a|||",
                "Paraguay": "3c,0,44,0,2a|3c,0,95,0,2a||",
                "Peru": "4n,0,44,0,2a|4n,0,95,0,2a||",
                "Philippines": "37p,0,44,0,2a|37p,0,95,0,2a||",
                "Phoenix_Islands": "90,0,nl,0,2a|||",
                "Pierre_Miquelon": "8i,0,33,0,1sp,0,35,0,44,0,2a|8i,0,33,0,1sp,0,35,0,95,0,2a||",
                "Pitcairn": "7e,0,2a|||",
                "Ponape": "9s,0,2a|||",
                "Pyongyang": "8a,0,2a|||",
                "Qyzylorda": "37t,0,44,0,2a|37t,0,95,0,2a||",
                "Reunion": "b4,0,2a|||",
                "Rothera": "5q,0,2a|||",
                "Sakhalin": "164,0,44,0,2a|164,0,95,0,2a||",
                "Samara": "29,0,44,0,2a|29,0,95,0,2a||",
                "Samoa": "2c,0,44,0,2a|2c,0,95,0,2a||",
                "Seychelles": "8cd,0,2a|||",
                "Singapore": "m8,0,44,0,2a|||",
                "Solomon": "b5,0,nl,0,2a|||",
                "South_Georgia": "2ht,0,2u,0,2a|||",
                "Suriname": "mg,0,2a|||",
                "Syowa": "8ca,0,2a|||",
                "Tahiti": "8n,0,2a|||",
                "Taipei": "3d,0,44,0,2a|3d,0,95,0,2a||",
                "Tajikistan": "3mh,0,2a|||",
                "Tokelau": "5d,0,2a|||",
                "Tonga": "2j,0,44,0,2a|2j,0,95,0,2a||",
                "Truk": "6p,0,2a|||",
                "Turkmenistan": "2l2,0,44,0,2a|2l2,0,95,0,2a||",
                "Tuvalu": "6h,0,2a|||",
                "Uruguay": "4k,0,44,0,2a|4k,0,95,0,2a||",
                "Uzbekistan": "2l3,0,44,0,2a|2l3,0,95,0,2a||",
                "Vanuatu": "2f,0,44,0,2a|2f,0,95,0,2a||",
                "Venezuela": "7s,0,2a|||",
                "Vladivostok": "28,0,44,0,2a|28,0,95,0,2a||",
                "Volgograd": "3f,0,44,0,2a|3f,0,95,0,2a||",
                "Vostok": "5t,0,2a|||",
                "Wake": "5r,0,137,0,2a|||",
                "Wallis": "5f,0,1sp,0,5k,0,2a|||",
                "Yakutsk": "3u,0,44,0,2a|3u,0,95,0,2a||",
                "Yekaterinburg": "5l,0,44,0,2a|5l,0,95,0,2a||"
            }
        },
        "tr": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|78e,0,44,0,2a||",
                "Europe/London": "|722,0,95,0,2a||"
            },
            "metazone": {
                "Acre": "3q,0,44,0,2a|3q,0,95,0,2a||",
                "Afghanistan": "2f0,0,2a|||",
                "Africa_Central": "1bn,0,c5,0,2a|||",
                "Africa_Eastern": "10e,0,c5,0,2a|||",
                "Africa_Southern": "2ht,0,c5,0,44,0,2a|||",
                "Africa_Western": "11k,0,c5,0,44,0,2a|11k,0,c5,0,95,0,2a||",
                "Alaska": "3p,0,44,0,2a|3p,0,95,0,2a||",
                "Almaty": "36b,0,44,0,2a|36b,0,95,0,2a||",
                "Amazon": "5m,0,44,0,2a|5m,0,95,0,2a||",
                "America_Central": "15n,0,m2,0,1c2,0,44,0,2a|15n,0,m2,0,1c2,0,95,0,2a||",
                "America_Eastern": "15n,0,m2,0,10e,0,44,0,2a|15n,0,m2,0,10e,0,95,0,2a||",
                "America_Mountain": "15n,0,m2,0,36f,0,44,0,2a|15n,0,m2,0,36f,0,95,0,2a||",
                "America_Pacific": "15n,0,m2,0,10c,0,44,0,2a|15n,0,m2,0,10c,0,95,0,2a||",
                "Anadyr": "36g,0,44,0,2a|36g,0,95,0,2a||",
                "Apia": "3e,0,44,0,2a|3e,0,95,0,2a||",
                "Aqtau": "36i,0,44,0,2a|36i,0,95,0,2a||",
                "Aqtobe": "2gg,0,44,0,2a|2gg,0,95,0,2a||",
                "Arabian": "36j,0,44,0,2a|36j,0,95,0,2a||",
                "Argentina": "1si,0,44,0,2a|1si,0,95,0,2a||",
                "Argentina_Western": "11k,0,1si,0,44,0,2a|11k,0,1si,0,95,0,2a||",
                "Armenia": "2k6,0,44,0,2a|2k6,0,95,0,2a||",
                "Atlantic": "r2,0,44,0,2a|r2,0,95,0,2a||",
                "Australia_Central": "1bn,0,16m,0,44,0,2a|1bn,0,16m,0,95,0,2a||",
                "Australia_CentralWestern": "36k,0,16m,0,44,0,2a|36k,0,16m,0,95,0,2a||",
                "Australia_Eastern": "10e,0,16m,0,44,0,2a|10e,0,16m,0,95,0,2a||",
                "Australia_Western": "11k,0,16m,0,44,0,2a|11k,0,16m,0,95,0,2a||",
                "Azerbaijan": "36l,0,44,0,2a|36l,0,95,0,2a||",
                "Azores": "36m,0,44,0,2a|36m,0,95,0,2a||",
                "Bangladesh": "2kb,0,44,0,2a|2kb,0,95,0,2a||",
                "Bhutan": "as,0,2a|||",
                "Bolivia": "8gf,0,2a|||",
                "Brasilia": "3a,0,44,0,2a|3a,0,95,0,2a||",
                "Brunei": "9o,0,8g4,5,8g2,0,2a|||",
                "Cape_Verde": "7c,0,3j,0,44,0,2a|7c,0,3j,0,95,0,2a||",
                "Casey": "b7,0,2a|||",
                "Chamorro": "6j,0,2a|||",
                "Chatham": "27,0,44,0,2a|27,0,95,0,2a||",
                "Chile": "36s,0,44,0,2a|36s,0,95,0,2a||",
                "China": "1s7,0,44,0,2a|1s7,0,95,0,2a||",
                "Choibalsan": "1s8,0,44,0,2a|1s8,0,95,0,2a||",
                "Christmas": "99,0,137,0,2a|||",
                "Cocos": "7v,0,nl,0,2a|||",
                "Colombia": "36t,0,44,0,2a|36t,0,95,0,2a||",
                "Cook": "2v,0,nl,0,44,0,2a|2v,0,nl,0,8fn,0,95,0,2a||",
                "Cuba": "370,0,44,0,2a|370,0,95,0,2a||",
                "Davis": "5s,0,2a|||",
                "DumontDUrville": "5b,5,5j,0,2a|||",
                "East_Timor": "10e,0,7n,0,2a|||",
                "Easter": "371,0,137,0,44,0,2a|371,0,137,0,95,0,2a||",
                "Ecuador": "1k0,0,2a|||",
                "Europe_Central": "1bn,0,1ds,0,44,0,2a|1bn,0,1ds,0,95,0,2a||",
                "Europe_Eastern": "10e,0,1ds,0,44,0,2a|10e,0,1ds,0,95,0,2a||",
                "Europe_Further_Eastern": "8fd,0,10e,0,1ds,0,2a|||",
                "Europe_Western": "11k,0,1ds,0,44,0,2a|11k,0,1ds,0,95,0,2a||",
                "Falkland": "4v,0,nl,0,44,0,2a|4v,0,nl,0,95,0,2a||",
                "Fiji": "4q,0,44,0,2a|4q,0,95,0,2a||",
                "French_Guiana": "1sb,0,8fc,0,2a|||",
                "French_Southern": "1sb,0,2ht,0,1sp,0,27v,0,2a|||",
                "Galapagos": "ak,0,2a|||",
                "Gambier": "6v,0,2a|||",
                "Georgia": "373,0,44,0,2a|373,0,95,0,2a||",
                "Gilbert_Islands": "6q,0,nl,0,2a|||",
                "GMT": "5u,0,8f5,0,2a|||",
                "Greenland_Eastern": "10e,0,1sq,0,44,0,2a|10e,0,1sq,0,95,0,2a||",
                "Greenland_Western": "11k,0,1sq,0,44,0,2a|11k,0,1sq,0,95,0,2a||",
                "Guam": "83,0,44,0,2a|||",
                "Gulf": "8f2,0,2a|||",
                "Guyana": "7a,0,2a|||",
                "Hawaii_Aleutian": "4g,5,1l3,0,44,0,2a|4g,5,1l3,0,95,0,2a||",
                "Hong_Kong": "2l,0,2p,0,44,0,2a|2l,0,2p,0,95,0,2a||",
                "Hovd": "2g,0,44,0,2a|2g,0,95,0,2a||",
                "India": "30i,0,44,0,2a|||",
                "Indian_Ocean": "8eq,0,8eo,0,2a|||",
                "Indochina": "8ed,0,2a|||",
                "Indonesia_Central": "1bn,0,2h6,0,2a|||",
                "Indonesia_Eastern": "10e,0,2h6,0,2a|||",
                "Indonesia_Western": "11k,0,2h6,0,2a|||",
                "Iran": "1sj,0,44,0,2a|1sj,0,95,0,2a||",
                "Irkutsk": "1sk,0,44,0,2a|1sk,0,95,0,2a||",
                "Israel": "1sl,0,44,0,2a|1sl,0,95,0,2a||",
                "Japan": "375,0,44,0,2a|375,0,95,0,2a||",
                "Kamchatka": "37,5,378,0,44,0,2a|37,5,378,0,95,0,2a||",
                "Kazakhstan_Eastern": "10e,0,1mt,0,2a|||",
                "Kazakhstan_Western": "11k,0,1mt,0,2a|||",
                "Korea": "379,0,44,0,2a|379,0,95,0,2a||",
                "Kosrae": "6f,0,2a|||",
                "Krasnoyarsk": "41,0,44,0,2a|41,0,95,0,2a||",
                "Kyrgystan": "8dl,0,2a|||",
                "Lanka": "9c,0,2a|||",
                "Line_Islands": "ac,0,nl,0,2a|||",
                "Lord_Howe": "1u,0,24,0,44,0,2a|1u,0,24,0,95,0,2a||",
                "Macau": "176,0,44,0,2a|176,0,95,0,2a||",
                "Macquarie": "6m,0,137,0,2a|||",
                "Magadan": "2o,0,44,0,2a|2o,0,95,0,2a||",
                "Malaysia": "8dk,0,2a|||",
                "Maldives": "8di,0,2a|||",
                "Marquesas": "1jm,0,nl,0,2a|||",
                "Marshall_Islands": "7o,0,nl,0,2a|||",
                "Mauritius": "4u,0,44,0,2a|4u,0,95,0,2a||",
                "Mawson": "63,0,2a|||",
                "Mexico_Northwest": "37a,0,10g,0,44,0,2a|37a,0,10g,0,95,0,2a||",
                "Mexico_Pacific": "10g,0,10c,0,37b,0,44,0,2a|10g,0,10c,0,37b,0,95,0,2a||",
                "Mongolia": "4f,0,37j,0,44,0,2a|4f,0,37j,0,95,0,2a||",
                "Moscow": "37l,0,44,0,2a|37l,0,95,0,2a||",
                "Myanmar": "8v,0,2a|||",
                "Nauru": "5v,0,2a|||",
                "Nepal": "8u,0,2a|||",
                "New_Caledonia": "142,0,37o,0,44,0,2a|142,0,37o,0,95,0,2a||",
                "New_Zealand": "142,0,go,0,44,0,2a|142,0,go,0,95,0,2a||",
                "Newfoundland": "53,0,44,0,2a|53,0,95,0,2a||",
                "Niue": "8g,0,2a|||",
                "Norfolk": "6l,0,137,0,2a|||",
                "Noronha": "1s,0,2,0,23,0,44,0,2a|1s,0,2,0,23,0,95,0,2a||",
                "North_Mariana": "15n,0,bd,0,nl,0,2a|||",
                "Novosibirsk": "3b,0,44,0,2a|3b,0,95,0,2a||",
                "Omsk": "26,0,44,0,2a|26,0,95,0,2a||",
                "Pakistan": "3i,0,44,0,2a|3i,0,95,0,2a||",
                "Palau": "9b,0,2a|||",
                "Papua_New_Guinea": "9a,0,142,0,8co,0,2a|||",
                "Paraguay": "3c,0,44,0,2a|3c,0,95,0,2a||",
                "Peru": "4n,0,44,0,2a|4n,0,95,0,2a||",
                "Philippines": "37p,0,44,0,2a|37p,0,95,0,2a||",
                "Phoenix_Islands": "90,0,nl,0,2a|||",
                "Pierre_Miquelon": "8i,0,33,0,1sp,0,35,0,44,0,2a|8i,0,33,0,1sp,0,35,0,95,0,2a||",
                "Pitcairn": "7e,0,2a|||",
                "Ponape": "9s,0,2a|||",
                "Pyongyang": "8a,0,2a|||",
                "Qyzylorda": "37t,0,44,0,2a|37t,0,95,0,2a||",
                "Reunion": "b4,0,2a|||",
                "Rothera": "5q,0,2a|||",
                "Sakhalin": "164,0,44,0,2a|164,0,95,0,2a||",
                "Samara": "29,0,44,0,2a|29,0,95,0,2a||",
                "Samoa": "2c,0,44,0,2a|2c,0,95,0,2a||",
                "Seychelles": "8cd,0,2a|||",
                "Singapore": "m8,0,44,0,2a|||",
                "Solomon": "b5,0,nl,0,2a|||",
                "South_Georgia": "2ht,0,2u,0,2a|||",
                "Suriname": "mg,0,2a|||",
                "Syowa": "8ca,0,2a|||",
                "Tahiti": "8n,0,2a|||",
                "Taipei": "3d,0,44,0,2a|3d,0,95,0,2a||",
                "Tajikistan": "3mh,0,2a|||",
                "Tokelau": "5d,0,2a|||",
                "Tonga": "2j,0,44,0,2a|2j,0,95,0,2a||",
                "Truk": "6p,0,2a|||",
                "Turkmenistan": "2l2,0,44,0,2a|2l2,0,95,0,2a||",
                "Tuvalu": "6h,0,2a|||",
                "Uruguay": "4k,0,44,0,2a|4k,0,95,0,2a||",
                "Uzbekistan": "2l3,0,44,0,2a|2l3,0,95,0,2a||",
                "Vanuatu": "2f,0,44,0,2a|2f,0,95,0,2a||",
                "Venezuela": "7s,0,2a|||",
                "Vladivostok": "28,0,44,0,2a|28,0,95,0,2a||",
                "Volgograd": "3f,0,44,0,2a|3f,0,95,0,2a||",
                "Vostok": "5t,0,2a|||",
                "Wake": "5r,0,137,0,2a|||",
                "Wallis": "5f,0,1sp,0,5k,0,2a|||",
                "Yakutsk": "3u,0,44,0,2a|3u,0,95,0,2a||",
                "Yekaterinburg": "5l,0,44,0,2a|5l,0,95,0,2a||"
            }
        },
        "twq": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "tzm": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "ug": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|ce0,0,ej,0,6k||",
                "Europe/London": "|ce1,0,ej,0,6k||"
            },
            "metazone": {
                "Acre": "b2f,0,e7,0,6k|b2i,0,ej,0,6k||",
                "Afghanistan": "b2k,0,6k|||",
                "Africa_Central": "1p1,0,2se,0,6k|||",
                "Africa_Eastern": "1g9,0,2se,0,6k|||",
                "Africa_Southern": "4r5,0,2se,0,e7,0,6k|||",
                "Africa_Western": "18l,0,2se,0,e7,0,6k|18l,0,2se,0,ej,0,6k||",
                "Alaska": "8b1,0,e7,0,6k|8b1,0,ej,0,6k||",
                "Almaty": "8b0,0,e7,0,6k|8b0,0,ej,0,6k||",
                "Amazon": "89d,0,e7,0,6k|89d,0,ej,0,6k||",
                "America_Central": "1p1,0,1dl,0,e7,0,6k|1p1,0,1dl,0,ej,0,6k||",
                "America_Eastern": "1g9,0,1dl,0,e7,0,6k|1g9,0,1dl,0,ej,0,6k||",
                "America_Mountain": "897,0,e7,0,6k|897,0,ej,0,6k||",
                "America_Pacific": "38g,0,29b,0,e7,0,6k|38g,0,29b,0,ej,0,6k||",
                "Anadyr": "890,0,e7,0,6k|890,0,ej,0,6k||",
                "Aqtau": "88r,0,e7,0,6k|88r,0,ej,0,6k||",
                "Aqtobe": "88p,0,e7,0,6k|88p,0,ej,0,6k||",
                "Arabian": "88n,0,e7,0,6k|88n,0,ej,0,6k||",
                "Argentina": "38i,0,e7,0,6k|38i,0,ej,0,6k||",
                "Argentina_Western": "18l,0,38i,0,e7,0,6k|18l,0,38i,0,ej,0,6k||",
                "Armenia": "88h,0,e7,0,6k|88h,0,ej,0,6k||",
                "Atlantic": "88g,0,29b,0,e7,0,6k|88g,0,29b,0,ej,0,6k||",
                "Australia_Central": "29a,0,1p1,0,1dl,0,e7,0,6k|29a,0,1p1,0,1dl,0,ej,0,6k||",
                "Australia_CentralWestern": "b39,0,1p1,0,18l,0,1dl,0,e7,0,6k|29a,0,1p1,0,18l,0,1dl,0,ej,0,6k||",
                "Australia_Eastern": "29a,0,1g9,0,1dl,0,e7,0,6k|29a,0,1g9,0,1dl,0,ej,0,6k||",
                "Australia_Western": "29a,0,18l,0,1dl,0,e7,0,6k|29a,0,18l,0,1dl,0,ej,0,6k||",
                "Azerbaijan": "88e,0,e7,0,6k|88e,0,ej,0,6k||",
                "Azores": "88d,0,e7,0,6k|88d,0,ej,0,6k||",
                "Bangladesh": "88c,0,e7,0,6k|88c,0,ej,0,6k||",
                "Bhutan": "b3d,0,6k|||",
                "Bolivia": "b3e,0,6k|||",
                "Brasilia": "88b,0,e7,0,6k|88b,0,ej,0,6k||",
                "Brunei": "b3g,0,b3h,0,6k|||",
                "Cape_Verde": "887,0,886,0,e7,0,6k|887,0,886,0,ej,0,6k||",
                "Casey": "b3k,0,6k|||",
                "Chamorro": "b3l,0,e7,0,6k|||",
                "Chatham": "884,0,e7,0,6k|884,0,ej,0,6k||",
                "Chile": "881,0,e7,0,6k|881,0,ej,0,6k||",
                "China": "880,0,e7,0,6k|880,0,ej,0,6k||",
                "Choibalsan": "87n,0,e7,0,6k|87n,0,ej,0,6k||",
                "Christmas": "b3q,0,2rl,0,6k|||",
                "Cocos": "b3s,0,2rl,0,6k|||",
                "Colombia": "87a,0,e7,0,6k|87a,0,ej,0,6k||",
                "Cook": "877,0,1gb,0,e7,0,6k|877,0,1gb,0,b40,0,ej,0,6k||",
                "Cuba": "871,0,e7,0,6k|871,0,ej,0,6k||",
                "Davis": "b42,0,6k|||",
                "DumontDUrville": "b43,5,b44,0,6k|||",
                "East_Timor": "1g9,0,b45,0,6k|||",
                "Easter": "b46,0,2rl,0,e7,0,6k|b47,0,2rl,0,ej,0,6k||",
                "Ecuador": "b48,0,6k|||",
                "Europe_Central": "1p1,0,2ft,0,e7,0,6k|1p1,0,2ft,0,ej,0,6k||",
                "Europe_Eastern": "1g9,0,2ft,0,e7,0,6k|1g9,0,2ft,0,ej,0,6k||",
                "Europe_Western": "18l,0,2ft,0,e7,0,6k|18l,0,2ft,0,ej,0,6k||",
                "Falkland": "86s,0,1gb,0,e7,0,6k|86s,0,1gb,0,ej,0,6k||",
                "Fiji": "86q,0,e7,0,6k|86q,0,ej,0,6k||",
                "French_Guiana": "86p,0,86o,0,86g,0,6k|||",
                "French_Southern": "86p,0,86o,0,4r5,0,38j,0,b4g,0,6k|||",
                "Galapagos": "4nd,0,6k|||",
                "Gambier": "b4h,0,6k|||",
                "Georgia": "86b,0,e7,0,6k|86b,0,ej,0,6k||",
                "Gilbert_Islands": "b4j,0,1gb,0,6k|||",
                "GMT": "b4k,0,6k|||",
                "Greenland_Eastern": "1g9,0,38k,0,e7,0,6k|1g9,0,38k,0,ej,0,6k||",
                "Greenland_Western": "18l,0,38k,0,e7,0,6k|18l,0,38k,0,ej,0,6k||",
                "Guam": "b4m,0,e7,0,6k|||",
                "Gulf": "b4n,0,e7,0,6k|||",
                "Guyana": "86g,0,6k|||",
                "Hawaii_Aleutian": "860,5,85v,0,e7,0,6k|860,5,85v,0,ej,0,6k||",
                "Hong_Kong": "85u,0,e7,0,6k|85u,0,ej,0,6k||",
                "Hovd": "85s,0,e7,0,6k|85s,0,ej,0,6k||",
                "India": "b4s,0,e7,0,6k|||",
                "Indian_Ocean": "85r,0,29b,0,6k|||",
                "Indochina": "85r,0,b4u,0,6k|||",
                "Indonesia_Central": "1p1,0,4qv,0,6k|||",
                "Indonesia_Eastern": "1g9,0,4qv,0,6k|||",
                "Indonesia_Western": "18l,0,4qv,0,6k|||",
                "Iran": "856,0,e7,0,6k|856,0,ej,0,6k||",
                "Irkutsk": "854,0,e7,0,6k|854,0,ej,0,6k||",
                "Israel": "851,0,e7,0,6k|851,0,ej,0,6k||",
                "Japan": "850,0,e7,0,6k|850,0,ej,0,6k||",
                "Kamchatka": "84u,5,84q,0,e7,0,6k|84u,5,84q,0,ej,0,6k||",
                "Kazakhstan_Eastern": "1g9,0,84p,0,6k|||",
                "Kazakhstan_Western": "18l,0,84p,0,6k|||",
                "Korea": "84o,0,e7,0,6k|84o,0,ej,0,6k||",
                "Kosrae": "b58,0,6k|||",
                "Krasnoyarsk": "84l,0,e7,0,6k|84l,0,ej,0,6k||",
                "Kyrgystan": "b5a,0,6k|||",
                "Lanka": "b5b,0,b5c,0,6k|||",
                "Line_Islands": "s2,0,1gb,0,6k|||",
                "Lord_Howe": "rh,5,84j,0,e7,0,6k|rh,5,84j,0,ej,0,6k||",
                "Macau": "84c,0,e7,0,6k|84c,0,ej,0,6k||",
                "Macquarie": "b5f,0,1gb,0,6k|||",
                "Magadan": "2al,0,e7,0,6k|2al,0,ej,0,6k||",
                "Malaysia": "b5g,0,6k|||",
                "Maldives": "b5h,0,6k|||",
                "Marquesas": "b5i,0,6k|||",
                "Marshall_Islands": "rb,0,1gb,0,6k|||",
                "Mauritius": "83v,0,e7,0,6k|83v,0,ej,0,6k||",
                "Mawson": "b5k,0,6k|||",
                "Mexico_Northwest": "38o,0,18l,0,4qt,0,1dl,0,e7,0,6k|38o,0,18l,0,4qt,0,1dl,0,ej,0,6k||",
                "Mexico_Pacific": "38o,0,38g,0,29b,0,e7,0,6k|38o,0,38g,0,29b,0,ej,0,6k||",
                "Mongolia": "83h,0,e7,0,6k|83h,0,ej,0,6k||",
                "Moscow": "83b,0,e7,0,6k|83b,0,ej,0,6k||",
                "Myanmar": "b5p,0,6k|||",
                "Nauru": "b5q,0,6k|||",
                "Nepal": "b5r,0,6k|||",
                "New_Caledonia": "2ra,0,835,0,e7,0,6k|2ra,0,835,0,ej,0,6k||",
                "New_Zealand": "2ra,0,833,0,e7,0,6k|2ra,0,833,0,ej,0,6k||",
                "Newfoundland": "82v,0,e7,0,6k|82v,0,ej,0,6k||",
                "Niue": "b60,0,6k|||",
                "Norfolk": "s6,0,1gb,0,6k|||",
                "Noronha": "82t,5,82o,0,e7,0,6k|82t,5,82o,0,ej,0,6k||",
                "North_Mariana": "4qt,0,b63,0,1gb,0,6k|||",
                "Novosibirsk": "822,0,e7,0,6k|822,0,ej,0,6k||",
                "Omsk": "821,0,e7,0,6k|821,0,ej,0,6k||",
                "Pakistan": "820,0,e7,0,6k|820,0,ej,0,6k||",
                "Palau": "b67,0,6k|||",
                "Papua_New_Guinea": "b68,0,2ra,0,b69,0,6k|||",
                "Paraguay": "81r,0,e7,0,6k|81r,0,ej,0,6k||",
                "Peru": "81o,0,e7,0,6k|81o,0,ej,0,6k||",
                "Philippines": "81j,0,e7,0,6k|81j,0,ej,0,6k||",
                "Phoenix_Islands": "b6d,0,1gb,0,6k|||",
                "Pierre_Miquelon": "81h,0,81g,0,38j,0,815,0,e7,0,6k|81h,0,81g,0,38j,0,815,0,ej,0,6k||",
                "Pitcairn": "b6h,0,6k|||",
                "Ponape": "b6i,0,6k|||",
                "Qyzylorda": "80n,0,e7,0,6k|80n,0,ej,0,6k||",
                "Reunion": "b6k,0,6k|||",
                "Rothera": "b6l,0,6k|||",
                "Sakhalin": "80i,0,e7,0,6k|80i,0,ej,0,6k||",
                "Samara": "2ij,0,e7,0,6k|2ij,0,ej,0,6k||",
                "Samoa": "b6n,0,e7,0,6k|b6o,0,ej,0,6k||",
                "Seychelles": "b6p,0,6k|||",
                "Singapore": "b6q,0,6k|||",
                "Solomon": "b6r,0,1gb,0,6k|||",
                "South_Georgia": "4r5,0,b6s,0,6k|||",
                "Suriname": "b6t,0,6k|||",
                "Syowa": "b6u,0,6k|||",
                "Tahiti": "b6v,0,6k|||",
                "Taipei": "807,0,e7,0,6k|807,0,ej,0,6k||",
                "Tajikistan": "b71,0,6k|||",
                "Tokelau": "b72,0,6k|||",
                "Tonga": "2ho,0,e7,0,6k|2ho,0,ej,0,6k||",
                "Truk": "b73,0,6k|||",
                "Turkmenistan": "804,0,e7,0,6k|804,0,ej,0,6k||",
                "Tuvalu": "b75,0,6k|||",
                "Uruguay": "803,0,e7,0,6k|803,0,ej,0,6k||",
                "Uzbekistan": "7vu,0,e7,0,6k|7vu,0,ej,0,6k||",
                "Vanuatu": "7vt,0,e7,0,6k|7vt,0,ej,0,6k||",
                "Venezuela": "b79,0,6k|||",
                "Vladivostok": "7v0,0,e7,0,6k|7v0,0,ej,0,6k||",
                "Volgograd": "7uc,0,e7,0,6k|7uc,0,ej,0,6k||",
                "Vostok": "b7c,0,6k|||",
                "Wake": "b7d,0,2rl,0,6k|||",
                "Wallis": "b7e,0,38j,0,b7f,0,6k|||",
                "Yakutsk": "7ub,0,e7,0,6k|7ub,0,ej,0,6k||",
                "Yekaterinburg": "7tt,0,e7,0,6k|7tt,0,ej,0,6k||"
            }
        },
        "uk": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|79,0,fk,0,7d,0,qe,0,ce2||",
                "Europe/London": "|79,0,fk,0,7d,0,n2,0,ce3,0,ce4||"
            },
            "metazone": {
                "Acre": "295,0,7qr,k,0,4qm|295,0,7qr,k,0,4ql||",
                "Afghanistan": "79,0,7d,0,qe,0,b7o|||",
                "Africa_Central": "79,0,b7p,0,7d|||",
                "Africa_Eastern": "79,0,b7q,0,7d|||",
                "Africa_Southern": "79,0,b7r,0,7d|||",
                "Africa_Western": "79,0,7pr,0,fs,0,7d|79,0,7pr,0,fk,0,7d||",
                "Alaska": "79,0,fs,0,7d,0,ag,0,7p7|79,0,fk,0,7d,0,ag,0,7p7||",
                "Amazon": "79,0,fs,0,7d,0,ag,0,7of|79,0,fk,0,7d,0,ag,0,7of||",
                "America_Central": "79,0,1vj,0,7ob,0,fs,0,7d|79,0,1vj,0,7ob,0,fk,0,7d||",
                "America_Eastern": "79,0,1vj,0,2pf,0,fs,0,7d|79,0,1vj,0,2pf,0,fk,0,7d||",
                "America_Mountain": "79,0,1vj,0,7o6,0,fs,0,7d|79,0,1vj,0,7o6,0,fk,0,7d||",
                "America_Pacific": "79,0,1vj,0,39b,0,fs,0,7d|79,0,1vj,0,39b,0,fk,0,7d||",
                "Anadyr": "295,0,18u,k,0,4qm|295,0,18u,k,0,4ql||",
                "Apia": "79,0,fs,0,7d,0,qe,0,7o4|79,0,fk,0,7d,0,qe,0,7o4||",
                "Arabian": "79,0,7o3,0,fs,0,7d|79,0,7o3,0,fk,0,7d||",
                "Argentina": "79,0,fs,0,7o2,0,7d|79,0,fk,0,7o2,0,7d||",
                "Argentina_Western": "79,0,fs,0,7nt,0,7d|79,0,fk,0,79,0,7nt,0,7d||",
                "Armenia": "79,0,7nj,0,fs,0,7d|79,0,7nj,0,fk,0,7d||",
                "Atlantic": "79,0,7nh,0,fs,0,7d|79,0,7nh,0,fk,0,7d||",
                "Australia_Central": "79,0,fs,0,7nd,0,7d|79,0,fk,0,7nd,0,7d||",
                "Australia_CentralWestern": "79,0,fs,0,7n7,0,7n5,0,7d|79,0,fk,0,7n7,0,7n5,0,7d||",
                "Australia_Eastern": "79,0,fs,0,7n1,0,7d|79,0,fk,0,7n1,0,7d||",
                "Australia_Western": "79,0,fs,0,7ld,0,7d|79,0,fk,0,7ld,0,7d||",
                "Azerbaijan": "79,0,fs,0,7d,0,qe,0,7l9|79,0,fk,0,7d,0,qe,0,7l9||",
                "Azores": "79,0,fs,0,7d,0,ag,0,7l5,0,1q9|79,0,fk,0,7d,0,ag,0,7l5,0,1q9||",
                "Bangladesh": "79,0,fs,0,7d,0,n2,0,q8|79,0,fk,0,7d,0,n2,0,q8||",
                "Bhutan": "79,0,7d,0,n2,0,b8l|||",
                "Bolivia": "79,0,b8m,0,7d|||",
                "Brasilia": "79,0,fs,0,7kg,0,7d|79,0,fk,0,7kg,0,7d||",
                "Brunei": "79,0,7d,0,n2,0,b8o|||",
                "Cape_Verde": "79,0,fs,0,7d,0,ag,0,129,0,12p,5,115|79,0,fk,0,7d,0,ag,0,129,0,12p,5,115||",
                "Chamorro": "79,0,7d,0,ag,0,b8q,0,b8r,0,129|||",
                "Chatham": "79,0,fs,0,7d,0,ag,0,39i,0,162|79,0,fk,0,7d,0,ag,0,39i,0,162||",
                "Chile": "79,0,fs,0,7kb,0,7d|79,0,fk,0,7kb,0,7d||",
                "China": "79,0,7jq,0,fs,0,7d|79,0,7jq,0,fk,0,7d||",
                "Choibalsan": "79,0,fs,0,7d,0,n2,0,11e|79,0,fk,0,7d,0,n2,0,11e||",
                "Christmas": "79,0,7d,0,ag,0,126,0,b90|||",
                "Cocos": "79,0,7d,0,ag,0,b91,0,1q9|||",
                "Colombia": "79,0,fs,0,7im,0,7d|79,0,fk,0,7im,0,7d||",
                "Cook": "79,0,fs,0,7d,0,ag,0,1q9,0,17u|79,0,fk,0,7d,0,ag,0,1q9,0,17u||",
                "Cuba": "79,0,fs,0,7d,0,ag,0,7ii|79,0,fk,0,7d,0,ag,0,7ii||",
                "Davis": "79,0,7d,0,n2,0,b94|||",
                "DumontDUrville": "79,0,7d,0,n2,0,1gu,0,b95|||",
                "East_Timor": "79,0,7d,0,n2,0,b96,0,b97|||",
                "Easter": "79,0,fs,0,7d,0,ag,0,126,0,170|79,0,fk,0,7d,0,ag,0,126,0,170||",
                "Ecuador": "79,0,7d,0,qe,0,b98|||",
                "Europe_Central": "79,0,7ih,0,fs,0,7d|79,0,7ih,0,fk,0,7d||",
                "Europe_Eastern": "79,0,7ic,0,fs,0,7d|79,0,7ic,0,fk,0,7d||",
                "Europe_Further_Eastern": "79,0,b9b,0,b9c,0,7d|||",
                "Europe_Western": "79,0,7i8,0,fs,0,7d|79,0,7i8,0,fk,0,7d||",
                "Falkland": "79,0,fs,0,7d,0,ag,0,7i5,0,1q9|79,0,fk,0,7d,0,ag,0,7i5,0,1q9||",
                "Fiji": "79,0,fs,0,7d,0,ag,0,7i4|79,0,fk,0,7d,0,ag,0,7i4||",
                "French_Guiana": "79,0,7d,0,b9g,0,b9h|||",
                "French_Southern": "79,0,7d,0,ag,0,b9i,0,b9j,0,1tt,0,b9k,0,b9l|||",
                "Galapagos": "79,0,7d,0,ag,0,b9m,0,129|||",
                "Gambier": "79,0,7d,0,ag,0,126,0,b9n|||",
                "Georgia": "79,0,fs,0,7d,0,n2,0,7i1|79,0,fk,0,7d,0,n2,0,7i1||",
                "Gilbert_Islands": "79,0,7d,0,ag,0,129,0,8bk|||",
                "GMT": "79,0,b9p|||",
                "Greenland_Eastern": "79,0,fs,0,2pf,0,7d,0,n2,0,39r|79,0,fk,0,2pf,0,7d,0,n2,0,39r||",
                "Greenland_Western": "79,0,fs,0,4q3,0,7d,0,n2,0,39r|79,0,fk,0,4q3,0,7d,0,n2,0,39r||",
                "Gulf": "79,0,7d,0,b9s,0,b9t|||",
                "Guyana": "79,0,7d,0,n2,0,b9u|||",
                "Hawaii_Aleutian": "79,0,fs,0,7hj,5,7hd,0,7d|79,0,fk,0,7hj,5,7hd,0,7d||",
                "Hong_Kong": "79,0,fs,0,7d,0,n2,0,7hb|79,0,fk,0,7d,0,n2,0,7hb||",
                "Hovd": "79,0,fs,0,7d,0,n2,0,7ha|79,0,fk,0,7d,0,n2,0,7ha||",
                "India": "79,0,ba3,0,fs,0,7d|||",
                "Indian_Ocean": "79,0,7d,0,qe,0,ba4,0,ba5|||",
                "Indochina": "79,0,7d,0,qe,0,ba6|||",
                "Indonesia_Central": "79,0,ba7,0,7d|||",
                "Indonesia_Eastern": "79,0,ba8,0,7d|||",
                "Indonesia_Western": "79,0,ba9,0,7d|||",
                "Iran": "79,0,7h6,0,fs,0,7d|79,0,7h6,0,fk,0,7d||",
                "Irkutsk": "79,0,7h5,0,fs,0,7d|79,0,7h5,0,fk,0,7d||",
                "Israel": "79,0,7h3,0,fs,0,7d|79,0,7h3,0,fk,0,7d||",
                "Japan": "79,0,7gs,0,fs,0,7d|79,0,7gs,0,fk,0,7d||",
                "Kamchatka": "79,0,7gl,0,fs,0,7d|79,0,7gl,0,fk,0,7d||",
                "Kazakhstan_Eastern": "79,0,2pf,0,7d,0,n2,0,7gi|||",
                "Kazakhstan_Western": "79,0,4q3,0,7d,0,n2,0,7gi|||",
                "Korea": "79,0,7gh,0,fs,0,7d|79,0,7gh,0,fk,0,7d||",
                "Kosrae": "79,0,7d,0,ag,0,126,0,1qi|||",
                "Krasnoyarsk": "79,0,7ge,0,fs,0,7d|79,0,7ge,0,fk,0,7d||",
                "Kyrgystan": "79,0,7d,0,n2,0,bai|||",
                "Lanka": "295,0,1et|||",
                "Line_Islands": "79,0,7d,0,ag,0,126,0,1eo|||",
                "Lord_Howe": "79,0,fs,0,7d,0,ag,0,126,0,oi,5,10a|79,0,fk,0,7d,0,ag,0,126,0,oi,5,10a||",
                "Macquarie": "79,0,7d,0,ag,0,126,0,baj|||",
                "Magadan": "79,0,7gd,0,fs,0,7d|79,0,7gd,0,fk,0,7d||",
                "Malaysia": "79,0,7d,0,n2,0,bal|||",
                "Maldives": "79,0,7d,0,ag,0,bam|||",
                "Marquesas": "79,0,7d,0,ag,0,ban,0,129|||",
                "Marshall_Islands": "79,0,7d,0,ag,0,bao,0,1q9|||",
                "Mauritius": "79,0,fs,0,7d,0,ag,0,126,0,7gb|79,0,fk,0,7d,0,ag,0,126,0,7gb||",
                "Mawson": "79,0,7d,0,ag,0,3a0,0,1lt|||",
                "Mexico_Northwest": "79,0,fs,0,7en,0,7d,0,n2,0,3a4|79,0,fk,0,7en,0,7d,0,n2,0,3a4||",
                "Mexico_Pacific": "79,0,fs,0,39b,0,7d,0,n2,0,3a4|79,0,fk,0,39b,0,7d,0,n2,0,3a4||",
                "Mongolia": "79,0,fs,0,7d,0,qe,0,or,5,7e9|79,0,fk,0,7d,0,qe,0,or,5,7e9||",
                "Moscow": "79,0,7e8,0,fs,0,7d|79,0,7e8,0,fk,0,7d||",
                "Myanmar": "79,0,7d,0,n2,0,bav|||",
                "Nauru": "79,0,7d,0,ag,0,126,0,14s|||",
                "Nepal": "79,0,7d,0,n2,0,bb0|||",
                "New_Caledonia": "79,0,fs,0,7d,0,ag,0,129,0,7dl,0,7dh|79,0,fk,0,7d,0,ag,0,129,0,7dl,0,7dh||",
                "New_Zealand": "79,0,fs,0,7d,0,n2,0,7dg,0,7de|79,0,fk,0,7d,0,n2,0,7dg,0,7de||",
                "Newfoundland": "79,0,fs,0,7d,0,ag,0,126,0,14t|79,0,fk,0,7d,0,n2,0,14t||",
                "Niue": "79,0,7d,0,ag,0,126,0,bb5|||",
                "Norfolk": "79,0,7d,0,ag,0,126,0,146|||",
                "Noronha": "79,0,fs,0,7d,0,ag,0,39i,0,141,5,7dd,5,132|79,0,fk,0,7d,0,ag,0,39i,0,141,5,7dd,5,132||",
                "Novosibirsk": "79,0,7dc,0,fs,0,7d|79,0,7dc,0,fk,0,7d||",
                "Omsk": "79,0,7db,0,fs,0,7d|79,0,7db,0,fk,0,7d||",
                "Pakistan": "79,0,fs,0,7d,0,n2,0,7da|79,0,fk,0,7d,0,n2,0,7da||",
                "Palau": "79,0,7d,0,ag,0,126,0,159|||",
                "Papua_New_Guinea": "79,0,7d,0,ag,0,129,0,13k,0,125,0,bba|||",
                "Paraguay": "79,0,fs,0,7d6,0,7d|79,0,fk,0,7d6,0,7d||",
                "Peru": "79,0,fs,0,7d,0,n2,0,q1|79,0,fk,0,7d,0,n2,0,q1||",
                "Philippines": "79,0,fs,0,7d,0,ag,0,7d3|79,0,fk,0,7d,0,ag,0,7d3||",
                "Phoenix_Islands": "79,0,7d,0,ag,0,129,0,530|||",
                "Pierre_Miquelon": "79,0,fs,0,7d,0,ag,0,129,0,pr,5,7cv,0,1tt,0,3q6|79,0,fk,0,7d,0,ag,0,129,0,pr,5,7cv,0,1tt,0,3q6||",
                "Pitcairn": "79,0,7d,0,ag,0,129,0,bbe|||",
                "Ponape": "79,0,7d,0,ag,0,126,0,1v2|||",
                "Pyongyang": "79,0,7d,0,n2,0,bbf|||",
                "Qyzylorda": "295,0,1cj,k,0,4qm|295,0,1cj,k,0,4ql||",
                "Reunion": "79,0,7d,0,ag,0,126,0,bbg|||",
                "Rothera": "79,0,7d,0,ag,0,3a0,0,157|||",
                "Sakhalin": "79,0,7cu,0,fs,0,7d|79,0,7cu,0,fk,0,7d||",
                "Samara": "79,0,7cr,0,fs,0,7d|79,0,7cr,0,fk,0,7d||",
                "Samoa": "79,0,fs,0,7d,0,ag,0,126,0,p0|79,0,fk,0,7d,0,ag,0,126,0,p0||",
                "Seychelles": "79,0,7d,0,ag,0,bbj,0,1q9|||",
                "Singapore": "79,0,7d,0,n2,0,bbk|||",
                "Solomon": "79,0,7d,0,ag,0,bbl,0,129|||",
                "South_Georgia": "79,0,7d,0,ag,0,126,0,bbm,0,bbn|||",
                "Suriname": "79,0,7d,0,n2,0,bbo|||",
                "Syowa": "79,0,7d,0,ag,0,3a0,0,bbp|||",
                "Tahiti": "79,0,7d,0,ag,0,126,0,bbq|||",
                "Taipei": "79,0,fs,0,7d,0,n2,0,7cp|79,0,fk,0,7d,0,n2,0,7cp||",
                "Tajikistan": "79,0,7d,0,n2,0,bbs|||",
                "Tokelau": "79,0,7d,0,ag,0,129,0,156|||",
                "Tonga": "79,0,fs,0,7d,0,ag,0,129,0,ot|79,0,fk,0,7d,0,ag,0,129,0,ot||",
                "Truk": "79,0,7d,0,ag,0,129,0,1f2|||",
                "Turkmenistan": "79,0,fs,0,7d,0,n2,0,7c7|79,0,fk,0,7d,0,n2,0,7c7||",
                "Tuvalu": "79,0,7d,0,ag,0,129,0,14j|||",
                "Uruguay": "79,0,fs,0,7d,0,qe,0,7c5|79,0,fk,0,7d,0,qe,0,7c5||",
                "Uzbekistan": "79,0,fs,0,7d,0,qe,0,7bs|79,0,fk,0,7d,0,qe,0,7bs||",
                "Vanuatu": "79,0,fs,0,7d,0,ag,0,129,0,os|79,0,fk,0,7d,0,ag,0,129,0,os||",
                "Venezuela": "79,0,7d,0,n2,0,bc0|||",
                "Vladivostok": "79,0,7bq,0,fs,0,7d|79,0,7bq,0,fk,0,7d||",
                "Volgograd": "79,0,7bn,0,fs,0,7d|79,0,7bn,0,fk,0,7d||",
                "Vostok": "79,0,7d,0,ag,0,3a0,0,155|||",
                "Wake": "79,0,7d,0,ag,0,126,0,84k|||",
                "Wallis": "79,0,7d,0,ag,0,129,0,bc3,0,1tt,0,14l|||",
                "Yakutsk": "79,0,7bl,0,fs,0,7d|79,0,7bl,0,fk,0,7d||",
                "Yekaterinburg": "79,0,7bk,0,fs,0,7d|79,0,7bk,0,fk,0,7d||"
            }
        },
        "ur-IN": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6m5,0,i9,0,3g||",
                "Europe/London": "|6af,0,gg,0,3g||"
            },
            "metazone": {
                "Afghanistan": "3ki,0,3g|||",
                "Africa_Central": "205,0,1lb,0,3g|||",
                "Africa_Eastern": "10l,0,1lb,0,3g|||",
                "Africa_Southern": "1ot,0,1lb,0,fj,0,3g|||",
                "Africa_Western": "ru,0,1lb,0,fj,0,3g|ru,0,1lb,0,gg,0,3g||",
                "Alaska": "3ac,0,i9,0,3g|3ac,0,ki,0,kj,0,3g||",
                "Amazon": "4pn,0,fj,0,3g|4pn,0,gg,0,3g||",
                "America_Central": "1jr,0,i9,0,3g|1jr,0,ki,0,kj,0,3g||",
                "America_Eastern": "20b,0,i9,0,3g|20b,0,ki,0,kj,0,3g||",
                "America_Mountain": "3al,0,i9,0,3g|3al,0,ki,0,kj,0,3g||",
                "America_Pacific": "20e,0,i9,0,3g|20e,0,ki,0,kj,0,3g||",
                "Anadyr": "3am,0,i9,0,3g|3am,0,gg,0,3g||",
                "Apia": "3an,0,fj,0,3g|3an,0,ki,0,kj,0,3g||",
                "Arabian": "3ap,0,fj,0,3g|3ap,0,ki,0,kj,0,3g||",
                "Argentina": "20i,0,fj,0,3g|20i,0,gg,0,3g||",
                "Argentina_Western": "ru,0,20i,0,fj,0,3g|ru,0,20i,0,gg,0,3g||",
                "Armenia": "3aq,0,fj,0,3g|3aq,0,gg,0,3g||",
                "Atlantic": "3ar,0,i9,0,3g|3ar,0,ki,0,kj,0,3g||",
                "Australia_Central": "1cv,0,1jr,0,i9,0,3g|1cv,0,1jr,0,ki,0,kj,0,3g||",
                "Australia_CentralWestern": "1cv,0,1jr,0,20m,0,i9,0,3g|1cv,0,1jr,0,20m,0,ki,0,kj,0,3g||",
                "Australia_Eastern": "1cv,0,20b,0,i9,0,3g|1cv,0,20b,0,ki,0,kj,0,3g||",
                "Australia_Western": "7a5,0,20m,0,i9,0,3g|1cv,0,20m,0,ki,0,kj,0,3g||",
                "Azerbaijan": "3av,0,fj,0,3g|3av,0,gg,0,3g||",
                "Azores": "3b4,0,c3,0,rd,0,1n|3b4,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Bangladesh": "3b9,0,3ba,0,fj,0,3g|3b9,0,3ba,0,gg,0,3g||",
                "Bhutan": "780,0,3g|||",
                "Bolivia": "77n,0,3g|||",
                "Brasilia": "20q,0,fj,0,3g|20q,0,gg,0,3g||",
                "Brunei": "3jr,0,3jq,0,3g|||",
                "Cape_Verde": "2es,0,3bb,0,fj,0,3g|2es,0,3bb,0,gg,0,3g||",
                "Chamorro": "3ji,0,fj,0,3g|||",
                "Chatham": "3bf,0,i9,0,3g|3bf,0,ki,0,kj,0,3g||",
                "Chile": "3bg,0,fj,0,3g|3bg,0,gg,0,3g||",
                "China": "211,0,fj,0,3g|76t,0,ki,0,kj,0,3g||",
                "Choibalsan": "76g,0,fj,0,3g|747,0,gg,0,3g||",
                "Christmas": "742,0,1jd,0,3g|||",
                "Cocos": "3jb,0,13q,0,3g|||",
                "Colombia": "3bh,0,fj,0,3g|3bh,0,gg,0,3g||",
                "Cook": "3bi,0,13q,0,fj,0,3g|3bi,0,13q,0,734,0,gg,0,3g||",
                "Cuba": "3bj,0,i9,0,3g|3bj,0,ki,0,kj,0,3g||",
                "Davis": "72p,0,3g|||",
                "DumontDUrville": "728,5,726,0,3g|||",
                "East_Timor": "10l,0,3j3,0,3g|||",
                "Easter": "3bm,0,1jd,0,fj,0,3g|3bm,0,1jd,0,gg,0,3g||",
                "Ecuador": "71o,0,3g|||",
                "Europe_Central": "205,0,1jc,0,c3,0,rd,0,1n|205,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Europe_Eastern": "10l,0,1jc,0,c3,0,rd,0,1n|10l,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Europe_Further_Eastern": "71i,0,10l,0,71g,0,1n|||",
                "Europe_Western": "ru,0,1jc,0,c3,0,rd,0,1n|ru,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Falkland": "3bu,0,14p,0,13q,0,fj,0,3g|3bu,0,14p,0,13q,0,gg,0,3g||",
                "Fiji": "3bv,0,fj,0,3g|3bv,0,gg,0,3g||",
                "French_Guiana": "3c3,0,3c5,0,3g|||",
                "French_Southern": "3c3,0,1ot,0,22o,0,6t9,0,3g|||",
                "Galapagos": "6t8,0,3g|||",
                "Gambier": "6rk,0,3g|||",
                "Georgia": "2g3,0,fj,0,3g|2g3,0,gg,0,3g||",
                "Gilbert_Islands": "6qn,0,13q,0,3g|||",
                "GMT": "1n0,0,6qe,0,be9,0,3g|||",
                "Greenland_Eastern": "10l,0,1n0,0,14p,0,i9,0,3g|10l,0,1n0,0,14p,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Greenland_Western": "ru,0,1n0,0,14p,0,i9,0,3g|ru,0,1n0,0,14p,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Gulf": "3ii,0,fj,0,3g|||",
                "Guyana": "3c5,0,3g|||",
                "Hawaii_Aleutian": "3c8,0,3c9,0,i9,0,3g|3c8,0,3c9,0,ki,0,kj,0,3g||",
                "Hong_Kong": "3ca,0,3cb,0,fj,0,3g|3ca,0,3cb,0,gg,0,3g||",
                "Hovd": "3cc,0,fj,0,3g|3cc,0,gg,0,3g||",
                "India": "bef,0,fj,0,3g|||",
                "Indian_Ocean": "6q5,0,3cd,0,3g|||",
                "Indochina": "3cd,0,211,0,3g|||",
                "Indonesia_Central": "205,0,2f5,0,3g|||",
                "Indonesia_Eastern": "10l,0,2f5,0,3g|||",
                "Indonesia_Western": "ru,0,2f5,0,3g|||",
                "Iran": "21q,0,fj,0,3g|21q,0,ki,0,kj,0,3g||",
                "Irkutsk": "3dq,0,fj,0,3g|3dq,0,gg,0,3g||",
                "Israel": "21s,0,fj,0,3g|21s,0,ki,0,kj,0,3g||",
                "Japan": "3dt,0,fj,0,3g|3dt,0,ki,0,kj,0,3g||",
                "Kamchatka": "3e0,5,3e3,0,i9,0,3g|3e0,5,3e3,0,gg,0,3g||",
                "Kazakhstan_Eastern": "10l,0,3e6,0,3g|||",
                "Kazakhstan_Western": "ru,0,3e6,0,3g|||",
                "Korea": "3e7,0,fj,0,3g|3e7,0,ki,0,kj,0,3g||",
                "Kosrae": "6ft,0,3g|||",
                "Krasnoyarsk": "6fs,0,fj,0,3g|6f9,0,gg,0,3g||",
                "Kyrgystan": "6f8,0,3g|||",
                "Line_Islands": "6f6,0,13q,0,3g|||",
                "Lord_Howe": "3e9,0,3ea,0,i9,0,3g|3e9,0,3ea,0,ki,0,kj,0,3g||",
                "Macquarie": "6eu,0,1jd,0,3g|||",
                "Magadan": "6es,0,i9,0,3g|6eq,0,gg,0,3g||",
                "Malaysia": "6ep,0,3g|||",
                "Maldives": "6en,0,3g|||",
                "Marquesas": "6em,0,3g|||",
                "Marshall_Islands": "6el,0,13q,0,3g|||",
                "Mauritius": "3eb,0,fj,0,3g|3eb,0,gg,0,3g||",
                "Mawson": "6ej,0,3g|||",
                "Mexico_Northwest": "225,0,ru,0,3ec,0,i9,0,3g|225,0,ru,0,3ec,0,ki,0,kj,0,3g||",
                "Mexico_Pacific": "3ed,0,20e,0,i9,0,3g|3ed,0,20e,0,ki,0,kj,0,3g||",
                "Mongolia": "3ee,0,3ef,0,fj,0,3g|3ee,0,3ef,0,gg,0,3g||",
                "Moscow": "2eo,0,i9,0,3g|2eo,0,gg,0,3g||",
                "Myanmar": "3hc,0,3g|||",
                "Nauru": "6ea,0,3g|||",
                "Nepal": "6e8,0,3g|||",
                "New_Caledonia": "1ob,0,3eg,0,fj,0,3g|1ob,0,3eg,0,gg,0,3g||",
                "New_Zealand": "3eh,0,14p,0,fj,0,3g|3eh,0,14p,0,ki,0,kj,0,3g||",
                "Newfoundland": "1ob,0,3ei,0,14p,0,i9,0,3g|1ob,0,3ei,0,14p,0,ki,0,kj,0,3g||",
                "Niue": "6dr,0,3g|||",
                "Norfolk": "6dp,0,1jd,0,c3,0,1n|||",
                "Noronha": "3ej,0,2u7,0,6dj,0,fj,0,3g|3ej,0,2u7,0,447,0,gg,0,3g||",
                "Novosibirsk": "3ek,0,fj,0,3g|3ek,0,gg,0,3g||",
                "Omsk": "22h,0,fj,0,3g|22h,0,gg,0,3g||",
                "Pakistan": "22i,0,fj,0,3g|22i,0,gg,0,3g||",
                "Palau": "6dh,0,3g|||",
                "Papua_New_Guinea": "6dc,0,1ob,0,6da,0,3g|||",
                "Paraguay": "3em,0,fj,0,3g|3em,0,gg,0,3g||",
                "Peru": "3en,0,fj,0,3g|3en,0,gg,0,3g||",
                "Philippines": "3eo,0,fj,0,3g|3eo,0,gg,0,3g||",
                "Phoenix_Islands": "6d5,0,13q,0,3g|||",
                "Pierre_Miquelon": "3ep,0,3er,0,22o,0,3es,0,i9,0,3g|3ep,0,3er,0,22o,0,3es,0,ki,0,kj,0,3g||",
                "Pitcairn": "6cu,0,3g|||",
                "Ponape": "6cl,0,3g|||",
                "Pyongyang": "6ck,0,6ce,0,1n|||",
                "Reunion": "6cd,0,6cc,0,3g|||",
                "Rothera": "6ca,0,3g|||",
                "Sakhalin": "3f0,0,fj,0,3g|3f0,0,gg,0,3g||",
                "Samara": "if,0,i9,0,3g|if,0,gg,0,3g||",
                "Samoa": "3f2,0,fj,0,3g|3f2,0,ki,0,kj,0,3g||",
                "Seychelles": "6c6,0,3g|||",
                "Singapore": "3g0,0,fj,0,3g|||",
                "Solomon": "6c5,0,13q,0,3g|||",
                "South_Georgia": "1ot,0,2g3,0,3g|||",
                "Suriname": "3fv,0,3g|||",
                "Syowa": "4is,0,3g|||",
                "Tahiti": "6c4,0,3g|||",
                "Taipei": "6c3,0,3f4,0,i9,0,3g|6bu,0,3f4,0,ki,0,kj,0,3g||",
                "Tajikistan": "6bs,0,3g|||",
                "Tokelau": "6bq,0,3g|||",
                "Tonga": "3f5,0,fj,0,3g|3f5,0,gg,0,3g||",
                "Truk": "6bo,0,3g|||",
                "Turkmenistan": "3f7,0,fj,0,3g|3f7,0,gg,0,3g||",
                "Tuvalu": "6bj,0,3g|||",
                "Uruguay": "3f8,0,fj,0,3g|3f8,0,gg,0,3g||",
                "Uzbekistan": "231,0,fj,0,3g|231,0,gg,0,3g||",
                "Vanuatu": "3fb,0,fj,0,3g|3fb,0,gg,0,3g||",
                "Venezuela": "6ba,0,3g|||",
                "Vladivostok": "3fd,0,3ff,0,fj,0,3g|3fd,0,3ff,0,gg,0,3g||",
                "Volgograd": "3fk,0,i9,0,3g|3fk,0,gg,0,3g||",
                "Vostok": "62o,0,3g|||",
                "Wake": "2pn,0,1jd,0,3g|||",
                "Wallis": "62n,0,22o,0,62k,0,3g|||",
                "Yakutsk": "3kj,0,i9,0,3g|3kj,0,gg,0,3g||",
                "Yekaterinburg": "3km,0,i9,0,3g|3km,0,gg,0,3g||"
            }
        },
        "ur": {
            "gmtFormat": "GMT {0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6m5,0,i9,0,3g||",
                "Europe/London": "|6af,0,gg,0,3g||"
            },
            "metazone": {
                "Afghanistan": "3ki,0,c3,0,1n|||",
                "Africa_Central": "205,0,1lb,0,3g|||",
                "Africa_Eastern": "10l,0,1lb,0,3g|||",
                "Africa_Southern": "1ot,0,1lb,0,fj,0,3g|||",
                "Africa_Western": "ru,0,1lb,0,fj,0,3g|ru,0,1lb,0,gg,0,3g||",
                "Alaska": "3ac,0,i9,0,3g|3ac,0,ki,0,kj,0,3g||",
                "Amazon": "4pn,0,fj,0,3g|bh2,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "America_Central": "1jr,0,i9,0,3g|1jr,0,ki,0,kj,0,3g||",
                "America_Eastern": "20b,0,i9,0,3g|20b,0,ki,0,kj,0,3g||",
                "America_Mountain": "3al,0,i9,0,3g|3al,0,ki,0,kj,0,3g||",
                "America_Pacific": "20e,0,i9,0,3g|20e,0,ki,0,kj,0,3g||",
                "Anadyr": "3am,0,i9,0,3g|3am,0,gg,0,3g||",
                "Apia": "3an,0,fj,0,3g|3an,0,ki,0,kj,0,3g||",
                "Arabian": "3ap,0,c3,0,rd,0,1n|3ap,0,ki,0,kj,0,3g||",
                "Argentina": "20i,0,fj,0,3g|20i,0,gg,0,3g||",
                "Argentina_Western": "ru,0,20i,0,c3,0,rd,0,1n|ru,0,20i,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Armenia": "3aq,0,c3,0,rd,0,1n|3aq,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Atlantic": "3ar,0,i9,0,3g|3ar,0,ki,0,kj,0,3g||",
                "Australia_Central": "1cv,0,1jr,0,i9,0,3g|1cv,0,1jr,0,ki,0,kj,0,3g||",
                "Australia_CentralWestern": "1cv,0,1jr,0,20m,0,i9,0,3g|1cv,0,1jr,0,20m,0,ki,0,kj,0,3g||",
                "Australia_Eastern": "1cv,0,20b,0,i9,0,3g|1cv,0,20b,0,ki,0,kj,0,3g||",
                "Australia_Western": "7a5,0,20m,0,i9,0,3g|1cv,0,20m,0,ki,0,kj,0,3g||",
                "Azerbaijan": "3av,0,c3,0,rd,0,1n|3av,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Azores": "3b4,0,c3,0,rd,0,1n|3b4,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Bangladesh": "3b9,0,3ba,0,c3,0,rd,0,1n|3b9,0,3ba,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Bhutan": "780,0,c3,0,1n|||",
                "Bolivia": "77n,0,c3,0,1n|||",
                "Brasilia": "20q,0,i9,0,3g|20q,0,gg,0,3g||",
                "Brunei": "3jr,0,3jq,0,3g|||",
                "Cape_Verde": "2es,0,3bb,0,fj,0,3g|2es,0,3bb,0,gg,0,3g||",
                "Chamorro": "3ji,0,fj,0,3g|||",
                "Chatham": "3bf,0,i9,0,3g|3bf,0,ki,0,kj,0,3g||",
                "Chile": "3bg,0,c3,0,rd,0,1n|3bg,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "China": "211,0,fj,0,3g|76t,0,ki,0,kj,0,3g||",
                "Choibalsan": "76g,0,fj,0,3g|747,0,gg,0,3g||",
                "Christmas": "742,0,1jd,0,3g|||",
                "Cocos": "3jb,0,13q,0,3g|||",
                "Colombia": "3bh,0,c3,0,rd,0,1n|3bh,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Cook": "3bi,0,13q,0,fj,0,3g|3bi,0,13q,0,734,0,gg,0,3g||",
                "Cuba": "3bj,0,i9,0,3g|3bj,0,ki,0,kj,0,3g||",
                "Davis": "72p,0,3g|||",
                "DumontDUrville": "728,5,726,0,3g|||",
                "East_Timor": "10l,0,3j3,0,3g|||",
                "Easter": "3bm,0,1jd,0,c3,0,rd,0,1n|3bm,0,1jd,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Ecuador": "71o,0,c3,0,1n|||",
                "Europe_Central": "205,0,1jc,0,c3,0,rd,0,1n|205,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Europe_Eastern": "10l,0,1jc,0,c3,0,rd,0,1n|10l,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Europe_Further_Eastern": "71i,0,10l,0,71g,0,1n|||",
                "Europe_Western": "ru,0,1jc,0,c3,0,rd,0,1n|ru,0,1jc,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Falkland": "3bu,0,14p,0,13q,0,c3,0,rd,0,1n|3bu,0,14p,0,13q,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Fiji": "3bv,0,fj,0,3g|3bv,0,gg,0,3g||",
                "French_Guiana": "3c3,0,3c5,0,c3,0,1n|||",
                "French_Southern": "3c3,0,1ot,0,22o,0,6t9,0,3g|||",
                "Galapagos": "6t8,0,c3,0,1n|||",
                "Gambier": "6rk,0,3g|||",
                "Georgia": "2g3,0,c3,0,rd,0,1n|2g3,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Gilbert_Islands": "6qn,0,13q,0,3g|||",
                "GMT": "1n0,0,6qe,0,c3,0,bh3,0,1n|||",
                "Greenland_Eastern": "10l,0,1n0,0,14p,0,i9,0,3g|10l,0,1n0,0,14p,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Greenland_Western": "ru,0,1n0,0,14p,0,i9,0,3g|ru,0,1n0,0,14p,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Gulf": "3ii,0,c3,0,rd,0,1n|||",
                "Guyana": "3c5,0,c3,0,1n|||",
                "Hawaii_Aleutian": "3c8,0,3c9,0,i9,0,3g|3c8,0,3c9,0,ki,0,kj,0,3g||",
                "Hong_Kong": "3ca,0,3cb,0,fj,0,3g|3ca,0,3cb,0,gg,0,3g||",
                "Hovd": "3cc,0,fj,0,3g|3cc,0,gg,0,3g||",
                "India": "bh4,0,c3,0,rd,0,1n|||",
                "Indian_Ocean": "6q5,0,3cd,0,3g|||",
                "Indochina": "3cd,0,211,0,3g|||",
                "Indonesia_Central": "205,0,2f5,0,3g|||",
                "Indonesia_Eastern": "10l,0,2f5,0,3g|||",
                "Indonesia_Western": "ru,0,2f5,0,3g|||",
                "Iran": "21q,0,c3,0,rd,0,1n|21q,0,ki,0,kj,0,3g||",
                "Irkutsk": "3dq,0,fj,0,3g|3dq,0,gg,0,3g||",
                "Israel": "21s,0,c3,0,rd,0,1n|21s,0,ki,0,kj,0,3g||",
                "Japan": "3dt,0,fj,0,3g|3dt,0,ki,0,kj,0,3g||",
                "Kamchatka": "3e0,5,3e3,0,i9,0,3g|3e0,5,3e3,0,gg,0,3g||",
                "Kazakhstan_Eastern": "10l,0,3e6,0,c3,0,1n|||",
                "Kazakhstan_Western": "ru,0,3e6,0,c3,0,1n|||",
                "Korea": "3e7,0,fj,0,3g|3e7,0,ki,0,kj,0,3g||",
                "Kosrae": "6ft,0,3g|||",
                "Krasnoyarsk": "6fs,0,fj,0,3g|6f9,0,gg,0,3g||",
                "Kyrgystan": "6f8,0,c3,0,1n|||",
                "Line_Islands": "6f6,0,13q,0,3g|||",
                "Lord_Howe": "3e9,0,3ea,0,i9,0,3g|3e9,0,3ea,0,ki,0,kj,0,3g||",
                "Macquarie": "6eu,0,1jd,0,c3,0,1n|||",
                "Magadan": "6es,0,i9,0,3g|6eq,0,gg,0,3g||",
                "Malaysia": "6ep,0,3g|||",
                "Maldives": "6en,0,c3,0,1n|||",
                "Marquesas": "6em,0,3g|||",
                "Marshall_Islands": "6el,0,13q,0,3g|||",
                "Mauritius": "3eb,0,fj,0,3g|3eb,0,gg,0,3g||",
                "Mawson": "6ej,0,3g|||",
                "Mexico_Northwest": "225,0,ru,0,3ec,0,i9,0,3g|225,0,ru,0,3ec,0,ki,0,kj,0,3g||",
                "Mexico_Pacific": "3ed,0,20e,0,i9,0,3g|3ed,0,20e,0,ki,0,kj,0,3g||",
                "Mongolia": "3ee,0,3ef,0,fj,0,3g|3ee,0,3ef,0,gg,0,3g||",
                "Moscow": "2eo,0,i9,0,3g|2eo,0,gg,0,3g||",
                "Myanmar": "3hc,0,3g|||",
                "Nauru": "6ea,0,3g|||",
                "Nepal": "6e8,0,c3,0,1n|||",
                "New_Caledonia": "1ob,0,3eg,0,fj,0,3g|1ob,0,3eg,0,gg,0,3g||",
                "New_Zealand": "3eh,0,14p,0,c3,0,rd,0,1n|3eh,0,14p,0,ki,0,kj,0,3g||",
                "Newfoundland": "1ob,0,3ei,0,14p,0,i9,0,3g|1ob,0,3ei,0,14p,0,ki,0,kj,0,3g||",
                "Niue": "6dr,0,3g|||",
                "Norfolk": "6dp,0,1jd,0,c3,0,1n|||",
                "Noronha": "3ej,0,2u7,0,6dj,0,c3,0,rd,0,1n|3ej,0,2u7,0,447,0,gg,0,3g||",
                "Novosibirsk": "3ek,0,fj,0,3g|3ek,0,gg,0,3g||",
                "Omsk": "22h,0,fj,0,3g|22h,0,gg,0,3g||",
                "Pakistan": "22i,0,c3,0,rd,0,1n|22i,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Palau": "6dh,0,3g|||",
                "Papua_New_Guinea": "6dc,0,1ob,0,6da,0,3g|||",
                "Paraguay": "3em,0,c3,0,rd,0,1n|3em,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Peru": "3en,0,c3,0,rd,0,1n|3en,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Philippines": "3eo,0,fj,0,3g|3eo,0,gg,0,3g||",
                "Phoenix_Islands": "6d5,0,13q,0,3g|||",
                "Pierre_Miquelon": "3ep,0,3er,0,22o,0,3es,0,i9,0,3g|3ep,0,3er,0,22o,0,3es,0,ki,0,kj,0,3g||",
                "Pitcairn": "6cu,0,3g|||",
                "Ponape": "6cl,0,3g|||",
                "Pyongyang": "6ck,0,6ce,0,1n|||",
                "Reunion": "6cd,0,6cc,0,3g|||",
                "Rothera": "6ca,0,c3,0,1n|||",
                "Sakhalin": "3f0,0,fj,0,3g|3f0,0,gg,0,3g||",
                "Samara": "if,0,i9,0,3g|if,0,gg,0,3g||",
                "Samoa": "3f2,0,fj,0,3g|3f2,0,ki,0,kj,0,3g||",
                "Seychelles": "6c6,0,3g|||",
                "Singapore": "3g0,0,fj,0,3g|||",
                "Solomon": "6c5,0,13q,0,3g|||",
                "South_Georgia": "1ot,0,2g3,0,3g|||",
                "Suriname": "3fv,0,c3,0,1n|||",
                "Syowa": "4is,0,3g|||",
                "Tahiti": "6c4,0,3g|||",
                "Taipei": "6c3,0,3f4,0,i9,0,3g|6bu,0,3f4,0,ki,0,kj,0,3g||",
                "Tajikistan": "6bs,0,c3,0,1n|||",
                "Tokelau": "6bq,0,3g|||",
                "Tonga": "3f5,0,fj,0,3g|3f5,0,gg,0,3g||",
                "Truk": "6bo,0,3g|||",
                "Turkmenistan": "3f7,0,c3,0,rd,0,1n|3f7,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Tuvalu": "6bj,0,3g|||",
                "Uruguay": "3f8,0,c3,0,rd,0,1n|3f8,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Uzbekistan": "231,0,c3,0,rd,0,1n|231,0,c3,0,t6,0,t7,0,c3,0,1n||",
                "Vanuatu": "3fb,0,fj,0,3g|3fb,0,gg,0,3g||",
                "Venezuela": "6ba,0,c3,0,1n|||",
                "Vladivostok": "3fd,0,3ff,0,fj,0,3g|3fd,0,3ff,0,gg,0,3g||",
                "Volgograd": "3fk,0,i9,0,3g|3fk,0,gg,0,3g||",
                "Vostok": "62o,0,c3,0,1n|||",
                "Wake": "2pn,0,1jd,0,3g|||",
                "Wallis": "62n,0,22o,0,62k,0,3g|||",
                "Yakutsk": "3kj,0,i9,0,3g|3kj,0,gg,0,3g||",
                "Yekaterinburg": "3km,0,i9,0,3g|3km,0,gg,0,3g||"
            }
        },
        "uz-Arab": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "uz-Cyrl": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|1pi,0,md,0,82||",
                "Europe/London": "|5u9,0,md,0,82||"
            },
            "metazone": {
                "Afghanistan": "bh5,0,82|||",
                "Africa_Central": "201,0,mi,0,82|||",
                "Africa_Eastern": "1l7,0,mi,0,82|||",
                "Africa_Southern": "61r,0,mi,0,82|||",
                "Africa_Western": "1d9,0,mi,0,ab,0,82|1d9,0,mi,0,md,0,82||",
                "Alaska": "12l,0,ab,0,82|12l,0,10k,0,82||",
                "Amazon": "18e,0,ab,0,82|18e,0,md,0,82||",
                "America_Central": "1vs,0,m6,0,61l,0,ab,0,82|1vs,0,m6,0,61l,0,10k,0,82||",
                "America_Eastern": "1vs,0,m6,0,61h,0,ab,0,82|1vs,0,m6,0,61h,0,10k,0,82||",
                "America_Mountain": "1vs,0,m6,0,61g,0,ab,0,82|1vs,0,m6,0,61g,0,10k,0,82||",
                "America_Pacific": "1vs,0,m6,0,61f,0,4m2,0,ab,0,82|1vs,0,m6,0,61f,0,4m2,0,10k,0,82||",
                "Arabian": "619,0,ab,0,82|619,0,10k,0,82||",
                "Argentina": "hk,0,ab,0,82|hk,0,md,0,82||",
                "Argentina_Western": "1d9,0,hk,0,ab,0,82|1d9,0,hk,0,md,0,82||",
                "Armenia": "618,0,ab,0,82|618,0,md,0,82||",
                "Atlantic": "2fc,0,ab,0,82|2fc,0,10k,0,82||",
                "Australia_Central": "201,0,eo,0,ab,0,82|201,0,eo,0,10k,0,82||",
                "Australia_CentralWestern": "201,0,eo,0,1d9,0,ab,0,82|201,0,eo,0,1d9,0,10k,0,82||",
                "Australia_Eastern": "1l7,0,eo,0,ab,0,82|1l7,0,eo,0,10k,0,82||",
                "Australia_Western": "1d9,0,eo,0,ab,0,82|1d9,0,eo,0,10k,0,82||",
                "Azerbaijan": "613,0,ab,0,82|613,0,md,0,82||",
                "Azores": "30f,0,ab,0,82|30f,0,md,0,82||",
                "Bangladesh": "q8,0,ab,0,82|q8,0,md,0,82||",
                "Bhutan": "16f,0,82|||",
                "Bolivia": "1pu,0,82|||",
                "Brasilia": "15k,0,ab,0,82|15k,0,md,0,82||",
                "Brunei": "1gr,0,bhm,0,82|||",
                "Cape_Verde": "12p,5,115,0,ab,0,82|12p,5,115,0,md,0,82||",
                "Chamorro": "bhn,0,82|||",
                "Chatham": "60v,0,ab,0,82|60v,0,10k,0,82||",
                "Chile": "150,0,ab,0,82|150,0,md,0,82||",
                "China": "4m1,0,ab,0,82|4m1,0,10k,0,82||",
                "Choibalsan": "11e,0,ab,0,82|11e,0,md,0,82||",
                "Christmas": "4l3,0,2dk,0,82|||",
                "Cocos": "1le,0,1ks,0,82|||",
                "Colombia": "15d,0,ab,0,82|15d,0,md,0,82||",
                "Cook": "1p2,0,1ks,0,ab,0,82|1p2,0,1ks,0,bhs,0,md,0,82||",
                "Cuba": "ql,0,ab,0,82|ql,0,10k,0,82||",
                "Davis": "72u,0,82|||",
                "DumontDUrville": "bht,5,72v,5,730,0,82|||",
                "East_Timor": "1l7,0,1ki,0,82|||",
                "Easter": "170,0,2dk,0,ab,0,82|170,0,2dk,0,md,0,82||",
                "Ecuador": "1qk,0,82|||",
                "Europe_Central": "201,0,lo,0,ab,0,82|201,0,lo,0,md,0,82||",
                "Europe_Eastern": "1l7,0,lo,0,ab,0,82|1l7,0,lo,0,md,0,82||",
                "Europe_Western": "1d9,0,lo,0,ab,0,82|1d9,0,lo,0,md,0,82||",
                "Falkland": "60m,0,1ks,0,ab,0,82|60m,0,1ks,0,md,0,82||",
                "Fiji": "458,0,ab,0,82|458,0,md,0,82||",
                "French_Guiana": "307,0,bhv,0,82|||",
                "French_Southern": "307,0,bi0,0,hb,0,4lu,0,82|||",
                "Galapagos": "1na,0,82|||",
                "Gambier": "bi1,0,82|||",
                "Georgia": "15e,0,ab,0,82|15e,0,md,0,82||",
                "Gilbert_Islands": "1ne,0,1ks,0,82|||",
                "GMT": "4ht,0,82|||",
                "Greenland_Eastern": "1l7,0,pq,0,ab,0,82|1l7,0,pq,0,md,0,82||",
                "Greenland_Western": "1d9,0,pq,0,ab,0,82|1d9,0,pq,0,md,0,82||",
                "Gulf": "bi2,0,82|||",
                "Guyana": "1ql,0,82|||",
                "Hawaii_Aleutian": "45a,5,60l,0,ab,0,82|45a,5,60l,0,10k,0,82||",
                "Hong_Kong": "13t,0,ab,0,82|13t,0,md,0,82||",
                "Hovd": "qg,0,ab,0,82|qg,0,md,0,82||",
                "India": "bi4,0,82|||",
                "Indian_Ocean": "60k,0,4m2,0,82|||",
                "Indochina": "60k,5,4m1,0,82|||",
                "Indonesia_Central": "201,0,vo,0,82|||",
                "Indonesia_Eastern": "1l7,0,vo,0,82|||",
                "Indonesia_Western": "1d9,0,vo,0,82|||",
                "Iran": "60h,0,ab,0,82|60h,0,10k,0,82||",
                "Irkutsk": "12u,0,ab,0,82|12u,0,md,0,82||",
                "Israel": "60f,0,ab,0,82|60f,0,10k,0,82||",
                "Japan": "1bb,0,ab,0,82|1bb,0,10k,0,82||",
                "Kazakhstan_Eastern": "1l7,0,608,0,82|||",
                "Kazakhstan_Western": "1d9,0,608,0,82|||",
                "Korea": "15j,0,ab,0,82|15j,0,10k,0,82||",
                "Kosrae": "1qi,0,82|||",
                "Krasnoyarsk": "14h,0,ab,0,82|14h,0,md,0,82||",
                "Kyrgystan": "bi9,0,82|||",
                "Line_Islands": "1eo,0,1ks,0,82|||",
                "Lord_Howe": "oi,0,607,0,ab,0,82|oi,0,607,0,10k,0,82||",
                "Macquarie": "bib,0,2dk,0,82|||",
                "Magadan": "qq,0,ab,0,82|qq,0,md,0,82||",
                "Malaysia": "1pp,0,82|||",
                "Maldives": "4lt,0,bic|||",
                "Marquesas": "73j,0,82|||",
                "Marshall_Islands": "4ls,0,1ks,0,82|||",
                "Mauritius": "15t,0,ab,0,82|15t,0,md,0,82||",
                "Mawson": "bid,0,82|||",
                "Mongolia": "or,5,qo,0,ab,0,82|or,5,qo,0,md,0,82||",
                "Moscow": "r6,0,ab,0,82|r6,0,md,0,82||",
                "Myanmar": "1qm,0,82|||",
                "Nauru": "14s,0,82|||",
                "Nepal": "16d,0,82|||",
                "New_Caledonia": "2lq,0,15l,0,ab,0,82|2lq,0,15l,0,md,0,82||",
                "New_Zealand": "2lq,0,15h,0,ab,0,82|2lq,0,15h,0,10k,0,82||",
                "Newfoundland": "14t,0,ab,0,82|14t,0,10k,0,82||",
                "Niue": "1q4,0,82|||",
                "Norfolk": "146,0,2dk,0,82|||",
                "Noronha": "138,0,13a,0,132,0,ab,0,82|138,0,13a,0,132,0,md,0,82||",
                "Novosibirsk": "qt,0,ab,0,82|qt,0,md,0,82||",
                "Omsk": "r5,0,ab,0,82|r5,0,md,0,82||",
                "Pakistan": "605,0,ab,0,82|605,0,md,0,82||",
                "Palau": "159,0,82|||",
                "Papua_New_Guinea": "13k,5,2lq,0,1m5,0,82|||",
                "Paraguay": "13p,0,ab,0,82|13p,0,md,0,82||",
                "Peru": "q1,0,ab,0,82|q1,0,md,0,82||",
                "Philippines": "2lj,0,ab,0,82|2lj,0,md,0,82||",
                "Phoenix_Islands": "158,0,1ks,0,82|||",
                "Pierre_Miquelon": "2c3,5,13m,0,hb,0,qp,0,ab,0,82|2c3,5,13m,0,hb,0,qp,0,10k,0,82||",
                "Pitcairn": "1q6,0,82|||",
                "Ponape": "1v2,0,82|||",
                "Reunion": "1qo,0,82|||",
                "Rothera": "157,0,82|||",
                "Sakhalin": "r8,0,ab,0,82|r8,0,md,0,82||",
                "Samoa": "p0,0,ab,0,82|p0,0,10k,0,82||",
                "Seychelles": "73p,0,1ks,0,82|||",
                "Singapore": "16b,0,82|||",
                "Solomon": "4lp,0,1ks,0,82|||",
                "South_Georgia": "61r,0,6gr,0,82|||",
                "Suriname": "18v,0,82|||",
                "Syowa": "big,0,82|||",
                "Tahiti": "1ma,0,82|||",
                "Taipei": "45g,0,ab,0,82|45g,0,10k,0,82||",
                "Tajikistan": "bih,0,82|||",
                "Tokelau": "156,0,82|||",
                "Tonga": "ot,0,ab,0,82|ot,0,md,0,82||",
                "Truk": "1f2,0,82|||",
                "Turkmenistan": "601,0,ab,0,82|601,0,md,0,82||",
                "Tuvalu": "14j,0,82|||",
                "Uruguay": "14a,0,ab,0,82|14a,0,md,0,82||",
                "Uzbekistan": "600,0,ab,0,82|600,0,md,0,82||",
                "Vanuatu": "os,0,ab,0,82|os,0,md,0,82||",
                "Venezuela": "1mf,0,82|||",
                "Vladivostok": "qn,0,ab,0,82|qn,0,md,0,82||",
                "Volgograd": "q9,0,ab,0,82|q9,0,md,0,82||",
                "Vostok": "155,0,82|||",
                "Wake": "1m0,0,2dk,0,82|||",
                "Wallis": "bik,0,hb,0,14l,0,82|||",
                "Yakutsk": "14d,0,ab,0,82|14d,0,md,0,82||",
                "Yekaterinburg": "136,0,ab,0,82|136,0,md,0,82||"
            }
        },
        "uz-Latn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|640,0,a1,0,2s||",
                "Europe/London": "|3i5,0,a1,0,2s||"
            },
            "metazone": {
                "Afghanistan": "5vv,0,2s|||",
                "Africa_Central": "13o,0,c5,0,2s|||",
                "Africa_Eastern": "11q,0,c5,0,2s|||",
                "Africa_Southern": "2e4,0,c5,0,6i,0,2s|||",
                "Africa_Western": "1jp,0,c5,0,6i,0,2s|1jp,0,c5,0,a1,0,2s||",
                "Alaska": "1rr,0,6i,0,2s|1rr,0,a1,0,2s||",
                "Amazon": "2k3,0,6i,0,2s|2k3,0,a1,0,2s||",
                "America_Central": "13o,0,m2,0,6i,0,2s|13o,0,m2,0,a1,0,2s||",
                "America_Eastern": "11q,0,m2,0,6i,0,2s|11q,0,m2,0,a1,0,2s||",
                "America_Mountain": "3l1,0,6i,0,2s,0,3l2|3l1,0,a1,0,2s,0,3l2||",
                "America_Pacific": "1vc,0,1m4,0,6i,0,2s|1vc,0,1m4,0,a1,0,2s||",
                "Apia": "3e,0,6i,0,2s|3e,0,a1,0,2s||",
                "Arabian": "3l3,0,3l5,0,6i,0,2s|3l3,0,3l5,0,a1,0,2s||",
                "Argentina": "1e,0,6i,0,2s|1e,0,a1,0,2s||",
                "Argentina_Western": "1jp,0,1e,0,6i,0,2s|1jp,0,1e,0,a1,0,2s||",
                "Armenia": "3l7,0,6i,0,2s|3l7,0,a1,0,2s||",
                "Atlantic": "2k7,0,6i,0,2s|2k7,0,a1,0,2s||",
                "Australia_Central": "13o,0,r3,0,6i,0,2s|13o,0,r3,0,a1,0,2s||",
                "Australia_CentralWestern": "13o,0,r3,0,1v7,0,6i,0,2s|13o,0,r3,0,1v7,0,a1,0,2s||",
                "Australia_Eastern": "11q,0,r3,0,6i,0,2s|11q,0,r3,0,a1,0,2s||",
                "Australia_Western": "1gv,0,r3,0,6i,0,2s|1gv,0,r3,0,a1,0,2s||",
                "Azerbaijan": "3l8,0,6i,0,2s|3l8,0,a1,0,2s||",
                "Azores": "1lg,0,rv,0,6i,0,2s|1lg,0,rv,0,a1,0,2s||",
                "Bangladesh": "3n,0,6i,0,2s|3n,0,a1,0,2s||",
                "Bhutan": "1k4,0,2s|||",
                "Bolivia": "3jj,0,2s|||",
                "Brasilia": "1s2,0,6i,0,2s|1s2,0,a1,0,2s||",
                "Brunei": "5v0,5,5uu,0,2s|||",
                "Cape_Verde": "2kc,5,3j,0,6i,0,2s|2kc,5,3j,0,a1,0,2s||",
                "Chamorro": "6j,0,6i,0,2s|||",
                "Chatham": "3l9,0,6i,0,2s|3l9,0,a1,0,2s||",
                "Chile": "cg,0,6i,0,2s|cg,0,a1,0,2s||",
                "China": "3lb,0,6i,0,2s|3lb,0,a1,0,2s||",
                "Choibalsan": "3lc,0,6i,0,2s|3lc,0,a1,0,2s||",
                "Christmas": "5u1,0,1ip,0,2s|||",
                "Cocos": "1eg,0,rv,0,2s|||",
                "Colombia": "1s9,0,6i,0,2s|1s9,0,a1,0,2s||",
                "Cook": "1ec,0,rv,0,6i,0,2s|1ec,0,rv,0,5tv,0,a1,0,2s||",
                "Cuba": "tb,0,6i,0,2s|tb,0,a1,0,2s||",
                "Davis": "5tu,0,2s|||",
                "DumontDUrville": "5tt,5,5to,0,2s|||",
                "East_Timor": "11q,0,7n,0,2s|||",
                "Easter": "1sa,0,1ip,0,6i,0,2s|1sa,0,1ip,0,a1,0,2s||",
                "Ecuador": "1k0,0,2s|||",
                "Europe_Central": "13o,0,1jg,0,6i,0,2s|13o,0,1jg,0,a1,0,2s||",
                "Europe_Eastern": "11q,0,1jg,0,6i,0,2s|11q,0,1jg,0,a1,0,2s||",
                "Europe_Further_Eastern": "mo,0,1n6,0,5tl,0,2s|||",
                "Europe_Western": "1gv,0,1jg,0,6i,0,2s|1gv,0,1jg,0,a1,0,2s||",
                "Falkland": "1ov,0,rv,0,6i,0,2s|1ov,0,rv,0,a1,0,2s||",
                "Fiji": "4q,0,6i,0,2s|4q,0,a1,0,2s||",
                "French_Guiana": "2ke,0,5tk,0,2s|||",
                "French_Southern": "2ke,0,2e4,0,5ti,0,1n6,0,27v,0,2s|||",
                "Galapagos": "ak,0,2s|||",
                "Gambier": "5tg,0,2s|||",
                "Georgia": "3lf,0,6i,0,2s|3lf,0,a1,0,2s||",
                "Gilbert_Islands": "6q,0,rv,0,2s|||",
                "GMT": "5tc,0,5t9,0,2s|||",
                "Greenland_Eastern": "11q,0,1uf,0,6i,0,2s|11q,0,1uf,0,a1,0,2s||",
                "Greenland_Western": "1gv,0,1uf,0,6i,0,2s|1gv,0,1uf,0,a1,0,2s||",
                "Gulf": "5t5,0,5t0,0,6i,0,2s|||",
                "Guyana": "5sv,0,2s|||",
                "Hawaii_Aleutian": "3lj,5,3lk,0,6i,0,2s|3lj,5,3lk,0,a1,0,2s||",
                "Hong_Kong": "2kh,0,6i,0,2s|2kh,0,a1,0,2s||",
                "Hovd": "3ll,0,6i,0,2s|3ll,0,a1,0,2s||",
                "India": "5sm,0,2s|||",
                "Indian_Ocean": "3k9,0,1m4,0,2s|||",
                "Indochina": "5sl,0,2s|||",
                "Indonesia_Central": "13o,0,2fh,0,2s|||",
                "Indonesia_Eastern": "11q,0,2fh,0,2s|||",
                "Indonesia_Western": "1jp,0,2fh,0,2s|||",
                "Iran": "3ln,0,6i,0,2s|3ln,0,a1,0,2s||",
                "Irkutsk": "3h,0,6i,0,2s|3h,0,a1,0,2s||",
                "Israel": "3lq,0,6i,0,2s|3lq,0,a1,0,2s||",
                "Japan": "1sn,0,6i,0,2s|1sn,0,a1,0,2s||",
                "Kazakhstan_Eastern": "11q,0,3m2,0,2s|||",
                "Kazakhstan_Western": "1jp,0,3m2,0,2s|||",
                "Korea": "1so,0,6i,0,2s|1so,0,a1,0,2s||",
                "Kosrae": "6f,0,2s|||",
                "Krasnoyarsk": "41,0,6i,0,2s|41,0,a1,0,2s||",
                "Kyrgystan": "5s4,0,2s|||",
                "Line_Islands": "3kd,0,rv,0,2s|||",
                "Lord_Howe": "1u,5,3m4,0,6i,0,2s|1u,5,3m4,0,a1,0,2s||",
                "Macquarie": "4ru,0,1ip,0,2s|||",
                "Magadan": "2o,0,6i,0,2s|2o,0,a1,0,2s||",
                "Malaysia": "3kk,0,2s|||",
                "Maldives": "3kl,0,rv,0,2s|||",
                "Marquesas": "1jm,0,rv,0,2s|||",
                "Marshall_Islands": "7o,0,rv,0,2s|||",
                "Mauritius": "3m7,0,6i,0,2s|3m7,0,a1,0,2s||",
                "Mawson": "2v0,0,2s|||",
                "Mexico_Northwest": "3m9,5,1v7,0,10g,0,6i,0,2s|3m9,5,1v7,0,10g,0,a1,0,2s||",
                "Mexico_Pacific": "10g,0,1vc,0,1m4,0,6i,0,2s|10g,0,1vc,0,1m4,0,a1,0,2s||",
                "Mongolia": "4f,5,2m,0,6i,0,2s|4f,5,2m,0,a1,0,2s||",
                "Moscow": "pi,0,6i,0,2s|pi,0,a1,0,2s||",
                "Myanmar": "3kq,0,2s|||",
                "Nauru": "5v,0,2s|||",
                "Nepal": "8u,0,2s|||",
                "New_Caledonia": "1nq,0,1ss,0,6i,0,2s|1nq,0,1ss,0,a1,0,2s||",
                "New_Zealand": "1nq,0,1st,0,6i,0,2s|1nq,0,1st,0,a1,0,2s||",
                "Newfoundland": "1ta,0,6i,0,2s|1ta,0,a1,0,2s||",
                "Niue": "5rs,0,2s|||",
                "Norfolk": "6l,0,1ip,0,2s|||",
                "Noronha": "23a,5,4i,5,1tb,0,6i,0,2s|23a,5,4i,5,1tb,0,a1,0,2s||",
                "Novosibirsk": "3b,0,6i,0,2s|3b,0,a1,0,2s||",
                "Omsk": "26,0,6i,0,2s|26,0,a1,0,2s||",
                "Pakistan": "3mb,0,6i,0,2s|3mb,0,a1,0,2s||",
                "Palau": "9b,0,2s|||",
                "Papua_New_Guinea": "9a,5,1nq,0,5rq,0,2s|||",
                "Paraguay": "3mc,0,6i,0,2s|3mc,0,a1,0,2s||",
                "Peru": "4n,0,6i,0,2s|4n,0,a1,0,2s||",
                "Philippines": "1tc,0,6i,0,2s|1tc,0,a1,0,2s||",
                "Phoenix_Islands": "1a7,0,rv,0,2s|||",
                "Pierre_Miquelon": "14i,5,3md,0,1n6,0,u2,0,6i,0,2s|14i,5,3md,0,1n6,0,u2,0,a1,0,2s||",
                "Pitcairn": "1ko,0,2s|||",
                "Ponape": "9s,0,2s|||",
                "Pyongyang": "3kv,0,2s|||",
                "Reunion": "5rj,0,2s|||",
                "Rothera": "1po,0,2s|||",
                "Sakhalin": "1th,0,6i,0,2s|1th,0,a1,0,2s||",
                "Samoa": "2c,0,6i,0,2s|2c,0,a1,0,2s||",
                "Seychelles": "5rh,0,rv,0,2s|||",
                "Singapore": "m8,0,2s|||",
                "Solomon": "b5,0,rv,0,2s|||",
                "South_Georgia": "2e4,0,5rf,0,2s|||",
                "Suriname": "mg,0,2s|||",
                "Syowa": "3lt,0,2s|||",
                "Tahiti": "198,0,2s|||",
                "Taipei": "3me,0,6i,0,2s|3me,0,a1,0,2s||",
                "Tajikistan": "5rd,0,2s|||",
                "Tokelau": "5d,0,2s|||",
                "Tonga": "2j,0,6i,0,2s|2j,0,a1,0,2s||",
                "Truk": "6p,0,2s|||",
                "Turkmenistan": "3mf,0,6i,0,2s|3mf,0,a1,0,2s||",
                "Tuvalu": "6h,0,2s|||",
                "Uruguay": "3mg,0,6i,0,2s|3mg,0,a1,0,2s||",
                "Uzbekistan": "3mj,0,6i,0,2s|3mj,0,a1,0,2s||",
                "Vanuatu": "2f,0,6i,0,2s|2f,0,a1,0,2s||",
                "Venezuela": "2g9,0,2s|||",
                "Vladivostok": "28,0,6i,0,2s|28,0,a1,0,2s||",
                "Volgograd": "3f,0,6i,0,2s|3f,0,a1,0,2s||",
                "Vostok": "5t,0,2s|||",
                "Wake": "3pq,0,1ip,0,2s|||",
                "Wallis": "3pr,0,1n6,0,5k,0,2s|||",
                "Yakutsk": "3u,0,6i,0,2s|3u,0,a1,0,2s||",
                "Yekaterinburg": "5l,0,6i,0,2s|5l,0,a1,0,2s||"
            }
        },
        "uz": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|640,0,a1,0,2s||",
                "Europe/London": "|3i5,0,a1,0,2s||"
            },
            "metazone": {
                "Afghanistan": "5vv,0,2s|||",
                "Africa_Central": "13o,0,c5,0,2s|||",
                "Africa_Eastern": "11q,0,c5,0,2s|||",
                "Africa_Southern": "2e4,0,c5,0,6i,0,2s|||",
                "Africa_Western": "1jp,0,c5,0,6i,0,2s|1jp,0,c5,0,a1,0,2s||",
                "Alaska": "1rr,0,6i,0,2s|1rr,0,a1,0,2s||",
                "Amazon": "2k3,0,6i,0,2s|2k3,0,a1,0,2s||",
                "America_Central": "13o,0,m2,0,6i,0,2s|13o,0,m2,0,a1,0,2s||",
                "America_Eastern": "11q,0,m2,0,6i,0,2s|11q,0,m2,0,a1,0,2s||",
                "America_Mountain": "3l1,0,6i,0,2s,0,3l2|3l1,0,a1,0,2s,0,3l2||",
                "America_Pacific": "1vc,0,1m4,0,6i,0,2s|1vc,0,1m4,0,a1,0,2s||",
                "Apia": "3e,0,6i,0,2s|3e,0,a1,0,2s||",
                "Arabian": "3l3,0,3l5,0,6i,0,2s|3l3,0,3l5,0,a1,0,2s||",
                "Argentina": "1e,0,6i,0,2s|1e,0,a1,0,2s||",
                "Argentina_Western": "1jp,0,1e,0,6i,0,2s|1jp,0,1e,0,a1,0,2s||",
                "Armenia": "3l7,0,6i,0,2s|3l7,0,a1,0,2s||",
                "Atlantic": "2k7,0,6i,0,2s|2k7,0,a1,0,2s||",
                "Australia_Central": "13o,0,r3,0,6i,0,2s|13o,0,r3,0,a1,0,2s||",
                "Australia_CentralWestern": "13o,0,r3,0,1v7,0,6i,0,2s|13o,0,r3,0,1v7,0,a1,0,2s||",
                "Australia_Eastern": "11q,0,r3,0,6i,0,2s|11q,0,r3,0,a1,0,2s||",
                "Australia_Western": "1gv,0,r3,0,6i,0,2s|1gv,0,r3,0,a1,0,2s||",
                "Azerbaijan": "3l8,0,6i,0,2s|3l8,0,a1,0,2s||",
                "Azores": "1lg,0,rv,0,6i,0,2s|1lg,0,rv,0,a1,0,2s||",
                "Bangladesh": "3n,0,6i,0,2s|3n,0,a1,0,2s||",
                "Bhutan": "1k4,0,2s|||",
                "Bolivia": "3jj,0,2s|||",
                "Brasilia": "1s2,0,6i,0,2s|1s2,0,a1,0,2s||",
                "Brunei": "5v0,5,5uu,0,2s|||",
                "Cape_Verde": "2kc,5,3j,0,6i,0,2s|2kc,5,3j,0,a1,0,2s||",
                "Chamorro": "6j,0,6i,0,2s|||",
                "Chatham": "3l9,0,6i,0,2s|3l9,0,a1,0,2s||",
                "Chile": "cg,0,6i,0,2s|cg,0,a1,0,2s||",
                "China": "3lb,0,6i,0,2s|3lb,0,a1,0,2s||",
                "Choibalsan": "3lc,0,6i,0,2s|3lc,0,a1,0,2s||",
                "Christmas": "5u1,0,1ip,0,2s|||",
                "Cocos": "1eg,0,rv,0,2s|||",
                "Colombia": "1s9,0,6i,0,2s|1s9,0,a1,0,2s||",
                "Cook": "1ec,0,rv,0,6i,0,2s|1ec,0,rv,0,5tv,0,a1,0,2s||",
                "Cuba": "tb,0,6i,0,2s|tb,0,a1,0,2s||",
                "Davis": "5tu,0,2s|||",
                "DumontDUrville": "5tt,5,5to,0,2s|||",
                "East_Timor": "11q,0,7n,0,2s|||",
                "Easter": "1sa,0,1ip,0,6i,0,2s|1sa,0,1ip,0,a1,0,2s||",
                "Ecuador": "1k0,0,2s|||",
                "Europe_Central": "13o,0,1jg,0,6i,0,2s|13o,0,1jg,0,a1,0,2s||",
                "Europe_Eastern": "11q,0,1jg,0,6i,0,2s|11q,0,1jg,0,a1,0,2s||",
                "Europe_Further_Eastern": "mo,0,1n6,0,5tl,0,2s|||",
                "Europe_Western": "1gv,0,1jg,0,6i,0,2s|1gv,0,1jg,0,a1,0,2s||",
                "Falkland": "1ov,0,rv,0,6i,0,2s|1ov,0,rv,0,a1,0,2s||",
                "Fiji": "4q,0,6i,0,2s|4q,0,a1,0,2s||",
                "French_Guiana": "2ke,0,5tk,0,2s|||",
                "French_Southern": "2ke,0,2e4,0,5ti,0,1n6,0,27v,0,2s|||",
                "Galapagos": "ak,0,2s|||",
                "Gambier": "5tg,0,2s|||",
                "Georgia": "3lf,0,6i,0,2s|3lf,0,a1,0,2s||",
                "Gilbert_Islands": "6q,0,rv,0,2s|||",
                "GMT": "5tc,0,5t9,0,2s|||",
                "Greenland_Eastern": "11q,0,1uf,0,6i,0,2s|11q,0,1uf,0,a1,0,2s||",
                "Greenland_Western": "1gv,0,1uf,0,6i,0,2s|1gv,0,1uf,0,a1,0,2s||",
                "Gulf": "5t5,0,5t0,0,6i,0,2s|||",
                "Guyana": "5sv,0,2s|||",
                "Hawaii_Aleutian": "3lj,5,3lk,0,6i,0,2s|3lj,5,3lk,0,a1,0,2s||",
                "Hong_Kong": "2kh,0,6i,0,2s|2kh,0,a1,0,2s||",
                "Hovd": "3ll,0,6i,0,2s|3ll,0,a1,0,2s||",
                "India": "5sm,0,2s|||",
                "Indian_Ocean": "3k9,0,1m4,0,2s|||",
                "Indochina": "5sl,0,2s|||",
                "Indonesia_Central": "13o,0,2fh,0,2s|||",
                "Indonesia_Eastern": "11q,0,2fh,0,2s|||",
                "Indonesia_Western": "1jp,0,2fh,0,2s|||",
                "Iran": "3ln,0,6i,0,2s|3ln,0,a1,0,2s||",
                "Irkutsk": "3h,0,6i,0,2s|3h,0,a1,0,2s||",
                "Israel": "3lq,0,6i,0,2s|3lq,0,a1,0,2s||",
                "Japan": "1sn,0,6i,0,2s|1sn,0,a1,0,2s||",
                "Kazakhstan_Eastern": "11q,0,3m2,0,2s|||",
                "Kazakhstan_Western": "1jp,0,3m2,0,2s|||",
                "Korea": "1so,0,6i,0,2s|1so,0,a1,0,2s||",
                "Kosrae": "6f,0,2s|||",
                "Krasnoyarsk": "41,0,6i,0,2s|41,0,a1,0,2s||",
                "Kyrgystan": "5s4,0,2s|||",
                "Line_Islands": "3kd,0,rv,0,2s|||",
                "Lord_Howe": "1u,5,3m4,0,6i,0,2s|1u,5,3m4,0,a1,0,2s||",
                "Macquarie": "4ru,0,1ip,0,2s|||",
                "Magadan": "2o,0,6i,0,2s|2o,0,a1,0,2s||",
                "Malaysia": "3kk,0,2s|||",
                "Maldives": "3kl,0,rv,0,2s|||",
                "Marquesas": "1jm,0,rv,0,2s|||",
                "Marshall_Islands": "7o,0,rv,0,2s|||",
                "Mauritius": "3m7,0,6i,0,2s|3m7,0,a1,0,2s||",
                "Mawson": "2v0,0,2s|||",
                "Mexico_Northwest": "3m9,5,1v7,0,10g,0,6i,0,2s|3m9,5,1v7,0,10g,0,a1,0,2s||",
                "Mexico_Pacific": "10g,0,1vc,0,1m4,0,6i,0,2s|10g,0,1vc,0,1m4,0,a1,0,2s||",
                "Mongolia": "4f,5,2m,0,6i,0,2s|4f,5,2m,0,a1,0,2s||",
                "Moscow": "pi,0,6i,0,2s|pi,0,a1,0,2s||",
                "Myanmar": "3kq,0,2s|||",
                "Nauru": "5v,0,2s|||",
                "Nepal": "8u,0,2s|||",
                "New_Caledonia": "1nq,0,1ss,0,6i,0,2s|1nq,0,1ss,0,a1,0,2s||",
                "New_Zealand": "1nq,0,1st,0,6i,0,2s|1nq,0,1st,0,a1,0,2s||",
                "Newfoundland": "1ta,0,6i,0,2s|1ta,0,a1,0,2s||",
                "Niue": "5rs,0,2s|||",
                "Norfolk": "6l,0,1ip,0,2s|||",
                "Noronha": "23a,5,4i,5,1tb,0,6i,0,2s|23a,5,4i,5,1tb,0,a1,0,2s||",
                "Novosibirsk": "3b,0,6i,0,2s|3b,0,a1,0,2s||",
                "Omsk": "26,0,6i,0,2s|26,0,a1,0,2s||",
                "Pakistan": "3mb,0,6i,0,2s|3mb,0,a1,0,2s||",
                "Palau": "9b,0,2s|||",
                "Papua_New_Guinea": "9a,5,1nq,0,5rq,0,2s|||",
                "Paraguay": "3mc,0,6i,0,2s|3mc,0,a1,0,2s||",
                "Peru": "4n,0,6i,0,2s|4n,0,a1,0,2s||",
                "Philippines": "1tc,0,6i,0,2s|1tc,0,a1,0,2s||",
                "Phoenix_Islands": "1a7,0,rv,0,2s|||",
                "Pierre_Miquelon": "14i,5,3md,0,1n6,0,u2,0,6i,0,2s|14i,5,3md,0,1n6,0,u2,0,a1,0,2s||",
                "Pitcairn": "1ko,0,2s|||",
                "Ponape": "9s,0,2s|||",
                "Pyongyang": "3kv,0,2s|||",
                "Reunion": "5rj,0,2s|||",
                "Rothera": "1po,0,2s|||",
                "Sakhalin": "1th,0,6i,0,2s|1th,0,a1,0,2s||",
                "Samoa": "2c,0,6i,0,2s|2c,0,a1,0,2s||",
                "Seychelles": "5rh,0,rv,0,2s|||",
                "Singapore": "m8,0,2s|||",
                "Solomon": "b5,0,rv,0,2s|||",
                "South_Georgia": "2e4,0,5rf,0,2s|||",
                "Suriname": "mg,0,2s|||",
                "Syowa": "3lt,0,2s|||",
                "Tahiti": "198,0,2s|||",
                "Taipei": "3me,0,6i,0,2s|3me,0,a1,0,2s||",
                "Tajikistan": "5rd,0,2s|||",
                "Tokelau": "5d,0,2s|||",
                "Tonga": "2j,0,6i,0,2s|2j,0,a1,0,2s||",
                "Truk": "6p,0,2s|||",
                "Turkmenistan": "3mf,0,6i,0,2s|3mf,0,a1,0,2s||",
                "Tuvalu": "6h,0,2s|||",
                "Uruguay": "3mg,0,6i,0,2s|3mg,0,a1,0,2s||",
                "Uzbekistan": "3mj,0,6i,0,2s|3mj,0,a1,0,2s||",
                "Vanuatu": "2f,0,6i,0,2s|2f,0,a1,0,2s||",
                "Venezuela": "2g9,0,2s|||",
                "Vladivostok": "28,0,6i,0,2s|28,0,a1,0,2s||",
                "Volgograd": "3f,0,6i,0,2s|3f,0,a1,0,2s||",
                "Vostok": "5t,0,2s|||",
                "Wake": "3pq,0,1ip,0,2s|||",
                "Wallis": "3pr,0,1n6,0,5k,0,2s|||",
                "Yakutsk": "3u,0,6i,0,2s|3u,0,a1,0,2s||",
                "Yekaterinburg": "5l,0,6i,0,2s|5l,0,a1,0,2s||"
            }
        },
        "vai-Latn": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "vai-Vaii": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "vai": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "vi": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|6b,0,1km,0,ce8,5,ce9||",
                "Europe/London": "|6b,0,h6,0,hj,0,cea||"
            },
            "metazone": {
                "Acre": "6b,0,g3,0,3q|6b,0,h6,0,hj,0,3q||",
                "Afghanistan": "6b,0,b6|||",
                "Africa_Central": "6b,0,1kp,0,2v2|||",
                "Africa_Eastern": "6b,0,1ga,0,2v2|||",
                "Africa_Southern": "6b,0,g3,0,3ml,0,2v2|||",
                "Africa_Western": "6b,0,g3,0,18b,0,2v2|6b,0,h6,0,hj,0,18b,0,2v2||",
                "Alaska": "6b,0,g3,0,3p|6b,0,h6,0,hj,0,3p||",
                "Almaty": "6b,0,g3,0,56|6b,0,h6,0,hj,0,56||",
                "Amazon": "6b,0,g3,0,5m|6b,0,h6,0,hj,0,5m||",
                "America_Central": "6b,0,1km,0,1t2,0,5pk|6b,0,19e,0,168,0,1t2,0,5pk||",
                "America_Eastern": "6b,0,1km,0,1t2,0,5ph|6b,0,19e,0,168,0,1t2,0,5ph||",
                "America_Mountain": "6b,0,1km,0,1t2,0,5nj|6b,0,19e,0,168,0,1t2,0,5nj||",
                "America_Pacific": "6b,0,1km,0,3mm,0,2so,0,1r4|6b,0,h6,0,168,0,3mm,0,2so,0,1r4||",
                "Anadyr": "6b,0,g3,0,3l|6b,0,19e,0,168,0,3l||",
                "Apia": "6b,0,g3,0,3e|6b,0,h6,0,hj,0,3e||",
                "Aqtau": "6b,0,g3,0,50|6b,0,h6,0,hj,0,50||",
                "Aqtobe": "6b,0,g3,0,5o|6b,0,h6,0,hj,0,5o||",
                "Arabian": "6b,0,1km,0,5n4,0,5n3|6b,0,h6,0,hj,0,5n4,0,5n3||",
                "Argentina": "6b,0,g3,0,1e|6b,0,h6,0,hj,0,1e||",
                "Argentina_Western": "6b,0,1km,0,1t2,0,blf,0,1e|6b,0,19e,0,168,0,1t2,0,blg,0,1e||",
                "Armenia": "6b,0,g3,0,4l|6b,0,h6,0,hj,0,4l||",
                "Atlantic": "6b,0,g3,0,50r,0,18b,0,1r4|6b,0,h6,0,168,0,50r,0,18b,0,1r4||",
                "Australia_Central": "6b,0,g3,0,16e,0,1kp,0,1pj,0,1rk|6b,0,h6,0,hj,0,16e,0,1kp,0,1pj,0,1rk||",
                "Australia_CentralWestern": "6b,0,g3,0,16e,0,1kp,0,18b,0,1pj,0,1rk|6b,0,h6,0,hj,0,16e,0,1kp,0,18b,0,1pj,0,1rk||",
                "Australia_Eastern": "6b,0,g3,0,16e,0,1ga,0,1pj,0,1rk|6b,0,h6,0,hj,0,16e,0,1ga,0,1pj,0,1rk||",
                "Australia_Western": "6b,0,g3,0,16e,0,18b,0,1pj,0,1rk|6b,0,h6,0,hj,0,16e,0,18b,0,1pj,0,1rk||",
                "Azerbaijan": "6b,0,g3,0,7m|6b,0,h6,0,hj,0,7m||",
                "Azores": "6b,0,g3,0,4r|6b,0,h6,0,hj,0,4r||",
                "Bangladesh": "6b,0,g3,0,3n|6b,0,h6,0,hj,0,3n||",
                "Bhutan": "6b,0,as|||",
                "Bolivia": "6b,0,aa|||",
                "Brasilia": "6b,0,g3,0,3a|6b,0,h6,0,hj,0,3a||",
                "Brunei": "6b,0,9o,0,a4|||",
                "Cape_Verde": "6b,0,g3,0,7c,0,3j|6b,0,h6,0,hj,0,7c,0,3j||",
                "Chamorro": "6b,0,6j|||",
                "Chatham": "6b,0,g3,0,27|6b,0,h6,0,hj,0,27||",
                "Chile": "6b,0,g3,0,49|6b,0,h6,0,hj,0,49||",
                "China": "6b,0,g3,0,1kp,0,3r8|6b,0,h6,0,hj,0,1kp,0,3r8||",
                "Choibalsan": "6b,0,g3,0,2q|6b,0,h6,0,hj,0,2q||",
                "Christmas": "6b,0,19d,0,99|||",
                "Cocos": "6b,0,1k8,0,19d,0,7v|||",
                "Colombia": "6b,0,g3,0,4m|6b,0,h6,0,hj,0,4m||",
                "Cook": "6b,0,g3,0,1k8,0,19d,0,2v|6b,0,blo,0,h6,0,hj,0,1k8,0,19d,0,2v||",
                "Cuba": "6b,0,g3,0,34|6b,0,h6,0,hj,0,34||",
                "Davis": "6b,0,5s|||",
                "DumontDUrville": "6b,0,5b,5,5j|||",
                "East_Timor": "6b,0,1ga,0,7n|||",
                "Easter": "6b,0,g3,0,19d,0,50k,0,50j|6b,0,h6,0,hj,0,19d,0,50k,0,50j||",
                "Ecuador": "6b,0,ai|||",
                "Europe_Central": "6b,0,g3,0,1kp,0,2a7|6b,0,h6,0,hj,0,1kp,0,2a7||",
                "Europe_Eastern": "6b,0,g3,0,1ga,0,2a7|6b,0,h6,0,hj,0,1ga,0,2a7||",
                "Europe_Further_Eastern": "6b,0,bls,0,2a7,0,blt,0,1ga|||",
                "Europe_Western": "6b,0,g3,0,18b,0,2a7|6b,0,h6,0,168,0,18b,0,2a7||",
                "Falkland": "6b,0,g3,0,1k8,0,19d,0,4v|6b,0,h6,0,hj,0,1k8,0,19d,0,4v||",
                "Fiji": "6b,0,g3,0,4q|6b,0,h6,0,hj,0,4q||",
                "French_Guiana": "6b,0,am,0,blu,0,50h|||",
                "French_Southern": "6b,0,3ml,0,bm0,0,3r9,0,3ml,0,1pj,0,50h|||",
                "Galapagos": "6b,0,ak|||",
                "Gambier": "6b,0,6v|||",
                "Georgia": "6b,0,g3,0,50f|6b,0,h6,0,hj,0,50f||",
                "Gilbert_Islands": "6b,0,1k8,0,19d,0,6q|||",
                "GMT": "6b,0,1kp,0,bm3,0,5u|||",
                "Greenland_Eastern": "6b,0,g3,0,16e,0,1ga,0,2k|6b,0,h6,0,hj,0,16e,0,1ga,0,2k||",
                "Greenland_Western": "6b,0,g3,0,16e,0,18b,0,2k|6b,0,h6,0,hj,0,16e,0,18b,0,2k||",
                "Guam": "6b,0,g3,0,83|||",
                "Gulf": "6b,0,g3,0,bm4,0,bm5|||",
                "Guyana": "6b,0,7a|||",
                "Hawaii_Aleutian": "6b,0,g3,0,4g,5,62|6b,0,h6,0,hj,0,4g,5,62||",
                "Hong_Kong": "6b,0,g3,0,508,0,506|6b,0,h6,0,hj,0,508,0,506||",
                "Hovd": "6b,0,g3,0,2g|6b,0,h6,0,hj,0,2g||",
                "India": "6b,0,g3,0,503,0,500|||",
                "Indian_Ocean": "6b,0,503,0,500,0,1r4|||",
                "Indochina": "6b,0,1ga,0,1r4|||",
                "Indonesia_Central": "6b,0,16e,0,1kp,0,2t|||",
                "Indonesia_Eastern": "6b,0,16e,0,1ga,0,2t|||",
                "Indonesia_Western": "6b,0,16e,0,18b,0,2t|||",
                "Iran": "6b,0,g3,0,5g|6b,0,h6,0,hj,0,5g||",
                "Irkutsk": "6b,0,g3,0,3h|6b,0,h6,0,hj,0,3h||",
                "Israel": "6b,0,g3,0,4e|6b,0,h6,0,hj,0,4e||",
                "Japan": "6b,0,g3,0,4vu,0,4vs|6b,0,h6,0,hj,0,4vu,0,4vs||",
                "Kamchatka": "6b,0,1km,0,37,5,3k|6b,0,19e,0,168,0,37,5,3k||",
                "Kazakhstan_Eastern": "6b,0,16e,0,1ga,0,45|||",
                "Kazakhstan_Western": "6b,0,16e,0,18b,0,45|||",
                "Korea": "6b,0,g3,0,4vo,0,3r8|6b,0,h6,0,hj,0,4vo,0,3r8||",
                "Kosrae": "6b,0,6f|||",
                "Krasnoyarsk": "6b,0,g3,0,41|6b,0,h6,0,hj,0,41||",
                "Kyrgystan": "6b,0,bl|||",
                "Lanka": "6b,0,9c|||",
                "Line_Islands": "6b,0,1k8,0,19d,0,ac|||",
                "Lord_Howe": "6b,0,g3,0,1u,0,24|6b,0,h6,0,hj,0,1u,0,24||",
                "Macau": "6b,0,g3,0,4vn,0,4vk|6b,0,h6,0,hj,0,4vn,0,4vk||",
                "Macquarie": "6b,0,4vc,0,6m|||",
                "Magadan": "6b,0,g3,0,2o|6b,0,19e,0,168,0,2o||",
                "Malaysia": "6b,0,bf|||",
                "Maldives": "6b,0,9k|||",
                "Marquesas": "6b,0,9e|||",
                "Marshall_Islands": "6b,0,1k8,0,19d,0,7o|||",
                "Mauritius": "6b,0,g3,0,4u|6b,0,h6,0,hj,0,4u||",
                "Mawson": "6b,0,63|||",
                "Mexico_Northwest": "6b,0,g3,0,18b,0,2mp,0,5e|6b,0,h6,0,hj,0,18b,0,2mp,0,5e||",
                "Mexico_Pacific": "6b,0,g3,0,3mm,0,2so,0,1r4,0,5e|6b,0,h6,0,hj,0,3mm,0,2so,0,1r4,0,5e||",
                "Mongolia": "6b,0,1km,0,4f,0,2m|6b,0,19e,0,168,0,4f,0,2m||",
                "Moscow": "6b,0,g3,0,4v7|6b,0,h6,0,hj,0,4v7||",
                "Myanmar": "6b,0,8v|||",
                "Nauru": "6b,0,5v|||",
                "Nepal": "6b,0,8u|||",
                "New_Caledonia": "6b,0,g3,0,1m,0,4o|6b,0,h6,0,hj,0,1m,0,4o||",
                "New_Zealand": "6b,0,g3,0,1m,0,74|6b,0,h6,0,hj,0,1m,0,74||",
                "Newfoundland": "6b,0,g3,0,53|6b,0,h6,0,hj,0,53||",
                "Niue": "6b,0,8g|||",
                "Norfolk": "6b,0,4vc,0,6l|||",
                "Noronha": "6b,0,g3,0,1s,0,2,0,23|6b,0,h6,0,hj,0,1s,0,2,0,23||",
                "North_Mariana": "6b,0,1k8,0,19d,0,2mp,0,bd|||",
                "Novosibirsk": "6b,0,1km,0,3b|6b,0,19e,0,168,0,3b||",
                "Omsk": "6b,0,1km,0,26|6b,0,19e,0,168,0,26||",
                "Pakistan": "6b,0,g3,0,3i|6b,0,h6,0,hj,0,3i||",
                "Palau": "6b,0,9b|||",
                "Papua_New_Guinea": "6b,0,9a,0,1m,0,a8|||",
                "Paraguay": "6b,0,g3,0,3c|6b,0,h6,0,hj,0,3c||",
                "Peru": "6b,0,g3,0,4n|6b,0,h6,0,hj,0,4n||",
                "Philippines": "6b,0,g3,0,4v5|6b,0,h6,0,hj,0,4v5||",
                "Phoenix_Islands": "6b,0,1k8,0,19d,0,90|||",
                "Pierre_Miquelon": "6b,0,g3,0,7u,0,33,0,3r9,0,35|6b,0,h6,0,hj,0,8i,0,33,0,3r9,0,35||",
                "Pitcairn": "6b,0,7e|||",
                "Ponape": "6b,0,9s|||",
                "Pyongyang": "6b,0,2so,0,bmj|||",
                "Qyzylorda": "6b,0,g3,0,4d|6b,0,h6,0,hj,0,4d||",
                "Reunion": "6b,0,b4|||",
                "Rothera": "6b,0,5q|||",
                "Sakhalin": "6b,0,g3,0,55|6b,0,19e,0,168,0,55||",
                "Samara": "6b,0,g3,0,29|6b,0,19e,0,168,0,29||",
                "Samoa": "6b,0,g3,0,2c|6b,0,bmk,0,bml,0,2c||",
                "Seychelles": "6b,0,8k|||",
                "Singapore": "6b,0,bi|||",
                "Solomon": "6b,0,1k8,0,19d,0,b5|||",
                "South_Georgia": "6b,0,3ml,0,2u|||",
                "Suriname": "6b,0,8q|||",
                "Syowa": "6b,0,65|||",
                "Tahiti": "6b,0,8n|||",
                "Taipei": "6b,0,g3,0,4v4,0,2mp|6b,0,h6,0,hj,0,4v4,0,2mp||",
                "Tajikistan": "6b,0,bj|||",
                "Tokelau": "6b,0,5d|||",
                "Tonga": "6b,0,g3,0,2j|6b,0,h6,0,hj,0,2j||",
                "Truk": "6b,0,6p|||",
                "Turkmenistan": "6b,0,g3,0,4t|6b,0,h6,0,hj,0,4t||",
                "Tuvalu": "6b,0,6h|||",
                "Uruguay": "6b,0,g3,0,4k|6b,0,h6,0,hj,0,4k||",
                "Uzbekistan": "6b,0,g3,0,60|6b,0,h6,0,hj,0,60||",
                "Vanuatu": "6b,0,g3,0,2f|6b,0,h6,0,hj,0,2f||",
                "Venezuela": "6b,0,7s|||",
                "Vladivostok": "6b,0,g3,0,28|6b,0,19e,0,168,0,28||",
                "Volgograd": "6b,0,g3,0,3f|6b,0,h6,0,hj,0,3f||",
                "Vostok": "6b,0,5t|||",
                "Wake": "6b,0,19d,0,5r|||",
                "Wallis": "6b,0,5f,0,3r9,0,5k|||",
                "Yakutsk": "6b,0,g3,0,3u|6b,0,19e,0,168,0,3u||",
                "Yekaterinburg": "6b,0,g3,0,5l|6b,0,19e,0,168,0,5l||"
            }
        },
        "vo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "vun": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "wae": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {
                "Atlantic": "4uu,0,3ra|4uu,0,3rb||",
                "Europe_Central": "4un,0,3ra|4un,0,3rb|1bd|1be",
                "Europe_Eastern": "4ul,0,3ra|4ul,0,3rb|1bg|1bh",
                "Europe_Western": "4uj,0,3ra|4uj,0,3rb|1bk|1bl"
            }
        },
        "xog": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "yav": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "yi": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "yo-BJ": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "yo": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "yue": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|39v||",
                "Europe/London": "|39t||"
            },
            "metazone": {
                "Acre": "3rc|3rd||",
                "Afghanistan": "3rh|||",
                "Africa_Central": "3ri|||",
                "Africa_Eastern": "3rj|||",
                "Africa_Southern": "4u9|||",
                "Africa_Western": "3rk|3rl||",
                "Alaska": "3rn|31r||",
                "Almaty": "3ru|3rv||",
                "Amazon": "3s4|3s6||",
                "America_Central": "4ti|3s8||",
                "America_Eastern": "4ta|4t9||",
                "America_Mountain": "4t5|4t3||",
                "America_Pacific": "4t1|4t0||",
                "Anadyr": "3s9|3sa||",
                "Apia": "3sb|3sc||",
                "Aqtau": "3sd|3se||",
                "Aqtobe": "3sf|3sj||",
                "Arabian": "3sk|3sl||",
                "Argentina": "3so|3sr||",
                "Argentina_Western": "3sv|3t1||",
                "Armenia": "3t2|3t4||",
                "Atlantic": "3t6|3t7||",
                "Australia_Central": "3tb|3tc||",
                "Australia_CentralWestern": "3td|3tf||",
                "Australia_Eastern": "3tg|3th||",
                "Australia_Western": "3ti|3tj||",
                "Azerbaijan": "8hg|8h8||",
                "Azores": "3tm|3tn||",
                "Bangladesh": "3tp|3ts||",
                "Bhutan": "3tt|||",
                "Bolivia": "3tu|||",
                "Brasilia": "3tv|3u0||",
                "Brunei": "3u2|||",
                "Cape_Verde": "8fo|8fj||",
                "Casey": "3u3|||",
                "Chamorro": "3u5|||",
                "Chatham": "3u6|3u7||",
                "Chile": "3u8|3u9||",
                "China": "3ua|3ub||",
                "Choibalsan": "3uc|3ud||",
                "Christmas": "3ue|||",
                "Cocos": "8dn|||",
                "Colombia": "3uf|3ui||",
                "Cook": "3uj|3uk||",
                "Cuba": "3ul|3um||",
                "Davis": "3un|||",
                "DumontDUrville": "8ch|||",
                "East_Timor": "3uo|||",
                "Easter": "3up|3uq||",
                "Ecuador": "8bs|||",
                "Europe_Central": "3ur|3us||",
                "Europe_Eastern": "3ut|3uu||",
                "Europe_Further_Eastern": "3uv|||",
                "Europe_Western": "3v0|3v1||",
                "Falkland": "3v2|3v3||",
                "Fiji": "3v4|3v6||",
                "French_Guiana": "3v9|||",
                "French_Southern": "3va|||",
                "Galapagos": "8aa|||",
                "Gambier": "3vb|||",
                "Georgia": "89s|89o||",
                "Gilbert_Islands": "3vd|||",
                "GMT": "3ve|||",
                "Greenland_Eastern": "3vg|3vi||",
                "Greenland_Western": "3vj|3vk||",
                "Guam": "404|||",
                "Gulf": "85c|||",
                "Guyana": "83u|||",
                "Hawaii_Aleutian": "166,5,405|166,5,409||",
                "Hong_Kong": "40b|40d||",
                "Hovd": "40l|40o||",
                "India": "7s2|||",
                "Indian_Ocean": "419|||",
                "Indochina": "7ro|||",
                "Indonesia_Central": "434|||",
                "Indonesia_Eastern": "435|||",
                "Indonesia_Western": "436|||",
                "Iran": "437|439||",
                "Irkutsk": "7pt|7pn||",
                "Israel": "43b|43d||",
                "Japan": "43e|43f||",
                "Kamchatka": "43g|43h||",
                "Kazakhstan_Eastern": "43n|||",
                "Kazakhstan_Western": "43o|||",
                "Korea": "43p|43q||",
                "Kosrae": "7hl|||",
                "Krasnoyarsk": "43r|43t||",
                "Kyrgystan": "441|||",
                "Lanka": "442|||",
                "Line_Islands": "444|||",
                "Lord_Howe": "449|44a||",
                "Macau": "44e|44g||",
                "Macquarie": "7bh|||",
                "Magadan": "44o|44p||",
                "Malaysia": "44q|||",
                "Maldives": "7a1|||",
                "Marquesas": "6fq|||",
                "Marshall_Islands": "474|||",
                "Mauritius": "6ds|6dk||",
                "Mawson": "476|||",
                "Mexico_Northwest": "477|478||",
                "Mexico_Pacific": "479|47a||",
                "Mongolia": "47b|47c||",
                "Moscow": "47g|47h||",
                "Myanmar": "47i|||",
                "Nauru": "69b|||",
                "Nepal": "47j|||",
                "New_Caledonia": "47k|68r||",
                "New_Zealand": "47l|47m||",
                "Newfoundland": "47n|47o||",
                "Niue": "47q|||",
                "Norfolk": "47r|||",
                "Noronha": "47s,0,5,0,681|47s,0,5,0,680||",
                "North_Mariana": "47u|||",
                "Novosibirsk": "47v|480||",
                "Omsk": "481|482||",
                "Pakistan": "483|484||",
                "Palau": "485|||",
                "Papua_New_Guinea": "65s|||",
                "Paraguay": "486|487||",
                "Peru": "488|48b||",
                "Philippines": "48c|48d||",
                "Phoenix_Islands": "48e|||",
                "Pierre_Miquelon": "48f|48g||",
                "Pitcairn": "63m|||",
                "Ponape": "48h|||",
                "Qyzylorda": "48i|48j||",
                "Reunion": "48k|||",
                "Rothera": "48m|||",
                "Sakhalin": "48n|48o||",
                "Samara": "48p|48q||",
                "Samoa": "48r|48s||",
                "Seychelles": "61n|||",
                "Singapore": "61m|||",
                "Solomon": "61e|||",
                "South_Georgia": "48v|||",
                "Suriname": "5vs|||",
                "Syowa": "2op|||",
                "Tahiti": "494|||",
                "Taipei": "49g|49h||",
                "Tajikistan": "49k|||",
                "Tokelau": "49l|||",
                "Tonga": "5ui|5ud||",
                "Truk": "49m|||",
                "Turkmenistan": "49n|4a3||",
                "Tuvalu": "5rv|||",
                "Uruguay": "4a6|4a7||",
                "Uzbekistan": "4a9|4ac||",
                "Vanuatu": "5r8|5r7||",
                "Venezuela": "4ad|||",
                "Vladivostok": "4ae|4af||",
                "Volgograd": "4ag|4ah||",
                "Vostok": "4ai|||",
                "Wake": "4aj|||",
                "Wallis": "4ak|||",
                "Yakutsk": "5q3|5q2||",
                "Yekaterinburg": "4al|4am||"
            }
        },
        "zgh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {},
            "metazone": {}
        },
        "zh-Hans-HK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tp||",
                "Europe/London": "|2ti||"
            },
            "metazone": {
                "Acre": "2t9|2t8||",
                "Afghanistan": "2t7|||",
                "Africa_Central": "2t6|||",
                "Africa_Eastern": "2t5|||",
                "Africa_Southern": "2t4|||",
                "Africa_Western": "2t3|2t1||",
                "Alaska": "2t0|2sv||",
                "Almaty": "2su|2st||",
                "Amazon": "2ss|2sq||",
                "America_Central": "2sp|2sn||",
                "America_Eastern": "2sm|2sl||",
                "America_Mountain": "2sk|2sj||",
                "America_Pacific": "2si|2sh||",
                "Anadyr": "2sf|2sd||",
                "Apia": "2sc|2sb||",
                "Aqtau": "2sa|2s8||",
                "Aqtobe": "2s6|2s3||",
                "Arabian": "2s1|2s0||",
                "Argentina": "2rv|2ru||",
                "Argentina_Western": "2rr|2rq||",
                "Armenia": "2rp|2ro||",
                "Atlantic": "2rm|2rk||",
                "Australia_Central": "2rj|2ri||",
                "Australia_CentralWestern": "2rh|2rf||",
                "Australia_Eastern": "2rd|2rc||",
                "Australia_Western": "2rb|2r8||",
                "Azerbaijan": "2r7|2r6||",
                "Azores": "2qj|2qf||",
                "Bangladesh": "2q9|2q8||",
                "Bhutan": "2q7|||",
                "Bolivia": "2q2|||",
                "Brasilia": "2pm|2pj||",
                "Brunei": "2pi|||",
                "Cape_Verde": "2ph|2pg||",
                "Casey": "2os|||",
                "Chamorro": "2on|||",
                "Chatham": "2om|2ok||",
                "Chile": "2oj|2oi||",
                "China": "2of|2oe||",
                "Choibalsan": "2od|2oc||",
                "Christmas": "2o9|||",
                "Cocos": "2o0|||",
                "Colombia": "2nt|2ns||",
                "Cook": "2nr|2nq||",
                "Cuba": "2nk|2n1||",
                "Davis": "2n0|||",
                "DumontDUrville": "2n5|||",
                "East_Timor": "318|||",
                "Easter": "314|30u||",
                "Ecuador": "304|||",
                "Europe_Central": "2vs|2vn||",
                "Europe_Eastern": "2uj|2uc||",
                "Europe_Further_Eastern": "2u9|||",
                "Europe_Western": "2tn|2r5||",
                "Falkland": "2r2|2r1||",
                "Fiji": "2r0|2qu||",
                "French_Guiana": "2qs|||",
                "French_Southern": "2qp|||",
                "Galapagos": "2qo|||",
                "Gambier": "2qn|||",
                "Georgia": "2ql|2qk||",
                "Gilbert_Islands": "2qh|||",
                "GMT": "2qg|||",
                "Greenland_Eastern": "2qe|2qa||",
                "Greenland_Western": "2q6|2q4||",
                "Guam": "2q1|||",
                "Gulf": "2pu|||",
                "Guyana": "2po|||",
                "Hawaii_Aleutian": "166,5,2pa|166,5,2p4||",
                "Hong_Kong": "2or|2oh||",
                "Hovd": "2o6|2o4||",
                "India": "2o3|||",
                "Indian_Ocean": "2o2|||",
                "Indochina": "2no|||",
                "Indonesia_Central": "2nn|||",
                "Indonesia_Eastern": "2nm|||",
                "Indonesia_Western": "2nh|||",
                "Iran": "2ne|2v5||",
                "Irkutsk": "2nb|2na||",
                "Israel": "2n6|2mv||",
                "Japan": "2mu|2mq||",
                "Kamchatka": "1lk,5,2mm|1lk,5,2mg||",
                "Kazakhstan_Eastern": "2mf|||",
                "Kazakhstan_Western": "2md|||",
                "Korea": "2mc|2mb||",
                "Kosrae": "2ma|||",
                "Krasnoyarsk": "2m9|2m8||",
                "Kyrgystan": "2m2|||",
                "Lanka": "31g|||",
                "Line_Islands": "317|||",
                "Lord_Howe": "30t|30o||",
                "Macau": "30d|309||",
                "Macquarie": "306|||",
                "Magadan": "303|300||",
                "Malaysia": "2vu|||",
                "Maldives": "2vr|||",
                "Marquesas": "2vp|||",
                "Marshall_Islands": "2vo|||",
                "Mauritius": "2vk|2vi||",
                "Mawson": "2vh|||",
                "Mexico_Northwest": "2vg|2vf||",
                "Mexico_Pacific": "2vb|2ul||",
                "Mongolia": "2uf|2uk||",
                "Moscow": "2ui|2ue||",
                "Myanmar": "2ud|||",
                "Nauru": "2u6|||",
                "Nepal": "2u5|||",
                "New_Caledonia": "2u4|2u1||",
                "New_Zealand": "2tt|2to||",
                "Newfoundland": "2tk|2s2||",
                "Niue": "2rn|||",
                "Norfolk": "2qi|||",
                "Noronha": "1l6,5,2qc|1l6,5,2q3||",
                "North_Mariana": "2pv|||",
                "Novosibirsk": "2pr|2pq||",
                "Omsk": "2p9|2ov||",
                "Pakistan": "2ou|2o5||",
                "Palau": "2o1|||",
                "Papua_New_Guinea": "2nu|||",
                "Paraguay": "2np|2nj||",
                "Peru": "2ni|2nc||",
                "Philippines": "2n8|2n7||",
                "Phoenix_Islands": "2mn|||",
                "Pierre_Miquelon": "2ml|2mj||",
                "Pitcairn": "2mh|||",
                "Ponape": "2m7|||",
                "Pyongyang": "2m5|||",
                "Qyzylorda": "2m0|2ls||",
                "Reunion": "2lr|||",
                "Rothera": "31p|||",
                "Sakhalin": "31m|31l||",
                "Samara": "316|30b||",
                "Samoa": "30a|2uv||",
                "Seychelles": "2us|||",
                "Singapore": "2ur|||",
                "Solomon": "2up|||",
                "South_Georgia": "2un|||",
                "Suriname": "2u8|||",
                "Syowa": "2tr|||",
                "Tahiti": "2th|||",
                "Taipei": "2tf|2td||",
                "Tajikistan": "2ta|||",
                "Tokelau": "2sr|||",
                "Tonga": "2sg|2s4||",
                "Truk": "2rg|||",
                "Turkmenistan": "2re|2pt||",
                "Tuvalu": "2oq|||",
                "Uruguay": "2ol|2nl||",
                "Uzbekistan": "2ng|2m3||",
                "Vanuatu": "31f|31d||",
                "Venezuela": "313|||",
                "Vladivostok": "30m|30h||",
                "Volgograd": "30e|30c||",
                "Vostok": "308|||",
                "Wake": "305|||",
                "Wallis": "302|||",
                "Yakutsk": "301|2vc||",
                "Yekaterinburg": "2v9|2v6||"
            }
        },
        "zh-Hans-MO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tp||",
                "Europe/London": "|2ti||"
            },
            "metazone": {
                "Acre": "2t9|2t8||",
                "Afghanistan": "2t7|||",
                "Africa_Central": "2t6|||",
                "Africa_Eastern": "2t5|||",
                "Africa_Southern": "2t4|||",
                "Africa_Western": "2t3|2t1||",
                "Alaska": "2t0|2sv||",
                "Almaty": "2su|2st||",
                "Amazon": "2ss|2sq||",
                "America_Central": "2sp|2sn||",
                "America_Eastern": "2sm|2sl||",
                "America_Mountain": "2sk|2sj||",
                "America_Pacific": "2si|2sh||",
                "Anadyr": "2sf|2sd||",
                "Apia": "2sc|2sb||",
                "Aqtau": "2sa|2s8||",
                "Aqtobe": "2s6|2s3||",
                "Arabian": "2s1|2s0||",
                "Argentina": "2rv|2ru||",
                "Argentina_Western": "2rr|2rq||",
                "Armenia": "2rp|2ro||",
                "Atlantic": "2rm|2rk||",
                "Australia_Central": "2rj|2ri||",
                "Australia_CentralWestern": "2rh|2rf||",
                "Australia_Eastern": "2rd|2rc||",
                "Australia_Western": "2rb|2r8||",
                "Azerbaijan": "2r7|2r6||",
                "Azores": "2qj|2qf||",
                "Bangladesh": "2q9|2q8||",
                "Bhutan": "2q7|||",
                "Bolivia": "2q2|||",
                "Brasilia": "2pm|2pj||",
                "Brunei": "2pi|||",
                "Cape_Verde": "2ph|2pg||",
                "Casey": "2os|||",
                "Chamorro": "2on|||",
                "Chatham": "2om|2ok||",
                "Chile": "2oj|2oi||",
                "China": "2of|2oe||",
                "Choibalsan": "2od|2oc||",
                "Christmas": "2o9|||",
                "Cocos": "2o0|||",
                "Colombia": "2nt|2ns||",
                "Cook": "2nr|2nq||",
                "Cuba": "2nk|2n1||",
                "Davis": "2n0|||",
                "DumontDUrville": "2n5|||",
                "East_Timor": "318|||",
                "Easter": "314|30u||",
                "Ecuador": "304|||",
                "Europe_Central": "2vs|2vn||",
                "Europe_Eastern": "2uj|2uc||",
                "Europe_Further_Eastern": "2u9|||",
                "Europe_Western": "2tn|2r5||",
                "Falkland": "2r2|2r1||",
                "Fiji": "2r0|2qu||",
                "French_Guiana": "2qs|||",
                "French_Southern": "2qp|||",
                "Galapagos": "2qo|||",
                "Gambier": "2qn|||",
                "Georgia": "2ql|2qk||",
                "Gilbert_Islands": "2qh|||",
                "GMT": "2qg|||",
                "Greenland_Eastern": "2qe|2qa||",
                "Greenland_Western": "2q6|2q4||",
                "Guam": "2q1|||",
                "Gulf": "2pu|||",
                "Guyana": "2po|||",
                "Hawaii_Aleutian": "166,5,2pa|166,5,2p4||",
                "Hong_Kong": "2or|2oh||",
                "Hovd": "2o6|2o4||",
                "India": "2o3|||",
                "Indian_Ocean": "2o2|||",
                "Indochina": "2no|||",
                "Indonesia_Central": "2nn|||",
                "Indonesia_Eastern": "2nm|||",
                "Indonesia_Western": "2nh|||",
                "Iran": "2ne|2v5||",
                "Irkutsk": "2nb|2na||",
                "Israel": "2n6|2mv||",
                "Japan": "2mu|2mq||",
                "Kamchatka": "1lk,5,2mm|1lk,5,2mg||",
                "Kazakhstan_Eastern": "2mf|||",
                "Kazakhstan_Western": "2md|||",
                "Korea": "2mc|2mb||",
                "Kosrae": "2ma|||",
                "Krasnoyarsk": "2m9|2m8||",
                "Kyrgystan": "2m2|||",
                "Lanka": "31g|||",
                "Line_Islands": "317|||",
                "Lord_Howe": "30t|30o||",
                "Macau": "30d|309||",
                "Macquarie": "306|||",
                "Magadan": "303|300||",
                "Malaysia": "2vu|||",
                "Maldives": "2vr|||",
                "Marquesas": "2vp|||",
                "Marshall_Islands": "2vo|||",
                "Mauritius": "2vk|2vi||",
                "Mawson": "2vh|||",
                "Mexico_Northwest": "2vg|2vf||",
                "Mexico_Pacific": "2vb|2ul||",
                "Mongolia": "2uf|2uk||",
                "Moscow": "2ui|2ue||",
                "Myanmar": "2ud|||",
                "Nauru": "2u6|||",
                "Nepal": "2u5|||",
                "New_Caledonia": "2u4|2u1||",
                "New_Zealand": "2tt|2to||",
                "Newfoundland": "2tk|2s2||",
                "Niue": "2rn|||",
                "Norfolk": "2qi|||",
                "Noronha": "1l6,5,2qc|1l6,5,2q3||",
                "North_Mariana": "2pv|||",
                "Novosibirsk": "2pr|2pq||",
                "Omsk": "2p9|2ov||",
                "Pakistan": "2ou|2o5||",
                "Palau": "2o1|||",
                "Papua_New_Guinea": "2nu|||",
                "Paraguay": "2np|2nj||",
                "Peru": "2ni|2nc||",
                "Philippines": "2n8|2n7||",
                "Phoenix_Islands": "2mn|||",
                "Pierre_Miquelon": "2ml|2mj||",
                "Pitcairn": "2mh|||",
                "Ponape": "2m7|||",
                "Pyongyang": "2m5|||",
                "Qyzylorda": "2m0|2ls||",
                "Reunion": "2lr|||",
                "Rothera": "31p|||",
                "Sakhalin": "31m|31l||",
                "Samara": "316|30b||",
                "Samoa": "30a|2uv||",
                "Seychelles": "2us|||",
                "Singapore": "2ur|||",
                "Solomon": "2up|||",
                "South_Georgia": "2un|||",
                "Suriname": "2u8|||",
                "Syowa": "2tr|||",
                "Tahiti": "2th|||",
                "Taipei": "2tf|2td||",
                "Tajikistan": "2ta|||",
                "Tokelau": "2sr|||",
                "Tonga": "2sg|2s4||",
                "Truk": "2rg|||",
                "Turkmenistan": "2re|2pt||",
                "Tuvalu": "2oq|||",
                "Uruguay": "2ol|2nl||",
                "Uzbekistan": "2ng|2m3||",
                "Vanuatu": "31f|31d||",
                "Venezuela": "313|||",
                "Vladivostok": "30m|30h||",
                "Volgograd": "30e|30c||",
                "Vostok": "308|||",
                "Wake": "305|||",
                "Wallis": "302|||",
                "Yakutsk": "301|2vc||",
                "Yekaterinburg": "2v9|2v6||"
            }
        },
        "zh-Hans-SG": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tp||",
                "Europe/London": "|2ti||"
            },
            "metazone": {
                "Acre": "2t9|2t8||",
                "Afghanistan": "2t7|||",
                "Africa_Central": "2t6|||",
                "Africa_Eastern": "2t5|||",
                "Africa_Southern": "2t4|||",
                "Africa_Western": "2t3|2t1||",
                "Alaska": "2t0|2sv||",
                "Almaty": "2su|2st||",
                "Amazon": "2ss|2sq||",
                "America_Central": "2sp|2sn||",
                "America_Eastern": "2sm|2sl||",
                "America_Mountain": "2sk|2sj||",
                "America_Pacific": "2si|2sh||",
                "Anadyr": "2sf|2sd||",
                "Apia": "2sc|2sb||",
                "Aqtau": "2sa|2s8||",
                "Aqtobe": "2s6|2s3||",
                "Arabian": "2s1|2s0||",
                "Argentina": "2rv|2ru||",
                "Argentina_Western": "2rr|2rq||",
                "Armenia": "2rp|2ro||",
                "Atlantic": "2rm|2rk||",
                "Australia_Central": "2rj|2ri||",
                "Australia_CentralWestern": "2rh|2rf||",
                "Australia_Eastern": "2rd|2rc||",
                "Australia_Western": "2rb|2r8||",
                "Azerbaijan": "2r7|2r6||",
                "Azores": "2qj|2qf||",
                "Bangladesh": "2q9|2q8||",
                "Bhutan": "2q7|||",
                "Bolivia": "2q2|||",
                "Brasilia": "2pm|2pj||",
                "Brunei": "2pi|||",
                "Cape_Verde": "2ph|2pg||",
                "Casey": "2os|||",
                "Chamorro": "2on|||",
                "Chatham": "2om|2ok||",
                "Chile": "2oj|2oi||",
                "China": "2of|2oe||",
                "Choibalsan": "2od|2oc||",
                "Christmas": "2o9|||",
                "Cocos": "2o0|||",
                "Colombia": "2nt|2ns||",
                "Cook": "2nr|2nq||",
                "Cuba": "2nk|2n1||",
                "Davis": "2n0|||",
                "DumontDUrville": "2n5|||",
                "East_Timor": "318|||",
                "Easter": "314|30u||",
                "Ecuador": "304|||",
                "Europe_Central": "2vs|2vn||",
                "Europe_Eastern": "2uj|2uc||",
                "Europe_Further_Eastern": "2u9|||",
                "Europe_Western": "2tn|2r5||",
                "Falkland": "2r2|2r1||",
                "Fiji": "2r0|2qu||",
                "French_Guiana": "2qs|||",
                "French_Southern": "2qp|||",
                "Galapagos": "2qo|||",
                "Gambier": "2qn|||",
                "Georgia": "2ql|2qk||",
                "Gilbert_Islands": "2qh|||",
                "GMT": "2qg|||",
                "Greenland_Eastern": "2qe|2qa||",
                "Greenland_Western": "2q6|2q4||",
                "Guam": "2q1|||",
                "Gulf": "2pu|||",
                "Guyana": "2po|||",
                "Hawaii_Aleutian": "166,5,2pa|166,5,2p4||",
                "Hong_Kong": "2or|2oh||",
                "Hovd": "2o6|2o4||",
                "India": "2o3|||",
                "Indian_Ocean": "2o2|||",
                "Indochina": "2no|||",
                "Indonesia_Central": "2nn|||",
                "Indonesia_Eastern": "2nm|||",
                "Indonesia_Western": "2nh|||",
                "Iran": "2ne|2v5||",
                "Irkutsk": "2nb|2na||",
                "Israel": "2n6|2mv||",
                "Japan": "2mu|2mq||",
                "Kamchatka": "1lk,5,2mm|1lk,5,2mg||",
                "Kazakhstan_Eastern": "2mf|||",
                "Kazakhstan_Western": "2md|||",
                "Korea": "2mc|2mb||",
                "Kosrae": "2ma|||",
                "Krasnoyarsk": "2m9|2m8||",
                "Kyrgystan": "2m2|||",
                "Lanka": "31g|||",
                "Line_Islands": "317|||",
                "Lord_Howe": "30t|30o||",
                "Macau": "30d|309||",
                "Macquarie": "306|||",
                "Magadan": "303|300||",
                "Malaysia": "2vu||1r5|",
                "Maldives": "2vr|||",
                "Marquesas": "2vp|||",
                "Marshall_Islands": "2vo|||",
                "Mauritius": "2vk|2vi||",
                "Mawson": "2vh|||",
                "Mexico_Northwest": "2vg|2vf||",
                "Mexico_Pacific": "2vb|2ul||",
                "Mongolia": "2uf|2uk||",
                "Moscow": "2ui|2ue||",
                "Myanmar": "2ud|||",
                "Nauru": "2u6|||",
                "Nepal": "2u5|||",
                "New_Caledonia": "2u4|2u1||",
                "New_Zealand": "2tt|2to||",
                "Newfoundland": "2tk|2s2||",
                "Niue": "2rn|||",
                "Norfolk": "2qi|||",
                "Noronha": "1l6,5,2qc|1l6,5,2q3||",
                "North_Mariana": "2pv|||",
                "Novosibirsk": "2pr|2pq||",
                "Omsk": "2p9|2ov||",
                "Pakistan": "2ou|2o5||",
                "Palau": "2o1|||",
                "Papua_New_Guinea": "2nu|||",
                "Paraguay": "2np|2nj||",
                "Peru": "2ni|2nc||",
                "Philippines": "2n8|2n7||",
                "Phoenix_Islands": "2mn|||",
                "Pierre_Miquelon": "2ml|2mj||",
                "Pitcairn": "2mh|||",
                "Ponape": "2m7|||",
                "Pyongyang": "2m5|||",
                "Qyzylorda": "2m0|2ls||",
                "Reunion": "2lr|||",
                "Rothera": "31p|||",
                "Sakhalin": "31m|31l||",
                "Samara": "316|30b||",
                "Samoa": "30a|2uv||",
                "Seychelles": "2us|||",
                "Singapore": "2ur||2ad|",
                "Solomon": "2up|||",
                "South_Georgia": "2un|||",
                "Suriname": "2u8|||",
                "Syowa": "2tr|||",
                "Tahiti": "2th|||",
                "Taipei": "2tf|2td||",
                "Tajikistan": "2ta|||",
                "Tokelau": "2sr|||",
                "Tonga": "2sg|2s4||",
                "Truk": "2rg|||",
                "Turkmenistan": "2re|2pt||",
                "Tuvalu": "2oq|||",
                "Uruguay": "2ol|2nl||",
                "Uzbekistan": "2ng|2m3||",
                "Vanuatu": "31f|31d||",
                "Venezuela": "313|||",
                "Vladivostok": "30m|30h||",
                "Volgograd": "30e|30c||",
                "Vostok": "308|||",
                "Wake": "305|||",
                "Wallis": "302|||",
                "Yakutsk": "301|2vc||",
                "Yekaterinburg": "2v9|2v6||"
            }
        },
        "zh-Hans": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tp||",
                "Europe/London": "|2ti||"
            },
            "metazone": {
                "Acre": "2t9|2t8||",
                "Afghanistan": "2t7|||",
                "Africa_Central": "2t6|||",
                "Africa_Eastern": "2t5|||",
                "Africa_Southern": "2t4|||",
                "Africa_Western": "2t3|2t1||",
                "Alaska": "2t0|2sv||",
                "Almaty": "2su|2st||",
                "Amazon": "2ss|2sq||",
                "America_Central": "2sp|2sn||",
                "America_Eastern": "2sm|2sl||",
                "America_Mountain": "2sk|2sj||",
                "America_Pacific": "2si|2sh||",
                "Anadyr": "2sf|2sd||",
                "Apia": "2sc|2sb||",
                "Aqtau": "2sa|2s8||",
                "Aqtobe": "2s6|2s3||",
                "Arabian": "2s1|2s0||",
                "Argentina": "2rv|2ru||",
                "Argentina_Western": "2rr|2rq||",
                "Armenia": "2rp|2ro||",
                "Atlantic": "2rm|2rk||",
                "Australia_Central": "2rj|2ri||",
                "Australia_CentralWestern": "2rh|2rf||",
                "Australia_Eastern": "2rd|2rc||",
                "Australia_Western": "2rb|2r8||",
                "Azerbaijan": "2r7|2r6||",
                "Azores": "2qj|2qf||",
                "Bangladesh": "2q9|2q8||",
                "Bhutan": "2q7|||",
                "Bolivia": "2q2|||",
                "Brasilia": "2pm|2pj||",
                "Brunei": "2pi|||",
                "Cape_Verde": "2ph|2pg||",
                "Casey": "2os|||",
                "Chamorro": "2on|||",
                "Chatham": "2om|2ok||",
                "Chile": "2oj|2oi||",
                "China": "2of|2oe||",
                "Choibalsan": "2od|2oc||",
                "Christmas": "2o9|||",
                "Cocos": "2o0|||",
                "Colombia": "2nt|2ns||",
                "Cook": "2nr|2nq||",
                "Cuba": "2nk|2n1||",
                "Davis": "2n0|||",
                "DumontDUrville": "2n5|||",
                "East_Timor": "318|||",
                "Easter": "314|30u||",
                "Ecuador": "304|||",
                "Europe_Central": "2vs|2vn||",
                "Europe_Eastern": "2uj|2uc||",
                "Europe_Further_Eastern": "2u9|||",
                "Europe_Western": "2tn|2r5||",
                "Falkland": "2r2|2r1||",
                "Fiji": "2r0|2qu||",
                "French_Guiana": "2qs|||",
                "French_Southern": "2qp|||",
                "Galapagos": "2qo|||",
                "Gambier": "2qn|||",
                "Georgia": "2ql|2qk||",
                "Gilbert_Islands": "2qh|||",
                "GMT": "2qg|||",
                "Greenland_Eastern": "2qe|2qa||",
                "Greenland_Western": "2q6|2q4||",
                "Guam": "2q1|||",
                "Gulf": "2pu|||",
                "Guyana": "2po|||",
                "Hawaii_Aleutian": "166,5,2pa|166,5,2p4||",
                "Hong_Kong": "2or|2oh||",
                "Hovd": "2o6|2o4||",
                "India": "2o3|||",
                "Indian_Ocean": "2o2|||",
                "Indochina": "2no|||",
                "Indonesia_Central": "2nn|||",
                "Indonesia_Eastern": "2nm|||",
                "Indonesia_Western": "2nh|||",
                "Iran": "2ne|2v5||",
                "Irkutsk": "2nb|2na||",
                "Israel": "2n6|2mv||",
                "Japan": "2mu|2mq||",
                "Kamchatka": "1lk,5,2mm|1lk,5,2mg||",
                "Kazakhstan_Eastern": "2mf|||",
                "Kazakhstan_Western": "2md|||",
                "Korea": "2mc|2mb||",
                "Kosrae": "2ma|||",
                "Krasnoyarsk": "2m9|2m8||",
                "Kyrgystan": "2m2|||",
                "Lanka": "31g|||",
                "Line_Islands": "317|||",
                "Lord_Howe": "30t|30o||",
                "Macau": "30d|309||",
                "Macquarie": "306|||",
                "Magadan": "303|300||",
                "Malaysia": "2vu|||",
                "Maldives": "2vr|||",
                "Marquesas": "2vp|||",
                "Marshall_Islands": "2vo|||",
                "Mauritius": "2vk|2vi||",
                "Mawson": "2vh|||",
                "Mexico_Northwest": "2vg|2vf||",
                "Mexico_Pacific": "2vb|2ul||",
                "Mongolia": "2uf|2uk||",
                "Moscow": "2ui|2ue||",
                "Myanmar": "2ud|||",
                "Nauru": "2u6|||",
                "Nepal": "2u5|||",
                "New_Caledonia": "2u4|2u1||",
                "New_Zealand": "2tt|2to||",
                "Newfoundland": "2tk|2s2||",
                "Niue": "2rn|||",
                "Norfolk": "2qi|||",
                "Noronha": "1l6,5,2qc|1l6,5,2q3||",
                "North_Mariana": "2pv|||",
                "Novosibirsk": "2pr|2pq||",
                "Omsk": "2p9|2ov||",
                "Pakistan": "2ou|2o5||",
                "Palau": "2o1|||",
                "Papua_New_Guinea": "2nu|||",
                "Paraguay": "2np|2nj||",
                "Peru": "2ni|2nc||",
                "Philippines": "2n8|2n7||",
                "Phoenix_Islands": "2mn|||",
                "Pierre_Miquelon": "2ml|2mj||",
                "Pitcairn": "2mh|||",
                "Ponape": "2m7|||",
                "Pyongyang": "2m5|||",
                "Qyzylorda": "2m0|2ls||",
                "Reunion": "2lr|||",
                "Rothera": "31p|||",
                "Sakhalin": "31m|31l||",
                "Samara": "316|30b||",
                "Samoa": "30a|2uv||",
                "Seychelles": "2us|||",
                "Singapore": "2ur|||",
                "Solomon": "2up|||",
                "South_Georgia": "2un|||",
                "Suriname": "2u8|||",
                "Syowa": "2tr|||",
                "Tahiti": "2th|||",
                "Taipei": "2tf|2td||",
                "Tajikistan": "2ta|||",
                "Tokelau": "2sr|||",
                "Tonga": "2sg|2s4||",
                "Truk": "2rg|||",
                "Turkmenistan": "2re|2pt||",
                "Tuvalu": "2oq|||",
                "Uruguay": "2ol|2nl||",
                "Uzbekistan": "2ng|2m3||",
                "Vanuatu": "31f|31d||",
                "Venezuela": "313|||",
                "Vladivostok": "30m|30h||",
                "Volgograd": "30e|30c||",
                "Vostok": "308|||",
                "Wake": "305|||",
                "Wallis": "302|||",
                "Yakutsk": "301|2vc||",
                "Yekaterinburg": "2v9|2v6||"
            }
        },
        "zh-Hant-HK": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|39v||",
                "Europe/London": "|39t||"
            },
            "metazone": {
                "Acre": "3rc|3rd||",
                "Afghanistan": "3rh|||",
                "Africa_Central": "3ri|||",
                "Africa_Eastern": "3rj|||",
                "Africa_Southern": "8hl|||",
                "Africa_Western": "3rk|3rl||",
                "Alaska": "3rn|31r||",
                "Almaty": "3ru|3rv||",
                "Amazon": "3s4|3s6||",
                "America_Central": "8gh|3s8||",
                "America_Eastern": "8fr|8fp||",
                "America_Mountain": "8fe|8fa||",
                "America_Pacific": "8f8|8dt||",
                "Anadyr": "3s9|3sa||",
                "Apia": "3sb|3sc||",
                "Aqtau": "3sd|3se||",
                "Aqtobe": "3sf|3sj||",
                "Arabian": "3sk|3sl||",
                "Argentina": "3so|3sr||",
                "Argentina_Western": "3sv|3t1||",
                "Armenia": "3t2|3t4||",
                "Atlantic": "3t6|3t7||",
                "Australia_Central": "3tb|3tc||",
                "Australia_CentralWestern": "3td|3tf||",
                "Australia_Eastern": "3tg|3th||",
                "Australia_Western": "3ti|3tj||",
                "Azerbaijan": "8d5|8d2||",
                "Azores": "3tm|3tn||",
                "Bangladesh": "3tp|3ts||",
                "Bhutan": "3tt|||",
                "Bolivia": "3tu|||",
                "Brasilia": "3tv|3u0||",
                "Brunei": "3u2|||",
                "Cape_Verde": "8cn|8c9||",
                "Casey": "3u3|||",
                "Chamorro": "3u5|||",
                "Chatham": "3u6|3u7||",
                "Chile": "3u8|3u9||",
                "China": "3ua|3ub||",
                "Choibalsan": "3uc|3ud||",
                "Christmas": "3ue|||",
                "Cocos": "8bu|||",
                "Colombia": "3uf|3ui||",
                "Cook": "3uj|3uk||",
                "Cuba": "3ul|3um||",
                "Davis": "3un|||",
                "DumontDUrville": "8bg|||",
                "East_Timor": "3uo|||",
                "Easter": "3up|3uq||",
                "Ecuador": "8bb|||",
                "Europe_Central": "3ur|3us||",
                "Europe_Eastern": "3ut|3uu||",
                "Europe_Further_Eastern": "3uv|||",
                "Europe_Western": "3v0|3v1||",
                "Falkland": "3v2|3v3||",
                "Fiji": "3v4|3v6||",
                "French_Guiana": "3v9|||",
                "French_Southern": "3va|||",
                "Galapagos": "8b8|||",
                "Gambier": "3vb|||",
                "Georgia": "8al|8aj||",
                "Gilbert_Islands": "3vd|||",
                "GMT": "3ve|||",
                "Greenland_Eastern": "3vg|3vi||",
                "Greenland_Western": "3vj|3vk||",
                "Guam": "404|||",
                "Gulf": "8a2|||",
                "Guyana": "89l|||",
                "Hawaii_Aleutian": "166,5,405|166,5,409||",
                "Hong_Kong": "40b|40d||",
                "Hovd": "40l|40o||",
                "India": "89f|||",
                "Indian_Ocean": "419|||",
                "Indochina": "89c|||",
                "Indonesia_Central": "434|||",
                "Indonesia_Eastern": "435|||",
                "Indonesia_Western": "436|||",
                "Iran": "437|439||",
                "Irkutsk": "7v9|7rp||",
                "Israel": "43b|43d||",
                "Japan": "43e|43f||",
                "Kamchatka": "43g|43h||",
                "Kazakhstan_Eastern": "43n|||",
                "Kazakhstan_Western": "43o|||",
                "Korea": "43p|43q||",
                "Kosrae": "7rc|||",
                "Krasnoyarsk": "43r|43t||",
                "Kyrgystan": "441|||",
                "Lanka": "442|||",
                "Line_Islands": "444|||",
                "Lord_Howe": "449|44a||",
                "Macau": "44e|44g||",
                "Macquarie": "7qp|||",
                "Magadan": "44o|44p||",
                "Malaysia": "44q|||",
                "Maldives": "7qo|||",
                "Marquesas": "7q8|||",
                "Marshall_Islands": "474|||",
                "Mauritius": "7pc|7on||",
                "Mawson": "476|||",
                "Mexico_Northwest": "477|478||",
                "Mexico_Pacific": "479|47a||",
                "Mongolia": "47b|47c||",
                "Moscow": "47g|47h||",
                "Myanmar": "47i|||",
                "Nauru": "7fl|||",
                "Nepal": "47j|||",
                "New_Caledonia": "47k|7fg||",
                "New_Zealand": "47l|47m||",
                "Newfoundland": "47n|47o||",
                "Niue": "47q|||",
                "Norfolk": "47r|||",
                "Noronha": "7ah|79s||",
                "North_Mariana": "47u|||",
                "Novosibirsk": "47v|480||",
                "Omsk": "481|482||",
                "Pakistan": "483|484||",
                "Palau": "485|||",
                "Papua_New_Guinea": "79n|||",
                "Paraguay": "486|487||",
                "Peru": "488|48b||",
                "Philippines": "48c|48d||",
                "Phoenix_Islands": "48e|||",
                "Pierre_Miquelon": "48f|48g||",
                "Pitcairn": "79m|||",
                "Ponape": "48h|||",
                "Pyongyang": "4le|||",
                "Qyzylorda": "48i|48j||",
                "Reunion": "48k|||",
                "Rothera": "48m|||",
                "Sakhalin": "48n|48o||",
                "Samara": "48p|48q||",
                "Samoa": "48r|48s||",
                "Seychelles": "6pm|||",
                "Singapore": "6pl|||",
                "Solomon": "6pc|||",
                "South_Georgia": "48v|||",
                "Suriname": "595|||",
                "Syowa": "2op|||",
                "Tahiti": "494|||",
                "Taipei": "49g|49h||",
                "Tajikistan": "49k|||",
                "Tokelau": "49l|||",
                "Tonga": "541|8j0||",
                "Truk": "49m|||",
                "Turkmenistan": "49n|4a3||",
                "Tuvalu": "8ad|||",
                "Uruguay": "4a6|4a7||",
                "Uzbekistan": "4a9|4ac||",
                "Vanuatu": "7p4|7oj||",
                "Venezuela": "4ad|||",
                "Vladivostok": "4ae|4af||",
                "Volgograd": "4ag|4ah||",
                "Vostok": "4ai|||",
                "Wake": "4aj|||",
                "Wallis": "4ak|||",
                "Yakutsk": "6q9|6ot||",
                "Yekaterinburg": "4al|4am||"
            }
        },
        "zh-Hant-MO": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|39v||",
                "Europe/London": "|39t||"
            },
            "metazone": {
                "Acre": "3rc|3rd||",
                "Afghanistan": "3rh|||",
                "Africa_Central": "3ri|||",
                "Africa_Eastern": "3rj|||",
                "Africa_Southern": "8hl|||",
                "Africa_Western": "3rk|3rl||",
                "Alaska": "3rn|31r||",
                "Almaty": "3ru|3rv||",
                "Amazon": "3s4|3s6||",
                "America_Central": "8gh|3s8||",
                "America_Eastern": "8fr|8fp||",
                "America_Mountain": "8fe|8fa||",
                "America_Pacific": "8f8|8dt||",
                "Anadyr": "3s9|3sa||",
                "Apia": "3sb|3sc||",
                "Aqtau": "3sd|3se||",
                "Aqtobe": "3sf|3sj||",
                "Arabian": "3sk|3sl||",
                "Argentina": "3so|3sr||",
                "Argentina_Western": "3sv|3t1||",
                "Armenia": "3t2|3t4||",
                "Atlantic": "3t6|3t7||",
                "Australia_Central": "3tb|3tc||",
                "Australia_CentralWestern": "3td|3tf||",
                "Australia_Eastern": "3tg|3th||",
                "Australia_Western": "3ti|3tj||",
                "Azerbaijan": "8d5|8d2||",
                "Azores": "3tm|3tn||",
                "Bangladesh": "3tp|3ts||",
                "Bhutan": "3tt|||",
                "Bolivia": "3tu|||",
                "Brasilia": "3tv|3u0||",
                "Brunei": "3u2|||",
                "Cape_Verde": "8cn|8c9||",
                "Casey": "3u3|||",
                "Chamorro": "3u5|||",
                "Chatham": "3u6|3u7||",
                "Chile": "3u8|3u9||",
                "China": "3ua|3ub||",
                "Choibalsan": "3uc|3ud||",
                "Christmas": "3ue|||",
                "Cocos": "8bu|||",
                "Colombia": "3uf|3ui||",
                "Cook": "3uj|3uk||",
                "Cuba": "3ul|3um||",
                "Davis": "3un|||",
                "DumontDUrville": "8bg|||",
                "East_Timor": "3uo|||",
                "Easter": "3up|3uq||",
                "Ecuador": "8bb|||",
                "Europe_Central": "3ur|3us||",
                "Europe_Eastern": "3ut|3uu||",
                "Europe_Further_Eastern": "3uv|||",
                "Europe_Western": "3v0|3v1||",
                "Falkland": "3v2|3v3||",
                "Fiji": "3v4|3v6||",
                "French_Guiana": "3v9|||",
                "French_Southern": "3va|||",
                "Galapagos": "8b8|||",
                "Gambier": "3vb|||",
                "Georgia": "8al|8aj||",
                "Gilbert_Islands": "3vd|||",
                "GMT": "3ve|||",
                "Greenland_Eastern": "3vg|3vi||",
                "Greenland_Western": "3vj|3vk||",
                "Guam": "404|||",
                "Gulf": "8a2|||",
                "Guyana": "89l|||",
                "Hawaii_Aleutian": "166,5,405|166,5,409||",
                "Hong_Kong": "40b|40d||",
                "Hovd": "40l|40o||",
                "India": "89f|||",
                "Indian_Ocean": "419|||",
                "Indochina": "89c|||",
                "Indonesia_Central": "434|||",
                "Indonesia_Eastern": "435|||",
                "Indonesia_Western": "436|||",
                "Iran": "437|439||",
                "Irkutsk": "7v9|7rp||",
                "Israel": "43b|43d||",
                "Japan": "43e|43f||",
                "Kamchatka": "43g|43h||",
                "Kazakhstan_Eastern": "43n|||",
                "Kazakhstan_Western": "43o|||",
                "Korea": "43p|43q||",
                "Kosrae": "7rc|||",
                "Krasnoyarsk": "43r|43t||",
                "Kyrgystan": "441|||",
                "Lanka": "442|||",
                "Line_Islands": "444|||",
                "Lord_Howe": "449|44a||",
                "Macau": "44e|44g||",
                "Macquarie": "7qp|||",
                "Magadan": "44o|44p||",
                "Malaysia": "44q|||",
                "Maldives": "7qo|||",
                "Marquesas": "7q8|||",
                "Marshall_Islands": "474|||",
                "Mauritius": "7pc|7on||",
                "Mawson": "476|||",
                "Mexico_Northwest": "477|478||",
                "Mexico_Pacific": "479|47a||",
                "Mongolia": "47b|47c||",
                "Moscow": "47g|47h||",
                "Myanmar": "47i|||",
                "Nauru": "7fl|||",
                "Nepal": "47j|||",
                "New_Caledonia": "47k|7fg||",
                "New_Zealand": "47l|47m||",
                "Newfoundland": "47n|47o||",
                "Niue": "47q|||",
                "Norfolk": "47r|||",
                "Noronha": "7ah|79s||",
                "North_Mariana": "47u|||",
                "Novosibirsk": "47v|480||",
                "Omsk": "481|482||",
                "Pakistan": "483|484||",
                "Palau": "485|||",
                "Papua_New_Guinea": "79n|||",
                "Paraguay": "486|487||",
                "Peru": "488|48b||",
                "Philippines": "48c|48d||",
                "Phoenix_Islands": "48e|||",
                "Pierre_Miquelon": "48f|48g||",
                "Pitcairn": "79m|||",
                "Ponape": "48h|||",
                "Pyongyang": "4le|||",
                "Qyzylorda": "48i|48j||",
                "Reunion": "48k|||",
                "Rothera": "48m|||",
                "Sakhalin": "48n|48o||",
                "Samara": "48p|48q||",
                "Samoa": "48r|48s||",
                "Seychelles": "6pm|||",
                "Singapore": "6pl|||",
                "Solomon": "6pc|||",
                "South_Georgia": "48v|||",
                "Suriname": "595|||",
                "Syowa": "2op|||",
                "Tahiti": "494|||",
                "Taipei": "49g|49h||",
                "Tajikistan": "49k|||",
                "Tokelau": "49l|||",
                "Tonga": "541|8j0||",
                "Truk": "49m|||",
                "Turkmenistan": "49n|4a3||",
                "Tuvalu": "8ad|||",
                "Uruguay": "4a6|4a7||",
                "Uzbekistan": "4a9|4ac||",
                "Vanuatu": "7p4|7oj||",
                "Venezuela": "4ad|||",
                "Vladivostok": "4ae|4af||",
                "Volgograd": "4ag|4ah||",
                "Vostok": "4ai|||",
                "Wake": "4aj|||",
                "Wallis": "4ak|||",
                "Yakutsk": "6q9|6ot||",
                "Yekaterinburg": "4al|4am||"
            }
        },
        "zh-Hant": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|39v||",
                "Europe/London": "|39t||"
            },
            "metazone": {
                "Acre": "3rc|3rd||",
                "Afghanistan": "3rh|||",
                "Africa_Central": "3ri|||",
                "Africa_Eastern": "3rj|||",
                "Africa_Southern": "4u9|||",
                "Africa_Western": "3rk|3rl||",
                "Alaska": "3rn|31r||",
                "Almaty": "3ru|3rv||",
                "Amazon": "3s4|3s6||",
                "America_Central": "4ti|3s8||",
                "America_Eastern": "4ta|4t9||",
                "America_Mountain": "4t5|4t3||",
                "America_Pacific": "4t1|4t0||",
                "Anadyr": "3s9|3sa||",
                "Apia": "3sb|3sc||",
                "Aqtau": "3sd|3se||",
                "Aqtobe": "3sf|3sj||",
                "Arabian": "3sk|3sl||",
                "Argentina": "3so|3sr||",
                "Argentina_Western": "3sv|3t1||",
                "Armenia": "3t2|3t4||",
                "Atlantic": "3t6|3t7||",
                "Australia_Central": "3tb|3tc||",
                "Australia_CentralWestern": "3td|3tf||",
                "Australia_Eastern": "3tg|3th||",
                "Australia_Western": "3ti|3tj||",
                "Azerbaijan": "8hg|8h8||",
                "Azores": "3tm|3tn||",
                "Bangladesh": "3tp|3ts||",
                "Bhutan": "3tt|||",
                "Bolivia": "3tu|||",
                "Brasilia": "3tv|3u0||",
                "Brunei": "3u2|||",
                "Cape_Verde": "8fo|8fj||",
                "Casey": "3u3|||",
                "Chamorro": "3u5|||",
                "Chatham": "3u6|3u7||",
                "Chile": "3u8|3u9||",
                "China": "3ua|3ub||",
                "Choibalsan": "3uc|3ud||",
                "Christmas": "3ue|||",
                "Cocos": "8dn|||",
                "Colombia": "3uf|3ui||",
                "Cook": "3uj|3uk||",
                "Cuba": "3ul|3um||",
                "Davis": "3un|||",
                "DumontDUrville": "8ch|||",
                "East_Timor": "3uo|||",
                "Easter": "3up|3uq||",
                "Ecuador": "8bs|||",
                "Europe_Central": "3ur|3us||",
                "Europe_Eastern": "3ut|3uu||",
                "Europe_Further_Eastern": "3uv|||",
                "Europe_Western": "3v0|3v1||",
                "Falkland": "3v2|3v3||",
                "Fiji": "3v4|3v6||",
                "French_Guiana": "3v9|||",
                "French_Southern": "3va|||",
                "Galapagos": "8aa|||",
                "Gambier": "3vb|||",
                "Georgia": "89s|89o||",
                "Gilbert_Islands": "3vd|||",
                "GMT": "3ve|||",
                "Greenland_Eastern": "3vg|3vi||",
                "Greenland_Western": "3vj|3vk||",
                "Guam": "404|||",
                "Gulf": "85c|||",
                "Guyana": "83u|||",
                "Hawaii_Aleutian": "166,5,405|166,5,409||",
                "Hong_Kong": "40b|40d||",
                "Hovd": "40l|40o||",
                "India": "7s2|||",
                "Indian_Ocean": "419|||",
                "Indochina": "7ro|||",
                "Indonesia_Central": "434|||",
                "Indonesia_Eastern": "435|||",
                "Indonesia_Western": "436|||",
                "Iran": "437|439||",
                "Irkutsk": "7pt|7pn||",
                "Israel": "43b|43d||",
                "Japan": "43e|43f||",
                "Kamchatka": "43g|43h||",
                "Kazakhstan_Eastern": "43n|||",
                "Kazakhstan_Western": "43o|||",
                "Korea": "43p|43q||",
                "Kosrae": "7hl|||",
                "Krasnoyarsk": "43r|43t||",
                "Kyrgystan": "441|||",
                "Lanka": "442|||",
                "Line_Islands": "444|||",
                "Lord_Howe": "449|44a||",
                "Macau": "44e|44g||",
                "Macquarie": "7bh|||",
                "Magadan": "44o|44p||",
                "Malaysia": "44q|||",
                "Maldives": "7a1|||",
                "Marquesas": "6fq|||",
                "Marshall_Islands": "474|||",
                "Mauritius": "6ds|6dk||",
                "Mawson": "476|||",
                "Mexico_Northwest": "477|478||",
                "Mexico_Pacific": "479|47a||",
                "Mongolia": "47b|47c||",
                "Moscow": "47g|47h||",
                "Myanmar": "47i|||",
                "Nauru": "69b|||",
                "Nepal": "47j|||",
                "New_Caledonia": "47k|68r||",
                "New_Zealand": "47l|47m||",
                "Newfoundland": "47n|47o||",
                "Niue": "47q|||",
                "Norfolk": "47r|||",
                "Noronha": "47s,0,5,0,681|47s,0,5,0,680||",
                "North_Mariana": "47u|||",
                "Novosibirsk": "47v|480||",
                "Omsk": "481|482||",
                "Pakistan": "483|484||",
                "Palau": "485|||",
                "Papua_New_Guinea": "65s|||",
                "Paraguay": "486|487||",
                "Peru": "488|48b||",
                "Philippines": "48c|48d||",
                "Phoenix_Islands": "48e|||",
                "Pierre_Miquelon": "48f|48g||",
                "Pitcairn": "63m|||",
                "Ponape": "48h|||",
                "Pyongyang": "4le|||",
                "Qyzylorda": "48i|48j||",
                "Reunion": "48k|||",
                "Rothera": "48m|||",
                "Sakhalin": "48n|48o||",
                "Samara": "48p|48q||",
                "Samoa": "48r|48s||",
                "Seychelles": "61n|||",
                "Singapore": "61m|||",
                "Solomon": "61e|||",
                "South_Georgia": "48v|||",
                "Suriname": "5vs|||",
                "Syowa": "2op|||",
                "Tahiti": "494|||",
                "Taipei": "49g|49h||",
                "Tajikistan": "49k|||",
                "Tokelau": "49l|||",
                "Tonga": "5ui|5ud||",
                "Truk": "49m|||",
                "Turkmenistan": "49n|4a3||",
                "Tuvalu": "5rv|||",
                "Uruguay": "4a6|4a7||",
                "Uzbekistan": "4a9|4ac||",
                "Vanuatu": "5r8|5r7||",
                "Venezuela": "4ad|||",
                "Vladivostok": "4ae|4af||",
                "Volgograd": "4ag|4ah||",
                "Vostok": "4ai|||",
                "Wake": "4aj|||",
                "Wallis": "4ak|||",
                "Yakutsk": "5q3|5q2||",
                "Yekaterinburg": "4al|4am||"
            }
        },
        "zh": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|2tp||",
                "Europe/London": "|2ti||"
            },
            "metazone": {
                "Acre": "2t9|2t8||",
                "Afghanistan": "2t7|||",
                "Africa_Central": "2t6|||",
                "Africa_Eastern": "2t5|||",
                "Africa_Southern": "2t4|||",
                "Africa_Western": "2t3|2t1||",
                "Alaska": "2t0|2sv||",
                "Almaty": "2su|2st||",
                "Amazon": "2ss|2sq||",
                "America_Central": "2sp|2sn||",
                "America_Eastern": "2sm|2sl||",
                "America_Mountain": "2sk|2sj||",
                "America_Pacific": "2si|2sh||",
                "Anadyr": "2sf|2sd||",
                "Apia": "2sc|2sb||",
                "Aqtau": "2sa|2s8||",
                "Aqtobe": "2s6|2s3||",
                "Arabian": "2s1|2s0||",
                "Argentina": "2rv|2ru||",
                "Argentina_Western": "2rr|2rq||",
                "Armenia": "2rp|2ro||",
                "Atlantic": "2rm|2rk||",
                "Australia_Central": "2rj|2ri||",
                "Australia_CentralWestern": "2rh|2rf||",
                "Australia_Eastern": "2rd|2rc||",
                "Australia_Western": "2rb|2r8||",
                "Azerbaijan": "2r7|2r6||",
                "Azores": "2qj|2qf||",
                "Bangladesh": "2q9|2q8||",
                "Bhutan": "2q7|||",
                "Bolivia": "2q2|||",
                "Brasilia": "2pm|2pj||",
                "Brunei": "2pi|||",
                "Cape_Verde": "2ph|2pg||",
                "Casey": "2os|||",
                "Chamorro": "2on|||",
                "Chatham": "2om|2ok||",
                "Chile": "2oj|2oi||",
                "China": "2of|2oe||",
                "Choibalsan": "2od|2oc||",
                "Christmas": "2o9|||",
                "Cocos": "2o0|||",
                "Colombia": "2nt|2ns||",
                "Cook": "2nr|2nq||",
                "Cuba": "2nk|2n1||",
                "Davis": "2n0|||",
                "DumontDUrville": "2n5|||",
                "East_Timor": "318|||",
                "Easter": "314|30u||",
                "Ecuador": "304|||",
                "Europe_Central": "2vs|2vn||",
                "Europe_Eastern": "2uj|2uc||",
                "Europe_Further_Eastern": "2u9|||",
                "Europe_Western": "2tn|2r5||",
                "Falkland": "2r2|2r1||",
                "Fiji": "2r0|2qu||",
                "French_Guiana": "2qs|||",
                "French_Southern": "2qp|||",
                "Galapagos": "2qo|||",
                "Gambier": "2qn|||",
                "Georgia": "2ql|2qk||",
                "Gilbert_Islands": "2qh|||",
                "GMT": "2qg|||",
                "Greenland_Eastern": "2qe|2qa||",
                "Greenland_Western": "2q6|2q4||",
                "Guam": "2q1|||",
                "Gulf": "2pu|||",
                "Guyana": "2po|||",
                "Hawaii_Aleutian": "166,5,2pa|166,5,2p4||",
                "Hong_Kong": "2or|2oh||",
                "Hovd": "2o6|2o4||",
                "India": "2o3|||",
                "Indian_Ocean": "2o2|||",
                "Indochina": "2no|||",
                "Indonesia_Central": "2nn|||",
                "Indonesia_Eastern": "2nm|||",
                "Indonesia_Western": "2nh|||",
                "Iran": "2ne|2v5||",
                "Irkutsk": "2nb|2na||",
                "Israel": "2n6|2mv||",
                "Japan": "2mu|2mq||",
                "Kamchatka": "1lk,5,2mm|1lk,5,2mg||",
                "Kazakhstan_Eastern": "2mf|||",
                "Kazakhstan_Western": "2md|||",
                "Korea": "2mc|2mb||",
                "Kosrae": "2ma|||",
                "Krasnoyarsk": "2m9|2m8||",
                "Kyrgystan": "2m2|||",
                "Lanka": "31g|||",
                "Line_Islands": "317|||",
                "Lord_Howe": "30t|30o||",
                "Macau": "30d|309||",
                "Macquarie": "306|||",
                "Magadan": "303|300||",
                "Malaysia": "2vu|||",
                "Maldives": "2vr|||",
                "Marquesas": "2vp|||",
                "Marshall_Islands": "2vo|||",
                "Mauritius": "2vk|2vi||",
                "Mawson": "2vh|||",
                "Mexico_Northwest": "2vg|2vf||",
                "Mexico_Pacific": "2vb|2ul||",
                "Mongolia": "2uf|2uk||",
                "Moscow": "2ui|2ue||",
                "Myanmar": "2ud|||",
                "Nauru": "2u6|||",
                "Nepal": "2u5|||",
                "New_Caledonia": "2u4|2u1||",
                "New_Zealand": "2tt|2to||",
                "Newfoundland": "2tk|2s2||",
                "Niue": "2rn|||",
                "Norfolk": "2qi|||",
                "Noronha": "1l6,5,2qc|1l6,5,2q3||",
                "North_Mariana": "2pv|||",
                "Novosibirsk": "2pr|2pq||",
                "Omsk": "2p9|2ov||",
                "Pakistan": "2ou|2o5||",
                "Palau": "2o1|||",
                "Papua_New_Guinea": "2nu|||",
                "Paraguay": "2np|2nj||",
                "Peru": "2ni|2nc||",
                "Philippines": "2n8|2n7||",
                "Phoenix_Islands": "2mn|||",
                "Pierre_Miquelon": "2ml|2mj||",
                "Pitcairn": "2mh|||",
                "Ponape": "2m7|||",
                "Pyongyang": "2m5|||",
                "Qyzylorda": "2m0|2ls||",
                "Reunion": "2lr|||",
                "Rothera": "31p|||",
                "Sakhalin": "31m|31l||",
                "Samara": "316|30b||",
                "Samoa": "30a|2uv||",
                "Seychelles": "2us|||",
                "Singapore": "2ur|||",
                "Solomon": "2up|||",
                "South_Georgia": "2un|||",
                "Suriname": "2u8|||",
                "Syowa": "2tr|||",
                "Tahiti": "2th|||",
                "Taipei": "2tf|2td||",
                "Tajikistan": "2ta|||",
                "Tokelau": "2sr|||",
                "Tonga": "2sg|2s4||",
                "Truk": "2rg|||",
                "Turkmenistan": "2re|2pt||",
                "Tuvalu": "2oq|||",
                "Uruguay": "2ol|2nl||",
                "Uzbekistan": "2ng|2m3||",
                "Vanuatu": "31f|31d||",
                "Venezuela": "313|||",
                "Vladivostok": "30m|30h||",
                "Volgograd": "30e|30c||",
                "Vostok": "308|||",
                "Wake": "305|||",
                "Wallis": "302|||",
                "Yakutsk": "301|2vc||",
                "Yekaterinburg": "2v9|2v6||"
            }
        },
        "zu": {
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "zone": {
                "Europe/Dublin": "|667,0,8e,5,c6,0,s3||",
                "Europe/London": "|667,0,8e,5,c1,0,qd||"
            },
            "metazone": {
                "Afghanistan": "7r,0,8e,5,b6|||",
                "Africa_Central": "7r,0,8e,5,n,0,1t|||",
                "Africa_Eastern": "7r,0,c7p,0,c5|||",
                "Africa_Southern": "7r,0,pj,0,c7r,0,c5|||",
                "Africa_Western": "7r,0,pj,0,4n6,0,c5|7r,0,qd,0,4n6,0,c5||",
                "Alaska": "7r,0,8e,5,3p,0,pj|7r,0,8e,5,3p,0,116||",
                "Amazon": "7r,0,8e,5,5m,0,pj|7r,0,8e,5,5m,0,qd||",
                "America_Central": "7r,0,8e,5,bm,0,1rn,0,n,0,pj|7r,0,8e,5,bm,0,1rn,0,n,0,116||",
                "America_Eastern": "7r,0,8e,5,bm,0,1rn,0,1r,0,pj|7r,0,8e,5,bm,0,1rn,0,1r,0,116||",
                "America_Mountain": "7r,0,8e,5,bm,0,1rn,0,5a,0,pj|7r,0,8e,5,bm,0,1rn,0,5a,0,116||",
                "America_Pacific": "7r,0,8e,5,bm,0,1rn,0,2h,0,pj|7r,0,8e,5,bm,0,1rn,0,2h,0,116||",
                "Anadyr": "2jj,5,3l,0,4,0,1|2jj,5,3l,0,6,0,1||",
                "Apia": "7r,0,8e,5,3e,0,s3|7r,0,8e,5,3e,0,116||",
                "Arabian": "7r,0,s3,0,8e,5,87|7r,0,2tj,0,8e,5,87||",
                "Argentina": "7r,0,8e,5,1e,0,pj|7r,0,8e,5,1e,0,qd||",
                "Argentina_Western": "7r,0,51v,0,3il,5,1e,0,pj|7r,0,51v,0,3il,5,1e,0,qd||",
                "Armenia": "7r,0,51r,0,8e,5,4l|7r,0,1em,0,8e,5,4l||",
                "Atlantic": "7r,0,8e,5,6g,0,pj|7r,0,8e,5,6g,0,116||",
                "Australia_Central": "7r,0,8e,5,13,0,n,0,s3|7r,0,8e,5,13,0,n,0,116||",
                "Australia_CentralWestern": "7r,0,8e,5,13,0,n,0,1g,0,s3|7r,0,116,0,8e,5,13,0,n,0,1g||",
                "Australia_Eastern": "7r,0,s3,0,8e,5,13,0,1r|7r,0,116,0,8e,5,13,0,1r||",
                "Australia_Western": "7r,0,8e,5,13,0,t,0,s3|7r,0,8e,5,13,0,t,0,116||",
                "Azerbaijan": "7r,0,s3,0,8e,5,7m|7r,0,1em,0,8e,5,7m||",
                "Azores": "7r,0,pj,0,8e,5,4r|7r,0,qd,0,8e,5,4r||",
                "Bangladesh": "7r,0,8e,5,3n,0,s3|7r,0,8e,5,3n,0,qd||",
                "Bhutan": "7r,0,8e,5,as|||",
                "Bolivia": "7r,0,8e,5,aa|||",
                "Brasilia": "7r,0,8e,5,3a,0,pj|7r,0,8e,5,3a,0,qd||",
                "Brunei": "7r,0,8e,5,9o,0,a4|||",
                "Cape_Verde": "7r,0,51r,0,8e,5,7c,0,3j|7r,0,1em,0,8e,5,7c,0,3j||",
                "Chamorro": "7r,0,pj,0,8e,5,6j|||",
                "Chatham": "7r,0,s3,0,8e,5,27|7r,0,116,0,8e,5,27||",
                "Chile": "7r,0,8e,5,49,0,pj|7r,0,8e,5,49,0,qd||",
                "China": "7r,0,s3,0,8e,5,4h|7r,0,2tj,0,8e,5,4h||",
                "Choibalsan": "7r,0,1en,0,8e,5,2q|7r,0,1em,0,15,5,2q||",
                "Christmas": "7r,0,8e,5,99,0,1f|||",
                "Cocos": "7r,0,8e,5,7v,0,o|||",
                "Colombia": "7r,0,8e,5,4m,0,pj|7r,0,8e,5,4m,0,qd||",
                "Cook": "7r,0,s3,0,8e,5,2v,0,o|7r,0,c88,0,c89,0,8e,5,2v,0,o||",
                "Cuba": "7r,0,8e,5,34,0,pj|7r,0,8e,5,34,0,116||",
                "Davis": "7r,0,8e,5,5s|||",
                "DumontDUrville": "7r,0,8e,5,5b,5,5j|||",
                "East_Timor": "7r,0,8e,5,1r,0,7n|||",
                "Easter": "7r,0,8e,5,7t,0,1f,0,pj|7r,0,8e,5,7t,0,1f,0,qd||",
                "Ecuador": "7r,0,8e,5,ai|||",
                "Europe_Central": "7r,0,pj,0,8e,5,n,0,r4|7r,0,qd,0,8e,5,n,0,r4||",
                "Europe_Eastern": "7r,0,pj,0,8e,5,17,0,r4|7r,0,qd,0,8e,5,17,0,r4||",
                "Europe_Further_Eastern": "7r,0,8e,5,bu,5,c0,0,r4|||",
                "Europe_Western": "7r,0,pj,0,8e,5,t,0,r4|7r,0,qd,0,8e,5,t,0,r4||",
                "Falkland": "7r,0,8e,5,4v,0,o,0,pj|7r,0,8e,5,4v,0,o,0,qd||",
                "Fiji": "7r,0,s3,0,8e,5,4q|7r,0,1em,0,8e,5,4q||",
                "French_Guiana": "7r,0,8e,5,84,0,am|||",
                "French_Southern": "7r,0,8e,5,84,0,c7,0,3ik,5,c4|||",
                "Galapagos": "7r,0,8e,5,ak|||",
                "Gambier": "7r,0,8e,5,6v|||",
                "Georgia": "7r,0,s3,0,8e,5,2u|7r,0,1em,0,8e,5,2u||",
                "Gilbert_Islands": "7r,0,8e,5,6q,0,o|||",
                "GMT": "7r,0,8e,5,5u,0,bh|||",
                "Greenland_Eastern": "7r,0,8e,5,1r,0,2k,0,pj|7r,0,8e,5,1r,0,2k,0,116||",
                "Greenland_Western": "7r,0,8e,5,1g,0,2k,0,pj|7r,0,8e,5,1g,0,2k,0,qd||",
                "Gulf": "7r,0,s3,0,8e,5,bn|||",
                "Guyana": "7r,0,8e,5,7a|||",
                "Hawaii_Aleutian": "7r,0,8e,5,4g,5,50n,0,pj|7r,0,8e,5,4g,5,50n,0,116||",
                "Hong_Kong": "7r,0,s3,0,8e,5,2l,0,2p|7r,0,1em,0,8e,5,2l,0,2p||",
                "Hovd": "7r,0,1en,0,8e,5,2g|7r,0,1em,0,15,5,2g||",
                "India": "7r,0,8e,5,af,0,s3|||",
                "Indian_Ocean": "7r,0,8e,5,br,0,bv|||",
                "Indochina": "7r,0,8e,5,9p|||",
                "Indonesia_Central": "7r,0,8e,5,n,0,2t|||",
                "Indonesia_Eastern": "7r,0,8e,5,17,0,2t|||",
                "Indonesia_Western": "7r,0,8e,5,t,0,2t|||",
                "Iran": "7r,0,8e,5,5g,0,s3|7r,0,8e,5,5g,0,116||",
                "Irkutsk": "7r,0,1en,0,8e,5,3h|7r,0,qd,0,15,5,3h||",
                "Israel": "7r,0,s3,0,8e,5,4e|7r,0,116,0,c8c,5,4e||",
                "Japan": "7r,0,s3,0,8e,5,6o|7r,0,2tj,0,8e,5,6o||",
                "Kamchatka": "2jj,5,37,5,3k,0,4,0,1|2jj,5,37,5,3k,0,6,0,1||",
                "Kazakhstan_Eastern": "7r,0,8e,5,c8d,0,3il,5,45|||",
                "Kazakhstan_Western": "7r,0,4n6,0,3il,5,45|||",
                "Korea": "7r,0,c8e,0,8e,5,qf|7r,0,2tj,0,8e,5,qf||",
                "Kosrae": "7r,0,8e,5,6f|||",
                "Krasnoyarsk": "7r,0,1en,0,8e,5,41|7r,0,qd,0,15,5,41||",
                "Kyrgystan": "7r,0,8e,5,bl|||",
                "Line_Islands": "7r,0,8e,5,ac,0,o|||",
                "Lord_Howe": "7r,0,8e,5,1u,0,24,0,s3|7r,0,8e,5,1u,0,24,0,116||",
                "Macquarie": "7r,0,8e,5,6m,0,1f|||",
                "Magadan": "7r,0,1en,0,8e,5,2o|7r,0,qd,0,15,5,2o||",
                "Malaysia": "7r,0,8e,5,bf|||",
                "Maldives": "7r,0,8e,5,9k|||",
                "Marquesas": "7r,0,8e,5,9e|||",
                "Marshall_Islands": "7r,0,8e,5,7o,0,o|||",
                "Mauritius": "7r,0,s3,0,8e,5,4u|7r,0,1em,0,8e,5,4u||",
                "Mawson": "7r,0,8e,5,63|||",
                "Mexico_Northwest": "7r,0,8e,5,80,0,5e,0,pj|7r,0,8e,5,80,0,5e,0,116||",
                "Mexico_Pacific": "7r,0,8e,5,81,0,2h,0,pj|7r,0,8e,5,81,0,2h,0,116||",
                "Mongolia": "7r,0,1en,0,8e,5,4f,0,2m|7r,0,1em,0,15,5,4f,0,2m||",
                "Moscow": "7r,0,8e,5,71,0,pj|7r,0,qd,0,15,5,71||",
                "Myanmar": "7r,0,8e,5,8v|||",
                "Nauru": "7r,0,8e,5,5v|||",
                "Nepal": "7r,0,8e,5,8u|||",
                "New_Caledonia": "7r,0,8e,5,1m,0,4o,0,pj|7r,0,8e,5,1m,0,4o,0,qd||",
                "New_Zealand": "7r,0,s3,0,8e,5,1m,0,74|7r,0,116,0,8e,5,1m,0,74||",
                "Newfoundland": "7r,0,8e,5,53,0,pj|7r,0,8e,5,53,0,116||",
                "Niue": "7r,0,8e,5,8g|||",
                "Norfolk": "7r,0,8e,5,6l,0,o|||",
                "Noronha": "7r,0,8e,5,1s,0,2,0,23,0,pj|7r,0,8e,5,1s,0,2,0,23,0,qd||",
                "Novosibirsk": "7r,0,1en,0,8e,5,3b|7r,0,qd,0,8e,5,3b||",
                "Omsk": "7r,0,1en,0,8e,5,26|7r,0,qd,0,8e,5,26||",
                "Pakistan": "7r,0,8e,5,3i,0,s3|7r,0,8e,5,3i,0,qd||",
                "Palau": "7r,0,8e,5,9b|||",
                "Papua_New_Guinea": "7r,0,8e,5,9a,0,1m,0,a8|||",
                "Paraguay": "7r,0,8e,5,3c,0,pj|7r,0,8e,5,3c,0,qd||",
                "Peru": "7r,0,8e,5,4n,0,pj|7r,0,8e,5,4n,0,qd||",
                "Philippines": "7r,0,s3,0,8e,5,86|7r,0,1em,0,8e,5,86||",
                "Phoenix_Islands": "7r,0,8e,5,90,0,o|||",
                "Pierre_Miquelon": "c8f,0,8e,5,8i,0,33,0,3ik,5,35,0,pj|7r,0,8e,5,8i,0,33,0,3ik,5,35,0,116||",
                "Pitcairn": "7r,0,8e,5,7e|||",
                "Ponape": "7r,0,8e,5,9s|||",
                "Pyongyang": "7r,0,8e,5,8a|||",
                "Reunion": "7r,0,8e,5,b4|||",
                "Rothera": "7r,0,8e,5,5q|||",
                "Sakhalin": "7r,0,1en,0,8e,5,55|7r,0,qd,0,15,5,55||",
                "Samara": "2jj,5,29,0,4,0,1|2jj,5,29,0,6,0,1||",
                "Samoa": "7r,0,8e,5,2c,0,pj|7r,0,8e,5,2c,0,116||",
                "Seychelles": "7r,0,8e,5,8k|||",
                "Singapore": "7r,0,s3,0,8e,5,bi|||",
                "Solomon": "7r,0,8e,5,b5,0,o|||",
                "South_Georgia": "7r,0,8e,5,85,0,2u|||",
                "Suriname": "7r,0,8e,5,8q|||",
                "Syowa": "7r,0,8e,5,65|||",
                "Tahiti": "7r,0,8e,5,8n|||",
                "Taipei": "7r,0,s3,0,8e,5,3d|7r,0,2tj,0,8e,5,3d||",
                "Tajikistan": "7r,0,8e,5,bj|||",
                "Tokelau": "7r,0,8e,5,5d|||",
                "Tonga": "7r,0,8e,5,2j,0,pj|7r,0,8e,5,2j,0,qd||",
                "Truk": "7r,0,8e,5,6p|||",
                "Turkmenistan": "7r,0,s3,0,8e,5,4t|7r,0,1em,0,8e,5,4t||",
                "Tuvalu": "7r,0,8e,5,6h|||",
                "Uruguay": "7r,0,8e,5,4k,0,pj|7r,0,8e,5,4k,0,qd||",
                "Uzbekistan": "7r,0,s3,0,8e,5,60|7r,0,1em,0,8e,5,60||",
                "Vanuatu": "7r,0,8e,5,2f,0,pj|7r,0,8e,5,2f,0,qd||",
                "Venezuela": "7r,0,8e,5,7s|||",
                "Vladivostok": "7r,0,1en,0,8e,5,28|7r,0,qd,0,15,5,28||",
                "Volgograd": "7r,0,1en,0,8e,5,3f|7r,0,8e,5,3f,0,qd||",
                "Vostok": "7r,0,8e,5,5t|||",
                "Wake": "7r,0,8e,5,5r,0,1f|||",
                "Wallis": "7r,0,8e,5,5f,0,3ik,5,5k|||",
                "Yakutsk": "7r,0,1en,0,8e,5,3u|7r,0,qd,0,15,5,3u||",
                "Yekaterinburg": "7r,0,1en,0,8e,5,5l|7r,0,qd,0,15,5,5l||"
            }
        }
    },
    "zoneNameIndex": [
        " ",
        "Time",
        "de",
        "heure",
        "Standard",
        "-",
        "Summer",
        "توقيت",
        "hora",
        "Hora",
        "normale",
        "d’été",
        "Daylight",
        "estándar",
        "verano",
        "du",
        "الصيفي",
        "الرسمي",
        "Ora",
        "des",
        ",",
        "za",
        "време",
        "Central",
        "Islands",
        "время",
        "padrão",
        "நேரம்",
        "verão",
        "Western",
        "Waktu",
        "Saa",
        "vrijeme",
        "čas",
        "îles",
        "Australian",
        "da",
        "e",
        "do",
        "Eastern",
        "European",
        "del",
        "standardno",
        "समय",
        "la",
        "vreme",
        "Argentina",
        "Island",
        "West",
        "standaardtijd",
        "zomertijd",
        "Sommerzeit",
        "Normalzeit",
        "normaltid",
        "New",
        "وقت",
        "التوقيت",
        "l’Ouest",
        "стандардно",
        "East",
        "Fernando",
        "Africa",
        "Lord",
        "l’Est",
        "летње",
        "стандартное",
        "летнее",
        "Noronha",
        "Howe",
        "Zeit",
        "Omsk",
        "Chatham",
        "Vladivostok",
        "Samara",
        "Saati",
        "рачунање",
        "Samoa",
        "времена",
        "غرب",
        "Vanuatu",
        "Hovd",
        "Pacific",
        "tijd",
        "Tonga",
        "Greenland",
        "Hong",
        "Bator",
        "সময়",
        "Magadan",
        "Kong",
        "Choibalsan",
        "ਵੇਲਾ",
        "vaqti",
        "Indonesia",
        "Georgia",
        "Cook",
        "Vaxtı",
        "&",
        "las",
        "Pierre",
        "Cuba",
        "Miquelon",
        "estàndard",
        "Petropavlovsk",
        "standard",
        "d’estiu",
        "Brasilia",
        "Novosibirsk",
        "Paraguay",
        "Taipei",
        "Apia",
        "Volgograd",
        "ٹائم",
        "Irkutsk",
        "Pakistan",
        "Verde",
        "Kamchatski",
        "Anadyr",
        "l’Australie",
        "Bangladesh",
        "letnje",
        "Alaska",
        "Acre",
        "oriental",
        "표준시",
        "sommertid",
        "Yakutsk",
        "vremena",
        "računanje",
        "Krasnoyarsk",
        "جزر",
        "Wastani",
        "Standart",
        "Kazakhstan",
        "ώρα",
        "நிலையான",
        "occidental",
        "Chile",
        "l’île",
        "ljetno",
        "Australia",
        "Qyzylorda",
        "Israel",
        "Ulan",
        "Hawaii",
        "China",
        "di",
        "tid",
        "Uruguay",
        "Armenia",
        "Colombia",
        "Peru",
        "Caledonia",
        "Europa",
        "Fiji",
        "Azores",
        "tíð",
        "Turkmenistan",
        "Mauritius",
        "Falkland",
        "Aqtau",
        "شرق",
        "na",
        "Newfoundland",
        "d’Europe",
        "Sakhalin",
        "Almaty",
        "Macau",
        "standarde",
        "ya",
        "Mountain",
        "Dumont",
        "verore",
        "Tokelau",
        "Mexico",
        "Wallis",
        "Iran",
        "Panas",
        "Musim",
        "d’Urville",
        "Futuna",
        "Yekaterinburg",
        "Amazon",
        "मानक",
        "Aqtobe",
        "Piawai",
        "Rothera",
        "Wake",
        "Davis",
        "Vostok",
        "Greenwich",
        "Nauru",
        "Uzbekistan",
        "legale",
        "Aleutian",
        "Mawson",
        "laikas",
        "Syowa",
        "Am",
        "houa",
        "సమయం",
        "Horário",
        "സമയം",
        "Giờ",
        "sommartid",
        "din",
        "સમય",
        "Kosrae",
        "Atlantic",
        "Tuvalu",
        "standart",
        "Chamorro",
        "ۋاقتى",
        "Norfolk",
        "Macquarie",
        "centre",
        "Japan",
        "Chuuk",
        "Gilbert",
        "central",
        "கோடை",
        "das",
        "वेळ",
        "Gambier",
        "टाईम",
        "Moscow",
        "שעון",
        "ٹایِم",
        "Zealand",
        "Horario",
        "Nouvelle",
        "d’Afrique",
        "ಸಮಯ",
        "за",
        "Guyana",
        "laiks",
        "Cape",
        "часом",
        "Pitcairn",
        "idő",
        "цаг",
        "Amser",
        "أستراليا",
        "ժամանակ",
        "وسط",
        "уақыты",
        "Azerbaijan",
        "Timor",
        "Marshall",
        "დრო",
        "cas",
        "Isikhathi",
        "Venezuela",
        "Easter",
        "St.",
        "Cocos",
        "Northwest",
        "Mexican",
        "вақти",
        "Guam",
        "French",
        "South",
        "Philippine",
        "Arabian",
        "Korean",
        "for",
        "Pyongyang",
        "ሰዓት",
        "sa",
        "Majira",
        "sase",
        "වේලාව",
        "Niue",
        "Oriental",
        "Saint",
        "joto",
        "Seychelles",
        "ᎠᏟᎢᎵᏒ",
        "الشمالية",
        "Tahiti",
        "Groenland",
        "et",
        "Suriname",
        "Oras",
        "eur",
        "Pacifique",
        "Nepal",
        "Myanmar",
        "Phoenix",
        "ਮਿਆਰੀ",
        "عادی",
        "nord",
        "하계",
        "Yaz",
        "taimi",
        "час",
        "Nord",
        "Christmas",
        "Papua",
        "Palau",
        "Lanka",
        "África",
        "Marquesas",
        "تابستانی",
        "standaardtyd",
        "أوروبا",
        "Ocidental",
        "لأمريكا",
        "Maldives",
        "во",
        "মানক",
        "vară",
        "Brunei",
        "Indochina",
        "vanlig",
        "centrale",
        "Ponape",
        "wagt",
        "Pacífico",
        "хан",
        "убактысы",
        "yozgi",
        "Θερινή",
        "Yay",
        "Darussalam",
        "ग्रीष्मकालीन",
        "a",
        "Χειμερινή",
        "Guinea",
        "summartíð",
        "Bolivia",
        "стандарт",
        "Line",
        "standardny",
        "Ilhas",
        "India",
        "на",
        "GMT",
        "Ecuador",
        "स्टैंडर्ड",
        "Galapagos",
        "Islas",
        "Guiana",
        "Nueva",
        "المحيط",
        "أفريقيا",
        "Géorgie",
        "indonésien",
        "Bhutan",
        "Ώρα",
        "México",
        "isla",
        "américain",
        "시간",
        "Groenlandia",
        "islas",
        "Reunion",
        "Solomon",
        "Afghanistan",
        "Casey",
        "ordua",
        "Açores",
        "Nova",
        "(czas",
        "tyd",
        "Mariana",
        "í",
        "Malaysia",
        "Amazonas",
        "Mean",
        "Singapore",
        "Tajikistan",
        "y",
        "Kyrgystan",
        "North",
        "Gulf",
        "غرينلاند",
        "பகலொளி",
        "الأرجنتين",
        "Indian",
        "جزيرة",
        "الهادي",
        "Further",
        "Ocean",
        "eastern",
        "British",
        "Ata",
        "کا",
        "Antarctic",
        "Afrika",
        "Irish",
        "Southern",
        "Half",
        "d’Apia",
        "Moscou",
        "d’Anadyr",
        "Centre",
        "somertyd",
        "d’Iran",
        "Cap",
        "Chili",
        "ਗਰਮੀਆਂ",
        "ਦਾ",
        "Mchana",
        "Cabo",
        "গ্রীষ্মকালীন",
        "Fidji",
        "Austrália",
        "Terre",
        "Galápagos",
        "സ്റ്റാൻഡേർഡ്",
        "Samhraidh",
        "Maurice",
        "mexicain",
        "d’Oulan",
        "l’Amazonie",
        "l’Ouzbékistan",
        "Calédonie",
        "d’Alma",
        "Corée",
        "l’Alaska",
        "Chine",
        "Vert",
        "ouest",
        "l’Acre",
        "Krasnoïarsk",
        "d’Irkoutsk",
        "d’Israël",
        "Zélande",
        "l’Atlantique",
        "Neuve",
        "argentin",
        "Japon",
        "Caighdeánach",
        "Novossibirsk",
        "Pérou",
        "Philippines",
        "l’Arabie",
        "d’Aqtöbe",
        "Sakhaline",
        "ప్రామాణిక",
        "Turkménistan",
        "l’Uruguay",
        "Malouines",
        "Aléoutiennes",
        "Pâques",
        "Iakoutsk",
        "d’Ekaterinbourg",
        "d’Aktaou",
        "Colombie",
        "Ouest",
        "Rocheuses",
        "Mexique",
        "Pohnpei",
        "Standar",
        "ئۆلچەملىك",
        "standardaeg",
        "Padrão",
        "d’Hawaii",
        "nyári",
        "Verão",
        "vasaros",
        "normaaliaika",
        "kesäaika",
        "totonu",
        "àm",
        "Bun",
        "يازلىق",
        "liliu",
        "suveaeg",
        "delle",
        "ಪ್ರಮಾಣಿತ",
        "Австралия",
        "Tìde",
        "braniega",
        "standerttiid",
        "žiemos",
        "les",
        "téli",
        "simmertiid",
        "avancée",
        "መደበኛ",
        "الجديدة",
        "प्रमाण",
        "سٹینڑاڑ",
        "إندونيسيا",
        "estandarra",
        "ziemas",
        "štandardný",
        "جورجيا",
        "letní",
        "دي",
        "Summerzäit",
        "стандартно",
        "standardní",
        "Volgogrado",
        "ᎠᏟᎶᏍᏗ",
        "vasaras",
        "лятно",
        "سٹینڈرڈ",
        "літнім",
        "verán",
        "အချိန်",
        "Normalzäit",
        "Staðaltími",
        "letný",
        "Siang",
        "Sumartími",
        "стандартним",
        "стандартты",
        "standardni",
        "udako",
        "Filipinas",
        "Safonol",
        "часово",
        "Chuẩn",
        "samhraidh",
        "lětni",
        "Haf",
        "аьхкенан",
        "кышкы",
        "lěśojski",
        "жазғы",
        "tomusky",
        "жайкы",
        "სტანდარტული",
        "ստանդարտ",
        "зуны",
        "سمر",
        "(חורף)",
        "стандартан",
        "සම්මත",
        "ամառային",
        "(קיץ)",
        "አቆጣጠር",
        "ზაფხულის",
        "Zelanda",
        "Pedro",
        "poletni",
        "cʼhoañv",
        "Brunéi",
        "Palaos",
        "EET",
        "EEST",
        "CET",
        "WET",
        "WEST",
        "о",
        "CEST",
        "Terranova",
        "Mùa",
        "Ilha",
        "ng",
        "tidssone",
        "Perú",
        "ва",
        "Oost",
        "aika",
        "Àm",
        "Corea",
        "માનક",
        "hañv",
        "Стандардно",
        "Hè",
        "Аргентина",
        "Летно",
        "ordu",
        "Западная",
        "della",
        "Zäit",
        "tiid",
        "سَمَر",
        "Arabia",
        "Barat",
        "Magharibi",
        "Europese",
        "letni)",
        "убакыты",
        "aeg",
        "i",
        "Aktau",
        "standardowy)",
        "Sud",
        "Време",
        "Réunion",
        "اسٹینڈرڈ",
        "వేసవి",
        "Восточная",
        "समर",
        "စံတော်ချိန်",
        "ساموا",
        "سمارا",
        "فرناندو",
        "ગ્રીષ્મ",
        "Центральная",
        "جنوب",
        "dagligtyd",
        "и",
        "Aktobe",
        "Brasília",
        "San",
        "Macao",
        "Pascua",
        "Острва",
        "á",
        "فلاديفوستوك",
        "فولغوغراد",
        "الأمازون",
        "موسكو",
        "برازيليا",
        "المركزي",
        "الرأس",
        "الأخضر",
        "نوفوسيبيرسك",
        "أومسك",
        "باكستان",
        "تشاتام",
        "تونغا",
        "ماغادان",
        "باتور",
        "أولان",
        "للمكسيك",
        "Bangladés",
        "شيلي",
        "الصين",
        "Magadán",
        "الشرقي",
        "تركمانستان",
        "Atlántico",
        "الجبلي",
        "أورغواي",
        "noroeste",
        "दिवा",
        "تايبيه",
        "نيوفاوندلاند",
        "كولومبيا",
        "كووك",
        "المكسيك",
        "Malvinas",
        "كوبا",
        "ساخالين",
        "Irán",
        "هاو",
        "لشمال",
        "استر",
        "العربي",
        "Moscú",
        "ياكوتسك",
        "ਪ੍ਰਕਾਸ਼",
        "أرمينيا",
        "أوزبكستان",
        "باراغواي",
        "كاليدونيا",
        "فوكلاند",
        "Ulán",
        "–",
        "فيجي",
        "Ekaterimburgo",
        "ڈے",
        "لائٹ",
        "الفرنسية",
        "كوريا",
        "كازاخستان",
        "بيرو",
        "فانواتو",
        "كامتشاتسكي",
        "بيتروبافلوفسك",
        "الأطلسي",
        "اليابان",
        "Mauricio",
        "إسرائيل",
        "Australische",
        "Krasnojarsk",
        "إركوتسك",
        "الفيلبين",
        "سانت",
        "بيير",
        "إيران",
        "نورونها",
        "وميكولون",
        "موريشيوس",
        "أذربيجان",
        "أزورس",
        "الهند",
        "Tadjikistan",
        "هوفد",
        "يكاترينبورغ",
        "Taipéi",
        "هاواي",
        "Turkmenistán",
        "بنجلاديش",
        "ألوتيان",
        "هونغ",
        "كونغ",
        "نيوزيلندا",
        "Vrijeme",
        "Европа",
        "Azerbaiyán",
        "Uzbekistán",
        "Kamchatka",
        "Montañas",
        "Hawái",
        "Japón",
        "Pakistán",
        "Miquelón",
        "Fiyi",
        "Amerika",
        "Kazajistán",
        "Sajalín",
        "နွေရာသီ",
        "Америка",
        "кэмэ",
        "Singapur",
        "centroccidental",
        "ಬೇಸಿಗೆ",
        "init",
        "Tag",
        "ёзги",
        "Sri",
        "Час",
        "Surinam",
        "उन्हाळी",
        "Африка",
        "ᎪᎩ",
        "ഗ്രീഷ്‌മകാല",
        "ග්‍රීෂ්ම",
        "Kirghizistan",
        "Mashariki",
        "Kaliningrad",
        "Аустралијско",
        "Salomon",
        "gaƒoƒome",
        "Midden",
        "Timur",
        "Singapour",
        "La",
        "horario",
        "Guyane",
        "у",
        "Marquises",
        "Bhoutan",
        "l’Océan",
        "Népal",
        "Golfe",
        "Indien",
        "Ligne",
        "d’Argentine",
        "d’Indochine",
        "Terres",
        "occidentale",
        "française",
        "Francesa",
        "l’Équateur",
        "l’Arménie",
        "d’Arménie",
        "Bolivie",
        "an",
        "Adaları",
        "দিবালোক",
        "d’Azerbaïdjan",
        "françaises",
        "moyenne",
        "irlandaise",
        "Nioué",
        "l’Azerbaïdjan",
        "Malaisie",
        "Mariannes",
        "l’Inde",
        "Guinée",
        "antarctiques",
        "Papouasie",
        "l’Afghanistan",
        "britannique",
        "australes",
        "l’Argentine",
        "illes",
        "méridionale",
        "Gronelândia",
        "Aleutianas",
        "தீவுகள்",
        "mwa",
        "сметање",
        "времето",
        "Occidental",
        "Czas",
        "Golfo",
        "Лорд",
        "Стандартны",
        "Ostrva",
        "Ishujve",
        "eilandse",
        "Nordamerikanische",
        "Kepulauan",
        "orientale",
        "पूर्वी",
        "Улан",
        "Вануату",
        "Тонга",
        "en",
        "Maldivas",
        "Самоа",
        "மத்திய",
        "Ulaanbaatar",
        "d’Austràlia",
        "Norte",
        "தர",
        "централно",
        "Северноамеричко",
        "Paraguai",
        "Visiwa",
        "Índico",
        "së",
        "Perëndimore",
        "Fénix",
        "летні",
        "Francesas",
        "Летні",
        "Sachalin",
        "Moskva",
        "esijwayelekile",
        "Irlanda",
        "Tengah",
        "острва",
        "d’Europa",
        "стандартны",
        "Australijsko",
        "Гренландия",
        "Сен",
        "Kati",
        "gaƒoƒoɖoanyime",
        "و",
        "Geórgia",
        "vya",
        "Перу",
        "Indonésia",
        "ar",
        "पश्चिमी",
        "të",
        "Jekaterinburg",
        "மேற்கத்திய",
        "Бангладеш",
        "Волгоград",
        "Afrikaanse",
        "Georgische",
        "Nieuw",
        "sasehlobo",
        "в",
        "Korea",
        "Ховд",
        "Hongkong",
        "Wolgograd",
        "Jakutsk",
        "Guayana",
        "Куба",
        "ŋkekeme",
        "Владивосток",
        "Батор",
        "Микелон",
        "Магадан",
        "Tahití",
        "Lindore",
        "Новосибирск",
        "Severnoameričko",
        "Azoren",
        "Qərbi",
        "media",
        "Atlantik",
        "Avstraliya",
        "Europe",
        "Омск",
        "Москва",
        "ਪੱਛਮੀ",
        "Сахалин",
        "ஆஸ்திரேலியன்",
        "Birmania",
        "مارشال",
        "meridiano",
        "معیاری",
        "Papúa",
        "Summerziit",
        "فوتونا",
        "لورد",
        "d’Amèrica",
        "ماوسون",
        "Острова",
        "Новая",
        "ክረምት",
        "Uruguai",
        "Mexiko",
        "بوتان",
        "Пакистан",
        "Иран",
        "Aqtöbe",
        "афричко",
        "مغربی",
        "orollari",
        "Schtandardziit",
        "Salomón",
        "لاين",
        "esivamile",
        "Reunión",
        "Bután",
        "نورفولك",
        "نيوي",
        "لآبيا",
        "डेलाइट",
        "مونت",
        "Argentijnse",
        "británica",
        "Antárticas",
        "ᎢᎦ",
        "Sudáfrica",
        "ᎪᎯ",
        "Malasia",
        "بالاو",
        "الهندي",
        "الصينية",
        "دو",
        "सूर्यप्रकाश",
        "ماكواري",
        "روفيل",
        "Kirguistán",
        "கிழக்கத்திய",
        "este",
        "بابوا",
        "المقاطعات",
        "غينيا",
        "und",
        "Arabische",
        "تيمور",
        "الشرقية",
        "Mexicaanse",
        "لأبيا",
        "بوليفيا",
        "موسم",
        "گرما",
        "الجنوبية",
        "Afganistán",
        "الإكوادور",
        "Kuba",
        "ماليزيا",
        "والأنتارتيكية",
        "الـمالديف",
        "فينكس",
        "غلاباغوس",
        "ماركيساس",
        "الأوروبي",
        "Alasca",
        "بيتكيرن",
        "ألاسكا",
        "(أكثر",
        "fakalūsia",
        "جامبير",
        "ஐரோப்பிய",
        "ਸਮਾਂ",
        "بونابي",
        "بيونغ",
        "كوسرا",
        "كراسنويارسك",
        "أفغانستان",
        "og",
        "يانغ",
        "Mikelon",
        "لكراسنويارسك",
        "بروناي",
        "ريونيون",
        "جيلبرت",
        "روثيرا",
        "ਆਈਲੈਂਡ",
        "قرغيزستان",
        "غرينتش",
        "غوام",
        "الخليج",
        "غيانا",
        "شرقًا)",
        "أيرلندا",
        "centralno",
        "ميانمار",
        "ناورو",
        "واليس",
        "Groenlandse",
        "نيبال",
        "لشويبالسان",
        "الكريسماس",
        "للورد",
        "ويك",
        "فوستوك",
        "Гренланд",
        "سيشل",
        "كوكوس",
        "западно",
        "سنغافورة",
        "فنزويلا",
        "لألاسكا",
        "غايانا",
        "بريطانيا",
        "توفالو",
        "أنادير",
        "ماريانا",
        "سليمان",
        "شوك",
        "تشامورو",
        "توكيلاو",
        "شويبالسان",
        "لأنادير",
        "سورينام",
        "سايووا",
        "تاهيتي",
        "طاجكستان",
        "دافيز",
        "દિવસ",
        "CAT",
        "Leste",
        "Isole",
        "Marianas",
        "የቀን",
        "Индонезия",
        "EAT",
        "WAST",
        "పగటి",
        "Navidad",
        "Ecuatoriales",
        "SAST",
        "Tierras",
        "Tayikistán",
        "Australes",
        "Espóradas",
        "WAT",
        "meridional",
        "Şərqi",
        "ഡേലൈറ്റ്",
        "Noroeste",
        "Узбекистан",
        "Montanha",
        "Хау",
        "ਪੂਰਬੀ",
        "Pasifik",
        "Sul",
        "Doğu",
        "Isla",
        "Meksika",
        "océano",
        "l’Àfrica",
        "stanice",
        "кундузги",
        "مشرقی",
        "сүрүн",
        "Colômbia",
        "Ostrvu",
        "Coreia",
        "dos",
        "USA",
        "Atlântico",
        "Arábia",
        "ostrva",
        "kupow",
        "pacifičko",
        "istočno",
        "dell’Australia",
        "جزایر",
        "Ulaya",
        "Верде",
        "sasemini",
        "Zelândia",
        "Макао",
        "Terra",
        "Australisë",
        "Тихоокеанское",
        "zapadno",
        "Aleutas",
        "Чойбалсан",
        "પ્રમાણભૂત",
        "Japão",
        "Paquistão",
        "தீவு",
        "Vestur",
        "Batı",
        "Qendrore",
        "Западно",
        "западное",
        "제도",
        "கிழக்கு",
        "Sharqiy",
        "мексиканское",
        "Ecaterimburgo",
        "cha",
        "डेलाईट",
        "Sacalina",
        "Páscoa",
        "Νησιών",
        "сайыҥҥы",
        "Uzbequistão",
        "पश्चिम",
        "Нова",
        "острові",
        "Golf",
        "Δυτικής",
        "островах",
        "рӕстӕг",
        "Otoka",
        "São",
        "Arménia",
        "Caledónia",
        "Havai",
        "Источно",
        "टापु",
        "দ্বীপপুঞ্জ",
        "standardtid",
        "পূর্ব",
        "Аляска",
        "Pionyang",
        "Cazaquistão",
        "කාලය",
        "Кабо",
        "Maurícia",
        "Irão",
        "Miquelão",
        "Mexicano",
        "Иркутск",
        "Taipé",
        "Turquemenistão",
        "Moskauer",
        "Норонья",
        "Azerbaijão",
        "Moscovo",
        "Čas",
        "Екатеринбург",
        "Adası",
        "Фернандо",
        "источно",
        "де",
        "Eropah",
        "tími",
        "HDT",
        "Evropës",
        "የበጋ",
        "dell’Europa",
        "HST",
        "lejano",
        "Indonesische",
        "Папуа",
        "ordutegia",
        "Пьер",
        "Sjevernoameričko",
        "Markaziy",
        "Парагвай",
        "آئلینڈز",
        "Kisiwa",
        "l’Argentina",
        "Гонконг",
        "Mərkəzi",
        "Jakoetsk",
        "szigeteki",
        "Фернанду",
        "Yeni",
        "Moskou",
        "l’Oest",
        "Rocky",
        "Норфолк",
        "zónaidő",
        "Tsjojbalsan",
        "Mèxic",
        "Уругвай",
        "Pacífic",
        "केन्द्रीय",
        "Якутск",
        "מערב",
        "மேற்கு",
        "مرکز",
        "Красноярск",
        "Sen",
        "Тувалу",
        "Wes",
        "Футуна",
        "Usbekistan",
        "America",
        "ஆப்பிரிக்க",
        "لینڈ",
        "americană",
        "ᏭᏕᎵᎬ",
        "Науру",
        "Ньюфаундленд",
        "Eileanan",
        "afričko",
        "Чили",
        "l’oest",
        "Grenlàndia",
        "ಹಗಲು",
        "letni",
        "Восток",
        "Токелау",
        "Ротера",
        "Феникс",
        "Палау",
        "IST",
        "Eurohpá",
        "Koreaanse",
        "Колумбия",
        "Грузия",
        "Pulau",
        "የሰዓት",
        "Зеландия",
        "standardowy",
        "Корея",
        "Бразилия",
        "Каледония",
        "Wladiwostok",
        "Kuzey",
        "ਉੱਤਰੀ",
        "미",
        "Амазон",
        "nan",
        "Апиа",
        "Маврикий",
        "Источни",
        "Ανατολικής",
        "индонезијско",
        "Фиджи",
        "Чатем",
        "Ishullit",
        "Sahalin",
        "Израиль",
        "夏威夷",
        "Insulelor",
        "hè",
        "ᎠᏟᎢᎵᏒᎩ",
        "астравоў",
        "Сингапур",
        "Nowosibirsk",
        "Непал",
        "Miền",
        "Бутан",
        "Bolívia",
        "Западна",
        "MDT",
        "Alma",
        "Irkoetsk",
        "Kamtsjatski",
        "Avustralya",
        "Kazachse",
        "Macause",
        "동부",
        "Amazone",
        "կղզիների",
        "Zapadno",
        "Filipijnse",
        "அர்ஜென்டினா",
        "Armeense",
        "Пасхи",
        "Turkmeense",
        "অস্ট্রেলীয়",
        "Australiese",
        "westelijke",
        "Oezbeekse",
        "Makao",
        "अस्ट्रेलिया",
        "Inland",
        "Ostküsten",
        "Jekaterinenburg",
        "Westküsten",
        "Bengalese",
        "Αυστραλίας",
        "Zentral",
        "ordutegi",
        "Şimali",
        "கிரீன்லாந்து",
        "Bangladesch",
        "HAST",
        "Meksiko",
        "HADT",
        "ди",
        "பசிபிக்",
        "Самара",
        "Fidschi",
        "서부",
        "planinsko",
        "Avstralia",
        "dan",
        "Кука",
        "Australiei",
        "Казахстан",
        "Pasqua",
        "Βόρειας",
        "Ostgrönland",
        "Westgrönland",
        "Aleuten",
        "Αμερικής",
        "Мексико",
        "Hongkongse",
        "오스트레일리아",
        "დასავლეთ",
        "Tây",
        "Kamtschatka",
        "ᏗᏜ",
        "Амазонка",
        "AKST",
        "Grenland",
        "استرالیا",
        "امریکا",
        "Централно",
        "ਆਸਟ੍ਰੇਲੀਆਈ",
        "غەربىي",
        "Oos",
        "Армения",
        "Occidentale",
        "wa",
        "ਅਮਰੀਕੀ",
        "MST",
        "grada",
        "AKDT",
        "Анадир",
        "Суринам",
        "ADT",
        "острови",
        "კუნძულების",
        "Indische",
        "dell’Isola",
        "Est",
        "Singapura",
        "Nuova",
        "Taiti",
        "Afrikës",
        "Грузија",
        "adalary",
        "Хонг",
        "Đảo",
        "mùa",
        "Re",
        "ostrova",
        "اروپا",
        "AST",
        "salu",
        "PDT",
        "PST",
        "uharteetako",
        "EDT",
        "EST",
        "CDT",
        "CST",
        "kupy",
        "dell’Africa",
        "ᏗᎧᎸᎬ",
        "Salų",
        "Horari",
        "Geòrgia",
        "d’Indonèsia",
        "Zuid",
        "hihifo",
        "Zeelandse",
        "Ευρώπης",
        "मध्य",
        "Feniks",
        "nyugat",
        "Тайвань",
        "Japanse",
        "ਕੇਂਦਰੀ",
        "Westafrikanische",
        "Ɣedzeƒe",
        "алеутско",
        "Краснојарск",
        "പടിഞ്ഞാറൻ",
        "Titina",
        "পশ্চিম",
        "Paaseilandse",
        "Argentinische",
        "Westargentinische",
        "Armenische",
        "Zentralaustralische",
        "океан",
        "/Westaustralische",
        "Ostaustralische",
        "Westaustralische",
        "Tongaanse",
        "Colombiaanse",
        "Gorllewin",
        "Israëlische",
        "Азербејџан",
        "Chilenische",
        "Chinesische",
        "Tschoibalsan",
        "vaxtı",
        "Cʼhornôg",
        "Kolumbianische",
        "Cookinseln",
        "Kubanische",
        "Ostrvima",
        "Osterinsel",
        "Япония",
        "Mitteleuropäische",
        "MEZ",
        "MESZ",
        "Osteuropäische",
        "OEZ",
        "OESZ",
        "Uruguayaanse",
        "Westeuropäische",
        "WEZ",
        "WESZ",
        "Falklandinseln",
        "Orta",
        "Бразилија",
        "Vanuatuaanse",
        "Каледонија",
        "పశ్చిమ",
        "Зеланд",
        "Њуфаундленд",
        "Чатам",
        "Нороња",
        "Чиле",
        "Iraanse",
        "Merkezi",
        "Paraguayaanse",
        "Chowd",
        "Havajsko",
        "Чојбалсан",
        "Krasnoiarsk",
        "Iar",
        "Guyaanse",
        "Парагвај",
        "Iranische",
        "Israelische",
        "Japanische",
        "Azerbeidzjaanse",
        "Филиппины",
        "Филипини",
        "Koreanische",
        "Пјер",
        "Кизилорда",
        "Колумбија",
        "otoka",
        "Europei",
        "Nordwestliche",
        "Zone",
        "Pazifikzone",
        "Израелско",
        "কেন্দ্রীয়",
        "Neukaledonische",
        "Neuseeland",
        "Neufundland",
        "آسٹریلین",
        "Peruaanse",
        "d’Omsk",
        "Aleoetische",
        "Pakistanische",
        "Fijische",
        "Paraguayanische",
        "Peruanische",
        "Philippinische",
        "Петропавловск",
        "Ғарбий",
        "Chinese",
        "Туркменистан",
        "Уругвај",
        "ਯੂਰਪੀ",
        "летно",
        "Азербайджан",
        "Taipeh",
        "Источноевропско",
        "Tonganische",
        "Западноевропско",
        "Chileense",
        "قىسىم",
        "Китай",
        "Фиџи",
        "Јакутск",
        "ಪಶ್ಚಿಮ",
        "බටහිර",
        "Гвајана",
        "Avrupa",
        "Falklandeilandse",
        "Cubaanse",
        "Avropa",
        "Kaapverdische",
        "Centraal",
        "Cookeilandse",
        "איי",
        "युरोपेली",
        "mendebaldeko",
        "Lääne",
        "Braziliaanse",
        "중부",
        "Mauritiaanse",
        "Caledonische",
        "Mianmar",
        "Kuk",
        "Акре",
        "Pakistaanse",
        "Samoaanse",
        "Kokos",
        "Anadir",
        "Noordwest",
        "Extremo",
        "Čas:",
        "Padrãu",
        "sehlobo",
        "Esimisiwe",
        "Лайн",
        "Dwyrain",
        "ekialdeko",
        "తూర్పు",
        "hahake",
        "Ланка",
        "Конг",
        "द्वीप",
        "Oileáin",
        "indonezijsko",
        "Чуук",
        "Kusini",
        "Kiangazi",
        "аралдарынын",
        "Länsi",
        "Jojia",
        "දිවාආලෝක",
        "නැගෙනහිර",
        "geassiáigi",
        "dábálašáigi",
        "Туркмения",
        "Самарское",
        "время*",
        "Кызылорда",
        "Северо",
        "Fidži",
        "Kanlurang",
        "Камчатский",
        "алеутское",
        "Istočni",
        "Гавайско",
        "டி",
        "ஜார்ஜியா",
        "Фолклендские",
        "О",
        "SHBA",
        "Восточный",
        "amerikane",
        "Արևմտյան",
        "Азорские",
        "Атлантическое",
        "Bregut",
        "Аравия",
        "Paqësorit",
        "Саудовская",
        "Актобе",
        "Актау",
        "Анадырь",
        "Argjentinës",
        "شەرقىي",
        "Đông",
        "ئاراللىرى",
        "Aleutiano",
        "Petropavlovsko",
        "(США)",
        "Горное",
        "Ата",
        "Алма",
        "Южная",
        "планинско",
        "Արևելյան",
        "Pacifico",
        "Акри",
        "zona",
        "în",
        "Centrale",
        "Vakarų",
        "Бруней",
        "Νέας",
        "dell’Argentina",
        "Дюмон",
        "G‘arbiy",
        "гӀ",
        "Малхбузен",
        "Austrais",
        "Малхбален",
        "Terras",
        "Günbatar",
        "Iakutsk",
        "Tajiquistão",
        "Salomão",
        "dhe",
        "Tími",
        "Grenlandës",
        "Reunião",
        "Kizilorda",
        "Pjer",
        "Guiné",
        "Noronja",
        "Malásia",
        "Quirguistão",
        "meksičko",
        "Korejsko",
        "Japansko",
        "Izraelsko",
        "Oceano",
        "Índia",
        "Батыс",
        "Zapadnoevropsko",
        "Istočnoevropsko",
        "Equador",
        "d’Alaska",
        "Butão",
        "Afeganistão",
        "পশ্চিমি",
        "Arabijsko",
        "Hau",
        "Јекатеринбург",
        "kamčatsko",
        "Aleutianes",
        "den",
        "Европӕйаг",
        "ڈےلایِٔٹ",
        "Нови",
        "d’Irkutsk",
        "Маурицијус",
        "d’Israel",
        "Хов",
        "Verãu",
        "Шри",
        "Корејско",
        "казахстанско",
        "Ɣetoɖoƒe",
        "камчатско",
        "Петропавловско",
        "Јапанско",
        "Иркуцк",
        "Индијско",
        "ইউরোপের",
        "oroli",
        "Хавајско",
        "Гуам",
        "Западни",
        "Κεντρικής",
        "இந்தோனேசிய",
        "Фолкландска",
        "Russisk",
        "Средњеевропско",
        "Ускршња",
        "sentralaustralsk",
        "Кукова",
        "Азори",
        "Арабијско",
        "Istočno",
        "Акутобе",
        "Акватау",
        "Алмати",
        "Filipines",
        "یورپ",
        "آئلینڈ",
        "நியூ",
        "Аљаска",
        "Yevropa",
        "Апија",
        "Атлантско",
        "Selatan",
        "Iarthar",
        "दक्षिण",
        "Markiz",
        "Natal",
        "по",
        "Gʻarbiy",
        "Ear",
        "سنٹرل",
        "Reter",
        "കിഴക്കൻ",
        "ദ്വീപുകൾ",
        "Зүүн",
        "Ekvador",
        "Баруун",
        "aleutsko",
        "наш",
        "Butan",
        "बेटे",
        "ብርሃን",
        "ደሴቶች",
        "Quần",
        "Острво",
        "острво",
        "Islles",
        "దీవుల",
        "Illas",
        "Seicheles",
        "Gündogar",
        "Britânica",
        "a’",
        "Тимор",
        "ᎠᏰᏟ",
        "ᎠᏟᎢᎵᏍᏒᎩ",
        "Šiaurės",
        "chuẩn",
        "ಪೂರ್ವ",
        "Pitkern",
        "Trung",
        "აღმოსავლეთ",
        "افریقا",
        "ороллари",
        "Wostok",
        "Antárcticas",
        "tokelau",
        "द्वीपसमूह",
        "fakaʻamelika",
        "දුපත්",
        "Aleut",
        "turkmensk",
        "Маккуори",
        "费尔南多",
        "Шарқий",
        "Kap",
        "japansk",
        "kinesisk",
        "افریقہ",
        "Papoea",
        "न्यू",
        "Кокос",
        "а",
        "Azor",
        "стандартон",
        "Itä",
        "कुक",
        "彼得罗巴甫洛夫斯克",
        "l’Amazones",
        "দ্বীপ",
        "Түндүк",
        "पूर्व",
        "muntanya",
        "Standardno",
        "Ekaterinburg",
        "Батыш",
        "Моусон",
        "àrab",
        "ostrvima",
        "Уэйк",
        "d’Armènia",
        "l’Atlàntic",
        "sing",
        "okeani",
        "Гвинея",
        "pakistansk",
        "d’Azerbaidjan",
        "сӕрдыгон",
        "Bangla",
        "Таити",
        "ostrovov",
        "Desh",
        "टापू",
        "iransk",
        "Венесуэла",
        "Canolbarth",
        "Britse",
        "Verd",
        "Xile",
        "Xina",
        "Aleutine",
        "Gogledd",
        "Colòmbia",
        "l’illa",
        "yr",
        "Солтүстік",
        "Ierse",
        "filippinsk",
        "Kazakistan",
        "مرکزی",
        "Ynys",
        "گرین",
        "Ynysoedd",
        "מזרח",
        "kelet",
        "Αφρικής",
        "Северноамериканско",
        "va",
        "Indijsko",
        "नोरोन्हा",
        "ᏚᎦᏚᏛᎢ",
        "Галапагос",
        "ओम्स्क",
        "аралдарының",
        "Malvines",
        "Гилберт",
        "Almati",
        "chilensk",
        "표준",
        "আফ্রিকা",
        "peruansk",
        "प्रमाणवेळ",
        "l’est",
        "දහවල්",
        "Africii",
        "Japó",
        "Kamtxatka",
        "Yangi",
        "Կենտրոնական",
        "מרכז",
        "samoansk",
        "Maurici",
        "colombiansk",
        "oest",
        "bangladeshisk",
        "d’Ulan",
        "Даруссалам",
        "пацифичко",
        "Јерменија",
        "Νησιού",
        "Nieu",
        "Зеленортска",
        "vest",
        "ცენტრალური",
        "نیو",
        "Caledònia",
        "Северозападни",
        "Мексички",
        "Пацифик",
        "ದ್ವೀಪಗಳ",
        "Тајпеј",
        "Irkuck",
        "ਗਰਮੀ",
        "ਰੁੱਤ",
        "georgisk",
        "l’Uruguai",
        "Ida",
        "Kesk",
        "l’Uzbekistan",
        "Vanatu",
        "अफ्रिकी",
        "d’Ekaterinburg",
        "جنوبی",
        "Юккъера",
        "Folklend",
        "جزیرهٔ",
        "ئوتتۇرا",
        "Кук",
        "loto",
        "Zeland",
        "uruguayansk",
        "මධ්‍යම",
        "Meadhan",
        "Pier",
        "арлын",
        "koreansk",
        "Гвиана",
        "Tajpej",
        "paraguayansk",
        "Ziit",
        "ostrovů",
        "Frans",
        "Franse",
        "Ирландия",
        "Nước",
        "կղզու",
        "వెలుతురు",
        "Indonezisë",
        "Austur",
        "Rotera",
        "Малайзия",
        "ja",
        "Сёва",
        "આઇલેન્ડ્સ",
        "Gvajana",
        "Боливия",
        "પશ્ચિમી",
        "ઉત્તર",
        "મધ્ય",
        "erdialdeko",
        "Дейвис",
        "Ниуе",
        "Verder",
        "Питкерн",
        "Шығыс",
        "isole",
        "Островах",
        "dell’Indonesia",
        "വേനൽക്കാല",
        "Inizi",
        "Oileán",
        "Arab",
        "Oirthear",
        "Nya",
        "och",
        "Косрае",
        "Чаморро",
        "Эквадор",
        "Гайана",
        "Мьянма",
        "Ниуэ",
        "Реюньон",
        "Silangang",
        "Уоллис",
        "Sentral",
        "Австрали",
        "Gruzija",
        "Kirgistan",
        "közép",
        "Αργεντινής",
        "የአውስትራሊያ",
        "ausztráliai",
        "Joto",
        "Dương",
        "MYT",
        "szigeti",
        "Muda",
        "Stillehavskysten",
        "θερινή",
        "χειμερινή",
        "Awstraliýa",
        "nordamerikanske",
        "argentinsko",
        "र",
        "središnje",
        "ग्रीनल्यान्डको",
        "Kukova",
        "तुर्कमेनिस्तान",
        "Zapadni",
        "Úc",
        "टोंगा",
        "Ավստրալիայի",
        "American",
        "Kolumbija",
        "उलान",
        "d’Australia",
        "Alyaska",
        "Tanjung",
        "Cile",
        "Sakit",
        "Okean",
        "चिली",
        "केप",
        "Braziliya",
        "kazahstansko",
        "केंद्रीय",
        "अमेरिकी",
        "उत्तरी",
        "Çin",
        "Çoybalsan",
        "Kolumbiya",
        "Pasxa",
        "Fransız",
        "və",
        "Hov",
        "Laut",
        "Qrenlandiya",
        "Filipina",
        "Ostrvo",
        "Arjantin",
        "İran",
        "İrkutsk",
        "İsrail",
        "Ástralíu",
        "Yaponiya",
        "Koreya",
        "ve",
        "Grönland",
        "မြောက်အမေရိက",
        "Kaledoniya",
        "Zelandiya",
        "யேகாடெரின்பர்க்",
        "யகுட்ஸ்க்",
        "வோல்கோக்ராட்",
        "விளாடிவோஸ்டோக்",
        "miền",
        "வனுவாட்டு",
        "உஸ்பெகிஸ்தான்",
        "ಉತ್ತರ",
        "ಅಮೆರಿಕದ",
        "உருகுவே",
        "துர்க்மெனிஸ்தான்",
        "ಆಸ್ಟ್ರೇಲಿಯಾದ",
        "Nyufaundlend",
        "Noronya",
        "Filippin",
        "டோங்கா",
        "Mauricijus",
        "தாய்பே",
        "Мексика",
        "Saxalin",
        "Austraalia",
        "אוסטרליה",
        "சமோவா",
        "சமரா",
        "சகலின்",
        "Severozapadni",
        "Hindi",
        "Kaledonija",
        "Аўстраліі",
        "вострава",
        "Meksički",
        "і",
        "Pacifik",
        "Novi",
        "Njufaundlend",
        "अर्जेनटिनी",
        "Paragvaj",
        "Filipini",
        "Waqtiyada",
        "Jakutski",
        "આઇલેન્ડ",
        "Чаморо",
        "Пхеньян",
        "ઓસ્ટ્રેલિયન",
        "태평양",
        "아르헨티나",
        "sewjeroameriski",
        "ostrvo",
        "અમેરિકન",
        "Grenlandiya",
        "Omski",
        "Čojbalsan",
        "그린란드",
        "Острови",
        "Čile",
        "멕시코",
        "Čatam",
        "Krasnojarski",
        "Irkutski",
        "آسٹریلِیَن",
        "Zelenortska",
        "Brazilija",
        "Avstralski",
        "Bangladeš",
        "Azori",
        "Ipar",
        "Amerikako",
        "čas:",
        "Понапе",
        "Zigg",
        "অঞ্চলের",
        "দিনের",
        "প্রশান্ত",
        "g‘arbiy",
        "Azerbejdžan",
        "মহাসাগরীয়",
        "গৃষ্মকালীন",
        "pacifiski",
        "Tinch",
        "Atlantsko",
        "Pódpołnocnoameriski",
        "Чыгыш",
        "Insel",
        "Norteamérica",
        "Osidental",
        "північноамериканським",
        "Μεξικού",
        "Tadschikistan",
        "ऑस्ट्रेलियन",
        "Jermenija",
        "Seychellen",
        "গ্রীনল্যান্ড",
        "Urugvaj",
        "ਅਫਰੀਕਾ",
        "Шимолий",
        "Astràilia",
        "ਪੈਸਿਫਿਕ",
        "ਅਰਜਨਟੀਨਾ",
        "Австралийн",
        "Марказий",
        "эргийн",
        "Төв",
        "ਅਤੇ",
        "وسطی",
        "ਗ੍ਰੀਨਲੈਂਡ",
        "സെൻട്രൽ",
        "അമേരിക്കൻ",
        "വടക്കെ",
        "Zapadna",
        "ایسٹرن",
        "கைஜைலோர்டா",
        "மிக்குயிலான்",
        "پیسفک",
        "பியரி",
        "செயின்ட்",
        "பிலிப்பைன்",
        "ارجنٹینا",
        "آرژانتین",
        "பெரு",
        "பராகுவே",
        "ویسٹرن",
        "Akutobe",
        "பாகிஸ்தான்",
        "Akvatau",
        "برازیلیا",
        "ஓம்ஸ்க்",
        "நோவோசிபிரிஸ்க்",
        "நோரோன்ஹா",
        "Kolombia",
        "Apija",
        "hAstráile",
        "چین",
        "பெர்னான்டோ",
        "நியூஃபவுண்ட்லாந்து",
        "Macquarieinsel",
        "Kaledonia",
        "Aljaska",
        "நியூசிலாந்து",
        "Австралија",
        "Futunase",
        "கலிடோனியா",
        "ओस्ट्रेलिया",
        "Chuukse",
        "Tadzjiekse",
        "மாஸ்கோ",
        "Singaporese",
        "Kirgisistan",
        "Seychelse",
        "და",
        "گرینلند",
        "ავსტრალიის",
        "Réunionse",
        "ამერიკის",
        "ჩრდილოეთ",
        "பாடர்",
        "Belause",
        "ایران",
        "Niuese",
        "اسرائیل",
        "Nepalese",
        "हवाई",
        "Ozean",
        "Myanmarese",
        "உலன்",
        "மெக்ஸிகன்",
        "மெக்ஸிகோ",
        "Kosraese",
        "شمال",
        "مکزیک",
        "வடமேற்கு",
        "Centralno",
        "Oceaan",
        "மொரிஷியஸ்",
        "Guamese",
        "மகதன்",
        "Akre",
        "மக்காவ்",
        "ஹோவ்",
        "லார்ட்",
        "اومسک",
        "پاکستان",
        "க்ரஸ்னோயார்ஸ்க்",
        "Süd",
        "Timorese",
        "halve",
        "கொரிய",
        "اور",
        "Osttimor",
        "Bruneise",
        "Australyske",
        "Pasifiki",
        "Кина",
        "கஜகஸ்தான்",
        "கம்சட்ஸ்கி",
        "Atlantiki",
        "ازبکستان",
        "Azeribaijani",
        "Uchina",
        "Ore",
        "பெட்ரோபவ்லோவ்ஸ்க்",
        "ஜப்பான்",
        "இஸ்ரேல்",
        "இர்குட்ஸ்க்",
        "ஈரான்",
        "Fernandu",
        "Keski",
        "severoamerický",
        "Wyspy",
        "salas",
        "Pasaka",
        "fakaʻaositelēlia",
        "Ufaransa",
        "Austrālijas",
        "atlantsko",
        "Israeli",
        "Kaskazini",
        "mchana",
        "pasifiki",
        "Kinesko",
        "Ufilipino",
        "அக்ரே",
        "Srednjeevropsko",
        "Γροιλανδίας",
        "Indokina",
        "Sentraal",
        "ඇමරිකානු",
        "උතුරු",
        "д’Юрвиль",
        "Oostelike",
        "அலாஸ்கா",
        "Гамбье",
        "அல்மாடி",
        "அமேசான்",
        "και",
        "மவுன்டைன்",
        "Australijos",
        "அனாடையர்",
        "Arabisk",
        "ஏபியா",
        "அட்டௌ",
        "அட்டோபே",
        "அரேபிய",
        "प्यासिफिक",
        "Pasifiese",
        "ஆர்மேனிய",
        "Питкэрн",
        "Argentinië",
        "Gitnang",
        "Westelike",
        "Bangladesj",
        "ᎡᎳᏗᏜ",
        "Amerikos",
        "Rytų",
        "Uskršnja",
        "அட்லாண்டிக்",
        "Australya",
        "Argentinei",
        "அசர்பைஜான்",
        "வங்கதேச",
        "பிரேசிலியா",
        "கேப்",
        "வெர்டே",
        "சத்தாம்",
        "சிலி",
        "Severoamerický",
        "Awstralia",
        "Vest",
        "சீன",
        "și",
        "சோய்பால்சன்",
        "Folklandska",
        "Franceze",
        "Groenlandei",
        "Noii",
        "கொலம்பியா",
        "குக்",
        "கியூபா",
        "ஹோவ்த்",
        "ஈஸ்டர்",
        "ஹாங்காங்",
        "Kina",
        "ஃபாக்லாந்து",
        "ஃபிஜி",
        "கயானா",
        "அலேஷியன்",
        "ஹவாய்",
        "Mið",
        "Franska",
        "grønlendsk",
        "Eystur",
        "യൂറോപ്യൻ",
        "Георгия",
        "ᏳᎳᏈ",
        "ᎤᎦᏚᏛᎢ",
        "Таджикистан",
        "Индокитай",
        "යුරෝපීය",
        "Europos",
        "Euroopan",
        "Nowa",
        "Валис",
        "Вејк",
        "Венецуела",
        "Трук",
        "Таџикистан",
        "Тахити",
        "Јужна",
        "Сејшели",
        "Гвинеја",
        "Северна",
        "Мосон",
        "Маркиз",
        "Малдиви",
        "Малезија",
        "Индия",
        "Индокина",
        "чыгыш",
        "антарктичко",
        "јужно",
        "Француско",
        "Zentralafrikanische",
        "Ostafrikanische",
        "Südafrikanische",
        "Aserbeidschanische",
        "Француска",
        "Aserbaidschanische",
        "Еквадор",
        "Bolivianische",
        "Димон",
        "Дејвис",
        "Weihnachtsinsel",
        "Kokosinseln",
        "Боливија",
        "Ecuadorianische",
        "Kaliningrader",
        "Französisch",
        "Französische",
        "Antarktisgebiete",
        "Inseln",
        "Mittlere",
        "जॉर्जिया",
        "भारतीय",
        "Indischer",
        "Zentralindonesische",
        "Ostindonesische",
        "Westindonesische",
        "Ostkasachische",
        "ოკეანის",
        "Westkasachische",
        "Linieninseln",
        "ევროპის",
        "ईस्टर्न",
        "Malaysische",
        "Antarktika",
        "Stáisiún",
        "Malediven",
        "hEorpa",
        "Marshallinseln",
        "Nepalesische",
        "Norfolkinsel",
        "Nördliche",
        "Marianen",
        "Ewrop",
        "Neuguinea",
        "Phoenixinseln",
        "Pitcairninseln",
        "Европын",
        "Pjöngjang",
        "Quysylorda",
        "Tuath",
        "Qysylorda",
        "Roinn",
        "Eòrpa",
        "Salomoninseln",
        "Südgeorgische",
        "Uruguyanische",
        "Uruguayanische",
        "Venezolaanse",
        "Tuvaluaanse",
        "Tahitiaanse",
        "Афганистан",
        "Surinaamse",
        "Salomonseilandse",
        "Pitcairneilandse",
        "Phoenixeilandse",
        "Шова",
        "zahodni",
        "Zentraal",
        "Guineese",
        "залив",
        "مغرِبی",
        "час:",
        "Mariaanse",
        "Noordelijk",
        "Еуропа",
        "Norfolkeilandse",
        "ئاۋسترالىيە",
        "ئوكيان",
        "ಯುರೋಪಿಯನ್",
        "saarte",
        "Nauruaanse",
        "Marshalleilandse",
        "Marquesaseilandse",
        "ದಿನದ",
        "Maldivische",
        "Maleisische",
        "Kirgizische",
        "Indochinese",
        "Indiase",
        "Gilberteilandse",
        "Gambiereilandse",
        "Galapagoseilandse",
        "Antarctische",
        "Eropa",
        "zuidelijke",
        "oostelijk",
        "यूरोपीय",
        "Ecuadoraanse",
        "Cocoseilandse",
        "Christmaseilandse",
        "Boliviaanse",
        "Bhutaanse",
        "యూరోపియన్",
        "उत्तर",
        "oeste",
        "Âu",
        "Irische",
        "Britische",
        "Afghaanse",
        "új",
        "Ýewropa",
        "SGT",
        "óceáni",
        "fakaʻeulope",
        "बेट",
        "França",
        "mexikansk",
        "eilânske",
        "Авганистан",
        "ماگادان",
        "nordvästmexikansk",
        "israelisk",
        "Europeu",
        "Jakutsko",
        "Valis",
        "Vejk",
        "गुयाना",
        "اندونزی",
        "वेस्टर्न",
        "Галапагосские",
        "Pjongjanško",
        "Omsko",
        "Chovd",
        "Honolulu",
        "यूरोप",
        "კუნძულის",
        "västgrönländsk",
        "कोलंबिया",
        "Novosibirsko",
        "östgrönländsk",
        "Marijanska",
        "अरबी",
        "Severna",
        "अमरिका",
        "Guyanatid",
        "Falklandsöarna",
        "Lár",
        "västeuropeisk",
        "पश्चीम",
        "östeuropeisk",
        "Ljetno",
        "centraleuropeisk",
        "Påskön",
        "kubansk",
        "Киргистан",
        "Lajn",
        "Cooköarnas",
        "Krasnojarsko",
        "Tjojbalsan",
        "azorisk",
        "azerbajdzjansk",
        "västaustralisk",
        "östaustralisk",
        "västcentralaustralisk",
        "centralaustralisk",
        "Сент",
        "Kamčatski",
        "Irkutsko",
        "nordamerikansk",
        "armenisk",
        "västargentinsk",
        "östargentinsk",
        "saudiarabisk",
        "Zalivsko",
        "västnordamerikansk",
        "bergen",
        "Klippiga",
        "Французские",
        "ເວລາ",
        "dell’Uzbekistan",
        "Nordamerikanesch",
        "Французская",
        "Yhdysvaltain",
        "dell’Uruguay",
        "ਨਿਊ",
        "östnordamerikansk",
        "Filippine",
        "Perù",
        "ਜਾਰਜੀਆ",
        "Минское",
        "centralnordamerikansk",
        "ਆਈਲੈਂਡਸ",
        "Mosca",
        "västafrikansk",
        "полулетнее",
        "Кокосовые",
        "ਮੱਧ",
        "Рождества",
        "নিউ",
        "Кейси",
        "mexický",
        "Aimeireaga",
        "Џорџија",
        "ਦੱਖਣੀ",
        "Chuain",
        "(Messico)",
        "Messico",
        "Гилберта",
        "västbrasiliansk",
        "ইন্দোনেশিয়া",
        "Eilean",
        "Соломонска",
        "Indoneziei",
        "coreana",
        "ороли",
        "Giappone",
        "Israele",
        "dell’Iran",
        "Реинион",
        "জর্জিয়া",
        "Среднее",
        "Южные",
        "इस्टर",
        "Маријанска",
        "Figi",
        "युरोपियन",
        "Georgiei",
        "फिजी",
        "Мијанмар",
        "Гринвичу",
        "Janubiy",
        "Маршалска",
        "बाटोर",
        "стандарное",
        "Персидский",
        "Europpa",
        "আর্জেনটিনা",
        "Insulele",
        "Кошре",
        "Argentinski",
        "Summerzick",
        "Zick",
        "Azore",
        "Индийский",
        "প্রমাণ",
        "দক্ষিণ",
        "মধ্য",
        "Cina",
        "Capo",
        "Insula",
        "ماسکو",
        "океанско",
        "aleutski",
        "Azzorre",
        "کیپ",
        "dell’Azerbaigian",
        "centroccidentale",
        "Weschtafrikanischi",
        "Afganistan",
        "Mitteleuropäischi",
        "Oschteuropäischi",
        "Weschteuropäischi",
        "Западный",
        "انڈونیشیا",
        "Orientale",
        "Centrinės",
        "орталық",
        "인도네시아",
        "борбордук",
        "유럽",
        "Атлантика",
        "Gujana",
        "dell’Atlantico",
        "dell’Armenia",
        "аралдары",
        "Indoneziya",
        "ᎢᏤ",
        "және",
        "Ejkrit",
        "araba",
        "тихоокеанско",
        "Rocciose",
        "Montagne",
        "Киргизия",
        "dell’Amazzonia",
        "dell’Alaska",
        "[Ako]",
        "ياۋروپا",
        "Jugore",
        "Ísrael",
        "станцыі",
        "Kasakstan",
        "Euroopa",
        "جارجیا",
        "Pergunungan",
        "Паўночнаамерыканскі",
        "Perancis",
        "Мальдивы",
        "Jepun",
        "Venesuela",
        "Маркизские",
        "Almatit",
        "קיץ",
        "Amazonës",
        "Brezit",
        "Malor",
        "Aktöbe",
        "ದ್ವೀಪ",
        "Anadirit",
        "Apias",
        "Антарктические",
        "Aktaut",
        "тимор",
        "Aktobit",
        "arabe",
        "д’Урвил",
        "Armenisë",
        "Atlantikut",
        "אירופה",
        "Qendroro",
        "Azerbajxhanit",
        "Azoreve",
        "Bangladeshit",
        "Brasilës",
        "Kepit",
        "Gjelbër",
        "Katamit",
        "Evrópu",
        "Endonezya",
        "Kilit",
        "Маршалловы",
        "Kinës",
        "အလယ်ပိုင်း",
        "Северные",
        "полу",
        "Çoibalsanit",
        "Kolumbisë",
        "మధ్యమ",
        "Kubës",
        "Lindor",
        "Pashkës",
        "Fixhit",
        "İndoneziya",
        "Guajanës",
        "(Келинг)",
        "Gjeorgjisë",
        "تونگا",
        "Hauai",
        "Kongut",
        "Hovdit",
        "Iranit",
        "Güney",
        "Irkutskut",
        "Izraelit",
        "Japonisë",
        "Petropavllovsk",
        "Kamçatkës",
        "Çili",
        "Марианские",
        "Кинеско",
        "Kazakistanit",
        "koreane",
        "Krasnojarskut",
        "Houit",
        "Понпеи",
        "Makaos",
        "Дарусалум",
        "Брунеј",
        "ऑस्‍ट्रेलियाई",
        "Kep.",
        "Magadanit",
        "Mauritiusit",
        "चीन",
        "سامارا",
        "Meksikës",
        "Veriperëndimore",
        "Сейшельские",
        "Eysturevropa",
        "meksikane",
        "Batorit",
        "Moskës",
        "Cənubi",
        "Соломоновы",
        "Marqueses",
        "gaska",
        "nuorti",
        "इंडोनेशिया",
        "जापान",
        "Islla",
        "Kaledonisë",
        "oarje",
        "దీవి",
        "Europeeske",
        "දූපත්",
        "मागादान",
        "Јужно",
        "मेक्सिको",
        "मेक्सिकन",
        "Zelandës",
        "Եվրոպայի",
        "гӀайренаш",
        "डे",
        "Njufaundlendit",
        "[Tokës",
        "территории",
        "esase",
        "uzbekisk",
        "نو",
        "S:t",
        "Re]",
        "Noronjës",
        "उरुग्वे",
        "Novosibirskut",
        "इन्डोनेशिया",
        "Omskut",
        "Великобритания",
        "जर्जिया",
        "Pakistanit",
        "Paraguait",
        "አውሮፓ",
        "vestafrikansk",
        "Amazonka",
        "Perusë",
        "Filipineve",
        "Ermenistan",
        "Atlantika",
        "Shën",
        "पेरु",
        "Mikuelon",
        "Bangladeş",
        "Kabo",
        "nyzeeländsk",
        "Fransuz",
        "Kaledonien",
        "Brunej",
        "Gonkong",
        "európai",
        "Kizilordit",
        "Sakalinit",
        "Samarës",
        "vestaustralsk",
        "Samoas",
        "stillahavstid",
        "የምዕራባዊ",
        "sentraleuropeisk",
        "østeuropeisk",
        "vesteuropeisk",
        "Tajpeit",
        "Tongës",
        "Kazakstan",
        "Indonesië",
        "antarktičko",
        "Türkmenistan",
        "Özbekistan",
        "Georgië",
        "havajsko",
        "Turkmenistanit",
        "Uruguait",
        "Uzbekistanit",
        "Vanuatusë",
        "Vladivostokut",
        "sentrale",
        "sentraal",
        "Volgogradit",
        "Jakutskut",
        "Ekaterinburgut",
        "Γεωργίας",
        "Ινδονησίας",
        "पाकिस्तान",
        "Филиппин",
        "मॉस्को",
        "Nove",
        "l’oceà",
        "Índic",
        "d’Indoxina",
        "Newydd",
        "Янги",
        "留尼汪时间",
        "克孜洛尔达夏令时间",
        "malaysisk",
        "Централна",
        "Berriko",
        "克孜洛尔达标准时间",
        "Nij",
        "吉尔吉斯斯坦时间",
        "乌兹别克斯坦夏令时间",
        "Kirguizistan",
        "平壤时间",
        "Malàisia",
        "波纳佩时间",
        "克拉斯诺亚尔斯克夏令时间",
        "克拉斯诺亚尔斯克标准时间",
        "科斯雷时间",
        "韩国夏令时间",
        "韩国标准时间",
        "哈萨克斯坦西部时间",
        "Британија",
        "哈萨克斯坦东部时间",
        "堪察加夏令时间",
        "皮特凯恩时间",
        "Reunió",
        "圣皮埃尔和密克隆群岛夏令时间",
        "Salomó",
        "圣皮埃尔和密克隆群岛标准时间",
        "堪察加标准时间",
        "菲尼克斯群岛时间",
        "hAfraice",
        "Bắc",
        "日本夏令时间",
        "Veneçuela",
        "Aigéin",
        "ახალი",
        "日本标准时间",
        "以色列夏令时间",
        "戴维斯时间",
        "古巴夏令时间",
        "आफ्रिका",
        "අප්‍රිකානු",
        "Francuska",
        "迪蒙迪尔维尔时间",
        "以色列标准时间",
        "菲律宾夏令时间",
        "菲律宾标准时间",
        "indisk",
        "伊尔库茨克夏令时间",
        "伊尔库茨克标准时间",
        "秘鲁夏令时间",
        "දිවා",
        "伊朗标准时间",
        "Čamoro",
        "乌兹别克斯坦标准时间",
        "印度尼西亚西部时间",
        "秘鲁标准时间",
        "巴拉圭夏令时间",
        "古巴标准时间",
        "乌拉圭夏令时间",
        "印度尼西亚东部时间",
        "印度尼西亚中部时间",
        "印度支那时间",
        "巴拉圭标准时间",
        "库克群岛仲夏时间",
        "库克群岛标准时间",
        "哥伦比亚夏令时间",
        "哥伦比亚标准时间",
        "巴布亚新几内亚时间",
        "жана",
        "科科斯群岛时间",
        "帕劳时间",
        "印度洋时间",
        "印度时间",
        "科布多夏令时间",
        "巴基斯坦夏令时间",
        "科布多标准时间",
        "Nua",
        "agus",
        "圣诞岛时间",
        "აფრიკის",
        "मार्शल",
        "乔巴山夏令时间",
        "乔巴山标准时间",
        "中国夏令时间",
        "中国标准时间",
        "Afraga",
        "香港夏令时间",
        "智利夏令时间",
        "智利标准时间",
        "查坦夏令时间",
        "乌拉圭标准时间",
        "查坦标准时间",
        "查莫罗时间",
        "h",
        "昭和基地時間",
        "图瓦卢时间",
        "香港标准时间",
        "凯西时间",
        "Maldive",
        "巴基斯坦标准时间",
        "鄂木斯克夏令时间",
        "Божићно",
        "даљи",
        "исток",
        "Европе",
        "阿留申夏令时间",
        "Гамбије",
        "Средње",
        "Гриничу",
        "okeāna",
        "鄂木斯克标准时间",
        "阿留申标准时间",
        "Заливско",
        "Лајн",
        "Nuaidh",
        "Маквери",
        "східним",
        "佛得角夏令时间",
        "佛得角标准时间",
        "文莱达鲁萨兰时间",
        "巴西利亚夏令时间",
        "Понпеј",
        "Пјонгјаншко",
        "巴西利亚标准时间",
        "ویک",
        "圭亚那时间",
        "Illa",
        "新西伯利亚夏令时间",
        "新西伯利亚标准时间",
        "Керла",
        "土库曼斯坦夏令时间",
        "海湾标准时间",
        "北马里亚纳群岛时间",
        "Afrikan",
        "关岛时间",
        "玻利维亚标准时间",
        "迪诺罗尼亚岛夏令时间",
        "格陵兰岛西部夏令时间",
        "Шинэ",
        "格陵兰岛西部标准时间",
        "不丹时间",
        "孟加拉夏令时间",
        "孟加拉标准时间",
        "格陵兰岛东部夏令时间",
        "रोथेरा",
        "迪诺罗尼亚岛标准时间",
        "afghansk",
        "格陵兰岛东部标准时间",
        "亚速尔群岛夏令时间",
        "格林尼治标准时间",
        "吉尔伯特群岛时间",
        "诺福克岛时间",
        "亚速尔群岛标准时间",
        "格鲁吉亚夏令时间",
        "格鲁吉亚标准时间",
        "ᎬᎿᎨᏍᏛ",
        "甘比尔时间",
        "加拉帕戈斯时间",
        "法属南方和南极领地时间",
        "આફ્રિકા",
        "પૂર્વ",
        "法属圭亚那标准时间",
        "પશ્ચિમ",
        "斐济夏令时间",
        "Uuden",
        "斐济标准时间",
        "福克兰群岛夏令时间",
        "福克兰群岛标准时间",
        "Naujosios",
        "vandenyno",
        "西欧夏令时间",
        "阿塞拜疆夏令时间",
        "阿塞拜疆标准时间",
        "澳大利亚西部夏令时间",
        "યુરોપિયન",
        "يېڭى",
        "澳大利亚西部标准时间",
        "澳大利亚东部夏令时间",
        "澳大利亚东部标准时间",
        "土库曼斯坦标准时间",
        "澳大利亚中西部夏令时间",
        "楚克时间",
        "澳大利亚中西部标准时间",
        "澳大利亚中部夏令时间",
        "澳大利亚中部标准时间",
        "大西洋夏令时间",
        "ئارىلى",
        "大西洋标准时间",
        "纽埃时间",
        "亚美尼亚夏令时间",
        "亚美尼亚标准时间",
        "阿根廷西部夏令时间",
        "阿根廷西部标准时间",
        "rytų",
        "אפריקה",
        "阿根廷夏令时间",
        "阿根廷标准时间",
        "阿拉伯夏令时间",
        "阿拉伯标准时间",
        "纽芬兰夏令时间",
        "阿克托别夏令时间",
        "汤加夏令时间",
        "האי",
        "阿克托别标准时间",
        "далайн",
        "阿克套夏令时间",
        "האיים",
        "阿克套标准时间",
        "阿皮亚夏令时间",
        "阿皮亚标准时间",
        "阿纳德尔夏令时间",
        "ئافرىقا",
        "阿纳德尔标准时间",
        "汤加标准时间",
        "北美太平洋夏令时间",
        "北美太平洋标准时间",
        "北美山区夏令时间",
        "北美山区标准时间",
        "北美东部夏令时间",
        "北美东部标准时间",
        "北美中部夏令时间",
        "Bình",
        "北美中部标准时间",
        "亚马逊夏令时间",
        "托克劳时间",
        "亚马逊标准时间",
        "阿拉木图夏令时间",
        "阿拉木图标准时间",
        "阿拉斯加夏令时间",
        "阿拉斯加标准时间",
        "西部非洲夏令时间",
        "ఆఫ్రికా",
        "西部非洲标准时间",
        "南部非洲时间",
        "东部非洲时间",
        "中部非洲时间",
        "阿富汗时间",
        "阿克里夏令时间",
        "阿克里标准时间",
        "塔吉克斯坦时间",
        "Afrikos",
        "Ирска",
        "台北夏令时间",
        "Африкийн",
        "台北标准时间",
        "Zachodnia",
        "塔希提岛时间",
        "英国夏令时间",
        "semini",
        "纽芬兰标准时间",
        "venezuelansk",
        "Affrekaanesche",
        "西欧标准时间",
        "新西兰夏令时间",
        "爱尔兰标准时间",
        "ڑےلایِٔٹ",
        "昭和时间",
        "ദ്വീപ്",
        "新西兰标准时间",
        "अफ़्रीका",
        "ججیٖرُک",
        "Standardni",
        "新喀里多尼亚夏令时间",
        "Poletni",
        "یوٗرپی",
        "新喀里多尼亚标准时间",
        "尼泊尔时间",
        "瑙鲁时间",
        "ڈی",
        "苏里南时间",
        "远东标准时间",
        "ఆస్ట్రేలియా",
        "otoki",
        "东欧夏令时间",
        "缅甸时间",
        "莫斯科夏令时间",
        "乌兰巴托标准时间",
        "ecuadoriansk",
        "Standardní",
        "莫斯科标准时间",
        "东欧标准时间",
        "乌兰巴托夏令时间",
        "墨西哥太平洋夏令时间",
        "मध्‍य",
        "南乔治亚岛时间",
        "አፍሪካ",
        "所罗门群岛时间",
        "ደሴት",
        "新加坡标准时间",
        "塞舌尔时间",
        "Жаңа",
        "lejana",
        "萨摩亚夏令时间",
        "Mouson",
        "britànica",
        "Phi",
        "d’Irlanda",
        "የምስራቅ",
        "伊朗夏令时间",
        "叶卡捷琳堡夏令时间",
        "Enez",
        "Նոր",
        "叶卡捷琳堡标准时间",
        "Aafrika",
        "墨西哥太平洋标准时间",
        "雅库茨克夏令时间",
        "နွေရာသီစံတော်ချိန်",
        "ookeani",
        "墨西哥西北部夏令时间",
        "墨西哥西北部标准时间",
        "莫森时间",
        "毛里求斯夏令时间",
        "l’Afganistan",
        "毛里求斯标准时间",
        "boliviansk",
        "sud",
        "中欧夏令时间",
        "马绍尔群岛时间",
        "马克萨斯群岛时间",
        "Աֆրիկայի",
        "马尔代夫时间",
        "中欧标准时间",
        "bhutansk",
        "马来西亚时间",
        "Günorta",
        "马加丹夏令时间",
        "雅库茨克标准时间",
        "瓦利斯和富图纳时间",
        "马加丹标准时间",
        "厄瓜多尔标准时间",
        "威克岛时间",
        "麦夸里岛时间",
        "Француз",
        "沃斯托克时间",
        "澳门夏令时间",
        "萨摩亚标准时间",
        "萨马拉夏令时间",
        "伏尔加格勒夏令时间",
        "澳门标准时间",
        "伏尔加格勒标准时间",
        "Азор",
        "Uus",
        "海参崴夏令时间",
        "Hindistan",
        "Островите",
        "Kiritimati",
        "Täze",
        "海参崴标准时间",
        "Affrica",
        "豪勋爵岛夏令时间",
        "ಕೇಂದ್ರ",
        "mig",
        "Afrikaanske",
        "estiu",
        "豪勋爵岛标准时间",
        "复活节岛夏令时间",
        "l’Equador",
        "afrikai",
        "ဩစတြေးလျား",
        "Cefnfor",
        "委内瑞拉时间",
        "复活节岛标准时间",
        "ಆಫ್ರಿಕಾ",
        "萨马拉标准时间",
        "莱恩群岛时间",
        "东帝汶时间",
        "l’Extrem",
        "uharteko",
        "Orient",
        "Новай",
        "瓦努阿图夏令时间",
        "fakaʻafelika",
        "瓦努阿图标准时间",
        "兰卡时间",
        "የምዕራብ",
        "अफ्रीका",
        "Guaiana",
        "d’Antàrtida",
        "库页岛夏令时间",
        "库页岛标准时间",
        "indokinesisk",
        "Meridià",
        "罗瑟拉时间",
        "l’Índia",
        "阿拉斯加夏令時間",
        "moskovsko",
        "novozelandsko",
        "newfoundlandsko",
        "novosibirsko",
        "omsko",
        "pakistansko",
        "paragvajsko",
        "peruansko",
        "filipinsko",
        "Sveti",
        "Petar",
        "Kizilorde",
        "sahalinsko",
        "samarsko",
        "samoansko",
        "tajpeško",
        "Tonge",
        "turkmenistansko",
        "urugvajsko",
        "uzbekistansko",
        "Vanuatua",
        "vladivostočko",
        "volgogradsko",
        "jakutsko",
        "ekaterinburško",
        "batorsko",
        "ulan",
        "fakafonuamata",
        "Suid",
        "Čojbalsanski",
        "sjeverozapadno",
        "Mauricijusa",
        "fakaʻasenitina",
        "Magadanski",
        "magadansko",
        "pasifika",
        "mexiski",
        "makaosko",
        "krasnojarsko",
        "korejsko",
        "japansko",
        "izraelsko",
        "irkutsko",
        "iransko",
        "hovdsko",
        "hongkonško",
        "zapadnogrenlandsko",
        "istočnogrenlandsko",
        "gruzijsko",
        "Fidžija",
        "falklandsko",
        "zapadnoeuropsko",
        "istočnoeuropsko",
        "srednjoeuropsko",
        "Uskršnjeg",
        "चोइबाल्सन",
        "kubansko",
        "otočje",
        "államokbeli",
        "csendes",
        "kolumbijsko",
        "चाथाम",
        "choibalsansko",
        "kinesko",
        "čileansko",
        "Chathama",
        "भर्दे",
        "otočja",
        "és",
        "बंगलादेशी",
        "grönlandi",
        "Zelenortskog",
        "Grenlandiýa",
        "एजोरेस्",
        "we",
        "brazilijsko",
        "bangladeško",
        "adasy",
        "azorsko",
        "mexikói",
        "azerbajdžansko",
        "zapadnoaustralsko",
        "istočnoaustralsko",
        "australsko",
        "srednjoaustralsko",
        "armensko",
        "umman",
        "Ýuwaş",
        "arapsko",
        "Apije",
        "anadirsko",
        "amazonsko",
        "altmajsko",
        "aljaško",
        "zapadnoafričko",
        "अजरबैजान",
        "वेक",
        "व्लादिवोस्तोक",
        "ताहिती",
        "स्योवा",
        "Amasone",
        "सेंट",
        "Ամերիկայի",
        "Արգենտինայի",
        "पेरू",
        "गिनी",
        "नोवोसिबिर्स्क",
        "fakamekisikou",
        "և",
        "एट्लान्टिक",
        "Գրենլանդիայի",
        "अर्मेनिया",
        "Մեքսիկայի",
        "Sentrale",
        "मॉरीशस",
        "గ్రీన్‌ల్యాండ్",
        "आपिया",
        "మరియు",
        "होवे",
        "लॉर्ड",
        "लाइन",
        "Berg",
        "हिमाली",
        "अमेजन",
        "काँग",
        "हाँग",
        "ग्रीनलैंड",
        "Arabiese",
        "गिल्बर्ट",
        "और",
        "दक्षिणी",
        "अलस्काको",
        "क्यूबा",
        "Armenië",
        "Atlantiese",
        "Aserbeidjan",
        "अटलांटिक",
        "आर्मेनिया",
        "अर्जेंटीना",
        "Teluk",
        "vanuatisk",
        "प्रशांत",
        "Makau",
        "Almatı",
        "Moskwa",
        "Baru",
        "పసిఫిక్",
        "Dağ",
        "Anadır",
        "usbekisk",
        "Aktav",
        "Arabistan",
        "İçbatı",
        "Azerbaycan",
        "Azorlar",
        "Bandaríkjanna",
        "Kanada",
        "Kyrrahafssvæðinu",
        "Argentínu",
        "Asore",
        "Şili",
        "Kolombiya",
        "eyjum",
        "מקסיקו",
        "Küba",
        "Paskalya",
        "Brasilië",
        "Gürcistan",
        "tongansk",
        "Japonya",
        "సెంట్రల్",
        "גרינלנד",
        "Kamçatski",
        "Kore",
        "Kuzeybatı",
        "Kıyısı",
        "Grænlandi",
        "ஃப்யூடுனா",
        "மற்றும்",
        "வாலிஸ்",
        "Havaí",
        "வேக்",
        "வோஸ்டோக்",
        "Batur",
        "Kaap",
        "Moskova",
        "eyju",
        "வெனிசுலா",
        "Kaledonya",
        "Filipinler",
        "אי",
        "Mexíkó",
        "துவாலு",
        "Kızılorda",
        "(Samara)",
        "சுக்",
        "Colombië",
        "டோக்கெலாவ்",
        "தஜிகிஸ்தான்",
        "Cookeilande",
        "தஹிதி",
        "ஸ்யோவா",
        "சுரினாம்",
        "தெற்கு",
        "சாலமன்",
        "சிங்கப்பூர்",
        "சீசெல்ஸ்",
        "Paaseiland",
        "ארגנטינה",
        "Falklandeilande",
        "ארה״ב",
        "newzealandsk",
        "تىنچ",
        "kaledonsk",
        "ئارگېنتىنا",
        "ۋە",
        "گىرېنلاند",
        "meksikanske",
        "nordvestlige",
        "mauritisk",
        "مېكسىكا",
        "Aleusiër",
        "ગ્રીનલેન્ડ",
        "પૂર્વીય",
        "øya",
        "પેસિફિક",
        "પૂર્વી",
        "eiland",
        "Kamtsjatskij)",
        "(Petropavlovsk",
        "Noordwes",
        "israelsk",
        "Meksikaanse",
        "Zelandia",
        "Ulaanbatar",
        "Kaledonië",
        "Arxentina",
        "Khovd",
        "Aleutene",
        "тихоокеанським",
        "vestgrønlandsk",
        "Mheagsago",
        "østgrønlandsk",
        "Seeland",
        "fijiansk",
        "Falklandsøyene",
        "архіпелазі",
        "Guinee",
        "Filippynse",
        "Sint",
        "Påskeøya",
        "cubansk",
        "Cookøyene",
        "Oesbekistan",
        "Graonlainn",
        "Гренландії",
        "የመካከለኛው",
        "英國夏令時間",
        "Kapp",
        "愛爾蘭標準時間",
        "станції",
        "ምስራቃዊ",
        "Argantaine",
        "Sèimh",
        "Мексиці",
        "nOileán",
        "Iosrael",
        "asorisk",
        "aserbajdsjansk",
        "பிரிட்டிஷ்",
        "østaustralsk",
        "ஐரிஷ்",
        "الاسکا",
        "Graonlainne",
        "Uingereza",
        "ரோதேரா",
        "ரீயூனியன்",
        "atlanterhavskystlig",
        "பியாங்யாங்",
        "போனாபே",
        "பிட்கெய்ர்ன்",
        "ماؤنٹین",
        "انیدر",
        "ایپیا",
        "ஃபோனிக்ஸ்",
        "عرب",
        "آرمینیا",
        "اٹلانٹک",
        "கினியா",
        "பபுவா",
        "பாலவ்",
        "آذربائیجان",
        "armensk",
        "Sile",
        "மரினா",
        "வடக்கு",
        "ازوریس",
        "hAirgintíne",
        "நார்ஃபோக்",
        "நியு",
        "Chiúin",
        "بنگلہ",
        "دیش",
        "ورڈی",
        "நேபாள",
        "நவ்ரூ",
        "மியான்மர்",
        "چیتھم",
        "چلی",
        "کولمبیا",
        "کک",
        "کیوبا",
        "vestargentinsk",
        "மாசன்",
        "ایسٹر",
        "மார்ஷல்",
        "மார்கியூசாஸ்",
        "மாலத்தீவுகள்",
        "மலேஷிய",
        "Groenlânske",
        "மாக்கியூரி",
        "argentinsk",
        "فاک",
        "فجی",
        "லைன்",
        "லங்கா",
        "கிர்கிஸ்தான்",
        "فرینچ",
        "கோஸ்ரே",
        "گیانا",
        "Argentynske",
        "estive",
        "ہوائی",
        "الیوٹیئن",
        "ہانگ",
        "کانگ",
        "ہووڈ",
        "ہند",
        "இந்தோசீன",
        "பெருங்கடல்",
        "இந்தியப்",
        "இந்திய",
        "Filipsoyggjar",
        "Nýsæland",
        "Nýkaledónia",
        "Móritius",
        "வளைகுடா",
        "கம்",
        "Falklandsoyggjar",
        "சராசரி",
        "கிரீன்விச்",
        "கில்பர்ட்",
        "Vesturevropa",
        "கேம்பியர்",
        "கலபகோஸ்",
        "அண்டார்டிக்",
        "தெற்கத்திய",
        "பிரெஞ்சு",
        "Miðevropa",
        "ஃபிரஞ்சு",
        "Páskaoyggin",
        "Cooksoyggjar",
        "தூர",
        "Kili",
        "ஈக்வடார்",
        "Grønhøvdaoyggjar",
        "திமோர்",
        "உர்வில்லே",
        "Azorurnar",
        "டுமோண்ட்",
        "டேவிஸ்",
        "Aserbadjan",
        "அரை",
        "vestur",
        "eystur",
        "miðvestur",
        "கோகோஸ்",
        "mið",
        "கிறிஸ்துமஸ்",
        "Amasona",
        "Vesturafrika",
        "arabisk",
        "ارکتسک",
        "சாமோரோ",
        "(Anadyr)",
        "جاپان",
        "டருஸ்ஸலாம்",
        "புருனே",
        "پیٹروپاؤلووسک",
        "பொலிவியா",
        "பூடான்",
        "کیمچسکی",
        "அசோர்ஸ்",
        "அசோரஸ்",
        "قزاخستان",
        "کوریا",
        "Pasipiko",
        "لارڈ",
        "ہووے",
        "ماریشس",
        "میکسیکو",
        "میکسیکن",
        "یولان",
        "بیتور",
        "کیلیڈونیا",
        "نیوزی",
        "فاؤنڈ",
        "فرنانڈو",
        "نوووسیبرسک",
        "Meksikon",
        "پیراگوئے",
        "پیرو",
        "فلپائن",
        "سینٹ",
        "தென்",
        "پیئر",
        "مکلیئون",
        "Ayalandi",
        "ஆஃப்கானிஸ்தான்",
        "Grönlannin",
        "سخالین",
        "(USA)",
        "ساموآ",
        "Ushelisheli",
        "پیئی",
        "ٹونگا",
        "wastani",
        "ترکمانستان",
        "یوروگوئے",
        "kisiwa",
        "Krasnoyask",
        "وانوآٹو",
        "Bahari",
        "ولادی",
        "Antaktiki",
        "ووستک",
        "kati",
        "Argentiinan",
        "Tyynenmeren",
        "nusu",
        "وولگوگراد",
        "یکاترینبورگ",
        "یاکوتسک",
        "ولگاگراد",
        "ولادی‌وستوک",
        "واناتو",
        "اروگوئه",
        "ترکمنستان",
        "Arabiani",
        "Uarabuni",
        "تایپه",
        "سورینام",
        "سنگاپور",
        "ساخالین",
        "قیزیل‌اوردا",
        "میکلون",
        "سنت‌پیر",
        "فیلیپین",
        "پرو",
        "پاراگوئه",
        "نووسیبیرسک",
        "نورونیا",
        "Mountains",
        "østkysten",
        "det",
        "دی",
        "Britanija",
        "alaskisk",
        "Irska",
        "क्यूबाको",
        "फल्कल्यान्ड",
        "फ्रेन्च",
        "እና",
        "ግሪንላንድ",
        "एलुटियन",
        "हङकङ",
        "होब्ड",
        "इरानी",
        "ईर्कुट्स्क",
        "इजरायल",
        "काजकस्तान",
        "कोरियाली",
        "क्रासनोयार्क",
        "लर्ड",
        "हावे",
        "मउरिटस",
        "نیوفاندلند",
        "زلاند",
        "मेक्सिकोको",
        "मस्को",
        "नयाँ",
        "جدید",
        "कालेदोनिया",
        "न्यूजिल्यान्ड",
        "फर्नान्डो",
        "کالدونیای",
        "میانمار",
        "مسکو",
        "नोभोसिविर्स्क",
        "पाकिस्तानी",
        "पाराग्वे",
        "اولان‌باتور",
        "موریس",
        "ماکائو",
        "फिलिपिनी",
        "सेन्ट",
        "पियर्रे",
        "मिक्युलोनको",
        "साखालिन",
        "सामोअ",
        "ताइपेइ",
        "उज्बेकिस्तान",
        "भानुआतु",
        "भ्लादिभास्टोक",
        "भोल्गाग्राद",
        "याकुस्ट",
        "येकाटेरिनबर्ग",
        "የሜክሲኮ",
        "የኒው",
        "لردهو",
        "Ireland",
        "Britaniya",
        "کراسنویارسک",
        "کره",
        "قزاقستان",
        "پتروپاولوسک‐کامچاتسکی",
        "ژاپن",
        "ایرکوتسک",
        "هند",
        "خوود",
        "هنگ‌کنگ",
        "წყნარი",
        "هاوایی‐الوشن",
        "არგენტინის",
        "خلیج",
        "گرجستان",
        "nase",
        "ne",
        "d’África",
        "norteamericanu",
        "Pacíficu",
        "فرانسه",
        "گویان",
        "فیجی",
        "d’Arxentina",
        "Este",
        "Xapón",
        "فالکلند",
        "ایستر",
        "Méxicu",
        "Bátor",
        "تیمور",
        "Paquistán",
        "Dağlıq",
        "Ərəbistan",
        "کوبا",
        "کوک",
        "کلمبیا",
        "Ermənistan",
        "کوکوس",
        "چویبالسان",
        "شیلی",
        "Azərbaycan",
        "გრენლანდიის",
        "Banqladeş",
        "چت‌هام",
        "چامورو",
        "Boliviya",
        "Kape",
        "Çatham",
        "کیپ‌ورد",
        "Fici",
        "Gurcüstan",
        "Havay",
        "دارالسلام",
        "برونئی",
        "بنگلادش",
        "آزور",
        "მექსიკის",
        "آذربایجان",
        "جمهوری",
        "Honq",
        "آتلانتیک",
        "Konq",
        "ارمنستان",
        "عربستان",
        "آپیا",
        "آنادیر",
        "کوهستانی",
        "Hind",
        "အနောက်",
        "အာဂျင်တီးနား",
        "Qazaxıstan",
        "Layn",
        "Maqadan",
        "آمازون",
        "آلماآتا",
        "آلاسکا",
        "افغانستان",
        "یکوتسک",
        "Malayziya",
        "Maldiv",
        "یکاٹیرِنبرگ",
        "Mavriki",
        "Şimal",
        "Ulanbator",
        "Myanma",
        "Venecuela",
        "Paraqvay",
        "Čuuk",
        "Mexikoko",
        "Pxenyan",
        "eta",
        "Tog‘",
        "(AQSH)",
        "Saudiya",
        "Tadžikistan",
        "Arabistoni",
        "Орталық",
        "Armaniston",
        "Ozarbayjon",
        "Chatem",
        "Pazifikoko",
        "Xitoy",
        "Choybalsan",
        "шығыс",
        "Тынық",
        "Gruziya",
        "Novosibirski",
        "батыс",
        "Mehhiko",
        "Gavayi",
        "aleut",
        "Xovd",
        "Malaisia",
        "Eron",
        "Šova",
        "аралы",
        "Isroil",
        "Džordžija",
        "Фолкленд",
        "Syova",
        "Južna",
        "Solomonska",
        "Sejšeli",
        "Reinion",
        "Qozogʻiston",
        "Taybey",
        "Xau",
        "Gröönimaa",
        "Ponpej",
        "Mavrikiy",
        "Алеут",
        "Shimoli",
        "Gvineja",
        "Pokiston",
        "Paragvay",
        "Pyer",
        "Tayvan",
        "Turkmaniston",
        "Urugvay",
        "Tacikistan",
        "Tonqa",
        "O‘zbekiston",
        "Vaikse",
        "Nam",
        "Thái",
        "Türkmənistan",
        "Αικατερίνμπουργκ",
        "Γιακούτσκ",
        "Mijanmar",
        "Uruqvay",
        "Βόλγκογκραντ",
        "Βλαδιβοστόκ",
        "Өзбекстан",
        "Βανουάτου",
        "Ουζμπεκιστάν",
        "Moson",
        "Ουρουγουάης",
        "Maršalska",
        "Maldivi",
        "Malezija",
        "Makveri",
        "Τουρκμενιστάν",
        "Τόνγκα",
        "Šri",
        "Košre",
        "Ταϊπέι",
        "Σαμόα",
        "okeansko",
        "Σαχαλίνης",
        "Μικελόν",
        "Griniču",
        "po",
        "Srednje",
        "Πιερ",
        "Gambije",
        "južno",
        "Francusko",
        "Σαιντ",
        "Evrope",
        "istok",
        "dalji",
        "Φιλιππινών",
        "Περού",
        "timor",
        "Παραγουάης",
        "d’Urvil",
        "Dimon",
        "Dejvis",
        "polu",
        "Πακιστάν",
        "Ομσκ",
        "Νοβοσιμπίρσκ",
        "(Keling)",
        "Νορόνια",
        "Božićno",
        "ντε",
        "Φερνάρντο",
        "Γης",
        "Ζηλανδίας",
        "Darusalum",
        "Καληδονίας",
        "Bolivija",
        "Μόσχας",
        "Μπατόρ",
        "Ουλάν",
        "Ειρηνικού",
        "Βορειοδυτικού",
        "Μαυρίκιου",
        "Μάγκανταν",
        "Μακάο",
        "Χάου",
        "Λορντ",
        "Κρασνόγιαρσκ",
        "Κορέας",
        "Južno",
        "Καζακστάν",
        "Καμτσάτσκι",
        "Πετροπαβλόβσκ",
        "Avganistan",
        "Ιαπωνίας",
        "Ισραήλ",
        "Ιρκούτσκ",
        "Ιράν",
        "Χοβντ",
        "Κονγκ",
        "Χονγκ",
        "νήσων",
        "Αλεούτιων",
        "Χαβάης",
        "Γουιάνας",
        "Φίτζι",
        "Φώκλαντ",
        "Πάσχα",
        "Ανατολικού",
        "Κούβας",
        "Κουκ",
        "Κολομβίας",
        "Τσοϊμπαλσάν",
        "Κίνας",
        "Χιλής",
        "Τσάθαμ",
        "Ακρωτηρίου",
        "Özbəkistan",
        "Volqoqrad",
        "Ueyk",
        "Uollis",
        "Πράσινου",
        "Μπραζίλια",
        "Yekaterinburq",
        "Μπανγκλαντές",
        "Αζορών",
        "Αζερμπαϊτζάν",
        "Заходняй",
        "Κεντροδυτικής",
        "Грэнландыі",
        "Ατλαντικού",
        "Мікелон",
        "Западноафриканско",
        "Αρμενίας",
        "източно",
        "Остров",
        "северозападно",
        "আলাস্কা",
        "পূর্বাঞ্চলের",
        "পার্বত্য",
        "অনদ্য্র্",
        "Αραβική",
        "Απία",
        "Αναντίρ",
        "অপিয়া",
        "আরবি",
        "Ορεινή",
        "আর্মেনিয়া",
        "অতলান্তিক",
        "আজারবাইজান",
        "Ανατολική",
        "Κεντρική",
        "Αμαζονίου",
        "Αλάσκας",
        "এজোরেস",
        "Νότιας",
        "বাংলাদেশ",
        "ব্রাসিলিয়া",
        "ɣetoɖofe",
        "ŋkekme",
        "ƒe",
        "কেপ",
        "ভার্দ",
        "Todzidukɔwo",
        "চ্যাথাম",
        "Quốc",
        "và",
        "Standardzit",
        "Summerzit",
        "艾克標準時間",
        "艾克夏令時間",
        "ग्रीनलँड",
        "Астӕуккаг",
        "চিলি",
        "阿富汗時間",
        "中非時間",
        "東非時間",
        "西非標準時間",
        "西非夏令時間",
        "आणि",
        "阿拉斯加標準時間",
        "চীন",
        "চয়বালসন",
        "কোলোম্বিয়া",
        "কুক",
        "Kaledonije",
        "কিউবা",
        "阿拉木圖標準時間",
        "阿拉木圖夏令時間",
        "ইস্টার",
        "Скӕсӕн",
        "Ныгъуылӕн",
        "ফকল্যান্ড",
        "亞馬遜標準時間",
        "Гуырдзыстоны",
        "亞馬遜夏令時間",
        "ফিজি",
        "中部夏令時間",
        "阿那底河標準時間",
        "阿那底河夏令時間",
        "阿皮亞標準時間",
        "阿皮亞夏令時間",
        "阿克陶標準時間",
        "阿克陶夏令時間",
        "阿克托比標準時間",
        "Мӕскуыйы",
        "ফরাসি",
        "अर्जेंटिना",
        "阿克托比夏令時間",
        "阿拉伯標準時間",
        "阿拉伯夏令時間",
        "पॅसिफिक",
        "पौर्वात्य",
        "阿根廷標準時間",
        "এবং",
        "হাওয়াই",
        "阿根廷夏令時間",
        "আলেউত",
        "হং",
        "কং",
        "阿根廷西部標準時間",
        "হোভড",
        "阿根廷西部夏令時間",
        "亞美尼亞標準時間",
        "ইরান",
        "亞美尼亞夏令時間",
        "ইরকুটস্ক",
        "大西洋標準時間",
        "大西洋夏令時間",
        "ইজরায়েল",
        "জাপান",
        "পিত্রেপ্যাভলস্ক",
        "澳洲中部標準時間",
        "澳洲中部夏令時間",
        "澳洲中西部標準時間",
        "ক্যামচ্যাটস্কি",
        "澳洲中西部夏令時間",
        "澳洲東部標準時間",
        "澳洲東部夏令時間",
        "澳洲西部標準時間",
        "澳洲西部夏令時間",
        "মান",
        "কজাকস্তান",
        "亞速爾群島標準時間",
        "亞速爾群島夏令時間",
        "কোরিয়ান",
        "孟加拉標準時間",
        "ਅਲਾਸਕਾ",
        "ক্রাসনোয়ার্স্কি",
        "孟加拉夏令時間",
        "不丹時間",
        "玻利維亞時間",
        "巴西利亞標準時間",
        "巴西利亞夏令時間",
        "ಮಧ್ಯ",
        "汶萊時間",
        "凱西站時間",
        "ਅਲਮਾਟੀ",
        "查莫洛時間",
        "查坦群島標準時間",
        "查坦群島夏令時間",
        "智利標準時間",
        "智利夏令時間",
        "中國標準時間",
        "中國夏令時間",
        "喬巴山標準時間",
        "喬巴山夏令時間",
        "聖誕島時間",
        "哥倫比亞標準時間",
        "ಪೆಸಿಫಿಕ್",
        "লর্ড",
        "哥倫比亞夏令時間",
        "庫克群島標準時間",
        "庫克群島半夏令時間",
        "古巴標準時間",
        "古巴夏令時間",
        "戴維斯時間",
        "東帝汶時間",
        "復活節島標準時間",
        "復活節島夏令時間",
        "中歐標準時間",
        "中歐夏令時間",
        "東歐標準時間",
        "東歐夏令時間",
        "歐洲遠東時間",
        "西歐標準時間",
        "西歐夏令時間",
        "福克蘭群島標準時間",
        "福克蘭群島夏令時間",
        "斐濟標準時間",
        "হাওয়ে",
        "斐濟夏令時間",
        "মসয়",
        "ಮತ್ತು",
        "法屬圭亞那時間",
        "法國南方及南極時間",
        "甘比爾群島時間",
        "ਅਮੇਜ਼ਨ",
        "吉爾伯特群島時間",
        "格林威治標準時間",
        "ম্যাগাডান",
        "格陵蘭東部標準時間",
        "ಗ್ರೀನ್‌ಲ್ಯಾಂಡ್",
        "格陵蘭東部夏令時間",
        "格陵蘭西部標準時間",
        "格陵蘭西部夏令時間",
        "Galabagos",
        "Waqtiga",
        "Kolambiya",
        "Xagaaga",
        "kolambiya",
        "ee",
        "ah",
        "Caadiga",
        "Jekaterinburgski",
        "mehiški",
        "মরিশাস",
        "in",
        "Wolgogradski",
        "아크레",
        "Wladiwostokski",
        "關島標準時間",
        "阿留申標準時間",
        "서아프리카",
        "알래스카",
        "알마티",
        "阿留申夏令時間",
        "아마존",
        "香港標準時間",
        "ਮਾਉਂਟੇਨ",
        "香港夏令時間",
        "Filipinski",
        "아나디리",
        "아피아",
        "악타우",
        "악퇴베",
        "아라비아",
        "Paraguayski",
        "科布多標準時間",
        "아르메니아",
        "대서양",
        "科布多夏令時間",
        "중서부",
        "아제르바이잔",
        "아조레스",
        "방글라데시",
        "브라질리아",
        "카보",
        "베르데",
        "채텀",
        "칠레",
        "중국",
        "초이발산",
        "Nowosibirski",
        "콜롬비아",
        "쿡",
        "쿠바",
        "이스터섬",
        "印度洋時間",
        "동유럽",
        "서유럽",
        "포클랜드",
        "피지",
        "프랑스령",
        "가이아나",
        "그루지아",
        "Moskowski",
        "하와이",
        "알류샨",
        "홍콩",
        "호브드",
        "Batorski",
        "이란",
        "이르쿠츠크",
        "이스라엘",
        "일본",
        "페트로파블롭스크",
        "캄차츠키",
        "카자흐스탄",
        "크라스노야르스크",
        "로드",
        "하우",
        "마카오",
        "마가단",
        "모리셔스",
        "Mexiski",
        "북서부",
        "울란바토르",
        "모스크바",
        "뉴칼레도니아",
        "뉴질랜드",
        "뉴펀들랜드",
        "페르난도",
        "데",
        "노로냐",
        "노보시비르스크",
        "옴스크",
        "파키스탄",
        "파라과이",
        "페루",
        "필리핀",
        "세인트피에르",
        "미클롱",
        "키질로르다",
        "사할린",
        "사마라",
        "사모아",
        "대만",
        "통가",
        "투르크메니스탄",
        "우루과이",
        "우즈베키스탄",
        "바누아투",
        "블라디보스토크",
        "볼고그라드",
        "야쿠츠크",
        "예카테린부르크",
        "印尼中部時間",
        "印尼東部時間",
        "印尼西部時間",
        "伊朗標準時間",
        "افریٖقا",
        "伊朗夏令時間",
        "Korejski",
        "以色列標準時間",
        "ارجٮ۪نٹیٖنا",
        "以色列夏令時間",
        "日本標準時間",
        "日本夏令時間",
        "彼得羅巴甫洛夫斯克標準時間",
        "彼得羅巴甫洛夫斯克日光節約時間",
        "উত্তরপশ্চিম",
        "ਐਪੀਆ",
        "centralni",
        "Chowdski",
        "Hongkongski",
        "東哈薩克時間",
        "西哈薩克時間",
        "韓國標準時間",
        "韓國夏令時間",
        "克拉斯諾亞爾斯克標準時間",
        "ਅਕਤਾਉ",
        "克拉斯諾亞爾斯克夏令時間",
        "مشرِقی",
        "گریٖن",
        "لینڑُک",
        "吉爾吉斯時間",
        "蘭卡時間",
        "ਅਕਤੋਬ",
        "萊恩群島時間",
        "Cookowych",
        "মেক্সিকোর",
        "نورونہا",
        "ਅਰਬੀ",
        "豪勳爵島標準時間",
        "豪勳爵島夏令時間",
        "Kapverdski",
        "মেক্সিকান",
        "ਅਰਮੀਨੀਆ",
        "澳門標準時間",
        "jewöhnlijje",
        "澳門夏令時間",
        "awstralski",
        "Apiaski",
        "górski",
        "vun",
        "centralny",
        "Amaconaski",
        "Борбордук",
        "馬加丹標準時間",
        "馬加丹夏令時間",
        "馬來西亞時間",
        "Yekaterinburgsk",
        "Usbekisk",
        "Uruguayansk",
        "Turkmensk",
        "Tongansk",
        "Тынч",
        "উলান",
        "Кытай",
        "ਅਟਲਾਂਟਿਕ",
        "Filippinsk",
        "tichomorský",
        "Peruviansk",
        "Paraguayansk",
        "Фижи",
        "Pakistansk",
        "Гавайи",
        "Newfoundlandsk",
        "Newzealandsk",
        "Nykaledonsk",
        "বাতোর",
        "ਅਜ਼ਰਬਾਈਜਾਨ",
        "Тайпей",
        "Moskovitisk",
        "Mexicansk",
        "Nordvestmexicansk",
        "Koreansk",
        "මෙක්සිකෝ",
        "Japansk",
        "Мексикийн",
        "ග්‍රීන්ලන්ත",
        "සහ",
        "Israelsk",
        "Iransk",
        "Vestgrønlandsk",
        "ඔස්ට්‍රේලියානු",
        "ඕස්ට්‍රේලියානු",
        "ආර්ජන්ටිනා",
        "Østgrønlandsk",
        "Georgiansk",
        "පැසිෆික්",
        "Fransk",
        "Fijiansk",
        "Falklandsøerne",
        "Vesteuropæisk",
        "Østeuropæisk",
        "Centraleuropæisk",
        "Påskeøen",
        "Cubansk",
        "Cookøerne",
        "Colombiansk",
        "Kinesisk",
        "ਅਜੋਰੇਸ",
        "ਬੰਗਲਾਦੇਸ਼",
        "Chilensk",
        "Kapverdisk",
        "Brasiliansk",
        "Bangladeshisk",
        "Azorerne",
        "Aserbajdsjansk",
        "ਬ੍ਰਾਜ਼ੀਲੀਆ",
        "ਕੇਪ",
        "Vestaustralsk",
        "Østaustralsk",
        "centralaustralsk",
        "Vestlig",
        "Centralaustralsk",
        "Armensk",
        "Vestargentinsk",
        "Argentinsk",
        "Vestafrikansk",
        "Pilipinas",
        "Mecsico",
        "ਵਰਡ",
        "馬紹爾群島時間",
        "Las",
        "莫森時間",
        "墨西哥西北部標準時間",
        "墨西哥西北部夏令時間",
        "墨西哥太平洋標準時間",
        "墨西哥太平洋夏令時間",
        "烏蘭巴托標準時間",
        "烏蘭巴托夏令時間",
        "ਚੈਥਮ",
        "Ariannin",
        "De",
        "莫斯科標準時間",
        "莫斯科夏令時間",
        "緬甸時間",
        "尼泊爾時間",
        "新喀里多尼亞標準時間",
        "紐西蘭標準時間",
        "紐西蘭夏令時間",
        "紐芬蘭標準時間",
        "紐芬蘭夏令時間",
        "Samarský",
        "紐埃島時間",
        "諾福克島時間",
        "費爾南多",
        "miquelonský",
        "北馬里亞納群島時間",
        "新西伯利亞標準時間",
        "新西伯利亞夏令時間",
        "鄂木斯克標準時間",
        "鄂木斯克夏令時間",
        "巴基斯坦標準時間",
        "巴基斯坦夏令時間",
        "帛琉時間",
        "巴拉圭標準時間",
        "巴拉圭夏令時間",
        "秘魯標準時間",
        "Howa",
        "lorda",
        "秘魯夏令時間",
        "菲律賓標準時間",
        "菲律賓夏令時間",
        "鳳凰群島時間",
        "聖皮埃爾和密克隆群島標準時間",
        "聖皮埃爾和密克隆群島夏令時間",
        "波納佩時間",
        "克孜勒奧爾達標準時間",
        "克孜勒奧爾達夏令時間",
        "留尼旺時間",
        "aleutský",
        "羅瑟拉時間",
        "庫頁島標準時間",
        "庫頁島夏令時間",
        "薩馬拉標準時間",
        "薩馬拉夏令時間",
        "薩摩亞標準時間",
        "薩摩亞夏令時間",
        "Yakuțk",
        "Uruguayului",
        "南喬治亞時間",
        "Filipine",
        "Pakistanului",
        "Zeelande",
        "Caledonii",
        "大溪地時間",
        "Moscovei",
        "mexicane",
        "zonei",
        "Mexicului",
        "Coreei",
        "Kazahstanul",
        "Kamciațki",
        "Japoniei",
        "Israelului",
        "Irkuțk",
        "Iranului",
        "台北標準時間",
        "台北夏令時間",
        "Letní",
        "Cookových",
        "塔吉克時間",
        "托克勞群島時間",
        "楚克島時間",
        "土庫曼標準時間",
        "Paștelui",
        "Cubei",
        "Columbiei",
        "Anadyrský",
        "pacifický",
        "Chinei",
        "Capul",
        "Brasiliei",
        "Azerbaidjanului",
        "horský",
        "Armeniei",
        "土庫曼夏令時間",
        "arabă",
        "montană",
        "烏拉圭標準時間",
        "烏拉圭夏令時間",
        "orientală",
        "烏茲別克標準時間",
        "centrală",
        "Amazonului",
        "烏茲別克夏令時間",
        "委內瑞拉時間",
        "海參崴標準時間",
        "海參崴夏令時間",
        "伏爾加格勒標準時間",
        "伏爾加格勒夏令時間",
        "沃斯托克時間",
        "威克島時間",
        "瓦利斯和富圖納群島時間",
        "葉卡捷琳堡標準時間",
        "葉卡捷琳堡夏令時間",
        "Гринландын",
        "ᎠᏂᏍᏆᏂ",
        "ਚਿਲੀ",
        "ᎢᏤᏍᏛᏱ",
        "ਚੀਨ",
        "ᎠᎴ",
        "Ramiojo",
        "ᎠᏥᏂᏘᏂᎠ",
        "Argentinos",
        "Мексикан",
        "vakarų",
        "ਚੌਇਬਾਲਸਨ",
        "Гренланди",
        "মস্কো",
        "AZOST",
        "океанан",
        "AZOT",
        "Salos",
        "Тийна",
        "Jekaterinburško",
        "Volgogradsko",
        "Vladivostočko",
        "Vanuatuansko",
        "Uzbekistansko",
        "Urugvajsko",
        "Turkmenistansko",
        "Tongansko",
        "Tajpejsko",
        "Samoansko",
        "Sahalinsko",
        "Filipinsko",
        "ir",
        "Peruansko",
        "Paragvajsko",
        "Pakistansko",
        "ostrvu",
        "Grenlandijos",
        "Njufaundlendsko",
        "Novozelandsko",
        "Novokaledonijsko",
        "Moskovsko",
        "Ulanbatorsko",
        "ба",
        "Meksičko",
        "Sjeverozapadno",
        "Mauricijsko",
        "Magadansko",
        "Iransko",
        "Hovdsko",
        "Hongkonško",
        "aleućansko",
        "Zapadnogrenlandsko",
        "Istočnogrenlandsko",
        "Meksikos",
        "Gruzijsko",
        "Folklandsko",
        "Centralnoevropsko",
        "Uskršnjeostrvsko",
        "Kubansko",
        "Kukovim",
        "Kolumbijsko",
        "Čojbalsansko",
        "Čileansko",
        "Čatamsko",
        "Zelenortsko",
        "Brazilijsko",
        "Bangladeško",
        "Azorsko",
        "Azerbejdžansko",
        "Zapadnoaustralijsko",
        "Istočnoaustralijsko",
        "centralnozapadno",
        "Centralnoaustralijsko",
        "ਕੋਲੰਬੀਆ",
        "ਕੁੱਕ",
        "Armensko",
        "Zapadnoargentinsko",
        "Argentinsko",
        "Akvtobe",
        "Klusā",
        "Akvtau",
        "Apijsko",
        "centrālais",
        "rietumu",
        "Amazonsko",
        "Almatu",
        "Meksyk",
        "Aljaskansko",
        "ক্যালেডোনিয়া",
        "Kuka",
        "ਕਿਊਬਾ",
        "Zapadnoafričko",
        "নিউজিল্যান্ড",
        "ਈਸਟਰ",
        "Grenlandia",
        "un",
        "Таипеи",
        "নিউফাউন্ডল্যান্ড",
        "ফার্নান্দো",
        "баруун",
        "вемена",
        "Meksikas",
        "zachodnioaustralijski",
        "ডি",
        "Пацифичко",
        "Argentyna",
        "Arabski",
        "pacyficzny",
        "Планинско",
        "समोआ",
        "समारा",
        "पोनापे",
        "Аргентины",
        "নোরোনহা",
        "कैलेडोनिया",
        "ਯਕੇਤਰਿਨਬਰਗ",
        "ਯਕੁਤਸਕ",
        "নোভোসিবির্স্ক",
        "म्यानमार",
        "ওমস্ক",
        "जपान",
        "ईरान",
        "পাকিস্তান",
        "होव्ड",
        "ਵੋਲਗੋਗ੍ਰੇਡ",
        "ਵਲਾਦੀਵੋਸਤਕ",
        "ग्रीनलैण्ड",
        "ईस्टर",
        "चैथम",
        "बांगलादेश",
        "ਵਾਨੂਆਟੂ",
        "প্যারাগুয়ে",
        "अनादीर",
        "अलास्का",
        "পেরু",
        "ਉਜ਼ਬੇਕਿਸਤਾਨ",
        "ਉਰੂਗਵੇ",
        "ਤੁਰਕਮੇਨਿਸਤਾਨ",
        "Nevez",
        "Источен",
        "ਟੋਂਗਾ",
        "ha",
        "ফিলিপাইন",
        "ਤੈਪਈ",
        "সেন্ট",
        "Greunland",
        "ਸਾਮੋਆ",
        "ਸਖਲੀਨ",
        "পিয়ের",
        "ਕਿਜ਼ਲੋਰਡਾ",
        "Aostralia",
        "Kreizaostralia",
        "Arcʼhantina",
        "ആഫ്രിക്ക",
        "ইয়েকাতেরিনবুর্গ",
        "ਮਿਕੇਲਨ",
        "য়াকুতস্ক",
        "ভলগোগ্রাড",
        "ਪੀਅਰੇ",
        "ਸੈਂਟ",
        "ব্লাদিবোস্তোক",
        "ভানুয়াতু",
        "উজবেকিস্তান",
        "উরুগুয়ে",
        "പസഫിക്",
        "അർജന്റീന",
        "ഓസ്ട്രേലിയൻ",
        "ഓസ്‌ട്രേലിയൻ",
        "ਫਿਲਿਪੀਨੀ",
        "ਪੇਰੂ",
        "ਪੈਰਾਗਵੇ",
        "ਪਾਕਿਸਤਾਨ",
        "তুর্কমেনিস্তান",
        "ਓਮਸਕ",
        "টোঙ্গা",
        "ਨੌਵੋਸਿਬੀਰਸਕ",
        "ਨੋਰੋਨਹਾ",
        "ਫ਼ਾਕਲੈਂਡ",
        "ਡੇ",
        "ਫਰਨਾਂਡੋ",
        "তাইপেই",
        "ਨਿਊਫਾਉਂਡਲੈਂਡ",
        "ਨਿਊਜ਼ੀਲੈਂਡ",
        "ਕੈਲੇਡੋਨੀਆ",
        "সামোয়া",
        "ഗ്രീൻലാൻഡ്",
        "ਮਾਸਕੋ",
        "ਬਟੋਰ",
        "ਉਲਨ",
        "ਮੈਕਸੀਕਨ",
        "ও",
        "ਮੈਕਸੀਕੋ",
        "ਉੱਤਰ",
        "ਮੌਰਿਸ਼ਸ",
        "ਮੈਗੇਡਨ",
        "ਮਕਾਉ",
        "ਹੋਵੇ",
        "ਲੌਰਡ",
        "ਕ੍ਰਾਸਨੋਯਾਰਸਕ",
        "ਫ਼ਿਜ਼ੀ",
        "ਕੋਰੀਆਈ",
        "ਕਜ਼ਾਖ਼ਸਤਾਨ",
        "ਜਪਾਨ",
        "ਇਜ਼ਰਾਈਲ",
        "ਇਰਕੁਤਸਕ",
        "ਈਰਾਨ",
        "ਇੰਡੋਨੇਸ਼ੀਆ",
        "ਹੋਵਡ",
        "ਕਾਂਗ",
        "ਹਾਂਗ",
        "ਅਲੇਯੂਸ਼ਿਅਨ",
        "ਹਵਾਈ",
        "সামারা",
        "সাখালিন",
        "মিকেলন",
        "ਫ੍ਰੈਂਚ",
        "कोलम्बियाली",
        "ന്യൂ",
        "ഡി",
        "Sina",
        "Индонезија",
        "Западен",
        "Источна",
        "Өмнөд",
        "अर्जण्टिना",
        "ईंडोनीशिया",
        "मलेशिया",
        "न्यु",
        "पापुआ",
        "एवं",
        "Гринвич",
        "Wyspa",
        "wschodnioeuropejski",
        "Gilberta",
        "Wschodnia",
        "Хятадын",
        "Indonezja",
        "Austrumeiropas",
        "Zachodni",
        "د",
        "وخت",
        "Indonezijos",
        "гӀайре",
        "Индонези",
        "ᏣᎠᏥᎢ",
        "ᎢᏂᏙᏂᏍᏯ",
        "Pietų",
        "Tsieina",
        "Casachstan",
        "Индонезийн",
        "Илин",
        "Арҕаа",
        "gaskka",
        "áigi",
        "ඉන්දුනීසියානු",
        "ගිම්හාන",
        "Жаӊы",
        "аралынын",
        "батыш",
        "Jewöhnlijje",
        "Oß",
        "سیووا",
        "نیوٗ",
        "اِنڑونیشِیا",
        "Vzhodni",
        "vzhodni",
        "اَیلینڑز",
        "Singapurski",
        "Indonezijski",
        "Afganistanit",
        "ಜಾರ್ಜಿಯಾ",
        "Alaskës",
        "Alsaskës",
        "ಅರ್ಜೆಂಟೀನಾ",
        "ದಕ್ಷಿಣ",
        "Butanit",
        "Bolivisë",
        "Durasalamit",
        "Kejsit",
        "Kamorros",
        "Krishtlindjeve",
        "Dejvisit",
        "d’Urvilës",
        "Timorit",
        "Ekuadorit",
        "Largët",
        "ग्रीष्म",
        "Territoreve",
        "Antarktike",
        "Galapagosit",
        "Gambierit",
        "Meridianit",
        "Grinuiçit",
        "Guamit",
        "Gjirit",
        "Indisë",
        "Oqeanit",
        "Indokinës",
        "Perëndimor",
        "Kosrës",
        "Kirgistanit",
        "Lankasë",
        "Sporadikë",
        "Ekuatorialë",
        "Makuari",
        "Malajzisë",
        "Maldivit",
        "Markezë",
        "Marshallë",
        "Mausonit",
        "Mianmarit",
        "Naurusë",
        "Nepalit",
        "खाडी",
        "Niuesë",
        "Marianës",
        "Veriut",
        "Palaut",
        "Guinesë",
        "Verore",
        "Pitkernit",
        "Ponapeit",
        "Penianit",
        "Reunionit",
        "Rodherës",
        "Sishelës",
        "Singaporit",
        "Xhorxhas",
        "Jugut",
        "रियुनियन",
        "Surinamit",
        "остров",
        "Рождество",
        "Sjouit",
        "Tahitit",
        "Taxhikistanit",
        "फुटुना",
        "Tokelaut",
        "Çukut",
        "Pusat",
        "Tuvalusë",
        "Hari",
        "Separuh",
        "平壤時間",
        "ceruk",
        "timur",
        "Venezuelës",
        "Antartika",
        "Min",
        "Lautan",
        "Vostokut",
        "Uejk",
        "Uollisit",
        "Futunës",
        "Соломон",
        "ACST",
        "Çuuk",
        "Маршалл",
        "Мальдив",
        "Антарктика",
        "Indoneesia",
        "Georgiako",
        "Хитой",
        "океани",
        "centralafrikansk",
        "östafrikansk",
        "sydafrikansk",
        "Marşal",
        "Almatynormaltid",
        "Almatysommartid",
        "Anadyrnormaltid",
        "ပစိဖိတ်",
        "Anadyrsommartid",
        "Aqtaunormaltid",
        "Aqtausommartid",
        "Aqtöbenormaltid",
        "Aqtöbesommartid",
        "atlantnormaltid",
        "atlantsommartid",
        "Bruneitid",
        "Caseytid",
        "Chamorrotid",
        "Julöns",
        "Çamorro",
        "ინდონეზიის",
        "Keelingöarnas",
        "ഇന്തോനേഷ്യ",
        "östtimorisk",
        "Kaliningradtid",
        "Sydterritoriernas",
        "Galápagostid",
        "Sur",
        "Gambiertid",
        "Kiribatitid",
        "Greenwichtid",
        "Guamtid",
        "d’Indonesia",
        "Xeorxa",
        "Persiska",
        "saseNtshonalanga",
        "Les",
        "vikentid",
        "Brittiska",
        "Indiska",
        "oceanöarnas",
        "centralindonesisk",
        "گالاپاگوس",
        "östindonesisk",
        "västindonesisk",
        "Kamtjatkanormaltid",
        "Kamtjatkasommartid",
        "आइरिश",
        "dell’Irlanda",
        "Regno",
        "Unito",
        "östkazakstansk",
        "västkazakstansk",
        "အင်ဒိုနီးရှား",
        "Kosraetid",
        "kirgizisk",
        "Lankatid",
        "Lineöarnas",
        "Macaunormaltid",
        "Macausommartid",
        "Macquarietid",
        "Maldivernatid",
        "Marquesastid",
        "Marshallöarnas",
        "Mawsontid",
        "burmesisk",
        "Naurutid",
        "nepalesisk",
        "Niuetid",
        "Norfolköns",
        "Nordmarianernas",
        "Palautid",
        "Guineas",
        "Enderburytid",
        "Pitcairntid",
        "Ponapetid",
        "Pyongyangtid",
        "Qyzylordanormaltid",
        "Qyzylordasommartid",
        "Réuniontid",
        "Rotheratid",
        "Samaranormaltid",
        "Samarasommartid",
        "ኢንዶኔዢያ",
        "Seychellernatid",
        "Singaporetid",
        "Salomonöarnas",
        "sydgeorgisk",
        "Surinamtid",
        "strandarde",
        "Syowatid",
        "Tahititid",
        "Irlandës",
        "britanike",
        "Tadzjikistantid",
        "የምስራቃዊ",
        "sentralafrikansk",
        "sørafrikansk",
        "Tokelautid",
        "Chuuktid",
        "Tuvalutid",
        "irländsk",
        "Vostoktid",
        "brittisk",
        "Honolulunormaltid",
        "Wakeöarnas",
        "Futunaöarnas",
        "Georgian",
        "Honolulusommartid",
        "Indonesian",
        "Indonesiya",
        "and",
        "Georgyske",
        "Yndonezyske",
        "Síne",
        "Seoirsia",
        "ایمیزون",
        "Tagikistan",
        "Salomone",
        "Riunione",
        "Fenice",
        "hIndinéise",
        "መካከለኛ",
        "Marchesi",
        "የአርጀንቲና",
        "Malesia",
        "equatoriali",
        "Sporadi",
        "західним",
        "Innd",
        "Innse",
        "Kamchatsky",
        "dell’Indocina",
        "Seychelle",
        "Indiano",
        "dell’Oceano",
        "dell’India",
        "francesi",
        "antartiche",
        "australi",
        "middeltid",
        "francese",
        "(Kaliningrad)",
        "dell’Ecuador",
        "leste",
        "Xeorxia",
        "літній",
        "стандартний",
        "Afghanischtan",
        "Zentralafrikanischi",
        "Oschtafrikanischi",
        "Süüdafrikanischi",
        "ziit",
        "અર્જેન્ટીના",
        "شىمالىي",
        "ઇન્ડોનેશિયા",
        "ھىندونېزىيە",
        "ન્યુ",
        "meridionale",
        "dell’Afghanistan",
        "רגיל",
        "האוקיינוס",
        "جەنۇبىي",
        "סין",
        "Nýju",
        "Indónesíutími",
        "אינדונזיה",
        "దక్షిణ",
        "החדשה",
        "Afríkutími",
        "అర్జెంటీనా",
        "ఆస్ట్రేలియన్",
        "दारूस्सलम",
        "कोकोस",
        "mo",
        "ग्रीनविच",
        "महासागर",
        "कोसराए",
        "क्रास्नोयार्स्क",
        "జార్జియా",
        "ఇండోనేషియా",
        "Ինդոնեզիայի",
        "న్యూ",
        "सिंगापुर",
        "fakaʻinitonisia",
        "indonéziai",
        "Indoneziýa",
        "Makkuori",
        "argentínai",
        "d’Urvillea",
        "fakaʻotumotu",
        "Davistid",
        "የቱርክመኒስታን",
        "Indosjina",
        "የኡራጓይ",
        "माउंटेन",
        "Macáu",
        "एनाडीयर",
        "Маўрыкія",
        "अरब",
        "Indonezia",
        "ཧ་ཝའི་",
        "የኡዝቤኪስታን",
        "Паўночна",
        "заходні",
        "ऑस्ट्रेलियाई",
        "अज़रबैजान",
        "अज़ोरेस",
        "बांग्लादेश",
        "fakataipei",
        "fakahaʻamoa",
        "ब्राजीलिया",
        "samala",
        "мексіканскі",
        "Мексіканскі",
        "वर्ड",
        "चामोरो",
        "ціхаакіянскі",
        "የቫኗቱ",
        "कॉइबाल्सन",
        "sakāline",
        "太平洋夏令時間",
        "太平洋標準時間",
        "fakakisilōta",
        "山區夏令時間",
        "mikeloni",
        "山區標準時間",
        "piea",
        "तिमोर",
        "fakasā",
        "東部夏令時間",
        "東部標準時間",
        "ग्रीष्‍मकालीन",
        "अग्र",
        "फ़ॉकलैंड",
        "फ़िजी",
        "fakafilipaine",
        "Къилба",
        "fakapelū",
        "中部標準時間",
        "fakapalakuai",
        "fakapākisitani",
        "ʻomisiki",
        "novosipīsiki",
        "Moris",
        "Gwalarn",
        "nolōnia",
        "te",
        "Mecʼhiko",
        "fakafēnanito",
        "हवाई–आल्यूशन",
        "የኬፕ",
        "Moskov",
        "fakafonuaʻilofoʻou",
        "Батара",
        "fakanuʻusila",
        "Маскоўскі",
        "इर्कुत्स्क",
        "इज़राइल",
        "Лаьмнийн",
        "पेट्रोपेवलास्क",
        "कैमचात्सकी",
        "南非標準時間",
        "कज़ाखस्तान",
        "कोरियाई",
        "(АЦШ)",
        "ቨርዴ",
        "fakakaletōniafoʻou",
        "Perou",
        "Filipinez",
        "СаӀудийн",
        "mosikou",
        "Wešteuropäiši",
        "fakaʻulānipātā",
        "Ošteuropäiši",
        "tokelauhihifo",
        "Mitteleuropäiši",
        "ཕར་ནེན་ཌོ་",
        "ཌི་",
        "Арави",
        "बटोर",
        "Эрмалойчоь",
        "fakamaulitiusi",
        "Atlantiši",
        "न्यूज़ीलैंड",
        "न्यूफ़ाउंडलैंड",
        "makatani",
        "fakamakau",
        "फ़र्नांर्डो",
        "Đài",
        "Philippin",
        "fakamotuʻeikihoue",
        "Matxcơva",
        "पैराग्वे",
        "Атлантикан",
        "फ़िलिपीन",
        "kalasinoiāsiki",
        "đảo",
        "पियरे",
        "मिकेलान",
        "पिटकैर्न",
        "fakakōlea",
        "fakakasakitani",
        "सखालिन",
        "petelopavilovisiki",
        "Cao",
        "fakasiapani",
        "fakaʻisileli",
        "Ma",
        "Hàn",
        "ताइपे",
        "ताजिकिस्तान",
        "टोकेलाऊ",
        "Bản",
        "चूक",
        "Nhật",
        "तुवालू",
        "Độ",
        "उज़्बेकिस्तान",
        "वनुआतू",
        "Ấn",
        "वोल्गोग्राड",
        "ʻīkutisiki",
        "Kông",
        "fakaʻilaani",
        "Hồng",
        "याकुत्स्क",
        "येकातेरिनबर्ग",
        "afganistansko",
        "srednjoafričko",
        "istočnoafričko",
        "južnoafričko",
        "Gruzia",
        "Sant",
        "Pháp",
        "малхбузен",
        "Sinh",
        "Phục",
        "Pêr",
        "Азоран",
        "Aleutia",
        "Mauriciu",
        "Бразили",
        "Broenei",
        "Đại",
        "Каледоніі",
        "Зеландыі",
        "Ньюфаўндлендскі",
        "Ouzbekistan",
        "Anyiehe",
        "Yekaterinbourg",
        "butansko",
        "bolivijsko",
        "आकर",
        "የቻታም",
        "d’Ulán",
        "የቺሊ",
        "ды",
        "Caseyja",
        "Chamorra",
        "Наронья",
        "Новасібірскі",
        "Колумби",
        "gaƒoƒoɖanyime",
        "Božićnog",
        "Кукан",
        "Kokosovih",
        "Омскі",
        "Αφγανιστάν",
        "Cookovih",
        "Cookovo",
        "Пакістанскі",
        "polusatni",
        "pomak",
        "Мархин",
        "Davisa",
        "esezingeni",
        "istočnotimorsko",
        "የቻይና",
        "ekvadorsko",
        "saseNyakatho",
        "अलमाटी",
        "dalekoistočno",
        "europsko",
        "अमाज़ोन",
        "Фолклендан",
        "Французийн",
        "Francuske",
        "Gvajane",
        "južnofrancusko",
        "सैंट्रल",
        "Galapagosa",
        "Gambiera",
        "Парагвая",
        "Gilbertovih",
        "univerzalno",
        "Перуанскі",
        "माऊन्टन",
        "guamsko",
        "zaljevsko",
        "gvajansko",
        "Гуьржийчоь",
        "पैसीफीक",
        "Філіпінскі",
        "अक़्टाऊ",
        "indijsko",
        "Indijskog",
        "oceana",
        "indokinesko",
        "srednjoindonezijsko",
        "istočnoindonezijsko",
        "zapadnoindonezijsko",
        "अक़्टोबे",
        "Фенікс",
        "የቾይባልሳን",
        "П’ер",
        "Гавайн",
        "istočnokazahstansko",
        "zapadnokazahstansko",
        "алеутийн",
        "आरमीनी",
        "kirgistansko",
        "lankansko",
        "Инди",
        "अटलांटीक",
        "malezijsko",
        "maldivsko",
        "markižansko",
        "Maršalovih",
        "Singapoer",
        "mawsonsko",
        "आज़रबैजान",
        "Μπουτάν",
        "Βολιβίας",
        "ГӀажарийчоь",
        "mianmarsko",
        "Naurua",
        "nepalsko",
        "Μπρουνέι",
        "Νταρουσαλάμ",
        "आज़ोर",
        "የየካተሪንበርግ",
        "Niuea",
        "Sjevernomarijanskih",
        "Τσαμόρο",
        "Япони",
        "湯加標準時間",
        "Palaua",
        "Papue",
        "Gvineje",
        "Корей",
        "Косраэ",
        "Solomoneilande",
        "Χριστουγέννων",
        "ब्राज़ीलिया",
        "Pitcairna",
        "Ponapea",
        "pjongjanško",
        "Κόκος",
        "Reuniona",
        "Rothere",
        "ब्रुनेई",
        "Maleisië",
        "Maledive",
        "sejšelsko",
        "singapursko",
        "Salomonskih",
        "Južne",
        "Georgije",
        "surinamsko",
        "Syowe",
        "Tahitija",
        "Ντέιβις",
        "tadžikistansko",
        "Tokelaua",
        "Ντιμόντ",
        "Chuuka",
        "Ντερβίλ",
        "Tuvalua",
        "Сахалінскі",
        "Τιμόρ",
        "Маврики",
        "venezuelsko",
        "Εκουαδόρ",
        "काप",
        "vostočko",
        "περαιτέρω",
        "Къилбаседа",
        "fakahovite",
        "kongi",
        "fakahongi",
        "fakahauaʻi",
        "zapadoafriski",
        "Американ",
        "alaskaski",
        "Γαλλικής",
        "wuchodny",
        "hórski",
        "arabski",
        "argentinski",
        "zapadoargentinski",
        "armenski",
        "atlantiski",
        "srjedźoawstralski",
        "वेर्दे",
        "fakaseōsia",
        "wuchodoawstralski",
        "zapadoawstralski",
        "azerbajdźanski",
        "acorski",
        "bangladešski",
        "fakakuiana",
        "fakafisi",
        "Brasiliski",
        "fokulani",
        "kapverdski",
        "Γαλλικού",
        "chathamski",
        "chilski",
        "chinski",
        "Νότου",
        "የደቡብ",
        "kolumbiski",
        "kubaski",
        "fakalapanui",
        "fakakiupa",
        "fakaʻotumotukuki",
        "fakakolomipia",
        "Jutrowneje",
        "fakakoipalisani",
        "srjedźoeuropski",
        "wuchodoeuropski",
        "zapadoeuropski",
        "falklandski",
        "fidźiski",
        "fakasiaina",
        "fakasili",
        "fakasatihami",
        "vēte",
        "fakamuiʻi",
        "fakapalāsila",
        "georgiski",
        "wuchodogrönlandski",
        "zapadogrönlandski",
        "fakapengilātesi",
        "fakaʻāsolesi",
        "hawaiisko",
        "fakaʻasapaisani",
        "Ανταρκτικής",
        "ʻatalanitiki",
        "fakaʻāmenia",
        "Γκαλάπαγκος",
        "fakaʻalepea",
        "iranski",
        "israelski",
        "japanski",
        "fakaʻakitōpe",
        "fakaʻakitau",
        "korejski",
        "fakaapia",
        "ʻanatili",
        "Γκάμπιερ",
        "የኮሎምቢያ",
        "Γκίλμπερτ",
        "moʻunga",
        "mauritiuski",
        "Mawsonski",
        "Μέση",
        "sewjerozapadny",
        "Γκρίνουιτς",
        "Каледони",
        "fakaʻamasōne",
        "nowokaledonski",
        "nowoseelandski",
        "nowofundlandski",
        "fakaʻalamati",
        "pakistanski",
        "fakaʻalasika",
        "Γκουάμ",
        "Κόλπου",
        "peruski",
        "filipinski",
        "Зеланди",
        "चीली",
        "चाईना",
        "sachalinski",
        "samoaski",
        "चोईबालसान",
        "Paraguái",
        "fakaʻakelī",
        "የኩክ",
        "Ýekaterinburg",
        "Ýakutsk",
        "Taipehski",
        "Wanuatu",
        "Ινδίας",
        "tongaski",
        "Urugwaý",
        "turkmenski",
        "Ινδικού",
        "uruguayski",
        "uzbekski",
        "vanuatuski",
        "Taýbeý",
        "Pýer",
        "Ωκεανού",
        "Ινδοκίνας",
        "oosterse",
        "Filippinler",
        "Филиппинаш",
        "蘇里南時間",
        "የኩባ",
        "अर्ध",
        "Paragwaý",
        "क्युबा",
        "alaszkai",
        "Marshalleilande",
        "amazóniai",
        "középső",
        "Tyd",
        "keleti",
        "hegyvidéki",
        "Δυτικού",
        "Тайбэя",
        "Anadíri",
        "apiai",
        "Aqtaui",
        "Aqtobei",
        "arab",
        "Κοσράης",
        "Päkistan",
        "örményországi",
        "atlanti",
        "የአላስካ",
        "azerbajdzsáni",
        "azori",
        "bangladesi",
        "Noronýa",
        "Nýufaundlend",
        "brazíliai",
        "zöld",
        "foki",
        "Κιργιστάν",
        "Zelandiýa",
        "chathami",
        "chilei",
        "kínai",
        "csojbalszani",
        "Kaledoniýa",
        "Λάιν",
        "Saxalín",
        "kolumbiai",
        "cook",
        "Meksikan",
        "kubai",
        "G.b.",
        "D.g.",
        "Mawrikiý",
        "húsvét",
        "Туркменин",
        "Seixeles",
        "Krasnoýarsk",
        "falkland",
        "fidzsi",
        "francia",
        "Koreýa",
        "déli",
        "Μακουάρι",
        "Gazagystan",
        "Ýaponiýa",
        "Ysraýyl",
        "grúziai",
        "Eýran",
        "Узбекистанан",
        "Howd",
        "Μαλαισίας",
        "Μαλδίβων",
        "Gawaý",
        "hawaii",
        "aleuti",
        "hongkongi",
        "hovdi",
        "indiai",
        "Μαρκέσας",
        "Μάρσαλ",
        "iráni",
        "irkutszki",
        "izraeli",
        "japán",
        "Petropavlovszk",
        "kamcsatkai",
        "kazahsztáni",
        "koreai",
        "Gruziýa",
        "krasznojarszki",
        "आईलंड",
        "Μόσον",
        "Gilberteilande",
        "Macaui",
        "Pasha",
        "Kolumbiýa",
        "Туркменістана",
        "Çoýbalsan",
        "Hytaý",
        "Çatem",
        "Werde",
        "mauritiusi",
        "የኢስተር",
        "északnyugat",
        "फ़ाल्कलैण्ड",
        "ulánbátori",
        "moszkvai",
        "Braziliýa",
        "फीजी",
        "फ्रान्सीसी",
        "Μιανμάρ",
        "kaledóniai",
        "zélandi",
        "fundlandi",
        "Azerbaýjan",
        "tarap",
        "günbatar",
        "Ναούρου",
        "novoszibirszki",
        "omszki",
        "pakisztáni",
        "Νεπάλ",
        "Уругвайскі",
        "ýurtlary",
        "paraguayi",
        "perui",
        "fülöp",
        "Arap",
        "Узбекістана",
        "Kersfeeseiland",
        "(ABŞ)",
        "Qyzylordai",
        "ýeri",
        "Daglyk",
        "szahalini",
        "Szamarai",
        "szamoai",
        "ᏧᎦᎾᏮ",
        "Alýaska",
        "Νιούε",
        "Νόρφολκ",
        "जोर्जिया",
        "mediaantyd",
        "Уладзівастоцкі",
        "taipei",
        "Βόρειες",
        "คัมชัตสกี",
        "tongai",
        "อะลูเชียน",
        "türkmenisztáni",
        "ఏకాటెరిన్బర్గ్",
        "uruguayi",
        "üzbegisztáni",
        "vanuatui",
        "యాకుట్స్క్",
        "vlagyivosztoki",
        "volgográdi",
        "వోల్గోగ్రాడ్",
        "వ్లాడివోస్టోక్",
        "వనౌటు",
        "ఉజ్బెకిస్తాన్",
        "ఉరుగ్వే",
        "తుర్క్మెనిస్థాన్",
        "టాంగా",
        "Μαριάνες",
        "ᎠᎳᏍᎦ",
        "Валгаградскі",
        "गुआम",
        "Հարավային",
        "Παλάου",
        "Παπουά",
        "Νέα",
        "Ալյասկայի",
        "Ամազոնյան",
        "Γουινέα",
        "Լեռնային",
        "(ԱՄՆ)",
        "Խաղաղօվկիանոսյան",
        "Ապիայի",
        "Սաուդյան",
        "Արաբիայի",
        "የአማዞን",
        "ստնադարտ",
        "Հայաստանի",
        "Ատլանտյան",
        "ᎠᎺᏌᏂ",
        "արևմտյան",
        "Ադրբեջանի",
        "Ազորյան",
        "Taxiquistán",
        "Բանգլադեշի",
        "తైపీ",
        "సమోవా",
        "Բրազիլիայի",
        "సమారా",
        "Կաբո",
        "Վերդեի",
        "సఖాలిన్",
        "Չաթեմ",
        "Φίνιξ",
        "Չիլիի",
        "Չինաստանի",
        "Չոյբալսանի",
        "కిజిలోర్డా",
        "మిక్వెలాన్",
        "సెయింట్",
        "Կոլումբիայի",
        "Կուկի",
        "ఫిలిప్పైన్",
        "Կուբայի",
        "పెరూ",
        "పరాగ్వే",
        "ఓమ్స్క్",
        "నొరోన్హా",
        "Զատկի",
        "డి",
        "ᎣᏓᎸ",
        "ఫెర్నాండో",
        "Ֆոլքլենդյան",
        "Ֆիջիի",
        "Ֆրանսիական",
        "ల్యాండ్",
        "న్యూఫౌండ్",
        "ᎠᏈᎠ",
        "న్యూజిల్యాండ్",
        "కాలెడోనియా",
        "आलटन",
        "Վրաստանի",
        "మాస్కో",
        "బతోర్",
        "Πίτκερν",
        "ఉలన్",
        "మెక్సికన్",
        "మెక్సికో",
        "Հավայան",
        "ալեության",
        "Հոնկոնգի",
        "Հովդի",
        "వాయవ్య",
        "మారిషస్",
        "మగడాన్",
        "మకావ్",
        "Πονάπε",
        "Իրանի",
        "Իրկուտսկի",
        "Իսրայելի",
        "Ճապոնիայի",
        "Ղազախստանի",
        "Կորեայի",
        "హోవ్",
        "Կրասնոյարսկի",
        "లార్డ్",
        "క్రాస్నోయార్స్క్",
        "Լորդ",
        "Հաուի",
        "కొరియన్",
        "Մագադանի",
        "కామ్ఛాట్స్కి",
        "పెట్రోపావ్లోవ్స్క్",
        "జపాన్",
        "ఇజ్రాయిల్",
        "Մավրիկիոսի",
        "ఇరక్వుట్స్క్",
        "Հյուսիսարևմտյան",
        "Πιονγιάνγκ",
        "խաղաղօվկիանոսյան",
        "Ուլան",
        "Բատորի",
        "Մոսկվայի",
        "ఇరాన్",
        "Ρεϊνιόν",
        "హోవ్డ్",
        "Ροθέρα",
        "Կալեդոնիայի",
        "Զելանդիայի",
        "Նյուֆաունդլենդի",
        "హాంకాంగ్",
        "అల్యూషియన్",
        "Ֆերնանդու",
        "դի",
        "Նորոնյայի",
        "Նովոսիբիրսկի",
        "Օմսկի",
        "Պակիստանի",
        "హవాయ్",
        "ᎠᎴᏈᏯ",
        "Σάμαρας",
        "Պարագվայի",
        "Պերուի",
        "Ֆիլիպինների",
        "Σαμάρας",
        "Սեն",
        "Պիեռ",
        "Միքելոնի",
        "గయానా",
        "ఫ్రెంచ్",
        "ఫిజీ",
        "ఫాక్‌ల్యాండ్",
        "हाँगकॉंग",
        "Սախալինի",
        "Սամոայի",
        "ఈస్టర్",
        "క్యూబా",
        "కుక్",
        "కొలంబియా",
        "Σεϋχελλών",
        "Σιγκαπούρης",
        "చోయిబల్సాన్",
        "Թայպեյի",
        "చైనా",
        "చిలీ",
        "Տոնգայի",
        "చాథమ్",
        "Թուրքմենստանի",
        "వెర్డె",
        "Ուրուգվայի",
        "Ուզբեկստանի",
        "Վանուատույի",
        "కేప్",
        "Վլադիվոստոկի",
        "Վոլգոգրադի",
        "బ్రెజిలియా",
        "బంగ్లాదేశ్",
        "అజోర్స్",
        "అజర్బైజాన్",
        "Յակուտսկի",
        "Եկատերինբուրգի",
        "Σολομώντα",
        "Σουρινάμ",
        "Σίοβα",
        "Αϊτής",
        "ᎠᎵᎻᏂᎠ",
        "Τατζικιστάν",
        "Τοκελάου",
        "የመካከለኛ",
        "Pegunungan",
        "Bagian",
        "Brasil",
        "Τσουκ",
        "ᎠᏏᎵᏆᏌᏂ",
        "Tiongkok",
        "Τουβαλού",
        "ᎠᏐᎴᏏ",
        "ᏆᏂᎦᎵᏕᏍ",
        "Uruguái",
        "Paskah",
        "Βενεζουέλας",
        "Якуцкі",
        "ᏆᏏᎵᏯ",
        "Prancis",
        "అట్లాంటిక్",
        "Βόστοκ",
        "Γουέικ",
        "ఆర్మేనియా",
        "Ουόλις",
        "Φουτούνα",
        "అరేబియన్",
        "అక్టోబె",
        "Jepang",
        "అక్వాటు",
        "Екацярынбургскі",
        "ఏపియా",
        "Uzbequistán",
        "ᎢᎬᎾᏕᎾ",
        "ᎢᏤᏳᏍᏗ",
        "Selandia",
        "అనాన్డ్రి",
        "Volgográu",
        "ᏣᏝᎻ",
        "ᏥᎵ",
        "మౌంటెయిన్",
        "ᏓᎶᏂᎨᏍᏛ",
        "ᏦᏱᏆᎵᏌᏂ",
        "Suður",
        "Cocoseilande",
        "ईरकुर्त्स्क",
        "Afríku",
        "ईस्राइल",
        "Yekaterimburgu",
        "Amasónsvæðinu",
        "miðhluta",
        "ᎪᎸᎻᏈᎢᎠ",
        "ᎠᏓᏍᏓᏴᎲᏍᎩ",
        "austurhluta",
        "Klettafjöllum",
        "ᎫᏆ",
        "Apía",
        "Arabíu",
        "पेत्रोपावलोस्क",
        "Armeníu",
        "Atlantshafssvæðinu",
        "कामचटका",
        "miðvesturhluta",
        "Aserbaídsjan",
        "Asóreyjum",
        "Bangladess",
        "क़ज़ाख़स्तान",
        "అమెజాన్",
        "Brasilíu",
        "అల్మాటి",
        "Grænhöfðaeyjum",
        "staðaltími",
        "Síle",
        "Kína",
        "कोरिया",
        "అలాస్కా",
        "Kólumbíu",
        "Cooks",
        "ᏥᏌᏕᎴᎯᏌᏅ",
        "Əfqanıstan",
        "Kúbu",
        "क्रासनोयार्स्क",
        "ᏩᎩ",
        "ᏫᏥ",
        "የፋልክላንድ",
        "Páskaeyju",
        "लंका",
        "የፊጂ",
        "लार्ड़",
        "होव",
        "Falklandseyjum",
        "Fídjíeyjum",
        "माकाऊ",
        "मगदन",
        "ఏకర్",
        "የፈረንሳይ",
        "मालदीव",
        "Georgíu",
        "የተራራ",
        "Bhoetan",
        "የፓስፊክ",
        "मॉरिशीयस",
        "मॉसन",
        "Aleúta",
        "ACDT",
        "ACWST",
        "ACWDT",
        "AEST",
        "Íran",
        "Kóreu",
        "AEDT",
        "AWST",
        "AWDT",
        "LHST",
        "LHDT",
        "NZST",
        "Máritíus",
        "Norðvestur",
        "NZDT",
        "Úlan",
        "Moskvu",
        "Rập",
        "Ả",
        "ᎠᎵᎢᎵᏒ",
        "Kaledóníu",
        "Nýja",
        "Sjálandi",
        "Nýfundnalandi",
        "मँगोलिया",
        "HKT",
        "Paragvæ",
        "Filippseyjum",
        "HKST",
        "Sankti",
        "Samóa",
        "Амазонско",
        "ᎭᏩᏱ",
        "núi",
        "ᎠᎵᏳᏏᎠᏂ",
        "ᎰᏂᎩ",
        "ᎪᏂᎩ",
        "ᎰᏩᏗ",
        "Fenikseilande",
        "Túrkmenistan",
        "britanică",
        "Úrúgvæ",
        "Úsbekistan",
        "Vanúatú",
        "नेपाल",
        "Irlandei",
        "የጂዮርጂያ",
        "ᎢᎳᏂ",
        "ᎢᎫᏥᎧ",
        "ᎢᏏᎵᏱ",
        "ᏣᏩᏂᏏ",
        "ᎧᏎᎧᏍᏕᏂ",
        "ᎪᎵᎠᏂ",
        "न्युज़ीलैण्ड",
        "ᏝᏍᏃᏯᏍᎧ",
        "न्युफाऊंडलैण्ड",
        "standaard",
        "ᎤᎬᏫᏳᎯ",
        "ᎭᏫ",
        "नॉरफोक",
        "ᎹᎦᏓᏂ",
        "फेरनान्दो",
        "द",
        "ਬ੍ਰਿਟਿਸ਼",
        "Апия",
        "ᎼᎵᏏᎥᏍ",
        "Арабско",
        "ᏧᏴᏢ",
        "नोवोसीबीर्स्क",
        "ᎤᎳᏂ",
        "ᏆᏙᎸ",
        "ᎹᏍᎦᏫ",
        "Аржентинско",
        "Западноаржентинско",
        "पलाऊ",
        "ᎧᎵᏙᏂᎠᏂ",
        "ᏏᎢᎴᏂᏗ",
        "ᎢᏤᎤᏂᏩᏛᏓᎦᏙᎯ",
        "Арменско",
        "атлантическо",
        "ᏪᎾᏅᏙ",
        "Ꮥ",
        "ᏃᎶᎾᎭ",
        "ᏃᏬᏏᏈᏍᎧ",
        "ᎣᎻᏍᎧ",
        "ᏆᎩᏍᏖᏂ",
        "पारागुए",
        "ਆਇਰਿਸ਼",
        "फीलीपीन्स",
        "ᏆᎵᏇ",
        "ᏇᎷ",
        "ᎠᏂᏈᎵᎩᏃ",
        "halfsomertyd",
        "ᎤᏓᏅᏘ",
        "ᏈᏰ",
        "đông",
        "ᎻᏇᎶᏂ",
        "सेँ",
        "trung",
        "BOT",
        "पीयॅर",
        "Азербайджанско",
        "मीकलों",
        "Азорски",
        "ᏌᎧᎵᏂ",
        "ᏌᎼᎠ",
        "ECT",
        "Centroccidental",
        "बेलायती",
        "Océano",
        "PET",
        "PEST",
        "雅庫次克夏令時間",
        "雅庫次克標準時間",
        "Бангладешко",
        "क़ीज़ीलोर्डा",
        "britisk",
        "ᏔᏱᏇ",
        "የሃዋይ",
        "सख़ालीन",
        "ᏙᎾᎦ",
        "Бразилско",
        "Lõuna",
        "ᏛᎵᎩᎺᏂᏍᏔᏂ",
        "Almatõ",
        "Amazonase",
        "Ameerika",
        "Idaranniku",
        "Mäestikuvööndi",
        "አሌኡት",
        "ᏳᎷᏇ",
        "Anadõri",
        "ᎤᏍᏇᎩᏍᏖᏂ",
        "Araabia",
        "Armeenia",
        "Atlandi",
        "ᏩᏄᏩᏚ",
        "Aserbaidžaani",
        "Assooride",
        "Bangladeshi",
        "सेशेल्स",
        "ᏭᎳᏗᏬᏍᏙᎩ",
        "Brasiilia",
        "Roheneemesaarte",
        "ᏬᎶᎪᏝᏗ",
        "Chathami",
        "Tšiili",
        "Hiina",
        "Tšojbalsani",
        "萬那杜夏令時間",
        "萬那杜標準時間",
        "Cooki",
        "Vaxt",
        "የሆንግ",
        "Kuuba",
        "Tojikiston",
        "ᏯᎫᏥᎧ",
        "Georgiya",
        "Lihavõttesaare",
        "Seyshel",
        "ᏰᎧᏖᎵᏂᏊᎦ",
        "Reyunion",
        "Acrejský",
        "ኮንግ",
        "Falklandi",
        "የሆቭድ",
        "Prantsuse",
        "Чатъм",
        "Gvineya",
        "Чилийско",
        "Niuye",
        "Китайско",
        "Gruusia",
        "吐瓦魯時間",
        "Чойбалсанско",
        "Západoafrický",
        "Guami",
        "Aljašský",
        "Qirgʻiziston",
        "Aleuudi",
        "Hongkongi",
        "Hovdi",
        "Almatský",
        "Amazonský",
        "Iraani",
        "Iisraeli",
        "Jaapani",
        "Kamtšatka",
        "Kasahstani",
        "የህንድ",
        "centrální",
        "Howe’i",
        "východní",
        "Magadani",
        "टॉंगा",
        "Hindixitoy",
        "Hindiston",
        "irsk",
        "तुर्कमेनीस्तान",
        "Saarte",
        "Mauritiuse",
        "Apijský",
        "Loode",
        "Aktauský",
        "Ulaanbaatari",
        "Gayana",
        "ko‘rfazi",
        "Aktobský",
        "Kaledoonia",
        "Meremaa",
        "Newfoundlandi",
        "Fors",
        "Arabský",
        "Pakistani",
        "Argentinský",
        "o‘rtacha",
        "Peruu",
        "Filipiini",
        "Grinvich",
        "Pierre’i",
        "Miqueloni",
        "Západoargentinský",
        "Gambye",
        "Kõzõlorda",
        "hududlari",
        "Sahhalini",
        "Gvianasi",
        "Minsk",
        "Arménský",
        "Atlantický",
        "d’Yurvil",
        "Türkmenistani",
        "Usbekistani",
        "Vladivostoki",
        "Volgogradi",
        "Dyumon",
        "Deyvis",
        "yarim",
        "Středoaustralský",
        "Rojdestvo",
        "Středozápadní",
        "australský",
        "Východoaustralský",
        "Západoaustralský",
        "Ázerbájdžánský",
        "Azorský",
        "Bangladéšský",
        "Британия",
        "ऊरुगुए",
        "Alaskako",
        "Brasilijský",
        "東加夏令時間",
        "Amazoniako",
        "Kapverdský",
        "ऊज़्बेकिस्तान",
        "mendialdeko",
        "東加標準時間",
        "Anadyrreko",
        "Apiako",
        "Arabiako",
        "Argentinako",
        "Armeniako",
        "Atlantikoko",
        "erdi",
        "Azerbaijango",
        "Azoreetako",
        "Bangladesheko",
        "वनुआटु",
        "Dorussalom",
        "Brasiliako",
        "Bruney",
        "Verdeko",
        "Chathamský",
        "Chathamgo",
        "Txileko",
        "Txinako",
        "Choibalsango",
        "Chilský",
        "Čínský",
        "Kolonbiako",
        "Čojbalsanský",
        "Kubako",
        "वेनेज़ुएला",
        "व्लादीवॉस्तॉक",
        "Kokosových",
        "वॉलगोग्राद",
        "Pazko",
        "Kolumbijský",
        "Колумбийско",
        "የአፒያ",
        "የኢራን",
        "Fijiko",
        "Kubánský",
        "Кубинско",
        "Dumonta",
        "याकुट्स्क",
        "येकातेरीनाबुर्ग",
        "Velikonočního",
        "蘇利南時間",
        "Středoevropský",
        "SEČ",
        "Afgʻoniston",
        "Ўзбекистон",
        "Туркманистон",
        "Aleutiar",
        "Kongo",
        "Hovdeko",
        "Покистон",
        "SELČ",
        "Хове",
        "Қозоғистон",
        "Irango",
        "Irkutskeko",
        "Israelgo",
        "Japoniako",
        "Kamchatskiko",
        "Koreako",
        "Исроил",
        "Krasnoyarskeko",
        "Эрон",
        "Howeko",
        "Magadango",
        "Ҳинд",
        "алеут",
        "Фолькленд",
        "Maurizioko",
        "Východoevropský",
        "영국",
        "ipar",
        "Batorreko",
        "Moskuko",
        "Západoevropský",
        "Falklandský",
        "Чатхам",
        "Fidžijský",
        "Zeelanda",
        "Ternuako",
        "Озарбайжон",
        "Noronhako",
        "Novosibirskeko",
        "Omskeko",
        "Pakistango",
        "Арманистон",
        "Арабистон",
        "Paraguayko",
        "Peruko",
        "Filipinetako",
        "Mikeluneko",
        "索羅門群島時間",
        "тинч",
        "тоғ",
        "шарқий",
        "Sakhalingo",
        "Samarako",
        "Samoako",
        "марказий",
        "新加坡標準時間",
        "塞席爾時間",
        "d’Acre",
        "antarktických",
        "Taipeiko",
        "Жанубий",
        "území",
        "სამხრეთ",
        "Tongako",
        "아일랜드",
        "Turkmenistango",
        "ალასკის",
        "ამაზონიის",
        "የእስራኤል",
        "Uruguayko",
        "აღმოსავლეთის",
        "მაუნთინის",
        "Uzbekistango",
        "Vanuatuko",
        "აპიას",
        "არაბეთის",
        "Gruzínský",
        "სომხეთის",
        "ატლანტიკის",
        "Vladivostokeko",
        "Volgogradeko",
        "აზერბაიჯანის",
        "აზორის",
        "Gilbertových",
        "ბანგლადეშის",
        "فٹونا",
        "Yakutskeko",
        "ბრაზილიის",
        "والیز",
        "ووسٹاک",
        "კაბო",
        "ვერდეს",
        "Великденски",
        "ჩატემის",
        "ჩილეს",
        "ჩინეთის",
        "დროის",
        "სარტყელი",
        "ჩოიბალსანის",
        "የጃፓን",
        "Východogrónský",
        "Západogrónský",
        "კოლუმბიის",
        "კუკის",
        "የፔትሮፓቭሎስኪ",
        "კუბის",
        "Централноевропейско",
        "zálivu",
        "Източноевропейско",
        "ካምቻትስኪ",
        "აღდგომის",
        "Hongkongský",
        "Hovdský",
        "Аљашко",
        "ფოლკლენდის",
        "ფიჯის",
        "Milad",
        "Западноевропейско",
        "Фолклендски",
        "皮特肯時間",
        "ካዛኪስታን",
        "Íránský",
        "Irkutský",
        "საქართველოს",
        "Izraelský",
        "Japonský",
        "የኮሪያ",
        "بولیوی",
        "kamčatský",
        "Irlandiya",
        "የዓረቢያ",
        "ჰავაისა",
        "ალეუტის",
        "ჰონკონგის",
        "ჰოვდის",
        "ინდოეთის",
        "Korejský",
        "Suider",
        "ირანის",
        "ირკუტსკის",
        "ისრაელის",
        "იაპონიის",
        "ყაზახეთის",
        "კორეის",
        "Krasnojarský",
        "კრასნოიარსკის",
        "Yarım",
        "Арменија",
        "ლორდ",
        "ჰაუს",
        "Rovníkových",
        "მაგადანის",
        "Атланско",
        "کریسمس",
        "Devis",
        "Macajský",
        "მავრიკის",
        "Magadanský",
        "ჩრდილო",
        "Dümon",
        "ულან",
        "ბატორის",
        "მოსკოვის",
        "d’Ürvil",
        "دیویس",
        "دومون",
        "دورویل",
        "კალედონიის",
        "ზელანდიის",
        "ნიუფაუნდლენდის",
        "Гамбие",
        "شرقی",
        "ფერნანდო",
        "დე",
        "ნორონიას",
        "ნოვოსიბირსკის",
        "ომსკის",
        "პაკისტანის",
        "Marshallových",
        "اکوادور",
        "Mauricijský",
        "პარაგვაის",
        "პერუს",
        "ფილიპინების",
        "مکان‌های",
        "სენ",
        "პიერის",
        "მიკელონის",
        "دیگر",
        "巴布亞紐幾內亞時間",
        "Severozápadní",
        "Грузинско",
        "Mexický",
        "სახალინის",
        "სამოას",
        "سرزمین‌های",
        "Ulánbátarský",
        "جنوبگان",
        "Moskevský",
        "گامبیه",
        "isikhathi",
        "گیلبرت",
        "ტაიბეის",
        "گرینویچ",
        "Средно",
        "ტონგის",
        "گوام",
        "თურქმენეთის",
        "Irlandia",
        "ურუგვაის",
        "უზბეკეთის",
        "ვანუატუს",
        "فارس",
        "ვლადივოსტოკის",
        "ვოლგოგრადის",
        "Novokaledonský",
        "Novozélandský",
        "Newfoundlandský",
        "Източногренландско",
        "იაკუტსკის",
        "ეკატერინბურგის",
        "اقیانوس",
        "هندوچین",
        "Западногренландско",
        "souostroví",
        "d’Almaty",
        "Novosibirský",
        "Pasifiku",
        "Atlantiku",
        "Sentru",
        "Omský",
        "Pákistánský",
        "Kənar",
        "Papuy",
        "Оңтүстік",
        "کوسرای",
        "የሎርድ",
        "قرقیزستان",
        "لانکا",
        "لاین",
        "Guiney",
        "тау",
        "Paraguayský",
        "мұхиты",
        "Сауд",
        "Арабиясы",
        "مکواری",
        "Peruánský",
        "مالزی",
        "Әзірбайжан",
        "مالدیو",
        "مارکوئز",
        "Filipínský",
        "Fénixových",
        "Қытай",
        "аралының",
        "Хавайско",
        "迪諾羅尼亞夏令時間",
        "迪諾羅尼亞標準時間",
        "Пасха",
        "norteamericana",
        "نائورو",
        "نپال",
        "Kyzylordský",
        "Хонконгско",
        "Ховдско",
        "Sachalinský",
        "ሆዌ",
        "نیوئه",
        "نورفولک",
        "Samojský",
        "Qvianası",
        "ماریانای",
        "شمالی",
        "britansko",
        "Гавай",
        "Antarktik",
        "Qalapaqos",
        "پالائو",
        "پاپوا",
        "گینهٔ",
        "Georgie",
        "Жапония",
        "Қазақстан",
        "新喀里多尼亞群島夏令時間",
        "Šówa",
        "فونیکس",
        "Qambier",
        "Tchajpejský",
        "پیتکایرن",
        "پوناپه",
        "мұхит",
        "Мәскеу",
        "پیونگ‌یانگ",
        "የማጋዳን",
        "ریونیون",
        "Новосібір",
        "Омбы",
        "Пәкістан",
        "روترا",
        "諾魯時間",
        "Tonžský",
        "سیشل",
        "Иранско",
        "Тайбэй",
        "سلیمان",
        "Түрікменстан",
        "جورجیای",
        "Turkmenský",
        "شووا",
        "تاهیتی",
        "Иркутско",
        "تاجیکستان",
        "توکلائو",
        "Uruguayský",
        "چوئوک",
        "Uzbecký",
        "تووالو",
        "Vanuatský",
        "Qrinviç",
        "Vladivostocký",
        "ونزوئلا",
        "Volgogradský",
        "Jakutský",
        "وستوک",
        "Jekatěrinburský",
        "والیس",
        "Японско",
        "irsko",
        "Acren",
        "Камчатски",
        "ब्रिटिश",
        "Körfəz",
        "Корейско",
        "Qayana",
        "Красноярско",
        "برٹش",
        "Etelä",
        "montañes",
        "Alaskan",
        "Almatyn",
        "Amazonin",
        "Mynyddoedd",
        "keskinen",
        "itäinen",
        "Kalliovuorten",
        "norteamericanes",
        "Anadyrin",
        "Apian",
        "Aqtaw’n",
        "Aqtöben",
        "Saudi",
        "Tawel",
        "Armenian",
        "Kanadan",
        "Atlantin",
        "Läntisen",
        "Azerbaidžanin",
        "Azorien",
        "Bangladeshin",
        "Arabaidd",
        "Магаданско",
        "Brasilian",
        "وینزوئیلا",
        "Antarktiese",
        "Verden",
        "Iwerydd",
        "የማውሪሺየስ",
        "Chathamin",
        "Chilen",
        "Kiinan",
        "Tšoibalsan",
        "ٹوالو",
        "Aserbaijan",
        "Kolumbian",
        "Cookinsaarten",
        "Kuuban",
        "چوک",
        "በጋ",
        "ٹوکیلاؤ",
        "Pääsiäissaaren",
        "تاجکستان",
        "Okeanı",
        "تئی",
        "Falklandinsaarten",
        "Fidžin",
        "Ranskan",
        "Мавриций",
        "تائی",
        "تاہیتی",
        "سولمن",
        "سیشلیز",
        "Y",
        "Hindçin",
        "ሰሜናዊ",
        "روتھیرا",
        "ምእራብ",
        "یونین",
        "ری",
        "یانگ",
        "Havaijin",
        "Aleuttien",
        "Hongkongin",
        "Hovdin",
        "Intian",
        "پیانگ",
        "پوناپے",
        "Pasg",
        "Iranin",
        "អាល់ដ្យូសិន",
        "Irkutskin",
        "Israelin",
        "Japanin",
        "Kamtšatkan",
        "Kazakstanin",
        "پٹکائرن",
        "Krasnojarskin",
        "Мексиканско",
        "Уланбаторско",
        "Falklands/Malvinas",
        "Howen",
        "Macaon",
        "فینکس",
        "Magadanin",
        "Московско",
        "የአርመኒያ",
        "ፓሲፊክ",
        "گنی",
        "Mauritiuksen",
        "پاپوآ",
        "Luoteis",
        "Новокаледонско",
        "Batorin",
        "Moskovan",
        "پلاؤ",
        "Залив",
        "نورنہا",
        "模里西斯夏令時間",
        "Kaledonian",
        "Seelannin",
        "Newfoundlandin",
        "Новозеландско",
        "نارفوک",
        "Noronhan",
        "نیئو",
        "模里西斯標準時間",
        "Novosibirskin",
        "Omskin",
        "Pakistanin",
        "Нюфаундлендско",
        "d’Aqtau",
        "Paraguayn",
        "Perun",
        "Filippiinien",
        "d’Aqtobe",
        "Pierren",
        "Miquelonin",
        "نیپال",
        "រដូវ​ក្ដៅ",
        "ناؤرو",
        "Siapan",
        "Qızılordan",
        "d’Arabia",
        "Arglwydd",
        "Sahalinin",
        "Samaran",
        "Samoan",
        "Korse",
        "ماؤسن",
        "Orllewin",
        "مارشل",
        "مارکیسس",
        "مالدیپ",
        "Taipein",
        "ملیشیا",
        "میگیدن",
        "Tongan",
        "مگادان",
        "Turkmenistanin",
        "مکوآری",
        "Uruguayn",
        "Uzbekistanin",
        "Vanuatun",
        "Нороня",
        "Vladivostokin",
        "Volgogradin",
        "Pasiffig",
        "لائن",
        "និង​មីគុយឡុង",
        "کرغستان",
        "کریسنویارسک",
        "Jakutskin",
        "Jekaterinburgin",
        "Новосибирско",
        "Moscfa",
        "Омско",
        "Seland",
        "Timog",
        "Periw",
        "Пакистанско",
        "Qırğızıstan",
        "የኡላን",
        "Парагвайско",
        "Karaniwang",
        "Bundok",
        "Перуанско",
        "Atlantiko",
        "馬可薩斯時間",
        "Kanluran",
        "کرسنویارسک",
        "کوسرے",
        "Tyrcmenistan",
        "Wsbecistan",
        "Филипинско",
        "ባቶር",
        "Пиер",
        "Afghansk",
        "Makari",
        "Hilagang",
        "kanlurang",
        "Centralafrikansk",
        "Østafrikansk",
        "Sydafrikansk",
        "Miðafrika",
        "Eysturafrika",
        "Suðurafrika",
        "d’Armenia",
        "Atlánticu",
        "የሞስኮ",
        "Markesas",
        "Bretland",
        "Реюнион",
        "Stóra",
        "Сахалинско",
        "d’Azerbaixán",
        "Самоанско",
        "Írsk",
        "የአትላንቲክ",
        "Bangladex",
        "ካሌዶኒያ",
        "Джорджия",
        "Jólaoyggj",
        "Kokosoyggjar",
        "ዚላንድ",
        "Eysturtimor",
        "የኒውፋውንድላንድ",
        "Cabu",
        "ಅಮೆಜಾನ್",
        "Тайпе",
        "longri",
        "Bhutanesisk",
        "Boliviansk",
        "eilande",
        "ಪೂರ್ವದ",
        "ಪರ್ವತ",
        "بریتانیا",
        "ಅನಡೀರ್‌",
        "ಅಪಿಯಾ",
        "ಅರೇಬಿಯನ್",
        "Fronsku",
        "sunnaru",
        "ಅರ್ಮೇನಿಯ",
        "ಅಟ್ಲಾಂಟಿಕ್",
        "landaøki",
        "ಅಜರ್ಬೈಜಾನ್",
        "ಅಜೋರಸ್",
        "ಬಾಂಗ್ಲಾದೇಶ",
        "Antarktis",
        "Gilbertoyggjar",
        "ಬ್ರೆಸಿಲಿಯಾ",
        "የፈርናንዶ",
        "Туркменистанско",
        "ಕೇಪ್",
        "ವರ್ಡ್",
        "Indiahav",
        "ಚಥಾಮ್",
        "ಚಿಲಿ",
        "ಚೀನಾ",
        "Уругвайско",
        "Cocosøerne",
        "Узбекистанско",
        "Kirgisia",
        "Lineoyggjar",
        "Macquariesoyggj",
        "ಕೊಲಂಬಿಯಾ",
        "ಕುಕ್",
        "Maldivoyggjar",
        "ಕ್ಯೂಬಾ",
        "Marshalloyggjar",
        "ዲ",
        "(Burma)",
        "Владивостокско",
        "Østtimor",
        "ಈಸ್ಟರ್",
        "Norfolksoyggj",
        "Nýguinea",
        "Волгоградско",
        "ಫಾಲ್ಕ್‌ಲ್ಯಾಂಡ್",
        "ಫಿಜಿ",
        "ಫ್ರೆಂಚ್",
        "ಗಯಾನಾ",
        "Phoenixoyggjar",
        "Pitcairnoyggjar",
        "Seyskelloyggjar",
        "Singapor",
        "Salomonoyggjar",
        "Suðurgeorgiaoyggjar",
        "Tadsjikistan",
        "Ecuadoriansk",
        "Wakeoyggj",
        "Futunaoyggjar",
        "Qvineya",
        "ಹವಾಯಿ",
        "ಅಲ್ಯುಟಿಯನ್",
        "ಹಾಂಗ್",
        "ಕಾಂಗ್",
        "ಹವ್ಡ್",
        "Уейк",
        "Fjernøsteuropæisk",
        "Уолис",
        "Якутскско",
        "ಇಂಡೋನೇಷಿಯಾ",
        "Екатеринбургско",
        "ಇರಾನ್",
        "ಇರ್‌ಕುಟಸ್ಕ್",
        "ಇಸ್ರೇಲ್",
        "ಜಪಾನ್",
        "ಪೆತ್ರೋಪಾವ್ಲೋಸ್ಕ್‌‌",
        "ಕಮ್ಚತ್ಸ್‌ಕೀ",
        "ಕಜಕಿಸ್ತಾನ್",
        "ಕೊರಿಯನ್",
        "আফগানিস্তান",
        "ಕ್ರಾಸ್‌ನೊಯಾರ್ಸ್ಕ್",
        "Franske",
        "Sydlige",
        "ಲಾರ್ಡ್",
        "ಹೋವ್",
        "ایرلند",
        "Antarktiske",
        "ಮಗಡಾನ್",
        "Territorier",
        "Jekaterinburški",
        "Volgograjski",
        "Vladivostoški",
        "ಮಾರಿಷಸ್",
        "Vanuatujski",
        "ವಾಯವ್ಯ",
        "ಮೆಕ್ಸಿಕೊ",
        "ಮೆಕ್ಸಿಕನ್",
        "ಉಲನ್",
        "ಬ್ಯಾಟರ್",
        "ಮಾಸ್ಕೋ",
        "Uzbekistanski",
        "Urugvajski",
        "Turkmenistanski",
        "ಹೊಸ",
        "ಕ್ಯಾಲೆಡೋನಿಯಾ",
        "Otok",
        "ನ್ಯೂಜಿಲ್ಯಾಂಡ್",
        "ನ್ಯೂಫೌಂಡ್‌ಲ್ಯಾಂಡ್",
        "Tongovski",
        "Tajpejski",
        "Samoanski",
        "Samarski",
        "ನೊರೊನ್ಹಾ",
        "Sahalinski",
        "Perujski",
        "ನೊವೊಸಿಬಿರ್‌ಸ್ಕ್",
        "ಒಮಾಸ್ಕ್",
        "ಪಾಕಿಸ್ತಾನ",
        "Paragvajski",
        "Pakistanski",
        "Noronški",
        "Novofundlandski",
        "ಪರಾಗ್ವೇ",
        "ಪೆರು",
        "ಫಿಲಿಫೈನ್",
        "Novozelandski",
        "ಸೇಂಟ್",
        "ಪಿಯರ್",
        "ಮಿಕ್ವೆಲನ್",
        "Novokaledonijski",
        "Moskovski",
        "Ulanbatorski",
        "pacifiški",
        "severozahodni",
        "ಸ್ಯಾಕ್‌ಹಲಿನ್",
        "ಸಮರ",
        "ಸಮೋವಾ",
        "Indië",
        "Mauricijski",
        "kazahstanski",
        "Japonski",
        "Izraelski",
        "Iranski",
        "ತೈಪೆ",
        "Gilbertøerne",
        "Hovdski",
        "ಟೊಂಗಾ",
        "Hongkonški",
        "ತುರ್ಕ್‌ಮೇನಿಸ್ತಾನ್",
        "Havajski",
        "ಉರುಗ್ವೇ",
        "ಉಜ್ಬೇಕಿಸ್ತಾನ್",
        "ವನೌತು",
        "Zahodnogrenlandski",
        "ವ್ಲಾಡಿವೋಸ್ಟೋಕ್",
        "ವೋಲ್ಗೋಗಾರ್ಡ್",
        "Vzhodnogrenlandski",
        "Gruzijski",
        "آئرش",
        "ምስራቅ",
        "ಯಾಕುಟ್ಸಕ್",
        "Fidžijski",
        "Falklandsko",
        "Golflandene",
        "Indisk",
        "Indiske",
        "아프가니스탄",
        "Βρετανίας",
        "중앙아프리카",
        "동아프리카",
        "남아프리카",
        "የኦምስክ",
        "Centralindonesisk",
        "Østindonesisk",
        "Vestindonesisk",
        "የፓኪስታን",
        "Ιρλανδίας",
        "Britiski",
        "Østkasakhstansk",
        "산악",
        "산지",
        "Vestkasakhstansk",
        "আমাজন",
        "Kirgisisk",
        "Langa",
        "Linjeøerne",
        "অ্যামাজন",
        "Malaysisk",
        "Maldivisk",
        "Marshalløerne",
        "ምዕራባዊ",
        "የአዘርባይጃን",
        "yay",
        "Myanmarsk",
        "Nepalesisk",
        "Seyşel",
        "부탄",
        "볼리비아",
        "Sinqapur",
        "브루나이",
        "የፓራጓይ",
        "Nordmarianerne",
        "케이시",
        "차모로",
        "Corciya",
        "Ny",
        "የፔሩ",
        "የፊሊፒን",
        "크리스마스섬",
        "코코스",
        "Britisk",
        "Phoenixøen",
        "Seychellisk",
        "절반",
        "Singaporeansk",
        "데이비스",
        "뒤몽뒤르빌",
        "동티모르",
        "Salomonøerne",
        "에콰도르",
        "ቅዱስ",
        "Tahitisk",
        "극동",
        "Tadsjikisk",
        "Fixi",
        "የፒዬር",
        "আর্জেনটিনার",
        "ሚኴሎን",
        "남부",
        "식민지",
        "및",
        "남극",
        "갈라파고스",
        "감비에",
        "Venezuelansk",
        "길버트",
        "그리니치",
        "Wakeøen",
        "걸프만",
        "Irsk",
        "Vaunatu",
        "የአዞረስ",
        "የባንግላዴሽ",
        "인도",
        "인도양",
        "인도차이나",
        "雅庫茨克夏令時間",
        "Britansko",
        "Afganistansko",
        "ভুটান",
        "Centralnoafričko",
        "Istočnoafričko",
        "Južnoafričko",
        "বোলিভিয়া",
        "조선",
        "코스라에섬",
        "Irsko",
        "키르기스스탄",
        "라인",
        "ব্রুনেই",
        "দারুসসালাম",
        "所羅門群島時間",
        "매쿼리섬",
        "የሳክሃሊን",
        "말레이시아",
        "몰디브",
        "마르키즈",
        "마셜",
        "চামেরো",
        "모슨",
        "新加坡時間",
        "塞舌爾時間",
        "গ্রীষ্মকাল",
        "የሳማራ",
        "미얀마",
        "나우루",
        "네팔",
        "Заходнеафрыканскі",
        "ক্রিসমাস",
        "የሳሞዋ",
        "니우에",
        "노퍽섬",
        "কোকোস",
        "ব্রিটিশ",
        "আইরিশ",
        "Аляскі",
        "بحر",
        "অর্ধেক",
        "팔라우",
        "파푸아뉴기니",
        "雅庫茨克標準時間",
        "ডেভিস",
        "ডুমন্ট",
        "피닉스",
        "দ্য’উরভিলে",
        "وچ",
        "핏케언",
        "포나페",
        "평양",
        "Îles",
        "레위니옹",
        "로데라",
        "d’Hawaï",
        "টিমর",
        "جلبرٹ",
        "세이셸",
        "싱가포르",
        "솔로몬",
        "사우스",
        "조지아",
        "수리남",
        "쇼와",
        "타히티",
        "Амазонскі",
        "타지키스탄",
        "토켈라우",
        "ইকুয়েডর",
        "추크",
        "Британско",
        "투발루",
        "centrâl",
        "Butansko",
        "orientâl",
        "베네수엘라",
        "ocidentâl",
        "Mosche",
        "보스톡",
        "웨이크섬",
        "월리스푸투나",
        "Bolivijsko",
        "অতিরিক্ত",
        "대한민국",
        "اٮ۪کرے",
        "گیمبیئر",
        "Brunejsko",
        "Sintraal",
        "Zahodnoevropski",
        "ইউরোপীয়",
        "Sûd",
        "Čamorsko",
        "جنوٗبی",
        "цэнтральны",
        "اٮ۪لاسکا",
        "ўсходні",
        "اٮ۪لمٮ۪ٹی",
        "اٮ۪مَزَن",
        "ماونٹین",
        "پیسِفِک",
        "Vzhodnoevropski",
        "Srednjeevropski",
        "اٮ۪کٹاؤ",
        "اٮ۪کٹوب",
        "ارٮ۪بِیَن",
        "Arabyske",
        "ارمیٖنِیا",
        "اٮ۪ٹلانٹِک",
        "горны",
        "গায়ানা",
        "otok",
        "Velikonočni",
        "اَزَربیجان",
        "اٮ۪زورٕس",
        "Kubanski",
        "بَنٛگلادیش",
        "Cookovootoški",
        "Kolumbijski",
        "برٮ۪سِلِیا",
        "Ціхаакіянскі",
        "আন্টার্কটিক",
        "Božićnom",
        "Kitajski",
        "Čilski",
        "Čatamski",
        "Brasilski",
        "چِلی",
        "چَینا",
        "کوےبٮ۪لسَن",
        "Bangladeški",
        "Azorski",
        "Azerbeidzjaanske",
        "کولومبِیا",
        "کُک",
        "Azerbajdžanski",
        "کیوٗبا",
        "গালাপাগোস",
        "گالاپاگوز",
        "انٹارکٹک",
        "Brazyljaanske",
        "Atlantski",
        "Armenski",
        "ایٖسٹَر",
        "গ্যাম্বিয়ার",
        "Kaapverdyske",
        "Sileenske",
        "فاکلینڑ",
        "فیٖجی",
        "Sineeske",
        "Апіі",
        "Anadirski",
        "Pacifiški",
        "Gorski",
        "جورجِیاہُک",
        "গিলবার্ট",
        "গ্রীনিচ",
        "Centralni",
        "Amazonski",
        "Aljaški",
        "Kolombiaanske",
        "Cookeilânse",
        "মিন",
        "Kubaanske",
        "Poluljetno",
        "Zahodnoafriški",
        "حَواے",
        "اٮ۪لیوٗٹِیَن",
        "jekaterinburský",
        "کانٛگ",
        "jakutský",
        "حووڑ",
        "volgogradský",
        "vladivostocký",
        "vanuatský",
        "uzbecký",
        "uruguajský",
        "Peaskeeilânske",
        "turkménsky",
        "tonžský",
        "اِرکُٹسک",
        "اِسرٲیِلی",
        "টাইম",
        "جاپٲنۍ",
        "کَمچَٹکا",
        "کَزاکھِستان",
        "کورِیا",
        "tchajpejský",
        "کرٮ۪سنوےیارسک",
        "samojský",
        "sachalinský",
        "pierre",
        "لعاڑ",
        "filipínsky",
        "ڑےلایٔٹ",
        "مَکَعوٗ",
        "مَگَدَن",
        "peruánsky",
        "paraguajský",
        "pakistanský",
        "omský",
        "مورِشَس",
        "novosibirský",
        "مونگولِیا",
        "Саудаўскай",
        "súostrovia",
        "newfoundlandský",
        "novozélandský",
        "نِو",
        "novokaledónsky",
        "moskovský",
        "نِوزِلینڑ",
        "Falklâneilânske",
        "ulanbátarský",
        "severozápadný",
        "maurícijský",
        "magadanský",
        "krasnojarský",
        "Fijyske",
        "شُمٲلی",
        "kórejský",
        "نۄوۄسِبٔرسک",
        "اۄمسک",
        "پاکِستان",
        "japonský",
        "izraelský",
        "irkutský",
        "پیرٮ۪گوے",
        "پٔروٗ",
        "پھِلِپایِن",
        "iránsky",
        "سینٛٹ",
        "پَیری",
        "مِقیوٗلَن",
        "chovdský",
        "hongkonský",
        "قِزلوڑا",
        "západogrónsky",
        "východogrónsky",
        "سَکھٮ۪لِن",
        "سمٮ۪را",
        "سٮ۪موآ",
        "gruzínsky",
        "fidžijský",
        "falklandský",
        "západoeurópsky",
        "východoeurópsky",
        "stredoeurópsky",
        "Veľkonočného",
        "Istočnotimorsko",
        "kubánsky",
        "kolumbijský",
        "Guyaanske",
        "ٹعانٛگا",
        "čojbalsanský",
        "تُرکمٮ۪نِستان",
        "čínsky",
        "یوٗرٮ۪گوَے",
        "čilský",
        "chathamský",
        "وَنوٗاَٹوٗ",
        "kapverdský",
        "ولاڑِووسٹوک",
        "وولگوگریڑ",
        "brazílsky",
        "bangladéšsky",
        "azorský",
        "azerbajdžanský",
        "západoaustrálsky",
        "východoaustrálsky",
        "austrálsky",
        "stredozápadný",
        "উপসাগরীয়",
        "Ekvadorsko",
        "یورپی",
        "stredoaustrálsky",
        "بعید",
        "Wäß",
        "Sommerzigg",
        "গুয়ানা",
        "ier",
        "Аравіі",
        "ایکواڈور",
        "Dalekoistočnoevropsko",
        "Kapvärdejaansche",
        "Meddel",
        "Аргенцінскі",
        "Aleoetyske",
        "Weß",
        "atlantický",
        "arménsky",
        "západoargentínsky",
        "İngiltere",
        "argentínsky",
        "arabský",
        "Fidžiju",
        "ڈی’ارویلے",
        "Fidžijsko",
        "ڈومونٹ",
        "Түштүк",
        "Francuskogvajansko",
        "Iraanske",
        "Francuskoj",
        "Israëlyske",
        "Japanske",
        "Južnoj",
        "убакыт",
        "Teritoriji",
        "тоо",
        "Koreaanske",
        "Арабия",
        "Аргенціны",
        "Азербайжан",
        "apijský",
        "Капе",
        "ڈیوس",
        "Крисмас",
        "Antarktiku",
        "Galapagosko",
        "Gambijersko",
        "Дэвис",
        "д",
        "Урвил",
        "Истер",
        "východný",
        "centrálny",
        "نصف",
        "Арменіі",
        "Gilbertovim",
        "amazonský",
        "aljašský",
        "Mauritiaanske",
        "Griničko",
        "ভারতীয়",
        "Атлантычны",
        "západoafrický",
        "Жапон",
        "Казакстан",
        "ভারত",
        "යෙකටෙරින්බර්ග්",
        "Макуари",
        "Маркезас",
        "යකුට්ස්ක්",
        "Kaledonyske",
        "Жаңы",
        "Нюфаундлэнд",
        "වොල්ගොග්‍රාඩ්",
        "Сейшел",
        "ව්ලදිවෝස්ටෝක්",
        "Seelânske",
        "වනුආටු",
        "Newfoundlânske",
        "උස්බෙකිස්තාන",
        "Түркмөнстан",
        "Gvajansko",
        "ইন্দোচীন",
        "کرسمس",
        "උරුගුවේ",
        "ටර්ක්මෙනිස්තාන",
        "ටොංගා",
        "Westafrikanesch",
        "کوائبلسان",
        "Arabesch",
        "Argentinesch",
        "Westargentinesch",
        "Armenesch",
        "Zentralaustralesch",
        "/Westaustralesch",
        "Ostaustralesch",
        "Westaustralesch",
        "තායිපේ",
        "සැමෝවා",
        "සඛලින්",
        "Chilenesch",
        "Chinesesch",
        "මැකෝලන්",
        "පියරේ",
        "Kolumbianesch",
        "Cookinselen",
        "Kubanesch",
        "Ouschterinsel",
        "ශාන්ත",
        "Mëtteleuropäesch",
        "Osteuropäesch",
        "Westeuropäesch",
        "Falklandinselen",
        "Franséisch",
        "පිලිපීන",
        "පේරු",
        "Georgesch",
        "පැරගුවේ",
        "පාකිස්ථාන",
        "ඔම්ස්ක්",
        "නොවසිබිර්ස්ක්",
        "නොරොන්හා",
        "ඩි",
        "ෆර්නැන්ඩෝ",
        "Iranesch",
        "Israelesch",
        "Japanesch",
        "නිව්ෆවුන්ලන්ත",
        "නවසීලන්ත",
        "Koreanesch",
        "සෙලඩොනියානු",
        "නව",
        "මොස්කව්",
        "බාටර්",
        "Nordwest",
        "Mexikanesch",
        "Pazifik",
        "උලාන්",
        "Neikaledonesch",
        "Neiséiland",
        "Neifundland",
        "Pakistaanske",
        "Pakistanesch",
        "වයඹ",
        "Paraguayanesch",
        "Peruanesch",
        "Philippinnesch",
        "මුරුසි",
        "මෙගඩන්",
        "හෝව්",
        "ලෝර්ඩ්",
        "ක්‍රස්නොයාර්ස්ක්",
        "Tonganesch",
        "කොරියානු",
        "කසකස්තාන",
        "Jekaterinbuerg",
        "ජපාන",
        "ඊශ්‍රායල",
        "цэнтральнай",
        "ඉර්කුට්ස්ක්",
        "ඉරාන",
        "کوئبلسان",
        "හොව්ඩ්",
        "හොංකොං",
        "අලෙයුතියාන්",
        "හවායි",
        "Paraguayaanske",
        "ජෝර්ජියානු",
        "Peruaanske",
        "ගයනා",
        "ප්‍රංශ",
        "ෆිජි",
        "ෆෝක්ලන්ඩ්",
        "পশ্চিমী",
        "چینی",
        "ඊස්ටර්",
        "කියුබානු",
        "කුක්",
        "කොලොම්බියා",
        "İrlandiya",
        "චොයිබල්සාන්",
        "චීන",
        "චිලී",
        "චැතම්",
        "කේප්වේඩ්",
        "බ්‍රසීල",
        "බංගලාදේශ",
        "ඇසොර්ස්",
        "අසර්බයිජාන්",
        "Заходні",
        "Samoaanske",
        "අත්ලාන්තික්",
        "ආමේනියානු",
        "Indijskom",
        "okeanu",
        "අරාබි",
        "Indokinesko",
        "අපියා",
        "Centralnoindonezijsko",
        "කඳුකර",
        "بولیویا",
        "Istočnoindonezijsko",
        "ඇමර්සන්",
        "Tongaanske",
        "ඇලස්කා",
        "Zapadnoindonezijsko",
        "Цэнтральнай",
        "ເວລາມາດຕະຖານ",
        "ບັງກະລາເທດ",
        "بھوٹان",
        "Uruguayaanske",
        "දකුණු",
        "усходняй",
        "Vanuatuaanske",
        "заходняй",
        "Азербайджана",
        "Азорскіх",
        "Istočnokazahstansko",
        "Zapadnokazahstansko",
        "Ḥong",
        "Бангладэш",
        "Kirgistansko",
        "কোসরেই",
        "İrlanda",
        "Екатеринбуур",
        "Дьокуускай",
        "Быладьыбастыак",
        "Сахалыын",
        "Пакистаан",
        "Омскай",
        "Новосибирскай",
        "Сэйлэнд",
        "Саҥа",
        "Москуба",
        "Баатар",
        "Магадаан",
        "Красноярскай",
        "Кэриэй",
        "Казахстаан",
        "Дьоппуон",
        "Курусуун",
        "Theas",
        "কিরগিস্তান",
        "Киин",
        "Эрмээн",
        "Makvori",
        "hAmasóine",
        "Lárnach",
        "Арааб",
        "Oirthir",
        "Sléibhte",
        "লাইন",
        "Malezijsko",
        "hAraibe",
        "ແລະ",
        "Maldivsko",
        "hAirméine",
        "Atlantaigh",
        "Maršalovim",
        "Mheániarthar",
        "hAsarbaiseáine",
        "nAsór",
        "Banglaidéise",
        "皮特康時間",
        "巴布亞新畿內亞時間",
        "Bhrasília",
        "Бразільскі",
        "ম্যাককুরি",
        "Rinn",
        "費爾南多迪諾羅尼亞夏令時間",
        "ເອລູທຽນ",
        "মালয়েশিয়া",
        "Mijanmarsko",
        "Nepalsko",
        "馬爾地夫時間",
        "মালদ্বীপ",
        "মার্কেসাস",
        "Colóime",
        "آسٹریلیا",
        "Chúba",
        "মার্শাল",
        "Norfolško",
        "Cásca",
        "Pódwjacornoafriski",
        "Каба",
        "মসন",
        "Fháclainne",
        "Fhidsí",
        "Alaskojski",
        "Вердэ",
        "費爾南多迪諾羅尼亞標準時間",
        "Чатэма",
        "pódzajtšny",
        "মহসাগরীয়",
        "Ghambier",
        "Чылійскі",
        "Кітая",
        "Arabiski",
        "Чайбалсана",
        "Pódwjacornoargentinski",
        "Armeński",
        "Atlantiski",
        "Srjejźoawstralski",
        "Haváí",
        "Ailiúit",
        "Cong",
        "Srjejźopódwjacorny",
        "Papui",
        "Pódzajtšnoawstralski",
        "Pódwjacornoawstralski",
        "hIaráine",
        "Azerbajdžaniski",
        "Seapáine",
        "Phetropavlovsk",
        "Casacstáine",
        "Cóiré",
        "Acorski",
        "Bangladešski",
        "Insulei",
        "Venezuelei",
        "Lanca",
        "Surinamului",
        "麥覺理時間",
        "Mhacao",
        "Noua",
        "єкатеринбурзьким",
        "якутським",
        "Mhagadan",
        "волгоградським",
        "Nepalului",
        "Myanmarului",
        "владивостоцьким",
        "Novoj",
        "Узбекистані",
        "Mhuirís",
        "Marchize",
        "Kârgâzstan",
        "Iarthuaisceart",
        "Mheicsiceo",
        "Meicsiceach",
        "Ulánbátar",
        "Mhoscó",
        "Уругваї",
        "Nárú",
        "Туркменістані",
        "Indochinei",
        "Oceanului",
        "Indiei",
        "Golfului",
        "Gvineji",
        "Greenwhich",
        "Chaladóine",
        "ແລະ​ມິ​ກົວ​ລອນ",
        "Shéalainne",
        "Antarctice",
        "Thalamh",
        "Australe",
        "Teritoriile",
        "Franceză",
        "Éisc",
        "Fhernando",
        "îndepărtate",
        "Тайбеї",
        "Ecuadorului",
        "самарським",
        "Timorului",
        "Pacastáine",
        "сахалінським",
        "П’єр",
        "Pharagua",
        "Pheiriú",
        "Filipíneach",
        "Філіппінах",
        "Boliviei",
        "Bhutanului",
        "парагвайським",
        "Shakhalin",
        "Shamara",
        "Shamó",
        "Пакистані",
        "омським",
        "новосибірським",
        "ді",
        "Зеландії",
        "Thaipei",
        "Новій",
        "Каледонії",
        "Thonga",
        "Tuircméanastáine",
        "Meridionale",
        "Нової",
        "Uragua",
        "hÚisbéiceastáine",
        "Afganistanului",
        "Vanuatú",
        "Turcomenistão",
        "Caledônia",
        "Maurício",
        "Casaquistão",
        "Irã",
        "Groenlândia",
        "گاٛت",
        "مینجاٛیی",
        "ئستاٛنداٛرد",
        "روٙشنایی",
        "نئهادار",
        "روٙز",
        "Ako",
        "Groelândia",
        "московським",
        "Баторі",
        "Arzeibaijão",
        "মায়ানমার",
        "নাউরু",
        "Iacútsc",
        "Chathamski",
        "Chilski",
        "Chinski",
        "Aliaskos",
        "Almatos",
        "Amazonės",
        "Choibalsański",
        "নেপাল",
        "centro",
        "північнозахідним",
        "kalnų",
        "Finiks",
        "Kokosowych",
        "Anadyrės",
        "Apijos",
        "Aktobės",
        "Arabijos",
        "Kolumbiski",
        "Armėnijos",
        "Atlanto",
        "Deas",
        "Standardny",
        "Azerbaidžano",
        "Azorų",
        "Amasoin",
        "Bangladešo",
        "Centro",
        "Armênia",
        "Brazilijos",
        "Indiese",
        "Kubański",
        "Žaliojo",
        "Kyšulio",
        "Monadh",
        "新喀里多尼亞夏令時間",
        "Čatamo",
        "Čilės",
        "Kinijos",
        "Čoibalsano",
        "瑙魯時間",
        "Jatšowneje",
        "Arabach",
        "Kolumbijos",
        "Kuko",
        "Калумбійскі",
        "Kubos",
        "Reunionsko",
        "Siaraich",
        "Airmeinia",
        "Siar",
        "Velykų",
        "Srjejźoeuropski",
        "Asarbaideàin",
        "Vidurio",
        "Pódzajtšnoeuropski",
        "Kaliningradski",
        "Asorach",
        "Bangladais",
        "Fidžio",
        "Prancūzijos",
        "Pódwjacornoeuropski",
        "Маврикій",
        "Bhrasilia",
        "магаданським",
        "красноярським",
        "Gruzijos",
        "Gilberto",
        "корейським",
        "Казахстані",
        "Chip",
        "Uaine",
        "камчатським",
        "Sìne",
        "Havajų–Aleutų",
        "Honkongo",
        "Hovdo",
        "Indijos",
        "Falklandski",
        "японським",
        "Irano",
        "Izraelio",
        "Japonijos",
        "Kamčiatkos",
        "Kazachstano",
        "Korėjos",
        "ізраїльським",
        "Coloimbia",
        "іркутським",
        "іранським",
        "Lordo",
        "Cùba",
        "Magadano",
        "Ховді",
        "Гонконзі",
        "Càisge",
        "алеутським",
        "Mauricijaus",
        "Fidźiski",
        "নিউই",
        "Batoro",
        "Maskvos",
        "гавайсько",
        "নরফোক",
        "科斯瑞時間",
        "Kaledonijos",
        "Zelandijos",
        "Niufaundlendo",
        "Fàclannach",
        "Fìdi",
        "Noronjos",
        "Guidheàna",
        "Pakistano",
        "Frainge",
        "Paragvajaus",
        "Filipinų",
        "Грузії",
        "Pjero",
        "Mikelono",
        "Фіджі",
        "Фолклендських",
        "Cairtbheile",
        "Kyzylordos",
        "західноєвропейським",
        "antarktiskeho",
        "Sachalino",
        "Samaros",
        "східноєвропейським",
        "Hawai’i",
        "’s",
        "Aleutach",
        "Innseachan",
        "центральноєвропейським",
        "Кубі",
        "Taipėjaus",
        "لودیځې",
        "Tongos",
        "колумбійським",
        "Turkmėnistano",
        "Urugvajaus",
        "Uzbekistano",
        "Jekaterynburg",
        "Vladivostoko",
        "Jakuck",
        "Wołgograd",
        "Władywostok",
        "Urugwaj",
        "Jekaterinburgo",
        "Sejšelsko",
        "Singapursko",
        "Filipiny",
        "Paragwaj",
        "Nowosybirsk",
        "Ioràin",
        "Rietumāfrikas",
        "Seapaine",
        "Aļaskas",
        "Amazones",
        "Centrālais",
        "Austrumu",
        "Kalnu",
        "Gambierski",
        "Casachstàin",
        "Anadiras",
        "Apijas",
        "Arābijas",
        "pussalas",
        "Argentīnas",
        "Rietumargentīnas",
        "Armēnijas",
        "Atlantijas",
        "Choirèa",
        "Georgiski",
        "китайським",
        "austrumu",
        "Azerbaidžānas",
        "Azoru",
        "Macàthu",
        "Bangladešas",
        "Fundlandia",
        "Eilein",
        "Brazīlijas",
        "moskiewski",
        "Ułan",
        "Kaboverdes",
        "Północno",
        "Četemas",
        "Čīles",
        "Ķīnas",
        "Čoibalsanas",
        "чилійським",
        "Gilbertowych",
        "Greenwichski",
        "Kazachstan",
        "Kolumbijas",
        "бразильським",
        "Kubas",
        "Kamczacki",
        "Pietropawłowsk",
        "Japonia",
        "Lieldienu",
        "Izrael",
        "Centrāleiropas",
        "Pódzajtšnogrönlandski",
        "Mhoiriseas",
        "Pódwjacornogrönlandski",
        "Kobdo",
        "Rietumeiropas",
        "Folklenda",
        "(Malvinu)",
        "Aleuty",
        "Hawaje",
        "Solomonskim",
        "thuath",
        "Mhosgo",
        "Gruzja",
        "Азорських",
        "Fidżi",
        "Falklandy",
        "Gruzijas",
        "Азербайджані",
        "zachodnioeuropejski",
        "Austrumgrenlandes",
        "Rietumgrenlandes",
        "західноавстралійським",
        "środkowoeuropejski",
        "Ekwador",
        "Havaju–Aleutu",
        "Honkongas",
        "Hovdas",
        "Indijas",
        "Wielkanocna",
        "Wschodni",
        "Cooka",
        "Kolumbia",
        "Irānas",
        "Irkutskas",
        "Izraēlas",
        "Japānas",
        "Petropavlovskas",
        "Kamčatskas",
        "Chailleann",
        "Czojbalsan",
        "Korejas",
        "Krasnojarskas",
        "Chiny",
        "Przylądka",
        "Lorda",
        "Hava",
        "Zielonego",
        "Magadanas",
        "golfa",
        "Bangladesz",
        "Azory",
        "Azerbejdżan",
        "Maurīcijas",
        "wschodnioaustralijski",
        "Ziemeļrietumu",
        "Shealainn",
        "piekrastes",
        "Ulanbatoras",
        "Maskavas",
        "Talamh",
        "środkowo",
        "Jaunkaledonijas",
        "Jaunzēlandes",
        "Ņūfaundlendas",
        "środkowoaustralijski",
        "Èisg",
        "Noroņas",
        "Novosibirskas",
        "Omskas",
        "Pakistānas",
        "atlantycki",
        "Paragvajas",
        "Filipīnu",
        "східноавстралійським",
        "Senpjēras",
        "Mikelonas",
        "Pagastàin",
        "австралійським",
        "Półwysep",
        "центральнозахідним",
        "wschodnioamerykański",
        "Sahalīnas",
        "Samaras",
        "środkowoamerykański",
        "amazoński",
        "центральноавстралійським",
        "Paraguaidh",
        "Pearù",
        "Filipineach",
        "атлантичним",
        "Taibei",
        "вірменським",
        "Tongas",
        "zachodnioafrykański",
        "Turkmenistānas",
        "Urugvajas",
        "Uzbekistānas",
        "Qızılorda",
        "Vladivostokas",
        "Volgogradas",
        "Samotha",
        "західноаргентинським",
        "ਫੁਟੂਨਾ",
        "ਵਾਲਿਸ",
        "Jakutskas",
        "Jekaterinburgas",
        "аргентинським",
        "арабським",
        "Апіа",
        "Južnodžordžijsko",
        "гірським",
        "Hawaiisko",
        "Turcmanastàin",
        "ਵੇਕ",
        "ਵੋਸਟੋਕ",
        "центральним",
        "Uruguaidh",
        "Usbagastàn",
        "Surinamsko",
        "Амазонці",
        "Анадирско",
        "Tahićansko",
        "ਵੈਨੇਜ਼ੂਏਲਾ",
        "瓦努阿圖夏令時間",
        "западна",
        "Ерменија",
        "Tadžikistansko",
        "毛里裘斯夏令時間",
        "Čučko",
        "Кубы",
        "Азорес",
        "montaña",
        "árabe",
        "Зелениот",
        "‘Рт",
        "Четем",
        "ਟੁਵਾਲੂ",
        "Tuvaluansko",
        "Acerbaixán",
        "ਚੂਕ",
        "瓦努阿圖標準時間",
        "Велигденскиот",
        "Средноевропско",
        "Алясці",
        "Фолкландските",
        "ਟੋਕੇਲਾਉ",
        "Irański",
        "ਤਾਜਿਕਿਸਤਾਨ",
        "毛里裘斯標準時間",
        "Israelski",
        "Хаваи",
        "Алеутски",
        "ਤਾਹੀਤੀ",
        "ਸਿਓਵਾ",
        "Japański",
        "Израел",
        "Јапонија",
        "Кореја",
        "ਸੂਰੀਨਾਮ",
        "伊爾庫次克夏令時間",
        "ਸੋਲੋਮਨ",
        "ਸਿੰਗਾਪੁਰ",
        "ਸੇਸ਼ਲਸ",
        "західноафриканським",
        "Маврициус",
        "伊爾庫次克標準時間",
        "Нов",
        "Fidxi",
        "Микелан",
        "ਰੋਥੇਰਾ",
        "ਰਿਯੂਨੀਅਨ",
        "Сакалин",
        "Güiana",
        "ਪਯੋਂਗਯਾਂਗ",
        "ਪੋਨਾਪੇ",
        "എയ്ക്കർ",
        "馬克薩斯時間",
        "Venecuelansko",
        "পালাউ",
        "ਪਿਟਕੈਰਨ",
        "മധ്യ",
        "পাপুয়া",
        "Hawai",
        "Petropávlovsk",
        "Casaquistán",
        "Linijowych",
        "Magadański",
        "গিনি",
        "mexicano",
        "അൽമതി",
        "ആമസോൺ",
        "Moscova",
        "馬爾代夫時間",
        "麥夸里群島時間",
        "മൗണ്ടൻ",
        "Акрі",
        "അനാഡിർ",
        "അപിയ",
        "അഖ്തൌ",
        "അഖ്തോബ്",
        "അറേബ്യൻ",
        "Turcomenistán",
        "അർമേനിയ",
        "അറ്റ്‌ലാന്റിക്",
        "Usbequistán",
        "Marshallowych",
        "അസർബൈജാൻ",
        "അസോർസ്",
        "ബംഗ്ലാദേശ്",
        "ਫਿਨਿਕਸ",
        "Mauriciski",
        "ബ്രസീലിയ",
        "科斯雷時間",
        "dłujkowjacorny",
        "കേപ്",
        "വെർദെ",
        "ਗਿਨੀ",
        "ചാത്തം",
        "ചിലി",
        "ചൈന",
        "ਪਾਪੂਆ",
        "ਪਲਾਉ",
        "ফোনিক্স",
        "Пасхі",
        "印度支那時間",
        "伊爾庫茨克夏令時間",
        "കൊളംബിയ",
        "കുക്ക്",
        "Nepalski",
        "ക്യൂബ",
        "ਮਰਿਆਨਾ",
        "Nowokaledoniski",
        "Nowoseelandski",
        "Nowofundlandski",
        "印度標準時間",
        "ഈസ്റ്റർ",
        "ਨੋਰਫੌਕ",
        "એકર",
        "ഗ്രീഷ്മകാല",
        "ਨੀਊ",
        "ഫാക്ക്‌ലാൻഡ്",
        "ഫിജി",
        "ഫ്രഞ്ച്",
        "ഗയാന",
        "Цэнтральнаеўрапейскі",
        "Усходнееўрапейскі",
        "Pakistański",
        "পিটকেয়ার্ন",
        "ജോർജ്ജിയ",
        "ਨੇਪਾਲ",
        "ਨਾਉਰੂ",
        "ਮਿਆਂਮਾਰ",
        "পোনাপে",
        "পিয়ংইয়াং",
        "Peruski",
        "ഹവായ്",
        "അലൂഷ്യൻ",
        "ഹോങ്കോങ്ങ്",
        "ഹോഡ്",
        "ഇന്ത്യൻ",
        "દક્ષિણ",
        "রিইউনিয়ন",
        "Phoenixowych",
        "ഇറാൻ",
        "અલાસ્કા",
        "রথেরা",
        "ഇസ്രായേൽ",
        "ജപ്പാൻ",
        "പെട്രോപാവ്‌ലോസ്ക്",
        "കംചാസ്കി",
        "കസാഖിസ്ഥാൻ",
        "കൊറിയൻ",
        "ਮੌਸਨ",
        "ക്രാസ്‌നോയാർസ്‌ക്",
        "અલ્માટી",
        "ਮਾਰਸ਼ਲ",
        "ਮਾਰਕਿਸਾਸ",
        "ലോർഡ്",
        "ഹോവ്",
        "മകൌ",
        "ਮਾਲਦੀਵ",
        "മഗാദൻ",
        "ਮਲੇਸ਼ੀਆ",
        "એમેઝોન",
        "ਮੈਕਕਵੇਰੀ",
        "Miqueloński",
        "മൗറീഷ്യസ്",
        "Pitcairnowych",
        "വടക്കുപടിഞ്ഞാറൻ",
        "മെക്‌സിക്കൻ",
        "മെക്സിക്കൻ",
        "ഉലൻ",
        "ബറ്റർ",
        "يېكاتېرىنبۇرگ",
        "ਲਾਈਨ",
        "ਲੰਕਾ",
        "ਕਿਰਗਿਸਤਾਨ",
        "Ponapski",
        "માઉન્ટન",
        "കാലിഡോണിയ",
        "ന്യൂസിലാൻഡ്",
        "ന്യൂഫൗണ്ട്‌ലാന്റ്",
        "ਕੋਸਰੇ",
        "Reunionski",
        "ഫെർണാഡോ",
        "നൊറോൻഹ",
        "અનાડિર",
        "ياكۇتسك",
        "ۋولگاگراد",
        "અક્તાઉ",
        "ഓംസ്‌ക്ക്",
        "പാക്കിസ്ഥാൻ",
        "ઍક્ટોબ",
        "અરેબિયન",
        "ਇੰਡੋਨੇਸ਼ੀਆਈ",
        "പരാഗ്വേ",
        "പെറു",
        "ഫിലിപ്പൈൻ",
        "ਇੰਡੋਚਾਈਨਾ",
        "സെന്റ്",
        "പിയറി",
        "ആൻഡ്",
        "മിക്വലൻ",
        "ਮਹਾਂਸਾਗਰ",
        "ਹਿੰਦ",
        "ਭਾਰਤੀ",
        "ഖിസിലോർഡ",
        "Sachalinski",
        "ۋىلادىۋوستوك",
        "സഖാലിൻ",
        "സമാറ",
        "Samoaski",
        "આર્મેનિયા",
        "અટલાન્ટિક",
        "ਗੁਯਾਨਾ",
        "ਖਾੜੀ",
        "ਗੁਆਮ",
        "伊爾庫茨克標準時間",
        "ਮੀਨ",
        "ਗ੍ਰੀਨਵਿਚ",
        "ਗਿਲਬਰਟ",
        "തായ്‌പെയ്",
        "Заходнееўрапейскі",
        "ਗੈਂਬੀਅਰ",
        "ടോംഗ",
        "ਗਲਾਪਾਗੋਸ",
        "തുർക്ക്‌മെനിസ്ഥാൻ",
        "ਐਂਟਾਰਟਿਕ",
        "ഉറുഗ്വേ",
        "ഉസ്‌ബെക്കിസ്ഥാൻ",
        "വന്വാതു",
        "અઝરબૈજાન",
        "വ്ലാഡിവോസ്റ്റോക്",
        "വോൾഗോഗ്രാഡ്",
        "ਗੁਏਨਾ",
        "એઝોર્સ",
        "બાંગ્લાદેશ",
        "ۋانۇئاتۇ",
        "ئۆزبېكىستان",
        "യാകസ്‌ക്ക്",
        "യെക്കാറ്റരിൻബർഗ്",
        "ਹੋਰ",
        "બ્રાઝિલિયા",
        "ئۇرۇگۋاي",
        "تۈركمەنىستان",
        "કૅપ",
        "વર્ડે",
        "تەيبېي",
        "ચેતહામ",
        "ચિલી",
        "Аляскийн",
        "Амазоны",
        "ચીન",
        "Уулын",
        "Номхон",
        "ચોઇબાલ્સન",
        "Апиагийн",
        "Арабын",
        "ساخارىن",
        "Арменийн",
        "Атлантын",
        "Фалклендскіх",
        "төв",
        "قىزىلئوردا",
        "зүүн",
        "Азербайжаны",
        "Азорын",
        "Бангладешийн",
        "કોલંબિયા",
        "કુક",
        "Бразилийн",
        "ਇਕਵੇਡੋਰ",
        "Кэйп",
        "Вердийн",
        "সেশেলস",
        "Чатемын",
        "Чилийн",
        "مىكېلون",
        "Чойбалсангийн",
        "ક્યૂબા",
        "Колумбын",
        "Күүк",
        "ਤਿਮੂਰ",
        "Кубын",
        "ਡਿਉਰਵਿਲੇ",
        "ਡਿਉਮੋਂਟ",
        "ਡੇਵਿਸ",
        "Исландын",
        "پىئېر",
        "ساينىت",
        "ਅੱਧ",
        "فىلىپپىن",
        "Фолькландын",
        "Арлуудын",
        "Фижигийн",
        "Францын",
        "پېرۇ",
        "ઇસ્ટર",
        "ਕੋਕਸ",
        "پاراگۋاي",
        "ਕ੍ਰਿਸਮਸ",
        "Гүржийн",
        "Surinamski",
        "યુરોપીયન",
        "پاكىستان",
        "ئومسك",
        "نوۋوسىبىرسك",
        "ਚਾਮੋਰੋ",
        "Хавай",
        "Алеутын",
        "Конгийн",
        "Ховдын",
        "Энэтхэгийн",
        "ਕੇਸੀ",
        "ફિજી",
        "ફ્રેન્ચ",
        "Ираны",
        "Эрхүүгийн",
        "Израилийн",
        "Японы",
        "Казахстаны",
        "Солонгосын",
        "ગયાના",
        "Красноярскийн",
        "ਦਾਰੂਸਲਾਮ",
        "Хоугийн",
        "ਬਰੂਨੇਈ",
        "Магаданы",
        "نورونخا",
        "ਬੋਲੀਵੀਆ",
        "ਭੂਟਾਨ",
        "અને",
        "Мавритусын",
        "فېرناندو",
        "хойд",
        "نىۋفوئۇنلاند",
        "номхон",
        "Улаанбаатарын",
        "Москвагийн",
        "زېلاندىيە",
        "જ્યોર્જિયા",
        "كالېدونىيە",
        "সিঙ্গাপুর",
        "Каледонийн",
        "Зеландын",
        "Нью",
        "Фаундлендын",
        "موسكۋا",
        "Норонагийн",
        "Новосибирскийн",
        "Омскийн",
        "Пакистаны",
        "Tchajpejski",
        "ئۇلانباتور",
        "Парагвайн",
        "Перугийн",
        "সলোমন",
        "હવાઇ",
        "Микелоны",
        "એલ્યુશિઅન",
        "હોંગ",
        "કોંગ",
        "હોવ્ડ",
        "ભારતીય",
        "Сахалины",
        "Самоагийн",
        "蓋亞那時間",
        "ماۋرىتىئۇس",
        "Tongaski",
        "ઈરાન",
        "ઇર્કુત્સ્ક",
        "ઇઝરાઇલ",
        "જાપાન",
        "પેટ્રોપેવલોવ્સ્ક",
        "Тайпейн",
        "કામચતસ્કી",
        "કઝાકિસ્તાન",
        "Тонгагийн",
        "કોરિયન",
        "Туркменистаны",
        "ئاۋمېن",
        "Уругвайн",
        "Узбекистаны",
        "Вануатугийн",
        "ਅਫ਼ਰੀਕਾ",
        "Владивостокийн",
        "Волгоградын",
        "خاي",
        "Вейк",
        "كىراسنويارسك",
        "Якутын",
        "Екатеринбургийн",
        "كورىيە",
        "قازاقىستان",
        "كامچاتكسكى",
        "લોર્ડ",
        "હોવ",
        "મકાઉ",
        "پېتروپاۋلوۋسك",
        "મગાડન",
        "ياپونىيە",
        "ئىسرائىلىيە",
        "अल्माटी",
        "अ‍ॅमेझॉन",
        "ئىركۇتسك",
        "पर्वतीय",
        "ئىران",
        "एपिया",
        "अ‍ॅक्ताउ",
        "अ‍ॅक्टोबे",
        "अरेबियन",
        "મોરિશિયસ",
        "波斯灣海域標準時間",
        "अझरबैजान",
        "अ‍ॅझोरेस",
        "ਅਫ਼ਗਾਨਿਸਤਾਨ",
        "ઉત્તરપશ્ચિમ",
        "ब्राझिलिया",
        "рӕстӕмбис",
        "व्हर्डे",
        "चॅथम",
        "चीनी",
        "चोईबाल्सन",
        "Гринвичы",
        "મેક્સિકો",
        "મેક્સીકન",
        "ઉલાન",
        "ھىندى",
        "خوۋد",
        "મોસ્કો",
        "شياڭگاڭ",
        "ئالېيۇت",
        "ھاۋاي",
        "Turkmeniski",
        "फॉकलंड",
        "સેલેડોનિયા",
        "फ्रेंच",
        "गयाना",
        "ન્યુઝીલેન્ડ",
        "ન્યૂફાઉન્ડલેન્ડ",
        "সুরিনাম",
        "ସମୟ",
        "austeuropeisk",
        "گىرۇزىيە",
        "Uruguayski",
        "अलूशन",
        "होव्ह्ड",
        "austaustralsk",
        "گىۋىيانا",
        "इराण",
        "इर्कुत्सक",
        "इस्रायल",
        "पेट्रोपाव्हलोस्क",
        "कामचाट्स्की",
        "कझाकस्तान",
        "कोरियन",
        "قاراشلىق",
        "فىرانسىيەگە",
        "فىجى",
        "मकाऊ",
        "فالكلاند",
        "मॅगाडन",
        "Uzbekiski",
        "નોવસિબિર્સ્ક",
        "वायव्य",
        "كۇبا",
        "Vanuatski",
        "પાકિસ્તાન",
        "कॅलेडोनिया",
        "न्यूझीलंड",
        "फाउंडलंड",
        "كۇك",
        "फर्नांडो",
        "दी",
        "كولومبىيە",
        "पॅराग्वे",
        "फिलिपाइन",
        "Venezuelski",
        "पियर",
        "मिक्वेलोन",
        "પેરાગ્વે",
        "પેરુ",
        "क़िझीलोर्डा",
        "ફિલિપાઇન",
        "सखलिन",
        "सामरा",
        "सामोआ",
        "چويبالسان",
        "સેંટ",
        "પીએરે",
        "तैपेई",
        "उझबेकिस्तान",
        "वानुआतु",
        "એન્ડ",
        "व्होल्गोग्राड",
        "મિકીલોન",
        "جۇڭگو",
        "چىلى",
        "याकुत्सक",
        "येकतरिनबर्ग",
        "چاتام",
        "કિઝિલોર્ડા",
        "تۇمشۇق",
        "يېشىل",
        "સખાલિન",
        "સમારા",
        "સમોઆ",
        "بىرازىلىيە",
        "باڭلادىش",
        "ئازور",
        "ئەزەربەيجان",
        "সায়োওয়া",
        "ئاتلانتىك",
        "ئەرمېنىيە",
        "તાઇપેઇ",
        "Ħin",
        "Ċentrali",
        "Ewropew",
        "তাহিতি",
        "ئەرەب",
        "ટોંગા",
        "ئاقتۆبە",
        "તુર્કમેનિસ્તાન",
        "ئاقتاي",
        "ઉરૂગ્વે",
        "အနောက်အာဖရိက",
        "ઉઝ્બેકિસ્તાન",
        "વનાતૂ",
        "ئانادىر",
        "વ્લાડિવોસ્ટોક",
        "વોલ્ગોગ્રેડ",
        "အာမေဇုံ",
        "နွေရာသီအချိန်",
        "Фіджы",
        "তাজাখাস্তান",
        "تاغ",
        "টোকেলাউ",
        "အရှေ့ပိုင်း",
        "યાકુત્સ્ક",
        "યેકાટેરિનબર્ગ",
        "中南半島時間",
        "ئامازون",
        "চুক",
        "印度時間",
        "အာရေဗျ",
        "টুভালু",
        "דרום",
        "အာမေးနီးယား",
        "အတ္တလန်တစ်",
        "圭亞那時間",
        "အလယ်အနောက်ပိုင်း",
        "အရှေ့ဩစတြေးလျား",
        "喬治亞夏令時間",
        "အဇာဘိုင်ဂျန်",
        "အေဇိုးရီးစ်",
        "ဘင်္ဂလားဒေ့ရှ်",
        "喬治亞標準時間",
        "אלסקה",
        "ဘရာဇီး",
        "אמזונס",
        "ကိတ်ပ်",
        "ဗာဒီ",
        "波斯灣海域時間",
        "החוף",
        "המזרחי",
        "ချီလီ",
        "တရုတ်",
        "אזור",
        "ההרים",
        "בארה״ב",
        "加拉巴哥群島時間",
        "ကိုလံဘီယာ",
        "אנדיר",
        "圖瓦盧時間",
        "ကျူးဘား",
        "אפיה",
        "חצי",
        "ভেনেজুয়েলা",
        "ערב",
        "格魯吉亞夏令時間",
        "ארמניה",
        "格魯吉亞標準時間",
        "ဥရောပ",
        "အရှေ့ဥရောပ",
        "האטלנטי",
        "အနောက်ဥရောပ",
        "ဖော့ကလန်ကျွန်းစု",
        "ভসটক",
        "אזרבייג׳אן",
        "ওয়েক",
        "האזוריים",
        "בנגלדש",
        "ئالمۇتا",
        "ئالياسكا",
        "ברזיליה",
        "ဂျော်ဂျီယာ",
        "ওয়ালিস",
        "ফুটুনা",
        "כף",
        "ורדה",
        "加拉帕戈群島時間",
        "צ׳טהאם",
        "צ׳ילה",
        "厄瓜多爾時間",
        "ဟာဝိုင်ယီ",
        "အယ်လူးရှန်း",
        "ဟောင်ကောင်",
        "צ׳ויבלסן",
        "迪蒙迪維爾時間",
        "အိန္ဒိယ",
        "Грузінскі",
        "वालिस्",
        "Гілберта",
        "भास्टोक",
        "အီရန်",
        "קולומביה",
        "קוק",
        "အစ္စရေး",
        "ဂျပန်",
        "भेनेज्युएला",
        "厄瓜多時間",
        "ကိုရီးယား",
        "可可斯群島時間",
        "उरूग्वे",
        "टुभालु",
        "चुउक",
        "तोकेलाउ",
        "ताजिकस्तान",
        "קובה",
        "सुरिनामा",
        "မကာအို",
        "सोलोमोन",
        "सेयेचेलास्",
        "佛得角夏令時間",
        "Showa",
        "प्योङयाङ",
        "पिटकैरण",
        "Seyşeller",
        "မောရစ်ရှ်",
        "Усходняй",
        "အနောက်တောင်",
        "杜蒙杜比爾時間",
        "फिनिक्स",
        "הפסחא",
        "የታይፔይ",
        "पपूवा",
        "पालाउ",
        "佛得角標準時間",
        "Gine",
        "פוקלנד",
        "नोर्फल्क",
        "निउए",
        "न्यूफाउनल्यान्डको",
        "न्यूफाउनडल्यान्डको",
        "နယူးဇီလန်",
        "နယူးဖောင်လန်",
        "פיג׳י",
        "גיאנה",
        "亞塞拜疆夏令時間",
        "नेपाली",
        "नाउरु",
        "亞塞拜疆標準時間",
        "Menezioù",
        "म्वसन",
        "ပါကစ္စတန်",
        "Anadyrʼ",
        "मार्किसस",
        "ပါရာဂွေး",
        "ပီရူး",
        "ဖိလစ်ပိုင်",
        "माल्दिभ्स",
        "मलेसिया",
        "माक्वेरी",
        "Гавайска",
        "Maldivler",
        "किर्गिस्तान",
        "Malezya",
        "Kırgızistan",
        "גאורגיה",
        "科科斯群島時間",
        "Алеуцкі",
        "Ганконга",
        "Хоўда",
        "इन्डोचाइना",
        "हिन्द",
        "北美太平洋夏令時間",
        "האלאוטיים",
        "הוואי",
        "הונג",
        "मिन",
        "ထိုင်ပေ",
        "קונג",
        "ग्याम्बियर",
        "गालापागोस्",
        "अन्टार्टिक",
        "חובד",
        "တာ့ခ်မင်နစ္စတန်",
        "ग्वाना",
        "ဥရုဂွေး",
        "ဥဇဘက်ကစ္စတန်",
        "הודו",
        "Hindiçin",
        "थप",
        "ဗလာဒီဗော့စတော့ခ်",
        "Azerbaidjan",
        "ईक्वोडोर",
        "איראן",
        "टिमोर",
        "उर्भिले",
        "डी‘",
        "डुमोन्ट",
        "डेभिस",
        "Okyanusu",
        "आधा",
        "Hint",
        "ישראל",
        "østafrikansk",
        "יפן",
        "פטרופבלובסק",
        "קמצ׳טסקי",
        "קזחסטן",
        "קוריאה",
        "Körfez",
        "קרסנויארסק",
        "Azorez",
        "Ortalama",
        "הלורד",
        "האו",
        "北美太平洋標準時間",
        "מקאו",
        "北美山區夏令時間",
        "מגדן",
        "Guyanası",
        "Daha",
        "北美山區標準時間",
        "Cʼhab",
        "מאוריציוס",
        "Glas",
        "צפון",
        "維德角夏令時間",
        "אולן",
        "בטור",
        "מוסקבה",
        "Yarı",
        "維德角標準時間",
        "北美東部夏令時間",
        "קלדוניה",
        "北美東部標準時間",
        "ניו",
        "Christmasøya",
        "Kokosøyene",
        "זילנד",
        "ניופאונדלנד",
        "halv",
        "Selam",
        "østtimoresisk",
        "Darü’s",
        "פרננדו",
        "די",
        "נורוניה",
        "fjern",
        "נובוסיבירסק",
        "אומסק",
        "פקיסטן",
        "franske",
        "sørterritorier",
        "Galápagosøyene",
        "Bolivya",
        "Gilbertøyene",
        "北美中部標準時間",
        "Іранскі",
        "פרגוואי",
        "Persiabukta",
        "guyansk",
        "פרו",
        "הפיליפינים",
        "Іркуцкі",
        "Indiahavet",
        "סנט",
        "sentralindonesisk",
        "østindonesisk",
        "vestindonesisk",
        "פייר",
        "ומיקלון",
        "Pask",
        "Kreizeuropa",
        "Ізраільскі",
        "østkasakhstansk",
        "vestkasakhstansk",
        "Gwiana",
        "kirgisisk",
        "Linjeøyene",
        "亞塞拜然夏令時間",
        "Macquarieøya",
        "סחלין",
        "maldivisk",
        "Marquesasøyene",
        "Marshalløyene",
        "סמרה",
        "סמואה",
        "亞塞拜然標準時間",
        "myanmarsk",
        "naurisk",
        "nepalsk",
        "Японіі",
        "南非時間",
        "Norfolkøya",
        "Marianene",
        "d’Irán",
        "palauisk",
        "papuansk",
        "Карэі",
        "Oseaan",
        "Краснаярскі",
        "Phoenixøyene",
        "טאיפיי",
        "Jorjia",
        "seychellisk",
        "singaporsk",
        "Salomonøyene",
        "Sør",
        "surinamsk",
        "tahitisk",
        "tadsjikisk",
        "ʻiekatelinepūki",
        "Chuukøyene",
        "טונגה",
        "tuvalsk",
        "ʻiākutisiki",
        "טורקמניסטן",
        "volikokalati",
        "אורוגוואי",
        "Futunaøyene",
        "अफगानिस्तान",
        "אוזבקיסטן",
        "ונואטו",
        "valativositoki",
        "ולדיווסטוק",
        "וולגוגרד",
        "fakavanuatu",
        "fakaʻusipekitani",
        "fakaʻulukuai",
        "fakatūkimenisitani",
        "יקוטסק",
        "יקטרינבורג",
        "fakatonga",
        "irlandesa",
        "የቶንጋ",
        "湯加夏令時間",
        "भुटानी",
        "बोलिभिया",
        "ब्राजिलिया",
        "ब्राजिलीया",
        "ब्रुनाइ",
        "Kazakstán",
        "चामोर्रो",
        "Norfolkeilande",
        "अलास्‍का",
        "क्रिस्मस",
        "Магаданскі",
        "अमेज़न",
        "एपिआ",
        "ရယ်ခါးတီရင်ဘာခ်နွေရာသီအချိန်",
        "ယူခူးတ်စ်နွေရာသီအချိန်",
        "ယူခူးတ်စ်စံတော်ချိန်",
        "ဖူကျူနာ",
        "ဝေါလီစ်နှင့်",
        "ဝိတ်ခ်ကျွန်းအချိန်",
        "ဗိုစ်တိုခ်အချိန်",
        "ဗိုလ်ဂိုဂရက်နွေရာသီအချိန်",
        "ဗိုလ်ဂိုဂရက်စံတော်ချိန်",
        "ဗင်နီဇွဲလား",
        "ဗနွားတူနွေရာသီအချိန်",
        "ဗနွားတူစံတော်ချိန်",
        "တူဗားလူအချိန်",
        "ချုခ်အချိန်",
        "တွန်ဂါနွေရာသီအချိန်",
        "တွန်ဂါစံတော်ချိန်",
        "တိုကီလာဥ",
        "တာဂျစ်ကစ္စတန်",
        "တဟီတီအချိန်",
        "ရှိုဝါအချိန်",
        "စူးရီနာမ်အချိန်",
        "တောင်ဂျော်ဂျီယာ",
        "ဆော်လမွန်ကျွန်းစုအချိန်",
        "စင်္ကာပူ",
        "ဆေးရှဲ",
        "ဆမိုးအား",
        "ဆမိုးအားစံတော်ချိန်",
        "ဆာခါလင်",
        "ဆာခါလင်စံတော်ချိန်",
        "ရိုသီရာအချိန်",
        "ဟေညွန်",
        "ပြုံယန်း",
        "ဖိုနာဖဲအ်အချိန်",
        "ပါတ်ကယ်ရင်",
        "မီခွီလွန်",
        "စိန့်ပီအဲနှင့်",
        "စိန့်ပီအဲနှင့်မီခွီလွန်စံတော်ချိန်",
        "ဖီးနစ်ကျွန်းစုအချိန်",
        "ပါပူအာနယူးဂီနီ",
        "ပလာအို",
        "အွမ်းစ်ခ်နွေရာသီအချိန်",
        "အွမ်းစ်ခ်စံတော်ချိန်",
        "နိုဗိုစဲဘီအဲယ်စ်နွေရာသီအချိန်",
        "နိုဗိုစဲဘီအဲယ်စ်စံတော်ချိန်",
        "ဖာနန်ဒိုးဒီနိုးရိုးညာနွေရာသီအချိန်",
        "ဖာနန်ဒိုးဒီနိုးရိုးညာစံတော်ချိန်",
        "နောဖော့ခ်ကျွန်းအချိန်",
        "နီဦးအေအချိန်",
        "နယူးကာလီဒိုးနီးယားနွေရာသီအချိန်",
        "နယူးကာလီဒိုးနီးယားစံတော်ချိန်",
        "နီပေါ",
        "နာဥူရူအချိန်",
        "မြန်မာ",
        "မော်စကို",
        "မော်စကိုစံတော်ချိန်",
        "ဥလန်ဘာတောနွေရာသီအချိန်",
        "ဥလန်ဘာတောစံတော်ချိန်",
        "မက္ကစီကန်",
        "မက္ကဆီကန်",
        "မက္ကစီကို",
        "မက္ကဆီကို",
        "မော်စွန်အချိန်",
        "မာရှယ်ကျွန်းစုအချိန်",
        "မာခေးအပ်စ်အချိန်",
        "မော်လဒိုက်",
        "မလေးရှား",
        "မာဂါဒန်းနွေရာသီအချိန်",
        "မာဂါဒန်းစံတော်ချိန်",
        "မက်ကွယ်ရီကျွန်း",
        "လော့ဒ်ဟောင်",
        "လော့ဒ်ဟောင်စံတော်ချိန်",
        "လိုင်းကျွန်းစုအချိန်",
        "သီရိလင်္ကာ",
        "ကာဂျစ္စတန်",
        "ခရာ့စ်နိုရာစ်နွေရာသီအချိန်",
        "ခရာ့စ်နိုရာစ်စံတော်ချိန်",
        "ခိုစ်ရိုင်အချိန်",
        "အနောက်ကာဇက်စတန်အချိန်",
        "အရှေ့ကာဇက်စတန်",
        "အီရူခူတ်နွေရာသီအချိန်",
        "အီရူခူတ်စံတော်ချိန်",
        "အနောက်ပိုင်း",
        "အင်ဒိုချိုင်းနား",
        "သမုဒ္ဒရာ",
        "ဟိုးဗ်နွေရာသီအချိန်",
        "ဟိုးဗ်စံတော်ချိန်",
        "ဂိုင်ယာနာ",
        "ပင်လယ်ကွေ့",
        "ဂရင်းလန်",
        "ဂရင်းလန်း",
        "အရှေ့ဂရင်းလန်",
        "အရှေ့ဂရင်းလန်း",
        "ဂရင်းနစ်",
        "ဂီလ်ဘတ်ကျွန်းစုအချိန်",
        "ဂမ်ဘီယာ",
        "ဂါလားပါဂိုးစ်အချိန်",
        "အန္တာတိတ်အချိန်",
        "ပြင်သစ်တောင်ပိုင်းနှင့်",
        "ဂီအားနား",
        "ပြင်သစ်",
        "ဖီဂျီနွေရာသီအချိန်",
        "ဖီဂျီစံတော်ချိန်",
        "ထပ်တိုးအရှေ့ပိုင်းဥရောပအချိန်",
        "အီကွေဒေါ",
        "အီစတာကျွန်းနွေရာသီအချိန်",
        "အီစတာကျွန်းစံတော်ချိန်",
        "အရှေ့တီမော",
        "ဒါရ်ဗီးလ်အချိန်",
        "ဒူးမော့တ်",
        "ဒေးဗစ်အချိန်",
        "ကွတ်က်ကျွန်းစုနွေရာသီအချိန်",
        "ကွတ်ခ်ကျွန်းစုစံတော်ချိန်",
        "ကိုကိုးကျွန်းအချိန်",
        "ခရစ်စမတ်ကျွန်းအချိန်",
        "ချွဲဘော်ဆန်းနွေရာသီအချိန်",
        "ချွဲဘော်ဆန်းစံတော်ချိန်",
        "ချာသမ်",
        "ချားသမ်စံတော်ချိန်",
        "ချာမိုရိုအချိန်",
        "ဘရူနိုင်း",
        "ဘိုလီးဘီးယား",
        "ဘူတန်",
        "အနောက်ဩစတြေးလျား",
        "အပီယာ",
        "အပီယာစံတော်ချိန်",
        "ပစိဖိတ်စံတော်ချိန်",
        "တောင်တန်းဒေသ",
        "တောင်တန်းဒေသစံတော်ချိန်",
        "အရှေ့ပိုင်းစံတော်ချိန်",
        "အလယ်ပိုင်းစံတော်ချိန်",
        "အလားစကား",
        "အလားစကားစံတော်ချိန်",
        "တောင်အာဖရိက",
        "အရှေ့အာဖရိက",
        "အလယ်အာဖရိက",
        "အာဖဂန်နစ္စတန်",
        "Sajf",
        "tas",
        "वॉलिस",
        "व्होस्टॉक",
        "व्हेनेझुएला",
        "सुरिनाम",
        "सोलोमॉन",
        "सिंगापूर",
        "प्योंगयंँग",
        "पोनॅपे",
        "‍फोनिक्स",
        "मरिना",
        "न्युए",
        "नेपाळ",
        "नउरु",
        "मार्क्वेसास",
        "मालदिव",
        "मॅक्वेरी",
        "किरगिस्तान",
        "क्रास्नोयार्क्स",
        "कोस्राई",
        "SRT",
        "austafrikansk",
        "इंडोचायना",
        "हिंदमहासागर",
        "ग्रीनिच",
        "greenwich",
        "ଖ୍ରୀଷ୍ଟମାସ",
        "ଆଇଲ୍ୟାଣ୍ଡ",
        "गॅम्बियर",
        "ଗାଲାପାଗୋସ୍",
        "गॅलापागोस",
        "अंटार्क्टिक",
        "यूरोपीयन",
        "इक्वेडोर",
        "ड्युर्विल",
        "ड्युमॉन्ट",
        "डेव्हिस",
        "कॉकोस",
        "ख्रिसमस",
        "दारूसलाम",
        "बोलिव्हिया",
        "भूतान",
        "अफगाणिस्तान",
        "ऐकर",
        "एकर",
        "Футунагийн",
        "Востокийн",
        "Венесуэлийн",
        "Тувалугийн",
        "Чүүкийн",
        "Токелаугийн",
        "Тажикистаны",
        "Таитигийн",
        "Сёвагийн",
        "Суринамын",
        "Жоржийн",
        "арлуудын",
        "Соломоны",
        "Сингапурын",
        "Сейшелийн",
        "Ротерагийн",
        "Реюньоны",
        "Пхеньяны",
        "Понапегийн",
        "Питкернийн",
        "Пьерре",
        "ရယ်ခါးတီရင်ဘားခ်စံတော်ချိန်",
        "Палаугийн",
        "Ниуэгийн",
        "Балбын",
        "Науругийн",
        "Мьянмарын",
        "Моусоны",
        "Маршаллын",
        "Маркесасын",
        "Мальдивийн",
        "Малайзын",
        "Арлын",
        "Кыргызстаны",
        "Косрэгийн",
        "хойгийн",
        "Энэтхэг",
        "Гуяанагийн",
        "Гольфийн",
        "үндсэн",
        "Гринвичийн",
        "Жильбер",
        "Гамбьегийн",
        "Галапагосын",
        "Антарктик",
        "Гияанагийн",
        "дорнод",
        "Алс",
        "Эквадорын",
        "Тиморын",
        "д’Юрвилийн",
        "Дэвисийн",
        "хагас",
        "Чаморрогийн",
        "Дарассаламын",
        "Боливийн",
        "Бутаны",
        "Афганистаны",
        "ഫ്യൂച്യുന",
        "ആന്റ്",
        "വാലിസ്",
        "വേക്ക്",
        "വോസ്റ്റോക്",
        "വെനിസ്വേല",
        "ടുവാലു",
        "ചൂക്ക്",
        "ടോക്കെലൂ",
        "താജിക്കിസ്ഥാൻ",
        "താഹിതി",
        "സയോവ",
        "സുരിനെയിം",
        "ജോർജ്ജിയൻ",
        "ദക്ഷിണ",
        "സോളമൻ",
        "സിംഗപ്പൂർ",
        "സീഷെൽസ്",
        "സമോവാ",
        "സമോവ",
        "റോഥെറ",
        "റീയൂണിയൻ",
        "പ്യോംഗ്‌യാംഗ്",
        "പൊനാപ്പ്",
        "പിറ്റ്കേൻ",
        "ഫിനിക്‌സ്",
        "ഗിനിയ",
        "പാപ്പുവ",
        "പലാവു",
        "നോവോസിബിർസ്‌ക്",
        "നോവോസിബിർസ്ക്",
        "മറിയാനാ",
        "നോർത്ത്",
        "നോർഫാക്ക്",
        "ന്യൂയി",
        "നേപ്പാൾ",
        "നൗറു",
        "മ്യാൻമാർ",
        "മോസ്‌കോ",
        "മോസ്കോ",
        "മാസൺ",
        "മാർഷൽ",
        "മർക്കസസ്",
        "മാലിദ്വീപുകൾ",
        "മലേഷ്യ",
        "മക്വാറി",
        "ലൈൻ",
        "ലങ്ക",
        "കിർഗിസ്ഥാൻ",
        "കൊസ്ര",
        "ഇർകസ്‌ക്",
        "ഇർകസ്ക്",
        "ഇൻഡോചൈന",
        "മഹാസമുദ്ര",
        "ഗൾഫ്",
        "ഗ്വാം",
        "മീൻ",
        "ഗ്രീൻവിച്ച്",
        "ഗിൽബേർട്ട്",
        "ഗാമ്പിയർ",
        "ഗാലപ്പഗോസ്",
        "അന്റാർട്ടിക്",
        "സതേൺ",
        "കിഴക്കേയറ്റത്തെ",
        "ഇക്വഡോർ",
        "തിമോർ",
        "ഉർവില്ലെ",
        "ഡുമോണ്ട്",
        "ഡേവിസ്",
        "അർദ്ധ",
        "കൊക്കോസ്",
        "ക്രിസ്‌മസ്",
        "ചോയിബൽസാൻ",
        "ചോയ്‌ബൽസാൻ",
        "ചമോറോ",
        "ദാറുസ്സലാം",
        "ബ്രൂണൈ",
        "ബൊളീവിയ",
        "ഭൂട്ടാൻ",
        "അലാസ്‌ക",
        "അലാസ്ക",
        "ദക്ഷിണാഫ്രിക്ക",
        "അഫ്‌ഗാനിസ്ഥാൻ",
        "островот",
        "Сајова",
        "Соломонските",
        "Ријунион",
        "Пјонгјанг",
        "Мјанмар",
        "Маршалски",
        "Маркесас",
        "Островот",
        "Линиски",
        "Косра",
        "Индиски",
        "Индија",
        "Мексиканскиот",
        "Гринич",
        "Гамбе",
        "Калининградско",
        "дурвил",
        "Кокосовите",
        "Божиќниот",
        "Дарусалам",
        "Брунеи",
        "Арапско",
        "арапско",
        "Република",
        "Јужноафриканска",
        "Источноафриканско",
        "Средноафриканско",
        "Futunas",
        "Volisas",
        "Veika",
        "Vostokas",
        "Venecuēlas",
        "środkowoafrykański",
        "wschodnioafrykański",
        "południowoafrykański",
        "Čūkas",
        "Tadžikistānas",
        "Šovas",
        "Surinamas",
        "Dienviddžordžijas",
        "Zālamana",
        "Singapūras",
        "Seišeļu",
        "Roteras",
        "Reinjonas",
        "Phenjanas",
        "Ponapē",
        "Pitkērnas",
        "Fēniksa",
        "Jaungvinejas",
        "Norfolkas",
        "Nepālas",
        "Mjanmas",
        "Mosonas",
        "Māršala",
        "Marķīza",
        "Maldīvijas",
        "Boliwia",
        "Malaizijas",
        "Makvorija",
        "Lainas",
        "Czamorro",
        "Kirgizstānas",
        "Rietumkazahstānas",
        "Austrumkazahstānas",
        "Bożego",
        "Narodzenia",
        "Kokosowe",
        "Rietumindonēzijas",
        "Austrumindonēzijas",
        "Centrālindonēzijas",
        "Indoķīnas",
        "Gajānas",
        "līča",
        "Persijas",
        "dalszy",
        "Griničas",
        "Gambjē",
        "Galapagu",
        "teritorijas",
        "Francuskie",
        "Terytoria",
        "Południowe",
        "Antarktyczne",
        "Antarktikas",
        "uniwersalny",
        "Dienvidjūru",
        "Francijas",
        "Zatoka",
        "Perska",
        "Gviānas",
        "Franču",
        "(FET)",
        "indyjski",
        "Indyjski",
        "indochiński",
        "josla",
        "Środkowa",
        "laika",
        "Ekvadoras",
        "Austrumtimoras",
        "Dimondirvilas",
        "Deivisas",
        "(Kīlinga)",
        "Kokosu",
        "Sporady",
        "Środkowopolinezyjskie",
        "Malezja",
        "Malediwy",
        "Markizy",
        "Marshalla",
        "Ziemsvētku",
        "Čamorra",
        "Darusalamas",
        "Brunejas",
        "Mjanma",
        "Bolīvijas",
        "Butānas",
        "Dienvidāfrikas",
        "Gwinea",
        "Austrumāfrikas",
        "Centrālāfrikas",
        "Pjongjang",
        "Seszele",
        "Salomona",
        "Południowa",
        "Afganistānas",
        "Tadżykistan",
        "Futūnos",
        "Wenezuela",
        "Voliso",
        "Veiko",
        "Vostoko",
        "Venesuelos",
        "Čuko",
        "Tadžikistano",
        "معیاري",
        "Tahičio",
        "اورپا",
        "اوړي",
        "گرينويچ",
        "Siovos",
        "Surinamo",
        "Džordžijos",
        "Saliamono",
        "Singapūro",
        "Seišelių",
        "Roteros",
        "Reunjono",
        "Pchenjano",
        "Ponapės",
        "Pitkerno",
        "Fenikso",
        "Gvinėjos",
        "Marianos",
        "Norfolko",
        "Niujė",
        "Nepalo",
        "Mianmaro",
        "Mosono",
        "Maršalo",
        "Markizo",
        "Maldyvų",
        "Malaizijos",
        "Makvorio",
        "Laino",
        "Lankos",
        "Kirgistano",
        "Kosrajė",
        "Indokinijos",
        "Gajanos",
        "įlankos",
        "Persijos",
        "Guamo",
        "Grinvičo",
        "Gambyro",
        "Galapagų",
        "antarktinis",
        "Gvianos",
        "Folklando",
        "Folklandų",
        "Tolimųjų",
        "Ekvadoro",
        "salos",
        "Timoro",
        "d’Urvilio",
        "Diumono",
        "Deiviso",
        "pusės",
        "Kokosų",
        "Kalėdų",
        "Keisio",
        "Darusalamo",
        "Brunėjaus",
        "ACT",
        "AMT",
        "AMST",
        "Bolivijos",
        "Butano",
        "Afganistano",
        "BRT",
        "BRST",
        "Coco",
        "Meio",
        "Antártida",
        "Meridiano",
        "standartinis",
        "ເວລາລະດູຮ້ອນເຢກາເຕລິນເບີກ",
        "ເວລາມາດຕະຖານເຢກາເຕລິນເບີກ",
        "ເວລາລະດູຮ້ອນຢາກູດສ",
        "ເວລາມາດຕະຖານຢາກູດສ",
        "ຟູຕູນາ",
        "Fênix",
        "Cingapura",
        "ເວລາວາລລິສ",
        "ເວລາເກາະເວກ",
        "ວອສໂຕກ",
        "ເວລາລະດູຮ້ອນໂວໂກກຣາດ",
        "ເວລາມາດຕະຖານໂວໂກກຣາດ",
        "ເວລາລະດູຮ້ອນລາດີໂວສຕົກ",
        "ເວລາມາດຕະຖານລາດີໂວສຕົກ",
        "ເວ​ລາ​ເວ​ເນ​ຊູ​ເອ​ລາ",
        "ເວລາລະດູຮ້ອນວານູອາຕູ",
        "ເວລາມາດຕະຖານວານູອາຕູ",
        "ເວລາລະດູຮ້ອນອຸສເບກິດສະຖານ",
        "ເວລາມາດຕະຖານອຸສເບກິດສະຖານ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ອູ​ຣູ​ກວຍ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ອູ​ຣູ​ກວຍ",
        "ເວລາຕູວາລູ",
        "ເວລາລະດູຮ້ອນຕວກເມນິສຖານ",
        "ເວລາມາດຕະຖານຕວກເມນິສຖານ",
        "ເວລາຊຸກ",
        "ເວລາລະດູຮ້ອນຕອງກາ",
        "ເວລາມາດຕະຖານຕອງກາ",
        "ເວລາໂຕເກເລົາ",
        "ເວລາທາຈິກິສຖານ",
        "ເວ​ລາ​ຕອນ​ທ່​ຽງ​ໄທ​ເປ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ໄທ​ເປ",
        "ເວລາທາຮິຕິ",
        "ໂຊວາ",
        "ເວ​ລາ​ຊຸ​ຣິ​ນາມ",
        "ເວລາຈໍເຈຍໃຕ້",
        "ເວລາຫມູ່ເກາະໂຊໂລມອນ",
        "ເວ​ລາ​ສິງ​ກະ​ໂປ",
        "ເວ​ລາ​ເຊ​ເຊ​ລ​ສ໌",
        "ເວລາລະດູຮ້ອນຊາມົວ",
        "ເວລາມາດຕະຖານຊາມົວ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນຊາ​ຮາ​ລິນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານຊາ​ຮາ​ລິນ",
        "ໂຣທີຕາ",
        "ເວ​ລາ​ເຣ​ອູ​ນິ​ຢົງ",
        "ເວລາລະດູຮ້ອນຄີວລໍດາ",
        "ເວລາມາດຕະຖານຄີວລໍດາ",
        "ເວລາປຽງຢາງ",
        "ເວລາໂປເນບ",
        "ເວລາພິດແຄຣ໌ນ",
        "​ເວ​ລາຕອນ​ທ່ຽງເຊນ​ປີ​ແອ",
        "​ເວ​ລາມາດ​ຕະ​ຖານເຊນ​ປີ​ແອ",
        "ເວລາຫມູ່ເກາະຟີນິກ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ຟິ​ລິບ​ປິນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຟິ​ລິບ​ປິນ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ເປ​ຣູ",
        "ເວ​ລາ​​ມາ​ດ​ຕະ​ຖານເປ​ຣູ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ປາ​ຣາ​ກວຍ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ປາ​ຣາ​ກວຍ",
        "ເວລາປາປົວກິນີ",
        "ເວລາປາເລົາ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ປາ​ກີ​ສ​ຖານ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ປາ​ກີສຖານ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນອອມ​ສ​ຄ໌",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອອມ​ສ​ຄ໌",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນໂນ​ໂບ​ຊິ​ບິ​ສ​ຄ໌",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານໂນ​ໂບ​ຊິ​ບິ​ສ​ຄ໌",
        "ເວລາຫມູ່ເກາະມາເຣຍນາເຫນືອ",
        "ເວລາລະດູຮ້ອນເຟນັນໂດເດໂນຮອນຮາ",
        "ເວລາມາດຕະຖານເຟນັນໂດເດໂນຮອນຮາ",
        "ເວ​ລາ​ເກາະ​ນໍ​ຟອ​ລ໌ກ",
        "ເວລານິອູເອ",
        "ເວລາກາງເວັນນິວຟາວແລນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ນິວ​ຟາວ​ແລນ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ນິວ​ຊີ​ແລນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານນິວ​ຊີ​ແລນ",
        "ເວລາລະດູຮ້ອນນິວແຄລິໂດເນຍ",
        "ເວລາມາດຕະຖານນິວແຄລິໂດເນຍ",
        "​ເວ​ລາ​ເນ​ປານ",
        "ເວ​ລາ​ນາ​ອູ​ຣຸ",
        "ເວລາມຽນມາ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນມອ​ສ​ໂຄ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານມອ​ສ​ໂຄ",
        "ເວລາລະດູຮ້ອນອູລານບາເຕີ",
        "ອູລານບາເຕີ",
        "ເວລາກາງເວັນແປຊິຟິກເມັກຊິກັນ",
        "ເວລາມາດຕະຖານແປຊິຟິກເມັກຊິກັນ",
        "ເວລາກາງເວັນເມັກຊິກັນນອດເວສ",
        "​ເວ​ລາ​ມາດ​ຕະ​ຖານນອດ​ເວ​ສ​ເມັກ​ຊິ​ໂກ",
        "ເວລາມໍສັນ",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ເມົາ​ຣິ​ທຽ​ສ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານເມົາ​ຣິ​ທຽ​ສ",
        "ເວ​ລາ​ໝູ່​ເກາະ​ມາ​ແຊວ",
        "ເວລາມາເຄີຊັສ",
        "ເວລາມັນດີຟ",
        "ເວ​ລາ​ມາ​ເລ​ເຊຍ",
        "ເວລາລະດູຮ້ອນເມັກກາເດນ",
        "ເວລາມາດຕະຖານເມັກກາເດນ",
        "ເວ​ລາ​ເກາະ​ແມັກ​ຄົວ​ຣີ",
        "ເວລາລະດູຮ້ອນມາເກົາ",
        "ເວລາມາດຕະຖານມາເກົາ",
        "​ເວ​ລ​ສາ​ຕອນ​​ທ່ຽງ​ລອດ​ເຮົາ​",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ລອດ​ເຮົາ",
        "ເວ​ລາ​ໝູ່​ເກາະ​ລາຍ",
        "ເວລາລັງກາ",
        "ເວລາເຄຍກິສຖານ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນຄຣັສ​ໂນ​ຢາ​ສ​ຄ໌",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານຄຣັສ​ໂນ​ຢາ​ສ​ຄ໌",
        "ເວລາຄອສແຣ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ເກົາ​ຫລີ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ເກົາ​ຫລີ",
        "ເວ​ລາ​ຄາ​ຊັກ​ສ​ຖານ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ຄາ​ຊັກ​ສ​ຖານ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ຍີ່​ປຸ່ນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຍີ່​ປຸ່ນ",
        "ເວລາກາງເວັນອິສຣາເອວ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອິ​ສ​ຣາ​ເອວ",
        "ເວ​ລາລະ​ດູ​ຮ້ອນອີ​ຄຸດ​ສ​ຄ໌",
        "ເວ​ລາມາດ​ຕະ​ຖານອີ​ຄຸດ​ສ​ຄ໌",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ອີ​ຣາ​ນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອີ​ຣານ",
        "ເວ​ລາ​ອິນ​ໂດ​ເນ​ເຊຍ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ອິນ​ໂດ​ເນ​ເຊຍ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ອິນ​ໂດ​ເນ​ເຊຍ​ກາງ",
        "ເວລາອິນດູຈີນ",
        "ເວລາຫມະຫາສະຫມຸດອິນເດຍ",
        "ອິນເດຍ",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ຮອບ​ດ໌",
        "​ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຮອບ​ດ໌",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ຮອງ​ກົງ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຮອງ​ກົງ",
        "ເວລາຕອນທ່ຽງຮາວາຍ",
        "ເວລາມາດຕະຖານຮາວາຍ",
        "ເວລາກາຍອານາ",
        "ເວ​ລາ​ກູ​ລ​໌ຟ",
        "ເວລາກວມ",
        "ເວລາຕອນທ່ຽງກຣີນແລນຕາເວັນຕົກ",
        "ເວລາມາດຕະຖານກຣີນແລນຕາເວັນຕົກ",
        "ເວລາລະດູຮ້ອນກຣີນແລນຕາເວັນອອກ",
        "ເວລາມາດຕະຖານຕາເວັນອອກກຣີນແລນ",
        "ເວ​ລາກຣີນ​ວິ​ຊ",
        "ເວລາຫມູ່ເກາະກິລເບີດ",
        "ເວລາລະດູຮ້ອນຈໍເຈຍ",
        "ເວລາມາດຕະຖານຈໍເຈຍ",
        "ເວລາແກມເບຍ",
        "ເວ​ລາ​ກາ​ລາ​ປາ​ກອ​ສ",
        "ແອນຕາກຕິກ",
        "ເວລາຝຣັ່ງຕອນໃຕ້",
        "ເວ​ລາ​ເຟ​ຣນ​ຊ໌​ເກຍ​ນາ",
        "ເວລາລະດູຮ້ອນຟິຈິ",
        "ເວລາມາດຕະຖານຟິຈິ",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນໝູ່​ເກາະ​ຟອ​ລ໌ກ​ແລນ",
        "​ເວ​ລາ​ມາດ​ຕະ​ຖານໝູ່​ເກາະ​ຟອ​ລ໌ກ​ແລນ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນຢູ​ໂຣບ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານຢູ​ໂຣບ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​​ຢູ​ໂຣ​ປຽນ​ຕາ​ເວັນ​ອອກ​ໄກ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນຢູ​ໂຣບ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ມາ​ດ​ຕະ​ຖານ​ຢູ​ໂຣບ​ຕາ​ເວັນ​ອອກ",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ຢູ​ໂຣບ​ກາງ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຢູ​ໂຣບກາງ",
        "ເວ​ລາ​ເອ​ກົວ​ດໍ",
        "Ииндийэ",
        "Ираан",
        "Ыраан",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນເກາະ​ອີ​ສ​ເຕີ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານເກາະ​ອີ​ສ​ເຕີ",
        "ເວລາຕີມໍຕາເວັນອອກ",
        "ເວລາດູມອງດູວິລ",
        "Кыргыстаан",
        "ເວລາເດວິດ",
        "ເວລາກາງເວັນຄິວບາ",
        "ເວລາມາດຕະຖານຂອງຄິວບາ",
        "ເວ​ລາ​ເຄິ່ງ​ລະ​ດູ​ຮ້ອນ​ໝູ່​ເກາະ​ຄຸກ",
        "ເວລາມາດຕະຖານຫມູ່ເກາະຄຸກ",
        "ເວລາລະດູຮ້ອນໂຄລໍາເບຍ",
        "ເວລາມາດຕະຖານໂຄລຳເບຍ",
        "ເວລາຫມູ່ເກາະໂກໂກສ",
        "ເວ​ລາ​ເກາະ​ຄ​ຣິສ​ມາສ",
        "ເວລາລະ​ດູ​ຮ້ອນໂຊຍບາຊັນ",
        "ເວລາມາດຕະຖານໂຊຍບາຊັນ",
        "​ເວ​ລາ​ຕອນ​ທ່ຽງ​ຈີນ",
        "ເວລາມາດຕະຖານຈີນ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນຊິ​ລີ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານຊິ​ລີ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ຊາ​ທາມ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ຊາ​ທາມ",
        "ເວ​ລາ​ຈາ​ໂມ​ໂຣ",
        "ເວລາເຄຊີ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ເຄບ​ເວີດ",
        "ඇෆ්ගනිස්ථාන",
        "​ເວ​ລາ​ມາດ​ຕະ​ຖານ​ເຄບ​ເວີດ",
        "​ເວ​ລາບຣູ​ໄນດາ​ຣຸສ​ຊາ​ລາມ",
        "ເວລາຕາມເຂດລະດູຮ້ອນຕາມເຂດບຣາຊີເລຍ",
        "ເວລາມາດຕາຖານເບຣຊີເລຍ",
        "ເວ​ລາ​ໂບ​ລິ​ເວຍ",
        "ເວ​ລາ​ພູ​ຖານ",
        "ລະດູຮ້ອນ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນອາ​ໂຊ​ເຣ​ສ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອາ​ໂຊ​ເຣ​ສ",
        "ເວລາລະດູຮ້ອນອັສເຊີໄບຈັນ",
        "ເວລາມາດຕະຖານອັສເຊີໄບຈັນ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ອອສ​ເຕຣ​ລຽນ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ມາ​ດ​ຕະ​ຖານອອສ​ເຕຣ​ລຽນ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ອອສ​ເຕຣ​ລຽນ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ມາດຕະຖານ​​​ອອສ​ເຕຣ​ລຽນ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ອອສ​ເຕຣ​ລຽນ​ກາງ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອອສ​ເຕຣ​ລຽນ​ກາງ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ຕອນ​ທ່ຽງ​ອອສ​ເຕຣ​ເລຍ​ກາງ",
        "ເວ​ລາມາດ​ຕະ​ຖານອອ​ສ​ເຕຣ​ເລຍ​ກ​າງ",
        "ເວລາກາງເວັນຂອງອາແລນຕິກ",
        "ເວລາມາດຕະຖານຂອງອາແລນຕິກ",
        "ເວລາລະດູຮ້ອນອາເມເນຍ",
        "ເວລາມາດຕະຖານອາເມເນຍ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນເວ​ສ​ເທິນອາ​ເຈນ​ທິ​ນາ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານເວ​ສ​ເທິນອາ​ເຈນ​ທິ​ນາ",
        "නැඟෙනහිර",
        "​ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ອາ​ເຈນ​ທິ​ນາ",
        "​ເວ​ລາ​ມາດ​ຕະ​ຖານອາ​ເຈນ​ທິ​ນາ",
        "ເວລາກາງເວັນອາຣາບຽນ",
        "භුතාන",
        "බොලිවියා",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອາ​ຣາ​ບຽນ",
        "බෘනායි",
        "දරුස්සලාම්",
        "ເວລາລະດູຮ້ອນອັດໂຕເບ",
        "චමොරෝ",
        "ເວລາມາດຕະຖານອັດໂຕເບ",
        "ເວລາລະດູຮ້ອນອັດຕາອູ",
        "ເວລາມາດຕະຖານອັດຕາອູ",
        "ເວລາກາງເວັນອາເພຍ",
        "ක්‍රිස්මස්",
        "ເວລາມາດຕະຖານເອເພຍ",
        "කොකෝස්",
        "ເວລາກາງເວັນແປຊິຟິກ",
        "ເວລາມາດຕະຖານແປຊິຟິກ",
        "භාග",
        "ເວລາກາງເວັນແຖບພູເຂົາ",
        "ඩාවිස්",
        "දුමොන්ත්",
        "ඩ්උර්විල්",
        "ටිමෝර්",
        "ເວລາມາດຕະຖານແຖບພູເຂົາ",
        "ເວລາກາງເວັນຕາເວັນອອກ",
        "ඉක්වදෝර්",
        "ເວລາມາດຕະຖານຕາເວັນອອກ",
        "තවත්",
        "ເວລາກາງເວັນກາງ",
        "ເວລາມາດຕະຖານກາງ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນອາ​ເມ​ຊອນ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານອາ​ເມ​ຊອນ",
        "දකුණුදිග",
        "ເວລາລະດູຮ້ອນອໍມາຕີ",
        "ඇන්ටාර්ක්ටික්",
        "ගලපගොස්",
        "ගැම්බියර්",
        "ເວລາມາດຕະຖານອໍມາຕີ",
        "ගිල්බර්ට්",
        "ග්‍රිනිච්",
        "ເວລາກາງເວັນອະແລສກາ",
        "ගල්ෆ්",
        "ເວລາມາດຕະຖານອະແລສກາ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ອາ​ຟຣິ​ກາ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ອາ​ຟຣິ​ກາ​ຕາ​ເວັນ​ຕົກ",
        "ເວ​ລາ​ອາ​ຟຣິ​ກາ​ໃຕ້",
        "ඉන්දියානු",
        "ඉන්දියන්",
        "සාගර",
        "ඉන්දුචීන",
        "ເວ​ລາ​ອາ​ຟຣິ​ກາ​ຕາ​ເວັນ​ອອກ",
        "ເວ​ລາ​ອາ​ຟຣິ​ກາ​ກາງ",
        "ອັຟການິສຖານ",
        "ເວລາລະດູຮ້ອນຂອງອາເກຣ",
        "ເວລາມາດຕະຖານຂອງອາເກຣ",
        "Uruguayanesch",
        "Uruguyanesch",
        "කොස්රේ",
        "Südgeorgesch",
        "කිර්ගිස්තාන",
        "ශ්‍රී",
        "ලංකා",
        "ලයින්",
        "Salomoninselen",
        "Standardzäit",
        "මැක්කුඅරි",
        "Pitcairninselen",
        "මැලේසියානු",
        "මාලදිවයින්",
        "මාර්කුඑසාස්",
        "මාර්ෂල්",
        "Phoenixinselen",
        "මොව්සන්",
        "Neiguinea",
        "Norfolkinselen",
        "Nepalesesch",
        "Marshallinselen",
        "Maldiven",
        "මියන්මාර්",
        "නාවුරු",
        "නේපාල",
        "Malaysesch",
        "Linneninselen",
        "Westkasachesch",
        "Ostkasachesch",
        "නියු",
        "නොෆොල්ක්",
        "Westindonesesch",
        "Ostindonesesch",
        "Zentralindonesesch",
        "Indeschen",
        "Indesch",
        "Mëttler",
        "පලාවු",
        "පැපුවා",
        "නිව්",
        "ගිනීයා",
        "Inselen",
        "Antarktisgebidder",
        "Guayane",
        "ෆීනික්ස්",
        "Ecuadorianesch",
        "Kokosinselen",
        "Chrëschtdagsinsel",
        "පිට්කෙයාන්",
        "පොනපේ",
        "ප්යොන්ග්යන්",
        "රියුනියන්",
        "රොතෙරා",
        "Bolivianesch",
        "Aserbaidschanesch",
        "සීෂෙල්ස්",
        "සිංගප්පුරු",
        "සොලොමන්",
        "ජෝජියා",
        "සුරිනාම",
        "ස්යෝවා",
        "ටාහිටි",
        "Aserbeidschanesch",
        "ටජිකිස්තාන",
        "ටොකෙලාවු",
        "Südafrikanesch",
        "චුක්",
        "Ostafrikanesch",
        "ටුවාලු",
        "Zentralafrikanesch",
        "Тажикстан",
        "Саоа",
        "Суринаме",
        "වෙනිසියුලා",
        "Жоржия",
        "Питкэрнг",
        "වොස්ටොක්",
        "වේක්",
        "වැලිස්",
        "ෆුටුනා",
        "Мйанмар",
        "Кыргызстан",
        "afganský",
        "stredoafrický",
        "východoafrický",
        "juhoafrický",
        "күндүзгү",
        "Индокытай",
        "булуңунун",
        "Персия",
        "Түштүгү",
        "Минск",
        "Калининград",
        "Экуадор",
        "жарым",
        "Азорс",
        "Seischälle",
        "Summerzigg",
        "Ozejan",
        "dem",
        "Standat",
        "Söd",
        "یٮ۪کَٹرِنبٔرگ",
        "یٮ۪کَٹٔرِنبٔرگ",
        "یَکُٹُسک",
        "یَکُٹسک",
        "فیوٗٹیوٗنا",
        "تہٕ",
        "والِس",
        "bhutánsky",
        "bolívijský",
        "ووسٹوک",
        "brunejský",
        "وٮ۪نٮ۪زیوٗلا",
        "chamorrský",
        "اُزبیکِستانُک",
        "اُزبیکِستان",
        "ٹوٗوَلوٗ",
        "ٹٔرک",
        "Vianočného",
        "ٹوکٮ۪لو",
        "تازِکِستان",
        "ٹاہِٹی",
        "Davisovej",
        "východotimorský",
        "سُرِنام",
        "ekvádorský",
        "جورجِیا",
        "ہُنٛد",
        "minský",
        "ججیٖرَن",
        "سولومَن",
        "سِنٛگاپوٗر",
        "francúzskoguyanský",
        "Francúzskych",
        "južných",
        "galapágsky",
        "gambierský",
        "سیشٮ۪لٕز",
        "greenwichský",
        "روتھٮ۪را",
        "رِیوٗنِیَن",
        "Perzského",
        "guyanský",
        "پونیپ",
        "پِٹکیرٕن",
        "indický",
        "indickooceánsky",
        "indočínsky",
        "stredoindonézsky",
        "východoindonézsky",
        "západoindonézsky",
        "پھونِکس",
        "گٮ۪نی",
        "پاپُعا",
        "پَلاو",
        "Kamčatský",
        "Kamčatskijský",
        "východokazachstanský",
        "západokazachstanský",
        "مَرِیانا",
        "kosrajský",
        "نورفعاک",
        "kirgizský",
        "نِیوٗ",
        "malajzijský",
        "maldivský",
        "markézsky",
        "لینڑ",
        "Mawsonovej",
        "فاونڑ",
        "فاونڑلینڑ",
        "کیلٮ۪ڑونِیس",
        "mjanmarský",
        "nauruský",
        "nepálsky",
        "کیلٮ۪ڑونِیا",
        "نٮ۪پٲلۍ",
        "نَعوٗروٗ",
        "niuejský",
        "norfolský",
        "مِیانمَر",
        "ماسَن",
        "مارشَل",
        "مارقیوٗسَس",
        "palauský",
        "Novej",
        "مالدیٖوٕز",
        "مَلیشِیا",
        "حووے",
        "لایِٔن",
        "Pitcairnových",
        "ponapský",
        "pchjongjanský",
        "réunionský",
        "Rotherovej",
        "لَنٛکا",
        "کِرگِستان",
        "seychelský",
        "singapurský",
        "Šalamúnových",
        "Južnej",
        "surinamský",
        "tahitský",
        "کورسَے",
        "tadžický",
        "tokelauský",
        "اِیٖرٲنی",
        "chuukský",
        "اِیٖرٲنۍ",
        "tuvalský",
        "اِنڑوچَینا",
        "ٹایِن",
        "اوشَن",
        "venezuelský",
        "ہِندوستٲنۍ",
        "ہِنٛدوستان",
        "حانٛگ",
        "حانگ",
        "Afganistanski",
        "Centralnoafriški",
        "Vzhodnoafriški",
        "Južnoafriški",
        "گُیَنا",
        "گَلف",
        "گُوٮ۪م",
        "میٖن",
        "وِچ",
        "گرٛیٖن",
        "گِلبٲٹ",
        "گٮ۪مبِیَر",
        "گٮ۪لٮ۪پیٚگوز",
        "فرٮ۪نٛچ",
        "گیوٗٮ۪نا",
        "فرٛٮ۪نٛچ",
        "یوٗرِپی",
        "اِکویڑَر",
        "ٹیٖمَر",
        "ایٖسٹ",
        "اُرویٖل",
        "ڑمانٹ",
        "ڑیوِس",
        "حاف",
        "کوکوز",
        "کرٛسمَس",
        "Butanski",
        "Bolivijski",
        "چٮ۪تھَم",
        "Brunejski",
        "Čamorski",
        "کٮ۪تھَم",
        "کٮ۪مورو",
        "ؤرڑو",
        "Božičnootoški",
        "دَروٗسَلَم",
        "Kokosovi",
        "برٛوٗنَے",
        "بولِوِیا",
        "بوٗٹان",
        "srednjepoletni",
        "ٹ",
        "Vzhodnotimorski",
        "ڈےلایٔٹ",
        "مغرِبیٖ",
        "Ekvadorski",
        "اٮ۪نڑیٖر",
        "اٮ۪نَڑیٖر",
        "Dodatni",
        "vzhodnoevropski",
        "افغانِستان",
        "ಯೇಕೇಟರಿನ್‍ಬರ್ಗ್",
        "ಯೇಕಟರಿನ್‌ಬರ್ಗ್",
        "Francoska",
        "ಫ್ಯುಟುನಾ",
        "Francoski",
        "južni",
        "ವ್ಯಾಲೀಸ್",
        "antarktični",
        "Galapaški",
        "ವೇಕ್",
        "Gilbertovi",
        "Greenwiški",
        "srednji",
        "ವೋಸ್ಟೊಕ್",
        "ವೆನಿಜುವೆಲಾ",
        "Zalivski",
        "Gvajanski",
        "ತುವಾಲು",
        "ಚುಕ್",
        "ಟೊಕೆಲಾವ್",
        "Indijski",
        "Indijskooceanski",
        "Indokitajski",
        "ತಝಕಿಸ್ತಾನ್",
        "osrednji",
        "ತಾಹಿತಿ",
        "ಸ್ಯೊವಾ",
        "ಸುರಿನೇಮ್",
        "ಸಾಲಮನ್",
        "Zahodni",
        "Kosrajški",
        "Kirgizistanski",
        "Ekvatorski",
        "otoki:",
        "Macquarieski",
        "Malezijski",
        "Maldivski",
        "Markizni",
        "Marshallovi",
        "ಸಿಂಗಾಪುರ್",
        "ಸೀಷೆಲ್ಸ್",
        "ರೊತೇರಾ",
        "ರಿಯೂನಿಯನ್",
        "ಪ್ಯೊಂಗ್ಯಾಂಗ್",
        "ಪೊನಾಪೆ",
        "Mjanmarski",
        "Naurujski",
        "ಪಿಟ್‌ಕೈರ್ನ್",
        "ಫಿನಿಕ್ಸ್",
        "ಗಿನಿಯಾ",
        "Niuejski",
        "Norfolški",
        "ನ್ಯೂ",
        "ಪಪುವಾ",
        "Palavski",
        "Papuanski",
        "ಪಾಲಾವ್",
        "ದೆ",
        "Otočje",
        "Pitcairnski",
        "Pjongjanški",
        "Rotherski",
        "ಫರ್ನಾಂಡೋ",
        "ಡೆ",
        "ಫೆರ್ನಾಂಡೋ",
        "Sejšelski",
        "Salomonovootoški",
        "Južnogeorgijski",
        "Tahitijski",
        "ನಾರ್ಫೋಕ್",
        "Tadžikistanski",
        "Tokelavski",
        "ನಿಯು",
        "ಬೇಸಿಗೆಯ",
        "ನೇಪಾಳ",
        "Tuvalujski",
        "ನೌರು",
        "ಮ್ಯಾನ್ಮಾರ್",
        "ಮಾವ್‌ಸನ್",
        "ಮಾರ್ಷಲ್",
        "ಮಾರ್ಕ್ಯುಸಸ್",
        "vostoški",
        "ಮಾಲ್ಡೀವ್ಸ್",
        "ಮಲೇಷಿಯಾ",
        "ಮ್ಯಾಕ್‌ಕ್ಯುರೈ",
        "ಬೆಳಗಿನ",
        "ಲೈನ್",
        "ಕಿರ್ಗಿಸ್ತಾನ್",
        "ಕೊಸರೆ",
        "ಇಂಡೋನೇಷಿಯ",
        "ಇಂಡೊಚೈನಾ",
        "ಮಹಾಸಾಗರ",
        "ಹಿಂದೂ",
        "ಭಾರತೀಯ",
        "ಗಲ್ಫ್",
        "ಕಾಲಮಾನ",
        "ಸರಾಸರಿ",
        "ಗ್ರೀನ್‌ವಿಚ್",
        "ಗಿಲ್ಬರ್ಟ್",
        "ಗ್ಯಾಂಬಿಯರ್",
        "ಗಾಲಾಪಾಗೋಸ್",
        "ಅಂಟಾರ್ಟಿಕಾ",
        "ಮತ್ತಷ್ಟು",
        "ಈಕ್ವೆಡಾರ್",
        "ಟಿಮೋರ್",
        "ಉರ್ವಿಲೆ",
        "ಡಿ",
        "ಡುಮಂಟ್",
        "ಡೇವಿಸ್",
        "ಮಧ್ಯಕಾಲೀನ",
        "ಕೋಕೋಸ್",
        "ಕ್ರಿಸ್ಮಸ್",
        "ಚೊಯ್‌ಬಲ್ಸಾನ್",
        "ಚೊಯ್‌‌ಬಲ್ಸಾನ್‌",
        "ಚಮೋರೋ",
        "ದಾರುಸಲೆಮ್",
        "ಬ್ರೂನಿ",
        "ಬೊಲಿವಿಯಾ",
        "ಭೂತಾನ್",
        "ಅರ್ಜೆಂಟಿನಾ",
        "ಕೇಂದ್ರೀಯ",
        "‌ಅಲಾಸ್ಕಾ",
        "ಅಲಸ್ಕಾ",
        "ಅಫಘಾನಿಸ್ತಾನ",
        "ម៉ោង​នៅ​អ៊ិខាធឺរីនប៊័ក​រដួវ​​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ិខាធឺរីនប៊័ក",
        "ម៉ោង​នៅ​យ៉ាគុត​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​យ៉ាគុត",
        "និង​ផូទូណា",
        "ម៉ោង​នៅ​វ៉ាលីស",
        "ម៉ោង​នៅ​កោះវេក",
        "ម៉ោង​នៅ​វ័រស្តុក",
        "ម៉ោង​នៅ​វ៉ូហ្កាក្រាត​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​វ៉ូហ្កាក្រាត",
        "ម៉ោង​នៅ​វ៉្លាឌីវ៉ូស្តក​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​វ៉្លាឌីវ៉ូស្តក",
        "ម៉ោង​នៅ​វ៉េណេស៊ុយអេឡា",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​វ៉ានូទូ",
        "ម៉ោង​​ស្តង់ដារ​នៅ​វ៉ានូទូ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អ៊ូសបេគីស្ថាន",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ូសបេគីស្ថាន",
        "ម៉ោង​​រដូវ​ក្ដៅ​នៅ​អ៊ុយរូហ្គាយ",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ុយរូហ្គាយ",
        "ម៉ោង​នៅ​ទុយវ៉ាឡុ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​តួកម៉េនីស្ថាន​",
        "ម៉ោង​ស្តង់ដារ​នៅតួកម៉េនីស្ថាន",
        "ម៉ោង​នៅ​ចូអុក",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​តុងកា",
        "ម៉ោង​ស្តង់ដារ​​នៅ​តុងកា",
        "ម៉ោង​នៅ​តូខេឡៅ",
        "ម៉ោងនៅតាជីគីស្តង់",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​តៃប៉ិ",
        "ម៉ោង​ស្តង់ដារ​នៅ​តៃប៉ិ",
        "ម៉ោង​នៅ​តាហិទី",
        "ម៉ោង​នៅ​ស៊ីអូវ៉ា",
        "ម៉ោង​នៅ​សូរីណាម",
        "ម៉ោង​នៅ​កោះ​ហ្សកហ្ស៊ី",
        "ម៉ោង​នៅ​កោះ​សូឡូម៉ុន",
        "ម៉ោង​នៅ​សិង្ហបូរី",
        "ម៉ោង​នៅ​សីស្ហែល",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​សាម៉ៅ",
        "ម៉ោង​ស្តង់ដារនៅ​សាម៉ៅ",
        "ម៉ោង​នៅ​សាក់ខាលីន​រដួវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​សាក់ខាលីន",
        "ម៉ោង​នៅ​រ៉ូធឺរ៉ា",
        "ម៉ោងនៅរេអ៊ុយ៉ុង",
        "ម៉ោងនៅព្យុងយ៉ាង",
        "ម៉ោង​នៅ​ប៉ូណាប់",
        "ម៉ោង​នៅ​ភីឃឺន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅសង់​ព្យែរ",
        "ម៉ោង​ស្តង់ដារ​នៅសង់​ព្យែរ",
        "ម៉ោង​នៅ​កោះ​ផូនីក",
        "ម៉ោង​រដូវ​ក្ដៅ​​នៅ​ហ្វីលីពីន",
        "ម៉ោង​ស្តង់ដារ​នៅ​ហ្វីលីពីន",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ប៉េរូ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ប៉េរូ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅប៉ារ៉ាហ្គាយ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ប៉ារ៉ាហ្គាយ",
        "ម៉ោង​នៅ​ប៉ាបៅញូកីនៀ",
        "ម៉ោង​នៅ​ផាឡៅ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ប៉ាគីស្ថាន",
        "ម៉ោង​ស្ដង់ដារ​នៅ​ប៉ាគីស្ថាន",
        "ម៉ោង​នៅ​អូម​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​អូម",
        "ម៉ោង​នៅ​ណូវ៉ូស៊ីប៊ីក​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ណូវ៉ូស៊ីប៊ីក",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ហ្វឺណាន់ដូ​ដឺណូរ៉ុនហា",
        "ម៉ោង​​ស្តង់ដារ​នៅ​ហ្វឺណាន់ដូ​ដឺណូរ៉ុនហា",
        "ម៉ោង​នៅ​កោះ​ន័រហ្វក់",
        "ម៉ោងនៅ​ដ្យូអៀ",
        "ម៉ោង​ពេលថ្ងៃ​នៅ​ញូហ្វោនឡែន",
        "ម៉ោង​​ស្តង់ដារ​​នៅ​ញូហ្វោនឡែន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​នូវែលសេឡង់",
        "ម៉ោង​ស្តង់ដារ​នៅ​នូវែលសេឡង់",
        "ម៉ោង​នៅ​ញូ​កាឡេដូនៀ",
        "ម៉ោង​ស្តង់ដារ​នៅ​កាឡេដូនៀ",
        "ម៉ោងនៅនេប៉ាល់",
        "ម៉ោង​នៅ​ណូរូ",
        "ម៉ោង​នៅ​ភូមា",
        "ម៉ោង​នៅ​មូស្គូ​",
        "ម៉ោង​ស្តង់ដារ​នៅ​មូស្គូ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អ៊ូឡាន​បាទូ",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ូឡាន​បាទូ",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ប៉ាសីុហ្វិក​ម៉ិកស៊ិក",
        "ម៉ោង​ស្តង់ដា​នៅ​ប៉ាសីុហ្វិក​ម៉ិកស៊ិក",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ម៉ិកស៊ិកភាគពាយព្យ",
        "ម៉ោង​ស្តង់ដា​នៅ​ម៉ិកស៊ិកភាគពាយព្យ",
        "ម៉ោង​នៅ​ម៉ៅ​សាន់",
        "ម៉ោង​​រដូវ​ក្ដៅនៅ​ម៉ូរីស",
        "ម៉ោង​ស្តង់ដារ​នៅ​ម៉ូរីស",
        "ម៉ោង​នៅ​ម៉ាសាល",
        "ម៉ោង​នៅ​កោះ​ម៉ាគឺសាស់",
        "ម៉ោង​នៅ​ម៉ាល់ឌីវ",
        "ម៉ោង​នៅ​ម៉ាឡេស៊ី",
        "ម៉ោង​នៅ​ម៉ាហ្កាដាន​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ម៉ាហ្កាដាន",
        "ម៉ោង​នៅ​កោះ​ម៉ាកគែរី",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ឡតហៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ឡត​ហៅ",
        "ម៉ោង​នៅ​កោះ​ឡាញ",
        "ម៉ោងនៅគៀរហ្គីស្តង់",
        "ម៉ោង​នៅ​ក្រាណូយ៉ាស​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ក្រាណូយ៉ាស",
        "ម៉ោង​នៅ​កូស្រៃ",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​កូរ៉េ",
        "ម៉ោង​ស្តង់ដារ​នៅ​កូរ៉េ",
        "ម៉ោង​នៅ​កាហ្សាក់ស្ថាន​ខាង​​​លិច",
        "ម៉ោង​កាហ្សាក់ស្ថាន​​ខាង​កើត",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅជប៉ុន",
        "ម៉ោង​ស្តង់ដារ​នៅ​ជប៉ុន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អ៊ីស្រាអែល",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ីស្រាអែល",
        "ម៉ោង​នៅ​អ៊ីគុត​រដូវ​ក្ដៅ",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ីគុត",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អ៊ីរ៉ង់",
        "ម៉ោង​ស្តង់ដារ​នៅ​អ៊ីរ៉ង់",
        "ម៉ោង​នៅ​ឥណ្ឌូណេស៊ី​​ខាង​លិច",
        "ម៉ោង​នៅ​ឥណ្ឌូណេស៊ី​​ខាង​កើត",
        "ម៉ោង​នៅ​ឥណ្ឌូណេស៊ី​​​កណ្ដាល",
        "ម៉ោង​នៅ​ឥណ្ឌូចិន",
        "ម៉ោង​នៅ​មហាសមុទ្រ​ឥណ្ឌា",
        "ម៉ោង​នៅ​ឥណ្ឌា",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ហូវ",
        "ម៉ោង​ស្តង់ដារ​នៅហូវ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ហុងកុង",
        "ម៉ោង​ស្តង់ដារ​នៅ​ហុងកុង",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ហាវៃ",
        "ម៉ោង​ស្តង់ដារ​​នៅ​ហាវៃ",
        "ម៉ោង​នៅ​ឃ្វីយ៉ាន",
        "ម៉ោង​ស្តង់ដា​នៅ​កាល់",
        "ម៉ោងរដូវក្តៅនៅហ្គ្រីនលែនខាងលិច",
        "ម៉ោងស្តង់ដារនៅហ្គ្រីនលែនខាងលិច",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ហ្គ្រីនលែនខាង​កើត",
        "ម៉ោង​​​ស្តង់ដារ​នៅ​​ហ្គ្រីនលែន​ខាង​កើត",
        "ម៉ោងនៅគ្រីនវិច",
        "ម៉ោង​នៅ​កោះ​កីប៊ឺត",
        "ម៉ោង​​រដូវ​ក្ដៅ​នៅ​ហ្សកហ្ស៊ី",
        "ម៉ោង​ស្តង់ដារ​នៅ​ហ្សកហ្ស៊ី",
        "ម៉ោង​នៅ​កាំបៀ",
        "ម៉ោង​នៅ​កាឡាប៉ាកូស",
        "និង​បារាំង​ខាង​ត្បូង",
        "ម៉ោង​នៅ​អែតាឃីត",
        "ម៉ោង​នៅ​ឃ្វីយ៉ាន​បារាំង",
        "ម៉ោង​​រដូវ​ក្ដៅ​នៅ​ហ៊្វីជី",
        "ម៉ោង​ស្តង់ដារ​នៅ​ហ៊្វីជី",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​កោះ​ហ្វក់ឡែន",
        "ម៉ោង​ស្តង់ដារ​នៅ​កោះ​ហ្វក់ឡែន",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អឺរ៉ុប​ខាង​លិច",
        "ម៉ោង​ស្តង់ដារ​នៅ​អឺរ៉ុប​ខាង​លិច",
        "ម៉ោង​នៅ​ចុងភាគ​ខាង​កើត​អឺរ៉ុប​",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អឺរ៉ុប​​ខាង​កើត​",
        "ម៉ោង​ស្តង់ដារ​​នៅ​អឺរ៉ុប​​ខាង​កើត​",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អឺរ៉ុប​កណ្ដាល",
        "ម៉ោង​ស្តង់ដារ​នៅ​អឺរ៉ុប​កណ្ដាល",
        "ម៉ោង​នៅ​អេក្វាទ័រ",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​កោះ​​ខាង​កើត",
        "ម៉ោង​ស្តង់ដារ​នៅ​កោះ​​​ខាង​កើត",
        "ម៉ោង​នៅ​​ទីម័រ​ខាង​កើត",
        "ម៉ោង​នៅ​ឌុយម៉ុងដឺអ៊ុយវីល",
        "ម៉ោង​នៅ​ដាវីស",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​គុយបា",
        "ម៉ោង​ស្តង់ដារ​នៅ​គុយបា",
        "ម៉ោង​​ពាក់កណ្ដាល​រដូវ​ក្ដៅ​នៅ​កោះ​ខូក",
        "ម៉ោង​ស្តង់ដារ​នៅ​កោះ​ខូក",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​កូឡុំប៊ី",
        "ម៉ោង​ស្តង់ដារ​នៅ​កូឡុំប៊ី",
        "ម៉ោង​នៅ​កោះ​កូកូស",
        "ម៉ោង​នៅ​កោះ​គ្រីស្មាស",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​ឆូបាល់សាន",
        "ម៉ោង​ស្តង់ដារ​នៅ​ឆូបាល់សាន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ចិន",
        "ម៉ោង​ស្តង់ដារ​នៅ​ចិន",
        "ម៉ោងរដូវក្តៅនៅឈីលី",
        "ម៉ោងស្តង់ដារនៅឈីលី",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​ចាថាំ",
        "ម៉ោង​ស្តង់ដារ​នៅ​ចាថាំ",
        "ម៉ោង​នៅ​ចាំម៉ូរ៉ូ",
        "ម៉ោង​​រដូវ​ក្ដៅនៅ​កាប់វែរ",
        "ម៉ោង​ស្តង់ដារ​នៅ​កាប់វែរ",
        "ម៉ោងនៅប្រ៊ុយណេដារូសាឡឹម",
        "ម៉ោង​​រដូវ​ក្ដៅ​នៅ​ប្រាស៊ីលីយ៉ា",
        "ម៉ោង​ស្តង់ដារ​នៅ​ប្រាស៊ីលីយ៉ា",
        "ម៉ោង​នៅ​បូលីវី",
        "ម៉ោងនៅប៊ូតាន",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​បង់ក្លាដែស",
        "ម៉ោង​ស្ដង់ដារ​នៅ​បង់ក្លាដែស",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អេហ្សស",
        "ម៉ោង​ស្តង់ដារ​នៅ​អេហ្សស",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អាស៊ែបៃហ្សង់",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាស៊ែបៃហ្សង់",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អូស្ត្រាលី​ខាង​លិច",
        "ម៉ោង​​ស្តង់ដារ​នៅ​អូស្ត្រាលី​ខាង​លិច",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អូស្ត្រាលី​ខាង​កើត",
        "ម៉ោង​ស្តង់ដារ​នៅ​អូស្ត្រាលី​ខាង​កើត",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​​ភាគ​ខាង​លិច​នៃ​អូស្ត្រាលី​កណ្ដាល",
        "ម៉ោង​ស្តង់ដារ​នៅ​ភាគ​ខាង​លិច​នៃ​អូស្ត្រាលី​កណ្ដាល",
        "ម៉ោង​ពេលថ្ងៃ​​​​នៅ​អូស្ត្រាលី​កណ្ដាល",
        "ម៉ោង​ស្តង់ដារ​នៅ​អូស្ត្រាលី​កណ្ដាល",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អាត្លង់ទិក",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាត្លង់ទិក",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អាមេនី",
        "ម៉ោង​ស្ដង់ដារ​នៅ​អាមេនី",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អាសង់ទីន​​ខាង​លិច",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាសង់ទីន​​ខាង​លិច",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អាសង់ទីន",
        "ម៉ោង​​ស្តង់ដារ​នៅ​អាសង់ទីន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អារ៉ាប់",
        "ម៉ោង​ស្តង់ដារ​នៅ​អារ៉ាប់",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​អាប្យា",
        "ម៉ោង​ស្តង់ដា​នៅ​អាប្យា",
        "ម៉ោង​ពេល​ថ្ងៃ​​ភាគ​ខាងលិច​នៅ​អាមេរិក​ភាគ​ខាង​ជើង",
        "ម៉ោង​ស្តង់ដារ​ភាគ​ខាង​លិច​នៅ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​តំបន់​ភ្នំ​អាមេរិក​ភាគ​ខាង​ជើង",
        "ម៉ោង​ស្តង់ដារ​នៅ​តំបន់​ភ្នំ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​ពេល​ថ្ងៃ​ភាគខាង​កើតនៅ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​ស្តង់ដារ​ភាគ​ខាង​កើត​នៅ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​ពេល​ថ្ងៃ​ភាគ​កណ្ដាល​នៅ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​ស្តង់ដារ​ភាគ​កណ្ដាល​នៅ​អាមេរិក​ខាង​ជើង",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អាម៉ាសូន",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាម៉ាសូន",
        "ម៉ោង​ពេល​ថ្ងៃ​នៅ​​អាឡាស្កា",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាឡាស្កា",
        "ម៉ោង​​រដូវ​ក្ដៅ​នៅ​អាហ្វ្រិក​​​ខាងលិច",
        "ម៉ោង​ស្តង់ដារ​នៅ​អាហ្វ្រិក​ខាង​លិច",
        "ម៉ោង​នៅ​អាហ្វ្រិក​ខាង​ត្បូង",
        "ម៉ោង​នៅ​អាហ្វ្រិក​ខាង​កើត",
        "ម៉ោង​នៅ​អាហ្វ្រិក​កណ្ដាល",
        "ម៉ោង​នៅ​អាហ្វហ្គានីស្ថាន",
        "Тәжікстан",
        "Сейшель",
        "Қырғызстан",
        "Үндіқытай",
        "мұхитының",
        "Үнді",
        "Үндістан",
        "шығанағы",
        "Парсы",
        "аймағы",
        "оңтүстік",
        "Францияның",
        "Гвианасы",
        "Қиыр",
        "жартылай",
        "Ауғанстан",
        "ფუტუნას",
        "ვოლისი",
        "ვეიკის",
        "ვოსტოკის",
        "ვენესუელის",
        "ტუვალუს",
        "ჩუუკის",
        "ტოკელაუს",
        "ტაჯიკეთის",
        "ტაიტის",
        "სიოვას",
        "სურინამის",
        "გეორგიის",
        "სოლომონის",
        "სინგაპურის",
        "სეიშელის",
        "როთერის",
        "რეიუნიონის",
        "ფხენიანის",
        "პონაპეს",
        "პიტკერნის",
        "ფენიქსის",
        "გვინეის",
        "პაპუა",
        "პალაუს",
        "ნორფოლკის",
        "ნიუეს",
        "ნეპალის",
        "ნაურუს",
        "მიანმარის",
        "მოუსონის",
        "მარშალის",
        "მარკიზის",
        "მალდივების",
        "მალაიზიის",
        "მაქკუორის",
        "ლაინის",
        "ყირგიზეთის",
        "კოსრეს",
        "ინდოჩინეთის",
        "გაიანის",
        "ყურის",
        "სპარსეთის",
        "საშუალო",
        "გრინვიჩის",
        "გილბერტის",
        "გამბიერის",
        "გალაპაგოსის",
        "ანტარქტიკის",
        "სამხრეთის",
        "ფრანგული",
        "გვიანის",
        "საფრანგეთის",
        "შორეული",
        "ეკვადორის",
        "ტიმორის",
        "დურვილის",
        "დუმონ",
        "დევისის",
        "ნახევრად",
        "ქოქოსის",
        "შობის",
        "ჩამოროს",
        "დარუსალამის",
        "ბრუნეი",
        "ბოლივიის",
        "ბუტანის",
        "ავღანეთის",
        "エカテリンブルグ夏時間",
        "エカテリンブルグ標準時",
        "ヤクーツク夏時間",
        "ヤクーツク標準時",
        "ウォリス・フツナ時間",
        "ウェーク島時間",
        "ボストーク基地時間",
        "ボルゴグラード夏時間",
        "ボルゴグラード標準時",
        "ウラジオストク夏時間",
        "ウラジオストク標準時",
        "ベネズエラ時間",
        "バヌアツ夏時間",
        "バヌアツ標準時",
        "ウズベキスタン夏時間",
        "ウズベキスタン標準時",
        "ウルグアイ夏時間",
        "ウルグアイ標準時",
        "ツバル時間",
        "トルクメニスタン夏時間",
        "トルクメニスタン標準時",
        "チューク時間",
        "トンガ夏時間",
        "トンガ標準時",
        "トケラウ時間",
        "タジキスタン時間",
        "台北夏時間",
        "台北標準時",
        "タヒチ時間",
        "スリナム時間",
        "サウスジョージア時間",
        "ソロモン諸島時間",
        "シンガポール標準時",
        "セーシェル時間",
        "サモア夏時間",
        "サモア標準時",
        "サマラ夏時間",
        "サマラ標準時",
        "サハリン夏時間",
        "サハリン標準時",
        "ロゼラ基地時間",
        "レユニオン時間",
        "クズロルダ夏時間",
        "クズロルダ標準時",
        "平壌時間",
        "ポナペ時間",
        "ピトケアン時間",
        "サンピエール・ミクロン夏時間",
        "サンピエール・ミクロン標準時",
        "フェニックス諸島時間",
        "フィリピン夏時間",
        "フィリピン標準時",
        "ペルー夏時間",
        "ペルー標準時",
        "パラグアイ夏時間",
        "パラグアイ標準時",
        "パプアニューギニア時間",
        "パラオ時間",
        "パキスタン夏時間",
        "パキスタン標準時",
        "オムスク夏時間",
        "オムスク標準時",
        "ノヴォシビルスク夏時間",
        "ノヴォシビルスク標準時",
        "北マリアナ諸島時間",
        "フェルナンド・デ・ノローニャ夏時間",
        "フェルナンド・デ・ノローニャ標準時",
        "ノーフォーク島時間",
        "ニウエ時間",
        "ニューファンドランド夏時間",
        "ニューファンドランド標準時",
        "ニュージーランド夏時間",
        "ニュージーランド標準時",
        "ニューカレドニア夏時間",
        "ニューカレドニア標準時",
        "ネパール時間",
        "ナウル時間",
        "ミャンマー時間",
        "モスクワ夏時間",
        "モスクワ標準時",
        "ウランバートル夏時間",
        "ウランバートル標準時",
        "メキシコ太平洋夏時間",
        "メキシコ太平洋標準時",
        "メキシコ北西部夏時間",
        "メキシコ北西部標準時",
        "モーソン基地時間",
        "モーリシャス夏時間",
        "モーリシャス標準時",
        "マーシャル諸島時間",
        "マルキーズ時間",
        "モルディブ時間",
        "マレーシア時間",
        "マガダン夏時間",
        "マガダン標準時",
        "マッコーリー島時間",
        "マカオ夏時間",
        "マカオ標準時",
        "ロードハウ夏時間",
        "ロードハウ標準時",
        "ライン諸島時間",
        "ランカ時間",
        "キルギスタン時間",
        "クラスノヤルスク夏時間",
        "クラスノヤルスク標準時",
        "コスラエ時間",
        "韓国夏時間",
        "韓国標準時",
        "西カザフスタン時間",
        "東カザフスタン時間",
        "ペトロパブロフスク・カムチャツキー夏時間",
        "ペトロパブロフスク・カムチャツキー標準時",
        "JDT",
        "JST",
        "日本夏時間",
        "日本標準時",
        "イスラエル夏時間",
        "イスラエル標準時",
        "イルクーツク夏時間",
        "イルクーツク標準時",
        "イラン夏時間",
        "イラン標準時",
        "インドネシア西部時間",
        "インドネシア東部時間",
        "インドネシア中部時間",
        "インドシナ時間",
        "インド洋時間",
        "インド標準時",
        "ホブド夏時間",
        "ホブド標準時",
        "香港夏時間",
        "香港標準時",
        "ハワイ・アリューシャン夏時間",
        "ハワイ・アリューシャン標準時",
        "ガイアナ時間",
        "湾岸標準時",
        "グアム時間",
        "グリーンランド西部夏時間",
        "グリーンランド西部標準時",
        "グリーンランド東部夏時間",
        "グリーンランド東部標準時",
        "グリニッジ標準時",
        "ギルバート諸島時間",
        "ジョージア夏時間",
        "ジョージア標準時",
        "ガンビエ諸島時間",
        "ガラパゴス時間",
        "仏領南方南極時間",
        "仏領ギアナ時間",
        "フィジー夏時間",
        "フィジー標準時",
        "フォークランド諸島夏時間",
        "フォークランド諸島標準時",
        "西ヨーロッパ夏時間",
        "西ヨーロッパ標準時",
        "極東ヨーロッパ時間",
        "東ヨーロッパ夏時間",
        "東ヨーロッパ標準時",
        "中央ヨーロッパ夏時間",
        "中央ヨーロッパ標準時",
        "エクアドル時間",
        "イースター島夏時間",
        "イースター島標準時",
        "東ティモール時間",
        "デュモン・デュルヴィル基地時間",
        "デービス基地時間",
        "キューバ夏時間",
        "キューバ標準時",
        "クック諸島夏時間",
        "クック諸島標準時",
        "コロンビア夏時間",
        "コロンビア標準時",
        "ココス諸島時間",
        "クリスマス島時間",
        "チョイバルサン夏時間",
        "チョイバルサン標準時",
        "中国夏時間",
        "中国標準時",
        "チリ夏時間",
        "チリ標準時",
        "チャタム夏時間",
        "チャタム標準時",
        "チャモロ時間",
        "ケイシー基地時間",
        "カーボベルデ夏時間",
        "カーボベルデ標準時",
        "ブルネイ・ダルサラーム時間",
        "ブラジリア夏時間",
        "ブラジリア標準時",
        "ボリビア時間",
        "ブータン時間",
        "バングラデシュ夏時間",
        "バングラデシュ標準時",
        "アゾレス夏時間",
        "アゾレス標準時",
        "アゼルバイジャン夏時間",
        "アゼルバイジャン標準時",
        "オーストラリア西部夏時間",
        "オーストラリア西部標準時",
        "オーストラリア東部夏時間",
        "オーストラリア東部標準時",
        "オーストラリア中西部夏時間",
        "オーストラリア中西部標準時",
        "オーストラリア中部夏時間",
        "オーストラリア中部標準時",
        "大西洋夏時間",
        "大西洋標準時",
        "アルメニア夏時間",
        "アルメニア標準時",
        "西部アルゼンチン夏時間",
        "西部アルゼンチン標準時",
        "アルゼンチン夏時間",
        "アルゼンチン標準時",
        "アラビア夏時間",
        "アラビア標準時",
        "アクトベ夏時間",
        "アクトベ標準時",
        "アクタウ夏時間",
        "アクタウ標準時",
        "アピア夏時間",
        "アピア標準時",
        "アナディリ夏時間",
        "アナディリ標準時",
        "アメリカ太平洋夏時間",
        "アメリカ太平洋標準時",
        "アメリカ山地夏時間",
        "アメリカ山地標準時",
        "アメリカ東部夏時間",
        "アメリカ東部標準時",
        "アメリカ中部夏時間",
        "アメリカ中部標準時",
        "アマゾン夏時間",
        "アマゾン標準時",
        "アルマトイ夏時間",
        "アルトマイ標準時",
        "アラスカ夏時間",
        "アラスカ標準時",
        "西アフリカ夏時間",
        "西アフリカ標準時",
        "南アフリカ標準時",
        "東アフリカ時間",
        "中央アフリカ時間",
        "アフガニスタン時間",
        "アクレ夏時間",
        "アクレ標準時",
        "Yekaterinborg",
        "Fútúnaeyjum",
        "Venesúelatími",
        "Túvalútími",
        "Tókelá",
        "Tadsjíkistan",
        "Tahítí",
        "Súrinamtími",
        "Georgíutími",
        "Salómonseyjatími",
        "Singapúrtími",
        "eyjatími",
        "Fönixeyjatími",
        "Gíneu",
        "Palátími",
        "Nepaltími",
        "Mjanmar",
        "Markgreifafrúreyjum",
        "Maldíveyja",
        "Malasíutími",
        "eyjartími",
        "Línueyja",
        "Indókínatími",
        "Indlandshafstími",
        "Indlandstími",
        "Gvæjanatími",
        "Persaflóa",
        "við",
        "Suðurskautslandssvæði",
        "suðurhafssvæðum",
        "frönsku",
        "Gvæjana",
        "Frönsku",
        "Kalíníngrad",
        "ఆఫ్ఘనిస్థాన్",
        "Staðartími",
        "Ekvadortími",
        "Tímor",
        "Tíminn",
        "Hálfsumartími",
        "Kókoseyjatími",
        "Jólaeyjartími",
        "Brúneitími",
        "Bólivíutími",
        "Bútantími",
        "Afganistantími",
        "Nugini",
        "Utara",
        "Maladewa",
        "Kirghizia",
        "WIB",
        "WIT",
        "WITA",
        "Hindia",
        "ఆర్జెంటీనా",
        "Samudera",
        "Wilayah",
        "Jauh",
        "Ekuador",
        "Ֆուտունայի",
        "Ուոլիս",
        "Ուեյք",
        "భూటాన్",
        "బొలీవియా",
        "Վոստոկի",
        "బ్రూనే",
        "దరుసలామ్",
        "Վենեսուելայի",
        "Տուվալույի",
        "చామర్రో",
        "Տրուկի",
        "Տոկելաույի",
        "Տաջիկստանի",
        "Թաիթիի",
        "క్రిస్మస్",
        "Սյովայի",
        "కాకోస్",
        "Սուրինամի",
        "Ջորջիայի",
        "Սողոմոնի",
        "అర్థ",
        "Սինգապուրի",
        "డేవిస్",
        "డ్యూమాంట్",
        "డి’ఉర్విల్లే",
        "తైమూర్",
        "Սեյշելյան",
        "ఈక్వడార్",
        "Ռոտերայի",
        "సుదూర",
        "Ռեյունիոնի",
        "Փխենյանի",
        "Պոնապե",
        "Պիտկեռնի",
        "Ֆինիքս",
        "అంటార్కిటిక్",
        "గాలాపాగోస్",
        "గాంబియర్",
        "Գվինեայի",
        "గిల్బర్ట్",
        "గ్రీన్‌విచ్",
        "సగటు",
        "Պապուա",
        "గ్వామ్",
        "గల్ఫ్",
        "Պալաույի",
        "Նորֆոլկ",
        "Նիուեյի",
        "Նեպալի",
        "భారతదేశ",
        "హిందూ",
        "మహా",
        "సముద్ర",
        "ఇండోచైనా",
        "Նաուրուի",
        "Մյանմայի",
        "Մոուսոնի",
        "Մարշալյան",
        "Մարկիզյան",
        "Մալդիվների",
        "Մալայզիայի",
        "కజకి‌స్తాన్",
        "కజకిస్తాన్",
        "Մակկուորի",
        "కోస్రాయి",
        "Լայն",
        "కిర్గిస్థాన్",
        "లంకా",
        "లైన్",
        "Ղրղզստանի",
        "Կոսրաեյի",
        "Հնդկաչինական",
        "మాక్క్వారీ",
        "օվկիանոսի",
        "మలేషియా",
        "మాల్దీవుల",
        "మార్క్వేసాస్",
        "మార్షల్",
        "Հնդկական",
        "మావ్సన్",
        "Հնդկաստանի",
        "Գայանայի",
        "ծոցի",
        "Պարսից",
        "Գրինվիչի",
        "Ջիլբերթի",
        "మయన్మార్",
        "నౌరు",
        "నేపాల్",
        "Գամբյե",
        "Գալապագոսյան",
        "անտարկտիդյան",
        "հարավային",
        "Գվիանայի",
        "నియూ",
        "నార్ఫోక్",
        "Մինսկի",
        "Էկվադորի",
        "Թիմորի",
        "ఉత్తర",
        "మారియానా",
        "నోవోసిబిర్క్స్",
        "నోవోసిబిర్స్క్",
        "դ’Յուրվիլի",
        "పాకిస్తాన్",
        "పాకిస్థాన్",
        "పాలావ్",
        "పాపువా",
        "గినియా",
        "Դյումոն",
        "Դեյվիսի",
        "կիսաամառային",
        "ఫినిక్స్",
        "Կոկոսյան",
        "పియెర్",
        "Ծննդյան",
        "పియర్",
        "పిట్కైరన్",
        "పొనేప్",
        "ప్యోంగాంగ్",
        "Սուրբ",
        "రీయూనియన్",
        "రొతేరా",
        "Չամոռոյի",
        "Բրունեյի",
        "Բոլիվիայի",
        "సీషెల్స్",
        "సింగపూర్",
        "సొలొమన్",
        "సూరినామ్",
        "స్యోవా",
        "తహితి",
        "Բութանի",
        "వెలుతరు",
        "తజికిస్తాన్",
        "టోకెలావ్",
        "Աֆղանստանի",
        "చక్",
        "Jekatyerinburgi",
        "తువాలు",
        "jekatyerinburgi",
        "Jakutszki",
        "jakutszki",
        "వెనిజులా",
        "wake",
        "vosztoki",
        "వోస్టోక్",
        "వేక్",
        "వాలీస్",
        "ఫుటునా",
        "venezuelai",
        "tuvalui",
        "เวลามาตรฐานอาเกร",
        "เวลาฤดูร้อนอาเกร",
        "เวลาอัฟกานิสถาน",
        "เวลาแอฟริกากลาง",
        "เวลาแอฟริกาตะวันออก",
        "เวลาแอฟริกาใต้",
        "เวลามาตรฐานแอฟริกาตะวันตก",
        "เวลาฤดูร้อนแอฟริกาตะวันตก",
        "เวลามาตรฐานอะแลสกา",
        "เวลาออมแสงของอะแลสกา",
        "เวลามาตรฐานอัลมาตี",
        "เวลาฤดูร้อนอัลมาตี",
        "เวลามาตรฐานแอมะซอน",
        "เวลาฤดูร้อนแอมะซอน",
        "เวลามาตรฐานตอนกลางในอเมริกาเหนือ",
        "เวลาออมแสงตอนกลางในอเมริกาเหนือ",
        "เวลามาตรฐานทางตะวันออกในอเมริกาเหนือ",
        "เวลาออมแสงทางตะวันออกในอเมริกาเหนือ",
        "เวลามาตรฐานแถบภูเขาในอเมริกาเหนือ",
        "เวลาออมแสงแถบภูเขาในอเมริกาเหนือ",
        "เวลามาตรฐานแปซิฟิกในอเมริกาเหนือ",
        "เวลาออมแสงแปซิฟิกในอเมริกาเหนือ",
        "เวลามาตรฐานอะนาดีร์",
        "เวลาฤดูร้อนอะนาดีร์",
        "เวลามาตรฐานอาปีอา",
        "เวลาออมแสงอาปีอา",
        "เวลามาตรฐานอัคตาอู",
        "เวลาฤดูร้อนอัคตาอู",
        "เวลามาตรฐานอัคโทเบ",
        "เวลาฤดูร้อนอัคโทเบ",
        "เวลามาตรฐานอาหรับ",
        "เวลาออมแสงอาหรับ",
        "เวลามาตรฐานอาร์เจนตินา",
        "เวลาฤดูร้อนอาร์เจนตินา",
        "เวลามาตรฐานทางตะวันตกของอาร์เจนตินา",
        "เวลาฤดูร้อนทางตะวันตกของอาร์เจนตินา",
        "เวลามาตรฐานอาร์เมเนีย",
        "เวลาฤดูร้อนอาร์เมเนีย",
        "เวลามาตรฐานแอตแลนติก",
        "เวลาออมแสงของแอตแลนติก",
        "เวลามาตรฐานทางตอนกลางของออสเตรเลีย",
        "เวลาออมแสงทางตอนกลางของออสเตรเลีย",
        "เวลามาตรฐานทางตะวันตกตอนกลางของออสเตรเลีย",
        "เวลาออมแสงทางตะวันตกตอนกลางของออสเตรเลีย",
        "เวลามาตรฐานทางตะวันออกของออสเตรเลีย",
        "เวลาออมแสงทางตะวันออกของออสเตรเลีย",
        "เวลามาตรฐานทางตะวันตกของออสเตรเลีย",
        "เวลาออมแสงทางตะวันตกของออสเตรเลีย",
        "เวลามาตรฐานอาเซอร์ไบจาน",
        "เวลาฤดูร้อนอาเซอร์ไบจาน",
        "เวลามาตรฐานอะโซร์ส",
        "เวลาฤดูร้อนอะโซร์ส",
        "เวลามาตรฐานบังกลาเทศ",
        "เวลาฤดูร้อนบังกลาเทศ",
        "เวลาภูฏาน",
        "เวลาโบลิเวีย",
        "เวลามาตรฐานบราซิเลีย",
        "เวลาฤดูร้อนบราซิเลีย",
        "เวลาบรูไนดารุสซาลาม",
        "เวลามาตรฐานเคปเวิร์ด",
        "เวลาฤดูร้อนเคปเวิร์ด",
        "เวลาเคซีย์",
        "เวลาชามอร์โร",
        "เวลามาตรฐานแชทัม",
        "เวลาออมแสงแชทัม",
        "เวลามาตรฐานชิลี",
        "เวลาฤดูร้อนชิลี",
        "เวลามาตรฐานจีน",
        "เวลาออมแสงจีน",
        "เวลามาตรฐานชอยปาลชาน",
        "เวลาฤดูร้อนชอยปาลชาน",
        "เวลาเกาะคริสต์มาส",
        "เวลาหมู่เกาะโคโคส",
        "เวลามาตรฐานโคลอมเบีย",
        "เวลาฤดูร้อนโคลอมเบีย",
        "เวลามาตรฐานหมู่เกาะคุก",
        "เวลาครึ่งฤดูร้อนหมู่เกาะคุก",
        "เวลามาตรฐานคิวบา",
        "เวลาออมแสงของคิวบา",
        "เวลาเดวิส",
        "เวลาดูมองต์ดูร์วิลล์",
        "เวลาติมอร์ตะวันออก",
        "เวลามาตรฐานเกาะอีสเตอร์",
        "เวลาฤดูร้อนเกาะอีสเตอร์",
        "เวลาเอกวาดอร์",
        "เวลามาตรฐานยุโรปกลาง",
        "เวลาฤดูร้อนยุโรปกลาง",
        "เวลามาตรฐานยุโรปตะวันออก",
        "เวลาฤดูร้อนยุโรปตะวันออก",
        "เวลายุโรปตะวันออกไกล",
        "เวลามาตรฐานยุโรปตะวันตก",
        "เวลาฤดูร้อนยุโรปตะวันตก",
        "เวลามาตรฐานหมู่เกาะฟอล์กแลนด์",
        "เวลาฤดูร้อนหมู่เกาะฟอล์กแลนด์",
        "เวลามาตรฐานฟิจิ",
        "เวลาฤดูร้อนฟิจิ",
        "เวลาเฟรนช์เกียนา",
        "เวลาเฟรนช์เซาเทิร์นและแอนตาร์กติก",
        "เวลากาลาปาโกส",
        "เวลาแกมเบียร์",
        "เวลามาตรฐานจอร์เจีย",
        "เวลาฤดูร้อนจอร์เจีย",
        "เวลาหมู่เกาะกิลเบิร์ต",
        "เวลามาตรฐานกรีนิช",
        "เวลามาตรฐานกรีนแลนด์ตะวันออก",
        "เวลาฤดูร้อนกรีนแลนด์ตะวันออก",
        "เวลามาตรฐานกรีนแลนด์ตะวันตก",
        "เวลาฤดูร้อนกรีนแลนด์ตะวันตก",
        "เวลากวม",
        "เวลากัลฟ์",
        "เวลากายอานา",
        "เวลามาตรฐานฮาวาย",
        "truki",
        "เวลาออมแสงฮาวาย",
        "เวลามาตรฐานฮ่องกง",
        "เวลาฤดูร้อนฮ่องกง",
        "เวลามาตรฐานฮอฟด์",
        "เวลาฤดูร้อนฮอฟด์",
        "เวลาอินเดีย",
        "เวลามหาสมุทรอินเดีย",
        "เวลาอินโดจีน",
        "เวลาอินโดนีเซียตอนกลาง",
        "เวลาอินโดนีเซียฝั่งตะวันออก",
        "เวลาอินโดนีเซียฝั่งตะวันตก",
        "เวลามาตรฐานอิหร่าน",
        "เวลาออมแสงอิหร่าน",
        "เวลามาตรฐานอีร์คุตสค์",
        "เวลาฤดูร้อนอีร์คุตสค์",
        "เวลามาตรฐานอิสราเอล",
        "เวลาออมแสงอิสราเอล",
        "เวลามาตรฐานญี่ปุ่น",
        "เวลาออมแสงญี่ปุ่น",
        "เวลาเปโตรปัฟลอฟสค์",
        "tokelaui",
        "เวลาฤดูร้อนเปโตรปัฟลอฟสค์",
        "เวลาคาซัคสถานตะวันออก",
        "เวลาคาซัคสถานตะวันตก",
        "เวลามาตรฐานเกาหลี",
        "เวลาออมแสงเกาหลี",
        "เวลาคอสไร",
        "เวลามาตรฐานครัสโนยาสค์",
        "เวลาฤดูร้อนครัสโนยาสค์",
        "เวลาคีร์กีซสถาน",
        "เวลาลังกา",
        "เวลาหมู่เกาะไลน์",
        "เวลามาตรฐานลอร์ดโฮว์",
        "เวลาออมแสงลอร์ดโฮว์",
        "เวลามาตรฐานมาเก๊า",
        "เวลาฤดูร้อนมาเก๊า",
        "เวลาเกาะแมกควอรี",
        "เวลามาตรฐานมากาดาน",
        "เวลาฤดูร้อนมากาดาน",
        "เวลามาเลเซีย",
        "เวลามัลดีฟส์",
        "เวลามาร์เคซัส",
        "เวลาหมู่เกาะมาร์แชลล์",
        "เวลามาตรฐานมอริเชียส",
        "เวลาฤดูร้อนของมอริเชียส",
        "เวลามอว์สัน",
        "เวลามาตรฐานเม็กซิโกตะวันตกเฉียงเหนือ",
        "เวลาออมแสงเม็กซิโกตะวันตกเฉียงเหนือ",
        "เวลามาตรฐานแปซิฟิกเม็กซิโก",
        "เวลาออมแสงแปซิฟิกเม็กซิโก",
        "เวลามาตรฐานอูลานบาตอร์",
        "เวลาฤดูร้อนอูลานบาตอร์",
        "เวลามาตรฐานมอสโก",
        "เวลาฤดูร้อนมอสโก",
        "เวลาพม่า",
        "เวลานาอูรู",
        "เวลาเนปาล",
        "เวลามาตรฐานนิวแคลิโดเนีย",
        "เวลาฤดูร้อนนิวแคลิโดเนีย",
        "เวลามาตรฐานนิวซีแลนด์",
        "เวลาออมแสงนิวซีแลนด์",
        "เวลามาตรฐานนิวฟันด์แลนด์",
        "เวลาออมแสงนิวฟันด์แลนด์",
        "เวลานีอูเอ",
        "เวลาเกาะนอร์ฟอล์ก",
        "เวลามาตรฐานหมู่เกาะเฟอร์นันโด",
        "เวลาฤดูร้อนของหมู่เกาะเฟอร์นันโด",
        "เวลาหมู่เกาะมาเรียนาเหนือ",
        "เวลามาตรฐานโนโวซีบีสค์",
        "เวลาฤดูร้อนโนโวซีบีสค์",
        "เวลามาตรฐานออมสค์",
        "เวลาฤดูร้อนออมสค์",
        "เวลามาตรฐานปากีสถาน",
        "เวลาฤดูร้อนปากีสถาน",
        "เวลาปาเลา",
        "เวลาปาปัวนิวกินี",
        "เวลามาตรฐานปารากวัย",
        "เวลาฤดูร้อนปารากวัย",
        "เวลามาตรฐานเปรู",
        "เวลาฤดูร้อนเปรู",
        "เวลามาตรฐานฟิลิปปินส์",
        "เวลาฤดูร้อนฟิลิปปินส์",
        "เวลาหมู่เกาะฟินิกซ์",
        "เวลามาตรฐานแซงปีแยร์และมีเกอลง",
        "เวลาออมแสงของแซงปีแยร์และมีเกอลง",
        "เวลาพิตแคร์น",
        "เวลาโปนาเป",
        "เวลาเปียงยาง",
        "เวลามาตรฐานคืยซิลออร์ดา",
        "เวลาฤดูร้อนคืยซิลออร์ดา",
        "เวลาเรอูนียง",
        "เวลาโรธีรา",
        "เวลามาตรฐานซาคาลิน",
        "เวลาฤดูร้อนซาคาลิน",
        "เวลามาตรฐานซามารา",
        "เวลาฤดูร้อนซามารา",
        "เวลามาตรฐานซามัว",
        "เวลาฤดูร้อนซามัว",
        "เวลาเซเชลส์",
        "เวลาสิงคโปร์",
        "เวลาหมู่เกาะโซโลมอน",
        "เวลาเซาท์จอร์เจีย",
        "เวลาซูรินาเม",
        "เวลาไซโยวา",
        "เวลาตาฮีตี",
        "เวลามาตรฐานไทเป",
        "เวลาออมแสงไทเป",
        "เวลาทาจิกิสถาน",
        "เวลาโตเกเลา",
        "เวลามาตรฐานตองกา",
        "เวลาฤดูร้อนตองกา",
        "เวลาชุก",
        "เวลามาตรฐานเติร์กเมนิสถาน",
        "เวลาฤดูร้อนเติร์กเมนิสถาน",
        "เวลาตูวาลู",
        "เวลามาตรฐานอุรุกวัย",
        "เวลาฤดูร้อนอุรุกวัย",
        "เวลามาตรฐานอุซเบกิสถาน",
        "เวลาฤดูร้อนอุซเบกิสถาน",
        "เวลามาตรฐานวานูอาตู",
        "เวลาฤดูร้อนวานูอาตู",
        "เวลาเวเนซุเอลา",
        "เวลามาตรฐานวลาดีวอสตอค",
        "เวลาฤดูร้อนวลาดีวอสตอค",
        "เวลามาตรฐานวอลโกกราด",
        "เวลาฤดูร้อนวอลโกกราด",
        "เวลาวอสตอค",
        "เวลาเกาะเวก",
        "เวลาวาลลิสและฟุตูนา",
        "เวลามาตรฐานยาคุตสค์",
        "เวลาฤดูร้อนยาคุตสค์",
        "เวลามาตรฐานเยคาเตรินบูร์ก",
        "เวลาฤดูร้อนเยคาเตรินบูร์ก",
        "Owganystan",
        "tádzsikisztáni",
        "tahiti",
        "syowai",
        "szurinámi",
        "georgiai",
        "salamon",
        "szingapúri",
        "seychelle",
        "rotherai",
        "réunioni",
        "phenjani",
        "ponape",
        "pitcairn",
        "phoenix",
        "guineai",
        "pápua",
        "palaui",
        "mariana",
        "Észak",
        "norfolk",
        "niuei",
        "nepáli",
        "naurui",
        "Boliwiýa",
        "mianmari",
        "Bruneý",
        "mawsoni",
        "marshall",
        "marquises",
        "maldív",
        "malajziai",
        "Krismas",
        "magadáni",
        "magadani",
        "Deýwis",
        "Dýumon",
        "d",
        "Ýurwil",
        "macquarie",
        "sor",
        "Uzak",
        "Lankai",
        "kirgizisztáni",
        "Gambýe",
        "kosraei",
        "Grinwiç",
        "boýunça",
        "orta",
        "indokínai",
        "Pars",
        "aýlagy",
        "Gaýana",
        "guyanai",
        "öbölbeli",
        "középidő",
        "ummany",
        "Hindihytaý",
        "greenwichi",
        "gilbert",
        "gambieri",
        "galápagosi",
        "antarktiszi",
        "guianai",
        "Kosraýe",
        "minszki",
        "Gyrgyzstan",
        "Laýn",
        "ecuadori",
        "Malaýziýa",
        "Maldiwler",
        "timori",
        "dumont",
        "davisi",
        "fél",
        "Mýanma",
        "kókusz",
        "karácsony",
        "chamorrói",
        "bolíviai",
        "butáni",
        "Nowosibisk",
        "Argentínai",
        "Gwineýa",
        "dél",
        "afganisztáni",
        "Wostokski",
        "Reýunýon",
        "Seýşel",
        "Georgiýa",
        "Sýowa",
        "Taýiti",
        "venezuelski",
        "Täjigistan",
        "tuvaluski",
        "Tuwalu",
        "chuukski",
        "tokelauski",
        "tadźikski",
        "Wenesuela",
        "Weýk",
        "Wollis",
        "tahitiski",
        "Syowaski",
        "surinamski",
        "južnogeorgiski",
        "Salomonskich",
        "seychellski",
        "Rotheraski",
        "fakaʻafikānisitani",
        "reunionski",
        "ponapeski",
        "nowoginejski",
        "tonga",
        "papua",
        "palauski",
        "niueski",
        "nepalski",
        "nauruski",
        "myanmarski",
        "marquesaski",
        "malediwski",
        "malajziski",
        "kirgiski",
        "kosraeski",
        "zapadnokazachski",
        "wuchodnokazachski",
        "zapadoindoneski",
        "wuchodoindoneski",
        "srjedźoindoneski",
        "indochinski",
        "indiskooceanski",
        "indiski",
        "guyanski",
        "Persiskeho",
        "fakapūtani",
        "fakapolīvia",
        "gambierski",
        "fakapulunei",
        "galapagoski",
        "teritorija",
        "fakakeesi",
        "fakakamolo",
        "južneho",
        "Francoskeho",
        "francoskoguyanski",
        "ekwadorski",
        "fakamotukilisimasi",
        "fakamotukokosi",
        "wuchodnotimorski",
        "Urvilleski",
        "d´",
        "fakatavisi",
        "fakatūmoni",
        "tūvile",
        "fakatimoa",
        "Daviski",
        "fakaʻekuetoa",
        "Hodowneje",
        "ange",
        "chamorroski",
        "bruneiski",
        "boliwiski",
        "bhutanski",
        "fakafalanisē",
        "fakaʻanetātikafalanisē",
        "fakakalapakosi",
        "fakakamipiē",
        "sjedźozapadny",
        "fakakilipasi",
        "fakakiliniuisi",
        "mālie",
        "srjedźozapadny",
        "fakakuami",
        "fakakūlifi",
        "južnoafriski",
        "wuchodoafriski",
        "centralnoafriski",
        "afghanski",
        "fakaʻinitia",
        "fakamoanaʻinitia",
        "fakaʻinitosiaina",
        "फ़्यूचूना",
        "वालिस",
        "वोस्तोक",
        "सूरीनाम",
        "सोलोमन",
        "सेशल्स",
        "रीयूनियन",
        "प्योंगयांग",
        "fakakosilae",
        "फ़ीनिक्स",
        "fakakīkisitani",
        "fakalangikā",
        "fakaʻotumotulaine",
        "पलाउ",
        "नॉरफ़ॉक",
        "fakamotumakuali",
        "नीयू",
        "fakamaleisia",
        "fakamalativisi",
        "fakamākesasi",
        "fakaʻotumotumasolo",
        "नौरू",
        "fakamausoni",
        "म्यांमार",
        "माव्सन",
        "मार्केसस",
        "मक्वारी",
        "fakapema",
        "fakanaulu",
        "fakanepali",
        "किर्गिस्‍तान",
        "इंडोचाइना",
        "हिंद",
        "fakaniuē",
        "fakanoafōki",
        "खाड़ी",
        "टाइम",
        "मीन",
        "fakamalianatokelau",
        "गैंबियर",
        "का",
        "गैलापेगोस",
        "fakapalau",
        "fakapapuaniukini",
        "अंटार्कटिक",
        "फ़्रांस",
        "फ़्रेंच",
        "fakaʻotumotufoinikisi",
        "इक्वाडोर",
        "डर्विल",
        "ड्यूमोंट",
        "डेविस",
        "fakapitikani",
        "fakapōnapē",
        "अर्द्ध",
        "fakalēunioni",
        "fakalotela",
        "क्रिसमस",
        "ब्रूनेई",
        "बोलीविया",
        "seiseli",
        "fakasingapoa",
        "fakaʻotumotusolomone",
        "fakasiosiatonga",
        "fakasuliname",
        "fakasioua",
        "fakatahisi",
        "भूटान",
        "fakatasikitani",
        "fakatokelau",
        "अफ़गानिस्तान",
        "fakatūke",
        "ופוטונה",
        "fakatūvalu",
        "וואליס",
        "וייק",
        "ווסטוק",
        "fakavenesuela",
        "ונצואלה",
        "טובאלו",
        "fakavositoki",
        "fakamotuueke",
        "fakaʻuvea",
        "futuna",
        "צ׳וק",
        "טוקלאו",
        "טג׳יקיסטן",
        "טהיטי",
        "סייווה",
        "סורינאם",
        "ג׳ורג׳יה",
        "שלמה",
        "סינגפור",
        "סיישל",
        "רות׳רה",
        "ראוניון",
        "פיונגיאנג",
        "פונאפי",
        "פיטקרן",
        "פיניקס",
        "גיניאה",
        "פפואה",
        "פלאו",
        "נורפוק",
        "ניואה",
        "נפאל",
        "נאורו",
        "מיאנמר",
        "מאוסון",
        "מרשל",
        "מרקיז",
        "המלדיביים",
        "מלזיה",
        "מקווארי",
        "חורף",
        "ליין",
        "קירגיזסטן",
        "קוסראה",
        "אירקוסטק",
        "אירקוטסק",
        "ההודי",
        "המפרץ",
        "מדינות",
        "גריניץ׳‏",
        "גילברט",
        "גמבייה",
        "גלאפגוס",
        "צרפת",
        "של",
        "והאנטארקטיות",
        "הדרומיות",
        "הארצות",
        "הצרפתית",
        "מינסק",
        "אקוודור",
        "טימור",
        "ד׳אורוויל",
        "דומון",
        "ئاكرې",
        "דיוויס",
        "הקיץ)",
        "ئاكرى",
        "(מחצית",
        "ئافغانىستان",
        "קוקוס",
        "כריסטמס",
        "צ׳אמורו",
        "דארוסלאם",
        "ברוניי",
        "בוליביה",
        "בהוטן",
        "אפגניסטן",
        "ફ્યુચુના",
        "વૉલિસ",
        "વૅક",
        "વોસ્ટોક",
        "વેનેઝુએલા",
        "ટવાલૂ",
        "ચુઉક",
        "ટોકલાઉ",
        "તાજીકિસ્તાન",
        "તાહિતી",
        "સ્યોવા",
        "સૂરીનામ",
        "ئاۋستىرالىيە",
        "જ્યોર્જીયા",
        "સોલોમન",
        "સિંગાપુર",
        "بۇتان",
        "بولىۋىيە",
        "સેશલ્સ",
        "بىرۇنىي",
        "دارۇسسالام",
        "રોથેરા",
        "રીયૂનિયન",
        "كاسېي",
        "چاموررو",
        "યોન્ગ્યાન્ગ",
        "પોનપે",
        "પિટકેયર્ન",
        "ફોનિક્સ",
        "روژدېستۋو",
        "ગિની",
        "كوكۇس",
        "પાપુઆ",
        "પલાઉ",
        "ઓમ્સ્ક",
        "يېرىم",
        "ઓમસ્ક",
        "داۋىس",
        "دۇمونت",
        "دۇرۋىل",
        "تىمور",
        "پاسكاليا",
        "ئېستېر",
        "ئېكۋادور",
        "મારિયાના",
        "નોરોહા",
        "દે",
        "ફરનેન્ડો",
        "નોરોન્હા",
        "ડી",
        "ફર્નાન્ડો",
        "ئانتاركتىكا",
        "گامبىيېر",
        "નોરફૉક",
        "گىلبېرت",
        "گىرىنۋىچ",
        "નીયુ",
        "گۇئام",
        "گۇلف",
        "નેપાળ",
        "નૌરુ",
        "મ્યાનમાર",
        "બટોર",
        "ھىندىستان",
        "બાટોર",
        "چىنى",
        "મોસન",
        "માર્શલ",
        "માર્ક્યૂસસ",
        "માલદીવ",
        "મલેશિયા",
        "મેક્વાયર",
        "લાઇન",
        "લંકા",
        "કિર્ગિઝતાન",
        "كوسرائې",
        "ક્રેસનોયાર્સ્ક",
        "قىرغىزىستان",
        "سىرى",
        "لانكا",
        "ક્રસ્નોયાર્સ્ક",
        "કોસરે",
        "ماككۇۋارى",
        "مالايشىيا",
        "مالدىۋې",
        "ماركىز",
        "ઇન્ડોચાઇના",
        "ماۋسون",
        "મહાસાગર",
        "ગલ્ફ",
        "ગ્વામ",
        "મધ્યમ",
        "بىرما",
        "ناۋرۇ",
        "نېپال",
        "ગ્રીનવિચ",
        "ગિલબર્ટ",
        "ગેમ્બિયર",
        "ગાલાપાગોસ",
        "نىيۇئې",
        "એન્ટાર્કટિક",
        "સાઉધર્ન",
        "مارىيانا",
        "ફોકલૅંડ",
        "ફૉકલૅંડ",
        "આગળનું",
        "پالاۋ",
        "پاپۇئا",
        "گىۋىنېيەسى",
        "એક્વાડોર",
        "તિમોર",
        "ડી‘ઉર્વિલ",
        "فېنىكس",
        "ડ્યુમોન્ટ",
        "ડેવિસ",
        "અર્ધ",
        "پىتكاير",
        "پونپېي",
        "કોકોઝ",
        "رېئونىيون",
        "روتېرا",
        "ક્રિસમસ",
        "ساموئا",
        "سەمەرقەنت",
        "سېيشېل",
        "سىنگاپور",
        "سولومون",
        "جورجىيە",
        "سۇرىنام",
        "شوۋا",
        "تايتى",
        "કેમોરો",
        "تاجىكىستان",
        "توكېلاۋ",
        "چۇك",
        "દારુસલામ",
        "تۇۋالۇ",
        "બ્રૂનેઈ",
        "બોલિવિયા",
        "ભૂટાન",
        "ۋېنېزۇئېلا",
        "આર્જેન્ટીના",
        "અપિયા",
        "ۋوستوك",
        "ۋېيك",
        "ۋاللىس",
        "فۇتۇنا",
        "એપિયા",
        "કેન્દ્રિય",
        "અફઘાનિસ્તાન",
        "Toquelau",
        "Sakhalín",
        "Liña",
        "Quirguicistán",
        "europeo",
        "Афганістані",
        "центральноафриканським",
        "східноафриканським",
        "південноафриканським",
        "extremo",
        "medio",
        "Nadal",
        "chamorro",
        "Uallas",
        "Bheiniseala",
        "Tubhalu",
        "Taidigeastàin",
        "Shyowa",
        "Suranaim",
        "Seòrsea",
        "Sholaimh",
        "Singeapòr",
        "Sheiseall",
        "Chàirn",
        "Peit",
        "Paputhaiche",
        "Gini",
        "Palabh",
        "Mairianach",
        "Neapàl",
        "Nabhru",
        "Miànmar",
        "Mhawson",
        "Mharshall",
        "Бутані",
        "болівійським",
        "Mharcais",
        "Брунеї",
        "Mhaladaibh",
        "Північних",
        "Маріанських",
        "Mhalaidhsea",
        "MhicGuaire",
        "Teraina",
        "Cìorgastain",
        "Різдва",
        "Кокосових",
        "Innseanach",
        "Sìn",
        "Девіс",
        "дʼЮрвіль",
        "Східному",
        "Тиморі",
        "Еквадорі",
        "Cuan",
        "Chamais",
        "далекосхідним",
        "європейським",
        "Ghileabairt",
        "Ghalapagos",
        "Antartaigeach",
        "Французької",
        "Гвіани",
        "Французьких",
        "Південних",
        "Антарктичних",
        "територіях",
        "Галапагоських",
        "Гамб’є",
        "Deasach",
        "Гринвічем",
        "ear",
        "fhaide",
        "Перської",
        "затоки",
        "Гаяні",
        "nas",
        "Eacuadoir",
        "Thìomor",
        "Dhavis",
        "індійським",
        "Індійському",
        "Океані",
        "Індокитаї",
        "центральноіндонезійським",
        "східноіндонезійським",
        "західноіндонезійським",
        "thìde",
        "Leth",
        "Chocos",
        "Nollaig",
        "Chasey",
        "Salàm",
        "as",
        "Dàr",
        "Киргизстані",
        "Маккуорі",
        "Bhrùnaigh",
        "Малайзії",
        "Мальдівах",
        "Маркізьких",
        "Маршалових",
        "Boilibhia",
        "Butàin",
        "Afghanastàin",
        "Futúna",
        "Vailís",
        "Veiniséala",
        "Мʼянмі",
        "Непалі",
        "Thuvalu",
        "Tócalá",
        "Táidsíceastáine",
        "Thaihítí",
        "Ніуе",
        "Shuranam",
        "Sholomón",
        "Shingeapór",
        "Séiséal",
        "Гвінея",
        "Phohnpei",
        "Fhéinics",
        "Phapua",
        "Піткерн",
        "Пхеньяні",
        "Реюньйон",
        "Ghuine",
        "Thuaidh",
        "Сейшельських",
        "Сінгапурі",
        "Соломонових",
        "Південна",
        "Джорджія",
        "Суринамі",
        "Сева",
        "Таїті",
        "Máirianach",
        "Таджикистані",
        "Neipeal",
        "Mhaenmar",
        "Marcasach",
        "Венесуелі",
        "Mhaildíve",
        "Malaeisia",
        "Уолліс",
        "Guaire",
        "Mhic",
        "Líne",
        "Shrí",
        "Cirgeastáine",
        "hInd",
        "Indiaigh",
        "hIndia",
        "Guáine",
        "Murascaille",
        "Ghuam",
        "MAG",
        "Meán",
        "Chireabaití",
        "Domhain",
        "Deisceart",
        "Francacha",
        "Chríocha",
        "Fraince",
        "Ghuáin",
        "Chianoirthear",
        "Eacuadór",
        "Thoir",
        "Thíomór",
        "Leathshamhraidh",
        "Nollag",
        "Seamórach",
        "Darasalám",
        "Brúiné",
        "Bolaive",
        "Bútáine",
        "hAfganastáine",
        "Fenezolaanske",
        "Tuvaluaanske",
        "Tahitiaanske",
        "Surinaamske",
        "Salomonseilânske",
        "Pitcairneillânske",
        "Phoenixeilânske",
        "Guineeske",
        "Mariaanske",
        "Noardlike",
        "Norfolkeilânske",
        "Nauruaanske",
        "Marshalleilânske",
        "Marquesaseilânske",
        "Maldivyske",
        "Maleisyske",
        "Kirgizyske",
        "Yndochinese",
        "Yndyske",
        "Yndiaaske",
        "Gilberteilânske",
        "Gambiereilânske",
        "Galapagoseilânske",
        "Antarctyske",
        "Súdlike",
        "Frânske",
        "Frâns",
        "Ecuadoraanske",
        "Kokoseilânske",
        "Krysteilânske",
        "Boliviaanske",
        "Bhutaanske",
        "Afghaanske",
        "GFT",
        "HAT",
        "HNT",
        "HAP",
        "مین",
        "HNP",
        "HAR",
        "HNR",
        "HAE",
        "HNE",
        "انڈیا",
        "HAC",
        "HNC",
        "Heure",
        "Lorde",
        "Antartiko",
        "at",
        "France",
        "Katimugang",
        "silangang",
        "Pinaka",
        "Kalahati",
        "Futunan",
        "Wallisin",
        "Waken",
        "Vostokin",
        "Venezuelan",
        "Tuvalun",
        "Chuukin",
        "Tokelaun",
        "Tadžikistanin",
        "Tahitin",
        "Syowan",
        "Surinamen",
        "Salomonsaarten",
        "Singaporen",
        "Seychellien",
        "Rotheran",
        "Réunionin",
        "Pjongjangin",
        "Pohnpein",
        "Pitcairnin",
        "Phoenixsaarten",
        "Guinean",
        "Palaun",
        "Mariaanien",
        "Pohjois",
        "Norfolkinsaarten",
        "Niuen",
        "Nepalin",
        "Naurun",
        "Myanmarin",
        "Mawsonin",
        "Marshallinsaarten",
        "Marquesasin",
        "Malediivien",
        "Malesian",
        "Macquariensaaren",
        "Linesaarten",
        "Lankan",
        "Kirgisian",
        "Kosraen",
        "Indokiinan",
        "valtameren",
        "Guyanan",
        "Arabiemiirikuntien",
        "Guamin",
        "Greenwichin",
        "Gilbertsaarten",
        "Gambierin",
        "Galápagossaarten",
        "alueiden",
        "antarktisten",
        "eteläisten",
        "Guayanan",
        "Itäisemmän",
        "Ecuadorin",
        "Timorin",
        "d’Urvillen",
        "Davisin",
        "Kookossaarten",
        "Joulusaaren",
        "Tšamorron",
        "Caseyn",
        "Brunein",
        "Bolivian",
        "Bhutanin",
        "Afganistanin",
        "Yekaterinburgeko",
        "Jekaterinburgeko",
        "Futunako",
        "Vostokeko",
        "Venezuelako",
        "امیزون",
        "اصل",
        "ہندوستان",
        "Афғонистон",
        "Tuvaluko",
        "Chuukeko",
        "Tokelauko",
        "Tadjikistango",
        "Tahitiko",
        "Syowako",
        "Surinamgo",
        "Hego",
        "Singapurreko",
        "Rotherako",
        "Piongiangeko",
        "Ponapeko",
        "Pitcairneko",
        "Ginea",
        "Palauko",
        "Niueko",
        "Даруссалом",
        "Каморро",
        "Nepalgo",
        "Nauruko",
        "Myanmarreko",
        "Mawsoneko",
        "ярим",
        "Думонт",
        "Markesetako",
        "Гвианаси",
        "жанубий",
        "Гамбиер",
        "Кўрфаз",
        "Maldivetako",
        "Ҳиндистон",
        "Malaysiako",
        "Kirgizistango",
        "Kosraeko",
        "Indotxinako",
        "Қирғизистон",
        "Indikoko",
        "Маквари",
        "ороллар",
        "Моувсон",
        "Ozeano",
        "Indiako",
        "Сьова",
        "Тожикистон",
        "Guyanako",
        "Golkoko",
        "Уэллис",
        "meridianoko",
        "Gambierreko",
        "Galapagoetako",
        "antartikoetako",
        "austral",
        "lurralde",
        "Frantziaren",
        "Frantseseko",
        "Europako",
        "urruneko",
        "Ekialde",
        "Ekuadorreko",
        "Timorreko",
        "Ekialdeko",
        "d’Urvilleko",
        "Daviseko",
        "uda",
        "Chamorroko",
        "Darussalamgo",
        "Boliviako",
        "Bhutango",
        "hegoaldeko",
        "Afganistango",
        "Jakaterinburgi",
        "Jekaterinburgi",
        "Wallise",
        "Wake’i",
        "Vostoki",
        "Chuuki",
        "Tadžikistani",
        "Saalomoni",
        "Singapuri",
        "Seišelli",
        "Réunioni",
        "Pyongyangi",
        "Pitcairni",
        "Fööniksisaarte",
        "Paapua",
        "Belau",
        "Norfolki",
        "Nepali",
        "Birma",
        "Mawsoni",
        "Marshalli",
        "Markiisaarte",
        "Maldiivi",
        "​​aeg",
        "saare",
        "Line’i",
        "Kõrgõzstani",
        "Indohiina",
        "lahe",
        "Pärsia",
        "Greenwichi",
        "Gilberti",
        "Gambier’",
        "Galapagose",
        "Lõunaalade",
        "Antarktiliste",
        "Guajaana",
        "Valgevene",
        "Kaliningradi",
        "Ecuadori",
        "Timori",
        "d’Urville’i",
        "Davise",
        "osaline",
        "Kookossaarte",
        "Jõulusaare",
        "Tšamorro",
        "Boliivia",
        "Bhutani",
        "Afganistani",
        "VET",
        "UYST",
        "UYT",
        "COST",
        "COT",
        "CLST",
        "CLT",
        "WARST",
        "WART",
        "ARST",
        "ART",
        "CHADT",
        "CHAST",
        "GYT",
        "ChST",
        "NDT",
        "NST",
        "mây",
        "tây",
        "ཡེ་ཀ་ཏེ་རིན་བརག་བྱཱར་དུས་ཆུ་ཚོད",
        "ཡེ་ཀ་ཏེ་རིན་བརག་ཚད་ལྡན་ཆུ་ཚོད",
        "ཡ་ཀུཙིཀི་བྱཱར་དུས་ཆུ་ཚོད",
        "ཡ་ཀུཙིཀི་ཚད་ལྡན་ཆུ་ཚོད",
        "བཱོལ་གོ་གིརེཌ་བྱཱར་དུས་ཆུ་ཚོད",
        "བཱོལ་གོ་གིརེཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "བ་ལ་ཌི་བོསི་ཏོཀ་བྱཱར་དུས་ཆུ་ཚོད",
        "Nửa",
        "བ་ལ་ཌི་བོསི་ཏོཀ་ཚད་ལྡན་ཆུ་ཚོད",
        "བེ་ནི་ཛུ་ཝེ་ལ་ཆུ་ཚོད",
        "ཡུ་རུ་གུ་ཝཱའི་བྱཱར་དུས་ཆུ་ཚོད",
        "Châu",
        "Viễn",
        "thuộc",
        "ཡུ་རུ་གུ་ཝཱའི་ཚད་ལྡན་ཆུ་ཚོད",
        "Cực",
        "སུ་རི་ནཱམ་ཆུ་ཚོད",
        "སེ་ཤཱལས་ཆུ་ཚོད",
        "bình",
        "Vùng",
        "Vịnh",
        "ས་ཁ་ལིན་བྱཱར་དུས་ཆུ་ཚོད",
        "ས་ཁ་ལིན་ཚད་ལྡན་ཆུ་ཚོད",
        "རི་ཡུ་ནི་ཡཱན་ཆུ་ཚོད",
        "པའི་རི་དང་མི་ཀི་ལཱོན་ཉིན་སྲུང་ཆུ་ཚོད",
        "པའི་རི་དང་མི་ཀི་ལཱོན་ཚད་ལྡན་ཆུ་ཚོད",
        "པ་རུ་བྱཱར་དུས་ཆུ་ཚོད",
        "པ་རུ་ཚད་ལྡན་ཆུ་ཚོད",
        "པ་ར་གུ་ཝའི་བྱཱར་དུས་ཆུ་ཚོད",
        "པ་ར་གུ་ཝའི་ཚད་ལྡན་ཆུ་ཚོད",
        "པ་ཀི་ས྄ཏཱན་བྱཱར་དུས་ཆུ་ཚོད",
        "པ་ཀི་ས྄ཏཱན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨོམསཀི་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨོམསཀི་ཚད་ལྡན་ཆུ་ཚོད",
        "Nhưỡng",
        "ban",
        "ngày",
        "ནོ་བོ་སི་བིརསཀི་བྱཱར་དུས་ཆུ་ཚོད",
        "ནོ་བོ་སི་བིརསཀི་ཚད་ལྡན་ཆུ་ཚོད",
        "ནོ་རཱོན་ཧ་བྱཱར་དུས་ཆུ་ཚོད",
        "ནོ་རཱོན་ཧ་ཚད་ལྡན་ཆུ་ཚོད",
        "ནིའུ་ཕའུནཌ་ལེནཌ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ནིའུ་ཕའུནཌ་ལེནཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "ནིའུ་ཛི་ལེནཌ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ནིའུ་ཛི་ལེནཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "ནེ་པཱལ་ཆུ་ཚོད",
        "མཽས་ཀོ་བྱཱར་དུས་ཆུ་ཚོད",
        "མཽས་ཀོ་ཚད་ལྡན་ཆུ་ཚོད",
        "མོ་རི་ཤཱས་བྱཱར་དུས་ཆུ་ཚོད",
        "མོ་རི་ཤཱས་ཚད་ལྡན་ཆུ་ཚོད",
        "མཱལ་དིབས་ཆུ་ཚོད",
        "མ་གྷ་དཱན་བྱཱར་དུས་ཆུ་ཚོད",
        "མ་གྷ་དཱན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཀརསི་ནོ་ཡརསཀི་བྱཱར་དུས་ཆུ་ཚོད",
        "ཀརསི་ནོ་ཡརསཀི་ཚད་ལྡན་ཆུ་ཚོད",
        "ཀོ་རི་ཡ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཀོ་རི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཇ་པཱན་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཇ་པཱན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨིས་རེལ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཨིས་རེལ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨར་ཀུཙི་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨར་ཀུཙི་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨི་རཱན་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཨི་རཱན་ཚད་ལྡན་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨིན་ཌོ་ནེ་ཤི་ཡ་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཨིན་ཌོ་ནེ་ཤི་ཡ་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཨིན་ཌོ་ནེ་ཤི་ཡ་ཆུ་ཚོད",
        "ཨིན་ཌོ་ཅཱའི་ན་ཆུ་ཚོད",
        "རྒྱ་གར་གྱི་རྒྱ་མཚོ་ཆུ་ཚོད",
        "རྒྱ་གར་ཆུ་ཚོད",
        "ཨེ་ལིའུ་ཤེན་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཨེ་ལིའུ་ཤེན་ཚད་ལྡན་ཆུ་ཚོད",
        "གུ་ཡ་ན་ཆུ་ཚོད",
        "གཱལཕི་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་གིརིན་ལེནཌ་བྱཱར་དུས་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་གིརིན་ལེནཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་གིརིན་ལེནཌ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་གིརིན་ལེནཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "གིརིན་ཝིཆ་ལུ་ཡོད་པའི་ཆུ་ཚོད",
        "ཇཽ་ཇཱ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཇཽ་ཇཱ་ཚད་ལྡན་ཆུ་ཚོད",
        "ག་ལ་པ་གོསི་ཆུ་ཚོད",
        "ཕིརེནཅ་གི་ཡ་ན་ཆུ་ཚོད",
        "ཕལཀ་ལེནཌ་ཨཱའི་ལེནཌས་བྱཱར་དུས་ཆུ་ཚོད",
        "ཕལཀ་ལེནཌ་ཨཱའི་ལེནཌས་ཚད་ལྡན་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཡུ་རོ་པེན་བྱཱར་དུས་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཡུ་རོ་པེན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཡུ་རོ་པེན་བྱཱར་དུས་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཡུ་རོ་པེན་ཚད་ལྡན་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཡུ་རོ་པེན་བྱཱར་དུས་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཡུ་རོ་པེན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨེ་ཀུ་ཌཽ་ཆུ་ཚོད",
        "ཨིསི་ཊར་ཨཱའི་ལེནཌ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨིསི་ཊར་ཨཱའི་ལེནཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཀིའུ་བྷ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཀིའུ་བྷ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཀོ་ལོམ་བྷི་ཡ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཀོ་ལོམ་བྷི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཁི་རིསྟ་མེས་མཚོ་གླིང་ཆུ་ཚོད",
        "རྒྱ་ནག་ཉིན་སྲུང་ཆུ་ཚོད",
        "རྒྱ་ནག་ཚད་ལྡན་ཆུ་ཚོད",
        "ཅི་ལི་བྱཱར་དུས་ཆུ་ཚོད",
        "ཅི་ལི་ཚད་ལྡན་ཆུ་ཚོད",
        "ཀེཔ་བཱཌ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཀེཔ་བཱཌ་ཚད་ལྡན་ཆུ་ཚོད",
        "བྲ་ཛི་ལི་ཡ་བྱཱར་དུས་ཆུ་ཚོད",
        "བྲ་ཛི་ལི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "བྷོ་ལི་བི་ཡ་ཆུ་ཚོད",
        "འབྲུག་ཡུལ་ཆུ་ཚོད",
        "བངྒ་ལ་དེཤ་བྱཱར་དུས་ཆུ་ཚོད",
        "བངྒ་ལ་དེཤ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨེ་ཛོརས་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨེ་ཛོརས་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨ་ཛར་བྷའི་ཇཱན་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨ་ཛར་བྷའི་ཇཱན་ཚད་ལྡན་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "དབུས་ནུབ་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཉིན་སྲུང་ཆུ་ཚོད",
        "དབུས་ནུབ་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཉིན་སྲུང་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཨཱོས་ཊྲེལ་ལི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨེཊ་ལེན་ཊིཀ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཨེཊ་ལེན་ཊིཀ་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨར་མི་ནི་ཡ་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨར་མི་ནི་ཡ་ཚད་ལྡན་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨར་ཇེན་ཊི་ན་བྱཱར་དུས་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨར་ཇེན་ཊི་ན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨར་ཇེན་ཊི་ན་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨར་ཇེན་ཊི་ན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨ་རེ་བྷི་ཡན་སྲུང་ཆུ་ཚོད",
        "ཨ་རེ་བྷི་ཡན་ཚད་ལྡན་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་པེ་སི་ཕིག་ཉིན་སྲུང་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་པེ་སི་ཕིག་ཚད་ལྡན་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་མའུ་ཊེན་ཉིན་སྲུང་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་མའུ་ཊེན་ཚད་ལྡན་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་ཤར་ཕྱོགས་ཉིན་སྲུང་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་ཤར་ཕྱོགས་ཚད་ལྡན་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་དབུས་ཕྱོགས་ཉིན་སྲུང་ཆུ་ཚོད",
        "བྱང་ཨ་མི་རི་ཀ་དབུས་ཕྱོགས་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨེ་མ་ཛཱོན་བྱཱར་དུས་ཆུ་ཚོད",
        "ཨེ་མ་ཛཱོན་ཚད་ལྡན་ཆུ་ཚོད",
        "ཨ་ལསི་ཀ་ཉིན་སྲུང་ཆུ་ཚོད",
        "ཨ་ལསི་ཀ་ཚད་ལྡན་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨཕ་རི་ཀཱ་བྱཱར་དུས་ཆུ་ཚོད",
        "ནུབ་ཕྱོགས་ཨཕ་རི་ཀཱ་ཚད་ལྡན་ཆུ་ཚོད",
        "ལྷོ་ཕྱོགས་ཨཕ་རི་ཀཱ་ཆུ་ཚོད",
        "ཤར་ཕྱོགས་ཨཕ་རི་ཀཱ་ཆུ་ཚོད",
        "དབུས་ཕྱོགས་ཨཕ་རི་ཀཱ་ཆུ་ཚོད",
        "ཨཕ་ག་ནི་ས྄ཏཱནཆུ་ཚོད",
        "Tuvalski",
        "Chuukski",
        "Tokelauski",
        "Tadźikiski",
        "Tahitiski",
        "Pódpołdnjowogeorgiski",
        "Salomoński",
        "Seychelski",
        "Nowoginejski",
        "Palauski",
        "Niueski",
        "Nauruski",
        "Myanmarski",
        "Marqueski",
        "Malediwski",
        "Malajziski",
        "Kirgiski",
        "Kosraeski",
        "Pódwjacornokazachski",
        "Pódzajtšnokazachski",
        "Pódwjacornoindoneski",
        "Pódzajtšnoindoneski",
        "Srjejźoindoneski",
        "Indochinski",
        "Indiskooceaniski",
        "Indiski",
        "Guyański",
        "Persiskego",
        "Galapagoski",
        "teritoriuma",
        "pódpołdnjowego",
        "francojskego",
        "Francojskoguyański",
        "Ekuadorski",
        "Pódzajtšnotimorski",
        "DumontDUrville",
        "Gódownych",
        "Chamorrski",
        "Bruneiski",
        "Boliwiski",
        "Bhutański",
        "Pódpołdnjowoafriski",
        "Pódzajtšnoafriski",
        "Srjejźoafriski",
        "Afghaniski",
        "Tajicistan",
        "Indo",
        "Gwlff",
        "Frengig",
        "Antarctig",
        "ac",
        "Deheuol",
        "Ffrengig",
        "Pell",
        "Hanner",
        "Nadolig",
        "Venezuelský",
        "Tuvalský",
        "Chuukský",
        "Tokelauský",
        "Tádžický",
        "Tahitský",
        "Surinamský",
        "Jižní",
        "Šalamounových",
        "Singapurský",
        "Seychelský",
        "Rotherovy",
        "Réunionský",
        "Pchjongjangský",
        "Ponapský",
        "Pitcairnova",
        "Nové",
        "Palauský",
        "Severomariánský",
        "Norfolský",
        "Niuejský",
        "Nepálský",
        "Naurský",
        "Myanmarský",
        "Mawsonovy",
        "Markézský",
        "Maledivský",
        "Malajský",
        "Srílanský",
        "Kyrgyzský",
        "Kosrajský",
        "Západokazachstánský",
        "Východokazachstánský",
        "Západoindonéský",
        "Východoindonéský",
        "Středoindonéský",
        "Indočínský",
        "Indickooceánský",
        "Indický",
        "Guyanský",
        "Perského",
        "Guamský",
        "střední",
        "Greenwichský",
        "Gambierský",
        "Galapážský",
        "jižních",
        "Francouzských",
        "Francouzskoguyanský",
        "Dálněvýchodoevropský",
        "Ekvádorský",
        "Východotimorský",
        "d’Urvilla",
        "Davisovy",
        "Vánočního",
        "Chamorrský",
        "Caseyho",
        "Brunejský",
        "Bolivijský",
        "Bhútánský",
        "Jihoafrický",
        "Východoafrický",
        "Středoafrický",
        "Afghánský",
        "ᏊᏚᎾ",
        "ᏩᎵᏍ",
        "ᎤᏰᏨ",
        "ᏬᏍᏙᎧ",
        "ᏪᏁᏑᏪᎳ",
        "ᏚᏩᎷ",
        "ᏧᎩ",
        "ᏙᎨᎳᎤ",
        "ᏔᏥᎩᏍᏕᏂ",
        "ᏔᎯᏘ",
        "ᏏᏲᏩ",
        "ᏒᎵᎾᎻ",
        "ᏐᎶᎹᏂ",
        "ᏏᏂᎦᏉᎵ",
        "ᏎᏤᎴᏏ",
        "ᎳᏞᎳ",
        "ᎴᏳᏂᎠᏂ",
        "ᏈᏯᏂᎩᏰᏂᎩ",
        "ᏉᎾᏇ",
        "ᏈᎧᎵᏂ",
        "ᏧᎴᎯᏌᏅᎯ",
        "ᎩᎢᏂ",
        "ᏆᏇ",
        "ᏆᎷ",
        "ᏃᎵᏬᎵᎩ",
        "ᏂᏳ",
        "ᏁᏆᎵ",
        "ᎾᎷ",
        "ᎹᏯᎹᎵ",
        "ᎹᏌᏂ",
        "ᎹᏌᎵ",
        "ᎹᎵᎨᏌᏏ",
        "ᎹᎵᏗᏫᏍ",
        "ᎹᎴᏏᎢᎠ",
        "ᎹᏇᎵ",
        "ᎠᏍᏓᏅᏅ",
        "ᎩᎵᏣᎢᏍ",
        "ᎪᏍᎴ",
        "ᎢᏂᏙᏓᎶᏂᎨᏍᏛ",
        "ᎠᎺᏉᎯ",
        "ᎠᏂᏴᏫᏯ",
        "ᎢᏂᏗᎢᎠ",
        "ᎦᏯᎾ",
        "ᎡᏉᏄᎸᏗ",
        "ᎢᏳᏍᏗ",
        "ᎩᎵᏇᏘ",
        "ᎦᎻᏇᎵ",
        "ᏓᎦᏏ",
        "ᎡᏆ",
        "ᎤᏁᏍᏓᎶ",
        "ᎤᎦᏃᏮ",
        "ᎠᏂᎦᎸᏥ",
        "ᏈᏯᎾ",
        "ᎠᏂᎦᎸ",
        "ᎡᏆᏙᎵ",
        "ᏘᎼᎵ",
        "Ꮧ’ᎤᎵᏫᎵ",
        "ᏚᎼᎾᏘ",
        "ᏕᏫᏏ",
        "ᎪᎪᏍ",
        "ᏓᏂᏍᏓᏲᎯᎲ",
        "ᏣᎼᎶ",
        "ᏓᎷᏌᎳᎻ",
        "ᏊᎾᎢ",
        "ᏉᎵᏫᎠ",
        "ᏊᏔᏂ",
        "ᎠᏫᎨᏂᏍᏖᏂ",
        "е",
        "Георги",
        "Соломонан",
        "Сейшелан",
        "Гвиней",
        "Маршалан",
        "Маркизан",
        "Мальдиваш",
        "Малайзи",
        "Киргизи",
        "Индин",
        "айма",
        "ГӀажарийн",
        "юкъара",
        "Гринвичица",
        "Гилбертан",
        "Галапагосан",
        "Антарктидан",
        "къилба",
        "Минскера",
        "Калининградера",
        "Кокосийн",
        "ден",
        "винчу",
        "пайхамар",
        "Ӏийса",
        "Боливи",
        "пачхьалкх",
        "ОвхӀан",
        "веме",
        "Макверијско",
        "Лине",
        "Киргизстан",
        "Хонгконшко",
        "средње",
        "Гамбијер",
        "Божићна",
        "Зеленортско",
        "Зелениртско",
        "лтње",
        "फ़ुतुना",
        "वालीस",
        "वाके",
        "वॉस्तॉक",
        "तुवालु",
        "ट्रुक",
        "टोकेलौ",
        "ताजीक़ीस्तान",
        "टाहिटी",
        "सीओवा",
        "सुरीनाम",
        "साऊथ",
        "सॉलॉमन",
        "सींगापुर",
        "रियूनियन",
        "(पोह्नपेई)",
        "पीटकैर्न",
        "फीनीक्स",
        "गीनी",
        "मारिआना",
        "नॉर्थ",
        "नीऊई",
        "नाऊरु",
        "र्स्टैंडर्ड",
        "मार्केज़ास",
        "लाईन",
        "क़ीर्ग़स्तान",
        "कोसरी",
        "चइना",
        "ईंडो",
        "महासगर",
        "गल्फ़",
        "ग्रीनीच",
        "गीलबर्ट",
        "गांबिये",
        "गालापागोस",
        "एक्वाडौर",
        "टीमोर",
        "ईस्ट",
        "ड्युरवील",
        "ड्युमों",
        "डेवीस",
        "कोको",
        "क्रीसमस",
        "चामरो",
        "सलाम",
        "उस",
        "दर",
        "बोलिविया",
        "भुटान",
        "अर्जनटिना",
        "अफ़गानी",
        "Su",
        "Sechelez",
        "Reünion",
        "Maldivez",
        "Kyrgyzstan",
        "Indez",
        "Pers",
        "mor",
        "Pleg",
        "(AKG)",
        "keitat",
        "Amzer",
        "hag",
        "Frañs",
        "aostral",
        "Douaroù",
        "cʼhall",
        "Kokoz",
        "Suafrika",
        "Kreizafrika",
        "Венецуелско",
        "Чуюк",
        "Таджикистанско",
        "Таитянско",
        "Суринамско",
        "Южна",
        "Соломонови",
        "Сингапурско",
        "Сейшелски",
        "Пхенян",
        "Времето",
        "Непалско",
        "Мианмарско",
        "Моусън",
        "Маршалови",
        "Маркизки",
        "Малдивски",
        "Малайзийско",
        "Екваториални",
        "Киргизстанско",
        "Кошрай",
        "Западноказахстанско",
        "Източноказахстанско",
        "Западноиндонезийско",
        "Източноиндонезийско",
        "Централноиндонезийско",
        "Индокитайско",
        "Индийски",
        "Индийско",
        "Гаяна",
        "Персийски",
        "гринуичко",
        "Гилбърт",
        "Галапагоско",
        "територии",
        "антарктически",
        "южни",
        "Френски",
        "Френска",
        "източноевропейско",
        "Далечно",
        "Еквадорско",
        "Източнотиморско",
        "Дюрвил",
        "Кокосови",
        "Боливийско",
        "Бутанско",
        "Южноафриканско",
        "Източноафриканско",
        "Централноафриканско",
        "Афганистанско",
        "Уоліс",
        "Усход",
        "Венесуэльскі",
        "Такелау",
        "Таджыкістана",
        "Таіці",
        "Сурынама",
        "Джорджыі",
        "Паўднёвай",
        "Саламонавых",
        "Сінгапурскі",
        "Сейшэльскіх",
        "Ратэра",
        "Рэюньёна",
        "Пхеньянскі",
        "Понпеі",
        "Піткэрн",
        "Гвінеі",
        "Норфалк",
        "Ніуэ",
        "Непальскі",
        "М’янмы",
        "стандатны",
        "Моўсан",
        "Маршалавых",
        "Маркізскіх",
        "Мальдыў",
        "Малайзіі",
        "Макуоры",
        "Кыргызстана",
        "Кусаіе",
        "Заходнеказахстанскі",
        "Усходнеказахстанскі",
        "Заходнеінданезійскі",
        "Усходнеінданезійскі",
        "Цэнтральнаінданезійскі",
        "Індакітайскі",
        "акіяна",
        "Індыйскага",
        "Індыі",
        "Гаяны",
        "заліва",
        "Персідскага",
        "Грынвічы",
        "па",
        "Гамб’е",
        "атравоў",
        "Галапагоскіх",
        "тэрыторый",
        "Антарктычных",
        "Паўднёвых",
        "Французскіх",
        "Гвіяны",
        "Французскай",
        "Беларускі",
        "Эквадорскі",
        "Тымора",
        "Усходняга",
        "Дзюрвіль",
        "Дзюмон",
        "Дэйвіс",
        "Паўлетні",
        "Какосавых",
        "Каляд",
        "Чамора",
        "Брунея",
        "Балівійскі",
        "Бутана",
        "Паўднёваафрыканскі",
        "Усходнеафрыканскі",
        "Цэнтральнаафрыканскі",
        "Афганістанскі",
        "branu",
        "Marianes",
        "estandar",
        "d’Indochina",
        "Índicu",
        "Océanu",
        "Golfu",
        "francés",
        "l’antárticu",
        "sur",
        "saseMpumalanga",
        "estremu",
        "saseNingizimu",
        "d’Ecuador",
        "d’Afganistán",
        "স.",
        "ভা.",
        "ভাৰতীয়",
        "የያኩትስክ",
        "ያኩትስክ",
        "ፉቱና",
        "የዋሊስ",
        "የዌክ",
        "የቮስቶክ",
        "የቫልጎራድ",
        "esiyingxenye",
        "yasehlobo",
        "የቮልጎራድ",
        "የቭላዲቮስቶክ",
        "sakwa",
        "Mpumalanga",
        "esisezengeni",
        "Iikhathi",
        "የቪላዲቮስቶክ",
        "የቬኔዝዌላ",
        "የአይሪሽ",
        "የብሪትሽ",
        "የቱቫሉ",
        "የቹክ",
        "የቶኬላው",
        "የታጂኪስታን",
        "የታሂቲ",
        "የሲዮዋ",
        "የሱሪናም",
        "ጂዮርጂያ",
        "Ірландскі",
        "Брытанскі",
        "Ирландско",
        "የሰለሞን",
        "የሲንጋፒር",
        "የሴሸልስ",
        "Iwerzhon",
        "Breizh",
        "Veur",
        "आईरीश",
        "ब्रीटीश",
        "የሮቴራ",
        "የሬዩኒየን",
        "የፕዮንግያንግ",
        "የፖናፔ",
        "Ирланди",
        "Британин",
        "ᎨᎵᎩ",
        "ᏈᏗᏏ",
        "Irský",
        "Britský",
        "Iwerddon",
        "Prydain",
        "የፒትካይርን",
        "የፊኒክስ",
        "ጊኒ",
        "ኒው",
        "Iriski",
        "የፓፗ",
        "ཨཱ་ཡརིཤ་བྱཱར་དུས་ཆུ་ཚོད",
        "བྲཱི་ཊིཤ་བྱཱར་དུས་ཆུ་ཚོད",
        "የፓላው",
        "ሰአት",
        "የኖቮሲብሪስክ",
        "የኖቮሲቢርስክ",
        "BST",
        "ኖሮንሃ",
        "Iiri",
        "Briti",
        "Irlandako",
        "Londresko",
        "ኖሮንቻ",
        "የኖርፎልክ",
        "Irlannin",
        "Britannian",
        "የኒዩዌ",
        "Britain",
        "የኔፓል",
        "የናውሩ",
        "የሚያንማር",
        "የማውሰን",
        "የማርሻል",
        "Caighdéanach",
        "hÉireann",
        "ACÉ",
        "Breataine",
        "ASB",
        "Èireann",
        "TSÈ",
        "(Èirinn)",
        "Bhreatainn",
        "TSB",
        "irlandés",
        "británico",
        "આઈરિશ",
        "બ્રિટિશ",
        "אירלנד",
        "בריטניה",
        "የማርኴሳስ",
        "የማልዲቭስ",
        "የማሌይዢያ",
        "የማከሪ",
        "Irski",
        "ír",
        "brit",
        "Իռլանդական",
        "Բրիտանական",
        "የላይን",
        "Inggris",
        "Írlandi",
        "Bretlandi",
        "የኪርጊስታን",
        "የክራስኖያርስክ",
        "የክራስኖይአርስክ",
        "アイルランド標準時",
        "英国夏時間",
        "ირლანდიის",
        "ბრიტანეთის",
        "የኮስራኤ",
        "Ұлыбритания",
        "ម៉ោង​រដូវ​ក្ដៅ​នៅ​អៀរឡង់​",
        "ម៉ោង​រដូវ​ក្ដៅ​​នៅ​ចក្រភព​អង់គ្លេស",
        "ಐರಿಷ್",
        "ಬ್ರಿಟಿಷ್",
        "ኢርኩትስክ",
        "የኢርኩትስክ",
        "اَیرِش",
        "برطٲنوی",
        "Irland",
        "Jrußbretannije",
        "የኢንዶቻይና",
        "Iresch",
        "Britesch",
        "ເວ​ລາ​ມາດ​ຕະ​ຖານ​ໄອ​ຣິ​ຊ",
        "ເວ​ລາ​ລະ​ດູ​ຮ້ອນ​ອັງ​ກິດ",
        "Airijos",
        "Britanijos",
        "Īrijas",
        "Lielbritānijas",
        "Ирско",
        "ഐറിഷ്",
        "ബ്രിട്ടീഷ്",
        "Ирландын",
        "Британийн",
        "आयरिश",
        "အိုင်းရစ်ရှ်စံတော်ချိန်",
        "ဗြိတိန်နွေရာသီအချိန်",
        "ውቅያኖስ",
        "የጉያና",
        "የባህረሰላጤ",
        "ማዕከላዊ",
        "ግሪንዊች",
        "Brytyjski",
        "czas",
        "የጂልበርት",
        "Britânico",
        "የጋምቢየር",
        "የጋላፓጎስ",
        "አንታርክቲክ",
        "අයර්ලන්ත",
        "බ්‍රිතාන්‍ය",
        "írsky",
        "britský",
        "irski",
        "britanski",
        "ደቡባዊ",
        "ጉያና",
        "የአውሮፓ",
        "የሩቅ",
        "የኢኳዶር",
        "ቲሞር",
        "ዱርቪል",
        "የዱሞንት",
        "የዴቪስ",
        "ግማሽ",
        "የኮኮስ",
        "የገና",
        "የሰአት",
        "የቻሞሮ",
        "ዳሩሳላም",
        "የብሩኔይ",
        "የብራዚላ",
        "የብራሲሊያ",
        "ఐరిష్",
        "బ్రిటీష్",
        "เวลามาตรฐานไอร์แลนด์",
        "เวลาฤดูร้อนอังกฤษ",
        "Irlandiýa",
        "Beýik",
        "Britaniýa",
        "fakaʻaealani",
        "fakapilitānia",
        "የቦሊቪያ",
        "የቡታን",
        "ئىرېلاند",
        "ئەنگلىيە",
        "Ірландії",
        "Великій",
        "Британії",
        "መካከለኛው",
        "አርጀንቲና",
        "ጊዜ",
        "Ai",
        "len",
        "Anh",
        "የአናድይር",
        "የአናዲይር",
        "የቀንሰዓት",
        "የአፍጋኒስታን",
        "Гвинейн"
    ]
});};
