function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var config = {
  debug: false,
  containerId: null,
  dataLayerName: 'dataLayer',
  dataLayer: undefined,
  preview: undefined,
  auth: undefined,
  execution: 'async' // assumesPageview: true,

};
var initializedDataLayerName;
/**
 * Google tag manager plugin
 * @link https://getanalytics.io/plugins/google-tag-manager
 * @link https://developers.google.com/tag-manager/
 * @param {object} pluginConfig - Plugin settings
 * @param {string} pluginConfig.containerId - The Container ID uniquely identifies the GTM Container.
 * @param {string} [pluginConfig.dataLayerName=dataLayer] - The optional name for dataLayer-object. Defaults to dataLayer.
 * @param {string} [pluginConfig.customScriptSrc] - Load Google Tag Manager script from a custom source
 * @param {string} [pluginConfig.preview] - The preview-mode environment
 * @param {string} [pluginConfig.auth] - The preview-mode authentication credentials
 * @param {string} [pluginConfig.execution] - The script execution mode
 * @return {object} Analytics plugin
 * @example
 *
 * googleTagManager({
 *   containerId: 'GTM-123xyz'
 * })
 */

function googleTagManager() {
  var pluginConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaultScriptSrc = 'https://www.googletagmanager.com/gtm.js'; // Allow for userland overides of base methods

  return {
    name: 'google-tag-manager',
    config: _objectSpread(_objectSpread({}, config), pluginConfig),
    initialize: function initialize(_ref) {
      var config = _ref.config;
      var containerId = config.containerId,
          dataLayerName = config.dataLayerName,
          customScriptSrc = config.customScriptSrc,
          preview = config.preview,
          auth = config.auth,
          execution = config.execution;

      if (!containerId) {
        throw new Error('No google tag manager containerId defined');
      }

      if (preview && !auth) {
        throw new Error('When enabling preview mode, both preview and auth parameters must be defined');
      }

      var scriptSrc = customScriptSrc || defaultScriptSrc;

      if (!scriptLoaded(containerId, scriptSrc)) {
        /* eslint-disable */
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '',
              p = preview ? '&gtm_preview=' + preview + '&gtm_auth=' + auth + '&gtm_cookies_win=x' : '';

          if (execution) {
            j[execution] = true;
          }

          j.src = "".concat(scriptSrc, "?id=") + i + dl + p;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', dataLayerName, containerId);
        /* eslint-enable */


        initializedDataLayerName = dataLayerName;
        config.dataLayer = window[dataLayerName];
      }
    },
    page: function page(_ref2) {
      var payload = _ref2.payload;
          _ref2.options;
          _ref2.instance;
          var config = _ref2.config;

      if (typeof config.dataLayer !== 'undefined') {
        config.dataLayer.push(payload.properties);
      }
    },
    track: function track(_ref3) {
      var payload = _ref3.payload;
          _ref3.options;
          var config = _ref3.config;

      if (typeof config.dataLayer !== 'undefined') {
        var anonymousId = payload.anonymousId,
            userId = payload.userId,
            properties = payload.properties;
        var formattedPayload = properties;

        if (userId) {
          formattedPayload.userId = userId;
        }

        if (anonymousId) {
          formattedPayload.anonymousId = anonymousId;
        }

        if (!properties.category) {
          formattedPayload.category = 'All';
        }

        if (config.debug) {
          console.log('dataLayer push', _objectSpread({
            event: payload.event
          }, formattedPayload));
        }

        config.dataLayer.push(_objectSpread({
          event: payload.event
        }, formattedPayload));
      }
    },
    loaded: function loaded() {
      var hasDataLayer = !!initializedDataLayerName && !!(window[initializedDataLayerName] && Array.prototype.push !== window[initializedDataLayerName].push);
      return scriptLoaded(pluginConfig.containerId, pluginConfig.customScriptSrc || defaultScriptSrc) && hasDataLayer;
    }
  };
}
var regexCache = {};
/*
TODO add logic to make it impossible to load 2 plugins with the same container ID
[containerID]: pluginName
*/

function scriptLoaded(containerId, scriptSrc) {
  var regex = regexCache[containerId];

  if (!regex) {
    var scriptSrcEscaped = scriptSrc.replace(/^https?:\/\//, '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    regex = new RegExp(scriptSrcEscaped + '.*[?&]id=' + containerId);
    regexCache[containerId] = regex;
  }

  var scripts = document.querySelectorAll('script[src]');
  return !!Object.keys(scripts).filter(function (key) {
    return (scripts[key].src || '').match(regex);
  }).length;
}

/* This module will shake out unused code and work in browser and node 🎉 */

var index = googleTagManager ;

export { index as default };
